import React from "react";
import {Button, Col, Row} from "reactstrap";
import TreeGriCollectDataEntryComponent from "../tree-dataentry-component";

export const ValueTreeStep = props => (
	<React.Fragment>
		<Row>
			<Col>
				<div className="page-title-box">
					<div className="page-title-right">
						<Button
							color={"primary"}
							onClick={ clickEvent => {
								props.previousStep();
							}}
						>
							Indietro
						</Button>
						<span>&nbsp;&nbsp;</span>
						<Button
							color={"primary"}
							onClick={ clickEvent => {
								props.nextStep();
							}}
						>
							Avanti
						</Button>
					</div>
				</div>
			</Col>
		</Row>
		
		<Row>
			<Col>
				<TreeGriCollectDataEntryComponent
					tree={ props.tree }
					seed={ props.seed }
					collect={ props.collect }
					fullDataCollect={ props.fullDataCollect }
					onTreeUpdate={ tree => props.onUpdate(tree) }
					reloadTree={ (tree, tabId) => {
						return props.onUpdate( tree, tabId );
					}}
				/>
			
			</Col>
		</Row>
		
	</React.Fragment>
);
