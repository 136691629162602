// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import {Button, Card, CardHeader, Col, Row} from "reactstrap";
import {PATH_PREFIX, permissions, role} from "../env";
import {showlog} from "../utils/dev-utils";
import {getLastElementOr, onlyNotNull} from "../utils/array-utils";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Spinner from "../components/Spinner";
import {getCurrentRoleLabel} from "../utils/role-utils";
import {translate} from "../components/i18n/translate-function";

const fieldMap = {
};


const hiddenFields = [
];


const paginationOptions = {
    paginationSize: 20,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Permessi
        </span>
    ),
    sizePerPageList: [20]
};

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];


const sortableFields = [
    "id",
    "name"
];



const formatColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldMap[field] || field,
                    sort: sortableFields.includes(field)
                });
            })
            .filter(ff => !hiddenFields.includes(ff.dataField))
    );
}

const {SearchBar} = Search;


export default class PermissionsPage extends React.Component {

    state = {}

    constructor(props) {
        super( props );
    }

    render() {

        // showlog("render di welcome page");
        // showlog( this.state );

        if( !!this.state.counter && this.state.counter === 5 ) {
            alert("Version: 1.1.1");
        }

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: 'Welcome Page', path: '' + PATH_PREFIX + '/' },
                        { label: getCurrentRoleLabel(), active: true }
                    ]}
                    title={'Permessi correnti'}
                />


                <div className={""}>

                    {
                        (!!this.state.permissions)
                            ? (
                                <React.Fragment>
                                    {/*     SDG  -------------------------------------------------------------------------------- */}

                                    <ToolkitProvider
                                        bootstrap4
                                        keyField="id"
                                        search
                                    >
                                        {props => (
                                            <React.Fragment>
                                                <Row>
                                                    <Col>
                                                        <SearchBar {...props.searchProps} />
                                                    </Col>
                                                </Row>
                                                <BootstrapTable
                                                    bootstrap4
                                                    striped
                                                    {...props.baseProps}
                                                    size="sm"
                                                    responsive
                                                    keyField="id"
                                                    bordered={ false }
                                                    data={ this.state.permissions }
                                                    columns={ formatColumns(Object.keys(this.state.permissions[0])) }
                                                    defaultSorted={ defaultSorted }
                                                    pagination={ paginationFactory(paginationOptions) }
                                                    wrapperClasses="table-responsive"
                                                />
                                            </React.Fragment>
                                        )}

                                    </ToolkitProvider>
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <Spinner className="spinner-border-sm"/>
                                    <span className={"loading-text-alignment"}>Caricamento...</span>
                                </React.Fragment>
                            )
                    }



                </div>


            </React.Fragment>
        );
    }

    componentDidMount() {



        this.setState({
            ...this.state,
            permissions:
                Object.keys(role)
                    // nome ruolo corrente
                    .filter(r => role[r].name === localStorage.getItem("role"))

                    // ruolo
                    .map( r => role[r] )

                    // permessi (id) del ruolo
                    .map( r => r.permissions )

                    // [0]
                    .reduce( getLastElementOr, null)

                    // permessi del ruolo
                    .map( permissionId => {

                        return (
                            Object.keys( permissions )
                                .filter( perm => ( permissions[ perm ].id === permissionId ) )
                                .map( perm => permissions[ perm ] )
                                .reduce( getLastElementOr, null )
                        );

                    })

                    // clean
                    .filter( onlyNotNull )


        })


    }

};

