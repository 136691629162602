import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {PATH_PREFIX, permissions, role} from "../../../env";
import SuperAdminUserEditModalContent from "../../../components/area/super-admin/user-edit-modal";
import DeleteUserQuestionModal from "../../../components/area/delete-user-prompt-modal";
import {loadUsers} from "../../../business-logic/users";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {Redirect} from "react-router-dom";
import {onlyNotNull} from "../../../utils/array-utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import ConfirmNewUserModal from '../../../components/area/super-admin/confirm-new-user-modal';
import {translate} from '../../../components/i18n/translate-function';

const fieldMap = {
	id: "Id",
	picture: " ",
	// title: "Titolo",
	firstName: translate("Name"),
	lastName: translate("Surname"),
	role: translate("Role"),
	companyName: translate("Companies"),
	email: "Email",
	leadingCompany: "Organizzazione",
	language: translate("Language"),
	buttons: " ",
	leadingcompany_id: "leadingcompany_id"	//	nascosto

};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Users")}
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'lastName',
		order: 'asc',
	},
];

const hiddenFields = [
	"id",
	"title",
	"leadingcompany_id",
	isUserRole( role.PROJECT_MANAGER ) ? null : "leadingCompany",
	"companyId",
	"language_id"
]
.filter( onlyNotNull );

const sortableFields = [
	//"Titolo",
	translate("Name"),
	translate("Surname"),
	translate("Role"),
	translate("Companies"),
	"Email",
	"title",
	"firstName",
	"lastName",
	"role",
	"companyName",
	"email",
	"leadingCompany",
	"language"
];

const formatColumns = (columnList, hiddenColumnList = []) => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
			.filter( ff => !hiddenColumnList.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SuperAdminUsers extends Component {

	state = {
		data: null
	}

	constructor(props) {
		super( props );
		this.loadData = this.loadData.bind( this );
	}


	render() {


		renderlog("SuperAdminUsers Page", this.state, this.props);


		if( !!this.state.assignConsultantPage ) {
			return (
				<Redirect to={PATH_PREFIX +"/consuler/assignto/"+ this.state.assignConsultantPage.id} />
			);
		}





		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						/*{
							label:
							Object.keys( role )
								.map( roleObjectName => role[ roleObjectName ] )
								.filter( roleObject => roleObject.name === localStorage.getItem("role") )
								.reduce( getLastElementOr, "Nessun Ruolo" )
								.label
							, path: PATH_PREFIX +'/welcome' }, */
						{ label: translate("Users"), active: true }
					]}
					title={translate("Users")}
				/>






							<Card>
								<CardBody>



									<Row>
										<Col>
											<div className="page-title-box">
												<div className="page-title-right">

													{
														(!!hasPermission(permissions.ADD_USER))
															? (
																<Button
																	color={"success"}
																	onClick={ clickEvent => {
																		console.log("click nuovo utente onClick");
																		this.setState({
																			...this.state,
																			newUserModal: true
																		});
																	}}
																>
																	<i className="uil-user"/> {translate("New User")}
																</Button>
															)
															: null
													}


												</div>
												<h4 className="page-title">{translate("Users List")}</h4>
											</div>
										</Col>
									</Row>


				{
					(!!this.state.data && this.state.data.length > 0)
						? (



									<ToolkitProvider
										bootstrap4
										keyField="Id"
										search
										exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
										{props => (
											<React.Fragment>
												<Row>
													<Col>
														<SearchBar {...props.searchProps} />
													</Col>
													{/*
													<Col className="text-right">
														<ExportCSVButton {...props.csvProps} className="btn btn-primary">
															Export CSV
														</ExportCSVButton>
													</Col>
													*/}
												</Row>


												<BootstrapTable
													bootstrap4
													striped
													{...props.baseProps}
													size="sm"
													responsive
													keyField="Id"
													data={ this.state.data }
													columns={
														formatColumns(
															Object.keys( this.state.data[0] ),
															[
																!!hasPermission( permissions.READ_COMPANY )
																	? ""
																	: "companyName"
															]
														)
													}
													defaultSorted={ defaultSorted }
													pagination={ paginationFactory(paginationOptions) }
													wrapperClasses="table-responsive"
												/>


											</React.Fragment>
										)}
									</ToolkitProvider>


						)
						: (
							<WaitingOrNodataMessage
								waiting={ !(!!this.state.data && this.state.data.length === 0) }
								nodataMessage={ "Nessun Utente" }
								fullHeight={ true }
							/>
						)
				}



								</CardBody>
							</Card>




				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={ this.state.errorMessage }
								noheader={ true }
								noHazard={ true }
								onClose={ () => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}



				{
					((!!this.state.newUserModal) || !!this.state.editUserModal)
						? (
							<SuperAdminUserEditModalContent
								close={ openConfirmModal => {

									this.setState({
										...this.state,
										openConfirmNewUserModal: openConfirmModal,
										newUserModal: false,
										editUserModal: false,
										user: openConfirmModal
									})


								}}
								user={

									!!this.state.user
										? ({
											companyId: this.state.user.companyId,
											companyName: this.state.user.companyName,
											email: this.state.user.email,
											firstName: this.state.user.firstName,
											id: this.state.user.id,
											lastName: this.state.user.lastName,
											role: this.state.user.role,
											leadingcompany_id: this.state.user.leadingcompany_id,
											language_id: this.state.user.language_id
										})
										: null

								}
								isEdit={ !!this.state.editUserModal }
							/>
						)
						: null
				}

				{

					(!!this.state.openConfirmNewUserModal)

						? (

							<ConfirmNewUserModal
								esternalStateUser = {this.state.openConfirmNewUserModal}
								isNewUser={ !!this.state.openConfirmNewUserModal.isNewUser }
								//esternalPropsUser = {!!this.props.user}
								onClose = {closeEvent => {

									this.setState({
										...this.state,
										openConfirmNewUserModal: false,
										errorMessage: !!closeEvent ? "E' stata inviata una email con un link di attivazione valido per 48 ore" : null,
										user: null
									}, ()=>{
										// if(!!closeEvent){

											setTimeout(
												function() {
													this.loadData();
												}.bind( this ),
												1000
											)

										// }
									})


								}}
							/>

						) : null

				}


				{
					(!!this.state.deletePopup)
						? (
							<DeleteUserQuestionModal
								user={this.state.deletePopup}
								onClose={() => {
									this.setState({deletePopup: null})
								}}
								onConfirm={userDeletedStatus => {
									console.log("utente eliminato");
									console.log(userDeletedStatus);
									this.loadData( () => {
										this.setState({...this.state, deletePopup: null})
									});
								}}
							/>
						)
						: null
				}

			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
	}


	loadData( onEnd ) {

		loadUsers()



			// profilePicture ------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.map(row => {
							// TODO pictures
							row.picture = (
								<div
									className={"profile-picture-in-list picture-alternative"}
								>
									{ row.firstName.substr(0, 1).toUpperCase() + row.lastName.substr(0, 1).toUpperCase() }
								</div>
							);
							return row;
						})
				);
			})


			// buttons -------------------------------------------------------------------------------------------------
			.then( responseData => {
				// debugger;
				return (
					responseData
						.map( row => {


							showlog("riga in tabella", row);
							/*
							{
								companyId: 6
								companyName: "CLS"
								email: "admin@example.com"
								firstName: "Francesco"
								id: 24
								lastName: "Verdi"
								picture: {$$typeof: Symbol(react.element), type: "div", key: null, ref: null, props: {…}, …}
								role: "Admin"
								title: "Ing."
							}
							 */



							// row.email;
							// row["email"];


							row.buttons = (
								<React.Fragment>
									{
										(
											row.role === role.SUPER_ADMIN.label
											&& !hasPermission( permissions.EDIT_SUPER_ADMIN_ROLE_USER )
										)
											? null
											: (
												<i
													className="mdi mdi-account-edit-outline comander-icon"
													onClick={ clickEvent => {
														console.log("modifica ", row.id);
														this.setState({...this.state, editUserModal: true, user: row});
													}}
												/>
											)
									}


									<i
										className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"
										onClick={ clickEvent => {
											console.log("elimina ", row.id);
											this.setState({...this.state, deletePopup: row});
										}}
									/>

									{
										(row.role === role.CONSULTANT.label)
											? (
												<i
													className="mdi mdi-account-tie-outline comander-icon comander-icon-trash"
													onClick={ clickEvent => {
														console.log("elimina ", row.id);
														this.setState({...this.state, assignConsultantPage: row});
													}}
												/>
											)
											: null
									}

								</React.Fragment>
							);
								{/*<div>Modifica | Elimina</div>*/}
							return row;
						})
				);
			})


			// field sort ----------------------------------------------------------------------------------------------
			.then( responseData => {

				// debugger;


				return (
					responseData
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})

			// finish --------------------------------------------------------------------------------------------------
			.then( responseData => {

				// debugger;

				/*  format column
				let dataTable =
					responseData
						.map( row => {
							let rowDTO = {};
							Object.keys(row)
								.forEach( field => {
									rowDTO[ fieldMap[field] || field ]= row[field];
								})
							;
							return rowDTO;
						})

				;
				*/

				this.setState(
					{
						...this.state,
						data: responseData,
						errorMessage: null
					},
					() => {
						if( typeof onEnd === "function" ) onEnd();
					}
				);
			})
			.catch( e => {
				errorlog("Load users", e );
			})
		;

	}
}
