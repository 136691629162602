import {Component} from "react";
import {validationSuperAdminNewUser} from "../../business-logic/companies";
import {saveNewUserBySuperAdmin} from "../../business-logic/users";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteUserBySuperAdmin} from "../../services/users-service";
import {errorlog, showlog} from "../../utils/dev-utils";

export default class DeleteUserQuestionModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.onClose( forceRefresh );
	}

	render() {

		let context = (
			"Sicuro di voler eliminare l'utente "+ this.props.user.firstName +" "+ this.props.user.lastName +" ?"
		);

		if( !!this.props.message ) context = this.props.message;

		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={ "modal-colored-header bg-danger" }>
					ATTENZIONE
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {
							console.log("click su conferma");
							deleteUserBySuperAdmin( this.props.user.id )
								.then( () => {
									this.props.onConfirm( true );
								})
								.catch(e => {
									errorlog("errore", e);
								})
						}}
					>
						OK
					</Button>{' '}
					<Button color="link" onClick={this.toggle}>
						annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;
	}

	componentDidMount() {}
}
