import React, {useState} from "react";
import {emoj, errorlog, renderlog, showlog} from "../../../utils";
import {Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ActionButton from "../../save-button";
import {EMOJ} from "../../../emoj";
import {loadAdminQuestionNote, loadInformativeNote, loadQuestionNote, saveNote} from "../../../business-logic/rating";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {translate} from "../../i18n/translate-function";

export const QuestionNoteModal = props => {

    renderlog("QuestionNoteModal", null, props);

    const [newNote, setNewNote] = useState();
    const [note, setNote] = useState();
    const [modified, setModified] = useState(false);


    let content = (
        <Modal isOpen={ true } toggle={ () => props.onClose() }>
            <ModalHeader toggle={ () => props.onClose() }>
                <span
                    className={"question-history-button cursor-pointer sirating-themes-accordion-title"}
                >
                    { emoj( EMOJ.clipboard ) }
                </span>
                {translate("Notes")}
            </ModalHeader>
            <ModalBody>


                {
                    (note !== undefined || note === "")
                        ? (
                            <Input
                                type="textarea"
                                row={ 32 }
                                name={"note-" + props.question.id}
                                id={"note-" + props.question.id}
                                placeholder={translate("Add a note")}
                                className={"question-note"}
                                defaultValue={
                                    note
                                }
                                onBlur={ blurEvent => {
                                    showlog( blurEvent.target.value );
                                    setModified(true)
                                    setNewNote( blurEvent.target.value );
                                }}
                                onChange={changeEvent =>{
                                    setModified(true)
                                }}
                                required
                                disabled={ !!props.isInAudit }
                            />
                        )
                        : (
                            <WaitingOrNodataMessage
                                waiting={ !note /*&& note !== ""*/ }
                                spinnerColor={"success"}
                                waitingMessage={""}
                                nodataMessage={"Nessuna nota"}
                            />
                        )
                }



            </ModalBody>
            <ModalFooter>
                <ActionButton
                    buttonText={translate("Save")}
                    promise={ () => {
                        return saveNote( props.ratingId, props.question.id, (!!modified || note === null || note === undefined || !note)?newNote:note, !!props.isSRTool, !!props.isAdmin );
                    }}
                    onProcess={ result => {
                        showlog("saving question note", result);
                        if ( typeof props.onSave === "function" ) props.onSave( (!!modified || note === null || note === undefined || !note)?newNote:note );
                    }}
                    onResult={ result => {
                        // debugger;
                        props.onClose();
                        // props.onSave( note );
                        // props.onClose()
                    }}
                    onError={ error => {
                        errorlog("Salvataggio nota", error );
                    }}

                />
            </ModalFooter>
        </Modal>
    );


    if(note === undefined && note !== "") {
        showlog("caricamento della nota");

        if( !!props.isSRTool ) {
            loadInformativeNote( props.ratingId, props.question.id )
                .then( questionNote => {
                    showlog("arrivo della nota");
                    showlog( questionNote );
                    if( typeof questionNote === "object" )   setNote( questionNote.data );
                    else {
                        if( typeof questionNote === "string" )   setNote( questionNote );
                        else setNote("");
                    }
                })
                .catch( err => {
                    errorlog("Caricamento nota informativa", err );
                    setNote( null );
                })
        }
        else {

            if( !!props.isAdmin ) {
                loadAdminQuestionNote( props.ratingId, props.question.id )
                    .then( questionNote => {
                        showlog("arrivo della nota");
                        showlog( questionNote );
                        if( typeof questionNote === "object" )   setNote( questionNote.data );
                        else {
                            if( typeof questionNote === "string" )   setNote( questionNote );
                            else setNote("");
                        }
                    })
                    .catch( err => {
                        errorlog("Caricamento nota", err );
                    })
            }
            else {
                loadQuestionNote( props.ratingId, props.question.id )
                    .then( questionNote => {
                        showlog("arrivo della nota");
                        showlog( questionNote );
                        if( typeof questionNote === "object" )   setNote( questionNote.data );
                        else {
                            if( typeof questionNote === "string" )   setNote( questionNote );
                            else setNote("");
                        }
                    })
                    .catch( err => {
                        errorlog("Caricamento nota", err );
                    })
            }


        }

    }

    return content;

}
