import React, {Component} from 'react';
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {
	CALENDAR_DATE_FORMAT_IT,
	COLOR_POLICY,
	DATE_FORMAT_IT,
	MAX_COLLECTION_NAME_LENGTH,
	permissions,
	role
} from "../../../env";
import {Button, CustomInput, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import HyperDatepicker from "../../Datepicker";
import "../modal.css";
import moment from "moment";
import {loadCollect, saveNewCollect} from "../../../business-logic/gri";
import Spinner from "../../Spinner";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../save-button";
import {getBaseCompaniesList} from "../../../business-logic/companies";
import {extractErrorMessage, getLastElementOr} from "../../../utils";
import Select from "react-select";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {translate} from "../../i18n/translate-function";

export default class AddGriCollectModal extends Component {
	state = {
		startTime: moment( new Date() ).format( DATE_FORMAT_IT ),
		allCollect: this.props.allCollect || [],
		inWait: !this.props.allCollect,
		griType: -1,
		openConfirmContext: false
	}



	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );
	}

	toggle = (resultData, avoidWait = false, error) => {
		if( !!avoidWait ) {
			this.props.onClose( resultData );
		}
		else {
			if( !this.state.waiting ) this.props.onClose( resultData, error );
		}
	}

	render() {

		renderlog(
			"AddCollectModal",
			this.state,
			this.props
		);
		showlog("starttime", this.state.startTime.substring(6) )


		let context = (

			<React.Fragment>

				<AvForm>

					{
						!!this.state.selectedCollect
							? null
							: (
								<AvGroup>
									<Select
										className="react-select gri-type-select"
										classNamePrefix="react-select"
										name="gri-type-select"
										id="gri-type-select"
										onChange={ changeEvent => {
											this.setState({
												...this.state,
												griType: changeEvent.value
											})
										}}
										options={[
											{ value: 0, label: "GRI 2019" },
											{ value: 1, label: "GRI 2021" }
										]}
										placeholder={ <span className={"select-placeholder"}>Scegli Versione</span> }
									/>
								</AvGroup>
							)
					}


					{/*     NOME RACCOLTA  -------------------------------------------------------------------------------- */}
					<AvGroup>
						<Label for={ "newCollectName" } className={""}>Nome</Label>
						<AvInput
							type={ "text" }
							name={ "newCollectName" }
							id={ "newCollectName" }
							value={ this.state.newCollectName }
							className={""}
							onChange={ changeTextEvent => {

								if( changeTextEvent.target.value.length > (MAX_COLLECTION_NAME_LENGTH +1) ) {
									// alert( translate( "CollectionNameCannotGreaterThen" ) + MAX_COLLECTION_NAME_LENGTH );
									this.setState(
										{
											...this.state,
											error: translate( "CollectionNameCannotGreaterThen" ) + MAX_COLLECTION_NAME_LENGTH
										})
								}
								else {
									this.setState(
										{
											...this.state,
											newCollectName: changeTextEvent.target.value.substr( 0, MAX_COLLECTION_NAME_LENGTH ),
											error: null
										})
								}

							}}
							required
						/>
						{
							(!!this.state.error)
								? <label className="error-message-label">{ this.state.error }</label>
								: null
						}
					</AvGroup>



					{/*     DATA_INIZIO  -------------------------------------------------------------------------------- */}
					<AvGroup>
						<Label for="startTime">Data inizio</Label>
						<HyperDatepicker
							hideAddon={true}
							dateFormat={ CALENDAR_DATE_FORMAT_IT }
							onSelect={ clickEvent => {
								showlog("HyperDatepicker onSelect");
								showlog(clickEvent);

								/*
								if(parseInt((new Date()).getTime()) - parseInt(new Date( clickEvent ).getTime()) > 0) {
									// data antecedente ad oggi
									this.setState({
										...this.state,
										dateError: "Attenzione data antecedente ad oggi"
									})
								}
								else {
									this.setState(
										{
											...this.state,
											startTime: moment( clickEvent ).format( DATE_FORMAT_IT ),
											dateError: null
										})
								}
								*/

								this.setState({
									...this.state,
									startTime: moment( clickEvent ).format( DATE_FORMAT_IT ),
									dateError: null
								})



							}}
							value={ this.state.startTime }
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							scrollableYearDropdown
							dropdownMode="select"
							yearDropdownItemNumber={15}
							todayButton="Oggi"
							// minDate={new Date()}
							// maxDate={addDays(new Date(), 5)}
							locale={ "it" }
							highlightDates={[ new Date() ]}
							showWeekNumbers
						/>
						{
							(!!this.state.dateError)
								? <label className="error-message-label">{ this.state.dateError }</label>
								: null
						}
					</AvGroup>




					{/*     ANNO  -------------------------------------------------------------------------------- */}
					<FormGroup >
						<Label for="year">Anno</Label>

						<Select
							className="react-select gri-type-select"
							classNamePrefix="react-select"
							name="gri-type-select"
							id="gri-type-select"
							onChange={ changeEvent => {
								this.setState({
									...this.state,
									year: changeEvent.value
								})
							}}
							options={[
								{ value: parseInt(this.state.startTime.substring(6), 10)+1, label: parseInt(this.state.startTime.substring(6), 10)+1 },
								{ value: parseInt(this.state.startTime.substring(6)), label: parseInt(this.state.startTime.substring(6))  },
								{ value: parseInt(this.state.startTime.substring(6), 10)-1, label: parseInt(this.state.startTime.substring(6), 10)-1 },
								{ value: parseInt(this.state.startTime.substring(6), 10)-2, label: parseInt(this.state.startTime.substring(6), 10)-2 },
								{ value: parseInt(this.state.startTime.substring(6), 10)-3, label: parseInt(this.state.startTime.substring(6), 10)-3 },
							]}
							placeholder={ <span className={"select-placeholder"}>Seleziona Anno</span> }
						/>


{/*
						<div className={"modal-form-input-group"}>
							<AvInput
								type={ "number" }
								name={ "year" }
								id={ "year" }
								value={ this.state.year }
								className={""}
								onChange={ changeTextEvent => {

									this.setState(
										{
											...this.state,
											year: parseInt(changeTextEvent.target.value, 10)
										})
								}}
								required
							/>
						</div> */}
					</FormGroup>


					{
						!!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.PROJECT_MANAGER )
							? (
								<FormGroup>
									{/*     AZIENDA  -------------------------------------------------------------------------------- */}
									<Label for="title">Azienda { (!this.state.companies) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
									<Select
										className="react-select"
										classNamePrefix="react-select"
										name="company"
										id="company"
										onChange={ changeEvent => {
											this.setState({
												...this.state,
												companySelected: changeEvent
											})
										}}
										options={ (!!this.state.companies ? this.state.companies.map( c => ({value: c.id, label: c.name})) : []) }
										value={ this.state.companySelected }
										placeholder={ <span className={"select-placeholder"}>Seleziona Azienda</span> }
									/>
								</FormGroup>
							)
							: null
					}


					{
						!!isUserRole( role.PROJECT_MANAGER )
							? null
							: (
								<AvGroup className={"modal-form-label-input-inline"}>
									<CustomInput
										type="switch"
										id={"isPmAuthorized"}
										name={"isPmAuthorized"}
										label={"Autorizza ARB SBRL a socio unico"}
										className={"pmAuthorizationToggler"}
										onClick={clickEvent => {




											if( !!document.getElementById( "isPmAuthorized" ) ) {
												document.getElementById("isPmAuthorized").checked = !this.state.isPMAuthorized;
											}

											this.setState({
												...this.state,
												isPMAuthorized: !this.state.isPMAuthorized
											});

										}}

									/>
								</AvGroup>
							)
					}


					{/*
						!!hasPermission( permissions.ACTIVATE_INTERNAL_ASSURANCE )
							? (
								<AvGroup className={"modal-form-label-input-inline"}>
									<CustomInput
										type="switch"
										id={"isInternalAssurance"}
										name={"isInternalAssurance"}
										label={"Assurance interno"}
										className={"pmAuthorizationToggler"}
										onClick={clickEvent => {




											if( !!document.getElementById( "isInternalAssurance" ) ) {
												document.getElementById("isInternalAssurance").checked = !this.state.isInternalAssurance;
											}

											this.setState({
												...this.state,
												isInternalAssurance: !this.state.isInternalAssurance
											});

										}}

									/>
								</AvGroup>
							)
							: null

					<AvGroup className={"modal-form-label-input-inline"}>
						<Select
							className="react-select gri-type-select"
							classNamePrefix="react-select"
							name="gri-type-select"
							id="gri-type-select"
							onChange={ changeEvent => {
								this.setState({
									...this.state,
									griType: changeEvent.value
								})
							}}
							options={[
								{ value: 0, label: "GRI 2019" },
								{ value: 1, label: "GRI 2021" }
							]}
							placeholder={ <span className={"select-placeholder"}>Scegli Versione</span> }
						/>
					</AvGroup>

					*/}


				</AvForm>


			</React.Fragment>
		);


		let waitingMessage = (


			( !!this.state.selectedCollect )
				? (
					<React.Fragment>
						<span>Potrebbe volerci qualche minuto</span><br/>
						<span>Ti verrà inviata una email al termine del processo</span>
					</React.Fragment>
				)
				: (
					<React.Fragment>
						<span>Potrebbe volerci qualche minuto</span><br/>
						<span>al termine del processo verrai reindirizzato alla sezione di configurazione della raccolta</span>
					</React.Fragment>
				)
		);


		let saveButtonEnabled = false;
		if( !!isUserRole( role.PROJECT_MANAGER ) ) {

			if( !!this.state.selectedCollect ) {
				if(
					!!this.state.startTime
					&& this.state.year
					&& this.state.newCollectName
					&& this.state.companySelected
				) {
					saveButtonEnabled = true;
				}
			}
			else {
				if(
					!!this.state.startTime
					&& this.state.year
					&& this.state.newCollectName
					&& this.state.companySelected
					&& this.state.griType !== -1
				) {
					saveButtonEnabled = true;
				}
			}


		}
		else {

			if(
				!!this.state.startTime
				&& this.state.year
				&& this.state.newCollectName
				&& this.state.griType !== -1
			) {
				saveButtonEnabled = true;
			}
		}

		if( !!this.state.error ) saveButtonEnabled = false;

		if(!!this.state.openConfirmContext){
			context =
			<React.Fragment>
				<div>
					Confermi di voler creare una nuova raccolta {
						(!!this.state.selectedCollect)
							? "da una precedente "
							: null
					}?
			    </div>
				<br/>
					<div className="confirm-new-collect-buttons-by-one">
						<ActionButton
							buttonText={ "Conferma" }
							className="confirm-new-collect-button-by-one"
							promise={ () => {

								return (
									new Promise((success, fail) => {


										if( !this.state.waiting ) {

											Promise.race([

												// timer ------------------------------------------------------------------
												new Promise((timerOff, _) => {
													setTimeout(
														() => timerOff(),
														7000
													)
												}),

												// saving ------------------------------------------------------------------
												saveNewCollect(
													this.state.startTime,
													this.state.year,
													this.state.newCollectName,
													this.state.selectedCollect || null,
													(!!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.PROJECT_MANAGER ))
														? this.state.companySelected.value
														: 0
													,
													this.state.isPMAuthorized,
													!!this.state.valuesIncluded,
													!!this.state.isInternalAssurance,
													!!this.state.griType
												)

											])

											.then( result => {

												// debugger;
												if( !!result ) {
													// creazione avvenuta
													showlog("creazione avvenuta");
													if( !this.state.selectedCollect ) {
														showlog("creazione avvenuta - NUOVA");
														success( true );
													}
													else {
														// creazione avvenuta da una copia
														showlog("creazione avvenuta - COPIA");
													}
												}
												else {
													// timer scaduto
													showlog("timer scaduto");
												}
												success();
											})
											.catch(e => {
												errorlog("Salvataggio nuova raccolta", e);
												// debugger;
												fail( e );
											});

										}

										else {

											success( true );
										}
									})

								);
							}}
							onResult={ result => {

								this.setState(
									{
										...this.state,
										waiting: true
									},
									() => {
										showlog(`fra 5sec dovrebbe avviarsi il toggle( ${ result } )`);
										setTimeout(
											function() {
												this.toggle(result, true);
											}.bind( this ),
											5000
										)
									})
								;

							}}
							onError={ error => {

								// debugger;
								showlog("%cExceptionStep #02", "background:#d1f; color:#fff; padding:3px; border-radius:3px; font-weight:bold;");

								errorlog( "E", error );
								let errorMessage = extractErrorMessage( error );

								this.setState(
									{
										...this.state,
										waiting: false,
										error: errorMessage,
										errorMessage: errorMessage
									},
									() => {

										showlog("%cExceptionStep #03", "background:#d1f; color:#fff; padding:3px; border-radius:3px; font-weight:bold;");

										setTimeout(
											function() {
												showlog("%cExceptionStep #04", "background:#d1f; color:#fff; padding:3px; border-radius:3px; font-weight:bold;");
												this.toggle( null, null, errorMessage );
											}.bind( this ),
											5000
										)
									}
								)

							}}
						/>

						<ActionButton
							buttonText={ "Torna indietro" }
							promise={ () => Promise.resolve() }
							onResult={ () => {

								this.setState({
									...this.state,
									openConfirmContext: false,
									valuesIncluded: false
								})


							} }
						/>
					</div>

			</React.Fragment>
		}

		let content = (
			<Modal isOpen={ true } >
				<ModalHeader className={this.state.headerClassName}>
					Nuova Raccolta
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{
						(!!this.state.errorMessage)
							? (
								<span className={ COLOR_POLICY.error.textClass }>{ this.state.errorMessage }</span>
							)
							: (
								(!this.state.waiting)
									? context
									: waitingMessage
							)
					}
				</ModalBody>

				{
					(!this.state.openConfirmContext )
						? (
							<ModalFooter className={this.state.bodyClass}>
								{
									(!!this.state.waiting || !!this.state.createError)
										? null
										: (
											<div className={"new-collect-comander-container"}>



												<span>Copia configurazione da</span>

												<Select
													className="react-select"
													classNamePrefix="react-select"
													name={"new-collect-comander-container-select"}
													id={"new-collect-comander-container-select"}

													onChange={changeEvent => {

														this.setState({
															...this.state,
															selectedCollect:
																this.state.allCollect
																	.filter( col => col.id === changeEvent.value )
																	.reduce( getLastElementOr, null )
														})


													}}

													value={
														this.state.allCollect
															.filter( col => !!this.state.selectedCollect && col.id === this.state.selectedCollect.id )
															.map( col => ({value: col.id, label: col.name}))
															.reduce( getLastElementOr, null )
													}
													options={
														[
															{value: 0, label: "Nessuna"},
															...this.state.allCollect
																.map( col => ({value: col.id, label: col.name}))
														]
													}
													placeholder={<span className={"select-placeholder"}>Nessuna Raccolta</span>}
												/>
												{
													!!this.state.inWait
														? (
															<Spinner color={"success"} className={"new-collect-comander-container-waiting-wheel"}/>
														)
														: (
															<div className={"new-collect-comander-container-waiting-wheel"}/>
														)
												}

												{/*	valuesIncluded	*/}
												{
													!!this.state.selectedCollect
														? (
															<CustomInput
																type="switch"
																id={"valuesIncluded-switch"}
																name={"valuesIncluded"}
																label={"copia valori"}
																className={"pmAuthorizationToggler"}
																onClick={clickEvent => {
																	if( !!document.getElementById( "valuesIncluded-switch" ) ) {
																		document.getElementById("valuesIncluded-switch").checked = !this.state.valuesIncluded;
																	}

																	this.setState({
																		...this.state,
																		valuesIncluded: !this.state.valuesIncluded
																	});

																}}

															/>
														)
														: null
												}

											</div>
										)
								}

								{
									( !!this.state.waiting )
										? null
										: (
											<Button
												color="link"
												onClick={ clickEvent => {
													if( !this.state.waiting ) this.toggle();
												}}
											>
												{ translate("Close") }
											</Button>
										)
								}


								{
									!!this.state.createError
										? null
										: (
											<ActionButton
												visible={ saveButtonEnabled }
												promise={ () => {
													return (
														new Promise((success, fail) => {

															if( !this.state.waiting ) {

																/*
																if(!!this.state.valuesIncluded){

																	this.setState({
																		...this.state,
																		openConfirmContext: true

																	})
																}
																else {

																	// TODO

																}
																*/

																this.setState({
																	...this.state,
																	openConfirmContext: true,
																	waiting: false
																},
																	() => {
																		success( true );
																	}
																)


															}
															else {
																// success( true );
															}
														})
													);
												}}
												onResult={ result => {

													/*
													this.setState(
														{
															...this.state,
															waiting: false
														},
														() => {
															this.toggle( result );
														}
													);
													*/

												}}
												onError={ error => {

													errorlog( "E", error );
													let errorMessage = extractErrorMessage( error );



													this.setState(
														{
															...this.state,
															waiting: false,
															error: errorMessage,
															errorMessage: errorMessage
														},
														() => {
															this.toggle( null, null, errorMessage );
														}
													)

												}}
											/>
										)
								}


					</ModalFooter>
				)
				: null
			}


			</Modal>


		);

		return content;
	}

	componentDidMount() {


		Promise.all([
			new Promise((success, fail) => {
				if( !!this.state.allCollect ) {
					if( !!hasPermission( permissions.GET_COLLECT ) ) {
						loadCollect()

							// finish   -----------------------------------------------------------------------------------------------
							.then( responseData => {

								success( responseData );

							})
							.catch( e => {
								errorlog("Load collects", e);

								fail( e );

							});
					}
					else {
						// permesso negato
						errorlog("Load collects permission DENIED!", "");
						fail("Permission Denied");
					}
				}
				else {
					success( this.state.allCollect )
				}
			}),
			getBaseCompaniesList()
				.then( list => {

					return list;
				})
				.catch(e => {
					errorlog("new collect modal > getAllCompanies", e);
					return Promise.reject( e );
				})


		])




			.then( resultList => {

				return ({
					collects: resultList[0],
					companies: resultList[1]
				})

			})



			.then( ({collects, companies}) => {

				this.setState({
					...this.state,
					allCollect: collects,
					companies: companies,
					inWait: false
				});

			})
			.catch( e => {
				this.setState({
					...this.state,
					inWait: false
				});
			})




	}



}

