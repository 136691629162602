import React, {Component} from 'react';
import {Card, CardBody, Col, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {permissions, role} from "../../../env";
import {
    loadAllComapnyAllowedDimensions,
    loadCompaniesData,
    loadCompanyAddress,
    loadCompanyContacts,
    updateCompany
} from "../../../business-logic/companies";
import TableDataModal from "../../../components/area/table-modal";
import {cloneObject, errorlog, getLastElementOr, onlyNotNull, renderlog, showlog} from "../../../utils";
import SectorListPopup from "../../../components/area/super-admin/sector-list-modal";
import Select from 'react-select';
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import ActionButton from "../../../components/save-button";
import CompanyModal from "../../../components/area/super-admin/company-edit-modal";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {translate} from '../../../components/i18n/translate-function';
import CompanyDeleteModal from "./company-delete-modal";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import SectorHistoryModal from "../../../components/area/super-admin/sector-history-modal";


const fieldMap = {
	partitaIva: translate("Vat Code"),
	ragioneSociale: translate("Company Name"),
	localita: "Città",
	provincia: "Provincia",
	indirizzo: "Indirizzo",
	// tipologiaCommerciale: "Tipologia Commerciale",
	// classeContabile: "Classe Contabile",
	// categoria: "Categoria",
	// contatti: "Contatti",
	//indirizzi: "Indirizzi",
	sector: translate("Sector"),
	leadingCompany: "Organizzazione",
	dimensionSelect: translate("Dimension"),
	" ": " "
};


const hiddenFields = [
	"Città",
	"Provincia",
	"Indirizzo",
	"dimensionId",
	"dimensionName",
	"undefined",
	"localita",
	"provincia",
	"indirizzo",
	"tipologiaCommerciale",
	"classeContabile",
	"categoria",
	"contatti",
	"Indirizzi",
	isUserRole( role.PROJECT_MANAGER ) ? null : "leadingCompany",
	!!hasPermission( permissions.EDIT_COMPANY ) ? null : " "
]
.filter( onlyNotNull );

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Companies")}
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];


const sortableFields = [
	translate("Company Name"),
	"ragioneSociale",
	"partitaIva",
	"Città",
	"Provincia",
	translate("Sector"),
	translate("Dimension"),
	"Tipologia Commerciale",
	"Classe Contabile",
	"Categoria",
	"leadingCompany"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SuperAdminCompanies extends Component {

	state = {
		data: null
	}

	constructor(props) {
		super( props );
		this.loadData = this.loadData.bind( this );
	}


	render() {

		renderlog("SuperAdminCompanies Page", this.state, this.props);


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						/* {
							label:
							Object.keys( role )
								.map( roleObjectName => role[ roleObjectName ] )
								.filter( roleObject => roleObject.name === localStorage.getItem("role") )
								.reduce( getLastElementOr, "Nessun Ruolo" )
								.label
							, path: PATH_PREFIX +'/welcome' }, */
						{ label: translate("Companies") , active: true }
					]}
					title={translate("Companies")}
				/>






				{
					!!this.state.sectorListModal
						? (
							<SectorListPopup
								companyId={ this.state.sectorListModal }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										sectorListModal: null
									},()=>{if ( closeEvent === "refresh" ) {
										this.loadData();
									}})
								}}
								onChoose={ list => {
									showlog("lista dei settori scelti");
									showlog(list);

									this.setState({
										...this.state,
										sectorListModal: null
									})

								}}
							/>
						)
						: null
				}

				{

					(!!this.state.sectorHistory)
						? (
							<SectorHistoryModal
								companyId={ this.state.sectorHistory }
								companyName = {this.state.sectorHistoryCompanyName}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										sectorHistory: null
									})
								}}
							/>
						)
						: null

				}

				{
					!!this.state.companyModal
						? (
							<CompanyModal
								company={ this.state.companyInEdit }
								onClose={ isToRefresh => {
									this.setState({
										...this.state,
										companyModal: null,
										companyInEdit: null
									},
										() => {
											if ( !!isToRefresh ) {
												this.loadData();
											}
										}
									)
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.companyDeleteModal)
						?
						(
							<CompanyDeleteModal
								 companyId={ this.state.companyDeleting.id }
								 companyRagioneSociale={ this.state.companyDeleting.ragioneSociale }
								onClose={ closeEvent => {
									this.setState({
											...this.state,
											companyDeleteModal: null
										},
										() => {
											if ( closeEvent === "refresh" ) {
												this.loadData();
											}else if (!!closeEvent){
												this.setState({
													...this.state,
													errorModal: true,
													errorText: closeEvent
												})
											}
										}
									)
								}}
							/>
						)
						:
						null
				}

				{
					(!!this.state.errorModal)
						?
						(
							<ErrorMessageModalComponent
								message={this.state.errorText}
								onClose={closeEvent =>{
									this.setState({
										...this.state,
										errorModal: false
									})
								}}
							/>

						)
						:
						null
				}



				<Card>
					<CardBody>

						<Row>
							<Col>
								<div className="page-title-box">
									<div className="page-title-right">
										{
											!!hasPermission( permissions.CREATE_COMPANY )
												? (
													<ActionButton
														buttonText={
															<React.Fragment>
																<i className={"uil-building"}/>
																<span>
																	&nbsp;&nbsp;&nbsp;&nbsp;{translate("New Company")}
																</span>
															</React.Fragment>
														}
														promise={ () => Promise.resolve() }
														onResult={ () => {
															this.setState({
																...this.state,
																companyModal: true
															});
														}}
													/>
												)
												: null
										}
									</div>
									<h4 className="page-title">{translate("Companies List")}</h4>
								</div>
							</Col>
						</Row>

						{
							(!!this.state.data && this.state.data.length > 0)
								? (
									<ToolkitProvider
										bootstrap4
										keyField="Id"
										search
										exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
										{props => (
											<React.Fragment>
												<Row>
													<Col>
														<SearchBar {...props.searchProps} />
													</Col>
												</Row>


												<BootstrapTable
													bootstrap4
													striped
													{...props.baseProps}
													size="sm"
													responsive
													keyField="Id"
													data={ this.state.data }
													columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
													defaultSorted={ defaultSorted }
													pagination={ paginationFactory(paginationOptions) }
													wrapperClasses="table-responsive"
												/>


											</React.Fragment>
										)}
									</ToolkitProvider>
								)
								: (
									<WaitingOrNodataMessage
										waiting={ !(!!this.state.data && this.state.data.length === 0) }
										waitMessage={"Caricamento..."}
										nodataMessage={"Nessuna Azienda"}
										className={""}
										fullHeight={ true }
										spinnerColor={"primary"}
									/>
								)
						}




					</CardBody>
				</Card>

				{
					(!!this.state.contactModal)
						? (
							<TableDataModal
								data={ this.state.contactList }
								fieldMap={ this.state.contactListFieldMap }
								sortableFields={[
									"Nome",
									"Cognome",
									"Email",
									"Ruolo",
									"Ufficio",
									"cognome",
									"indirEmail",
									"nome",
									"ruoloAziendali",
									"ufficioContatti"
								]}
								close={() => {
									this.setState({contactModal: null})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.addressModal)
						? (
							<TableDataModal
								data={ this.state.addressList }
								fieldMap={ this.state.addressListFieldMap }
								sortableFields={[
									"Indirizzo",
									"Citta",
									"Provincia",
									"Categoria",
									"Classe Contabile",
									"Tipologia Commerciale",
									"localita",
									// "provincia",
									"indirizzo",
									"provincia_descrizione"
								]}
								close={() => {
									this.setState({addressModal: null})
								}}
							/>
						)
						: null
				}

			</React.Fragment>
		);
	}

	componentDidMount() {


				this.loadData();

	}






	loadData() {
		loadCompaniesData()


			// adjust ---------------------------------------------------------------------------------------------------
			.then( list => {
				return (
					list
						.map( companyRow => {
							companyRow.dimensionId = companyRow.dimension.id;
							companyRow.dimensionName = companyRow.dimension.name;
							return companyRow;
						})
				);
			})


			// extras   -----------------------------------------------------------------------------------------------
			.then(data => {

				return (
					loadAllComapnyAllowedDimensions()
						.then( dimensions => {
							return {data: data, dimensions: dimensions};
						})
						.catch(e => Promise.reject( e ))
				);

			})


			// extras   -----------------------------------------------------------------------------------------------
			.then(({data, dimensions}) => {

				let dataCopy = cloneObject( data );
				// let dataCopy = safeCloneReactObject( data );
				showlog("dataCopy")
				showlog(dataCopy);
				// debugger;

				return (
					data
						.map( companyRow => {

							companyRow.contatti = (
								<i
									className="mdi mdi-contact-mail comander-icon"
									onClick={ clickEvent => {
										// console.log(companyRow);
										// console.log("contatti ", companyRow.id);
										this.setState(
											{...this.state, contactModal: companyRow, contactList: []},
											() => {
												// chiamata per contact list
												loadCompanyContacts( companyRow.id )
													.then( contactList => {

														this.setState({
															...this.state,
															contactList: contactList,
															contactListFieldMap: {
																nome: "Nome",
																cognome: "Cognome",
																indirEmail: "Email",
																numCellulare: "Cellulare",
																numCellulare2: "Cellulare 2",
																numTel: "Telefono",
																numTel2: "Telefono 2",
																ruoloAziendali: "Ruolo",
																ufficioContatti: "Ufficio",
															}
														})

													})
													.catch(e => {
														errorlog("errore", e);
													})
											}
										);
									}}
								/>
							);


							/* icone
							 uil-map-marker
							 uil-map
							 uil-map-pin
							 dripicons-location
							 dripicons-map
							 mdi mdi-map-marker
							 mdi mdi-map
							 mdi mdi-map-outline
							 */
							companyRow.indirizzi = (
								<i
									className="mdi mdi-map-outline comander-icon"
									onClick={ clickEvent => {
										// console.log("indirizzi ", companyRow.id);
										this.setState(
											{...this.state, addressModal: companyRow, addressList: []},
											() => {
												// chiamata per contact list
												loadCompanyAddress( companyRow.id )
													.then( addressList => {
														// console.log("addressList");
														// console.log(addressList);

														this.setState({
															...this.state,
															addressList:
																addressList
																	.map( adr => {
																		delete adr.ragioneSociale;
																		delete adr.partitaIva;
																		return adr;
																	}),
															addressListFieldMap: {
																// ragioneSociale: "Ragione Sociale",
																// partitaIva: "Partita IVA",
																indirizzo: "Indirizzo",
																localita: "Citta",
																provincia_descrizione: "Provincia",
																provincia: " Sigla",
																categoria: "Categoria",
																classeContabile: "Classe Contabile",
																tipologiaCommerciale: "Tipologia Commerciale",
															}
														})


													})
													.catch(e => {
														errorlog("errore", e);
													})
											}
										);
									}}
								/>
							);

							companyRow.sector = (

								<React.Fragment>
								<i
										style={{marginLeft: "20px"}}
									className="mdi mdi-leaf" // earth
									onClick={ clickEvent => {
										this.setState({
												...this.state,
												sectorListModal: companyRow.id
											});
									}}
								/>

									{
										(isUserRole(role.PROJECT_MANAGER))?
											(
												<i
													className="mdi mdi-history"
													style={{marginLeft: "20px",	fontSize: "1.2rem", color: "var(--primary)", marginRight: "-30px"}}
													onClick={clickEvent => {
														this.setState({
															...this.state,
															sectorHistory: companyRow.id,
															sectorHistoryCompanyName: companyRow.ragioneSociale
														})
													}}
												/>
											)
											:
											null
									}

								</React.Fragment>
							);

							companyRow.dimensionSelect = (
								<Select
									className="react-select" // dimension-width-select"
									name="dimension"
									id={"dimension-select-"+ companyRow.id}
									onChange={ changeEvent => {
										showlog("cambio dimensione");

										// updateCompany( companyRow.id, { TipologiaCommerciale: companyRow.tipologiaCommerciale, Dimensioni: changeEvent.value })
										updateCompany( companyRow.id, { TipologiaCommerciale: "x", Dimensioni: changeEvent.value })     // fake
											.then( result => {
												// soltanto per fare il refresh della pagina
												this.loadData();
											})
											.catch(e => {
												errorlog("errore", e);
											})

									}}
									options={
										dimensions
											.map( d => ({value: d.id, label: d.name}))
									}
									value={
										dimensions
											.filter( d => d.name === companyRow.dimension.name )
											.map( d => ({value: d.id, label: d.name}))
											.reduce( getLastElementOr, null)
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona Dimensione</span> }
								/>
							);



							// edit company
							if( !!hasPermission( permissions.EDIT_COMPANY )  && !!hasPermission(permissions.DELETE_COMPANY)) {
								companyRow[" "] = (
									<React.Fragment>
									<i
										className="mdi mdi-delete danger-trash"
										style={ (!!isUserRole(role.SUPER_ADMIN)?{marginLeft: "50px",
											marginRight: "-75px"}: {marginLeft: "40px",
											marginRight: "-50px"} )

										}
										onClick={ clickEvent => {
											this.setState({
												...this.state,
												companyDeleting: dataCopy.filter( d => d.id === companyRow.id ).reduce( getLastElementOr, null ),
												companyDeleteModal: true
												// deletingRagioneSociale: companyRow.ragioneSociale,
												// deletingId: companyRow.id
											})
										}}
									/>

										<i
											className="mdi mdi-pencil"
											style={ (!!isUserRole(role.SUPER_ADMIN)?{marginLeft: "25px",
												marginRight: "-15px"}: {marginLeft: "7px",
												marginRight: "0px"})

											}
											onClick={ clickEvent => {
												this.setState({
													...this.state,
													companyModal: true,
													companyInEdit: dataCopy.filter( d => d.id === companyRow.id ).reduce( getLastElementOr, null )
												});
											}}
										/>

									</React.Fragment>
								);
							}else if (!!hasPermission(permissions.EDIT_COMPANY)){
								companyRow[" "] = (
										<i
											className="mdi mdi-pencil"
											style={ (!!isUserRole(role.SUPER_ADMIN))?({
												marginLeft: "27px",
												marginRight: "-28px"
											}) : null }
											onClick={ clickEvent => {
												this.setState({
													...this.state,
													companyModal: true,
													companyInEdit: dataCopy.filter( d => d.id === companyRow.id ).reduce( getLastElementOr, null )
												});
											}}
										/>
								)

										}


							return companyRow;
						})
				);
			})

			// field sort ---------------------------------------------------------------------------------------------
			.then( data => {

				showlog("data after extras");
				showlog( data );

				return (
					data
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							// Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})

			/*
			// field map ----------------------------------------------------------------------------------------------
			.then( list => {

				console.log("data after field map");
				console.log( list );

				return (
					list
						.map( company => {
							let dto = {};
							Object.keys( company )
								.forEach( rawField => {
									dto[ fieldMap[ rawField ] ] = company[ rawField ];
								})
							;
							return dto;
						})
				);

			})
			*/


			// finish -------------------------------------------------------------------------------------------------
			.then( data => {
				// console.log("data after field sort");
				// console.log( data );


				this.setState({
					...this.state,
					data: data
				})
			})


			.catch(e => {
				errorlog("load data di company list", e);
			})

	}






}
