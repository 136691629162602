import {Component} from "react";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteMaterialTheme, removeRepeatingZone} from "../../../business-logic/gri";
import Spinner from "../../Spinner";


export default class TreeDataEntryZoneDeleteModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.onClose();
	}

	render() {

		let context = (
			"Sicuro di voler eliminare la ripetizione ?"
		);

		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={ "modal-colored-header bg-danger" }>
					ATTENZIONE
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {
							console.log("click su conferma");

							this.setState({
								...this.state,
								inWait: true
							})

							removeRepeatingZone( this.props.id )
								.then(() => {
									this.props.onClose();
								})
								.catch(e => {
									console.error("errore all'eliminazione di una ripetizione");
									console.log( e );
									this.props.onClose();
								})

						}}
					>
						{
							(!!this.state.inWait)
								? (
									<React.Fragment>
										<Spinner color={"primary"} className="spinner-border-sm mr-1" />
										<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
									</React.Fragment>
								)
								: "OK"
						}
					</Button>{' '}
					<Button color="link" onClick={this.toggle}>
						annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;
	}

	componentDidMount() {}
}
