import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    CustomInput,
    Label,
    Modal,
    ModalBody,
    ModalHeader, PopoverBody, PopoverHeader,
    Row,
    UncontrolledPopover
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {COLOR_POLICY, DATE_FORMAT_IT, HOST, PATH_PREFIX, permissions, RATING_STATUS, role} from "../../../env";
import {getRatingAttachments, loadEvaluations} from "../../../business-logic/active-service";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import moment from "moment";
import {RATING_TYPE} from "../../../conf/siscoring";
import {translate} from "../../../components/i18n/translate-function";
import {loadSectorQuestion, setIsOnlySectorMain} from "../../../business-logic/rating";
import {getLastElementOr} from "../../../utils";

const fieldMap = {
    code: "Codice",
    name: "Domanda",
    macroarea_name: "Macroarea",
    // thematic_name: "Tematica",
    processedTheme: "Tematica",
    // subthematic_name: "Sottotematica",
    // score: "Punteggio Massimo",
    sector_name: "Settore",
    alive:"Domanda attiva/non attiva",
    version:"Versione"
};


const hiddenFields = [
    "undefined",
    "id",
    "isMultipleAnswer",
    "macroarea_id",
    "ordering",
    "srSectorId",
    "subthematic_id",
    "thematic_id",
    "name_en",
    "companySize",
    "attachmentsGuidelines",
    "questionGuidelines",
    "score",
    "subthematic_name",
    "thematic_name",
    "choiches",
    "transparency",
    "resilience",
    "improvement",
    "name_it",
   // "alive",
    "companies",
    "isOnlySectorMain",
    "Linee Guida Allegati",
    "Linee Guida Domanda",
    "Dimensione",
    "gendergap"
];


const sortableFields = [
    "code",
    "name",
    "macroarea_name",
    "processedTheme",
    "sector_name",
    "alive",
    "version"
];

const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "Primo",
    prePageText: "Indietro",
    nextPageText: "Avanti",
    lastPageText: "Ultimo",
    nextPageTitle: "Prima pagina",
    prePageTitle: "Pagina precedente",
    firstPageTitle: "Prossima pagina",
    lastPageTitle: "Ultima pagina",
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Domande
        </span>
    ),
    sizePerPageList: [
        {
            text: "pochi",
            value: 25,
        },
        {
            text: "normale",
            value: 50,
        },
        {
            text: "molti",
            value: 250,
        }
    ]
};

const defaultSorted = [
    {
        dataField: "id",
        order: "asc",
    },
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;

export default class EvaluationAssociatedFilter1Modal extends Component {

    state = {
        data:[],
        waitData: true
    }

    constructor(props) {
        super( props );


        this.toggle = this.toggle.bind( this );

    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }



    render() {

        renderlog( "SectorQuestionAssociatedFilter2Modal", this.state, this.props);

        let contentWait = (
            <Modal isOpen={true} toggle={this.toggle} className={"large-modal"}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Domande di settore
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    <WaitingOrNodataMessage
                        waiting={ true }
                        fullHeight={ true }
                    />
                </ModalBody>
            </Modal>
        );

        let contentNoData = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Domande di settore
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {translate("NoData")}
                </ModalBody>
            </Modal>
        );

        let contentError = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Domande di settore
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    Errore ottenimento dati
                </ModalBody>
            </Modal>
        );

        let context= (

            <React.Fragment>

                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    search
                >
                    {props => (
                        <React.Fragment>

                            <div className={""} style={{marginLeft:"10px"}}><SearchBar {...props.searchProps} /></div>

                            {
                                (!!this.state.data && this.state.data.length > 0 )
                                    ? (

                                        <BootstrapTable
                                            bootstrap4
                                            striped
                                            {...props.baseProps}
                                            size="sm"
                                            responsive
                                            keyField="id"
                                            data={ this.state.data }
                                            columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
                                            defaultSorted={ defaultSorted }
                                            pagination={ paginationFactory(paginationOptions) }
                                            wrapperClasses="table-responsive"
                                            selectRow={{
                                                mode:"checkbox",
                                                clickToSelect: true,
                                                hideSelectColumn: true,

                                                onSelect: row => {
                                                    this.props.onClose( "SQ." + row.id );
                                                }

                                            }}
                                        />

                                    )
                                    : null
                            }

                        </React.Fragment>
                    )}

                </ToolkitProvider>

            </React.Fragment>

        )


        let content = (
            <Modal isOpen={true} toggle={this.toggle} className={"large-modal"}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Domande di settore
                </ModalHeader>
                <ModalBody className={this.state.bodyClass} style={{maxHeight: "837px", overflowY: "auto"}}>
                    {context}
                </ModalBody>
            </Modal>
        );

        if (!!this.state.waitData)
        {return contentWait}
        else if( !!this.state.data && this.state.data.length > 0 )
        {return content}
        else if( !!this.state.data && this.state.data.length === 0 )
        {return contentNoData}
        else if( !!this.state.error )
        {return contentError}

    }

    componentDidMount() {
        this.loadData()
    }


    loadData() {

        loadSectorQuestion()

            // logs ----------------------------------------------------------------------------------------------------------------
            .then( list => {
                showlog("list");
                showlog(list);

                return list;
            })

            /*
            // Choiches ------------------------------------------------------------------------------------------------
            .then( ({list, dimensions}) => {
                return (
                    Promise.all(
                        list
                            .map( question => {
                                return (
                                    loadChoiche(question.id)
                                        .then(choiche => {
                                            /*
                                            id: 4
                                            isRequiredAttachment: true
                                            isRequiredTextBox: true
                                            name_en: "blabla"
                                            name_it: "bla"
                                            score: 0
                                            srQuestionId: 29
                                            * /
                                            // showlog("scelta", choiche);
                                            question.choiches = choiche;
                                            return question;
                                        })
                                        .catch(e => {
                                            errorlog("loadChoiche di sector page",e);
                                            return Promise.reject( e );
                                        })
                                    );
                            })
                    )
                    .then( list => {
                        return ({dimensions: dimensions, list: list});
                    })
                );


            })
            */



            // extras ----------------------------------------------------------------------------------------------------------------
            .then( ({list, dimensions}) => {
                showlog("LISTAAAA", list)
                return (
                    list
                        .map( row => {


                            /*
                            è commentata perchè altrimenti non partecipa alla ricerca del testo
                             */
                            // row.name = <span className={""}>{ row.name_it }</span>;
                            row.name = row.name_it;

                            if(row.alive === true){
                                row.alive = "Attiva"
                            }else{
                                row.alive="Non attiva"
                            }

                            return row;
                        })
                );
            })


            // subThemes -----------------------------------------------------------------------------------------------
            .then( sectorQuestions => {
                return (
                    sectorQuestions
                        .map( sq => {
                            sq.processedTheme = (
                                <React.Fragment>
                                    <div>{ sq.thematic_name }</div>
                                    {
                                        (!!sq.subthematic_name)
                                            ? (
                                                <div>
                                                    <i className={"dripicons-skip material-themes-tab-name"}/>
                                                    { sq.subthematic_name }
                                                </div>
                                            )
                                            : null
                                    }
                                </React.Fragment>
                            );
                            return sq;
                        })
                );
            })


            // field sort   -------------------------------------------------------------------------------------------
            .then( responseData => {
                return (
                    responseData
                        .map( row => {

                            let sortedRow = {};
                            Object.keys(fieldMap)
                                .map( field => {
                                    sortedRow[field] = row[field];
                                })
                            ;
                            Object.assign(sortedRow, row);
                            return sortedRow;

                        })
                );
            })

            // evidentiation   -------------------------------------------------------------------------------------------
            // .then( responseData => {
            //     return (
            //         responseData
            //             .filter(row => row.alive === true)
            //     );
            // })

            // finish ----------------------------------------------------------------------------------------------------------------
            .then( sectorQuestions => {

                showlog("controllo ultimo step");
                showlog(sectorQuestions);


                return (
                    this.setState({
                            ...this.state,
                            waitData: false,
                            data: sectorQuestions,
                        },
                        () => {
                            return sectorQuestions;
                        }
                    )
                );

            })

            .catch(e => {
                errorlog("errore",e);
                this.setState({
                    ...this.state,
                    waitData: false,
                    data: [],
                    error: true
                })
            })
    }

}
