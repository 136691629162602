import {AddRowHistoryVersion, GetVersionHistory, ModifyRowHistoryVersion} from "../services/request-manager";


export const getVersionHistory = (toolId) => {
    return GetVersionHistory( toolId );
}

export const addRowHistoryVersion = (obj) => {
    return AddRowHistoryVersion( obj );
}

export const modifyRowHistoryVersion = (obj) => {
    return ModifyRowHistoryVersion( obj );
}
