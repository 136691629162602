import {
    editCurrentCollect,
    eraseInsurer,
    getInsurer,
    saveInsurerList,
    saveSRToolInformativeAudit,
    setAssurance,
    toggleAssuranceRequirement
} from "../services/collect";
import {setNewInsurer} from "./users";
import {COLLECT_STATUS, DATE_FORMAT_IT, role, SERVICE_DATE_FORMAT_IT} from "../env";
import moment from "moment";
import {getLastElementOr, onlyNotNull, showlog} from "../utils";

export const editCollect = data => {

    /*
    id,
    name,
    isPMAuthorized,
    insurerList
     */

    let payload = {
        // id: data.id,
        name: data.name,
        isPMAuthorized: data.isPMAuthorized,
        // insurerList: data.assurancers.map( a => a.id ) || []
        ...data
    };

    delete payload.data;
    delete payload.copyData;
    payload.dateStart = moment( payload.Inizio, DATE_FORMAT_IT ).format( SERVICE_DATE_FORMAT_IT );
    payload.dateEnd = moment( payload.dateEnd, DATE_FORMAT_IT ).format( SERVICE_DATE_FORMAT_IT );
    // delete payload.dateEnd;
    // payload.dateEnd

    return editCurrentCollect( data.id, payload );
}

export const setInsurerList = rawList => {
    // debugger;
    let payload = rawList.assurancers.map( a => a.id ) || [];
    return saveInsurerList( rawList.id, payload );
}

export const loadInsurer = (companyId, collectId) => {
    return (
        getInsurer( companyId, collectId )
            .then( data => {

                let list =
                    data.list
                        .map( a => {
                            a.active = data.active.map( al => al.id ).includes( a.id );
                            return a;
                        })
                ;

                return list;
            })
            .catch( e => {
                return Promise.reject( e );
            })
    );
}



export const saveNewInsurer = (companyId, data) => {

    let payload = {
        company: companyId,
        title: 1,
        firstName: data.newConsulerName,
        lastName: data.newConsulerLastname,
        role: role.ASSURANCER.name,
        email: data.newConsulerEmail,
        leading: 0
    };

    return setNewInsurer( payload );
}

export const deleteInsurer = (companyId, id) => {
    return eraseInsurer( id, companyId );
}


export const setAssuranceRequirement = (cid, flag) => {
    return toggleAssuranceRequirement( cid, !!flag );
}


export const activateAssurance = cid => {
    return setAssurance( cid, COLLECT_STATUS.inAssurance.id );
}

export const finishAssurance = cid => {
    return setAssurance( cid, COLLECT_STATUS.assuranceDone.id );
}



export const setSRToolAudit = (
    collectID,
    informativeID,
    statusID,
    text,
    exp,
    options = {
        itFormatDate: false,
        unix: true,
        datetimeFormat: DATE_FORMAT_IT
    }
) => {

    let body = {
        state: statusID,
        comment: text,
        expirationDate: (
            !!exp
                ? (
                    !!options.itFormatDate
                        ? moment( exp, DATE_FORMAT_IT  ).format(SERVICE_DATE_FORMAT_IT)
                        : (
                            !!options.unix
                                ? moment( exp, "x" ).format(SERVICE_DATE_FORMAT_IT)
                                : (
                                    !!options.datetimeFormat
                                        ? moment( exp, options.datetimeFormat ).format(SERVICE_DATE_FORMAT_IT)
                                        : moment( exp ).format(SERVICE_DATE_FORMAT_IT)
                                )
                        )
                )
                : moment( (new Date().getTime()), "x" ).format(SERVICE_DATE_FORMAT_IT)
        )
    };

    return saveSRToolInformativeAudit( collectID, informativeID, body );
}



export const getNodeById = (tree, id) => {}


// let stopSearch = false;
export const getNodeByPath = (node, path, onFind) => {

    if( path.length === 0 ) {
        return null;
    }

    // showlog( "getNodeByPath", node, path );

    if ( typeof node === "array" || node instanceof Array ) {
        node
            .forEach( child => {
                getNodeByPath(child, path, onFind)
            })
    }
    else {
        if ( typeof node === "object" || node instanceof Object ) {

            if( node.structureId === path[0] ) {
                path = path.filter((p, i) => i > 0 );
            }

            if( path.length === 0 ) {
                showlog("nodo trovato", node);
                if( typeof onFind === "function" ) onFind( node );
                return node;
            }
            else {
                return (
                    Object.keys( node )
                        .map( field => node[ field ] )
                        .filter( nodeField => ( typeof nodeField === "array" || nodeField instanceof Array ) )
                        .map( child => {
                            return getNodeByPath(child, path, onFind);
                        })
                        .filter( onlyNotNull )
                        .reduce( getLastElementOr, null )
                );
            }

        }
        else return null;

    }

}
