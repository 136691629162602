import React, {useState} from "react";
import "./index.css";
import itFlag from '../../../../../components/i18n/pic/png/IT@3x.png';
import enFlag from '../../../../../components/i18n/pic/png/GB@3x.png';
import esFlag from '../../../../../components/i18n/pic/png/ES@3x.png';
import frFlag from '../../../../../components/i18n/pic/png/FR@3x.png';
import deFlag from '../../../../../components/i18n/pic/png/DE@3x.png';
import {LANGUAGES} from "../../../../../conf/languages";
import {getLastElementOr} from "../../../../../utils";
import {setFlagToLanguage} from "../../../../../components/i18n/translate-function";

export const LanguageSelector = props => {

    const [isOpen, open] = useState();

    let currentLang = localStorage.getItem("language") || "IT-it";
    let lang =
        LANGUAGES
            .filter( l => l.code === currentLang )
            .reduce( getLastElementOr, LANGUAGES[0] )
    ;
    let flag = itFlag;
    switch(currentLang){
        case "IT-it": flag = itFlag;       break;
        case "EN-gb": flag = enFlag;       break;
        case "EN-us": flag = enFlag;       break;
        case "DE-de": flag = deFlag;       break;
        case "ES-es": flag = esFlag;       break;
        case "FR-fr": flag = frFlag;       break;
        default:      flag = itFlag;       break;
    }



    return (
        <div
            className={`language-box ${ props.className || "" } ${ !!isOpen ? "bg" : "" }`}
            onClick={ clickEvent => {
                open( !isOpen );
            }}
        >
            <div className={`${ !!isOpen ? "bg" : "" }`}>
                <img src={ flag } />
                <label>{ lang.label }</label>
                <i className={"dripicons-chevron-down"}/>
            </div>
            {
                LANGUAGES
                    // .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse

                    // ATTENZIONE temporaneo
                    .filter( lang => {

                            if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                            if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                            return true;

                    })

                    .filter( l => l.code !== currentLang )

                    .map( l => {
                        setFlagToLanguage( l );
                        return l;
                    })

                    /*
                    .map( l => {
                        l.flag = itFlag;
                        switch(l.code){
                            case "IT-it": l.flag = itFlag;       break;
                            case "EN-gb": l.flag = enFlag;       break;
                            case "EN-us": l.flag = enFlag;       break;
                            case "DE-de": l.flag = deFlag;       break;
                            case "ES-es": l.flag = esFlag;       break;
                            case "FR-fr": l.flag = frFlag;       break;
                            default:      l.flag = itFlag;       break;
                        }
                        return l;
                    })
                    */

                    .map( l => (
                        <div
                            className={`language-dropDown ${ !!isOpen ? "open" : "close" }`}
                            onClick={ clickEvent => {
                                localStorage.setItem("language", l.code);
                                if(!!props.onChange && (typeof props.onChange === "function")) props.onChange( l );
                                open( !isOpen );
                            }}
                        >
                            <img src={ l.flag } />
                            <label>{ l.label }</label>
                        </div>
                    ))
            }
        </div>
    );

};
