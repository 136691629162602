import React, {Suspense} from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	Input,
	Nav,
	NavItem,
	NavLink,
	PopoverBody,
	PopoverHeader,
	Row,
	TabContent,
	TabPane,
	UncontrolledPopover
} from "reactstrap";
import {withRouter} from 'react-router-dom';
import Spinner from "../../Spinner";
import classnames from 'classnames';
import {permissions} from "../../../env";
import {
	editMaterialThemesTabName,
	loadAllMTInformatives,
	loadMaterialThemes,
	moveInfoFromMaterialThemesToNotCategorized,
	moveInfoToMaterialThemes
} from "../../../business-logic/gri";
import TreeMaterialThemesDeleteModal from "./tree-material-themes-delete-modal";
import Select from 'react-select';
import TreeMaterialThemesNewModal from "./tree-material-themes-new-modal";
import {hasPermission} from "../../../business-logic/auth";
import {errorlog, getLastElementOr, renderlog, showlog} from "../../../utils";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";


const inWait = () => (
	<div className="d-flex justify-content-center">
		<Spinner color={"secondary"} />
	</div>
);

class TreeGriCollectMaterialThemesComponent extends React.Component {


	state = {
		tabs: [],
		collectId: (
			(!!this.props && !!this.props.collect && !!this.props.collect.id)
				? this.props.collect.id
				: (
					(!!this.props.location && !!this.props.location.state && !!this.props.location.state.id)
						? this.props.location.state.id
						: (
							this.props.match.params.id
						)
				)
		),
		wait: true
	}


	constructor( props ) {
		super( props );
		this.loadData = this.loadData.bind(this);
		this.getInformativeList = this.getInformativeList.bind(this);
	}





	getInformativeList( tab ) {


		return (
			this.state.data


				.filter( info => {


					let allCategorizedInfos = [];
					this.state.mt
						.map( i => i.informative )
						.map( i => {
							allCategorizedInfos = [ ...allCategorizedInfos, ...i ]
						})
					;

					if( tab.id === "ALL" ) return true;
					if( tab.id === "NC" ) {
						return ( !allCategorizedInfos.map( i => i.informativeId ).includes( info.informative.id ) );
					}
					else {

						return (
							this.state.mt
								.filter( i => i.id === tab.id )
								.filter( i => (
									i.informative
										.filter( ii => ii.informativeId === info.informative.id )
										.length > 0
								))
								.length > 0
						);

					}


				})

				.map( info => {

					let allCategorizedInfos = [];
					this.state.mt
						.map( i => i.informative )
						.map( i => {
							allCategorizedInfos = [ ...allCategorizedInfos, ...i ]
						})
					;
					let currentTM =
						this.state.mt
							.filter( t => t.id === tab.id )
							.reduce( getLastElementOr, null )
					;
					currentTM =
						this.state.mt
							.filter( t => t.id !== tab.id )
							.filter( t => (
								t.informative
									.filter( i => (i.informativeId === info.informative.id) )
									.reduce( getLastElementOr, null )
							))
							.reduce( getLastElementOr, null )
					;
					if( !!currentTM ) {
						Object.assign( currentTM, { label: currentTM.name, value: currentTM.id } );
					}
					let referiment =
						(
							!!currentTM
								? (
									currentTM.informative
										.filter( i => (i.informativeId === info.informative.id) )
										.reduce( getLastElementOr, { griMaterialThematicInformatives_id: 0 } )
										.griMaterialThematicInformatives_id
								)
								: 0
						)
					;


					// per i tab delle TM
					if( tab.id !== "ALL" && tab.id !== "NC" ) {
						// showlog("tab di una TM");
						currentTM =
							this.state.mt
								.filter( t => t.id === tab.id )
								.filter( t => (
									t.informative
										.filter( i => (i.informativeId === info.informative.id) )
										.reduce( getLastElementOr, null )
								))
								.reduce( getLastElementOr, null )
						;
						referiment =
							(
								!!currentTM
									? (
										currentTM.informative
											.filter( i => (i.informativeId === info.informative.id) )
											.reduce( getLastElementOr, { griMaterialThematicInformatives_id: 0 } )
											.griMaterialThematicInformatives_id
									)
									: 0
							)
						;
					}
					else {

					}


					return (
						<div>


							<div className={"material-themes-content-tab-row"}>
								<Row>
									<Col sm="12">


										{
											!!(allCategorizedInfos.map( i => i.informativeId ).includes( info.informative.id ))
												? <i className={"mdi mdi-bookmark bookmarked-tm"}/>
												: <span className={"tm-informative-raccomandation-icon-spacer"}>&nbsp;</span>
										}



										{
											!!info.informative.racccomandation
												? (
													<React.Fragment>
														<i
															id={"info-"+ info.informative.id +"-raccomandations"}
															className={"mdi dripicons-question tooltip-icon-cursor tooltip-icon tm-icon-tooltip"}
														/>
														<UncontrolledPopover
															placement="right"
															target={"info-"+ info.informative.id +"-raccomandations"}
															trigger="legacy"
														>
															<PopoverHeader>Raccomandazioni</PopoverHeader>
															<PopoverBody>{ info.informative.racccomandation }</PopoverBody>
														</UncontrolledPopover>
													</React.Fragment>
												)
												: <span className={"tm-informative-raccomandation-icon-spacer"}>&nbsp;</span>
										}


										<span
											className={"material-themes-info-code mt-bg-tab-"+ info.tab.id}
											id={"info-"+ info.informative.code}
										>
											{ info.informative.code }
										</span>
										<span className={"material-themes-info-chapter mt-text-tab-"+ info.tab.id } >{ info.chapter.name }</span>
										{/*<span className={"material-themes-arrow mt-text-tab-"+ info.tab.id }> > </span>*/}
										<span
											className={"material-themes-info-name mt-text-tab-"+ info.tab.id }
											id={"info-"+ info.informative.id}
										>
											{ info.informative.name }
										</span>


										<Select
											className="react-select material-themes-info-select"
											classNamePrefix="react-select"
											name={"materialThemesInfoSelect-"+ info.id}
											id={"materialThemesInfoSelect-"+ info.id}
											onChange={ changeEvent => {

												showlog("Cambio l'informativa nella tematica #1");
												showlog( info );
												showlog( changeEvent );

												this.setState({
													...this.state,
													wait: true
												},
													() => {

														if( !!changeEvent.value ) {


															moveInfoToMaterialThemes( info.informative.id, changeEvent.value, referiment, !currentTM )
																.then( result => {
																	this.loadData()
																		.then( () => {
																			changeEvent = null;
																		})
																		.catch( e => {
																			changeEvent = null;
																			return Promise.reject( e );
																		})
																	;
																})
																.catch(e => {
																	console.error("errore allo spostamento di una informativa");
																	showlog(e);
																	this.loadData()
																		.then( () => {
																			changeEvent = null;
																		})
																		.catch( e => {
																			changeEvent = null;
																			return Promise.reject( e );
																		})
																	;
																})
														}
														else {
															moveInfoFromMaterialThemesToNotCategorized( referiment )
																.then( result => {
																	this.loadData()
																		.then( () => {
																			changeEvent = null;
																		})
																		.catch( e => {
																			changeEvent = null;
																			return Promise.reject( e );
																		})
																	;
																})
																.catch(e => {
																	console.error("errore allo spostamento di una informativa");
																	showlog(e);
																	this.loadData()
																		.then( () => {
																			changeEvent = null;
																		})
																		.catch( e => {
																			changeEvent = null;
																			return Promise.reject( e );
																		})
																	;
																})
															;
														}

													}
												)

											}}
											options={[
												...this.state.mt
													.filter( t => t.id !== tab.id )
													.map( t => ({ value: t.id, label: t.name })),
												{ value: 0, label: "Non Classificata"}
											]}
											value={ currentTM }
											placeholder={ <span className={"select-placeholder"}>Cambia Tematica Materiale</span> }
										/>
										{/*
										<UncontrolledTooltip placement="top" target={"info-"+ info.id}>
											<span className="d-none d-lg-block">{ (!!info.reccomandation && !!info.reccomandation.it) ? info.reccomandation.it : "" }</span>
										</UncontrolledTooltip>
										*/}
									</Col>
								</Row>
							</div>



						</div>
					)
				})
		);


	}


	render() {

		renderlog("Material Theme TREE", this.state, this.props);

		let context = (
			<WaitingOrNodataMessage
				waiting={ !!this.state.wait }
				nodataMessage={"Qualcosa non sta andando..."}
				fullHeight={ true }
				spinnerColor={"success"}
			/>
		);

		if( !!!this.state.wait ) {

			if( !!this.state.data ) {

				context = (
					<Suspense fallback={inWait()}>

						<Nav tabs>
							{
								this.state.mt
									.map((tab, index) => {
										return (
											<NavItem key={index}>
												<NavLink
													id={ tab.griMaterialThematicsId }
													href="#"
													className={classnames({ active: this.state.activeTab === tab.griMaterialThematicsId })}

													onClick={() => {

														this.setState({
															...this.state,
															activeTab: tab.id
														})

													}}
												>

													{
														(!!this.state.editTabName && this.state.editTabName === tab.id)
															? (
																<Input

																	id={"editTab-"+ tab.id}
																	defaultValue={ tab.griMaterialThematicsName }
																	onChange={ textInsertEvent => {
																	}}

																	onBlur={ blurEvent => {

																		let newName = document.getElementById("editTab-"+ tab.id).value;
																		this.setState(
																			{
																				...this.state,
																				editTabName: null,
																				wait: true
																			},
																			() => {
																				editMaterialThemesTabName( tab.id, newName )
																					.then( result => {
																						showlog("edit del tab name effettuato con successo");
																						this.loadData();
																					})
																					.catch(e => {
																						console.error("ERRORE all'edit del tab name");
																						showlog(e);
																					})
																			}
																		)
																	}}

																	onKeyUp={ keyUpEvent => {

																		showlog(keyUpEvent.key);

																		if( keyUpEvent.key === "Enter" ) {

																			let newName = document.getElementById("editTab-"+ tab.id).value;

																			this.setState(
																				{
																					...this.state,
																					editTabName: null,
																					wait: true
																				},
																				() => {
																					editMaterialThemesTabName( tab.id, newName )
																						.then( result => {
																							showlog("edit del tab name effettuato con successo");
																							this.loadData();
																						})
																						.catch(e => {
																							console.error("ERRORE all'edit del tab name");
																							showlog(e);
																						})
																				}
																			)
																		}
																	}}

																/>
															)
															: <span className="material-themes-tab-name">{tab.griMaterialThematicsName}</span>
													}



													{
														(!!this.state.editTabName && this.state.editTabName === tab.id)
															? null
															: (
																(
																	!!hasPermission( permissions.EDIT_MATERIAL_THEME ) &&
																	!!hasPermission( permissions.ADD_MATERIAL_THEME )
																)
																	? (
																		<Button
																			outline
																			color={"link"}
																			className={"material-themes-tab-name-icon-button"}
																			onClick={ clickEvent => {

																				showlog("click on edit tab");
																				clickEvent.stopPropagation();

																				this.setState({
																					...this.state,
																					editTabName: tab.id
																				});
																			}}
																		>
																			<i className="mdi mdi-pencil" />
																		</Button>
																	)
																	: null
															)

													}


													{
														(!!this.state.editTabName && this.state.editTabName === tab.id)
															? null
															: (
																<i
																	className="mdi mdi-close-circle"
																	onClick={ clickEvent => {
																		clickEvent.stopPropagation();


																		showlog("elimino la tematica", tab);

																		this.setState({
																			...this.state,
																			deleteTabPopup: tab.id
																		});
																	}}
																/>
															)
													}



												</NavLink>
											</NavItem>
										);
									})
							}

							<NavItem key={"NC"}>
								<NavLink
									id={ "NC" }
									href="#"
									className={classnames({ active: this.state.activeTab === "NC" })}
									onClick={() => {

										this.setState({
											...this.state,
											activeTab: "NC",
										})

									}}
								>

									<div
										className={"material-themes-tab-name-as-button"}
									>
										<i className="mdi mdi-star-off"/>
										<span className="material-themes-tab-name">Non Classificate</span>
									</div>

								</NavLink>
							</NavItem>


							<NavItem key={"ALL"}>
								<NavLink
									id={ "ALL" }
									href="#"
									className={classnames({ active: this.state.activeTab === "ALL" })}
									onClick={() => {

										this.setState({
											...this.state,
											activeTab: "ALL",
										})

									}}
								>

									<div
										className={"material-themes-tab-name-as-button"}
									>
										<i className="mdi mdi-view-headline"/>
										<span className="material-themes-tab-name">Tutte</span>
									</div>

								</NavLink>
							</NavItem>


							<NavItem key={"nuovo"}>
								<NavLink
									id={ "nuovo" }
									href="#"
									onClick={() => {

										showlog("tab onclick NUOVO");

									}}
									className={"material-themes-tab-name-as-button"}
								>


									<Button
										color={"link"}
										onClick={ clickEvent => {
											showlog("click nuovo utente onClick");
											this.setState({
												...this.state,
												newMaterialTheme: true
											});
										}}
									>
										<i className="mdi mdi-plus-box-multiple"/> Nuova Tematica
									</Button>

								</NavLink>
							</NavItem>

						</Nav>

						<TabContent activeTab={this.state.activeTab}>
							{

								this.state.mt
									.map((tab, index) => {
										return (
											<TabPane
												tabId={tab.id}
												key={index}
												className={"material-themes-content-tab"}
											>
												<Row>
													<Col sm="10">

														{
															(tab.id === this.state.activeTab)
																? this.getInformativeList( tab )
																: null
														}

														{/*<GotoTopComponent/>*/}

													</Col>
												</Row>
											</TabPane>
										);
									})

							}


							<TabPane
								tabId={"NC"}
								key={"NC"}
								className={"material-themes-content-tab"}
							>
								<Row>
									<Col sm="12">

										{
											("NC" === this.state.activeTab)
												? this.getInformativeList({ id: "NC" })
												: null
										}

										{/*<GotoTopComponent/>*/}

									</Col>
								</Row>
							</TabPane>


							<TabPane
								tabId={"ALL"}
								key={"ALL"}
								className={"material-themes-content-tab"}
							>
								<Row>
									<Col sm="12">

										{
											("ALL" === this.state.activeTab)
												? this.getInformativeList({ id: "ALL" })
												: null
										}

										{/*<GotoTopComponent/>*/}

									</Col>
								</Row>
							</TabPane>


						</TabContent>

						{
							(!!this.state.deleteTabPopup)
								? (
									<TreeMaterialThemesDeleteModal
										id={ this.state.deleteTabPopup }
										onClose={ closeEvent => {
											this.setState(
												{
													...this.state,
													deleteTabPopup: null,
													wait: true
												},
												this.loadData
											);
										}}
									/>
								)
								: null
						}


						{
							(!!this.state.newMaterialTheme)
								? (
									<TreeMaterialThemesNewModal
										collect={ this.props.collect.id }
										onClose={ () => {
											this.setState(
												{
													...this.state,
													newMaterialTheme: null
												},
												this.loadData
											);
										}}
									/>
								)
								: null
						}


					</Suspense>

				);


			}

		}


		let content = (
			<div >
				{ context }
			</div>
		);

		return (
			<Row>
				<Col md={12}>

						<Card style={{ marginTop: "20px"}}>
							<CardBody>

								<Row><Col md={12}>&nbsp;&nbsp;</Col></Row>

								{ content }

							</CardBody>
						</Card>

				</Col>
			</Row>
		);


	}

	componentDidMount() {

		showlog("TreeGriCollectMaterialThemesComponent DID MOUNT");

		this.loadData()
			.then( result => {

			})
			.catch( e => {
				errorlog("Caricamento tematiche materiali", e);
			})
		;

	}


	loadData() {

		return (

			// lista di tutte le informative Materiali e Applicabili
			loadAllMTInformatives( this.state.collectId )

				.then( list => {

					return (
						loadMaterialThemes( this.state.collectId )
							.then( mtlist => {
								return ({data: list, mt: mtlist});
							})
							.catch(error => {
								errorlog("loadMaterialThemes", error);
								return Promise.reject(error);
							})
					);

				})

				.then( ({data, mt}) => {

					return (
						new Promise((success, fail) => {

							this.setState({
								...this.state,
								data: data,
								mt: mt,
								wait: false,
								activeTab: "ALL",
								tabs: []
							},
								() => {
									success();
								}
							)

						})
					);


				})


				.catch(error => {
					errorlog("loadAllMTInformatives", error);
					return Promise.reject(error);
				})
		);

	}

}

export default withRouter(TreeGriCollectMaterialThemesComponent);
