import React, {Component} from "react";
import PageTitle from "../../../components/PageTitle";
import {Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import CardBody from "reactstrap/es/CardBody";
import {COLOR_POLICY} from "../../../env";
import CompanyServicePage from "./company-service-page";
import {renderlog} from "../../../utils";
import OrganizzationServicePage from "./organizzation-service-page";
import {translate} from "../../../components/i18n/translate-function";


export default class ProjectManagerActiveService extends Component {

	state = {
		activeTab: "companies"
	}

	constructor(props) {
		super( props );

	}


	render() {

		renderlog("ProjectManagerActiveService", this.state, this.props, COLOR_POLICY.warning);

		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						/*{
							label:
								Object.keys( role )
									.map( roleObjectName => role[ roleObjectName ] )
									.filter( roleObject => roleObject.name === localStorage.getItem("role") )
									.reduce( getLastElementOr, "Nessun Ruolo" )
									.label,
							path: PATH_PREFIX +"/welcome"
						}, */
						{ label: translate("active_services"), active: true }
					]}
					title={translate("Services")}
				/>



					<Card>
						<CardBody>

							<Row>
								<Col>
									<div className="page-title-box">
										<h4 className="page-title">{translate("Services List")}</h4>
									</div>
								</Col>
							</Row>


							<Nav tabs>

								<NavItem key={"companies"}>
									<NavLink
										id={ "companies-nav-tab" }
										href="#"
										className={ (this.state.activeTab === "companies") ? "active-tab" : "" }
										onClick={() => {
											this.setState({activeTab: "companies"});
										}}
									>
										<span className="d-none d-lg-block">{ translate("Companies") }</span>
									</NavLink>
								</NavItem>


								<NavItem key={"organizzations"}>
									<NavLink
										id={ "organizzations-nav-tab" }
										href="#"
										className={ (this.state.activeTab === "organizzations") ? "active-tab" : "" }
										onClick={() => {
											this.setState({activeTab: "organizzations"});
										}}
									>
										<span className="d-none d-lg-block">{ translate("Leading_companies") }</span>
									</NavLink>
								</NavItem>


							</Nav>


							<TabContent activeTab={this.state.activeTab}>

								<TabPane
									tabId={"companies"}
									key={"companies"}
									className={"custom-tab-pane"}
								>
									<CompanyServicePage/>

									{/*<GotoTopComponent/>*/}

								</TabPane>

								<TabPane
									tabId={"organizzations"}
									key={"organizzations"}
									className={"custom-tab-pane"}
								>
									<OrganizzationServicePage/>

									{/*<GotoTopComponent/>*/}

								</TabPane>

							</TabContent>

						</CardBody>
					</Card>




			</React.Fragment>
		);
	}







}
