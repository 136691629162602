import React from "react";
import "./recap-status-icon-svg-component.css";

export const RecapStatusIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a"/>
            <g id="b">
                <g>
                    <path className="recap-status-icon-e"
                          d="M30.15,65.36v33.55c0,5.59,4.32,10.11,9.64,10.11h53.42c5.33,0,9.64-4.53,9.64-10.11V35.58"/>
                    <path className="recap-status-icon-e" d="M79.47,14.1H39.8c-5.33,0-9.64,4.53-9.64,10.11v2.25"/>
                    <polygon className="recap-status-icon-d" points="79.47 35.71 102.86 35.58 79.47 14.1 79.47 35.71"/>
                    <circle className="recap-status-icon-g" cx="44.99" cy="93.4" r="5.18"/>
                    <circle className="recap-status-icon-g" cx="59.47" cy="77.79" r="5.18"/>
                    <circle className="recap-status-icon-g" cx="73.06" cy="93.4" r="5.18"/>
                    <circle className="recap-status-icon-g" cx="90.48" cy="70.54" r="5.18"/>
                    <line className="recap-status-icon-g" x1="48.4" y1="89.5" x2="55.96" y2="81.94"/>
                    <line className="recap-status-icon-g" x1="63.63" y1="81.15" x2="70.18" y2="89.1"/>
                    <line className="recap-status-icon-g" x1="76.24" y1="87.6" x2="87.98" y2="75.07"/>
                    <circle className="recap-status-icon-c" cx="30.3" cy="45.48" r="19.02"/>
                    <polyline className="recap-status-icon-f" points="30.3 26.46 30.3 45.59 49.32 45.59"/>
                    <line className="recap-status-icon-g" x1="57.89" y1="53.51" x2="90.88" y2="53.51"/>
                    <line className="recap-status-icon-g" x1="57.89" y1="45.48" x2="90.88" y2="45.48"/>
                </g>
            </g>
        </svg>
    </div>
);
