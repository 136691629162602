// main
import React, {Component} from "react";
import {withRouter} from "react-router-dom";

// theme
import {Card, CardBody, Col, Row} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";

// constants & utils
import {COLOR_POLICY, permissions} from "../../../env";
import {errorlog, getLastElementOr, isNotEmptyArray, renderlog, safeCloneReactObject, showlog} from "../../../utils";

// Owner components
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {DeleteOrganizzationPrompt} from "../../../components/area/super-admin/delete-organizzation-prompt";
import ActionButton from "../../../components/save-button";

// BL & services
import {hasPermission} from "../../../business-logic/auth";
import {loadOrganizzations} from "../../../business-logic/organizzations";
import {loadAllComapnyAllowedDimensions, loadCompaniesData} from "../../../business-logic/companies";
import SectorListPopup from "../../../components/area/super-admin/sector-list-modal";
import CompanyModal from "../../../components/area/super-admin/company-edit-modal";
import {
    DeleteOrganizzationCompanyPrompt
} from "../../../components/area/super-admin/delete-organizzation-company-prompt";
import OrganizzationDetailsModal from "../../../components/area/super-admin/organizzation-details-modal";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import {translate} from "../../../components/i18n/translate-function";


const hiddenFields = [
    "id",
    "adminId",
    "companies",
    "Città",
    "Provincia",
    "Indirizzo",
    "dimensionId",
    // "dimensionName",
    "dimension",
    "codCliFor",
    "provincia_descrizione",
    "dimensioni",       // TODO
    "undefined",
    // "localita",
    "provincia",
    // "indirizzo",
    "tipologiaCommerciale",
    "classeContabile",
    // "categoria",
    "contatti",
    "Indirizzi",
    "leadingCompany",
    "companiesLoaded"
    // "comanders"
];

const fieldMap = {
    name: "Nome",
    adminName: "Super Admin",
    buttons: " "
};

const subFieldMap = {
    partitaIva: "Partita Iva",
    ragioneSociale: "Ragione Sociale",
    localita: "Città",
    provincia: "Provincia",
    indirizzo: "Indirizzo",
    sector: "Settore",
    dimensionName: "Dimensione",
    categoria: "Categoria",
    comanders: " "
    // leadingCompany: "Organizzazione",
    // dimensionSelect: "Dimensione"
}



const LIST_LIMIT = 8;
const paginationOptions = {
    paginationSize: 2,
    pageStartIndex: 1,
    showTotal: false,
    hidePageListOnlyOnePage: false,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [ LIST_LIMIT ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];

const sortableFields = [
];

const sortableSubFields = [
    "Ragione Sociale",
    "Città",
    "Provincia",
    "Settore",
    "Dimensione",
    "Tipologia Commerciale",
    "Classe Contabile",
    "Categoria",
    "leadingCompany"
];


const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}


const formatSubColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: subFieldMap[ field ] || field,
                    sort: sortableSubFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;


class OrganizzationPage extends Component {

    state = {
        data: []
    };

    constructor( props ) {
        super( props );
        this.loadData = this.loadData.bind( this );
        this.loadOrganizzationCompanies = this.loadOrganizzationCompanies.bind( this );
    }

    render() {


        renderlog("Organizzation Page", this.state, this.props, COLOR_POLICY.warning);

        let context = (null);

        if(!!this.state && !!this.state.data && this.state.data.length > 0) {


            let companies = [];

            context = (
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    search
                    // exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                >
                    {
                        props => (
                            <React.Fragment>
                                <Row>
                                    <Col>
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                </Row>

                                <BootstrapTable
                                    { ...props.baseProps }
                                    id={"sectorlist-table"}
                                    bootstrap4

                                    size="sm"
                                    responsive
                                    keyField="id"

                                    bordered={ false }
                                    data={
                                        this.state.data
                                            .map( row => {
                                                let newObj = {};
                                                Object.keys(row)
                                                    .map( field => {
                                                        if( !["companies", "rawCompanies"].includes( field ) ) newObj[field] = row[field];
                                                    })
                                                ;
                                                return newObj;
                                            })
                                    }
                                    columns={
                                        formatColumns(
                                            Object.keys(
                                                this.state.data
                                                    .map( row => {
                                                        let newObj = {};
                                                        Object.keys(row)
                                                            .map( field => {
                                                                if( !["companies", "rawCompanies"].includes( field ) ) newObj[field] = row[field];
                                                            })
                                                        ;
                                                        return newObj;
                                                    })
                                                    .reduce( getLastElementOr, {} )
                                            )
                                        )
                                    }
                                    defaultSorted={ defaultSorted }
                                    pagination={ paginationFactory(paginationOptions) }
                                    wrapperClasses="table-responsive"
                                    expandRow={{
                                        renderer: row => {
                                            showlog("riga selezionata");
                                            showlog(row);
                                            // showlog("allCompanies:", allCompanies);
                                            // showlog("rootData:", rootData);

                                            companies =
                                                this.state.data
                                                    .filter( d => d.id === row.id )
                                                    .reduce( getLastElementOr, { companies: [] } )
                                                    .companies
                                            ;

                                            showlog("%ccompanies", "color:#ff0;");
                                            showlog(companies);

                                            let innerContext = null;

                                            if(
                                                !!this.state &&
                                                !!this.state.data &&
                                                this.state.data.length > 0 &&
                                                !!isNotEmptyArray(companies)
                                            ) {

                                                innerContext = (
                                                    <div className={"organizzation-companies-container"}>


                                                        <Row><h4>Aziende</h4></Row>

                                                        {
                                                            !!hasPermission( permissions.CREATE_COMPANY )
                                                                ? (
                                                                    <Row>
                                                                        <ActionButton
                                                                            buttonText={
                                                                                <React.Fragment>
                                                                                    <i className={"uil-building"}/>
                                                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Nuova Azienda</span>
                                                                                </React.Fragment>
                                                                            }
                                                                            promise={ () => Promise.resolve() }
                                                                            onResult={ () => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    companyModal: row.id
                                                                                });
                                                                            }}
                                                                            className={"sdg-flag-icon-container"}
                                                                        />
                                                                    </Row>
                                                                )
                                                                : null
                                                        }


                                                        <Row>
                                                            <ToolkitProvider
                                                                bootstrap4
                                                                keyField="codCliFor"
                                                                search
                                                                // exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                                                            >
                                                                {
                                                                    props => (
                                                                        <React.Fragment>
                                                                            <BootstrapTable
                                                                                {...props.baseProps}
                                                                                id={"sectorlist-table"}
                                                                                bootstrap4
                                                                                striped
                                                                                size="sm"
                                                                                responsive
                                                                                keyField="codCliFor"

                                                                                bordered={false}
                                                                                data={ companies }
                                                                                columns={
                                                                                    formatSubColumns(
                                                                                        Object.keys(
                                                                                            companies
                                                                                                .reduce( getLastElementOr, null )
                                                                                        )
                                                                                    )
                                                                                }
                                                                                defaultSorted={defaultSorted}
                                                                                pagination={paginationFactory(paginationOptions)}
                                                                                wrapperClasses="table-responsive"
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </Row>


                                                    </div>
                                                )
                                            }
                                            else {
                                                innerContext = (

                                                    <div className={"organizzation-companies-container"}>
                                                        <Row><h4>Aziende</h4></Row>

                                                        {
                                                            !!hasPermission( permissions.CREATE_COMPANY )
                                                                ? (
                                                                    <Row>
                                                                        <ActionButton
                                                                            buttonText={
                                                                                <React.Fragment>
                                                                                    <i className={"uil-building"}/>
                                                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Nuova Azienda</span>
                                                                                </React.Fragment>
                                                                            }
                                                                            promise={ () => Promise.resolve() }
                                                                            onResult={ () => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    companyModal: row.id
                                                                                });
                                                                            }}
                                                                            className={"sdg-flag-icon-container"}
                                                                        />
                                                                    </Row>
                                                                )
                                                                : null
                                                        }


                                                        <WaitingOrNodataMessage
                                                            waiting={
                                                                !companies ||
                                                                (
                                                                    companies.length === 0 &&
                                                                    !(
                                                                        this.state.data
                                                                            .filter(d => d.id === row.id)
                                                                            .reduce( getLastElementOr, { companiesLoaded: false } )
                                                                            .companiesLoaded
                                                                    )
                                                                )
                                                            }
                                                            waitMessage={"caricamento"}
                                                            nodataMessage={"Nessuna Azienda"}
                                                            spinnerColor={"success"}
                                                        />

                                                    </div>
                                                );

                                                // se le companies non sono state già caricate
                                                if(
                                                    !
                                                        (this.state.data
                                                            .filter(d => d.id === row.id)
                                                            .reduce( getLastElementOr, { companiesLoaded: false } )
                                                            .companiesLoaded)
                                                ) {

                                                    this.loadOrganizzationCompanies( row.id )
                                                        .then( list => {

                                                            // debugger;
                                                            showlog("controllami");
                                                            showlog( this.state );

                                                            // debugger;

                                                        })
                                                        .catch( e => {
                                                            errorlog( "Caricamento Aziende da Organizzazione", e );
                                                        })
                                                    ;

                                                }
                                            }


                                            return innerContext;

                                        },
                                        showExpandColumn: false,
                                        onlyOneExpanding: true
                                    }}
                                />

                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>
            );
        }
        else {
            context = (
                <WaitingOrNodataMessage
                    waiting={ (!!this.state && !!isNotEmptyArray(this.state.data) /*&& this.state.data.length === 0*/) }
                    waitMessage={"caricamento"}
                    fullHeight={true}
                    nodataMessage={"Nessuna Organizzazione"}
                />
            )
        }

        let content = (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        /*{ label: getCurrentRoleLabel(), path: PATH_PREFIX +"/welcome" }, */
                        { label: translate("Organizzations"), active: true }
                    ]}
                    title={
                        <React.Fragment>
                            { translate("Leading_companies") }
                        </React.Fragment>

                    }
                />



                {
                    (!!this.state.deletePopup)
                        ? (
                            <DeleteOrganizzationPrompt
                                org={this.state.deletePopup}
                                onClose={ isToRefresh => {
                                    this.setState({deletePopup: null},
                                        () => {
                                            if( !!isToRefresh ) this.loadData();
                                        }
                                    )
                                }}
                                onDenied={ msg => {
                                    this.setState({
                                        deletePopup: null,
                                        errorMessage: (<span>Non puoi eliminare l'organizzazione<br/><em>{ msg }</em></span>)
                                    });
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.errorMessage)
                        ? (
                            <ErrorMessageModalComponent
                                message={this.state.errorMessage}
                                contentClassName={"big-font"} // choiche-status sirating-question-attachment-modal-icon comander-icon
                                noheader={true}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        errorMessage: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.deleteCompanyPopup)
                        ? (
                            <DeleteOrganizzationCompanyPrompt
                                org={this.state.deleteCompanyPopup}
                                onClose={ isToRefresh => {


                                    if ( !!isToRefresh ) {
                                        this.loadOrganizzationCompanies(this.state.currentOrganizzation)
                                            .then( result => {
                                                this.setState({
                                                        ...this.state,
                                                        deleteCompanyPopup: null
                                                    },
                                                    () => {

                                                            this.loadData();

                                                    }
                                                )
                                            })
                                            .catch(e => {
                                                errorlog("caricamento aziende dopo nuova azienda", e);
                                            })
                                    }
                                    else {
                                        this.setState({deleteCompanyPopup: null})
                                    }
                                }}
                                onDenied={ msg => {
                                    this.setState({
                                        deleteCompanyPopup: null,
                                        errorMessage: msg
                                    });
                                }}
                            />
                        )
                        : null
                }



                {
                    !!this.state.companyModal
                        ? (
                            <CompanyModal
                                organizzation={ this.state.companyModal }
                                onClose={ (isToRefresh, error) => {


                                    if( !!error ) {

                                    }
                                    else {
                                        if ( !!isToRefresh ) {
                                            this.loadOrganizzationCompanies(this.state.companyModal)
                                                .then( result => {
                                                    this.setState({
                                                            ...this.state,
                                                            companyModal: null
                                                        },
                                                        () => {
                                                            if ( !!isToRefresh ) {
                                                                this.loadData();
                                                            }
                                                        }
                                                    )
                                                })
                                                .catch(e => {
                                                    errorlog("caricamento aziende dopo nuova azienda", e);
                                                })
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                companyModal: null
                                            });
                                        }
                                    }





                                }}
                            />
                        )
                        : null
                }


                {
                    !!this.state.sectorListModal
                        ? (
                            <SectorListPopup
                                companyId={ this.state.sectorListModal }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        sectorListModal: null
                                    })
                                }}
                                onChoose={ list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        sectorListModal: null
                                    })

                                }}
                            />
                        )
                        : null
                }


                {

                    !!this.state.newOrganizationModal
                        ? (
                            <OrganizzationDetailsModal
                                details={ this.state.newOrganizationModal }
                                onClose={ isToRefresh => {
                                    this.setState({
                                        ...this.state,
                                        newOrganizationModal: null
                                    },
                                        () => {
                                            if ( !!isToRefresh ) {
                                                this.loadData();
                                            }
                                        }
                                    )
                                }}
                            />
                        )
                        : null

                }




                <Card>
                    <CardBody>


                        <Row>
                            <Col>
                                <div className="page-title-box">
                                    <div className="page-title-right">

                                        {
                                            ( !!hasPermission( permissions.CREATE_NEW_ORGANIZZATION ) )
                                                ? (
                                                    <ActionButton
                                                        color={"success"}
                                                        buttonText={"Nuova Organizzazione"}
                                                        promise={() => Promise.resolve()}
                                                        onResult={ result => {
                                                            this.setState({
                                                                newOrganizationModal: true
                                                            })
                                                        }}
                                                        onError={ err => {
                                                            errorlog("Set state in new organizzation", err);
                                                        }}
                                                    />
                                                )
                                                : null
                                        }


                                    </div>
                                    <h4 className="page-title">Lista organizzazioni</h4>
                                </div>
                            </Col>
                        </Row>

                        { context }

                    </CardBody>
                </Card>


            </React.Fragment>
        );



        return content;
    }




    componentDidMount() {

        this.loadData()
            .then( list => {})
            .catch( e => {
                errorlog( "Caricamento Organizzazioni", e );
            })
        ;

    }





    loadOrganizzationCompanies( oid ) {

        showlog("carico le aziende di "+ oid );

        return (
            new Promise((ok, ko) => {
                setTimeout(
                    () => {
                        ok([]);
                    },
                    1000
                )
            })


            .then( () => {

                return (

                    loadCompaniesData( oid )

                        // adjust ---------------------------------------------------------------------------------------------------
                        .then( list => {
                            return (
                                list
                                    .map( companyRow => {
                                        companyRow.dimensionId = companyRow.dimension.id;
                                        companyRow.dimensionName = companyRow.dimension.name;
                                        return companyRow;
                                    })
                            );
                        })


                        // extras   -----------------------------------------------------------------------------------------------
                        .then(data => {

                            return (
                                loadAllComapnyAllowedDimensions()
                                    .then( dimensions => {
                                        return {data: data, dimensions: dimensions};
                                    })
                                    .catch(e => Promise.reject( e ))
                            );

                        })


                        // extras   -----------------------------------------------------------------------------------------------
                        .then(({data, dimensions}) => {

                            return (
                                data
                                    .map( companyRow => {

                                        /*
                                        companyRow.contatti = ();
                                        companyRow.indirizzi = ();
                                        companyRow.dimensionSelect = ();
                                         */

                                        companyRow.sector = (
                                            <i
                                                className="mdi mdi-leaf" // earth
                                                onClick={ clickEvent => {
                                                    this.setState({
                                                        ...this.state,
                                                        sectorListModal: companyRow.id
                                                    });
                                                }}
                                            />
                                        );


                                        // eliminazione
                                        companyRow.comanders = (
                                            <React.Fragment>
                                                <i
                                                    className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"
                                                    onClick={ clickEvent => {
                                                        // showlog("permesso di eliminazione company");
                                                        // showlog( !!hasPermission( permissions.DELETE_COMPANY ) );
                                                        if( !!hasPermission( permissions.DELETE_COMPANY ) ) {
                                                            this.setState({...this.state, deleteCompanyPopup: companyRow, currentOrganizzation: oid});
                                                        }
                                                    }}
                                                />
                                            </React.Fragment>
                                        );

                                        return companyRow;
                                    })
                            );
                        })




                        // finish -------------------------------------------------------------------------------------------------
                        .then( companiesList => {

                            return (
                                new Promise( (suc, fai) => {

                                    let raw = companiesList;
                                    companiesList =
                                        companiesList
                                            .map( d => {
                                                delete d.contatti;
                                                delete d.indirizzi;
                                                return d;
                                            })
                                    ;


                                    let refreshedData =
                                        this.state.data
                                            .map( d => safeCloneReactObject( d ) )
                                    ;
                                    showlog("data", companiesList);
                                    showlog("state data", this.state.data);
                                    showlog("refreshed data", refreshedData);
                                    let dataOrg =
                                        refreshedData
                                            .filter( d => d.id === oid )
                                            .reduce( getLastElementOr, null )
                                    ;
                                    dataOrg.companies = companiesList;
                                    dataOrg.rawCompanies = raw;
                                    dataOrg.companiesLoaded = true;


                                    this.setState({
                                        ...this.state,
                                        data:
                                            this.state.data
                                                .map( (d, i) => Object.assign( {}, d, refreshedData[i]) )
                                    },
                                        () => {
                                            suc( refreshedData );
                                        }
                                    )
                                })
                            );

                        })


                        .catch(e => {
                            errorlog("errore", e);
                        })

                );



            })
        );
    }





    loadData() {
        return (

            new Promise((tillEnd, fail) => {

                if( !!hasPermission( permissions.GET_ORGANIZZATION ) ) {

                    loadOrganizzations()


                        .then( responseData => {
                            return (
                                responseData
                                    .map(row => {
                                        // row.adminId = row.superAdmin.id;
                                        // row.adminName = row.superAdmin.name;
                                        row.companies = [];
                                        // delete row.superAdmin;
                                        return row;
                                    })
                            );
                        })


                        // buttons -------------------------------------------------------------------------------------------------
                        .then( responseData => {
                            return (
                                responseData
                                    .map( row => {
                                        row.buttons = (
                                            <div className={"table-cell-comander-container"}>
                                                <i
                                                    className="mdi mdi-pencil-outline comander-icon"
                                                    onClick={ clickEvent => {
                                                        clickEvent.stopPropagation();
                                                        if( !!hasPermission( permissions.EDIT_ORGANIZZATION ) ) {
                                                            this.setState({
                                                                newOrganizationModal: row
                                                            })
                                                        }
                                                    }}
                                                />
                                                <i
                                                    className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"
                                                    onClick={ clickEvent => {
                                                        if( !!hasPermission( permissions.DELETE_ORGANIZZATION ) ) {
                                                            this.setState({...this.state, deletePopup: row});
                                                        }
                                                    }}
                                                />
                                            </div>
                                        );
                                        return row;
                                    })
                            );
                        })


                        // finish -------------------------------------------------------------------------------------------------
                        .then( list => {

                            this.setState({
                                    data: list
                                },
                                () => {
                                    tillEnd( list );
                                }
                            );

                        })

                        .catch( e => {
                            fail( e );
                        })

                }
                else {
                    fail("Permesso di lettura organizzazioni NEGATO");
                }

            })




        );
    }



}


export default withRouter(OrganizzationPage);
