import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import InformativeStateReport from "./area/super-admin/informative-state-report";

export const InformativeStateReportModal = props => (
	<Modal isOpen={ true } toggle={ props.onClose }>
		<ModalHeader>Report Stati Informative</ModalHeader>
		<ModalBody className={""}>
			<InformativeStateReport
				isAudit={true}
				collect={ props.collect }
				isAssurance={ props.isAssurance }
				evaluation_id={ props.ratingId }
				NoModal={true}
				data={ props.metadata }
			/>
		</ModalBody>
	</Modal>
);
