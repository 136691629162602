import {HTTP_CALL_SECONDS_WAIT_ALLOWED} from "../env";
/*
export const httpTimer = (
	new Promise((success, fail) => {
		setTimeout( () => fail("HTTP CALL WAITING TIME EXPIRED"), HTTP_CALL_SECONDS_WAIT_ALLOWED * 1000 );
	})
);
*/

/**
 * Timer sotto promise, allo scadere del timer viene lanciata la Promise.reject
 * @param seconds
 * @returns {Promise<unknown>}
 */
export const httpTimerStart = seconds => {
	let time = seconds || HTTP_CALL_SECONDS_WAIT_ALLOWED * 1000;
	return (
		new Promise((success, fail) => {
			setTimeout( () => fail("HTTP CALL WAITING TIME EXPIRED"), time );
		})
	)
};

export const httpTimer = {
	start: seconds => {
		let time = seconds || HTTP_CALL_SECONDS_WAIT_ALLOWED * 1000;
		return (
			new Promise((success, fail) => {
				setTimeout( () => fail("HTTP CALL WAITING TIME EXPIRED"), time );
			})
		)
	}
}
