import React from "react";
import "./indexd.css";

export const OnlyTextEmail = () => (
    <div id={"only-text-email-page"}>
        Nel mezzo del cammin di nostra vita mi ritrovai per una selva oscura che la via era smarrita.<br/>
        Quant'onesta pare la donna mia quand'ella altrui saluta<br/>
        Del senno di poi son piene le fosse
    </div>
);
