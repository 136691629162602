import React, {Component} from 'react';
import {Col, Modal, ModalFooter, ModalHeader, Row} from "reactstrap";
import {renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../../components/save-button";
import {Redirect} from "react-router-dom";
import {PATH_PREFIX} from '../../../env';
import {acceptEUPA} from '../../../business-logic/rating';
import {translate} from '../../../components/i18n/translate-function';

export default class ConfirmProfilationModal extends Component {

    state = {

    }

    constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );

    }

    toggle = () => {
        this.props.onClose();
    }


        render(){



            renderlog(
                "ConfirmProfilationPopUp",
            );

            showlog("esternal props 1+ " , this.props.esternalMatchId)
            showlog("esternal props 2+ ", this.props.esternalId)

            if(!!this.state.confirmProfilationFlag){
                return (
                    <Redirect
                        to={{
                            pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ (this.props.esternalMatchId || this.props.esternalId)
                        }}
                    />
                );
            }
            if(!!this.state.notConfirmProfilationFlag){
                return (
                    <Redirect
                        to={{
                            pathname: PATH_PREFIX +"/sirating-admin/" + translate("eupa")  +"/"+ (this.props.esternalMatchId || this.props.esternalId)
                        }}
                    />
                );
            }


            let content = (
                <Modal isOpen={ true } toggle={this.toggle} className={"segnalation-profilation-modal"}>
                    <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                        Confermi di voler accettare la profilazione e proseguire alla valutazione
                        SI Rating? Una volta accettata non potrai più richiedere modifiche al perimetro della valutazione.
                    </ModalHeader>
                    <ModalFooter className={this.state.bodyClass}>
                    <Row>
							<Col>
								<ActionButton
									buttonText={ "Accetta Profilazione" }
									//promise={ () => acceptEUPA( props.match.params.id || props.id ) }
									//onResult={ () => setGoAhead( true ) }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
                                    promise={ () => acceptEUPA( this.props.esternalMatchId  || this.props.esternalId ) }
									onResult={ () => {

                                        this.setState({
                                            ...this.state,
                                            confirmProfilationFlag: true
                                        })


                                    } }
								/>
							</Col>
                            <Row>
							<Col>
								<ActionButton
									buttonText={ "Torna indietro" }
									//promise={ () => Promise.resolve() }
									//onResult={ () => {
									//	setSegnalationPopUp(true)
									//} }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
                                    promise={ () => Promise.resolve() }
									onResult={ () => {

                                        /*this.setState({
                                            ...this.state,
                                            notConfirmProfilationFlag: true
                                        })*/
                                        this.props.onClose()


                                    } }
								/>
							</Col></Row>
						</Row>
                    </ModalFooter>
                </Modal>
            );

            return content;
        }

}
