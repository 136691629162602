import {errRemoteLog} from "../services/request-manager";
import {showlog} from "../utils";
import {REMOTE_LOG} from "../env";


export const remoteLog = (message, e, details) => {




    let error = e;
    if (e instanceof TypeError) {
        // showlog("typeerror");
        // showlog( e.message );
        // showlog( e.name );
        // showlog( typeof e );
        // showlog( Object.keys(e) );
        // showlog( e.toString() );
        // showlog( e.valueOf() );
        // showlog( new String(e) );
        // showlog( JSON.stringify( error ) );
        // showlog( (new String( e.valueOf() )) );
        // showlog( e );
    } else if (e instanceof RangeError) {
        // statements to handle RangeError exceptions
        // showlog("rangeerror");
        // showlog( e );
    } else if (e instanceof EvalError) {
        // statements to handle EvalError exceptions
        // showlog("evalerror");
        // showlog( e );
    } else {
        // showlog("altro");
        // showlog( e );
    }



    let payload = {
        message: e,
        ex: e,
        details: details,
        operation: message,
        time: (new Date()).getTime()
    };

    if( !!REMOTE_LOG ) return errRemoteLog( payload );
    return Promise.resolve();

}
