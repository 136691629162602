import {Component} from "react";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getLastElementOr} from "../../../utils/array-utils";
import ActionButton from "../../save-button";
import {treeSetConfirm} from "../../../business-logic/gri";



export default class SetTreeConfirmModal extends Component {

    state = {}

    constructor( props ) {
        super( props );

        this.toggle = this.toggle.bind( this );
    }


    toggle = () => {
        if( !this.state.inWait ) this.props.onClose();
    }

    render() {

        renderlog("configuration TREE Confirm MODAL", this.state, this.props, { color: "#11ffff", contrastColor: "#222222" });

        let context = (
            <span>Una volta salvata, la struttura non sarà più possibile modificarla</span>
        );

        if( !!this.props.message ) context = this.props.message;

        let content = (
            <Modal isOpen={ true } toggle={this.toggle}>
                <ModalBody className={this.state.bodyClass}>
                    { context }
                </ModalBody>
                <ModalFooter className={this.state.bodyClass}>
                    <ActionButton
                        buttonText={ "OK" }
                        promise={ () => {
                            return treeSetConfirm(this.props.collect);
                        }}
                        onProcess={ () => {
                            this.setState({
                                ...this.state,
                                inWait: true
                            })
                        }}
                        onResult={ result => {
                            this.props.onClose( true );
                        }}
                        onError={ e => {

                        }}
                    />
                    <Button color="link" onClick={this.toggle}>
                        annulla
                    </Button>
                </ModalFooter>
            </Modal>


        );


        return content;
    }

    componentDidMount() {}
}
