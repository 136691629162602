import React from "react";

export default class OKSVGComponent extends React.Component {
    constructor( props ) {
        super(props);
    }

    render() {
        return (
            <svg
                xmlns={"http://www.w3.org/2000/svg"}
                viewBox={"0 0 14 14"}
                style={ this.props.customStyle }
                className={ this.props.customClass || '' }
            >
                <path
                    fill={"#00C752"}
                    d={"M5.50183983,10.4944805 L5.50367966,10.4963203 L12.8482451,3.15175489 C13.5762779,4.25592793 14,5.57848014 14,7 C14,10.866 10.866,14 7,14 C3.134,14 0,10.866 0,7 C0,3.134 3.134,0 7,0 C8.67832535,0 10.218695,0.590646458 11.4245848,1.57541523 L11.4245848,1.57541523 L5.50183983,7.49816017 L3.50183983,5.49816017 L2.00183983,6.99816017 L5.5,10.4963203 L5.50183983,10.4944805 Z"}
                />
            </svg>
        );
    }
}
