import React from "react";
import {FormGroup, Input} from "reactstrap";
import {renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../save-button";

export default class TreeDataEntryValueBoxComponent extends React.Component {

	state = {
		// value: this.props.value
	}

	constructor( props ) {
		super( props );
	}

	render() {


		renderlog("DATA-ENTRY value BOX", this.state, this.props);


		let val =
			(!!this.props.node && !!this.props.node.value)
				? this.props.node.value[0]
				: ""
		;
		if( !!this.props.node.measureUnit && !!this.props.node.measureUnit.isString && !!!val) val = 0;
		// if(this.props.node.measureUnit.toLowerCase() !== NO_MEASUREMENT_UNIT && !!!val) val = 0;

		return (
			<React.Fragment>
				<div className={"tree-node-data-entry-value tree-node-data-entry-value-small"}>
					<FormGroup
						className={"tree-node-data-entry-value-box-component" + ((!!this.props.node.isString) ? " description-textarea-container-value" : "")}
					>
						<Input
							// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
							type={ !!this.props.node.isString ? "textarea" : "number" }
							rows={ !!this.props.node.isString ? 5 : 1 }
							name={ this.props.id}
							id={ this.props.id }
							// placeholder={ val }
							defaultValue={ this.props.val }
							className={

								(
									!!this.props.node.isString
										? "tree-node-data-entry-value-box-component-textarea "
										: "tree-node-data-entry-value-box-component-input "
								)
								+
								(!!this.props.setted)
									? "setted-value"
									: ""

							}
							// onChange={ changeEvent => {
							onBlur={ changeEvent => {
								this.setState({
									...this.state,
									// value: !!this.props.node.isString ? ""+ changeEvent.target.value : parseFloat(changeEvent.target.value)
									value: (
										!!this.props.node.isString
											? ""+ changeEvent.target.value
											: parseFloat(changeEvent.target.value)
									),
									forceInternal: true
								})
							}}
							disabled={ !!this.props.disabled }
						/>
					</FormGroup>


					{
						!!this.props.disabled
							? null
							: (
								<ActionButton
									className={ (!!!this.props.node.isString) ? "description-button-value" : "" }
									promise={ () => {
										return (
											Promise.resolve()
												.then( () => {
													if(!!this.props.onConfirm && typeof this.props.onConfirm === "function") {
														return this.props.onConfirm( this.state.value );  // , !!this.props.node.isString );
													}
													return null;
												})
										);
									}}
									onError={ e => {
										showlog("errore tornato all'action button");
										showlog( e );
									}}
								/>
							)
					}



				</div>
			</React.Fragment>
		);
	};


	componentDidMount() {
		// mountedlog("DATA-ENTRY value BOX", this.state, this.props);
		this.setState({ value: this.props.val });
	}

	componentDidUpdate() {

		// updatelog("DATA-ENTRY value BOX", this.state, this.props);
		// if( this.state.value !== this.props.val && !this.state.forceInternal ) this.setState({ value: this.props.val });

		// showlog("nodo virtuale", !!this.props.req, !!this.props.loadValueIsNeeded );

		// showlog("controllo valore del nodo", this.props.node);
		/*
		evaluate: true
		griRepeatingValueName: "ZonaUno"
		griSubrequirementEn: null
		griSubrequirementId: 121
		id: 58
		isString: true
		measureUnit: "description"
		name: "virtualNode-selfCloned"
		raw: {}
		seedId: 122509
		structureId: 122509
		timing: false


		req
	      ├── zone
	      │     ├── node
	      │     └── node
		  └── zone
	            ├── node
	            └── node



		*/


		/*
		if(!!this.props.req && !!this.props.loadValueIsNeeded && !this.state.called) {


			this.setState({
				...this.state,
				called: true,
				inWait: true,
				value: "...in caricamento"
			},
				() => {

				loadVirtualTreeNodeValue( this.props.req.id, this.props.req.structureId, this.props.zoneId, this.props.node.id  )
				// loadVirtualTreeNodeValue( this.props.req.id, this.props.node.structureId, this.props.node.id, this.props.node.id  )
					.then( result => {

						let value = null;
						if(!!result[0]) {
							this.setState({
								...this.state,
								value: ( !!result[0].valueType ? result[0].valueString : result[0].valueDecimal ),
								inWait: false
							})
						}

					})
					.catch(e => {
						errorlog("errore");
						console.log(e);
					})

				}
			)


		}

		*/



	}

}


