// GriRequirementGoalModal
import React, {Component} from 'react';
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {CALENDAR_DATE_FORMAT_IT, DATE_FORMAT_IT, START_MODERN_ISO_TIME} from "../../../env";
import {FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import HyperDatepicker from "../../Datepicker";
import "../modal.css";
import moment from "moment";
import {saveGoal, validationGriAdminGoal} from "../../../business-logic/gri";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../save-button";
import {isNumber} from "../../../utils/codes-utils";
import {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {translate} from "../../i18n/translate-function"; // the locale you want
registerLocale("it", it); // register it with the name you want


export default class GriRequirementGoalModal extends Component {
	state = {
		errors: {
			startValue: (!!this.props.requirement.valueStartGoal) ? "" : translate("MissingValue"),
			endValue: (!!this.props.requirement.valueGoal) ? "" : translate("MissingValue")
		},
		startValue: this.props.requirement.valueStartGoal || "",
		endValue: this.props.requirement.valueGoal || "",
		startTime:
			(
				!!this.props.requirement.dateStartGoal && ( moment( START_MODERN_ISO_TIME ) < moment( this.props.requirement.dateStartGoal ) )
					? moment( this.props.requirement.dateStartGoal || (new Date()) ).format( DATE_FORMAT_IT )
					: moment( this.props.collect.Inizio || (new Date()), DATE_FORMAT_IT ).format( DATE_FORMAT_IT )
			),
		endTime:
			(
				!!this.props.requirement.dateGoal && ( moment( START_MODERN_ISO_TIME ) < moment( this.props.requirement.dateGoal ) )
					? moment( this.props.requirement.dateGoal || (new Date()) ).add(1, "days").format( DATE_FORMAT_IT )
					: moment( this.props.collect.dateEnd || (new Date()), DATE_FORMAT_IT ).format( DATE_FORMAT_IT )
			),
		note: this.props.requirement.descriptionGoal
	}

	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );

		/*
		dateGoal: "1900-01-01T00:00:00"
		dateStartGoal: "1900-01-01T01:01:01"
		valueGoal: ""
		valueStartGoal: ""
		 */

	}

	toggle = () => {
		this.props.onClose();
	}

	render() {

		renderlog("GOALS", this.state, this.props);
		showlog("controllo date");
		showlog( !!this.props.requirement.dateStartGoal );
		showlog( ( moment( START_MODERN_ISO_TIME ) < moment( this.props.requirement.dateStartGoal ) ) );
		showlog( !!this.props.requirement.dateStartGoal && ( moment( START_MODERN_ISO_TIME ) < moment( this.props.requirement.dateStartGoal ) ) );

		let context = (
			<WaitingOrNodataMessage waiting={ true } waitMessage={"in attesa"} spinnerColor={"success"}/>
		);

		if( !!this.state.deleteContext ) {
			context = (
				<span>{ translate("ConfirmDeleteGoal") }</span>
			)
		}
		else {

		context = (
			<AvForm>



				{/*     DATA_INIZIO  -------------------------------------------------------------------------------- */}
				<FormGroup>
					<Label for="startTime">{ translate("StartDate") }</Label>
					<HyperDatepicker
						hideAddon={ false }
						dateFormat={ CALENDAR_DATE_FORMAT_IT }
						onSelect={ clickEvent => {
							console.log("HyperDatepicker onSelect");
							console.log(clickEvent);
							this.setState(
								{
									...this.state,
									startTime: moment( clickEvent ).format( DATE_FORMAT_IT )
								},
								() => {
									validationGriAdminGoal(this.state)
										.then(() => {
											console.log("validazione passata");
											this.setState(
												{
													...this.state,
													errors: {
														...this.state.error,
														endTime: null
													}
												})
										})
										.catch(e => {
											let errors = {};
											e.map(kv => Object.assign(errors, kv));
											this.setState({
												...this.state,
												errors
											})
										})
								})
						}}
						value={ this.state.startTime }
						selected={ this.state.startTime }
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						scrollableYearDropdown
						dropdownMode="select"
						yearDropdownItemNumber={15}
						todayButton={ translate("Today") }
						// minDate={new Date()}
						// maxDate={addDays(new Date(), 5)}
						locale={ "it" }
						highlightDates={[ new Date() ]}
						showWeekNumbers
						disabled={ this.props.disabled }
					/>
					{
						( !!this.state.errors && !!this.state.errors.startTime )
							? <label className="error-message-label">{ this.state.errors.startTime }</label>
							: null
					}
				</FormGroup>


				{/*     DATA_FINE   -------------------------------------------------------------------------------- */}
				<FormGroup>
					<Label for="endTime">{ translate("FinishDate") }</Label>
					<HyperDatepicker
						hideAddon={ false }
						dateFormat={ CALENDAR_DATE_FORMAT_IT }
						onSelect={ clickEvent => {
							console.log("HyperDatepicker onSelect");
							console.log(clickEvent);
							this.setState(
								{
									...this.state,
									endTime: moment( clickEvent ).format( DATE_FORMAT_IT )
								},
								() => {
									validationGriAdminGoal(this.state)
										.then(() => {
											console.log("validazione passata");
											this.setState(
												{
													...this.state,
													errors: {
														...this.state.error,
														endTime: null
													}
												})
										})
										.catch(e => {
											let errors = {};
											e.map(kv => Object.assign(errors, kv));
											this.setState({
												...this.state,
												errors
											})
										})
								})
						}}
						value={ this.state.endTime }
						selected={ this.state.endTime }
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						yearDropdownItemNumber={15}
						todayButton={ translate("Today") }
						// minDate={new Date()}
						// maxDate={addDays(new Date(), 5)}
						locale={ "IT" }
						highlightDates={[ new Date() ]}
						showWeekNumbers
						disabled={ this.props.disabled }
					/>
					{
						( !!this.state.errors && !!this.state.errors.endTime )
							? <label className="error-message-label">{ this.state.errors.endTime }</label>
							: null
					}
				</FormGroup>



				{/*     Valore Iniziale  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="validity">{ translate("StartValue") }</Label>
					<AvInput
						type={ "number" }
						name="startValue"
						id="startValue"
						value={ this.state.startValue }
						onChange={ changeTextEvent => {
							if(
								isNumber( changeTextEvent.target.value ) &&
								changeTextEvent.target.value.indexOf("e") === -1 &&
								changeTextEvent.target.value.indexOf("E") === -1
							) {
							this.setState(
								{
									...this.state,
									startValue: changeTextEvent.target.value
								},
								() => {

									validationGriAdminGoal(this.state)
										.then(() => {
											console.log("validazione passata");
											this.setState(
												{
													...this.state,
													errors: {
														...this.state.error,
														startValue: null
													}
												})
										})
										.catch(e => {
											let errors = {};
											e.map(kv => Object.assign(errors, kv));
											this.setState({
												...this.state,
												errors
											})
										})

								})
							}
						}}
						required
						disabled={ this.props.disabled }
					/>
					{
						( !!this.state.errors && !!this.state.errors.startValue )
							? <label className="error-message-label">{ this.state.errors.startValue }</label>
							: null
					}
				</AvGroup>

				{/*     Valore Finale  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="validity">{ translate("EndValue") }</Label>
					<AvInput
						type="text"
						name="endValue"
						id="endValue"
						value={ this.state.endValue }
						onChange={ changeTextEvent => {
							if(
								isNumber( changeTextEvent.target.value ) &&
								changeTextEvent.target.value.indexOf("e") === -1 &&
								changeTextEvent.target.value.indexOf("E") === -1
							) {
							this.setState(
								{
									...this.state,
									endValue: changeTextEvent.target.value
								},
								() => {
									validationGriAdminGoal(this.state)
										.then(() => {
											this.setState(
												{
													...this.state,
													errors: {
														...this.state.error,
														endValue: null
													}
												})
										})
										.catch(e => {
											let errors = {};
											e.map(kv => Object.assign(errors, kv));
											this.setState({
												...this.state,
												errors
											})
										})
								}
							)
							}
						}}
						required
						disabled={ this.props.disabled }
					/>
					{
						( !!this.state.errors && !!this.state.errors.endValue )
							? <label className="error-message-label">{ this.state.errors.endValue }</label>
							: null
					}
				</AvGroup>

				{
					( !!this.state.errors )
						? (
							<AvGroup>
								<label className="error-message-label">
									{
										Object.keys(this.state.errors)
											.map( field => !["startTime", "endTime"].includes( field ) )
											.map( field => this.state.errors[ field ] )
									}
								</label>
							</AvGroup>
						)
						: null
				}



				{/*     Descrizione  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="validity">{ translate("Description") }</Label>
					<AvInput
						type="textarea"
						row={ 32 }
						name="note"
						id="note"
						value={ this.state.note }
						onChange={ changeTextEvent => {
							this.setState(
								{
									...this.state,
									note: changeTextEvent.target.value
								})
						}}
						disabled={ this.props.disabled }
					/>
				</AvGroup>


			</AvForm>
		);




		}



		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{ translate("Goal") }
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>

					{
						(!!this.state.deleteContext)
							? (
								<React.Fragment>
									<ActionButton
										color={"link"}
										buttonText={ translate("Cancel") }
										promise={ () => {
											return Promise.resolve();
										}}
										onResult={ result => {
											this.setState({
												...this.state,
												deleteContext: false
											});
										}}
									/>


									{
										!!this.props.disabled
											? null
											: (
												<ActionButton
													color={"danger"}
													buttonText={ translate("Delete") }
													waitingMessage={ translate("InDeleting") }
													promise={ () => {
														return saveGoal( { id: this.props.requirement.id }, this.props.collect.id, this.props.level );
													}}
													onResult={ result => {
														this.props.onSave();
													}}
													onError={ e => {
														errorlog("errore al salvataggio di un obiettivo", e);
														this.setState({
															...this.state,
															errors: {
																...this.state.error,
																...e
															}
														});
													}}
												/>
											)
									}


								</React.Fragment>
							)
							:

								(
										!!this.props.disabled
											? null
											: (

												<React.Fragment>

													<ActionButton
														promise={ () => {
															if(
																Object.keys(this.state.errors)
																	.filter( err => ( !!this.state.errors && !!this.state.errors[err] ) )
																	.length
																=== 0
															) {
																// non ci sono errori di validazione
																return saveGoal( Object.assign({}, this.props.requirement, this.state ), this.props.collect.id, this.props.level );
															}
															else return Promise.reject( this.state.errors );
														}}
														onResult={ result => {
															this.props.onSave();
														}}
														onError={ e => {
															errorlog("errore al salvataggio di un obiettivo", e);
															this.setState({
																...this.state,
																errors: {
																	...this.state.error,
																	...e
																}
															});
														}}
														buttonText={ translate("Save") }
													/>

													<ActionButton
														color={"link"}
														buttonText={ translate("Delete") }
														waitingMessage={ translate("InDeleting") }
														promise={ () => {
															return Promise.resolve();
														}}
														onResult={ result => {
															this.setState({
																...this.state,
																deleteContext: true
															});
														}}
													/>

												</React.Fragment>
											)
								)


					}

				</ModalFooter>
			</Modal>


		);

		return content;
	}


}

