import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import { Redirect, Link } from 'react-router-dom';
import {PATH_PREFIX} from "../../../../env";
import {loadGriStructure, saveNewCollect} from "../../../../business-logic/gri";
import Spinner from "../../../Spinner";
import {errorlog, showlog} from "../../../../utils/dev-utils";
import { saveAs } from 'file-saver';
import {exportSeedTableToBusinessCSV} from "../../../../business-logic/tree-manager";
import ActionButton from "../../../save-button";
import {validationAdminNewUser} from "../../../../business-logic/companies";
import {editUserByAdmin, saveNewUserByAdmin} from "../../../../business-logic/users";

export const RecapStep = props => {

	// const [date, setDate] = useState(new Date());
	const [inWait, wait] = useState( false );
	const [isRedirectToDashboard, redirectToDashboard] = useState();

	/*
	useEffect( () => {
			if( !!isRedirectToDashboard ) {
				return <Redirect to="/srtool-admin-dashboard" />;
			}
		},
		[isRedirectToDashboard]
	);
	*/

	if( !!isRedirectToDashboard ) {
		return <Redirect to="/srtool-admin-dashboard" />;
	}

	return (
		<React.Fragment>

			<Row>
				<Col>
					<div className="page-title-box">
						<div className="page-title-right">
							<Button
								color={"link"}
								onClick={clickEvent => {
									props.previousStep();
								}}
							>
								Indietro
							</Button>
							&nbsp;&nbsp;&nbsp;&nbsp;


							<ActionButton
								buttonText={ "Dashboard" }
								promise={ () => {
									return (
										new Promise((success, fail) => {
											success( redirectToDashboard( true ) );
										})
									);
								}}
							/>
							{/*
							<Link
								className={"custom-button-style"}
								color={"primary"}
								onClick={clickEvent => {
									props.previousStep();
								}}
								to={PATH_PREFIX + "/dashboard"}
							>
								Dashboard
							</Link>
							*/}
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>

					<div>
						Configurazione e set dei valori finiti!
					</div>


					<Button
						color="success"
						onClick={ clickEvent => {
							wait( true );

							showlog(props);

							loadGriStructure( props.collect, 0 )
								.then(data => {
									showlog("Dati arrivati");
									showlog(data);

									exportSeedTableToBusinessCSV(data)
										.then( fileContent => {

											let file = new Blob([fileContent], {type: "text/csv;charset=utf-8"});
											// URL.createObjectURL(file);
											saveAs( file, "Raccolta "+ props.collectName);

											wait( false );

										})
									;

								})
								.catch( e => {
									errorlog("load gri-structure", e);
								})
						}}
					>
						{
							(!!inWait)
								? (
									<React.Fragment>
										<Spinner color={"#fffa"} className="spinner-border-sm mr-1" />
										<span>&nbsp;&nbsp;in download...&nbsp;&nbsp;</span>
									</React.Fragment>
								)
								: "Export CSV"
						}
					</Button>


				</Col>
			</Row>

		</React.Fragment>
	);
};
