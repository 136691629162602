import {beautify, errorlog} from "../../../../utils";
import ActionButton from "../../../../components/save-button";
import {convertTableToTree, convertToVirtualTree} from "../../../../business-logic/tree-manager";
import React, {useState} from "react";
import "./index.css";

export const TableToTreeTestPage = props => {

    const [tree, setTree] = useState();
    const [e, setE] = useState();

    return (
        <div className={"test-page"}>


            <div className={"test-page-comanders"}>
                <div>
                    <h4>raw data</h4>
                    <ActionButton
                        promise={ () => {
                            let rawdata = document.getElementById("rawTableData").value || "[]";
                            if( rawdata === "[]" ) return Promise.resolve( JSON.parse( rawdata ) );
                            let raw = JSON.parse( rawdata );
                            return (
                                convertTableToTree( raw )
                                    .then( tree => {
                                        // return Promise.resolve({});
                                        return convertToVirtualTree(tree, raw);
                                    })
                                    .catch( e => Promise.reject( e ) )
                            )
                        }}
                        onResult={ result => {
                            setTree( result );
                        }}
                        onError={ error => {
                            errorlog("Conversione", error);
                            setE( String( error ) );
                        }}
                        buttonText={"convert"}
                        className={"test-page-comanders-button"}
                    />
                </div>
                <textarea
                    id={"rawTableData"}
                />
            </div>
            <div className={"test-page-tree"}>
                <code>Converted</code>
                {
                    !!e
                        ? (
                            <span className={"test-page-error"}>{ e }</span>
                        )
                        : (
                            !!tree
                                ? <pre>{ beautify( tree ) }</pre>
                                : null
                        )
                }
            </div>
            <div className={"test-page-result"}>
                <span>Anteprima</span>
                <div></div>
            </div>



        </div>
    );

}
