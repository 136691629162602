import {translate} from "../components/i18n/translate-function";


export const GRI_ADMIN_TREE_DEEP_LEVELS_NAME = [
    "tab",
    "chapter",
    "info",
    "req",
    "sub",
    "subSub"
];

export const GRI_ADMIN_DATA_TREE_DEEP_LEVELS_NAME = [
    "tabs",
    "chapters",
    "infos",
    "requirements",
    "subReqs",
    "subSub"
];


export const SIRATING_TREE_DEEP_LEVELS_NAME = [
    "macroarea",
    "tematica",
    "sotto-tematica",
    "domanda"
];



export const TREE_STATUS_PATH = "treeStatusPath";
export const TREE_REF = "treeRef";
export const NEW_ZONE_REQUIREMENT_TYPE = 2;



export const TREE_TYPE = {
    SET:                { id: 1, name: "Configurazione",                    step: 1 },
    READ_ONLY:          { id: 4, name: "Configurazione ( sola lettura )"            },
    MATERIAL_THEMES:    { id: 2, name: "Tematiche Materiali",               step: 2 },
    VALUE_ENTRY:        { id: 3, name: "Inserimento valori",                step: 3 },
    RECAP:              { id: 5, name: "Recap",                             step: 4 }
};

export const TREE_SECTION = {
    CONFIGURATION:              "conf",
    READ_ONLY_CONFIGURATION:    "readonlyconf",
    MATERIAL_THEMES:            "materialthemes",
    DATA_ENTRY:                 "values",
    RECAP:                      "recap"
};


export const AVOID_NEW_COLLECT_ROLE_NAMES = [
    "GriManager"
];



export const NO_MEASUREMENT_UNIT =      "description";
export const VOID_MEASUREMENT_UNIT =    "description";
export const NUMERIC_MEASUREMENT_UNIT = "numeric";



export const MATERIALITY_REASON_LEVEL = [
    { value: 0, label: "NA" },
    { value: 0.75, label: "Bassa" },
    { value: 2, label: "Media" },
    { value: 3, label: "Alta" },
];


export const MEASURE_UNIT_ID_DATE = 8;



export const COLLECT_STATUS = {
    virgin:             { id: 0,   label: translate("Configuration phase") },
    confirmed:           { id: 10,  label: translate("Collection stage") },
    inAssurance:        { id: 30,  label: "Raccolta Completata" },
    assuranceDone:      { id: 40,  label: "Assurance Completato" }
}

export const INFORMATIVE_STATUS = {
    virgin:         { id: 0,  label: translate("To Complete"),                           operator: false,  adminLabel: translate("To Complete")},
    completed:      { id: 5,  label: "Da Validare",                             operator: false,  adminLabel: translate("Complete")},
    validated:      { id: 10, label: "Validate",                                operator: true,   adminLabel: "Validata"},
    notCompliant:   { id: 20, label: "Richieste di chiarimento o modifiche",     operator: true,   adminLabel: "Richieste di chiarimento o modifiche"},
    inWait:         { id: 25, label: "In attesa di validazione",                operator: false,  adminLabel: "In attesa di validazione"}
};

export const GRI_VERSION = {
    oldVersion: { id: 0, name: "", label: "GRI 2019" },
    newVersion: { id: 1, name: "", label: "GRI 2021" }
}
