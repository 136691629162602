/**
 * Genera un nuero casuale univoco o una stringa in caso di prefisso e/o postfisso
 * @param prefix prefisso
 * @param postfix postfisso
 * @returns {*}
 */
export let generateRandomUniqueNumber = (prefix, postfix) => {
    let timeCode = parseInt(
        (new Date()).getTime() +""+ ((new Date()).getTime() % 19255) +""+ Math.floor( Math.random() * Math.random() * 1000 / 11 )
    );
    return (
        (prefix || "") + timeCode + (postfix || "")
    );
};

/**
 * Controlla se l'elemento passato è un numero
 * @param toCheck
 * @returns {boolean}
 */
export let isNumber = toCheck => {
    if( !!!toCheck && toCheck !== 0 ) return false;
    return(
        !!(
            (new String( toCheck )).toString().trim()       // conversione in stringa non vuota
        ) &&
        !isNaN( toCheck )   // è un numero
    );
};

/**
 * Genera una stringa casuale
 * @returns {string}
 */
export const generateTBasedUniqueCode = () => {
    const charSet = "ABEFGHJKLMNPRTUVXY0";
    let now = (new Date()).getTime();
    let code = "";
    let safe = 0;
    while( now > 0 && safe < 20 ) {
        let index = (now % 10);
        index *= ( 1 + (safe % 2 === 0) );
        code += charSet.substr( index, 1);
        code += ( safe === 4 ) ? "-" : "";
        now = Math.floor(now / 10 );
        safe++;
    }
    return code;
};

/**
 * Genera un nuoro casuale univoco ogni volta più grande
 * @returns {number}
 */
export const generateUniqueAlwaysGreaterInteger = () => {
    return Math.floor( (new Date()).getTime() / 1000 ) - 1592583922;
};


/**
 *
 * @param password
 * @returns {Promise<unknown>}
 */
export const encrypt = password => {

    return new Promise( (success, fail) => {

        if( !!!password ) success( "" );
        else {
            let passwordCrypted = "";
            for (let i = 0; i < password.length; i++) {
                let byte = password[i].charCodeAt(0);
                byte = ( (((byte-5)*2)+8)*3 ) & 0xffff;
                byte=byte.toString(10).padStart(5, '0');
                passwordCrypted += byte + "";
            }
            success( passwordCrypted );
        }

    });


}
