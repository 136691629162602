import {
    ChoicheQuestions_Add,
    ChoicheQuestions_Update,
    Question_ADD,
    Question_UPDATE,
    QuestionModel_GET,
    QuestionModel_SiScoring_GET,
    Questions_GET
} from "./request-manager";

export const loadQuestion = (id, isSR) => {

    if( !!id ) {
        return Questions_GET( id );
    }
    else {


        // chiamata per il modello
        if( !!isSR ) {
            return (
                QuestionModel_GET()
                    .then( res => {
                        res.sdgList = [
                            {
                                "id": 1,
                                "name": "NO POVERTY",
                                "enable": false
                            },
                            {
                                "id": 2,
                                "name": "ZERO HUNGER",
                                "enable": false
                            },
                            {
                                "id": 3,
                                "name": "GOOD HEALTH AND WELL-BEING",
                                "enable": false
                            },
                            {
                                "id": 4,
                                "name": "QUALITY EDUCATION",
                                "enable": false
                            },
                            {
                                "id": 5,
                                "name": "GENDER EQUALITY",
                                "enable": false
                            },
                            {
                                "id": 6,
                                "name": "CLEAN WATER AND SANITATION",
                                "enable": false
                            },
                            {
                                "id": 7,
                                "name": "AFFORDABLE AND CLEAN ENERGY",
                                "enable": false
                            },
                            {
                                "id": 8,
                                "name": "DECENT WORK AND ECONOMIC GROWTH",
                                "enable": false
                            },
                            {
                                "id": 9,
                                "name": "INDUSTRY, INNOVATION AND INFRASTRUCTURE",
                                "enable": false
                            },
                            {
                                "id": 10,
                                "name": "REDUCED INEQUALITIES",
                                "enable": false
                            },
                            {
                                "id": 11,
                                "name": "SUSTAINABLE CITIES AN COMMUNITIES",
                                "enable": false
                            },
                            {
                                "id": 12,
                                "name": "RESPONSIBLE CONSUMPTION AND PRODUCTION",
                                "enable": false
                            },
                            {
                                "id": 13,
                                "name": "CLIMATE ACTION",
                                "enable": false
                            },
                            {
                                "id": 14,
                                "name": "LIFE BELOW WATER",
                                "enable": false
                            },
                            {
                                "id": 15,
                                "name": "LIFE ON LAND",
                                "enable": false
                            },
                            {
                                "id": 16,
                                "name": "PEACE, JUSTICE, AND STRONG INSTITUTIONS",
                                "enable": false
                            },
                            {
                                "id": 17,
                                "name": "PARTNERSHIP FOR THE GOALS",
                                "enable": false
                            }
                        ];
                        return res;
                    })
                    .catch( e => Promise.reject( e ) )
            )  // 1
        }
        else {
            return (
                QuestionModel_SiScoring_GET()
                    .then( res => {
                        res.sdgList = [
                            {
                                "id": 1,
                                "name": "NO POVERTY",
                                "enable": false
                            },
                            {
                                "id": 2,
                                "name": "ZERO HUNGER",
                                "enable": false
                            },
                            {
                                "id": 3,
                                "name": "GOOD HEALTH AND WELL-BEING",
                                "enable": false
                            },
                            {
                                "id": 4,
                                "name": "QUALITY EDUCATION",
                                "enable": false
                            },
                            {
                                "id": 5,
                                "name": "GENDER EQUALITY",
                                "enable": false
                            },
                            {
                                "id": 6,
                                "name": "CLEAN WATER AND SANITATION",
                                "enable": false
                            },
                            {
                                "id": 7,
                                "name": "AFFORDABLE AND CLEAN ENERGY",
                                "enable": false
                            },
                            {
                                "id": 8,
                                "name": "DECENT WORK AND ECONOMIC GROWTH",
                                "enable": false
                            },
                            {
                                "id": 9,
                                "name": "INDUSTRY, INNOVATION AND INFRASTRUCTURE",
                                "enable": false
                            },
                            {
                                "id": 10,
                                "name": "REDUCED INEQUALITIES",
                                "enable": false
                            },
                            {
                                "id": 11,
                                "name": "SUSTAINABLE CITIES AN COMMUNITIES",
                                "enable": false
                            },
                            {
                                "id": 12,
                                "name": "RESPONSIBLE CONSUMPTION AND PRODUCTION",
                                "enable": false
                            },
                            {
                                "id": 13,
                                "name": "CLIMATE ACTION",
                                "enable": false
                            },
                            {
                                "id": 14,
                                "name": "LIFE BELOW WATER",
                                "enable": false
                            },
                            {
                                "id": 15,
                                "name": "LIFE ON LAND",
                                "enable": false
                            },
                            {
                                "id": 16,
                                "name": "PEACE, JUSTICE, AND STRONG INSTITUTIONS",
                                "enable": false
                            },
                            {
                                "id": 17,
                                "name": "PARTNERSHIP FOR THE GOALS",
                                "enable": false
                            }
                        ];
                        return res;
                    })
                    .catch( e => Promise.reject( e ) )
            )
        }

        /*
        return (
            Promise.resolve({
                    "id": 825,
                    "code": "E1-1",
                    "score": 1,
                    "alive": true,
                    "isMultipleAnswer": false,
                    "srMacroareaId": 4,
                    "srThematicId": 20,
                    "srSubthematicId": 0,
                    "description": {
                        "it": "Adottate un sistema di gestione ambientale? modificato",
                        "en": "One"
                    },
                    "guidelines": {
                        "it": "Prova",
                        "en": ""
                    },
                    "sdgList": [
                        {
                            "id": 1,
                            "name": "NO POVERTY",
                            "enable": false
                        },
                        {
                            "id": 2,
                            "name": "ZERO HUNGER",
                            "enable": false
                        },
                        {
                            "id": 3,
                            "name": "GOOD HEALTH AND WELL-BEING",
                            "enable": false
                        },
                        {
                            "id": 4,
                            "name": "QUALITY EDUCATION",
                            "enable": false
                        },
                        {
                            "id": 5,
                            "name": "GENDER EQUALITY",
                            "enable": false
                        },
                        {
                            "id": 6,
                            "name": "CLEAN WATER AND SANITATION",
                            "enable": false
                        },
                        {
                            "id": 7,
                            "name": "AFFORDABLE AND CLEAN ENERGY",
                            "enable": false
                        },
                        {
                            "id": 8,
                            "name": "DECENT WORK AND ECONOMIC GROWTH",
                            "enable": false
                        },
                        {
                            "id": 9,
                            "name": "INDUSTRY, INNOVATION AND INFRASTRUCTURE",
                            "enable": false
                        },
                        {
                            "id": 10,
                            "name": "REDUCED INEQUALITIES",
                            "enable": false
                        },
                        {
                            "id": 11,
                            "name": "SUSTAINABLE CITIES AN COMMUNITIES",
                            "enable": false
                        },
                        {
                            "id": 12,
                            "name": "RESPONSIBLE CONSUMPTION AND PRODUCTION",
                            "enable": false
                        },
                        {
                            "id": 13,
                            "name": "CLIMATE ACTION",
                            "enable": false
                        },
                        {
                            "id": 14,
                            "name": "LIFE BELOW WATER",
                            "enable": false
                        },
                        {
                            "id": 15,
                            "name": "LIFE ON LAND",
                            "enable": false
                        },
                        {
                            "id": 16,
                            "name": "PEACE, JUSTICE, AND STRONG INSTITUTIONS",
                            "enable": false
                        },
                        {
                            "id": 17,
                            "name": "PARTNERSHIP FOR THE GOALS",
                            "enable": false
                        }
                    ],
                    "companySize": 0,
                    "transparency": false,
                    "resilience": false,
                    "improvement": false,
                    "thematicsQuestionList": [
                        {
                            "thematic_id": 35,
                            "thematic_name": "Ambiente",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": true,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 36,
                            "thematic_name": "Energia e Clima",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 37,
                            "thematic_name": "Risorse e Rifiuti",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 23,
                            "thematic_name": "Condizioni di lavoro, salute e sicurezza ",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 24,
                            "thematic_name": "Rapporti e Sviluppo della Comunità",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 25,
                            "thematic_name": "Sicurezza e Qualità del Prodotto",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 26,
                            "thematic_name": "Strategia CSR",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 27,
                            "thematic_name": "Etica",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        },
                        {
                            "thematic_id": 28,
                            "thematic_name": "Innovazione",
                            "subthematic_id": 0,
                            "sunthematic_name": null,
                            "isBelongThematic": false,
                            "isBelongSubthematic": false
                        }
                    ]
                })
        );

         */
    }




}


export const updateQuestion = question => Question_UPDATE( question.id, question );

export const updateChoiche = (choicheID, choiche) => ChoicheQuestions_Update( choicheID, choiche );
export const saveNewChoiche = (fatherQuestionID, choiche) => ChoicheQuestions_Add( fatherQuestionID, choiche );


export const storeNewQuestion = question => Question_ADD( question );
