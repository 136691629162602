import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {CALENDAR_MOMENT_DATE_FORMAT_YEARS, DATE_FORMAT_IT, PATH_PREFIX, permissions, TREE_TYPE} from "../../../env";
import moment from "moment";
import {loadCollect} from "../../../business-logic/gri";
import AddGriCollectModal from "../../../components/area/gri-admin/add-gri-collect-modal";
import Spinner from "../../../components/Spinner";
import {Redirect} from "react-router-dom";
import {hasPermission} from "../../../business-logic/auth";
import {errorlog, showlog} from "../../../utils/dev-utils";
import {translate} from '../../../components/i18n/translate-function';

const fieldMap = {
	id: "Id",
	// dateCreate: "Creata",
	dateStart: "Inizio",
	dateEnd: "Fine",
	// dateUpdate: "Aggiornata",
	// reportType: "Tipo Report",
	// timing: "Annuale",
	// userCreate: "Utente creato",
	// userUpdate: "Utente Aggiornato"
	// conf: "  ",
	// values: " "
	// " ": " "
};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Raccolte
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];

const sortableFields = [
	"Inizio"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
	);
}

const { SearchBar } = Search;








export default class AdminCollects extends Component {

	state = {
		data: null,
		tabToggle: null,
		navigationSteps: [
			{ label: 'SR Tool', path: PATH_PREFIX +'/welcome' },
			{ label: translate("Collects"), active: true }
		],
		treeType: TREE_TYPE.SET.id
	}

	constructor(props) {
		super( props );

		this.loadData = this.loadData.bind( this );

	}


	openTabs = {
		renderer: row => {
			showlog("apertura tabs");
			showlog(row);

			return (
				<Redirect
					to={{
						pathname: PATH_PREFIX +"/srtool/" + translate("collect") + "/"+ row.id,
						state: row
					}}
				/>
			);

		},
		showExpandColumn: false,
		onlyOneExpanding: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
			// return null;
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
			// return null;
		},
	};



	render() {


		showlog("state");
		showlog(!!this.state.data);
		showlog(this.state.data);
		showlog(this.state);


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={ this.state.navigationSteps }
					title={'Raccolte'}
				/>




				<Card>
					<CardBody>


						<Row>
							<Col>
								<div className="page-title-box">
									<div className="page-title-right">

										{
											( !!hasPermission( permissions.CREATE_NEW_COLLECT ) )
												? (
													<Button
														color={"success"}
														onClick={ clickEvent => {
															showlog("click nuovo utente onClick");
															this.setState({
																	...this.state,
																	newCollectModal: true,
																	tree: null,
																	waitForNewCollect: true
																},
																this.loadData
															);
														}}
													>
														{
															(!!this.state.waitForNewCollect)
																? (<Spinner color={"success"} />)
																: (
																	<React.Fragment>
																		<i className="mdi mdi-playlist-plus"></i> Nuova Raccolta
																	</React.Fragment>
																)
														}
													</Button>
												)
												: null
										}



									</div>
									<h4 className="page-title">{translate("Collects List")}</h4>
								</div>
							</Col>
						</Row>



						{
							(!!this.state.data && !!this.state.data.length && !this.state.tree)
								? (



									<ToolkitProvider
										bootstrap4
										keyField="Id"
										search
										exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
										{props => (
											<React.Fragment>
												<Row>
													<Col>
														<SearchBar {...props.searchProps} />
													</Col>
													{/*
													<Col className="text-right">
														<ExportCSVButton {...props.csvProps} className="btn btn-primary">
															Export CSV
														</ExportCSVButton>
													</Col>
													*/}
												</Row>


												<BootstrapTable
													bootstrap4
													striped
													{...props.baseProps}
													size="sm"
													responsive
													keyField="id"
													data={ this.state.data }
													columns={
														formatColumns(
															Object.keys( this.state.data[0] )
																.filter( field => Object.keys(fieldMap).includes( field ) )
														)
													}
													defaultSorted={ defaultSorted }
													pagination={ paginationFactory(paginationOptions) }
													wrapperClasses="table-responsive"
													expandRow={ this.openTabs }
												/>


											</React.Fragment>
										)}
									</ToolkitProvider>




								)
								: <div>Nessuna Raccolta</div>

						}



					</CardBody>
				</Card>

				{
					(!!this.state.newCollectModal)
						? (
							<AddGriCollectModal
								onClose={ () => {
									this.setState({
											...this.state,
											newCollectModal: null
										},
										() => {
											this.loadData();
										}
									)
								}}
							/>
						)
						: null
				}


			</React.Fragment>
		);
	}



	componentDidMount() {
		this.loadData();
	}


	loadData() {


		if( !!hasPermission( permissions.GET_COLLECT ) ) {
			loadCollect()

			// time format  -------------------------------------------------------------------------------------------
				.then( collects => {
					return (
						collects
							.map(collect => {

								collect.dateCreate = moment(collect.dateCreate).format(DATE_FORMAT_IT);
								collect.dateEnd = moment(collect.dateEnd).format(DATE_FORMAT_IT);
								collect.dateStart = moment(collect.dateStart).format(DATE_FORMAT_IT);
								collect.dateUpdate = moment(collect.dateUpdate).format(DATE_FORMAT_IT);
								collect.userCreate = moment(collect.userCreate).format(DATE_FORMAT_IT);
								collect.userUpdate = moment(collect.userUpdate).format(DATE_FORMAT_IT);

								collect.Inizio = moment(collect.dateStart.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);
								collect.Fine = moment(collect.dateEnd.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);

								return collect;
							})
					);
				})

				// additional fields ------------------------------------------------------------------------------------------

				/*
				// buttons
				.then( responseData => {
					return (
						responseData
							.map( row => {
								row[" "] = (
									<React.Fragment>
										<i
											className="mdi mdi-account-edit-outline comander-icon"
											onClick={ clickEvent => {
												showlog("modifica ", row.id);
												this.setState({...this.state, newUserModal: true, user: row});
											}}
										></i>
										<i
											className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"
											onClick={ clickEvent => {
												showlog("elimina ", row.id);
												this.setState({...this.state, deletePopup: row});
											}}
										></i>
									</React.Fragment>
								);
								return row;
							})
					);
				})
				*/

				// field sort   -------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( row => {

								let sortedRow = {};
								Object.keys(fieldMap)
									.map( field => {
										sortedRow[field] = row[field];
									})
								;
								Object.assign(sortedRow, row);
								return sortedRow;

							})
					);
				})

				// row sort -----------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.sort((x,y)=> {
								// if( x.dateEnd < y.dateEnd ) return 1;
								if( x.id < y.id ) return 1;
								return -1;
							})
					)
				})

				// finish   -----------------------------------------------------------------------------------------------
				.then( responseData => {

					showlog("load collect");
					showlog(responseData);


					this.setState(
						{
							...this.state,
							data: responseData,
							waitForNewCollect: null
						},
						() => {

						}
					);
				})
				.catch( e => {
					errorlog("loadCollect", e);
				});
		}



	}


	componentDidUpdate(prevProps, prevState, snapshot ) {
		showlog("UPDATE");
		showlog(prevProps);
		showlog(this.props);
		showlog(prevState);
		showlog(this.state);
		showlog(snapshot);

		if( this.state.nounce !== prevState.nounce ) {
			this.setState({
				...this.state,
				navigationSteps: [
					{ label: 'SR Tool', path: PATH_PREFIX +'/welcome' },
					{ label: translate("Collects"), active: true }
				],
				tree: null
			})
		}

	}
}
