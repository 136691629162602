import {translate} from "../components/i18n/translate-function";

export const SIRATING_TABS = [
    {
        id: 1,
        macroarea_id: 1,
        name: "Environmental",
        themes: [],
        open: true,
        path: [1]
    },
    {
        id: 2,
        macroarea_id: 2,
        name: "Social",
        themes: [],
        open: true,
        path: [2]
    },
    {
        id: 3,
        macroarea_id: 3,
        name: "Governance",
        themes: [],
        open: true,
        path: [3]
    }
];

export const SISCORING_TABS = [
    {
        id: 4,
        macroarea_id: 4,
        name: "Environmental",
        themes: [],
        open: true,
        path: [1]
    },
    {
        id: 5,
        macroarea_id: 5,
        name: "Social",
        themes: [],
        open: true,
        path: [2]
    },
    {
        id: 6,
        macroarea_id: 6,
        name: "Governance",
        themes: [],
        open: true,
        path: [3]
    }
];


export const RATING_STATUS = {
    configurationRunning: 			{ id: 0,  name: translate("Profiling in progress")  			},
    perimetralQuestionCompleted: 	{ id: 3,  name: translate( "Profiling Completed")			},
    perimetralQuestionValidated: 	{ id: 5,  name: translate("Configuration in progress")		},
    configurationComplete: 			{ id: 10, name: translate("Configuration completed")         },
    configurationValidated: 			{ id: 20, name: translate("Validated configuration")          },
    perimetralQuestionAccepted: 	{ id: 25, name: translate("Profiling accepted")				},
    ratingComplete: 				{ id: 30, name: translate("Ongoing audit")             },
    auditComplete: 					{ id: 35, name: translate("Audit completed")               },
    auditDone: 						{ id: 40, name: translate("Validated evaluation")				}
};


/**
 * @deprecated
 * @type {{runningConfiguration: {name: string, id: number}, validationCompleted: {name: string, id: number}, auditCompleted: {name: string, id: number}, runningValidation: {name: string, id: number}, auditDone: {name: string, id: number}}}
 */
export const SRADMIN_RATING_STATUS = {
    runningConfiguration : 	{ id: 0,  name: "Configurazione in corso"            },
    runningValidation: 		{ id: 20, name: "Valutazione in corso"              },
    validationCompleted: 	{ id: 30, name: "Valutazione in corso"              },
    auditCompleted: 		{ id: 35, name: "Audit completato"		            },
    auditDone: 				{ id: 40, name: "Valutazione confermata dall'audit" }
};

/**
 * @deprecated
 * @type {[{name: string, id: number}, {name: string, id: number}, {name: string, id: number}, {name: string, id: number}, {name: string, id: number}]}
 */
export const SRADMIN_QUESTION_STATUS = [
        { name: "Tutte", id: 0 },
        { name: "Domande Completate", id: 1 },
        { name: "In corso", id: 2 },
        { name: translate("To Complete"), id: 3 },
        { name: "Da Rispondere", id: 4 }
    ]
;

export const QUESTION_STATUS = {
    virgin: 				{ id: 0,  name: translate("Questions to be answered"), 				        colorPolicyReferiment: 0, label: "Domanda da rispondere", shortLabel: "Da Risp." },
    incomplete:				{ id: 3,  name: translate("Questions to be completed"), 				        colorPolicyReferiment: 2, label: "Risposta fornita", shortLabel: "Incompleta" },
    finished: 				{ id: 5,  name: translate("Completed Questions"), 					        colorPolicyReferiment: 3, label: "Risposta Salvata", shortLabel: "Finita" },
    validated:				{ id: 10, name: translate("Validated Questions"), 					        colorPolicyReferiment: 4, label: "Domanda validata", shortLabel: "Valida" },
    lowNonCompliant:		{ id: 20, name: translate( "Questions requesting additional information"), 						colorPolicyReferiment: 6, label: "Info Aggiuntive", shortLabel: "Info Richieste" },
    attachmentWaiting:		{ id: 21, name: translate("Questions requesting an attachment"),			    colorPolicyReferiment: 6, label: "Allegato richiesto", shortLabel: "Allegato Richiesto" },
    attachmentToValidate:	{ id: 23, name: translate("Questions with attachments to be validated"), 				colorPolicyReferiment: 6, label: "Allegato da validare", shortLabel: "Allegato in Attesa" },
    waitNonCompliant:		{ id: 25, name: translate("Questions with additional info pending validation"), 	colorPolicyReferiment: 5, label: "Info Aggiuntive in attesa di validazione", shortLabel: "Risposta" },
    highNonCompliant:		{ id: 30, name: translate("Mild non-compliance questions"), 							colorPolicyReferiment: 7, label: "NC grave", shortLabel: "NC Grave" },

    cancelled:		        { id: 35, name: translate("Questions cancelled"), 							colorPolicyReferiment: 11, label: "Annullata", shortLabel: "Annullata" },

    // questionAuditReady:		{ id: 100, name: "Domande NC Grave", 							colorPolicyReferiment: 7, label: "NC grave" },
    // questionToAudit:		{ id: 101, name: "Domande NC Grave", 							colorPolicyReferiment: 7, label: "NC grave" }


};


export const QUESTION_TEXT_COLOR_POLICY = {
    edit: 		{ id: 0, name: "inEdit", 		class: "question-edit-text", 		colorNameTheme: "primary" },
    incomplete: { id: 1, name: "incomplete", 	class: "question-disabled-text", 	colorNameTheme: "danger" },
    pending: 	{ id: 2, name: "pending", 		class: "question-pending-text", 	colorNameTheme: "info" },
    complete: 	{ id: 3, name: "complete", 		class: "question-enabled-text", 	colorNameTheme: "success" },
    disabled:   { id: 4, name: "disabled", 		class: "question-avoid-text", 		colorNameTheme: "secondary" },
    cancelled:  { id: 11, name: "cancelled",     class: "question-cancelled-text", 	colorNameTheme: "light" }
};

export const PERIMETRAL_QUESTION_SECTORS_BELONG = 401;
export const PERIMETRAL_QUESTION_TEXT_ANSWER	= "Descriptive";
export const PERIMETRAL_QUESTION_TO_AVOID	    = [406];

export const SIRATING_ADMIN_CHART_THEMATICS_ENV	= 1;
export const SIRATING_ADMIN_CHART_THEMATICS_SOC	= 2;
export const SIRATING_ADMIN_CHART_THEMATICS_GOV	= 3;
export const SIRATING_ADMIN_CHART_SDG	= 4;

