import React, {Component} from 'react';
import {Card, CardBody, Col, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {PATH_PREFIX, role} from "../../../env";
import {changeConsultantCompany, loadConsulterCompaniesData} from "../../../business-logic/companies";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import StandBySystemModalComponent from "../../../components/area/gri-admin/standby-system-modal";
import Spinner from "../../../components/Spinner";
import {getLastElementOr} from "../../../utils/array-utils";
import {translate} from '../../../components/i18n/translate-function';

const fieldMap = {
	companyId: "Id",
	companyName: "Ragione Sociale"
};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Utenti
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];

const sortableFields = [
	"Ragione Sociale"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class ConsulerCompanies extends Component {

	state = {
		data: null
	}

	constructor(props) {
		super( props );
		this.loadData = this.loadData.bind( this );
	}


	render() {

		renderlog("Consuler Companies", this.state, this.props)


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{
							label:
							Object.keys( role )
								.map( roleObjectName => role[ roleObjectName ] )
								.filter( roleObject => roleObject.name === localStorage.getItem("role") )
								.reduce( getLastElementOr, "Nessun Ruolo" )
								.label
							, path: PATH_PREFIX +'/welcome' },
						{ label: translate("Companies"), active: true }
					]}
					title={'Aziende'}
				/>




				{
					(!!this.state.data)
						? (
							<Card>
								<CardBody>

									<Row>
										<Col>
											<div className="page-title-box">
												<div className="page-title-right">
												</div>
												<h4 className="page-title">Lista aziende</h4>
											</div>
										</Col>
									</Row>




									<ToolkitProvider
										bootstrap4
										keyField="Id"
										search
										exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
										{props => (
											<React.Fragment>
												<Row>
													<Col>
														<SearchBar {...props.searchProps} />
													</Col>
													{/*
													<Col className="text-right">
														<ExportCSVButton {...props.csvProps} className="btn btn-primary">
															Export CSV
														</ExportCSVButton>
													</Col>
													*/}
												</Row>


												<BootstrapTable
													bootstrap4
													striped
													{...props.baseProps}
													size="sm"
													responsive
													keyField="Id"
													data={ this.state.data }
													columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
													defaultSorted={ defaultSorted }
													pagination={ paginationFactory(paginationOptions) }
													wrapperClasses="table-responsive"
													selectRow={{
														mode: 'checkbox',
														clickToSelect: false,
														selectionRenderer: row => {


															if( !!row.checked && !row.disabled && !this.state.wait) {


																showlog("riga selezionata");
																showlog(row);

																let selectedElement =
																	this.state.data
																		.filter( company => company.Id === row.rowKey )
																		.reduce( (final, cursor) => Object.assign( {}, final, cursor ), {})
																;

																showlog("SelectedElement");
																showlog(selectedElement);

																this.setState({
																	...this.state,
																	wait: true
																},
																() => {
																		changeConsultantCompany( selectedElement.Id )
																			.then( result => {

																				showlog("result di change company");
																				showlog( result );

																				// localStorage
																				localStorage.setItem("company_id", selectedElement.Id);
																				localStorage.setItem("company_name", selectedElement["Ragione Sociale"]);
																				localStorage.setItem("companyName", selectedElement["Ragione Sociale"]);


																				window.location.href = window.location.href;
																			})
																			.catch(e => {
																				errorlog("error",e);


																				showlog( e );
																				// window.location.href = window.location.href;
																			})
																	}
																)

																return (
																	<React.Fragment>
																		<Spinner color={"primary"} className="spinner-border-sm mr-1" />
																		<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
																	</React.Fragment>
																);


															}




															return (
																<div className="custom-control custom-checkbox">
																	<input
																		type="checkbox"
																		className="custom-control-input"
																		checked={row.checked}
																		disabled={row.disabled}
																	/>
																	<label
																		className="custom-control-label"
																		onClick={e => {
																			e.preventDefault();
																		}}/>
																</div>
															);

														},
														selectionHeaderRenderer: ({indeterminate, ...rest}) => {
															return null;
														}
													}}
												/>


											</React.Fragment>
										)}
									</ToolkitProvider>

								</CardBody>
							</Card>
						)
						: null
				}


				{
					(!!this.state.wait)
						? <StandBySystemModalComponent/>
						: null
				}


			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
	}


	loadData() {
		loadConsulterCompaniesData( fieldMap )

			// field sort ---------------------------------------------------------------------------------------------
			.then( data => {

				console.log("data after extras");
				console.log( data );

				return (
					data
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							// Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})

			// field map ----------------------------------------------------------------------------------------------
			.then( list => {

				console.log("data after field map");
				console.log( list );

				return (
					list
						.map( company => {
							let dto = {};
							Object.keys( company )
								.forEach( rawField => {
									dto[ fieldMap[ rawField ] ] = company[ rawField ];
								})
							;
							return dto;
						})
				);

			})



			// finish -------------------------------------------------------------------------------------------------
			.then( data => {
				// console.log("data after field sort");
				// console.log( data );


				this.setState({
					...this.state,
					data: data
				})
			})

	}






}
