import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    CustomInput,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledCollapse
} from 'reactstrap';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {loadAllSectors, loadAllThemes, loadCompaniesData} from "../../../business-logic/companies";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import {addNewQuestionSector, disableSectorQuestion, editNewQuestionSector} from "../../../business-logic/rating";
import ActionButton from "../../save-button";
import {cloneObject} from "../../../utils/object-utils";
import {getSectorCompanies, loadSectorQuestionsDimensions} from "../../../business-logic/question-sectors";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {COLOR_POLICY, errorMessage, permissions, QUESTION_TEXT_COLOR_POLICY} from "../../../env";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {hasPermission} from "../../../business-logic/auth";
import {loadSdg} from "../../../business-logic/sdg";
import classnames from "classnames";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {emoj, extractErrorMessage} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {translate} from "../../i18n/translate-function";

const allRequiredFields = [
    //"code",
    "q_it",
    "q_en",
    "score",
    "selectedSector",
    "selectedThemes"
];


const fieldMap = {
    ragioneSociale: "Ragione Sociale",
    localita: "Città",
    indirEmail: "Email"
};


const hiddenFields = [
    "categoria",
    "classeContabile",
    "codCliFor",
    "dimension",
    "dimensionId",
    "dimensionName",
    "dimensioni",
    "id",
    "indirizzo",
    "partitaIva",
    "provincia",
    "provincia_descrizione",
    "tipologiaCommerciale",
    "leadingCompany",
    "employeesNumbersLevel"
];


const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Aziende
        </span>
    ),
    sizePerPageList: [5]
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];


const sortableFields = [
    "ragioneSociale",
    "localita"
];




const formatColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldMap[field] || field,
                    sort: sortableFields.includes(field)
                });
            })
            .filter(ff => !hiddenFields.includes(ff.dataField))
    );
}




const fieldSdgMap = {
    id: "Id",
    name: "Nome",
};


const hiddenSdgFields = [
    "srSdgQuestions",
    "srSdgQuestionsStructure",
    "srReportsSdg"
];



const sortableSdgFields = [
    "Nome"
];

const formatSdgColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields.includes(ff.dataField))
    );
}

const {SearchBar} = Search;

export default class AddSectorQuestionModal extends Component {

    state = {
        multiAnswer: (!!this.props.question) ? !!this.props.question.isMultipleAnswer : false,
        code: (!!this.props.question) ? this.props.question.code : "",
        q_it: (!!this.props.question) ? this.props.question.name_it : "",
        q_en: (!!this.props.question) ? this.props.question.name_en : "",
        score: (!!this.props.question) ? this.props.question.score : 0,
        selectedDimension: (!!this.props.question) ? this.props.question.companySize : 0,
        selectedCompanies: [],
        attachmentGuideLines: (!!this.props.question) ? this.props.question.attachmentsGuidelines : "",
        questionGuidelines: (!!this.props.question) ? this.props.question.questionGuidelines : "",
        selectedSdg: [],
        transparency: (!!this.props.question) ? this.props.question.transparency : false,
        resilience: (!!this.props.question) ? this.props.question.resilience : false,
        improvement: (!!this.props.question) ? this.props.question.improvement : false,
        gendergap: (!!this.props.question) ? this.props.question.gendergap : false,
        isDisabled: (!!this.props.question ? !this.props.question.alive : false)
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
        this.isReadyForSave = this.isReadyForSave.bind(this);
    }


    toggle = () => {
        if (!this.state.waiting) this.props.onClose();
    }


    isReadyForSave() {

        let levelOne =

            Object.keys(this.state)

            	// controllo esistenza campi
                .filter(k => allRequiredFields.includes(k))

                // controllo campi validi
                .filter(k => !!this.state[k])

                // controllo se sono tutti
                .length === allRequiredFields.length

        ;

        let levelTwo =
            (!!this.state.selectedThemes && !!this.state.selectedThemes.subThemes && this.state.selectedThemes.subThemes.length > 0)
                ? !!this.state.selectedSubThemes
                : true
        ;

        let levelThree = !this.state.errorMessage;

        showlog("isReadyForSave", levelOne, levelTwo, levelThree, (levelOne && levelTwo && levelThree));
        return (levelOne && levelTwo && levelThree);
    }


    render() {

        renderlog("AddSectorQuestionModal", this.state, this.props, COLOR_POLICY.validated);

        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm"/>
                <span className={"loading-text-alignment"}>Caricamento...</span>
            </React.Fragment>
        );


        /*
        la domanda ha già delle aziende selezionate
            si
                policy = success
            no
                in edit
                    si
                        policy = primary
                    no
                        policy = secondary
        */
        let companiesClassPolicy = "";
        companiesClassPolicy =
            (!!this.state.companiesInEdit)
                ? QUESTION_TEXT_COLOR_POLICY.edit.class
                : (
                    !!isNotEmptyArray(this.state.selectedCompanies)
                        ? QUESTION_TEXT_COLOR_POLICY.complete.class
                        : (
                            !!isNotEmptyArray(this.state.companies)
                                ? QUESTION_TEXT_COLOR_POLICY.disabled.class
                                : [QUESTION_TEXT_COLOR_POLICY.disabled.class, "upload-error-text"].join(" ")
                        )
                )
        ;


        context = (
            <AvForm>

                {/* ---- CONFIGURAZIONE ------------------------------------------------------------------------------------------------------------ */}
                <Card className="mb-0">
                    <CardHeader className={""}>
                        <NavLink
                            id={"textSection"}
                            href="#"
                            onClick={clickEvent => {
                                this.setState({
                                    ...this.state,
                                    textSection: !!!this.state.textSection,
                                    activeTab: "domande-tab"
                                })
                            }}
                        >
                            <div
                                className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 small-accordion-title "}>
                                <strong className={ (!!this.state.textInEdit) ? COLOR_POLICY.edit.textClass : "" }>Configurazione</strong>
                            </div>
                            <span className={"float-right"}>
                                    {
                                        (!!this.state.textSection)
                                            ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                            : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                    }
                            </span>
                        </NavLink>
                    </CardHeader>
                    <UncontrolledCollapse toggler={"#textSection"}>
                        <CardBody>


                            <Nav tabs>

                                <NavItem key={0}>
                                    <NavLink
                                        id={"domande-tab"}
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === "domande-tab" })}
                                        onClick={() => {

                                            this.setState({
                                                ...this.state,
                                                activeTab: "domande-tab"
                                            });
                                        }}>
                                        <i
                                            className={classnames(
                                                'd-lg-none',
                                                'd-block',
                                                'mr-1'
                                            )} />
                                        <span className="d-none d-lg-block">Testi</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem key={1}>
                                    <NavLink
                                        id={"tematiche-tab"}
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === "tematiche-tab" })}
                                        onClick={() => {

                                            this.setState({
                                                ...this.state,
                                                activeTab: "tematiche-tab"
                                            });
                                        }}>
                                        <i
                                            className={classnames(
                                                'd-lg-none',
                                                'd-block',
                                                'mr-1'
                                            )} />
                                        <span className="d-none d-lg-block">Tematiche</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem key={2}>
                                    <NavLink
                                        id={"score-tab"}
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === "score-tab" })}
                                        onClick={() => {

                                            this.setState({
                                                ...this.state,
                                                activeTab: "score-tab"
                                            });
                                        }}>
                                        <i
                                            className={classnames(
                                                'd-lg-none',
                                                'd-block',
                                                'mr-1'
                                            )} />
                                        <span className="d-none d-lg-block">Punteggi</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem key={2}>
                                    <NavLink
                                        id={"attachment-tab"}
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === "attachment-tab" })}
                                        onClick={() => {

                                            this.setState({
                                                ...this.state,
                                                activeTab: "attachment-tab"
                                            });
                                        }}>
                                        <i
                                            className={classnames(
                                                'd-lg-none',
                                                'd-block',
                                                'mr-1'
                                            )}
                                        />
                                        <span className="d-none d-lg-block">{ translate("Attachments") }</span>
                                    </NavLink>
                                </NavItem>

                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>


                                <TabPane
                                    tabId={"domande-tab"}
                                    key={0}
                                    className={"custom-tab-pane"}
                                >
                                    {/*     QUESTION  -------------------------------------------------------------------------------- */}



                                    {/* <AvGroup>
                                        <Label for="code">Codice</Label>
                                        <AvInput
                                            className={"sector-question-text " + ((!!this.state.code) ? "" : "av-invalid is-invalid")}
                                            type="textarea"
                                            row={64}
                                            name="code"
                                            id="code"
                                            value={this.state.code}
                                            onChange={changeTextEvent => {

                                                this.setState({
                                                    ...this.state,
                                                    code: changeTextEvent.target.value,
                                                    textInEdit: true
                                                })

                                            }}
                                            // required
                                        />
                                    </AvGroup> */}


                                    <AvGroup>
                                        <Label for="q_it">IT</Label>
                                        <AvInput
                                            className={"sector-question-text " + ((!!this.state.q_it) ? "" : "av-invalid is-invalid")}
                                            type="textarea"
                                            row={64}
                                            name="q_it"
                                            id="q_it"
                                            value={this.state.q_it}
                                            onChange={changeTextEvent => {

                                                this.setState({
                                                    ...this.state,
                                                    q_it: changeTextEvent.target.value,
                                                    textInEdit: true
                                                })

                                            }}
                                            required
                                        />
                                    </AvGroup>

                                    <AvGroup>
                                        <Label for="q_en">EN</Label>
                                        <AvInput
                                            type="textarea"
                                            className={((!!this.state.q_en) ? "" : "av-invalid is-invalid")}
                                            row={32}
                                            name="q_en"
                                            id="q_en"
                                            value={this.state.q_en}
                                            onChange={changeTextEvent => {

                                                this.setState({
                                                    ...this.state,
                                                    q_en: changeTextEvent.target.value,
                                                    textInEdit: true
                                                })

                                            }}
                                            required
                                        />
                                    </AvGroup>


                                    <AvGroup>
                                        <Label for="questionGuidelines">{ translate("Guide_Lines") }</Label>
                                        <AvInput
                                            className={"sector-question-text " + ((!!this.state.questionGuidelines) ? "" : "av-invalid is-invalid")}
                                            type="textarea"
                                            row={64}
                                            name="questionGuidelines"
                                            id="questionGuidelines"
                                            value={this.state.questionGuidelines}
                                            onChange={changeTextEvent => {

                                                this.setState({
                                                    ...this.state,
                                                    questionGuidelines: changeTextEvent.target.value,
                                                    textInEdit: true
                                                })

                                            }}
                                            required
                                        />
                                    </AvGroup>

                                    {/*<GotoTopComponent/>*/}

                                </TabPane>

                                <TabPane
                                    tabId={"tematiche-tab"}
                                    key={1}
                                    className={"custom-tab-pane"}
                                >
                                    {/*     TEMATICA  -------------------------------------------------------------------------------- */}
                                    <FormGroup>
                                        <Label for="title">Tematica {(!this.state.themes) ?
                                            <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
                                        <Select
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="themes"
                                            id="themes"
                                            onChange={changeEvent => {

                                                this.setState({
                                                    ...this.state,
                                                    selectedThemes: changeEvent,
                                                    selectedSubThemes: null,     // indipendentemente se la tematica ha sotto-tematica o no
                                                    themesInEdit: true,          // #deprecated
                                                    textInEdit: true
                                                })

                                            }}
                                            options={
                                                !!this.state.themes
                                                    ? this.state.themes
                                                    : []
                                            }
                                            value={this.state.selectedThemes}
                                            placeholder={<span className={"select-placeholder"}>Seleziona Tematica</span>}
                                        />
                                    </FormGroup>


                                    {
                                        (!!this.state.selectedThemes && !!this.state.selectedThemes.subThemes && this.state.selectedThemes.subThemes.length > 0)
                                            ? (
                                                <FormGroup>
                                                    <Label for="subThemes">Sottotematica</Label>
                                                    <Select
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        name="subThemes"
                                                        id="subThemes"
                                                        onChange={changeEvent => {
                                                            showlog("subThemes scelta");
                                                            showlog(changeEvent);


                                                            this.setState({
                                                                ...this.state,
                                                                selectedSubThemes: changeEvent,
                                                                textInEdit: true
                                                            })


                                                        }}
                                                        options={
                                                            !!this.state.selectedThemes
                                                                ? (
                                                                    this.state.selectedThemes.subThemes.map(st => ({
                                                                        ...st,
                                                                        value: st.id,
                                                                        label: st.name
                                                                    }))
                                                                )
                                                                : []
                                                        }
                                                        value={this.state.selectedSubThemes}
                                                        placeholder={<span
                                                            className={"select-placeholder"}>Seleziona Tematica</span>}
                                                    />
                                                </FormGroup>
                                            )
                                            : null
                                    }

                                    {/*<GotoTopComponent/>*/}

                                </TabPane>



                                <TabPane
                                    tabId={"score-tab"}
                                    key={0}
                                    className={"custom-tab-pane"}
                                >
                                    {/*     PUNTEGGIO -------------------------------------------------------------------------------- */}
                                    <AvGroup>
                                        <Label for="score" className={"label-inline-input"}>Punteggio Massimo</Label>
                                        <AvInput
                                            className={"inline-input " + ((!!this.state.score) ? "" : "av-invalid is-invalid")}
                                            type="number"
                                            name="score"
                                            id="score"
                                            value={this.state.score}
                                            onChange={changeTextEvent => {


                                                this.setState({
                                                    ...this.state,
                                                    score: parseFloat(changeTextEvent.target.value),
                                                    textInEdit: true
                                                })

                                            }}
                                            required
                                        />
                                    </AvGroup>

                                    {/*     MULTI RISPOSTA -------------------------------------------------------------------------------- */}
                                    <CustomInput
                                        type="switch"
                                        id={"sectorQuestion_isMultiAnswer"}
                                        name={"sectorQuestion_isMultiAnswer"}
                                        label="Risposta multipla"
                                        className={"sector-question-switch-box " + ((!!this.isReadyForSave()) ? "" : "av-invalid is-invalid")}
                                        onClick={clickEvent => {


                                            showlog("switch cliccato");


                                            if (!!document.getElementById("sectorQuestion_isMultiAnswer")) {
                                                document.getElementById("sectorQuestion_isMultiAnswer").checked = !!!this.state.multiAnswer;
                                            }

                                            this.setState({
                                                ...this.state,
                                                multiAnswer: !!!this.state.multiAnswer,
                                                textInEdit: true
                                            })

                                        }}
                                        // valid={ (!!this.props.question) ? !!this.props.question.isMultipleAnswer : !!this.state.multiAnswer }
                                        defaultChecked={!!this.state.multiAnswer}
                                    />

                                    {/*<GotoTopComponent/>*/}

                                </TabPane>

                                <TabPane
                                    tabId={"attachment-tab"}
                                    key={0}
                                    className={"custom-tab-pane"}
                                >
                                    <AvGroup>
                                        <Label for="attachmentGuideLines">Linee Guida Allegato</Label>
                                        <AvInput
                                            className={"sector-question-text " + ((!!this.state.attachmentGuideLines) ? "" : "av-invalid is-invalid")}
                                            type="textarea"
                                            row={64}
                                            name="attachmentGuideLines"
                                            id="attachmentGuideLines"
                                            value={this.state.attachmentGuideLines}
                                            onChange={changeTextEvent => {

                                                this.setState({
                                                    ...this.state,
                                                    attachmentGuideLines: changeTextEvent.target.value,
                                                    textInEdit: true
                                                })

                                            }}
                                            // required
                                        />
                                    </AvGroup>

                                    {/*<GotoTopComponent/>*/}

                                </TabPane>

                            </TabContent>



                        </CardBody>
                    </UncontrolledCollapse>
                </Card>


                {/* ---- AZIENDA ------------------------------------------------------------------------------------------------------------ */}
                <Card className="mb-0">
                    <CardHeader className={""}>
                        <NavLink
                            id={"companySection"}
                            href="#"
                            onClick={clickEvent => {
                                this.setState({
                                    ...this.state,
                                    companySection: !!!this.state.companySection
                                },
                                () => {
                                    if( !isNotEmptyArray( this.state.companies ) ) {
                                        loadCompaniesData()

                                            // adjust ---------------------------------------------------------------------------------------------------
                                            .then(list => {
                                                return (
                                                    list
                                                        .map(companyRow => {
                                                            // debugger;
                                                            companyRow.dimensionId = companyRow.dimension.id;
                                                            companyRow.dimensionName = companyRow.dimension.name;
                                                            return companyRow;
                                                        })
                                                );
                                            })

                                            // field sort ---------------------------------------------------------------------------------------------
                                            .then(data => {
                                                return (
                                                    data
                                                        .map(row => {
                                                            let sortedRow = {};
                                                            Object.keys(fieldMap)
                                                                .map(field => {
                                                                    sortedRow[field] = row[field];
                                                                })
                                                            ;
                                                            Object.assign(sortedRow, row);
                                                            return sortedRow;
                                                        })
                                                );
                                            })

                                            // aziende già presenti -----------------------------------------------------------------------------------
                                            .then(data => {
                                                return (
                                                    new Promise((success, fail) => {
                                                        if (!!this.props.question) {
                                                            success(
                                                                getSectorCompanies(this.props.question.id)
                                                                    .then(result => {
                                                                        return ({
                                                                            data: data,
                                                                            selectedCompanies: result
                                                                        });
                                                                    })
                                                            )
                                                        } else {
                                                            success({
                                                                data: data,
                                                                selectedCompanies: []
                                                            });
                                                        }
                                                    })
                                                    .then(({data, selectedCompanies}) => {
                                                        return ({
                                                            data: data,
                                                            selectedCompanies: selectedCompanies
                                                        })
                                                    })
                                                )
                                            })

                                            // finish -------------------------------------------------------------------------------------------------
                                            .then(({data, selectedCompanies}) => {

                                                this.setState({
                                                    ...this.state,
                                                    companies: data,
                                                    selectedCompanies: selectedCompanies
                                                })
                                            })
                                    }

                                })
                            }}
                        >
                            <div
                                className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 small-accordion-title "}>
                                <strong className={ (!!this.state.companiesInEdit) ? COLOR_POLICY.edit.textClass : "" }>Aziende</strong>
                            </div>
                            <span className={"float-right"}>
                                    {
                                        (!!this.state.companySection)
                                            ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                            : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                    }
                            </span>
                        </NavLink>
                    </CardHeader>
                    <UncontrolledCollapse toggler={"#companySection"}>
                        <CardBody>
                            {/* LISTA AZIENDE ---------------------------------------------------------------------------------- */}


                            {
                                (!!this.state.companies && this.state.companies.length > 0)
                                    ? (
                                        <ToolkitProvider
                                            bootstrap4
                                            keyField="id"
                                            search
                                        >
                                            {props => (

                                                <React.Fragment>
                                                    <Row>
                                                        <Col>
                                                            <SearchBar {...props.searchProps} />
                                                        </Col>
                                                    </Row>
                                                    <BootstrapTable
                                                        bootstrap4
                                                        striped
                                                        {...props.baseProps}
                                                        size="sm"
                                                        responsive
                                                        keyField="id"
                                                        bordered={false}
                                                        data={this.state.companies}
                                                        columns={formatColumns(Object.keys(this.state.companies[0]))}
                                                        defaultSorted={defaultSorted}
                                                        pagination={paginationFactory(paginationOptions)}
                                                        wrapperClasses="table-responsive"
                                                        selectRow={{
                                                            mode: 'checkbox',
                                                            clickToSelect: true,


                                                            onSelect: row => {
                                                                showlog("riga selezionata su onSelect");
                                                                showlog(row);


                                                                /*
                                                                categoria: "CATEGORIA1"
                                                                classeContabile: "CLASSE CONTABILE1"
                                                                codCliFor: 6
                                                                dimension: {id: 1, name: "Piccola/Media"}
                                                                dimensionId: 1
                                                                dimensionName: "Piccola/Media"
                                                                dimensioni: 1
                                                                id: 6
                                                                indirizzo: "VIA DELLO SBARCO 96"
                                                                localita: "MARSALA"
                                                                partitaIva: "04802500829"
                                                                provincia: "TP"
                                                                provincia_descrizione: "Trapani"
                                                                ragioneSociale: "CLS"
                                                                tipologiaCommerciale: null
                                                                 */

                                                                if (!!this.state.selectedCompanies && !!this.state.selectedCompanies.some(s => s === row.id)) {

                                                                    this.setState({
                                                                        ...this.state,
                                                                        selectedCompanies: this.state.selectedCompanies.filter(s => s !== row.id),
                                                                        companiesInEdit: true
                                                                    });

                                                                } else {

                                                                    this.setState({
                                                                        ...this.state,
                                                                        selectedCompanies: [...this.state.selectedCompanies, row.id],
                                                                        companiesInEdit: true
                                                                    });

                                                                }


                                                            },


                                                            selectionRenderer: row => {

                                                                if (
                                                                    !!(
                                                                        this.state.selectedCompanies
                                                                            .filter(companyId => companyId === row.rowKey /*&& !!row.checked && !row.disabled*/)
                                                                            .reduce(getLastElementOr, null)
                                                                    )
                                                                ) row.checked = true;
                                                                else row.checked = false;


                                                                return (
                                                                    <div
                                                                        className="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            checked={row.checked}
                                                                            disabled={row.disabled}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                            }}/>
                                                                    </div>
                                                                );

                                                            },
                                                            selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                                                return null;
                                                            }
                                                        }}

                                                    />


                                                </React.Fragment>

                                            )}

                                        </ToolkitProvider>
                                    )
                                    : (
                                        <WaitingOrNodataMessage
                                            waiting={ !(!!this.state.companies && this.state.companies.length === 0) }
                                            nodataMessage={emoj(EMOJ.sad_but_relieved_face) +"    Nessuna Azienda"}
                                        />
                                    )
                            }






                            {/*     DIMENSIONE  -------------------------------------------------------------------------------- */}
                            <FormGroup>
                                <Label for="dim">Dimensione Aziende</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="dim"
                                    id="dim"
                                    onChange={changeEvent => {
                                        this.setState({
                                            ...this.state,
                                            selectedDimension: changeEvent
                                        })
                                    }}
                                    options={

                                        (!!this.state.dimensions)
                                            ? (
                                                this.state.dimensions
                                                    .map(d => ({value: d.id, label: d.name}))
                                            )
                                            : []
                                    }
                                    value={
                                        (!!this.state.dimensions)
                                            ? (
                                                this.state.dimensions
                                                    .filter(d => d.id === this.state.selectedDimension.value)
                                                    .map(d => ({value: d.id, label: d.name}))
                                                    .reduce( getLastElementOr, {value: 0, label: "Tutte"})
                                            )
                                            : null
                                    }
                                    placeholder={<span className={"select-placeholder"}>Seleziona Dimensione</span>}
                                />
                            </FormGroup>


                            {/*     SETTORE  -------------------------------------------------------------------------------- */}
                            <FormGroup>
                                <Label for="title">Settore {(!this.state.sectors) ?
                                    <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="sector"
                                    id="sector"
                                    onChange={changeEvent => {
                                        this.setState({
                                            ...this.state,
                                            selectedSector: changeEvent
                                        })
                                    }}
                                    options={this.state.sectors}
                                    value={this.state.selectedSector}
                                    placeholder={<span className={"select-placeholder"}>Seleziona Settore</span>}
                                />
                            </FormGroup>


                        </CardBody>
                    </UncontrolledCollapse>
                </Card>




                {/* ---- SDG ------------------------------------------------------------------------------------------------------------ */}
                <Card className="mb-0">
                    <CardHeader className={""}>
                        <NavLink
                            id={"sdgSection"}
                            href="#"
                            onClick={clickEvent => {
                                this.setState({
                                    ...this.state,
                                    sdgSection: !!!this.state.sdgSection
                                },
                                    () => {

                                        if(!isNotEmptyArray(this.state.sdg)) {
                                            loadSdg()
                                                .then( list => {
                                                    showlog("lista sdg");
                                                    showlog( list );

                                                    if( !!this.props.question && !!this.props.question.id ) {
                                                        return (
                                                            loadSdg( this.props.question.id )
                                                                .then( activeList => {
                                                                    return ({
                                                                        list: list,
                                                                        activeSdg: activeList
                                                                    })
                                                                })
                                                        );
                                                    }
                                                    return ({
                                                        list: list,
                                                        activeSdg: []
                                                    });

                                                })
                                                .then( ({list, activeSdg}) => {


                                                    this.setState({
                                                        ...this.state,
                                                        sdg: list,
                                                        selectedSdg: activeSdg
                                                    })
                                                })
                                                .catch(e => {
                                                    errorlog("Caricamento SDG",e)
                                                })
                                        }


                                    }
                                )
                            }}
                        >
                            <div
                                className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 small-accordion-title "}>
                                <strong className={ (!!this.state.sdgInEdit) ? COLOR_POLICY.edit.textClass : "" }>SDG</strong>
                            </div>
                            <span className={"float-right"}>
                                    {
                                        (!!this.state.sdgSection)
                                            ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                            : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                    }
                            </span>
                        </NavLink>
                    </CardHeader>
                    <UncontrolledCollapse toggler={"#sdgSection"}>
                        <CardBody>

                            {
                                (!!this.state.sdg && this.state.sdg.length > 0)
                                    ? (
                                        <React.Fragment>
                                            {/*     SDG  -------------------------------------------------------------------------------- */}

                                            <ToolkitProvider
                                                bootstrap4
                                                keyField="id"
                                                search
                                            >
                                                {props => (

                                                    <React.Fragment>
                                                        <Row>
                                                            <Col>
                                                                <SearchBar {...props.searchProps} />
                                                            </Col>
                                                        </Row>
                                                        <BootstrapTable
                                                            bootstrap4
                                                            striped
                                                            {...props.baseProps}
                                                            size="sm"
                                                            responsive
                                                            keyField="id"
                                                            bordered={false}
                                                            data={this.state.sdg}
                                                            columns={formatSdgColumns(Object.keys(this.state.sdg[0]))}
                                                            defaultSorted={defaultSorted}
                                                            pagination={paginationFactory(paginationOptions)}
                                                            wrapperClasses="table-responsive"
                                                            selectRow={{
                                                                mode: 'checkbox',
                                                                clickToSelect: true,


                                                                onSelect: row => {
                                                                    showlog("riga selezionata su onSelect");
                                                                    showlog(row);


                                                                    if (!!this.state.selectedSdg && !!this.state.selectedSdg.some(s => s === row.id)) {

                                                                        this.setState({
                                                                            ...this.state,
                                                                            selectedSdg: this.state.selectedSdg.filter(s => s !== row.id),
                                                                            sdgInEdit: true
                                                                        });

                                                                    } else {

                                                                        this.setState({
                                                                            ...this.state,
                                                                            selectedSdg: [...this.state.selectedSdg, row.id],
                                                                            sdgInEdit: true
                                                                        });

                                                                    }


                                                                },


                                                                selectionRenderer: row => {


                                                                    if (
                                                                        !!this.state.selectedSdg &&
                                                                        !!this.state.selectedSdg
                                                                            .filter(sdgId => sdgId === row.rowKey /*&& !!row.checked && !row.disabled*/)
                                                                            .reduce(getLastElementOr, null)
                                                                    ) row.checked = true;
                                                                    else row.checked = false;


                                                                    return (
                                                                        <div
                                                                            className="custom-control custom-checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="custom-control-input"
                                                                                checked={row.checked}
                                                                                disabled={row.disabled}
                                                                            />
                                                                            <label
                                                                                className="custom-control-label"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}/>
                                                                        </div>
                                                                    );

                                                                },
                                                                selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                                                    return null;
                                                                }
                                                            }}

                                                        />


                                                    </React.Fragment>

                                                )}

                                            </ToolkitProvider>

                                        </React.Fragment>
                                    )
                                    : (
                                            <WaitingOrNodataMessage
                                                waiting={ !(!!this.state.sdg && this.state.sdg.length === 0) }
                                                nodataMessage={emoj(EMOJ.sad_but_relieved_face) +"    Nessun SDG"}
                                            />
                                    )
                            }

                        </CardBody>
                    </UncontrolledCollapse>
                </Card>

                {/* ---- CRITERI ------------------------------------------------------------------------------------------------------------ */}
                <Card className="mb-0">
                    <CardHeader className={""}>
                        <NavLink
                            id={"critariasSection"}
                            href="#"
                            onClick={clickEvent => {
                                this.setState({
                                    ...this.state,
                                    critariasSection: !!!this.state.critariasSection
                                })
                            }}
                        >
                            <div
                                className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 small-accordion-title "}>
                                <strong className={ (!!this.state.critariasInEdit) ? COLOR_POLICY.edit.textClass : "" }>Criteri</strong>
                            </div>
                            <span className={"float-right"}>
                                    {
                                        (!!this.state.critariasSection)
                                            ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                            : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                    }
                            </span>
                        </NavLink>
                    </CardHeader>
                    <UncontrolledCollapse toggler={"#critariasSection"}>
                        <CardBody>


                            {/*     CRITERI  -------------------------------------------------------------------------------- */}
                            <FormGroup>
                                {/*     transparency    */}
                                <CustomInput
                                    type="checkbox"
                                    name={"criteriasTransparency"}
                                    id={"criteriasTransparency"}
                                    label={"Trasparenza"}
                                    className={

                                        (!!this.state.transparency)
                                            ? COLOR_POLICY.edit.textClass
                                            : (
                                                (!!this.props.question && !!this.props.question.transparency)
                                                    ? COLOR_POLICY.validated.textClass
                                                    : ""
                                            )

                                    }
                                    onClick={clickEvent => {

                                        this.setState({
                                            ...this.state,
                                            critariasInEdit: true,
                                            transparency: !this.state.transparency
                                        })

                                    }}
                                    defaultChecked={ !!this.props.question && !!this.props.question.transparency }
                                />

                                {/*     resilience      */}
                                <CustomInput
                                    type="checkbox"
                                    name={"criteriasResilience"}
                                    id={"criteriasResilience"}
                                    label={"Resilienza"}
                                    className={

                                        (!!this.state.resilience)
                                            ? COLOR_POLICY.edit.textClass
                                            : (
                                                (!!this.props.question && !!this.props.question.resilience)
                                                    ? COLOR_POLICY.validated.textClass
                                                    : ""
                                            )

                                    }
                                    onClick={clickEvent => {
                                        this.setState({
                                            ...this.state,
                                            critariasInEdit: true,
                                            resilience: !this.state.resilience
                                        })
                                    }}
                                    defaultChecked={ !!this.props.question && !!this.props.question.resilience }
                                />

                                {/*     improvement     */}
                                <CustomInput
                                    type="checkbox"
                                    name={"criteriasImprovement"}
                                    id={"criteriasImprovement"}
                                    label={"Miglioramento"}
                                    className={

                                        (!!this.state.improvement)
                                            ? COLOR_POLICY.edit.textClass
                                            : (
                                                (!!this.props.question && !!this.props.question.improvement)
                                                    ? COLOR_POLICY.validated.textClass
                                                    : ""
                                            )

                                    }
                                    onClick={clickEvent => {
                                        this.setState({
                                            ...this.state,
                                            critariasInEdit: true,
                                            improvement: !this.state.improvement
                                        })
                                    }}
                                    defaultChecked={ !!this.props.question && !!this.props.question.improvement }
                                />

                                {/*     gendergap     */}
                                <CustomInput
                                    type="checkbox"
                                    name={"criteriasGenderGap"}
                                    id={"criteriasGenderGap"}
                                    label={"Gender Gap"}
                                    className={

                                        (!!this.state.gendergap)
                                            ? COLOR_POLICY.edit.textClass
                                            : (
                                                (!!this.props.question && !!this.props.question.gendergap)
                                                    ? COLOR_POLICY.validated.textClass
                                                    : ""
                                            )

                                    }
                                    onClick={clickEvent => {
                                        this.setState({
                                            ...this.state,
                                            critariasInEdit: true,
                                            gendergap: !this.state.gendergap
                                        })
                                    }}
                                    defaultChecked={ !!this.props.question && !!this.props.question.gendergap }
                                />

                            </FormGroup>

                        </CardBody>
                    </UncontrolledCollapse>
                </Card>


            </AvForm>
        );


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName + (!!this.state.errorMessage ? " red" : "")}>
                    {
                        (!!this.props.question)
                            ? "Modifica domanda di settore"
                            : "Nuova Domanda di Settore"
                    }
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                <ModalFooter>

                    {
                        !!this.state.errorMessage
                            ? (
                                <span
                                    className={"error-tag"}
                                >
                                    { this.state.errorMessage }
                                </span>
                            )
                            : null
                    }

                    {/*
                        (!!hasPermission( permissions.DELETE_SECTOR_QUESTION ) && !!this.props.question)
                            ? (
                                <div
                                    className={"tooltip-icon-cursor req-button-label comander-icon-trash"}
                                    onClick={ () => {
                                        deleteSectorQuestion( this.props.question.id )
                                            .then( errorMessage => {
                                                // debugger;
                                                this.props.onSuccess( !!errorMessage ? errorMessage : true );
                                            })
                                            .catch(e => {
                                                errorlog("errore", e );
                                                this.props.onClose( e );
                                            })
                                    }}
                                >
                                    <i className="mdi mdi-trash-can-outline comander-icon comander-icon-trash" />
                                    <span className="comander-icon-trash">&nbsp;&nbsp;Elimina</span>
                                </div>
                            )
                            : null
                    */}

                    {
                        (!!hasPermission( permissions.DELETE_SECTOR_QUESTION ) && !!this.props.question)
                            ? (
                                <CustomInput
                                    type="switch"
                                    id={"isDisabled"}
                                    name={"isDisabled"}
                                    label={"Disabilitata"}
                                    className={"pmAuthorizationToggler"}
                                    onClick={clickEvent => {
                                        // debugger;



                                        if( !!document.getElementById( "isDisabled" ) ) {
                                            document.getElementById("isDisabled").checked = !this.state.isDisabled;
                                        }

                                        this.setState({
                                            ...this.state,
                                            isDisabled: !this.state.isDisabled
                                        },
                                            () => {
                                                disableSectorQuestion( this.props.question.id )
                                                    .then( errorMessage => {
                                                        // debugger;
                                                        this.props.onSuccess( !!errorMessage ? errorMessage : null );
                                                    })
                                                    .catch(e => {
                                                        errorlog("errore", e );
                                                        let errorMessage = extractErrorMessage( e );
                                                        this.setState({
                                                            ...this.state,
                                                            errorMessage: errorMessage
                                                        })
                                                        // this.props.onClose( e );
                                                    })
                                            }
                                        );

                                    }}
                                    checked={ !!this.state.isDisabled }
                                />
                            )
                            : null
                    }


                    {
                        (!!this.isReadyForSave())
                            ? (
                                <ActionButton
                                    promise={() => {

                                        if (!!this.props.question) {
                                            let state = cloneObject(this.state);

                                            if (state.selectedThemes.subThemes.length === 0) {
                                                delete state.selectedSubThemes;
                                            }
                                            return editNewQuestionSector(state, this.props.question.id);
                                        } else {
                                            return addNewQuestionSector(this.state)
                                        }
                                    }}
                                    onResult={result => {

                                        // debugger;

                                        if( !!!result ) this.props.onSuccess();
                                        else {
                                            let warningMessage =
                                                (
                                                    (!!isNotEmptyArray( result ))
                                                        ? (
                                                            result
                                                                .reduce( getLastElementOr, { code: 0 } )
                                                                .code
                                                                    === errorMessage.SECTOR_QUESTION_EDIT_PERMISSION_DENIED.code
                                                        )
                                                        : null
                                                )
                                                    ? errorMessage.SECTOR_QUESTION_EDIT_PERMISSION_DENIED.msg
                                                    : null
                                            ;

                                            this.props.onSuccess( warningMessage )
                                        }


                                    }}
                                    onError={e => {

                                        let errorMessage = extractErrorMessage( e );
                                        this.setState({
                                            ...this.state,
                                            errorMessage: errorMessage
                                        })

                                        // this.props.onClose();
                                    }}
                                />
                            )
                            : (
                                <Button
                                    color="secondary"
                                    onClick={clickEvent => {

                                    }}
                                >
                                    <em>Salva</em>
                                </Button>
                            )
                    }




                </ModalFooter>

            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }


    loadData(onEnd) {


        // sectors ----------------------------------------------------------------------------------------------------------------
        loadAllSectors()

        // logs ----------------------------------------------------------------------------------------------------------------
        // .then( list => {
        // 	showlog("sector list");
        // 	showlog(list);
        //
        // 	return list;
        // })


            // adjust ----------------------------------------------------------------------------------------------------------------
            .then(list => {
                return ({
                    processed:
                        list
                            .map(sector => ({
                                label: sector.name.trim(),
                                value: sector.id
                            })),
                    list:
                        list
                            .map(sector => {
                                sector.name = sector.name.trim();
                                return sector;
                            })
                });
            })


            // extra info ----------------------------------------------------------------------------------------------------------------
            .then(({list, processed}) => {

                return (
                    loadSectorQuestionsDimensions()
                        .then(dimensions => {
                            return {list: list, processed: processed, dimensions: dimensions};
                        })
                        .catch(e => Promise.reject(e))
                );

            })



            // thematics ----------------------------------------------------------------------------------------------------------------
            .then(({list, processed, dimensions}) => {

                return (
                    loadAllThemes()
                        .then(allThemes => {

                            allThemes.area =
                                allThemes.area
                                    .filter( a => (
                                        (a.id === 1) ||
                                        (a.id === 2) ||
                                        (a.id === 3) ||
                                        (a.id === "1") ||
                                        (a.id === "2") ||
                                        (a.id === "3")
                                    ))
                            ;

                            let allTh = [];

                            allThemes.area
                                .map(a => {
                                    allTh = [
                                        ...allTh,
                                        ...a.themes
                                    ];
                                })
                            ;

                            return ({
                                themes:
                                    allTh
                                        .map(th => ({
                                            ...th,
                                            value: th.id,
                                            label: th.name
                                        })),
                                tree: allThemes
                            });


                        })
                        .then(({themes, tree}) => {
                            return ({
                                list: list,
                                processed: processed,
                                themes: themes,
                                themesTree: tree,
                                dimensions: dimensions
                            });
                        })
                        .catch(e => {
                            errorlog("errore",e);
                            return ({
                                list: list,
                                processed: processed,
                                themes: [],
                                themesTree: {},
                                dimensions: dimensions
                            });
                        })
                );

            })


            // logs ----------------------------------------------------------------------------------------------------------------
            // .then( data => {
            // 	showlog("data");
            // 	showlog(data);
            //
            // 	return data;
            // })


            // finish ----------------------------------------------------------------------------------------------------------------
            .then(data => {

                showlog("controllo ultimo step");
                showlog(data);


                /*
                id: 35
                isMultipleAnswer: true
                macroarea_id: 1
                macroarea_name: "Environmental"
                name_en: "new question"
                name_it: "Nuova Domanda"
                ordering: 0
                score: 33
                sector_name: "Produzione di elettrodomestici                                                                                                                                                                          "
                srSectorId: 2
                subthematic_id: 6
                subthematic_name: "Azioni di adattamento ai rischi fisici"
                thematic_id: 4
                thematic_name: "Cambiamenti Climatici"



                 */



                this.setState({
                    ...this.state,
                    sectors: data.processed,
                    rawSectors: data.list,
                    themes: data.themes,
                    themesTree: data.themesTree,
                    selectedSector: (
                        (!!this.props.question)
                            ? (
                                (!!data.processed)
                                    ? (
                                        data.processed
                                            .filter(s => s.value === this.props.question.srSectorId)
                                            .reduce( getLastElementOr, null)
                                    )
                                    : null
                            )
                            : null
                    ),
                    selectedThemes: (
                        (!!this.props.question)
                            ? (
                                (!!data.themes)
                                    ? (
                                        data.themes
                                            .filter(s => s.id === this.props.question.thematic_id)
                                            .reduce( getLastElementOr, null)
                                    )
                                    : null
                            )
                            : null
                    ),
                    selectedSubThemes: (
                        (!!this.props.question)
                            ? (
                                (!!data.themes)
                                    ? (
                                        data.themes
                                            .filter(s => s.id === this.props.question.thematic_id)
                                            .reduce( getLastElementOr, {subThemes: []})
                                            .subThemes
                                            .filter(s => s.id === this.props.question.subthematic_id)
                                            .map(st => ({...st, value: st.id, label: st.name}))
                                            .reduce( getLastElementOr, null)
                                    )
                                    : null
                            )
                            : null
                    ),
                    dimensions: data.dimensions
                })
            })


            .catch(e => {
                errorlog("errore",e);
            })


    }

}
