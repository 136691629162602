// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import CardBody from "reactstrap/es/CardBody";
import {Card, CardHeader, Col, Row} from "reactstrap";
import {PATH_PREFIX} from "../env";
import {HOST} from "../../src/env";
import {translate} from '../components/i18n/translate-function';


export default class SupportPage extends React.Component {

    state = {
        content: "",
        successMessage: false,
        isButtonShow: false
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: translate("Support"), path: '' + PATH_PREFIX + '/contactus', active: true }
                    ]}
                    title={'Contatti'}
                />

                <Row>
                    <Col sm="12">
                        <Card className="mb-0" key={"welcome-page"}>
                            <CardHeader>
                                <h5 className="m-0">
                                    <div className="custom-accordion-title d-block pt-2 pb-2">
                                        Pagina di contatti
                                    </div>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                    <img style={{height:'100px'}} src={ HOST +"/assets/Loghi/ARB.svg" } alt="logo" />
                                       <br/> <br/> <br/>
                                        <p>
                                            ARB S.P.B.A. - Startup Innovativa <br/>
                                            Sede Legale: Viale Adriano Olivetti, 36 - 38122 Trento (TN) <br/>
                                            Sede operativa: Via Francia, 21/C – 37135 Verona (VR) <br/>
                                            Sede operativa: Via Dello Sbarco, 96 - 91025 Marsala (TP) <br/>
                                            Sede operativa: Galleria Pattari, 2 – 20122 Milano (MI) <br/>
                                            Mail: <a href={"mailto:info@arbspa.it"}>info@arbspa.it</a> <br/>
                                            Pec: <a href={"amministrazione@pec.arbspa.it"}>amministrazione@pec.arbspa.it</a> <br/>
                                        </p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }


};

