import React from "react";
import "./collect-report-icon-svg-component.css";

export const CollectReportIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >

        <svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g>
                <path class="report-icon-c1"
                     d="m63.58,79.04c5.29.03,10.58,0,15.87,0,5.37,0,10.74.02,16.11,0-5.37.02-10.74,0-16.11,0-5.29,0-10.58.02-15.87,0Z"/>
                <path class="report-icon-c1"
                      d="m63.41,55.18c5.37.02,10.74,0,16.1,0,5.37,0,10.74.01,16.1,0-5.37.02-10.74,0-16.1,0-5.37,0-10.74.02-16.1,0Z"/>
                <path class="report-icon-c2"
                      d="m114.7,111.95c0-8.63,0-17.26,0-25.88,0-18.8.02-37.61,0-56.41,0-6.22-3.69-11.45-9.31-12.79-2.49-.59-5.16-.48-7.75-.58-2.23-.09-4.47-.02-6.8-.02,0-1.31,0-2.2,0-3.09,0-3.5-.76-4.27-4.28-4.31-2.12-.03-4.24.11-6.34-.07-.77-.07-1.79-.68-2.15-1.35C75.29,2.34,70.99.05,65.3,0c-5.77-.05-9.91,2.51-12.72,7.49-.37.65-1.4,1.24-2.17,1.3-2.27.17-4.56,0-6.83.08-2.65.09-3.48.98-3.51,3.57-.01,1.2,0,2.39,0,3.82-3.75,0-7.24-.07-10.71.01-7.68.19-13.13,5.59-13.14,13.13-.04,27.92-.03,55.84,0,83.76,0,5.18,2.63,8.73,7.28,10.86.79.36,1.59.7,2.39,1.05h79.15c.18-.15.34-.36.55-.43,6.58-2.29,9.13-5.82,9.14-12.7ZM46.34,15.02c2.2,0,4.36-.05,6.52.01,2.35.07,3.73-.89,4.47-3.23,1.23-3.88,4.28-6.12,7.89-6.15,3.67-.03,6.71,2.26,7.98,6.33.67,2.15,1.99,3.03,4.11,3.03,2.34,0,4.69,0,7.21,0v11.65h-38.17v-11.65Zm56.2,104.01c-.8.1-1.62.08-2.44.08-23.12,0-46.24,0-69.37-.01-1.29,0-2.61-.08-3.87-.35-2.9-.62-4.46-2.58-4.47-5.53-.01-28.01-.02-56.01,0-84.02,0-4.05,2.67-6.68,6.74-6.73,3.56-.05,7.12,0,10.93,0,0,2.32-.02,4.42,0,6.51.04,2.66,1.07,3.79,3.63,3.79,14.49.02,28.98.03,43.48,0,2.42,0,3.57-1.2,3.63-3.59.05-2.11.01-4.22.01-6.68,4.18,0,8.07-.15,11.95.05,3.21.16,5.62,2.95,5.71,6.23.07,2.52.02,5.05.02,7.57,0,25.32,0,50.64,0,75.96,0,4.31-1.69,6.23-5.96,6.74Z"/>
                <path class="report-icon-c2"
                      d="m95.6,72.86c-10.66-.01-21.32-.01-31.98,0-2.78,0-4.09,1.01-4.1,3.06-.01,2.05,1.32,3.11,4.06,3.13,5.29.03,10.58,0,15.87,0,5.37,0,10.74.02,16.11,0,2.77-.01,4.09-1.04,4.11-3.08.01-2.06-1.31-3.1-4.06-3.1Z"/>
                <path class="report-icon-c2"
                      d="m63.41,55.18c5.37.02,10.74,0,16.1,0,5.37,0,10.74.01,16.1,0,2.78,0,4.06-1.02,4.04-3.09-.02-1.97-1.28-3.02-3.89-3.03-10.82-.03-21.63-.03-32.45,0-2.58,0-3.82,1.11-3.8,3.11.03,2,1.27,3,3.88,3.01Z"/>
                <path class="report-icon-c2"
                      d="m95.65,96.76c-10.73,0-21.46,0-32.19,0-2.67,0-3.88.92-3.94,2.91-.06,2.08,1.19,3.08,4,3.09,5.36.03,10.73,0,16.09,0,5.36,0,10.73.02,16.09,0,2.7-.01,3.89-.94,3.94-2.92.05-2.1-1.2-3.08-4-3.08Z"/>
                <path
                    d="m63.53,102.76c5.36.03,10.73,0,16.09,0,5.36,0,10.73.02,16.09,0-5.36.03-10.73,0-16.09,0-5.36,0-10.73.02-16.09,0Z"/>
            </g>
            <path class="report-icon-c2"
                  d="m47,108.52h-11.7c-2.41,0-4.38-1.96-4.38-4.38v-11.7c0-2.41,1.96-4.38,4.38-4.38h11.7c2.41,0,4.38,1.96,4.38,4.38v11.7c0,2.41-1.96,4.38-4.38,4.38Zm-11.7-16.45c-.21,0-.38.17-.38.38v11.7c0,.21.17.38.38.38h11.7c.21,0,.38-.17.38-.38v-11.7c0-.21-.17-.38-.38-.38h-11.7Z"/>
            <path class="report-icon-c2"
                  d="m40.64,81.01c-.66,0-1.3-.26-1.77-.73l-6.93-6.93c-.92-.92-1.1-2.43-.27-3.44.95-1.16,2.67-1.23,3.7-.19l5.27,5.27,9.48-9.48c.94-.94,2.48-1.13,3.49-.28,1.14.96,1.19,2.65.16,3.68l-11.37,11.37c-.47.47-1.1.73-1.77.73Z"/>
            <path class="report-icon-c2"
                  d="m40.64,58.58c-.66,0-1.3-.26-1.77-.73l-6.93-6.93c-.92-.92-1.1-2.43-.27-3.44.95-1.16,2.67-1.23,3.7-.19l5.27,5.27,9.48-9.48c.94-.94,2.48-1.13,3.49-.28,1.14.96,1.19,2.65.16,3.68l-11.37,11.37c-.47.47-1.1.73-1.77.73Z"/>
        </svg>
    </div>
);
