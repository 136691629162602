import React, {Suspense} from "react";
import {Progress} from "reactstrap";
import {StrengthPasswordValidator} from "../business-logic/auth";
import {renderlog, showlog} from "../utils/dev-utils";
import {getLastElementOr, onlyNotNull} from "../utils/array-utils";
import {COLOR_POLICY} from "../env";
import {translate} from "./i18n/translate-function";
import {urlDecode} from "../utils";


const keyLabel_it = {
	error: urlDecode(" ") ,
	weak: translate("weak"),
	average: translate("average"),
	strong: translate("strong"),
	secure: translate("secure")
};

export default class StrengthPasswordIndicatorComponent extends React.Component {


	state = {
		color: "primary"
	}

	constructor( props ) {
		super( props );
		this.inWait                     = this.inWait.bind( this );

		this.keys = [
			"error",
			"weak",
			"average",
			"strong",
			"secure"
		];
		this.validator = StrengthPasswordValidator;
	}

	inWait() {
		return (
			<React.Fragment>Wait</React.Fragment>
		);
	}

	render() {


		renderlog("StrengthPasswordIndicatorComponent", null, null, COLOR_POLICY.warning);


		return (
			<Suspense fallback={this.inWait()}>
				<Progress
					value={
						(
							(
								Object.values(keyLabel_it)
									.map( (t, i) => ( this.state.key === t ) ? i : null)
									.filter( onlyNotNull )
									.reduce(getLastElementOr, 0)
								// [0] || 0
							)
							/ (Object.values(keyLabel_it).length -1)
						)
						* 100
					}
					// color={ this.state.color }
					// className={"mt-2"}
					style={{
						height: 3,
						// backgroundColor: this.state.color
					}}
					animated
					// striped
				/>
				<label className="password-strongness-value-label">{ this.state.key }</label>
			</Suspense>
		);
	}

	componentDidMount() {}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if( prevProps.value !== this.props.value ) {

			let result = this.validator( this.props.value, this.props.blackList );
			showlog("StrengthPasswordValidator: ", result);

			/*
			color: "#ee0000"
			key: "error"
			message: "At least 1 uppercase character please"
			success: false
			*/

			this.setState({
				...this.state,
				color: result.color,
				key: keyLabel_it[result.key] || result.key,
				message: result.message,
				success: result.success
			},
				() => {
					document.getElementsByClassName("progress-bar progress-bar-animated progress-bar-striped")[0].style.backgroundColor = result.color;
					this.props.result( result );
				})

		}
	}

}
