// @flow
import jwtDecode from 'jwt-decode';
import {Cookies} from 'react-cookie';


/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();


    // showlog("user da authUtils.getLoggedInUser()");
    // showlog( user );


    if (!user) {


        // showlog("controllo il path");
        // showlog(window.location.href);


        return false;
    }





    /*
    new Date(JSON.parse(atob("eyJpZCI6IjI1IiwibmJmIjoxNjE3MzUxMzc3LCJleHAiOjE2MTczNTE0MDcsImlhdCI6MTYxNzM1MTM3N30")).exp *1000)
     */






    // const decoded = jwtDecode(user.jwtToken);
    const decoded = jwtDecode( localStorage.getItem("jwtToken") );




    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('isUserAuthenticated access token expired');

        window.isInLogin = true;

        // refreshToken();
        return false;
    } else {
        return true;
    }


};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');

    // showlog("controllo cookie user");
    // showlog(user);
    // showlog(user ? (typeof user == 'object' ? user : JSON.parse(atob(user))) : null);

    return user ? (typeof user == 'object' ? user : JSON.parse(atob(user))) : null;
};

export { isUserAuthenticated, getLoggedInUser };
