// @flow
import jwtDecode from 'jwt-decode';
import {Cookies} from 'react-cookie';
import {editNewPassword, enableUserByPasswordReset, signupCustomer} from "../services/auth-service";
import {permissions, role} from "../env";
import passwordStrength from "pwd-strength";
import passwordValidator from "password-validator";
import {getLastElementOr, onlyNotNull} from "../utils/array-utils";
import {showlog} from "../utils";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
	const user = getLoggedInUser();


	if (!user) {
		return false;
	}

	// const decoded = jwtDecode(user.jwtToken);
	const decoded = jwtDecode( localStorage.getItem("jwtToken") );
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		console.warn('auth access token expired');
		return false;
	} else {
		return true;
	}


};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
	const cookies = new Cookies();
	const user = cookies.get('user');
	return user ? (typeof user == 'object' ? user : JSON.parse(atob(user))) : null;
};

export { isUserAuthenticated, getLoggedInUser };


export const editPassword = (uid, newPassword) => {
	let userDTO = {
		id: uid,
		password: newPassword,
		confirmPassword: newPassword
	}
	return editNewPassword( userDTO );
};

// ( this.state.email, this.state.password, this.state.confirmPassword, this.state.token, this.state.mode )
export const confirmUserByPasswordReset = (email, clearPwd, confirmClearPassword, token, mode) => {
	let payload = {
		password: clearPwd,
		confirmPassword: confirmClearPassword,
		token: token,
		isNew: (!!mode && (mode === 1 || mode === "1" || mode === true || mode.toLowerCase().trim() === "true"))
	};
	return enableUserByPasswordReset( email, payload );
}

export const hasPermissionById = permissionId => {
	// TODO
}

export const hasPermissionByName = permissionName => {
	// TODO
}


/**
 * Oggetto relativo al ruolo es: role.SUPER_ADMIN
 *
 * @param roleObject
 * @returns {boolean}
 */
export const isUserRole = roleObject => {
	return localStorage.getItem("role") === roleObject.name;
}


export const getAllPermissions = () => (

	Object.keys(role)

		// nome ruolo corrente
		.filter(r => role[r].name === localStorage.getItem("role"))

		// ruolo
		.map( r => role[r] )

		// permessi (id) del ruolo
		.map( r => r.permissions )

		// [0]
		.reduce( getLastElementOr, null)

		// clean
		.filter( onlyNotNull )

		// permessi del ruolo
		.map( permissionId => {

			return (

				Object.keys(permissions)
					.filter( p => permissions[p].id === permissionId )
					.map(p => permissions[p])
					.filter( onlyNotNull )
					.reduce( getLastElementOr, null)
			);

		})

		// clean
		.filter( onlyNotNull )
);


export const hasPermission = permissionObj => {


	return (

			Object.keys(role)
				// nome ruolo corrente
				.filter(r => role[r].name === localStorage.getItem("role"))

				// ruolo
				.map( r => role[r] )

				// permessi (id) del ruolo
				.map( r => r.permissions )

				// [0]
				.reduce( getLastElementOr, [] )

				// clean
				.filter( onlyNotNull )

				// permessi del ruolo
				.map( permissionId => {


					// showlog("permessi del ruolo", permissionId);

					return (

						Object.keys(permissions)
							.filter( p => permissions[p].id === permissionId )
							.map(p => permissions[p])
							.filter( onlyNotNull )
							.reduce( getLastElementOr, null)
					);

				})

				// clean
				.filter( onlyNotNull )

				// permesso passato
				.filter( perm => (!!permissionObj &&!!permissionObj.id && perm.id === permissionObj.id) )

				.length > 0
	);

}




export const StrengthPasswordValidator = (pwd, blackList) => {

	if( !pwd ) return ({
		color: "#ee0000",
		key: "error",
		message: "missing password",
		success: false
	});


	let result = (
		passwordStrength(
			pwd,
			{
				minUpperChars: 1,
				minLowerChars: 1,
				minSpecialChars: 1,
				minPasswordLength: 8,
				maxConsecutiveRepeatingChars: 3
			}
		)
	);


	let validator = new passwordValidator();

	let black_list = [
		"password",
		"P4ssw0rd",
		"P455w0rd",
		"Password123",
		"abcABC12",
		"Password123$"
	];

	if(!!blackList) {
		black_list = [
			...black_list,
			...blackList
		]
	}

	validator
		.is().min(8)          // Minimum length 8
		.is().max(64)         // Maximum length 64
		.has().uppercase()          // Must have uppercase letters
		.has().lowercase()          // Must have lowercase letters
		.has().digits()             // Must have at least 1 digits
		.has().symbols()            // Must have at least 1 special character
		.has().not().spaces()       // Should not have spaces

		// black list
		.is().not().oneOf(black_list)
	;

	let isValid = validator.validate( pwd );


	return (
		Object.assign(
			result,
			{
				success: isValid
			}
		)
	);



}



export const signup = data => {
	showlog( data );

	/*
	{
	  "firstName": "Vito",
	  "lastName": "Lipari",
	  "email": "vito@mail.com",
	  "accepted": true,
	  "companyName": "TestAzi",
	  "piva": "987987987987",
	  "starepro": "Sicily/TP/IT",
	  "sector": {
		"id": 2,
		"label": "Produzione di elettrodomestici",
		"value": 2
	  },
	  "category": "2",
	  "positionInCompany": "3",
	  "nemp": "4"
	}
	 */
	let payload = {
		
		FirstName: data.firstName,
		LastName: data.lastName,
		Email: data.email,
		language_id : data.language_id,
		CompanyName: data.companyName,
		Vat: data.piva,
		sector_id: data.sector,
		// categoria: data.category,
		// posizionenellazienda: data.positionInCompany,
		employeesNumbersLevel: data.nemp,
		Dimension: data.dimension,
		CodFiscale: data.codiceFiscale,
		CodiceSdi: data.codiceUnivoco,
		Riferimento: data.datiLegale,
		IndirEmailPecsdi: data.pec,
		Indirizzo: data.indirizzo,
		Cap: data.cap,
		Provincia: data.provincia,
		//NumTel: data.telefono,
		Localita:data.comune
		
		

		
	};
	return signupCustomer( payload );
}
