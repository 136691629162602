import React from "react";
import {Component} from "react";
import {
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label, Button, CardHeader, CardBody, Card, NavLink, UncontrolledCollapse, Col, Row
} from 'reactstrap';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {
    loadAllComapnyAllowedDimensions,
    loadAllSectors, loadAllThemes, loadCompaniesData
} from "../../../business-logic/companies";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import {addNewQuestionSector, deleteSectorQuestion, editNewQuestionSector} from "../../../business-logic/rating";
import ActionButton from "../../save-button";
import {cloneObject} from "../../../utils/object-utils";
import {getSectorCompanies, loadSectorQuestionsDimensions} from "../../../business-logic/question-sectors";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {COLOR_POLICY, permissions, QUESTION_TEXT_COLOR_POLICY} from "../../../env";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {hasPermission} from "../../../business-logic/auth";
import {loadQuestionSdg, loadSdg, saveQuestionSdg} from "../../../business-logic/sdg";
import {translate} from "../../i18n/translate-function";





const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Sdg
        </span>
    ),
    sizePerPageList: [5]
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];


const fieldSdgMap = {
    id: "Id",
    name: "Nome",
};


const hiddenSdgFields = [
    "srSdgQuestions",
    "srSdgQuestionsStructure",
    "srReportsSdg"
];


const sortableSdgFields = [
    "Nome"
];

const formatSdgColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields.includes(ff.dataField))
    );
}

const {SearchBar} = Search;

export default class SdgManagerModal extends Component {

    state = {
        selectedSdg: []
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }



    render() {

        renderlog("SdgManagerModal", this.state, this.props, COLOR_POLICY.validated);

        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm"/>
                <span className={"loading-text-alignment"}>Caricamento...</span>
            </React.Fragment>
        );



        if( !!isNotEmptyArray( this.state.sdg ) ) {
            context = (

                <React.Fragment>
                    {/*     SDG  -------------------------------------------------------------------------------- */}

                    <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        search
                    >
                        {props => (

                            <React.Fragment>
                                <Row>
                                    <Col>
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    {...props.baseProps}
                                    size="sm"
                                    responsive
                                    keyField="id"
                                    bordered={false}
                                    data={this.state.sdg}
                                    columns={formatSdgColumns(Object.keys(this.state.sdg[0]))}
                                    defaultSorted={defaultSorted}
                                    pagination={paginationFactory(paginationOptions)}
                                    wrapperClasses="table-responsive"
                                    selectRow={{
                                        mode: 'checkbox',
                                        clickToSelect: true,


                                        onSelect: row => {
                                            showlog("riga selezionata su onSelect");
                                            showlog(row);


                                            if (!!this.state.selectedSdg && !!this.state.selectedSdg.some(s => s === row.id)) {

                                                this.setState({
                                                    ...this.state,
                                                    selectedSdg: this.state.selectedSdg.filter(s => s !== row.id),
                                                    sdgInEdit: true
                                                });

                                            } else {

                                                this.setState({
                                                    ...this.state,
                                                    selectedSdg: [...this.state.selectedSdg, row.id],
                                                    sdgInEdit: true
                                                });

                                            }


                                        },


                                        selectionRenderer: row => {


                                            if (
                                                !!this.state.selectedSdg &&
                                                !!this.state.selectedSdg
                                                    .filter(sdgId => sdgId === row.rowKey /*&& !!row.checked && !row.disabled*/)
                                                    .reduce(getLastElementOr, null)
                                            ) row.checked = true;
                                            else row.checked = false;


                                            return (
                                                <div
                                                    className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        checked={row.checked}
                                                        disabled={row.disabled}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                        }}/>
                                                </div>
                                            );

                                        },
                                        selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                            return null;
                                        }
                                    }}

                                />


                            </React.Fragment>

                        )}

                    </ToolkitProvider>

                </React.Fragment>
            );
        }




        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    SDG
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    { context }
                </ModalBody>
                <ModalFooter>


                    <ActionButton
                        promise={ () => saveQuestionSdg( this.props.ratingId, this.props.id, this.state.selectedSdg ) }
                        onResult={result => {
                            this.props.onClose();
                        }}
                        onError={e => {
                            this.props.onClose();
                        }}
                    />

                </ModalFooter>

            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }


    loadData() {

        loadSdg()
            .then( list => {
                return ({sdg: list})
            })
            .then( ({ sdg }) => {
                loadQuestionSdg( this.props.ratingId, this.props.id )
                    .then( selectedSdg => {
                        showlog("lista sdg in domande");
                        showlog( sdg );
                        showlog( selectedSdg );
                        // debugger;
                        this.setState({
                            ...this.state,
                            sdg: sdg,
                            selectedSdg: selectedSdg
                        })
                    })
            })
            .catch(e => {
                errorlog("Caricamento SDG in domande",e);
            })
        ;

    }

}
