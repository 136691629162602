import {Component} from "react";
import React from "react";
import PageTitle from "../../components/PageTitle";
import {Button, Card, CardBody, Col, Label, Row} from "reactstrap";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {editPassword} from "../../business-logic/auth";
import {PATH_PREFIX} from "../../env";
import Spinner from "../../components/area/gri-admin/add-gri-collect-modal";
import StrengthPasswordIndicatorComponent from "../auth/set-password-page";
import {translate} from "../../components/i18n/translate-function";


export default class EditPasswordPage extends Component {

	state = {
		data: null
	}

	constructor(props) {
		super(props);
	}


	render() {
		return (
			<React.Fragment>

				<PageTitle
					title={'Modifica password'}
					breadCrumbItems={[
						{ label: 'Profile', path: PATH_PREFIX +'/profile' },
						{ label: 'Edit Password', path: PATH_PREFIX +'/profile/edit-password', active: true },
					]}
				/>



				<Row>
					<Col sm={4}>
						<Card>
							<CardBody>

								<AvForm>

									{/*     PASSWORD  -------------------------------------------------------------------------------- */}
									<AvGroup>
										<Label for="password">Nuova Password</Label>
										<AvInput
											type={ (!!this.state.visiblePassword) ? "text" : "password" }
											name="password"
											id="password"
											value={ this.state.password }
											onChange={ changeTextEvent => {


												this.setState(
													{
														...this.state,
														password: changeTextEvent.target.value
													},
													newState => {

													}
												)
											}}
											required
											className={ (!!this.state.notValidPassword) ? "password-input-not-valid" : "" }
										/>
										{
											// className="uil uil-eye password-eye"
											<i
												className={"mdi mdi-eye" +( (!!this.state.visiblePassword) ? "" : "-off" )+ " password-eye"}
												onClick={ clickEvent => {
													this.setState(
														Object.assign(
															{},
															this.state,
															{ visiblePassword: !!!this.state.visiblePassword }
														)
													)
												}}
											/>
										}
										{
											( !!this.state.errors && !!this.state.errors.password )
												? <label className="error-message-label">{ this.state.errors.password }</label>
												: null
										}
										<StrengthPasswordIndicatorComponent
											value={ this.state.password }
											blackList={[]}
											result={ result => {

												this.setState({
													...this.state,
													notValidPassword: !result.success
												})

											}}
										/>
									</AvGroup>

									{/*     CONFERMA_PASSWORD  -------------------------------------------------------------------------------- */}
									<AvGroup>
										<Label for="confirmPassword">Conferma Password</Label>
										<AvInput
											type="password"
											name="confirmPassword"
											id="confirmPassword"
											value={ this.state.confirmPassword }
											onChange={ changeTextEvent => {


												this.setState(
													{
														...this.state,
														confirmPassword: changeTextEvent.target.value
													},
													newState => {

													}
												)
											}}
											required
										/>
										{
											( !!this.state.errors && !!this.state.errors.confirmPassword )
												? <label className="error-message-label">{ this.state.errors.confirmPassword }</label>
												: null
										}

									</AvGroup>

								</AvForm>

								<br/>


								<Row>
									<Col lg={10}/>
									<Col lg={1}>
										<Button
											color={"primary"}
											onClick={ clickEvent => {
												// validazione
												if( this.state.password === this.state.confirmPassword ) {

													editPassword( localStorage.getItem("id"), this.state.password )
														.then(result => {
															this.setState({
																...this.state,
																errors: {}
															})
														})
														.catch(e => {
															this.setState({
																...this.state,
																errors: { confirmPassword: JSON.stringify(e) }
															})
														})
												}
												else {
													this.setState({
														...this.state,
														errors: { confirmPassword: "La password non corrisponde" }
													})
												}
											}}
										>
											{
												(!!this.state.waiting)
													? (
														<React.Fragment>
															<Spinner color={"primary"} className="spinner-border-sm mr-1" />
															<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
														</React.Fragment>
													)
													: "Salva"
											}
										</Button>
									</Col>
								</Row>

							</CardBody>
						</Card>
					</Col>
				</Row>



			</React.Fragment>
		);
	}

}
