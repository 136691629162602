import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import "./siscoring-pdf.css";
import Spinner from "../../../components/Spinner";
import {errorlog, extractErrorMessage, renderlog, showlog} from "../../../utils";
import {loadSiScoringPdfData, sendPdfContent} from "../../../business-logic/siscoring";
import moment from "moment";
import {DATE_FORMAT_IT} from "../../../conf/siroad";
import ActionButton from "../../../components/save-button";
import {extractBase64ImageFromSvgChart} from "../../../business-logic/charts";
import {cssContent} from "./siscoring-pdf-css";
import {SiscoringWaitingDownloadModal} from "./siscoring-waiting-download-modal";
import {translate} from "../../../components/i18n/translate-function";


/**
 * /si-scoring/report/pdf/:id
 */
class SiScoringPdfPage extends Component {

    state = {
        ready: false,
        companyName: <span className={"wait-container"}><Spinner color={"#f00"} className="spinner-border-sm mr-1" /></span>
    }


    constructor( props ) {
        super( props );

        this.downloadPdf = this.downloadPdf.bind( this );
        this.drawChart = this.drawChart.bind( this );
        this.drawLines = this.drawLines.bind( this );
        this.drawPrintableChart = this.drawPrintableChart.bind( this );


    }


    downloadPdf() {

        return (
            this.drawPrintableChart()
                .then( pdf => {

                    return (
                        new Promise((onEnd, fail) => {

                            this.setState({
                                ...this.state,
                                downloadWaitModal: 100,
                                downloadContent: pdf
                            },
                                () => {
                                    onEnd();
                                }
                            )

                        })
                        .then( () => {

                            // spostato nella modale
                            return true;

                        })
                    )




                })
                .catch(e => {
                    errorlog("pdf download process", e);
                    let errorMessage = extractErrorMessage( e );
                    showlog(errorMessage);
                    this.setState({
                        ...this.state,
                        errorMessage: errorMessage
                    });
                })
        );


    }

    drawChart() {}

    drawPrintableChart() {

        // immagine dal canvas
        // let canvas = document.getElementById("circleChart");
        // let image = canvas.toDataURL("image/png");
        // let imageDomElement = document.createElement('img');
        // imageDomElement.setAttribute("id", "circleChartImage");
        // imageDomElement.src = image;
        // imageDomElement.style.cssText = "position: absolute; top: 1040px;";
        // // imageDomElement.onload( () => {
        // document.getElementById("siscoring-pdf-page_2").appendChild( imageDomElement );
        // })





        // immagine dall'svg
        return (

            extractBase64ImageFromSvgChart( document.getElementById("line-chart") )
                .then( data => {


                    /*
                    showlog("html di svg");
                    showlog( document.getElementById("line-chart").outerHTML );

                    showlog("base64");
                    showlog( data );

                    */

                    /*
                    let imageSvgDomElement = document.createElement('img');
                    imageSvgDomElement.setAttribute("id", "svgChartImage");
                    imageSvgDomElement.src = data;
                    imageSvgDomElement.style.cssText = "position: absolute; top: 100px;";
                    document.getElementById("siscoring-pdf-page_2").appendChild( imageSvgDomElement );
                    */

                    // }) // spostato alla fine



                    // send data

                    let str = `
                        <!DOCTYPE html>
                            <html lang="en">
                            <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
                                <meta name="theme-color" content="#000000">
                                <meta content="A fully featured admin theme which can be used to build CRM, CMS, etc." name="description">
                                <meta content="Coderthemes" name="author">
                                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
                                <meta http-equiv="Pragma" content="no-cache">
                                <meta http-equiv="Expires" content="0">
                                <title>SI Road</title>
                            </head>
                              <body>
                    `;


                    // estrazione html ( in stringa ) da una parte della pagina
                    str += document.getElementById("pdf-container").innerHTML;

                    // inject css
                    str = str.split("##innerCSS##").join( cssContent );

                    // cambio misure della viewbox
                    str = str.split('viewBox={"0 0 297 210"}').join('viewBox={"0 0 623.7 441"}');

                    // immagine stampabile
                    let canvasPrint = document.getElementById("circleChart_print");
                    let imagePrint = canvasPrint.toDataURL("image/png");

                    // eliminazione canvas




                    // la parte di stringa fino al canvas cercato
                    let str_11 = str.substring( 0, str.search(/<canvas[a-zA-Z0-9 ="'`#@]* id=['"`\$\{]circleChart_print/) );
                    let str_12 = str.substring( str.indexOf('></canvas>') +10 );
                    str = str_11 + str_12;

                    let str_1 = "";
                    let str_2 = "";

                    str_1 = str.substring( 0, str.indexOf("<img") );
                    str_2 = str.substring( str.indexOf('style="position: absolute; top: 1040px;">') +41 );
                    // str = str_1 + str_2;


                    let texts = `
                        <div id="chartText">
                            <span class="chart-small-text blue-text">${ Math.floor(this.state.datas.gov * 100) +"%" }</span>
                            <span class="chart-small-text green-text">${ Math.floor(this.state.datas.env * 100) +"%" }</span>
                            <span class="chart-small-text red-text">${ Math.floor(this.state.datas.soc * 100) +"%" }</span>
                            <span class="chart-small-text main-text">${ Math.floor(this.state.datas.autoRating * 100) +"%" }</span>
                        </div>
                    `;


                    str = str_1 + `<img id="chartImage" src="${ imagePrint }" style="position: absolute; top: 1850px; width: 100%;">` + texts + str_2;


                    // showlog("html fino a img");
                    // showlog( str_1.replace(/(data:image\/[jpgen]+;base64,)([a-zA-Z0-9\+\/]+)([=]*)/, "$1xxx$3") );
                    // showlog("html da fine img alla fine");
                    // showlog( str_2.replace(/(data:image\/[jpgen]+;base64,)([a-zA-Z0-9\+\/]+)([=]*)/, "$1xxx$3") );
                    // debugger;

                    str += `
                        </body>
                    </html>
                    `;


                    showlog("invio dati ");
                    showlog( str );
                   // debugger;


                    return (
                        sendPdfContent( str )
                            .then( result => {
                                showlog("ok");
                                showlog( result );

                                return (
                                    new Promise((s, f) => {

                                        this.setState({
                                            ...this.state,
                                            pdfSrc: str,
                                            pdf: result
                                        },
                                            () => {
                                                s( result );
                                            }
                                        )

                                    })
                                )

                            })
                            .catch( e => {
                                errorlog("invio pdf data", e);
                                return Promise.reject( e );
                            })
                    )


                }) // alla creazione dell'immagine svg
        );




    }

    drawLines() {

        const startGreenLinePoint = {
            x: 120,
            y: 144
        }

        const startRedLinePoint = {
            x: startGreenLinePoint.x +57,
            y: startGreenLinePoint.y
        }

        const startBlueLinePoint = {
            x: startRedLinePoint.x +57,
            y: startGreenLinePoint.y
        }

        if( !!this.state.ready ) {
            return (
                <React.Fragment>
                    <svg
                        viewBox={"0 0 297 210"}
                        xmlns="http://www.w3.org/2000/svg"
                        id={"line-chart"}
                    >
                        <defs>
                            {/*    Green Gradient        */}
                            <linearGradient id="fullGreenGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#0a0" />
                                <stop offset={ "100%" } stopColor="#0f0" />
                            </linearGradient>
                            <linearGradient id="middleGreenGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#0a0" />
                                <stop offset={ "100%" } stopColor="#0d0" />
                            </linearGradient>
                            <linearGradient id="lowGreenGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#0a0" />
                                <stop offset={ "100%" } stopColor="#0a0" />
                            </linearGradient>

                            {/*    Red Gradient        */}
                            <linearGradient id="fullRedGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#d30" />
                                <stop offset={ "100%" } stopColor="#f80" />
                            </linearGradient>
                            <linearGradient id="middleRedGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#d30" />
                                <stop offset={ "100%" } stopColor="#f20" />
                            </linearGradient>
                            <linearGradient id="lowRedGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#d30" />
                                <stop offset={ "100%" } stopColor="#d30" />
                            </linearGradient>

                            {/*    Blue Gradient        */}
                            <linearGradient id="fullBlueGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#35d" />
                                <stop offset={ "100%" } stopColor="#1df" />
                            </linearGradient>
                            <linearGradient id="middleBlueGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#35d" />
                                <stop offset={ "100%" } stopColor="#08f" />
                            </linearGradient>
                            <linearGradient id="lowBlueGradient" gradientTransform="rotate(0)">
                                <stop offset="0%"  stopColor="#35d" />
                                <stop offset={ "100%" } stopColor="#35d" />
                            </linearGradient>
                        </defs>

                        {/* Environment ------------------------------------------------- */}
                        <line
                            x1={ startGreenLinePoint.x }
                            y1={ startGreenLinePoint.y }
                            x2={ startGreenLinePoint.x + (45 * this.state.envAmbPerc) }
                            y2={ startGreenLinePoint.y +0.0001 }
                            stroke={
                                (this.state.envAmbPerc < 0.26 )
                                    ? 'url("#lowGreenGradient")'
                                    : (
                                        (this.state.envAmbPerc < 0.51 )
                                            ? 'url("#middleGreenGradient")'
                                            : 'url("#fullGreenGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startGreenLinePoint.x + 22.5 }
                            x2={ startGreenLinePoint.x + 22.5 }
                            y1={ startGreenLinePoint.y -1.5 }
                            y2={ startGreenLinePoint.y +4.5 }
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startGreenLinePoint.x +23 }
                            y={ startGreenLinePoint.y +4.5 }
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}




                        <line
                            x1={ startGreenLinePoint.x }
                            y1={ startGreenLinePoint.y +17.5 } // 161
                            x2={ startGreenLinePoint.x + (45 * this.state.envECPerc) }
                            y2={ startGreenLinePoint.y +17.5001 }
                            stroke={
                                (this.state.envECPerc < 0.26 )
                                    ? 'url("#lowGreenGradient")'
                                    : (
                                        (this.state.envECPerc < 0.51 )
                                            ? 'url("#middleGreenGradient")'
                                            : 'url("#fullGreenGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startGreenLinePoint.x +22.5 }
                            x2={ startGreenLinePoint.x +22.5 }
                            y1={ startGreenLinePoint.y +16 } // 159.5
                            y2={ startGreenLinePoint.y +21.5 }   // 165
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startGreenLinePoint.x +23 }
                            y={ startGreenLinePoint.y +21.5 }
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}



                        <line
                            x1={ startGreenLinePoint.x }
                            y1={ startGreenLinePoint.y +35.5 }  // 179
                            x2={ startGreenLinePoint.x + (45 * this.state.envRRPerc) }
                            y2={ startGreenLinePoint.y +35.5001 }
                            stroke={
                                (this.state.envRRPerc < 0.26 )
                                    ? 'url("#lowGreenGradient")'
                                    : (
                                        (this.state.envRRPerc < 0.51 )
                                            ? 'url("#middleGreenGradient")'
                                            : 'url("#fullGreenGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startGreenLinePoint.x +22.5 }
                            x2={ startGreenLinePoint.x +22.5 }
                            y1={ startGreenLinePoint.y + 34 } // 177.5
                            y2={ startGreenLinePoint.y + 39.5 } // 183
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startGreenLinePoint.x +23 }
                            y={ startGreenLinePoint.y +39.5 } // 183
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}





                        {/* Social ------------------------------------------------------- */}
                        <line
                            x1={ startRedLinePoint.x }
                            y1={ startRedLinePoint.y }
                            x2={ startRedLinePoint.x + (45 * this.state.socCond) }
                            y2={ startRedLinePoint.y +0.0001 }
                            stroke={
                                (this.state.socCond < 0.26 )
                                    ? 'url("#lowRedGradient")'
                                    : (
                                        (this.state.socCond < 0.51 )
                                            ? 'url("#middleRedGradient")'
                                            : 'url("#fullRedGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startRedLinePoint.x + 22.5 }
                            x2={ startRedLinePoint.x + 22.5 }
                            y1={ startRedLinePoint.y -1.5 }
                            y2={ startRedLinePoint.y +4.5 }
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startRedLinePoint.x +23 }
                            y={ startRedLinePoint.y +4.5 }
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}



                        <line
                            x1={ startRedLinePoint.x }
                            y1={ startRedLinePoint.y +17.5 } // 161
                            x2={ startRedLinePoint.x + (45 * this.state.socRCC) }
                            y2={ startRedLinePoint.y +17.5001 }
                            stroke={
                                (this.state.socRCC < 0.26 )
                                    ? 'url("#lowRedGradient")'
                                    : (
                                        (this.state.socRCC < 0.51 )
                                            ? 'url("#middleRedGradient")'
                                            : 'url("#fullRedGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startRedLinePoint.x +22.5 }
                            x2={ startRedLinePoint.x +22.5 }
                            y1={ startRedLinePoint.y +16 } // 159.5
                            y2={ startRedLinePoint.y +21.5 }   // 165
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startRedLinePoint.x +23 }
                            y={ startRedLinePoint.y +21.5 }
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}



                        <line
                            x1={ startRedLinePoint.x }
                            y1={ startRedLinePoint.y +35.5 }  // 179
                            x2={ startRedLinePoint.x + (45 * this.state.socSQ) }
                            y2={ startRedLinePoint.y +35.5001 }
                            stroke={
                                (this.state.socSQ < 0.26 )
                                    ? 'url("#lowRedGradient")'
                                    : (
                                        (this.state.socSQ < 0.51 )
                                            ? 'url("#middleRedGradient")'
                                            : 'url("#fullRedGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startRedLinePoint.x +22.5 }
                            x2={ startRedLinePoint.x +22.5 }
                            y1={ startRedLinePoint.y + 34 } // 177.5
                            y2={ startRedLinePoint.y + 39.5 } // 183
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startRedLinePoint.x +23 }
                            y={ startRedLinePoint.y +39.5 } // 183
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}



                        {/* Governance ------------------------------------------------------- */}
                        <line
                            x1={ startBlueLinePoint.x }
                            y1={ startBlueLinePoint.y }
                            x2={ startBlueLinePoint.x + (45 * this.state.govSTR) }
                            y2={ startBlueLinePoint.y +0.0001 }
                            stroke={
                                (this.state.govSTR < 0.26 )
                                    ? 'url("#lowBlueGradient")'
                                    : (
                                        (this.state.govSTR < 0.51 )
                                            ? 'url("#middleBlueGradient")'
                                            : 'url("#fullBlueGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startBlueLinePoint.x + 22.5 }
                            x2={ startBlueLinePoint.x + 22.5 }
                            y1={ startBlueLinePoint.y -1.5 }
                            y2={ startBlueLinePoint.y +4.5 }
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startBlueLinePoint.x +23 }
                            y={ startBlueLinePoint.y +4.5 }
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}



                        <line
                            x1={ startBlueLinePoint.x }
                            y1={ startBlueLinePoint.y +17.5 } // 161
                            x2={ startBlueLinePoint.x + (45 * this.state.govEt) }
                            y2={ startBlueLinePoint.y +17.5001 }
                            stroke={
                                (this.state.govEt < 0.26 )
                                    ? 'url("#lowBlueGradient")'
                                    : (
                                        (this.state.govEt < 0.51 )
                                            ? 'url("#middleBlueGradient")'
                                            : 'url("#fullBlueGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startBlueLinePoint.x +22.5 }
                            x2={ startBlueLinePoint.x +22.5 }
                            y1={ startBlueLinePoint.y +16 } // 159.5
                            y2={ startBlueLinePoint.y +21.5 }   // 165
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startBlueLinePoint.x +23 }
                            y={ startBlueLinePoint.y +21.5 }
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}




                        <line
                            x1={ startBlueLinePoint.x }
                            y1={ startBlueLinePoint.y +35.5 }  // 179
                            x2={ startBlueLinePoint.x + (45 * this.state.govInn) }
                            y2={ startBlueLinePoint.y +35.5001 }
                            stroke={
                                (this.state.govInn < 0.26 )
                                    ? 'url("#lowBlueGradient")'
                                    : (
                                        (this.state.govInn < 0.51 )
                                            ? 'url("#middleBlueGradient")'
                                            : 'url("#fullBlueGradient")'
                                    )
                            }
                            strokeWidth={ 2 }
                            strokeLinecap={"round"}
                        />
                        {/*}
                        <line
                            x1={ startBlueLinePoint.x +22.5 }
                            x2={ startBlueLinePoint.x +22.5 }
                            y1={ startBlueLinePoint.y + 34 } // 177.5
                            y2={ startBlueLinePoint.y + 39.5 } // 183
                            stroke={"#777"}
                            strokeWidth={ 0.25 }
                        />
                        <text
                            x={ startBlueLinePoint.x +23 }
                            y={ startBlueLinePoint.y +39.5 } // 183
                            className={"middle-line-text"}
                            stroke={"#aaa"}
                            strokeWidth={ 0.1 }
                        >
                            50% - BENCHMARK
                        </text>
                        */}



                        {/* central chart * /}

                        <circle
                            strokeWidth={ 1 }
                            stroke={"#0001"}
                            fill={"transparent"}
                            cx={ 195 }
                            cy={ 70 }
                            r={ 35 }
                        />
                        <circle
                            strokeWidth={ 1 }
                            stroke={"#0001"}
                            fill={"transparent"}
                            cx={ 195 }
                            cy={ 70 }
                            r={ 27 }
                        />
                        <circle
                            strokeWidth={ 1 }
                            stroke={"#0001"}
                            fill={"transparent"}
                            cx={ 195 }
                            cy={ 70 }
                            r={ 19 }
                        />
                        */}

                    </svg>



                    <canvas width={"1123"} height={"794"} id={"circleChart"}/>
                    <canvas width={"8000"} height={"8000"} id={"circleChart_print"}/>



                </React.Fragment>
            )
        }

        return null;


    }



    render() {


        renderlog("SiScoring Report PDF", this.state, this.props);





        let languageSelector = localStorage.getItem("language").substr(0, 2).toLowerCase();

        let context = (
            <div
                id={"pdf-container"}
            >

                <div id={"pdf"}>
                    <style id={"innerStyle"}>##innerCSS##</style>
                    <div className={"pdf-page lang_"+ languageSelector +"-siscoring-pdf-page_1"} id={"siscoring-pdf-page_1"}>
                        <span className={"text-content"}>{ this.state.companyName }</span>
                        <div id={"companyData"}>
                            {
                                !!this.state.companyInfo
                                    ? (
                                        <React.Fragment>
                                            <div className={"companyData-element"} id={"company-data-name"}>
                                                <span>{ this.state.companyInfo.companyName }</span>
                                            </div>
                                            <div className={"companyData-element"} id={"company-data-address"}>
                                                <span>{ this.state.companyInfo.address }</span>
                                            </div>
                                            <div className={"companyData-element"} id={"company-data-city"}>
                                                <span>{
                                                    this.state.companyInfo.city +" ("+
                                                    this.state.companyInfo.province +" )" +", "
                                                    +this.state.companyInfo.postalCode
                                                }</span>
                                            </div>
                                            <div className={"companyData-element"} id={"company-data-vat"}>
                                                <span>{ this.state.companyInfo.vat }</span>
                                            </div>
                                            <div className={"companyData-element"} id={"company-data-release-date"}>
                                                <span>{ moment( this.state.companyInfo.releaseDate ).format( DATE_FORMAT_IT ) }</span>
                                            </div>
                                        </React.Fragment>
                                    )
                                    : null
                            }

                        </div>
                    </div>
                    <div className={"pdf-page lang_"+ languageSelector +"-siscoring-pdf-page_2"} id={"siscoring-pdf-page_2"}>
                        {
                            !!this.state.ready
                                ? this.drawLines()
                                : (this.state.companyName)
                        }
                        <div id={"sdg-values-container"}>
                            {
                                !!this.state.sdg
                                    ? (
                                        this.state.sdg
                                            .map( v => (
                                                <span className={"sdg-value-perc"}>{ v + ( v !== 'NA' ? "%" : "" ) }</span>
                                            ))
                                    )
                                    : null
                            }
                        </div>
                    </div>
                    <div className={"pdf-page lang_"+ languageSelector +"-siscoring-pdf-page_3"} id={"siscoring-pdf-page_3"}>
                        {/*
                            this.state.
                                envAmbPerc: 0.22
                                envECPerc: 0.17
                                envRRPerc: 0.54
                                govEt: 0.53
                                govInn: 0.2
                                govSTR: 0.73
                                socCond: 0.86
                                socRCC: 0.51
                                socSQ: 0.69
                        */}
                        <div className={"block-perc"} id={"envAmbPerc"}>{ Math.floor(this.state.envAmbPerc *100) } %</div>
                        <div className={"block-perc"} id={"envECPerc"}>{ Math.floor(this.state.envECPerc *100) } %</div>
                        <div className={"block-perc"} id={"envRRPerc"}>{ Math.floor(this.state.envRRPerc *100) } %</div>

                        <div className={"block-perc"} id={"govEt"}>{ Math.floor(this.state.govEt *100) } %</div>
                        <div className={"block-perc"} id={"govInn"}>{ Math.floor(this.state.govInn *100) } %</div>
                        <div className={"block-perc"} id={"govSTR"}>{ Math.floor(this.state.govSTR *100) } %</div>

                        <div className={"block-perc"} id={"socSQ"}>{ Math.floor(this.state.socSQ *100) } %</div>
                        <div className={"block-perc"} id={"socCond"}>{ Math.floor(this.state.socCond *100) } %</div>
                        <div className={"block-perc"} id={"socRCC"}>{ Math.floor(this.state.socRCC *100) } %</div>
                    </div>
                    <div className={"pdf-page lang_"+ languageSelector +"-siscoring-pdf-page_4"} id={"siscoring-pdf-page_4"}/>
                    {/*
                    <div className={"pdf-page"} id={"siscoring-pdf-page_5"}/>
                    <div className={"pdf-page"} id={"siscoring-pdf-page_6"}/>
                    */}
                </div>
            </div>
        );

        let content = (
            <div>
                <h4>
                    SI Scoring Report
                    {
                        !this.state.pdfReady
                            ? null
                            : (
                                <ActionButton
                                    buttonText={ translate("Download") }
                                    waitingMessage={ translate("Loading_") }
                                    className={"float-right font-weight-normal"}
                                    promise={ () => {
                                        return (
                                            new Promise((onEnd, onFail) => {
                                                this.setState({
                                                    ...this.state,
                                                    downloadWaitModal: 1
                                                },
                                                    () => {

                                                        this.downloadPdf()
                                                            .then( res => {
                                                                showlog("button resolve");
                                                                onEnd();
                                                            })
                                                            .catch(e => {
                                                                onFail( e );
                                                            })

                                                    }
                                                )
                                            })
                                        );
                                    }}
                                    onResult={ result => {
                                        showlog("button resolve");


                                        // this.setState({
                                        //         ...this.state,
                                        //         pdfReady: true
                                        //     },
                                        //
                                        //     () => {
                                        //         this.downloadPdf()
                                        //
                                        //     }
                                        //
                                        // );
                                    }}
                                    onError={ e => {
                                        errorlog("Download SiScoring PDF", e);
                                        let errorMessage = extractErrorMessage( e );
                                        showlog(errorMessage);
                                        this.setState({
                                            ...this.state,
                                            errorMessage: errorMessage
                                        });
                                    }}
                                />
                            )

                        /*
                        !this.state.pdfReady
                            ? (
                                <ActionButton
                                    buttonText={"Scarica"}
                                    className={"float-right font-weight-normal"}
                                    promise={ () => {
                                        return Promise.resolve();
                                    }}
                                    onResult={ result => {
                                        showlog("button resolve");
                                        this.setState({
                                            ...this.state,
                                            pdfReady: true
                                        },

                                () => {
                                            this.downloadPdf()

                                        }

                                        );
                                    }}
                                    onError={ e => {
                                        showlog( e );
                                    }}
                                />
                            )
                            : (
                                <span className={"float-right font-weight-normal"}>Generazione Sustainability Scorecard versione pdf in corso...</span>
                            )
                        */
                    }
                </h4>
                { context }


                {
                    !!this.state.downloadWaitModal
                        ? (
                            <SiscoringWaitingDownloadModal
                                perc={ this.state.downloadWaitModal }
                                file={ this.state.downloadContent }
                                companyName={ this.state.companyInfo.companyName }
                                errorMessage={ this.state.errorMessage }
                                onClose={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        downloadWaitModal: 0,
                                        downloadContent: null
                                    });
                                }}
                            />
                        )
                        : null
                }


            </div>
        );

        return content;
    }

    componentDidMount() {

        let centralPoint = { x: 705, y: 195 };
        loadSiScoringPdfData( this.props.match.params.id )
            .then( data => {




                return (
                    new Promise((success, fail) => {

                        this.setState({
                            ...this.state,
                            companyName: <span className={"text-content"}>{ data.companyName }</span>,
                            ...data,
                            ready: true
                        },
                            () => {
                                success( data );
                            }
                        );

                    })
                )

            })


            // disegno cerchio
            .then( data => {
                let drawArea = document.getElementById("circleChart").getContext("2d");



                drawArea.lineCap = "round";

                let circularGreenGradient = drawArea.createConicGradient( (Math.PI / 2) -0.1, 740, 185 );
                circularGreenGradient.addColorStop(0, "#0a0");
                circularGreenGradient.addColorStop(1, "#0f0");

                let circularRedGradient = drawArea.createConicGradient( (Math.PI / 2) -0.1, 740, 185 );
                circularRedGradient.addColorStop(0, "#e54a31");
                circularRedGradient.addColorStop(1, "#f79e49");

                let circularBlueGradient = drawArea.createConicGradient( (Math.PI / 2) -0.1, 740, 185 );
                circularBlueGradient.addColorStop(0, "#014180");
                circularBlueGradient.addColorStop(1, "#7ed2e9");



                drawArea.lineWidth = 4;
                // placeholders
                drawArea.strokeStyle = "#ddd";
                drawArea.beginPath();
                drawArea.arc( 738, 185, 72, 0, 2 * Math.PI );
                drawArea.stroke();

                drawArea.beginPath();
                drawArea.arc( 738, 185, 101, 0, 2 * Math.PI );
                drawArea.stroke();

                drawArea.beginPath();
                drawArea.arc( 740, 185, 130, 0, 2 * Math.PI );
                drawArea.stroke();



                drawArea.lineWidth = 12;
                // charts
                drawArea.strokeStyle = circularBlueGradient;
                drawArea.beginPath();
                drawArea.arc( 738, 185, 72, 0, data.gov * 2 * Math.PI );
                drawArea.stroke();

                drawArea.strokeStyle = circularRedGradient;
                drawArea.beginPath();
                drawArea.arc( 738, 185, 101, 0, data.soc * 2 * Math.PI );
                drawArea.stroke();

                drawArea.strokeStyle = circularGreenGradient;
                drawArea.beginPath();
                drawArea.arc( 740, 185, 130, 0, data.env * 2 * Math.PI );
                drawArea.stroke();



                drawArea.lineWidth = 1;

                drawArea.strokeStyle = "#080";
                drawArea.beginPath();
                drawArea.moveTo(550, 325);
                drawArea.lineTo(650, 325);
                drawArea.lineTo(675, 300);
                drawArea.stroke();


                drawArea.strokeStyle = "#800";
                drawArea.beginPath();
                drawArea.moveTo(1000, 310);
                drawArea.lineTo(875, 310);
                drawArea.lineTo(825, 250);
                drawArea.stroke();


                drawArea.strokeStyle = "#008";
                drawArea.beginPath();
                drawArea.moveTo(1050, 110);
                drawArea.lineTo(875, 110);
                drawArea.lineTo(800, 150);
                drawArea.stroke();




                drawArea.strokeStyle = "#6b6c6b";
                drawArea.fillStyle = "#6b6c6b";
                drawArea.font = "40px Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                // drawArea.strokeText(Math.floor(data.autoRating * 100) +"%", 740, 185);
                drawArea.fillText(Math.floor(data.autoRating * 100) +"%", centralPoint.x, centralPoint.y);

                drawArea.strokeStyle = "rgb(1, 65, 128)";
                drawArea.fillStyle = "rgb(1, 65, 128)";
                drawArea.font = "22px Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                drawArea.fillText(Math.floor(data.gov * 100) +"%", centralPoint.x + 275, centralPoint.y -115);

                drawArea.strokeStyle = "rgb(1, 120, 58)";
                drawArea.fillStyle = "rgb(1, 120, 58)";
                drawArea.font = "22px Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                drawArea.fillText(Math.floor(data.env * 100) +"%", centralPoint.x -130, centralPoint.y +105);

                drawArea.strokeStyle = "rgb(240, 112, 76)";
                drawArea.fillStyle = "rgb(240, 112, 76)";
                drawArea.font = "22px Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                drawArea.fillText(Math.floor(data.soc * 100) +"%", centralPoint.x +275, centralPoint.y +85);


                document.getElementById("circleChart").style.cssText = "display: none; visibility: hidden;";

                return data;

            })



            // disegno secondo cerchio
            .then( data => {

                let drawAreaForPrinter = document.getElementById("circleChart_print").getContext("2d");

                let x = centralPoint.x + 35;  // 740;    // 740
                let y = centralPoint.y -17;  // 178;    // 740
                let scale = 7.1;


                drawAreaForPrinter.lineCap = "round";

                let circularGreenGradient = drawAreaForPrinter.createConicGradient( (Math.PI / 2) -0.1, x  * scale, y  * scale );
                circularGreenGradient.addColorStop(0, "#0a0");
                circularGreenGradient.addColorStop(1, "#0f0");

                let circularRedGradient = drawAreaForPrinter.createConicGradient( (Math.PI / 2) -0.1, x  * scale, y  * scale );
                circularRedGradient.addColorStop(0, "#e54a31");
                circularRedGradient.addColorStop(1, "#f79e49");

                let circularBlueGradient = drawAreaForPrinter.createConicGradient( (Math.PI / 2) -0.1, x  * scale, y  * scale );
                circularBlueGradient.addColorStop(0, "#014180");
                circularBlueGradient.addColorStop(1, "#7ed2e9");



                drawAreaForPrinter.lineWidth = 4 * scale;
                // placeholders
                drawAreaForPrinter.strokeStyle = "#ddd";
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.arc( x  * scale, y  * scale, 72 * scale, 0, 2 * Math.PI );
                drawAreaForPrinter.stroke();

                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.arc( x  * scale, y  * scale, 101 * scale, 0, 2 * Math.PI );
                drawAreaForPrinter.stroke();

                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.arc( x  * scale, y  * scale, 130 * scale, 0, 2 * Math.PI );
                drawAreaForPrinter.stroke();



                drawAreaForPrinter.lineWidth = 12 * scale;
                // charts
                drawAreaForPrinter.strokeStyle = circularBlueGradient;
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.arc( x  * scale, y  * scale, 72 * scale, 0, data.gov * 2 * Math.PI );
                drawAreaForPrinter.stroke();

                drawAreaForPrinter.strokeStyle = circularRedGradient;
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.arc( x  * scale, y  * scale, 101 * scale, 0, data.soc * 2 * Math.PI );
                drawAreaForPrinter.stroke();

                drawAreaForPrinter.strokeStyle = circularGreenGradient;
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.arc( x  * scale, y  * scale, 130 * scale, 0, data.env * 2 * Math.PI );
                drawAreaForPrinter.stroke();



                drawAreaForPrinter.lineWidth = 1 * scale;

                drawAreaForPrinter.strokeStyle = "#080";
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.moveTo((x -188) * scale, (y +140) * scale);
                drawAreaForPrinter.lineTo((x -88) * scale, (y +140) * scale);
                drawAreaForPrinter.lineTo((x -63) * scale, (y +115) * scale);
                drawAreaForPrinter.stroke();


                drawAreaForPrinter.strokeStyle = "#800";
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.moveTo((x +262) * scale, (y +125) * scale);
                drawAreaForPrinter.lineTo((x +137) * scale, (y +125) * scale);
                drawAreaForPrinter.lineTo((x +87) * scale, (y +65) * scale);
                drawAreaForPrinter.stroke();


                drawAreaForPrinter.strokeStyle = "#008";
                drawAreaForPrinter.beginPath();
                drawAreaForPrinter.moveTo((x +312) * scale, (y -75) * scale);
                drawAreaForPrinter.lineTo((x +137) * scale, (y -75) * scale);
                drawAreaForPrinter.lineTo((x +62) * scale, (y -35) * scale);
                drawAreaForPrinter.stroke();



                /*
                drawAreaForPrinter.strokeStyle = "#6b6c6b";
                drawAreaForPrinter.fillStyle = "#6b6c6b";
                drawAreaForPrinter.font = "40pt Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                // drawAreaForPrinter.strokeText(Math.floor(data.autoRating * 100) +"%", 740, 185);
                drawAreaForPrinter.fillText(Math.floor(data.autoRating * 100) +"%", 695 * scale, 203 * scale);

                drawAreaForPrinter.strokeStyle = "rgb(1, 65, 128)";
                drawAreaForPrinter.fillStyle = "rgb(1, 65, 128)";
                drawAreaForPrinter.font = "22pt Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                drawAreaForPrinter.fillText(Math.floor(data.gov * 100) +"%", 995 * scale, 80 * scale);

                drawAreaForPrinter.strokeStyle = "rgb(1, 120, 58)";
                drawAreaForPrinter.fillStyle = "rgb(1, 120, 58)";
                drawAreaForPrinter.font = "22pt Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                drawAreaForPrinter.fillText(Math.floor(data.env * 100) +"%", 575 * scale, 300 * scale);

                drawAreaForPrinter.strokeStyle = "rgb(240, 112, 76)";
                drawAreaForPrinter.fillStyle = "rgb(240, 112, 76)";
                drawAreaForPrinter.font = "22pt Raleway, Raleway-Medium, Lato, Lato-Medium, Verdana";
                drawAreaForPrinter.fillText(Math.floor(data.soc * 100) +"%", 980 * scale, 280 * scale);
                */

                document.getElementById("circleChart_print").style.cssText = "display: none; visibility: hidden;";


                // return data;

                return (
                    new Promise(( success, fail ) => {
                        this.setState({
                            ...this.state,
                            datas: data,
                            pdfReady: true
                        },
                            () => {
                                success( data )
                            }
                        )
                    })
                );



            })


            // generezione html e download pdf
            .then( datas => {

                // immagine dal canvas
                let canvas = document.getElementById("circleChart");
                let image = canvas.toDataURL("image/png");
                let imageDomElement = document.createElement('img');
                imageDomElement.setAttribute("id", "circleChartImage");
                imageDomElement.src = image;
                imageDomElement.style.cssText = "position: absolute; top: 1040px;";
                // imageDomElement.onload( () => {


                    document.getElementById("siscoring-pdf-page_2").appendChild( imageDomElement );
                // })


            })


            .catch( e => {
                errorlog("caricamento dato SiScoring PDF", e);
                this.setState({
                    ...this.state,
                    error: "Errore",
                    ready: true
                });
            })


    }

}
export default withRouter( SiScoringPdfPage );
