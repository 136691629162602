import React, {useState} from "react";
import {errorlog, extractErrorMessage, getLastElementOr, renderlog, showlog} from "../../../../utils";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import {LANGUAGES} from "../../../../conf/languages";
import {Label} from "reactstrap";
import ActionButton from "../../../../components/save-button";
import {EMAIL_REGEX, NAME_REGEX} from "../../../../conf/siroad";
import {ForgotPasswordPanel} from "./forgot-password";
import {DIMENSIONS} from "../../../../conf/dimensions";
import {loadAllProvinces} from "../../../../business-logic/companies";
import {signup} from "../../../../business-logic/auth";
import {translate} from "../../../../components/i18n/translate-function";
import {LanguageSelector} from "./language-component";


const sectors = [
    {id:1, label: "Abbigliamento, accessori e calzature"},
    {id:2, label: "Produzione di elettrodomestici"},
    {id:3, label: "Prodotti per l'edilizia e arredamento"},
    {id:4, label: "Prodotti per la casa e personali"},
    {id:5, label: "Giocattoli e articoli sportivi"},
    {id:6, label: "Distributori mono-marchio e distributori multi-marchio specializzati E-commerce"},
    {id:7, label: "Estrazione e lavorazione del carbone"},
    {id:8, label: "Estrazione e lavorazione dei materiali per edilizia"},
    {id:9, label: "Produzione di ferro e acciaio"},
    {id:10, label: "Estrazione di ferro e altri materiali minerari"},
    {id:11, label: "Estrazione di petrolio & gas "},
    {id:12, label: "Distribuzione di petrolio & gas"},
    {id:13, label: "Raffinazione di petrolio & gas "},
    {id:14, label: "Servizi di petrolio & gas"},
    {id:15, label: "Asset management"},
    {id:16, label: "Banca di investimenti"},
    {id:17, label: "Banche commerciali"},
    {id:18, label: "Investimenti per clienti "},
    {id:19, label: "Finanza immobiliare"},
    {id:20, label: "Assicurazioni commerciali"},
    {id:21, label: "Prodotti agricoli "},
    {id:22, label: "Carne, pollame e allevamenti "},
    {id:23, label: "Cibo lavorato "},
    {id:24, label: "Bevande alcoliche "},
    {id:25, label: "Bevande non alcoliche"},
    {id:26, label: "Commercianti e distributori di cibo e bevande "},
    {id:27, label: "Ristoranti e bar"},
    {id:28, label: "Industria del tabacco"},
    {id:29, label: "Industria farmaceutica "},
    {id:30, label: "Distributori e dettaglianti farmaceutici"},
    {id:31, label: "Servizi di assistenza sanitaria"},
    {id:32, label: "Produzione e distribuzione strumenti biomedicali"},
    {id:33, label: "Sistema elettrico e generazione di energia"},
    {id:34, label: "Produzione e distribuzione di gas"},
    {id:35, label: "Distribuzione di acqua "},
    {id:36, label: "Servizi ingegneristici e di costruzioni "},
    {id:37, label: "Costruttori immobiliari"},
    {id:38, label: "Mercato immobiliare"},
    {id:39, label: "Servizi immobiliari"},
    {id:40, label: "Gestione dei rifiuti "},
    {id:41, label: "Biocarburanti "},
    {id:42, label: "Celle combustibili e batterie industriali"},
    {id:43, label: "Tecnologie solari e progetti di sviluppo"},
    {id:44, label: "Energia eolica e progetti di sviluppo"},
    {id:45, label: "Gestione del verde e delle foreste "},
    {id:46, label: "Prodotti in carta "},
    {id:47, label: "Aerospaziale e difesa"},
    {id:48, label: "Packaging e container"},
    {id:49, label: "Strumenti elettronici ed elettrici "},
    {id:50, label: "Macchinari industriali "},
    {id:51, label: "Industria chimica "},
    {id:52, label: "Pubblicità e marketing "},
    {id:53, label: "Comunicazione e intrattenimento"},
    {id:54, label: "Case da gioco "},
    {id:55, label: "Hotel e alloggi "},
    {id:56, label: "Strutture per lo svago "},
    {id:57, label: "Distributori di prodotti/attrezzature medico-sanitari"},
    {id:58, label: "Assicurazione sanitaria"},
    {id:59, label: "Borsa valori e Borsa merci "},
    {id:60, label: "Servizi educativi e di formazione"},
    {id:61, label: "Servizi professionali e commerciali"},
    {id:62, label: "Servizi di produzione elettronica e design elettronico Hardware"},
    {id:63, label: "Software e servizi informatici "},
    {id:64, label: "Servizi via web e online "},
    {id:65, label: "Servizi di telecomunicazioni "},
    {id:66, label: "Compagnie aeree "},
    {id:67, label: "Flotte aeree per trasporto merci "},
    {id:68, label: "Automobili"},
    {id:69, label: "Parti di ricambio per automobili "},
    {id:70, label: "Noleggio auto e leasing"},
    {id:71, label: "Navi da crociera"},
    {id:72, label: "Trasporto merci marittimo"},
    {id:73, label: "Trasporto su rotaia"},
    {id:74, label: "Trasporto su gomma"},
    {id:75, label: "Semiconduttori"}
];

const categories = [
    {id: 1, label: "UNO"},
    {id: 1, label: "DUE"},
    {id: 1, label: "TRE"}
];


/**
 * Step 01
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const SignupProfilePanelContent = props => {

    const [data, setData] = useState({...props.dataPack});
    const [forgotPasswordForm, setForgotPasswordForm] = useState();
    const [language, setLanguage] = useState(
        LANGUAGES
            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )

            // ATTENZIONE temporaneo
            .filter( lang => {

                    if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                    if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                    return true;

            })

            .reduce( getLastElementOr, LANGUAGES[0] )
    );

    renderlog("Profile", data, props);



    if( !!forgotPasswordForm ) {
        return (
            <ForgotPasswordPanel
                onClose={ closeEvent => {
                    setForgotPasswordForm( false);
                }}
            />
        );
    }



    let form = (
        <React.Fragment>
            <AvForm
                className={"signup-panel-profile-form-container"}
                onValidSubmit={ (event, values) => {
                    showlog("onValidSubmit");
                    showlog(event);
                    showlog(values);
                }}
            >

                {/* ----------------------------------------------------------------------- */}
                <AvGroup className={"signup-panel-profile-input-container"}>
                    <AvInput
                        type={"text"}
                        name={"name"}
                        id={"signupProfile_name"}
                        className={"signupInput"}
                        label={""}
                        value={ data.firstName }
                        defaultValue={ data.firstName }
                        placeholder={translate("Name")}
                        onChange={ changeEvent => {
                            setData({...data, firstName: changeEvent.target.value});
                        }}
                        disabled={ false }
                        required
                    />
                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                </AvGroup>

                {/* ----------------------------------------------------------------------- */}
                <AvGroup className={"signup-panel-profile-input-container"}>
                    <AvInput
                        type={"text"}
                        name={"lastName"}
                        id={"signupProfile_lastName"}
                        className={"signupInput"}
                        label={""}
                        value={ data.lastName }
                        defaultValue={ data.lastName }
                        placeholder={translate("Surname")}
                        onChange={ changeEvent => {
                            setData({...data, lastName: changeEvent.target.value});
                        }}
                        disabled={ false }
                        required
                    />
                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                </AvGroup>

                {/* ----------------------------------------------------------------------- */}
                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                    <AvInput
                        type={"email"}
                        name={"email"}
                        id={"signupProfile_email"}
                        className={"signupInput"}
                        label={""}
                        value={ data.email }
                        defaultValue={ data.email }
                        placeholder={translate("Email")}
                        onChange={ changeEvent => {
                            setData({...data, email: changeEvent.target.value});
                        }}
                        disabled={ false }
                        required
                    />
                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                </AvGroup>

                {/* ----------------------------------------------------------------------- */}
                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>

                     {/*<Label for="lingua">{ translate("select_language_text") }</Label>*/}



                    <Select
                        // className="react-select gri-type-select"
                        // classNamePrefix="react-select"
                        // name="gri-type-select"
                        // id="gri-type-select"
                        type={"lingua"}
                        name={"lingua"}
                        id={"lingua"}
                        className={"signupInput"}
                        label={""}
                        value={
                            (!!data.language_id)
                                ? (
                                    LANGUAGES
                                        .filter(ol => (ol.value === data.language_id ))
                                        .reduce(getLastElementOr , null)
                                )
                                : null
                        }
                        placeholder={ <span className={"select-placeholder"}>{translate("Select Language")}</span> }
                        options={
                            LANGUAGES

                                // ATTENZIONE temporaneo
                                .filter( lang => {

                                        if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                                        if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                                        return true;

                                })

                        }
                        onChange={ changeEvent => {

                            setData({...data, language_id: changeEvent.value});

                        }}
                        disabled={ false }
                        required

                    />
                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                </AvGroup>



                {/* ----------------------------------------------------------------------- */}
                <AvGroup className={"signup-panel-profile-input-container signup-panel-profile-checkbox-container"}>
                    <AvInput
                        type={"checkbox"}
                        name={"accepted"}
                        id={"signupProfile_accept"}

                        className={""}
                        value={ !!data.accepted }
                        // defaultValue={ !!data.accepted }
                        placeholder={"xxx"}
                        onChange={ changeEvent => {
                            setData({...data, accepted: changeEvent.target.checked});
                        }}
                        onClick={ clickEvent => {

                        }}
                        disabled={ false }
                        checked={ !!data.accepted }
                        required
                    />
                    <Label>
                        <span className={""}>
                                {translate("Accept the")}&nbsp;
                            <span
                                className={"signup-link"}
                                onClick={ clickEvent => {

                                }}
                            >
                                    Privacy Policy
                                </span>
                            &nbsp;{translate("and")}&nbsp;
                            <span
                                className={"signup-link"}
                                onClick={ clickEvent => {

                                }}
                            >
                                    Terms of Use
                                </span>
                            </span>
                    </Label>
                </AvGroup>

                <ActionButton
                    visible={ true }
                    buttonText={translate("SIGN IN")}
                    className={"signup-panel-profile-button "+ (!!data.accepted ? "" : "signup-panel-profile-button-disabled")}
                    promise={ () => {
                        //return Promise.resolve( !!data.accepted );

                        if(!!data.accepted){

                            if(
                                (
                                    !!data.email
                                    && !!data.firstName
                                    && !!data.lastName
                                    && !!data.language_id
                                )
                                && (
                                    (!!data.email.match( EMAIL_REGEX ))
                                    & (!!data.firstName.match( NAME_REGEX ))
                                    & (!!data.lastName.match( NAME_REGEX ))
                                )
                            ) {
                                return (
                                    Promise.resolve()
                                )
                            }

                            else if(!data.firstName){
                                return Promise.reject(    "Nome mancante"    )
                            }

                            else if(!data.lastName){
                                return Promise.reject(    "Cognome mancante"    )
                            }
                            else if(((!data.email) || (!data.email.match( EMAIL_REGEX )))){
                                return Promise.reject(    "Email mancante o errata"    )
                            }
                            else if(!data.language_id){
                                return Promise.reject(    "Lingua mancante"    )
                            }

                        }else if(!data.accepted){
                            return Promise.reject(    "Privacy Policy e Term of Use non accetati"    )
                        }
                    }}
                    onProgress={ progress => {

                    }}
                    onResult={ result => {


                        props.onNext(data);

                    }}
                    onError={ e => {
                        let errorMessage = extractErrorMessage(e)
                        setData({...data, errorMessage: errorMessage })
                    }}
                />
            </AvForm>

            <div className={"signup-panel-profile-element"} id={"signup-panel-profile-alternative"}>
                <span>
                    {translate("Do you already have an account?")}&nbsp;
                    <span
                        className={"signup-link"}
                        onClick={ clickEvent => {
                            props.gotoSignIn();
                        }}
                    >
                        {translate("Log in here")}
                    </span>
                </span>
                <span
                    className={"signup-link"}
                    onClick={ clickEvent => {
                        // props.onForgotPassword();
                        setForgotPasswordForm( true );
                    }}
                >
                    {translate("Forgot your password?")}
                </span>
            </div>
        </React.Fragment>
    );


    return (


        <React.Fragment>

            {/* ---- TINY-SCREEN --------------------------------------------------------------- */}
            <div id="sign-landing-page" className={"sign-landing-page tiny-screen"}>
                <div className={"top"} id={"top-tinyScreen"}>

                    <div className={"landing-page-comander signin tiny-screen"}>
                        <LanguageSelector
                            onChange={ language => {
                                setLanguage( language );
                            }}
                        />

                    </div>

                    <div className={"signup-panel-profile-logo-container tiny-screen"}>
                        {/*}
                        <img
                            src={SiRoadLogoWhiteText}
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"130px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-title-signup"}>
                            <p>{ translate("signup_step_01_row_01") }</p>
                            <p>{ translate("signup_step_01_row_02") }</p>
                            <p>{ translate("signup_step_01_row_03") }</p>
                        </div>
                    </div>

                    <div className={"panel tiny-screen"}>
                        <div className={"signup-panel-profile-container"}>




                            <div
                                className={"signup-panel-profile-element"}
                                id={"signup-panel-profile-form"}
                            >

                                { form }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---- SMALL-SCREEN --------------------------------------------------------------- */}
            <div className={"signup-panel-profile-logo-container small-screen"}>
                {/*}
                <img
                    src={SiRoadLogoWhiteText}
                    alt={"SI Road"}
                    id={"siroad-logo-small-screen"}
                    width={"130px"}
                />
                */}
                <div style={{ minHeight: "130px" }}/>
                <div className={"signup-panel-profile-title-signup"}>
                    <p>{ translate("signup_step_01_row_01") }</p>
                    <p>{ translate("signup_step_01_row_02") }</p>
                    <p>{ translate("signup_step_01_row_03") }</p>
                </div>
            </div>

            <div className={"panel small-screen"}>
                <div className={"signup-panel-profile-container"}>
                    <div
                        className={"signup-panel-profile-element"}
                        id={"signup-panel-profile-form"}
                    >
                        { form }
                    </div>
                </div>
            </div>



            {/* ---- DESKTOP --------------------------------------------------------------- */}
            <div className={"panel desktop"}>
                <div className={"signup-panel-profile-container"}>


                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                        className={"language-selector-in-panel"}
                    />


                    <div className={"signup-panel-profile-logo-container desktop"}>
                        {/*}
                        <img
                            // src={SiRoadLogoWhiteText}
                            src={ PRODUCTION_HOST +"/assets/Loghi/SiRoad_home.png" }
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"180px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-element signup-panel-profile-title-signup"}>
                            <p>{ translate("signup_step_01_row_01") }</p>
                            <p>{ translate("signup_step_01_row_02") }</p>
                            <p>{ translate("signup_step_01_row_03") }</p>
                        </div>
                    </div>
                    <div
                        className={"signup-panel-profile-element no-alt"}
                        id={"signup-panel-profile-form"}
                    >

                        { form }

                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}


/**
 * Step 02
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const SignupProfilePanelContentCompany = props => {

    const [data, setData] = useState({...props.dataPack});
    const [language, setLanguage] = useState(
        LANGUAGES
            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )

            // ATTENZIONE temporaneo
            .filter( lang => {

                    if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                    if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                    return true;

            })


            .reduce( getLastElementOr, LANGUAGES[0] )
    );

    renderlog("Company", data, props);


    let form = (
        <AvForm
            className={"signup-panel-company-form-container"}
            onValidSubmit={ (event, values) => {
                showlog("onValidSubmit");
                showlog(event);
                showlog(values);
            }}
        >
            <br/>
            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container signupInput_fullLength"}>
                <AvInput
                    type={"text"}
                    name={"companyName"}
                    id={"signupProfile_companyName"}
                    className={"signupInput"}
                    label={""}
                    value={ data.companyName }
                    defaultValue={ data.companyName }
                    placeholder={translate("Company Name registrazione")}
                    onChange={ changeEvent => {
                        setData({...data, companyName: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>




            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container signupInput_fullLength"}>
                <Select
                    className="react-select signup-panel-company-select"
                    classNamePrefix="react-select"
                    name="sectors"
                    id="sectors-select"
                    value= {
                        sectors
                            .map( s => ({...s, value: s.id}))
                            .filter (s => s.id == data.sector )
                            .reduce(getLastElementOr, null)
                    }
                    onChange={ option => {
                        setData({...data, sector: option.value})
                    }}
                    options={ sectors.map( s => ({...s, value: s.id})) }
                    placeholder={<span className={"select-panel-company-select-placeholder"}>{translate("Sector")}</span>}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>

            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"number"}
                    name={"nemp"}
                    id={"signupProfile_nemp"}
                    className={"signupInput"}
                    label={""}
                    value={ data.nemp }
                    defaultValue={ data.nemp }
                    placeholder={translate("Number of employees")}
                    onChange={ changeEvent => {
                        setData({...data, nemp: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>

            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container signup-panel-company-input-container"}>
                <Select
                    // className="react-select gri-type-select"
                    // classNamePrefix="react-select"
                    // name="gri-type-select"
                    // id="gri-type-select"

                    type={"dimensione"}
                    name={"dimensione"}
                    id={"dimensione"}
                    className={"signupInput"}
                    label={""}
                    value={
                        (!!data.dimension)
                            ? (
                                DIMENSIONS
                                    .filter(ol => (ol.value === data.dimension ))
                                    .reduce(getLastElementOr , null)
                            )
                            : null
                    }
                    placeholder={ <span className={"select-placeholder"}>{translate("Select Dimension")}</span> }
                    options={ DIMENSIONS }
                    onChange={ changeEvent => {

                        setData({...data, dimension: changeEvent.value});

                    }}
                    disabled={ false }
                    required

                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>

            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"text"}
                    name={"codiceFiscale"}
                    id={"codiceFiscale"}
                    className={"signupInput"}
                    label={""}
                    value={ data.codiceFiscale }
                    defaultValue={ data.codiceFiscale }
                    placeholder={translate("Fiscal Code")}
                    onChange={ changeEvent => {
                        setData({...data, codiceFiscale: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>

            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"text"}
                    name={"codiceUnivoco"}
                    id={"codiceUnivoco"}
                    className={"signupInput"}
                    label={""}
                    value={ data.codiceUnivoco }
                    defaultValue={ data.codiceUnivoco }
                    placeholder={"Codice Univoco"}
                    onChange={ changeEvent => {
                        setData({...data, codiceUnivoco: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>


            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"text"}
                    name={"pec"}
                    id={"pec"}
                    className={"signupInput"}
                    label={""}
                    value={ data.pec }
                    defaultValue={ data.pec }
                placeholder={translate("CEM")}
                    onChange={ changeEvent => {
                        setData({...data, pec: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
            </AvGroup>


            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"text"}
                    name={"piva"}
                    id={"signupProfile_piva"}
                    className={"signupInput"}
                    label={""}
                    value={ data.piva }
                    defaultValue={ data.piva }
                    placeholder={translate("Vat Code")}
                    onChange={ changeEvent => {
                        setData({...data, piva: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>



            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container signupInput_fullLength"}>
                <AvInput
                    type={"text"}
                    name={"datiLegale"}
                    id={"datiLegale"}
                    className={"signupInput"}
                    label={""}
                    value={ data.datiLegale }
                    defaultValue={ data.datiLegale }
                    placeholder={translate("Legal Data")}      //Dati Legale Rappresentante
                    onChange={ changeEvent => {
                        setData({...data, datiLegale: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
            </AvGroup>




            <div className={"signup-panel-company-button-container"}>
                <ActionButton
                    color={"#d00"}
                    className={"signup-panel-company-button-disabled"}
                    buttonText={translate("BACK")}
                    promise={ () => {
                        return Promise.resolve();
                    }}
                    onResult={ result => {
                        props.onPrevious( data )
                    }}
                />
                <ActionButton
                    visible={ true }
                    buttonText={translate("FORWARD")}
                    className={"signup-panel-profile-button signup-panel-profile-button-step-2"}
                    promise={ () => {
                        // TODO
                        // return signup( data );
                        //return Promise.resolve( !!data.accepted  );

                        if(
                            (
                                !!data.companyName
                                && !!data.nemp
                                && !!data.dimension
                                && !!data.codiceFiscale
                                && !!data.codiceUnivoco
                                && !!data.piva
                                && !!data.sector
                            )
                            // && (
                            //     (!!data.email.match( EMAIL_REGEX ))
                            //     & (!!data.firstName.match( NAME_REGEX ))
                            //     & (!!data.lastName.match( NAME_REGEX ))
                            // )
                        ) {
                            return (
                                Promise.resolve()
                            )
                        }
                        else if(!data.companyName){
                            return Promise.reject(    "Ragione sociale mancante"    )
                        }
                        else if(!data.sector){
                            return Promise.reject(    "Settore mancante"    )
                        }
                        else if(!data.nemp){
                            return Promise.reject(    "Numero dipendenti mancante"    )
                        }
                        else if(!data.dimension){
                            return Promise.reject(    "Dimensione mancante"    )
                        }
                        else if(!data.codiceFiscale){
                            return Promise.reject(    "Codice fiscale mancante"    )
                        }
                        else if(!data.codiceUnivoco){
                            return Promise.reject(    "Codice univoco mancante"    )
                        }
                        else if(!data.piva){
                            return Promise.reject(    "Partita iva mancante"    )
                        }


                    }}
                    onProgress={ progress => {

                    }}
                    onResult={ result => {

                        props.onNext(data);

                    }}
                    onError={ e => {
                        let errorMessage = extractErrorMessage(e)
                        setData({...data, errorMessage: errorMessage })
                    }}
                />
            </div>

        </AvForm>
    );


    return (

        <React.Fragment>

            {/* ---- TINY-SCREEN --------------------------------------------------------------- */}
            <div id="sign-landing-page" className={"sign-landing-page tiny-screen"}>
                <div className={"top"} id={"top-tinyScreen"}>

                    <div className={"landing-page-comander signin tiny-screen"}>
                        <LanguageSelector
                            onChange={ language => {
                                setLanguage( language );
                            }}
                        />

                    </div>

                    <div className={"signup-panel-profile-logo-container tiny-screen"}>
                        {/*}
                        <img
                            src={SiRoadLogoWhiteText}
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"130px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-title-signup"}>
                            <p>{ translate("signup_step_01_row_01") }</p>
                            <p>{ translate("signup_step_01_row_02") }</p>
                            <p>{ translate("signup_step_01_row_03") }</p>
                        </div>
                    </div>

                    <div className={"panel panel-step-2 tiny-screen"}>
                        <div className={"signup-panel-profile-container"}>
                            <div
                                className={"signup-panel-profile-element"}
                                id={"signup-panel-profile-form"}
                            >

                                { form }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---- SMALL-SCREEN --------------------------------------------------------------- */}
            <div className={"signup-panel-profile-logo-container small-screen"}>
                {/*}
                <img
                    src={SiRoadLogoWhiteText}
                    alt={"SI Road"}
                    id={"siroad-logo-small-screen"}
                    width={"130px"}
                />
                */}
                <div style={{ minHeight: "130px" }}/>
                <div className={"signup-panel-profile-title-signup"}>
                    <p>{ translate("signup_step_01_row_01") }</p>
                    <p>{ translate("signup_step_01_row_02") }</p>
                    <p>{ translate("signup_step_01_row_03") }</p>
                </div>
            </div>

            <div className={"panel small-screen"}>
                <div className={"signup-panel-profile-container"}>
                    <div
                        className={"signup-panel-profile-element"}
                        id={"signup-panel-profile-form"}
                    >
                        { form }
                    </div>
                </div>
            </div>



            {/* ---- DESKTOP --------------------------------------------------------------- */}
            <div className={"panel desktop"}>
                <div className={"signup-panel-profile-container"}>

                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                        className={"language-selector-in-panel"}
                    />

                    <div className={"signup-panel-profile-logo-container desktop"}>
                        {/*}
                        <img
                            // src={SiRoadLogoWhiteText}
                            src={ PRODUCTION_HOST +"/assets/Loghi/SiRoad_home.png" }
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"180px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-element signup-panel-profile-title-signup"}>
                            <p>{ translate("signup_step_01_row_01") }</p>
                            <p>{ translate("signup_step_01_row_02") }</p>
                            <p>{ translate("signup_step_01_row_03") }</p>
                        </div>
                    </div>
                    <div
                        className={"signup-panel-profile-element no-alt"}
                        id={"signup-panel-profile-form"}
                    >

                        { form }


                    </div>
                </div>
            </div>
        </React.Fragment>


    );

}


/**
 * Step 03
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const SignupProfilePanelContentAddress = props => {

    const [data, setData] = useState({ ...props.dataPack });
    const [provinces, setProvinces] = useState();
    const [error, setError] = useState();
    const [language, setLanguage] = useState(
        LANGUAGES
            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )

            // ATTENZIONE temporaneo
            .filter( lang => {

                    if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                    if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                    return true;

            })


            .reduce( getLastElementOr, LANGUAGES[0] )
    );

    if(!provinces && !(provinces instanceof Array) && !error){
        loadAllProvinces()
            .then(
                lista =>{ setProvinces(lista)}
            )
            .catch(
                err => {
                    errorlog("errore caricamento province", err)
                    setError(err)
                }
            )
    }

    renderlog(
        "Sede Legale",
        data,
        props,
        {
            color: "#f53068",
            contrastColor: "#fff"
        }
    );


    let form = (
        <AvForm
            className={"signup-panel-company-form-container"}
            onValidSubmit={ (event, values) => {
                showlog("onValidSubmit");
                showlog(event);
                showlog(values);
            }}
        >


            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"indirizzo"}
                    name={"indirizzo"}
                    id={"indirizzo"}
                    className={"signupInput"}
                    label={""}
                    value={ data.indirizzo }
                    defaultValue={ data.indirizzo }
                    placeholder={"Indirizzo"}
                    onChange={ changeEvent => {
                        setData({...data, indirizzo: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>


            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"comune"}
                    name={"comune"}
                    id={"comune"}
                    className={"signupInput"}
                    label={""}
                    value={ data.comune }
                    defaultValue={ data.comune }
                    placeholder={translate("City")}
                    onChange={ changeEvent => {
                        setData({...data, comune: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>


            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>
                <AvInput
                    type={"cap"}
                    name={"cap"}
                    id={"cap"}
                    className={"signupInput"}
                    label={""}
                    value={ data.cap }
                    defaultValue={ data.cap }
                    placeholder={translate("ZIP Code")}
                    onChange={ changeEvent => {
                        setData({...data, cap: changeEvent.target.value})
                    }}
                    disabled={ false }
                    required
                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>

            {/* ----------------------------------------------------------------------- */}
            <AvGroup className={"signup-panel-company-input-container"}>

                <Select
                    type={"provincia"}
                    name={"provincia"}
                    id={"provincia"}
                    className={"signupInput"}
                    label={""}
                    value = {

                        (!provinces && !(provinces instanceof Array))
                            ? []
                            :
                            (
                                provinces
                                    .map(provincia =>
                                        (
                                            {
                                                value: provincia.code,
                                                label: provincia.description,
                                                ...provincia
                                            }
                                        ))
                                    .filter ( p => p.value == data.provincia)
                                    .reduce(getLastElementOr, null)
                            )

                    }
                    placeholder={ <span className={"select-placeholder"}>{translate("Select Province")}</span> }
                    options={

                        (!provinces && !(provinces instanceof Array))
                            ? []
                            :
                            (
                                provinces.map(provincia =>
                                    (
                                        {
                                            value: provincia.code,
                                            label: provincia.description,
                                            ...provincia
                                        }
                                    ))
                            )
                    }
                    onChange={ changeEvent => {

                        setData({...data, provincia: changeEvent.value});

                    }}
                    disabled={ false }
                    required

                />
                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
            </AvGroup>



            <div className={"signup-panel-company-button-container"}>
                <ActionButton
                    color={"#d00"}
                    className={"signup-panel-company-button-disabled signup-panel-profile-button-disabled-step-3"}
                    buttonText={translate("BACK")}
                    promise={ () => {
                        return Promise.resolve();
                    }}
                    onResult={ result => {
                        props.onPrevious( data )
                    }}
                />
                <ActionButton
                    visible={ true }
                    buttonText={translate("SUBSCRIBE")}
                    className={"signup-panel-profile-button signup-panel-profile-button-step-3"}
                    // promise={ () => {
                    //     // TODO
                    //     return signup( data );
                    //     // return Promise.resolve(  );
                    // }}
                    // onProgress={ progress => {

                    // }}
                    // onResult={ result => {
                    //     props.onLogin()
                    // }}
                    promise={ () => {
                        //return Promise.resolve( !!data.accepted );

                        if(
                            (
                                !!data.indirizzo
                                && !!data.cap
                                && !!data.provincia
                                && !!data.comune
                            )
                            // && (
                            //     (!!data.email.match( EMAIL_REGEX ))
                            //     & (!!data.firstName.match( NAME_REGEX ))
                            //     & (!!data.lastName.match( NAME_REGEX ))
                            // )
                        ) {

                            return (

                                signup( data )


                            )
                                ;
                        }
                        else if(!data.indirizzo){
                            return Promise.reject(    "Indirizzo mancante"    )
                        }
                        else if(!data.cap){
                            return Promise.reject(    "Cap mancante"    )
                        }
                        else if(!data.provincia){
                            return Promise.reject(    "Provincia mancante"    )
                        }
                        else if(!data.comune){
                            return Promise.reject(    "Comune mancante"    )
                        }


                    }}
                    onProgress={ progress => {

                    }}
                    onResult={ result => {

                        props.onNext(data)


                    }}


                    onError={ e => {
                        let errorMessage = extractErrorMessage(e)
                        setData({...data, errorMessage: errorMessage })
                    }}
                />
            </div>

        </AvForm>

    );


    return (



        <React.Fragment>

            {/* ---- TINY-SCREEN --------------------------------------------------------------- */}
            <div id="sign-landing-page" className={"sign-landing-page tiny-screen"}>
                <div className={"top"} id={"top-tinyScreen"}>

                    <div className={"landing-page-comander signin tiny-screen"}>
                        <LanguageSelector
                            onChange={ language => {
                                setLanguage( language );
                            }}
                        />

                    </div>

                    <div className={"signup-panel-profile-logo-container tiny-screen"}>
                        {/*}
                        <img
                            src={SiRoadLogoWhiteText}
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"130px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-title-signup"}>
                            <p>{ translate("signup_step_01_row_01") }</p>
                            <p>{ translate("signup_step_01_row_02") }</p>
                            <p>{ translate("signup_step_01_row_03") }</p>
                        </div>
                    </div>

                    <div className={"panel tiny-screen"}>
                        <div className={"signup-panel-profile-container"}>
                            <div
                                className={"signup-panel-profile-element"}
                                id={"signup-panel-profile-form"}
                            >

                                { form }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ---- SMALL-SCREEN --------------------------------------------------------------- */}
            <div className={"signup-panel-profile-logo-container small-screen"}>
                {/*}
                <img
                    src={SiRoadLogoWhiteText}
                    alt={"SI Road"}
                    id={"siroad-logo-small-screen"}
                    width={"130px"}
                />
                */}
                <div style={{ minHeight: "130px" }}/>
                <div className={"signup-panel-profile-title-signup"}>
                    <p>{ translate("signup_step_01_row_01") }</p>
                    <p>{ translate("signup_step_01_row_02") }</p>
                    <p>{ translate("signup_step_01_row_03") }</p>
                </div>
            </div>

            <div className={"panel small-screen"}>
                <div className={"signup-panel-profile-container"}>
                    <div
                        className={"signup-panel-profile-element"}
                        id={"signup-panel-profile-form"}
                    >
                        { form }
                    </div>
                </div>
            </div>



            {/* ---- DESKTOP --------------------------------------------------------------- */}
            <div className={"panel desktop"}>
                <div className={"signup-panel-profile-container"}>

                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                        className={"language-selector-in-panel"}
                    />


                    <div className={"signup-panel-profile-logo-container desktop"}>
                        {/*}
                        <img
                            // src={SiRoadLogoWhiteText}
                            src={ PRODUCTION_HOST +"/assets/Loghi/SiRoad_home.png" }
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"180px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-element signup-panel-profile-title-signup"}>
                            <p>{ translate("signup_step_01_row_01") }</p>
                            <p>{ translate("signup_step_01_row_02") }</p>
                            <p>{ translate("signup_step_01_row_03") }</p>
                        </div>
                    </div>
                    <div
                        className={"signup-panel-profile-element no-alt"}
                        id={"signup-panel-profile-form"}
                    >


                        { form }

                    </div>
                </div>
            </div>
        </React.Fragment>



    );
}
