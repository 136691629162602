import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import React from "react";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {saveNewZone} from "../../../business-logic/gri";
import Spinner from "../../Spinner";
import {errorlog} from "../../../utils/dev-utils";
import {translate} from "../../i18n/translate-function";


const mesi = [
	"Gennaio",
	"Febbraio",
	"Marzo",
	"Aprile",
	"Maggio",
	"Giugno",
	"Luglio",
	"Agosto",
	"Settembre",
	"Ottobre",
	"Novembre",
	"Dicembre"
];

export default class TreeDataEntryNewZoneModalComponent extends React.Component {

	state = {
		m: Array( mesi.length ).fill("")
	}

	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );
		// this.loadServices   = this.loadServices.bind( this );
		// this.loadCompanies  = this.loadCompanies.bind( this );
		// this.editService       = this.editService.bind( this );
		// this.addNewService       = this.addNewService.bind( this );
		// this.validateField  = this.validateField.bind( this );
	}

	toggle = () => {
		this.props.onClose();
	}

	render() {

		console.log(
			"%c RENDER %c TreeDataEntryNewZoneModalComponent ",
			"background:#38f; color:#ddd; border-radius:3px 0px 0px 3px; padding:3px;",
			"background:#38f8; color:#fff; border-radius:0px 3px 3px 0px; padding:3px;"
		);
		console.log(this.state);
		console.log(this.props);


		let context = (
			<AvForm>

				<AvGroup>
					<Label for={ this.props.id +"-newZoneInput" } className={""}>Entità</Label>
					<AvInput
						type="text"
						name={ this.props.id +"-newZoneInput" }
						id={ this.props.id +"-newZoneInput" }
						value={ this.state.zoneName }
						className={"tree-data-entry-value-input"}
						onChange={ changeTextEvent => {
							this.setState(
								{
									...this.state,
									zoneName: changeTextEvent.target.value
								})
						}}
						required
					/>
				</AvGroup>


			</AvForm>
		);


		let content = (
			<Modal isOpen={ true } >
				<ModalHeader className={this.state.headerClassName}>
					Nuova Entità
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {

							this.setState({
								...this.state,
								inWait: true
							})
							saveNewZone(this.state.zoneName, !!this.props.timing, this.props.id, this.props.structureId)
								.then( result => {
									this.props.onConfirm( result );
								})
								.catch(e => {
									errorlog("errore",e);
									this.props.onClose( e );
								})


						}}
					>
						{
							(!!this.state.inWait)
								? (
									<React.Fragment>
										<Spinner color={"primary"} className="spinner-border-sm mr-1" />
										<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
									</React.Fragment>
								)
								: "Salva"
						}
					</Button>{' '}
					<Button color="secondary" onClick={this.toggle}>
						{ translate("Close") }
					</Button>
				</ModalFooter>
			</Modal>


		);

		return content;
	}

	componentDidMount() {



	}


};
