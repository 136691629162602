import React from "react";
import "./guide-line-icon-svg-component.css";
export const GuideLineIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            clickEvent.stopPropagation();
            clickEvent.preventDefault();
        }}
    >
        <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 97.68">
            <path className="cls-1-gl"
                  d="M35.12,67.94c0,1,.29,1.18,1.21,1.17,4,0,8.05,0,12.07,0s8.15,0,12.22,0c.43,0,1.07.25,1.06-.6a18.44,18.44,0,0,0-.23-4.33c-.64-2.61-3-4.25-6.18-4.26H37.36c-2.22,0-2.16,0-2.24,2.17"/>
            <path className="cls-1-gl"
                  d="M95.22,33.25c0-.27,0-.54,0-.81a.46.46,0,0,0-.43-.54c-1.32-.32-1.8,0-1.8,1.34v35c0,2.48-.74,3.21-3.23,3.21H36c-2.57,0-3.28-.71-3.28-3.3V32.48c0-.27,0-.48-.38-.58-1.24-.34-1.85.06-1.85,1.3,0,14.93,0,24.32,0,39.24,0,1.13.37,1.29,1.36,1.29,7.82,0,15.65,0,23.47,0a2.31,2.31,0,0,1,2.43,1.44A5.69,5.69,0,0,0,68,75.18a2.42,2.42,0,0,1,2.54-1.49c7.78,0,15.56,0,23.34,0,1.1,0,1.41-.22,1.41-1.38C95.2,57.48,95.22,48.13,95.22,33.25Z"/>
            <path className="cls-1-gl"
                  d="M36.4,57.54c6.2,0,12.39,0,18.59,0a8.94,8.94,0,0,1,6.26,2.05c.08.07.24,0,.43.09,0-6.66,0-7.72,0-14.32,0-.76-.33-.82-1-.92A13.85,13.85,0,0,1,49,30.52a16.22,16.22,0,0,1,1.19-5.61c-4.68,0-9.24,0-13.8,0-1,0-1.25.24-1.25,1.25,0,11.9.05,18.26,0,30.16C35.12,57.35,35.42,57.54,36.4,57.54Z"/>
            <path className="cls-1-gl"
                  d="M62.88,42.18A11.5,11.5,0,1,0,51.37,30.7,11.39,11.39,0,0,0,62.88,42.18ZM64.06,33c0,1.4,0,2.8,0,4.2,0,.82-.15,1.59-1.21,1.58s-1.15-.73-1.15-1.5c0-2.85,0-5.69,0-8.53,0-.8.26-1.51,1.15-1.53s1.23.73,1.23,1.58Zm-1.2-11.55c1.11,0,1.18.87,1.2,1.66s-.09,1.79-1.18,1.8-1.2-.87-1.2-1.72S61.75,21.43,62.86,21.42Z"/>
            <path className="cls-1-gl"
                  d="M64.77,44.43c-.76.09-.71.49-.71,1v14.3c3.53-2.28,4-2.2,8.41-2.21q8.49,0,17,0c.78,0,1.19-.06,1.19-1.06,0-12,0-18.53,0-30.56,0-.89-.28-1-1.07-1-4.34,0-8.68,0-13,0-.28,0-.62-.14-.84.13a.5.5,0,0,0,0,.18A13.85,13.85,0,0,1,64.77,44.43Z"/>
            <path className="cls-2-lg"
                  d="M94.66,29.13c-.93-.09-1.26-.5-1.25-1.38s0-1.81,0-2.71a2.94,2.94,0,0,0-2.71-2.9,12,12,0,0,0-1.89-.08H75.89a2.52,2.52,0,0,1-2.11-.68,12.49,12.49,0,0,0-9-4.6H60.7A12.72,12.72,0,0,0,52,21.4a2.51,2.51,0,0,1-2.11.68H38.6c-1,0-2.08,0-3.12,0a3,3,0,0,0-3.12,3.22,22.06,22.06,0,0,0,0,2.3c.07,1.1-.37,1.59-1.49,1.52a2.47,2.47,0,0,0-2.74,1.74V74.76a2.61,2.61,0,0,0,2.63,1.75c.54,0,1.08,0,1.62,0,7,0,14,0,21,0a3.06,3.06,0,0,1,2.76,1.32,7,7,0,0,0,5.24,3c3.1.25,6-.12,8-2.84a3.51,3.51,0,0,1,3.22-1.49q10.92.06,21.84,0c2.49,0,3.24-.82,3.24-3.26V32.53C97.74,29.91,97.29,29.37,94.66,29.13ZM90.6,67.35a1.89,1.89,0,0,0,0,.67c.17,1-.31,1.09-1.13,1.08-4,0-8.06,0-12.08,0s-8.14,0-12.21,0c-.89,0-1.1-.23-1.15-1.12-.37-6.07,2.48-8.32,8-8.14,5.78.18,11.58.06,17.37,0,.93,0,1.22.22,1.2,1.18ZM75.68,25.05c.22-.27.56-.13.84-.13,4.34,0,8.68,0,13,0,.79,0,1.08.12,1.07,1,0,12,0,18.53,0,30.56,0,1-.41,1.06-1.19,1.06q-8.48,0-17,0c-4.4,0-4.88-.07-8.41,2.21V45.43c0-.51,0-.91.71-1a13.85,13.85,0,0,0,10.88-19.2A.5.5,0,0,1,75.68,25.05ZM62.86,19.18A11.5,11.5,0,1,1,51.37,30.7,11.46,11.46,0,0,1,62.86,19.18Zm-27.74,7c0-1,.23-1.27,1.25-1.25,4.56.06,9.12,0,13.8,0A16.22,16.22,0,0,0,49,30.52,13.85,13.85,0,0,0,60.74,44.41c.63.1,1,.16,1,.92,0,6.6,0,7.66,0,14.32-.19,0-.35,0-.43-.09A8.94,8.94,0,0,0,55,57.51c-6.2,0-12.39,0-18.59,0-1,0-1.28-.19-1.28-1.24C35.17,44.4,35.16,38,35.12,26.14Zm0,35.92c.08-2.17,0-2.17,2.24-2.17H55.27c3.16,0,5.54,1.65,6.18,4.26a18.44,18.44,0,0,1,.23,4.33c0,.85-.63.59-1.06.6-4.07,0-8.14,0-12.22,0s-8,0-12.07,0c-.92,0-1.24-.18-1.21-1.17ZM93.84,73.74c-7.78,0-15.56,0-23.34,0A2.42,2.42,0,0,0,68,75.18a5.69,5.69,0,0,1-10.21-.05,2.31,2.31,0,0,0-2.43-1.44c-7.82,0-15.65,0-23.47,0-1,0-1.36-.16-1.36-1.29.05-14.92,0-24.31,0-39.24,0-1.24.61-1.64,1.85-1.3.37.1.37.31.38.58V68.19c0,2.59.71,3.3,3.28,3.3H89.76c2.49,0,3.23-.73,3.23-3.21v-35c0-1.32.48-1.66,1.8-1.34a.46.46,0,0,1,.43.54c0,.27,0,.54,0,.81,0,14.88,0,24.23,0,39.11C95.25,73.52,94.94,73.74,93.84,73.74Z"/>
            <path className="cls-2-lg"
                  d="M65.09,35.55l-2.25,1.83a2.34,2.34,0,0,1-2.28.51c-1.16-.52-.34-3.39-.34-3.39L62,28.2s.22-.53-.43-.17a22.09,22.09,0,0,1-2.06.91s-.71.27-.38-.35A10,10,0,0,1,62.52,26c.81-.33,2.43-.47,2.14.95s-2.09,7.48-2.09,7.63-.52,1.81.3,1.26c.77-.39,2.14-.94,2.14-.94S66,34.64,65.09,35.55Z"/>
            <ellipse className="cls-2-lg" cx="64.34" cy="23.3" rx="1.47" ry="1.35"
                     transform="translate(22.04 77.09) rotate(-71.72)"/>
        </svg>
    </div>
);
