import React from "react";
import {errorlog, renderlog, showlog} from "../../../../utils/dev-utils";
import PageTitle from "../../../../components/PageTitle";
import {getLastElementOr, isNotEmptyArray, onlyNotNull} from "../../../../utils";
import {MOMENT_JS_INVALID_DATE_MSG, PATH_PREFIX, TABS} from "../../../../conf/siroad";
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import {clearSADashboardFilter, loadSuperAdminDashboardData} from "../../../../business-logic/super-admin-dashboard";
import {Col, CustomInput, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import "./super-admin-dashboard.css";
import {SuperAdminChartSettingsModal} from "../../../../components/area/super-admin/super-admin-chart-setting-modal";
import moment from "moment";
import classnames from "classnames";
import ActionButton from "../../../../components/save-button";
import {SRToolInfoBox} from "../../../../components/srtool-info-box";
import Spinner from "../../../../components/Spinner";
import {translate} from "../../../../components/i18n/translate-function";


const CHARTS = {
	chart_1: 1,
	chart_2: 2,
	chart_3: 3,
	chart_4: 4,
	chart_5: 5,
	chart_6: 6,
	chart_7: 7,
	chart_8: 8
};


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


export default class SRToolSuperAdminDashboardPage extends React.Component {


	state = {
		lastCollect: true,
		activeTab: TABS.general.id,
		tablePage: 1,
		paginationOptions: {
			paginationSize: 5,
			pageStartIndex: 1,
			firstPageText: translate('First'),
			prePageText: translate('backward'),
			nextPageText: translate('forward'),
			lastPageText: translate('Last'),
			nextPageTitle: translate('first page'),
			prePageTitle: translate('previous page'),
			firstPageTitle: translate('next page'),
			lastPageTitle: translate('last page'),
			showTotal: true,
			paginationTotalRenderer: (from, to, size) => (
				<span className="react-bootstrap-table-pagination-total ml-2">
	            Righe da {from} a {to} di {size}
	        </span>
			),
			sizePerPageList: [
				{
					text: "pochi",
					value: 8,
				},
				{
					text: "normale",
					value: 50,
				},
				{
					text: "molti",
					value: 250,
				}
			],
			onPageChange: changePageEvent => {
				showlog("cambio pagina");
				showlog(changePageEvent);   // page number
				this.setState({
					...this.state,
					tablePage: changePageEvent,
					paginationOptions: {
						...this.state.paginationOptions,
						page: changePageEvent
					}
				});
			},
			page: 1
		}
	}

	constructor(props) {
		super( props );
		this.loadData = this.loadData.bind( this );

		let c =
			Array( 10 )
				.fill(true)
				.map( (chart, index) => (
					<div className={"command-column-header"}>
						Filtro
						<i
							className={"uil-edit-alt"}
							onClick={ clickEvent => {
								clickEvent.stopPropagation();
								this.setState({
									...this.state,
									chartFilterModal: index
								});
							}}
						/>
					</div>
				))
		;

		showlog("charts")
		showlog(c)


	}


	fieldMap =
		Object.assign(
			{ company_name: "Azienda" },
			...Array( 10 )
				.fill(true)
				.map( (chart, index) =>
					{
						let c = {};
						c["chart_"+ (index +1)] = (
							<div className={"command-column-header"} id={"chart_"+ (index +1)}>
								Filtro
								<i
									className={"uil-edit-alt"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
										this.setState({
											...this.state,
											chartFilterModal: index +1
										});
									}}
								/>
							</div>
						);
						return c;
					}

				)
		)
	;


	hiddenFields = [
		"company_id",
		"subData",
		"chart_0 - ",
		"chart_1 - "
	]
	.filter( onlyNotNull)
	;


	// paginationOptions = {
	// 	paginationSize: 5,
	// 	pageStartIndex: 1,
	// 	firstPageText: "Primo",
	// 	prePageText: "Indietro",
	// 	nextPageText: "Avanti",
	// 	lastPageText: "Ultimo",
	// 	nextPageTitle: "Prima pagina",
	// 	prePageTitle: "Pagina precedente",
	// 	firstPageTitle: "Prossima pagina",
	// 	lastPageTitle: "Ultima pagina",
	// 	showTotal: true,
	// 	paginationTotalRenderer: (from, to, size) => (
	// 		<span className="react-bootstrap-table-pagination-total ml-2">
	//             Righe da {from} a {to} di {size}
	//         </span>
	// 	),
	// 	sizePerPageList: [
	// 		{
	// 			text: "pochi",
	// 			value: 8,
	// 		},
	// 		{
	// 			text: "normale",
	// 			value: 50,
	// 		},
	// 		{
	// 			text: "molti",
	// 			value: 250,
	// 		}
	// 	],
	// 	onPageChange: changePageEvent => {
	// 		showlog("cambio pagina");
	// 		showlog(changePageEvent);   // page number
	// 		this.setState({
	// 			...this.state,
	// 			tablePage: changePageEvent
	// 		});
	// 	},
	// 	page: this.state.tablePage || 1
	// };




	defaultSorted = [
		{
			id: "company_name",
			order: "asc",
		},
	];

	sortableFields = [
		"company_name",
		"chart_1",
		"chart_2",
		"chart_3",
		"chart_4"
	];


	formatColumns = columnList => {

		// showlog("controllo colonne");
		// showlog(columnList);
		// showlog(this.fieldMap);
		// showlog(Object.keys(this.fieldMap).map( f => Object.keys(this.fieldMap[f])[0]));
		// showlog(Object.keys(this.fieldMap).map( f => Object.keys(f)[0] ));


		return [
			...columnList
				.map( field => {

					// showlog("controllo");
					// showlog( field.split(" - ")[0] );
					// showlog( this.fieldMap[ field.split(" - ")[0] ] );

					return ({
						dataField: field,
						text:
							this.fieldMap[ field ] ||
							(
								<div className={"command-column-header"}>
									{
										field
											.split(" - ")
											.filter( (p, i) => i > 0 )
											.join(" - ")
									}
									<i
										className={"uil-edit-alt"}
										onClick={ clickEvent => {
											clickEvent.stopPropagation();

											showlog("click set filters");
											showlog( CHARTS[ field.split(" - ")[0] ] );
											showlog( field.split(" - ") );

											this.setState({
												...this.state,
												chartFilterModal:
													CHARTS[ field.split(" - ")[0] ]
											});
										}}
									/>
									{
										(!!this.state.deleteInWait && (this.state.deleteInWait === field))
										// (true)
											? (
												<Spinner color={"red"} className="red-little-spinner-into-header-column" />
											)
											: (
												<i
													className={"uil-times-circle"}
													onClick={ clickEvent => {
														showlog("code");
														showlog(
															field
																.split(" - ")
																[0]
																.split("_")
																[1]
														);

														this.setState({
																...this.state,
																deleteInWait: field
															},
															() => {
																let graphId = field.split(" - ")[0].split("_")[1];
																clearSADashboardFilter( graphId )
																	.then( result => {
																		window.location.href = window.location.href;
																	})
																	.catch(e => {
																		errorlog("Eliminazione filtro", e);
																		window.location.href = window.location.href; // forse non va bene
																	})
															}
														)


													}}
												/>
											)
									}

								</div>
							)
						,
						sort: this.sortableFields.includes( field )
					});
				})
				.filter( ff => !this.hiddenFields.includes(ff.dataField) ),

			...Object.keys(this.fieldMap)
				.filter( field => field !== "company_name")
				.filter( field => !columnList.map( cl => cl.split(" - ")[0]).includes(field) )
				.map( field => ({
					dataField: field,
					text: this.fieldMap[field]
				}))
		];
	}

	render() {

		renderlog("SRToolSuperAdminDashboardPage", this.state, this.props);




		// set della pagina
		// this.paginationOptions.page = this.state.tablePage || 1;
		// showlog(this.paginationOptions);


		let currentTab =
			Object.keys( TABS )
				.filter( field => ( TABS[ field ].id === this.state.activeTab ))
				.reduce( getLastElementOr, Object.keys( TABS )[0] )
		;









		let content = (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{ label: "SR Tool" //getCurrentRoleLabel()
						, path: PATH_PREFIX +"/welcome" },
						{ label: "Dashboard", active: true }
					]}
					title={
						<React.Fragment>
							{"Dashboard"}
							<SRToolInfoBox
								className={"srtool-info-box"}
							/>
						</React.Fragment>

					}
				/>


				{
					!!this.state.chartFilterModal
						? (
							<SuperAdminChartSettingsModal
								chart={ this.state.chartFilterModal }
								tab={ this.state.activeTab }
								year={
									!!this.state.year
										? this.state.year
										: moment().format("yyyy")
								}
								onSaveConfiguration={ data => {
									showlog(data);


									// this.setState({
									// 	...this.state,
									// 	chartFilterModal: undefined
									// })

									showlog("SuperAdminChartSettingsModal > onSaveConfiguration");
									this.loadData();


								}}
								onClose={ error => {
									this.setState({
										...this.state,
										chartFilterModal: undefined
									})
								}}
							/>
						)
						: null
				}


				{
					(
						!!this.state.data
						&& !!this.state.data[ currentTab ]
						&& this.state.data[ currentTab ].length > 0
					)
						? (
							<React.Fragment>


								<Nav tabs>

									{
										Object.keys( TABS )
											.map( field => (
												<NavItem key={ TABS[ field ].id }>
													<NavLink
														id={ TABS[ field ].id }
														href="#"
														className={classnames({ active: this.state.activeTab === TABS[ field ].id })}
														onClick={ clickEvent => {
															this.setState({
																...this.state,
																activeTab: TABS[ field ].id
															},
																() => {
																	showlog("tab clicked");
																	this.loadData();
																}
															);
														}}
													>
														<span>{ TABS[ field ].name }</span>
													</NavLink>
												</NavItem>
											))
									}

								</Nav>


								<TabContent activeTab={this.state.activeTab}>

									{
										Object.keys( TABS )
											.map( (field, index) => (
												<TabPane
													tabId={ TABS[ field ].id }
													key={ index +"-"+ TABS[ field ].id }
													className={"tab-pane-container"}
												>


													<ToolkitProvider
														bootstrap4
														keyField="company_id"
														search
														// exportCSV={{ onlyExportFiltered: true, exportAll: false }}
													>
														{
															props => (
																<React.Fragment>

																	<Row>
																		<Col sm={6}>
																			<SearchBar {...props.searchProps} />
																		</Col>
																		{/*<Col sm={2}/>*/}
																		<Col sm={6} className={"sadashboard-datepicker-container float-right"}>


																			<CustomInput
																				type="switch"
																				id={"switch-year"}
																				name={"switch-year"}
																				label="Ultima Raccolta"
																				className={"sadashboard-switch"}
																				onClick={clickEvent => {

																					this.setState({
																							...this.state,
																							lastCollect: !this.state.lastCollect,
																							year:
																								!this.state.lastCollect
																									? 0
																									: (
																										!!document.getElementById("sadashboardyear")
																											? document.getElementById("sadashboardyear").value
																											: (this.state.year || (new Date()).getFullYear())
																							        )
																						},
																						() => {
																							showlog("switch onclick");
																							this.loadData();
																						}
																					)


																				}}
																				valid={ (this.state.year == 0) || !!this.state.lastCollect }
																				defaultChecked={!!this.state.lastCollect}
																				checked={!!this.state.lastCollect}
																			/>

																		{/*</Col>*/}
																		{/*<Col sm={3} className={"sadashboard-datepicker-container float-right"}>*/}



																			{
																				(/*(this.state.year == 0) || */!!this.state.lastCollect)
																					? null
																					: (

																						<React.Fragment>

																							<Label className={"sadashboard-datepicker-label"}>Anno</Label>

																							<Input
																								type={ "number" }
																								name={ "year" }
																								id={ "sadashboardyear" }
																								defaultValue={
																									!!this.state.year
																										? this.state.year // moment( this.state.year ).format("yyyy")
																										: moment().format("yyyy")
																								}
																								// value={ this.state.year }
																								className={ "sadashboard-year-input "+ (((this.state.year == 0) || !!this.state.lastCollect) ? "green-sadashboard-year-input" : "") }


																								// onBlur={ event => {
																								onChange={ event => {

																									showlog("anno scelto");
																									showlog(event.target.value);

																									let year = parseInt(moment( event.target.value ).format("yyyy"), 10);
																									if(moment( event.target.value ).format("yyyy") === MOMENT_JS_INVALID_DATE_MSG ) {
																										year = 0;
																									}

																									this.setState({
																											...this.state,
																											year: year
																										},
																										() => {
																											// this.loadData();
																										}
																									);
																								}}


																								disabled={ !!this.state.lastCollect }
																							/>

																							<ActionButton
																								buttonText={"OK"}
																								className="non-compliant-comander-button set-year-button"
																								promise={ () => {

																									return (
																										new Promise((success, fail) => {
																											/*
																											let rawYear = document.getElementById("sadashboardyear").value;


																											let year = parseInt(moment( rawYear ).format("yyyy"), 10);
																											if(moment( rawYear ).format("yyyy") === MOMENT_JS_INVALID_DATE_MSG ) {
																												year = 0;
																											}

																											showlog("anno");
																											showlog( document.getElementById("sadashboardyear").value );
																											debugger;

																											this.setState({
																													...this.state,
																													year: year
																												},
																												() => {

																													showlog("action button on click");
																													this.loadData()
																														.then( result => {
																															success();
																														})
																														.catch(e => {
																															fail( e );
																														})
																													;
																												}
																											);
																											*/

																											this.loadData()
																												.then( result => {
																													success();
																												})
																												.catch(e => {
																													fail( e );
																												})
																											;



																										})
																									);


																								}}
																								onProcess={ () => {

																								}}
																								onResult={ result => {

																								}}
																								onError={ error => {
																									errorlog( error );
																								}}
																							/>
																						</React.Fragment>

																					)
																			}


																		</Col>
																	</Row>

																	{/*
																	<Row>
																		<Col>
																			<code>this.state.data[ field ]</code>
																			<pre>
																				{ beautify( this.state.data[ field ] ) }
																			</pre>
																		</Col>
																	</Row>
																	*/}


																	{
																		isNotEmptyArray( this.state.data[ field ] )
																			? (
																				<div className={"sadashboard-two-table"}>

																					<div className={"sadashboard-table-1"}>
																						<BootstrapTable
																							/* Tabella completa */
																							bootstrap4
																							striped
																							{...props.baseProps}
																							size="sm"
																							responsive
																							keyField="company_id"
																							data={ this.state.data[ field ] }
																							columns={ [{
																								dataField: "company_name",
																								text: this.fieldMap["company_name"]
																							}] }
																							defaultSorted={ this.defaultSorted }
																							// pagination={ paginationFactory(  ) }
																							// pagination={ paginationFactory( this.state.paginationOptions ) }
																							wrapperClasses="table-responsive super-admin-dashboard-main-table"
																						/>
																					</div>

																					<div className={"sadashboard-table-2"}>
																						<BootstrapTable
																							/* Tabella completa */
																							bootstrap4
																							striped
																							{...props.baseProps}
																							size="sm"
																							responsive
																							keyField="company_id"
																							data={ this.state.data[ field ] }
																							columns={
																								this.formatColumns(
																									Object.keys(
																										this.state.data[ field ]
																											.reduce( getLastElementOr, null )
																									)
																									.filter( fieldField => fieldField !== "company_name" )
																								)
																							}
																							// columns={ this.state.columns }
																							defaultSorted={ this.defaultSorted }
																							// pagination={ paginationFactory( this.state.paginationOptions ) }
																							wrapperClasses="table-responsive super-admin-dashboard-main-table"
																						/>
																					</div>
																				</div>

																			)
																			: null
																	}


																</React.Fragment>

															)
														}

													</ToolkitProvider>


												</TabPane>
											))
									}

									{/*<GotoTopComponent/>*/}

								</TabContent>




							</React.Fragment>
						)

						: (
							<WaitingOrNodataMessage
								waiting={ !(!!this.state.data && this.state.data.length === 0) }
								fullHeight={ true }
								nodataMessage={"Nessun Dato"}
							/>
						)
				}
			</React.Fragment>

		);

		return content;


	}


	componentDidMount() {

		showlog("componentDidMount");

		this.loadData();

	}




	loadData = () => {

		// debugger;

		showlog("parte anno "+ this.state.year);

		return (
			new Promise((success, fail) => {
				loadSuperAdminDashboardData( this.state.year, this.state.activeTab )

					// field sort   -------------------------------------------------------------------------------------------
					// .then( responseData => tableFieldSort(responseData, this.fieldMap) )

					// generazione colonne
					.then( responseData => {

						let c = [];

						// tutte le colonne
						responseData
							.map( row => Object.keys( row ))
							.map( arr => {
								c = [
									...c,
									...arr
								];
							})
						;

						// distinct
						let cols =
							c
								.filter((value, index, self) => self.indexOf(value) === index )
						;



						responseData
							.map( row => {
								cols
									.map( c => {
										if( !row[c] ) {
											row[c] = null;
										}
									})
							})
						;



						responseData
							.map( row => {
								if( !row.subData || JSON.stringify(row.subData) === "{}" || JSON.stringify(row.subData) === "[]" ) {
									row.subData = <span>&nbsp;</span>;
								}
								else {

									// row.subData[]

									// row.subData = ();
								}
							})




						// responseData =
						responseData
							.map( row => {
								Object.keys( row )
									.map( field => {
										if( !row[ field ] || JSON.stringify(row[ field ]) === "{}" || JSON.stringify(row[ field ]) === "[]" ) {
											row[ field ] = <span>&nbsp;</span>;
										}
										else {


											try {

												let subTable = JSON.parse( row[ field ] );


												/*
												controllo valori annuali / mesili

												I valori annuali si trovano sempre con un header 0
												e sono in una lista di 13 valori o di un solo valore

												I valori annuali si trovano sempre come 12 valori
												 */

												if(
													subTable.length === 1
													// || subTable.length === 13
												) {
													// valore annuale
													// showlog("%cvalore annuale", "background:#f0f; color: #fff;");
													// showlog(subTable);
													// showlog(subTable[0][0]);

													row[ field ] = subTable[0][0] || subTable[0]["0"];
												}
												else {

													// controllo primo valore attivo


													showlog("%ctabella interna della riga per il campo %c"+ field, "color:#ff0;", "background:#ff0; color:#222;");
													showlog( row[ field ] );

													row[ field ] =
														(
															<table className={"internal-minimal-table"}>
																<thead>
																{
																	subTable
																		.filter( (v, i) => i > 0 )
																		// .filter( v => !!v.isValueActive )
																		.map( subRow => (
																			<th>
																				{
																					Object.keys( subRow )
																						.map( subHeader => subHeader )
																						.reduce( getLastElementOr, "")
																				}
																			</th>
																		))
																}
																</thead>
																<tbody>
																<tr>
																	{
																		subTable
																			.filter( (v, i) => i > 0 )
																			// .filter( v => !!v.isValueActive )
																			.map( subRow => (
																				<td>
																					{
																						Object.keys( subRow )
																							.map( subHeader => subRow[subHeader] )
																							.reduce( getLastElementOr, null )
																					}
																				</td>
																			))
																	}
																</tr>
																</tbody>
															</table>
														)
													;
												}




												// showlog( "row[ field ]" );
												// showlog( row[ field ] );
												// showlog( row );

											}
											catch (e) {

												if( field.length > 20 ) {
													showlog("%cerrore al field "+ field, "color:#f00;");
													showlog(e);
												}

												row[ field ] = row[ field ];

											}

										}


										return row;
									})
							})



						// collect and company
						responseData
							.map( data => {
								let collectName = (
									(
										!!data.company_name.split("-mark-")
										&& !!data.company_name.split("-mark-")[1]
										&& data.company_name.split("-mark-")[1] !== undefined
										&& typeof data.company_name.split("-mark-")[1] !== "undefined"
										&& data.company_name.split("-mark-")[1] !== "undefined"
									)
										? (<div className={"sa-dashboard-collect-name"}>{ data.company_name.split("-mark-")[1] }</div>)
										: null
								)
								let company_processedName = (
									<div>
										<div>{ data.company_name.split("-mark-")[0] }</div>
										{ collectName }
									</div>
								);
								data.company_name = company_processedName;
								return data;
							})


						showlog("responseData");
						showlog(responseData);


						return responseData;
					})


					.then( data => {

						showlog("data");
						showlog(data);

						let currentTab =
							Object.keys( TABS )
								.filter( field => ( TABS[ field ].id === this.state.activeTab ))
								.reduce( getLastElementOr, Object.keys( TABS )[0] )
						;

						let tabData = {};
						// tabData =
						Object.keys( TABS )
							.map( field => {
								tabData[ field ] = []
								if( currentTab === field ) {
									if( isNotEmptyArray( data ) ) {
										tabData[ field ] = data;
									}
									else {

										// non accade MAI !!!!

										// showlog("");


										tabData[ field ] =
											// array quante le aziende
											data
												.map( (d, i) => {

													let r = {};

													Object.keys(this.fieldMap)
														.map( fi => {
															r["chart_"+ i] = this.fieldMap[fi];
														})
													;

													return r;

												})
									}
								}
							})
						;


						tabData[ currentTab ] =
							tabData[ currentTab ]
								.map( objRow => {

									let newObj = objRow;


									Object.keys( this.fieldMap )
										.map( field => {
											Object.keys(objRow)
												.map(objRowField => {
													if( objRowField.indexOf( field ) !== -1 && field !== "company_name") {
														field = null;
													}
												})
											return field;
										})
										.filter( onlyNotNull )
										.map( field => {
											if( field !== "company_name" ) {
												newObj[ field ] = (<span>&nbsp;</span>);
											}
										})

									// showlog("riga");
									// showlog( newObj );
									// showlog( Object.keys( newObj ) );

									// debugger;

									return newObj;


								})


						;




						showlog("tabData");
						showlog(tabData);

						return tabData
					})


					// finish
					.then( data => {

						showlog("data");
						showlog(data);

						this.setState({
							...this.state,
							data: data,
							chartFilterModal: undefined
						},
					() => {
							success();
						});
					})
					.catch( e => {
						errorlog("Caricamento dati per la dashboard di SA", e);
						fail( e );
					})
			})
		);


	}

}
