import React, {Component} from 'react';
import {Alert, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../../components/save-button";
import {PATH_PREFIX} from '../../../env';
import {modifyProfilation} from '../../../business-logic/modify-profilation';
import {extractErrorMessage} from '../../../utils';
import {translate} from '../../../components/i18n/translate-function';


export default class SegnalationProfilationModal extends Component {

    state = {
		note: null,
		alertOpen:false,
		alertMessage:""
    }

    constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );

    }

    toggle = () => {
        this.props.onClose();
    }


        render(){

            renderlog(
                "ProfilationSegnalationPopUp",
            );


            let content = (
                <Modal isOpen={ true } toggle={this.toggle} className={"segnalation-profilation-modal"}>

					<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                        Segnala al team ARB le modifiche che ritienei opportune.
                        Le modifiche proposte potranno avere un effetto sul perimetro della valutazione:
                    </ModalHeader>

                    <ModalBody className={this.state.bodyClass}>

							<Input
										type="textarea"
										row={ 32 }
										name={"note" }
										id={"note" }
										placeholder={"Aggiungi una nota"}
										className={"question-note"}
										defaultValue={
											this.state.note
										}
										onBlur={ blurEvent => {

											//setNewNote( blurEvent.target.value );
											this.setState({
												...this.state,
												note: [blurEvent.target.value]
											}, ()=>{
												showlog("Richiesta modifiche profilazione: " , this.state.note );

											})

										}}
										required
										//disabled={ !!props.isInAudit }
									/>

                    </ModalBody>

                    <ModalFooter className={this.state.bodyClass}>
                    <Row>
							<Col>
								<ActionButton
									buttonText={ "Invia segnalazione" }
									//promise={ () => acceptEUPA( props.match.params.id || props.id ) }
									//onResult={ () => setGoAhead( true ) }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
									promise={ () => Promise.resolve() }
									onResult={ () => {

										modifyProfilation( (this.props.esternalProps.match.params.id || this.props.esternalProps.id), this.state.note)
											.then(result => {
												window.location.href = PATH_PREFIX +"/sirating-admin/" + translate("evaluations")
											})
											.catch(err => {
												let errMessage = extractErrorMessage(err)
												showlog("errore mail", errMessage)

												this.setState({
													...this.state,
													alertMessage: errMessage,
													alertOpen:true
												})
											})
										} }
								/>
							</Col>
                            <Row>
							<Col>
								<ActionButton
									buttonText={ "Torna indietro" }
									//promise={ () => Promise.resolve() }
									//onResult={ () => {
									//	setSegnalationPopUp(true)
									//} }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
									promise={ () => Promise.resolve() }
									onResult={ () => {

                                        /*this.setState({
                                            ...this.state,
                                            notConfirmSegnalationFlag: true
                                        }) */
										this.props.onClose()


                                    } }
								/>
							</Col></Row>
						</Row>
                    </ModalFooter>




						{
							!!this.state.alertOpen
							? (
							<Alert color="danger" isOpen={true}>
								<div>
									<code>Errore { this.state.alertMessage }</code>
								</div>
							</Alert>
							)
							: null
						}

				</Modal>


            );

            return content;
        }


}
