import React from "react";
import GRITag from "../assets/images/gritag_1.jpeg";
import GRITag2 from "../assets/images/gritag_2.png";
import {UncontrolledTooltip} from "reactstrap";

export const SRToolInfoBox = props => (
    <div className={ !!props.className ? props.className : "" }>
        <i className="dripicons-question question-icon" id="srtool-text" />
        <UncontrolledTooltip placement="bottom" target="srtool-text" >
            <span>
                SR Tool è stato sottoposto a certificazione attraverso il GRI Certified Software e Tools Program,<br/>
                che garantisce che il contenuto degli standard GRI è accuratamente presentato all’interno del software SR Tool.<br/>
                Questa certificazione non riguarda il software o lo strumento digitale in sé, né la sua funzionalità
            </span>
        </UncontrolledTooltip>
        <img src={ GRITag } alt={"Content used with permissions"} className={"srtool-info-images"}/>
        <img src={ GRITag2 } alt={"Software & Tools partner"} className={"srtool-info-images"}/>
    </div>
);
