// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import notFoundImg from '../../assets/images/file-searching.svg';
import PageTitle from '../../components/PageTitle';

export default class ErrorPageNotFound extends React.Component {
    
    render() {
        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: 'Pages', path: '/pages/error-404' },
                        { label: '404', path: '/pages/error-404', active: true },
                    ]}
                    title={'404 Error'}
                />
    
                <Row className="justify-content-center">
                    <Col lg={4}>
                        <div className="text-center">
                            <img src={notFoundImg} height="90" alt="" />
                            <h1 className="text-error mt-4">404</h1>
                            <h4 className="text-uppercase text-danger mt-3">Pagina non trovata</h4>
                            <p className="text-muted mt-3">
                                Hai digitato nella barra degli indirizzi un percorso non corretto.<br /> Non preoccuparti puoi sempre ritornare all'applicazione
                            </p>
    
                            <Link className="btn btn-info mt-3" to="/">
                                <i className="mdi mdi-reply"></i> Ritorna alla Home
                            </Link>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
};

