import React, {Component} from "react";
import {Col, Modal, ModalBody, ModalHeader, Row, Table} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../env";
// import { Score_Get } from "../../../../src/services/request-manager";
import {getMaterialityReport} from "../../../business-logic/score";
import {translate} from "../../i18n/translate-function";


const fieldSdgMap = {
    thematic_name: "Tematica/Sottotematica",
    subthematic_name: "Sottotematica",
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    materiality: "Livello di Materialità",
    percentage: "%",
    sdg: "SDG"
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];











const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];


const hiddenSdgFields2 = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];




export default class MaterialityReport extends Component {


    importData = () => {




        //return Score_Get(this.props.evaluation_id, 0).then(r => {
        return (

            getMaterialityReport(this.props.evaluation_id)
                .then(r => {

                    console.log("rrrrrrrrrrrrrrrrr", r);
                    return (r.data || r);

                })

                .catch(e => {
                    console.log("errore");
                    console.log(e);
                })

        );







    }






    loadData() {

        this.importData()
            .then(data => {


                console.log("dasdgsaydguyds", data);

                // debugger
                this.setState({
                    data: data,



                })

            });


    }


    state = {
        data: false,

    }


    setCurrentThematic = (thematic) => {
        this.thematicCurrent = thematic;
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
        this.thematicCurrent = "";
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }


    generateTable = (data) => {

        let thematicCurrent = [];

        return (

            data.map((row, index) => {
                let i = index == 0 ? 1 : index;
                thematicCurrent.push(row.thematic);
                return (

                    (row.thematic != thematicCurrent[i - 1] || index == 0)

                        ?

                        <tr style={{ borderTop: "2px solid black" }}>
                            <td>{row.thematic}</td>
                            <td>{row.subthematic}</td>
                            <td>{this.valuesTextCovert(row.value)}</td>
                        </tr>

                        :
                        <tr>
                            <td></td>
                            <td>{row.subthematic}</td>
                            <td>{this.valuesTextCovert(row.value)}</td>
                        </tr>
                )

            }


            )

        )

    }

    valuesTextCovert(value) {

    switch (value) {
        case 0:
            return "NA";
        case 0.75:
            return translate("Low");
        case 1:
            return translate("Low");
        case 2:
            return translate("Medium");
        case 3:
            return translate("High");
        default:
            return "";

    }

}


render() {



    renderlog("MaterialityReportModal", this.state, this.props, COLOR_POLICY.validated);

    let context = (
        <React.Fragment>
            <Spinner className="spinner-border-sm" />
            <span className={"loading-text-alignment"}>Caricamento...</span>
        </React.Fragment>
    );



    if (!!this.state.data) {

        context = (



            <React.Fragment style={{ alignContent: 'center' }}>
                <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                    <Col>

                        <Table style={{ textAlign: "left", fontSize: "11px", overflow: "show" }}>
                            <thead>
                                <tr>
                                    <th>{translate("Topic_SubTopic")}</th>
                                    <th>
                                        {
                                            !!this.props.isGenderGap
                                                ? null
                                                : "Sottotematica"
                                        }
                                    </th>
                                    <th>{translate("Value")}</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.generateTable(this.state.data)

                                }


                            </tbody>
                        </Table>




                    </Col>
                </Row>

            </React.Fragment>
        );
    }




    let content = (
        <Modal isOpen={true} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                {translate("Materiality")}
            </ModalHeader>
            <ModalBody className={this.state.bodyClass}>
                {context}
            </ModalBody>
            {/*
                <ModalFooter>
                </ModalFooter>
                */}
        </Modal>


    );



    if (!!this.props.NoModal) {
        return context;
    } else {
        return content;
    }


}

componentDidMount() {
    this.loadData();
}







}




