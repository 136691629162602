import React from "react";
import Spinner from "./Spinner";
import {translate} from "./i18n/translate-function";

/**
 * Questo componente crea uno spinner di attesa o un messaggio di mandcanza dati
 * <br/>
 * props:
 * - <b>waiting</b>: <i>boolean</i> flag per lo switch da attesa a mancanza dati
 * - <b>nodataMessage</b>: <i>string</i> messaggio di mancanza dati
 * - <b>waitMessage</b>: <i>string</i> messaggio di attesa
 * - <b>spinnerColor</b>: <i>string</i> colore predefinito della rotellina ( solo alcuni: success, primary, ... )
 * - <b>fullHeight</b>: <i>boolean</i> flag per indicare se il componente è un componente di pagina o di componente
 * - <b>className</b>: <i>string</i> classe del component
 * - <b>spinnerClass</b>: <i>string</i> classe dello spinner
 *
 * <br/>
 * <br/>
 * <br/>
 * <br/>
 * <table>
 *     <tr><th>prop</th><th>tipo</th><th>descrizione</th></tr>
 *     <tr><td>waiting</td><td>boolean</td><td>flag attesa o mancanza dati</td></tr>
 *     <tr><td>nodataMessage</td><td>string</td><td>testo mancanza dati</td></tr>
 *     <tr><td>waitMessage</td><td>string</td><td>messaggio di attesa</td></tr>
 *     <tr><td>spinnerColor</td><td>string</td><td>colore predefinito della rotellina ( solo alcuni: success, primary, ... )</td></tr>
 *     <tr><td>fullHeight</td><td>boolean</td><td>componente di pagina o di componente</td></tr>
 *     <tr><td>className</td><td>string</td><td>classe del component</td></tr>
 *     <tr><td>spinnerClass</td><td>string</td><td>classe dello spinner</td></tr>
 * </table>
 *
 *
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const WaitingOrNodataMessage = props => (
    (!!props.waiting)
        ? (
            <div
                className={
                    "centered-block"
                    + (!!props.fullHeight ? " almost-full-height" : "")
                    + (!!props.className ? " "+ props.className : "")
                }
            >
                <Spinner color={ !!props.spinnerColor ? props.spinnerColor : "primary" } className={ (!!props.spinnerClass ? props.spinnerClass : "spinner-border-sm") } />
                {
                    !!props.waitMessage
                        ? <span>&nbsp;&nbsp;{ props.waitMessage }&nbsp;&nbsp;</span>
                        : <span>&nbsp;&nbsp;{translate("Loading_")}&nbsp;&nbsp;</span>
                }
            </div>
        )
        : (
            <div
                className={
                    "centered-block"
                    + (!!props.fullHeight ? " almost-full-height" : "")
                    + (!!props.className ? " "+ props.className : "")
                }
            >
                {/*<span>{ emoj(EMOJ.sad_but_relieved_face) }&nbsp;&nbsp;&nbsp;&nbsp;{ props.nodataMessage }</span>*/}
                <span>{ props.nodataMessage }</span>
            </div>
        )
)
