// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import CardBody from "reactstrap/es/CardBody";
import {Button, Card, CardHeader, Col, Row} from "reactstrap";
import {PATH_PREFIX, permissions, role} from "../env";
import {loadCollect} from "../business-logic/gri";
import {Link} from "react-router-dom";
import {hasPermission, isUserRole} from "../business-logic/auth";
import AddGriCollectModal from "../components/area/gri-admin/add-gri-collect-modal";
import Spinner from "../components/Spinner";
import {renderlog, showlog} from "../utils/dev-utils";
import {HOST} from "../../src/env";
import * as info from "../../package.json";



export default class CredentialsPage extends React.Component {

    state = {}

    constructor(props) {
        super( props );
    }

    render() {

        renderlog("credentials", this.state, this.props);

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        {label: 'Credentials Page', path: '' + PATH_PREFIX + '/credentials', active: true}
                    ]}
                    title={'Credentials'}
                />



                <div>
                    <span>Versione:&nbsp;&nbsp;<code>{ info.version }</code></span>
                </div>

                {/*
                <div>
                    <span>Commit:&nbsp;&nbsp;<code>{ info.commit }</code></span>
                </div>
                */}

                {
                    (!!this.state)
                        ? (
                            Object.keys( this.state )
                                .map( key => (
                                    <div>
                                        <span>{ key }:&nbsp;&nbsp;<code>{ this.state[ key ] }</code></span>
                                    </div>
                                ))
                        )
                        : null
                }

            </React.Fragment>
        );
    }

    componentDidMount() {

        if( !!document.getElementById("lastUpdate") && !!document.getElementById("lastUpdate").value ) {
            let lastUpdate = document.getElementById("lastUpdate").value.split(",");
            this.setState({
                lastUpdateTime: lastUpdate[0] || "",
                fingerprint: lastUpdate[1] || ""
            })
        }

    }


};

