import React from "react";
import { Component } from "react";
import {
    Modal,
    ModalHeader,
    Button,
    ModalBody, Row, Col, Label, Table, ModalFooter
} from 'reactstrap';
import Spinner from '../../Spinner';
import { errorlog, renderlog, showlog } from "../../../utils/dev-utils";
import { getLastElementOr, isNotEmptyArray } from "../../../utils/array-utils";
import { COLOR_POLICY, permissions, QUESTION_TEXT_COLOR_POLICY } from "../../../env";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { hasPermission } from "../../../business-logic/auth";
// import { Score_Get } from "../../../../src/services/request-manager";
import { cloneObject } from "../../../utils/object-utils";
import {getPreviewAttachmentsQuestions, getSendAttachmentsQuestions} from "../../../business-logic/score";
import styles from "../../../../src/style/sdgTable.css";
import {translate} from "../../i18n/translate-function";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import parse from "html-react-parser";




const fieldSdgMap = {
    thematic_name: "Tematica/Sottotematica",
    subthematic_name: "Sottotematica",
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    materiality: "Livello di Materialità",
    percentage: "%",
    sdg: "SDG"
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];











const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];


const hiddenSdgFields2 = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];




export default class SendMailAttachmentsQuestions extends Component {


    sendMail = () => {

        this.setState({send:1});


        //return Score_Get(this.props.evaluation_id, 0).then(r => {
        return (

            getSendAttachmentsQuestions(this.props.evaluation_id)
                .then(r => {

                    this.setState({send:2});

                })

                .catch(e => {
                    return false;
                    console.log("errore");
                    console.log(e);
                })

        );







    }






    loadData() {

        this.importData()
            .then(data => {


                console.log("dasdgsaydguyds", data);

                // debugger
                this.setState({
                    data: true,
                    status: data.status


                })

            });


    }


    state = {
        data: false,
        status: false,
        send: 0,
        preview: false
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }



    render() {



        renderlog("SendMailAttachModal", this.state, this.props, COLOR_POLICY.validated);


        let content

        if (this.state.send === 0) {
            content = (
                <React.Fragment>
                    <br/>
                    <span className={"loading-text-alignment"} style={{fontSize:"24px", marginRight:"223px"}}>Sei sicuro di voler inviare l'email?</span>
                    <br/><br/>
                    <div className="button-list" style={{marginLeft:"10px"}}>
                        <Button type="button" color="success"
                            onClick={()=>{
                                this.sendMail();
                            }}
                        >
                            Invia Email
                        </Button>
                        <Button type="button" color="secondary"
                            onClick={()=>{
                            this.toggle();
                        }}
                        >
                            Annulla
                        </Button>
                    </div>
                    <br/>
                </React.Fragment>
            );
        }


        if (this.state.send === 1) {
            content = (
                <React.Fragment>
                    <Spinner className="spinner-border-sm" />
                    <span className={"loading-text-alignment"}>Invio in corso...</span>
                </React.Fragment>
            );
        }


        if (this.state.send === 2) {
            content = (

                <React.Fragment style={{ alignContent: 'center' }}>
                    <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                        <Col>
                            <br />
                            <p style={{ color: 'green', fontWeight: 'bold', fontSize: '18px' }}>
                                {"Email Inviata con Successo !"}
                            </p>
                        </Col>
                    </Row>

                </React.Fragment>
            );
        }


        let  modal = (
            <Modal isOpen={true} toggle={this.toggle} className={ "prev-modal"}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Invio Email delle Domande per cui è stato richiesto l'allegato
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {
                        (!this.state.preview) ?
                            (
                                <WaitingOrNodataMessage waiting={ true } waitMessage={translate("Loading_")} spinnerColor={"success"}/>
                            ) : (

                                <div>
                                    {this.state.preview}
                                </div>
                            )
                    }
                </ModalBody>
            <ModalFooter>
                {content}
            </ModalFooter>

            </Modal>


        );




        return modal;
    }

    createComponent = (str) => parse( str );

    componentDidMount() {
        getPreviewAttachmentsQuestions(this.props.evaluation_id)
            // .then(resposne => showlog("RESPONSE", resposne))
            .then(response =>{

                this.setState({
                    ...this.state,
                    preview: this.createComponent( response.html )
                })
            })
    }







}




