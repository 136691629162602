// https://unicode.org/emoji/charts/full-emoji-list.html
export const EMOJ = {
	first_place_medal: "0x1F947",
	second_place_medal: "0x1F948",
	third_place_medal: "0x1F949",
	A_button_blood_type: "0x1F170",
	AB_button_blood_type: "0x1F18E",
	abacus: "0x1F9EE",
	accordion: "0x1FA97",
	adhesive_bandage: "0x1FA79",
	admission_tickets: "0x1F39F",
	aerial_tramway: "0x1F6A1",
	airplane: "0x2708",
	airplane_arrival: "0x1F6EC",
	airplane_departure: "0x1F6EB",
	alarm_clock: "0x23F0",
	alembic: "0x2697",
	alien: "0x1F47D",
	alien_monster: "0x1F47E",
	ambulance: "0x1F691",
	american_football: "0x1F3C8",
	amphora: "0x1F3FA",
	anatomical_heart: "0x1FAC0",
	anchor: "0x2693",
	anger_symbol: "0x1F4A2",
	angry_face: "0x1F620",
	angry_face_with_horns: "0x1F47F",
	anguished_face: "0x1F627",
	ant: "0x1F41C",
	antenna_bars: "0x1F4F6",
	anxious_face_with_sweat: "0x1F630",
	Aquarius: "0x2652",
	Aries: "0x2648",
	articulated_lorry: "0x1F69B",
	artist: "0x1F9D1,0x200D,0x1F3A8",
	artist_palette: "0x1F3A8",
	astonished_face: "0x1F632",
	astronaut: "0x1F9D1,0x200D,0x1F680",
	ATM_sign: "0x1F3E7",
	atom_symbol: "0x269B",
	auto_rickshaw: "0x1F6FA",
	automobile: "0x1F697",
	avocado: "0x1F951",
	axe: "0x1FA93",
	B_button_blood_type: "0x1F171",
	baby: "0x1F476",
	baby_angel: "0x1F47C",
	baby_bottle: "0x1F37C",
	baby_chick: "0x1F424",
	baby_symbol: "0x1F6BC",
	BACK_arrow: "0x1F519",
	backhand_index_pointing_down: "0x1F447",
	backhand_index_pointing_left: "0x1F448",
	backhand_index_pointing_right: "0x1F449",
	backhand_index_pointing_up: "0x1F446",
	backpack: "0x1F392",
	bacon: "0x1F953",
	badger: "0x1F9A1",
	badminton: "0x1F3F8",
	bagel: "0x1F96F",
	baggage_claim: "0x1F6C4",
	baguette_bread: "0x1F956",
	balance_scale: "0x2696",
	bald: "0x1F9B2",
	ballet_shoes: "0x1FA70",
	balloon: "0x1F388",
	ballot_box_with_ballot: "0x1F5F3",
	banana: "0x1F34C",
	banjo: "0x1FA95",
	bank: "0x1F3E6",
	bar_chart: "0x1F4CA",
	barber_pole: "0x1F488",
	baseball: "0x26BE",
	basket: "0x1F9FA",
	basketball: "0x1F3C0",
	bat: "0x1F987",
	bathtub: "0x1F6C1",
	battery: "0x1F50B",
	beach_with_umbrella: "0x1F3D6",
	beaming_face_with_smiling_eyes: "0x1F601",
	bear: "0x1F43B",
	beating_heart: "0x1F493",
	beaver: "0x1F9AB",
	bed: "0x1F6CF",
	beer_mug: "0x1F37A",
	beetle: "0x1FAB2",
	bell: "0x1F514",
	bell_pepper: "0x1FAD1",
	bell_with_slash: "0x1F515",
	bellhop_bell: "0x1F6CE",
	bento_box: "0x1F371",
	beverage_box: "0x1F9C3",
	bicycle: "0x1F6B2",
	bikini: "0x1F459",
	billed_cap: "0x1F9E2",
	biohazard: "0x2623",
	bird: "0x1F426",
	birthday_cake: "0x1F382",
	bison: "0x1F9AC",
	black_cat: "0x1F408,0x200D,0x2B1B",
	black_circle: "0x26AB",
	black_flag: "0x1F3F4",
	black_heart: "0x1F5A4",
	black_large_square: "0x2B1B",
	black_medium_square: "0x25FC",
	black_medium_small_square: "0x25FE",
	black_nib: "0x2712",
	black_small_square: "0x25AA",
	black_square_button: "0x1F532",
	blossom: "0x1F33C",
	blowfish: "0x1F421",
	blue_book: "0x1F4D8",
	blue_circle: "0x1F535",
	blue_heart: "0x1F499",
	blue_square: "0x1F7E6",
	blueberries: "0x1FAD0",
	boar: "0x1F417",
	bomb: "0x1F4A3",
	bone: "0x1F9B4",
	bookmark: "0x1F516",
	bookmark_tabs: "0x1F4D1",
	books: "0x1F4DA",
	boomerang: "0x1FA83",
	bottle_with_popping_cork: "0x1F37E",
	bouquet: "0x1F490",
	bow_and_arrow: "0x1F3F9",
	bowl_with_spoon: "0x1F963",
	bowling: "0x1F3B3",
	boxing_glove: "0x1F94A",
	boy: "0x1F466",
	brain: "0x1F9E0",
	bread: "0x1F35E",
	breast_feeding: "0x1F931",
	brick: "0x1F9F1",
	bridge_at_night: "0x1F309",
	briefcase: "0x1F4BC",
	briefs: "0x1FA72",
	bright_button: "0x1F506",
	broccoli: "0x1F966",
	broken_heart: "0x1F494",
	broom: "0x1F9F9",
	brown_circle: "0x1F7E4",
	brown_heart: "0x1F90E",
	brown_square: "0x1F7EB",
	bubble_tea: "0x1F9CB",
	bucket: "0x1FAA3",
	bug: "0x1F41B",
	building_construction: "0x1F3D7",
	bullet_train: "0x1F685",
	bullseye: "0x1F3AF",
	burrito: "0x1F32F",
	bus: "0x1F68C",
	bus_stop: "0x1F68F",
	bust_in_silhouette: "0x1F464",
	busts_in_silhouette: "0x1F465",
	butter: "0x1F9C8",
	butterfly: "0x1F98B",
	cactus: "0x1F335",
	calendar: "0x1F4C5",
	call_me_hand: "0x1F919",
	camel: "0x1F42A",
	camera: "0x1F4F7",
	camera_with_flash: "0x1F4F8",
	camping: "0x1F3D5",
	Cancer: "0x264B",
	candle: "0x1F56F",
	candy: "0x1F36C",
	canned_food: "0x1F96B",
	canoe: "0x1F6F6",
	Capricorn: "0x2651",
	card_file_box: "0x1F5C3",
	card_index: "0x1F4C7",
	card_index_dividers: "0x1F5C2",
	carousel_horse: "0x1F3A0",
	carp_streamer: "0x1F38F",
	carpentry_saw: "0x1FA9A",
	carrot: "0x1F955",
	castle: "0x1F3F0",
	cat: "0x1F408",
	cat_face: "0x1F431",
	cat_with_tears_of_joy: "0x1F639",
	cat_with_wry_smile: "0x1F63C",
	chains: "0x26D3",
	chair: "0x1FA91",
	chart_decreasing: "0x1F4C9",
	chart_increasing: "0x1F4C8",
	chart_increasing_with_yen: "0x1F4B9",
	check_box_with_check: "0x2611",
	check_mark: "0x2714",
	check_mark_button: "0x2705",
	cheese_wedge: "0x1F9C0",
	chequered_flag: "0x1F3C1",
	cherries: "0x1F352",
	cherry_blossom: "0x1F338",
	chess_pawn: "0x265F",
	chestnut: "0x1F330",
	chicken: "0x1F414",
	child: "0x1F9D2",
	children_crossing: "0x1F6B8",
	chipmunk: "0x1F43F",
	chocolate_bar: "0x1F36B",
	chopsticks: "0x1F962",
	Christmas_tree: "0x1F384",
	church: "0x26EA",
	cigarette: "0x1F6AC",
	cinema: "0x1F3A6",
	circled_M: "0x24C2",
	circus_tent: "0x1F3AA",
	cityscape: "0x1F3D9",
	cityscape_at_dusk: "0x1F306",
	CL_button: "0x1F191",
	clamp: "0x1F5DC",
	clapper_board: "0x1F3AC",
	clapping_hands: "0x1F44F",
	classical_building: "0x1F3DB",
	clinking_beer_mugs: "0x1F37B",
	clinking_glasses: "0x1F942",
	clipboard: "0x1F4CB",
	clockwise_vertical_arrows: "0x1F503",
	closed_book: "0x1F4D5",
	closed_mailbox_with_lowered_flag: "0x1F4EA",
	closed_mailbox_with_raised_flag: "0x1F4EB",
	closed_umbrella: "0x1F302",
	cloud: "0x2601",
	cloud_with_lightning: "0x1F329",
	cloud_with_lightning_and_rain: "0x26C8",
	cloud_with_rain: "0x1F327",
	cloud_with_snow: "0x1F328",
	clown_face: "0x1F921",
	club_suit: "0x2663",
	clutch_bag: "0x1F45D",
	coat: "0x1F9E5",
	cockroach: "0x1FAB3",
	cocktail_glass: "0x1F378",
	coconut: "0x1F965",
	coffin: "0x26B0",
	coin: "0x1FA99",
	cold_face: "0x1F976",
	collision: "0x1F4A5",
	comet: "0x2604",
	compass: "0x1F9ED",
	computer_disk: "0x1F4BD",
	computer_mouse: "0x1F5B1",
	confetti_ball: "0x1F38A",
	confounded_face: "0x1F616",
	confused_face: "0x1F615",
	construction: "0x1F6A7",
	construction_worker: "0x1F477",
	control_knobs: "0x1F39B",
	convenience_store: "0x1F3EA",
	cook: "0x1F9D1,0x200D,0x1F373",
	cooked_rice: "0x1F35A",
	cookie: "0x1F36A",
	cooking: "0x1F373",
	COOL_button: "0x1F192",
	copyright: "0x00A9",
	couch_and_lamp: "0x1F6CB",
	counterclockwise_arrows_button: "0x1F504",
	couple_with_heart: "0x1F491",
	couple_with_heart__man_man: "0x1F468,0x200D,0x2764,0xFE0F,0x200D,0x1F468",
	couple_with_heart__woman_man: "0x1F469,0x200D,0x2764,0xFE0F,0x200D,0x1F468",
	couple_with_heart__woman_woman: "0x1F469,0x200D,0x2764,0xFE0F,0x200D,0x1F469",
	cow: "0x1F404",
	cow_face: "0x1F42E",
	cowboy_hat_face: "0x1F920",
	crab: "0x1F980",
	crayon: "0x1F58D",
	credit_card: "0x1F4B3",
	crescent_moon: "0x1F319",
	cricket: "0x1F997",
	cricket_game: "0x1F3CF",
	crocodile: "0x1F40A",
	croissant: "0x1F950",
	cross_mark: "0x274C",
	cross_mark_button: "0x274E",
	crossed_fingers: "0x1F91E",
	crossed_flags: "0x1F38C",
	crossed_swords: "0x2694",
	crown: "0x1F451",
	crying_cat: "0x1F63F",
	crying_face: "0x1F622",
	crystal_ball: "0x1F52E",
	cucumber: "0x1F952",
	cup_with_straw: "0x1F964",
	cupcake: "0x1F9C1",
	curling_stone: "0x1F94C",
	curly_hair: "0x1F9B1",
	curly_loop: "0x27B0",
	currency_exchange: "0x1F4B1",
	curry_rice: "0x1F35B",
	custard: "0x1F36E",
	customs: "0x1F6C3",
	cut_of_meat: "0x1F969",
	cyclone: "0x1F300",
	dagger: "0x1F5E1",
	dango: "0x1F361",
	dashing_away: "0x1F4A8",
	deaf_man: "0x1F9CF,0x200D,0x2642,0xFE0F",
	deaf_person: "0x1F9CF",
	deaf_woman: "0x1F9CF,0x200D,0x2640,0xFE0F",
	deciduous_tree: "0x1F333",
	deer: "0x1F98C",
	delivery_truck: "0x1F69A",
	department_store: "0x1F3EC",
	derelict_house: "0x1F3DA",
	desert: "0x1F3DC",
	desert_island: "0x1F3DD",
	desktop_computer: "0x1F5A5",
	detective: "0x1F575",
	diamond_suit: "0x2666",
	diamond_with_a_dot: "0x1F4A0",
	dim_button: "0x1F505",
	disappointed_face: "0x1F61E",
	disguised_face: "0x1F978",
	divide: "0x2797",
	diving_mask: "0x1F93F",
	diya_lamp: "0x1FA94",
	dizzy: "0x1F4AB",
	dna: "0x1F9EC",
	dodo: "0x1F9A4",
	dog: "0x1F415",
	dog_face: "0x1F436",
	dollar_banknote: "0x1F4B5",
	dolphin: "0x1F42C",
	door: "0x1F6AA",
	dotted_six_pointed_star: "0x1F52F",
	double_curly_loop: "0x27BF",
	double_exclamation_mark: "0x203C",
	doughnut: "0x1F369",
	dove: "0x1F54A",
	down_arrow: "0x2B07",
	down_left_arrow: "0x2199",
	down_right_arrow: "0x2198",
	downcast_face_with_sweat: "0x1F613",
	downwards_button: "0x1F53D",
	dragon: "0x1F409",
	dragon_face: "0x1F432",
	dress: "0x1F457",
	drooling_face: "0x1F924",
	drop_of_blood: "0x1FA78",
	droplet: "0x1F4A7",
	drum: "0x1F941",
	duck: "0x1F986",
	dumpling: "0x1F95F",
	dvd: "0x1F4C0",
	e_mail: "0x1F4E7",
	eagle: "0x1F985",
	ear: "0x1F442",
	ear_of_corn: "0x1F33D",
	ear_with_hearing_aid: "0x1F9BB",
	egg: "0x1F95A",
	eggplant: "0x1F346",
	eight_o_clock: "0x1F557",
	eight_pointed_star: "0x2734",
	eight_spoked_asterisk: "0x2733",
	eight_thirty: "0x1F563",
	eject_button: "0x23CF",
	electric_plug: "0x1F50C",
	elephant: "0x1F418",
	elevator: "0x1F6D7",
	eleven_o_clock: "0x1F55A",
	eleven_thirty: "0x1F566",
	elf: "0x1F9DD",
	END_arrow: "0x1F51A",
	envelope: "0x2709",
	envelope_with_arrow: "0x1F4E9",
	euro_banknote: "0x1F4B6",
	evergreen_tree: "0x1F332",
	ewe: "0x1F411",
	exclamation_question_mark: "0x2049",
	exploding_head: "0x1F92F",
	expressionless_face: "0x1F611",
	eye: "0x1F441",
	eye_in_speech_bubble: "0x1F441,0xFE0F,0x200D,0x1F5E8,0xFE0F",
	eyes: "0x1F440",
	face_blowing_a_kiss: "0x1F618",
	face_savoring_food: "0x1F60B",
	face_screaming_in_fear: "0x1F631",
	face_vomiting: "0x1F92E",
	face_with_hand_over_mouth: "0x1F92D",
	face_with_head_bandage: "0x1F915",
	face_with_medical_mask: "0x1F637",
	face_with_monocle: "0x1F9D0",
	face_with_open_mouth: "0x1F62E",
	face_with_raised_eyebrow: "0x1F928",
	face_with_rolling_eyes: "0x1F644",
	face_with_steam_from_nose: "0x1F624",
	face_with_symbols_on_mouth: "0x1F92C",
	face_with_tears_of_joy: "0x1F602",
	face_with_thermometer: "0x1F912",
	face_with_tongue: "0x1F61B",
	face_without_mouth: "0x1F636",
	factory: "0x1F3ED",
	factory_worker: "0x1F9D1,0x200D,0x1F3ED",
	fairy: "0x1F9DA",
	falafel: "0x1F9C6",
	fallen_leaf: "0x1F342",
	family: "0x1F46A",
	family__man_boy: "0x1F468,0x200D,0x1F466",
	family__man_boy_boy: "0x1F468,0x200D,0x1F466,0x200D,0x1F466",
	family__man_girl: "0x1F468,0x200D,0x1F467",
	family__man_girl_boy: "0x1F468,0x200D,0x1F467,0x200D,0x1F466",
	family__man_girl_girl: "0x1F468,0x200D,0x1F467,0x200D,0x1F467",
	family__man_man_boy: "0x1F468,0x200D,0x1F468,0x200D,0x1F466",
	family__man_man_boy_boy: "0x1F468,0x200D,0x1F468,0x200D,0x1F466,0x200D,0x1F466",
	family__man_man_girl: "0x1F468,0x200D,0x1F468,0x200D,0x1F467",
	family__man_man_girl_boy: "0x1F468,0x200D,0x1F468,0x200D,0x1F467,0x200D,0x1F466",
	family__man_man_girl_girl: "0x1F468,0x200D,0x1F468,0x200D,0x1F467,0x200D,0x1F467",
	family__man_woman_boy: "0x1F468,0x200D,0x1F469,0x200D,0x1F466",
	family__man_woman_boy_boy: "0x1F468,0x200D,0x1F469,0x200D,0x1F466,0x200D,0x1F466",
	family__man_woman_girl: "0x1F468,0x200D,0x1F469,0x200D,0x1F467",
	family__man_woman_girl_boy: "0x1F468,0x200D,0x1F469,0x200D,0x1F467,0x200D,0x1F466",
	family__man_woman_girl_girl: "0x1F468,0x200D,0x1F469,0x200D,0x1F467,0x200D,0x1F467",
	family__woman_boy: "0x1F469,0x200D,0x1F466",
	family__woman_boy_boy: "0x1F469,0x200D,0x1F466,0x200D,0x1F466",
	family__woman_girl: "0x1F469,0x200D,0x1F467",
	family__woman_girl_boy: "0x1F469,0x200D,0x1F467,0x200D,0x1F466",
	family__woman_girl_girl: "0x1F469,0x200D,0x1F467,0x200D,0x1F467",
	family__woman_woman_boy: "0x1F469,0x200D,0x1F469,0x200D,0x1F466",
	family__woman_woman_boy_boy: "0x1F469,0x200D,0x1F469,0x200D,0x1F466,0x200D,0x1F466",
	family__woman_woman_girl: "0x1F469,0x200D,0x1F469,0x200D,0x1F467",
	family__woman_woman_girl_boy: "0x1F469,0x200D,0x1F469,0x200D,0x1F467,0x200D,0x1F466",
	family__woman_woman_girl_girl: "0x1F469,0x200D,0x1F469,0x200D,0x1F467,0x200D,0x1F467",
	farmer: "0x1F9D1,0x200D,0x1F33E",
	fast_down_button: "0x23EC",
	fast_reverse_button: "0x23EA",
	fast_up_button: "0x23EB",
	fast_forward_button: "0x23E9",
	fax_machine: "0x1F4E0",
	fearful_face: "0x1F628",
	feather: "0x1FAB6",
	female_sign: "0x2640",
	ferris_wheel: "0x1F3A1",
	ferry: "0x26F4",
	field_hockey: "0x1F3D1",
	file_cabinet: "0x1F5C4",
	file_folder: "0x1F4C1",
	film_frames: "0x1F39E",
	film_projector: "0x1F4FD",
	fire: "0x1F525",
	fire_engine: "0x1F692",
	fire_extinguisher: "0x1F9EF",
	firecracker: "0x1F9E8",
	firefighter: "0x1F9D1,0x200D,0x1F692",
	fireworks: "0x1F386",
	first_quarter_moon: "0x1F313",
	first_quarter_moon_face: "0x1F31B",
	fish: "0x1F41F",
	fish_cake_with_swirl: "0x1F365",
	fishing_pole: "0x1F3A3",
	five_o_clock: "0x1F554",
	five_thirty: "0x1F560",
	flag_in_hole: "0x26F3",
	flag__Afghanistan: "0x1F1E6,0x1F1EB",
	flag__Aland_Islands: "0x1F1E6,0x1F1FD",
	flag__Albania: "0x1F1E6,0x1F1F1",
	flag__Algeria: "0x1F1E9,0x1F1FF",
	flag__American_Samoa: "0x1F1E6,0x1F1F8",
	flag__Andorra: "0x1F1E6,0x1F1E9",
	flag__Angola: "0x1F1E6,0x1F1F4",
	flag__Anguilla: "0x1F1E6,0x1F1EE",
	flag__Antarctica: "0x1F1E6,0x1F1F6",
	flag__Antigua_and_Barbuda: "0x1F1E6,0x1F1EC",
	flag__Argentina: "0x1F1E6,0x1F1F7",
	flag__Armenia: "0x1F1E6,0x1F1F2",
	flag__Aruba: "0x1F1E6,0x1F1FC",
	flag__Ascension_Island: "0x1F1E6,0x1F1E8",
	flag__Australia: "0x1F1E6,0x1F1FA",
	flag__Austria: "0x1F1E6,0x1F1F9",
	flag__Azerbaijan: "0x1F1E6,0x1F1FF",
	flag__Bahamas: "0x1F1E7,0x1F1F8",
	flag__Bahrain: "0x1F1E7,0x1F1ED",
	flag__Bangladesh: "0x1F1E7,0x1F1E9",
	flag__Barbados: "0x1F1E7,0x1F1E7",
	flag__Belarus: "0x1F1E7,0x1F1FE",
	flag__Belgium: "0x1F1E7,0x1F1EA",
	flag__Belize: "0x1F1E7,0x1F1FF",
	flag__Benin: "0x1F1E7,0x1F1EF",
	flag__Bermuda: "0x1F1E7,0x1F1F2",
	flag__Bhutan: "0x1F1E7,0x1F1F9",
	flag__Bolivia: "0x1F1E7,0x1F1F4",
	flag__Bosnia_and_Herzegovina: "0x1F1E7,0x1F1E6",
	flag__Botswana: "0x1F1E7,0x1F1FC",
	flag__Bouvet_Island: "0x1F1E7,0x1F1FB",
	flag__Brazil: "0x1F1E7,0x1F1F7",
	flag__British_Indian_Ocean_Territory: "0x1F1EE,0x1F1F4",
	flag__British_Virgin_Islands: "0x1F1FB,0x1F1EC",
	flag__Brunei: "0x1F1E7,0x1F1F3",
	flag__Bulgaria: "0x1F1E7,0x1F1EC",
	flag__Burkina_Faso: "0x1F1E7,0x1F1EB",
	flag__Burundi: "0x1F1E7,0x1F1EE",
	flag__Cambodia: "0x1F1F0,0x1F1ED",
	flag__Cameroon: "0x1F1E8,0x1F1F2",
	flag__Canada: "0x1F1E8,0x1F1E6",
	flag__Canary_Islands: "0x1F1EE,0x1F1E8",
	flag__Cape_Verde: "0x1F1E8,0x1F1FB",
	flag__Caribbean_Netherlands: "0x1F1E7,0x1F1F6",
	flag__Cayman_Islands: "0x1F1F0,0x1F1FE",
	flag__Central_African_Republic: "0x1F1E8,0x1F1EB",
	flag__Ceuta_and_Melilla: "0x1F1EA,0x1F1E6",
	flag__Chad: "0x1F1F9,0x1F1E9",
	flag__Chile: "0x1F1E8,0x1F1F1",
	flag__China: "0x1F1E8,0x1F1F3",
	flag__Christmas_Island: "0x1F1E8,0x1F1FD",
	flag__Clipperton_Island: "0x1F1E8,0x1F1F5",
	flag__Cocos_Keeling_Islands: "0x1F1E8,0x1F1E8",
	flag__Colombia: "0x1F1E8,0x1F1F4",
	flag__Comoros: "0x1F1F0,0x1F1F2",
	flag__Congo___Brazzaville: "0x1F1E8,0x1F1EC",
	flag__Congo___Kinshasa: "0x1F1E8,0x1F1E9",
	flag__Cook_Islands: "0x1F1E8,0x1F1F0",
	flag__Costa_Rica: "0x1F1E8,0x1F1F7",
	flag__Cote_d_Ivoire: "0x1F1E8,0x1F1EE",
	flag__Croatia: "0x1F1ED,0x1F1F7",
	flag__Cuba: "0x1F1E8,0x1F1FA",
	flag__Curacao: "0x1F1E8,0x1F1FC",
	flag__Cyprus: "0x1F1E8,0x1F1FE",
	flag__Czechia: "0x1F1E8,0x1F1FF",
	flag__Denmark: "0x1F1E9,0x1F1F0",
	flag__Diego_Garcia: "0x1F1E9,0x1F1EC",
	flag__Djibouti: "0x1F1E9,0x1F1EF",
	flag__Dominica: "0x1F1E9,0x1F1F2",
	flag__Dominican_Republic: "0x1F1E9,0x1F1F4",
	flag__Ecuador: "0x1F1EA,0x1F1E8",
	flag__Egypt: "0x1F1EA,0x1F1EC",
	flag__El_Salvador: "0x1F1F8,0x1F1FB",
	flag__England: "0x1F3F4,0xE0067,0xE0062,0xE0065,0xE006E,0xE0067,0xE007F",
	flag__Equatorial_Guinea: "0x1F1EC,0x1F1F6",
	flag__Eritrea: "0x1F1EA,0x1F1F7",
	flag__Estonia: "0x1F1EA,0x1F1EA",
	flag__Eswatini: "0x1F1F8,0x1F1FF",
	flag__Ethiopia: "0x1F1EA,0x1F1F9",
	flag__European_Union: "0x1F1EA,0x1F1FA",
	flag__Falkland_Islands: "0x1F1EB,0x1F1F0",
	flag__Faroe_Islands: "0x1F1EB,0x1F1F4",
	flag__Fiji: "0x1F1EB,0x1F1EF",
	flag__Finland: "0x1F1EB,0x1F1EE",
	flag__France: "0x1F1EB,0x1F1F7",
	flag__French_Guiana: "0x1F1EC,0x1F1EB",
	flag__French_Polynesia: "0x1F1F5,0x1F1EB",
	flag__French_Southern_Territories: "0x1F1F9,0x1F1EB",
	flag__Gabon: "0x1F1EC,0x1F1E6",
	flag__Gambia: "0x1F1EC,0x1F1F2",
	flag__Georgia: "0x1F1EC,0x1F1EA",
	flag__Germany: "0x1F1E9,0x1F1EA",
	flag__Ghana: "0x1F1EC,0x1F1ED",
	flag__Gibraltar: "0x1F1EC,0x1F1EE",
	flag__Greece: "0x1F1EC,0x1F1F7",
	flag__Greenland: "0x1F1EC,0x1F1F1",
	flag__Grenada: "0x1F1EC,0x1F1E9",
	flag__Guadeloupe: "0x1F1EC,0x1F1F5",
	flag__Guam: "0x1F1EC,0x1F1FA",
	flag__Guatemala: "0x1F1EC,0x1F1F9",
	flag__Guernsey: "0x1F1EC,0x1F1EC",
	flag__Guinea: "0x1F1EC,0x1F1F3",
	flag__Guinea_Bissau: "0x1F1EC,0x1F1FC",
	flag__Guyana: "0x1F1EC,0x1F1FE",
	flag__Haiti: "0x1F1ED,0x1F1F9",
	flag__Heard_and_McDonald_Islands: "0x1F1ED,0x1F1F2",
	flag__Honduras: "0x1F1ED,0x1F1F3",
	flag__Hong_Kong_SAR_China: "0x1F1ED,0x1F1F0",
	flag__Hungary: "0x1F1ED,0x1F1FA",
	flag__Iceland: "0x1F1EE,0x1F1F8",
	flag__India: "0x1F1EE,0x1F1F3",
	flag__Indonesia: "0x1F1EE,0x1F1E9",
	flag__Iran: "0x1F1EE,0x1F1F7",
	flag__Iraq: "0x1F1EE,0x1F1F6",
	flag__Ireland: "0x1F1EE,0x1F1EA",
	flag__Isle_of_Man: "0x1F1EE,0x1F1F2",
	flag__Israel: "0x1F1EE,0x1F1F1",
	// flag__Italy: "0x1F1EE,0x1F1F9",
	flag__Italy: "0x1F1EE",
	flag__Jamaica: "0x1F1EF,0x1F1F2",
	flag__Japan: "0x1F1EF,0x1F1F5",
	flag__Jersey: "0x1F1EF,0x1F1EA",
	flag__Jordan: "0x1F1EF,0x1F1F4",
	flag__Kazakhstan: "0x1F1F0,0x1F1FF",
	flag__Kenya: "0x1F1F0,0x1F1EA",
	flag__Kiribati: "0x1F1F0,0x1F1EE",
	flag__Kosovo: "0x1F1FD,0x1F1F0",
	flag__Kuwait: "0x1F1F0,0x1F1FC",
	flag__Kyrgyzstan: "0x1F1F0,0x1F1EC",
	flag__Laos: "0x1F1F1,0x1F1E6",
	flag__Latvia: "0x1F1F1,0x1F1FB",
	flag__Lebanon: "0x1F1F1,0x1F1E7",
	flag__Lesotho: "0x1F1F1,0x1F1F8",
	flag__Liberia: "0x1F1F1,0x1F1F7",
	flag__Libya: "0x1F1F1,0x1F1FE",
	flag__Liechtenstein: "0x1F1F1,0x1F1EE",
	flag__Lithuania: "0x1F1F1,0x1F1F9",
	flag__Luxembourg: "0x1F1F1,0x1F1FA",
	flag__Macao_SAR_China: "0x1F1F2,0x1F1F4",
	flag__Madagascar: "0x1F1F2,0x1F1EC",
	flag__Malawi: "0x1F1F2,0x1F1FC",
	flag__Malaysia: "0x1F1F2,0x1F1FE",
	flag__Maldives: "0x1F1F2,0x1F1FB",
	flag__Mali: "0x1F1F2,0x1F1F1",
	flag__Malta: "0x1F1F2,0x1F1F9",
	flag__Marshall_Islands: "0x1F1F2,0x1F1ED",
	flag__Martinique: "0x1F1F2,0x1F1F6",
	flag__Mauritania: "0x1F1F2,0x1F1F7",
	flag__Mauritius: "0x1F1F2,0x1F1FA",
	flag__Mayotte: "0x1F1FE,0x1F1F9",
	flag__Mexico: "0x1F1F2,0x1F1FD",
	flag__Micronesia: "0x1F1EB,0x1F1F2",
	flag__Moldova: "0x1F1F2,0x1F1E9",
	flag__Monaco: "0x1F1F2,0x1F1E8",
	flag__Mongolia: "0x1F1F2,0x1F1F3",
	flag__Montenegro: "0x1F1F2,0x1F1EA",
	flag__Montserrat: "0x1F1F2,0x1F1F8",
	flag__Morocco: "0x1F1F2,0x1F1E6",
	flag__Mozambique: "0x1F1F2,0x1F1FF",
	flag__Myanmar_Burma: "0x1F1F2,0x1F1F2",
	flag__Namibia: "0x1F1F3,0x1F1E6",
	flag__Nauru: "0x1F1F3,0x1F1F7",
	flag__Nepal: "0x1F1F3,0x1F1F5",
	flag__Netherlands: "0x1F1F3,0x1F1F1",
	flag__New_Caledonia: "0x1F1F3,0x1F1E8",
	flag__New_Zealand: "0x1F1F3,0x1F1FF",
	flag__Nicaragua: "0x1F1F3,0x1F1EE",
	flag__Niger: "0x1F1F3,0x1F1EA",
	flag__Nigeria: "0x1F1F3,0x1F1EC",
	flag__Niue: "0x1F1F3,0x1F1FA",
	flag__Norfolk_Island: "0x1F1F3,0x1F1EB",
	flag__North_Korea: "0x1F1F0,0x1F1F5",
	flag__North_Macedonia: "0x1F1F2,0x1F1F0",
	flag__Northern_Mariana_Islands: "0x1F1F2,0x1F1F5",
	flag__Norway: "0x1F1F3,0x1F1F4",
	flag__Oman: "0x1F1F4,0x1F1F2",
	flag__Pakistan: "0x1F1F5,0x1F1F0",
	flag__Palau: "0x1F1F5,0x1F1FC",
	flag__Palestinian_Territories: "0x1F1F5,0x1F1F8",
	flag__Panama: "0x1F1F5,0x1F1E6",
	flag__Papua_New_Guinea: "0x1F1F5,0x1F1EC",
	flag__Paraguay: "0x1F1F5,0x1F1FE",
	flag__Peru: "0x1F1F5,0x1F1EA",
	flag__Philippines: "0x1F1F5,0x1F1ED",
	flag__Pitcairn_Islands: "0x1F1F5,0x1F1F3",
	flag__Poland: "0x1F1F5,0x1F1F1",
	flag__Portugal: "0x1F1F5,0x1F1F9",
	flag__Puerto_Rico: "0x1F1F5,0x1F1F7",
	flag__Qatar: "0x1F1F6,0x1F1E6",
	flag__Reunion: "0x1F1F7,0x1F1EA",
	flag__Romania: "0x1F1F7,0x1F1F4",
	flag__Russia: "0x1F1F7,0x1F1FA",
	flag__Rwanda: "0x1F1F7,0x1F1FC",
	flag__Samoa: "0x1F1FC,0x1F1F8",
	flag__San_Marino: "0x1F1F8,0x1F1F2",
	flag__Sao_Tome_and_Principe: "0x1F1F8,0x1F1F9",
	flag__Saudi_Arabia: "0x1F1F8,0x1F1E6",
	flag__Scotland: "0x1F3F4,0xE0067,0xE0062,0xE0073,0xE0063,0xE0074,0xE007F",
	flag__Senegal: "0x1F1F8,0x1F1F3",
	flag__Serbia: "0x1F1F7,0x1F1F8",
	flag__Seychelles: "0x1F1F8,0x1F1E8",
	flag__Sierra_Leone: "0x1F1F8,0x1F1F1",
	flag__Singapore: "0x1F1F8,0x1F1EC",
	flag__Sint_Maarten: "0x1F1F8,0x1F1FD",
	flag__Slovakia: "0x1F1F8,0x1F1F0",
	flag__Slovenia: "0x1F1F8,0x1F1EE",
	flag__Solomon_Islands: "0x1F1F8,0x1F1E7",
	flag__Somalia: "0x1F1F8,0x1F1F4",
	flag__South_Africa: "0x1F1FF,0x1F1E6",
	flag__South_Georgia_and_South_Sandwich_Islands: "0x1F1EC,0x1F1F8",
	flag__South_Korea: "0x1F1F0,0x1F1F7",
	flag__South_Sudan: "0x1F1F8,0x1F1F8",
	flag__Spain: "0x1F1EA,0x1F1F8",
	flag__Sri_Lanka: "0x1F1F1,0x1F1F0",
	flag__St_Barthelemy: "0x1F1E7,0x1F1F1",
	flag__St_Helena: "0x1F1F8,0x1F1ED",
	flag__St_Kitts_and_Nevis: "0x1F1F0,0x1F1F3",
	flag__St_Lucia: "0x1F1F1,0x1F1E8",
	flag__St_Martin: "0x1F1F2,0x1F1EB",
	flag__St_Pierre_and_Miquelon: "0x1F1F5,0x1F1F2",
	flag__St_Vincent_and_Grenadines: "0x1F1FB,0x1F1E8",
	flag__Sudan: "0x1F1F8,0x1F1E9",
	flag__Suriname: "0x1F1F8,0x1F1F7",
	flag__Svalbard_and_Jan_Mayen: "0x1F1F8,0x1F1EF",
	flag__Sweden: "0x1F1F8,0x1F1EA",
	flag__Switzerland: "0x1F1E8,0x1F1ED",
	flag__Syria: "0x1F1F8,0x1F1FE",
	flag__Taiwan: "0x1F1F9,0x1F1FC",
	flag__Tajikistan: "0x1F1F9,0x1F1EF",
	flag__Tanzania: "0x1F1F9,0x1F1FF",
	flag__Thailand: "0x1F1F9,0x1F1ED",
	flag__Timor_Leste: "0x1F1F9,0x1F1F1",
	flag__Togo: "0x1F1F9,0x1F1EC",
	flag__Tokelau: "0x1F1F9,0x1F1F0",
	flag__Tonga: "0x1F1F9,0x1F1F4",
	flag__Trinidad_and_Tobago: "0x1F1F9,0x1F1F9",
	flag__Tristan_da_Cunha: "0x1F1F9,0x1F1E6",
	flag__Tunisia: "0x1F1F9,0x1F1F3",
	flag__Turkey: "0x1F1F9,0x1F1F7",
	flag__Turkmenistan: "0x1F1F9,0x1F1F2",
	flag__Turks_and_Caicos_Islands: "0x1F1F9,0x1F1E8",
	flag__Tuvalu: "0x1F1F9,0x1F1FB",
	flag__US_Outlying_Islands: "0x1F1FA,0x1F1F2",
	flag__US_Virgin_Islands: "0x1F1FB,0x1F1EE",
	flag__Uganda: "0x1F1FA,0x1F1EC",
	flag__Ukraine: "0x1F1FA,0x1F1E6",
	flag__United_Arab_Emirates: "0x1F1E6,0x1F1EA",
	flag__United_Kingdom: "0x1F1EC,0x1F1E7",
	flag__United_Nations: "0x1F1FA,0x1F1F3",
	flag__United_States: "0x1F1FA,0x1F1F8",
	flag__Uruguay: "0x1F1FA,0x1F1FE",
	flag__Uzbekistan: "0x1F1FA,0x1F1FF",
	flag__Vanuatu: "0x1F1FB,0x1F1FA",
	flag__Vatican_City: "0x1F1FB,0x1F1E6",
	flag__Venezuela: "0x1F1FB,0x1F1EA",
	flag__Vietnam: "0x1F1FB,0x1F1F3",
	flag__Wales: "0x1F3F4,0xE0067,0xE0062,0xE0077,0xE006C,0xE0073,0xE007F",
	flag__Wallis_and_Futuna: "0x1F1FC,0x1F1EB",
	flag__Western_Sahara: "0x1F1EA,0x1F1ED",
	flag__Yemen: "0x1F1FE,0x1F1EA",
	flag__Zambia: "0x1F1FF,0x1F1F2",
	flag__Zimbabwe: "0x1F1FF,0x1F1FC",
	flamingo: "0x1F9A9",
	flashlight: "0x1F526",
	flat_shoe: "0x1F97F",
	flatbread: "0x1FAD3",
	fleur_de_lis: "0x269C",
	flexed_biceps: "0x1F4AA",
	floppy_disk: "0x1F4BE",
	flower_playing_cards: "0x1F3B4",
	flushed_face: "0x1F633",
	fly: "0x1FAB0",
	flying_disc: "0x1F94F",
	flying_saucer: "0x1F6F8",
	fog: "0x1F32B",
	foggy: "0x1F301",
	folded_hands: "0x1F64F",
	fondue: "0x1FAD5",
	foot: "0x1F9B6",
	footprints: "0x1F463",
	fork_and_knife: "0x1F374",
	fork_and_knife_with_plate: "0x1F37D",
	fortune_cookie: "0x1F960",
	fountain: "0x26F2",
	fountain_pen: "0x1F58B",
	four_leaf_clover: "0x1F340",
	four_o_clock: "0x1F553",
	four_thirty: "0x1F55F",
	fox: "0x1F98A",
	framed_picture: "0x1F5BC",
	FREE_button: "0x1F193",
	french_fries: "0x1F35F",
	fried_shrimp: "0x1F364",
	frog: "0x1F438",
	front_facing_baby_chick: "0x1F425",
	frowning_face: "0x2639",
	frowning_face_with_open_mouth: "0x1F626",
	fuel_pump: "0x26FD",
	full_moon: "0x1F315",
	full_moon_face: "0x1F31D",
	funeral_urn: "0x26B1",
	game_die: "0x1F3B2",
	garlic: "0x1F9C4",
	gear: "0x2699",
	gem_stone: "0x1F48E",
	Gemini: "0x264A",
	genie: "0x1F9DE",
	ghost: "0x1F47B",
	giraffe: "0x1F992",
	girl: "0x1F467",
	glass_of_milk: "0x1F95B",
	glasses: "0x1F453",
	globe_showing_Americas: "0x1F30E",
	globe_showing_Asia_Australia: "0x1F30F",
	globe_showing_Europe_Africa: "0x1F30D",
	globe_with_meridians: "0x1F310",
	gloves: "0x1F9E4",
	glowing_star: "0x1F31F",
	goal_net: "0x1F945",
	goat: "0x1F410",
	goblin: "0x1F47A",
	goggles: "0x1F97D",
	gorilla: "0x1F98D",
	graduation_cap: "0x1F393",
	grapes: "0x1F347",
	green_apple: "0x1F34F",
	green_book: "0x1F4D7",
	green_circle: "0x1F7E2",
	green_heart: "0x1F49A",
	green_salad: "0x1F957",
	green_square: "0x1F7E9",
	grimacing_face: "0x1F62C",
	grinning_cat: "0x1F63A",
	grinning_cat_with_smiling_eyes: "0x1F638",
	grinning_face: "0x1F600",
	grinning_face_with_big_eyes: "0x1F603",
	grinning_face_with_smiling_eyes: "0x1F604",
	grinning_face_with_sweat: "0x1F605",
	grinning_squinting_face: "0x1F606",
	growing_heart: "0x1F497",
	guard: "0x1F482",
	guide_dog: "0x1F9AE",
	guitar: "0x1F3B8",
	hamburger: "0x1F354",
	hammer: "0x1F528",
	hammer_and_pick: "0x2692",
	hammer_and_wrench: "0x1F6E0",
	hamster: "0x1F439",
	hand_with_fingers_splayed: "0x1F590",
	handbag: "0x1F45C",
	handshake: "0x1F91D",
	hatching_chick: "0x1F423",
	headphone: "0x1F3A7",
	headstone: "0x1FAA6",
	health_worker: "0x1F9D1,0x200D,0x2695,0xFE0F",
	hear_no_evil_monkey: "0x1F649",
	heart_decoration: "0x1F49F",
	heart_exclamation: "0x2763",
	heart_suit: "0x2665",
	heart_with_arrow: "0x1F498",
	heart_with_ribbon: "0x1F49D",
	heavy_dollar_sign: "0x1F4B2",
	hedgehog: "0x1F994",
	helicopter: "0x1F681",
	herb: "0x1F33F",
	hibiscus: "0x1F33A",
	high_voltage: "0x26A1",
	high_heeled_shoe: "0x1F460",
	high_speed_train: "0x1F684",
	hiking_boot: "0x1F97E",
	hindu_temple: "0x1F6D5",
	hippopotamus: "0x1F99B",
	hole: "0x1F573",
	hollow_red_circle: "0x2B55",
	honey_pot: "0x1F36F",
	honeybee: "0x1F41D",
	hook: "0x1FA9D",
	horizontal_traffic_light: "0x1F6A5",
	horse: "0x1F40E",
	horse_face: "0x1F434",
	horse_racing: "0x1F3C7",
	hospital: "0x1F3E5",
	hot_beverage: "0x2615",
	hot_dog: "0x1F32D",
	hot_face: "0x1F975",
	hot_pepper: "0x1F336",
	hot_springs: "0x2668",
	hotel: "0x1F3E8",
	hourglass_done: "0x231B",
	hourglass_not_done: "0x23F3",
	house: "0x1F3E0",
	house_with_garden: "0x1F3E1",
	houses: "0x1F3D8",
	hugging_face: "0x1F917",
	hundred_points: "0x1F4AF",
	hushed_face: "0x1F62F",
	hut: "0x1F6D6",
	ice: "0x1F9CA",
	ice_cream: "0x1F368",
	ice_hockey: "0x1F3D2",
	ice_skate: "0x26F8",
	ID_button: "0x1F194",
	inbox_tray: "0x1F4E5",
	incoming_envelope: "0x1F4E8",
	index_pointing_up: "0x261D",
	infinity: "0x267E",
	information: "0x2139",
	input_latin_letters: "0x1F524",
	input_latin_lowercase: "0x1F521",
	input_latin_uppercase: "0x1F520",
	input_numbers: "0x1F522",
	input_symbols: "0x1F523",
	jack_o_lantern: "0x1F383",
	Japanese_acceptable_button: "0x1F251",
	Japanese_application_button: "0x1F238",
	Japanese_bargain_button: "0x1F250",
	Japanese_congratulations_button: "0x3297",
	Japanese_discount_button: "0x1F239",
	Japanese_free_of_charge_button: "0x1F21A",
	Japanese_here_button: "0x1F201",
	Japanese_monthly_amount_button: "0x1F237",
	Japanese_no_vacancy_button: "0x1F235",
	Japanese_not_free_of_charge_button: "0x1F236",
	Japanese_open_for_business_button: "0x1F23A",
	Japanese_passing_grade_button: "0x1F234",
	Japanese_prohibited_button: "0x1F232",
	Japanese_reserved_button: "0x1F22F",
	Japanese_secret_button: "0x3299",
	Japanese_service_charge_button: "0x1F202",
	Japanese_vacancy_button: "0x1F233",
	Japanese_castle: "0x1F3EF",
	Japanese_dolls: "0x1F38E",
	Japanese_post_office: "0x1F3E3",
	Japanese_symbol_for_beginner: "0x1F530",
	jeans: "0x1F456",
	joker: "0x1F0CF",
	joystick: "0x1F579",
	judge: "0x1F9D1,0x200D,0x2696,0xFE0F",
	kaaba: "0x1F54B",
	kangaroo: "0x1F998",
	key: "0x1F511",
	keyboard: "0x2328",
	keycap__star: "0x002A,0xFE0F,0x20E3",
	keycap__hash: "0x0023,0xFE0F,0x20E3",
	keycap__0: "0x0030,0xFE0F,0x20E3",
	keycap__1: "0x0031,0xFE0F,0x20E3",
	keycap__10: "0x1F51F",
	keycap__2: "0x0032,0xFE0F,0x20E3",
	keycap__3: "0x0033,0xFE0F,0x20E3",
	keycap__4: "0x0034,0xFE0F,0x20E3",
	keycap__5: "0x0035,0xFE0F,0x20E3",
	keycap__6: "0x0036,0xFE0F,0x20E3",
	keycap__7: "0x0037,0xFE0F,0x20E3",
	keycap__8: "0x0038,0xFE0F,0x20E3",
	keycap__9: "0x0039,0xFE0F,0x20E3",
	kick_scooter: "0x1F6F4",
	kimono: "0x1F458",
	kiss: "0x1F48F",
	kiss_mark: "0x1F48B",
	kiss__man_man: "0x1F468,0x200D,0x2764,0xFE0F,0x200D,0x1F48B,0x200D,0x1F468",
	kiss__woman_man: "0x1F469,0x200D,0x2764,0xFE0F,0x200D,0x1F48B,0x200D,0x1F468",
	kiss__woman_woman: "0x1F469,0x200D,0x2764,0xFE0F,0x200D,0x1F48B,0x200D,0x1F469",
	kissing_cat: "0x1F63D",
	kissing_face: "0x1F617",
	kissing_face_with_closed_eyes: "0x1F61A",
	kissing_face_with_smiling_eyes: "0x1F619",
	kitchen_knife: "0x1F52A",
	kite: "0x1FA81",
	kiwi_fruit: "0x1F95D",
	knocked_out_face: "0x1F635",
	knot: "0x1FAA2",
	koala: "0x1F428",
	lab_coat: "0x1F97C",
	label: "0x1F3F7",
	lacrosse: "0x1F94D",
	ladder: "0x1FA9C",
	lady_beetle: "0x1F41E",
	laptop: "0x1F4BB",
	large_blue_diamond: "0x1F537",
	large_orange_diamond: "0x1F536",
	last_quarter_moon: "0x1F317",
	last_quarter_moon_face: "0x1F31C",
	last_track_button: "0x23EE",
	latin_cross: "0x271D",
	leaf_fluttering_in_wind: "0x1F343",
	leafy_green: "0x1F96C",
	ledger: "0x1F4D2",
	left_arrow: "0x2B05",
	left_arrow_curving_right: "0x21AA",
	left_luggage: "0x1F6C5",
	left_speech_bubble: "0x1F5E8",
	left_facing_fist: "0x1F91B",
	left_right_arrow: "0x2194",
	leg: "0x1F9B5",
	lemon: "0x1F34B",
	Leo: "0x264C",
	leopard: "0x1F406",
	level_slider: "0x1F39A",
	Libra: "0x264E",
	light_bulb: "0x1F4A1",
	light_rail: "0x1F688",
	link: "0x1F517",
	linked_paperclips: "0x1F587",
	lion: "0x1F981",
	lipstick: "0x1F484",
	litter_in_bin_sign: "0x1F6AE",
	lizard: "0x1F98E",
	llama: "0x1F999",
	lobster: "0x1F99E",
	locked: "0x1F512",
	locked_with_key: "0x1F510",
	locked_with_pen: "0x1F50F",
	locomotive: "0x1F682",
	lollipop: "0x1F36D",
	long_drum: "0x1FA98",
	lotion_bottle: "0x1F9F4",
	loudly_crying_face: "0x1F62D",
	loudspeaker: "0x1F4E2",
	love_hotel: "0x1F3E9",
	love_letter: "0x1F48C",
	love_you_gesture: "0x1F91F",
	luggage: "0x1F9F3",
	lungs: "0x1FAC1",
	lying_face: "0x1F925",
	mage: "0x1F9D9",
	magic_wand: "0x1FA84",
	magnet: "0x1F9F2",
	magnifying_glass_tilted_left: "0x1F50D",
	magnifying_glass_tilted_right: "0x1F50E",
	mahjong_red_dragon: "0x1F004",
	male_sign: "0x2642",
	mammoth: "0x1F9A3",
	man: "0x1F468",
	man_artist: "0x1F468,0x200D,0x1F3A8",
	man_astronaut: "0x1F468,0x200D,0x1F680",
	man_biking: "0x1F6B4,0x200D,0x2642,0xFE0F",
	man_bouncing_ball: "0x26F9,0xFE0F,0x200D,0x2642,0xFE0F",
	man_bowing: "0x1F647,0x200D,0x2642,0xFE0F",
	man_cartwheeling: "0x1F938,0x200D,0x2642,0xFE0F",
	man_climbing: "0x1F9D7,0x200D,0x2642,0xFE0F",
	man_construction_worker: "0x1F477,0x200D,0x2642,0xFE0F",
	man_cook: "0x1F468,0x200D,0x1F373",
	man_dancing: "0x1F57A",
	man_detective: "0x1F575,0xFE0F,0x200D,0x2642,0xFE0F",
	man_elf: "0x1F9DD,0x200D,0x2642,0xFE0F",
	man_facepalming: "0x1F926,0x200D,0x2642,0xFE0F",
	man_factory_worker: "0x1F468,0x200D,0x1F3ED",
	man_fairy: "0x1F9DA,0x200D,0x2642,0xFE0F",
	man_farmer: "0x1F468,0x200D,0x1F33E",
	man_feeding_baby: "0x1F468,0x200D,0x1F37C",
	man_firefighter: "0x1F468,0x200D,0x1F692",
	man_frowning: "0x1F64D,0x200D,0x2642,0xFE0F",
	man_genie: "0x1F9DE,0x200D,0x2642,0xFE0F",
	man_gesturing_NO: "0x1F645,0x200D,0x2642,0xFE0F",
	man_gesturing_OK: "0x1F646,0x200D,0x2642,0xFE0F",
	man_getting_haircut: "0x1F487,0x200D,0x2642,0xFE0F",
	man_getting_massage: "0x1F486,0x200D,0x2642,0xFE0F",
	man_golfing: "0x1F3CC,0xFE0F,0x200D,0x2642,0xFE0F",
	man_guard: "0x1F482,0x200D,0x2642,0xFE0F",
	man_health_worker: "0x1F468,0x200D,0x2695,0xFE0F",
	man_in_lotus_position: "0x1F9D8,0x200D,0x2642,0xFE0F",
	man_in_manual_wheelchair: "0x1F468,0x200D,0x1F9BD",
	man_in_motorized_wheelchair: "0x1F468,0x200D,0x1F9BC",
	man_in_steamy_room: "0x1F9D6,0x200D,0x2642,0xFE0F",
	man_in_tuxedo: "0x1F935,0x200D,0x2642,0xFE0F",
	man_judge: "0x1F468,0x200D,0x2696,0xFE0F",
	man_juggling: "0x1F939,0x200D,0x2642,0xFE0F",
	man_kneeling: "0x1F9CE,0x200D,0x2642,0xFE0F",
	man_lifting_weights: "0x1F3CB,0xFE0F,0x200D,0x2642,0xFE0F",
	man_mage: "0x1F9D9,0x200D,0x2642,0xFE0F",
	man_mechanic: "0x1F468,0x200D,0x1F527",
	man_mountain_biking: "0x1F6B5,0x200D,0x2642,0xFE0F",
	man_office_worker: "0x1F468,0x200D,0x1F4BC",
	man_pilot: "0x1F468,0x200D,0x2708,0xFE0F",
	man_playing_handball: "0x1F93E,0x200D,0x2642,0xFE0F",
	man_playing_water_polo: "0x1F93D,0x200D,0x2642,0xFE0F",
	man_police_officer: "0x1F46E,0x200D,0x2642,0xFE0F",
	man_pouting: "0x1F64E,0x200D,0x2642,0xFE0F",
	man_raising_hand: "0x1F64B,0x200D,0x2642,0xFE0F",
	man_rowing_boat: "0x1F6A3,0x200D,0x2642,0xFE0F",
	man_running: "0x1F3C3,0x200D,0x2642,0xFE0F",
	man_scientist: "0x1F468,0x200D,0x1F52C",
	man_shrugging: "0x1F937,0x200D,0x2642,0xFE0F",
	man_singer: "0x1F468,0x200D,0x1F3A4",
	man_standing: "0x1F9CD,0x200D,0x2642,0xFE0F",
	man_student: "0x1F468,0x200D,0x1F393",
	man_superhero: "0x1F9B8,0x200D,0x2642,0xFE0F",
	man_supervillain: "0x1F9B9,0x200D,0x2642,0xFE0F",
	man_surfing: "0x1F3C4,0x200D,0x2642,0xFE0F",
	man_swimming: "0x1F3CA,0x200D,0x2642,0xFE0F",
	man_teacher: "0x1F468,0x200D,0x1F3EB",
	man_technologist: "0x1F468,0x200D,0x1F4BB",
	man_tipping_hand: "0x1F481,0x200D,0x2642,0xFE0F",
	man_vampire: "0x1F9DB,0x200D,0x2642,0xFE0F",
	man_walking: "0x1F6B6,0x200D,0x2642,0xFE0F",
	man_wearing_turban: "0x1F473,0x200D,0x2642,0xFE0F",
	man_with_veil: "0x1F470,0x200D,0x2642,0xFE0F",
	man_with_white_cane: "0x1F468,0x200D,0x1F9AF",
	man_zombie: "0x1F9DF,0x200D,0x2642,0xFE0F",
	man__bald: "0x1F468,0x200D,0x1F9B2",
	man__blond_hair: "0x1F471,0x200D,0x2642,0xFE0F",
	man__curly_hair: "0x1F468,0x200D,0x1F9B1",
	man__red_hair: "0x1F468,0x200D,0x1F9B0",
	man__white_hair: "0x1F468,0x200D,0x1F9B3",
	man_s_shoe: "0x1F45E",
	mango: "0x1F96D",
	mantelpiece_clock: "0x1F570",
	manual_wheelchair: "0x1F9BD",
	map_of_Japan: "0x1F5FE",
	maple_leaf: "0x1F341",
	martial_arts_uniform: "0x1F94B",
	mate: "0x1F9C9",
	meat_on_bone: "0x1F356",
	mechanic: "0x1F9D1,0x200D,0x1F527",
	mechanical_arm: "0x1F9BE",
	mechanical_leg: "0x1F9BF",
	medical_symbol: "0x2695",
	megaphone: "0x1F4E3",
	melon: "0x1F348",
	memo: "0x1F4DD",
	men_holding_hands: "0x1F46C",
	men_with_bunny_ears: "0x1F46F,0x200D,0x2642,0xFE0F",
	men_wrestling: "0x1F93C,0x200D,0x2642,0xFE0F",
	men_s_room: "0x1F6B9",
	menorah: "0x1F54E",
	mermaid: "0x1F9DC,0x200D,0x2640,0xFE0F",
	merman: "0x1F9DC,0x200D,0x2642,0xFE0F",
	merperson: "0x1F9DC",
	metro: "0x1F687",
	microbe: "0x1F9A0",
	microphone: "0x1F3A4",
	microscope: "0x1F52C",
	middle_finger: "0x1F595",
	military_helmet: "0x1FA96",
	military_medal: "0x1F396",
	milky_way: "0x1F30C",
	minibus: "0x1F690",
	minus: "0x2796",
	mirror: "0x1FA9E",
	moai: "0x1F5FF",
	mobile_phone: "0x1F4F1",
	mobile_phone_off: "0x1F4F4",
	mobile_phone_with_arrow: "0x1F4F2",
	money_bag: "0x1F4B0",
	money_with_wings: "0x1F4B8",
	money_mouth_face: "0x1F911",
	monkey: "0x1F412",
	monkey_face: "0x1F435",
	monorail: "0x1F69D",
	moon_cake: "0x1F96E",
	moon_viewing_ceremony: "0x1F391",
	mosque: "0x1F54C",
	mosquito: "0x1F99F",
	motor_boat: "0x1F6E5",
	motor_scooter: "0x1F6F5",
	motorcycle: "0x1F3CD",
	motorized_wheelchair: "0x1F9BC",
	motorway: "0x1F6E3",
	mount_fuji: "0x1F5FB",
	mountain: "0x26F0",
	mountain_cableway: "0x1F6A0",
	mountain_railway: "0x1F69E",
	mouse: "0x1F401",
	mouse_face: "0x1F42D",
	mouse_trap: "0x1FAA4",
	mouth: "0x1F444",
	movie_camera: "0x1F3A5",
	Mrs_Claus: "0x1F936",
	multiply: "0x2716",
	mushroom: "0x1F344",
	musical_keyboard: "0x1F3B9",
	musical_note: "0x1F3B5",
	musical_notes: "0x1F3B6",
	musical_score: "0x1F3BC",
	muted_speaker: "0x1F507",
	mx_claus: "0x1F9D1,0x200D,0x1F384",
	nail_polish: "0x1F485",
	name_badge: "0x1F4DB",
	national_park: "0x1F3DE",
	nauseated_face: "0x1F922",
	nazar_amulet: "0x1F9FF",
	necktie: "0x1F454",
	nerd_face: "0x1F913",
	nesting_dolls: "0x1FA86",
	neutral_face: "0x1F610",
	NEW_button: "0x1F195",
	new_moon: "0x1F311",
	new_moon_face: "0x1F31A",
	newspaper: "0x1F4F0",
	next_track_button: "0x23ED",
	NG_button: "0x1F196",
	night_with_stars: "0x1F303",
	nine_o_clock: "0x1F558",
	nine_thirty: "0x1F564",
	ninja: "0x1F977",
	no_bicycles: "0x1F6B3",
	no_entry: "0x26D4",
	no_littering: "0x1F6AF",
	no_mobile_phones: "0x1F4F5",
	no_one_under_eighteen: "0x1F51E",
	no_pedestrians: "0x1F6B7",
	no_smoking: "0x1F6AD",
	non_potable_water: "0x1F6B1",
	nose: "0x1F443",
	notebook: "0x1F4D3",
	notebook_with_decorative_cover: "0x1F4D4",
	nut_and_bolt: "0x1F529",
	O_button_blood_type: "0x1F17E",
	octopus: "0x1F419",
	oden: "0x1F362",
	office_building: "0x1F3E2",
	office_worker: "0x1F9D1,0x200D,0x1F4BC",
	ogre: "0x1F479",
	oil_drum: "0x1F6E2",
	OK_button: "0x1F197",
	OK_hand: "0x1F44C",
	old_key: "0x1F5DD",
	old_man: "0x1F474",
	old_woman: "0x1F475",
	older_person: "0x1F9D3",
	olive: "0x1FAD2",
	om: "0x1F549",
	ON_arrow: "0x1F51B",
	oncoming_automobile: "0x1F698",
	oncoming_bus: "0x1F68D",
	oncoming_fist: "0x1F44A",
	oncoming_police_car: "0x1F694",
	oncoming_taxi: "0x1F696",
	one_o_clock: "0x1F550",
	one_piece_swimsuit: "0x1FA71",
	one_thirty: "0x1F55C",
	onion: "0x1F9C5",
	open_book: "0x1F4D6",
	open_file_folder: "0x1F4C2",
	open_hands: "0x1F450",
	open_mailbox_with_lowered_flag: "0x1F4ED",
	open_mailbox_with_raised_flag: "0x1F4EC",
	Ophiuchus: "0x26CE",
	optical_disk: "0x1F4BF",
	orange_book: "0x1F4D9",
	orange_circle: "0x1F7E0",
	orange_heart: "0x1F9E1",
	orange_square: "0x1F7E7",
	orangutan: "0x1F9A7",
	orthodox_cross: "0x2626",
	otter: "0x1F9A6",
	outbox_tray: "0x1F4E4",
	owl: "0x1F989",
	ox: "0x1F402",
	oyster: "0x1F9AA",
	P_button: "0x1F17F",
	package: "0x1F4E6",
	page_facing_up: "0x1F4C4",
	page_with_curl: "0x1F4C3",
	pager: "0x1F4DF",
	paintbrush: "0x1F58C",
	palm_tree: "0x1F334",
	palms_up_together: "0x1F932",
	pancakes: "0x1F95E",
	panda: "0x1F43C",
	paperclip: "0x1F4CE",
	parachute: "0x1FA82",
	parrot: "0x1F99C",
	part_alternation_mark: "0x303D",
	party_popper: "0x1F389",
	partying_face: "0x1F973",
	passenger_ship: "0x1F6F3",
	passport_control: "0x1F6C2",
	pause_button: "0x23F8",
	paw_prints: "0x1F43E",
	peace_symbol: "0x262E",
	peach: "0x1F351",
	peacock: "0x1F99A",
	peanuts: "0x1F95C",
	pear: "0x1F350",
	pen: "0x1F58A",
	pencil: "0x270F",
	penguin: "0x1F427",
	pensive_face: "0x1F614",
	people_holding_hands: "0x1F9D1,0x200D,0x1F91D,0x200D,0x1F9D1",
	people_hugging: "0x1FAC2",
	people_with_bunny_ears: "0x1F46F",
	people_wrestling: "0x1F93C",
	performing_arts: "0x1F3AD",
	persevering_face: "0x1F623",
	person: "0x1F9D1",
	person_biking: "0x1F6B4",
	person_bouncing_ball: "0x26F9",
	person_bowing: "0x1F647",
	person_cartwheeling: "0x1F938",
	person_climbing: "0x1F9D7",
	person_facepalming: "0x1F926",
	person_feeding_baby: "0x1F9D1,0x200D,0x1F37C",
	person_fencing: "0x1F93A",
	person_frowning: "0x1F64D",
	person_gesturing_NO: "0x1F645",
	person_gesturing_OK: "0x1F646",
	person_getting_haircut: "0x1F487",
	person_getting_massage: "0x1F486",
	person_golfing: "0x1F3CC",
	person_in_bed: "0x1F6CC",
	person_in_lotus_position: "0x1F9D8",
	person_in_manual_wheelchair: "0x1F9D1,0x200D,0x1F9BD",
	person_in_motorized_wheelchair: "0x1F9D1,0x200D,0x1F9BC",
	person_in_steamy_room: "0x1F9D6",
	person_in_suit_levitating: "0x1F574",
	person_in_tuxedo: "0x1F935",
	person_juggling: "0x1F939",
	person_kneeling: "0x1F9CE",
	person_lifting_weights: "0x1F3CB",
	person_mountain_biking: "0x1F6B5",
	person_playing_handball: "0x1F93E",
	person_playing_water_polo: "0x1F93D",
	person_pouting: "0x1F64E",
	person_raising_hand: "0x1F64B",
	person_rowing_boat: "0x1F6A3",
	person_running: "0x1F3C3",
	person_shrugging: "0x1F937",
	person_standing: "0x1F9CD",
	person_surfing: "0x1F3C4",
	person_swimming: "0x1F3CA",
	person_taking_bath: "0x1F6C0",
	person_tipping_hand: "0x1F481",
	person_walking: "0x1F6B6",
	person_wearing_turban: "0x1F473",
	person_with_skullcap: "0x1F472",
	person_with_veil: "0x1F470",
	person_with_white_cane: "0x1F9D1,0x200D,0x1F9AF",
	person__bald: "0x1F9D1,0x200D,0x1F9B2",
	person__beard: "0x1F9D4",
	person__blond_hair: "0x1F471",
	person__curly_hair: "0x1F9D1,0x200D,0x1F9B1",
	person__red_hair: "0x1F9D1,0x200D,0x1F9B0",
	person__white_hair: "0x1F9D1,0x200D,0x1F9B3",
	petri_dish: "0x1F9EB",
	pick: "0x26CF",
	pickup_truck: "0x1F6FB",
	pie: "0x1F967",
	pig: "0x1F416",
	pig_face: "0x1F437",
	pig_nose: "0x1F43D",
	pile_of_poo: "0x1F4A9",
	pill: "0x1F48A",
	pilot: "0x1F9D1,0x200D,0x2708,0xFE0F",
	pinata: "0x1FA85",
	pinched_fingers: "0x1F90C",
	pinching_hand: "0x1F90F",
	pine_decoration: "0x1F38D",
	pineapple: "0x1F34D",
	ping_pong: "0x1F3D3",
	pirate_flag: "0x1F3F4,0x200D,0x2620,0xFE0F",
	Pisces: "0x2653",
	pizza: "0x1F355",
	placard: "0x1FAA7",
	place_of_worship: "0x1F6D0",
	play_button: "0x25B6",
	play_or_pause_button: "0x23EF",
	pleading_face: "0x1F97A",
	plunger: "0x1FAA0",
	plus: "0x2795",
	polar_bear: "0x1F43B,0x200D,0x2744,0xFE0F",
	police_car: "0x1F693",
	police_car_light: "0x1F6A8",
	police_officer: "0x1F46E",
	poodle: "0x1F429",
	pool_8_ball: "0x1F3B1",
	popcorn: "0x1F37F",
	post_office: "0x1F3E4",
	postal_horn: "0x1F4EF",
	postbox: "0x1F4EE",
	pot_of_food: "0x1F372",
	potable_water: "0x1F6B0",
	potato: "0x1F954",
	potted_plant: "0x1FAB4",
	poultry_leg: "0x1F357",
	pound_banknote: "0x1F4B7",
	pouting_cat: "0x1F63E",
	pouting_face: "0x1F621",
	prayer_beads: "0x1F4FF",
	pregnant_woman: "0x1F930",
	pretzel: "0x1F968",
	prince: "0x1F934",
	princess: "0x1F478",
	printer: "0x1F5A8",
	prohibited: "0x1F6AB",
	purple_circle: "0x1F7E3",
	purple_heart: "0x1F49C",
	purple_square: "0x1F7EA",
	purse: "0x1F45B",
	pushpin: "0x1F4CC",
	puzzle_piece: "0x1F9E9",
	rabbit: "0x1F407",
	rabbit_face: "0x1F430",
	raccoon: "0x1F99D",
	racing_car: "0x1F3CE",
	radio: "0x1F4FB",
	radio_button: "0x1F518",
	radioactive: "0x2622",
	railway_car: "0x1F683",
	railway_track: "0x1F6E4",
	rainbow: "0x1F308",
	rainbow_flag: "0x1F3F3,0xFE0F,0x200D,0x1F308",
	raised_back_of_hand: "0x1F91A",
	raised_fist: "0x270A",
	raised_hand: "0x270B",
	raising_hands: "0x1F64C",
	ram: "0x1F40F",
	rat: "0x1F400",
	razor: "0x1FA92",
	receipt: "0x1F9FE",
	record_button: "0x23FA",
	recycling_symbol: "0x267B",
	red_apple: "0x1F34E",
	red_circle: "0x1F534",
	red_envelope: "0x1F9E7",
	red_exclamation_mark: "0x2757",
	red_hair: "0x1F9B0",
	red_heart: "0x2764",
	red_paper_lantern: "0x1F3EE",
	red_question_mark: "0x2753",
	red_square: "0x1F7E5",
	red_triangle_pointed_down: "0x1F53B",
	red_triangle_pointed_up: "0x1F53A",
	registered: "0x00AE",
	relieved_face: "0x1F60C",
	reminder_ribbon: "0x1F397",
	repeat_button: "0x1F501",
	repeat_single_button: "0x1F502",
	rescue_worker_s_helmet: "0x26D1",
	restroom: "0x1F6BB",
	reverse_button: "0x25C0",
	revolving_hearts: "0x1F49E",
	rhinoceros: "0x1F98F",
	ribbon: "0x1F380",
	rice_ball: "0x1F359",
	rice_cracker: "0x1F358",
	right_anger_bubble: "0x1F5EF",
	right_arrow: "0x27A1",
	right_arrow_curving_down: "0x2935",
	right_arrow_curving_left: "0x21A9",
	right_arrow_curving_up: "0x2934",
	right_facing_fist: "0x1F91C",
	ring: "0x1F48D",
	ringed_planet: "0x1FA90",
	roasted_sweet_potato: "0x1F360",
	robot: "0x1F916",
	rock: "0x1FAA8",
	rocket: "0x1F680",
	roll_of_paper: "0x1F9FB",
	rolled_up_newspaper: "0x1F5DE",
	roller_coaster: "0x1F3A2",
	roller_skate: "0x1F6FC",
	rolling_on_the_floor_laughing: "0x1F923",
	rooster: "0x1F413",
	rose: "0x1F339",
	rosette: "0x1F3F5",
	round_pushpin: "0x1F4CD",
	rugby_football: "0x1F3C9",
	running_shirt: "0x1F3BD",
	running_shoe: "0x1F45F",
	sad_but_relieved_face: "0x1F625",
	safety_pin: "0x1F9F7",
	safety_vest: "0x1F9BA",
	Sagittarius: "0x2650",
	sailboat: "0x26F5",
	sake: "0x1F376",
	salt: "0x1F9C2",
	sandwich: "0x1F96A",
	Santa_Claus: "0x1F385",
	sari: "0x1F97B",
	satellite: "0x1F6F0",
	satellite_antenna: "0x1F4E1",
	sauropod: "0x1F995",
	saxophone: "0x1F3B7",
	scarf: "0x1F9E3",
	school: "0x1F3EB",
	scientist: "0x1F9D1,0x200D,0x1F52C",
	scissors: "0x2702",
	Scorpio: "0x264F",
	scorpion: "0x1F982",
	screwdriver: "0x1FA9B",
	scroll: "0x1F4DC",
	seal: "0x1F9AD",
	seat: "0x1F4BA",
	see_no_evil_monkey: "0x1F648",
	seedling: "0x1F331",
	selfie: "0x1F933",
	service_dog: "0x1F415,0x200D,0x1F9BA",
	seven_o_clock: "0x1F556",
	seven_thirty: "0x1F562",
	sewing_needle: "0x1FAA1",
	shallow_pan_of_food: "0x1F958",
	shamrock: "0x2618",
	shark: "0x1F988",
	shaved_ice: "0x1F367",
	sheaf_of_rice: "0x1F33E",
	shield: "0x1F6E1",
	shinto_shrine: "0x26E9",
	ship: "0x1F6A2",
	shooting_star: "0x1F320",
	shopping_bags: "0x1F6CD",
	shopping_cart: "0x1F6D2",
	shortcake: "0x1F370",
	shorts: "0x1FA73",
	shower: "0x1F6BF",
	shrimp: "0x1F990",
	shuffle_tracks_button: "0x1F500",
	shushing_face: "0x1F92B",
	sign_of_the_horns: "0x1F918",
	singer: "0x1F9D1,0x200D,0x1F3A4",
	six_o_clock: "0x1F555",
	six_thirty: "0x1F561",
	skateboard: "0x1F6F9",
	skier: "0x26F7",
	skis: "0x1F3BF",
	skull: "0x1F480",
	skull_and_crossbones: "0x2620",
	skunk: "0x1F9A8",
	sled: "0x1F6F7",
	sleeping_face: "0x1F634",
	sleepy_face: "0x1F62A",
	slightly_frowning_face: "0x1F641",
	slightly_smiling_face: "0x1F642",
	slot_machine: "0x1F3B0",
	sloth: "0x1F9A5",
	small_airplane: "0x1F6E9",
	small_blue_diamond: "0x1F539",
	small_orange_diamond: "0x1F538",
	smiling_cat_with_heart_eyes: "0x1F63B",
	smiling_face: "0x263A",
	smiling_face_with_halo: "0x1F607",
	smiling_face_with_heart_eyes: "0x1F60D",
	smiling_face_with_hearts: "0x1F970",
	smiling_face_with_horns: "0x1F608",
	smiling_face_with_smiling_eyes: "0x1F60A",
	smiling_face_with_sunglasses: "0x1F60E",
	smiling_face_with_tear: "0x1F972",
	smirking_face: "0x1F60F",
	snail: "0x1F40C",
	snake: "0x1F40D",
	sneezing_face: "0x1F927",
	snow_capped_mountain: "0x1F3D4",
	snowboarder: "0x1F3C2",
	snowflake: "0x2744",
	snowman: "0x2603",
	snowman_without_snow: "0x26C4",
	soap: "0x1F9FC",
	soccer_ball: "0x26BD",
	socks: "0x1F9E6",
	soft_ice_cream: "0x1F366",
	softball: "0x1F94E",
	SOON_arrow: "0x1F51C",
	SOS_button: "0x1F198",
	spade_suit: "0x2660",
	spaghetti: "0x1F35D",
	sparkle: "0x2747",
	sparkler: "0x1F387",
	sparkles: "0x2728",
	sparkling_heart: "0x1F496",
	speak_no_evil_monkey: "0x1F64A",
	speaker_high_volume: "0x1F50A",
	speaker_low_volume: "0x1F508",
	speaker_medium_volume: "0x1F509",
	speaking_head: "0x1F5E3",
	speech_balloon: "0x1F4AC",
	speedboat: "0x1F6A4",
	spider: "0x1F577",
	spider_web: "0x1F578",
	spiral_calendar: "0x1F5D3",
	spiral_notepad: "0x1F5D2",
	spiral_shell: "0x1F41A",
	sponge: "0x1F9FD",
	spoon: "0x1F944",
	sport_utility_vehicle: "0x1F699",
	sports_medal: "0x1F3C5",
	spouting_whale: "0x1F433",
	squid: "0x1F991",
	squinting_face_with_tongue: "0x1F61D",
	stadium: "0x1F3DF",
	star: "0x2B50",
	star_and_crescent: "0x262A",
	star_of_David: "0x2721",
	star_struck: "0x1F929",
	station: "0x1F689",
	Statue_of_Liberty: "0x1F5FD",
	steaming_bowl: "0x1F35C",
	stethoscope: "0x1FA7A",
	stop_button: "0x23F9",
	stop_sign: "0x1F6D1",
	stopwatch: "0x23F1",
	straight_ruler: "0x1F4CF",
	strawberry: "0x1F353",
	student: "0x1F9D1,0x200D,0x1F393",
	studio_microphone: "0x1F399",
	stuffed_flatbread: "0x1F959",
	sun: "0x2600",
	sun_behind_cloud: "0x26C5",
	sun_behind_large_cloud: "0x1F325",
	sun_behind_rain_cloud: "0x1F326",
	sun_behind_small_cloud: "0x1F324",
	sun_with_face: "0x1F31E",
	sunflower: "0x1F33B",
	sunglasses: "0x1F576",
	sunrise: "0x1F305",
	sunrise_over_mountains: "0x1F304",
	sunset: "0x1F307",
	superhero: "0x1F9B8",
	supervillain: "0x1F9B9",
	sushi: "0x1F363",
	suspension_railway: "0x1F69F",
	swan: "0x1F9A2",
	sweat_droplets: "0x1F4A6",
	synagogue: "0x1F54D",
	syringe: "0x1F489",
	T_Rex: "0x1F996",
	t_shirt: "0x1F455",
	taco: "0x1F32E",
	takeout_box: "0x1F961",
	tamale: "0x1FAD4",
	tanabata_tree: "0x1F38B",
	tangerine: "0x1F34A",
	Taurus: "0x2649",
	taxi: "0x1F695",
	teacher: "0x1F9D1,0x200D,0x1F3EB",
	teacup_without_handle: "0x1F375",
	teapot: "0x1FAD6",
	tear_off_calendar: "0x1F4C6",
	technologist: "0x1F9D1,0x200D,0x1F4BB",
	teddy_bear: "0x1F9F8",
	telephone: "0x260E",
	telephone_receiver: "0x1F4DE",
	telescope: "0x1F52D",
	television: "0x1F4FA",
	ten_o_clock: "0x1F559",
	ten_thirty: "0x1F565",
	tennis: "0x1F3BE",
	tent: "0x26FA",
	test_tube: "0x1F9EA",
	thermometer: "0x1F321",
	thinking_face: "0x1F914",
	thong_sandal: "0x1FA74",
	thought_balloon: "0x1F4AD",
	thread: "0x1F9F5",
	three_o_clock: "0x1F552",
	three_thirty: "0x1F55E",
	thumbs_down: "0x1F44E",
	thumbs_up: "0x1F44D",
	ticket: "0x1F3AB",
	tiger: "0x1F405",
	tiger_face: "0x1F42F",
	timer_clock: "0x23F2",
	tired_face: "0x1F62B",
	toilet: "0x1F6BD",
	Tokyo_tower: "0x1F5FC",
	tomato: "0x1F345",
	tongue: "0x1F445",
	toolbox: "0x1F9F0",
	tooth: "0x1F9B7",
	toothbrush: "0x1FAA5",
	TOP_arrow: "0x1F51D",
	top_hat: "0x1F3A9",
	tornado: "0x1F32A",
	trackball: "0x1F5B2",
	tractor: "0x1F69C",
	trade_mark: "0x2122",
	train: "0x1F686",
	tram: "0x1F68A",
	tram_car: "0x1F68B",
	transgender_flag: "0x1F3F3,0xFE0F,0x200D,0x26A7,0xFE0F",
	transgender_symbol: "0x26A7",
	triangular_flag: "0x1F6A9",
	triangular_ruler: "0x1F4D0",
	trident_emblem: "0x1F531",
	trolleybus: "0x1F68E",
	trophy: "0x1F3C6",
	tropical_drink: "0x1F379",
	tropical_fish: "0x1F420",
	trumpet: "0x1F3BA",
	tulip: "0x1F337",
	tumbler_glass: "0x1F943",
	turkey: "0x1F983",
	turtle: "0x1F422",
	twelve_o_clock: "0x1F55B",
	twelve_thirty: "0x1F567",
	two_hearts: "0x1F495",
	two_o_clock: "0x1F551",
	two_hump_camel: "0x1F42B",
	two_thirty: "0x1F55D",
	umbrella: "0x2602",
	umbrella_on_ground: "0x26F1",
	umbrella_with_rain_drops: "0x2614",
	unamused_face: "0x1F612",
	unicorn: "0x1F984",
	unlocked: "0x1F513",
	up_arrow: "0x2B06",
	up_down_arrow: "0x2195",
	up_left_arrow: "0x2196",
	up_right_arrow: "0x2197",
	UP_button: "0x1F199",
	upside_down_face: "0x1F643",
	upwards_button: "0x1F53C",
	vampire: "0x1F9DB",
	vertical_traffic_light: "0x1F6A6",
	vibration_mode: "0x1F4F3",
	victory_hand: "0x270C",
	video_camera: "0x1F4F9",
	video_game: "0x1F3AE",
	videocassette: "0x1F4FC",
	violin: "0x1F3BB",
	Virgo: "0x264D",
	volcano: "0x1F30B",
	volleyball: "0x1F3D0",
	VS_button: "0x1F19A",
	vulcan_salute: "0x1F596",
	waffle: "0x1F9C7",
	waning_crescent_moon: "0x1F318",
	waning_gibbous_moon: "0x1F316",
	warning: "0x26A0",
	wastebasket: "0x1F5D1",
	watch: "0x231A",
	water_buffalo: "0x1F403",
	water_closet: "0x1F6BE",
	water_pistol: "0x1F52B",
	water_wave: "0x1F30A",
	watermelon: "0x1F349",
	waving_hand: "0x1F44B",
	wavy_dash: "0x3030",
	waxing_crescent_moon: "0x1F312",
	waxing_gibbous_moon: "0x1F314",
	weary_cat: "0x1F640",
	weary_face: "0x1F629",
	wedding: "0x1F492",
	whale: "0x1F40B",
	wheel_of_dharma: "0x2638",
	wheelchair_symbol: "0x267F",
	white_cane: "0x1F9AF",
	white_circle: "0x26AA",
	white_exclamation_mark: "0x2755",
	white_flag: "0x1F3F3",
	white_flower: "0x1F4AE",
	white_hair: "0x1F9B3",
	white_heart: "0x1F90D",
	white_large_square: "0x2B1C",
	white_medium_square: "0x25FB",
	white_medium_small_square: "0x25FD",
	white_question_mark: "0x2754",
	white_small_square: "0x25AB",
	white_square_button: "0x1F533",
	wilted_flower: "0x1F940",
	wind_chime: "0x1F390",
	wind_face: "0x1F32C",
	window: "0x1FA9F",
	wine_glass: "0x1F377",
	winking_face: "0x1F609",
	winking_face_with_tongue: "0x1F61C",
	wolf: "0x1F43A",
	woman: "0x1F469",
	woman_and_man_holding_hands: "0x1F46B",
	woman_artist: "0x1F469,0x200D,0x1F3A8",
	woman_astronaut: "0x1F469,0x200D,0x1F680",
	woman_biking: "0x1F6B4,0x200D,0x2640,0xFE0F",
	woman_bouncing_ball: "0x26F9,0xFE0F,0x200D,0x2640,0xFE0F",
	woman_bowing: "0x1F647,0x200D,0x2640,0xFE0F",
	woman_cartwheeling: "0x1F938,0x200D,0x2640,0xFE0F",
	woman_climbing: "0x1F9D7,0x200D,0x2640,0xFE0F",
	woman_construction_worker: "0x1F477,0x200D,0x2640,0xFE0F",
	woman_cook: "0x1F469,0x200D,0x1F373",
	woman_dancing: "0x1F483",
	woman_detective: "0x1F575,0xFE0F,0x200D,0x2640,0xFE0F",
	woman_elf: "0x1F9DD,0x200D,0x2640,0xFE0F",
	woman_facepalming: "0x1F926,0x200D,0x2640,0xFE0F",
	woman_factory_worker: "0x1F469,0x200D,0x1F3ED",
	woman_fairy: "0x1F9DA,0x200D,0x2640,0xFE0F",
	woman_farmer: "0x1F469,0x200D,0x1F33E",
	woman_feeding_baby: "0x1F469,0x200D,0x1F37C",
	woman_firefighter: "0x1F469,0x200D,0x1F692",
	woman_frowning: "0x1F64D,0x200D,0x2640,0xFE0F",
	woman_genie: "0x1F9DE,0x200D,0x2640,0xFE0F",
	woman_gesturing_NO: "0x1F645,0x200D,0x2640,0xFE0F",
	woman_gesturing_OK: "0x1F646,0x200D,0x2640,0xFE0F",
	woman_getting_haircut: "0x1F487,0x200D,0x2640,0xFE0F",
	woman_getting_massage: "0x1F486,0x200D,0x2640,0xFE0F",
	woman_golfing: "0x1F3CC,0xFE0F,0x200D,0x2640,0xFE0F",
	woman_guard: "0x1F482,0x200D,0x2640,0xFE0F",
	woman_health_worker: "0x1F469,0x200D,0x2695,0xFE0F",
	woman_in_lotus_position: "0x1F9D8,0x200D,0x2640,0xFE0F",
	woman_in_manual_wheelchair: "0x1F469,0x200D,0x1F9BD",
	woman_in_motorized_wheelchair: "0x1F469,0x200D,0x1F9BC",
	woman_in_steamy_room: "0x1F9D6,0x200D,0x2640,0xFE0F",
	woman_in_tuxedo: "0x1F935,0x200D,0x2640,0xFE0F",
	woman_judge: "0x1F469,0x200D,0x2696,0xFE0F",
	woman_juggling: "0x1F939,0x200D,0x2640,0xFE0F",
	woman_kneeling: "0x1F9CE,0x200D,0x2640,0xFE0F",
	woman_lifting_weights: "0x1F3CB,0xFE0F,0x200D,0x2640,0xFE0F",
	woman_mage: "0x1F9D9,0x200D,0x2640,0xFE0F",
	woman_mechanic: "0x1F469,0x200D,0x1F527",
	woman_mountain_biking: "0x1F6B5,0x200D,0x2640,0xFE0F",
	woman_office_worker: "0x1F469,0x200D,0x1F4BC",
	woman_pilot: "0x1F469,0x200D,0x2708,0xFE0F",
	woman_playing_handball: "0x1F93E,0x200D,0x2640,0xFE0F",
	woman_playing_water_polo: "0x1F93D,0x200D,0x2640,0xFE0F",
	woman_police_officer: "0x1F46E,0x200D,0x2640,0xFE0F",
	woman_pouting: "0x1F64E,0x200D,0x2640,0xFE0F",
	woman_raising_hand: "0x1F64B,0x200D,0x2640,0xFE0F",
	woman_rowing_boat: "0x1F6A3,0x200D,0x2640,0xFE0F",
	woman_running: "0x1F3C3,0x200D,0x2640,0xFE0F",
	woman_scientist: "0x1F469,0x200D,0x1F52C",
	woman_shrugging: "0x1F937,0x200D,0x2640,0xFE0F",
	woman_singer: "0x1F469,0x200D,0x1F3A4",
	woman_standing: "0x1F9CD,0x200D,0x2640,0xFE0F",
	woman_student: "0x1F469,0x200D,0x1F393",
	woman_superhero: "0x1F9B8,0x200D,0x2640,0xFE0F",
	woman_supervillain: "0x1F9B9,0x200D,0x2640,0xFE0F",
	woman_surfing: "0x1F3C4,0x200D,0x2640,0xFE0F",
	woman_swimming: "0x1F3CA,0x200D,0x2640,0xFE0F",
	woman_teacher: "0x1F469,0x200D,0x1F3EB",
	woman_technologist: "0x1F469,0x200D,0x1F4BB",
	woman_tipping_hand: "0x1F481,0x200D,0x2640,0xFE0F",
	woman_vampire: "0x1F9DB,0x200D,0x2640,0xFE0F",
	woman_walking: "0x1F6B6,0x200D,0x2640,0xFE0F",
	woman_wearing_turban: "0x1F473,0x200D,0x2640,0xFE0F",
	woman_with_headscarf: "0x1F9D5",
	woman_with_veil: "0x1F470,0x200D,0x2640,0xFE0F",
	woman_with_white_cane: "0x1F469,0x200D,0x1F9AF",
	woman_zombie: "0x1F9DF,0x200D,0x2640,0xFE0F",
	woman__bald: "0x1F469,0x200D,0x1F9B2",
	woman__blond_hair: "0x1F471,0x200D,0x2640,0xFE0F",
	woman__curly_hair: "0x1F469,0x200D,0x1F9B1",
	woman__red_hair: "0x1F469,0x200D,0x1F9B0",
	woman__white_hair: "0x1F469,0x200D,0x1F9B3",
	woman_s_boot: "0x1F462",
	woman_s_clothes: "0x1F45A",
	woman_s_hat: "0x1F452",
	woman_s_sandal: "0x1F461",
	women_holding_hands: "0x1F46D",
	women_with_bunny_ears: "0x1F46F,0x200D,0x2640,0xFE0F",
	women_wrestling: "0x1F93C,0x200D,0x2640,0xFE0F",
	women_s_room: "0x1F6BA",
	wood: "0x1FAB5",
	woozy_face: "0x1F974",
	world_map: "0x1F5FA",
	worm: "0x1FAB1",
	worried_face: "0x1F61F",
	wrapped_gift: "0x1F381",
	wrench: "0x1F527",
	writing_hand: "0x270D",
	yarn: "0x1F9F6",
	yawning_face: "0x1F971",
	yellow_circle: "0x1F7E1",
	yellow_heart: "0x1F49B",
	yellow_square: "0x1F7E8",
	yen_banknote: "0x1F4B4",
	yin_yang: "0x262F",
	yo_yo: "0x1FA80",
	zany_face: "0x1F92A",
	zebra: "0x1F993",
	zipper_mouth_face: "0x1F910",
	zombie: "0x1F9DF",
	zzz: "0x1F4A4"
};
