import React, {useState} from "react";
import {closeAllNodes, openAllNodes} from "../business-logic/tree-manager";
import {showlog} from "../utils/dev-utils";

// eslint-disable-next-line no-restricted-globals

export const TreeToggler = props => {


    const [opening, open] = useState( false );
    const [closing, close] = useState( false );

    return (
        <div className={"tree-toggler"+ (!!props.className ? (" "+ props.className) : "")}>
            {/*<span className={"nav-tab-spacer"}/>*/}
            <span className={""}>
                {
                    (!!closing)
                        ? <i className={"tree-tab-comanders mdi mdi-timer"} />
                        : (
                            <i
                                className={"tree-tab-comanders mdi mdi-arrow-collapse-vertical"}
                                onClick={ clickEvent => {
                                    new Promise((success, fail) => {
                                        close( true );
                                        // let newTree = closeAllNodes( props.tree );
                                        // success( newTree );
                                        closeAllNodes( props.tree )
                                            .then( newTree => {
                                                success( newTree );
                                            })
                                            .catch(e => {
                                                fail( e );
                                            })
                                        ;
                                    })
                                        .then( newTree => {
                                            showlog("apertura finita");
                                            close( false );
                                            props.onFinish( newTree );
                                        })
                                }}
                            />
                        )
                }
            </span>
            <span className={""}>
                {
                    (!!opening)
                        ? <i className={"tree-tab-comanders mdi mdi-timer"} />
                        : (
                            <i
                                className={"tree-tab-comanders mdi mdi-arrow-expand-vertical"}
                                onClick={ clickEvent => {

                                    new Promise((success, fail) => {
                                        open( true );
                                        // let newTree = openAllNodes( props.tree );
                                        // success( newTree );
                                        openAllNodes( props.tree )
                                            .then( newTree => {
                                                success( newTree );
                                            })
                                            .catch(e => {
                                                fail( e );
                                            })
                                        ;
                                    })
                                        .then( newTree => {
                                            open( false );
                                            props.onFinish( newTree );
                                        })
                                }}
                            />
                        )
                }
            </span>
        </div>
    );
}

