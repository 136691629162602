// @flow
import React, {Component, Suspense} from 'react';
import {connect} from 'react-redux';
import SetPasswordPage from "../pages/auth/set-password-page";
import SignupPage from "../pages/area/public/signup";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
// const Footer = React.lazy(() => import("./Footer"));
// loading
const loading = () => <div className="text-center">In Attesa</div>;

type DefaultLayoutProps = {
    children?: any,
};

class DefaultLayout extends Component<DefaultLayoutProps> {
    /**
     * On component update - update layout
     */
    componentDidMount = () => {
        if (document.body) document.body.classList.add('authentication-bg');
    };

    /**
     * On component unmount - reset layout
     */
    componentWillUnmount = () => {
        if (document.body) document.body.classList.remove('authentication-bg');
    };

    render() {
        const children = this.props.children || null;
        return (
            <Suspense fallback={loading()}>


                {
                    (
                        window
                            .location
                            .href                                       // http://195.231.2.108/passwordsetting/E6AB422F64BB36D62F70051ED260C8F4D499E5BCF0C20130DD6CD9F61CD99C073751F3BE5EFAD9A4/vitolipari1981@gmail.com/0/1
                            .split("/")                        // [http:, , 195..., passwordsetting, ...]
                            .filter((_, i) => i > 2)      // [passwordsetting, ...]
                            .filter(p => !!p)                             // [passwordsetting, ...]
                            //.reverse()                                  // [..., passwordsetting]  // tolto per legacy
                            [0] === "passwordsetting"                   // passwordsetting === passwordsetting
                    )
                        ? (
                            <SetPasswordPage
                                token={
                                    window
                                        .location
                                        .href
                                        .split("/")
                                        .filter((_, i) => i > 2)
                                        .filter(p => !!p)
                                        .reverse()
                                        [3]
                                }
                                email={
                                    window
                                        .location
                                        .href
                                        .split("/")
                                        .filter((_, i) => i > 2)
                                        .filter(p => !!p)
                                        .reverse()
                                        [2]
                                }
                                mode={
                                    window
                                        .location
                                        .href
                                        .split("/")
                                        .filter((_, i) => i > 2)
                                        .filter(p => !!p)
                                        .reverse()
                                        [1]
                                }
                                lang={
                                    window
                                        .location
                                        .href
                                        .split("/")
                                        .filter((_, i) => i > 2)
                                        .filter(p => !!p)
                                        .reverse()
                                        [0]
                                }
                            />

                        )
                        : <SignupPage/>

                }


            </Suspense>
        );
    }
}

export default connect()(DefaultLayout);
