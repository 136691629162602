import React, {Component} from "react";
import {errorlog, renderlog, showlog} from "../../../utils";
import {DATE_FORMAT_IT, permissions, role} from "../../../env";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import DeleteServiceQuestionModal from "../../../components/area/super-admin/delete-service-prompt-modal";
import NewServiceModal from "../../../components/area/super-admin/new-service-modal";
import {loadOrganizzationActiveService} from "../../../business-logic/active-service";
import moment from "moment";
import {Button, Col, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {translate} from "../../../components/i18n/translate-function";


const fieldMap = {
    id: "id",
    leading_name: "Organizzazione",
    service_name: "Servizio",
    date_create: "Data Creazione",
    date_start: "Inizio",
    date_update: "Aggiornamento",
    date_expire: "Scadenza",
    date_monthsNumber: "Mesi di Validità",
    collectionsMaxNumber: "Raccolte",
    evaluationsMaxNumber: "Valutazioni",
    action: " "
};

const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rowsfrom")} {from} {translate("to")} {to} {translate("of")} {size} {translate("active_services")}
        </span>
    ),
    sizePerPageList: [
        {
            text: "pochi",
            value: 25,
        },
        {
            text: "normale",
            value: 50,
        },
        {
            text: "molti",
            value: 250,
        }
    ]
};

const defaultSorted = [
    {
        dataField: "Id",
        order: "asc",
    },
];

const sortableFields = [
    "Azienda",
    "Servizio",
    "Data Creazione",
    "Inizio",
    "Aggiornamento",
    "Scadenza",
    "Mesi di Validità"
];

const hiddenFields = [
    "id",
    !!isUserRole( role.ADMIN )
        ? "Azienda"
        : ""
];

const formatColumns = (columnList, hiddenColumnList = []) => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
            .filter( ff => !hiddenColumnList.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;



export default class OrganizzationServicePage extends Component {

    state = {
        data: null
    }


    constructor( props ) {
        super( props );

        this.loadData = this.loadData.bind( this );
    }

    render() {

        renderlog("OrganizzationServicePage", this.state, this.props);

        return (
            <React.Fragment>

                <ToolkitProvider
                    bootstrap4
                    keyField="Id"
                    search
                    exportCSV={{onlyExportFiltered: true, exportAll: false}}>
                    {props => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                <Col sm={ 2 }>
                                    <div className="page-title-right float-right">
                                        {
                                            (!!hasPermission( permissions.CREATE_ACTIVE_SERVICE ))
                                                ? (
                                                    <Button
                                                        color={"success"}
                                                        onClick={ clickEvent => {
                                                            console.log("click nuovo servizio onClick");
                                                            this.setState({
                                                                ...this.state,
                                                                newServiceModal: true
                                                            });
                                                        }}
                                                    >
                                                        <i className="uil-user"/> {translate("New Service")}
                                                    </Button>
                                                )
                                                : null
                                        }

                                    </div>
                                </Col>
                            </Row>


                            {
                                (!!this.state.data && this.state.data.length > 0)
                                    ? (
                                        <BootstrapTable
                                            bootstrap4
                                            striped
                                            {...props.baseProps}
                                            size="sm"
                                            responsive
                                            keyField="Id"
                                            data={this.state.data}
                                            columns={
                                                formatColumns(
                                                    Object.keys(this.state.data[0]),
                                                    [
                                                        !!hasPermission(permissions.EDIT_ACTIVE_SERVICE)
                                                            ? ""
                                                            : " "
                                                    ]
                                                )
                                            }
                                            defaultSorted={defaultSorted}
                                            pagination={paginationFactory(paginationOptions)}
                                            wrapperClasses="table-responsive"
                                        />
                                    )
                                    : (
                                        <WaitingOrNodataMessage
                                            waiting={!(!!this.state.data && this.state.data.length === 0)}
                                            nodataMessage={"Nessun Servizio"}
                                            className={"bottom-margin-alignment"}
                                        />
                                    )
                            }

                        </React.Fragment>
                    )}
                </ToolkitProvider>


                {
                    (!!this.state.deletePopup)
                        ? (
                            <DeleteServiceQuestionModal
                                service={this.state.deletePopup}
                                onClose={() => {
                                    this.setState({deletePopup: null})
                                }}
                                onConfirm={() => {
                                    this.setState({
                                        ...this.state,
                                        deletePopup: null
                                    }, () => {
                                        this.loadData();
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    ( !!this.state.newServiceModal && hasPermission( permissions.EDIT_ACTIVE_SERVICE ) )
                        ? (
                            <NewServiceModal
                                isOrganizzation={ true }
                                service={this.state.newServiceModal}
                                onClose={() => {
                                    this.setState({...this.state, newServiceModal: null}, () => {
                                        this.loadData();
                                    })
                                }}
                                onConfirm={() => {
                                    this.setState({...this.state, newServiceModal: null}, () => {
                                        this.loadData();
                                    })
                                }}
                            />
                        )
                        : null
                }



            </React.Fragment>
        );
    }


    componentDidMount() {
        this.loadData();
    }


    loadData() {

        loadOrganizzationActiveService()

            // date
            .then( list => {

                return (
                    list
                        .map( service => {
                            service.date_create = moment(service.date_create).format(DATE_FORMAT_IT);
                            service.date_start = moment(service.date_start).format(DATE_FORMAT_IT);
                            service.date_expire = moment(service.date_expire).format(DATE_FORMAT_IT);
                            service.date_update = moment(service.date_update).format(DATE_FORMAT_IT);
                            return service;
                        })
                );
            })


            // extras   -----------------------------------------------------------------------------------------------
            .then(data => {

                return (
                    data
                        .map( service => {
                            /*
                            uil-edit-alt
                            mdi mdi-calendar-edit
                            mdi mdi-timetable
                             */

                            showlog("controllo service");
                            showlog(service);

                            service.action = (
                                <React.Fragment>
                                    <i
                                        className="uil-edit-alt comander-icon"
                                        onClick={ clickEvent => {
                                            console.log("modifica ", service.id);
                                            this.setState({...this.state, newServiceModal: service});
                                        }}
                                    />
                                    <i
                                        className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"
                                        onClick={ clickEvent => {
                                            console.log("elimina ", service.id);
                                            this.setState({...this.state, deletePopup: service});
                                        }}
                                    />
                                </React.Fragment>
                            );
                            return service;
                        })
                );
            })



            // field sort ---------------------------------------------------------------------------------------------
            .then( data => {

                console.log("data after extras");
                console.log( data );

                return (
                    data
                        .map( row => {

                            let sortedRow = {};
                            Object.keys(fieldMap)
                                .map( field => {
                                    sortedRow[field] = row[field];
                                })
                            ;
                            // Object.assign(sortedRow, row);
                            return sortedRow;

                        })
                );
            })

            // field map ----------------------------------------------------------------------------------------------
            .then( list => {

                console.log("data after field map");
                console.log( list );

                return (
                    list
                        .map( company => {
                            let dto = {};
                            Object.keys( company )
                                .forEach( rawField => {
                                    dto[ fieldMap[ rawField ] ] = company[ rawField ];
                                })
                            ;
                            return dto;
                        })
                );

            })


            // adjust ------------------------------------------------------------------------------------------------
            .then( list => {

                return (
                    list
                        .map( service => {
                            if( service.Servizio === "SiRating" )   service.Raccolte = "";
                            // else                                    service.Valutazioni = "";
                            return service;
                        })
                );

            })

            // finish -------------------------------------------------------------------------------------------------
            .then( data => {
                // console.log("data after field sort");
                // console.log( data );


                this.setState({
                    ...this.state,
                    data: data,
                    nounce: (new Date()).getTime()
                })
            })



            .catch(e => {
                errorlog("Caricamento servizi attivi per organizzazioni", e);
            })

    }



}


