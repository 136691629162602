import {
	getAssociatedEvaluations, getAssociatedSectorQuestions, getAssociatedSectors,
	getSectorQuestionsDimensions,
	getSectorQuestionsEvaluationsLink,
	loadAllSectorCompanies,
	loadAllSectorQuestion,
	putSectorQuestionMain
} from "../services/sector-question-service";


export const loadSectorQuestionsDimensions = () => {
	return getSectorQuestionsDimensions();
}


export const getSectorCompanies = sectorQuestionId => {
	return loadAllSectorCompanies( sectorQuestionId );
}

export const isOnlyMainSector = (obj,id) => {
	return putSectorQuestionMain(obj,id)
}


export const getSectorQuestion = () => loadAllSectorQuestion();

export const loadAssociatedEvaluations = id => {
	return getAssociatedEvaluations( id )
}

export const loadAssociatedSectorQuestion = id => {
	return getAssociatedSectorQuestions( id )
}


export const getSectorQuestionLinkedToEvaluations = (secID, evID) => getSectorQuestionsEvaluationsLink(secID, evID);

export const loadAssociatedSectors = id => {
	return getAssociatedSectors( id )
}