import React, {Component} from "react";
import {Modal, ModalFooter, ModalHeader} from 'reactstrap';
import {renderlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../env";
import "../../../style/collect-report-modal.css";
import {translate} from "../../i18n/translate-function";
import ActionButton from "../../save-button";
import {getCollectCsvFile} from "../../../business-logic/role-tree-manager";

export default class ExportCSVModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

        this.toggle = this.toggle.bind(this);

    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }

    componentDidMount(){}

    render() {

        renderlog("ExportCSVModal", this.state, this.props, COLOR_POLICY.validated);


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    {translate("Export Csv")}
                </ModalHeader>
                {/*<ModalBody className={this.state.bodyClass}>*/}
                {/*    <div>*/}
                {/*        Selezionare l'estensione del file che si sta per esportare.*/}
                {/*    </div>*/}
                {/*</ModalBody>*/}
                <ModalFooter>

                    <ActionButton
                        buttonText={"Formato csv"}
                        promise={() => getCollectCsvFile(this.props.id, this.props.fileName + ".csv", 0, "csv") }
                        onResult={() => {

                            this.props.onClose()

                        }}
                    />


                    <ActionButton
                        buttonText={"Formato excel"}
                        promise={() => getCollectCsvFile(this.props.id, this.props.fileName + ".csv", 0, "xls") }
                        onResult={() => {

                            this.props.onClose()

                        }}
                    />


                    <ActionButton
                        buttonText={"Annulla"}
                        promise={() => Promise.resolve()}
                        onResult={() => {

                            this.props.onClose()

                        }}
                    />

                </ModalFooter>

            </Modal>
        );


            return content;


    }

}
