import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {PATH_PREFIX, permissions, role} from "../../../env";
import DeleteUserQuestionModal from "../../../components/area/delete-user-prompt-modal";
import AdminUserEditModalContent from "../../../components/area/admin/user-edit-modal";
import {loadUsers} from "../../../business-logic/users";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {errorlog, renderlog} from "../../../utils/dev-utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import {onlyNotNull} from "../../../utils";
import {SRToolInfoBox} from "../../../components/srtool-info-box";
import {translate} from '../../../components/i18n/translate-function';

const fieldMap = {
	// id: "Id",
	picture: " ",
	//title: "Titolo",
	firstName: "Nome",
	lastName: "Cognome",
	role: "Ruolo",
	companyName: "Azienda",
	email: "Email",
	leadingCompany: "Organizzazione",
	language:"Lingua",
	" ": " "
};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Utenti
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];

const sortableFields = [
	"title",
	"firstName",
	"lastName",
	"role",
	"companyName",
	"email",
	"leadingCompany",
	"language"
];

const hiddenFields = [
	"id",
	"companyName",
	isUserRole( role.PROJECT_MANAGER ) ? null : "leadingCompany",
	"language_id"
]
.filter( onlyNotNull );

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class GriAdminUsers extends Component {

	state = {
		data: []
	}

	constructor(props) {
		super( props );
	}


	render() {

		renderlog("GriAdminUsers Page", this.state, this.props);


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{ label: 'SR Tool', path: PATH_PREFIX +'/welcome' },
						{ label: translate("Users"), active: true }
					]}
					title={
						<React.Fragment>
							{"Utenti"}
							<SRToolInfoBox
								className={"srtool-info-box"}
							/>
						</React.Fragment>
					}
				/>


				<Card>
					<CardBody>



						<Row>
							<Col>
								<div className="page-title-box">
									<div className="page-title-right">

										{
											(!!hasPermission( permissions.ADD_USER ))
												? (
													<Button
														color={"success"}
														onClick={ clickEvent => {
															console.log("click nuovo utente onClick");
															this.setState({
																...this.state,
																newUserModal: true
															});
														}}
													>
														<i className="uil-user"/> Nuovo Utente
													</Button>
												)
												: null
										}

									</div>
									<h4 className="page-title">{translate("Users List")}</h4>
								</div>
							</Col>
						</Row>


						{
							(!!this.state.data && this.state.data.length > 0 )
								? (


									<ToolkitProvider
										bootstrap4
										keyField="Id"
										search
										exportCSV={{ onlyExportFiltered: true, exportAll: false }}
									>
										{
											props => (
												<React.Fragment>
													<Row>
														<Col>
															<SearchBar {...props.searchProps} />
														</Col>
														{/*
														<Col className="text-right">
															<ExportCSVButton {...props.csvProps} className="btn btn-primary">
																Export CSV
															</ExportCSVButton>
														</Col>
														*/}
													</Row>


													<BootstrapTable
														bootstrap4
														striped
														{...props.baseProps}
														size="sm"
														responsive
														keyField="Id"
														data={ this.state.data || [] }
														columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
														defaultSorted={ defaultSorted }
														pagination={ paginationFactory(paginationOptions) }
														wrapperClasses="table-responsive"
													/>


												</React.Fragment>
											)
										}
									</ToolkitProvider>



								)
								: (
									<WaitingOrNodataMessage
										waiting={ !(!!this.state.data && this.state.data.length === 0) }
										fullHeight={ true }
										nodataMessage={translate("No Users")}
									/>
								)
						}


					</CardBody>
				</Card>


				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={ this.state.errorMessage }
								noheader={ true }
								noHazard={ true }
								onClose={ () => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.newUserModal)
						? (
							<AdminUserEditModalContent
								close={ forceRefresh => {
									let isToRefresh = (!!forceRefresh && typeof forceRefresh === "boolean" && forceRefresh === true)
									this.setState(
										{
											...this.state,
											newUserModal: false,
											user: null,
											errorMessage: !!isToRefresh ? "E' stata inviata una email con un link di attivazione valido per 48 ore" : null
										},
										() => {
											if( !!forceRefresh ) this.loadData();
										}
									);
								}}
								user={ this.state.user }
							/>
						)
						: null
				}

				{
					(!!this.state.deletePopup)
						? (
							<DeleteUserQuestionModal
								user={this.state.deletePopup}
								onClose={() => {
									this.setState({deletePopup: null})
								}}
								onConfirm={userDeletedStatus => {
									console.log("utente eliminato");
									console.log(userDeletedStatus);
									this.loadData( () => {
										this.setState({...this.state, deletePopup: null})
									});
								}}
							/>
						)
						: null
				}

			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
	}


	loadData( onEnd ) {

		loadUsers()


			// profilePicture ------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.map(row => {
							// TODO pictures
							row.picture = (
								<div
									className={"profile-picture-in-list picture-alternative"}
								>
									{ row.firstName.substr(0, 1).toUpperCase() + row.lastName.substr(0, 1).toUpperCase() }
								</div>
							);
							return row;
						})
				);
			})


			// buttons -------------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.map( row => {
							row[" "] = (
								<React.Fragment>
									<i
										className="mdi mdi-account-edit-outline comander-icon"
										onClick={ clickEvent => {
											console.log("modifica ", row.id);
											this.setState({...this.state, newUserModal: true, user: row});
										}}
									/>
									<i
										className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"
										onClick={ clickEvent => {
											console.log("elimina ", row.id);
											this.setState({...this.state, deletePopup: row});
										}}
									/>
								</React.Fragment>
							);
							return row;
						})
				);
			})


			// field sort ----------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							// Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})


			// finish --------------------------------------------------------------------------------------------------
			.then( responseData => {

				/*  format column
				let dataTable =
					responseData
						.map( row => {
							let rowDTO = {};
							Object.keys(row)
								.forEach( field => {
									rowDTO[ fieldMap[field] || field ]= row[field];
								})
							;
							return rowDTO;
						})

				;
				*/

				this.setState(
					{
						...this.state,
						data: responseData
					},
					() => {
						if( typeof onEnd === "function" ) onEnd();
					}
				);
			})
			.catch( e => {
				errorlog("Caricamento Utenti", e );
			})
		;

	}
}
