import React, { useState } from "react";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {renderlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../conf/color-policy";
import {
    CardHeader,
    Modal,
    ModalBody,
    ModalHeader,
    NavLink,
    Card,
    CardBody,
    Collapse,
    CustomInput
} from "reactstrap";
import ActionButton from "../../save-button";
import {loadMeasureUnitList, setUnit} from "../../../business-logic/gri";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {capitalize} from "../../../utils/string-utils";
import {cloneObject} from "../../../utils/object-utils";
import {getLastElementOr} from "../../../utils/array-utils";


export const MeasureUnitModal = props => {

    const [data, setData] = useState();
    const [errors, setErrors] = useState();
    const [activeSection, setActiveSection] = useState();
    const [activeUnit, setActiveUnit] = useState();
    const [inWait, setWait] = useState();

    renderlog("MeasureUnitModal", data, props, COLOR_POLICY.warning);


    if( !data ) {
        loadMeasureUnitList( props.collect.id )
            .then( muList => setData( muList ) )
            .catch( e => setErrors( e ) )
        ;
    }

    let context =
        (!!data && data.length > 0)
            ? (
                <AvForm>

                    {
                        data
                            .map( category => (
                                <Card className="mb-0">
                                    <CardHeader className={"measure-unit-categories-list"}>
                                        <NavLink
                                            id={ "cat-"+ category.id }
                                            href="#"
                                            onClick={clickEvent => {
                                                if( activeSection === category.id ) setActiveSection( 0 );
                                                else                                setActiveSection( category.id );
                                            }}
                                        >
                                            <div
                                                className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 small-accordion-title "}>
                                                <strong>
                                                    {
                                                        capitalize( category.name )
                                                    }
                                                </strong>
                                            </div>
                                            {/*}
                                            <span className={"float-right custom-tab-pane"}>
                                                {
                                                    (activeSection === category.id)
                                                        ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                                        : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow accordion-icon-gray"/>
                                                }
                                            </span>
                                            */}
                                        </NavLink>

                                        <ActionButton
                                            promise={ () => {
                                                setWait( true );

                                                let muSelected =
                                                    data
                                                        .filter( cat => cat.id === category.id )
                                                        .reduce( getLastElementOr, null )
                                                        .measureUnits
                                                            .filter( mu => !!mu.isSelected )
                                                            .reduce( getLastElementOr, null )
                                                                .id
                                                ;

                                                return setUnit(props.collect.id, category.id, muSelected);
                                            }}
                                            onResult={result => {
                                                props.onClose( true );
                                            }}
                                            onError={e => {
                                                props.onClose();
                                            }}
                                            className={"header-action-button"}
                                        />


                                    </CardHeader>
                                    <Collapse
                                        isOpen={ (activeSection === category.id) }
                                        toggler={ "#cat-"+ category.id }
                                    >
                                        <CardBody>
                                            {
                                                category.measureUnits
                                                    .map( unit => (
                                                        <div className={""}>
                                                            <CustomInput
                                                                type="radio"
                                                                name={"measure-unit-" + unit.id}
                                                                id={"measure-unit-" + unit.id}
                                                                label={ unit.name }
                                                                onClick={clickEvent => {

                                                                    let dataTmp = cloneObject( data );
                                                                    dataTmp
                                                                        .filter( cat => cat.id === category.id )
                                                                        .reduce( getLastElementOr, null )
                                                                        .measureUnits
                                                                            .map( un => {
                                                                                un.isSelected = false;
                                                                                if( un.id === unit.id ) un.isSelected = true;
                                                                            })
                                                                    ;

                                                                    setData( dataTmp );
                                                                }}
                                                                defaultChecked={ unit.isSelected }
                                                                checked={ unit.isSelected }
                                                            />
                                                        </div>
                                                    ))
                                            }
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            ))
                    }


                </AvForm>

            )
            : (
                <WaitingOrNodataMessage
                    waiting={ !(!!data && data.length === 0) }
                    waitMessage={"Caricamento Unità di Misura"}
                    nodataMessage={"Nessuna Unità di Misura"}
                    spinnerColor={"success"}
                />
            )
    ;

    if( !!errors ) {
        context = <span>{ errors }</span>
    }

    let content = (
        <Modal
            isOpen={true}
            toggle={ () => {
                if(!inWait) props.onClose()
            }}
        >
            <ModalHeader
                toggle={ () => {
                    if(!inWait) props.onClose()
                }}
                className={""}
            >
                <span>Unità di Misura</span>
            </ModalHeader>
            <ModalBody className={""}>
                { context }
            </ModalBody>

        </Modal>
    );


    return content;

};
