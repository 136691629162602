import React, {Component} from "react";
import {COLOR_POLICY, permissions, RATING_TYPE, role} from "../../../env";
import {CustomInput, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,} from 'reactstrap';
import {Search} from 'react-bootstrap-table2-toolkit';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {loadAllComapnyAllowedDimensions, loadCompaniesData} from "../../../business-logic/companies";

import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {loadActiveService} from "../../../business-logic/active-service";
import Select from "react-select";
import {addNewRating} from "../../../business-logic/rating";
import ActionButton from "../../save-button";
import {getLastElementOr, onlyNotNull} from "../../../utils/array-utils";
import {emoj, extractErrorMessage} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {loadOrganizzations} from "../../../business-logic/organizzations";
import {translate} from "../../i18n/translate-function";

const hiddenFields = [
	"id",
	"Selezionato"
];

const fieldMap = {
	sector_id: "id",
	sector_name: "Settore",
	selected: "Selezionato"
};


const LIST_LIMIT = 8;
const paginationOptions = {
	paginationSize: 2,
	pageStartIndex: 1,
	showTotal: false,
	hidePageListOnlyOnePage: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
	),
	sizePerPageList: [ LIST_LIMIT ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];

const sortableFields = [
	"Settore",
	"Selezionato"
];


const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
// const { ExportCSVButton } = CSVExport;


export default class AddRatingModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
		this.loadData = this.loadData.bind( this );
	}




	toggle = () => {
		if(!this.state.waiting) this.props.onClose();
	}

	render() {


		renderlog("AddRatingModal", this.state, this.props, COLOR_POLICY.edit)



		let context = (
				<AvForm>

					{/*     NOME  -------------------------------------------------------------------------------- */}
					<AvGroup>
						<Label for="firstName">{translate("Name")}</Label>
						<AvInput
							type="text"
							name="firstName"
							id="firstName"
							value={ this.state.name }
							onChange={ changeTextEvent => {

								console.log("cambio firstName");
								this.setState(
								{
									...this.state,
									name: changeTextEvent.target.value
								})
							}}
							required
						/>
					</AvGroup>


					{
						!!isUserRole( role.PROJECT_MANAGER )
							? (
								<FormGroup>
									<CustomInput
										type="switch"
										id={"isOrganizzation"}
										name={"isOrganizzation"}
										label={"Organizzazione"}
										className={"pmAuthorizationToggler"}
										onClick={clickEvent => {

											showlog("toggle", clickEvent);

											if( !!this.state.toggleOrganizzation ) {
												// spegnimento
												this.setState({
													...this.state,
													companies: null,
													companySelected: null,
													toggleOrganizzation: !this.state.toggleOrganizzation
												},
												() => {
													this.loadData();
												})

											}
											else {
												if( !!hasPermission( permissions.GET_ORGANIZZATION ) ) {


													this.setState({
															...this.state,
															companies: null,
															companySelected: null,
															toggleOrganizzation: !this.state.toggleOrganizzation
														},
														() => {
															loadOrganizzations()
																.then( list => {

																	Promise.all(
																		list
																			.map( org => org.id )
																			.map( orgId => {

																				return (
																					loadCompaniesData( orgId )
																						.then( companyData => {
																							return (
																								companyData
																									.map( com => Object.assign({leadingCompanyId: orgId}, com) )
																							);
																						})
																						.catch(e => {
																							errorlog("Caricamento Aziende delle organizzazioni", e);
																							return Promise.reject( e );
																						})
																				);


																			})
																	)

																		.then( list => {

																			return (
																				list
																					.reduce( (merged, current) => ([...merged, ...current]), [])
																			);

																		})

																		.then( mergedList => {

																			this.setState({
																				...this.state,
																				companies:
																					mergedList
																						.map( c => ({
																							id: c.id,
																							value: c.id,
																							name: c.ragioneSociale,
																							label: c.ragioneSociale +"("+ <em>{ c.leadingCompany }</em> +")",
																							org: c.leadingCompany,
																							orgid: c.leadingCompanyId,
																							dimensioni: c.dimension.id
																						}))
																			},()=>{showlog("aziende di organizzazioni",this.state.companies)});

																			// debugger;

																		})

																		.catch(e => {
																			errorlog("Caricamento lista aziende per ogni organizzazione", e);
																		})



																})
																.catch(e => {
																	errorlog("Caricamento lista organizzazioni", e);
																})
														})

												}
											}





										}}
										// valid={  }
										defaultChecked={ false }
									/>
								</FormGroup>
							)
							: null
					}


					{

						((!!this.props.isSiScoring || !!this.props.isGenderGap) && (!isUserRole(role.PROJECT_MANAGER) && !isUserRole(role.ARB_OPERATOR)))
							? null
							: (
								<React.Fragment>
									{/*     AZIENDA  -------------------------------------------------------------------------------- */}
									<FormGroup>
										<Label for="title">Azienda { (!this.state.companies) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
										<Select
											className="react-select"
											classNamePrefix="react-select"
											name="company"
											id="company"
											onChange={ changeEvent => {
												this.setState({
													...this.state,
													companySelected: changeEvent,
													dimensionSelected:
														this.state.dimensions
															.filter( d => {
																return (
																	d.id === (
																		this.state.companies
																			.filter( c => c.id === changeEvent.value )
																			.map( c => c.dimensioni )
																			.reduce( getLastElementOr, null)
																	)
																);
															})
															.filter( onlyNotNull )
															.map( d => ({value: d.id, label: d.name}))
															.reduce( getLastElementOr, null)
												})
											}}
											options={ (!!this.state.companies ? this.state.companies.map( c => ({value: c.id, label: c.name})) : []) }
											value={ this.state.companySelected }
											placeholder={ <span className={"select-placeholder"}>Seleziona Azienda</span> }
										/>
									</FormGroup>



									{/*     DIMENSIONE  -------------------------------------------------------------------------------- */}
									{
										!this.props.isGenderGap
											? (
												<FormGroup>
													<Label for="title">Dimensione</Label>
													<Select
														className="react-select"
														classNamePrefix="react-select"
														name="dim"
														id="dim"
														onChange={ changeEvent => {
															this.setState({
																...this.state,
																dimensionSelected: changeEvent
															})
														}}
														options={ (!!this.state.dimensions ? this.state.dimensions.map( d => ({value: d.id, label: d.name})) : []) }
														value={ this.state.dimensionSelected }
														placeholder={ <span className={"select-placeholder"}>Seleziona dimensione</span> }
													/>
												</FormGroup>
											)
											: null
									}



								</React.Fragment>
							)
					}






				</AvForm>
			);


		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{translate("Evaluation")}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{
						(!!this.state.createError)
							? (
								<span
									className={ COLOR_POLICY.error.textClass }
								>
									{ this.state.createError }
								</span>
							)
							: context
					}
				</ModalBody>
				<ModalFooter>
					{
						(
							(!!this.state.companySelected && !!this.state.dimensionSelected && !!this.state.name)
							|| ((!!this.props.isSiScoring || !!this.props.isGenderGap) && !!this.state.name)
						)
							? (
								<React.Fragment>
									{/*
									<Label for="cloneConfFrom">Clona la configurazione da</Label>

									<Select
										className="react-select req-right-comander-select chapter-select-box"
										classNamePrefix="react-select"
										name="cloneConfFrom"
										id="cloneConfFrom"
										onChange={ changeEvent => {

											this.setState({
												...this.state,
												cloneFrom: changeEvent.value
											});

										}}
										options={[
											{ value: 0, label: "nessuna" },
											...this.props.ratingList
												.filter( rating => rating.Dimensione === this.state.dimensionSelected.label )
												.map( rating => ({value: rating.id, label: rating.Nome}) )
										]}
									/>
									*/}

									{
										!!this.state.waiting
											? null
											: (
												<ActionButton
													color={"link"}
													buttonText={"annulla"}
													promise={ () => {
														return Promise.resolve();
													}}
													onResult={  toggleEvent => this.props.onClose() }
												/>
											)
									}

									{
										!!this.state.createError
											? null
											: (
												<ActionButton
													visible={
														(!!this.state.companySelected && !!this.state.dimensionSelected && !!this.state.name)
														|| ((!!this.props.isSiScoring || !!this.props.isGenderGap) && !!this.state.name)
													}
													promise={ () => {
														if( !!this.props.isSiScoring ) {
															let companyID = localStorage.getItem("company_id");
															if( isUserRole( role.PROJECT_MANAGER )) companyID = this.state.companySelected.value;
															return addNewRating( companyID, 1, this.state.name, null, RATING_TYPE.siscoring );
														}
														else {
															if( !!this.props.isGenderGap ) {
																return addNewRating( this.state.companySelected.value, 1, this.state.name, null, RATING_TYPE.gendergap );
															}
															else {
																return addNewRating( this.state.companySelected.value, this.state.dimensionSelected.value, this.state.name, this.state.cloneFrom, RATING_TYPE.sirating );
															}
														}
													}}
													onProcess={ () => {
														this.setState({
															...this.state,
															waiting: true
														})
													}}
													onResult={ result => {
														this.props.onClose()
													}}
													onError={ e => {

														let errorMessage =  ( extractErrorMessage(e.error.error) === "Non vi sono servizi attivi" ) ? ( extractErrorMessage(e.error.error) ): (extractErrorMessage( e ) ) ; //extractErrorMessage( e )

														showlog("%c"+ emoj(EMOJ.face_with_symbols_on_mouth) +" Errore al salvataggio di un nuovo Rating", "color: #f33; font-size: 20px;");
														showlog( e );
														// this.props.onClose( e || "Qualcosa è andato storto!" );

														this.setState(
															{
																...this.state,
																// createError: "Il servizio potrebbe non essere attivo",
																createError: errorMessage,
																waiting: false
															})

													}}
												/>
											)
									}

								</React.Fragment>
							)
							: null
					}
				</ModalFooter>

			</Modal>


		);


		return content;
	}

	componentDidMount() {
		this.loadData();
	}






	loadData( onEnd ) {

		loadActiveService()


			// filters ---------------------------------------------------------------------------------------------------
			.then( list => {

				showlog("lista arrivata da loadActiveServices");
				showlog( list );

				/*
				company_id: 6
				company_name: "CLS"
				date_create: "2021-04-21T13:01:03.827"
				date_expire: "2021-12-15T00:00:00"
				date_monthsNumber: 8
				date_start: "2021-04-15T00:00:00"
				date_update: "2021-04-21T13:01:03.827"
				id: 16
				service_id: 2
				service_name: "SiRating"
				 */
				return [ ...new Map( list.map( as => [ as.company_id, { id: as.company_id, name: as.company_name, ...as }] ) ).values() ];
			})




			// Extras Data ---------------------------------------------------------------------------------------------
			.then( data => {

				showlog("dopo filters");
				showlog(data);

				return (
					loadCompaniesData()
						.then( companies => {
							return (
								data
									.map( compObj => {



										showlog("servizio attivo e lista companies");
										showlog(compObj);
										showlog(
											companies
												.filter( c => c.id === compObj.company_id )
												.reduce( getLastElementOr, null)
										);
										showlog(companies);

										return (
											Object.assign(
												{},
												compObj,
												companies
													.filter( c => c.id === compObj.company_id )
													.reduce( getLastElementOr, null)
											)
										);
									})
							);
						})
						.then( companies => {
							return (
								loadAllComapnyAllowedDimensions()
									.then( dimensions => {
										return {companies: companies, dimensions: dimensions};
									})
									.catch(e => Promise.reject( e ))
							);
						})
						.catch(e => Promise.reject(e))
				);
			})


			// field sort ---------------------------------------------------------------------------------------------
			.then( ({companies, dimensions}) => {

				console.log("data after extras");
				console.log(companies, dimensions);


				this.setState({
					...this.state,
					companies: companies,
					dimensions: dimensions
				})
			})



	}

}
