// @flow
import React from 'react';
import CardBody from "reactstrap/es/CardBody";
import {Card, CardHeader, Col, Row} from "reactstrap";
import {translate} from "../components/i18n/translate-function";


export default class PrivacyPolicyPage extends React.Component {

    state = {
        content: "",
        successMessage: false,
        isButtonShow: false,
        attachedFiles: []
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div className={"page column-layout max-width-centered"}>

                <h3>Si Road Privacy</h3>

                <Card
                    className={"mb-0 simple-page-card"}
                    Style={ "margin-bottom: 10rem !important;" } // l'unico modo per poterlo fare
                    key={"welcome-page"}
                >
                    <CardHeader>
                        <h5 className="m-0">
                            <div className="custom-accordion-title d-block pt-2 pb-2">
                                {translate("WEBSITE PRIVACY POLICY")}
                            </div>
                        </h5>
                    </CardHeader>
                    <CardBody>

                        <Row>
                            <Col>

                                <span className={"custom-accordion-title d-block pt-2 pb-2"}>  1. {translate("IDENTITY OF THE DATA CONTROLLER. CONTACT DETAILS")}   </span>

                                <div className={"justify"}>
                                    {translate("privacy policy first paragraph first part")}
                                    <br/><br/>
                                    {translate("privacy policy first paragraph second part")}
                                </div>

                                <br/>

                                <span className={"custom-accordion-title d-block pt-2 pb-2"}>   2. {translate("NAVIGATION DATA")}   </span>

                                <div className={"justify"}>
                                    {translate("privacy policy second paragraph first part")}
                                    <br/><br/>
                                    {translate("privacy policy second paragraph second part")}
                                </div>

                                <br/>

                                <span className={"custom-accordion-title d-block pt-2 pb-2"}>  3. {translate("DATA COMMUNICATED BY THE USER")}  </span>

                                <div className={"justify"}>
                                    {translate("privacy policy third paragraph first part")}
                                    <br/><br/>
                                    {translate("privacy policy third paragraph second part")}
                                </div>

                                <br/>

                                <span className={"custom-accordion-title d-block pt-2 pb-2"}>  4. {translate("PURPOSE AND LEGAL BASIS")}  </span>

                                <div className={"justify"}>
                                    {translate("privacy policy fourth paragraph first part")}
                                    <br/><br/>
                                    {translate("privacy policy fourth paragraph second part")}
                                </div>

                                <br/>

                                <span className={"custom-accordion-title d-block pt-2 pb-2"}> 5. {translate("PROCESSING METHODS AND STORAGE TIMES")} </span>

                                <div className={"justify"}>
                                    {translate("privacy policy fifth paragraph first part")}
                                    <br/><br/>
                                    {translate("privacy policy fifth paragraph second part")}
                                    <br/><br/>
                                    {translate("privacy policy fifth paragraph third part")}
                                </div>

                                <br/>

                                <span className={"custom-accordion-title d-block pt-2 pb-2"}>  6. {translate("RIGHTS OF THE INTERESTED PARTIES")}   </span>

                                <div className={"justify"}>
                                    {translate("privacy policy sixth paragraph first part")}
                                    <br/><br/>
                                    {translate("privacy policy sixth paragraph second part")}
                                    <br/><br/>
                                    {translate("privacy policy sixth paragraph third part")}
                                </div>

                            </Col>
                        </Row>


                    </CardBody>
                </Card>


            </div>
        );
    }


};

