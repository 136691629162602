import React from "react";
import "./glossary-icon-svg-component.css";

export const GlossaryIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >

        <svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <path class="glossary-icon"
                  d="m105.88,36.34h-16.1c-16.4,0-32.8,0-49.21-.02-2.16,0-4.23-.2-6.15-.58-4.66-.93-8.66-3.55-10.99-7.18-2.23-3.49-2.73-7.5-1.41-11.3,2.18-6.27,8.52-10.17,16.55-10.17,14.58-.01,29.17-.01,43.75-.01h24.07c2.07,0,3.54-.39,4.38-1.16.62-.56.91-1.35.9-2.41-.02-1.71-.68-3.46-5.41-3.46h-16.87c-7.83,0-15.67,0-23.5,0-9.46,0-18.93,0-28.39.01-13.71.01-24.05,9.39-24.07,21.82-.02,11.31-.01,22.62,0,33.93v14.54c0,16.54,0,33.07,0,49.61,0,4.2.97,5.1,5.47,5.1,29.1,0,58.19,0,87.29,0,4.51,0,5.49-.91,5.49-5.09,0-26.17,0-52.35,0-78.52,0-4.28-.92-5.09-5.8-5.09Zm-64.38,7.13h11.94v23.31l-1.86-1.45c-3.09-2.4-5.52-2.33-8.12.23l-1.96,1.93v-24.03Zm62.18,74.48H21.47V38.48l1.57.61c.79.31,1.59.6,2.38.89,2.08.77,4.22,1.57,6.23,2.55,1.31.64,1.81,2.57,1.82,3.64.09,9.72.1,19.36.02,28.66-.02,2.74.63,3.95,2.5,4.61,1.9.67,3.46.16,5.38-1.75,1.09-1.09,2.22-2.16,3.44-3.3.57-.54,1.16-1.1,1.78-1.68l.81-.77,2.76,2.55c1.4,1.3,2.71,2.51,4,3.73,1.61,1.52,3.13,1.87,4.95,1.15,1.65-.66,2.35-1.82,2.34-3.89-.04-7.49-.03-14.98-.03-22.56v-9.31h42.26v74.35Z"/>
            <path class="glossary-icon"
                  d="m106.63,18.19c-22.7,0-45.39,0-68.09,0-1.89,0-3.26.38-4.07,1.12-.62.56-.93,1.37-.93,2.41,0,.86.01,3.5,5.04,3.5,8.07,0,16.14,0,24.2,0h9.68s9.58,0,9.58,0c8.21,0,16.42,0,24.63,0,1.86,0,3.26-.39,4.07-1.13.62-.57.94-1.37.93-2.4,0-.86-.01-3.5-5.05-3.5Z"/>
        </svg>


    </div>
);
