import React, {Component} from "react";
import {
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    PopoverBody,
    PopoverHeader,
    Row,
    Table,
    UncontrolledPopover
} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog} from "../../../utils/dev-utils";
import {isNotEmptyArray} from "../../../utils/array-utils";
import {COLOR_POLICY} from "../../../env";
import {QuestionReport_Get} from "../../../../src/services/request-manager";
import {translate} from "../../i18n/translate-function";


const fieldSdgMap = {
    thematic_name: "Tematica/Sottotematica",
    subthematic_name: "Sottotematica",
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    materiality: "Livello di Materialità",
    percentage: "%",
    sdg: "SDG"
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];











const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];


const hiddenSdgFields2 = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];

const htmlJoin = "<br/>"


export default class QuestionReport extends Component {



    /*
    importData = () => {




        //return Score_Get(this.props.evaluation_id, 0).then(r => {
        return (

            getQuestionReport(this.props.evaluation_id)
            // getQuestionReport(12)
                .then(r => {

                    console.log("rrrrrrrrrrrrrrrrr", r);
                    return (r.data || r);

                })

                .catch(e => {
                    console.log("errore");
                    console.log(e);
                })

        );







    }






    loadData() {

        // this.importData()
        //     .then(data => {


        let data = this.state.data;


                console.log("dasdgsaydguyds", data);


                this.setState({
                    data: true,

                    questionAnsweredSum: data[0].questionAnswered.count + data[1].questionAnswered.count + data[2].questionAnswered.count,
                    questionToAnswerSum: data[0].questionToAnswer.count + data[1].questionToAnswer.count + data[2].questionToAnswer.count,
                    questionAttachmentToValidateSum: data[0].questionAttachmentToValidate.count + data[1].questionAttachmentToValidate.count + data[2].questionAttachmentToValidate.count,
                    questionMildNcSum: data[0].questionMildNc.count + data[1].questionMildNc.count + data[2].questionMildNc.count,
                    questionWaitNcSum: data[0].questionWaitNc.count + data[1].questionWaitNc.count + data[2].questionWaitNc.count,
                    questionGraveNcSum: data[0].questionGraveNc.count + data[1].questionGraveNc.count + data[2].questionGraveNc.count,
                    questionTotalSum: data[0].questionTotal.count + data[1].questionTotal.count + data[2].questionTotal.count,
                    questionValidateSum: data[0].questionValidate.count + data[1].questionValidate.count + data[2].questionValidate.count,


                    questionAnswered0: data[0].questionAnswered.count, questionAnsweredList0: data[0].questionAnswered.thematicList,
                    questionToAnswer0: data[0].questionToAnswer.count, questionToAnswerList0: data[0].questionToAnswer.thematicList,
                    questionAttachmentToValidate0: data[0].questionAttachmentToValidate.count, questionAttachmentToValidateList0: data[0].questionAttachmentToValidate.thematicList,
                    questionMildNc0: data[0].questionMildNc.count, questionMildNcList0: data[0].questionMildNc.thematicList,
                    questionWaitNc0: data[0].questionWaitNc.count, questionWaitNcList0: data[0].questionWaitNc.thematicList,
                    questionGraveNc0: data[0].questionGraveNc.count, questionGraveNcList0: data[0].questionGraveNc.thematicList,
                    questionTotal0: data[0].questionTotal.count, questionTotalList0: data[0].questionTotal.thematicList,
                    questionValidate0: data[0].questionValidate.count, questionValidateList0: data[0].questionValidate.thematicList,


                    questionAnswered1: data[1].questionAnswered.count, questionAnsweredList1: data[1].questionAnswered.thematicList,
                    questionToAnswer1: data[1].questionToAnswer.count, questionToAnswerList1: data[1].questionToAnswer.thematicList,
                    questionAttachmentToValidate1: data[1].questionAttachmentToValidate.count, questionAttachmentToValidateList1: data[1].questionAttachmentToValidate.thematicList,
                    questionMildNc1: data[1].questionMildNc.count, questionMildNcList1: data[1].questionMildNc.thematicList,
                    questionWaitNc1: data[1].questionWaitNc.count, questionWaitNcList1: data[1].questionWaitNc.thematicList,
                    questionGraveNc1: data[1].questionGraveNc.count, questionGraveNcList1: data[1].questionGraveNc.thematicList,
                    questionTotal1: data[1].questionTotal.count, questionTotalList1: data[1].questionTotal.thematicList,
                    questionValidate1: data[1].questionValidate.count, questionValidateList1: data[1].questionValidate.thematicList,

                    questionAnswered2: data[2].questionAnswered.count, questionAnsweredList2: data[2].questionAnswered.thematicList,
                    questionToAnswer2: data[2].questionToAnswer.count, questionToAnswerList2: data[2].questionToAnswer.thematicList,
                    questionAttachmentToValidate2: data[2].questionAttachmentToValidate.count, questionAttachmentToValidateList2: data[2].questionAttachmentToValidate.thematicList,
                    questionMildNc2: data[2].questionMildNc.count, questionMildNcList2: data[2].questionMildNc.thematicList,
                    questionWaitNc2: data[2].questionWaitNc.count, questionWaitNcList2: data[2].questionWaitNc.thematicList,
                    questionGraveNc2: data[2].questionGraveNc.count, questionGraveNcList2: data[2].questionGraveNc.thematicList,
                    questionTotal2: data[2].questionTotal.count, questionTotalList2: data[2].questionTotal.thematicList,
                    questionValidate2: data[2].questionValidate.count, questionValidateList2: data[2].questionValidate.thematicList,


                })

            // });


    }
    */

    constructor(props) {
        super(props);

        this.state = {
            // data: this.props.data || [],
            values:null,
            questionAnswered: 0,
            questionAttachmentToValidate: 0,
            questionToAnswer: 0,
            QuestionMildNc: 0,
            QuestionWaitNc: 0,
            QuestionGraveNc: 0,
            questionTotal: 0,
            questionValidate: 0,
            questionCancelled: 0
        }

        this.toggle = this.toggle.bind(this);
        // this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }

    attachThematicsList(id,count,list ) {


        return (



            count!=0 ?

            (
                <React.Fragment>
                    <div id={"popover-"+id} style={{ display: 'inline-block', marginLeft: '-6px' }}> <b style={{color:"#727cf5",cursor:"pointer"}}>{count}</b> </div>
                    <div>
                        <UncontrolledPopover placement={"popover-"+id} target={"popover-"+id} trigger="legacy">
                            <PopoverHeader>Elenco tematiche</PopoverHeader>
                            <PopoverBody>
                                {

                                    list.map(x => (<li className={"rightSpaceList"}>{x}</li>))
                                }

                            </PopoverBody>
                        </UncontrolledPopover>

                    </div>

                </React.Fragment>
            )
            :

            <span style={{color:"gray",cursor:"pointer"}}>{count}</span>
        )



    }


    variableNameNumnberToList(variableName)

    {


        Object.keys( this.state ).filter(x=> x==variableName )

        let variableNameCoverted="";
        variableNameCoverted=variableName.replace('0', 'List0');
        variableNameCoverted=variableName.replace('1', 'List1');
        variableNameCoverted=variableName.replace('2', 'List2');

    }

    getStringNameobj(objName){

        return Object.keys( this.state ).filter(x=> x===objName )[0];
    }


    getValues(data){



        if( !isNotEmptyArray( data ) ) {
            return ({
                questionAnsweredSum: 0,
                questionToAnswerSum: 0,
                questionAttachmentWaitingSum: 0,
                questionAttachmentToValidateSum: 0,
                questionMildNcSum: 0,
                questionWaitNcSum: 0,
                questionGraveNcSum: 0,
                questionTotalSum: 0,
                questionValidateSum: 0,
                questionCancelledSum: 0,

                questionAnswered0: 0,
                questionToAnswer0: 0,
                questionAttachmentWaiting0: 0,
                questionAttachmentToValidate0: 0,
                questionMildNc0: 0,
                questionWaitNc0: 0,
                questionGraveNc0: 0,
                questionTotal0: 0,
                questionValidate0: 0,
                questionCancelled0: 0,


                questionAnswered1: 0,
                questionToAnswer1: 0,
                questionAttachmentWaiting1:    0,
                questionAttachmentToValidate1: 0,
                questionMildNc1: 0,
                questionWaitNc1: 0,
                questionGraveNc1: 0,
                questionTotal1: 0,
                questionValidate1: 0,
                questionCancelled1: 0,

                questionAnswered2: 0,
                questionToAnswer2: 0,
                questionAttachmentWaiting2: 0,
                questionAttachmentToValidate2: 0,
                questionMildNc2: 0,
                questionWaitNc2: 0,
                questionGraveNc2: 0,
                questionTotal2: 0,
                questionValidate2: 0,
                questionCancelled2: 0
            });
        }


        data =
            data
                .map( d => {
                    if( !d.questionAnswered ) d.questionAnswered = {count:0};
                    if( !d.questionToAnswer ) d.questionToAnswer = {count:0};
                    if( !d.questionAttachmentWaiting ) d.questionAttachmentWaiting = {count:0};
                    if( !d.questionAttachmentToValidate ) d.questionAttachmentToValidate = {count:0};
                    if( !d.questionMildNc ) d.questionMildNc = {count:0};
                    if( !d.questionWaitNc ) d.questionWaitNc = {count:0};
                    if( !d.questionGraveNc ) d.questionGraveNc = {count:0};
                    if( !d.questionTotal ) d.questionTotal = {count:0};
                    if( !d.questionValidate ) d.questionValidate = {count:0};
                    if( !d.questionCancelled ) d.questionCancelled = {count:0};
                    return d;
                })
        ;

        let safer = 0;
        while( ((3 - data.length) > 0) && safer < 10 ) {
            data.push({
                questionAnswered: {count:0},
                questionToAnswer: {count:0},
                questionAttachmentWaiting: {count:0},
                questionAttachmentToValidate: {count:0},
                questionMildNc: {count:0},
                questionWaitNc: {count:0},
                questionGraveNc: {count:0},
                questionTotal: {count:0},
                questionValidate: {count:0},
                questionCancelled: {count:0}
            });
            safer++;
        }

        return( {
            questionAnsweredSum: data[0].questionAnswered.count + data[1].questionAnswered.count + data[2].questionAnswered.count,
            questionToAnswerSum: data[0].questionToAnswer.count + data[1].questionToAnswer.count + data[2].questionToAnswer.count,
            questionAttachmentWaitingSum: data[0].questionAttachmentWaiting.count + data[1].questionAttachmentWaiting.count + data[2].questionAttachmentWaiting.count,
            questionAttachmentToValidateSum: data[0].questionAttachmentToValidate.count + data[1].questionAttachmentToValidate.count + data[2].questionAttachmentToValidate.count,
            questionMildNcSum: data[0].questionMildNc.count + data[1].questionMildNc.count + data[2].questionMildNc.count,
            questionWaitNcSum: data[0].questionWaitNc.count + data[1].questionWaitNc.count + data[2].questionWaitNc.count,
            questionGraveNcSum: data[0].questionGraveNc.count + data[1].questionGraveNc.count + data[2].questionGraveNc.count,
            questionTotalSum: data[0].questionTotal.count + data[1].questionTotal.count + data[2].questionTotal.count,
            questionValidateSum: data[0].questionValidate.count + data[1].questionValidate.count + data[2].questionValidate.count,
            questionCancelledSum: data[0].questionCancelled.count + data[1].questionCancelled.count + data[2].questionCancelled.count,

            questionAnswered0: data[0].questionAnswered.count, questionAnsweredList0: data[0].questionAnswered.thematicList,
            questionToAnswer0: data[0].questionToAnswer.count, questionToAnswerList0: data[0].questionToAnswer.thematicList,
            questionAttachmentWaiting0:    data[0].questionAttachmentWaiting.count,    questionAttachmentWaitingList0:       data[0].questionAttachmentWaiting.thematicList,
            questionAttachmentToValidate0: data[0].questionAttachmentToValidate.count, questionAttachmentToValidateList0: data[0].questionAttachmentToValidate.thematicList,
            questionMildNc0: data[0].questionMildNc.count, questionMildNcList0: data[0].questionMildNc.thematicList,
            questionWaitNc0: data[0].questionWaitNc.count, questionWaitNcList0: data[0].questionWaitNc.thematicList,
            questionGraveNc0: data[0].questionGraveNc.count, questionGraveNcList0: data[0].questionGraveNc.thematicList,
            questionTotal0: data[0].questionTotal.count, questionTotalList0: data[0].questionTotal.thematicList,
            questionValidate0: data[0].questionValidate.count, questionValidateList0: data[0].questionValidate.thematicList,
            questionCancelled0: data[0].questionCancelled.count, questionCancelledList0: data[0].questionCancelled.thematicList,


            questionAnswered1: data[1].questionAnswered.count, questionAnsweredList1: data[1].questionAnswered.thematicList,
            questionToAnswer1: data[1].questionToAnswer.count, questionToAnswerList1: data[1].questionToAnswer.thematicList,
            questionAttachmentWaiting1:    data[1].questionAttachmentWaiting.count,    questionAttachmentWaitingList1: data[1].questionAttachmentWaiting.thematicList,
            questionAttachmentToValidate1: data[1].questionAttachmentToValidate.count, questionAttachmentToValidateList1: data[1].questionAttachmentToValidate.thematicList,
            questionMildNc1: data[1].questionMildNc.count, questionMildNcList1: data[1].questionMildNc.thematicList,
            questionWaitNc1: data[1].questionWaitNc.count, questionWaitNcList1: data[1].questionWaitNc.thematicList,
            questionGraveNc1: data[1].questionGraveNc.count, questionGraveNcList1: data[1].questionGraveNc.thematicList,
            questionTotal1: data[1].questionTotal.count, questionTotalList1: data[1].questionTotal.thematicList,
            questionValidate1: data[1].questionValidate.count, questionValidateList1: data[1].questionValidate.thematicList,
            questionCancelled1: data[1].questionCancelled.count, questionCancelledList1: data[1].questionCancelled.thematicList,

            questionAnswered2: data[2].questionAnswered.count, questionAnsweredList2: data[2].questionAnswered.thematicList,
            questionToAnswer2: data[2].questionToAnswer.count, questionToAnswerList2: data[2].questionToAnswer.thematicList,
            questionAttachmentWaiting2:    data[2].questionAttachmentWaiting.count,    questionAttachmentWaitingList2: data[2].questionAttachmentWaiting.thematicList,
            questionAttachmentToValidate2: data[2].questionAttachmentToValidate.count, questionAttachmentToValidateList2: data[2].questionAttachmentToValidate.thematicList,
            questionMildNc2: data[2].questionMildNc.count, questionMildNcList2: data[2].questionMildNc.thematicList,
            questionWaitNc2: data[2].questionWaitNc.count, questionWaitNcList2: data[2].questionWaitNc.thematicList,
            questionGraveNc2: data[2].questionGraveNc.count, questionGraveNcList2: data[2].questionGraveNc.thematicList,
            questionTotal2: data[2].questionTotal.count, questionTotalList2: data[2].questionTotal.thematicList,
            questionValidate2: data[2].questionValidate.count, questionValidateList2: data[2].questionValidate.thematicList,
            questionCancelled2: data[2].questionCancelled.count, questionCancelledList2: data[2].questionCancelled.thematicList,
        })

    }


    componentDidMount(){


        if(!!this.props.data){
            this.setState({values:this.getValues(this.props.data)});

        }else{

            QuestionReport_Get(this.props.evaluation_id, 0).then(r => {

                this.setState({values:this.getValues(r)});
            })
        }


    }

    render() {


        renderlog("QuestionReportModal", this.state, this.props, COLOR_POLICY.validated);


        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm" />
                <span className={"loading-text-alignment"}>Caricamento...</span>
            </React.Fragment>
        );



        if (this.state.values) {
            context = (

                <React.Fragment>
                    <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                        <Col>
                            <br />
                            {/* <SearchBar {...props.searchProps} /> */} <br />



                            <Table>
                                <thead>
                                    <tr>
                                        <th>Stato della domanda</th>
                                        <th>Enviromental</th>
                                        <th>Social</th>
                                        <th>Governance</th>
                                        <th>Totale</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {/* <tr >
                                        <td style={{ background: '#dad8fd' }}><b>Tutte</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.state.values.questionTotal0}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.state.values.questionTotal1}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.state.values.questionTotal2}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.state.values.questionTotalSum}</b></td>
                                    </tr> */}
                                    <tr >
                                        <td style={{ background: '#dad8fd' }}><b>Tutte</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('questionTotal0',this.state.values.questionTotal0,this.state.values.questionTotalList0)}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('questionTotal1',this.state.values.questionTotal1,this.state.values.questionTotalList1)}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('questionTotal2',this.state.values.questionTotal2,this.state.values.questionTotalList2)}</b></td>
                                        <td style={{ background: '#dad8fd'}} className="tableBorderLeft">{this.state.values.questionTotalSum}</td>
                                    </tr>

                                    {

                                        (!!this.props.isAudit) &&

                                        (
                                            <React.Fragment>
                                                <tr>
                                                    <td>Da Analizzare</td>
                                                    <td>{this.attachThematicsList('questionAnswered0',this.state.values.questionAnswered0,this.state.values.questionAnsweredList0,)}</td>
                                                    <td>{this.attachThematicsList('questionAnswered1',this.state.values.questionAnswered1,this.state.values.questionAnsweredList1,)}</td>
                                                    <td>{this.attachThematicsList('questionAnswered2',this.state.values.questionAnswered2,this.state.values.questionAnsweredList2,)}</td>
                                                    <td className="tableBorderLeft">{this.state.values.questionAnsweredSum}</td>
                                                </tr>
                                            </React.Fragment>
                                        )

                                    }

                                    {

                                        (!this.props.isAudit) &&

                                        (
                                            <React.Fragment>
                                                <tr>
                                                    <td>Da Rispondere</td>
                                                    <td>{this.attachThematicsList('questionToAnswer0',this.state.values.questionToAnswer0,this.state.values.questionToAnswerList0,)}</td>
                                                    <td>{this.attachThematicsList('questionToAnswer1',this.state.values.questionToAnswer1,this.state.values.questionToAnswerList1,)}</td>
                                                    <td>{this.attachThematicsList('questionToAnswer2',this.state.values.questionToAnswer2,this.state.values.questionToAnswerList2,)}</td>
                                                    <td className="tableBorderLeft">{this.state.values.questionToAnswerSum}</td>
                                                </tr>
                                                <tr>
                                                    <td>Risposte</td>
                                                    <td>{this.attachThematicsList('questionAnswered0',this.state.values.questionAnswered0,this.state.values.questionAnsweredList0,)}</td>
                                                    <td>{this.attachThematicsList('questionAnswered1',this.state.values.questionAnswered1,this.state.values.questionAnsweredList1,)}</td>
                                                    <td>{this.attachThematicsList('questionAnswered2',this.state.values.questionAnswered2,this.state.values.questionAnsweredList2,)}</td>
                                                    <td className="tableBorderLeft">{this.state.values.questionAnsweredSum}</td>
                                                </tr>
                                            </React.Fragment>
                                        )

                                    }


                                    <tr>
                                        <td>Allegato richiesto</td>
                                        <td>{this.attachThematicsList('questionAttachmentWaiting0',this.state.values.questionAttachmentWaiting0,this.state.values.questionAttachmentWaitingList0,)}</td>
                                        <td>{this.attachThematicsList('questionAttachmentWaiting1',this.state.values.questionAttachmentWaiting1,this.state.values.questionAttachmentWaitingList1,)}</td>
                                        <td>{this.attachThematicsList('questionAttachmentWaiting2',this.state.values.questionAttachmentWaiting2,this.state.values.questionAttachmentWaitingList2,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.questionAttachmentWaitingSum}</td>
                                    </tr>
                                    <tr>
                                        <td>Allegato da Validare</td>
                                        <td>{this.attachThematicsList('questionAttachmentToValidate0',this.state.values.questionAttachmentToValidate0,this.state.values.questionAttachmentToValidateList0,)}</td>
                                        <td>{this.attachThematicsList('questionAttachmentToValidate1',this.state.values.questionAttachmentToValidate1,this.state.values.questionAttachmentToValidateList1,)}</td>
                                        <td>{this.attachThematicsList('questionAttachmentToValidate2',this.state.values.questionAttachmentToValidate2,this.state.values.questionAttachmentToValidateList2,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.questionAttachmentToValidateSum}</td>
                                    </tr>
                                    <tr>
                                        <td>Info Aggiuntive</td>
                                        <td>{this.attachThematicsList('questionMildNc0',this.state.values.questionMildNc0,this.state.values.questionMildNcList0,)}</td>
                                        <td>{this.attachThematicsList('questionMildNc1',this.state.values.questionMildNc1,this.state.values.questionMildNcList1,)}</td>
                                        <td>{this.attachThematicsList('questionMildNc2',this.state.values.questionMildNc2,this.state.values.questionMildNcList2,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.questionMildNcSum}</td>

                                    </tr>
                                    <tr>
                                        <td>NC Grave</td>
                                        <td>{this.attachThematicsList('questionGraveNc0',this.state.values.questionGraveNc0,this.state.values.questionGraveNcList0,)}</td>
                                        <td>{this.attachThematicsList('questionGraveNc1',this.state.values.questionGraveNc1,this.state.values.questionGraveNcList1,)}</td>
                                        <td>{this.attachThematicsList('questionGraveNc2',this.state.values.questionGraveNc2,this.state.values.questionGraveNcList2,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.questionGraveNcSum}</td>
                                    </tr>
                                    <tr>
                                        <td>NC In Attesa</td>
                                        <td>{this.attachThematicsList('questionWaitNc0',this.state.values.questionWaitNc0,this.state.values.questionWaitNcList0,)}</td>
                                        <td>{this.attachThematicsList('questionWaitNc1',this.state.values.questionWaitNc1,this.state.values.questionWaitNcList1,)}</td>
                                        <td>{this.attachThematicsList('questionWaitNc2',this.state.values.questionWaitNc2,this.state.values.questionWaitNcList2,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.questionWaitNcSum}</td>
                                    </tr>
                                    <tr>
                                        <td>Annullate</td>
                                        <td>{this.attachThematicsList('questionCancelled0',this.state.values.questionCancelled0,this.state.values.questionCancelledList0,)}</td>
                                        <td>{this.attachThematicsList('questionCancelled1',this.state.values.questionCancelled1,this.state.values.questionCancelledList1,)}</td>
                                        <td>{this.attachThematicsList('questionCancelled2',this.state.values.questionCancelled2,this.state.values.questionCancelledList2,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.questionCancelledSum}</td>
                                    </tr>
                                    <tr>
                                        <td>Validate</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('questionValidateNc0',this.state.values.questionValidate0,this.state.values.questionValidateList0,)}</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('questionValidateNc1',this.state.values.questionValidate1,this.state.values.questionValidateList1,)}</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('questionValidateNc2',this.state.values.questionValidate2,this.state.values.questionValidateList2,)}</td>
                                        <td className="tableBorderLeft tableBorderTop">{this.state.values.questionValidateSum}</td>
                                    </tr>

                                </tbody>
                            </Table>




                        </Col>
                    </Row>

                </React.Fragment>
            );
        }




        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Report Domande
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>


        );



        if (!!this.props.NoModal) {
            return context;
        } else {
            return content;
        }


    }








}




