import React from "react";
import {errorlog, renderlog} from "../../../../utils/dev-utils";
import {PATH_PREFIX} from "../../../../env";
import PageTitle from "../../../../components/PageTitle";
import {Col, Row} from "reactstrap";
import SRToolInformativeChart from "./informative-chart";
import {ChartSettingsModal} from "../../../../components/area/gri-admin/chart-settings-modal";
import {getLastElementOr, onlyNotNull} from "../../../../utils/array-utils";
import {loadDashboardInit} from "../../../../business-logic/charts";
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import {SRToolInfoBox} from "../../../../components/srtool-info-box";
import SRToolLogoDarkText from "../../../../assets/images/Sr_tool_logo_dark_text_2.svg";
import {translate} from "../../../../components/i18n/translate-function";
import VersionModal from "../../../../components/area/super-admin/version-modal";

const CHARTS = [
    { id: 1, title:  translate("GRAPHIC") +" #1" },
    { id: 2, title: translate("GRAPHIC") +" #2" },
    { id: 3, title: translate("GRAPHIC") +" #3" },
    { id: 4, title: translate("GRAPHIC") +" #4" },
    { id: 5, title: translate("GRAPHIC") +" #5", fixed: true },
    // { id: 6, title: "Grafico #6" }
];

const MIN = 20;
const MAX = 55;

const ID_TYPE = [
    { id: 0, type: "requirement" },
    { id: 1, type: "subRequirement" },
    { id: 2, type: "subSubRequirement" }
];

export default class SRToolBaseChartPage extends React.Component {


    state = {
        dataFilterObj: null,
        arg: null,
        conf: []
    }

    constructor(props) {
        super( props );
        this.loadData = this.loadData.bind( this );
    }




    render() {

        renderlog( "SRToolChartPage", this.state, this.props);

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: "SR Tool", path: PATH_PREFIX +"/welcome" },
                        { label: "Dashboard", active: true }
                    ]}
                    title={
                        <React.Fragment>
                            {/*
                            <img src={ PRODUCTION_HOST +"/assets/Loghi/SR_Tool_logo.svg" } alt="logo" height="50" style={{marginRight:'10px'}} />
                            */}
                            <img
                                // src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
                                src={ SRToolLogoDarkText }
                                style={{
                                    marginRight:'10px',
                                    height: "60px",
                                    float: "left",
                                    marginTop: "10px"
                                }}
                            />

                            <p style={{float:"left", marginTop: "27px",
                                marginBottom: "-23px",
                                fontSize: "14px",cursor:"pointer",height:"0px"}}
                               onClick={clickEvent => {
                                   this.setState({
                                       versionModal: true
                                   })
                               }
                               }> v. {(localStorage.getItem("srToolVersion")).trim()} </p>

                            {/*"Dashboard"*/}
                            <SRToolInfoBox
                                className={"srtool-info-box"}
                            />
                        </React.Fragment>
                    }
                />


                {
                    !!this.state.versionModal
                        ? (
                            <VersionModal
                                tool={"SR Tool-1"}
                                onClose={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        versionModal: false
                                    })
                                }}/>
                        )
                        :
                        null
                }

                {
                    (!!this.state.data && this.state.data.length > 0)
                        ? (
                            <React.Fragment>
                                <Row>
                                    {
                                        CHARTS
                                            .filter( (ch, index) => index < 2)
                                            .map( ch => (
                                                <Col xl={6} lg={6}>
                                                    <SRToolInformativeChart
                                                        key={ ch.id }
                                                        chartId={ ch.id }
                                                        title={ ch.title }
                                                        arg={
                                                            this.state.conf
                                                                .filter( c => c.chartId === ch.id )
                                                                .reduce( getLastElementOr, null )
                                                        }
                                                        goals={
                                                            this.state.goals
                                                                .filter( c => c.chartId === ch.id )
                                                                .reduce( getLastElementOr, null )
                                                        }
                                                        openChartSettings={ () => {
                                                            this.setState({ chartSettingsModal: ch.id })
                                                        }}
                                                    />
                                                </Col>
                                            ))
                                    }
                                </Row>
                                <Row>
                                    {
                                        CHARTS
                                            .filter( (ch, index) => ((index > 1) && (index < 4)))
                                            .map( ch => (
                                                <Col xl={6} lg={6}>
                                                    <SRToolInformativeChart
                                                        key={ ch.id }
                                                        chartId={ ch.id }
                                                        title={ ch.title }
                                                        arg={
                                                            this.state.conf
                                                                .filter( c => c.chartId === ch.id )
                                                                .reduce( getLastElementOr, null )
                                                        }
                                                        goals={
                                                            this.state.goals
                                                                .filter( c => c.chartId === ch.id )
                                                                .reduce( getLastElementOr, null )
                                                        }
                                                        openChartSettings={ () => {
                                                            this.setState({ chartSettingsModal: ch.id })
                                                        }}
                                                    />
                                                </Col>
                                            ))
                                    }
                                </Row>

                                <Row>
                                    {/*
                                        CHARTS
                                            .filter( (ch, index) => index > 3)
                                            .map( ch => (
                                                <Col xl={6} lg={6}>
                                                    <SRToolInformativeChart
                                                        key={ ch.id }
                                                        chartId={ ch.id }
                                                        title={ ch.title }
                                                        arg={ CHART_5_DATA.arg }
                                                        goals={ CHART_5_DATA.goals }
                                                        openChartSettings={ () => {
                                                            if( ch.id === 5 ) {

                                                            }
                                                            else {
                                                                this.setState({ chartSettingsModal: ch.id })
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            ))
                                    */}
                                </Row>

                            </React.Fragment>
                        )
                        : (
                            <WaitingOrNodataMessage
                                waiting={ !(!!this.state.data && this.state.data.length === 0) }
                                fullHeight={ true }
                                nodataMessage={"Nessun Dato"}
                            />
                        )
                }




                {
                    (!!this.state.chartSettingsModal)
                        ? (
                            <ChartSettingsModal
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        chartSettingsModal: undefined
                                    })
                                }}
                                chartId={ this.state.chartSettingsModal }
                                configuration={
                                    this.state.conf
                                        .filter( c => c.chartId === this.state.chartSettingsModal )
                                        .reduce( getLastElementOr, null )
                                }
                                onSaveConfiguration={ conf => {
                                    // debugger;
                                    this.setState({
                                        ...this.state,
                                        conf:
                                            [
                                                ...this.state.conf
                                                    .filter( c => c.chartId !== this.state.chartSettingsModal ),
                                                { chartId: this.state.chartSettingsModal, ...conf, forceLoad: true }
                                            ],
                                        chartSettingsModal: undefined,

                                        // TODO
                                        goals:
                                            [
                                                ...this.state.goals
                                                    .filter( c => c.chartId == this.state.chartSettingsModal )
                                            ]

                                        /*
                                            data
                                                .map( singleData => ({
                                                        chartId: singleData.metadata.graphId,
                                                        valueGoal:
                                                            (singleData.payload.length === 1)
                                                                ? singleData.payload[0].valueGoal
                                                                : null
                                                        ,
                                                        valueStartGoal:
                                                            (singleData.payload.length === 1)
                                                                ? singleData.payload[0].valueStartGoal
                                                                : null
                                                    })
                                                )
                                        */
                                    })
                                }}
                            />
                        )
                        : null
                }


            </React.Fragment>
        );
    }


    componentDidMount() {

        // errorlog("prova");
        // warninglog("Prova");

        this.loadData()
            .then( () => {
                // showlog( this.state, this.props );
                // debugger;
            })
        ;


    }


    loadData() {

        return (
            Promise.resolve([])

                // dashboardinit
                .then( () => {
                    return loadDashboardInit();
                })

                /*
                // experiment --------------------------------------------------------------------------------------------------
                .then( data => {
                    if(!!isNotEmptyArray( data )) {
                        data =
                            data
                                .map( singleData => {
                                    singleData.payload = [];
                                    return singleData;
                                })
                        ;
                    }
                    return data;
                })
                */


                // grafici fissi --------------------------------------------------------------------------------------------------
                .then( data => {


                    return data;

                    /*
                    return (
                        new Promise((success, fail) => {

                            let conf = {};
                            let fixedGoals = {};

                            this.setState({
                                ...this.state,
                                conf:
                                    [
                                        ...this.state.conf
                                            .filter( c => c.chartId !== this.state.chartSettingsModal ),
                                        { chartId: 5, ...conf, forceLoad: true }
                                    ],
                                chartSettingsModal: undefined,

                                goals:
                                    [
                                        ...this.state.goals
                                            .filter( c => c.chartId == this.state.chartSettingsModal ),
                                        fixedGoals
                                    ]

                            },

                                () => {

                                    success( data );

                                }

                            )


                        })
                    );

                     */

                })

                // finish --------------------------------------------------------------------------------------------------
                .then( data => {

                    return (
                        new Promise((success, fail) => {

                            if( !data || data.length === 0 ) {
                                this.setState({
                                    ...this.state,
                                    data: CHARTS,
                                    goals: [
                                        {
                                            chartId: 1,
                                            valueGoal: 0,
                                            valueStartGoal: 0
                                        },
                                        {
                                            chartId: 2,
                                            valueGoal: 0,
                                            valueStartGoal: 0
                                        },
                                        {
                                            chartId: 3,
                                            valueGoal: 0,
                                            valueStartGoal: 0
                                        },
                                        {
                                            chartId: 4,
                                            valueGoal: 0,
                                            valueStartGoal: 0
                                        }
                                    ],
                                    conf: [
                                        { chartId: 1 },
                                        { chartId: 2 },
                                        { chartId: 3 },
                                        { chartId: 4 }
                                    ]
                                })
                            }
                            else {
                                // debugger
                                this.setState({
                                    ...this.state,
                                    data:
                                        data
                                            .map( singleData => (
                                                [
                                                    ...singleData.payload
                                                        .map( (datas, dIndex) => ({
                                                            name: datas.collection_name || dIndex,
                                                            data:
                                                                datas.values
                                                                    .map( val => val.valueDecimal )
                                                        })),
                                                    /*
                                                    ( singleData.payload.length === 1 )
                                                        ? ({
                                                            name: "Obiettivo",
                                                            type: "straight",
                                                            data:
                                                                Array(12)
                                                                    .fill(true)
                                                                    .map((el, index) => parseFloat( singleData.payload[0].valueStartGoal ) + parseFloat(parseFloat(parseFloat(singleData.payload[0].valueGoal - singleData.payload[0].valueStartGoal) / 11) * index))
                                                        })
                                                        : null
                                                     */
                                                ]
                                            ))
                                            .filter( onlyNotNull )
                                    ,


                                    goals:
                                        data
                                            .map( singleData => ({
                                                chartId: singleData.metadata.graphId,
                                                valueGoal:
                                                    (singleData.payload.length === 1)
                                                        ? singleData.payload[0].valueGoal
                                                        : null
                                                ,
                                                valueStartGoal:
                                                    (singleData.payload.length === 1)
                                                        ? singleData.payload[0].valueStartGoal
                                                        : null
                                                ,
                                                dateGoal:
                                                    (singleData.payload.length === 1)
                                                        ? singleData.payload[0].dateGoal
                                                        : null
                                                ,
                                                dateStartGoal:
                                                    (singleData.payload.length === 1)
                                                        ? singleData.payload[0].dateStartGoal
                                                        : null
                                            }))
                                    ,

                                    conf:
                                        data
                                            .map( singleData => ({
                                                chartId: singleData.metadata.graphId,
                                                UnitMeasurment:singleData.metadata.unitMeasurment,
                                                name: singleData.metadata.graphName,

                                                // comanders
                                                path: singleData.metadata.filter.pathList,

                                                thematics: singleData.metadata.filter.tree.thematicList.map( th => ({id: th.id, it: th.name})),
                                                thematicSelected: singleData.metadata.filter.pathList[0],

                                                chapters: singleData.metadata.filter.tree.chapterList.map( ch => ({id: ch.id, it: ch.name})),
                                                chapterSelected: singleData.metadata.filter.pathList[1],

                                                informatives: singleData.metadata.filter.tree.informativeList.map( inf => ({id: inf.id, it: inf.name})),
                                                informativeSelected: singleData.metadata.filter.pathList[2],

                                                requirements: singleData.metadata.filter.tree.requirementList.map( req => ({id: req.id, it: req.name})),
                                                requirementSelected: singleData.metadata.filter.pathList[3],

                                                subrequirements: singleData.metadata.filter.tree.requirementeSub1List.map( sub => ({id: sub.id, it: sub.name})),
                                                subrequirementSelected: singleData.metadata.filter.pathList[4],

                                                subsubrequirements: singleData.metadata.filter.tree.requirementeSub2List.map( subsub => ({id: subsub.id, it: subsub.name})),
                                                subsubrequirementSelected: singleData.metadata.filter.pathList[5],


                                                // visualization
                                                graphType_select: singleData.metadata.graphtype,
                                                collectionCompare_select: singleData.metadata.collectioncompare,
                                                timing_select: singleData.metadata.timing,
                                                thematic_id: singleData.metadata.filter.pathList[0],
                                                chapter_id: singleData.metadata.filter.pathList[1],
                                                informative_id: singleData.metadata.filter.pathList[2],
                                                requirement_id: singleData.metadata.filter.pathList[3],
                                                subrequirement: singleData.metadata.filter.pathList[4],
                                                subsubrequirement: singleData.metadata.filter.pathList[5],


                                                selectedCollect: singleData.metadata.griTimingId,

                                                type :
                                                    (!!singleData.metadata.filter.pathList[3])
                                                        ? ID_TYPE[0].id
                                                        : (
                                                            (!!singleData.metadata.filter.pathList[4])
                                                                ? ID_TYPE[1].id
                                                                : (
                                                                    (!!singleData.metadata.filter.pathList[5])
                                                                        ? ID_TYPE[2].id
                                                                        : ID_TYPE[0].id
                                                                )
                                                        ),

                                                id:
                                                    (!!singleData.metadata.filter.pathList[3])
                                                        ? singleData.metadata.filter.tree.requirementList.filter( req => req.id === singleData.metadata.filter.pathList[3] ).reduce( getLastElementOr, "noName" ).id
                                                        : (
                                                            (!!singleData.metadata.filter.pathList[4])
                                                                ? singleData.metadata.filter.tree.requirementeSub1List.filter( sub => sub.id === singleData.metadata.filter.pathList[4] ).reduce( getLastElementOr, "noName" ).id
                                                                : (
                                                                    (!!singleData.metadata.filter.pathList[5])
                                                                        ? singleData.metadata.filter.tree.requirementeSub2List.filter( subsub => subsub.id === singleData.metadata.filter.pathList[5] ).reduce( getLastElementOr, "noName" ).id
                                                                        : 0
                                                                )
                                                        ),



                                                data:
                                                    [
                                                        ...singleData.payload
                                                            .map( (datas, dIndex) => ({
                                                                name: datas.collection_name || dIndex,
                                                                data:
                                                                    datas.values
                                                                        .map( val => val.valueDecimal )
                                                            })),
                                                        /*
                                                        ( singleData.payload.length === 1 )
                                                            ? ({
                                                                name: "Obiettivo",
                                                                type: "straight",
                                                                data:
                                                                    Array(12)
                                                                        .fill(true)
                                                                        .map((el, index) => parseFloat( singleData.payload[0].valueStartGoal ) + parseFloat(parseFloat(parseFloat(singleData.payload[0].valueGoal - singleData.payload[0].valueStartGoal) / 11) * index))
                                                            })
                                                            : null
                                                        */
                                                    ]
                                                        .filter( onlyNotNull )



                                            }))
                                    },
                                    () => {
                                        success();
                                    }
                                )
                            }



                        })
                    );



                })

                .catch(e => {
                    errorlog("errore", e);
                    return Promise.reject( e );
                })

        );

    }





}


