import React, {Suspense, useEffect, useState} from 'react';
import {renderlog, showlog} from "../../../utils/dev-utils";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {COLOR_POLICY, PATH_PREFIX} from "../../../env";
import PageTitle from "../../../components/PageTitle";
import "../../../style/audit.css";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import ActionButton from "../../../components/save-button";
import {downloadProfilingData} from "../../../business-logic/rating";
import SegnalationProfilationModal from './segnalation-profilation-modal';
import ConfirmProfilationModal from './confirm-profilation-modal';
import {translate} from '../../../components/i18n/translate-function';


export const SiRatingAdminEupaPage = props => {

	renderlog("SIRatingAdminEUPAPage", {}, props);


	const [errorMessage, setErrorMessage] = useState();
	//const [goAhead, setGoAhead] = useState( false );
	const [segnalationPopUpFlag, setSegnalationPopUp] = useState(false);
	const [confirmProfPopUpFlag, setConfirmProfilationPopUp] = useState(false);

	useEffect(() => {
		// re-render
		showlog("re-render a causa di un errore");
	}, [errorMessage]);


	/* if( !!goAhead ) {
		return (
			<Redirect
				to={{
					pathname: PATH_PREFIX +"/sirating-admin/evaluation/"+ (props.match.params.id || props.id)
				}}
			/>
		);
	}  */


	return (
		<Suspense
			fallback={
				<WaitingOrNodataMessage
					waiting={true}
					fullHeight={true}
				/>
			}
		>

			<PageTitle
				breadCrumbItems={[
					/*{
						label:
						Object.keys( role )
							.map( roleObjectName => role[ roleObjectName ] )
							.filter( roleObject => roleObject.name === localStorage.getItem("role") )
							.reduce( getLastElementOr, "Nessun Ruolo" )
							.label
						, path: PATH_PREFIX + "/welcome" }, */
					{ label: "SI Rating", path: PATH_PREFIX + "/sirating-admin/" + translate("evaluations") },
					{ label: translate("Profilation"), active: true }
				]}
				title={""}
			/>

			{
				!!segnalationPopUpFlag
					? (
						<SegnalationProfilationModal
							esternalProps={props}
							onClose={closeEvent => {
								setSegnalationPopUp(false)
							}}
						/>
					)
					: null
			}

			{
				!!confirmProfPopUpFlag
					? (
						<ConfirmProfilationModal
						esternalMatchId = {props.match.params.id}
						esternalId = {props.id}
						onClose={closeEvent => {
							setConfirmProfilationPopUp(false)
						}}
						/>
					)
					: null
			}


			<Card>
				<CardHeader>
					<h4>
						Profilazione
					</h4>
				</CardHeader>
				<CardBody>

					<CardBody>

						<span>
							Per poter procedere con la compilazione del questionario per la valutazione SI Rating, ti chiediamo di leggere attentamente
							la profilazione della tua organizzazione. Se concordi con quanto indicato, ti chiediamo di cliccare su "Accetta Profilazione".
							Se desideri segnalare delle modifiche ti chiediamo di cliccare su "Rifiuta e richiedi modifiche".
							Per ogni dubbio, contattaci tramite la pagina <a href="/support">support</a>.
							 <br /><br />
							Per visualizzare la profilazione, clicca&nbsp;
							<span
								className={COLOR_POLICY.evident.textClass + " tooltip-icon-cursor"}
								onClick={clickEvent => {
									// download pdf
									downloadProfilingData(props.match.params.id || props.id)
								}}
							>
								qui
							</span>
							.
						</span>

						{
							(!!errorMessage)
								? (
									<Row>
										<Col>
											<span className={COLOR_POLICY.error.textClass}>{errorMessage}</span>
										</Col>
									</Row>
								)
								: null
						}

						<Row>
							<Col>
								<br/>
							</Col>
						</Row>

						<Row>
							<Col>
								{/*	<ActionButton
									buttonText={ "Accetta Profilazione" }
									promise={ () => acceptEUPA( props.match.params.id || props.id ) }
									onResult={ () => setGoAhead( true ) }
									onError={ error => {
										setErrorMessage( error );
										// setGoAhead( true );	// test
									}}
								/> */}
								<ActionButton
									buttonText={"Accetta Profilazione"}
									promise={() => Promise.resolve()}
									onResult={() => {
										setConfirmProfilationPopUp(true)
									}}
								/*	onError={ error => {
										setErrorMessage( error );
										// setGoAhead( true );	// test
									}} */
								/>
							</Col>
							<Col>
								<ActionButton
									buttonText={"Rifiuta e richiedi modifiche"}
									promise={() => Promise.resolve()}
									onResult={() => {
										setSegnalationPopUp(true)
									}}
								//onError={ error => {
								//	setErrorMessage( error );
								//	// setGoAhead( true );	// test
								//}}
								/>
							</Col>
						</Row>

					</CardBody>

				</CardBody>
			</Card>




		</Suspense>
	)




}
