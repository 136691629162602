import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import PageTitle from "../../../components/PageTitle";
import {
    CALENDAR_MOMENT_DATE_FORMAT_YEARS,
    DATE_FORMAT_IT,
    PATH_PREFIX,
    permissions,
    TABS,
    TREE_TYPE
} from "../../../env";
import moment from "moment";
import {cloneOpenNodes, convertTableToTree, exportSeedTableToCSV} from "../../../business-logic/tree-manager";
import {loadCollect, loadGriStructure} from "../../../business-logic/gri";
import TreeWizard from "../../../components/area/gri-admin/tree-wizard";
import Spinner from "../../../components/Spinner";
import {hasPermission} from "../../../business-logic/auth";
import DeleteCollectQuestionModal from "../../../components/area/admin/delete-collect-question-modal-component";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {SRToolInfoBox} from "../../../components/srtool-info-box";
import {translate} from '../../../components/i18n/translate-function';


export default class TreePage extends Component {

	state = {
		data: null,
		selectedCollect: {},
		treeType: TREE_TYPE.SET.id,
		step: this.props?.location?.state?.step || 3,
		Inizio: this.props?.location?.state?.Inizio,
		dateEnd: this.props?.location?.state?.dateEnd,
		wait: true,
		name: this.props?.location?.state?.name || "",
		currentTab: TABS.general.id
	}

	constructor(props) {
		super( props );

		// let { id } = useParams();
		// this.params = { id: id }

		this.getCollectionData = this.getCollectionData.bind( this );
		this.loadAllCollectsAndFilter = this.loadAllCollectsAndFilter.bind( this );

	}


	render() {


		renderlog("tree page", this.state, this.props);


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{ label: 'SR Tool', path: PATH_PREFIX +'/welcome' },
						{
							label: translate("Collects"),
							path: PATH_PREFIX +'/srtool/' + translate("collects"),
							callBack: clickEvent => {
								console.log("link to /srtool/" + translate("collects"));


							}
						},
						{
							label: this.state.Inizio +" - "+ this.state.dateEnd,
							active: true
						}
					]}
					title={
						<React.Fragment>
							{"Raccolte"}
							<SRToolInfoBox
								className={"srtool-info-box"}
							/>
						</React.Fragment>
					}
				/>




						{
							(!!this.state.noCollect)
								? (
									<Row>
										<Col>
											<div className="page-title-box">
												<h4 className="page-title">Raccolta inesistente</h4>
											</div>
										</Col>
									</Row>
								)
								: null
						}





				{

							(!!this.state.tree && !this.state.wait)
								? (
									<Card>
										<CardBody>




											<Row>
												<Col>
													<div className="page-title-box">

														<h4 className="page-title">
															{ this.state.name /*this.props.match.params.id*/ }

															{
																(hasPermission( permissions.EDIT_COLLECT ))
																	? (
																		<Button
																			outline
																			color={"link"}
																			className={"material-themes-tab-name-icon-button"}
																			onClick={ clickEvent => {
																				// TODO in next release
																			}}
																		>
																			<i className="mdi mdi-pencil" />
																		</Button>
																	)
																	: null
															}
															&nbsp;&nbsp;
															{
																(hasPermission( permissions.DELETE_COLLECT ))
																	? (
																		<Button
																			outline
																			color={"link"}
																			className={"material-themes-tab-name-icon-button"}
																			onClick={ clickEvent => {

																				this.setState({
																					...this.state,
																					deleteCollectPrompt: true
																				})

																			}}
																		>
																			<i className="mdi mdi-delete" style={{ fontSize: "20px" }}/>
																		</Button>
																	)
																	: null
															}

														</h4>
														<p className="text-muted font-14 mb-4">
															{ this.state.Inizio +" - "+ this.state.dateEnd }
														</p>
													</div>
												</Col>
											</Row>








											<TreeWizard
												tree={ this.state.tree }
												collect={ this.props.location.state?.id || this.props.match.params.id }
												fullDataCollect={
													this.props.location.state ||
													{
														Inizio: this.state.Inizio,
														// currentTab: this.state.currentTab,
														dateEnd: this.state.dateEnd,
														name: this.state.name,
														step: this.state.step,
													}
												}
												step={ this.state.step || 3 }
												seed={ this.state.treeSeed }
												location={ this.props.location }
												match={ this.props.match }
												onForceRefresh={ tabId => {



													return (
														new Promise((success, fail) => {

															console.log("collects force refresh");

															let id =
																this.props?.location?.state?.id ||
																parseInt(window.location.href.split("/")[window.location.href.split("/").length -1].split("?")[0], 10) ||
																this.props?.match?.params?.id
															;
															// if( !!this.props.location.state.id ) id = this.props.location.state.id;
															// if( !!this.props.match.params.id ) id = ;


															if(!!tabId) {


																	this.setState({
																		...this.state,
																		currentTab: tabId
																	},
																		() => {
																			success(this.getCollectionData( id ));
																		})


															}
															else success(this.getCollectionData( id ));

														})
													);



												}}
											/>


										</CardBody>
									</Card>
								)
								: (
									(!this.state.noCollect)
										? (
											<div className="d-flex justify-content-center">
												<Spinner color={"primary"} className="spinner-border-sm mr-1" />
												<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
											</div>
										)
										: <div>Nessuna Raccolta</div>
								)


				}




				{
					(!!this.state.deleteCollectPrompt)
						? (
							<DeleteCollectQuestionModal
								id={
									this.props?.location?.state?.id ||
									parseInt(window.location.href.split("/")[window.location.href.split("/").length -1].split("?")[0], 10) ||
									this.props?.match?.params?.id
								}
								name={ this.state.name }
								onClose={() => {
									this.setState({deleteCollectPrompt: null})
								}}
								onConfirm={ collectDeletedStatus => {


									let newLocation =
										window.location.href
											.split("/")
											.filter( (_, i) => i < 3)
											.join("/")
										+ PATH_PREFIX +'/srtool/' + translate("collects")
									;
									window.location.href = newLocation;

									// this.setState({deleteCollectPrompt: null})

									/*
									console.log("utente eliminato");
									console.log(userDeletedStatus);
									this.loadData( () => {
										this.setState({...this.state, deleteCollectPrompt: null})
									});
									*/
								}}
							/>
						)
						: null
				}



			</React.Fragment>
		);
	}



	componentDidMount() {

		console.log("le props");
		console.log(this.props);

		if( !!this.props?.match?.params.id ) {
			this.getCollectionData( this.props.match.params.id/*, this.props.location.state.step*/ )
				.then(() => {

					console.log("controllo da URL");
					if( !this.props.location || !this.props.location.state || !this.props.location.state.step ) {

						console.log("siamo da URL");
						showlog("props");
						showlog(this.props);



						this.loadAllCollectsAndFilter()
							.then(() => {




								this.setState(
									{
										...this.state,
										wait: null
									},
									() => {
										console.log("FINISH");


										showlog(this.state);
										showlog(this.props);


									}
								)

							})
							.catch(e => {
								console.error("errore al acricamento delle raccolte");
								console.log(e);
							})

					}
					else {

						this.setState(
							{
								...this.state,
								wait: null
							},
							() => {
								console.log("FINISH");
							}
						)
					}

				})
				.catch( e => {
					errorlog("controllami", e);

					// NON so se va bene
					this.setState({
						...this.state,
						wait: null
					})


				})
			;
		}



	}



	getCollectionData( id, previousTree ) {




		console.log("getCollectionData", id, previousTree, this.state.currentTab);
		return (

			new Promise((success, fail) => {

				showlog("controllo pre-chiamata");
				showlog( (!!this.state && !!this.state.currentTab) ? this.state.currentTab : 0 );
				showlog( this.state.currentTab );
				// debugger;

				loadGriStructure( id, (!!this.state && !!this.state.currentTab) ? this.state.currentTab : 0 )

					.then(resultObj => {

						console.log("resultObj");
						console.log(resultObj);
						// console.table(resultObj);
						console.log( exportSeedTableToCSV(resultObj) );

						convertTableToTree( resultObj )
							.then( tree => {

								let freshTree = (!!previousTree) ? cloneOpenNodes( previousTree, tree ) : tree;

								this.setState(
									Object.assign(
										{},
										this.state,
										{
											tree: freshTree,
											treeSeed: resultObj
										}
									),
									() => {


										console.log("READY");
										console.log( this.state );

										success( freshTree );

									}
								);

							})
						;

					})
					.catch(e => {
						console.error("error");
						console.log(e);
						this.setState(
							{
								...this.state,
								noCollect: true
							},
							fail
						)
					})

			})

		);
	}

	loadAllCollectsAndFilter() {


		if( !!hasPermission( permissions.GET_COLLECT ) ) {
			return (
				loadCollect()

				// time format  -------------------------------------------------------------------------------------------
					.then( collects => {
						return (
							collects
								.map(collect => {

									collect.Inizio = moment(collect.dateStart.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);
									collect.Fine = moment(collect.dateEnd.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);
									collect.dateEnd = collect.Fine;
									return collect;
								})
						);
					})

					.then( list => {


						console.log("filtro la lista per id = "+ this.props.match.params.id);
						console.log(list.filter( c => parseInt(c.id, 10) === parseInt(this.props.match.params.id, 10) ));

						let currentCollect =
							list
								.filter( c => parseInt(c.id, 10) === parseInt(this.props.match.params.id, 10) )
								[0]
						;


						return (
							this.getCollectionData( currentCollect.id )
								.then( () => {
									return (
										this.setState(
											{
												...this.state,
												Inizio: currentCollect.Inizio,
												dateEnd: currentCollect.dateEnd,
												step: currentCollect.step,
												name: currentCollect.name
											},
											() => {





												return true;
											}
										)
									)

								})
						);
					})
					.catch(e => {
						return Promise.reject( e );
					})
			)
		}
		else return Promise.reject("PERMISSION DENIED");







	}



}
