import React, {Component} from 'react';
import {Card, CardBody, Col, Row, UncontrolledTooltip,} from 'reactstrap';
import PageTitle from "../../../components/PageTitle";
import {
	COLLECT_STATUS,
	DATE_FORMAT_IT,
	GRI_VERSION,
	PATH_PREFIX,
	permissions,
	role,
	TABS,
	TREE_SECTION,
	TREE_TYPE
} from "../../../env";
import moment from "moment";
import {
	cloneOpenNodes,
	convertTableToTree,
	convertToVirtualTree,
	exportSeedTableToCSV
} from "../../../business-logic/tree-manager";
import TreeGriCollectComponent from "../../../components/area/gri-admin/tree-component";
import {loadCollect, loadGriStructure, loadGriStructureValue, treeSetConfirm} from "../../../business-logic/gri";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {
	emoj,
	errorlog,
	getLastElementOr,
	isNotEmptyArray,
	isNotEmptyObject,
	loadDatalog,
	renderlog,
	showlog
} from "../../../utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import TreeGriCollectDataEntryComponent from "../../../components/area/gri-admin/tree-dataentry-component";
import ActionButton from "../../../components/save-button";
import {EMOJ} from "../../../emoj";
import TreeGriCollectMaterialThemesComponent from "../../../components/area/gri-admin/tree-material-themes-component";
import DeleteCollectQuestionModal from "../../../components/area/admin/delete-collect-question-modal-component";
import ManageCollectModal from "../../../components/manage-collect-modal";
import {finishAssurance} from "../../../business-logic/collect";
import {InformativeStateReportModal} from "../../../components/informative-state-report-modal";
import {SettingsCollectSvgIconComponent} from "../../../components/svg/settings-collect-svg-icon-component";
import AssuranceDoneAttachmentModal from "../si-rating-admin/assurance-done-attachment-modal";
import {TrashSvgIconComponent} from "../../../components/svg/trash-svg-icon-component";
import {EditStructureSvgIconComponent} from "../../../components/svg/edit-structure-svg-icon-component";
import {CompletedQuestionsIconSvgComponent} from "../../../components/svg/completed-questions-icon-svg-component";
import {RecapStatusIconSvgComponent} from "../../../components/svg/recap-status-icon-svg-component";
import {DownloadIconSvgComponent} from "../../../components/svg/download-icon-svg-component";
import {SRToolInfoBox} from "../../../components/srtool-info-box";
import {translate} from '../../../components/i18n/translate-function';
import CollectQuestionReport from "../../../components/area/super-admin/collect-question-report-modal";
import {getCollectReport} from "../../../business-logic/get-collect-report";
import GlossarioCollectModal from "../../../components/area/super-admin/glosario-collect-modal";
import {getCollectGlossary} from "../../../business-logic/get-collect-glossary";
import ConfirmCompleteCollectModal from "../../../components/area/super-admin/confirm-complete-collect-modal";
import ExportCSVModal from "../../../components/area/super-admin/export-csv-modal";
import {CollectReportIconSvgComponent} from "../../../components/svg/collect-report-icon-svg-component";
import {GlossaryIconSvgComponent} from "../../../components/svg/glossary-icon-svg-component";


export default class SRToolManagerPage extends Component {

	state = {
		data: null,
		selectedCollect: {},
		treeType: TREE_TYPE.SET.id,
		step: this.props?.location?.state?.step || 3,
		Inizio: this.props.location.state?.Inizio,
		dateEnd: this.props.location.state?.dateEnd,
		wait: true,
		name: this.props.location.state?.name || "",
		params: { section: TREE_SECTION.DATA_ENTRY },
		goBackEnabled: true,
		raw: {
			state: 0
		},
		collectData : []
	}

	constructor(props) {
		super( props );


		this.loadData = this.loadData.bind( this );
		this.isCollectInAssurance = this.isCollectInAssurance.bind( this );
		this.isCollectInDoneAssurance = this.isCollectInDoneAssurance.bind( this );


	}


	isCollectInAssurance 		= () => ( !!this.state.raw && !!this.state.raw.state && (this.state.raw.state === COLLECT_STATUS.inAssurance.id) );
	isCollectInDoneAssurance 	= () => ( !!this.state.raw && !!this.state.raw.state && (this.state.raw.state === COLLECT_STATUS.assuranceDone.id) );


	render() {

		renderlog("Collect TREE handler Page", this.state, this.props, { color: "#ff1188", contrastColor: "#ffffff" });
		showlog( this.props.location.search );


		let collect =
			Object.assign(
				{},
				this.props.location.state,
				{ id: this.props.match.params.id },
				this.state
			)
		;

		let page = (
			<TreeGriCollectDataEntryComponent
				tree={ this.state.tree }
				seed={ this.state.seeds }
				collect={ collect }
				isCollectDone={ (!!this.isCollectInDoneAssurance() /*|| !!this.isCollectInAssurance()*/) }
				collectStatus={ this.state.raw.state }
				onTreeUpdate={ (tree, activeTabId) => {

					return (
						new Promise((success, fail) => {
							this.setState({
								...this.state,
								tree: tree,
								activeTab: activeTabId,
								onTreeUpdateCall: true
							},
								() => {
									success({tree: tree, activeTab: activeTabId} );
								}
							)
						})
						.then( ({tree, activeTab}) => {
							if( !!activeTab ) return Promise.resolve();
							return this.loadData();
						})
					)
				}}
				assuraceIsActive={ isActive => {
					this.setState({
						...this.state,
						isValidateAssuranceActive: isActive
					})
				}}
				// collect={ this.props.match.params.id }
				/*
                seed={ this.state.seed }
                fullDataCollect={ props.fullDataCollect }
                onTreeUpdate={ tree => props.onUpdate(tree) }
                reloadTree={ (tree, tabId) => {
                    return props.onUpdate( tree, tabId );
                }}
                */
			/>
		);


		if( !this.isCollectInAssurance() ) {
			if(!!this.state.tmPage || this.state.params.section === TREE_SECTION.MATERIAL_THEMES ) {
				// this.props.history.push({search: "section="+ TREE_SECTION.MATERIAL_THEMES});
				page = (
					<TreeGriCollectMaterialThemesComponent
						tree={ this.state.tree }
						seed={ this.state.seed }
						collect={ collect }
						isCollectDone={ (!!this.isCollectInDoneAssurance() /*|| !!this.isCollectInAssurance()*/) }
						collectStatus={ this.state.raw.state }
					/>
				);
			}

			if(!!this.state.configPage || this.state.params.section === TREE_SECTION.CONFIGURATION ) {
				// this.props.history.push({search: "section="+ TREE_SECTION.CONFIGURATION});



				if( !isUserRole( role.SRTool_MANAGER ) ) {
					page = (
						<TreeGriCollectComponent
							tree={this.state.tree}
							isNoDataScenario={ (this.state.step === 1) }
							collect={ collect }
							isCollectDone={ (!!this.isCollectInDoneAssurance() /*|| !!this.isCollectInAssurance()*/) }
							collectStatus={ this.state.raw.state }
							onTreeUpdate={ (tree, activeTabId) => {
								return (
									new Promise((success, fail) => {
										this.setState({
												...this.state,
												tree: tree,
												activeTab: activeTabId,
												onTreeUpdateCall: true
											},
											() => {
												success( tree );
											}
										)
									})
										.then( tree => {
											return this.loadData();
										})
								)
							}}
							componentInWait={ readyEvent => {
								showlog("%cATTESA", "font-size:3em;");
								this.setState({
									...this.state,
									goBackEnabled: false
								})
							}}
							componentReady={ readyEvent => {
								showlog("%cATTESA OFF", "font-size:3em;");
								setTimeout(
									function() {

										this.setState({
											...this.state,
											goBackEnabled: true
										})

									}.bind( this ),
									1000
								)
							}}
						/>
					);
				}
			}
		}






		if(this.state.params.section === "hidden" && this.state.params.key === "123" ) {

		}

		// if( !!this.state.goBackEnabled ) debugger;


		let collectStatusLabel =
			Object.keys(COLLECT_STATUS)
				.map( field => COLLECT_STATUS[ field ] )
				.filter( status => status.id === this.state.raw.state )
				.reduce( getLastElementOr, { label: "Inconsitent status" })
				.label
		;
		if( !!this.state.configPage ) {
			collectStatusLabel = translate("Configuration phase");
		}



		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{ label: 'SR Tool', path: PATH_PREFIX +'/welcome' },
						{
							label: translate("Collects"),
							path: PATH_PREFIX +'/srtool/' + translate("collects"),
							callBack: clickEvent => {
								// TODO

							}
						},
						{
							label: this.state.Inizio +" - "+ this.state.dateEnd,
							active: true
						}
					]}
					title={
						<React.Fragment>
							{translate("Collects")}
							<SRToolInfoBox
								className={"srtool-info-box"}
							/>
						</React.Fragment>
					}
				/>



				{
					(!!this.state.deleteCollectPrompt)
						? (
							<DeleteCollectQuestionModal
								id={ collect.id }
								name={ collect.name }
								onClose={() => {
									this.setState({deleteCollectPrompt: null})
								}}
								onConfirm={ collectDeletedStatus => {


									let newLocation =
										window.location.href
											.split("/")
											.filter( (_, i) => i < 3)
											.join("/")
										+ PATH_PREFIX +'/srtool/' + translate("collects")
									;
									window.location.href = newLocation;

									// this.setState({deleteCollectPrompt: null})


								}}
							/>
						)
						: null
				}


				{
					!!this.state.assuranceDoneAttachmentModal
						? (
							<AssuranceDoneAttachmentModal
								collect={ this.state.raw }
								onClose={() => {
									this.setState({
										...this.state,
										assuranceDoneAttachmentModal: null
									})
								}}
							/>
						)
						: null
				}


				{
					!!this.state.reportCollectModal
					? (
						<CollectQuestionReport
							id = {this.state.raw.id}
							data = {this.state.collectData.table.map( data => {

									if (data.tabId == 1) {
										data.tabId = 5
										return data
									} else return data

								}

							)}
							percentage = {this.state.collectData.percentage}
							tabToFilter = {  ([
								... new Map( this.state.collectData.table.map( data => [ data.tabId, data ]) ).values()
							].map( data => data.tabId)) }
							onClose={ closeEvent => {
									this.setState({
										...this.state,
										reportCollectModal: null
									})
								}
							}
						/>
						)
						: null
				}


				{
					!!this.state.glossarioCollectModal
						? (
							<GlossarioCollectModal
								data = {this.state.glossaryData}
								id = {this.state.raw.id}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										glossarioCollectModal: null
									})
								}
								}
							/>
						)
						: null
				}


				{
					(!!this.state.exportCSVModal)
					?
						(
							<ExportCSVModal
							id={this.props.match.params.id}
							fileName={ (!!this.state.name ? this.state.name : "report")}
							onClose={closeEvent => {
								this.setState({
									...this.state,
									exportCSVModal: null
								})
							}}

							/>
						)
						:
						null
				}


				{
					!!this.state.confirmCompletedModal
					?
						(
							<ConfirmCompleteCollectModal
							completed = {this.state.confirmData}
							id = {this.state.raw.id}
							onClose = { closeEvent => {
									this.setState({
										...this.state,
										confirmCompletedModal: false
									},()=> {
										if(closeEvent=="completed"){
											this.loadData()
										}
									})
							}
							}
							/>
						)
						:
						null
				}



				{
					!!this.state.settingsModal
						? (
							<ManageCollectModal
								collect={ collect }
								urlparams={ this.state.params }
								isInAssurance={ (this.state.raw.state > COLLECT_STATUS.confirmed.id) }
								avoidMeasureUnits={
									!(
										(
											this.state.params.section === TREE_SECTION.CONFIGURATION
											|| !!this.state.configPage
										)
										&& !isUserRole( role.SRTool_MANAGER )
									)
								}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										settingsModal: null,
										name:
											(!!closeEvent && !!closeEvent.name)
												? closeEvent.name
												: this.state.name
									});
								}}
								refresh={ () => {
									// debugger;
									return (
										this.loadData()
											.then( result => {
												return true;
											})
									)

								}}
							/>
						)
						: null
				}



				{
					!!this.state.ratingLegend
						? (
							<InformativeStateReportModal
								ratingId={ this.props.match.params.id  }
								isAssurance={ !!isUserRole( role.ASSURANCER ) }
								metadata={{}}
								collect={ this.state.ratingLegend }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										ratingLegend: null
									})
								}}
							/>
						)
						: null
				}


				{
					( isNotEmptyObject( this.state.tree ) && !this.state.wait )
						? (
							<Card
								className={
									!!this.isCollectInDoneAssurance()
										? "collect-finished collect-in-assurance-done"
										: (
											!!this.isCollectInAssurance()
												? "collect-finished collect-in-assurance"
												: ""
										)
								}
							>
								<CardBody>

									<div className={"srtool-container-page"}>

									<Row>
										<Col sm={6} lg={6} xl={6}>
											<h4 className="header-title">
												{
													(!!this.state.noCollect)
														? "Raccolta  inesistente"
														: (
															(!!this.state.configPage)
																? (
																	<span>
																		<i className={"dripicons-network-1"}/>
																		&nbsp;&nbsp;
																		{ this.state.name  }
																	</span>
																)
																: (
																	(!!this.state.tmPage)
																		? (
																			<span>
																				{ emoj( EMOJ.star ) }
																				&nbsp;&nbsp;
																				{ this.state.name  }
																			</span>
																		)
																		: (this.state.name )
																)
														)
												}
												<span
													className={"collect-version"}
												>
													{
														(!!this.state.raw && !!this.state.raw.version)
															? " ( "+ GRI_VERSION.newVersion.label +" )"
															: " ( "+ GRI_VERSION.oldVersion.label +" )"
													}
												</span>
											</h4>
											<p className="text-muted font-14 mb-4">
												{ this.state.Inizio +" - "+ this.state.dateEnd }
											</p>



										</Col>

										<Col sm={6} lg={6} xl={6} className={"srtool-comander-box"}>

											{/*	status	*/}
											<span className={"assurance-status-label" + ((this.state.raw.state < COLLECT_STATUS.inAssurance.id ) ? " assurance-status-label-not-final-state" : "")}>
												{ collectStatusLabel }
											</span>

										</Col>

									</Row>



									<Row>


										<Col sm={6} lg={6} xl={6} className={"srtool-comander-box"}/>
										<Col sm={6} lg={6} xl={6} className={"srtool-comander-box"}>


											{/*		attachment							*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(
													(this.state.raw.state === COLLECT_STATUS.assuranceDone.id) &&
													!isUserRole( role.SRTool_MANAGER )
												)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={ <i className={"mdi mdi-attachment"}/> }
																color={"none"}
																className={"srtool-comander-button assurance-attachment-icon"}
																promise={ () => {
																	return Promise.resolve();
																}}
																onResult={ result => {
																	this.setState({
																		...this.state,
																		assuranceDoneAttachmentModal: true
																	})
																}}
																onError={ error => {
																	errorlog( "E2", error );
																}}
															/>
														</div>
													)
													: null
											}

											{/*		conferma assurance					*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(
													(this.state.raw.state === COLLECT_STATUS.inAssurance.id) &&
													!!isUserRole( role.ASSURANCER )
												)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={"Conferma Assurance"}
																promise={ () => {
																	return (
																		new Promise((yes, no) => {
																			let isYes = window.confirm( "Sicuro di voler confermare l'assurance" );
																			if( !!isYes ) yes();
																			else no();
																		})
																	)
																}}
																onResult={ result => {
																	finishAssurance( this.props.match.params.id )
																		.then( result => {
																			window.location.href = window.location.href;
																		})
																		.catch(e => {
																			errorlog("confirm assurance", e);
																		})

																}}
																onError={ error => {
																	// si è scelto il no
																	// errorlog("errore 202203101024", error);
																}}
																className={"srtool-comander-button confirm-assurance-button"}
															/>
														</div>
													)
													: null
											}

											{/*		completa raccolta					*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(
													(this.state.raw.state === COLLECT_STATUS.confirmed.id) &&
													( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) || !!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.ADMIN ) ) // da gestire come permessi
												)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={
																	<CompletedQuestionsIconSvgComponent className={""}  id={"completaRaccolta"}/>
																}
																color={ "none" }
																className={"srtool-comander-button"}
																promise={ () =>{
																	return( getCollectReport(this.state.raw.id, 1)
																	)
																}
																	// return (
																	// 	new Promise( (yes, no) => {
																	// 		let isYes = window.confirm( "La raccolta è completata?" );
																	// 		if( !!isYes ) yes();
																	// 		else no();
																	// 	})
																	// 		.then( () => {
																	// 			return treeSetComplete( collect.id );
																	// 		})
																	// )
																}
																waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {
																	//window.location.href = window.location.href;
																	{
																		this.setState({
																			...this.state,
																			confirmData: result,
																			confirmCompletedModal: true
																		},()=>{showlog("RESULT",result)})
																	}

																}}
																onError={ error => {
																	errorlog("completamento raccolta", error );
																}}
															/>

															<UncontrolledTooltip placement="top" target={"completaRaccolta"}>
																<span className="">{translate("Complete collection")}</span>
															</UncontrolledTooltip>

														</div>
												)
													: null
											}




											{/*		settings							*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(
													((this.state.raw.state > COLLECT_STATUS.virgin.id) && (this.state.raw.state < COLLECT_STATUS.assuranceDone.id)) &&
													( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) || !!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.ADMIN ) ) // da gestire come permessi
												)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={
																	<SettingsCollectSvgIconComponent className={""} id={"imppostazioniRaccolta"} />
																}
																color={"none"}
																className={"srtool-comander-button"}
																promise={ () => {
																	return Promise.resolve();
																}}
																waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {

																	this.setState({
																		...this.state,
																		settingsModal: true
																	})

																}}
																onError={ error => {
																	errorlog( error );
																}}
															/>


															<UncontrolledTooltip placement="top" target={"imppostazioniRaccolta"}>
																<span className="">{translate("Collection settings")}</span>
															</UncontrolledTooltip>


														</div>
													)
													: null
											}




											{/*		recap								*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(this.state.raw.state > COLLECT_STATUS.virgin.id)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={
																	<RecapStatusIconSvgComponent className={""} id={"reportStatiInformative"}/>
																}
																color={"none"}
																className={"srtool-comander-button"}
																promise={ () => {
																	return Promise.resolve();
																}}
																waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {

																	this.setState({
																		...this.state,
																		ratingLegend: this.state.raw
																	})

																}}
																onError={ error => {
																	errorlog( error );
																}}
															/>

															<UncontrolledTooltip placement="top" target={"reportStatiInformative"}>
																<span className="">{translate("Information Status Reports")}</span>
															</UncontrolledTooltip>


														</div>
													)
													: null
											}



											{/*		download							*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
													(this.state.raw.state > COLLECT_STATUS.virgin.id)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
															buttonText={
																	<DownloadIconSvgComponent className={""} id={"esportaexcel"}/>
																}
																color={"none"}
																className={"srtool-comander-button"}
																// promise={
																// () => {
																// 	// return (
																// 	// 	new Promise( (success, fail) => {
																// 	//
																// 	// 		this.setState({
																// 	// 			...this.state,
																// 	// 			csvDownloadInWait: true
																// 	// 		},
																// 	// 			() => {
																// 	// 				loadGriStructureValue( collect.id, 0 )
																// 	// 					.then(data => {
																// 	// 						return (
																// 	// 							exportSeedTableToBusinessCSV(data)
																// 	// 								.then( fileContent => {
																// 	// 									let file = new Blob([fileContent], {type: "text/csv;charset=utf-8"});
																// 	// 									saveAs( file, "Raccolta "+ collect.name +".csv" );
																// 	// 									return true;
																// 	// 								})
																// 	// 								.then(() => {
																// 	// 									success();
																// 	// 								})
															// 	// 						);
																// 	// 					})
																// 	// 					.catch( e => {
																// 	// 						errorlog("load gri-structure", e);
															// 	// 						fail( e );
																// 	// 					})
															// 	// 			})
															// 	//
																// 	//
																// 	// 	})
																// 	// );
																//
															// }}
															/* cambiare nome del file finale */
															promise={() =>

																Promise.resolve()

															//getCollectCsvFile(this.props.match.params.id, (!!this.state.name ? this.state.name : "report") + ".xls", 0)

															}
															waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {

																	this.setState({
																		...this.state,
																		exportCSVModal: true
																	})

																	// this.setState({
																	// 	...this.state,
																	// 	csvDownloadInWait: null
																	// })

																}}
																onError={ error => {
																errorlog( error );
																}}
															/>


															<UncontrolledTooltip placement="top" target={"esportaexcel"}>
																<span className="">{translate("Export Csv")}</span>
															</UncontrolledTooltip>

													</div>
													)
													: null
											}




											{/*		delete								*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(
													((this.state.raw.state > COLLECT_STATUS.virgin.id) && (this.state.raw.state < COLLECT_STATUS.assuranceDone.id)) &&
													( hasPermission( permissions.DELETE_COLLECT ) ) &&
													( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) || !!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.ADMIN ) ) // da gestire come permessi
												)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={(
																	<TrashSvgIconComponent className={""} id={"eliminaraccolta"} />
																)}
																color={"none"}
																className={"srtool-comander-button"}
																promise={ () => {
																	return Promise.resolve();
																}}
																waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {
																	this.setState({
																		...this.state,
																		deleteCollectPrompt: true
																	})
																}}
																onError={ error => {
																	errorlog( "E2", error );
																}}
															/>


															<UncontrolledTooltip placement="top" target={"eliminaraccolta"}>
																<span className="">{translate("Delete Collection")}</span>
															</UncontrolledTooltip>


														</div>


													)
													: null
											}


											{/*		report raccolta				*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{

												(this.state.raw.state > COLLECT_STATUS.virgin.id)
													?
													(
														<div className={"srtool-comander-container"} >
															<ActionButton

																buttonText={
																	// farsi mandare nuovo svg	<CompletedQuestionsIconSvgComponent className={""}  id={"reportRaccolta"}/>
																	//<i className={"mdi mdi-format-float-left info-icon"} id={"reportRaccolta"}style={{height:"22px", flexDirection : "row", color:"#98a6ad"}} ></i>
																	<CollectReportIconSvgComponent className={""} id={"reportRaccolta"}/>
																}
																color={ "none" }
																className={"srtool-comander-button"}
																promise={ () => {

																	return (

																		getCollectReport(this.state.raw.id, 0)

																	)

																}}
																waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {

																	//window.location.href = window.location.href;

																	this.setState({
																		...this.state,
																		collectData: result,
																		reportCollectModal: true
																	}, () => {showlog("RESULT", result)})

																}}
																onError={ error => {
																	errorlog("report raccolta", error );
																}}
															/>

															<UncontrolledTooltip placement="top" target={"reportRaccolta"}>
																<span className="">{translate("Collect Report")}</span>
															</UncontrolledTooltip>

														</div>
													)
													:
													null

											}


											{/*      glossario raccolta                             */}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(this.state.raw.state > COLLECT_STATUS.virgin.id)
													?
													(

														<div className={"srtool-comander-container"} >
															<ActionButton

																buttonText={
																	// farsi mandare nuovo svg	<CompletedQuestionsIconSvgComponent className={""}  id={"reportRaccolta"}/>
																	//<i className={"mdi mdi-format-list-bulleted info-icon"} id={"glossarioRaccolta"}style={{height:"22px", flexDirection : "row", color:"#98a6ad"}} ></i>
																	<GlossaryIconSvgComponent  className={""} id={"glossarioRaccolta"}/>
																}
																color={ "none" }
																className={"srtool-comander-button"}
																promise={ () => {

																	return (

																		getCollectGlossary()

																	)

																}}
																waitingMessage={" "}
																onProcess={ () => {

																}}
																onResult={ result => {

																	//window.location.href = window.location.href;

																	this.setState({
																		...this.state,
																		glossaryData: result,
																		glossarioCollectModal: true
																	})

																}}
																onError={ error => {
																	errorlog("glossario raccolta", error );
																}}
															/>

															<UncontrolledTooltip placement="top" target={"glossarioRaccolta"}>
																<span className="">{ translate("GRI Glossary") }</span>
															</UncontrolledTooltip>

														</div>

													)
													:
													null
											}


											{/*		configuration						*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}


											{
												(
													(this.state.raw.state === COLLECT_STATUS.confirmed.id) &&
													( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) || !!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.ADMIN ) ) // da gestire come permessi
												)
													? (
														((!!this.state.configPage || !!this.state.tmPage) && !!this.state.goBackEnabled)
															? (
																<div className={"srtool-comander-container"}>
																	<ActionButton
																		buttonText={

																			(this.state.step === 1)
																				? translate("Save")
																				: (
																					<span className={"dataset-value-button go-back-conf-tree-button"}>
																						{ translate("Go to the collection phase") } <i className={"dripicons-arrow-thin-right"}/>
																					</span>
																				)

																		}
																		color={ (this.state.step === 1) ? "primary" : "none" }
																		className={"" + ((this.state.step === 1) ? "" : " small-lateral-padding")}
																		promise={ () => {
																			if(this.state.step === 1) {
																				return (
																					treeSetConfirm(
																						Object.assign(
																							{},
																							this.props.location.state,
																							{ id: this.props.match.params.id },
																							this.state
																						).id
																					)
																						.then( result => {
																							return this.loadData();
																						})
																				);
																			}
																			else {
																				return Promise.resolve();
																			}
																		}}
																		waitingMessage={" "}
																		onProcess={ () => {

																		}}
																		onResult={ result => {

																			this.props.history.push({search: "section="+ TREE_SECTION.DATA_ENTRY});
																			this.setState({
																					...this.state,
																					configPage: undefined,
																					tmPage: undefined,
																					activeTab: TABS.general.id,
																					currentTab: TABS.general,
																					params: {
																						...this.state.params,
																						section: TREE_SECTION.DATA_ENTRY
																					}
																				},
																				() => {
																					// debugger;
																					this.loadData();
																				}
																			)

																		}}
																	/>
																</div>
															)
															: (
																<div className={"srtool-comander-container"}>
																	<ActionButton
																		buttonText={
																			<EditStructureSvgIconComponent
																				className={""}  id={"configuraraccolta"}
																			/>
																		}
																		color={ "none" }
																		className={"srtool-comander-button"}
																		promise={ () => {
																			return Promise.resolve();
																		}}
																		waitingMessage={" "}
																		onProcess={ () => {

																		}}
																		onResult={ result => {
																			this.props.history.push({search: "section="+ TREE_SECTION.CONFIGURATION});
																			this.setState({
																					...this.state,
																					configPage: true,
																					tmPage: undefined,
																					wait: true
																				},
																				() => {

																					// debugger;
																					// ATTENZIONE era commentato!
																					this.loadData()
																						.then( newTree => {
																							// debugger;

																							this.setState({
																								...this.state,
																								wait: false
																							});

																						})
																					;
																				}
																			)
																		}}
																	/>

																	<UncontrolledTooltip placement="top" target={"configuraraccolta"}>
																		<span className="">{translate("Set up collection")}</span>
																	</UncontrolledTooltip>

																</div>
															)
													)
													: null
											}







											{/*		salva configuration						*/}
											{/*-------------------------------------------------------------------------------------------------------------*/}
											{
												(
													(this.state.raw.state === COLLECT_STATUS.virgin.id) &&
													( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) || !!isUserRole( role.SUPER_ADMIN ) || !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.ADMIN ) ) // da gestire come permessi
												)
													? (
														<div className={"srtool-comander-container"}>
															<ActionButton
																buttonText={

																	(this.state.step === 1)
																		? translate("Save")
																		: (
																			<span className={"dataset-value-button go-back-conf-tree-button"}>
																				{ translate("Go to the collection phase") } <i className={"dripicons-arrow-thin-right"}/>
																			</span>
																		)

																}
																color={ (this.state.step === 1) ? "primary" : "none" }
																className={"" + ((this.state.step === 1) ? "" : " small-lateral-padding")}
																promise={ () => {
																	if(this.state.step === 1) {
																		return (
																			treeSetConfirm(
																				Object.assign(
																					{},
																					this.props.location.state,
																					{ id: this.props.match.params.id },
																					this.state
																				).id
																			)
																				.then( result => {
																					return this.loadData();
																				})
																		);
																	}
																	else {
																		return Promise.resolve();
																	}
																}}
																onResult={ result => {

																	this.props.history.push({search: "section="+ TREE_SECTION.DATA_ENTRY});
																	this.setState({
																			...this.state,
																			configPage: undefined,
																			tmPage: undefined,
																			activeTab: TABS.general.id,
																			currentTab: TABS.general,
																			params: {
																				...this.state.params,
																				section: TREE_SECTION.DATA_ENTRY
																			}
																		},
																		() => {
																			// debugger;
																			this.loadData();
																		}
																	)

																}}
															/>
														</div>
													)
													: null
											}


											{/*---- END --------------------------------------------------------------------------------------------------*/}


										</Col>


									</Row>


										{/*<div className={"srtool-target-page"}>*/}
											{ page }
										{/*</div>*/}

									</div>

								</CardBody>
							</Card>
						)
						: (
							<WaitingOrNodataMessage
								waiting={ !!this.state.wait }
								nodataMessage={"Nessun Dato"}
								fullHeight={ true }
							/>
						)
				}

			</React.Fragment>
		);
	}




	componentDidMount() {


		let params =
			(!!this.props && !!this.props.location && !!this.props.location.search)
				? (
					JSON.parse(
						("{"+ this.props.location.search +"}")
							.split("?").join("")
							.split("&").join(",")
							.split("=").join(":")

							.split(",").join("\",\"")
							.split(":").join("\":\"")
							.split("{").join("{\"")
							.split("}").join("\"}")
					)
				)
				: { section: TREE_SECTION.DATA_ENTRY }
		;
		this.setState({
			...this.state,
			onTreeUpdateCall: false,
			params: params,
			tmPage: (params.section === TREE_SECTION.MATERIAL_THEMES),
			configPage: (
				!!isUserRole( role.SRTool_MANAGER )
					? false
					: (params.section === TREE_SECTION.CONFIGURATION)
			),
			activeTab: (parseInt(params.area || 1, 10) || 1),
			currentTab:
				Object.keys(TABS)
					.map( tabKey => TABS[tabKey] )
					.filter( t => t.id === parseInt(params.area || 1, 10) )
					.reduce( getLastElementOr, TABS.general )
		},
			() => {
				this.loadData();
			}
		)



	}


	loadData() {

		loadDatalog("Collect TREE page", { color: "#dd11ff", contrastColor: "#ffffff" });



		return (

			new Promise((success, fail) => {

				// --------------------------------------------------------------------------------- metadati della raccolta

					if( !!hasPermission( permissions.GET_COLLECT ) ) {

						// debugger;
						loadCollect( parseInt(this.props.match.params.id, 10) )
							.then( rawCollect => {

								showlog("rawCollect");
								showlog(rawCollect);
								// debugger;
								success ({
									...rawCollect,
									Inizio: moment(rawCollect.dateStart).format(DATE_FORMAT_IT),
									Fine: moment(rawCollect.dateEnd).format(DATE_FORMAT_IT),
									dateEnd: moment(rawCollect.dateEnd).format(DATE_FORMAT_IT),
									// Inizio: this.props.location.state.Inizio || this.props.location.state.dateStart,
									// dateEnd: this.props.location.state.dateEnd,
									// step: this.props.location.state.step,	// <------------------------
									// name: this.props.location.state.name,
									// step: rawCollect.step,
									// name: rawCollect.name,
									raw: rawCollect
								});


							})
							.catch(e => {
								return Promise.reject( e );
							})


						/*
						if( !!this.props.location && !!this.props.location.state && !!this.props.location.state.id ) {

							loadCollect( parseInt(this.props.match.params.id, 10) )
								.then( rawCollect => {

									success ({
										Inizio: this.props.location.state.Inizio || this.props.location.state.dateStart,
										dateEnd: this.props.location.state.dateEnd,
										step: this.props.location.state.step,	// <------------------------
										name: this.props.location.state.name,
										raw: rawCollect		//	this.props.location.state
									});


								})
								.catch(e => {
									return Promise.reject( e );
								})

						}
						else {

							return (
								loadCollect()	// da cambiare con la raccolta corrente


									// time format  -------------------------------------------------------------------------------------------
									.then( collects => {

										return (
											collects
												.map(collect => {
													// collect.Inizio = moment(collect.dateStart.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);
													// collect.Fine = moment(collect.dateEnd.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);
													// collect.dateEnd = collect.Fine;
													collect.Inizio = moment(collect.dateStart).format(DATE_FORMAT_IT);
													collect.Fine = moment(collect.dateEnd).format(DATE_FORMAT_IT);
													collect.dateEnd = collect.Fine;


													// showlog("raccolta: "+ collect.name +" step: "+ collect.step);


													return collect;
												})
										);
									})


									.then( list => {

										let currentCollect =
											list
												.filter( c => parseInt(c.id, 10) === parseInt(this.props.match.params.id, 10) )
												.reduce( getLastElementOr, null )
										;

										showlog( currentCollect );
										// debugger;

										success (

											!!currentCollect
												? (
													{
														Inizio: currentCollect.Inizio,
														dateEnd: currentCollect.dateEnd,
														step: currentCollect.step,
														name: currentCollect.name,
														raw: currentCollect
													}
												)
												: {}

											);


									})
									.catch(e => fail( e ))
							)
						}
						*/



					}
					else fail("PERMISSION DENIED");
				})


				// ------------------------------------------------------------------------------------- dati della raccolta
				.then( ({Inizio, dateEnd, step, name, raw}) => {


					showlog("raw");
					showlog(raw);
					showlog(Inizio, dateEnd, step, name);

					/*
					Controllo sull'Assicuratore
					se la raccolta ha uno stato inferiorre a COLLECT_STATUS.inAssurance.id
					allora non può accedere alla pagina
					 */
					// if( (raw.state < COLLECT_STATUS.inAssurance.id) && !!isUserRole( role.ASSURANCER ) ) {
					// 	return Promise.reject({redirectTo: "srtool/collects", raw: raw});
					// }




					/*
					controllare se il tab attivo è già presente
					Se questa loadData viene chiamata da onTreeUpdate non si dovrebbe fare la chiamata


					controlla lo state ( onTreeUpdateCall, activeTab )
					controlla le props
					 */
					showlog("controllo prima della chiamata");
					showlog( this.state );
					showlog( this.props );


					/*
					if( !!this.state.onTreeUpdateCall ) {


						let virtualTree = convertToVirtualTree( this.state.tree, this.state.seeds );
						let treeTmp = cloneOpenNodes( this.state.tree, virtualTree );



						return ({
							tree: treeTmp,
							seeds: this.state.seeds,
							Inizio: Inizio,
							dateEnd: dateEnd,
							step: step,
							name: name,
							raw: raw
						});

					}
					else {
					*/
						return (
							new Promise((next, ko) => {

								showlog("controllo chiamata dati");
								showlog( this.state );



								// if( !!this.state.tree && isNotEmptyArray( this.state.tree.tabs )) {
								// 	next( this.state.seeds );
								// }
								// else {


									/*
									*/
									if( !!this.props.location && !!this.props.location.state && parseInt(this.props.location.state.step, 10) === 1 ) {
										next( loadGriStructure( this.props.match.params.id, this.state.activeTab ) );
									}
									else {

										if( !this.state.configPage )  	showlog( "%cloadGriStructureValue("+ this.props.match.params.id +", "+ this.state.activeTab +" )", "background:#ff0;" );
										else  							showlog( "loadGriStructure("+ this.props.match.params.id +", "+ this.state.activeTab +" )" );


										if( !this.state.configPage )  	next( loadGriStructureValue( this.props.match.params.id, this.state.activeTab ) );
										else  							next( loadGriStructure( this.props.match.params.id, this.state.activeTab ) );

										// if( !this.state.configPage )  	next( loadGriStructureValue( this.props.match.params.id, this.state.activeTab ) );
										// else  							next( this.state.seeds );

									}

									// next( loadGriStructure( this.props.match.params.id, this.state.activeTab ) );
									// next( this.state.seeds );

								// }




							})


							.then(resultObj => {

								let seed = resultObj;

								showlog("risultato di loadGriStructure/loadGriStructureValue");
								showlog(resultObj);





								// if( !isNotEmptyArray( resultObj ) ) return {}; // -------------------------------------------------------------------------------------
								if( !isNotEmptyArray( resultObj ) ) {
									resultObj = [
										{ griTabId: 1, griChapterId: 0, griInformativeId: 0, chapters: [{id: 0}] },
										{ griTabId: 1, griChapterId: 1, griInformativeId: 0, chapters: [{id: 0}] },
										{ griTabId: 2, griChapterId: 0, griInformativeId: 0, chapters: [{id: 0}] },
										{ griTabId: 3, griChapterId: 0, griInformativeId: 0, chapters: [{id: 0}] },
										{ griTabId: 4, griChapterId: 0, griInformativeId: 0, chapters: [{id: 0}] }
									];
								}; // -------------------------------------------------------------------------------------




								showlog( exportSeedTableToCSV( resultObj ) );




								return (
									convertTableToTree( resultObj )
										.then( tree => {

											// showlog(step, Inizio, dateEnd, name, raw);


											let virtualTree = tree;
											if( !this.state.configPage ) virtualTree = convertToVirtualTree( tree, seed );
											let treeTmp = cloneOpenNodes( this.state.tree || tree, virtualTree );

											return ({
												tree: treeTmp,
												seeds: seed,
												Inizio: Inizio,
												dateEnd: dateEnd,
												step: step,
												name: name,
												raw: raw
											});

										})
										.catch(e => Promise.reject( e ))
								);

							})
							.catch(e => Promise.reject( e ))

						);


					//}


				})



				// ---------------------------------------------------------------------------------------------------- final
				.then( ({tree, seeds, Inizio, dateEnd, step, name, raw}) => {


						return (

							new Promise((finished, notFinished) => {
								this.setState({
									...this.state,
									tree: (!!tree ? tree : this.state.tree),
									seeds: seeds,
									Inizio: Inizio,
									dateEnd: dateEnd,
									step: step,
									name: name,
									raw: raw,
									wait: null,
									nounce: (new Date()).getTime(),
									assuranceInWait: false,
									isValidateAssuranceActive: raw.isValidateAssuranceActive
								},
									() => {

										finished( (!!tree ? tree : this.state.tree) );
									}
								)

							})

						);

				})



				.catch(e => {
					errorlog("NO DATA", e);



					if( !!e.redirectTo ) {
						// return (
						// 	<Redirect
						// 		to={{
						// 			pathname: PATH_PREFIX +"/srtool/collects",
						// 			state: e.raw
						// 		}}
						// 	/>
						// );
						window.location.href = PATH_PREFIX +"/srtool/" + translate("collects");
					}

					return (

						new Promise((finished, notFinished) => {
							this.setState({
									...this.state,
									wait: null
								},
								() => {
									// notFinished( e );
									finished();
								}
							)

						})

					);


				})

		);





	}

}
