import {Component} from "react";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {emoj} from "../utils/string-utils";
import {EMOJ} from "../emoj";



export default class ErrorMessageModalComponent extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = () => {
		this.props.onClose();
	}

	render() {

		let context = (
			<div className={ "base-message-content "+ (!!this.props.noHazard ? "message-modal-content" : "error-message-modal-content") +" "+ (!!this.props.contentClassName ? this.props.contentClassName : "") }>
				{
					!this.props.noHazard
						// ? <React.Fragment>{ emoj( EMOJ.face_screaming_in_fear ) }<span>&nbsp;&nbsp;&nbsp;&nbsp;</span></React.Fragment>
						? <React.Fragment/>
						: null
				}
				{ this.props.message }
			</div>
		);

		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				{
					(!!this.props.noheader)
						? null
						: (
							<ModalHeader toggle={this.toggle} className={ "modal-colored-header bg-danger" }>
								{ this.props.title || "ATTENZIONE" }
							</ModalHeader>
						)
				}

				<ModalBody className={this.state.bodyClass +  (!!this.props.noHazard ? "" : " error-message-modal-context")}>
					{ context }
				</ModalBody>
			</Modal>

		);


		setTimeout(
			function() {
				this.toggle();
			}.bind( this ),
			2000
		)

		return content;
	}


	componentDidMount() {}
}
