import React, {Component} from "react";
import PageTitle from "../../../../components/PageTitle";
import {PATH_PREFIX} from "../../../../conf/siroad";


export default class TestPublicPage extends Component {

    constructor(props) {
        super( props );
    }



    render() {

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        {
                            label: "Pagina Pubblica", path: PATH_PREFIX +"/welcome" },
                            { label: "Test", active: true }
                    ]}
                    title={"Test Public Page"}
                />


                <span>Pagina</span>

            </React.Fragment>
        );
    }


}
