import React, {Component} from "react";
import {Button, Col, Modal, ModalBody, ModalHeader, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Spinner from '../../Spinner';
import {loadSelf} from "../../../business-logic/users";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {translate} from "../../i18n/translate-function";

const hiddenFields = [
	// "id",
	"email",
	"lastName"
];

const fieldMap = {
	id: "Id",
	picture: " ",
	//title: "Titolo",
	firstName: "Nome",
	lastName: "Cognome",
	// role: "Ruolo",
	// companyName: "Azienda",
	email: "Email"
};


const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Utenti
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];

const sortableFields = [
	"Titolo",
	"Nome",
	"Cognome",
	"Email"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


export default class GriManagerListPopup extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
		this.loadData = this.loadData.bind( this );
	}

	/*
	selectManager = {
		renderer: row => {
			console.log("click sulla riga");
			console.log(row);
			return (<div>Manager</div>);
		},
		showExpandColumn: false,
		onlyOneExpanding: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return !!isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
		},
	};
	*/


	toggle = forceRefresh => {
		this.props.onClose( forceRefresh );
	}

	render() {


		renderlog(
			"GriManagerListPopup",
			this.state,
			this.props,
			{ color: "#11ccff", contrastColor: "#ffffff" }
		)

		let context = (

			<React.Fragment>
				<Row>
					<Col>
						<Button
							className="p-0"
							color={"link"}
							onClick={ clickEvent => {
								console.log("Nuovo manager");
								this.props.addManager();
							}}
							style={{marginTop: "10px"}}
						>
							<i className="mdi mdi-account-edit-outline comander-icon" />
							<span className={""}>&nbsp;&nbsp;Aggiungi Manager&nbsp;&nbsp;</span>
						</Button>
					</Col>
				</Row>

				{
					(!!this.state.data && this.state.data.length > 0 )
						? (
							<ToolkitProvider
								bootstrap4
								keyField="id"
								search
								exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
								{props => (


									<React.Fragment>
										<Row>
											<Col>
												<SearchBar {...props.searchProps} />
											</Col>
										</Row>
										<BootstrapTable
											bootstrap4
											striped
											{...props.baseProps}
											size="sm"
											responsive
											keyField="id"
											bordered={ false }
											data={ this.state.data }
											columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
											defaultSorted={ defaultSorted }
											pagination={ paginationFactory(paginationOptions) }
											wrapperClasses="table-responsive"
											selectRow={{
												mode: 'checkbox',
												clickToSelect: true,


												onSelect: row => {
													showlog("riga selezionata");
													showlog( row );


													if ( row.id === this.props.currentUserManager ) {
														// deselezione

														this.setState({
																...this.state,
																selectedManager: null,
																deselectedManager: row		// serve per la rotellina
															},
															() => {
																this.props.onDeselect( row );
															}
														);


													}
													else {
														// selezione

														this.setState({
															...this.state,
															selectedManager: row
														},
															() => {
																this.props.onChoose( row );
															}
														);

													}


												},


												selectionRenderer: row => {


													if( !!row.checked && !row.disabled ) {
														// console.log("riga selezionata");
														// console.log(row);
													}


													if ( row.rowKey === this.props.currentUserManager ) row.checked = true;
													else row.checked = false;

													if ( !!this.state.selectedManager && row.rowKey === this.state.selectedManager.id ) row.checked = true;

													return (

														(!!this.state.selectedManager && row.rowKey === this.state.selectedManager.id)
															? (
																<div className="custom-checkbox">
																	<Spinner color={"#fffa"} className="spinner-border-sm mr-1 spinner-input" />
																</div>
															)
															: (

																(!!this.state.deselectedManager && row.rowKey === this.state.deselectedManager.id)
																	? (
																		<div className="custom-checkbox">
																			<Spinner color={"#fffa"} className="spinner-border-sm mr-1 spinner-input" />
																		</div>
																	)
																	: (
																		<div className="custom-control custom-checkbox">
																			<input
																				type="checkbox"
																				className="custom-control-input"
																				checked={row.checked}
																				disabled={row.disabled}
																			/>
																			<label
																				className="custom-control-label"
																				onClick={e => {
																					e.preventDefault();
																				}}/>
																		</div>
																	)

															)

													);

												},
												selectionHeaderRenderer: ({indeterminate, ...rest}) => {
													return null;
												}
											}}

										/>

									</React.Fragment>

								)}
							</ToolkitProvider>
					)
					: (
						<WaitingOrNodataMessage
							waiting={ !(!!this.state.data && this.state.data.length === 0) }
							fullHeight={ true }
							nodataMessage={"Nessun Utente"}
						/>
					)

				}

			</React.Fragment>

		)




		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					Managers
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
			</Modal>


		);


		return content;
	}

	componentDidMount() {
		this.loadData();
	}






	loadData( onEnd ) {

		let raws = null;


		return (
			loadSelf( this.props.collectId )


				// profilePicture ------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map(row => {
								// TODO
								row.picture = (
									<div
										className={"profile-picture-in-list picture-alternative"}
									>
										{ row.firstName.substr(0, 1).toUpperCase() + row.lastName.substr(0, 1).toUpperCase() }
									</div>
								);
								// console.log("row");
								// console.log(row);
								return row;
							})
					);
				})

				// additional fields --------------------------------------------------------------------------------------

				// merge fields --------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map(row => {
								row.firstName = row.lastName +" "+ row.firstName;
								return row;
							})
					);
				})


				// row sort --------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.sort((x, y) => {
								if( x.firstName > y.firstName ) return 1;
								return -1
							})
					);
				})


				// field sort ---------------------------------------------------------------------------------------------
				.then( responseData => {

					return (
						responseData
							.map( row => {

								let sortedRow = {};
								Object.keys(fieldMap)
									.map( field => {
										sortedRow[field] = row[field];
									})
								;
								// Object.assign(sortedRow, row);
								return sortedRow;

							})
					);
				})



				// finish
				.then( responseData => {


					this.setState(
						{
							...this.state,
							data: responseData,
							raws: raws
						},
						() => {
							if( typeof onEnd === "function" ) onEnd();
						}
					);
				})
				.catch( e => {
					errorlog("Caricamento lista managers", e);
				})
		);



	}

}
