export const FILE_ICON = {
	generic: "mdi mdi-file-outline",
	jpg:    "mdi mdi-file-image-outline",
	jpeg:   "mdi mdi-file-image-outline",
	png:    "mdi mdi-file-image-outline",
	bmp:    "mdi mdi-file-image-outline",
	gif:    "mdi mdi-file-image-outline",
	pdf:    "mdi mdi-file-pdf-outline",
	xls:    "mdi mdi-file-excel",
	xlsx:   "mdi mdi-file-excel",
	doc:    "mdi mdi-file-word",
	docx:   "mdi mdi-file-word",
	ppt:    "mdi mdi-file-powerpoint-outline",
	pptx:   "mdi mdi-file-powerpoint",
	txt:    "mdi mdi-file-document",
	json:   "mdi mdi-file-code-outline",
	csv:    "mdi mdi-file-table-outline",
	js:     "mdi mdi-file-code-outline",
	css:    "mdi mdi-file-code-outline",
	php:    "mdi mdi-file-code-outline",
	mp3:    "mdi mdi-file-music-outline",
	aac:    "mdi mdi-file-music-outline",
	wave:   "mdi mdi-file-music-outline"
};
