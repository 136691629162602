import React from "react";
import {PopoverBody, Progress, UncontrolledPopover} from 'reactstrap';
import {
	addNewService,
	getActiveServices,
	getAllServices,
	getEvaluations,
	getEvaluationsDashboard,
	getOrganizzationActiveServices,
	loadSuperAdminRatingDashboard,
	updateService
} from "../services/active-service";
import {DATE_FORMAT_IT, RATING_STATUS, SERVICE_DATE_FORMAT_IT} from "../env";
import moment from "moment";
import {showlog} from "../utils/dev-utils";
import {loadRatingAttachments} from "./companies";
import {loadSectorQuestionsDimensions} from "./question-sectors";
import {getLastElementOr} from "../utils/array-utils";


export const loadOrganizzationActiveService = () => {
	return getOrganizzationActiveServices();
};



export const loadActiveService = () => {
	return getActiveServices();
};

export const loadAllServices = () => {
	return getAllServices();
};

export const progressBar = (x) => {

	return <Progress value={x} style={{ marginBottom: "5px", marginTop: "5px", }}>{x + " %"}</Progress>
}


export const loadEvaluationsDashboard = () => {
	// showlog("controllo in BL");
	let toRet = (

		loadSectorQuestionsDimensions()
			.then(dimensionList => {

				return (
					getEvaluationsDashboard()
						.then(evs => {

							return (
								evs
									.map(ev => {
										// beautifyLog( ev );
										ev.dimension = dimensionList.filter(d => d.id === ev.companySize).reduce(getLastElementOr, null);
										return ev;
									})
							);
						})
						.catch(e => Promise.reject(e))
				);
			})


			// rows sort   -------------------------------------------------------------------------------------------
			.then(responseData => {
				return (
					responseData
						.sort((x, y) => {
							if (x.dateCreate < y.dateCreate) return 1;
							return -1;
						})
				)
			})



			.catch(e => Promise.reject(e))
	);
	// showlog("toRet");
	// showlog(toRet);

	return toRet;
}



export const loadSuperAdminRatingDashboardData = () => {
	return loadSuperAdminRatingDashboard();
}



export const loadEvaluations = (ratingType = 1, isArchived) => {
	// showlog("controllo in BL");
	let isAlive = (!!isArchived ? 0 : 1);
	let toRet = (
		// getCompanyAllowedDimension()
		loadSectorQuestionsDimensions()
			.then(dimensionList => {

				// showlog("dimensionList");
				// showlog(dimensionList);

				return (
					getEvaluations( ratingType, isAlive )
						.then(evs => {

							// showlog("evaluations");
							// showlog(evs);


							return (
								evs
									.map(ev => {
										// beautifyLog( ev );
										ev.dimension = dimensionList.filter(d => d.id === ev.companySize).reduce(getLastElementOr, null);
										return ev;
									})
							);
						})
						.catch(e => Promise.reject(e))
				);
			})


			// rows sort   -------------------------------------------------------------------------------------------
			.then(responseData => {
				return (
					responseData
						.sort((x, y) => {
							if (x.dateCreate < y.dateCreate) return 1;
							return -1;
						})
				)
			})



			// adjust ---------------------------------------------------------------------------------------------------
			.then(list => {
				return (
					list
						.map((evaluation,i) => {

							evaluation.dateCreate = moment(evaluation.dateCreate).format(DATE_FORMAT_IT);
							evaluation.lastModifiedStatus = moment(evaluation.lastModifiedStatus).format(DATE_FORMAT_IT);
							evaluation.dimensionId = evaluation.dimension.id;
							evaluation.dimensionName = evaluation.dimension.name;



							evaluation.assignee = <div style={{ textAlign: 'center' }}>

							{evaluation.assignee.map((x,y) => (

								<React.Fragment>
									<div
										id={`popover-${i+"-"+y}`}
										className={"profile-picture-in-list picture-alternative"}
										style={{ display: 'inline-block', marginLeft: '-6px' }}
										onClick={ clickEvent => {
											clickEvent.stopPropagation();
										}}
									>
										 {x.acronym}

									 </div>
									<div>
										<UncontrolledPopover placement={`top`} target={`popover-${i+"-"+y}`}>
											<PopoverBody>{x.name}</PopoverBody>
										</UncontrolledPopover>

									</div>
								</React.Fragment>
							))
							}
							</div>

							evaluation.manager = <div style={{ textAlign: 'center' }}>

							{evaluation.manager.map((x,y) =>

								<React.Fragment>
									<div
										id={`popover2-${i+"-"+y}`}
										className={"profile-picture-in-list picture-alternative"}
										style={{ display: 'inline-block', marginLeft: '-6px' }}
										onClick={ clickEvent => {
											clickEvent.stopPropagation();
										}}
									> {x.acronym} </div>
									<div>
										<UncontrolledPopover placement={`top`} target={`popover2-${i+"-"+y}`}>
											<PopoverBody>{x.name}</PopoverBody>
										</UncontrolledPopover>

									</div>
								</React.Fragment>

							)
							}
						</div>



							return evaluation;
						})
				);
			})




			// status -------------------------------------------------------------------------------------------------
			.then(list => {
				list
					.map(r => {

						// attenzione provvisorio
						// if( r.id === 1058 ) r.state = 20;

						r.status = r.state;
						r.state =
							Object.keys(RATING_STATUS)
								.map(status => RATING_STATUS[status])
								.filter(rs => rs.id === r.state)
								.map(rs => rs.name)
								.reduce(getLastElementOr, "Nessun Status")
							;

						return r;

					})
				return list;

			})





			.catch(e => Promise.reject(e))
	);
	// showlog("toRet");
	// showlog(toRet);

	return toRet;
}





export const getRatingAttachments = ratingId => {
	return loadRatingAttachments(ratingId);
}


export const saveNewService = (data, isOrganizzation) => {

	let dataDTO = {
		CompanyId: data.company_id || 0,
		DateStart: moment(data.startTime, DATE_FORMAT_IT).format(SERVICE_DATE_FORMAT_IT),
		MonthsNumber: data.validity,
		ServiceId: data.service_id,

		// organizzation
		LeadingId: !!isOrganizzation ? data.organizzation_id : 0,
		EvaluationsMaxNumber: !!isOrganizzation ? data.maxEvaluationsNumber : 0,
		CollectionsMaxNumber: !!isOrganizzation ? data.maxCollectionsNumber : 0
	};

	return addNewService(dataDTO);
}


export const editService = (data, isOrganizzation) => {

	let dataDTO = {
		CompanyId: data.company_id || 0,
		DateStart: moment(data.startTime, DATE_FORMAT_IT).format(SERVICE_DATE_FORMAT_IT),
		MonthsNumber: data.validity,
		ServiceId: data.service_id,

		// organizzation
		LeadingId: !!isOrganizzation ? data.organizzation_id : 0,
		EvaluationsMaxNumber: !!isOrganizzation ? data.maxEvaluationsNumber : 0,
		CollectionsMaxNumber: !!isOrganizzation ? data.maxCollectionsNumber : 0
	};

	return updateService(dataDTO, data.id);
}

export const validationSuperAdminNewService = dataField => {

	showlog("validationSuperAdminNewService");
	showlog(dataField);

	return (
		new Promise((success, fail) => {


			let errors = [];

			if( !dataField.isOrganizzation ) {
				// company ---------------------------------------------------------------------------------------------
				if (!dataField.company_id) errors.push({ company: "Manca la compagnia" });
				else {
					if (!!!dataField.company_id && dataField.company_id !== 0) errors.push({ company: "Formato id compagnia errato" });
					else {
						if (
							!!(
								(new String(dataField.company_id)).toString().trim()       // conversione in stringa non vuota
							) &&
							!isNaN(dataField.company_id)   // è un numero
						) { }
						else errors.push({ company: "Formato id compagnia errato" });
					}
				}
			}
			else {

				// organizzation
				if( !dataField.organizzation_id ) errors.push({ organizzation: "Manca l'organizzazione" });

				// max evaluations
				if( dataField.service_id === 2 && !dataField.maxEvaluationsNumber ) errors.push({ maxEvaluationsNumber: "Manca il numero massimo di valutazioni" });

				// max collects
				if( dataField.service_id === 1 && !dataField.maxCollectionsNumber ) errors.push({ maxCollectionsNumber: "Manca il numero massimo di raccolte" });

			}


			// service ------------------------------------------------------------------------------------------------
			if (!dataField.service_id) errors.push({ id: "Manca il servizio" });


			// start date
			if (!dataField.startTime) errors.push({ startTime: "Manca la data" });

			// validità
			if (!dataField.validity) errors.push({ validity: "Mancano i mesi di validità" });

			if (errors.length > 0) fail(errors);
			else success();

		})


	);
}


