import {Account_Create, Account_Delete, Account_Get, Account_Update, SelfAccount_Get} from "./request-manager";

export const addNewUserBySuperAdmin = userData => {
	return Account_Create(userData);
};

export const addNewUserByAdmin = userData => {
	return Account_Create(userData);
};




export const deleteUserBySuperAdmin = userId => {
	return Account_Delete(userId);
};


export const updateUserBySuperAdmin = userData => {

	// console.log("SERVICE - updateUserBySuperAdmin");
	// console.log( userData );

	return Account_Update(userData, userData.id);
};

export const updateUserByAdmin = userData => {
	return Account_Update(userData, userData.id);
};


export const getUsers = () => {
	return Account_Get();
};


export const getSelf = companyID => {
	return SelfAccount_Get( companyID );
}


