
export const COLOR_POLICY = {
    virgin: 	{ id: 0, name: "default", 		color: "#343a40", contrastColor: "#aaaaaa", negativeColor: "#cbc5bf", 	switchClassEnabled: "virgin-switch-enabled", 						switchClassDisabled: "virgin-switch-disabled", 						textClass: "color-policy-text-color-default", 		label: "Status di default" },
    edit: 		{ id: 1, name: "edit", 			color: "#727cf5", contrastColor: "#dddddd", negativeColor: "#8d830a", 	switchClassEnabled: "edit-switch-enabled", 							switchClassDisabled: "edit-switch-disabled", 						textClass: "color-policy-text-color-edit", 			label: "in modifica lato client, informazione ancora non presente a sistema" },
    evident: 	{ id: 9, name: "evident", 		color: "#1188ff", contrastColor: "#dddddd", negativeColor: "#c6502e", 	switchClassEnabled: "evident-switch-enabled", 						switchClassDisabled: "evident-switch-disabled", 					textClass: "color-policy-text-color-evident", 		label: "In Evidenza" },
    complete: 	{ id: 2, name: "complete", 		color: "#39afd1", contrastColor: "#ffffff", negativeColor: "#c6502e", 	switchClassEnabled: "complete-switch-enabled", 						switchClassDisabled: "complete-switch-disabled", 					textClass: "color-policy-text-color-complete", 		label: "Finito" },
    finished: 	{ id: 3, name: "finished", 		color: "#313a46", contrastColor: "#aaaaaa", negativeColor: "#cec5b9", 	switchClassEnabled: "finisched-switch-enabled", 						switchClassDisabled: "finisched-switch-disabled", 					textClass: "color-policy-text-color-finished", 		label: "Completo" },
    validated: 	{ id: 4, name: "validated", 	color: "#0acf97", contrastColor: "#222222", negativeColor: "#f53068", 	switchClassEnabled: "validated-switch-enabled", 					switchClassDisabled: "validated-switch-disabled", 					textClass: "color-policy-text-color-validated", 	label: "Dato validato" },
    select: 	{ id: 10, name: "select", 		color: "#ffff97", contrastColor: "#222222", negativeColor: "#053068", 	switchClassEnabled: "validated-switch-select", 						switchClassDisabled: "validated-switch-select", 					textClass: "color-policy-text-color-select", 		label: "Evidenziazione, selezione" },
    lowWarning: { id: 5, name: "lowWarning", 	color: "#ffbc00", contrastColor: "#444444", negativeColor: "#0043ff", 	switchClassEnabled: "non-compliant-with-message-switch-enabled", 	switchClassDisabled: "non-compliant-with-message-switch-disabled", 	textClass: "color-policy-text-color-lowWarning", 	label: "Attenzione non conformità ma in attesa di controllo" },
    warning: 	{ id: 6, name: "warning", 		color: "#fd7e14", contrastColor: "#000000", negativeColor: "#0281eb", 	switchClassEnabled: "non-compliant-low-switch-enabled", 			switchClassDisabled: "non-compliant-low-switch-disabled", 			textClass: "color-policy-text-color-warning", 		label: "Attenzione non conformità" },
    error: 		{ id: 7, name: "error", 		color: "#fa5c7c", contrastColor: "#000000", negativeColor: "#05a383", 	switchClassEnabled: "non-compliant-high-switch-enabled", 			switchClassDisabled: "non-compliant-high-switch-disabled", 			textClass: "color-policy-text-color-error", 		label: "Errore, Invalido, non conforme" },
    disabled:	{ id: 8, name: "disabled", 		color: "#6c757d", contrastColor: "#000000", negativeColor: "#bbbbbb", 	switchClassEnabled: "non-compliant-high-switch-enabled", 			switchClassDisabled: "non-compliant-high-switch-disabled", 			textClass: "color-policy-text-color-disabled", 		label: "Disabilitato" },
    cancelled:	{ id: 11, name: "cancelled", 	color: "#6c757d", contrastColor: "#000000", negativeColor: "#bbbbbb", 	switchClassEnabled: "non-compliant-high-switch-enabled", 			switchClassDisabled: "non-compliant-high-switch-disabled", 			textClass: "color-policy-text-color-disabled", 		label: "Annullata" }
}


export const MAGNITUDE = [
    { color: "#ff0000" },
    { color: "#ff3300" },
    { color: "#fd7e14" },
    { color: "#ffac00" },
    { color: "#ffff00" },
    { color: "#aaff00" },
    { color: "#77ff00" },
    { color: "#00ff00" }
];

export const MAGNITUDE_DARK = [
    { color: "#aa0000" },
    { color: "#aa3300" },
    { color: "#a85e14" },
    { color: "#da8c00" },
    { color: "#aaaa00" },
    { color: "#77aa00" },
    { color: "#55aa00" },
    { color: "#00aa00" }
];

export const COLOR_SCALE = [
    { color: "#ff0000" },
    { color: "#ff3300" },
    { color: "#fd7e14" },
    { color: "#ffac00" },
    { color: "#ffff00" },
    { color: "#aaff00" },
    { color: "#77ff00" },
    { color: "#00ff00" },
    { color: "#00ffac" },
    { color: "#00cfff" },
    { color: "#0057ff" },
    { color: "#0000ff" },
    { color: "#7750ff" },
    { color: "#af00cf" },
    { color: "#ff00ff" },
    { color: "#ff007f" }
];
