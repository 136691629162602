import {Component} from "react";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {emoj} from "../utils/string-utils";
import {EMOJ} from "../emoj";



export default class ConfirmSaveModal extends Component {

    state = {}

    constructor( props ) {
        super( props );

        this.toggle = this.toggle.bind( this );
    }


    toggle = () => {
        this.props.onClose();
    }

    render() {

        let content = (
            <Modal isOpen={ true } toggle={this.toggle}>

                            <ModalHeader toggle={this.toggle} >
                                { this.props.title || "" }

                                { (!!this.props.message)?(this.props.message):("Salvataggio effettuato correttamente.") }

                            </ModalHeader>

                {/*<ModalBody className={this.state.bodyClass + " confirmsave-message-modal-context"}>*/}
                {/*    { (!!this.props.message)?(this.props.message):("Salvataggio effettuato correttamente.") }*/}
                {/*</ModalBody>*/}
            </Modal>

        );



        setTimeout(
            function() {
                this.toggle();
            }.bind( this ),
            3000
        )

        return content;
    }


    componentDidMount() {}
}
