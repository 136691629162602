import React, {useState} from "react";
import {translate} from "../../../components/i18n/translate-function";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {loadAssociatedEvaluations} from "../../../business-logic/question-sectors";
import {renderlog} from "../../../utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";

const fieldMap = {
    id: "Id",
    name: translate("Rating"),
    version: translate("Version"),
    questionVersion: translate("QuestionVersion"),
    evaluationVersion: translate("EvaluationVersion"),
    question_description: translate("QuestionDescription"),
    company_name: translate("Company")
};


const hiddenFields = [
    "question_id",
    "code",
    "compani_id"
];


const sortableFields = [
    "id",
   "name",
    "version"
];

const defaultSorted = [
    {
        dataField: "Id",
        order: "asc",
    },
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const SectorQuestionModal = props => {

    renderlog("ASSOCIATED EVALUATIONS MODAL")

    const [evaluationsList, setEvaluationList] = useState();
    const [errors, setErrors] = useState();

    let content = (
        <div className={"light-white-backpan strong-blur-backpan"}>
            <WaitingOrNodataMessage
                waiting={ true }
                // nodataMessage={}
                waitMessage={ translate("Loading_") }
                spinnerColor={ "primary" /*"#35f"*/ }
                fullHeight={ true }
                // className={}
                // spinnerClass={}
            />
        </div>
    );



    if( !evaluationsList ) {
        loadAssociatedEvaluations( props.question.id )
            .then(list => {
                setEvaluationList( list );
            })
            .catch( e => {
                setEvaluationList([]);
                setErrors([translate("Error while getting evaluations list")])
            })
    }
    else {

        if( !!errors ){

            content= (
                <React.Fragment>
                    <ModalHeader  toggle={ toggleEvent => { props.onClose() } } >
                        Errore ottenimento valutazioni associate alla domanda SQ.{props.question.id}
                    </ModalHeader>
                    <ModalBody >
                        {errors}
                    </ModalBody>
                </React.Fragment>
            )

        }else if( evaluationsList.length === 0 ) {

            content =  (

                    <ModalHeader  toggle={ toggleEvent => { props.onClose() } } >
                        Non sono presenti valutazioni associate alla domanda SQ.{props.question.id}
                    </ModalHeader>

            )


        }else{

            content = (

                <React.Fragment>
                    <ModalHeader toggle={ toggleEvent => { props.onClose() }} >
                         Valutazioni associate alla domanda SQ.{props.question.id}
                    </ModalHeader>
                    <ModalBody style={{maxHeight: "837px", overflowY: "auto"}}>

                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            search
                        >
                            {props => (
                                <React.Fragment>


                                    <BootstrapTable
                                        //id={"id"}
                                        bootstrap4
                                        striped
                                        {...props.baseProps}
                                        size="sm"
                                        responsive
                                        keyField="id"
                                        bordered={ false }
                                        data={ evaluationsList }
                                        columns={ formatColumns( Object.keys( evaluationsList[0] ) ) }
                                        defaultSorted={ defaultSorted }
                                        //pagination={ paginationFactory(paginationOptions) }
                                        wrapperClasses="table-responsive"

                                    />


                                </React.Fragment>
                            )}
                        </ToolkitProvider>

                    </ModalBody>
                </React.Fragment>

            )
        }


        content = (
            <Modal
                isOpen={ true }
                toggle={ toggleEvent => { props.onClose() }}
                className={"large-modal"}
            >
                { content }
            </Modal>
        );

    }



    return content

}


export default SectorQuestionModal;
