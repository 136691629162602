import React from "react";
import {Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import {isNotEmptyArray} from "../../../utils";
import "../../../style/sr-tool-conf.css";
import {translate} from "../../i18n/translate-function";

export const NoDataChart = props => (
    <Card className={ "no-chart-data-card" }>
        <CardBody>
            <UncontrolledButtonDropdown className="float-right">
                <DropdownToggle tag="button" className="btn btn-link arrow-none card-drop p-0">
                    <i className="mdi mdi-dots-vertical"/>
                </DropdownToggle>

                <DropdownMenu right>
                    <DropdownItem
                        onClick={clickEvent => {
                            props.openSettings( props.id )
                        }}
                    >
                        Filtri
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>

            <h4 className="header-title mb-3">
                { props.title }
                {
                    !isNotEmptyArray( props.data )
                        ? <span className={"chart-nodata-notific"}>{translate("No Data")}</span>
                        : null
                }
            </h4>

            <i className="mdi mdi-chart-areaspline no-data-chart-symbol"/>
            {/*
            <span className={"no-data-chart-symbol"}>
                { emoj( EMOJ.gear ) }
            </span>
            */}
        </CardBody>
    </Card>
)



