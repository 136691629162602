import React from "react";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../conf/color-policy";
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {QUESTION_STATUS} from "../../../conf/sirating";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import ActionButton from "../../save-button";
import {questionAudit} from "../../../business-logic/rating";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import RequirementsFilter from "../super-admin/requirements-filter";
import SRToolCollectChartFilters from "../super-admin/srtool-collect-chart-filters";


export const ChartSettingsModal = props => {

    renderlog("ChartSettingsModal", null, props, COLOR_POLICY.disabled)

    const toggle = () => {
        if(!!true) props.onClose();
    }

    let	context = (
        <React.Fragment>
            <RequirementsFilter
                conf={ props.configuration }
                arg={ data => {
                    showlog("uscita filtri");
                    showlog( data );
                    props.onSaveConfiguration( Object.assign({}, data, { chartId: (!!props.configuration) ? props.configuration.chartId : props.chartId }) );
                    // this.setState({arg:data})
                }}
            />
        </React.Fragment>
    );

    let content = (
        <Modal isOpen={ true } toggle={toggle} className="rounded-modal">
            {/*}
            <ModalHeader toggle={toggle} className={"chart-data-settings-header"}>
                <span className={"no-data-chart-symbol-title"}>{ emoj( EMOJ.gear ) }</span> &nbsp;&nbsp; Impostazioni
            </ModalHeader>
            */}
            <ModalBody className={""}>
                {
                    // context
                }

                <SRToolCollectChartFilters
                    chartId={ props.chartId }
                    conf={ props.configuration }
                    onSave={ data => props.onSaveConfiguration( data ) }
                />

            </ModalBody>
        </Modal>
    );


    return content;

}
