import {roleTree} from "../env";
import {getLastElementOr, isNotEmptyArray, onlyNotNull} from "../utils/array-utils";
import {showlog} from "../utils/dev-utils";
import {getMaterialityCSVFile} from "../services/rating";
import {getMaterialityCollFile} from "../services/collect";

/**
 * ritorna il sotto-albero a partire dal nodo indicato
 */
export const getSubRoleTree = ( nodeId, current = roleTree ) => {
    if( nodeId === current.data.id ) return current;
    return (
        current.branches
            .map( n => {
                let subLevelNode = getSubRoleTree( nodeId, n );
                if( !!subLevelNode && subLevelNode.id === n.id ) return subLevelNode;
                return null;
            })
            .filter( onlyNotNull )
            .reduce( getLastElementOr, null )
    )
    ;
};



export const scanRoleTree = ( currentNode = roleTree, currentPath = [ ""+ roleTree.data.id ] ) => {
    currentNode.path = [ ...currentPath, currentNode.data.id ].filter( onlyNotNull );
    currentNode.branches
        .forEach( n => {
            scanRoleTree( n, currentNode.path );
        })
    ;
}


export const getAllNodesOfSubRoleTree = (subRoleTree = roleTree, previousNodes = [] ) => {
    // showlog("getAllNodesOfSubRoleTree", previousNodes, subRoleTree);
    let list = [ ...previousNodes ];
    if( !!subRoleTree && !!isNotEmptyArray( subRoleTree.branches ) ) {
        subRoleTree.branches
            .forEach( node => {
                list = [ ...list, ...getAllNodesOfSubRoleTree( node, [ ...previousNodes, subRoleTree.data, node.data ] ) ];
            })
        ;
    }
    return ([
        ... new Map(
            list
                .filter( onlyNotNull )
                .map( item => [ item.id, item ])
        ).values()
    ])
}

export const getCollectCsvFile = (rid, fileName, macroarea, cod) => {
    return getMaterialityCollFile(rid, fileName, macroarea, cod);
}



