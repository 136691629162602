import React, {Component} from "react";
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog, showlog} from "../../../utils/dev-utils";
import {isNotEmptyArray} from "../../../utils/array-utils";
import {COLOR_POLICY, QUESTION_TEXT_COLOR_POLICY} from "../../../env";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
// import { Score_Get } from "../../../../src/services/request-manager";
import {cloneObject} from "../../../utils/object-utils";
import {getScore} from "../../../business-logic/score";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {formatDigitLength} from "../../../utils";
import {translate} from "../../i18n/translate-function";


const fieldSdgMap = {
    thematic_name: translate("Topic_SubTopic"),
    subthematic_name: translate("SubTopic"),
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    materiality: translate("Materiality_Level"),
    percentage: translate("Score")
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];


const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            { `${ translate("Row_from") } ${ from } ${ translate("to") } ${ to } ${ translate("of") } ${ size }` }
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "maxScore", "sumScore", "macroarea_id", "macroarea_name", "subthematic_id", "thematic_id", "questions", "subthematic_code", "thematic_code","question_id", "question_code", "percentageNotRound"
];


const hiddenSdgFields2 = [
    "thematic_name", "subthematic_id", "maxScore", "sumScore", "macroarea_id", "macroarea_name", "thematic_id", "questions", "subthematic_code", "thematic_code", "question_code","question_id" , "percentageNotRound"
];


export default class ScoreModal extends Component {


    state = {
        colorEnv: COLOR_POLICY.disabled.color,
        colorSoc: COLOR_POLICY.disabled.color,
        colorGov: COLOR_POLICY.disabled.color

    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    importData = () => {


        //return Score_Get(this.props.evaluation_id, 0).then(r => {
        return (
            getScore(this.props.evaluation_id, 0)
                .then(r => {

                    showlog("Score_Get");
                    showlog(r);
                    showlog(r.thematicScores);
                    // debugger;
                    // return r.thematicScores;
                    return r;

                })

                .catch(e => {
                    console.log("errore");
                    console.log(e);
                })

        );


        //return data;
        // return (new Promise(function(resolve, reject) {
        //     if (data!=null) {
        //         resolve(data);
        //     } else {
        //         reject("");
        //     }
        // })


    }

    thematicFilter = (thematic_id) => {


        new Promise((resolve, reject) => {
            //alert(thematic_id);
            // debugger

            let data =
                {
                    macroareaScores: {
                        rating: this.state.rating,
                        environmental: this.state.environmental,
                        governance: this.state.governance,
                        social: this.state.social,
                    },
                    thematicScores: this.state.data_getRequest
                }


            let data_getRequest = cloneObject(data);
            // data.thematicScores.map((value, index) => {
            //      value.percentage = value.percentage + " %";
            //      value.Id = index + 1;
            //  })

            var result = [];
            let thematicIdList = null;
            let dataFilter = cloneObject(data);

            if (thematic_id !== 0) {
                dataFilter.thematicScores = dataFilter.thematicScores.filter(x => x.macroarea_id === thematic_id);
            }


            thematicIdList = [...new Map(dataFilter.thematicScores.map(item => [item.thematic_id, item])).values()].map(x => x.thematic_id);
            thematicIdList.forEach((element, index) => {

                let thematics = data.thematicScores.filter(x => {
                    return x.thematic_id === element
                });

                showlog("THEMATICS_filter",thematics)
                showlog("THEMATICS2_filter",thematics.filter(thematic => thematic.materiality !=0))

                if (thematics[0].subthematic_id === 0) {
                    result.push(thematics[0]);

                } else {
                    let icon = (
                        <React.Fragment>
                            <strong
                                className={ QUESTION_TEXT_COLOR_POLICY.edit.class }
                            >
                                { thematics[0].thematic_name }
                            </strong>
                            <i className="mdi mdi-plus-box-multiple-outline" />
                        </React.Fragment>
                    );
                    let obj = {
                        thematic_id: thematics[0].thematic_id,
                        thematic_name: icon,
                        percentage: parseInt( thematics.reduce((result, current) => result + current.materiality * current.percentage, 0) / thematics.reduce((result, current) => result + current.materiality, 0), 10),
                        materiality: formatDigitLength( thematics.reduce((result, current) => result + current.materiality, 0) / thematics.filter(thematic => thematic.materiality !==0).length, 2 )
                        // materiality:  thematics.reduce((result, current) => result + current.materiality, 0) / thematics.length
                    }
                    result.push(obj);
                }




            });

            data.thematicScores = result;

            resolve({data: data, data_getRequest: data_getRequest, thematic_id});
        }).then(data => {

                data.data.thematicScores = data.data.thematicScores.map(x => {
                    x.percentage += " %";
                    return x
                });

                this.setState({
                        ...this.state,
                        rating: data.data.macroareaScores.rating,
                        environmental: data.data.macroareaScores.environmental,
                        social: data.data.macroareaScores.social,
                        governance: data.data.macroareaScores.governance,
                        data_getRequest: data.data_getRequest.thematicScores,
                        data: data.data.thematicScores,
                        colorEnv: (data.thematic_id === 1 ? COLOR_POLICY.edit.color : COLOR_POLICY.disabled.color),
                        colorSoc: (data.thematic_id === 2 ? COLOR_POLICY.edit.color : COLOR_POLICY.disabled.color),
                        colorGov: (data.thematic_id === 3 ? COLOR_POLICY.edit.color : COLOR_POLICY.disabled.color),
                    }
                )
            }
        )

    }

    loadData() {

        this.importData()
            .then(data => {
                let data_getRequest = cloneObject(data);
                // data.thematicScores.map((value, index) => {
                //      value.percentage = value.percentage + " %";
                //      value.Id = index + 1;
                //  })



                showlog( data );
                // debugger;

                var result = [];
                let thematicIdList = [...new Map(data.thematicScores.map(item => [item.thematic_id, item])).values()].map(x => x.thematic_id);

                thematicIdList.forEach((element, index) => {

                    let thematics = data.thematicScores.filter(x => {
                        return x.thematic_id == element
                    });

                    showlog("THEMATICS",thematics)
                    showlog("THEMATICS2",thematics.filter(thematic => thematic.materiality !=0))

                    if (thematics[0].subthematic_id == 0) {
                        result.push(thematics[0]);

                    } else {
                        let icon = (
                            <React.Fragment><strong
                                style={{color: "rgb(114, 124, 245)"}}>{thematics[0].thematic_name}</strong> <i
                                className="mdi mdi-plus-box-multiple-outline"/></React.Fragment>
                        );
                        let obj = {
                            thematic_id: thematics[0].thematic_id,
                            thematic_name: icon,
                            percentage: Math.round(thematics.reduce((result, current) => result + (current.materiality * current.percentage), 0) / thematics.reduce((result, current) => result + current.materiality, 0)),
                            materiality: formatDigitLength( thematics.reduce((result, current) => result + current.materiality, 0) / thematics.filter(thematic => thematic.materiality !==0).length, 2)
                        }
                        result.push(obj);
                    }


                });

                data.thematicScores = result;

                return ({data: data, data_getRequest: data_getRequest});
            })
            .then(data => {

                data.data.thematicScores = data.data.thematicScores.map(x => {
                    x.percentage += " %";
                    return x
                });

                // debugger
                this.setState({
                    ...this.state,
                    rating: data.data.macroareaScores.rating,
                    environmental: data.data.macroareaScores.environmental,
                    social: data.data.macroareaScores.social,
                    governance: data.data.macroareaScores.governance,

                    environmental_materiality: data.data.macroareaScores.environmental_materiality,
                    social_materiality: data.data.macroareaScores.social_materiality,
                    governance_materiality: data.data.macroareaScores.governance_materiality,


                    data_getRequest: data.data_getRequest.thematicScores,
                    data: data.data.thematicScores
                })

            });


    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }

    printSubRow(row) {


        let data = this.state.data_getRequest.filter(x => x.thematic_id === row.thematic_id);

        return (


            <ToolkitProvider
                bootstrap4
                keyField="id"
                search
            >
                {props => (


                    <BootstrapTable
                        bootstrap4
                        striped
                        //{...props.baseProps}
                        size="sm"
                        responsive
                        keyField="id"
                        bordered={false}
                        data={data}
                        columns={formatSdgColumns2(Object.keys(data[0]))}
                        defaultSorted={defaultSorted}
                        //pagination={paginationFactory(paginationOptions)}
                        wrapperClasses="table-responsive"
                    />
                )}
            </ToolkitProvider>

        )

    }

    render() {

        renderlog("ScoreModal", this.state, this.props, COLOR_POLICY.validated);

        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm"/>
                <span className={"loading-text-alignment"}>{ translate("Loading_") }</span>
            </React.Fragment>
        );


        if (!!this.state.data) {


            context = (

                <React.Fragment>


                    <ToolkitProvider
                        bootstrap4
                        keyField="thematic_id"
                        search
                    >
                        {props => (

                            <React.Fragment style={{alignContent: 'center'}}>
                                <Row style={{alignContent: 'center', textAlign: 'center'}}>
                                    <Col>
                                        <Label className={"non-compliant-switch"} onClick={e => {
                                            this.thematicFilter(0)
                                        }} style={{marginRight: '35px', fontSize: '20px', color: '#089e74'}}><strong
                                            style={{color: '#089e74'}}>{ translate("Final_Score") }:</strong> {(this.state.rating)}%
                                        </Label>
                                        <br/>
                                    </Col>
                                </Row>

                                {
                                    !!this.props.isGenderGap
                                        ? null
                                        : (
                                            <Row style={{alignContent: 'center', textAlign: 'center'}}>
                                                <Col>
                                                    <br/>
                                                    {/* <SearchBar {...props.searchProps} /> */}


                                                    <Label className={"non-compliant-switch"} onClick={e => {
                                                        this.thematicFilter(1)
                                                    }} style={{
                                                        marginRight: '35px',
                                                        fontSize: '16px',
                                                        color: this.state.colorEnv
                                                    }}><strong
                                                        style={{color: this.state.colorEnv}}>ENVIRONMENTAL: {this.state.environmental}% </strong>( { this.state.environmental_materiality } )</Label>
                                                    <Label className={"non-compliant-switch"} onClick={e => {
                                                        this.thematicFilter(2)
                                                    }} style={{marginRight: '35px', fontSize: '16px'}}><strong
                                                        style={{color: this.state.colorSoc}}>SOCIAL: {this.state.social}% </strong>( { this.state.social_materiality } )</Label>
                                                    <Label className={"non-compliant-switch"} onClick={e => {
                                                        this.thematicFilter(3)
                                                    }} style={{fontSize: '16px'}}><strong
                                                        style={{color: this.state.colorGov}}>GOVERNANCE: {this.state.governance}% </strong>( { this.state.governance_materiality } )</Label>

                                                </Col>
                                            </Row>
                                        )
                                }

                                {
                                    (!!isNotEmptyArray( this.state.data ))
                                        ? (
                                            <BootstrapTable
                                                bootstrap4
                                                striped
                                                {...props.baseProps}
                                                size="sm"
                                                responsive
                                                keyField="thematic_id"
                                                bordered={false}
                                                data={this.state.data}
                                                columns={
                                                    formatSdgColumns(
                                                        Object.keys(this.state.data[0])
                                                            .filter( field => {

                                                                    return ( field !== "subthematic_name" && field !== "Sottotematica")

                                                            })
                                                    )
                                                }
                                                defaultSorted={defaultSorted}
                                                // pagination={paginationFactory(paginationOptions)}
                                                wrapperClasses="table-responsive"
                                                expandRow={{
                                                    renderer: row => {
                                                        if (row.subthematic_id != 0) {
                                                            return this.printSubRow(row);
                                                        }
                                                    },
                                                    showExpandColumn: false,
                                                    onlyOneExpanding: true,
                                                    expandHeaderColumnRenderer: ({isAnyExpands}) => {
                                                        return <i className="dripicons-minus"/>;
                                                        // return null;
                                                    },
                                                    expandColumnRenderer: ({expanded}) => {
                                                        return <i className="dripicons-minus"/>;
                                                        // return null;
                                                    }
                                                }}
                                            />
                                        )
                                        : (
                                            <WaitingOrNodataMessage
                                                waiting={ false }
                                                className={ "score-modal-small-text" }
                                                nodataMessage={ translate("NoData") }
                                            />
                                        )
                                }



                            </React.Fragment>

                        )}

                    </ToolkitProvider>

                </React.Fragment>
            );


        }


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>{ translate("ESG_Performance") }</ModalHeader>
                <ModalBody className={this.state.bodyClass}>{ context }</ModalBody>
            </Modal>
        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }


}


const formatSdgColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields.includes(ff.dataField))
    );
}

const formatSdgColumns2 = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields2.includes(ff.dataField))
    );
}

const {SearchBar} = Search;

