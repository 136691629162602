import React, {useState} from "react";
import siratingw from "../../../../assets/images/SI-Rating-w.png";
import srtoolw from "../../../../assets/images/SR-tool-w.png";
import siscoringlogo_w from "../../../../assets/images/logo_SI_Scoring_white.svg";
import GG from "../../../../assets/images/Logo_Parita_di_genere_white.svg";
import {isUserAuthenticated} from "../../../../helpers/authUtils";
import {cloneObject, decodeMarkDown, errorlog, getLastElementOr, renderlog, showlog} from "../../../../utils";
import {COLOR_POLICY} from "../../../../conf/color-policy";
import {EMAIL_REGEX, LANGUAGES, PRODUCTION_HOST, SIGNUP_STEP} from "../../../../env";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import ActionButton from "../../../../components/save-button";
import {loginService, requestResetPassword} from "../../../../services/auth-service";
import ErrorMessageModalComponent from "../../../../components/error-message-modal-component";
import {translate} from "../../../../components/i18n/translate-function";
import ARB_SPA_W from "../../../../assets/images/ARB_S.B.P.A_white.svg";
import {ForgotPasswordPanel} from "./forgot-password";
import {LanguageSelector} from "./language-component";
import SiRoadLogoWhiteText from "../../../../assets/images/Si-Road_white.png";
import SiRoadLogoBlackText from "../../../../assets/images/Si-Road_high.png";


export const SignLanding = props => (
    <React.Fragment>
        <SignLandingDesktopPage {...props} />
        {/*<SignLandingSmallPage   {...props} />*/}
        <SignLandingTinyPage    {...props} />
    </React.Fragment>
)



const ARBLogoComponent = props => (
    <a
        className={"arblogo-component"}
        href={"https://www.arbsb.it/"}
        target={"_blank"}
    >
        <span className={ !!props.whiteLogo ? "white-text" : "" }>powered by</span>
        <img
            src={ !!props.whiteLogo ? ARB_SPA_W : PRODUCTION_HOST + "/assets/Loghi/ARB.svg" }
            id={"arbsbpa-logo"}
            alt={"ARB SPA"}
        />
    </a>
);


const SiratingColumn = (
    <div>
        <img src={ siratingw } alt={"SI Rating"} id={"sirating-logo"}/>
        <div>
            <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_01"), {noSpan: true} ) }</p>
            <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_02"), {noSpan: true} ) }</p>
        </div>
        <img src={ srtoolw } alt={"SR Tool"} id={"srtool-logo"}/>
        <div>
            <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_03"), {noSpan: true} ) }</p>
            <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_04"), {noSpan: true} ) }</p>
        </div>
        {/*<img src={PRODUCTION_HOST + "/assets/Loghi/ARB.svg"} alt={"ARB SPA"} id={"arb-logo"}/>*/}
    </div>
);



/**
 * Landing page in modalità desktop
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SignLandingDesktopPage = props => {

    // const [loginForm, setLoginForm] = useState( !!props.step && (props.step === SIGNUP_STEP.LOGIN) );
    const [forgotPasswordForm, setForgotPasswordForm] = useState();
    const [data, setData] = useState({...props.data});
    const [email, setEmail] = useState();
    const [language, setLanguage] = useState(
        LANGUAGES

            // ATTENZIONE temporaneo
            .filter( lang => {

                    if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                    if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                    return true;


            })


            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )
            .reduce( getLastElementOr, LANGUAGES[0] )
    );

    if ( !!isUserAuthenticated() ) {
        if (window.location.href.indexOf("/account/login") !== -1) {
            // new location
            let newLocation =
                    window.location.href
                        .split("/")
                        .filter((_, i) => i < 3)
                        .join("/")
                //+ "/"
            ;
            window.location.href = newLocation;
        } else {
            // refresh page
            window.location.href = window.location.href;
        }
    }

    renderlog("SignLandingDesktopPage", {forgotPasswordForm, data, email}, props, COLOR_POLICY.select)
    // showlog( !!props.step );
    // showlog( (props.step === SIGNUP_STEP.LOGIN) );
    // showlog( `${props.step} === ${SIGNUP_STEP.LOGIN}` );
    // showlog( !!props.step && (props.step === SIGNUP_STEP.LOGIN) );
    // showlog( !!loginForm );



    if( !!forgotPasswordForm ) {

        return (
            <ForgotPasswordPanel
                onClose={ closeEvent => {
                    setForgotPasswordForm( false);
                }}
            />
        );


    }

    if(  !!props.step && (props.step === SIGNUP_STEP.LOGIN)  ) {
        return (
            <React.Fragment>

                <div className={"panel desktop"}>
                    <div className={"signup-panel-profile-container"}>

                        {
                            (!!data.errorMessage)
                                ? (
                                    <ErrorMessageModalComponent
                                        message={ data.errorMessage }
                                        noheader={true}
                                        onClose={() => {
                                            let newData = cloneObject( data );
                                            delete newData.errorMessage;

                                            setData( newData );

                                        }}
                                    />
                                )
                                : null
                        }

                        {/* tolto
                        <LanguageSelector
                            onChange={ language => {
                                setLanguage( language );
                            }}
                            className={"language-selector-in-panel"}
                        />
                        */}

                        <div className={"signup-panel-profile-logo-container desktop"}>
                            {/*}
                            */}
                            <img
                                src={SiRoadLogoBlackText}
                                // src={ PRODUCTION_HOST +"/assets/Loghi/SiRoad_home.png" }
                                alt={"SI Road"}
                                id={"siroad-logo-small-screen"}
                                width={"250px"}
                            />
                            {/*<div style={{ minHeight: "130px" }}/>*/}
                            <div className={"signup-panel-profile-element signup-panel-profile-title"}>{ translate("welcome_again_label") }</div>
                        </div>
                        <div
                            className={"signup-panel-profile-element"}
                            id={"signup-panel-profile-form"}
                        >
                            <AvForm
                                className={"signup-panel-profile-form-container"}
                                onValidSubmit={(event, values) => {
                                    showlog("onValidSubmit");
                                    showlog(event);
                                    showlog(values);
                                }}
                            >
                                {/* -------------------------------------------------------------------------- */}
                                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                    <AvInput
                                        type={"email"}
                                        name={"email"}
                                        id={"signupProfile_email"}
                                        className={"signupInput"}
                                        label={""}
                                        value={data.email}
                                        defaultValue={data.email}
                                        placeholder={"Email"}
                                        onChange={changeEvent => {
                                            setData({...data, email: changeEvent.target.value});
                                        }}
                                        disabled={false}
                                        required
                                    />
                                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                </AvGroup>

                                {/* -------------------------------------------------------------------------- */}
                                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                    <AvInput
                                        type={"password"}
                                        name={"password"}
                                        id={"signupProfile_password"}
                                        className={"signupInput"}
                                        label={""}
                                        value={data.password}
                                        defaultValue={data.password}
                                        placeholder={"Password"}
                                        onChange={changeEvent => {
                                            setData({...data, password: changeEvent.target.value});
                                        }}
                                        disabled={false}
                                        required
                                    />
                                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                </AvGroup>

                                <div className={"signup-panel-company-button-container"}>
                                    <ActionButton
                                        visible={true}
                                        buttonText={ translate("accessBigText") }
                                        className={"signup-panel-profile-button"}
                                        promise={() => {

                                            if( !data.password ) {
                                                return Promise.reject("Manca la Password");
                                            }

                                            return (
                                                new Promise((success, fail) => {

                                                    loginService(data.email, data.password)
                                                        .then(userData => {

                                                            success({...data, ...userData});

                                                        })
                                                        .catch(e => {
                                                            errorlog("Auth login error", e);		// non gestita lato server

                                                            let errorMessage = JSON.stringify(e.message || e.error.msg);
                                                            if( !!e.error ) {
                                                                if( !!e.error.message ) errorMessage = e.error.message;
                                                            }

                                                            fail( errorMessage );

                                                        })
                                                    ;

                                                })
                                            )


                                        }}
                                        onProgress={progress => {

                                        }}
                                        onResult={data => {

                                            window.localStorage.setItem("language", data.language || LANGUAGES[0].code)
                                            setData({
                                                ...data,
                                                username: data.email,
                                                password: data.password,
                                                // nounce: (new Date()).getTime(),
                                                loading: false,
                                                role: data.role
                                            })

                                        }}
                                        onError={error => {

                                            setData({
                                                ...data,
                                                errorMessage: error,
                                                loading: false
                                            })

                                        }}
                                    />
                                </div>
                            </AvForm>
                        </div>
                        <div className={"signup-panel-profile-element"} id={"signup-panel-profile-alternative"}>
                            {/*}
                            <span>
                                Non hai ancora un account?&nbsp;
                                <span
                                    className={"signup-link"}
                                    // className={"disabled-link"}
                                    onClick={clickEvent => {
                                        // props.onSignup();
                                        props.onNext();
                                    }}
                                >
                                    Iscriviti qui
                                </span>
                            </span>
                            */}
                            <span
                                className={"signup-link"}
                                onClick={clickEvent => {
                                    // props.onForgotPassword();
                                    setForgotPasswordForm( true );
                                }}
                            >
                                { translate("forgot_password_question") }
                            </span>
                        </div>
                        <div className={"signup-panel-profile-element"} id={"signup-panel-profile-footer"}>
                            <ARBLogoComponent/>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className={"sign-landing-page desktop"}>


                <div className={"landing-page-comander small-screen"}>
                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                    />
                    <ActionButton
                        visible={true}
                        buttonText={ translate("accessBigText") }
                        className={"signup-panel-profile-button signup-panel-profile-button-landing"}
                        promise={() => {
                            return Promise.resolve();
                        }}
                        onProgress={progress => {

                        }}
                        onResult={result => {
                            // setLoginForm( true );
                            showlog("Access Button clicked");
                            props.onNext();
                        }}
                        onError={error => {

                        }}
                    />
                </div>


                <div className={"first-column-signin"} style={{ marginTop: '22px' }}>
                    {/* provvisorio
                    <div style={{ minHeight: "95px" }}/>
                    */}
                    <img src={SiRoadLogoWhiteText} alt={"SI Road"}/>
                    <div id={"sign-landing-page-title"}>{ translate("landingPageWelcomeInSiRoad") }</div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_01"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_02"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_03"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_04"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    <img src={ siratingw } alt={"SI Rating"} id={"sirating-logo"}/>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_01"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_02"), {noSpan: true} ) }</p>
                    </div>
                    {/*<img src={ srtoolw } alt={"SR Tool"} id={"srtool-logo"}/>*/}
                    {/*---- rimozione srTool scadenza GRI*/}
                    {/*<div>*/}
                        {/*<p>{ decodeMarkDown( translate("landingPage_desktop_column_02_03"), {noSpan: true} ) }</p>*/}
                        {/*<p>{ decodeMarkDown( translate("landingPage_desktop_column_02_04"), {noSpan: true} ) }</p>*/}
                        {/*---- rimozione srTool scadenza GRI*/}
                    {/*</div>*/}

                    {/*<img src={PRODUCTION_HOST + "/assets/Loghi/ARB.svg"} alt={"ARB SPA"} id={"arb-logo"}/>*/}

                    <img
                        // src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
                        src={ siscoringlogo_w }
                        alt={"SI Scoring"}
                        id={"siscoring-logo"}
                    />
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_01"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_02"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    {/*<img*/}
                    {/*    // src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }*/}
                    {/*    src={ siscoringlogo_w }*/}
                    {/*    alt={"SI Scoring"}*/}
                    {/*    id={"siscoring-logo"}*/}
                    {/*/>*/}
                    {/*<div>*/}
                        {/*<p>{ decodeMarkDown( translate("landingPage_desktop_column_03_01"), {noSpan: true} ) }</p>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                        {/*<p>{ decodeMarkDown( translate("landingPage_desktop_column_03_02"), {noSpan: true} ) }</p>*/}
                    {/*</div>*/}
                    {/*---- rimozione srTool scadenza GRI*/}
                    <img
                        style={{marginTop:"4.5rem"}}
                     // stile messo per ---- rimozione srTool scadenza GRI
                        src={ GG }
                        alt="Parità di genere"
                        id={"gender-gap-logo"}
                    />
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_03"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_04"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_05"), {noSpan: true} ) }</p>
                    </div>
                </div>


                <div className={"landing-page-comander"}>
                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                    />
                    <ActionButton
                        visible={true}
                        buttonText={ translate("accessBigText") }
                        className={"signup-panel-profile-button signup-panel-profile-button-landing"}
                        promise={() => {
                            return Promise.resolve();
                        }}
                        onProgress={progress => {

                        }}
                        onResult={result => {
                            // setLoginForm( true );
                            showlog("Access Button clicked");
                            props.onNext();
                        }}
                        onError={error => {

                        }}
                    />
                </div>


            </div>
            <div className={"sign-landing-page-bottom desktop"}>
                <ARBLogoComponent whiteLogo={ true } />
            </div>

        </React.Fragment>
    );
}

/**
 * Landing page in modalità small page
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SignLandingSmallPage = props => {

    const [loginForm, setLoginForm] = useState( !!props.isLoginForm );
    const [data, setData] = useState({});
    const [forgotPasswordForm, setForgotPasswordForm] = useState();
    const [email, setEmail] = useState();
    const [language, setLanguage] = useState(
        LANGUAGES
            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )

            // ATTENZIONE temporaneo
            .filter( lang => {

                    if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                    if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                    return true;

            })


            .reduce( getLastElementOr, LANGUAGES[0] )
    );

    renderlog("SignLandingSmallPage", {loginForm, data}, props);


    if( !!forgotPasswordForm ) {
        return (
            <React.Fragment>

                <div className={"signup-panel-profile-logo-container small-screen"}>
                    {/*}
                    */}
                    <img
                        src={SiRoadLogoWhiteText}
                        alt={"SI Road"}
                        id={"siroad-logo-small-screen"}
                        width={"130px"}
                    />
                    {/*<div style={{ minHeight: "130px" }}/>*/}
                    <div className={"signup-panel-profile-title"}>{ translate("welcome_again_label") }</div>
                </div>

                <div className={"panel small-screen"}>
                    <div className={"signup-panel-profile-container"}>
                        <div
                            className={"signup-panel-profile-element"}
                            id={"signup-panel-profile-form"}
                        >

                            <AvForm
                                className={"signup-panel-profile-form-container"}
                                onValidSubmit={(event, values) => {
                                    showlog("onValidSubmit");
                                    showlog(event);
                                    showlog(values);
                                }}
                            >
                                {/* -------------------------------------------------------------------------- */}
                                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                    <AvInput
                                        type={"email"}
                                        name={"email"}
                                        id={"signupProfile_email"}
                                        className={"signupInput"}
                                        label={""}
                                        value={email}
                                        defaultValue={email}
                                        placeholder={"Email"}
                                        onChange={changeEvent => {
                                            setEmail( changeEvent.target.value );
                                        }}
                                        disabled={false}
                                        required
                                    />
                                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                </AvGroup>

                                <div className={"signup-panel-company-button-container"}>
                                    <ActionButton
                                        color={"#d00"}
                                        className={"signup-panel-company-button-disabled"}
                                        buttonText={ translate("button_text_cancel") }
                                        promise={ () => {
                                            return Promise.resolve();
                                        }}
                                        onResult={ result => {
                                            setForgotPasswordForm( false );
                                        }}
                                    />
                                    <ActionButton
                                        visible={ !!email && !!(email.match( EMAIL_REGEX )) }
                                        buttonText={ translate("button_text_send") }
                                        className={"signup-panel-profile-button "}
                                        promise={ () => requestResetPassword( email ) }
                                        onResult={ result => {
                                            alert("Ti è stata inviata una email all'indirizzo "+ email );
                                            setTimeout( function() { props.next() }, 5000)
                                        }}
                                        onError={ error => {
                                            alert("C'è stato un problema nell'invio della email");
                                            setTimeout( function() { props.next() }, 5000)
                                        }}
                                    />
                                </div>

                            </AvForm>

                        </div>
                    </div>



                </div>
                <div className={"sign-landing-page-bottom small-screen"}>
                    <ARBLogoComponent whiteLogo={ true } />
                </div>

            </React.Fragment>
        );
    }

    // if(!!loginForm) {
    if(  !!props.step && (props.step === SIGNUP_STEP.LOGIN)  ) {
        return (
            <React.Fragment>

                <div className={"signup-panel-profile-logo-container small-screen"}>
                    {/*}
                    <div style={{ minHeight: "130px" }}/>
                    */}
                    <img
                        src={SiRoadLogoWhiteText}
                        alt={"SI Road"}
                        id={"siroad-logo-small-screen"}
                        width={"130px"}
                    />
                    <div className={"signup-panel-profile-title"}>{ translate("welcome_again_label") }</div>
                </div>

                <div className={"panel small-screen"}>
                    <div className={"signup-panel-profile-container"}>

                        {
                            (!!data.errorMessage)
                                ? (
                                    <ErrorMessageModalComponent
                                        message={ data.errorMessage }
                                        noheader={true}
                                        onClose={() => {

                                            if(!!data) {
                                                let newData = cloneObject( data );
                                                delete newData.errorMessage;
                                                setData( newData );
                                            }

                                        }}
                                    />
                                )
                                : null
                        }


                        <div
                            className={"signup-panel-profile-element"}
                            id={"signup-panel-profile-form"}
                        >
                            <AvForm
                                className={"signup-panel-profile-form-container"}
                                onValidSubmit={(event, values) => {
                                    showlog("onValidSubmit");
                                    showlog(event);
                                    showlog(values);
                                }}
                            >
                                {/* -------------------------------------------------------------------------- */}
                                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                    <AvInput
                                        type={"email"}
                                        name={"email"}
                                        id={"signupProfile_email"}
                                        className={"signupInput"}
                                        label={""}
                                        value={data.email}
                                        defaultValue={data.email}
                                        placeholder={"mail"}
                                        onChange={changeEvent => {
                                            setData({...data, email: changeEvent.target.value});
                                        }}
                                        disabled={false}
                                        required
                                    />
                                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                </AvGroup>

                                {/* -------------------------------------------------------------------------- */}
                                <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                    <AvInput
                                        type={"password"}
                                        name={"password"}
                                        id={"signupProfile_password"}
                                        className={"signupInput"}
                                        label={""}
                                        value={data.password}
                                        defaultValue={data.password}
                                        placeholder={"Password"}
                                        onChange={changeEvent => {
                                            setData({...data, password: changeEvent.target.value});
                                        }}
                                        disabled={false}
                                        required
                                    />
                                    <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                </AvGroup>

                                <div className={"signup-panel-company-button-container"}>
                                    <ActionButton
                                        visible={true}
                                        buttonText={ translate("accessBigText") }
                                        className={"signup-panel-profile-button"}
                                        promise={() => {

                                            if( !data.password ) {
                                                return Promise.reject("Manca la Password");
                                            }

                                            return (
                                                new Promise((success, fail) => {

                                                    loginService(data.email, data.password)
                                                        .then(userData => {

                                                            success({...data, ...userData});

                                                        })
                                                        .catch(e => {
                                                            errorlog("Auth login error", e);		// non gestita lato server

                                                            let errorMessage = JSON.stringify(e.message || e.error.msg);
                                                            if( !!e.error ) {
                                                                if( !!e.error.message ) errorMessage = e.error.message;
                                                            }

                                                            fail( errorMessage );

                                                        })
                                                    ;

                                                })
                                            )


                                        }}
                                        onProgress={progress => {

                                        }}
                                        onResult={data => {

                                            window.localStorage.setItem("language", data.language || LANGUAGES[0].code)
                                            setData({
                                                ...data,
                                                username: data.email,
                                                password: data.password,
                                                // nounce: (new Date()).getTime(),
                                                loading: false,
                                                role: data.role
                                            })

                                        }}
                                        onError={error => {

                                            setData({
                                                ...data,
                                                errorMessage: error,
                                                loading: false
                                            })

                                        }}
                                    />
                                </div>
                            </AvForm>
                        </div>
                        <div className={"signup-panel-profile-element"} id={"signup-panel-profile-alternative"}>
                            {/*}
                            <span>
                                Non hai ancora un account?&nbsp;
                                <span
                                    className={"signup-link"}
                                    // className={"disabled-link"}
                                    onClick={clickEvent => {
                                        // props.onSignup();
                                        props.onNext();
                                    }}
                                >
                                    Iscriviti qui
                                </span>
                            </span>
                            */}
                            <span
                                className={"signup-link"}
                                onClick={clickEvent => {
                                    setForgotPasswordForm( true );
                                    // props.onNext();
                                }}
                            >
                                { translate("forgot_password_question") }
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"sign-landing-page-bottom small-screen"}>
                    <ARBLogoComponent whiteLogo={ true } />
                    {/*
                    <span>powered by</span>
                    <img
                        src={ ARB_SPA_W }
                        id={"arbsbpa-logo"}
                        alt={"ARB SPA"}
                        onClick={ clickEvent => { window.location.href = "https://arbspa.it"; }}
                    />
                    */}
                </div>

            </React.Fragment>
        );
    }

    return (
        <div className={"sign-landing-page small-screen"}>
            <div className={"top"}>
                {/*}
                <div style={{ minHeight: "95px" }}/>
                */}
                <img src={SiRoadLogoWhiteText} alt={"SI Road"}/>
                <div id={"sign-landing-page-title"}>{ decodeMarkDown( translate("landingPageWelcomeInSiRoad"), {noSpan: true} ) }</div>
            </div>
            <div className={"middle"}>
                <div className={"large-block"}>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_01"), {noSpan: true} ) }</p>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_02"), {noSpan: true} ) }</p>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_03"), {noSpan: true} ) }</p>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_04"), {noSpan: true} ) }</p>

                </div>
                <div>
                    <img src={siratingw} alt={"SI Rating"} id={"sirating-logo"}/>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_01"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_02"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    <img
                        // src={PRODUCTION_HOST + "/assets/Loghi/Si_Scoring.png"}
                        src={ siscoringlogo_w }
                        alt={"SI Scoring"}
                        id={"siscoring-logo"}
                    />
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_01"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_02"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    <img src={srtoolw} alt={"SR Tool"} id={"srtool-logo"}/>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_03"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_04"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    <img
                        src={GG}
                        alt="Parità di genere"
                        id={"gender-gap-logo"}
                    />
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_03"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_04"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_05"), {noSpan: true} ) }</p>
                    </div>




                </div>

                <div className={"sign-landing-page-bottom small-screen"}>
                    <ARBLogoComponent whiteLogo={ true } />
                </div>

            </div>


            <div className={"bottom"}>
                <ActionButton
                    visible={true}
                    buttonText={ translate("accessBigText") }
                    className={"signup-panel-profile-button  signup-panel-profile-button-landing"}
                    promise={() => {
                        return Promise.resolve();
                    }}
                    onProgress={progress => {

                    }}
                    onResult={result => {
                        // setLoginForm(true);
                        props.onNext();
                        // props.onNext();
                    }}
                    onError={error => {

                    }}
                />
            </div>



        </div>
    )
}


const SignLandingTinyPage = props => {
    const [loginForm, setLoginForm] = useState();
    const [data, setData] = useState({});
    const [forgotPasswordForm, setForgotPasswordForm] = useState();
    const [email, setEmail] = useState();
    const [language, setLanguage] = useState(
        LANGUAGES

            // ATTENZIONE temporaneo
            .filter( lang => {

                    if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
                    if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
                    return true;

            })

            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )
            .reduce( getLastElementOr, LANGUAGES[0] )
    );


    renderlog("SignLandingTinyPage", {loginForm, data}, props);


    if(!!forgotPasswordForm) {
        return (
            <div id="sign-landing-page" className={"sign-landing-page tiny-screen"}>
                <div className={"top"} id={"top-tinyScreen"}>

                    <div className={"landing-page-comander signin tiny-screen"}>
                    {/* tolto
                        <LanguageSelector
                            onChange={ language => {
                                setLanguage( language );
                            }}
                        />
                        */}

                    </div>

                    <div className={"signup-panel-profile-logo-container tiny-screen"}>
                        {/*}
                        <img
                            src={SiRoadLogoWhiteText}
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"130px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-title"}>{ translate("welcome_again_label") }</div>
                    </div>

                    <div className={"panel tiny-screen"}>
                        <div className={"signup-panel-profile-container"}>
                            <div
                                className={"signup-panel-profile-element"}
                                id={"signup-panel-profile-form"}
                            >

                                <AvForm
                                    className={"signup-panel-profile-form-container"}
                                    onValidSubmit={(event, values) => {
                                        showlog("onValidSubmit");
                                        showlog(event);
                                        showlog(values);
                                    }}
                                >
                                    {/* -------------------------------------------------------------------------- */}
                                    <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                        <AvInput
                                            type={"email"}
                                            name={"email"}
                                            id={"signupProfile_email"}
                                            className={"signupInput"}
                                            label={""}
                                            value={email}
                                            defaultValue={email}
                                            placeholder={"Email"}
                                            onChange={changeEvent => {
                                                setEmail( changeEvent.target.value );
                                            }}
                                            disabled={false}
                                            required
                                        />
                                        <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                    </AvGroup>


                                </AvForm>

                            </div>


                            <div className={"signup-panel-company-button-container"}>
                                <ActionButton
                                    color={"#d00"}
                                    className={"signup-panel-company-button-disabled"}
                                    buttonText={ translate("button_text_cancel") }
                                    promise={ () => {
                                        return Promise.resolve();
                                    }}
                                    onResult={ result => {
                                        setForgotPasswordForm( false );
                                    }}
                                />
                                <ActionButton
                                    visible={ !!email && !!(email.match( EMAIL_REGEX )) }
                                    buttonText={ translate("button_text_send") }
                                    className={"signup-panel-profile-button "}
                                    promise={ () => requestResetPassword( email ) }
                                    onResult={ result => {
                                        alert( translate("sent_email_alert_message") + email );
                                        setTimeout( function() { props.next() }, 5000)
                                    }}
                                    onError={ error => {
                                        alert( translate("sent_email_error_message") );
                                        setTimeout( function() { props.next() }, 5000)
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={"sign-landing-page-bottom tiny-screen"}>
                        <ARBLogoComponent whiteLogo={ true } />
                    </div>

                </div>

                <div className={"bottom step2"}>



                </div>

            </div>
        );
    }

    // if(!!loginForm) {
    if(  !!props.step && (props.step === SIGNUP_STEP.LOGIN)  ) {
        return (
            <div id="sign-landing-page" className={"sign-landing-page tiny-screen"}>
                <div className={"top"} id={"top-tinyScreen"}>


                    <div className={"landing-page-comander signin tiny-screen"}>
                    {/* tolto
                        <LanguageSelector
                            onChange={ language => {
                                setLanguage( language );
                            }}
                        />
                        */}

                    </div>


                    <div className={"signup-panel-profile-logo-container tiny-screen"}>
                        {/*}
                        <img
                            src={SiRoadLogoWhiteText}
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"130px"}
                        />
                        */}
                        <div style={{ minHeight: "130px" }}/>
                        <div className={"signup-panel-profile-title"}>{ translate("welcome_again_label") }</div>
                    </div>

                    <div className={"panel tiny-screen"}>
                        <div className={"signup-panel-profile-container"}>

                            {
                                (!!data.errorMessage)
                                    ? (
                                        <ErrorMessageModalComponent
                                            message={ data.errorMessage }
                                            noheader={true}
                                            onClose={() => {

                                                if(!!data) {
                                                    let newData = cloneObject( data );
                                                    delete newData.errorMessage;
                                                    setData( newData );
                                                }

                                            }}
                                        />
                                    )
                                    : null
                            }


                            <div
                                className={"signup-panel-profile-element"}
                                id={"signup-panel-profile-form"}
                            >
                                <AvForm
                                    className={"signup-panel-profile-form-container"}
                                    onValidSubmit={(event, values) => {
                                        showlog("onValidSubmit");
                                        showlog(event);
                                        showlog(values);
                                    }}
                                >
                                    {/* -------------------------------------------------------------------------- */}
                                    <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                        <AvInput
                                            type={"email"}
                                            name={"email"}
                                            id={"signupProfile_email"}
                                            className={"signupInput"}
                                            label={""}
                                            value={data.email}
                                            defaultValue={data.email}
                                            placeholder={"Email"}
                                            onChange={changeEvent => {
                                                setData({...data, email: changeEvent.target.value});
                                            }}
                                            disabled={false}
                                            required
                                        />
                                        <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                    </AvGroup>

                                    {/* -------------------------------------------------------------------------- */}
                                    <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                        <AvInput
                                            type={"password"}
                                            name={"password"}
                                            id={"signupProfile_password"}
                                            className={"signupInput"}
                                            label={""}
                                            value={data.password}
                                            defaultValue={data.password}
                                            placeholder={"Password"}
                                            onChange={changeEvent => {
                                                setData({...data, password: changeEvent.target.value});
                                            }}
                                            disabled={false}
                                            required
                                        />
                                        <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                                    </AvGroup>

                                    {/*}
                                <div className={"signup-panel-company-button-container"}>

                                </div>
                                */}
                                </AvForm>

                                <span
                                    className={"signup-link"}
                                    onClick={clickEvent => {
                                        setForgotPasswordForm( true );
                                    }}
                                >
                                { translate("forgot_password_question") }
                            </span>

                            </div>

                        </div>
                    </div>
                    <div className={"sign-landing-page-bottom tiny-screen"}>
                        <ARBLogoComponent whiteLogo={ true } />
                    </div>

                </div>

                <div className={"bottom step2"}>
                    <ActionButton
                        visible={true}
                        buttonText={ translate("accessBigText") }
                        className={"signup-panel-profile-button tiny-screen-access-button"}
                        promise={() => {



                            if( !data.password ) {
                                return Promise.reject("Manca la Password");
                            }

                            return (
                                new Promise((success, fail) => {


                                    loginService(data.email, data.password)
                                        .then(userData => {

                                            success({...data, ...userData});

                                        })
                                        .catch(e => {
                                            errorlog("Auth login error", e);		// non gestita lato server

                                            let errorMessage = JSON.stringify(e.message || e.error.msg);
                                            if( !!e.error ) {
                                                if( !!e.error.message ) errorMessage = e.error.message;
                                            }

                                            fail( errorMessage );

                                        })
                                    ;

                                })
                            )


                        }}
                        onProgress={progress => {

                        }}
                        onResult={data => {

                            window.localStorage.setItem("language", data.language || LANGUAGES[0].code)
                            setData({
                                ...data,
                                username: data.email,
                                password: data.password,
                                // nounce: (new Date()).getTime(),
                                loading: false,
                                role: data.role
                            })

                        }}
                        onError={error => {

                            setData({
                                ...data,
                                errorMessage: error,
                                loading: false
                            })

                        }}
                    />


                </div>

            </div>
        );
    }

    return (
        <div className={"sign-landing-page tiny-screen"}>
            <div className={"top"}>
                {/*}
                <div style={{ minHeight: "95px" }}/>
                */}
                <img src={SiRoadLogoWhiteText} alt={"SI Road"}/>
                <div id={"sign-landing-page-title"}>{ decodeMarkDown( translate("landingPageWelcomeInSiRoad"), {noSpan: true} ) }</div>
                {/*</div>*/}
                {/*<div className={"middle"}>*/}

                <div className={"landing-page-comander tiny-screen"}>

                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                    />

                </div>

                <div className={"large-block"}>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_01"), {noSpan: true} ) }</p>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_02"), {noSpan: true} ) }</p>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_03"), {noSpan: true} ) }</p>
                    <p>{ decodeMarkDown( translate("landingPage_desktop_column_01_04"), {noSpan: true} ) }</p>
                </div>
                <div>
                    <img src={siratingw} alt={"SI Rating"} id={"sirating-logo"}/>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_01"), {noSpan: true} ) }</p>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_02"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    <img
                        // src={PRODUCTION_HOST + "/assets/Loghi/Si_Scoring.png"}
                        src={ siscoringlogo_w }
                        alt={"SI Scoring"}
                        id={"siscoring-logo"}
                    />
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_01"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_02"), {noSpan: true} ) }</p>
                    </div>
                </div>
                <div>
                    {/*<img src={srtoolw} alt={"SR Tool"} id={"srtool-logo"}/>*/}
                    {/*<div>*/}
                    {/*    <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_03"), {noSpan: true} ) }</p>*/}
                    {/*    <p>{ decodeMarkDown( translate("landingPage_desktop_column_02_04"), {noSpan: true} ) }</p>*/}
                    {/*</div>*/}
                    {/*---- rimozione srTool scadenza GRI                    */}
                </div>
                <div>
                    <img
                        src={GG}
                        alt="Parità di genere"
                        id={"gender-gap-logo"}
                    />
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_03"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_04"), {noSpan: true} ) }</p>
                    </div>
                    <div>
                        <p>{ decodeMarkDown( translate("landingPage_desktop_column_03_05"), {noSpan: true} ) }</p>
                    </div>
                </div>

                <div className={"sign-landing-page-bottom tiny-screen"}>
                    <ARBLogoComponent whiteLogo={ true } />
                </div>

            </div>


            <div className={"bottom"}>
                <ActionButton
                    visible={true}
                    buttonText={ translate("accessBigText") }
                    className={"signup-panel-profile-button signup-panel-profile-button-landing tiny-screen-access-button"}
                    promise={() => {

                        window.scrollTo(0, 0);
                        setTimeout(
                            () => document.getElementById("top-tinyScreen").scrollTo(0, 0),
                            50
                        )
                        return Promise.resolve();
                    }}
                    onProgress={progress => {

                    }}
                    onResult={result => {
                        // setLoginForm(true);
                        props.onNext();
                        // props.onNext();
                    }}
                    onError={error => {

                    }}
                />
            </div>



        </div>
    )
}








