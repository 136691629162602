import {TREE_TYPE} from "../../../../env";
import React from "react";
import {Button, Col, Row} from "reactstrap";
import TreeGriCollectDataEntryComponent from "./value-tree-step";
import TreeGriCollectMaterialThemesComponent from "../tree-material-themes-component";


export const MaterialThemesStep = props => (
	<React.Fragment>
		<Row>
			<Col>
				<div className="page-title-box">
					<div className="page-title-right">
						<Button
							color={"primary"}
							onClick={ clickEvent => {
								props.previousStep();
							}}
						>
							Indietro
						</Button>
						<span>&nbsp;&nbsp;</span>
						<Button
							color={"primary"}
							onClick={ clickEvent => {
								props.nextStep();
							}}
						>
							Avanti
						</Button>
					</div>
				</div>
			</Col>
		</Row>
		
		<Row>
			<Col>
				<TreeGriCollectMaterialThemesComponent
					tree={ props.tree }
					seed={ props.seed }
					collect={ props.collect }
					fullDataCollect={ props.fullDataCollect }
				/>
			
			</Col>
		</Row>
	
	</React.Fragment>
);
