import React from "react";
import "./note-icon-svg-component.css";

export const AdminNoteIconSvgComponent = props => (
    <div
        id={ props.id }
        // className={"missing-goal-svg-container "+ props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
            if( typeof props.onClick === "function" ) props.onClick();
        }}
    >
        <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 97.68">
            <path className="nisc-2"
                  d="M90.05,37.15a33.19,33.19,0,0,1,.73-5.85c.37-1.93.2-2.36-1.72-2.89L77.83,25.36a8.31,8.31,0,0,1,1.54-1.61,7.5,7.5,0,0,0,4.14-8.17,7.63,7.63,0,0,0-6-5.89,7.54,7.54,0,0,0-8,4.19c-.21.39-.51.91-.86,1-3.38.82-5.32,3.06-6.3,6.31L61.68,21q-10-2.68-20-5.37c-2-.53-2.57-.21-3.08,1.69Q31.76,42.82,25,68.27c-.48,1.81-.2,2.28,1.64,2.78,3.09.86,6.19,1.69,9.36,2.56,0,4.67,0,9.25,0,13.83a1.87,1.87,0,0,0,0,.34,2.63,2.63,0,0,0,2.62,2H86.91A3,3,0,0,0,90,86.94v0C90,70.32,90,53.74,90.05,37.15ZM76.19,12.79A4.3,4.3,0,0,1,80.42,17a4.24,4.24,0,0,1-4.09,4.27A4.31,4.31,0,0,1,72,17,4.35,4.35,0,0,1,76.19,12.79Zm-7.37,5.45A7.86,7.86,0,0,0,75,24.42c-1.05,2.4-3.14,3.7-5.3,3.35a5.1,5.1,0,0,1-4.29-4.51A4.92,4.92,0,0,1,68.82,18.24Zm17.86,68.3H39.24V74.47l17.84,4.76c2.41.64,4.79,1.4,7.23,1.91a4.45,4.45,0,0,0,2.79-.36c3.7-2,7.35-4.13,11-6.33a4.13,4.13,0,0,0,1.69-2.21C81.9,64.48,84,56.68,86.05,48.9c.12-.44.27-.87.4-1.3l.23,0Zm-18.51-10c.41-1.61.76-2.93,1.16-4.47l4.52,1.17Zm8.72-5.82-7.55-2c-1.81-.47-2.37-.18-2.86,1.59-.69,2.5-1.35,5-2,7.6L28.29,68.21C32.69,51.77,37.06,35.44,41.48,19L53.9,22.28c1.81.49,3.6,1.08,5.43,1.43,2.15.4,3.8,1.21,4.07,3.77-.49,1.11-3,2-1.33,3.67s2.56-.75,3.86-1.42c3.52,1.85,6.92,1.77,10.07-1a1.28,1.28,0,0,1,1-.29c3.45.9,6.9,1.85,10.47,2.82C83.91,44.5,80.42,57.54,76.89,70.7Z"/>
            <rect className="nisc-2" x="41.17" y="45.87" width="33.4" height="3.46" rx="1.69"
                  transform="translate(13.09 -12.48) rotate(13.87)"/>
            <rect className="nisc-2" x="39.98" y="53.43" width="20.9" height="3.71" rx="1.81"
                  transform="translate(14.72 -10.47) rotate(13.87)"/>
        </svg>
    </div>
);
