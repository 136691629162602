import {
	GetAssociatedSector,
	associationEvaluationSectorQuestion_POST,
	getSectorQuestionEvaluationsLink,
	putIsOnlyMainSector,
	SectorQuestions_Get,
	SectorQuestionsCompanies_Get
} from "./request-manager";


export const getSectorQuestionsDimensions = () => {
	return (
		Promise.resolve([
			{ id: 0, name: "Tutte" },
			{ id: 1, name: "Piccole e Medie" },
			{ id: 2, name: "Grandi" }
		])
	);
}



export const getQuestionStatus = () => {
	return (
		Promise.resolve([
			{ id: 0,  name: "Domanda non ancora validata" },
			{ id: 5,  name: "" },
			{ id: 10, name: "Domanda validata" },
			{ id: 20, name: "Info Aggiuntive" },
			{ id: 25, name: "Info Aggiuntive in attesa di validazione" },
			{ id: 30, name: "NC Grave" }
		])
	)
};



export const loadAllSectorCompanies = id => {
	return SectorQuestionsCompanies_Get( id );
}

export const putSectorQuestionMain = (obj, id)=> {
	return putIsOnlyMainSector(obj, id)
}

export const loadAllSectorQuestion = () => SectorQuestions_Get();

export const getAssociatedEvaluations = id => getSectorQuestionEvaluationsLink(id, 0);

export const getAssociatedSectorQuestions = id => getSectorQuestionEvaluationsLink(0, id);

export const getSectorQuestionsEvaluationsLink = (sectorQuestionId, evaluationId) => getSectorQuestionEvaluationsLink(sectorQuestionId, evaluationId);


export const associationEvaluationSectorQuestion = ( secID, evID, IsAssociated ) => associationEvaluationSectorQuestion_POST( secID, evID, IsAssociated );

export const getAssociatedSectors = id => GetAssociatedSector( id );