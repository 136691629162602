import React from "react";
import "./edit-structure-svg-icon-component.css";

export const EditStructureSvgIconComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a"/>
            <g id="b">
                <g>
                    <rect className="edit-structure-icon-c" x="50.86" y="14.71" width="22.56" height="22.56" rx="3.14" ry="3.14"/>
                    <rect className="edit-structure-icon-c" x="11.07" y="73.91" width="22.56" height="22.56" rx="3.14" ry="3.14"/>
                    <rect className="edit-structure-icon-c" x="51.1" y="73.91" width="22.56" height="22.56" rx="3.14" ry="3.14"/>
                    <rect className="edit-structure-icon-c" x="91.12" y="73.91" width="22.56" height="22.56" rx="3.14" ry="3.14"/>
                    <line className="edit-structure-icon-c" x1="62.37" y1="37.27" x2="62.37" y2="73.91"/>
                    <path className="edit-structure-icon-c"
                          d="M22.35,73.91v-14.39c0-2.17,1.76-3.93,3.93-3.93H98.47c2.17,0,3.93,1.76,3.93,3.93v14.39"/>
                </g>
            </g>
        </svg>
    </div>
);
