import React from "react";
import {Redirect, Route} from 'react-router-dom';
import {PATH_PREFIX, PRODUCTION_HOST, role} from "../env";
import ErrorPageNotFound from "../pages/error/PageNotFound";
import TestPage from "../pages/test-page";
import PermissionsPage from "../pages/permissions-page";
import EmojPage from "../pages/emoj-page";
import WelcomePage from "../pages/welcome-page";
import SupportPage from "../pages/support-page";
import AboutPage from "../pages/about-page";
import ContactPage from "../pages/contact-page";
import SIRatingChartPage from "../pages/area/super-admin/rating-charts";
import EditPasswordPage from "../pages/profile/edit-password-page";
import {GriAdminCollectsPage, GriAdminUsersPage} from "../pages/area/gri-admin";
// import {GriAdminCollectsPage} from "../pages/area/gri-admin";
// import GriAdminUsersPage from "../pages/area/gri-admin";
// import SRToolBaseChartPage from "../pages/area/gri-admin";
import SRToolBaseChartPage from "../pages/area/gri-admin/dashboard/gri-admin-dashboard";


import TreePage from "../pages/area/gri-admin/tree-page";
import SRToolManagerPage from "../pages/area/gri-manager/tree-manager-page";
import {AdminCollectsPage, AdminUsersPage} from "../pages/area/admin";
import {SuperAdminUsersPage} from "../pages/area/super-admin";
import SuperAdminCompanies from "../pages/area/super-admin/companies";
import RatingPdfParametersPage from "../pages/area/super-admin/rating-pdf-parameters-page";
import SIRatingPage from "../pages/area/super-admin/si-rating-page";
import {isUserRole} from "../business-logic/auth";
import SIRatingSectorQuestioPage from "../pages/area/super-admin/si-rating-sector-question-page";
import SIRatingEvalutationPage from "../pages/area/super-admin/si-rating-evaluation-page";
import SuperAdminAssignToConsuler from "../pages/area/super-admin/assign-to-consuler-page";
import ConsulerCompanies from "../pages/area/consuler/companies";
import SiRatingAdminEvaluationsPage from "../pages/area/si-rating-admin/si-rating-admin-all-evaluations-page";
import SiRatingAdminStatusLegendColorPolicyPage
    from "../pages/area/si-rating-admin/si-rating-admin-status-legend-color-policy-page";
import SiRatingAdminSingleEvaluationPage from "../pages/area/si-rating-admin/si-rating-admin-single-evaluation-page";
import {getLoggedInUser, isUserAuthenticated} from "../helpers/authUtils";
import {showlog} from "../utils/dev-utils";
import SIRatingPerimetralQuestionPage from "../pages/area/si-rating-admin/si-rating-admin-perimetral-question-page";
import {SiRatingAdminEupaPage} from "../pages/area/si-rating-admin/si-rating-admin-eupa-page";
import SIRatingAdminChartPage from "../pages/area/si-rating-admin/dashboard";
import {
    SIRatingAdminComparationChartPage
} from "../pages/area/si-rating-admin/dashboard/sirating-admin-comparation-chart-page";
import SIRatingReadOnlyEvalutationPage from "../pages/area/super-admin/si-rating-evaluation-readonly-page";
import RequirementsFilter from "../components/area/super-admin/requirements-filter";
import {onlyNotNull} from "../utils/array-utils";
import {SRToolMenuLogoSvgComponent} from "../components/svg/srtool-menu-logo-svg-component";
import {SiRatingMenuLogoSvgComponent} from "../components/svg/sirating-menu-logo-svg-component";
import QuestionReport from "../components/area/super-admin/questionReport-modal";
import CredentialsPage from "../pages/credentials-page";
import OrganizzationPage from "../pages/area/super-admin/organizzations-page";
import ProjectManagerActiveService from "../pages/area/super-admin/active-services";
import SuperAdminActiveService from "../pages/area/super-admin/super-admin-active-services-page";
import SuperAdminRatingDashboard from "../pages/area/super-admin/super-admin-rating-dashboard";
import AdminActiveService from "../pages/area/admin/admin-active-services-page";
import SRToolSuperAdminDashboardPage from "../pages/area/super-admin/dashboard/super-admin-dashboard";
import TestPowerBiSRTool from "../pages/test-pbi-srtool";
import TestPowerBiSiRating from "../pages/test-pbi-sirating";
import AdminWelcomePage from "../pages/admin-welcome-page";
import EditQuestionSample from "../pages/edit-question-sample";
import EditQuestionPage from "../pages/edit-question";
import TestPublicPage from "../pages/area/super-admin/rating-charts/test-public-page";

import {SiScoringMenuLogoSvgComponent} from "../components/svg/si-scoring-menu-logo-svg-component";
import AllScoringPage from "../pages/area/admin/siscoring/all-scoring-page";
import SiScoringEvaluationPage from "../pages/area/admin/siscoring/si-scoring-evaluation-page";
import SiScoringPdfPage from "../pages/pdf/siscoring/siscoring-pdf-page";
import GenderGapEvaluations from "../pages/area/super-admin/gender-gap-evaluations";
import GenderGapSingleEvaluationPage from "../pages/area/super-admin/genger-gap-evaluation-page";
import GenderGapConfigurationPage from "../pages/area/super-admin/gender-gap-configuration-page";
import SignupPage from "../pages/area/public/signup";
import EditSiScoringQuestionPage from "../pages/edit-siscoring-question";
import {GenderGapIconSvgComponent} from "../components/svg/gender-gap-icon-svg-component";
import {OnlyTextEmail} from "../pages/area/public/only-text-email";
import {TableToTreeTestPage} from "../pages/area/public/table-to-tree-test-page";
import {translate} from "../components/i18n/translate-function";
import SrToolEditPage from "../pages/area/gri-admin/sr-tool-edit-page";

import userGuideManual from "../assets/images/SI_Scoring_user_guide.png";
import siratingSectorQuestionLinksPage from "../components/area/sirating/sirating-sector-question-links-page";
import PrivacyPolicyPage from "../pages/privacy-policy-page";
import SectorQuestionsManagement from "../pages/area/super-admin/sector-questions-management";
import CookiePolicyPage from "../pages/cookie-policy-page";


const Login = React.lazy(() =>
    import('../pages/auth/Login'));
const Logout = React.lazy(() =>
    import('../pages/auth/Logout'));



const PrivateRoute = ({ component: Component, roles, ...rest }) => (<
    Route {...rest}
    render={
        props => {

            if (!isUserAuthenticated()) {

                showlog("vado al login");

                // not logged in so redirect to login page with the return url
                return <Redirect to={
                    { pathname: '/account/login', state: { from: props.location } }}
                />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={
                    { pathname: "/" + PATH_PREFIX }}
                />;
            }

            // authorised so return component
            // showlog("set the routes");
            // showlog( localStorage.getItem("role") );


            // refreshToken();

            return <Component {...props}
            />;
        }
    }
/>
);



const PublicRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={ props => (<Component {...props} />) }
    />
);



export const authRoutes = {
    path: PATH_PREFIX + '/account',
    name: 'Auth',
    children: [{
        path: PATH_PREFIX + '/account/login',
        name: 'Login',
        // component: Login,
        component: SignupPage,
        route: Route,
    },
    {
        path: PATH_PREFIX + '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
        exact: true
    }
    ],
};


// root routes
export const rootRoute = {
    path: '/' + PATH_PREFIX,
    exact: true,
    component: (
        (isUserRole(role.ADMIN))
            ? AdminWelcomePage
            : WelcomePage
    ),
    route: PrivateRoute,
};


// welcomePage
export const welcomeRoutes = {
    path: PATH_PREFIX + "/welcome",
    name: 'Welcome',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: (
        (isUserRole(role.ADMIN) || isUserRole( role.SUPER_ADMIN ) || isUserRole(role.SRTool_ADMIN) || isUserRole(role.SR_ADMIN))
            ? AdminWelcomePage
            : WelcomePage
    ),
    exact: true
    // header: 'Welcome',
}

// credentials
export const credentialsRoutes = {
    path: PATH_PREFIX + "/credentials",
    name: 'Credentials',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-info-circle ',
    component: CredentialsPage,
    exact: true
    // header: 'Welcome',
}


// Not Found
export const notFoundRoute = {
    roles: Object.keys(role).map(r => role[r].name),
    component: ErrorPageNotFound,
    route: PrivateRoute,
    name: "404",
    // path: PATH_PREFIX +'/xxx',
};




// TestPage
export const testPageRoutes = {
    path: PATH_PREFIX + '/test-test',
    name: 'Welcome',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: TestPage,
    exact: true
    // header: 'Welcome',
}


export const testPowerBiPageRoutes = {
    path: PATH_PREFIX + '/test-pbi-srtool',
    name: 'Test',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    // component: TestPowerBiSRTool,
    component: TestPowerBiSRTool, // TestPowerBiSRTool, //TestPowerBiPage2,
    exact: true
}



export const testEditQuestionRoutes = {
    path: PATH_PREFIX + '/test-edit-question',
    name: 'Test',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    // component: TestPowerBiSRTool,
    component: EditQuestionSample,
    exact: true
}



export const editQuestionRoutes = {
    path: PATH_PREFIX + '/sirating/' + translate("edit-question"),
    name: 'Edit Question',
    route: PrivateRoute,
    roles: [ role.PROJECT_MANAGER.name ],
    icon: 'uil-calender',
    component: EditQuestionPage,
    exact: true
}



export const powerBiSiratingRoute = {
    // path: PATH_PREFIX + '/test-pbi-sirating',
    path: PATH_PREFIX + '/super-admin-dashboard',
    name: 'Test',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    // component: TestPowerBiSRTool,
    component: TestPowerBiSiRating, // TestPowerBiSRTool, //TestPowerBiPage2,
    exact: true
}


/**
 * /permissions
 * Pagina che illustra tutti i permessi del roulo dell'utente corrente
 *
 * @type {{path: string, component: PermissionsPage, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const permissionPageRoutes = {
    path: PATH_PREFIX + '/permissions',
    name: 'Welcome',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: PermissionsPage,
    exact: true
    // header: 'Welcome',
}

/**
 * /emoj
 * lista delle icone
 *
 * @type {{path: string, component: EmojPage, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const emojPageRoutes = {
    path: PATH_PREFIX + '/emoj',
    name: 'EmoJ',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-star',
    component: EmojPage,
    exact: true
    // header: 'Welcome',
}


/**
 * /support
 * pagina di supporto
 *
 * @type {{path: string, component: SupportPage, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const supportRoutes = {
    path: PATH_PREFIX + '/support',
    name: 'Supporto',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: SupportPage,
    exact: true
    // header: 'Welcome',
}

/**
 * /privacy-policy
 * pagina privacy policy
 *
 * @type {{path: string, component: PrivacyPolicy, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const privacyPolicyRoutes = {
    path: PATH_PREFIX + '/privacy-policy',
    name: 'Privacy Policy',
    route: PublicRoute,
    component: PrivacyPolicyPage,
    exact: true
}

/**
 * /privacy-policy
 * pagina privacy policy
 *
 * @type {{path: string, component: CookiePolicyPage, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const cookiePolicyRoutes = {
    path: PATH_PREFIX + '/cookie-policy',
    name: 'Cookie Policy',
    route: PublicRoute,
    component: CookiePolicyPage,
    exact: true
}


/**
 * /about
 * pagina di descrizione
 *
 * @type {{path: string, component: SupportPage, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const aboutRoutes = {
    path: PATH_PREFIX + '/about',
    name: 'About',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: AboutPage,
    exact: true
    // header: 'Welcome',
}


/**
 * /contactus
 * pagina dei contatti
 *
 * @type {{path: string, component: SupportPage, route: (function({component: *, roles?: *, [p: string]: *})), roles: *[], name: string, icon: string, exact: boolean}}
 */
export const contactusRoutes = {
    path: PATH_PREFIX + '/contactus',
    name: 'Contactus',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: ContactPage,
    exact: true
    // header: 'Welcome',
}


export const siratingChartsRoutes = {
    path: PATH_PREFIX + '/test/sirating-charts',
    name: 'sirating-charts',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'uil-calender',
    component: SIRatingChartPage,
    exact: true
}



export const editPasswordRoutes = {
    path: PATH_PREFIX + '/edit-password',
    name: 'Edit Password',
    component: EditPasswordPage,
    route: PrivateRoute,
    exact: true
};


export const griAdminAreaUsersRoute = {
    path: PATH_PREFIX + '/srtool/' +  translate("users"),
    name: translate("Users"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name
        , role.ADMIN.name
        , role.CONSULTANT.name
        , role.SRTool_ADMIN.name
    ],
    icon: 'uil-user',
    component: GriAdminUsersPage,
    callBack: clickEvent => {
        showlog("callBack di SRTool");
    },
    exact: true
    // header: 'Welcome',
}


export const griAdminAreaCollectsRoute = {
    path: PATH_PREFIX + '/srtool',
    name: 'SR Tool',
    roles: [
        role.PROJECT_MANAGER.name
        , role.ADMIN.name
        , role.CONSULTANT.name
        , role.SRTool_ADMIN.name
        , role.SRTool_MANAGER.name
        , role.SUPER_ADMIN.name
        , role.ASSURANCER.name
    ],
    icon: "mdi mdi-view-sequential", //'uil-user',
    logo: <SRToolMenuLogoSvgComponent /> ,
    exact: true,
    header: ' ',
    children: [
        // ( !!isUserRole( role.SRTool_ADMIN ))
        //     ? ({
        //         path: PATH_PREFIX +'/srtool-admin-dashboard',
        //         name: 'Dashboard',
        //         route: PrivateRoute,
        //         roles: [ role.SRTool_ADMIN.name ],
        //         icon: 'dripicons-graph-pie',
        //         component: SRToolBaseChartPage,
        //         exact: true
        //     })
        //     : null
        // ,

/*
        (
            !isUserRole( role.SUPER_ADMIN )
            && !isUserRole( role.PROJECT_MANAGER )
            && !isUserRole( role.SRTool_MANAGER )
            && !isUserRole( role.ARB_OPERATOR )
            && !isUserRole( role.ASSURANCER )
        )
            ? (
                {
                    path: PATH_PREFIX +'/srtool/users',
                    name: 'Utenti',
                    route: PrivateRoute,
                    roles: [
                        role.SRTool_ADMIN.name
                        // , role.PROJECT_MANAGER.name
                        // , role.SUPER_ADMIN.name
                        , role.ADMIN.name
                        , role.CONSULTANT.name
                    ],
                    icon: 'uil-user',
                    component: GriAdminUsersPage,
                    callBack: clickEvent => {
                        showlog("callBack di SRTool");
                    },
                    exact: true
                    // header: 'Welcome',
                }
            )
            : null
        ,
*/

        !!isUserRole(role.SUPER_ADMIN) ?
            ({
                path: PATH_PREFIX + '/srtool-super-admin-dashboard',
                name: 'Dashboard',
                route: PrivateRoute,
                roles: [role.SUPER_ADMIN.name],
                icon: 'dripicons-graph-pie',
                component: SRToolSuperAdminDashboardPage,
                callBack: clickEvent => {
                    showlog("callBack di SRTool");
                },
                exact: true
            }) :
            (
                (
                    (!isUserRole( role.SRTool_MANAGER ) && !isUserRole( role.ARB_OPERATOR ) && !isUserRole( role.ASSURANCER ))
                        ? (
                            {
                                path: PATH_PREFIX +'/srtool/admin-dashboard',
                                name: 'Dashboard',
                                route: PrivateRoute,
                                roles: [ role.ADMIN.name, role.SRTool_ADMIN.name, role.PROJECT_MANAGER.name ],
                                icon: 'dripicons-graph-pie',
                                component: SRToolBaseChartPage,
                                callBack: clickEvent => {
                                    showlog("callBack di SRTool");
                                },
                                exact: true
                            }
                        )
                        : null
                )

            ),

        {
            path: PATH_PREFIX + '/srtool/' + translate("collects"),
            name: translate("Collects"),
            component: GriAdminCollectsPage,
            route: PrivateRoute,
            roles: [
                role.PROJECT_MANAGER.name
                , role.ADMIN.name
                , role.CONSULTANT.name
                , role.SRTool_ADMIN.name
                , role.SRTool_MANAGER.name
                , role.SUPER_ADMIN.name
                , role.ARB_OPERATOR.name
                , role.ASSURANCER.name
            ],
            icon: "mdi mdi-view-sequential",
            callBack: clickEvent => {
                showlog("callBack di SRTool - lista di raccolte");
                window.routeObserver.path = PATH_PREFIX + '/srtool/' + translate("collects");
            },
            exact: true
        },
        (
            !!isUserRole( role.PROJECT_MANAGER )
                ? ({
                    path: PATH_PREFIX + '/srtool/' + translate("edit_srtool_url_path"),
                    name: translate("edit_srtool"),
                    component: SrToolEditPage,
                    route: PrivateRoute,
                    roles: [ role.PROJECT_MANAGER.name ],
                    icon: "mdi mdi-view-sequential",
                    callBack: clickEvent => {
                        showlog("callBack di SRTool Edit Page");

                    },
                    exact: true
                })
                : null
        )
    ]
        .filter(onlyNotNull)
}




/*
export const griAdminDashboardRoutes = {
    path: PATH_PREFIX +'/srtool-admin-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [ role.SRTool_ADMIN.name ],
    icon: 'dripicons-graph-pie',
    // component: SIRatingChartPage,
    component: SRToolBaseChartPage,
    // component: EcommerceDashboardPage,   // TODO da cambiare

    exact: true
};
*/


export const griAdminAreaTreeRoute = {
    path: PATH_PREFIX + '/srtool/' + translate("collect") + '/:id',
    name: translate("Collects"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name
        , role.ADMIN.name
        , role.CONSULTANT.name
        , role.SRTool_ADMIN.name
        , role.SRTool_MANAGER.name  // disabled
        , role.SUPER_ADMIN.name
        , role.ARB_OPERATOR.name
        , role.ASSURANCER.name
    ],
    icon: "mdi mdi-view-sequential", //'uil-user',
    component: TreePage,
    callBack: clickEvent => {
        showlog("callBack di SRTool");
    },
    exact: true
}


export const griManagerAreaTreeRoute = {
    path: PATH_PREFIX + '/srtool/' + translate("collect") + '/:id',
    name: translate("Collects"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name
        , role.ADMIN.name
        , role.CONSULTANT.name
        , role.SRTool_ADMIN.name
        , role.SRTool_MANAGER.name     // disabled
        , role.SUPER_ADMIN.name
        , role.ARB_OPERATOR.name
        , role.ASSURANCER.name
    ],
    icon: "mdi mdi-view-sequential", //'uil-user',
    component: SRToolManagerPage,
    callBack: clickEvent => {
        showlog("callBack di SRTool");
    },
    exact: true
}


export const adminAreaUsersRoute = {
    path: PATH_PREFIX + '/admin/' + translate("users"),
    name: translate("Users"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SUPER_ADMIN.name, role.SI_Scoring_Admin.name, role.Parità_Di_Genere_Admin.name
    ],
    icon: 'uil-user',
    component: AdminUsersPage,
    exact: true
    // header: 'Welcome',
};

export const adminAreaCollectsRoute = {
    path: PATH_PREFIX + '/admin/collects',
    name: translate("Collects"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name
    ],
    icon: 'mdi mdi-view-sequential',
    component: AdminCollectsPage,
    // header: 'Welcome',
    callBack: clickEvent => { },
    exact: true
}


export const adminAreaEupaRoute = {
    path: PATH_PREFIX + '/sirating-admin/' + translate("eupa") + '/:id',
    name: 'EUPA',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SUPER_ADMIN.name
    ],
    icon: 'mdi mdi-view-sequential',
    component: SiRatingAdminEupaPage,
    // header: 'Welcome',
    callBack: clickEvent => { },
    exact: true
}


export const superAdminAreaUsersRoute = {
    path: PATH_PREFIX + '/super-admin/' + translate("users"),
    name: translate("Users"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
    ],
    icon: 'uil-user',
    component: SuperAdminUsersPage,
    exact: true
    // header: 'Welcome',
};

export const superAdminLeadingRoute = {
    path: PATH_PREFIX + '/super-admin/' + translate("organizzations"),
    name: translate("Leading_companies"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name
    ],
    icon: 'uil-building',
    component: OrganizzationPage,
    exact: true
    // header: 'Welcome',
};

export const superAdminAreaCompaniesRoute = {
    path: PATH_PREFIX + '/super-admin/'+ translate("companies"),
    name: translate("Companies"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
    ],
    icon: 'uil-building',
    component: SuperAdminCompanies,
    exact: true
    // header: 'Welcome',
};

export const superAdminAreaActiveServicesRoute = {
    path: PATH_PREFIX + '/super-admin/active-services',
    name: 'Servizi Attivi',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
    ],
    icon: 'uil-clipboard-alt',
    component: SuperAdminActiveService,
    exact: true
    // header: 'Welcome',
};

/**
 * # Active Services
 * `/admin/active-services`
 *
 * Pagina con la lista dei servizi attivi
 * per un utente di tipo admin
 *
 * @type {{path: string, component: SuperAdminActiveService, route: (function({component: *, roles?: *, [p: string]: *})), roles: (string)[], name: string, icon: string, exact: boolean}}
 */
export const adminAreaActiveServicesRoute = {
    path: PATH_PREFIX + '/admin/active-services',
    name: 'Servizi Attivi',
    route: PrivateRoute,
    roles: [role.ADMIN.name],
    icon: 'uil-clipboard-alt',
    component: AdminActiveService,
    exact: true
    // header: 'Welcome',
};


export const projectManagerAreaActiveServicesRoute = {
    path: PATH_PREFIX + '/project-manager/active-services',
    name: 'Servizi Attivi',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name
    ],
    icon: 'uil-clipboard-alt',
    component: ProjectManagerActiveService,
    exact: true
    // header: 'Welcome',
};


// PATH_PREFIX +"/super-admin/si-rating/evaluations/report-parameters/:id"
export const superAdminAreaPdfParametersRoute = {
    path: PATH_PREFIX + "/super-admin/si-rating/" + translate("evaluations") + "/" + translate("report-parameters") + "/:id",
    name: 'parametri PDF',
    route: PrivateRoute,
    roles: [
        role.SUPER_ADMIN.name, role.ARB_OPERATOR.name, role.ADMIN.name, role.SR_ADMIN.name, role.PROJECT_MANAGER.name
    ],
    icon: 'uil-clipboard-alt',
    component: RatingPdfParametersPage,
    exact: true
    // header: 'Welcome',
};



// SIRating ---------------------------------------------------
export const superAdminAreaSIRatingRoute = {
    path: PATH_PREFIX + '/super-admin/si-rating',
    name: 'SI Rating',
    logo: <SiRatingMenuLogoSvgComponent /> ,
    header: ' ',
    roles: [
        role.PROJECT_MANAGER.name
    ],
    children: [
        /*
        {
            //path: PATH_PREFIX + '/legacy/super-admin-dashboard',
            path: PATH_PREFIX + '/super-admin-dashboard',
            name: 'Dashboard',
            route: PrivateRoute,
            roles: [role.PROJECT_MANAGER.name, role.SUPER_ADMIN.name, role.ARB_OPERATOR.name],
            icon: 'dripicons-graph-pie',
            //component: SIRatingChartPage, // TODO da cambiare
            component: TestPowerBiSiRating, // TODO da cambiare
            exact: true
        },
         */
        {
            path: "//",
            name: translate("user_manual"),
            icon: 'uil-star',
            logo: <img src={ userGuideManual } className={"user-guide-icon"}/>,
            roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name, role.ADMIN.name, role.SUPER_ADMIN.name],
            component: AllScoringPage,
            route: PrivateRoute,
            exact: true,
            callBack: () => {
                // window.open( `${ PRODUCTION_HOST }/assets/documents/user_guide/${ translate("usermanual_filename") }.pdf` );
                window.open( `${ PRODUCTION_HOST }/assets/documents/user_guide/sirating_manualeutente.pdf` );
            }
        },
        {
            path: PATH_PREFIX + '/super-admin/si-rating/' + translate("evaluations"),
            name: translate("Evaluations"),
            component: SIRatingPage,
            route: PrivateRoute,
            icon: 'dripicons-meter',
            exact: true
        },
        (
            !!isUserRole( role.PROJECT_MANAGER )
                ? (
                    {
                        path: PATH_PREFIX + '/sirating/' + translate("edit-question"),
                        name: translate("Edit Questions"),
                        route: PrivateRoute,
                        roles: [ role.PROJECT_MANAGER.name ],
                        icon: 'uil-calender',
                        component: EditQuestionPage,
                        exact: true
                    }
                )
                : null
        ),
        {
            path: PATH_PREFIX + '/super-admin/si-rating/' + translate("sector-questions"),
            name: translate("Sector Questions"),
            icon: "mdi mdi-format-list-bulleted",
            component: SIRatingSectorQuestioPage,
            route: PrivateRoute,
            exact: true
        },
        {
            path: PATH_PREFIX + '/super-admin/si-rating/sector-questions-management',
            name: "Sector Questions Management",
            icon: "mdi mdi-format-list-bulleted-type",
            component: SectorQuestionsManagement,
            route: PrivateRoute,
            //icon: 'dripicons-meter',
            exact: true
        },

    ]
    .filter( onlyNotNull )
}


export const adminAreaSIRatingRoute = {
    path: PATH_PREFIX + '/super-admin/si-rating',
    name: 'Si Rating',
    icon: 'uil-star',
    logo: <SiRatingMenuLogoSvgComponent /> ,
    header: ' ',
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
    ],
    children: [

        {
            path: PATH_PREFIX + '/super-admin/si-rating',
            name: 'Dashboard',
            icon: 'dripicons-meter',
            header: ' ',
            roles: [
                role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
            ]
        },

        {
            path: PATH_PREFIX + '/sirating-admin-dashboard',
            name: translate("Comparison"),
            icon: 'mdi mdi-view-sequential',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            component: SIRatingAdminChartPage,
            route: PrivateRoute,
            exact: true
        },
        {
            path: PATH_PREFIX + '/' + translate("sirating-admin-rating-comparation-dashboard"),
            name: 'Comparazione',
            icon: 'dripicons-scale',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            component: SIRatingAdminComparationChartPage,
            route: PrivateRoute,
            exact: true
        },

    ]
}



export const superAdminAreaSIRatingMenuRoute = {
    path: PATH_PREFIX + '/super-admin/si-rating',
    name: 'SI Rating',
    icon: 'uil-star',
    logo: <SiRatingMenuLogoSvgComponent /> ,
    header: ' ',
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
    ],
    children: [
        {
            path: PATH_PREFIX + '/legacy/super-admin-dashboard',
            name: translate("Evaluations Dashboard"),
            route: PrivateRoute,
            roles: [role.PROJECT_MANAGER.name, role.SUPER_ADMIN.name],
            icon: 'dripicons-graph-pie',
            component: SIRatingChartPage,
            exact: true
        },
        {
            path: PATH_PREFIX + '/super-admin-rating-dashboard',
            name: translate("Rating Dashboard"),
            route: PrivateRoute,
            roles: [role.SUPER_ADMIN.name],
            icon: 'dripicons-graph-pie',
            component: SuperAdminRatingDashboard,
            exact: true
        },
        {
            path: PATH_PREFIX + '/sirating-admin/' + translate("evaluations") ,
            name: translate("Evaluations"),
            route: PrivateRoute,
            roles: [
                role.PROJECT_MANAGER.name
                , role.ADMIN.name
                , role.CONSULTANT.name
                , role.SR_ADMIN.name
                , role.SR_CONSULER.name
                , role.SUPER_ADMIN.name
            ],
            icon: 'mdi mdi-view-sequential',
            component: SiRatingAdminEvaluationsPage,
            header: 'SI Rating',
            exact: true
        }
    // {
    //     path: PATH_PREFIX + '/' + translate("sirating-admin-rating-comparation-dashboard"),
    //     name: translate("Comparison"),
    //     icon: 'dripicons-scale',
    //     roles: [role.SR_ADMIN.name, role.ADMIN.name],
    //     header: translate("Evaluations"),
    //     component: SIRatingAdminComparationChartPage,
    //     route: PrivateRoute,
    //     exact: true
    // },
    ]
}



export const superAdminAreaSIRatingEvalutationRoute = {
    path: PATH_PREFIX + '/si-rating/' + translate("evaluation") + '/:id',
    name: "valutazione",
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name, role.SUPER_ADMIN.name
    ],
    icon: 'mdi mdi-account-tie-outline',
    component: SIRatingEvalutationPage,
    exact: true
};

export const superAdminAreaSIRatingPerimetralRoute = {
    path: PATH_PREFIX + '/si-rating/' + translate("perimetral") + '/:id',
    name: "valutazione",
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name
    ],
    icon: 'mdi mdi-account-tie-outline',
    component: SIRatingPerimetralQuestionPage,
    exact: true
};



export const superAdminAssignConsultantRoute = {
    path: PATH_PREFIX + "/consuler/assignto/:id",
    name: 'Associazione Aziende a Consulente',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name
    ],
    icon: 'mdi mdi-account-tie-outline',
    component: SuperAdminAssignToConsuler,
    exact: true
};


export const consultantAreaCompaniesRoute = {
    path: PATH_PREFIX + '/consuler/companies',
    name: translate("Companies"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.ARB_OPERATOR.name
    ],
    icon: 'uil-building',
    component: ConsulerCompanies,
    exact: true
    // header: 'Welcome',
};



export const siratingAdminEvalutaionsRoute = {
    path: PATH_PREFIX + '/admin/' + translate("evaluations") ,
    name: 'SI Rating',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SR_CONSULER.name
    ],
    icon: 'uil-star',
    logo: <SiRatingMenuLogoSvgComponent /> ,
    component: SiRatingAdminEvaluationsPage,
    header: ' ',
    exact: true,
    children: [


        {
            path: PATH_PREFIX + '/admin-dashboard',
            name: 'Dashboard',
            icon: 'dripicons-meter',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            // component: SIRatingAdminChartPage,
            component: TestPowerBiSiRating,
            route: PrivateRoute,
            exact: true
        },
        // {
        //     path: PATH_PREFIX + '/'  + translate("sirating-admin-rating-comparation-dashboard"),
        //     name: translate("Comparison"),
        //     icon: 'dripicons-scale',
        //     roles: [role.SR_ADMIN.name, role.ADMIN.name],
        //     header: translate("Evaluations"),
        //     component: SIRatingAdminComparationChartPage,
        //     route: PrivateRoute,
        //     exact: true
        // },
        {
            path: PATH_PREFIX + '/sirating-admin/' + translate("evaluations") ,
            name: translate("Evaluations"),
            route: PrivateRoute,
            roles: [
                role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SR_CONSULER.name, role.SUPER_ADMIN.name
            ],
            icon: 'mdi mdi-view-sequential',
            component: SiRatingAdminEvaluationsPage,
            header: 'SI Rating',
            exact: true
        },
    ]
};


/**
 * Solo per l'admin
 */
export const siratingAdminEvalutaionsMenu = {
    path: PATH_PREFIX + '/admin/'  + translate("evaluations") ,
    name: 'SI Rating',
    route: PrivateRoute,
    roles: [role.ADMIN.name],
    icon: 'uil-star',
    logo: <SiRatingMenuLogoSvgComponent /> ,
    component: SiRatingAdminEvaluationsPage,
    exact: true,
    children: [
        {
            path: PATH_PREFIX + '/admin-dashboard',
            name: 'Dashboard',
            icon: 'dripicons-meter',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            // component: SIRatingAdminChartPage,
            component: TestPowerBiSiRating,
            route: PrivateRoute,
            exact: true
        },
        {
            path: PATH_PREFIX + '/'  + translate("sirating-admin-rating-comparation-dashboard") ,
            name: translate("Comparison"),
            icon: 'dripicons-scale',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            component: SIRatingAdminComparationChartPage,
            route: PrivateRoute,
            exact: true
        },
        {
            path: PATH_PREFIX + '/sirating-admin/'  + translate("evaluations") ,
            name: translate("Evaluations"),
            route: PrivateRoute,
            roles: [role.ADMIN.name, role.SUPER_ADMIN.name],
            icon: 'mdi mdi-view-sequential',
            component: SiRatingAdminEvaluationsPage,
            exact: true
        },

    ]
};





export const test = {
    path: PATH_PREFIX + '/test',
    name: translate("Evaluations"),
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SR_CONSULER.name
    ],
    icon: 'uil-star',
    logo: <SiRatingMenuLogoSvgComponent /> ,
    component: QuestionReport,
    header: 'SI Rating',
    exact: true,
    children: [
        {
            path: PATH_PREFIX + '/test',
            name: 'Dashboard',
            route: PrivateRoute,
            roles: [
                role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SR_CONSULER.name
            ],
            icon: 'dripicons-meter',
            component: QuestionReport,
            header: 'SI Rating',
            exact: true
        },

        {
            path: PATH_PREFIX + '/sirating-admin-dashboard',
            name: translate("Evaluations"),
            icon: 'mdi mdi-view-sequential',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            component: SIRatingAdminChartPage,
            route: PrivateRoute,
            exact: true
        },
        {
            path: PATH_PREFIX + '/' + translate("sirating-admin-rating-comparation-dashboard") ,
            name: translate("Comparison"),
            icon: 'dripicons-scale',
            roles: [role.SR_ADMIN.name, role.ADMIN.name],
            header: translate("Evaluations"),
            component: SIRatingAdminComparationChartPage,
            route: PrivateRoute,
            exact: true
        },
    ]
};


export const siratingAdminColorPolicyStatusLegendRoute = {
    path: PATH_PREFIX + '/evaluations-legend',
    name: 'Legenda Stati',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SR_CONSULER.name
    ],
    icon: 'uil-book-open',
    // icon: 'uil-swatchbook',
    component: SiRatingAdminStatusLegendColorPolicyPage,
    exact: true
};



export const siratingAdminSingleEvalutaionRoute = {
    path: PATH_PREFIX + "/sirating-admin/" + translate("evaluation") + "/:id",
    name: 'Valutazione',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name, role.ADMIN.name, role.CONSULTANT.name, role.SR_ADMIN.name, role.SR_CONSULER.name, role.SUPER_ADMIN.name
    ],
    icon: 'mdi mdi-account-tie-outline',
    component: SiRatingAdminSingleEvaluationPage,
    exact: true
};




export const siratingEvaluationReadOnlyConfigurationPage = {
    path: PATH_PREFIX + "/super-admin/" + translate("configuration") + "/" + translate("evaluation ") + "/:id",
    name: 'Configurazione in sola lettura',
    route: PrivateRoute,
    roles: [
        role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name, role.CONSULTANT.name, role.SUPER_ADMIN.name
    ],
    icon: 'mdi mdi-account-tie-outline',
    component: SIRatingReadOnlyEvalutationPage,
    exact: true
};


export const dashboardRoutes = {
    path: PATH_PREFIX + '/test-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'dripicons-graph-pie', // uil-chart-pie
    component: SIRatingChartPage,
    exact: true
};





export const superAdminDashboardMenuRoutes = {
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [role.SR_ADMIN.name],
    icon: 'dripicons-graph-pie',
    exact: true,
    children: [{
        path: PATH_PREFIX + '/legacy/super-admin-dashboard',
        name: 'Dashboard',
        route: PrivateRoute,
        roles: [role.PROJECT_MANAGER.name, role.SUPER_ADMIN.name],
        icon: 'dripicons-graph-pie',
        component: SIRatingChartPage,
        exact: true
    },
    {
        path: PATH_PREFIX + '/super-admin-rating-dashboard',
        name: 'Rating Dashboard',
        route: PrivateRoute,
        roles: [role.SUPER_ADMIN.name],
        icon: 'dripicons-graph-pie',
        component: SuperAdminRatingDashboard,
        exact: true
    }
    ]
}


export const superAdminDashboardRoutes = {
    path: PATH_PREFIX + '/legacy/super-admin-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [role.PROJECT_MANAGER.name, role.SUPER_ADMIN.name],
    icon: 'dripicons-graph-pie',
    component: SIRatingChartPage, // TODO da cambiare
    exact: true

}


export const adminDashboardRoutes = {
    path: PATH_PREFIX + '/srtool/admin-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [role.ADMIN.name],
    icon: 'dripicons-graph-pie',
    component: SRToolBaseChartPage,
    callBack: clickEvent => {
        showlog("callBack di SRTool");
    },
    exact: true
};






export const consulerDashboardRoutes = {
    path: PATH_PREFIX + '/consuler-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [role.CONSULTANT.name],
    icon: 'dripicons-graph-pie',
    component: SIRatingChartPage, // TODO da cambiare
    exact: true
};

export const operatorDashboardRoutes = {
    path: PATH_PREFIX + '/operator-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [role.ARB_OPERATOR.name],
    icon: 'dripicons-graph-pie',
    component: SIRatingChartPage, // TODO da cambiare
    exact: true
};



export const griManagerDashboardRoutes = {
    path: PATH_PREFIX + '/srtool-manager-dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [ role.SRTool_ADMIN.name ],
    icon: 'dripicons-graph-pie',
    component: SIRatingChartPage, // TODO da cambiare
    callBack: clickEvent => {
        showlog("callBack di SRTool");
    },
    exact: true
};



export const griRequirementsFilter = {
    path: PATH_PREFIX + '/RequirementsFilter',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: [ role.SRTool_ADMIN.name ],
    icon: 'dripicons-graph-pie',
    component: RequirementsFilter, // TODO da cambiare
    exact: true
};




export const publicTestRoute = {
    path: PATH_PREFIX + '/public/test',
    name: 'public test',
    route: PublicRoute,
    component: TestPublicPage,
    exact: true
}


export const publicEmailOnlyTextRoute = {
    path: PATH_PREFIX + '/public/only-text-email',
    name: 'public only text email',
    route: PublicRoute,
    component: OnlyTextEmail,
    exact: true
}

/*
export const signupPageRoute = {
    path: PATH_PREFIX + '/public/register',
    name: 'public test',
    route: PublicRoute,
    component: SignupPage,
    exact: true
}
*/

export const signupPageRoute = {
    path: PATH_PREFIX + '/signup',
    name: 'signup',
    route: PublicRoute,
    // roles: ["unknown", null, undefined],
    component: SignupPage,
    exact: true
}





export const siscoringMenu = {

    name: 'SI Scoring',
    route: PrivateRoute,
    roles: [role.ADMIN.name, role.SI_Scoring_Admin.name, role.SUPER_ADMIN.name],
    icon: 'dripicons-graph-pie',
    logo: <SiScoringMenuLogoSvgComponent />,
    children: [
        {
            path: "//",
            name: translate("user_manual"),
            icon: 'uil-star',
            logo: <img src={ userGuideManual } className={"user-guide-icon"}/>,
            roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name, role.ADMIN.name, role.SUPER_ADMIN.name],
            component: AllScoringPage,
            route: PrivateRoute,
            exact: true,
            callBack: () => {
                window.open( `${ PRODUCTION_HOST }/assets/documents/user_guide/${ translate("usermanual_filename") }.pdf` );
            }
        },
        {
            path: PATH_PREFIX + '/si-scoring/' + translate("evaluations"),
            name: translate("Evaluations"),
            route: PrivateRoute,
            roles: [role.ADMIN.name, role.SI_Scoring_Admin.name, role.SUPER_ADMIN.name],
            icon: 'dripicons-graph-pie',
            component: AllScoringPage,
        }
    ]
}



export const siscoringPMMenu = {
    name: 'SI Scoring',
    // route: PrivateRoute,
    roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name],
    icon: 'dripicons-graph-pie',
    logo: <SiScoringMenuLogoSvgComponent /> ,
    header: " ",
    children: [
        {
            path: "//",
            name: translate("user_manual"),
            icon: 'uil-star',
            logo: <img src={ userGuideManual } className={"user-guide-icon"}/>,
            roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name],
            component: AllScoringPage,
            route: PrivateRoute,
            exact: true,
            callBack: () => {
                showlog("click on menu for user-manual");

                window.open( `${ PRODUCTION_HOST }/assets/documents/user_guide/${ translate("usermanual_filename") }.pdf` );



            }
        },
        {
            path: PATH_PREFIX + '/si-scoring/pm/' + translate("evaluations"),
            name: translate("Evaluations"),
            icon: 'uil-star',
            roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name],
            component: AllScoringPage,
            route: PrivateRoute,
            exact: true
        },
        (
            !!isUserRole( role.PROJECT_MANAGER )
                ? (
                    {
                        path: PATH_PREFIX + '/si-scoring/pm/' + translate("edit-question"),
                        name: translate("Questions Management"),
                        route: PrivateRoute,
                        roles: [ role.PROJECT_MANAGER.name ],
                        icon: 'uil-calender',
                        component: EditSiScoringQuestionPage,
                        exact: true
                    }
                )
                : null
        )
    ].filter( onlyNotNull )
}


export const siscoringRatingPageRoute = {
    path: PATH_PREFIX + "/si-scoring/" + translate("evaluation") + "/:id",
    name: 'Si Scoring Rating',
    route: PrivateRoute,
    roles: [ role.ADMIN.name, role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name, role.SI_Scoring_Admin.name, role.SUPER_ADMIN.name ],
    icon: 'mdi mdi-account-tie-outline',
    component: SiScoringEvaluationPage,
    exact: true
};


export const SiScoringPdfPageRouting = {
    path: PATH_PREFIX + "/si-scoring/report/pdf/:id",
    name: 'dev pdf',
    route: PrivateRoute,
    roles: Object.keys(role).map(r => role[r].name),
    icon: 'mdi mdi-account-tie-outline',
    component: SiScoringPdfPage,
    exact: true
}





export const genderGapMenu = {
    path: PATH_PREFIX + '/' + translate("gender-gap") + '/' + translate("evaluations"),
    name: translate("Gender Gap"),
    route: PrivateRoute,
    roles: [role.ADMIN.name, role.Parità_Di_Genere_Admin.name],
    icon: 'mdi mdi-gender-male-female',
    logo: <GenderGapIconSvgComponent/>,
    component: GenderGapEvaluations
}

export const genderGapPMMenu = {
    name: translate("Gender Gap"),
    // route: PrivateRoute,
    roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name],
    icon: 'mdi mdi-gender-male-female',
    logo: <GenderGapIconSvgComponent/>,
    header: " ",
    children: [
        {
            path: PATH_PREFIX + '/' + translate("gender-gap") + '/pm/' + translate("evaluations"),
            name: translate("Evaluations"),
            icon: 'mdi mdi-star-outline',
            roles: [role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name],
            component: GenderGapEvaluations,
            route: PrivateRoute,
            exact: true
        },
        (
            !!isUserRole( role.PROJECT_MANAGER )
                ? (
                    null
                    /*
                    {
                        path: PATH_PREFIX + '/' + translate("gender-gap") + '/pm/' + translate("edit-question"),
                        name: 'Gestione Domande',
                        route: PrivateRoute,
                        roles: [ role.PROJECT_MANAGER.name ],
                        icon: 'uil-calender',
                        component: EditGenderGapQuestionPage,
                        exact: true
                    }
                     */
                )
                : null
        )
    ].filter( onlyNotNull )
}


export const genderGapPMRatingConfiguration = {
    path: PATH_PREFIX + "/" + translate("gender-gap") + "/pm/" + translate("configuration") + "/:id",
    name: "Configurazione",
    route: PrivateRoute,
    roles: [ role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name ],
    icon: 'mdi mdi-gender-male-female',
    component: GenderGapConfigurationPage,
    exact: true
}

export const genderGapRatingPageRoute = {
    path: PATH_PREFIX + "/" + translate("gender-gap") + "/" + translate("evaluation") +"/:id",
    name: translate("Gender Gap"),
    route: PrivateRoute,
    roles: [ role.ADMIN.name, role.PROJECT_MANAGER.name, role.ARB_OPERATOR.name, role.Parità_Di_Genere_Admin.name ],
    icon: 'mdi mdi-gender-male-female',
    logo: <GenderGapIconSvgComponent/>,
    component: GenderGapSingleEvaluationPage,
    exact: true
};


export const SRToolTestRoute = {
    path: PATH_PREFIX + '/public/test/srtool',
    name: 'SRTool test',
    route: PublicRoute,
    component: TableToTreeTestPage,
    exact: true
}


export const siratingSectorQuestionLinksRoute = {
    path: PATH_PREFIX + '/sirating-sector-question',
    name: "Sirating SectorQuestion",
    route: PublicRoute,
    component: siratingSectorQuestionLinksPage,
    exact: true
}
