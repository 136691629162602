import React, {Component, Suspense} from 'react';
import {withRouter} from "react-router-dom";
import {
    loadAnswers,
    loadProgress,
    processAnswers_syncChain,
    questionAudit,
    setEvaluationStatus,
    setNewRatingName
} from "../../../business-logic/rating";
import {
    beautify,
    beautifyLog,
    cloneObject,
    emoj,
    errorlog,
    extractErrorMessage,
    formatDigitLength,
    getLastElementOr,
    isNotEmptyArray,
    onlyFirst,
    onlyNotNull,
    renderlog,
    showlog,
    sortById,
    warninglog
} from "../../../utils";
import {exportSeedTableToCSV, openAllNodes} from "../../../business-logic/tree-manager";
import {
    cloneOpenNodes,
    convertGenderGapQuestionTableToTree,
    convertSIRatingSubQuestionTableToSubQuestionChild,
    extractAllQuestions,
    getQuestionFatherTreeNodeByPath,
    getQuestionPath,
    getQuestionTreeNodeByPath,
    questionTreeLog
} from "../../../business-logic/question-tree-manager";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    NavLink,
    PopoverBody,
    PopoverHeader,
    Progress,
    Row,
    UncontrolledAlert,
    UncontrolledPopover
} from "reactstrap";
import {
    COLOR_POLICY,
    DATE_FORMAT_IT,
    errorMessage,
    GENDER_GAP_TAB_ID,
    GENDER_GAP_VOID_TREE,
    HELPER,
    MAGNITUDE_DARK,
    MATERIALITY_REASON_LEVEL,
    PATH_PREFIX,
    permissions,
    QUESTION_STATUS,
    QUESTION_TEXT_COLOR_POLICY,
    RATING_STATUS,
    RATING_TYPE,
    role,
    SIRATING_TREE_DEEP_LEVELS_NAME,
    TABS
} from "../../../env";
import {EMOJ} from "../../../emoj";
import StandBySystemModalComponent from "../../../components/area/gri-admin/standby-system-modal";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import Spinner from "../../../components/Spinner";
import Select from "react-select";
import PageTitle from "../../../components/PageTitle";
import ActionButton from "../../../components/save-button";
import {loadEvaluations} from "../../../business-logic/active-service";
import SiRatingQuestionAttachmentModal from "../si-rating-admin/sirating-question-attachment-modal";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import NCMessageModal from "../../../components/area/gri-admin/nc-message-modal";


import {getAllPermissions, hasPermission, isUserRole} from "../../../business-logic/auth";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {CloseRatingConfirmModal} from "../../../components/area/super-admin/close-rating-confirm-modal";
import "../../../style/audit.css";
import "../../../style/rating-page.css";
import SiRatingQuestionHistoryModal from "../../../components/area/sirating/question-history-modal";
import moment from "moment";
import EditMaterialityModal from "../../../components/area/super-admin/edit-materiality-modal";
import {TreeToggler} from "../../../components/tree-toggler";

import SendMailNcQuestions from "../../../components/area/super-admin/sendMailNcQuestions-modal.js"
import SendMailAttachmentsQuestions from "../../../components/area/super-admin/sendMailAttachmentsQuestions-modal.js"
import SendMailNewAttachmentsInserted
    from "../../../components/area/super-admin/sendMailNewAttachmentsInserted-modal.js"
import StateModal from "../../../components/area/super-admin/state-modal"
import EvaluationRolesArbModal from "../../../components/area/super-admin/evaluationRoles-list-modal";
import MaterailityReport from "../../../components/area/super-admin/materailityReport-modal";
import {getQuestionStatus} from "../../../utils/question-utils";

import {QuestionNoteModal} from "../../../components/area/gri-admin/question-note-modal";
import {NoteIconSvgComponent} from "../../../components/svg/note-icon-svg-component";
import {AdminNoteIconSvgComponent} from "../../../components/svg/admin-note-icon-svg-component";
import {ArchiveRatingConfirmModal} from "../../../components/archive-rating-confirm-modal";

import "../../../style/gender-gap.css";
import {createOpenAnswerData, saveOpenAnswer} from "../../../business-logic/gender-gap";
import {PreventiveQuestionModal} from "./preventive-question-modal.js";
import GotoTopComponent from "../../../components/goto-top-component";
import ConfirmAllValidationModal from './confirm-all-validation-modal';
import {translate} from '../../../components/i18n/translate-function';

// eslint-disable-next-line no-restricted-globals

let isRunned = false

const AVOID_FILTER_STATUS = [
    "incomplete"
];


const QUESTION_STATUS_ALL = {
    label: translate("AllTheQuestions"),
    value: -1,
    isPassCondition: () => true
};


const ALL_POSSIBLE_QUESTION_STATUS = [
        QUESTION_STATUS_ALL,
        {
            label: translate("Ongoing questions"),
            value: -2,
            isPassCondition: () => true
        },
        ...Object.keys(QUESTION_STATUS)
            .filter( status => !AVOID_FILTER_STATUS.includes(status) )
            .map(status => ({
                label: QUESTION_STATUS[status].name,
                value: QUESTION_STATUS[status].id,
                isPassCondition: questionStatus => (questionStatus === QUESTION_STATUS[status].id)
            }))
        ,
        {
            label: translate("Audited questions"),
            value: -3,
            isPassCondition: questionStatus => (questionStatus === QUESTION_STATUS.validated.id || questionStatus === QUESTION_STATUS.highNonCompliant.id || questionStatus === QUESTION_STATUS.attachmentWaiting.id)
        },
        {
            label: translate("Questions with Audits to be carried out"),
            value: -4,
            isPassCondition: questionStatus => (questionStatus === QUESTION_STATUS.incomplete.id || questionStatus === QUESTION_STATUS.finished.id || questionStatus === QUESTION_STATUS.attachmentToValidate.id || questionStatus === QUESTION_STATUS.waitNonCompliant.id)
            // isPassCondition: questionStatus => ( questionStatus < QUESTION_STATUS.validated.id )
        }
    ]
;

class GenderGapSingleEvaluationPage extends Component {

    state = {
        clientQuestions: [],
        editAnswer: [],
        nonCompliantReason: [],
        // nonCompliantDefaultExp: moment().add( 7, "days" ),
        questionInWait: [],
        waitingQuestions: [],
        isFromAuditer: !!this.props.isFromAuditer || (!!this.props.location && !!this.props.location.state && !!this.props.location.state.isFromAuditer),
        openAnswerSettled: [],
        confirmAllValidation: false
    };


    constructor(props) {
        super(props);

        this.questionChildren = [];

        this.loadData = this.loadData.bind(this);
        this.inWaiting = this.inWaiting.bind(this);
        this.buildQuestions = this.buildQuestions.bind(this);
        this.buildSubThemes = this.buildSubThemes.bind(this);
        this.isDisabledQuestion = this.isDisabledQuestion.bind(this);
        this.notCompliantComander = this.notCompliantComander.bind(this);
        this.nonCompliantAnswerBox = this.nonCompliantAnswerBox.bind(this);
        this.showConfirmAuditButton = this.showConfirmAuditButton.bind(this);
        this.isReadOnlyAudit = this.isReadOnlyAudit.bind(this);
        this.filterQuestion = this.filterQuestion.bind(this);
        this.isQuestionInAuditAndAuditIsRunning = this.isQuestionInAuditAndAuditIsRunning.bind(this);
        this.subQuestionRequest = this.subQuestionRequest.bind(this);
        this.getProgress = this.getProgress.bind(this);
        this.isComeFromAuditer = this.isComeFromAuditer.bind(this);	// non dovrebbe essere necessario
        this.getRatingId = this.getRatingId.bind(this);		// non dovrebbe essere necessario
        this.helperNode = this.helperNode.bind(this);		// non dovrebbe essere necessario

        this.buildOpenAnswers = this.buildOpenAnswers.bind(this);		// non dovrebbe essere necessario
        this.questionAllOpenAnswerSettled = this.questionAllOpenAnswerSettled.bind( this );
        this.isOperativeQuestion = this.isOperativeQuestion.bind(this);		// non dovrebbe essere necessario
        this.saveChain = this.saveChain.bind(this);		// non dovrebbe essere necessario


    }

    inWaiting() {
        return (
            <div>Wait</div>
        );
    }


    getRatingId = () => (
        (!!this.props.location && !!this.props.location.state && !!this.props.location.state.id)
            ? this.props.location.state.id
            : (
                this.props.match.params.id
                || this.props.id
                || this.state.metaData.id
            )
    );

    isComeFromAuditer = () => (
        (
            !!this.state.isFromAuditer
            || !!this.props.isFromAuditer
            || (!!this.props.location && !!this.props.location.state && !!this.props.location.state.isFromAuditer)
            // || this.state.metaData.status === RATING_STATUS.ratingComplete.id
            || this.state.metaData.status >= RATING_STATUS.ratingComplete.id
            // accesso da url
            || !!this.state.auditReady
        )
        && hasPermission(permissions.RATING_AUDIT)
    );





    /**
     * Controlla se la domanda è disabilitata,
     * se la domanda viene letta quando si è in audit la domanda è abilitata
     *
     * se la domanda viene letta da SiRatingAdmin allora:
     * se lo status della domanda vale QUESTION_STATUS.finished e lo status della validazione vale RATING_STATUS.ratingComplete
     * allora la domada è disabilitata
     * se è presente una non-conformità lieve allora la domanda è abilitata
     *
     * se si è nello status di audit validato la domanda è disabilitata
     * se si è nello status di audit confermato da operatore la domanda è abilitata
     *
     * @param questionId
     * @returns {boolean}
     */
    isDisabledQuestion(questionId) {

        // return false;   // ATTENZIONE GUARDAMI TODO riga da cancellare!!!

        // if( !!isUserRole( role.SUPER_ADMIN ) ) return true;


        /*
        Se PM o OPERATORE_ARB e non ancora in fase di audit
        le domande sono disabilitate
         */
        if(
            ( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) )
            && (!!this.state.metaData && !!this.state.metaData.status && parseInt(this.state.metaData.status, 10) < parseInt(RATING_STATUS.ratingComplete.id, 10))
        ) {
            return true;
        }



        if (!!this.state.metaData && !!this.state.metaData.status && parseInt(this.state.metaData.status, 10) === parseInt(RATING_STATUS.auditDone.id, 10)) return true;

        // permesso di continuare l'audit dopo che è stato confermato ma non validato
        if (this.isComeFromAuditer() && !!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) return false;


        return (
            (
                !this.isComeFromAuditer() // this.props.isFromAuditer // quando si è in Audit
                && !!getQuestionTreeNodeByPath(this.state.tree, getQuestionPath(this.state.tree, questionId))
                && parseInt(getQuestionTreeNodeByPath(this.state.tree, getQuestionPath(this.state.tree, questionId)).status, 10) >= parseInt(QUESTION_STATUS.incomplete.id, 10)
                && parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.ratingComplete.id, 10)
                && parseInt(getQuestionTreeNodeByPath(this.state.tree, getQuestionPath(this.state.tree, questionId)).status, 10) !== parseInt(QUESTION_STATUS.lowNonCompliant.id, 10)
            )
            && getQuestionTreeNodeByPath(this.state.tree, getQuestionPath(this.state.tree, questionId)).status !== QUESTION_STATUS.attachmentWaiting.id
        );
    }


    /**
     * domanda non disabilitata
     * se ha domande preventive, che siano risposte
     *
     * @param question
     * @returns {boolean}
     */
    isOperativeQuestion = question => {
        if( !!isNotEmptyArray(question.preventiveQuestions.question) ) {
            return (
                !this.isDisabledQuestion( question.id )
                && !!(
                    question.preventiveQuestions.answer
                        .reduce( getLastElementOr, null )
                )
            )
        }
        return (!this.isDisabledQuestion( question.id ) );
    }


    isQuestionInAuditAndAuditIsRunning(question) {

        if (this.isComeFromAuditer()) {

            // in audit?
            if (parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.ratingComplete.id, 10)) {

                // audit confermato da OPERATOR ?
                if (parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.auditComplete.id, 10)) {

                    // audit validato da SUPER-ADMIN ?
                    if (parseInt(this.state.metaData.status, 10) === parseInt(RATING_STATUS.auditDone.id, 10)) {

                        if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION)) {
                            // si
                            return true;
                        }

                    } else {

                        // si ha il permesso di continuare l'audit anche se è stato confermato dall'operatore?
                        if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) {
                            // si
                            return true;
                        }

                    }

                } else return true;

            }

        }
        return false;

    }


    nonCompliantAnswerBox(question) {

    }


    notCompliantComander(question) {

        if (!this.isQuestionInAuditAndAuditIsRunning(question)) return null;

        // non serve, ma è utile per la color_policy
        let switchClass =
            (!!question.nonCompliant)
                ? (
                    Object.keys(COLOR_POLICY)
                        .map(status => COLOR_POLICY[status])
                        .filter(cp => (
                            cp.id ===
                            getQuestionStatus( question.nonCompliant, QUESTION_STATUS.lowNonCompliant )
                                .colorPolicyReferiment
                        ))
                        .reduce(getLastElementOr, COLOR_POLICY.warning)
                        .switchClassEnabled
                )
                : COLOR_POLICY.warning.switchClassDisabled
        ;


        let treeTmp = cloneObject(this.state.tree);
        let refreshedQuestion =
            getQuestionTreeNodeByPath(
                treeTmp,   // albero
                !!isNotEmptyArray(question.path)
                    ? question.path
                    : getQuestionPath(treeTmp, question.id)
            )
        ;


        // ;
        // showlog("controllo domanda", question.id, question.status, question.expire, question);


        if (question.status >= QUESTION_STATUS.lowNonCompliant.id) {

        }


        return (
            <div className={"non-compliant-container"}>

                <div className={"non-compliant-container-status"}>


                    <div className={"non-compliant-container-status-label-container"}>
                        <Label for={"audit-non-compliant-" + question.id}>Status</Label>
                        {
                            (
                                !!isNotEmptyArray(this.state.questionInWait)
                                && !!this.state.questionInWait.includes(question.id)
                            )
                                ? (
                                    <span className={"margin-left"}>
                                    <Spinner color={"success"} className="spinner-border-sm mr-1" />
                                </span>
                                )
                                : null
                        }
                    </div>
                    <Select
                        className={"react-select non-compliant-select"}
                        classNamePrefix="react-select"
                        name={"audit-question-noncompliant-" + question.id}
                        id={"audit-question-noncompliant-" + question.id}
                        onChange={changeEvent => {

                            new Promise((success, fail) => {
                                if ( changeEvent.id < QUESTION_STATUS.lowNonCompliant.id || changeEvent.id === QUESTION_STATUS.cancelled.id ) {


                                    let questionsInWait = this.state.questionInWait;
                                    if ([QUESTION_STATUS.validated.id, QUESTION_STATUS.cancelled.id].includes( changeEvent.id )) {
                                        // if (changeEvent.id === QUESTION_STATUS.validated.id) {
                                        questionsInWait = [...this.state.questionInWait, question.id];
                                    }


                                    this.setState({
                                            ...this.state,
                                            questionInWait: questionsInWait
                                        },
                                        () => {

                                            showlog("%c  0  ", "background:#18f; color: #fff; font-size: 3em;");

                                            // setTimeout(function() {
                                            questionAudit(
                                                this.getRatingId(),
                                                question.id,
                                                changeEvent.id
                                            )
                                                .then(result => {

                                                    this.loadData(cloneObject(this.state.tree))
                                                        .then(() => {

                                                            this.setState({
                                                                    ...this.state,
                                                                    questionInWait: this.state.questionInWait.filter(qw => qw !== question.id)
                                                                },
                                                                () => {
                                                                    showlog("%c  3  ", "background:#18f; color: #fff; font-size: 3em;");
                                                                    showlog("audot ready", this.state.auditReady);
                                                                    success();
                                                                })


                                                        })
                                                        .catch(e => {
                                                            errorlog("errore alla chiamata GET per l'audit");
                                                            showlog(e);
                                                            fail(e);
                                                        })
                                                    ;


                                                })
                                                .catch(e => {
                                                    errorlog("errore alla chiamata dell'audit");
                                                    showlog(e);
                                                    fail(e);
                                                })
                                            // }.bind( this ), 10000000)





                                        }
                                    )


                                } else {

                                    let questionsInWait = this.state.questionInWait;
                                    if (changeEvent.id === QUESTION_STATUS.validated.id) {
                                        questionsInWait = [...this.state.questionInWait, question.id];
                                    }


                                    this.setState({
                                            ...this.state,
                                            questionInWait: questionsInWait
                                        },
                                        () => {
                                            success();
                                        })
                                }
                            })

                                // check auditReady
                                .then(() => {






                                    /*
                                    macroareaId: 1
                                    questionAnswered: {…}
                                    questionAttachmentToValidate: {…}
                                    questionAttachmentWaiting: {…}
                                    questionAuditReady: {…}
                                        count: 27
                                        thematicList: (1) […]
                                    questionCancelled: {…}
                                    questionCompleted: {…}
                                    questionGraveNc: {…}
                                    questionMildNc: {…}
                                    questionToAnswer: {…}
                                    questionToAudit: {…}
                                        count: 0
                                        thematicList: []
                                    questionTotal: {…}
                                        count: 27
                                        thematicList: (1) […]
                                    questionValidate: {…}
                                    questionWaitNc: {…}
                                     */



                                    // audit ready call
                                    let progress = 0;
                                    let tabInfo = { questionAuditReady: 0, questionTotalNumber: 0 };
                                    tabInfo =
                                        this.state.metaData.info
                                            .reduce((final, tabData) => {
                                                final.questionAuditReady += tabData.questionAuditReady.count;
                                                final.questionTotalNumber += tabData.questionTotal.count;
                                                return final;
                                            }, { questionAuditReady: 0, questionTotalNumber: 0 })
                                    ;
                                    progress = tabInfo.questionAuditReady / tabInfo.questionTotalNumber;
                                    progress *= 100;
                                    progress = Math.floor(progress);
                                    progress = parseInt(progress, 10);


                                    showlog("audit ready da ", progress);



                                    return progress === 100;


                                })

                                // fine -------------------------------------------------------------------------------------
                                .then(isAuditReady => {

                                    let refreshedTree = cloneObject(this.state.tree);


                                    let refreshedQuestion =
                                        getQuestionTreeNodeByPath(
                                            refreshedTree,   // albero
                                            !!isNotEmptyArray(question.path)
                                                ? question.path
                                                : getQuestionPath(refreshedTree, question.id)
                                        )
                                    ;
                                    refreshedQuestion.nonCompliant = changeEvent.id;
                                    refreshedQuestion.status = changeEvent.id;


                                    cloneOpenNodes(treeTmp, refreshedTree);


                                    // ;
                                    this.setState({
                                            ...this.state,
                                            tree: refreshedTree,
                                            auditReady: isAuditReady
                                        },


                                        () => {
                                            showlog("%c  4  ", "background:#18f; color: #fff; font-size: 3em;");
                                            showlog("state audit ready", this.state.auditReady);
                                            showlog( this.state.nonCompliantDefaultExp  );
                                            showlog( (!!question.expire ? moment(question.expire).format(DATE_FORMAT_IT) : moment().add(7, "days")) || moment().add(7, "days") );
                                            showlog( this.state.nonCompliantDefaultExp || (!!question.expire ? moment(question.expire).format(DATE_FORMAT_IT) : moment().add(7, "days")) || moment().add(7, "days") );
                                            showlog("------------------------------------------------------------------------------------------");
                                        }


                                    )
                                })
                                .catch(e => {
                                    errorlog("errore all'audit");
                                    showlog(e);
                                })

                        }}
                        options={
                            [
                                QUESTION_STATUS.validated,
                                QUESTION_STATUS.lowNonCompliant,
                                QUESTION_STATUS.attachmentWaiting,
                                QUESTION_STATUS.highNonCompliant,
                                QUESTION_STATUS.cancelled
                            ]
                                .map(qs => Object.assign({}, qs, { label: qs.name, value: qs.id }))
                        }
                        value={

                            [
                                QUESTION_STATUS.validated,
                                QUESTION_STATUS.lowNonCompliant,
                                QUESTION_STATUS.attachmentWaiting,
                                QUESTION_STATUS.highNonCompliant,
                                QUESTION_STATUS.cancelled
                            ]
                                .filter(qs => qs.id === question.status)
                                .map(qs => Object.assign({}, qs, { label: qs.name, value: qs.id }))
                                .reduce(getLastElementOr, null)

                        }
                        // defaultValue={ Object.assign({}, QUESTION_STATUS.lowNonCompliant, {label: QUESTION_STATUS.lowNonCompliant.name, value: QUESTION_STATUS.lowNonCompliant.id}) }
                        placeholder={<span className={"select-placeholder"}>Seleziona validità</span>}
                    />

                    {/*
                        (question.status === QUESTION_STATUS.lowNonCompliant.id || question.status === QUESTION_STATUS.attachmentWaiting.id)
                            ? (
                                <div className={"non-compliant-container-labeltextarea"}>
                                    <Label for="validity">Data scadenza</Label>
                                    <HyperDatepicker
                                        hideAddon={true}
                                        dateFormat={CALENDAR_DATE_FORMAT_IT}
                                        onSelect={clickEvent => {
                                            refreshedQuestion.nonCompliantExpiration = moment(clickEvent); //.format( DATE_FORMAT_IT );
                                            this.setState({
                                                ...this.state,
                                                nonCompliantDefaultExp: refreshedQuestion.nonCompliantExpiration,
                                                tree: treeTmp
                                            })
                                        }}
                                        value={this.state.nonCompliantDefaultExp || (!!question.expire ? moment(question.expire).format(DATE_FORMAT_IT) : moment().add(7, "days")) || moment().add(7, "days")}
                                    />
                                </div>
                            )
                            : null
                    */}


                </div>



                <div className={"non-compliant-container-data"}>

                    {
                        // question.status
                        // (!!question.nonCompliant && question.nonCompliant >= QUESTION_STATUS.lowNonCompliant.id)
                        (question.status === QUESTION_STATUS.lowNonCompliant.id || question.status === QUESTION_STATUS.highNonCompliant.id || question.status === QUESTION_STATUS.attachmentWaiting.id || question.status === QUESTION_STATUS.waitNonCompliant.id)
                            ? (
                                <AvForm>
                                    <AvGroup className={"audit-low-noncompliant-group"}>
                                        <div className={""}>
                                            <Label for={"audit-non-compliant-reason-" + question.id}>
                                                {
                                                    (question.status === QUESTION_STATUS.attachmentWaiting.id)
                                                        ? "Allegato Richiesto"
                                                        : (
                                                            (question.status === QUESTION_STATUS.waitNonCompliant.id)
                                                                ? "Risposta"
                                                                : "Motivazione"
                                                        )
                                                }
                                            </Label>
                                            <AvInput
                                                className={"sector-question-text"}
                                                type="textarea"
                                                row={64}
                                                name={"audit-non-compliant-reason-" + question.id}
                                                id={"audit-non-compliant-reason-" + question.id}

                                                defaultValue={question.ncMessage}

                                                onChange={changeTextEvent => {


                                                    if (changeTextEvent.target.value.length > 2) {
                                                        if (!refreshedQuestion.inEdit) {
                                                            refreshedQuestion.inEdit = true;

                                                            // ;
                                                            this.setState({
                                                                ...this.state,
                                                                tree: treeTmp
                                                            });
                                                        }
                                                    } else {
                                                        refreshedQuestion.inEdit = false;

                                                        // ;
                                                        this.setState({
                                                            ...this.state,
                                                            tree: treeTmp
                                                        });
                                                    }


                                                }}
                                                onBlur={blurEvent => {
                                                    refreshedQuestion.nonCompliantReason = blurEvent.target.value;

                                                    // ;
                                                    this.setState({
                                                        ...this.state,
                                                        tree: treeTmp
                                                    });
                                                }}
                                                required
                                            />

                                        </div>





                                    </AvGroup>
                                </AvForm>
                            )
                            : null
                    }




                    {
                        // (question.status >= QUESTION_STATUS.lowNonCompliant.id)
                        ( question.status >= QUESTION_STATUS.lowNonCompliant.id && question.status !== QUESTION_STATUS.cancelled.id )
                            ? (
                                <div className={"low-non-compliant-button"}>
                                    <ActionButton
                                        buttonText={"Conferma"}
                                        // visible={ /*!!question.nonCompliantReason && question.nonCompliantReason.length > 2 &&*/ !!question.inEdit }
                                        className={"non-compliant-comander-button"}
                                        promise={() => questionAudit(
                                            this.getRatingId(),
                                            question.id,
                                            // QUESTION_STATUS.lowNonCompliant.id,
                                            question.status,
                                            question.nonCompliantReason,
                                            question.nonCompliantExpiration || this.state.nonCompliantDefaultExp
                                            // this.state.nonCompliantReason.filter( ncr => ncr.id === question.id ).map( ncr => ncr.text ).reduce( getLastElementOr, "" ) // question.nonCompliantReason
                                            // document.getElementById( "audit-non-compliant-reason-"+ question.id ).value
                                        )}
                                        onProcess={() => {
                                            showlog("start audit");
                                        }}
                                        onResult={result => {
                                            let treeTmp = cloneObject(this.state.tree);
                                            let refreshedQuestion =
                                                getQuestionTreeNodeByPath(
                                                    treeTmp,   // albero
                                                    !!isNotEmptyArray(question.path)
                                                        ? question.path
                                                        : getQuestionPath(treeTmp, question.id)
                                                )
                                            ;

                                            refreshedQuestion.nonCompliant = QUESTION_STATUS.lowNonCompliant.id;
                                            // refreshedQuestion.status = QUESTION_STATUS.lowNonCompliant.id

                                            this.setState({
                                                    ...this.state,
                                                    buttonFeedBack: question
                                                },
                                                () => {
                                                    setTimeout(
                                                        function () {

                                                            this.loadData(cloneObject(this.state.tree))
                                                                .then(tree => {

                                                                    this.setState({
                                                                        ...this.state,
                                                                        buttonFeedBack: null
                                                                    })

                                                                })
                                                            ;

                                                        }.bind(this),
                                                        3000
                                                    )
                                                }
                                            )


                                        }}
                                        onError={error => {
                                            errorlog("Audit con commento",error);
                                            this.setState({
                                                    ...this.state,
                                                    error: {
                                                        ncSetStatus: error
                                                    }
                                                },
                                                () => {
                                                    setTimeout(
                                                        function () {

                                                            this.setState({
                                                                ...this.state,
                                                                error: null
                                                            })


                                                        }.bind(this),
                                                        3000
                                                    )
                                                }
                                            )
                                        }}
                                    />


                                    {
                                        (!!this.state.buttonFeedBack && this.state.buttonFeedBack.id === question.id)
                                            ? (
                                                <UncontrolledAlert color={"success"}>
                                                    <strong>Cambio Status </strong>
                                                    { getQuestionStatus( this.state.buttonFeedBack.status ).label }
                                                    &nbsp;
                                                    impostata con successo!
                                                </UncontrolledAlert>
                                            )
                                            : null
                                    }

                                    {
                                        (!!this.state.error && !!this.state.error.ncSetStatus)
                                            ? (
                                                <UncontrolledAlert color={"danger"}>
                                                    <strong>ATTENZIONE </strong> {errorMessage.GENERIC_FRONTEND_ERROR_MESSAGE.msg}
                                                </UncontrolledAlert>
                                            )
                                            : null
                                    }


                                </div>
                            )
                            : null
                    }
                </div>



                <div className={"non-compliant-container-select"}/>









            </div>
        );
    }


    isReadOnlyAudit() {
        let isReadOnly = true;

        if (this.isComeFromAuditer()) {

            if (parseInt(this.state.metaData.status, 10) !== parseInt(RATING_STATUS.auditDone.id, 10)) {
                // audit in sola lettura
            } else {
                // audit enabled
                if (!!this.state.auditReady) {
                    // audit pronto
                    if (!!hasPermission(permissions.RATING_AUDIT_CONFIRM)) {
                        isReadOnly = false;
                    } else {
                        // audit in sola lettura
                    }
                    if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) {
                        isReadOnly = false;
                    } else {
                        // audit in sola lettura
                    }
                } else {
                    // ancora in audit

                }
            }
        } else {
            // non siamo in audit
        }

        return isReadOnly;
    }


    showConfirmAuditButton() {


        showlog("%cCONTROLLO TASTO AUDIT", "font-size: 2em; color: #f00;");
        showlog("is come from auditer?", this.isComeFromAuditer());
        showlog("is state auditReady?", !!this.state.auditReady);
        showlog("rating status", this.state.metaData.status, RATING_STATUS.ratingComplete.id);
        showlog("");
        showlog("\n\n\n\n");



        let auditButton = null;

        if (this.isComeFromAuditer()) {



            // ;

            // audit enabled
            if (!!this.state.auditReady) {
                // audit pronto


                // showlog("audit finito");
                // showlog( !!hasPermission( permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM ) );

                // ;

                switch (parseInt(this.state.metaData.status, 10)) {

                    /*
                    lo status è 30
                    Le domande sono state tutte risposte e la valutazione
                    è stata confermata da SiRatingAdmin
                     */
                    case parseInt(RATING_STATUS.ratingComplete.id, 10):


                        if (!!hasPermission(permissions.RATING_AUDIT_CONFIRM)) {
                            auditButton = (
                                <span className={"in-audit-header-text float-right"}>
                                    <ActionButton
                                        buttonText={"Conferma Audit"}
                                        promise={() => {
                                            return (
                                                setEvaluationStatus(this.getRatingId(), false, true)
                                                    .then( result => {
                                                        return (
                                                            this.loadData(cloneObject(this.state.tree))
                                                                .then( loadResult => {
                                                                    return true;
                                                                })
                                                                .catch(e => {
                                                                    return Promise.reject( e );
                                                                })
                                                        );
                                                    })
                                                    .catch(e => {
                                                        return Promise.reject( e );
                                                    })
                                            );
                                        }}
                                        onResult={result => {
                                            // this.loadData(cloneObject(this.state.tree));
                                        }}
                                        onError={e => {
                                            errorlog("errore",e);
                                            this.setState({
                                                ...this.state,
                                                errorMessage: (
                                                    !!e
                                                        ? (
                                                            // emoj(EMOJ.sad_but_relieved_face) +
                                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;{!!e.msg ? e.msg : (!!e.error && !!e.error.message ? e.error.message : e)}</span>
                                                        )
                                                        : null
                                                )
                                            });
                                        }}
                                    />
                                </span>
                            );
                        } else {
                            // audit sola lettura
                            auditButton =
                                <span className={"in-audit-header-text float-right"}>Valutazione Validata</span>;
                        }
                        break;


                    /*
                    lo status è 35
                    - Le domande sono state tutte risposte e la valutazione è stata confermata da SiRatingAdmin
                    - l'audit è stato completato ed è stato confermato dall'operatore
                     */
                    case parseInt(RATING_STATUS.auditComplete.id, 10):
                        if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) {
                            auditButton = (
                                <span className={"in-audit-header-text float-right"}>
                                    <ActionButton
                                        buttonText={"Valida Audit"}
                                        promise={() => setEvaluationStatus(this.getRatingId(), false, true)}
                                        onResult={result => {
                                            this.loadData(cloneObject(this.state.tree));
                                        }}
                                        onError={e => {
                                            errorlog("errore",e);
                                            this.setState({
                                                ...this.state,
                                                errorMessage: (
                                                    !!e
                                                        ? emoj(EMOJ.sad_but_relieved_face) +
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;{!!e.msg ? e.msg : e}</span>
                                                        : null
                                                )
                                            });
                                        }}
                                    />
                                </span>
                            );
                        } else {
                            // audit sola lettura
                            auditButton =
                                <span className={"in-audit-header-text float-right"}>Valutazione Validata</span>;
                        }
                        break;

                    /*
                    lo status è 40
                    - Le domande sono state tutte risposte e la valutazione è stata confermata da SiRatingAdmin
                    - l'audit è stato completato ed è stato confermato dal super admin

                    l'audit può continuare soltanto se si ha il permesso di continuare l'audit dopo essere stato validato,
                    questo è un tipo di permesso che non dovrebbe avere nessuno
                     */
                    case parseInt(RATING_STATUS.auditDone.id, 10):
                        if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION)) {

                        } else {
                            // audit sola lettura
                            auditButton =
                                <span className={"in-audit-header-text float-right"}>Valutazione Validata</span>;
                        }
                        break;

                }

            }


        } else {
            // non siamo in audit
        }

        return auditButton;

    }



    helperNode = node => (

        (!!HELPER && (window.location.href.indexOf("help") !== -1))
            ? (
                <span
                    className={"helper-icon"}
                    onClick={ clickEvent => {
                        clickEvent.stopPropagation();
                        this.setState({
                            ...this.state,
                            helper: node
                        })
                    }}
                >
					<span>{ emoj( EMOJ.ambulance ) }</span>
					<code>{ node.id }</code>
				</span>
            )
            : null

    )


    canEditMateriality = () => {

        let canItEditMateriality = false;
        switch (parseInt(this.state.metaData.status, 10)) {

            /*
			lo status è 30
			Le domande sono state tutte risposte e la valutazione
			è stata confermata da SiRatingAdmin
			 */
            case parseInt(RATING_STATUS.ratingComplete.id, 10):
                if (!!hasPermission(permissions.RATING_AUDIT_CONFIRM)) {
                    canItEditMateriality = true;
                } else {
                    // audit sola lettura
                    canItEditMateriality = false;
                }
                break;


            /*
			lo status è 35
			- Le domande sono state tutte risposte e la valutazione è stata confermata da SiRatingAdmin
			- l'audit è stato completato ed è stato confermato dall'operatore
			 */
            case parseInt(RATING_STATUS.auditComplete.id, 10):
                if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) {
                    canItEditMateriality = true;
                } else {
                    // audit sola lettura
                    canItEditMateriality = false;
                }
                break;

            /*
			lo status è 40
			- Le domande sono state tutte risposte e la valutazione è stata confermata da SiRatingAdmin
			- l'audit è stato completato ed è stato confermato dal super admin

			l'audit può continuare soltanto se si ha il permesso di continuare l'audit dopo essere stato validato,
			questo è un tipo di permesso che non dovrebbe avere nessuno
			 */
            case parseInt(RATING_STATUS.auditDone.id, 10):
                if (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION)) {
                    canItEditMateriality = true;
                } else {
                    // audit sola lettura
                    canItEditMateriality = false;
                }
                break;

        }

        return canItEditMateriality;


    }

    /**
     * Quando tutte le risposte aperte della domanda sono state completate,
     * cioè quando tutte hanno un valore settato
     *
     * @param question
     * @returns {boolean}
     */
    questionAllOpenAnswerSettled = question => (
        createOpenAnswerData( question )
            .every( openAnswer => openAnswer.value )
    )



    saveChain = (promiseList = [], index = 0) => (

        new Promise((done, err) => {

            promiseList[index]()
                .then(result => {
                    index++;
                    if (index < promiseList.length) {
                        this.saveChain(promiseList, index)
                            .then( res => done() )
                            .catch( e => err( e ) )
                    }
                    else done();
                })
                .catch(e => err(e))

        })

    )


    render() {

        renderlog("GenderGapSingleEvaluationPage", this.state, this.props, COLOR_POLICY.lowWarning);

        if(!!this.state.confirmAllValidation){
            return (
                <ConfirmAllValidationModal
                esternalProps={this.props}
                onClose={closeEvent => {
                    this.setState({
                        ...this.state,
                        confirmAllValidation: false
                    })
                }}
                />
            );
        }

        if (!!this.state.loadDataError) {
            return (
                <div className={"centered-block almost-full-height"}>
                    {/*
                    <span>C'è qualche problema {emoj(EMOJ.sad_but_relieved_face)}</span>
                    <div><em>{ this.state.loadDataError.error.msg }</em></div>
                    */}
                    <span>{ this.state.loadDataError.error.msg }</span>
                </div>
            )
        } else {
            if (
                !this.state.tree ||
                !isNotEmptyArray( this.state.tree.tabs ) ||
                !isNotEmptyArray( this.state.tree.tabs[0].themes )
            ) {
                return (
                    <WaitingOrNodataMessage
                        waiting={!this.state.tree}
                        fullHeight={true}
                        nodataMessage={"Nessun Dato"}
                    />
                );
            }
        }


        let confirmingText = <div className="d-flex justify-content-center" style={{
            fontSize: "3rem",
            margin: "25vh"
        }}>{"    " + emoj(EMOJ.thumbs_up) + "    "}</div>;


        let questionList = extractAllQuestions(this.state.tree);
        // showlog("tutte le domande");
        // showlog(questionList);


        // showlog("STOP #02");


        let progress = 0;
        // let tabInfo = { questionCompletedNumber: 0, questionTotalNumber: 0 };
        //
        // if (!!this.state && !!this.state.metaData && !!this.state.metaData.info) {
        //     tabInfo =
        //         this.state.metaData.info
        //             .reduce((final, tabData) => {
        //                 final.questionCompletedNumber += tabData.questionCompletedNumber;
        //                 final.questionTotalNumber += tabData.questionTotalNumber;
        //                 return final;
        //             }, { questionCompletedNumber: 0, questionTotalNumber: 0 })
        //         ;
        // }

        progress = this.state.tabInfo.questionCompletedNumber / this.state.tabInfo.questionTotalNumber;
        progress *= 100;
        progress = Math.floor(progress);
        progress = parseInt(progress, 10);


        let progressColor =
            MAGNITUDE_DARK
                // .filter( (m, i) => (i % 2 === 1) )	// 4 step invece che 8
                .filter((m, i) => {
                    // let step = Math.floor( progress / (100 / MAGNITUDE.filter( (m, i) => (i % 2 === 1) ).length) ) - 1 + ( (progress % ( 100 / MAGNITUDE.filter( (m, i) => (i % 2 === 1) ).length )) > 0 ? 1 : 0 );
                    let step = Math.floor(progress / (100 / MAGNITUDE_DARK.length)) - 1 + ((progress % (100 / MAGNITUDE_DARK.length)) > 0 ? 1 : 0);
                    return i === step;
                })
                .reduce((final, current) => current.color, null)
        ;


        return (


            <Suspense fallback={this.inWaiting()}>
                {
                    (!!this.state.stateModal)
                        ? (
                            <StateModal
                                questionId={this.state.scoreModal}
                                evaluation_id={this.state.stateModal}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        stateModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.helper)
                        ? (
                            <div
                                className={"helper-pannel-backdrop"}
                                onClick={ clickEvent => {
                                    clickEvent.stopPropagation();
                                    this.setState({
                                        ...this.state,
                                        helper: null
                                    })
                                }}
                            >
                                <div
                                    className={"helper-pannel"}
                                >
                                    <code>
                                        Helper Domanda <strong>{ this.state.helper.id }</strong>
                                    </code>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;status:&nbsp;&nbsp;
                                        <span
                                            className={"classic-evidentiation"}
                                        >
                                            { this.state.helper.status }
                                        </span>
                                        &nbsp;&nbsp;
                                        {
                                            getQuestionStatus( this.state.helper.status , { name: "" } )
                                                .name
                                        }
                                    </span>



                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={"Siamo in Audit"}
                                            defaultChecked={ !!this.isComeFromAuditer() }
                                            disabled={ true }
                                        />
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsComeFromAuditerIsOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsComeFromAuditerIsOpen: !this.state.helperIsComeFromAuditerIsOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsComeFromAuditerIsOpen } >
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"is from auditer into state"}
                                                defaultChecked={ !!this.state.isFromAuditer }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"is from auditer into props"}
                                                defaultChecked={ !!this.props.isFromAuditer }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"is from auditer into props ( navigation )"}
                                                defaultChecked={ (!!this.props.location && !!this.props.location.state && !!this.props.location.state.isFromAuditer) }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"stato valutazione === status completato ( "+ this.state.metaData.status +" | "+ RATING_STATUS.ratingComplete.id +" )"}
                                                defaultChecked={ this.state.metaData.status === RATING_STATUS.ratingComplete.id }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={ permissions.RATING_AUDIT.label }
                                                defaultChecked={ hasPermission(permissions.RATING_AUDIT) }
                                                disabled={ true }
                                            />
                                        </div>
                                    </Collapse>



                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={"isQuestionInAuditAndAuditIsRunning"}
                                            defaultChecked={ !!this.isQuestionInAuditAndAuditIsRunning( this.state.helper ) }
                                            disabled={ true }
                                        />
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsQuestionInAuditAndAuditIsRunning_open ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsQuestionInAuditAndAuditIsRunning_open: !this.state.helperIsQuestionInAuditAndAuditIsRunning_open
                                                })
                                            }}
                                        />
                                    </div>

                                    <Collapse isOpen={ !!this.state.helperIsQuestionInAuditAndAuditIsRunning_open } >
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"Siamo in Audit"}
                                                defaultChecked={ !!this.isComeFromAuditer() }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"metadata status >= rating status complete ( "+ this.state.metaData.status +" | "+ RATING_STATUS.ratingComplete.id +" )"}
                                                defaultChecked={ !!(parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.ratingComplete.id, 10)) }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"audit confermato da OPERATOR"}
                                                defaultChecked={ !!(parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.auditComplete.id, 10)) }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"audit validato da SUPER-ADMIN"}
                                                defaultChecked={ !!(parseInt(this.state.metaData.status, 10) === parseInt(RATING_STATUS.auditDone.id, 10)) }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={ permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION.label }
                                                defaultChecked={ (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION)) }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={ permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM.label }
                                                defaultChecked={ (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) }
                                                disabled={ true }
                                            />
                                        </div>
                                    </Collapse>



                                    <div>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={"Domanda abilitata"}
                                            defaultChecked={ !this.isDisabledQuestion( this.state.helper ) }
                                            disabled={ true }
                                            className={ !this.isDisabledQuestion( this.state.helper ) ? "color-green" : "color-red" }
                                        />
                                    </div>
                                    <div>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={ permissions.EDIT_ANSWER_SCORE.label }
                                            defaultChecked={ !!hasPermission(permissions.EDIT_ANSWER_SCORE) }
                                            disabled={ true }
                                            className={ !!hasPermission(permissions.EDIT_ANSWER_SCORE) ? "" : "color-red" }
                                        />
                                    </div>


                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <Label>Nodo</Label>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsNodeOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsNodeOpen: !this.state.helperIsNodeOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsNodeOpen } >
                                        <pre>
                                            {
                                                beautify( this.state.helper )
                                            }
                                        </pre>
                                    </Collapse>



                                    <br/>
                                    <br/>
                                    <code>Ruolo</code> <span>{ localStorage.getItem("role") }</span>
                                    <br/>
                                    <br/>



                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <code>Permessi attuali</code>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsPermissionOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsPermissionOpen: !this.state.helperIsPermissionOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsPermissionOpen } >
                                        <pre>
                                            {
                                                getAllPermissions()
                                                    .sort( sortById )
                                                    .map( (perm, permIndex) => (
                                                        <div className={ "helper-row " + (((permIndex % 2) === 0 ) ? "even-row" : "odd-row") } key={ permIndex }>
                                                            <code>{ perm.id }</code>
                                                            <span>{ perm.label }</span>
                                                        </div>
                                                    ))
                                            }
                                        </pre>
                                    </Collapse>




                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <Label>Sate</Label>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsStateOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsStateOpen: !this.state.helperIsStateOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsStateOpen } >
                                        {

                                            Object.keys( this.state )
                                                .filter( key => !["clientQuestions", "editAnswer", "nonCompliantReason", "questionInWait", "seed", "tree", "wait", "helper", "helperIsStateOpen"].includes( key ) )
                                                .map( key => {
                                                    return (
                                                        <React.Fragment key={ key }>
                                                            <code>{ key }</code>
                                                            <pre>
                                                                { beautify( this.state[ key ] ) }
                                                            </pre>
                                                        </React.Fragment>
                                                    );

                                                })

                                        }
                                    </Collapse>


                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <code>Props</code>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsPropsOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsPropsOpen: !this.state.helperIsPropsOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsPropsOpen } >
                                        <pre>{ beautify( this.props ) }</pre>
                                    </Collapse>



                                </div>
                            </div>
                        )
                        : null
                }

                {

                    (!!this.state.sendMailNcQuestions)
                        ? (
                            <SendMailNcQuestions
                                questionId={this.state.scoreModal}
                                evaluation_id={this.state.sendMailNcQuestions}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        sendMailNcQuestions: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.sendMailAttachmentsQuestions)
                        ? (
                            <SendMailAttachmentsQuestions
                                questionId={this.state.scoreModal}
                                evaluation_id={this.state.sendMailAttachmentsQuestions}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        sendMailAttachmentsQuestions: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {

                    (!!this.state.sendMailNewAttachmentsInserted)
                        ? (
                            <SendMailNewAttachmentsInserted
                                questionId={this.state.scoreModal}
                                evaluation_id={this.state.sendMailNewAttachmentsInserted}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        sendMailNewAttachmentsInserted: null
                                    })
                                }}
                            />
                        )
                        : null
                }






                {
                    (!!this.state.materialityReportModal)
                        ? (
                            <MaterailityReport
                                evaluation_id={this.getRatingId()}
                                isGenderGap={ true }
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        materialityReportModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (this.isComeFromAuditer())
                        ? null
                        : (
                            <PageTitle
                                breadCrumbItems={[
                                    {
                                        label: translate("Gender Gap") /*
                                        Object.keys(role)
                                            .map(roleObjectName => role[roleObjectName])
                                            .filter(roleObject => roleObject.name === localStorage.getItem("role"))
                                            .reduce(getLastElementOr, "Nessun Ruolo")
                                            .label */
                                        , path: PATH_PREFIX + `/${translate("gender-gap")}${ isUserRole( role.PROJECT_MANAGER ) ? "/pm": "" }/${translate("evaluations")}`
                                    },
                                    { label: translate("Evaluations"), path: PATH_PREFIX + `/${translate("gender-gap")}${ isUserRole( role.PROJECT_MANAGER ) ? "/pm": "" }/${translate("evaluations")}` },
                                    {
                                        label: (!!this.state && !!this.state.metaData) ? this.state.metaData.name : this.props.match.params.id,
                                        active: true
                                    }
                                ]}
                                title={translate("Gender Gap")}
                            />
                        )
                }


                {
                    (!!this.state.userRolesSa)
                        ? (
                            <EvaluationRolesArbModal
                                type={1}
                                evaluationId={this.state.userRolesSa}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        userRolesSa: null
                                    })
                                }}
                                onChoose={list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        userRolesSa: null
                                    })

                                }}
                            />
                        )
                        : null
                }
                {
                    (!!this.state.userRolesArb)
                        ? (
                            <EvaluationRolesArbModal
                                type={2}
                                evaluationId={this.state.userRolesArb}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        userRolesArb: null
                                    })
                                }}
                                onChoose={list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        userRolesArb: null
                                    })

                                }}
                            />
                        )
                        : null
                }


                {/*
                    !!this.state.ratingLegend
                        ? (
                            <RatingLegendModal
                                ratingId={ this.getRatingId() }
                                metadata={ this.state.metaData.info }
                                onClose={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        ratingLegend: null
                                    })
                                }}
                            />
                        )
                        : null
                */}

                {
                    !!this.state.ratingLegend
                        ? (
                            <Modal
                                isOpen={ true }
                                toggle={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        ratingLegend: null
                                    })
                                }}
                            >
                                <ModalHeader>Report Stati Domande</ModalHeader>
                                <ModalBody className={"table-simulated"}>
                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height table-header-column"}>Stato delle domande</div>
                                        <div className={"table-cell table-cell-height table-header-column"}>Quantità</div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Tutte</div>
                                        <div className={"table-cell table-cell-height"}>{ extractAllQuestions(this.state.tree).length }</div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Da analizzare</div>
                                        <div className={"table-cell table-cell-height"}></div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Da Rispondere</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => !question.status )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Risposte</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.finished.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Allegato Richiesto</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.attachmentWaiting.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Allegato da Validare</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.attachmentToValidate.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Info Aggiuntive</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.lowNonCompliant.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>NC Grave</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.highNonCompliant.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>NC in Attesa</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.waitNonCompliant.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Annullate</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.cancelled.id )
                                                    .length
                                            }
                                        </div>
                                    </div>

                                    <div className={"table-row"}>
                                        <div className={"table-cell table-cell-height"}>Validate</div>
                                        <div className={"table-cell table-cell-height"}>
                                            {
                                                extractAllQuestions(this.state.tree)
                                                    .filter( question => question.status === QUESTION_STATUS.validated.id )
                                                    .length
                                            }
                                        </div>
                                    </div>



                                </ModalBody>
                            </Modal>
                        )
                        : null
                }



                {
                    !!this.state.archiveRatingPrompt
                        ? (
                            <ArchiveRatingConfirmModal
                                id={ this.state.archiveRatingPrompt.id }
                                name={ this.state.archiveRatingPrompt.name }
                                onClose = {closeEvent => {

                                    this.setState({
                                        ...this.state,
                                        archiveRatingPrompt: false
                                    })

                                }}
                            />
                        )
                        : null
                }


                <Card
                    className={
                        (this.isComeFromAuditer())
                            ? (
                                (parseInt(this.state.metaData.status, 10) !== parseInt(RATING_STATUS.auditDone.id, 10))
                                    ? "card-in-audit-bg"
                                    : "card-in-audit-done-bg"
                            )
                            : ""
                    }
                >
                    <CardHeader
                        className={
                            !!hasPermission(permissions.DISPLAY_RATING_LEGEND)
                                ? ""    // era legend-container
                                : ""
                        }
                    >
                        <h4>
                            <span
                                className={(this.isComeFromAuditer()) ? "in-audit-header-text" : null}
                            >
                                {
                                    !!this.isComeFromAuditer()
                                        ? (<span className={"tag"} style={{background: "#18f", color: "#fff"}}>Audit</span>)
                                        : ""
                                }
                                &nbsp;
                                {
                                    !!this.state.editRatingName
                                        ? (

                                            <Input
                                                type={"text"}
                                                defaultValue={
                                                    (!!this.state && !!this.state.metaData)
                                                        ? this.state.metaData.name
                                                        : this.props.match.params.id
                                                }
                                                id={ "ratingName" }
                                                className={"rating-name-input"}
                                                onBlur={ blurEvent => {
                                                    let newName = blurEvent.target.value
                                                    this.setState({
                                                            ...this.state,
                                                            editRatingNameInWait: true,
                                                            editRatingName: false,
                                                            editRatingNameTmp: newName
                                                        },
                                                        () => {
                                                            // chiamata
                                                            setNewRatingName( this.getRatingId(), newName )
                                                                .then( result => {
                                                                    // this.loadData();

                                                                    this.setState({
                                                                        ...this.state,
                                                                        metaData: {
                                                                            ...this.state.metaData,
                                                                            name: newName
                                                                        },
                                                                        editRatingNameInWait: false
                                                                    });

                                                                })
                                                                .catch(e => {
                                                                    errorlog("Cambio nome al rating", e);
                                                                    // this.loadData();

                                                                    this.setState({
                                                                            ...this.state,
                                                                            editRatingNameInWait: false,
                                                                            editRatingNameError: e
                                                                        },
                                                                        () => {
                                                                            setTimeout(function() {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    editRatingNameError: null
                                                                                })
                                                                            }.bind( this ), 5000)
                                                                        }
                                                                    );


                                                                })
                                                        }
                                                    )
                                                }}
                                            />

                                        )
                                        : (
                                            !!this.state.editRatingNameInWait
                                                ? this.state.editRatingNameTmp
                                                : (
                                                    (!!this.state && !!this.state.metaData)
                                                        ? this.state.metaData.name
                                                        : this.props.match.params.id
                                                )
                                        )
                                }
                            </span>


                            {
                                (!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
                                    ? (
                                        !!this.state.editRatingNameInWait
                                            ? <Spinner color={"#18f"} className="assurance-wait"/>
                                            : (
                                                <i
                                                    className="mdi mdi-pencil tree_comander"
                                                    onClick={ clickEvent => {
                                                        this.setState({
                                                            ...this.state,
                                                            editRatingName: true
                                                        })
                                                    }}
                                                />
                                            )
                                    )
                                    : null
                            }


                            {
                                (!!isUserRole(role.PROJECT_MANAGER) || !!isUserRole(role.ARB_OPERATOR))
                                    ? (
                                        <i
                                            className="mdi mdi-delete trash"
                                            onClick={ clickEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    archiveRatingPrompt: {name: this.state.metaData.name, id: this.getRatingId() }
                                                })
                                            }}
                                        />
                                    )
                                    : null
                            }


                            {
                                !!this.state.editRatingNameError
                                    ? <span className={"form-error-label"}>{ this.state.editRatingNameError }</span>
                                    : null
                            }



                            <br /><br />
                        </h4>


                        {
                            (!!hasPermission(permissions.DISPLAY_RATING_LEGEND))
                                ? (


                                    <React.Fragment>

                                        <div style={{ float: "left" }}>

                                            <React.Fragment key={"legend-commands"}>

                                                <Row>
                                                    <Col sm="4">
                                                        <Row>
                                                            <Col sm="2">


                                                                <i
                                                                    style={{ color: "#f57f06" }}
                                                                    className={"mdi mdi-email-outline info-icon"}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        showlog("modale degli allegati");


                                                                        this.setState({
                                                                            ...this.state,
                                                                            sendMailNcQuestions: this.getRatingId()
                                                                        })

                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                <span style={{ fontWeight: "bold" }}>Invio Domande Chiarimentii</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col sm="4">

                                                        <Row>
                                                            <Col sm="2">

                                                                <i
                                                                    style={{ color: "#f57f06" }}
                                                                    className={"mdi mdi-email-outline info-icon"}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        showlog("modale degli allegati 1");


                                                                        this.setState({
                                                                            ...this.state,
                                                                            sendMailAttachmentsQuestions: this.getRatingId()
                                                                        })

                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                <span style={{ fontWeight: "bold" }}>Invio Domande per cui è stato richiesto l'allegato</span>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="4">

                                                        <Row>
                                                            <Col sm="2">

                                                                <i
                                                                    style={{ color: "#f57f06" }}
                                                                    className={"mdi mdi-email-outline info-icon"}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        showlog("modale degli allegati 2");


                                                                        this.setState({
                                                                            ...this.state,
                                                                            sendMailNewAttachmentsInserted: this.getRatingId()
                                                                        })

                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                <span style={{ fontWeight: "bold" }}>Invia notifica disponibilità nuovi documenti da scaricare</span>

                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>

                                                {
                                                    !!isUserRole( role.PROJECT_MANAGER )
                                                        ? (
                                                            <Row>

                                                                <Col sm="4">
                                                                    <Row>
                                                                        <Col sm="2">

                                                                            <i

                                                                                className={"mdi mdi-account-star info-icon"}
                                                                                onClick={clickEvent => {
                                                                                    clickEvent.stopPropagation();
                                                                                    showlog("modale degli allegati");


                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        userRolesSa: this.getRatingId()
                                                                                    })

                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                            <span style={{ fontWeight: "bold" }}>Assegna responsabili alla valutazione</span>

                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <Row>
                                                                        <Col sm="2">

                                                                            <i

                                                                                className={"mdi mdi-account-alert info-icon"}
                                                                                onClick={clickEvent => {
                                                                                    clickEvent.stopPropagation();
                                                                                    showlog("modale degli allegati");


                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        userRolesArb: this.getRatingId()
                                                                                    })

                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                            <span style={{ fontWeight: "bold" }}>Assegna operatori alla valutazione</span>
                                                                        </Col>


                                                                    </Row>
                                                                </Col>

                                                                <Col sm="4">
                                                                    <Row>


                                                                        <Col sm="2">
                                                                            <i
                                                                                style={{ color: "#d94be2" }}
                                                                                className={"mdi mdi-account-key info-icon"}
                                                                                onClick={clickEvent => {
                                                                                    clickEvent.stopPropagation();
                                                                                    showlog("modale degli allegati");
                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        stateModal: this.getRatingId()
                                                                                    })

                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                            <span style={{ fontWeight: "bold" }}>{ translate('Operator Actions History') }</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        )
                                                        : null
                                                }






                                                <Row>



                                                    <Col sm="4">
                                                        <Row>
                                                            <Col sm="2">
                                                                <i
                                                                    style={{ color: "rgb(51, 166, 16)" }}
                                                                    className={"mdi mdi-format-list-bulleted info-icon"}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        showlog("modale degli allegati");
                                                                        this.setState({
                                                                            ...this.state,
                                                                            materialityReportModal: this.getRatingId()
                                                                        })

                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                <span style={{ fontWeight: "bold" }}>Report Materialità</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>


                                                    {
                                                        (!!hasPermission(permissions.DISPLAY_RATING_TABLE_LEGEND))
                                                            ? (
                                                                <Col sm="4">
                                                                    <Row
                                                                        onClick={ clickEvent => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                ratingLegend: true
                                                                            });
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <Col sm="2">
                                                                            <i
                                                                                style={{ color: "rgb(51, 166, 16)" }}
                                                                                className={"mdi mdi-format-list-bulleted info-icon"}
                                                                                onClick={clickEvent => {
                                                                                    clickEvent.stopPropagation();
                                                                                    showlog("modale degli allegati");
                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        ratingLegend: this.getRatingId()
                                                                                    })

                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                            <span style={{ fontWeight: "bold" }}>Report Stati Domande</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                            : null
                                                    }




                                                </Row>
                                            </React.Fragment>

                                        </div>


                                    </React.Fragment>

                                )

                                : null

                        }



                        {
                            (!!this.state.ratingCloseModal)
                                ? (
                                    <CloseRatingConfirmModal
                                        id={this.state.ratingCloseModal}
                                        isGenderGap={ true }
                                        onClose={() => {
                                            this.setState({
                                                ...this.state,
                                                ratingCloseModal: null
                                            })
                                        }}
                                    />
                                )
                                : null
                        }


                        {
                            // helper
                            ( false )
                                ? (
                                    <div>
                                        <code>Metadata</code>
                                        <pre>{ beautify( this.state.metaData ) }</pre>

                                        <code>isComeFromAuditer</code>
                                        <pre>{ beautify( this.isComeFromAuditer() ) }</pre>
                                    </div>
                                )
                                : null
                        }

                        <span className="float-right">

                            {

                                (
                                    !this.isComeFromAuditer() // this.props.isFromAuditer
                                    && (parseInt(this.state.metaData.status, 10) === parseInt(RATING_STATUS.configurationValidated.id, 10))
                                )
                                    ? (
                                        (!!this.state.ready)
                                            ? (
                                                <ActionButton
                                                    buttonText={"Concludi Valutazione"}
                                                    promise={() => {

                                                        // prompt
                                                        return (
                                                            new Promise((success, fail) => {
                                                                this.setState({
                                                                        ...this.state,
                                                                        ratingCloseModal: this.getRatingId()
                                                                    },
                                                                    () => {
                                                                        success();
                                                                    }
                                                                )
                                                            })
                                                        )

                                                        // return setEvaluationStatus( this.getRatingId(), true );
                                                    }}
                                                    onResult={result => {

                                                        // PATH_PREFIX + "/sirating-admin/evaluations"
                                                        // window.location.href = PATH_PREFIX + "/sirating-admin/evaluations";

                                                    }}
                                                    onError={e => {
                                                        errorlog("errore",e);
                                                        this.setState({
                                                            ...this.state,
                                                            errorMessage: (!!e ? emoj(EMOJ.sad_but_relieved_face) + "  " + e : null)
                                                        });
                                                    }}
                                                />
                                            )
                                            : (
                                                <React.Fragment>
                                                    <div className={"tree_wizard_step_bar_label"}>
                                                        <span>{translate("Completed Questions")} {progress}%</span>
                                                    </div>
                                                    <Progress
                                                        animated
                                                        striped
                                                        color={progressColor}
                                                        value={progress}
                                                        className="mb-3 progress-sm"
                                                        id={"questionProgress"}
                                                    />
                                                </React.Fragment>
                                            )
                                    )
                                    : null

                            }

                        </span>



                    </CardHeader>

                    <CardBody>

                        <div className={"genderGap-top-card-part"}>

                            <div>

                                <Label for="subThemes">{translate("Filter")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                                <Select
                                    className="react-select question-filter-select"
                                    classNamePrefix="react-select"
                                    name="question-filter"
                                    id="question-filter"
                                    /*
                                    onclick={ clickEvent => {
                                        showlog("tendina cliccata");
                                    }}
                                    onClick={ clickEvent => {
                                        let el = document.getElementsByClassName("react-select__option");
                                        showlog("elementi della tendina");
                                    }}
                                    */
                                    onChange={changeEvent => {
                                        showlog("filtro");
                                        showlog(changeEvent);


                                        this.setState({
                                                ...this.state,
                                                selectedQuestionFilter: changeEvent.value
                                            },
                                            () => {

                                                // ricerca della prima domanda
                                                let firstQuestion =
                                                    extractAllQuestions(this.state.tree)
                                                        .filter(onlyNotNull)
                                                        .filter(this.filterQuestion)
                                                        .filter(onlyFirst)
                                                        .reduce(getLastElementOr, {})
                                                ;
                                                firstQuestion.open = true;

                                                let treeTmp = cloneObject(this.state.tree);
                                                let refreshedTree = cloneObject(this.state.tree);


                                                if (!!firstQuestion && !!firstQuestion.path) {

                                                    firstQuestion.path
                                                        .reduce((path, currentPath) => {

                                                            path = [...path, currentPath];
                                                            let node = getQuestionTreeNodeByPath(treeTmp, path);

                                                            if (path.length === 1) {
                                                                node.activeTab = node.id;
                                                                let nodeForTab = getQuestionTreeNodeByPath(refreshedTree, path);
                                                                nodeForTab.activeTab = node.id;
                                                            } else {
                                                                node.open = true;
                                                            }

                                                            return path;
                                                        }, [])
                                                    ;

                                                }



                                                if( changeEvent.value !== QUESTION_STATUS_ALL.value ) {
                                                    openAllNodes( this.state.tree )
                                                        .then( newTree => {
                                                            cloneOpenNodes(newTree, refreshedTree);
                                                            this.setState({
                                                                ...this.state,
                                                                tree: refreshedTree
                                                            })
                                                        })
                                                        .catch(e => {
                                                            errorlog("Apertura tutti i nodi")
                                                            showlog( e );
                                                        })
                                                    ;
                                                }
                                                else {
                                                    cloneOpenNodes(treeTmp, refreshedTree);
                                                    this.setState({
                                                        ...this.state,
                                                        tree: refreshedTree
                                                    })
                                                }




                                            }
                                        )


                                    }}
                                    options={
                                        ALL_POSSIBLE_QUESTION_STATUS
                                            .filter( qs => {
                                                if( !!this.isComeFromAuditer() ) {
                                                    return ( qs.value >= 10 || qs.value === QUESTION_STATUS_ALL.value )
                                                }
                                                else return true;
                                            })
                                    }
                                    value={
                                        (
                                            typeof this.state.selectedQuestionFilter !== "undefined"
                                            && this.state.selectedQuestionFilter !== null
                                            && this.state.selectedQuestionFilter !== undefined
                                        )
                                            ? (
                                                ALL_POSSIBLE_QUESTION_STATUS
                                                    .filter(questionStatus => questionStatus.value === this.state.selectedQuestionFilter)
                                                    .reduce(
                                                        getLastElementOr,
                                                        QUESTION_STATUS_ALL
                                                    )
                                            )
                                            : QUESTION_STATUS_ALL
                                    }
                                    placeholder={<span className={"select-placeholder"}>{translate("Select filter")}</span>}
                                />



                                {this.showConfirmAuditButton()}
                            </div>

                            { !!this.isComeFromAuditer() ? (<ActionButton
                                            buttonText={ "Valida tutte" }
                                            //promise={ () => acceptEUPA( props.match.params.id || props.id ) }
                                            //onResult={ () => setGoAhead( true ) }
                                            //onError={ error => {
                                            //	setErrorMessage( error );
                                            //	// setGoAhead( true );	// test
                                            //}}
                                            promise={ () => Promise.resolve() }
                                            onResult={ () => {

                                                        this.setState({
                                                            ...this.state,
                                                            confirmAllValidation: true
                                                        })
                                                } }
                                        />) : null

                            }

                            <div>


                                {
                                    (!!this.state.params.test && (this.state.params.test === "multisave"))
                                        ? (
                                            <ActionButton
                                                visible={ !this.showConfirmAuditButton() }
                                                promise={ () => {
                                                    // salvataggio di tutte le domande in edit

                                        let promiseCallList = [];
                                        let waitingQuestions = this.state.waitingQuestions || [];

                                        this.state.tree.tabs[0].themes
                                            .map( th => {
                                                th.questions
                                                    .filter( question => this.state.editAnswer.includes( question.id ))
                                                    .map( questionToSave => {


                                                                    waitingQuestions = [...waitingQuestions, questionToSave.id ];
                                                                    let ncAnswer = null;
                                                                    if (!!questionToSave.isOpenAnswer) {
                                                                        ncAnswer = questionToSave.scoreOpenQuestion;

                                                                        // promiseCallList.push( () => saveOpenAnswer( this.getRatingId(), questionToSave.id, questionToSave ) );

                                                                        promiseCallList.push(
                                                                            () => saveOpenAnswer( this.getRatingId(), questionToSave.id, this.state.openAnswerSettled.filter( q => q.questionID === questionToSave.id ).reduce( getLastElementOr, null ) )
                                                                        );

                                                                    }
                                                                    else {

                                                                        if(!!questionToSave.isMultipleAnswer) {}
                                                                        else {}

                                                                        ncAnswer =
                                                                            questionToSave.choiches
                                                                                .filter( ch => isNotEmptyArray(ch.answers) )
                                                                                .reduce( getLastElementOr, { score: 0 } )
                                                                                .score
                                                                        ;

                                                                        promiseCallList.push( () => processAnswers_syncChain(questionToSave, this.getRatingId(), ncAnswer) );
                                                                    }

                                                                    // ------------------------------------------------------------------------------------
                                                                })
                                                        })

                                                    let snapshot = cloneObject(this.state.tree);
                                                    return (
                                                        new Promise((success, fail) => {
                                                            this.setState({
                                                                    ...this.state,
                                                                    waitingQuestions: waitingQuestions
                                                                },
                                                                () => {
                                                                    success();
                                                                }
                                                            )
                                                        })
                                                            .then( () => {
                                                                /*
                                                                return (
                                                                    this.saveChain( promiseCallList )
                                                                        .then( result => {
                                                                            showlog("catena finita");
                                                                            return result;
                                                                        })
                                                                        .catch(e => Promise.reject( e ))
                                                                );
                                                                */


                                                                return (
                                                                    Promise.all( promiseCallList.map( p => p() ) )
                                                                        .then( resultList => {
                                                                            showlog( "resultList" );
                                                                            showlog( resultList );
                                                                            return ( true );
                                                                        })
                                                                        .catch(e => {
                                                                            let errorMessage = extractErrorMessage( e );
                                                                            errorlog("Promise list", e);
                                                                            return Promise.reject( e );
                                                                        })
                                                                );


                                                            })
                                                            .catch(e =>  Promise.reject( e ))
                                                    );

                                                    //
                                                }}
                                                onProcess={ () => {

                                                }}
                                                onResult={ result => {

                                                    showlog("result finale");
                                                    showlog( result );

                                                    // reload page
                                                    this.loadData( cloneObject(this.state.tree) )
                                                        .then(() => {

                                                            let isRatingCompleted = true;
                                                            this.state.tree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            isRatingCompleted &= th.questions.every( q => q.status === QUESTION_STATUS.finished.id )
                                                                        })
                                                                })
                                                            ;

                                                            this.setState({
                                                                ...this.state,
                                                                editAnswer: [],
                                                                waitingQuestions: [],
                                                                ready: isRatingCompleted
                                                            });

                                                        })
                                                        .catch(e => {
                                                            errorlog(" single rating load data",e);
                                                        })


                                                    ;

                                                }}
                                                onError={ e => {
                                                    let errorMessage = extractErrorMessage( e );
                                                    errorlog("Save", e);
                                                    this.setState({
                                                        ...this.state,
                                                        errorMessage: errorMessage
                                                    });
                                                }}
                                            />
                                                    )
                                                    : null
                                            }



                                            <TreeToggler
                                                tree={this.state.tree}
                                                onFinish={newTree => {
                                                    this.setState({
                                                        ...this.state,
                                                        tree: newTree
                                                    })
                                                }}
                                            />
                                        </div>

                                    </div>


                                    <CardBody>


                                            {


                                                (!!this.state.wait)
                                                    ? (
                                                        <WaitingOrNodataMessage
                                                            waiting={!!this.state.wait /*&& ( this.state.tree.tabs.map( tab => tab.themes.length > 0 ).filter( onlyNotNull ).length > 0 )*/}
                                                nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                                                fullHeight={true}
                                                className={"bottom-margin-alignment"}
                                            />
                                        )
                                        : (
                                            <Row>
                                                <Col sm="12">

                                                    {
                                                        this.state.tree.tabs[0].themes
                                                            .map((th, chIndex) => {

                                                                let themeQuestionList = extractAllQuestions(this.state.tree, this.state.tree.tabs[0], th);

                                                                let progress =
                                                                    themeQuestionList
                                                                        // ATTENZIONE vengono tenute in considerazione anche le domande
                                                                        // che si trovano in uno stato intermedio
                                                                        .filter(s => s.status >= QUESTION_STATUS.incomplete.id)
                                                                        .length
                                                                    + "/" +
                                                                    themeQuestionList.length
                                                                ;

                                                                // let progressColor = (themeQuestionList.filter( s => !!s.isCompleted ).length < themeQuestionList.length) ? COLOR_POLICY.error : COLOR_POLICY.validated;
                                                                let progressColor =
                                                                    (themeQuestionList.filter(s => s.status >= QUESTION_STATUS.finished.id).length < themeQuestionList.length)
                                                                        ? COLOR_POLICY.error
                                                                        : COLOR_POLICY.validated
                                                                ;

                                                                // ;

                                                                let colorClassInfo =
                                                                    Object.keys(TABS)
                                                                        .map( tabKey => TABS[tabKey] )
                                                                        // .filter( this.state.tree.tabs[0] => (parseInt(GENDER_GAP_TAB_ID, 10) +1) === GENDER_GAP_TAB_ID )
                                                                        .filter( t => t.siratingId === GENDER_GAP_TAB_ID )
                                                                        .reduce( getLastElementOr, { className: "" } )
                                                                        .className
                                                                ;





                                                                return (
                                                                    <Card className="mb-0"
                                                                          key={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
                                                                        <CardHeader
                                                                            className={
                                                                                // classnames({ active: GENDER_GAP_TAB_ID === GENDER_GAP_TAB_ID })
                                                                                [
                                                                                    colorClassInfo
                                                                                ]
                                                                                    .join(" ")
                                                                            }
                                                                        >

                                                                            <NavLink
                                                                                id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}
                                                                                href="#"
                                                                                onClick={clickEvent => {

                                                                                    let treeTmp = cloneObject(this.state.tree);
                                                                                    let nodeTmp = getQuestionTreeNodeByPath(treeTmp, th.path);
                                                                                    nodeTmp.open = !getQuestionTreeNodeByPath(this.state.tree, th.path).open;



                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        tree: treeTmp
                                                                                    });


                                                                                }}
                                                                            >
                                                                                <h5 className="m-0">
                                                                                    <div
                                                                                        className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">

                                                                                        { this.helperNode( th ) }
                                                                                        {th.name}
                                                                                    </div>






                                                                                    {
                                                                                        (
                                                                                            !!hasPermission( permissions.SIRATING_READ_MATERIALITY_LEVEL )
                                                                                            && !!hasPermission( permissions.SIRATING_EDIT_MATERIALITY_LEVEL )
                                                                                            && !!th.materiality
                                                                                            && this.canEditMateriality()
                                                                                        )
                                                                                            ? (
                                                                                                <span
                                                                                                    className={"materiality-label"}
                                                                                                    onClick={clickEvent => {

                                                                                                        if( /*!!this.isQuestionInAuditAndAuditIsRunning() &&*/ !!th.materiality ) {

                                                                                                            let positionY = window.scrollY;
                                                                                                            showlog("position y "+ positionY);
                                                                                                            clickEvent.stopPropagation();
                                                                                                            this.setState({
                                                                                                                    ...this.state,
                                                                                                                    editMaterialityPopup: th
                                                                                                                },
                                                                                                                () => {
                                                                                                                    setTimeout(
                                                                                                                        () => {
                                                                                                                            window.scrollTo(0, positionY);
                                                                                                                            showlog("scrolled to "+ positionY);
                                                                                                                        },
                                                                                                                        5
                                                                                                                    )
                                                                                                                }
                                                                                                            )

                                                                                                        }


                                                                                                    }}
                                                                                                >
                                                                                                    {

                                                                                                        (!!th.materiality)
                                                                                                            ? (
                                                                                                                "Materialità " +
                                                                                                                MATERIALITY_REASON_LEVEL
                                                                                                                    .filter(m => m.value === th.materiality.value)
                                                                                                                    .map(m => m.label)
                                                                                                                    .reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0].label)
                                                                                                            )
                                                                                                            : null

                                                                                                    }

                                                                                                    {
                                                                                                        (
                                                                                                            !!hasPermission( permissions.SIRATING_READ_MATERIALITY_LEVEL )
                                                                                                            && !!hasPermission( permissions.SIRATING_EDIT_MATERIALITY_LEVEL )
                                                                                                            && !!th.materiality
                                                                                                            && this.canEditMateriality()
                                                                                                        )
                                                                                                            ? <i className={"uil-edit-alt materiality_edit-icon"} />
                                                                                                            : null
                                                                                                    }

                                                                                                </span>
                                                                                            )
                                                                                            : null
                                                                                    }




                                                                                    <span
                                                                                        className="float-right">


                                                                                        {
                                                                                            (!!th.open)
                                                                                                ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
                                                                                                : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
                                                                                        }
                                                                                    </span>
                                                                                    {
                                                                                        (this.isComeFromAuditer())
                                                                                            ? null
                                                                                            : (
                                                                                                <span
                                                                                                    className={"float-right set-value-button-container-is-navlink " + progressColor.textClass}
                                                                                                >
                                                                                                    {progress}
                                                                                                </span>
                                                                                            )
                                                                                    }
                                                                                </h5>

                                                                            </NavLink>
                                                                        </CardHeader>
                                                                        {/*<UncontrolledCollapse defaultOpen={ !!th.open } toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>*/}
                                                                        <Collapse isOpen={!!th.open}
                                                                                  toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
                                                                            <CardBody>


                                                                                {
                                                                                    // domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                    (!!th.open)
                                                                                        ? this.buildQuestions(th.questions)
                                                                                        : (
                                                                                            <WaitingOrNodataMessage
                                                                                                waiting={true}
                                                                                                nodataMessage={"in caricamento..."}
                                                                                            />
                                                                                        )
                                                                                }

                                                                                {   // sotto-tematica --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                    (!!th.open)
                                                                                        ? this.buildSubThemes(th.subThemes, GENDER_GAP_TAB_ID) // non dovrebbero esserci
                                                                                        : null

                                                                                }

                                                                            </CardBody>
                                                                        </Collapse>
                                                                    </Card>
                                                                );
                                                            })

                                                    }

                                                </Col>
                                            </Row>
                                        )
                                }


                                <GotoTopComponent/>

                        </CardBody>

                    </CardBody>

                </Card>


                {
                    (!!this.state.errorMessage)
                        ? (
                            <ErrorMessageModalComponent
                                message={this.state.errorMessage}
                                noheader={true}
                                noHazard={true}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        errorMessage: null
                                    })
                                }}
                            />
                        )
                        : null
                }




                {
                    !!this.state.preventiveMessageAlert
                        ? (
                            alert("ATTENZIONE!\nbisogna rispondere alla domanda preventiva")
                        )
                        : null
                }


                {
                    !!this.state.preventiveQuestionPopup
                        ? (
                            <PreventiveQuestionModal
                                rating={ this.getRatingId() }
                                question={ this.state.preventiveQuestionPopup }
                                // ready={ this.state.preventiveQuestionPopupReady }
                                ready={ this.state.lastLoadTimestamp }
                                onChoose={ preventiveQuestionChoiche => {

                                    this.setState({
                                        ...this.state,
                                        lastLoadTimestamp: (new Date()).getTime(),
                                        // preventiveQuestionPopup: null
                                        preventiveQuestionPopupReady: false,
                                        preventiveMessageAlert: null
                                    })
                                    this.loadData( cloneObject(this.state.tree) )
                                        .then( result => {
                                            this.setState({
                                                ...this.state,
                                                preventiveQuestionPopup: null,
                                                preventiveQuestionPopupReady: false
                                            })
                                        })
                                        .catch(e => {
                                            errorlog("LOAD DATA", e);
                                        })
                                    ;

                                        /*
                                        .then( () => {

                                            this.setState({
                                                ...this.state,
                                                // preventiveQuestionPopup: null
                                                preventiveQuestionPopupReady: true
                                            })
                                        })
                                        .catch(e => {
                                            errorlog("load data after preventive", e);
                                        })
                                         */

                                }}
                                onClose={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        preventiveQuestionPopup: null,
                                        preventiveQuestionPopupReady: false
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.inWaiting)
                        ? (
                            <StandBySystemModalComponent
                                message={this.state.processingMessage}
                                didMount={() => {

                                    /*
                                    this.setState({
                                        ...this.state,
                                        stopable: true
                                    })
                                    */
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.historyModal)
                        ? (
                            <SiRatingQuestionHistoryModal
                                ratingId={this.getRatingId()}
                                questionId={this.state.historyModal.id}
                                onClose={closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        historyModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    !!this.state.editMaterialityPopup
                        ? (
                            <EditMaterialityModal
                                ratingId={this.getRatingId()}
                                node={this.state.editMaterialityPopup}
                                onClose={isToReload => {
                                    this.setState({
                                            ...this.state,
                                            editMaterialityPopup: null
                                        },
                                        () => {
                                            if (!!isToReload) this.loadData( this.state.tree );
                                        }
                                    )
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.attachmentModal)
                        ? (
                            <SiRatingQuestionAttachmentModal
                                questionId={this.state.attachmentModalQuestionId}
                                question={this.state.attachmentModalQuestion}
                                ratingId={this.getRatingId()}
                                files={this.state.attachmentModal}
                                disableUpload={this.isDisabledQuestion(this.state.attachmentModalQuestionId)}
                                disableDelete={this.isDisabledQuestion(this.state.attachmentModalQuestionId)}
                                onAttachmentUploaded={attachments => {

                                    // ATENZIONE non prende i cancellati
                                    // let treeTmp = cloneObject(this.state.tree);
                                    // let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, getQuestionPath(treeTmp, this.state.attachmentModalQuestionId));

                                    let treeTmp = cloneObject(this.state.tree);
                                    let questionPath = getQuestionPath(treeTmp, this.state.attachmentModalQuestionId);

                                    let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                    refreshedQuestion.attachments = [...refreshedQuestion.attachments, ...attachments];


                                    this.loadData(cloneObject(this.state.tree))
                                        .then(() => {


                                            // probabilmente non serve

                                            // cloning open nodes
                                            let refreshedTree = cloneObject(this.state.tree);


                                            cloneOpenNodes(treeTmp, refreshedTree);


                                            this.setState({
                                                ...this.state,
                                                tree: refreshedTree
                                            });


                                        })
                                        .catch(e => {
                                            errorlog(" single rating load data",e);
                                        })

                                }}
                                onAttachmentDelete={attachmentIds => {
                                    // let treeTmp = cloneObject(this.state.tree);
                                    // let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, getQuestionPath(treeTmp, this.state.attachmentModalQuestionId));
                                    let treeTmp = cloneObject(this.state.tree);
                                    let questionPath = getQuestionPath(treeTmp, this.state.attachmentModalQuestionId);
                                    let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, questionPath);
                                    refreshedQuestion.attachments = refreshedQuestion.attachments.filter(a => !!!attachmentIds.includes(a.attachment_id));

                                    this.setState({
                                            ...this.state,
                                            tree: treeTmp
                                        },


                                        () => {

                                            this.loadData(cloneObject(this.state.tree))
                                                .then(() => {
                                                    this.setState({
                                                        ...this.state,
                                                        tree: treeTmp
                                                    });
                                                })
                                                .catch(e => {
                                                    errorlog(" single rating load data",e);
                                                })
                                            ;

                                        }
                                    )
                                }}
                                onClose={() => {
                                    this.setState({
                                            ...this.state,
                                            attachmentModal: null,
                                            attachmentModalQuestionId: 0
                                        },
                                        // this.loadData
                                    )
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.showNCMessage)
                        ? (
                            <NCMessageModal
                                ratingId={this.getRatingId()}
                                message={this.state.showNCMessage.ncMessage}
                                question={this.state.showNCMessage}
                                status={this.state.showNCMessage.status}
                                onClose={isToRefresh => {

                                    if (!!isToRefresh) {
                                        this.loadData(cloneObject(this.state.tree))
                                            .then(data => {
                                                this.setState({
                                                    ...this.state,
                                                    showNCMessage: null
                                                })
                                            })
                                        ;
                                    } else {
                                        this.setState({
                                            ...this.state,
                                            showNCMessage: null
                                        })
                                    }

                                }}
                            />
                        )
                        : null
                }



                {
                    (!!this.state.showAttWaitMessage)
                        ? (
                            <NCMessageModal
                                ratingId={this.getRatingId()}
                                message={this.state.showAttWaitMessage.ncMessage}
                                question={this.state.showAttWaitMessage}
                                status={this.state.showAttWaitMessage.status}
                                onClose={isToRefresh => {

                                    if (!!isToRefresh) {
                                        this.loadData(cloneObject(this.state.tree))
                                            .then(data => {
                                                this.setState({
                                                    ...this.state,
                                                    showAttWaitMessage: null
                                                })
                                            })
                                        ;
                                    } else {
                                        this.setState({
                                            ...this.state,
                                            showAttWaitMessage: null
                                        })
                                    }

                                }}
                            />
                        )
                        : null
                }



                {
                    (!!this.state.notePopup)
                        ? (
                            <QuestionNoteModal
                                ratingId={ this.getRatingId() }
                                question={ this.state.notePopup }
                                isInAudit={ this.isComeFromAuditer() && this.isQuestionInAuditAndAuditIsRunning( this.state.notePopup ) }
                                onSave={ result => {

                                    let refreshedTree = cloneObject(this.state.tree);
                                    let refreshedQuestion =
                                        getQuestionTreeNodeByPath(
                                            refreshedTree,   // albero
                                            this.state.notePopup.path
                                        )
                                    ;
                                    refreshedQuestion.hasNote = !!result;
                                    this.setState({
                                        ...this.state,
                                        tree: refreshedTree
                                    })

                                }}
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        notePopup: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.adminNotePopup)
                        ? (
                            <QuestionNoteModal
                                isAdmin={ true }
                                ratingId={ this.getRatingId() }
                                question={ this.state.adminNotePopup }
                                isInAudit={ this.isComeFromAuditer() && this.isQuestionInAuditAndAuditIsRunning( this.state.notePopup ) && !( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ) ) }
                                onSave={ result => {

                                    let refreshedTree = cloneObject(this.state.tree);
                                    let refreshedQuestion =
                                        getQuestionTreeNodeByPath(
                                            refreshedTree,   // albero
                                            this.state.adminNotePopup.path
                                        )
                                    ;
                                    refreshedQuestion.hasAdminNote = !!result;
                                    this.setState({
                                        ...this.state,
                                        tree: refreshedTree
                                    })

                                }}
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        adminNotePopup: null
                                    })
                                }}
                            />
                        )
                        : null
                }




            </Suspense>
        );
    }

    componentDidMount() {
        /*
        this.setState({
            ...this.state,
            errorMessage: "Elaborazione in corso",
        },
            () => {
                this.loadData();
            }
        )
        */
        this.loadData();

    }


    buildSubThemes(subThemes, tabid) {


        showlog("Sotto Tematica");
        showlog( subThemes, tabid );

        return (
            subThemes
                .map((sth, infoIndex) => {


                    let subThemeQuestionList =
                        extractAllQuestions(
                            this.state.tree,
                            getQuestionTreeNodeByPath(this.state.tree, sth.path.filter((p, i) => i < (sth.path.length - 2))),
                            getQuestionTreeNodeByPath(this.state.tree, sth.path.filter((p, i) => i < (sth.path.length - 1))),
                            sth
                        )
                    ;


                    let progress =
                        subThemeQuestionList
                            // ATTENZIONE vengono tenute in considerazione anche le domande
                            // che si trovano in uno stato intermedio
                            .filter(s => s.status >= QUESTION_STATUS.incomplete.id)
                            .length
                        + "/" +
                        subThemeQuestionList.length
                    ;

                    // let progressColor = (subThemeQuestionList.filter( s => !!s.isCompleted ).length < subThemeQuestionList.length) ? COLOR_POLICY.error : COLOR_POLICY.validated;
                    let progressColor =
                        // era finished
                        (subThemeQuestionList.filter(s => s.status >= QUESTION_STATUS.incomplete.id).length < subThemeQuestionList.length)
                            ? COLOR_POLICY.error
                            : COLOR_POLICY.validated
                    ;


                    let chapterColorClassInfo =
                        Object.keys(TABS)
                            .map( tabKey => TABS[tabKey] )
                            .filter( t => t.siratingId === tabid )
                            .reduce( getLastElementOr, { lightClassName: "" } )
                            .lightClassName
                    ;



                    return (
                        <Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
                            <CardHeader
                                className={
                                    [
                                        chapterColorClassInfo
                                    ]
                                        .join(" ")
                                }
                            >

                                <NavLink

                                    id={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}
                                    href="#"
                                    onClick={clickEvent => {

                                        let treeTmp = cloneObject(this.state.tree);
                                        let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, sth.path);
                                        nodeTmp.open = !getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open;
                                        this.setState({
                                            ...this.state,
                                            tree: treeTmp
                                        });


                                    }}
                                >


                                    <h5 className="m-0">
                                        {/*		custom-accordion-title		*/}
                                        <div className=" pt-2 pb-2 sirating-themes-accordion-title">

                                            { this.helperNode( sth ) }
                                            {sth.name}


                                            {
                                                (
                                                    !!hasPermission( permissions.SIRATING_READ_MATERIALITY_LEVEL )
                                                    && !!hasPermission( permissions.SIRATING_EDIT_MATERIALITY_LEVEL )
                                                    && !!sth.materiality
                                                    && this.canEditMateriality()
                                                )
                                                    ? (
                                                        <span
                                                            className={"materiality-label"}
                                                            onClick={clickEvent => {
                                                                let positionY = window.scrollY;
                                                                showlog("posizione y: "+ positionY);
                                                                clickEvent.stopPropagation();
                                                                this.setState({
                                                                        ...this.state,
                                                                        editMaterialityPopup: sth
                                                                    },
                                                                    () => {
                                                                        setTimeout(
                                                                            () => {
                                                                                window.scrollTo(0, positionY);
                                                                                showlog("scrolled to "+ positionY);
                                                                            },
                                                                            20
                                                                        )
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            Materialità {
                                                            MATERIALITY_REASON_LEVEL
                                                                .filter(m => m.value === sth.materiality.value)
                                                                .map(m => m.label)
                                                                .reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0].label)
                                                        }
                                                            <i
                                                                className={"uil-edit-alt materiality_edit-icon"}
                                                            />
                                                        </span>
                                                    )
                                                    : null
                                            }

                                        </div>


                                        <span className="float-right">

                                            {
                                                /*
                                                dripicons-plus
                                                mdi mdi-folder-plus
                                                mdi mdi-plus-box-multiple
                                                mdi mdi-timeline-plus
                                                mdi mdi-timeline-plus-outline
                                                */
                                                (!!getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open)
                                                    ?
                                                    <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
                                                    : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
                                            }
                                        </span>
                                        {
                                            (this.isComeFromAuditer())
                                                ? null
                                                : (
                                                    <span
                                                        className={"float-right set-value-button-container-is-navlink " + progressColor.textClass}>{progress}</span>
                                                )
                                        }
                                    </h5>
                                </NavLink>

                            </CardHeader>
                            {/*<UncontrolledCollapse defaultOpen={ !!sth.open } toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>*/}
                            <Collapse isOpen={!!sth.open}
                                      toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
                                <CardBody>

                                    {
                                        // domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                        (!!sth.open)
                                            ? this.buildQuestions(sth.questions)
                                            : (
                                                (
                                                    <WaitingOrNodataMessage
                                                        waiting={true}
                                                        nodataMessage={"in caricamento..."}
                                                    />
                                                )
                                            )

                                    }

                                </CardBody>
                            </Collapse>
                        </Card>
                    );
                })
        );

    }


    filterQuestion = question => {


        let activeFilter =
            ALL_POSSIBLE_QUESTION_STATUS
                .filter(questionStatus => questionStatus.value === this.state.selectedQuestionFilter)
                .reduce(
                    (final, current) => ({ id: current.value, ...current }),
                    QUESTION_STATUS_ALL
                )
        ;

        let filter = false;

        switch (activeFilter.value) {

            case QUESTION_STATUS_ALL.value:     // ----------------------------------- tutte le domande ----
                // nessun filtro
                filter = true;
                break;

            case QUESTION_STATUS.virgin.id:     // -------------------------------- domande mai toccate ----

                if( question.status === QUESTION_STATUS.virgin.id )         filter = true;
                if( question.status === QUESTION_STATUS.incomplete.id )     filter = true;
                if (!!this.state.editAnswer.some(ea => ea === question.id)) filter = true;
                else {
                    if (!!!question.isCompleted) {
                        // filter = true;
                    }
                }

                break;
            case -2: // -------------------------------------------------------------------------- edit ----
                filter = (!!this.state.editAnswer.some(ea => ea === question.id));
                break;


            case -3: // -------------------------------------------------------------------------------------
            case -4: // -------------------------------------------------------------------------------------
            case 21: // -------------------------------------------------------------------------------------
                filter = ( !!activeFilter.isPassCondition( question.status ) );
                break;


            /*
            case QUESTION_STATUS.incomplete.id:   // ---------------------------- domande da completare ----
                if (!!this.state.editAnswer.some(ea => ea === question.id)) {
                    // edit
                } else {
                    filter = (question.status === QUESTION_STATUS.incomplete.id);
                }
                break;
            */

            case QUESTION_STATUS.finished.id:   // --------------------------------- domande completate ----
                if (!!this.state.editAnswer.some(ea => ea === question.id)) {
                    // edit
                } else {
                    // if (!!question.isCompleted) {
                    // complete
                    filter = (question.status === QUESTION_STATUS.finished.id);
                    // }
                }
                break;
            case QUESTION_STATUS.validated.id:  // ----------------------------------- domande validate ----
                if (!!this.state.editAnswer.some(ea => ea === question.id)) {
                    // edit
                } else {
                    if (question.status === QUESTION_STATUS.validated.id) filter = true;
                    /*
                    if (!!question.isCompleted) {
                        // complete
                    }
                    else {
                        if( question.status === QUESTION_STATUS.validated.id ) filter = true;
                    }
                    */
                }

                break;
            case QUESTION_STATUS.lowNonCompliant.id:     // ----------------------- Non conformità lieve ----
                // if (question.status === QUESTION_STATUS.lowNonCompliant.id) filter = true;
                filter = ( !!activeFilter.isPassCondition( question.status ) );
                break;
            case QUESTION_STATUS.waitNonCompliant.id:    // ---- Non conforme e in attesa di validazione ----
                if (question.status === QUESTION_STATUS.waitNonCompliant.id) filter = true;
                break;
            case QUESTION_STATUS.highNonCompliant.id:    // ------------------------ Non conformità alta ----
                if (question.status === QUESTION_STATUS.highNonCompliant.id) filter = true;
                break;
            case QUESTION_STATUS.cancelled.id:    // ------------------------ Annullata ----
                if (question.status === QUESTION_STATUS.cancelled.id) filter = true;
                break;
        }

        return filter;


    }




    subQuestionRequest = (dataList, currentIndex = 0) /*(question, choiche, choicheIndex)*/ => {


        /*
        {
            choiche_id: 5,
            choiche_name: "esiste (specificare)",
            isRequiredAttachment: false,
            isRequiredTextBox: true,
            ordering: 1,
            score: 0,
            isOpeningQuestions: 0
        }
        */


        let question = null;
        let choiche = null;


        // dati nel sistema
        let checkedChoiche = false;
        let checkedSingleChoiche = false;

        let choicheDOM = null;
        let treeTmp = cloneObject(this.state.tree);
        let questionPath = [];
        let questionTreeNode = null;
        let questionTreeNodeList = [];

        let refreshedChoiche = null;


        return (

            Promise.all(
                dataList
                    .filter(data => {

                        question = data.q;
                        choiche = data.cs;


                        // dati nel sistema
                        checkedChoiche = ((!!choiche.answers && choiche.answers.length > 0) || !!choiche.checked);
                        checkedSingleChoiche = ((!!choiche.answers && choiche.answers.length > 0 && choiche.answers[0].choiche_id === choiche.choiche_id));

                        choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
                        // treeTmp = cloneObject(this.state.tree);
                        questionPath = [];
                        if (!!isNotEmptyArray(question.path)) questionPath = question.path;
                        else questionPath = getQuestionPath(treeTmp, question.id);

                        questionTreeNode = getQuestionTreeNodeByPath(treeTmp, questionPath);

                        if (!!!questionTreeNode) {
                            warninglog("Attenzione al path della question");
                            showlog(questionPath);
                            // ;
                        }


                        refreshedChoiche =
                            questionTreeNode.choiches
                                .filter(ch => ch.choiche_id === choiche.choiche_id)
                                .reduce(getLastElementOr, null)
                        ;


                        if (!!checkedSingleChoiche) {

                            showlog("chiamata di refresh", (!!choiche.isOpeningQuestions && (!!!isNotEmptyArray(question.child) || (question.child.length === 1 && !!question.child[0].tmp))));

                            if (!!choiche.isOpeningQuestions && !!!isNotEmptyArray(question.child) && !choiche.inWait) {
                                return true;
                            } else {
                                return false;
                            }

                        } else {
                            return false;
                        }

                    })


                    .map((data, index) => {


                        // ;

                        question = data.q;
                        choiche = data.cs;


                        // dati nel sistema
                        checkedChoiche = ((!!choiche.answers && choiche.answers.length > 0) || !!choiche.checked);
                        checkedSingleChoiche = ((!!choiche.answers && choiche.answers.length > 0 && choiche.answers[0].choiche_id === choiche.choiche_id));

                        choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
                        // treeTmp = cloneObject(this.state.tree);
                        questionPath = [];
                        if (!!isNotEmptyArray(question.path)) questionPath = question.path;
                        else questionPath = getQuestionPath(treeTmp, question.id);

                        questionTreeNode = getQuestionTreeNodeByPath(treeTmp, questionPath);

                        if (!!!questionTreeNode) {
                            warninglog("Attenzione al path della question");
                            showlog(questionPath);
                            // ;
                        } else {
                            refreshedChoiche =
                                questionTreeNode.choiches
                                    .filter(ch => ch.choiche_id === choiche.choiche_id)
                                    .reduce(getLastElementOr, null)
                            ;
                        }


                        questionTreeNodeList.push({ questionId: question.id, children: [] });

                        return (
                            loadAnswers(this.getRatingId(), GENDER_GAP_TAB_ID, refreshedChoiche.choiche_id)

                                // editTree ----------------------------------------------
                                .then(subQuestions => {

                                    // ;
                                    // showlog(index, question.id, refreshedChoiche.choiche_id);


                                    if (!!!isNotEmptyArray(subQuestions)) {
                                        // non arrivano le sotto-domande
                                        // si verifica se c'è un errore lato server

                                        questionTreeNode.child = [{ status: "server-error" }]
                                        return Promise.reject();
                                    }

                                    return (
                                        // convertSIRatingSubQuestionTableToSubQuestionChild( subQuestions, getQuestionPath(treeTmp, question.id) )
                                        convertSIRatingSubQuestionTableToSubQuestionChild(subQuestions, getQuestionPath(treeTmp, questionTreeNodeList[index].questionId))
                                            .then(nodes => {
                                                // questionTreeNode.child = nodes;
                                                // questionTreeNode.open = true;
                                                // nodes.map( n => n.code = n.question_code );

                                                // getQuestionTreeNodeByPath( treeTmp, getQuestionPath(treeTmp, question.id) ).child = nodes;;


                                                // questionTreeNode.child = nodes;
                                                questionTreeNode.open = true;
                                                choiche.inWait = false;

                                                questionTreeNode.child =
                                                    nodes
                                                        .map(qc => {
                                                            qc.open = true;
                                                            return qc;
                                                        })
                                                ;

                                                // questionTreeNodeList.push({questionId: question.id, children: nodes});

                                                // return questionTreeNode;

                                                // showlog(index, question.id, refreshedChoiche.choiche_id);
                                                questionTreeNodeList[index].children = nodes;

                                                // showlog(nodes);
                                                // ;
                                                return true;

                                            })
                                            .catch(e => {
                                                errorlog("errore",e);
                                                return Promise.reject(e);
                                            })
                                    );

                                })


                                // finish ---------------------------------------------------
                                .then(qTreeNode => {

                                    // ;

                                    if (!!checkedSingleChoiche) refreshedChoiche.active = true;

                                    choiche.inWait = false;
                                    refreshedChoiche.inWait = false;


                                    return qTreeNode;

                                })


                                .catch(e => {
                                    errorlog("errore",e);
                                    /*
                                    this.setState({
                                        ...this.state,
                                        subQuestionWait: null
                                    })
                                    */
                                })
                        );


                    })
            )
                .then(qTreeNode => {


                    // questionTreeNodeList


                    questionTreeNodeList
                        .map(data => {
                            let questionPathTmp = getQuestionPath(treeTmp, data.questionId);
                            let questionTreeNodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPathTmp);
                            questionTreeNodeTmp.child = data.children;
                            // showlog(data, questionTreeNodeTmp.child.length);
                        })
                    ;

                    showlog(treeTmp);

                    // ;


                    /*
                    // controllo disabilitazione di tutte le domande
                    è stato fatto in this.isDisabledQuestion()
                    if( !!isUserRole( role.SUPER_ADMIN ) ) {

                        let questionList = extractAllQuestions( treeTmp );
                        questionList.map(q => {




                        });
                    }
                    */




                    return (
                        new Promise( (finishedOk, finishedError) => {

                            this.setState({
                                    ...this.state,
                                    // subQuestionWait: refreshedChoiche.choiche_id,
                                    subQuestionWait: (!!questionTreeNode ? null : this.state.subQuestionWait),
                                    tree: treeTmp,
                                    // metaData: Object.assign({}, this.state.metaData, metaData)
                                },
                                () => {
                                    showlog("%c  2  ", "background:#18f; color: #fff; font-size: 3em;");
                                    showlog("state audit ready", this.state.auditReady);
                                    // return true;
                                    finishedOk();
                                }
                            )

                        })
                    );




                })


            // .then(() => {
            //
            //     return this.getProgress();
            //
            // })


        );


    }


    // re-computing all questions numbers ----------------------
    getProgress(questionTreeNode) {

        return (
            loadProgress(this.getRatingId())
                .then(result => {


                    this.setState({
                            ...this.state,
                            // errorMessage: "Visualizzazione completata",
                            metaData: Object.assign({}, this.state.metaData, { info: result })
                        },
                        () => {
                            return true;
                        })


                })
                .catch(e => {
                    errorlog("errore",e);
                    return Promise.reject(e);
                })
        );

    }



    buildOpenAnswers = question => (
        <div
            className={"open-answer-area"}
            key={"openAnswer-"+ question.id}
        >
            {/*
                <code>Question</code>
                <pre>{ beautify( question ) }</pre>
            */}

                <div className={"open-answer-content"}>
                    {
                        createOpenAnswerData( question )
                            .map( openAnswerObj => {
                                let textYear = openAnswerObj.text;
                                if( textYear.split(" anno ").length > 1 ) {
                                    let slideYear = parseInt( textYear.split(" anno ")[1].trim(), 10);
                                    let year = (new Date()).getFullYear() + slideYear;
                                    textYear = textYear.split(" anno ")[0] +" "+ year;
                                }


                                return (




                                        <div
                                            id={`openAnswer-${openAnswerObj.id}`}
                                            className={"single-open-answer-content"}
                                        >
                                            <div className={"single-open-answer-text"}>{ textYear }</div>
                                            <Input
                                                id={`openAnswerValue-${openAnswerObj.id}`}
                                                name={`openAnswerValue-${openAnswerObj.id}`}
                                                className={"single-open-answer-value"}
                                                type="number"
                                                defaultValue={openAnswerObj.value}
                                                required
                                                onMouseOver={ mouseOverEvent => {
                                                    showlog("mouseover");
                                                    // if(
                                                    //     !!isNotEmptyArray(question.preventiveQuestions.question)
                                                    //     && (
                                                    //         !(
                                                    //             question.preventiveQuestions.answer
                                                    //                 .reduce( getLastElementOr, null )
                                                    //         )
                                                    //     ) ) {
                                                    //         this.setState({
                                                    //             ...this.state,
                                                    //             preventiveMessageAlert: question
                                                    //         })
                                                    //     }
                                                }}
                                                disabled={
                                                    !!this.isDisabledQuestion(question.id)
                                                    // && !this.isOperativeQuestion( question )
                                                    || (
                                                        !!isNotEmptyArray(question.preventiveQuestions.question)
                                                        && (
                                                            !(
                                                                question.preventiveQuestions.answer
                                                                    .reduce( getLastElementOr, null )
                                                            )
                                                        )
                                                    )
                                                }
                                                onClick={ clickEvent => {
                                                    // alert("test 2");

                                                    if(
                                                        !!isNotEmptyArray(question.preventiveQuestions.question)
                                                        && (
                                                            !(
                                                                question.preventiveQuestions.answer
                                                                    .reduce( getLastElementOr, null )
                                                            )
                                                        ) ) {
                                                        this.setState({
                                                            ...this.state,
                                                            preventiveMessageAlert: question
                                                        })
                                                    }
                                                }}
                                                onFocus={focusEvent => {
                                                    focusEvent.persist();
                                                    showlog("focus on " + question.id + "." + openAnswerObj.id);
                                                    showlog(focusEvent);

                                                    // alert("test 1");

                                                }}
                                                onBlur={blurEvent => {
                                                }}
                                                onChange={changeEvent => {

                                                    let clonedOpenAnswerSettled = cloneObject(this.state.openAnswerSettled || []);
                                                    let corrispondentOpenAnswer =
                                                            clonedOpenAnswerSettled
                                                                .filter(questionsSettledOpenAnswer => (questionsSettledOpenAnswer.questionID === question.id))
                                                                .reduce(getLastElementOr, {openAnswers: []})
                                                                .openAnswers
                                                                .filter(settledOpenAnswer => settledOpenAnswer.id === openAnswerObj.id)
                                                                .reduce(getLastElementOr, {id: 0, text: "", value: 0})
                                                        // .value = changeEvent.target.value
                                                    ;
                                                    corrispondentOpenAnswer.value = changeEvent.target.value;

                                                    showlog("controllo al inserimento valori");
                                                    showlog(this.state.openAnswerSettled);
                                                    showlog(clonedOpenAnswerSettled);
                                                    showlog(corrispondentOpenAnswer);

                                                    this.setState({
                                                        ...this.state,
                                                        openAnswerSettled: clonedOpenAnswerSettled,
                                                        editAnswer: [...this.state.editAnswer, question.id]
                                                    })


                                                }}
                                            />
                                        </div>


                                )
                            })
                    }
                </div>

                <div>
                    {/*
                        ((
                                !!isNotEmptyArray(question.preventiveQuestions.question)
                                && (
                                    !(
                                        question.preventiveQuestions.answer
                                            .reduce( getLastElementOr, null )
                                    )
                                )
                            ))
                            ? <span className={"look-at-me-tag"}>Risapondere prima alla domanda preventiva</span>
                            : null
                    */}
                </div>

                <div className={"open-answer-audit"}>

                </div>

        </div>
    )


    buildQuestions(questions, isSubQuestion) {


        showlog("question");
        showlog(questions);


        return (
            questions
                .filter(onlyNotNull)

                .filter(this.filterQuestion)


                .map((question, qIndex) => {


                    // if( qIndex === 0 ) {
                    // 	showlog("prima domanda del filtro dopo l'apertura");
                    // 	showlog( question );
                    // }


                    let treeTmp = cloneObject(this.state.tree);
                    let questionPath = (!!question.path ? question.path : getQuestionPath(this.state.tree, question.id));
                    let questionNode = getQuestionTreeNodeByPath(treeTmp, questionPath);

                    // showlog("controllo apertura "+ question.id, questionNode.open);


                    /*
                        inEdit
                          ├── si	edit (primary)
                          └── no
                               └── isComplete
                                    ├── si	complete (info)
                                    └── no
                                          └── finished ( QUESTION_STATUS = 5 )
                                            ├── si	finished (dark)
                                            └── no
                                                 └── validated ( QUESTION_STATUS = 10 )
                                                    ├── si	validated (success)
                                                    └── no
                                                         └── Info Aggiuntive ( QUESTION_STATUS = 20 )
                                                            ├── si	warning (warning)
                                                            └── no
                                                                 └── NC in attesa ( QUESTION_STATUS = 25 )
                                                                    ├── si	lowWarning (orange)
                                                                    └── no
                                                                         └── NC grave ( QUESTION_STATUS = 30 )
                                                                                ├── si	error (danger)
                                                                                └── no	default (dark-gray)
                    */


                    let colorPolicy = COLOR_POLICY.virgin;
                    if (!!this.state.editAnswer.some(ea => ea === question.id)) colorPolicy = COLOR_POLICY.edit;
                    else {
                        if (question.status === QUESTION_STATUS.incomplete.id) colorPolicy = COLOR_POLICY.finished;  //  COLOR_POLICY.complete;
                        else {
                            if (question.status === QUESTION_STATUS.finished.id) colorPolicy = COLOR_POLICY.finished;
                            else {
                                if (question.status === QUESTION_STATUS.validated.id) colorPolicy = COLOR_POLICY.validated;
                                else {
                                    if (question.status === QUESTION_STATUS.lowNonCompliant.id) colorPolicy = COLOR_POLICY.warning;
                                    else {
                                        if (question.status === QUESTION_STATUS.waitNonCompliant.id) colorPolicy = COLOR_POLICY.lowWarning;
                                        else {
                                            if (question.status === QUESTION_STATUS.highNonCompliant.id) colorPolicy = COLOR_POLICY.error;
                                        }
                                    }
                                }
                            }
                        }
                    }


                    // color-policy-text-color-default
                    // color-policy-background-color-default
                    let statusColorPolicy = [
                        colorPolicy.textClass.split("-")[0],
                        colorPolicy.textClass.split("-")[1],
                        "background",
                        colorPolicy.textClass.split("-")[3],
                        colorPolicy.textClass.split("-")[4]
                    ]
                        .join("-");

                    return (
                        <Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>



                            <CardHeader
                                className={"question-header"}
                            >


                                <div
                                    className={"question-header-main"}
                                    onClick={clickEvent => {

                                        let treeTmp = cloneObject(this.state.tree);
                                        let questionPath = getQuestionPath(treeTmp, question.id);
                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                        // nodeTmp.open = !nodeTmp.open;
                                        nodeTmp.open = !question.open;

                                        this.setState({
                                            ...this.state,
                                            tree: treeTmp
                                        });


                                    }}
                                >
                                    <div className={"question-header-top"}>

                                        <div className={"question-header-top-main"}>



                                            {
                                                (!!this.state.waitingQuestions && this.state.waitingQuestions.includes( question.id ))
                                                    ? <Spinner color={"primary"} className={"status-ball-in-wait"}/>
                                                    : (
                                                        (this.state.metaData.status === RATING_STATUS.ratingComplete.id )
                                                            ? (
                                                                <span className={"tag status-tag "+ statusColorPolicy }>
                                                                    {
                                                                        Object.keys( QUESTION_STATUS )
                                                                            .map( field => QUESTION_STATUS[ field ] )
                                                                            .filter( qs => qs.id === question.status )
                                                                            .map( qs => qs.shortLabel )
                                                                            .reduce( getLastElementOr, QUESTION_STATUS.virgin.shortLabel )
                                                                    }
                                                                </span>
                                                            )
                                                            : <div className={"status-ball " + statusColorPolicy} />
                                                    )
                                            }


                                            <strong className={ colorPolicy.textClass }>{question.code}</strong>
                                            {
                                                !!question.questionGuidelines
                                                    ? (
                                                        <React.Fragment key={"gl-" + question.id}>
                                                            <i
                                                                id={"qgl-" + question.id}
                                                                className={"dripicons-information question-info-icon"}
                                                                onClick={clickEvent => {
                                                                    clickEvent.stopPropagation();
                                                                }}
                                                            />
                                                            <UncontrolledPopover
                                                                placement={"top"}
                                                                target={"qgl-" + question.id}
                                                                trigger="legacy"
                                                            >
                                                                <PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
                                                                <PopoverBody>{question.questionGuidelines}</PopoverBody>
                                                            </UncontrolledPopover>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                            }





                                            {
                                                (!!this.isQuestionInAuditAndAuditIsRunning(question) || !!hasPermission( permissions.SEE_RATING_ANSWER_SCORE ))
                                                    ? (
                                                        <span
                                                            className={"score-label"}
                                                        >
                                                            Punteggio Massimo: <strong>{question.scoreMax}</strong>
                                                            &nbsp;&nbsp;
                                                            ( { formatDigitLength( question.scoreOpenQuestion, 2 ) } )
                                                        </span>
                                                    )
                                                    : null
                                            }




                                        </div>





                                    </div>
                                    {/*
                                    Nel mezzo del cammin di nostra vita mi ritrovai per una selva oscura che la dritta via era smarrita! Quant'onesta pare la donna mia quand'ella latrui saluta. Del senno di poi so piene le fosse. é lastricata di buone intenzioni la strada per l'inferno
                                    */}
                                    <div className={"question-header-content"}>
                                        { this.helperNode( question ) }
                                        { question.name }
                                        <br/>
                                        {
                                            ((
                                                !!isNotEmptyArray(question.preventiveQuestions.question)
                                                && (
                                                    !(
                                                        question.preventiveQuestions.answer
                                                            .reduce( getLastElementOr, null )
                                                    )
                                                )
                                            ))
                                                ? <span className={"look-at-me-color"}>Rispondere prima alla domanda preventiva</span>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className={"question-header-com"}>



                                    {
                                        !!isNotEmptyArray(question.preventiveQuestions.question)
                                            ? (
                                                <ActionButton
                                                    buttonText={"Preventiva"}
                                                    promise={ () => Promise.resolve() }
                                                    onResult={ result => {
                                                        this.setState({
                                                            ...this.state,
                                                            preventiveQuestionPopup: question
                                                        });
                                                    }}
                                                    onError={ e => {
                                                        errorlog("apertura preventive question", e);
                                                    }}
                                                    className={
                                                        "question-save-button "+
                                                        (
                                                            !!(
                                                                question.preventiveQuestions.answer
                                                                    .reduce( getLastElementOr, null )
                                                            )
                                                                ? "green-background"
                                                                : ""
                                                        )
                                                    }
                                                />
                                            )
                                            : null
                                    }



                                    {
                                        (!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
                                            ? (
                                                !!question.hasAdminNote
                                                    ? <div className={"admin-note-present-status"}/>
                                                    : null
                                            )
                                            : null
                                    }
                                    {
                                        (!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
                                            ? (
                                                <span
                                                    className={"cursor-pointer srtool-note-icon"}
                                                    onClick={ clickEvent => {
                                                        showlog("click icon note");
                                                        this.setState({
                                                            ...this.state,
                                                            adminNotePopup: question
                                                        })
                                                    }}
                                                >
                                                    <AdminNoteIconSvgComponent/>
                                                </span>
                                            )
                                            : null
                                    }


                                    {
                                        !!question.hasNote
                                            ? <div className={"note-present-status"}/>
                                            : null
                                    }

                                    <span
                                        className={"cursor-pointer srtool-note-icon"}
                                        onClick={ clickEvent => {
                                            showlog("click icon note");
                                            this.setState({
                                                ...this.state,
                                                notePopup: question
                                            })
                                        }}
                                    >
                                        <NoteIconSvgComponent/>
                                    </span>


                                    <i
                                        className={"mdi mdi-history question-history-button cursor-pointer"}
                                        onClick={clickEvent => {
                                            showlog("modale dello storico");
                                            this.setState({
                                                ...this.state,
                                                historyModal: question
                                            });
                                        }}
                                    />

                                    <i
                                        id={ `gl-${ question.id }` }
                                        className={"mdi mdi-information-outline guidelinees-icon big"}
                                        onClick={clickEvent => {
                                            clickEvent.stopPropagation();
                                            clickEvent.preventDefault();

                                        }}
                                    />
                                    <UncontrolledPopover
                                        placement="top"
                                        target={ `gl-${ question.id }` }
                                        trigger="legacy"
                                        className={"question-guide-lines-popover"}
                                    >
                                        <PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
                                        <PopoverBody>
                                            <div>
                                                {
                                                    (!!question.questionGuidelines)
                                                        ? question.questionGuidelines
                                                        : ""
                                                }
                                            </div>
                                        </PopoverBody>
                                    </UncontrolledPopover>

                                    <i
                                        className={"mdi mdi-attachment sirating-question-attachment-icon cursor-pointer"}
                                        onClick={clickEvent => {
                                            showlog("modale degli allegati");
                                            this.setState({
                                                ...this.state,
                                                attachmentModal: question.attachments,
                                                attachmentModalQuestionId: question.id,
                                                attachmentModalQuestion: question
                                            });
                                        }}
                                    />
                                    {
                                        (!!question.attachments && question.attachments.length > 0)
                                            ? (
                                                <span
                                                    className={"sirating-question-attachment-magnitude "+ statusColorPolicy}
                                                >
                                                            {
                                                                (question.attachments.length > 99)
                                                                    ? "+99"
                                                                    : question.attachments.length
                                                            }
                                                        </span>
                                            )
                                            : null
                                    }

                                    {

                                        /**
                                         * in caso sia necessaio l'allegato
                                         *
                                         * se lo status della domanda è incompleto e la domanda
                                         * ha almeno una risposta che ha almeno un flag isRequiredAttachment
                                         *
                                         *
                                         * E' stato tolto
                                         * <React.Fragment key={"attachment-notific-" + question.id}>

                                         <span id={"caution-" + question.id}
                                         className={"sirating-question-attachment-attention tooltip-icon-cursor"}>
                                         {
                                                            // emoj( EMOJ.face_vomiting )
                                                            // emoj( EMOJ.red_exclamation_mark )
                                                            emoj(EMOJ.warning)
                                                        }
                                         </span>

                                         <UncontrolledPopover
                                         placement="top"
                                         target={"caution-" + question.id}
                                         trigger="legacy"
                                         >
                                         <PopoverHeader className={COLOR_POLICY.warning.textClass}>
                                         <span>{emoj(EMOJ.warning)} ATTENZIONE</span>
                                         </PopoverHeader>
                                         <PopoverBody>
                                         <span>
                                         Una delle risposte di questa domanda rende necessario allegare un file alla domanda
                                         </span>
                                         </PopoverBody>
                                         </UncontrolledPopover>

                                         </React.Fragment>
                                         *
                                         */
                                        (
                                            question.status === QUESTION_STATUS.incomplete.id &&
                                            !!(question.choiches
                                                .filter(choiche => !!isNotEmptyArray(choiche.answers))
                                                .filter(choiche => !!choiche.isRequiredAttachment)
                                                .reduce(getLastElementOr, null))
                                        )
                                            ? null // <--------- icona triangolo con relativo popup
                                            : null
                                    }



                                    {
                                        (!!question.open)
                                            ? (
                                                <i
                                                    className={"mdi mdi-minus-box-multiple font-18 accordion-arrow cursor-pointer "+ colorPolicy.textClass}
                                                    onClick={clickEvent => {

                                                        let treeTmp = cloneObject(this.state.tree);
                                                        let questionPath = getQuestionPath(treeTmp, question.id);
                                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                                        nodeTmp.open = false;

                                                        this.setState({
                                                            ...this.state,
                                                            tree: treeTmp
                                                        });


                                                    }}
                                                />
                                            )
                                            : (
                                                <i
                                                    className={"mdi mdi-plus-box-multiple font-18 accordion-arrow cursor-pointer "+ colorPolicy.textClass}
                                                    onClick={clickEvent => {

                                                        let treeTmp = cloneObject(this.state.tree);
                                                        let questionPath = getQuestionPath(treeTmp, question.id);
                                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                                        nodeTmp.open = true;

                                                        this.setState({
                                                            ...this.state,
                                                            tree: treeTmp
                                                        });


                                                    }}
                                                />
                                            )
                                    }


                                </div>





                            </CardHeader>



                            {/*<UncontrolledCollapse defaultOpen={ !!question.open } toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>*/}
                            <Collapse isOpen={!!question.open}
                                      toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>
                                <CardBody className={"answer-body"}>


                                    {
                                        (!!this.isDisabledQuestion(question.id) /*|| !!isSubQuestion*/)
                                            ? null
                                            : null
                                    }



                                    <div className={"answer-body-header"}>




                                        <div className={"answer-body-content"}>

                                            {
                                                // controllo choiches
                                                (
                                                    !!question.isOpenAnswer
                                                    // && JSON.stringify(question.openAnswers) !== "{}"
                                                    // && typeof question.openAnswers === "object"
                                                    // && !!question.openAnswers.text1
                                                )
                                                    ? (
                                                        this.buildOpenAnswers(question)
                                                    )
                                                    : (
                                                        <div className={"answer-body-content-choiches"}>

                                                            {
                                                                question.choiches
                                                                    .sort((x, y) => {
                                                                        if (x.ordering < y.ordering) return -1;
                                                                        return 1;
                                                                    })
                                                                    .map((choiche, choicheIndex) => {

                                                                        /*
                                                                        {
                                                                            choiche_id: 5,
                                                                            choiche_name: "esiste (specificare)",
                                                                            isRequiredAttachment: false,
                                                                            isRequiredTextBox: true,
                                                                            ordering: 1,
                                                                            score: 0,
                                                                            isOpeningQuestions: 0
                                                                        }
                                                                        */


                                                                        // ;

                                                                        // dati nel sistema
                                                                        let checkedChoiche = ((!!choiche.answers && choiche.answers.length > 0) || !!choiche.checked);
                                                                        let checkedSingleChoiche = ((!!choiche.answers && choiche.answers.length > 0 && choiche.answers[0].choiche_id === choiche.choiche_id));

                                                                        let choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
                                                                        let treeTmp = cloneObject(this.state.tree);
                                                                        let questionPath = [];
                                                                        if (!!isNotEmptyArray(question.path)) questionPath = question.path;
                                                                        else questionPath = getQuestionPath(treeTmp, question.id);

                                                                        let questionTreeNode = getQuestionTreeNodeByPath(treeTmp, questionPath);
                                                                        let refreshedChoiche = null;

                                                                        if (!!!questionTreeNode) {
                                                                            warninglog("Attenzione al path della question");
                                                                            showlog(question);
                                                                            showlog(questionPath);
                                                                            // ;
                                                                        } else {
                                                                            refreshedChoiche =
                                                                                questionTreeNode.choiches
                                                                                    .filter(ch => ch.choiche_id === choiche.choiche_id)
                                                                                    .reduce(getLastElementOr, null)
                                                                            ;
                                                                        }


                                                                        return (
                                                                            <div key={choicheIndex} className={"sirating-admin-choiche-area"}>

                                                                                {/*<Form>*/}

                                                                                <div>


                                                                                    {
                                                                                        (!!question.isMultipleAnswer)
                                                                                            ? ( // MULTIPLE ANSWER --------------------------------------------------------------------------------------
                                                                                                <FormGroup className={"choiche-question"}>
                                                                                                    <CustomInput
                                                                                                        type="checkbox"
                                                                                                        name={"reason-" + choiche.choiche_id}
                                                                                                        id={"reason-" + choiche.choiche_id}
                                                                                                        label={ choiche.choiche_name[0].charAt(0).toUpperCase() + choiche.choiche_name[0].slice(1) }
                                                                                                        className={

                                                                                                            (!!choiche.checked)
                                                                                                                ? QUESTION_TEXT_COLOR_POLICY.edit.class
                                                                                                                : (
                                                                                                                    (!!choiche.answers.reduce(getLastElementOr, null))
                                                                                                                        ? QUESTION_TEXT_COLOR_POLICY.complete.class
                                                                                                                        : ""
                                                                                                                )


                                                                                                        }
                                                                                                        onClick={clickEvent => {
                                                                                                            // showlog("click alla checkbox di scelta multipla");
                                                                                                            // showlog(choiche.checked);
                                                                                                            // showlog( !!checkedChoiche );


                                                                                                            // if( !choicheDOM.checked ) {
                                                                                                            if (!checkedChoiche) {
                                                                                                                // da attivare
                                                                                                                // showlog("ATTIVO la checkbox reason-" + choiche.choiche_id);
                                                                                                                // showlog( choicheDOM );
                                                                                                                refreshedChoiche.checked = true;
                                                                                                                refreshedChoiche.active = true;


                                                                                                                // questionTreeNode.choiches
                                                                                                                //     .map(ch => {
                                                                                                                //         ch.previousChoicheAnswer = (!!ch.answers ? ch.answers[0] : null);
                                                                                                                //     })
                                                                                                                // ;


                                                                                                                refreshedChoiche.answers.push({
                                                                                                                    choiche_id: refreshedChoiche.choiche_id,
                                                                                                                    questions_structure_id: question.questionsStructureId,
                                                                                                                    score: refreshedChoiche.score
                                                                                                                });


                                                                                                                if (!!choicheDOM) {
                                                                                                                    choicheDOM.checked = true;
                                                                                                                }



                                                                                                                // controllo disattivazione altre scelte
                                                                                                                /*
                                                                                                                La scelta si abilita
                                                                                                                Se la scelta corrente ha isNoneOfTheAbove=true
                                                                                                                    si disattivano tutte le altre scelte
                                                                                                                Se la scelta corrente ha isNoneOfTheAbove=false
                                                                                                                    si controllano tutte le altre scelte
                                                                                                                    se una scelta ha isNoneOfTheAbove=true
                                                                                                                        la si disabilita
                                                                                                                 */
                                                                                                                if( !!refreshedChoiche.isNoneOfTheAbove ) {
                                                                                                                    questionTreeNode.choiches
                                                                                                                        .filter( ch => ch.choiche_id !== refreshedChoiche.choiche_id )
                                                                                                                        .map(ch => {
                                                                                                                            let dom = document.getElementById("reason-" + ch.choiche_id);
                                                                                                                            if (!!dom) {
                                                                                                                                dom.checked = false;
                                                                                                                            }

                                                                                                                            // active
                                                                                                                            ch.active = false;
                                                                                                                            ch.checked = false;

                                                                                                                            // text
                                                                                                                            delete ch.text;

                                                                                                                            // attachment
                                                                                                                            delete ch.attachment;

                                                                                                                            // answer
                                                                                                                            ch.answers = [];

                                                                                                                        })
                                                                                                                    ;
                                                                                                                }
                                                                                                                else {
                                                                                                                    questionTreeNode.choiches
                                                                                                                        .filter( ch => !!ch.isNoneOfTheAbove )
                                                                                                                        .map(ch => {
                                                                                                                            let dom = document.getElementById("reason-" + ch.choiche_id);
                                                                                                                            if (!!dom) {
                                                                                                                                dom.checked = false;
                                                                                                                            }

                                                                                                                            // active
                                                                                                                            ch.active = false;
                                                                                                                            ch.checked = false;

                                                                                                                            // text
                                                                                                                            delete ch.text;

                                                                                                                            // attachment
                                                                                                                            delete ch.attachment;

                                                                                                                            // answer
                                                                                                                            ch.answers = [];

                                                                                                                        })
                                                                                                                    ;
                                                                                                                }





                                                                                                            } else {
                                                                                                                // da disattivare
                                                                                                                // showlog("disattivo la checkbox reason-" + choiche.choiche_id);
                                                                                                                // showlog( choicheDOM );
                                                                                                                refreshedChoiche.checked = false;
                                                                                                                refreshedChoiche.active = false;
                                                                                                                // delete refreshedChoiche.answers;
                                                                                                                refreshedChoiche.answers =
                                                                                                                    refreshedChoiche.answers
                                                                                                                        .filter(ans => ans.choiche_id !== choiche.choiche_id)
                                                                                                                ;
                                                                                                                if (!!choicheDOM) {
                                                                                                                    choicheDOM.checked = false;
                                                                                                                }
                                                                                                            }


                                                                                                            this.setState({
                                                                                                                    ...this.state,
                                                                                                                    tree: treeTmp,
                                                                                                                    editAnswer: [
                                                                                                                        ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                        question.id
                                                                                                                    ],
                                                                                                                    subQuestionId: choiche.choiche_id,
                                                                                                                    // subQuestionWait: choiche.choiche_id
                                                                                                                },

                                                                                                                () => {


                                                                                                                }
                                                                                                            );

                                                                                                        }}
                                                                                                        defaultChecked={!!checkedChoiche}
                                                                                                        disabled={
                                                                                                            this.isDisabledQuestion(question.id)

                                                                                                            && !(
                                                                                                                !!isNotEmptyArray(question.preventiveQuestions.question)
                                                                                                                && (
                                                                                                                    !(
                                                                                                                        question.preventiveQuestions.answer
                                                                                                                            .reduce( getLastElementOr, null )
                                                                                                                    )
                                                                                                                )
                                                                                                            )

                                                                                                        }
                                                                                                    />
                                                                                                </FormGroup>

                                                                                            )
                                                                                            : ( // SINGLE ANSWER ------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                                <FormGroup className={"choiche-question"}>
                                                                                                    <CustomInput
                                                                                                        type="radio"
                                                                                                        name={"reason-" + choiche.choiche_id}
                                                                                                        id={"reason-" + choiche.choiche_id}
                                                                                                        label={ choiche.choiche_name[0].charAt(0).toUpperCase() + choiche.choiche_name[0].slice(1) }
                                                                                                        disabled={
                                                                                                            this.isDisabledQuestion(question.id)
                                                                                                            && !(
                                                                                                                !!isNotEmptyArray(question.preventiveQuestions.question)
                                                                                                            )
                                                                                                        }
                                                                                                        onClick={clickEvent => {


                                                                                                            /**
                                                                                                             * é necessario perchè lato server le sotto-domande vengono cancellate,
                                                                                                             * con questo flag evitiamo di cancellare delle sotto-domande che fanno parte
                                                                                                             * del sistema
                                                                                                             *
                                                                                                             * @type {boolean}
                                                                                                             */
                                                                                                            questionTreeNode.isChangedAnswer = !choiche.active;
                                                                                                            // questionTreeNode.isChangedAnswer = ( ( refreshedChoiche.choiche_id === choiche.choiche_id ) && !choiche.active );


                                                                                                            showlog("controllo bugfix 3");
                                                                                                            beautifyLog(questionTreeNode.choiches);



                                                                                                            if (!checkedSingleChoiche) {


                                                                                                                questionTreeNode.choiches
                                                                                                                    .map(ch => {
                                                                                                                        ch.previousChoicheAnswer = (!!ch.answers ? ch.answers[0] : null);
                                                                                                                    })
                                                                                                                ;


                                                                                                                // reset di tutte le choiches
                                                                                                                questionTreeNode.choiches =
                                                                                                                    questionTreeNode.choiches
                                                                                                                        .map(ch => {
                                                                                                                            if (!!choicheDOM) {
                                                                                                                                choicheDOM.checked = false;
                                                                                                                            }

                                                                                                                            // active
                                                                                                                            ch.active = false;
                                                                                                                            ch.checked = false;

                                                                                                                            // text
                                                                                                                            delete ch.text;

                                                                                                                            // attachment
                                                                                                                            delete ch.attachment;

                                                                                                                            // answer
                                                                                                                            ch.answers = [];

                                                                                                                            return ch;
                                                                                                                        })
                                                                                                                ;
                                                                                                                // refreshedChoiche.active = (clickEvent.target.value === "on" ? false : true);
                                                                                                                refreshedChoiche.checked = true;
                                                                                                                refreshedChoiche.active = true;


                                                                                                                // da inserire dopo
                                                                                                                // if( !!choiche.isRequiredTextBox ) answer.motivation =

                                                                                                                refreshedChoiche.answers = [{
                                                                                                                    choiche_id: refreshedChoiche.choiche_id,
                                                                                                                    questions_structure_id: question.questionsStructureId
                                                                                                                }];


                                                                                                                questionTreeNode.choiches = [
                                                                                                                    ...questionTreeNode.choiches.filter(ch => ch.choiche_id !== refreshedChoiche.choiche_id),
                                                                                                                    refreshedChoiche
                                                                                                                ];


                                                                                                                // questionTreeNode.isChangedAnswer = false;


                                                                                                                // questionTreeNode.child = [{tmp: true}];
                                                                                                                refreshedChoiche.inWait = true;
                                                                                                                choiche.inWait = true;

                                                                                                                this.setState({
                                                                                                                        ...this.state,
                                                                                                                        tree: treeTmp,
                                                                                                                        editAnswer: [
                                                                                                                            ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                            question.id
                                                                                                                        ],
                                                                                                                        subQuestionId: choiche.choiche_id,
                                                                                                                        subQuestionWait: choiche.choiche_id
                                                                                                                    },

                                                                                                                    () => {


                                                                                                                        showlog("chiamata 1", !!choiche.isOpeningQuestions);


                                                                                                                        // if(!!choiche.isOpeningQuestions && (!!!isNotEmptyArray(question.child) || (question.child.length === 1 && !!question.child[0].tmp ))) {
                                                                                                                        if (!!choiche.isOpeningQuestions) {

                                                                                                                            // ;
                                                                                                                            // chiamata al click
                                                                                                                            loadAnswers(this.getRatingId(), GENDER_GAP_TAB_ID, choiche.choiche_id)

                                                                                                                                // editTree ----------------------------------------------
                                                                                                                                .then(subQuestions => {

                                                                                                                                    if (!!!isNotEmptyArray(subQuestions)) {
                                                                                                                                        // non arrivano le sotto-domande
                                                                                                                                        // si verifica se c'è un errore lato server

                                                                                                                                        questionTreeNode.child = [{ status: "server-error" }]
                                                                                                                                        return Promise.reject();
                                                                                                                                    }

                                                                                                                                    return (
                                                                                                                                        convertSIRatingSubQuestionTableToSubQuestionChild(subQuestions, getQuestionPath(treeTmp, question.id))
                                                                                                                                            .then(nodes => {

                                                                                                                                                // ;

                                                                                                                                                // nodes.map( n => n.code = n.question_code );
                                                                                                                                                // questionTreeNode.child = nodes;
                                                                                                                                                questionTreeNode.open = true;
                                                                                                                                                questionTreeNode.child =
                                                                                                                                                    nodes
                                                                                                                                                        .map(qc => {
                                                                                                                                                            qc.open = true;
                                                                                                                                                            return qc;
                                                                                                                                                        })
                                                                                                                                                ;
                                                                                                                                                return questionTreeNode;
                                                                                                                                            })
                                                                                                                                            .catch(e => {
                                                                                                                                                errorlog("errore",e);
                                                                                                                                                return Promise.reject(e);
                                                                                                                                            })
                                                                                                                                    );

                                                                                                                                })


                                                                                                                                // re-computing all questions numbers ----------------------
                                                                                                                                .then(questionTreeNode => {


                                                                                                                                    showlog("sotto-domande: controllami");
                                                                                                                                    showlog(questionTreeNode);
                                                                                                                                    // ;

                                                                                                                                    return (
                                                                                                                                        loadProgress(this.getRatingId())
                                                                                                                                            .then(result => {
                                                                                                                                                return ({
                                                                                                                                                    questionTreeNode: questionTreeNode,
                                                                                                                                                    metaData: { info: result }
                                                                                                                                                })
                                                                                                                                            })
                                                                                                                                            .catch(e => {
                                                                                                                                                errorlog("errore",e);
                                                                                                                                                return Promise.reject(e);
                                                                                                                                            })
                                                                                                                                    );

                                                                                                                                })

                                                                                                                                // finish ---------------------------------------------------
                                                                                                                                .then(({
                                                                                                                                           questionTreeNode,
                                                                                                                                           metaData
                                                                                                                                       }) => {

                                                                                                                                    choiche.inWait = false;
                                                                                                                                    refreshedChoiche.inWait = false;

                                                                                                                                    this.setState({
                                                                                                                                        ...this.state,
                                                                                                                                        tree: treeTmp,
                                                                                                                                        subQuestionWait: null,
                                                                                                                                        metaData: Object.assign({}, this.state.metaData, metaData)
                                                                                                                                    })
                                                                                                                                })
                                                                                                                                .catch(e => {
                                                                                                                                    errorlog("errore",e);
                                                                                                                                    /*
                                                                                                                                    this.setState({
                                                                                                                                        ...this.state,
                                                                                                                                        subQuestionWait: null
                                                                                                                                    })
                                                                                                                                    */
                                                                                                                                })

                                                                                                                        } else {

                                                                                                                            delete questionTreeNode.child;
                                                                                                                            this.setState({
                                                                                                                                ...this.state,
                                                                                                                                tree: treeTmp
                                                                                                                            })


                                                                                                                        }


                                                                                                                    }
                                                                                                                );
                                                                                                            } else {

                                                                                                                // questionTreeNode.isChangedAnswer = true;

                                                                                                            }


                                                                                                            showlog("controllo cambio risposta");
                                                                                                            showlog(questionTreeNode);
                                                                                                            showlog(question);
                                                                                                            // ;


                                                                                                            let newChoicheId =
                                                                                                                questionTreeNode.choiches
                                                                                                                    .filter( ch => (ch.answers.length > 0) )
                                                                                                                    .map( ch => ch.choiche_id )
                                                                                                                    .reduce( getLastElementOr, null )
                                                                                                            ;



                                                                                                        }}
                                                                                                        checked={
                                                                                                            !!checkedSingleChoiche
                                                                                                            // !!choiche.checked
                                                                                                        }
                                                                                                    />
                                                                                                </FormGroup>

                                                                                            )
                                                                                    }


                                                                                    { // TEXTBOX -------------------------------------------------------------------------------------------------------------------------------------------
                                                                                        (!!choiche.isRequiredTextBox /*&& !!choiche.active*/ && (!!checkedSingleChoiche || !!checkedChoiche))
                                                                                            ? (

                                                                                                <FormGroup
                                                                                                    className={"choiche-question-reason-box"}>

                                                                                                    <Row>
                                                                                                        <Col sm={3}>
                                                                                                            <Label for={""}>Motivazione</Label>
                                                                                                            <Label
                                                                                                                className={"material-themes-info-fathername single-um-label"}>Fornire
                                                                                                                una motivazione per la seguente
                                                                                                                scelta</Label>
                                                                                                        </Col>
                                                                                                        <Col sm={6}>
                                                                                                            <Input
                                                                                                                className={"sector-question-text standard-text-area audit-choiche-question-textarea"}
                                                                                                                type="textarea"
                                                                                                                row={64}
                                                                                                                name={"reason-" + choiche.choiche_id}
                                                                                                                id={""}
                                                                                                                /*
                                                                                                                onChange={changeEvent => {

                                                                                                                    let editQuestion = {
                                                                                                                        id: question.id,
                                                                                                                        choiches: [{
                                                                                                                            ...choiche,
                                                                                                                            text: changeEvent.target.value
                                                                                                                        }]
                                                                                                                    };


                                                                                                                    refreshedChoiche.answers =
                                                                                                                        refreshedChoiche.answers
                                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                            .map( ans => ({
                                                                                                                                ...ans,
                                                                                                                                motivation: changeEvent.target.value
                                                                                                                            }))
                                                                                                                    ;



                                                                                                                    // ;
                                                                                                                    this.setState({
                                                                                                                        ...this.state,
                                                                                                                        tree: treeTmp,
                                                                                                                        clientQuestions: [
                                                                                                                            ...this.state.clientQuestions.filter(cq => cq.id !== editQuestion.id),
                                                                                                                            editQuestion
                                                                                                                        ],
                                                                                                                        editAnswer: [
                                                                                                                            ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                            question.id
                                                                                                                        ]
                                                                                                                    },
                                                                                                                        () => {
                                                                                                                            // ;
                                                                                                                        }
                                                                                                                    )
                                                                                                                }}
                                                                                                                */
                                                                                                                defaultValue={
                                                                                                                    choiche.answers
                                                                                                                        .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                        .map(ans => ans.motivation)
                                                                                                                        .reduce(getLastElementOr, "")
                                                                                                                }
                                                                                                                required
                                                                                                                disabled={this.isDisabledQuestion(question.id)}
                                                                                                                onBlur={blurEvent => {
                                                                                                                    showlog(blurEvent.target.value);
                                                                                                                    // ;


                                                                                                                    let editQuestion = {
                                                                                                                        id: question.id,
                                                                                                                        choiches: [{
                                                                                                                            ...choiche,
                                                                                                                            text: blurEvent.target.value
                                                                                                                        }]
                                                                                                                    };


                                                                                                                    refreshedChoiche.answers =
                                                                                                                        refreshedChoiche.answers
                                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                            .map(ans => ({
                                                                                                                                ...ans,
                                                                                                                                motivation: blurEvent.target.value
                                                                                                                            }))
                                                                                                                    ;


                                                                                                                    // ;
                                                                                                                    this.setState({
                                                                                                                            ...this.state,
                                                                                                                            tree: treeTmp,
                                                                                                                            clientQuestions: [
                                                                                                                                ...this.state.clientQuestions.filter(cq => cq.id !== editQuestion.id),
                                                                                                                                editQuestion
                                                                                                                            ],
                                                                                                                            editAnswer: [
                                                                                                                                ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                                question.id
                                                                                                                            ]
                                                                                                                        },
                                                                                                                        () => {
                                                                                                                            // ;
                                                                                                                        }
                                                                                                                    )


                                                                                                                }}
                                                                                                            />

                                                                                                        </Col>



                                                                                                    </Row>

                                                                                                </FormGroup>

                                                                                            )
                                                                                            : null
                                                                                    }



                                                                                    {
                                                                                        (!!this.isQuestionInAuditAndAuditIsRunning(question) && !!hasPermission(permissions.EDIT_ANSWER_SCORE) && !!checkedChoiche)
                                                                                            ? (
                                                                                                <React.Fragment
                                                                                                    key={"question-score-" + question.id}>
                                                                                                    <Label
                                                                                                        className={"audit-score-edit-label"}>Punteggio</Label>
                                                                                                    <Input
                                                                                                        className={"choiche-question-reason-box audit-score-edit"}
                                                                                                        type="number"
                                                                                                        name={"choiche-question-score-" + choiche.choiche_id}
                                                                                                        id={"choiche-question-score-" + choiche.choiche_id}
                                                                                                        value={


                                                                                                            (
                                                                                                                choiche.answers
                                                                                                                    .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                    .map(ans => ans.score)
                                                                                                                    .reduce(getLastElementOr, null) !== null
                                                                                                                &&
                                                                                                                choiche.answers
                                                                                                                    .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                    .map(ans => ans.score)
                                                                                                                    .reduce(getLastElementOr, null) !== undefined

                                                                                                                    ? (
                                                                                                                        ""+ (choiche.answers
                                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                            .map(ans => ans.score)
                                                                                                                            .reduce(getLastElementOr, "0"))
                                                                                                                    )
                                                                                                                    : null
                                                                                                            )



                                                                                                            || (!!choiche.previousChoicheAnswer && !isNaN(choiche.previousChoicheAnswer.score) && choiche.previousChoicheAnswer.score)
                                                                                                            || parseFloat(choiche.score)
                                                                                                        }
                                                                                                        onChange={changeEvent => {

                                                                                                            let editQuestion = {
                                                                                                                id: question.id,
                                                                                                                choiches: [{
                                                                                                                    ...choiche,
                                                                                                                    score: parseFloat(changeEvent.target.value)
                                                                                                                }]
                                                                                                            };


                                                                                                            refreshedChoiche.answers =
                                                                                                                refreshedChoiche.answers
                                                                                                                    .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                    .map(ans => ({
                                                                                                                        ...ans,
                                                                                                                        // score: parseFloat(changeEvent.target.value)
                                                                                                                        score: changeEvent.target.value
                                                                                                                    }))
                                                                                                            ;


                                                                                                            // ;
                                                                                                            this.setState({
                                                                                                                    ...this.state,
                                                                                                                    tree: treeTmp,
                                                                                                                    clientQuestions: [
                                                                                                                        ...this.state.clientQuestions.filter(cq => cq.id !== editQuestion.id),
                                                                                                                        editQuestion
                                                                                                                    ],
                                                                                                                    editAnswer: [
                                                                                                                        ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                        question.id
                                                                                                                    ]
                                                                                                                },
                                                                                                                () => {
                                                                                                                    // ;
                                                                                                                }
                                                                                                            )
                                                                                                        }}
                                                                                                        required
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                            )
                                                                                            : (
                                                                                                (!!this.isQuestionInAuditAndAuditIsRunning(question) && (!hasPermission(permissions.EDIT_ANSWER_SCORE) || !checkedChoiche))
                                                                                                    ? <span className={"choiche-question-score choiche-question-score-top"}>(&nbsp;&nbsp;{

                                                                                                        (
                                                                                                            choiche.answers
                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                .map(ans => ans.score)
                                                                                                                .reduce(getLastElementOr, null) !== null
                                                                                                            &&
                                                                                                            choiche.answers
                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                .map(ans => ans.score)
                                                                                                                .reduce(getLastElementOr, null) !== undefined

                                                                                                                ? (
                                                                                                                    ""+ (choiche.answers
                                                                                                                        .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                        .map(ans => ans.score)
                                                                                                                        .reduce(getLastElementOr, "0"))
                                                                                                                )
                                                                                                                : null
                                                                                                        )

                                                                                                        || (!!choiche.previousChoicheAnswer && !isNaN(choiche.previousChoicheAnswer.score) && choiche.previousChoicheAnswer.score)
                                                                                                        || parseFloat(choiche.score)

                                                                                                    }&nbsp;&nbsp;)</span>
                                                                                                    : (
                                                                                                        !!hasPermission( permissions.SEE_RATING_ANSWER_SCORE )
                                                                                                            ? (
                                                                                                                <span className={"choiche-question-score choiche-question-score-top"}>
                                                                                                        &nbsp;&nbsp;(&nbsp;&nbsp;
                                                                                                                    {
                                                                                                                        (
                                                                                                                            choiche.answers
                                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                                .map(ans => ans.score)
                                                                                                                                .reduce(getLastElementOr, null) !== null
                                                                                                                            &&
                                                                                                                            choiche.answers
                                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                                .map(ans => ans.score)
                                                                                                                                .reduce(getLastElementOr, null) !== undefined

                                                                                                                                ? (
                                                                                                                                    ""+ (choiche.answers
                                                                                                                                        .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                                        .map(ans => ans.score)
                                                                                                                                        .reduce(getLastElementOr, "0"))
                                                                                                                                )
                                                                                                                                : null
                                                                                                                        )

                                                                                                                        || (!!choiche.previousChoicheAnswer && !isNaN(choiche.previousChoicheAnswer.score) && choiche.previousChoicheAnswer.score)
                                                                                                                        || parseFloat(choiche.score)
                                                                                                                    }
                                                                                                                    &nbsp;&nbsp;)&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                            )
                                                                                                            : null
                                                                                                    )
                                                                                            )

                                                                                    }

                                                                                </div>





                                                                            </div>

                                                                        );
                                                                    })
                                                            }
                                                        </div>

                                                    )
                                            }



                                        </div>




                                        {
                                            (!!question.isOpenAnswer)
                                                ? (
                                                    <ActionButton
                                                        visible={ this.isOperativeQuestion( question ) }
                                                        promise={ () => {

                                                            return saveOpenAnswer( this.getRatingId(), question.id, this.state.openAnswerSettled.filter( q => q.questionID === question.id ).reduce( getLastElementOr, null ) )
                                                        }}
                                                        onResult={ result => {

                                                            let refreshedTree = cloneObject(this.state.tree);
                                                            let refreshedQuestion =
                                                                getQuestionTreeNodeByPath(
                                                                    refreshedTree,   // albero
                                                                    question.path
                                                                )
                                                            ;
                                                            refreshedQuestion.status = QUESTION_STATUS.finished.id;

                                                            // controllo tutte le domande risposte
                                                            let isRatingCompleted = true;
                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            isRatingCompleted &= th.questions.every( q => q.status === QUESTION_STATUS.finished.id )
                                                                        })
                                                                })
                                                            ;

                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            showlog( tab.id, th.id, th.questions.map( q => q.status ), th.questions.every( q => q.status === QUESTION_STATUS.finished.id ));
                                                                        })
                                                                })
                                                            ;

                                                            this.setState({
                                                                ...this.state,
                                                                tree: refreshedTree,
                                                                ready: isRatingCompleted,
                                                                editAnswer: this.state.editAnswer.filter( ea => ea !== refreshedQuestion.id ),
                                                                tabInfo: {
                                                                    ...this.state.tabInfo,
                                                                    questionCompletedNumber: (this.state.tabInfo.questionCompletedNumber +1)
                                                                }
                                                            })


                                                        }}
                                                        onError={ e => {
                                                            errorlog("Salvataggio domande risposta aperta", e);
                                                            let errorMessage = extractErrorMessage( e );
                                                            this.setState({
                                                                ...this.state,
                                                                errorMessage: errorMessage
                                                            })
                                                        }}
                                                        className={"question-save-button"}
                                                    />
                                                )
                                                : (
                                                    <ActionButton
                                                        visible={
                                                            (
                                                                // scelte singole ---------------------------------------------------
                                                                (
                                                                    !!question.choiches
                                                                        .map(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                        .filter(onlyNotNull)
                                                                        .reduce(getLastElementOr, null)
                                                                )

                                                            )

                                                            &&

                                                            !this.isDisabledQuestion( question.id )

                                                        }
                                                        promise={() => {


                                                            return (
                                                                new Promise((succ, fail) => {

                                                                    this.setState({
                                                                            ...this.state,
                                                                            waitingQuestions: [...this.state.waitingQuestions, question.id ]
                                                                        },
                                                                        () => {




                                                                            // nel caso di scelta multipla ----------------------------------------------------------------------------------------------------------------
                                                                            let ncAnswer = null;
                                                                            if( !!question.isMultipleAnswer ) {
                                                                                let currentChoiches =
                                                                                    question.choiches
                                                                                        .filter(ch => !!(ch.answers.reduce(getLastElementOr, null)))
                                                                                        .map( answeredCh => {


                                                                                            let scoreAnswer = document.getElementById("choiche-question-score-" + answeredCh.choiche_id);
                                                                                            if (!!scoreAnswer && typeof scoreAnswer.value !== "undefined") scoreAnswer = scoreAnswer.value;
                                                                                            else {

                                                                                                // nel caso non sia presente l'input
                                                                                                // scoreAnswer = answeredCh.score;
                                                                                                scoreAnswer =
                                                                                                    question.choiches
                                                                                                        .filter(ch => (ch.choiche_id === answeredCh.choiche_id))
                                                                                                        .map(ch => {
                                                                                                            if( !!ch.answers && ch.answers.length > 0 ) {
                                                                                                                if( typeof ch.answers[0].score !== "undefined" ) {
                                                                                                                    return ch.answers[0].score;
                                                                                                                }
                                                                                                            }
                                                                                                            return answeredCh.score;
                                                                                                        })
                                                                                                        .reduce(getLastElementOr,  0)
                                                                                                ;
                                                                                            }


                                                                                            showlog("answeredCh");
                                                                                            showlog(answeredCh);


                                                                                            answeredCh.answers[0].score = scoreAnswer;  // aggiustamento
                                                                                            return scoreAnswer;
                                                                                        })
                                                                                ;


                                                                            }
                                                                            else {
                                                                                let currentChoiche =
                                                                                    question.choiches
                                                                                        .map(ch => (ch.answers.reduce(getLastElementOr, null)))
                                                                                        .filter(onlyNotNull)
                                                                                        .reduce(getLastElementOr, null)
                                                                                ;
                                                                                showlog("choiche id");
                                                                                showlog( currentChoiche )



                                                                                // let ncAnswer = document.getElementById("nc-answer_" + question.id);
                                                                                ncAnswer = document.getElementById("choiche-question-score-" + currentChoiche.choiche_id);
                                                                                if (!!ncAnswer && typeof ncAnswer.value !== "undefined") ncAnswer = ncAnswer.value;
                                                                                else {
                                                                                    ncAnswer =
                                                                                        question.choiches
                                                                                            .filter(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                                            // .map(ch => ch.score)
                                                                                            .map(ch => {
                                                                                                if( !!ch.answers && ch.answers.length > 0 ) {
                                                                                                    if( typeof ch.answers[0].score !== "undefined" ) {
                                                                                                        return ch.answers[0].score;
                                                                                                    }
                                                                                                }
                                                                                                return ch.score;
                                                                                            })
                                                                                            .reduce(getLastElementOr,  0)
                                                                                    ;
                                                                                }

                                                                                showlog("ncAnswer");
                                                                                showlog(ncAnswer);
                                                                                showlog( document.getElementById("choiche-question-score-" + currentChoiche.choiche_id) );


                                                                                showlog(
                                                                                    question.choiches
                                                                                        .filter(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                                        .map(ch => ch.score)
                                                                                        .reduce(getLastElementOr,  0)
                                                                                );



                                                                            }


                                                                            showlog("bugfix 1");
                                                                            showlog(question);
                                                                            showlog(ncAnswer);

                                                                            processAnswers_syncChain(question, this.getRatingId(), ncAnswer)
                                                                                .then( result => {
                                                                                    succ( result );
                                                                                })
                                                                                .catch( e => {
                                                                                    errorlog("process answer sync chain", e);
                                                                                    fail( e );
                                                                                })
                                                                            ;
                                                                        })


                                                                })
                                                            );

                                                        }}
                                                        onResult={result => {

                                                            let refreshedTree = cloneObject(this.state.tree);
                                                            let refreshedQuestion =
                                                                getQuestionTreeNodeByPath(
                                                                    refreshedTree,   // albero
                                                                    question.path
                                                                )
                                                            ;
                                                            refreshedQuestion.status = QUESTION_STATUS.finished.id;

                                                            // controllo tutte le domande risposte
                                                            let isRatingCompleted = true;
                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            isRatingCompleted &= th.questions.every( q => q.status === QUESTION_STATUS.finished.id )
                                                                        })
                                                                })
                                                            ;

                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            showlog( tab.id, th.id, th.questions.map( q => q.status ), th.questions.every( q => q.status === QUESTION_STATUS.finished.id ));
                                                                        })
                                                                })
                                                            ;

                                                            this.setState({
                                                                ...this.state,
                                                                tree: refreshedTree,
                                                                ready: isRatingCompleted,
                                                                editAnswer: this.state.editAnswer.filter( ea => ea !== refreshedQuestion.id ),
                                                                waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                tabInfo: {
                                                                    ...this.state.tabInfo,
                                                                    questionCompletedNumber: (this.state.tabInfo.questionCompletedNumber +1)
                                                                }
                                                            })




                                                        }}
                                                        onError={e => {
                                                            errorlog("errore al salvataggio",e);
                                                            this.setState({
                                                                ...this.state,
                                                                errorMessage: (!!e.error ? errorMessage.GENERIC_FRONTEND_ERROR_MESSAGE.msg : e),
                                                                waitingQuestions: this.state.waitingQuestions.filter( wq => wq === question.id )
                                                            })
                                                        }}
                                                        className={"question-save-button"}
                                                    />

                                                )
                                        }





                                    </div>



{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                                    {/*
                                    <div>
                                        <code>Question Data</code>
                                        <pre>{ beautify( question ) }</pre>
                                    </div>
                                    */}

                                    {
                                        (
                                            !this.isComeFromAuditer() /*this.props.isFromAuditer*/
                                            && !!!this.isDisabledQuestion(question.id)
                                            && !!question.ncMessage
                                            && question.status === parseInt(QUESTION_STATUS.lowNonCompliant.id, 10)
                                        )
                                            ? (
                                                <div
                                                    className={"nc-message tooltip-icon-cursor nc-low"}
                                                    onClick={clickEvent => {
                                                        this.setState({
                                                            ...this.state,
                                                            showNCMessage: question
                                                        })
                                                    }}
                                                >
                                                    <span className={"nc-message-title"}>
                                                        <span
                                                            className={"nc-message-title-icon"}>{emoj(EMOJ.construction)}</span> <br/>Richiesta di informazioni<br/>clicca qui per visualizzarle
                                                    </span>
                                                </div>
                                            )
                                            : null

                                    }

                                    {
                                        (!this.isComeFromAuditer() /*this.props.isFromAuditer*/ && !!question.ncMessage && question.status === parseInt(QUESTION_STATUS.highNonCompliant.id, 10))
                                            ? (
                                                <div
                                                    className={"nc-message tooltip-icon-cursor nc-high"}
                                                    onClick={clickEvent => {
                                                        this.setState({
                                                            ...this.state,
                                                            showNCMessage: question
                                                        })
                                                    }}
                                                >
                                                    <span className={"nc-message-title"}>
                                                        <span
                                                            className={"nc-message-title-icon"}>{emoj(EMOJ.police_car_light)}</span> NON CONFORME
                                                    </span>
                                                </div>
                                            )
                                            : null

                                    }

                                    {
                                        (!this.isComeFromAuditer() /*this.props.isFromAuditer*/ && !!question.ncMessage && question.status === parseInt(QUESTION_STATUS.attachmentWaiting.id, 10))
                                            ? (
                                                <div
                                                    className={"nc-message tooltip-icon-cursor nc-low"}
                                                    onClick={clickEvent => {
                                                        this.setState({
                                                            ...this.state,
                                                            showAttWaitMessage: question
                                                        })
                                                    }}
                                                >
                                                    <span className={"nc-message-title"}>
                                                        <span
                                                            className={"nc-message-title-icon"}>{emoj(EMOJ.paperclip)}</span> Allegato Richiesto
                                                    </span>
                                                </div>
                                            )
                                            : null

                                    }
{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}





                                    {
                                        !!this.isQuestionInAuditAndAuditIsRunning(question)
                                            ? (
                                                <div className={"answer-body-content-audit"}>
                                                    <span className={"audit-panel-title"}>Audit</span>
                                                    { this.notCompliantComander( question ) }
                                                </div>
                                            )
                                            : null
                                    }


                                </CardBody>
                            </Collapse>
                        </Card>
                    );
                })
        );
    }

    loadData(clonedCurrentTree) {

        return (
            loadAnswers(this.getRatingId(), GENDER_GAP_TAB_ID)

                // conversion ------------------------------------------------------------------------------------------
                .then(data => {

                    let list = data.dataset;
                    let metaData = {
                        info: data.questionCompleted
                    }


                    if( !isNotEmptyArray( list ) ) {
                        return ({
                            seed: [],
                            tree: cloneObject( GENDER_GAP_VOID_TREE ),
                            metaData: metaData
                        });
                    }
                    return (
                        convertGenderGapQuestionTableToTree(list)
                            .then(tree => {
                                showlog("tree");
                                showlog(tree);

                                // controllo dati
                                // if (tree.tabs.map(tab => tab.themes.length > 0).filter(onlyNotNull).length > 0) {
                                if (tree.tabs.map(tab => tab.themes.length > 0).every(onlyNotNull)) {
                                    let csv = exportSeedTableToCSV(list);
                                    showlog(csv);
                                }

                                let treeString = questionTreeLog(tree, false);
                                // showlog(...treeString);



                                let allQuestions = [];
                                tree.tabs
                                    .map( tab => {
                                        tab.themes
                                            .map( th => {
                                                allQuestions = [
                                                    ...allQuestions,
                                                    ...th.questions
                                                ];
                                            })
                                    })
                                ;
                                let openAnswerSettled = [];
                                allQuestions
                                    .map( q => {
                                        openAnswerSettled = [
                                            ...openAnswerSettled,
                                            {
                                                questionID: q.id,
                                                openAnswers: createOpenAnswerData( q )
                                            }
                                        ];
                                    })
                                ;

                                showlog("allQuestions, openAnswerSettled");
                                showlog( allQuestions );
                                showlog( openAnswerSettled );


                                return ({
                                    seed: list,
                                    tree: tree,
                                    metaData: metaData,
                                    openAnswerSettled: openAnswerSettled
                                });

                            })
                    );

                })

                // extras   --------------------------------------------------------------------------------------------
                .then(({ seed, tree, metaData, openAnswerSettled }) => {

                    return (
                        loadEvaluations( RATING_TYPE.gendergap )
                            .then(list => {

                                return ({
                                    seed: seed,
                                    tree: tree,
                                    metaData:
                                        Object.assign(
                                            {},
                                            metaData,
                                            list
                                                .filter(ev => ev.id === parseInt(this.getRatingId(), 10))
                                                .reduce(getLastElementOr, null)
                                        ),
                                    openAnswerSettled: openAnswerSettled
                                });
                            })
                    );

                })




                // finish ----------------------------------------------------------------------------------------------
                .then(({ seed, tree, metaData, openAnswerSettled }) => {


                    // if (!!clonedCurrentTree) cloneOpenNodes(clonedCurrentTree, tree);
                    // nuova procedura ---------------------------------------------------------------------------------
                    let treeTmp = cloneObject(tree);
                    if (!!clonedCurrentTree) {
                        cloneOpenNodes(clonedCurrentTree, treeTmp);
                        // cloneOpenNodes(this.state.tree, treeTmp);

                        // ripristino delle edit answer
                        clonedCurrentTree
                            .tabs
                            .map( tab => {
                                tab.themes
                                    .map( th => {
                                        th.questions
                                            .map( question => {

                                                let answeredChoiche =
                                                    question.choiches
                                                        .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                        .filter( onlyNotNull )
                                                        .reduce( getLastElementOr, isNotEmptyArray(question.choiches) ? question.choiches[0] : null )
                                                ;
                                                let nodeTmp = question;
                                                if( !!answeredChoiche ) {
                                                    nodeTmp = getQuestionTreeNodeByPath(treeTmp, question.path);
                                                    nodeTmp.choiches = question.choiches;
                                                }

                                            })
                                    })
                            })
                        ;

                        // ripristino delle edit answer in ritardo
                        this.state.tree
                            .tabs
                            .map( tab => {
                                tab.themes
                                    .map( th => {
                                        th.questions
                                            .map( question => {

                                                let answeredChoiche =
                                                    question.choiches
                                                        .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                        .filter( onlyNotNull )
                                                        .reduce( getLastElementOr, isNotEmptyArray(question.choiches) ? question.choiches[0] : null )
                                                ;
                                                let nodeTmp = question;
                                                if( !!answeredChoiche ) {
                                                    nodeTmp = getQuestionTreeNodeByPath(treeTmp, question.path);
                                                    nodeTmp.choiches = question.choiches;
                                                }

                                            })
                                    })
                            })
                        ;
                    }
                    // -------------------------------------------------------------------------------------------------

                    let progress = 0;
                    let tabInfo = { questionCompletedNumber: 0, questionTotalNumber: 0 };
                    tabInfo =
                        metaData.info
                            .reduce((final, tabData) => {
                                final.questionCompletedNumber += tabData.questionCompleted.count;
                                final.questionTotalNumber += tabData.questionTotal.count;
                                return final;
                            }, { questionCompletedNumber: 0, questionTotalNumber: 0 })
                    ;
                    progress = tabInfo.questionCompletedNumber / tabInfo.questionTotalNumber;
                    progress *= 100;
                    progress = Math.floor(progress);
                    progress = parseInt(progress, 10);


                    // progress for audit
                    let audit_progress = 0;
                    let audit_tabInfo = { questionAuditReady: 0, questionTotalNumber: 0 };
                    audit_tabInfo =
                        metaData.info
                            .reduce((final, tabData) => {
                                final.questionAuditReady += tabData.questionAuditReady.count;
                                final.questionTotalNumber += tabData.questionTotal.count;
                                return final;
                            }, { questionAuditReady: 0, questionTotalNumber: 0 })
                    ;
                    audit_progress = audit_tabInfo.questionAuditReady / audit_tabInfo.questionTotalNumber;
                    audit_progress *= 100;
                    audit_progress = Math.floor(audit_progress);
                    audit_progress = parseInt(audit_progress, 10);


                    let params = {};
                    if( !!this.props.location.search ) {
                        this.props.location.search
                            .split("?")
                            .reduce( getLastElementOr, this.props.location.search )
                            .split("&")
                            .forEach( kv => {
                                let k = kv.split("=")[0];
                                let v = kv.split("=")[1];
                                params[ k ] = v;
                            })
                    }


                    // ;
                    return (

                        Promise.all([
                            new Promise(( s, f ) => {
                                this.setState({
                                        ...this.state,
                                        seed: seed,
                                        // tree: tree,
                                        tree: treeTmp,
                                        activeTab: GENDER_GAP_TAB_ID,
                                        metaData: metaData,
                                        ready: (progress === 100),
                                        auditReady: (audit_progress === 100),
                                        tabInfo: tabInfo,
                                        auditTabInfo: audit_tabInfo,
                                        wait: false,
                                        editRatingNameInWait: false,
                                        openAnswerSettled: openAnswerSettled,
                                        preventiveQuestionPopupReady: true,
                                        lastLoadTimestamp: (new Date()).getTime(),
                                        params: params
                                    },
                                    () => {
                                        s();
                                    }
                                )
                            }),

                            new Promise(( ss, ff ) => {

                                let allChoiches = [];
                                let questionList = extractAllQuestions(this.state.tree);
                                questionList.map(q => {

                                    q.choiches.map(c => {

                                        allChoiches.push({ q: q, cs: c });

                                    })


                                })


                                ss( true );

                                /*
                                debugger;
                                this.subQuestionRequest(allChoiches)
                                    .then( resultSS => {
                                        showlog("%c  1.1  ", "background:#18f; color: #fff; font-size: 3em;", this.state);
                                        ss( resultSS );
                                    })
                                    .catch(e => {
                                        errorlog("Load subQuestionRequest", e);
                                        ff( e );
                                    })
                                ;
                                */


                            })
                        ])
                            .then( resultList => {
                                return true;
                            })

                            .catch(e => {
                                errorlog("promise all", e);
                                return Promise.reject( e );
                            })

                    );

                })


                /*
                .then(result => {

                    showlog("%c  1.2  ", "background:#18f; color: #fff; font-size: 3em;", this.state);
                    return true;

                })
                */

                .catch(e => {
                    errorlog("LoadData di SiRatingAdmin single Evaluation page",e);
                    this.setState({
                        ...this.state,
                        loadDataError: e
                    });
                    // return Promise.reject(e);
                })
        );


    }



}

export default withRouter(GenderGapSingleEvaluationPage);
