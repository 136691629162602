import {Component} from "react";
import React from "react";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addNewMaterialThemes, deleteMaterialTheme} from "../../../business-logic/gri";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Spinner from "../../Spinner";
import {errorlog, showlog} from "../../../utils/dev-utils";



export default class TreeMaterialThemesNewModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.onClose();
	}

	render() {

		let context = (
			<AvForm>

				<AvGroup>
					<Label for={ this.props.id +"-newMaterialThemeInput" } className={""}>Nuova Tematica Materiale</Label>
					<AvInput
						type="text"
						name={ this.props.id +"-newMaterialThemeInput" }
						id={ this.props.id +"-newMaterialThemeInput" }
						value={ this.state.name }
						className={"tree-data-entry-value-input"}
						onChange={ changeTextEvent => {
							this.setState(
								{
									...this.state,
									name: changeTextEvent.target.value
								})
						}}
						required
					/>
				</AvGroup>


			</AvForm>
		);

		let content = (
			<Modal isOpen={ true }>

				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {
							console.log("click su conferma");

							this.setState({
								...this.state,
								inWait: true
							})

							addNewMaterialThemes( this.state.name, this.props.collect )
								.then( () => {
									this.props.onClose();
								})
								.catch(e => {
									errorlog("errore", e );

									// TODO


									this.props.onClose();
								})


						}}
					>
						{
							(!!this.state.inWait)
								? (
									<React.Fragment>
										<Spinner color={"#fffa"} className="spinner-border-sm mr-1" />
										<span>&nbsp;&nbsp;elaborazione...&nbsp;&nbsp;</span>
									</React.Fragment>
								)
								: "OK"
						}
					</Button>{' '}
					<Button color="link" onClick={this.toggle}>
						annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;
	}

	componentDidMount() {}
}
