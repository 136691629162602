import React, {Component, Suspense} from "react";
import {Card, CardBody, CardFooter, Col, Label, Row} from 'reactstrap';

import Select from 'react-select';
import {
    EMOJ,
    errorlog,
    getLastElementOr,
    isNotEmptyArray,
    mountedlog,
    renderlog,
    showlog,
    sortById
} from "../../../utils";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import ActionButton from "../../save-button";
import "./srtool-collect-chart-filters.css";
import "../../../style/rounded-modal.css";
import {RequirementFilter} from "../../../services/request-manager";
import {hasPermission} from "../../../business-logic/auth";
import {loadCollect} from "../../../business-logic/gri";
import {permissions} from "../../../conf/permissions";
import {COLOR_POLICY} from "../../../env";


// strutture dati
const CHART_TYPES = [
    { id: 0, name: "line", label: "Curva" },
    { id: 1, name: "instogram", label: "Istogramma" },
    { id: 2, name: "pie", label: "Torta" },
];


export default class SuperAdminChartFilter extends Component {


    /**
     * Props in arrivo
     *
         UnitMeasurment: "€"
         chapterSelected: 7
         chapter_id: 7
         chapters: Array(7) [ {…}, {…}, {…}, … ]
         chartId: 4
         collectionCompare_select: 1
         data: Array(3) [ {…}, {…}, {…} ]
         graphType_select: 0
         id: 83
         informativeSelected: 57
         informative_id: 57
         informatives: Array(4) [ {…}, {…}, {…}, … ]
         name: "<b>201-1 - Valore economico direttamente generato e distribuito</b><br />i. Valore economico direttamente generato: ricavi"
         path: Array(6) [ 2, 7, 57, … ]
         requirementSelected: 83
         requirement_id: 83
         requirements: Array [ {…}, {…} ]
         subrequirement: 133
         subrequirementSelected: 133
         subrequirements: Array(7) [ {…}, {…}, {…}, … ]
         subsubrequirement: 0
         subsubrequirementSelected: 0
         subsubrequirements: Array []
         thematicSelected: 2
         thematic_id: 2
         thematics: Array(4) [ {…}, {…}, {…}, … ]
         timing_select: false
         type: 0
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            collects: null,
            compareCollects: this.props.conf?.collectionCompare_select || false,
            chartType: this.props.conf?.graphType_select || 0,
            thematics: this.props.tab || this.props.conf?.thematics,
            chapters: this.props.conf?.chapters,
            infos: this.props.conf?.informatives,
            reqs: this.props.conf?.requirements,
            subReqs: this.props.conf?.subrequirements,
            subSubReqs: this.props.conf?.subsubrequirements,
            selected: {
                collect: this.props.conf?.selectedCollect || 0,
                collectName: this.props.conf?.selectedCollectName || "",
                thematic: this.props.tab || this.props.conf?.thematic_id,
                chapter: this.props.conf?.chapter_id,
                info: this.props.conf?.informative_id,
                req: this.props.conf?.requirement_id,
                subReq: this.props.conf?.subrequirement,
                subSubReq: this.props.conf?.subsubrequirement
            },
            raw: this.props.conf
        }

        this.inBuilding = this.inBuilding.bind(this);
    }

    inBuilding = () => (
        <div>Wait</div>
    )

    filterReady = () => (
        (!this.state.compareCollects ? this.state.selected.collect : true)
        && !!this.state.selected.thematic
        && !!this.state.selected.chapter
        && !!this.state.selected.info
        && !!this.state.selected.req
        // && this.state.selected.subReq
        // && this.state.selected.subSubReq
        && !!this.props.chartId
    );

    render() {

        renderlog("SRToolCollectChartFilters", this.state, this.props, COLOR_POLICY.select);

        let content = (
            <Suspense fallback={this.inBuilding()}>

                <Card className="rounded-modal-container">

                    <CardBody>



                        {/*

                                    (!!this.state.collects && this.state.collects.length > 0)
                                        ? (
                                            <Row>
                                                <Col sm={6}>
                                                    <Label className={""}>Raccolta</Label>
                                                </Col>
                                                <Col sm={6}>
                                                    <Select
                                                        className={""}
                                                        classNamePrefix="react-select"
                                                        name={ "" }
                                                        id={ "" }
                                                        onChange={ changeEvent => {
                                                            this.setState({
                                                                ...this.state,
                                                                selected: {
                                                                    ...this.state.selected,
                                                                    collect: changeEvent.value,
                                                                    collectName: changeEvent.label
                                                                }
                                                            })
                                                        }}
                                                        value={
                                                            this.state.collects
                                                                .filter( th => this.state.selected.collect === th.id )
                                                                .map( th => ({ value: th.id, label: th.name }))
                                                                .reduce( getLastElementOr, null )
                                                        }
                                                        options={
                                                            this.state.collects
                                                                .map( th => ({ value: th.id, label: th.name }))
                                                        }
                                                        placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                        : (
                                            <WaitingOrNodataMessage
                                                waiting={ !(!!this.state.collects && this.state.collects.length === 0) }
                                                waitMessage={"Caricamento..."}
                                                nodataMessage={"Nessuna Raccolta"}
                                                className={"chart-filter-no-data-label"}
                                                fullHeight={ false }
                                                spinnerColor={"success"}
                                            />
                                        )


                        */}

                        {/*
                        <Row>
                            <Col sm={6}>
                                <Label className={""}>Tematica</Label>
                            </Col>
                            <Col sm={6}>

                                {
                                    (!!this.state.thematics && this.state.thematics.length > 0)
                                        ? (
                                            <Select
                                                className={"chart-filter-select"}
                                                classNamePrefix="react-select"
                                                name={ "" }
                                                id={ "theme-select" }
                                                onChange={ changeEvent => {
                                                    this.setState({
                                                            ...this.state,
                                                            selected: {
                                                                ...this.state.selected,
                                                                thematic: changeEvent.value
                                                            }
                                                        },
                                                        () => {
                                                            RequirementFilter(1, this.state.selected.thematic )
                                                                .then( list => {

                                                                    this.setState({ chapters: list })

                                                                })
                                                        }
                                                    )
                                                }}
                                                value={
                                                    this.state.thematics
                                                        .filter( th => this.state.selected.thematic === th.id )
                                                        .map( th => ({ value: th.id, label: th.it }))
                                                        .reduce( getLastElementOr, null )
                                                }
                                                options={
                                                    this.state.thematics
                                                        .map( th => ({ value: th.id, label: th.it }))
                                                }
                                                placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                isDisabled={ true }
                                            />
                                        )
                                        : (
                                            <WaitingOrNodataMessage
                                                waiting={ (!!this.state.thematics && this.state.thematics.length === 0) }
                                                waitMessage={"Caricamento..."}
                                                nodataMessage={"Nessuna Tematica"}
                                                className={"chart-filter-no-data-label"}
                                                fullHeight={ false }
                                                spinnerColor={"primary"}
                                            />
                                        )
                                }

                            </Col>
                        </Row>
                        */}

                        {
                            (!!this.state.selected && !!this.state.selected.thematic)
                                ? (
                                    <Row>
                                        <Col sm={6}>
                                            <Label className={""}>Capitolo</Label>
                                        </Col>
                                        <Col sm={6}>

                                            {
                                                (!!this.state.chapters && this.state.chapters.length > 0)
                                                    ? (
                                                        <Select
                                                            className={"chart-filter-select"}
                                                            classNamePrefix="react-select"
                                                            name={ "" }
                                                            id={ "chapter-select" }
                                                            onChange={ changeEvent => {
                                                                this.setState({
                                                                        ...this.state,
                                                                        selected: {
                                                                            ...this.state.selected,
                                                                            chapter: changeEvent.value
                                                                        }
                                                                    },
                                                                    () => {
                                                                        RequirementFilter(2, this.state.selected.chapter )
                                                                            .then( list => {
                                                                                this.setState({ infos: list })
                                                                            })
                                                                    }
                                                                )
                                                            }}
                                                            value={
                                                                this.state.chapters
                                                                    .filter( th => this.state.selected.chapter === th.id )
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                                    .reduce( getLastElementOr, null )
                                                            }
                                                            options={
                                                                this.state.chapters
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                            }
                                                            placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                        />
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !(!!this.state.chapters && this.state.chapters.length === 0) }
                                                            waitMessage={"Caricamento..."}
                                                            nodataMessage={"Nessun Capitolo"}
                                                            className={"chart-filter-no-data-label"}
                                                            fullHeight={ false }
                                                            spinnerColor={"primary"}
                                                        />
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                )
                                : null
                        }



                        {
                            (!!this.state.selected && !!this.state.selected.chapter)
                                ? (
                                    <Row>
                                        <Col sm={6}>
                                            <Label className={""}>Informativa</Label>
                                        </Col>
                                        <Col sm={6}>

                                            {
                                                (!!this.state.infos && this.state.infos.length > 0)
                                                    ? (
                                                        <Select
                                                            className={"chart-filter-select"}
                                                            classNamePrefix="react-select"
                                                            name={ "" }
                                                            id={ "info-select" }
                                                            onChange={ changeEvent => {
                                                                this.setState({
                                                                        ...this.state,
                                                                        selected: {
                                                                            ...this.state.selected,
                                                                            info: changeEvent.value
                                                                        }
                                                                    },
                                                                    () => {
                                                                        RequirementFilter(3, this.state.selected.info )
                                                                            .then( list => {
                                                                                this.setState({ reqs: list })
                                                                            })
                                                                    }
                                                                )
                                                            }}
                                                            value={
                                                                this.state.infos
                                                                    .filter( th => this.state.selected.info === th.id )
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                                    .reduce( getLastElementOr, null )
                                                            }
                                                            options={
                                                                this.state.infos
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                            }
                                                            placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                        />
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !(!!this.state.infos && this.state.infos.length === 0) }
                                                            waitMessage={"Caricamento..."}
                                                            nodataMessage={"Nessuna Informativa"}
                                                            className={"chart-filter-no-data-label"}
                                                            fullHeight={ false }
                                                            spinnerColor={"primary"}
                                                        />
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                )
                                : null
                        }


                        {
                            (!!this.state.selected && !!this.state.selected.info)
                                ? (
                                    <Row>
                                        <Col sm={6}>
                                            <Label className={""}>Requisito</Label>
                                        </Col>
                                        <Col sm={6}>

                                            {
                                                (!!this.state.reqs && this.state.reqs.length > 0)
                                                    ? (
                                                        <Select
                                                            className={"chart-filter-select"}
                                                            classNamePrefix="react-select"
                                                            name={ "" }
                                                            id={ "req-select" }
                                                            onChange={ changeEvent => {
                                                                this.setState({
                                                                        ...this.state,
                                                                        selected: {
                                                                            ...this.state.selected,
                                                                            req: changeEvent.value
                                                                        }
                                                                    },
                                                                    () => {
                                                                        RequirementFilter(4, this.state.selected.req )
                                                                            .then( list => {
                                                                                this.setState({ subReqs: list })
                                                                            })
                                                                    }
                                                                )
                                                            }}
                                                            value={
                                                                this.state.reqs
                                                                    .filter( th => this.state.selected.req === th.id )
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                                    .reduce( getLastElementOr, null )
                                                            }
                                                            options={
                                                                this.state.reqs
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                            }
                                                            placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                        />
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !(!!this.state.reqs && this.state.reqs.length === 0) }
                                                            waitMessage={"Caricamento..."}
                                                            nodataMessage={"Nessun Requisito"}
                                                            className={"chart-filter-no-data-label"}
                                                            fullHeight={ false }
                                                            spinnerColor={"primary"}
                                                        />
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                )
                                : null
                        }



                        {
                            (!!this.state.selected && !!this.state.selected.req)
                                ? (
                                    <Row>
                                        <Col sm={6}>
                                            <Label className={""}>Sotto-Requisito</Label>
                                        </Col>
                                        <Col sm={6}>

                                            {
                                                (!!this.state.subReqs && this.state.subReqs.length > 0)
                                                    ? (
                                                        <Select
                                                            className={"chart-filter-select"}
                                                            classNamePrefix="react-select"
                                                            name={ "" }
                                                            id={ "sub-req-select" }
                                                            onChange={ changeEvent => {
                                                                this.setState({
                                                                        ...this.state,
                                                                        selected: {
                                                                            ...this.state.selected,
                                                                            subReq: changeEvent.value
                                                                        }
                                                                    },
                                                                    () => {
                                                                        RequirementFilter(5, this.state.selected.subReq )
                                                                            .then( list => {
                                                                                this.setState({ subSubReqs: list })
                                                                            })
                                                                    }
                                                                )
                                                            }}
                                                            value={
                                                                this.state.subReqs
                                                                    .filter( th => this.state.selected.subReq === th.id )
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                                    .reduce( getLastElementOr, null )
                                                            }
                                                            options={
                                                                this.state.subReqs
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                            }
                                                            placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                        />
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !(!!this.state.subReqs && this.state.subReqs.length === 0) }
                                                            waitMessage={"Caricamento..."}
                                                            nodataMessage={"Nessun Sotto-Requisito"}
                                                            className={"chart-filter-no-data-label"}
                                                            fullHeight={ false }
                                                            spinnerColor={"primary"}
                                                        />
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                )
                                : null
                        }



                        {
                            (!!this.state.selected && !!this.state.selected.subReq)
                                ? (
                                    <Row>
                                        <Col sm={6}>
                                            <Label className={""}>Sotto-Sotto-Requisito</Label>
                                        </Col>
                                        <Col sm={6}>

                                            {
                                                (!!this.state.subSubReqs && this.state.subSubReqs.length > 0)
                                                    ? (
                                                        <Select
                                                            className={"chart-filter-select"}
                                                            classNamePrefix="react-select"
                                                            name={ "" }
                                                            id={ "sub-req-select" }
                                                            onChange={ changeEvent => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    selected: {
                                                                        ...this.state.selected,
                                                                        subSubReq: changeEvent.value
                                                                    }
                                                                })
                                                            }}
                                                            value={
                                                                this.state.subSubReqs
                                                                    .filter( th => this.state.selected.subSubReq === th.id )
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                                    .reduce( getLastElementOr, null )
                                                            }
                                                            options={
                                                                this.state.subSubReqs
                                                                    .map( th => ({ value: th.id, label: th.it }))
                                                            }
                                                            placeholder={ <span className={"select-placeholder"}>seleziona ...</span> }
                                                        />
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !(!!this.state.subSubReqs && this.state.subSubReqs.length === 0) }
                                                            waitMessage={"Caricamento..."}
                                                            nodataMessage={"Nessun Sotto-Sotto-Requisito"}
                                                            className={"chart-filter-no-data-label"}
                                                            fullHeight={ false }
                                                            spinnerColor={"primary"}
                                                        />
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                )
                                : null
                        }



                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col sm={12}>

                                <ActionButton
                                    buttonText={
                                        <span>
                                            OK&nbsp;&nbsp;
                                            <i className="mdi mdi-chart-areaspline"/>
                                        </span>
                                    }
                                    color={ !!this.filterReady() ? "success" : "light" }
                                    className="float-right"
                                    promise={ () => {
                                        showlog("controllo dati da inviare");



                                        let settingData = {
                                            thematic_id: this.state.selected.thematic,          // tematica selezionata
                                            chapter_id: this.state.selected.chapter,            // capitolo selezionata
                                            informative_id: this.state.selected.info,           // informativa selezionata
                                            requirement_id: this.state.selected.req,            // requisito selezionata
                                            subrequirement: this.state.selected.subReq,         // sotto-requisito selezionata
                                            subsubrequirement: this.state.selected.subSubReq,   // sotto-sotto-requisito selezionata

                                            /*
                                            type:                                               // indica il livello del nodo a partire dall'ultimo nodo possibile
                                                Object.keys(this.state.selected)
                                                    .map( sel => {
                                                        if( !!this.state.selected[sel] ) {
                                                            if( sel === "req" ) return 0;
                                                            if( sel === "subReq" ) return 1;
                                                            if( sel === "subSubReq" ) return 2;
                                                        }
                                                        return null;
                                                    })
                                                    .map( sel => ({id: sel}))
                                                    .sort( inverseSortById )
                                                    .filter( onlyFirst )
                                                    .reduce( getLastElementOr, {id: 0} )
                                                        .id
                                            ,
                                            id:                                                 // l'id del nodo all'ultimo livello
                                                Object.keys(this.state.selected)
                                                    .map( key => this.state.selected[key] )
                                                    .filter( onlyNotNull )
                                                    .reverse()
                                                    .filter( onlyFirst )
                                                    .reduce( getLastElementOr, 0 )
                                            ,
                                            */

                                            // timing_select: 0,
                                            // graphType_select: this.state.chartType,                             // tipo di grafico
                                            // collectionCompare_select: (!!this.state.compareCollects ? 1 : 0),   // flag di comparazione
                                            chartId: this.props.chartId,                                        // id del grafico
                                            // selectedCollect: this.state.selected.collect,                       // raccolta
                                            // selectedCollectName: (!!this.state.compareCollects ? "" : this.state.selected.collectName)                 // raccolta
                                        }


                                        showlog("dati da inviare");
                                        showlog(settingData);

                                        return Promise.resolve( settingData );
                                    }}
                                    onProcess={ () => {

                                    }}
                                    onResult={ resultData => {
                                        if( !!this.state.selected.req ) {
                                            this.props.onSave( resultData );
                                        }
                                    }}
                                    onError={ error => {
                                        errorlog( error );
                                    }}
                                />

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>







            </Suspense>
        );

        return content;
    }



    componentDidMount() {

        mountedlog("SRToolCollectChartFilters");

        return (
            Promise.all([

                    // collects --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((collectsSuccess, collectsFail) => {
                        loadCollect()
                            .then( collectList => collectsSuccess( collectList ) )
                            .catch( err => collectsFail( err ) )
                        ;
                    }),

                    // thematics --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((thematicsSuccess, thematicsFail) => {
                        if( !isNotEmptyArray( this.state.thematics ) ) {
                            RequirementFilter(0, 0)
                                .then( ths => thematicsSuccess( ths ) )
                                .catch( err => thematicsFail( err ) )
                            ;
                        }
                        else thematicsSuccess( this.state.thematics );
                    }),

                    // chapters --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((chaptersSuccess, chaptersFail) => {
                        if( !isNotEmptyArray( this.state.chapters ) && !!this.state.selected.thematic ) {
                            RequirementFilter(1, this.state.selected.thematic )
                                .then( list => chaptersSuccess( list ) )
                                .catch( err => chaptersFail( err ) )
                            ;
                        }
                        else chaptersSuccess( this.state.chapters );
                    }),

                    // infos --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((infosSuccess, infosFail) => {
                        if( !isNotEmptyArray( this.state.infos ) && !!this.state.selected.chapter ) {
                            RequirementFilter(2, this.state.selected.chapter )
                                .then( list => infosSuccess( list ) )
                                .catch( err => infosFail( err ) )
                            ;
                        }
                        else infosSuccess( this.state.infos );
                    }),

                    // reqs --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((reqsSuccess, reqsFail) => {
                        if( !isNotEmptyArray( this.state.reqs ) && !!this.state.selected.info ) {
                            RequirementFilter(3, this.state.selected.info )
                                .then( list => reqsSuccess( list ) )
                                .catch( err => reqsFail( err ) )
                            ;
                        }
                        else reqsSuccess( this.state.reqs );
                    }),

                    // subreqs --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((subreqsSuccess, subreqsFail) => {
                        if( !isNotEmptyArray( this.state.subReqs ) && !!this.state.selected.req ) {
                            RequirementFilter(4, this.state.selected.req )
                                .then( list => subreqsSuccess( list ) )
                                .catch( err => subreqsFail( err ) )
                            ;
                        }
                        else subreqsSuccess( this.state.subReqs );
                    }),

                    // subsubreqs --------------------------------------------------------------------------------------------------------------------------------
                    new Promise((subsubreqsSuccess, subsubreqsFail) => {
                        if( !isNotEmptyArray( this.state.subSubReqs ) && !!this.state.selected.subReq ) {
                            RequirementFilter(5, this.state.selected.subReq )
                                .then( list => subsubreqsSuccess( list ) )
                                .catch( err => subsubreqsFail( err ) )
                            ;
                        }
                        else subsubreqsSuccess( this.state.subSubReqs );
                    })

                ])

                // finish ------------------------------------------------------------------------------------------------
                .then( ([collects, thematics, chapters, infos, reqs, subreqs, subsubreqs]) => {

                    // debugger;

                    this.setState({
                        collects: collects.sort( sortById ),
                        thematics: thematics.sort( sortById ),
                        chapters: [...(chapters || [])].sort( sortById ),
                        infos: [...(infos || [])].sort( sortById ),
                        reqs: [...(reqs || [])].sort( sortById ),
                        subReqs: [...(subreqs || [])].sort( sortById ),
                        subSubReqs: [...(subsubreqs || [])].sort( sortById )
                    });

                })

                .catch( err => {
                    errorlog("Richieste Dati Filtri", err );
                })


        )

    }



    _componentDidMount() {

        mountedlog("SRToolCollectChartFilters");

        return (

            new Promise((success, fail) => {


                showlog("controllo tematiche", !isNotEmptyArray( this.state.thematics ) );
                if( !isNotEmptyArray( this.state.thematics ) ) {
                    RequirementFilter(0, 0)

                        // set thematic ----------------------------------------------------------------------------------------
                        .then( ths => {

                            this.setState(
                                { thematics: ths },
                                () => {
                                    success();
                                }
                            );

                        })

                        .catch( err => {
                            errorlog("Richiesta Filtri", err );
                            fail( err );
                        })
                }
                else {
                    success( this.state.thematics );
                }

            })

                // get collect list ------------------------------------------------------------------------------------
                .then( thematics => {


                    if( !this.state.compareCollects ) {

                        if( !!hasPermission( permissions.GET_COLLECT ) ) {

                            return (
                                loadCollect()
                                    .then( collectList => ({
                                        thematics: thematics,
                                        collects: collectList
                                    }))

                                    .catch( err => {
                                        errorlog("Lista raccolte", err );
                                        return Promise.reject( err );
                                    })

                            );

                        }
                        else {
                            showlog("Non hai i permessi di accesso alle raccolte");
                            return ({
                                thematics: thematics,
                                collects: this.state.collects || []
                            })
                        }
                    }
                    else {
                        return ({
                            thematics: thematics,
                            collects: this.state.collects || []
                        })
                    }
                })


                // get chapters ------------------------------------------------------------------------------------------------
                .then( ({collects, thematics}) => {
                    if( !isNotEmptyArray( this.state.chapters ) && !!this.state.selected.thematic ) {
                        RequirementFilter(1, this.state.selected.thematic )
                            .then( list => {
                                return({
                                    thematics: thematics,
                                    collects: collects,
                                    chapters: list
                                })
                            })
                    }
                    else {
                        return({
                            thematics: thematics,
                            collects: collects,
                            chapters: this.state.chapters
                        })
                    }
                })

                // get infos ------------------------------------------------------------------------------------------------
                .then( ({collects, thematics, chapters, infos}) => {
                    if( !isNotEmptyArray( this.state.infos ) && !!this.state.selected.chapter ) {
                        RequirementFilter(2, this.state.selected.chapter )
                            .then( list => {
                                return({
                                    thematics: thematics,
                                    collects: collects,
                                    chapters: chapters,
                                    infos: infos
                                })
                            })
                    }
                    else {
                        return({
                            thematics: thematics,
                            collects: collects,
                            chapters: chapters,
                            infos: this.state.infos
                        })
                    }
                })
                // get reqs ------------------------------------------------------------------------------------------------
                // get subreqs ------------------------------------------------------------------------------------------------
                // get subsubreqs ------------------------------------------------------------------------------------------------
                // finish ------------------------------------------------------------------------------------------------
                .then( ({collects, thematics, chapters, infos, reqs, subreqs, subsubreqs}) => {

                    this.setState({
                        collects: collects,
                        thematics: thematics,
                        chapters: chapters,
                        infos: infos,
                        reqs: reqs,
                        subreqs: subreqs,
                        subsubreqs: subsubreqs
                    });

                })

        );


    }


}

