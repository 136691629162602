import React, {Component} from "react";
import {Card, CardBody, CardHeader, Nav, NavItem, TabContent, TabPane} from "reactstrap";
import ActionButton from "../components/save-button";
import enFlag from '../components/LanguageDropdown/flags/us.jpg';
import italyFlag from '../components/LanguageDropdown/flags/italy.jpg';
import franceFlag from "../components/LanguageDropdown/flags/french.jpg";
import classnames from "classnames";

export default class EditQuestionSample extends Component {

	state = {
		data: [
			{
				id: 1,
				text: "testo della domanda",
				inEdit: false,
				answers: [
					{
						id: 10,
						text: "risposta 1",
						inEdit: false
					},
					{
						id: 20,
						text: "risposta 1",
						inEdit: false
					},
					{
						id: 30,
						text: "risposta 1",
						inEdit: false
					}
				]
			},
			{
				id: 2,
				text: "testo della seconda domanda",
				inEdit: false,
				answers: [
					{
						id: 11,
						text: "risposta 1",
						inEdit: false
					},
					{
						id: 21,
						text: "risposta 1",
						inEdit: false
					},
					{
						id: 31,
						text: "risposta 1",
						inEdit: false
					}
				]
			},
		]
	}

	constructor( props ) {
		super(props);
	}

	render() {


		return (

			<div style={{marginTop: "2rem"}}>
				<Nav tabs>
					<NavItem key={"it"} id={"it"} style={{borderRadius: "5px 5px 0px 0px", border: "solid #aaa 1px", borderBottom: "solid #fff 0px", padding: "5px", background: "#eee8"}}>
						<img src={ italyFlag } alt={"IT"} style={{width: "20px", borderRadius: "3px", marginRight: "10px"}}/>
						<span style={{display: "inline-block", paddingTop: "0px", marginRight: "30px"}}>IT</span>
					</NavItem>
					<NavItem key={"en"} style={{borderRadius: "5px 5px 0px 0px", border: "solid #aaa3 1px", borderBottom: "solid #fff 0px", padding: "5px"}}>
						<img src={ enFlag } alt={"EN"} style={{width: "20px", borderRadius: "3px", marginRight: "10px"}}/>
						<span style={{display: "inline-block", paddingTop: "0px", marginRight: "30px"}}>EN</span>
					</NavItem>
					<NavItem key={"fr"} style={{borderRadius: "5px 5px 0px 0px", border: "solid #aaa3 1px", borderBottom: "solid #fff 0px", padding: "5px"}}>
						<img src={ franceFlag } alt={"FR"} style={{width: "20px", borderRadius: "3px", marginRight: "10px"}}/>
						<span style={{display: "inline-block", paddingTop: "0px", marginRight: "30px"}}>FR</span>
					</NavItem>
				</Nav>

				<TabContent activeTab={"it"}>
					<TabPane
						tabId={"it"}
						key={"it"}
						className={classnames({ active: true })}
					>
						{
							this.state.data
								.map( q => (
									<Card>
										<CardHeader>
											<div className={""} style={{ display: "flex", flexDirection: "row"}}>
												<div className={""} >
													{
														!!q.inEdit
															? (
																<input
																	type={"text"}
																	defaultValue={ q.text }
																	id={ "q-"+ q.id }
																/>
															)
															: <span>{ q.text }</span>
													}
												</div>
												{
													!!q.inEdit
														? (
															<ActionButton
																promise={ () => {
																	return (
																		new Promise((success, fail) => {
																			setTimeout(
																				function() {
																					this.setState({
																						...this.state,
																						data:
																							this.state.data.map( d => {
																								if ( d.id === q.id ) {
																									d.inEdit = false;
																									d.text = document.getElementById("q-"+ q.id).value;
																								}
																								return d;
																							})
																					})
																				}.bind( this ),
																				1000
																			)
																		})
																	);
																}}
																onResult={ result => {

																}}
															/>
														)
														: (
															<i
																style={{marginLeft: "20px", cursor: "pointer"}}
																className="mdi mdi-pencil"
																onClick={ clickEvent => {
																	this.setState({
																		...this.state,
																		data:
																			this.state.data.map( d => {
																				if ( d.id === q.id ) d.inEdit = true;
																				return d;
																			})
																	})
																}}
															/>
														)
												}
											</div>
										</CardHeader>
										<CardBody>
											{
												q.answers
													.map( a => (
														<div className={""} style={{ display: "flex", flexDirection: "row"}}>
															<div className={""}>{ a.text }</div>
															{
																!!a.inEdit
																	? (
																		<ActionButton
																			promise={ () => {
																				return (
																					new Promise((success, fail) => {
																						setTimeout(
																							function() {
																								this.setSTate({
																									...this.state,
																									data:
																										this.state.data.map( d => {
																											if ( d.id === q.id ) {
																												d.answer
																													.map( aa => {
																														if ( aa.id === a.id ) aa.inEdit = false;
																														return aa;
																													})
																											}
																											return d;
																										})
																								})
																							}.bind( this ),
																							1000
																						)
																					})
																				);
																			}}
																			onResult={ result => {

																			}}
																		/>
																	)
																	: (
																		<i
																			className="mdi mdi-pencil"
																			style={{marginLeft: "20px", cursor: "pointer"}}
																			onClick={ clickEvent => {
																				this.setSTate({
																					...this.state,
																					data:
																						this.state.data.map( d => {
																							if ( d.id === q.id ) {
																								d.answer
																									.map( aa => {
																										if ( aa.id === a.id ) aa.inEdit = true;
																										return aa;
																									})
																							}
																							return d;
																						})
																				})
																			}}
																		/>
																	)
															}
														</div>
													))
											}
										</CardBody>
									</Card>
								))
						}

						{/*<GotoTopComponent/>*/}

					</TabPane>
				</TabContent>
			</div>



		);

	}
}
