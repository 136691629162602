import {sortById} from "./array-utils";
import {sha256} from "js-sha256";

export const getTreeObjectHash = obj => sha256( JSON.stringify( obj ) );

export const getTreeFingerPrint = tree => sha256( getTreeRawFingerPrint( tree ) );

export const getTreeRawFingerPrint = tree => btoa( JSON.stringify( getTreeSeedsFingerPrint( tree ) ).split("[").join("").split("]").join("") );

export const getTreeSeedsFingerPrint = tree => (
    JSON.stringify( getTreeRawSeedsFingerPrint( tree ) )
        .split("[").join("")
        .split("]").join("")
);

export const getTreeRawSeedsFingerPrint = tree => (
    tree
        .tabs
        .sort( sortById )
        .map( t => [
            {id: t.id, timing: t.timing, material: t.material},
            ...t.chapters
                .sort( sortById )
                .map( c =>
                    [
                        {id: c.id, timing: c.timing, material: c.material},
                        ...c.infos
                            .sort( sortById )
                            .map( i =>
                                [
                                    {id: i.id, timing: i.timing, material: i.material},
                                    ...i.requirements
                                        .sort( sortById )
                                        .map( r =>
                                            [
                                                {id: r.id, timing: r.timing, material: r.material},
                                                ...r.subReqs
                                                    .sort( sortById )
                                                    .map( s => [
                                                        {id: s.id, timing: s.timing, material: s.material},
                                                        ...s.subSubReqs
                                                            .sort( sortById )
                                                            .map( ss => ({id: ss.id, timing: ss.timing, material: ss.material}) )
                                                    ])
                                            ])
                                ])
                    ])
        ])
);

