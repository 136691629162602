import React, {Component} from "react";
import {COLOR_POLICY, COMPANY_EMPLOYEE, COMPANY_RANGE, EMAIL_REGEX, PHONE_REGEX} from "../../../env";
import {
	Button,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from "reactstrap";
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import Select from 'react-select';
import "../modal.css";
import {
	editUpdateCompany,
	loadAllComapnyAllowedDimensions,
	loadAllProvinces, loadAllSectors,
	saveCompany
} from "../../../business-logic/companies";
import {
	errorlog,
	extractErrorMessage,
	getLastElementOr,
	isNotEmptyArray,
	onlyNotNull,
	renderlog,
	showlog
} from "../../../utils";
import ActionButton from "../../save-button";
import classnames from "classnames";
import {translate} from "../../i18n/translate-function";


export default class CompanyModal extends Component {


	constructor( props ) {
		super( props );


		this.state = {
			name:
				this.props.name ||
				(
					(!!this.props.company && !!this.props.company.ragioneSociale)
						? this.props.company.ragioneSociale
						: null
				),
			piva:
				this.props.piva ||
				(
					(!!this.props.company && !!this.props.company.partitaIva)
						? this.props.company.partitaIva
						: null
				),
			dimList: [],
			dim:
				(
					(!!this.props.company && !!this.props.company.dimensionId)
						? this.props.company.dimensionId
						: null
				),
			employeesNumbersLevel: (
				(!!this.props.company && !!this.props.company.employeesNumbersLevel)
					? this.props.company.employeesNumbersLevel
					: null
			),
			email:
				(
					(!!this.props.company && !!this.props.company.indirEmail)
						? this.props.company.indirEmail
						: null
				),
			cap: (
				(!!this.props.company && !!this.props.company.cap)
					? this.props.company.cap
					: null
			),
			city: (
				(!!this.props.company && !!this.props.company.localita)
					? this.props.company.localita
					: null
			),
			address: (
				(!!this.props.company && !!this.props.company.indirizzo)
					? this.props.company.indirizzo
					: null
			),
			sedeLegale: (
				(!!this.props.company && !!this.props.company.sedeLegale)
					? this.props.company.sedeLegale
					: null
			),
			codiceFiscale: (
				(!!this.props.company && !!this.props.company.codFiscale)
					? this.props.company.codFiscale
					: null
			),
			codiceUnivoco: (
				(!!this.props.company && !!this.props.company.codiceSdi)
					? this.props.company.codiceSdi
					: null
			),
			pec: (
				(!!this.props.company && !!this.props.company.indirEmailPecsdi)
					? this.props.company.indirEmailPecsdi
					: null
			),
			datiLegale: (
				(!!this.props.company && !!this.props.company.riferimento)
					? this.props.company.riferimento
					: null
			),
			sector:
				(
					(!!this.props.company && !!this.props.company.sector_id)
						? this.props.company.sector_id
						: null
				),

			activeTab: "anagraphics-tab",
			sectorList: [],
			errors: {}
		}

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		if( typeof this.props.onClose === "function" && !this.state.inWait) this.props.onClose( forceRefresh );
	}

	render() {

		renderlog("PROJECT_MANAGER CompanyModal", this.state, this.props, COLOR_POLICY.validated);


		let context = (
			<AvForm>



				<Nav tabs>

					<NavItem key={0}>
						<NavLink
							id={"anagraphics-tab"}
							href="#"
							className={classnames({ active: this.state.activeTab === "anagraphics-tab" })}
							onClick={() => {

								this.setState({
									...this.state,
									activeTab: "anagraphics-tab"
								});
							}}>
							<i
								className={classnames(
									'd-lg-none',
									'd-block',
									'mr-1'
								)} />
							<span className="d-none d-lg-block">Anagrafica</span>
						</NavLink>
					</NavItem>

					<NavItem key={1}>
						<NavLink
							id={"address-tab"}
							href="#"
							className={classnames({ active: this.state.activeTab === "address-tab" })}
							onClick={() => {

								this.setState({
									...this.state,
									activeTab: "address-tab"
								});
							}}>
							<i
								className={classnames(
									'd-lg-none',
									'd-block',
									'mr-1'
								)} />
							<span className="d-none d-lg-block">Sede Legale</span>
						</NavLink>
					</NavItem>

				</Nav>


				<TabContent activeTab={this.state.activeTab}>


					<TabPane
						tabId={"anagraphics-tab"}
						key={0}
						className={"custom-tab-pane"}
					>

						{/*<AvForm>*/}

							{/*     PIVA  -------------------------------------------------------------------------------- */}
							<FormGroup>
								<Label for="piva">Partita IVA *</Label>
								<AvInput
									style={ (!this.state.piva)?({borderColor:"red"}): (null) }
									type="text"
									name="piva"
									id="piva"
									value={ this.state.piva }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											piva: changeTextEvent.target.value,
											errorMessage: null
										})
									}}
									//required
								/>
								{
									( !!this.state.errors && !!this.state.errors.piva )
										? <label className="error-message-label">{ this.state.errors.piva }</label>
										: null
								}
							</FormGroup>

							{/*     NOME  -------------------------------------------------------------------------------- */}
							<FormGroup>
								<Label for="name">Ragione Sociale *</Label>
								<AvInput
									style={ (!this.state.name)?({borderColor:"red"}): (null) }
									type="text"
									name="name"
									id="name"
									value={ this.state.name }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											name: changeTextEvent.target.value,
											errorMessage: null
										})
									}}
									//required
								/>
								{
									( !!this.state.errors && !!this.state.errors.name )
										? <label className="error-message-label">{ this.state.errors.name }</label>
										: null
								}
							</FormGroup>


							{/*     EMAIL  -------------------------------------------------------------------------------- */}
							<FormGroup>
								<Label for="email">Email</Label>
								<AvInput
									type="text"
									name="email"
									id="email"
									value={ this.state.email }
									onChange={ changeTextEvent => {

										showlog("testEmail: "+ changeTextEvent.target.value, EMAIL_REGEX );
										showlog( EMAIL_REGEX.test( changeTextEvent.target.value ) );

										this.setState({
											...this.state,
											email: changeTextEvent.target.value,
											errors: {
												...this.state.errors,
												email: !EMAIL_REGEX.test( changeTextEvent.target.value ) ? "formato email non valido" : null
											},
											errorMessage: null
										})
									}}
									// required
								/>
								{
									(!!this.state.errors && !!this.state.errors.email)
										? <label className="error-message-label">{ this.state.errors.email }</label>
										: null
								}
							</FormGroup>


							{/*     DIM  -------------------------------------------------------------------------------- */}
							<FormGroup>
								<Label for="dim">Dimensione * { (!isNotEmptyArray(this.state.dimList)) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
								<Select
									className={"react-select " + ((!this.state.dim)?("border-red-input"):"") }
									classNamePrefix="react-select"
									name="dim"
									id="dim"
									onChange={ changeEvent => {
										this.setState({
											...this.state,
											dim: this.state.dimList.filter( d => d.value === changeEvent.value ).reduce( getLastElementOr, { value: this.state.dimList[0].value } ).value,
											errorMessage: null
										})
									}}
									options={ this.state.dimList }
									value={
										!!this.state.dim
											? (
												this.state.dimList
													.filter( d => d.value === this.state.dim )
													.reduce( getLastElementOr, ( (!!this.props.company && !!this.props.company.dimensionId) ? this.props.company.dimensionId : this.state.dimList[0] ))
											)
											: null
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona Dimensione</span> }
									required
								/>
							</FormGroup>

							{/* SETTORE */}

							<FormGroup>
								<Label for="sector">Settore *  { (!isNotEmptyArray(this.state.sectorList)) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
								<Select
									className={"react-select "  + ((!this.state.sector)?("border-red-input"):"") }
									classNamePrefix="react-select"
									name="sector"
									id="sector"

									onChange={ changeEvent => {
										this.setState({
											...this.state,
											sector: changeEvent.value,
											errorMessage: null
										})
									}}
									options={ this.state.sectorList.map( sector => ({ value: sector.id, label: sector.name }) ) }
									value={
										!!this.state.sector
											? (
												this.state.sectorList
													.map( sector => ({ value: sector.id, label: sector.name }) )
													.filter( sector => sector.value === this.state.sector )
													.reduce( getLastElementOr, null)
											)
											: null
									}
									placeholder={<span className={"select-placeholder"}>Seleziona Settore</span>}
									required

								/>
							</FormGroup>

							{/*     FASCIA  -------------------------------------------------------------------------------- */}
							<FormGroup>
								<Label for="employeesNumbersLevel">Numero dipendenti *</Label>
								<Select
									className={"react-select "  + ((!this.state.employeesNumbersLevel)?("border-red-input"):"") }
									classNamePrefix="react-select"
									name="employeesNumbersLevel"
									id="employeesNumbersLevel"
									onChange={ changeEvent => {
										this.setState({
											...this.state,
											employeesNumbersLevel: changeEvent.value,
											errorMessage: null
										})
									}}
									options={
										COMPANY_EMPLOYEE
											.map( range => ({ value: range.id, label: range.name }) )
									}
									value={
										(!!this.state.employeesNumbersLevel)
											? (
												COMPANY_EMPLOYEE
													.map( range => ({ value: range.id, label: range.name }) )
													.filter( range => range.value === this.state.employeesNumbersLevel )
													.reduce( getLastElementOr, null )
													// .value
											)
											: null
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona Fascia</span> }
									required
								/>
							</FormGroup>


							{/*     SEDE LEGALE  -------------------------------------------------------------------------------- */}
{/*
							<FormGroup>
								<Label for="name">Sede Legale</Label>
								<AvInput
									type="text"
									name="sedeLegale"
									id="sedeLegale"
									value={ this.state.sedeLegale }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											sedeLegale: changeTextEvent.target.value,
											errorMessage: null
										})
									}}
									required
								/>
								{
									( !!this.state.errors && !!this.state.errors.sedeLegale )
										? <label className="error-message-label">{ this.state.errors.sedeLegale }</label>
										: null
								}
							</FormGroup>  */}


							{/*     CODICE FISCALE  -------------------------------------------------------------------------------- */}

							 <FormGroup>
								<Label for="codiceFiscale">Codice Fiscale *</Label>
								<AvInput
									style={ (!this.state.codiceFiscale)?({borderColor:"red"}): (null) }
									type="text"
									name="codiceFiscale"
									id="codiceFiscale"
									value={ this.state.codiceFiscale }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											codiceFiscale: changeTextEvent.target.value,
											errorMessage: null
										})
									}}
									//required
								/>
								{
									( !!this.state.errors && !!this.state.errors.codiceFiscale )
										? <label className="error-message-label">{ this.state.errors.codiceFiscale }</label>
										: null
								}
							</FormGroup>

							{/*     CODICE UNIVOCO/SDI  -------------------------------------------------------------------------------- */}

							 <FormGroup>
								<Label for="codiceUnivoco">Codice Univoco/SDI *</Label>
								<AvInput
									style={ (!this.state.codiceUnivoco)?({borderColor:"red"}): (null) }
									type="text"
									name="codiceUnivoco"
									id="codiceUnivoco"
									value={ this.state.codiceUnivoco }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											codiceUnivoco: changeTextEvent.target.value,
											errorMessage: null
										})
									}}
									//required
								/>
								{
									( !!this.state.errors && !!this.state.errors.codiceUnivoco )
										? <label className="error-message-label">{ this.state.errors.codiceUnivoco }</label>
										: null
								}
							</FormGroup>

							{/*     PEC (CAMPO FACOLTATIVO)  -------------------------------------------------------------------------------- */}

							<FormGroup>
								<Label for="pec">PEC</Label>
								<AvInput
									type="text"
									name="pec"
									id="pec"
									value={ this.state.pec }
									onChange={ changeTextEvent => {

										showlog("testEmail: "+ changeTextEvent.target.value, EMAIL_REGEX );
										showlog( EMAIL_REGEX.test( changeTextEvent.target.value ) );

										this.setState({
											...this.state,
											pec: changeTextEvent.target.value,
											errors: {
												...this.state.errors,
												pec: !EMAIL_REGEX.test( changeTextEvent.target.value ) ? "formato email non valido" : null
											},
											errorMessage: null
										})
									}}
									// required
								/>
								{
									(!!this.state.errors && !!this.state.errors.pec)
										? <label className="error-message-label">{ this.state.errors.pec }</label>
										: null
								}
							</FormGroup>


							{/*     DATI LEGALE RAPPRESENTANTE (FACOLTATIVO)  -------------------------------------------------------------------------------- */}


							<FormGroup>
								<Label for="datiLegale">Dati Legale Rappresentante</Label>
								<AvInput
									type="text"
									name="datiLegale"
									id="datiLegale"
									value={ this.state.datiLegale }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											datiLegale: changeTextEvent.target.value,
											errorMessage: null
										})
									}}
									//required
								/>
								{/* {
									( !!this.state.errors && !!this.state.errors.codiceUnivoco )
										? <label className="error-message-label">{ this.state.errors.codiceUnivoco }</label>
										: null
								} */}
							</FormGroup>

						{/*</AvForm>*/}

						{/*<GotoTopComponent/>*/}

					</TabPane>


					<TabPane
						tabId={"address-tab"}
						key={1}
						className={"custom-tab-pane"}
					>
						{/*     INDIRIZZO  -------------------------------------------------------------------------------- */}
						<AvGroup>
							<Label for="address">Indirizzo *</Label>
							<AvInput
								type="text"
								name="address"
								id="address"
								style={ (!this.state.address)?({borderColor:"red"}): (null) }
								value={ this.state.address }
								onChange={ changeTextEvent => {
									this.setState({
										...this.state,
										address: changeTextEvent.target.value,
										errorMessage: null
									})
								}}
								//required
							/>
							{
								( !!this.state.errors && !!this.state.errors.address )
									? <label className="error-message-label">{ this.state.errors.address }</label>
									: null
							}
						</AvGroup>

						{/*     LOCALITA  -------------------------------------------------------------------------------- */}
						<AvGroup>
							<Label for="city">Comune *</Label>
							<AvInput
								type="text"
								name="city"
								id="city"
								style={ (!this.state.city)?({borderColor:"red"}): (null) }
								value={ this.state.city }
								onChange={ changeTextEvent => {
									this.setState({
										...this.state,
										city: changeTextEvent.target.value,
										errorMessage: null
									})
								}}
								//required
							/>
							{
								( !!this.state.errors && !!this.state.errors.city )
									? <label className="error-message-label">{ this.state.errors.city }</label>
									: null
							}
						</AvGroup>


						{/*     CAP  -------------------------------------------------------------------------------- */}
						<AvGroup>
							<Label for="cap">CAP *</Label>
							<AvInput
								type="text"
								name="cap"
								id="cap"
								style={ (!this.state.cap)?({borderColor:"red"}): (null) }
								value={ this.state.cap }
								onChange={ changeTextEvent => {
									this.setState({
										...this.state,
										cap: changeTextEvent.target.value,
										errorMessage: null
									})
								}}
								//required
							/>
							{
								( !!this.state.errors && !!this.state.errors.cap )
									? <label className="error-message-label">{ this.state.errors.cap }</label>
									: null
							}
						</AvGroup>


						{/*     PROVINCE  -------------------------------------------------------------------------------- */}
						<AvGroup>
							<Label for="provinces" style={{color:"#6c757d"}}>Provincia * { (!this.state.provinces) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
							<Select
								className={ "react-select " + ((!this.state.province)?("border-red-input"):"") }
								classNamePrefix="react-select"
								name="provinces"
								id="provinces"
								onChange={ changeEvent => {
									this.setState({
										...this.state,
										province: changeEvent,
										errorMessage: null
									})
								}}
								options={ this.state.provinces }
								value={ this.state.province }
								placeholder={ <span className={"select-placeholder"}>Seleziona Provincia</span> }
								required
							/>
						</AvGroup>

						{/*     TEL1  -------------------------------------------------------------------------------- */}
						<AvGroup>
							<Label for="phone1">Telefono</Label>
							<AvInput
								type="text"
								name="phone1"
								id="phone1"
								value={ this.state.phone1 }
								onChange={ changeTextEvent => {
									this.setState({
										...this.state,
										phone1: changeTextEvent.target.value,
										errors: {
											...this.state.errors,
											phone1: !PHONE_REGEX.test( this.state.phone1 ) ? "formato numero non valido" : null
										}
									})
								}}
								//required
							/>
							{
								// ( (!!this.state.errors && !!this.state.errors.phone1) || ( !!this.state.phone1 && !PHONE_REGEX.test( this.state.phone1 ) ) )
								(!!this.state.errors && !!this.state.errors.phone1)
									? <label className="error-message-label">{ this.state.errors.phone1 }</label>
									: null
							}
						</AvGroup>

						{/*     TEL2  -------------------------------------------------------------------------------- */}
								{ /*<AvGroup>
										<Label for="name">Telefono</Label>
										<AvInput
											type="text"
											name="phone2"
											id="phone2"
											value={ this.state.phone2 }
											onChange={ changeTextEvent => {
												this.setState({
													...this.state,
													phone2: changeTextEvent.target.value,
													errors: {
														...this.state.errors,
														phone2: !PHONE_REGEX.test( this.state.phone2 ) ? "formato numero non valido" : null
													}
												})
											}}
											required
										/>
										{
											// ( (!!this.state.errors && !!this.state.errors.phone2) || ( !!this.state.phone2 && !PHONE_REGEX.test( this.state.phone2 ) ) )
											(!!this.state.errors && !!this.state.errors.phone2)
												? <label className="error-message-label">{ this.state.errors.phone2 }</label>
												: null
										}
									</AvGroup> /* }

						{/*<GotoTopComponent/>*/}
					</TabPane>


				</TabContent>











			</AvForm>
		)





		let content = (
			<Modal isOpen={ true } >
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{
						(!!this.props.name)
							? "Modifica Azienda"
							: "Nuova Azienda"
					}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>


					{
						!!this.state.errorMessage
							? (
								<span className={"modal-error-message"}>{ this.state.errorMessage }</span>
							)
							: null
					}



								<ActionButton
									promise={ () => {

										let missingFields =
											Object.keys( this.state )
												.filter( field => !this.state[ field ] )
												.map( field => {
													if(
														[
															"name",
															 "piva",
															 "dim",

															// "email",
															 "employeesNumbersLevel",
															  "sector",
															"address",
															"city",
															"cap",
															"province",
															 "codiceFiscale",
															 "codiceUnivoco"


														].includes(field)
													) {
														return field;
													}
													return null;
												})
												.filter( onlyNotNull )
												.map( field => {
													let mappingName = {
														"name": "Ragione Sociale",
														 "piva": "P.IVA",
														"dim": "Dimensione",
														// "email": "Email",
														"sector": "Settore",
														 "employeesNumbersLevel": "Numero dipendenti",
														 "address": "Indirizzo",
														 "city": "Comune",
														 "cap": "CAP",
														 "province": "Provincia",
														 "codiceFiscale": "Codice Fiscale",
														 "codiceUnivoco": "Codice Univoco/SDI",

													}
													return mappingName[ field ];
												})
										;

										if( missingFields.length > 0 ) {
											return Promise.reject(`I seguenti campi sono obbligatori: ${ missingFields.join(", ") }`);
										}
										else {
											return (
												new Promise((success, fail) => {
													this.setState({
															...this.state,
															inWait: true
														},
														() => {
															success(true);
														}
													)

												})
													.then( () => {
														if( !!this.props.company ) {

															let state = this.state;
															if( !state.dim ) {
																state.dim = {
																	value: this.props.company.dimensionId
																}
															}

															return editUpdateCompany( state, this.props.company.id )
														}
														else {
															return saveCompany( this.state, this.props.organizzation );
														}
													})
											)
										}


									}}
									onResult={ result => {
										if( typeof this.props.onClose === "function" ) this.props.onClose( true );
									}}
									onError={ e => {
										errorlog("Salvataggio Nuova Azienda", e);

										let errorMessage = extractErrorMessage( e );
										this.setState({
											...this.state,
											inWait: false,
											errorMessage: errorMessage
										})

										// if( typeof this.props.onClose === "function" ) this.props.onClose( true, e );
									}}
								/>



					<Button color="secondary" onClick={this.toggle}>
						{ translate("Close") }
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;

	}


	componentDidMount() {

		loadAllComapnyAllowedDimensions()
			.then( list => {

				return (
					list
						.map( dim => ({ label: dim.name, value: dim.id }) )
				);

			})

			.then( dimList => {
				loadAllProvinces()
					.then( provinces => {
						return ({
							dimList: dimList,
							provinces:
								provinces
									.map(p => ({
										value: p.code,
										label: p.description
									}))
						});
					})
					.then( ({dimList, provinces}) => {

						return (
							new Promise( (onEnd, onFail) => {

								this.setState({
									...this.state,
									dimList: dimList,
									provinces: provinces,
									province:
										provinces
											.filter( p => (
												!!this.props.company
												&& (p.value === this.props.company.provincia)
											))
											.reduce( getLastElementOr, null ),
									dim:
										(!!this.props.company && !!this.props.company.dimension)
											? this.props.company.dimension.id
											: null,
									phone1:
										(!!this.props.company && !!this.props.company.numTel)
											? this.props.company.numTel
											: null,
									phone2:
										(!!this.props.company && !!this.props.company.numTel2)
											? this.props.company.numTel2
											: null
								},
								() => {
											onEnd();
								})

							})
						);


					})
			})

		loadAllSectors()
			.then( result => {
				showlog("settori", result)
				this.setState({
					...this.state,
					sectorList: result
				})
			})

	}



};
