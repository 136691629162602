import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import "./index.css";
import BG1 from "./imgs/img1.jpg";
import BG2 from "./imgs/img2.jpg";
import BG3 from "./imgs/img3.jpg";
import BG4 from "./imgs/img4.jpg";
import BG5 from "./imgs/img5.jpg";
import {COLOR_POLICY, LANGUAGES, SIGNUP_STEP} from "../../../../env";
// import GG from "../../../../assets/images/gg_dark_text.png";
import "./large-screen.css";
import "./small-screen.css";
import "./tiny-screen.css";
import {getLastElementOr, renderlog, showlog} from "../../../../utils";
import Footer from "../../../../components/Footer";
import {SignupProfilePanelContent, SignupProfilePanelContentAddress, SignupProfilePanelContentCompany} from "./signup";
import {SignLanding} from "./signin";
import {isUserAuthenticated} from "../../../../helpers/authUtils";
import {translate} from "../../../../components/i18n/translate-function";


const carouselElements = [
    { id: 1, image: BG1 },
    { id: 2, image: BG2 },
    { id: 3, image: BG3 },
    { id: 4, image: BG4 },
    { id: 5, image: BG5 }
];




/**
 * MasterPage
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SignLandingPage = props => {

    const [scrollerStep, setScrollerStep]           = useState(0);
    const [isMessagePanelOpen, toggleMessagePanel]  = useState( false );
    const [dataPack, setDataPack]                   = useState({});
    const [language, setLanguage]                   = useState(
        LANGUAGES
            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )
            .reduce( getLastElementOr, LANGUAGES[0] )
    );
    const [step, setStep]                           = useState(
        (window.location.href.indexOf("/signup") !== -1)
            ? SIGNUP_STEP.PROFILE  // 1
            : SIGNUP_STEP.LANDING  // -2
    );



    renderlog("Landing", {scrollerStep, isMessagePanelOpen, dataPack, step}, props, COLOR_POLICY.warning);

    let panelContent = (
        <div>ERROR</div>
    );

    switch ( step ) {
        case SIGNUP_STEP.LANDING:  // -2
        case SIGNUP_STEP.LOGIN:    // -1
            panelContent = (
                <SignLanding
                    step={ step }
                    onNext={ () => {
                        showlog("Landing Desktop onNext");
                        setStep( SIGNUP_STEP.LOGIN )
                    }}

                    onForgotPassword={ () => {
                        setStep( SIGNUP_STEP.RESET_PASSWORD );
                    }}
                />
            );
            break;
        /*
        case SIGNUP_STEP.LOGIN:  // 0
            panelContent = (
                <SignLanding
                    step={ step }
                    onNext={ () => {
                        setStep( SIGNUP_STEP.PROFILE )
                    }}
                />
            );
            break;
        */
        case SIGNUP_STEP.PROFILE:  // 1
            panelContent = (
                <SignupProfilePanelContent
                    step={ step }
                    dataPack={ dataPack }
                    gotoSignIn={ () => {
                        setStep( SIGNUP_STEP.LOGIN )
                    }}
                    onForgotPassword={ () => {
                        setStep( SIGNUP_STEP.RESET_PASSWORD )
                    }}
                    onNext={ data => {
                        setDataPack({...dataPack, ...data});
                        setStep( SIGNUP_STEP.COMPANY )
                    }}
                    onPrevious={ data => {
                        setDataPack({...dataPack, ...data});
                        setStep( SIGNUP_STEP.LOGIN )
                    }}

                />
            );
            break;
        case SIGNUP_STEP.COMPANY:
            panelContent = (
                <SignupProfilePanelContentCompany
                    dataPack={ dataPack }
                    onNext={ data => {
                        setDataPack({...dataPack, ...data});
                        setStep( SIGNUP_STEP.COMPANY_ADDRESS )
                    }}
                    onPrevious={ data => {
                        setDataPack({...dataPack, ...data});
                        setStep( SIGNUP_STEP.PROFILE )
                    }}

                />
            );
            break;
        case SIGNUP_STEP.COMPANY_ADDRESS:
            panelContent = (
                <SignupProfilePanelContentAddress
                    dataPack={ dataPack }
                    onNext={ data => {
                        // setDataPack({...dataPack, ...data});

                        showlog("controllo autenticazione");
                        showlog( !!isUserAuthenticated() );


                        if ( !!isUserAuthenticated() ) {

                            window.location.href =
                                window.location.href
                                    .split("/")
                                    .filter((_, i) => i < 3)
                                    .join("/")
                            ;
                        }
                        else {
                            setStep( SIGNUP_STEP.LANDING )
                        }

                    }}
                    onPrevious={ () => {
                        setStep( SIGNUP_STEP.COMPANY )
                    }}
                />
            );
            break;
        case SIGNUP_STEP.COMPANY_DONE:
            panelContent = (
                <div>COMPANY DONE</div>
            );
            break;
            /*
        case SIGNUP_STEP.RESET_PASSWORD:
            panelContent = (
                <ResetPasswordPanelContent
                    previous={ () => {
                        setStep( SIGNUP_STEP.LOGIN );
                    }}
                    onSignup={ () => {
                        setStep( SIGNUP_STEP.PROFILE );
                    }}
                    next={ () => {
                        setStep( SIGNUP_STEP.RESET_PASSWORD_DONE );
                    }}
                />
            );
            break;
            */
        case SIGNUP_STEP.RESET_PASSWORD_DONE:
            panelContent = (
                <div>RESET PASSWORD DONE</div>
            );
            break;
        case SIGNUP_STEP.SUBSCRIPTION:
            panelContent = (
                <div>SUBSCRIPTION</div>
            );
            break;
        case SIGNUP_STEP.SUBSCRIPTION_DONE:
            panelContent = (
                <div>SUBSCRIPTION DONE</div>
            );
            break;
        default:
            panelContent = (
                <div>DEFAULT</div>
            );
            break;
    }



    /*/  SIMPLE CAROUSEL    //////////////////////////////////////////////////////////////////////////////////////////////////
    setTimeout(
        () => {
            setScrollerStep( (scrollerStep +1) % carouselElements.length );
        },
        4000
    );

    let scroller = (
        <div className={"carousel"}>
            <div className={"scroller scroller-step-"+ scrollerStep}>
                {
                    carouselElements
                        .map( cEl => (
                            <img className={"carousel-element"} src={ cEl.image }/>
                        ))
                }
            </div>
        </div>
    );
    ///////////////////////////////////////////////////////////////////////////////////////////////////*/


    //  FADE    //////////////////////////////////////////////////////////////////////////////////////////////////
    setTimeout(
        () => {
            setScrollerStep( (scrollerStep +1) % carouselElements.length );
        },
        4000
    );

    let scroller = (
        <div className={"carousel"}>
            <div className={"curtain"}/>
            {
                carouselElements
                    .map( (cEl, index) => (
                        <img
                            className={`carousel-element fade-${ (index < scrollerStep) ? "off" : "on" }`}
                            src={ cEl.image }
                            style={{ zIndex: carouselElements.length - (index +1) }}
                        />
                    ))
                }
        </div>
    );
    ////////////////////////////////////////////////////////////////////////////////////////////////////



    let mainPage = (
        <div className={"main-page"}>

            <div className={"mobile-safe-area"}/>

            <div className={"content-container"}>


                {/*
                <div className={"landing-page-comander"}>
                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                    />

                </div>


                <div>
                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                    />
                    <ActionButton
                        promise={ () => Promise.resolve() }
                        onResult={ result => {}}
                        onError={ error => {}}
                    />
                </div>
                */}

                { panelContent }
                <Footer
                    isLogged={ !!isUserAuthenticated() }
                />
                <AlternativeFooter/>

            </div>

            <div className={"mobile-safe-area"}/>
            <div className={"mobile-safe-area"}/>
            <div className={"mobile-safe-area"}/>

            { scroller }
        </div>
    );

    return mainPage;


}


const AlternativeFooter = () => (
    <div className={"alternative-footer"}>
        {/*
        <div>
            <span>Copyright © ARB S.B.P.A. 2022</span>
        </div>
        */}
        <div>
            {/*<span>Copyright © ARB S.B.P.A. 2023 </span>*/}
            {/*<span>Copyright © ARB S.B. 2024 </span>*/}
            <span>Copyright © {translate("business name")} </span>
        </div>
        <div>
            <span><a href="https://arbspa.it/index.php/contatti/" target="_blank"> {translate("footer_contact")}</a></span> |
            <span><a href="/support" target={"_blank"}> {translate("footer_support")} </a></span> |
            <span> <a href="/privacy-policy" target={"_blank"}> Privacy </a></span>
        </div>
        <div>
            <span> <a href="/cookie-policy" target={"_blank"}> Cookie Policy </a></span> |
            <span><a href="https://arbspa.it/" target={"_blank"}> {translate("footer_aboutcompany")} </a> </span>
        </div>
    </div>
);


export default withRouter( SignLandingPage );
