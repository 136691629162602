import React, {Component, Suspense} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import {
    loadAnswers,
    loadProgress,
    processAnswers_syncChain,
    saveAnswer,
    setNewRatingName
} from "../../../../business-logic/rating";
import {
    beautify,
    beautifyLog,
    cloneObject,
    emoj,
    errorlog,
    extractErrorMessage,
    getLastElementOr,
    isNotEmptyArray,
    onlyNotNull,
    renderlog,
    showlog,
    sortById,
    warninglog
} from "../../../../utils";
import {exportSeedTableToCSV} from "../../../../business-logic/tree-manager";
import {
    cloneOpenNodes,
    convertSIRatingQuestionTableToTree,
    convertSIRatingSubQuestionTableToSubQuestionChild,
    extractAllQuestions,
    getQuestionFatherTreeNodeByPath,
    getQuestionPath,
    getQuestionTreeNodeByPath,
    questionTreeLog
} from "../../../../business-logic/question-tree-manager";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    PopoverBody,
    PopoverHeader,
    Progress,
    Row,
    TabContent,
    TabPane,
    UncontrolledPopover,
    UncontrolledTooltip
} from "reactstrap";
import {
    COLOR_POLICY,
    HELPER,
    HOST,
    MAGNITUDE_DARK,
    MATERIALITY_REASON_LEVEL,
    PATH_PREFIX,
    permissions,
    QUESTION_STATUS,
    QUESTION_TEXT_COLOR_POLICY,
    RATING_STATUS,
    RATING_TYPE,
    role,
    SIRATING_TREE_DEEP_LEVELS_NAME,
    TABS
} from "../../../../env";
import {EMOJ} from "../../../../emoj";
import classnames from "classnames";
import StandBySystemModalComponent from "../../../../components/area/gri-admin/standby-system-modal";
import ErrorMessageModalComponent from "../../../../components/error-message-modal-component";
import Spinner from "../../../../components/Spinner";
import PageTitle from "../../../../components/PageTitle";
import ActionButton from "../../../../components/save-button";
import {loadEvaluations} from "../../../../business-logic/active-service";
// import SiRatingQuestionAttachmentModal from "./sirating-question-attachment-modal";
import {getAllPermissions, hasPermission, isUserRole} from "../../../../business-logic/auth";
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import {CloseRatingConfirmModal} from "../../../../components/area/super-admin/close-rating-confirm-modal";
import "../../../../style/audit.css";
import "../../../../style/rating-page.css";
import SiRatingQuestionHistoryModal from "../../../../components/area/sirating/question-history-modal";
import {TreeToggler} from "../../../../components/tree-toggler";
import StateModal from "../../../../components/area/super-admin/state-modal"
import EvaluationRolesArbModal from "../../../../components/area/super-admin/evaluationRoles-list-modal";
import MaterailityReport from "../../../../components/area/super-admin/materailityReport-modal";
import {getQuestionStatus} from "../../../../utils/question-utils";
import {RatingLegendModal} from "../../../../components/rating-legend-modal";
import {ArchiveRatingConfirmModal} from "../../../../components/archive-rating-confirm-modal";
import {passToEvaluationStatus} from "../../../../services/rating";
import {saveSiScoringOpenAnswer} from "../../../../business-logic/siscoring";
import GotoTopComponent from "../../../../components/goto-top-component";
import {translate} from '../../../../components/i18n/translate-function';
import ScoreModal from "../../../../components/area/super-admin/score-modal";
import SdgScoreModal from "../../../../components/area/super-admin/sdg-score-modal";


let isRunned = false

const AVOID_FILTER_STATUS = [
    "incomplete"
];


const QUESTION_STATUS_ALL = {
    label: "Tutte le domande",
    value: -1,
    isPassCondition: () => true
};


const ALL_POSSIBLE_QUESTION_STATUS = [
        QUESTION_STATUS_ALL,
        {
            label: "Domande in corso",
            value: -2,
            isPassCondition: () => true
        },
        ...Object.keys(QUESTION_STATUS)
            .filter( status => !AVOID_FILTER_STATUS.includes(status) )
            .map(status => ({
                label: QUESTION_STATUS[status].name,
                value: QUESTION_STATUS[status].id,
                isPassCondition: questionStatus => (questionStatus === QUESTION_STATUS[status].id)
            }))
    ]
;

class SiScoringEvaluationPage extends Component {

    state = {
        clientQuestions: [],
        editAnswer: [],
        nonCompliantReason: [],
        // nonCompliantDefaultExp: moment().add( 7, "days" ),
        questionInWait: [],
        waitingQuestions: []
    };


    constructor(props) {
        super(props);

        this.questionChildren = [];

        this.loadData = this.loadData.bind(this);
        this.inWaiting = this.inWaiting.bind(this);
        this.buildQuestions = this.buildQuestions.bind(this);
        this.buildSubThemes = this.buildSubThemes.bind(this);

        this.filterQuestion = this.filterQuestion.bind(this);

        this.subQuestionRequest = this.subQuestionRequest.bind(this);
        this.getProgress = this.getProgress.bind(this);

        this.getRatingId = this.getRatingId.bind(this);		// non dovrebbe essere necessario
        this.helperNode = this.helperNode.bind(this);		// non dovrebbe essere necessario
        this.refreshPage = this.refreshPage.bind(this);		// non dovrebbe essere necessario

        this.buildOpenAnswers = this.buildOpenAnswers.bind( this );


    }




    refreshPage = () => {

    }



    inWaiting() {
        return (
            <div>Wait</div>
        );
    }



    getRatingId = () => (
        (!!this.props.location && !!this.props.location.state && !!this.props.location.state.id)
            ? this.props.location.state.id
            : (
                this.props.match.params.id
                || this.props.id
                || this.state.metaData.id
            )
    );



    helperNode = node => (

        (!!HELPER && (window.location.href.indexOf("help") !== -1))
            ? (
                <span
                    className={"helper-icon"}
                    onClick={ clickEvent => {
                        clickEvent.stopPropagation();
                        this.setState({
                            ...this.state,
                            helper: node
                        })
                    }}
                >
					<span>{ emoj( EMOJ.ambulance ) }</span>
					<code>{ node.id }</code>
				</span>
            )
            : null

    )




    isRatingClosed = () => (this.state.metaData.status === RATING_STATUS.auditDone.id);


    buildOpenAnswers = question => (
        <div
            className={"open-answer-area"}
            key={"openAnswer-"+ question.id}
        >
            {/*
                <code>Question</code>
                <pre>{ beautify( question ) }</pre>
            */}

            <div className={"open-answer-content"}>

                <div
                    id={`openAnswer-${question.id}`}
                    className={"single-open-answer-content"}
                >
                    <Input
                        id={`openAnswerValue-${question.id}`}
                        name={`openAnswerValue-${question.id}`}
                        className={"single-open-answer-value"}
                        type="number"
                        defaultValue={ question.scoreOpenQuestion }
                        required
                        disabled={!!this.isRatingClosed()}
                        onFocus={focusEvent => {
                            focusEvent.persist();
                            showlog("focus on " + question.id );
                            showlog(focusEvent);
                        }}

                        /*  eliminato perchè lento anche se efficente!
                        onBlur={blurEvent => {
                        }}
                        onChange={changeEvent => {


                            // updating
                            let treeTmp = cloneObject(this.state.tree);
                            let questionPath = getQuestionPath(treeTmp, question.id);
                            let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                            nodeTmp.answers[0] = changeEvent.target.value;

                            this.setState({
                                ...this.state,
                                tree: treeTmp
                            });

                        }}
                        */


                    />
                </div>

            </div>
            <div className={"open-answer-audit"}>

            </div>

        </div>
    )


    render() {

        renderlog("SiScoringEvaluationPage", this.state, this.props);

        if( !!this.state.navigateToPdf ) {
             showlog("navigazione a /si-scoring/report/pdf/"+ this.state.navigateToPdf );
            // let obj = this.state.navigateToPdf;
            // delete obj[translate("Score")];
            return (
                <Redirect to={
                    { pathname: '/si-scoring/report/pdf/'+ this.state.navigateToPdf, state: { rating: this.state.navigateToPdf } }}
                />
            );
        }



        if (!!this.state.loadDataError) {
            return (
                <div className={"centered-block almost-full-height"}>
                    {/*
                    <span>C'è qualche problema {emoj(EMOJ.sad_but_relieved_face)}</span>
                    <div><em>{ this.state.loadDataError.error.msg }</em></div>
                    */}
                    <span>{ this.state.loadDataError.error.msg }</span>
                </div>
            )
        } else {
            if (!this.state.tree) {
                return (
                    <WaitingOrNodataMessage
                        waiting={!this.state.tree}
                        fullHeight={true}
                        nodataMessage={"Nessuna Valutazione"}
                        waitMessage={ translate("Loading_Questions") }
                    />
                );
            }
        }


        let confirmingText = <div className="d-flex justify-content-center" style={{
            fontSize: "3rem",
            margin: "25vh"
        }}>{"    " + emoj(EMOJ.thumbs_up) + "    "}</div>;


        let questionList = extractAllQuestions(this.state.tree);
        // showlog("tutte le domande");
        // showlog(questionList);


        // showlog("STOP #02");
        // debugger;

        let progress = 0;
        // let tabInfo = { questionCompletedNumber: 0, questionTotalNumber: 0 };
        //
        // if (!!this.state && !!this.state.metaData && !!this.state.metaData.info) {
        //     tabInfo =
        //         this.state.metaData.info
        //             .reduce((final, tabData) => {
        //                 final.questionCompletedNumber += tabData.questionCompletedNumber;
        //                 final.questionTotalNumber += tabData.questionTotalNumber;
        //                 return final;
        //             }, { questionCompletedNumber: 0, questionTotalNumber: 0 })
        //         ;
        // }

        progress = this.state.tabInfo.questionCompletedNumber / this.state.tabInfo.questionTotalNumber;
        progress *= 100;
        progress = Math.floor(progress);
        progress = parseInt(progress, 10);


        let progressColor =
            MAGNITUDE_DARK
                // .filter( (m, i) => (i % 2 === 1) )	// 4 step invece che 8
                .filter((m, i) => {
                    // let step = Math.floor( progress / (100 / MAGNITUDE.filter( (m, i) => (i % 2 === 1) ).length) ) - 1 + ( (progress % ( 100 / MAGNITUDE.filter( (m, i) => (i % 2 === 1) ).length )) > 0 ? 1 : 0 );
                    let step = Math.floor(progress / (100 / MAGNITUDE_DARK.length)) - 1 + ((progress % (100 / MAGNITUDE_DARK.length)) > 0 ? 1 : 0);
                    return i === step;
                })
                .reduce((final, current) => current.color, null)
            ;



        let totalProgressData =
            this.state.metaData.info
                .map( tabData => ({
                    answered: tabData.questionAnswered.count,
                    total: tabData.questionTotal.count
                }))
                .reduce((final, tab) => ({
                        answered: tab.answered + final.answered,
                        total: tab.total + final.total
                    }),
                    {
                        answered: 0,
                        total: 0
                    }
                )
        ;
        let totalProgress = parseInt(totalProgressData.answered, 10) / parseInt(totalProgressData.total, 10);

        return (


            <Suspense fallback={this.inWaiting()}>
                {
                    (!!this.state.stateModal)
                        ? (
                            <StateModal
                                questionId={this.state.scoreModal}
                                evaluation_id={this.state.stateModal}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        stateModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.helper)
                        ? (
                            <div
                                className={"helper-pannel-backdrop"}
                                onClick={ clickEvent => {
                                    clickEvent.stopPropagation();
                                    this.setState({
                                        ...this.state,
                                        helper: null
                                    })
                                }}
                            >
                                <div
                                    className={"helper-pannel"}
                                >
                                    <code>
                                        Helper Domanda <strong>{ this.state.helper.id }</strong>
                                    </code>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;status:&nbsp;&nbsp;
                                        <span
                                            className={"classic-evidentiation"}
                                        >
                                            { this.state.helper.status }
                                        </span>
                                        &nbsp;&nbsp;
                                        {
                                            getQuestionStatus( this.state.helper.status , { name: "" } )
                                                .name
                                        }
                                    </span>



                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={"Siamo in Audit"}
                                            defaultChecked={ true }
                                            disabled={ true }
                                        />
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsComeFromAuditerIsOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsComeFromAuditerIsOpen: !this.state.helperIsComeFromAuditerIsOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsComeFromAuditerIsOpen } >
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"is from auditer into state"}
                                                defaultChecked={ false }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"is from auditer into props"}
                                                defaultChecked={ false }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"is from auditer into props ( navigation )"}
                                                defaultChecked={ false }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"stato valutazione === status completato ( "+ this.state.metaData.status +" | "+ RATING_STATUS.ratingComplete.id +" )"}
                                                defaultChecked={ this.state.metaData.status === RATING_STATUS.ratingComplete.id }
                                                disabled={ true }
                                            />
                                        </div>
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={ permissions.RATING_AUDIT.label }
                                                defaultChecked={ hasPermission(permissions.RATING_AUDIT) }
                                                disabled={ true }
                                            />
                                        </div>
                                    </Collapse>



                                    <Collapse isOpen={ !!this.state.helperIsQuestionInAuditAndAuditIsRunning_open } >
                                        <div className={"info-button"}>
                                            <CustomInput
                                                type="checkbox"
                                                name={"helper-check-audit"}
                                                id={"helper-check-audit"}
                                                label={"Siamo in Audit"}
                                                defaultChecked={ false }
                                                disabled={ true }
                                            />
                                        </div>
                                            <div className={"info-button"}>
                                                <CustomInput
                                                    type="checkbox"
                                                    name={"helper-check-audit"}
                                                    id={"helper-check-audit"}
                                                    label={"metadata status >= rating status complete ( "+ this.state.metaData.status +" | "+ RATING_STATUS.ratingComplete.id +" )"}
                                                    defaultChecked={ !!(parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.ratingComplete.id, 10)) }
                                                    disabled={ true }
                                                />
                                            </div>
                                            <div className={"info-button"}>
                                                <CustomInput
                                                    type="checkbox"
                                                    name={"helper-check-audit"}
                                                    id={"helper-check-audit"}
                                                    label={"audit confermato da OPERATOR"}
                                                    defaultChecked={ !!(parseInt(this.state.metaData.status, 10) >= parseInt(RATING_STATUS.auditComplete.id, 10)) }
                                                    disabled={ true }
                                                />
                                            </div>
                                            <div className={"info-button"}>
                                                <CustomInput
                                                    type="checkbox"
                                                    name={"helper-check-audit"}
                                                    id={"helper-check-audit"}
                                                    label={"audit validato da SUPER-ADMIN"}
                                                    defaultChecked={ !!(parseInt(this.state.metaData.status, 10) === parseInt(RATING_STATUS.auditDone.id, 10)) }
                                                    disabled={ true }
                                                />
                                            </div>
                                            <div className={"info-button"}>
                                                <CustomInput
                                                    type="checkbox"
                                                    name={"helper-check-audit"}
                                                    id={"helper-check-audit"}
                                                    label={ permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION.label }
                                                    defaultChecked={ (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION)) }
                                                    disabled={ true }
                                                />
                                            </div>
                                            <div className={"info-button"}>
                                                <CustomInput
                                                    type="checkbox"
                                                    name={"helper-check-audit"}
                                                    id={"helper-check-audit"}
                                                    label={ permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM.label }
                                                    defaultChecked={ (!!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) }
                                                    disabled={ true }
                                                />
                                            </div>
                                    </Collapse>



                                    <div>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={"Domanda abilitata"}
                                            defaultChecked={ !this.isRatingClosed() }
                                            disabled={ true }
                                            className={ !this.isRatingClosed() ? "color-green" : "color-red" }
                                        />
                                    </div>
                                    <div>
                                        <CustomInput
                                            type="checkbox"
                                            name={"helper-check-audit"}
                                            id={"helper-check-audit"}
                                            label={ permissions.EDIT_ANSWER_SCORE.label }
                                            defaultChecked={ !!hasPermission(permissions.EDIT_ANSWER_SCORE) }
                                            disabled={ true }
                                            className={ !!hasPermission(permissions.EDIT_ANSWER_SCORE) ? "" : "color-red" }
                                        />
                                    </div>


                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <Label>Nodo</Label>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsNodeOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsNodeOpen: !this.state.helperIsNodeOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsNodeOpen } >
                                        <pre>
                                            {
                                                beautify( this.state.helper )
                                            }
                                        </pre>
                                    </Collapse>



                                    <br/>
                                    <br/>
                                    <code>Ruolo</code> <span>{ localStorage.getItem("role") }</span>
                                    <br/>
                                    <br/>



                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <code>Permessi attuali</code>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsPermissionOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsPermissionOpen: !this.state.helperIsPermissionOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsPermissionOpen } >
                                        <pre>
                                            {
                                                getAllPermissions()
                                                    .sort( sortById )
                                                    .map( (perm, permIndex) => (
                                                        <div className={ "helper-row " + (((permIndex % 2) === 0 ) ? "even-row" : "odd-row") }>
                                                            <code>{ perm.id }</code>
                                                            <span>{ perm.label }</span>
                                                        </div>
                                                    ))
                                            }
                                        </pre>
                                    </Collapse>




                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <Label>Sate</Label>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsStateOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsStateOpen: !this.state.helperIsStateOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsStateOpen } >
                                        {

                                            Object.keys( this.state )
                                                .filter( key => !["clientQuestions", "editAnswer", "nonCompliantReason", "questionInWait", "seed", "tree", "wait", "helper", "helperIsStateOpen"].includes( key ) )
                                                .map( key => {
                                                    return (
                                                        <React.Fragment>
                                                            <code>{ key }</code>
                                                            <pre>
                                                                { beautify( this.state[ key ] ) }
                                                            </pre>
                                                        </React.Fragment>
                                                    );

                                                })

                                        }
                                    </Collapse>


                                    <div className={"helper-root-node add-repeating-for-zones-button-box"}>
                                        <code>Props</code>
                                        <i
                                            className={ "helper-opener " + (!!this.state.helperIsPropsOpen ? "dripicons-chevron-up" : "dripicons-chevron-down") }
                                            onClick={ clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    helperIsPropsOpen: !this.state.helperIsPropsOpen
                                                })
                                            }}
                                        />
                                    </div>
                                    <Collapse isOpen={ !!this.state.helperIsPropsOpen } >
                                        <pre>{ beautify( this.props ) }</pre>
                                    </Collapse>



                                </div>
                            </div>
                        )
                        : null
                }




                {
                    (!!this.state.materialityReportModal)
                        ? (
                            <MaterailityReport
                                evaluation_id={this.getRatingId()}
                                isGenderGap={ true }    // anche se siamo in siscoring serve il flag e quindi va bene
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        materialityReportModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }



                            <PageTitle
                                breadCrumbItems={[
                                    {
                                        label: "SI Scoring" /*
                                            Object.keys(role)
                                                .map(roleObjectName => role[roleObjectName])
                                                .filter(roleObject => roleObject.name === localStorage.getItem("role"))
                                                .reduce(getLastElementOr, "Nessun Ruolo")
                                                .label */
                                        , path: PATH_PREFIX + "/si-scoring"+ ( isUserRole( role.PROJECT_MANAGER ) ? "/pm" : "" ) +"/" + translate("evaluations")
                                    },
                                    { label: translate("Evaluations"), path: PATH_PREFIX + "/si-scoring"+ ( isUserRole( role.PROJECT_MANAGER ) ? "/pm" : "" ) +"/" + translate("evaluations") },
                                    {
                                        label: (!!this.state && !!this.state.metaData) ? this.state.metaData.name : this.props.match.params.id,
                                        active: true
                                    }
                                ]}
                                title={"SI Scoring"}
                            />


                {
                    (!!this.state.userRolesSa)
                        ? (
                            <EvaluationRolesArbModal
                                type={1}
                                evaluationId={this.state.userRolesSa}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        userRolesSa: null
                                    })
                                }}
                                onChoose={list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        userRolesSa: null
                                    })

                                }}
                            />
                        )
                        : null
                }
                {
                    (!!this.state.userRolesArb)
                        ? (
                            <EvaluationRolesArbModal
                                type={2}
                                evaluationId={this.state.userRolesArb}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        userRolesArb: null
                                    })
                                }}
                                onChoose={list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        userRolesArb: null
                                    })

                                }}
                            />
                        )
                        : null
                }


                {
                    !!this.state.ratingLegend
                        ? (
                            <RatingLegendModal
                                ratingId={ this.getRatingId() }
                                metadata={ this.state.metaData.info }
                                onClose={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        ratingLegend: null
                                    })
                                }}
                            />
                        )
                        : null
                }



                {
                    !!this.state.archiveRatingPrompt
                        ? (
                            <ArchiveRatingConfirmModal
                                id={ this.state.archiveRatingPrompt.id }
                                name={ this.state.archiveRatingPrompt.name }
                                onClose={
                                closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        archiveRatingPrompt: null
                                    })
                                }
                                }
                            />
                        )
                        : null
                }

                {
                    (!!this.state.scoreModale)
                        ? (
                            <ScoreModal
                                questionId={ this.state.scoreModale }
                                evaluation_id={ this.state.evaluation_id }
                                isSiScoring={ true }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        scoreModale: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.sgdScoreModal)
                        ? (
                            <SdgScoreModal
                                questionId={ this.state.scoreModal }
                                evaluation_id={ this.state.evaluation_id }
                                isSiScoring={ true }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        sgdScoreModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.scoreModale)
                        ? (
                            <ScoreModal
                                questionId={ this.state.scoreModale }
                                evaluation_id={ this.state.evaluation_id }
                                isSiScoring={ true }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        scoreModale: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.sgdScoreModal)
                        ? (
                            <SdgScoreModal
                                questionId={ this.state.scoreModal }
                                evaluation_id={ this.state.evaluation_id }
                                isSiScoring={ true }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        sgdScoreModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                <Card>
                    <CardHeader className={!!hasPermission(permissions.DISPLAY_RATING_LEGEND) ? "" : ""}>
                        <h4>
                            <span>
                                &nbsp;
                                {
                                    !!this.state.editRatingName
                                        ? (

                                                    <Input
                                                        type={"text"}
                                                        defaultValue={
                                                            (!!this.state && !!this.state.metaData)
                                                                ? this.state.metaData.name
                                                                : this.props.match.params.id
                                                        }
                                                        id={ "ratingName" }
                                                        className={"rating-name-input"}
                                                        onBlur={ blurEvent => {
                                                            let newName = blurEvent.target.value
                                                            this.setState({
                                                                    ...this.state,
                                                                    editRatingNameInWait: true,
                                                                    editRatingName: false,
                                                                    editRatingNameTmp: newName
                                                                },
                                                                () => {
                                                                    // chiamata
                                                                    setNewRatingName( this.getRatingId(), newName )
                                                                        .then( result => {


                                                                            this.setState({
                                                                                ...this.state,
                                                                                metaData: {
                                                                                    ...this.state.metaData,
                                                                                    name: newName
                                                                                },
                                                                                editRatingNameInWait: false
                                                                            });

                                                                        })
                                                                        .catch(e => {
                                                                            errorlog("Cambio nome al rating", e);


                                                                            this.setState({
                                                                                    ...this.state,
                                                                                    editRatingNameInWait: false,
                                                                                    editRatingNameError: e
                                                                                },
                                                                                () => {
                                                                                    setTimeout(function() {
                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            editRatingNameError: null
                                                                                        })
                                                                                    }.bind( this ), 5000)
                                                                                }
                                                                            );


                                                                        })
                                                                }
                                                            )
                                                        }}
                                                    />

                                        )
                                        : (
                                            !!this.state.editRatingNameInWait
                                                ? this.state.editRatingNameTmp
                                                : (
                                                    (!!this.state && !!this.state.metaData)
                                                        ? this.state.metaData.name
                                                        : this.props.match.params.id
                                                )
                                        )
                                }
                            </span>


                            {
                                (!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
                                    ? (
                                        !!this.state.editRatingNameInWait
                                            ? <Spinner color={"#18f"} className="assurance-wait"/>
                                            : (
                                                <i
                                                    className="mdi mdi-pencil tree_comander"
                                                    onClick={ clickEvent => {
                                                        this.setState({
                                                            ...this.state,
                                                            editRatingName: true
                                                        })
                                                    }}
                                                />
                                            )
                                    )
                                    : null
                            }


                            {
                                (!!isUserRole(role.PROJECT_MANAGER) || !!isUserRole(role.ARB_OPERATOR))
                                    ? (
                                        <i
                                            className="mdi mdi-delete trash"
                                            onClick={ clickEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    archiveRatingPrompt: {name: this.state.metaData.name, id: this.getRatingId() }
                                                })
                                            }}
                                        />
                                    )
                                    : null
                            }


                            {
                                !!this.state.editRatingNameError
                                    ? <span className={"form-error-label"}>{ this.state.editRatingNameError }</span>
                                    : null
                            }


                            {
                                this.state.metaData.status === RATING_STATUS.auditDone.id
                                    ? (
                                        <React.Fragment>
                                         <span className={"in-audit-header-text float-right"}>{translate("evaluation_completed")}</span>

                                            <Row style={{flexDirection:"row-reverse",marginRight: "4vh"}}>

                                                <img
                                                    id={"pdf" }
                                                    src={HOST + "/assets/icons_evaluations/pdf.svg"}
                                                    alt={"SDG"}
                                                    className={"sdg-icon-file2"}
                                                    onClick={clickEvent => {
                                                        clickEvent.stopPropagation();

                                                        this.setState({
                                                            ...this.state,
                                                            navigateToPdf: this.getRatingId()
                                                        })


                                                    }}
                                                />

                                                <UncontrolledTooltip placement="top" target={"pdf"}>
                                                    <span className="">{"Score Card"}</span>
                                                </UncontrolledTooltip>

                                                <img
                                                     id={"sdg"}
                                                    src={HOST + "/assets/icons_evaluations/sdgs.svg"}
                                                    alt={"SDG"}
                                                    className={"sdg-icon-file2"}
                                                    onClick={clickEvent => {
                                                        clickEvent.stopPropagation();
                                                        this.setState({
                                                            ...this.state,
                                                            sgdScoreModal: this.getRatingId(),
                                                            evaluation_id: this.getRatingId()
                                                        })
                                                    }}
                                                />
                                                <UncontrolledTooltip placement="top" target={"sdg"}>
                                                    <span className=""> SDGs </span>
                                                </UncontrolledTooltip>

                                                <i
                                                    id={"punteggio"}
                                                    className={"mdi mdi-star-outline sdg-icon-star2"}
                                                    alt={"SDG"}
                                                    // className={"sdg-icon-file2"}
                                                    onClick={clickEvent => {
                                                        clickEvent.stopPropagation();
                                                        this.setState({
                                                            ...this.state,
                                                            scoreModale: this.getRatingId(),
                                                            evaluation_id: this.getRatingId()
                                                        })
                                                    }}
                                                />

                                                <UncontrolledTooltip placement="top" target={"punteggio"}>
                                                    <span className="">{translate("Final Score")}</span>
                                                </UncontrolledTooltip>


                                            </Row>

                                            </React.Fragment>



                                    )
                                    : (
                                        <span className={"in-audit-header-text float-right"}>{translate("evaluation_in_progress")}</span>
                                    )
                            }

                            {/*<br /><br />*/}
                        </h4>


                        {
                            (!!hasPermission(permissions.DISPLAY_RATING_LEGEND))
                                ? (


                                    <React.Fragment>

                                        <div style={{ float: "left" }}>

                                            <React.Fragment key={"legend-commands"}>

                                                <Row>




                                                            <Col sm="2">
                                                                <i
                                                                    style={{ color: "rgb(51, 166, 16)" }}
                                                                    className={"mdi mdi-format-list-bulleted info-icon"}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        showlog("modale degli allegati");
                                                                        this.setState({
                                                                            ...this.state,
                                                                            materialityReportModal: this.getRatingId()
                                                                        })

                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm="10" style={{ paddingTop: "20px" }}>
                                                                <span style={{ fontWeight: "bold" }}>{translate("Materiality")}</span>
                                                            </Col>




                                                </Row>
                                            </React.Fragment>

                                        </div>
                                        {/*
                                            (!!hasPermission(permissions.DISPLAY_RATING_TABLE_LEGEND))
                                                ? (
                                                    <div style={{ float: "right", marginTop: "-100px" }}>
                                                        <QuestionReport
                                                            isAudit={true}
                                                            evaluation_id={this.getRatingId()}
                                                            NoModal={true}
                                                            data={ this.state.metaData.info }
                                                        />
                                                    </div>
                                                )
                                                : null
                                        */}

                                    </React.Fragment>

                                )

                                : null

                        }



                        {
                            (!!this.state.ratingCloseModal)
                                ? (
                                    <CloseRatingConfirmModal
                                        id={this.state.ratingCloseModal}
                                        onClose={() => {
                                            this.setState({
                                                ...this.state,
                                                ratingCloseModal: null
                                            })
                                        }}
                                    />
                                )
                                : null
                        }


                        <span className="float-right">

                            {

                                // (this.state.metaData.status !== RATING_STATUS.auditDone.id)
                                !this.isRatingClosed()
                                    ? (
                                        ( Math.floor(totalProgress) === 1 )
                                            ? (
                                                <ActionButton
                                                    buttonText={translate("Finish Evaluation")}
                                                    promise={() => {


                                                        // refresh da fare qui


                                                        return passToEvaluationStatus( this.getRatingId(), { state: RATING_STATUS.auditDone.id });
                                                    }}
                                                    onResult={result => {
                                                        window.location.href = window.location.href;
                                                    }}
                                                    onError={e => {
                                                        errorlog("errore",e);

                                                        let errorMessage = extractErrorMessage( e );

                                                        this.setState({
                                                            ...this.state,
                                                            // errorMessage: (!!e ? emoj(EMOJ.sad_but_relieved_face) + "  " + e : null)
                                                            errorMessage: errorMessage
                                                        });
                                                    }}
                                                />
                                            )
                                            : (
                                                <React.Fragment>
                                                    <div className={"tree_wizard_step_bar_label"}>
                                                        <span>{translate("completed_questions")} { Math.floor(totalProgress * 100) }%</span>
                                                    </div>
                                                    <Progress
                                                        animated
                                                        striped
                                                        color={progressColor}
                                                        value={ Math.floor(totalProgress * 100) }
                                                        className="mb-3 progress-sm"
                                                        id={"questionProgress"}
                                                    />
                                                </React.Fragment>
                                            )
                                    )
                                    : null


                            }

                        </span>

                    </CardHeader>

                    <CardBody>






                    {/*
                        <Label for="subThemes">Filtro&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                        <Select
                            className="react-select question-filter-select"
                            classNamePrefix="react-select"
                            name="question-filter"
                            id="question-filter"

                            onChange={changeEvent => {
                                showlog("filtro");
                                showlog(changeEvent);


                                this.setState({
                                    ...this.state,
                                    selectedQuestionFilter: changeEvent.value
                                },
                                    () => {

                                        // ricerca della prima domanda
                                        let firstQuestion =
                                            extractAllQuestions(this.state.tree)
                                                .filter(onlyNotNull)
                                                .filter(this.filterQuestion)
                                                .filter(onlyFirst)
                                                .reduce(getLastElementOr, {})
                                            ;
                                        firstQuestion.open = true;

                                        let treeTmp = cloneObject(this.state.tree);
                                        let refreshedTree = cloneObject(this.state.tree);


                                        if (!!firstQuestion && !!firstQuestion.path) {

                                            firstQuestion.path
                                                .reduce((path, currentPath) => {

                                                    path = [...path, currentPath];
                                                    let node = getQuestionTreeNodeByPath(treeTmp, path);

                                                    if (path.length === 1) {
                                                        node.activeTab = node.id;
                                                        let nodeForTab = getQuestionTreeNodeByPath(refreshedTree, path);
                                                        nodeForTab.activeTab = node.id;
                                                    } else {
                                                        node.open = true;
                                                    }

                                                    return path;
                                                }, [])
                                                ;

                                        }



                                        if( changeEvent.value !== QUESTION_STATUS_ALL.value ) {
                                            openAllNodes( this.state.tree )
                                                .then( newTree => {
                                                    cloneOpenNodes(newTree, refreshedTree);
                                                    this.setState({
                                                        ...this.state,
                                                        tree: refreshedTree
                                                    })
                                                })
                                                .catch(e => {
                                                    errorlog("Apertura tutti i nodi")
                                                    showlog( e );
                                                })
                                            ;
                                        }
                                        else {
                                            cloneOpenNodes(treeTmp, refreshedTree);
                                            this.setState({
                                                ...this.state,
                                                tree: refreshedTree
                                            })
                                        }




                                    }
                                )


                            }}
                            options={ ALL_POSSIBLE_QUESTION_STATUS }
                            value={
                                (
                                    typeof this.state.selectedQuestionFilter !== "undefined"
                                    && this.state.selectedQuestionFilter !== null
                                    && this.state.selectedQuestionFilter !== undefined
                                )
                                    ? (
                                        ALL_POSSIBLE_QUESTION_STATUS
                                            .filter(questionStatus => questionStatus.value === this.state.selectedQuestionFilter)
                                            .reduce(
                                                getLastElementOr,
                                                QUESTION_STATUS_ALL
                                            )
                                    )
                                    : QUESTION_STATUS_ALL
                            }
                            placeholder={<span className={"select-placeholder"}>Seleziona Filtro</span>}
                        />


                        <br /><br />

                        <CardBody>

                        */}


                            <Nav tabs>
                                {
                                    this.state.tree.tabs
                                        .map((tab, index) => {

                                            let tabQuestionList = extractAllQuestions(this.state.tree, tab);

                                            //showlog("TABQUESTIONLIST", tabQuestionList.map ( a => a.code ))

                                            let progress = "";
                                            let auditProgress = "";



                                            let tabInfo =
                                                this.state.metaData.info
                                                    .filter(tabData => tabData.macroareaId === tab.id)
                                                    .map( tabData => ({
                                                        questionCompletedNumber: tabData.questionCompleted.count,
                                                        questionTotalNumber: tabData.questionTotal.count,
                                                        questionAuditReady: tabData.questionAuditReady.count
                                                    }))
                                                    .reduce(getLastElementOr, {
                                                        questionCompletedNumber: 0,
                                                        questionTotalNumber: 0,
                                                        questionAuditReady: 0
                                                    })
                                            ;
                                            if (!!tabInfo) {
                                                progress = tabInfo.questionCompletedNumber + "/" + tabInfo.questionTotalNumber;
                                                auditProgress = tabInfo.questionAuditReady + "/" + tabInfo.questionTotalNumber;
                                            }

                                            let progressColor = (tabInfo.questionCompletedNumber < tabInfo.questionTotalNumber) ? COLOR_POLICY.error : COLOR_POLICY.evident;
                                            let auditProgressColor = (tabInfo.questionAuditReady < tabInfo.questionTotalNumber) ? COLOR_POLICY.error : COLOR_POLICY.evident;

                                            // nuovo approccio
                                            let progressObj =
                                                this.state.metaData
                                                    .info
                                                    .filter( md => md.macroareaId === tab.id )
                                                    .reduce( getLastElementOr, {
                                                        questionAnswered: { count: 0 },
                                                        // questionToAnswer: { count: 0 }
                                                        questionTotal: { count: 0 }
                                                    })
                                            ;
                                            // progress = progressObj.questionAnswered.count + "/" + progressObj.questionToAnswer.count;
                                            progress = progressObj.questionAnswered.count + "/" + progressObj.questionTotal.count;





                                            let colorClassInfo =
                                                Object.keys(TABS)
                                                    .map( tabKey => TABS[tabKey] )
                                                    // .filter( tab => (parseInt(tab.id, 10) +1) === this.state.activeTab )
                                                    .filter( t => t.siratingId === tab.id )
                                                    .reduce( getLastElementOr, { className: "" } )
                                                    .className
                                            ;

                                            return (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        id={SIRATING_TREE_DEEP_LEVELS_NAME[0] + "-" + tab.id}
                                                        href="#"
                                                        className={
                                                            // classnames({ active: this.state.activeTab === tab.id })
                                                            [
                                                                colorClassInfo
                                                            ]
                                                            .join(" ")
                                                        }
                                                        onClick={() => {


                                                            // let treeTmp = cloneObject(this.state.tree);
                                                            // let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, [tab.id]);
                                                            // nodeTmp.open = true;	// non va bene

                                                            this.setState({
                                                                ...this.state,
                                                                activeTab: tab.id,
                                                                // wait: true
                                                                // tree: treeTmp
                                                            },
                                                                () => {
                                                                    // this.loadData();
                                                                }
                                                            );
                                                        }}>
                                                        <i
                                                            className={classnames(
                                                                tab.icon,
                                                                'd-lg-none',
                                                                'd-block',
                                                                'mr-1'
                                                            )} />
                                                        <span className="d-none d-lg-block">
                                                            {tab.name}
                                                            <React.Fragment>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <span className={"float-right " + progressColor.textClass}>{progress}</span>
                                                            </React.Fragment>
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        })
                                }


                                <TreeToggler
                                    tree={this.state.tree}
                                    onFinish={newTree => {
                                        this.setState({
                                            ...this.state,
                                            tree: newTree
                                        })
                                    }}
                                />


                            </Nav>

                            <TabContent activeTab={this.state.activeTab} className={"srtool-target-page"}>
                                {
                                    this.state.tree.tabs
                                        .map((tab, index) => {
                                            return (
                                                <TabPane
                                                    tabId={tab.id}
                                                    key={index}
                                                >


                                                    {


                                                        (!!this.state.wait)
                                                            ? (
                                                                <WaitingOrNodataMessage
                                                                    waiting={!!this.state.wait /*&& ( this.state.tree.tabs.map( tab => tab.themes.length > 0 ).filter( onlyNotNull ).length > 0 )*/}
                                                                    nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                                                                    fullHeight={true}
                                                                    className={"bottom-margin-alignment"}
                                                                    waitMessage={ translate("Loading_Questions") }
                                                                />
                                                            )
                                                            : (
                                                                <Row>
                                                                    <Col sm="12">

                                                                        {
                                                                            tab.themes.sort((a,b) => {
                                                                                return (a.code.split(".")[1] - b.code.split(".")[1])
                                                                            })
                                                                                .map((th, chIndex) => {

                                                                                    let themeQuestionList = extractAllQuestions(this.state.tree, tab, th);
                                                                                    //     .sort((a,b) => {
                                                                                    //     return (a.code.split(".")[1] - b.code.split(".")[1])
                                                                                    // });

                                                                                    //showlog("LISTAAAAAAAAA", themeQuestionList)
                                                                                    showlog("LISTAAAAAAAAA", themeQuestionList.map( a => a.code))
                                                                                    showlog("QUESTIONS", th.questions.sort((a,b) => {
                                                                                        return (a.code.split(".")[1] - b.code.split(".")[1])
                                                                                    }))
                                                                                    // showlog("tematic id", th.thematic_code)
                                                                                    showlog("tematic id", th)


                                                                                    let progress =
                                                                                        themeQuestionList
                                                                                            // ATTENZIONE vengono tenute in considerazione anche le domande
                                                                                            // che si trovano in uno stato intermedio
                                                                                            .filter(s => s.status >= QUESTION_STATUS.incomplete.id)
                                                                                            .length
                                                                                        + "/" +
                                                                                        themeQuestionList.length
                                                                                        ;

                                                                                    // let progressColor = (themeQuestionList.filter( s => !!s.isCompleted ).length < themeQuestionList.length) ? COLOR_POLICY.error : COLOR_POLICY.validated;
                                                                                    let progressColor =
                                                                                        (themeQuestionList.filter(s => s.status >= QUESTION_STATUS.finished.id).length < themeQuestionList.length)
                                                                                            ? COLOR_POLICY.error
                                                                                            : COLOR_POLICY.validated
                                                                                        ;

                                                                                    // ;

                                                                                    let colorClassInfo =
                                                                                        Object.keys(TABS)
                                                                                            .map( tabKey => TABS[tabKey] )
                                                                                            // .filter( tab => (parseInt(tab.id, 10) +1) === this.state.activeTab )
                                                                                            .filter( t => t.siratingId === tab.id )
                                                                                            .reduce( getLastElementOr, { className: "" } )
                                                                                            .className
                                                                                    ;





                                                                                    return (
                                                                                        <Card className="mb-0"
                                                                                            key={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
                                                                                            <CardHeader
                                                                                                className={
                                                                                                    // classnames({ active: this.state.activeTab === tab.id })
                                                                                                    [
                                                                                                        colorClassInfo
                                                                                                    ]
                                                                                                        .join(" ")
                                                                                                }
                                                                                            >

                                                                                                <NavLink
                                                                                                    id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}
                                                                                                    href="#"
                                                                                                    onClick={clickEvent => {

                                                                                                        let treeTmp = cloneObject(this.state.tree);
                                                                                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, th.path);
                                                                                                        nodeTmp.open = !getQuestionTreeNodeByPath(this.state.tree, th.path).open;
                                                                                                        // ;


                                                                                                        this.setState({
                                                                                                            ...this.state,
                                                                                                            tree: treeTmp
                                                                                                        });


                                                                                                    }}
                                                                                                >
                                                                                                    <h5 className="m-0">
                                                                                                        <div
                                                                                                            className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">

                                                                                                            { this.helperNode( th ) }
                                                                                                            {th.name}
                                                                                                        </div>






                                                                                                        {/*
                                                                                                            (
                                                                                                                !!hasPermission( permissions.SIRATING_READ_MATERIALITY_LEVEL )
                                                                                                                && !!hasPermission( permissions.SIRATING_EDIT_MATERIALITY_LEVEL )
                                                                                                                && !!th.materiality

                                                                                                            )
                                                                                                                ? (
                                                                                                                    <span
                                                                                                                        className={"materiality-label"}
                                                                                                                        onClick={clickEvent => {

                                                                                                                            if( !!th.materiality ) {

                                                                                                                                let positionY = window.scrollY;
                                                                                                                                showlog("position y "+ positionY);
                                                                                                                                clickEvent.stopPropagation();
                                                                                                                                this.setState({
                                                                                                                                        ...this.state,
                                                                                                                                        editMaterialityPopup: th
                                                                                                                                    },
                                                                                                                                    () => {
                                                                                                                                        setTimeout(
                                                                                                                                            () => {
                                                                                                                                                window.scrollTo(0, positionY);
                                                                                                                                                showlog("scrolled to "+ positionY);
                                                                                                                                            },
                                                                                                                                            5
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                )

                                                                                                                            }


                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {

                                                                                                                            (!!th.materiality)
                                                                                                                                ? (
                                                                                                                                    "Materialità " +
                                                                                                                                    MATERIALITY_REASON_LEVEL
                                                                                                                                        .filter(m => m.value === th.materiality.value)
                                                                                                                                        .map(m => m.label)
                                                                                                                                        .reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0].label)
                                                                                                                                )
                                                                                                                                : null

                                                                                                                        }

                                                                                                                        {
                                                                                                                            (
                                                                                                                                !!hasPermission( permissions.SIRATING_READ_MATERIALITY_LEVEL )
                                                                                                                                && !!hasPermission( permissions.SIRATING_EDIT_MATERIALITY_LEVEL )
                                                                                                                                && !!th.materiality

                                                                                                                            )
                                                                                                                                ? <i className={"uil-edit-alt materiality_edit-icon"} />
                                                                                                                                : null
                                                                                                                        }

                                                                                                                    </span>
                                                                                                                )
                                                                                                                : null
                                                                                                        */}





                                                                                                        <span
                                                                                                            className="float-right">


                                                                                                            {
                                                                                                                (!!th.open)
                                                                                                                    ? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
                                                                                                                    : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
                                                                                                            }
                                                                                                        </span>
                                                                                                        <span
                                                                                                            className={"float-right set-value-button-container-is-navlink " + progressColor.textClass}
                                                                                                        >
                                                                                                            {progress}
                                                                                                        </span>

                                                                                                    </h5>

                                                                                                </NavLink>
                                                                                            </CardHeader>
                                                                                            {/*<UncontrolledCollapse defaultOpen={ !!th.open } toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>*/}
                                                                                            <Collapse isOpen={!!th.open}
                                                                                                toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
                                                                                                <CardBody>


                                                                                                    {
                                                                                                        // domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                                        (!!th.open)
                                                                                                            ? this.buildQuestions(th.questions.sort((a,b) => {
                                                                                                                return (a.code.split(".")[1] - b.code.split(".")[1])
                                                                                                            }))
                                                                                                            : (
                                                                                                                <WaitingOrNodataMessage
                                                                                                                    waiting={true}
                                                                                                                    nodataMessage={"in caricamento..."}
                                                                                                                />
                                                                                                            )
                                                                                                    }

                                                                                                    {   // sotto-tematica --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                                        (!!th.open)
                                                                                                            ? this.buildSubThemes(th.subThemes, tab.id)
                                                                                                            : null

                                                                                                    }

                                                                                                </CardBody>
                                                                                            </Collapse>
                                                                                        </Card>
                                                                                    );
                                                                                })

                                                                        }




                                                                    </Col>
                                                                </Row>
                                                            )
                                                    }

                                                    <GotoTopComponent
                                                        alwaysVisible={ false }
                                                    />

                                                </TabPane>
                                            );
                                        })
                                }
                            </TabContent>
                        </CardBody>

                    {/*</CardBody>*/}
                </Card>


                {
                    (!!this.state.errorMessage)
                        ? (
                            <ErrorMessageModalComponent
                                message={this.state.errorMessage}
                                noheader={true}
                                noHazard={true}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        errorMessage: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.inWaiting)
                        ? (
                            <StandBySystemModalComponent
                                message={this.state.processingMessage}
                                didMount={() => {

                                    /*
                                    this.setState({
                                        ...this.state,
                                        stopable: true
                                    })
                                    */
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.historyModal)
                        ? (
                            <SiRatingQuestionHistoryModal
                                ratingId={this.getRatingId()}
                                questionId={this.state.historyModal.id}
                                onClose={closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        historyModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {/*
                    !!this.state.editMaterialityPopup
                        ? (

                        )
                        : null
                */}








            </Suspense>
        );
    }

    componentDidMount() {

        this.loadData();

    }


    buildSubThemes(subThemes, tabid) {

        return (
            subThemes
                .map((sth, infoIndex) => {


                    let subThemeQuestionList =
                        extractAllQuestions(
                            this.state.tree,
                            getQuestionTreeNodeByPath(this.state.tree, sth.path.filter((p, i) => i < (sth.path.length - 2))),
                            getQuestionTreeNodeByPath(this.state.tree, sth.path.filter((p, i) => i < (sth.path.length - 1))),
                            sth
                        )
                        ;


                    let progress =
                        subThemeQuestionList
                            // ATTENZIONE vengono tenute in considerazione anche le domande
                            // che si trovano in uno stato intermedio
                            .filter(s => s.status >= QUESTION_STATUS.incomplete.id)
                            .length
                        + "/" +
                        subThemeQuestionList.length
                        ;

                    // let progressColor = (subThemeQuestionList.filter( s => !!s.isCompleted ).length < subThemeQuestionList.length) ? COLOR_POLICY.error : COLOR_POLICY.validated;
                    let progressColor =
                        // era finished
                        (subThemeQuestionList.filter(s => s.status >= QUESTION_STATUS.incomplete.id).length < subThemeQuestionList.length)
                            ? COLOR_POLICY.error
                            : COLOR_POLICY.validated
                        ;


                    let chapterColorClassInfo =
                        Object.keys(TABS)
                            .map( tabKey => TABS[tabKey] )
                            .filter( t => t.siratingId === tabid )
                            .reduce( getLastElementOr, { lightClassName: "" } )
                            .lightClassName
                    ;
                    // debugger;


                    return (
                        <Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
                            <CardHeader
                                className={
                                    [
                                        chapterColorClassInfo
                                    ]
                                    .join(" ")
                                }
                            >

                                <NavLink

                                    id={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}
                                    href="#"
                                    onClick={clickEvent => {

                                        let treeTmp = cloneObject(this.state.tree);
                                        let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, sth.path);
                                        nodeTmp.open = !getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open;
                                        this.setState({
                                            ...this.state,
                                            tree: treeTmp
                                        });


                                    }}
                                >


                                    <h5 className="m-0">
                                        {/*		custom-accordion-title		*/}
                                        <div className=" pt-2 pb-2 sirating-themes-accordion-title">

                                            { this.helperNode( sth ) }
                                            {sth.name}


                                            {
                                                (
                                                    !!hasPermission( permissions.SIRATING_READ_MATERIALITY_LEVEL )
                                                    && !!hasPermission( permissions.SIRATING_EDIT_MATERIALITY_LEVEL )
                                                    && !!sth.materiality

                                                )
                                                    ? (
                                                        <span
                                                            className={"materiality-label"}
                                                            onClick={clickEvent => {
                                                                let positionY = window.scrollY;
                                                                showlog("posizione y: "+ positionY);
                                                                clickEvent.stopPropagation();
                                                                this.setState({
                                                                    ...this.state,
                                                                    editMaterialityPopup: sth
                                                                },
                                                                    () => {
                                                                        setTimeout(
                                                                            () => {
                                                                                window.scrollTo(0, positionY);
                                                                                showlog("scrolled to "+ positionY);
                                                                            },
                                                                            20
                                                                        )
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            Materialità {
                                                                MATERIALITY_REASON_LEVEL
                                                                    .filter(m => m.value === sth.materiality.value)
                                                                    .map(m => m.label)
                                                                    .reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0].label)
                                                            }
                                                            <i
                                                                className={"uil-edit-alt materiality_edit-icon"}
                                                            />
                                                        </span>
                                                    )
                                                    : null
                                            }

                                        </div>


                                        <span className="float-right">

                                            {
                                                /*
                                                dripicons-plus
                                                mdi mdi-folder-plus
                                                mdi mdi-plus-box-multiple
                                                mdi mdi-timeline-plus
                                                mdi mdi-timeline-plus-outline
                                                */
                                                (!!getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open)
                                                    ?
                                                    <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
                                                    : <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
                                            }
                                        </span>
                                        <span className={"float-right set-value-button-container-is-navlink " + progressColor.textClass}>{progress}</span>
                                    </h5>
                                </NavLink>

                            </CardHeader>
                            {/*<UncontrolledCollapse defaultOpen={ !!sth.open } toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>*/}
                            <Collapse isOpen={!!sth.open}
                                toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
                                <CardBody>

                                    {
                                        // domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                        (!!sth.open)
                                            ? this.buildQuestions(sth.questions)
                                            : (
                                                (
                                                    <WaitingOrNodataMessage
                                                        waiting={true}
                                                        nodataMessage={"in caricamento..."}
                                                    />
                                                )
                                            )

                                    }

                                </CardBody>
                            </Collapse>
                        </Card>
                    );
                })
        );

    }


    filterQuestion = question => {


        let activeFilter =
            ALL_POSSIBLE_QUESTION_STATUS
                .filter(questionStatus => questionStatus.value === this.state.selectedQuestionFilter)
                .reduce(
                    (final, current) => ({ id: current.value, ...current }),
                    QUESTION_STATUS_ALL
                )
            ;

        let filter = false;

        switch (activeFilter.value) {

            case QUESTION_STATUS_ALL.value:     // ----------------------------------- tutte le domande ----
                // nessun filtro
                filter = true;
                break;

            case QUESTION_STATUS.virgin.id:     // -------------------------------- domande mai toccate ----

                if( question.status === QUESTION_STATUS.virgin.id )         filter = true;
                if( question.status === QUESTION_STATUS.incomplete.id )     filter = true;
                if (!!this.state.editAnswer.some(ea => ea === question.id)) filter = true;
                else {
                    if (!!!question.isCompleted) {
                        // filter = true;
                    }
                }

                break;
            case -2: // -------------------------------------------------------------------------- edit ----
                filter = (!!this.state.editAnswer.some(ea => ea === question.id));
                break;


            case -3: // -------------------------------------------------------------------------------------
            case -4: // -------------------------------------------------------------------------------------
            case 21: // -------------------------------------------------------------------------------------
                filter = ( !!activeFilter.isPassCondition( question.status ) );
                break;


            /*
            case QUESTION_STATUS.incomplete.id:   // ---------------------------- domande da completare ----
                if (!!this.state.editAnswer.some(ea => ea === question.id)) {
                    // edit
                } else {
                    filter = (question.status === QUESTION_STATUS.incomplete.id);
                }
                break;
            */

            case QUESTION_STATUS.finished.id:   // --------------------------------- domande completate ----
                if (!!this.state.editAnswer.some(ea => ea === question.id)) {
                    // edit
                } else {
                    // if (!!question.isCompleted) {
                    // complete
                    filter = (question.status === QUESTION_STATUS.finished.id);
                    // }
                }
                break;
            case QUESTION_STATUS.validated.id:  // ----------------------------------- domande validate ----
                if (!!this.state.editAnswer.some(ea => ea === question.id)) {
                    // edit
                } else {
                    if (question.status === QUESTION_STATUS.validated.id) filter = true;
                    /*
                    if (!!question.isCompleted) {
                        // complete
                    }
                    else {
                        if( question.status === QUESTION_STATUS.validated.id ) filter = true;
                    }
                    */
                }

                break;
            case QUESTION_STATUS.lowNonCompliant.id:     // ----------------------- Non conformità lieve ----
                // if (question.status === QUESTION_STATUS.lowNonCompliant.id) filter = true;
                filter = ( !!activeFilter.isPassCondition( question.status ) );
                break;
            case QUESTION_STATUS.waitNonCompliant.id:    // ---- Non conforme e in attesa di validazione ----
                if (question.status === QUESTION_STATUS.waitNonCompliant.id) filter = true;
                break;
            case QUESTION_STATUS.highNonCompliant.id:    // ------------------------ Non conformità alta ----
                if (question.status === QUESTION_STATUS.highNonCompliant.id) filter = true;
                break;
            case QUESTION_STATUS.cancelled.id:    // ------------------------ Annullata ----
                if (question.status === QUESTION_STATUS.cancelled.id) filter = true;
                break;
        }

        return filter;


    }




    subQuestionRequest = (dataList, currentIndex = 0) /*(question, choiche, choicheIndex)*/ => {


        /*
        {
            choiche_id: 5,
            choiche_name: "esiste (specificare)",
            isRequiredAttachment: false,
            isRequiredTextBox: true,
            ordering: 1,
            score: 0,
            isOpeningQuestions: 0
        }
        */


        let question = null;
        let choiche = null;


        // dati nel sistema
        let checkedChoiche = false;
        let checkedSingleChoiche = false;

        let choicheDOM = null;
        let treeTmp = cloneObject(this.state.tree);
        let questionPath = [];
        let questionTreeNode = null;
        let questionTreeNodeList = [];

        let refreshedChoiche = null;


        return (

            Promise.all(
                dataList
                    .filter(data => {

                        question = data.q;
                        choiche = data.cs;


                        // dati nel sistema
                        checkedChoiche = ((!!choiche.answers && choiche.answers.length > 0) || !!choiche.checked);
                        checkedSingleChoiche = ((!!choiche.answers && choiche.answers.length > 0 && choiche.answers[0].choiche_id === choiche.choiche_id));

                        choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
                        // treeTmp = cloneObject(this.state.tree);
                        questionPath = [];
                        if (!!isNotEmptyArray(question.path)) questionPath = question.path;
                        else questionPath = getQuestionPath(treeTmp, question.id);

                        questionTreeNode = getQuestionTreeNodeByPath(treeTmp, questionPath);

                        if (!!!questionTreeNode) {
                            warninglog("Attenzione al path della question");
                            showlog(questionPath);
                            // ;
                        }


                        refreshedChoiche =
                            questionTreeNode.choiches
                                .filter(ch => ch.choiche_id === choiche.choiche_id)
                                .reduce(getLastElementOr, null)
                            ;


                        if (!!checkedSingleChoiche) {

                            showlog("chiamata di refresh", (!!choiche.isOpeningQuestions && (!!!isNotEmptyArray(question.child) || (question.child.length === 1 && !!question.child[0].tmp))));

                            if (!!choiche.isOpeningQuestions && !!!isNotEmptyArray(question.child) && !choiche.inWait) {
                                return true;
                            } else {
                                return false;
                            }

                        } else {
                            return false;
                        }

                    })


                    .map((data, index) => {


                        // ;

                        question = data.q;
                        choiche = data.cs;


                        // dati nel sistema
                        checkedChoiche = ((!!choiche.answers && choiche.answers.length > 0) || !!choiche.checked);
                        checkedSingleChoiche = ((!!choiche.answers && choiche.answers.length > 0 && choiche.answers[0].choiche_id === choiche.choiche_id));

                        choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
                        // treeTmp = cloneObject(this.state.tree);
                        questionPath = [];
                        if (!!isNotEmptyArray(question.path)) questionPath = question.path;
                        else questionPath = getQuestionPath(treeTmp, question.id);

                        questionTreeNode = getQuestionTreeNodeByPath(treeTmp, questionPath);

                        if (!!!questionTreeNode) {
                            warninglog("Attenzione al path della question");
                            showlog(questionPath);
                            // ;
                        } else {
                            refreshedChoiche =
                                questionTreeNode.choiches
                                    .filter(ch => ch.choiche_id === choiche.choiche_id)
                                    .reduce(getLastElementOr, null)
                                ;
                        }


                        questionTreeNodeList.push({ questionId: question.id, children: [] });

                        return (
                            loadAnswers(this.getRatingId(), this.state.activeTab || 1, refreshedChoiche.choiche_id)

                                // editTree ----------------------------------------------
                                .then(subQuestions => {

                                    // ;
                                    // showlog(index, question.id, refreshedChoiche.choiche_id);


                                    if (!!!isNotEmptyArray(subQuestions)) {
                                        // non arrivano le sotto-domande
                                        // si verifica se c'è un errore lato server

                                        questionTreeNode.child = [{ status: "server-error" }]
                                        return Promise.reject();
                                    }

                                    return (
                                        // convertSIRatingSubQuestionTableToSubQuestionChild( subQuestions, getQuestionPath(treeTmp, question.id) )
                                        convertSIRatingSubQuestionTableToSubQuestionChild(subQuestions, getQuestionPath(treeTmp, questionTreeNodeList[index].questionId))
                                            .then(nodes => {
                                                // questionTreeNode.child = nodes;
                                                // questionTreeNode.open = true;
                                                // nodes.map( n => n.code = n.question_code );

                                                // getQuestionTreeNodeByPath( treeTmp, getQuestionPath(treeTmp, question.id) ).child = nodes;;


                                                // questionTreeNode.child = nodes;
                                                questionTreeNode.open = true;
                                                choiche.inWait = false;

                                                questionTreeNode.child =
                                                    nodes
                                                        .map(qc => {
                                                            qc.open = true;
                                                            return qc;
                                                        })
                                                    ;

                                                // questionTreeNodeList.push({questionId: question.id, children: nodes});

                                                // return questionTreeNode;

                                                // showlog(index, question.id, refreshedChoiche.choiche_id);
                                                questionTreeNodeList[index].children = nodes;

                                                // showlog(nodes);
                                                // ;
                                                return true;

                                            })
                                            .catch(e => {
                                                errorlog("errore",e);
                                                return Promise.reject(e);
                                            })
                                    );

                                })


                                // finish ---------------------------------------------------
                                .then(qTreeNode => {

                                    // ;

                                    if (!!checkedSingleChoiche) refreshedChoiche.active = true;

                                    choiche.inWait = false;
                                    refreshedChoiche.inWait = false;


                                    return qTreeNode;

                                })


                                .catch(e => {
                                    errorlog("errore",e);
                                    /*
                                    this.setState({
                                        ...this.state,
                                        subQuestionWait: null
                                    })
                                    */
                                })
                        );


                    })
            )
                .then(qTreeNode => {


                    // questionTreeNodeList


                    questionTreeNodeList
                        .map(data => {
                            let questionPathTmp = getQuestionPath(treeTmp, data.questionId);
                            let questionTreeNodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPathTmp);
                            questionTreeNodeTmp.child = data.children;
                            // showlog(data, questionTreeNodeTmp.child.length);
                        })
                        ;

                    showlog(treeTmp);

                    // ;


                    /*
                    // controllo disabilitazione di tutte le domande
                    è stato fatto in this.isDisabledQuestion()
                    if( !!isUserRole( role.SUPER_ADMIN ) ) {

                        let questionList = extractAllQuestions( treeTmp );
                        questionList.map(q => {




                        });
                    }
                    */




                    return (
                        new Promise( (finishedOk, finishedError) => {

                            cloneOpenNodes( this.state.tree, treeTmp );

                            this.setState({
                                ...this.state,
                                // subQuestionWait: refreshedChoiche.choiche_id,
                                subQuestionWait: (!!questionTreeNode ? null : this.state.subQuestionWait),
                                tree: treeTmp,
                                // metaData: Object.assign({}, this.state.metaData, metaData)
                            },
                                () => {
                                    finishedOk();
                                }
                            )

                        })
                    );




                })





        );


    }


    // re-computing all questions numbers ----------------------
    getProgress(questionTreeNode) {

        return (
            loadProgress(this.getRatingId())
                .then(result => {



                    this.setState({
                        ...this.state,
                        // errorMessage: "Visualizzazione completata",
                        metaData: Object.assign({}, this.state.metaData, { info: result })
                    },
                        () => {
                            return true;
                        })


                })
                .catch(e => {
                    errorlog("errore",e);
                    return Promise.reject(e);
                })
        );

    }


    buildQuestions(questions, isSubQuestion) {


        return (
            questions
                .filter(onlyNotNull)

                .filter(this.filterQuestion)


                .map((question, qIndex) => {


                    // if( qIndex === 0 ) {
                    // 	showlog("prima domanda del filtro dopo l'apertura");
                    // 	showlog( question );
                    // }


                    let treeTmp = cloneObject(this.state.tree);
                    let questionPath = (!!question.path ? question.path : getQuestionPath(this.state.tree, question.id));
                    let questionNode = getQuestionTreeNodeByPath(treeTmp, questionPath);


                    let colorPolicy = COLOR_POLICY.virgin;
                    if (!!this.state.editAnswer.some(ea => ea === question.id)) colorPolicy = COLOR_POLICY.edit;
                    else {
                        if (question.status === QUESTION_STATUS.incomplete.id) colorPolicy = COLOR_POLICY.finished;  //  COLOR_POLICY.complete;
                        else {
                            if (question.status === QUESTION_STATUS.finished.id) colorPolicy = COLOR_POLICY.finished;
                            else {
                                if (question.status === QUESTION_STATUS.validated.id) colorPolicy = COLOR_POLICY.validated;
                                else {
                                    if (question.status === QUESTION_STATUS.lowNonCompliant.id) colorPolicy = COLOR_POLICY.warning;
                                    else {
                                        if (question.status === QUESTION_STATUS.waitNonCompliant.id) colorPolicy = COLOR_POLICY.lowWarning;
                                        else {
                                            if (question.status === QUESTION_STATUS.highNonCompliant.id) colorPolicy = COLOR_POLICY.error;
                                        }
                                    }
                                }
                            }
                        }
                    }


                    // color-policy-text-color-default
                    // color-policy-background-color-default
                    let statusColorPolicy = [
                        colorPolicy.textClass.split("-")[0],
                        colorPolicy.textClass.split("-")[1],
                        "background",
                        colorPolicy.textClass.split("-")[3],
                        colorPolicy.textClass.split("-")[4]
                    ]
                        .join("-");



                    showlog("question's choiches", question.choiches.sort((x, y) => (x.choiche_id < y.choiche_id)));


                    return (
                        <Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>



                            <CardHeader
                                className={"question-header"}
                            >


                                <div
                                    className={"question-header-main"}
                                    onClick={clickEvent => {

                                        let treeTmp = cloneObject(this.state.tree);
                                        let questionPath = getQuestionPath(treeTmp, question.id);
                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                        // nodeTmp.open = !nodeTmp.open;
                                        nodeTmp.open = !question.open;

                                        this.setState({
                                            ...this.state,
                                            tree: treeTmp
                                        });


                                    }}
                                >
                                    <div className={"question-header-top"}>

                                        <div className={"question-header-top-main"}>



                                            {
                                                (!!this.state.waitingQuestions && this.state.waitingQuestions.includes( question.id ))
                                                    ? <Spinner color={"primary"} className={"status-ball-in-wait"}/>
                                                    : <div className={"status-ball " + statusColorPolicy} />
                                            }


                                            <strong className={ colorPolicy.textClass }>{question.code}</strong>
                                            {
                                                !!question.questionGuidelines
                                                    ? (
                                                        <React.Fragment key={"gl-" + question.id}>
                                                            <i
                                                                id={"qgl-" + question.id}
                                                                className={"dripicons-information question-info-icon"}
                                                                onClick={clickEvent => {
                                                                    clickEvent.stopPropagation();
                                                                }}
                                                            />
                                                            <UncontrolledPopover
                                                                placement="top"
                                                                target={"qgl-" + question.id}
                                                                trigger="legacy"
                                                            >
                                                                <PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
                                                                <PopoverBody>{question.questionGuidelines}</PopoverBody>
                                                            </UncontrolledPopover>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                            }





                                            {
                                                (!!hasPermission( permissions.SEE_RATING_ANSWER_SCORE ))
                                                    ? (
                                                        <span
                                                            className={"score-label"}>Punteggio Massimo: <strong>{question.scoreMax}</strong></span>
                                                    )
                                                    : null
                                            }




                                        </div>





                                    </div>
                                    {/*
                                    Nel mezzo del cammin di nostra vita mi ritrovai per una selva oscura che la dritta via era smarrita! Quant'onesta pare la donna mia quand'ella latrui saluta. Del senno di poi so piene le fosse. é lastricata di buone intenzioni la strada per l'inferno
                                    */}
                                    <div className={"question-header-content"}>
                                        { this.helperNode( question ) }
                                        { question.name }
                                    </div>
                                </div>
                                <div className={"question-header-com"}>


                                    {/*
                                        (!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
                                            ? (
                                                !!question.hasAdminNote
                                                    ? <div className={"admin-note-present-status"}/>
                                                    : null
                                            )
                                            : null
                                    }
                                    {
                                        (!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
                                            ? (
                                                <span
                                                    className={"cursor-pointer srtool-note-icon"}
                                                    onClick={ clickEvent => {
                                                        showlog("click icon note");
                                                        this.setState({
                                                            ...this.state,
                                                            adminNotePopup: question
                                                        })
                                                    }}
                                                >
                                                    <AdminNoteIconSvgComponent/>
                                                </span>
                                            )
                                            : null
                                    }


                                    {
                                        !!question.hasNote
                                            ? <div className={"note-present-status"}/>
                                            : null
                                    }

                                    <span
                                        className={"cursor-pointer srtool-note-icon"}
                                        onClick={ clickEvent => {
                                            showlog("click icon note");
                                            this.setState({
                                                ...this.state,
                                                notePopup: question
                                            })
                                        }}
                                    >
                                        <NoteIconSvgComponent/>
                                    </span>


                                    <i
                                        className={"mdi mdi-history question-history-button cursor-pointer"}
                                        onClick={clickEvent => {
                                            showlog("modale dello storico");
                                            this.setState({
                                                ...this.state,
                                                historyModal: question
                                            });
                                        }}
                                    />

                                    <i
                                        className={"mdi mdi-attachment sirating-question-attachment-icon cursor-pointer"}
                                        onClick={clickEvent => {
                                            showlog("modale degli allegati");
                                            this.setState({
                                                ...this.state,
                                                attachmentModal: question.attachments,
                                                attachmentModalQuestionId: question.id,
                                                attachmentModalQuestion: question
                                            });
                                        }}
                                    />
                                    {
                                        (!!question.attachments && question.attachments.length > 0)
                                            ? (
                                                <span
                                                    className={"sirating-question-attachment-magnitude "+ statusColorPolicy}
                                                >
                                                            {
                                                                (question.attachments.length > 99)
                                                                    ? "+99"
                                                                    : question.attachments.length
                                                            }
                                                        </span>
                                            )
                                            : null
                                    }

                                    {

                                        /**
                                         * in caso sia necessaio l'allegato
                                         *
                                         * se lo status della domanda è incompleto e la domanda
                                         * ha almeno una risposta che ha almeno un flag isRequiredAttachment
                                         *
                                         *
                                         * E' stato tolto
                                         * <React.Fragment key={"attachment-notific-" + question.id}>

                                         <span id={"caution-" + question.id}
                                         className={"sirating-question-attachment-attention tooltip-icon-cursor"}>
                                         {
                                                            // emoj( EMOJ.face_vomiting )
                                                            // emoj( EMOJ.red_exclamation_mark )
                                                            emoj(EMOJ.warning)
                                                        }
                                         </span>

                                         <UncontrolledPopover
                                         placement="top"
                                         target={"caution-" + question.id}
                                         trigger="legacy"
                                         >
                                         <PopoverHeader className={COLOR_POLICY.warning.textClass}>
                                         <span>{emoj(EMOJ.warning)} ATTENZIONE</span>
                                         </PopoverHeader>
                                         <PopoverBody>
                                         <span>
                                         Una delle risposte di questa domanda rende necessario allegare un file alla domanda
                                         </span>
                                         </PopoverBody>
                                         </UncontrolledPopover>

                                         </React.Fragment>
                                         *
                                         * /
                                        (
                                            question.status === QUESTION_STATUS.incomplete.id &&
                                            !!(question.choiches
                                                .filter(choiche => !!isNotEmptyArray(choiche.answers))
                                                .filter(choiche => !!choiche.isRequiredAttachment)
                                                .reduce(getLastElementOr, null))
                                        )
                                            ? null // <--------- icona triangolo con relativo popup
                                            : null
                                    */}


                                    {/*
                                    <i
                                        id={ `gl-${ question.id }` }
                                        className={"mdi mdi-information-outline guidelinees-icon"}
                                        onClick={clickEvent => {
                                            clickEvent.stopPropagation();
                                            clickEvent.preventDefault();

                                        }}
                                    />
                                    <UncontrolledPopover
                                        placement="top"
                                        target={ `gl-${ question.id }` }
                                        trigger="legacy"
                                        className={"question-guide-lines-popover"}
                                    >
                                        <PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
                                        <PopoverBody>
                                            <div>
                                                {
                                                    (!!question.questionGuidelines)
                                                        ? question.questionGuidelines.it
                                                        : ""
                                                }
                                            </div>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                    */}


                                    {
                                        (!!question.open)
                                            ? (
                                                <i
                                                    className={"mdi mdi-minus-box-multiple font-18 accordion-arrow cursor-pointer "+ colorPolicy.textClass}
                                                    onClick={clickEvent => {

                                                        let treeTmp = cloneObject(this.state.tree);
                                                        let questionPath = getQuestionPath(treeTmp, question.id);
                                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                                        nodeTmp.open = false;

                                                        this.setState({
                                                            ...this.state,
                                                            tree: treeTmp
                                                        });


                                                    }}
                                                />
                                            )
                                            : (
                                                <i
                                                    className={"mdi mdi-plus-box-multiple font-18 accordion-arrow cursor-pointer "+ colorPolicy.textClass}
                                                    onClick={clickEvent => {

                                                        let treeTmp = cloneObject(this.state.tree);
                                                        let questionPath = getQuestionPath(treeTmp, question.id);
                                                        let nodeTmp = getQuestionTreeNodeByPath(treeTmp, questionPath);

                                                        nodeTmp.open = true;

                                                        this.setState({
                                                            ...this.state,
                                                            tree: treeTmp
                                                        });


                                                    }}
                                                />
                                            )
                                    }


                                </div>


                            </CardHeader>



                            {/*<UncontrolledCollapse defaultOpen={ !!question.open } toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>*/}
                            <Collapse isOpen={!!question.open}
                                toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>
                                <CardBody className={"answer-body"}>


                                    <div className={"answer-body-header"}>




                                        <div className={"answer-body-content"}>

                                            {/*
                                            <code>question data</code>
                                            <pre>{ beautify( question ) }</pre>
                                            */}

                                            <div className={"answer-body-content-choiches"}>

                                                {

                                                    !!question.isOpenAnswer
                                                        ? this.buildOpenAnswers( question )
                                                        : (
                                                            question.choiches

                                                                // TODO da gestire in futuro nella modifica delle scelte
                                                                // .sort((x, y) => {
                                                                //     if (x.ordering < y.ordering) return -1;
                                                                //     return 1;
                                                                // })


                                                                .sort((x, y) => {
                                                                    if(x.choiche_id < y.choiche_id) return -1;
                                                                    return 1;
                                                                })

                                                                .map((choiche, choicheIndex) => {

                                                                    /*
                                                                    {
                                                                        choiche_id: 5,
                                                                        choiche_name: "esiste (specificare)",
                                                                        isRequiredAttachment: false,
                                                                        isRequiredTextBox: true,
                                                                        ordering: 1,
                                                                        score: 0,
                                                                        isOpeningQuestions: 0
                                                                    }
                                                                    */


                                                                    // ;

                                                                    // dati nel sistema
                                                                    let checkedChoiche = ((!!choiche.answers && choiche.answers.length > 0) || !!choiche.checked);
                                                                    let checkedSingleChoiche = ((!!choiche.answers && choiche.answers.length > 0 && choiche.answers[0].choiche_id === choiche.choiche_id));

                                                                    let choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
                                                                    let treeTmp = cloneObject(this.state.tree);
                                                                    let questionPath = [];
                                                                    if (!!isNotEmptyArray(question.path)) questionPath = question.path;
                                                                    else questionPath = getQuestionPath(treeTmp, question.id);

                                                                    let questionTreeNode = getQuestionTreeNodeByPath(treeTmp, questionPath);
                                                                    let refreshedChoiche = null;

                                                                    if (!!!questionTreeNode) {
                                                                        warninglog("Attenzione al path della question");
                                                                        showlog(question);
                                                                        showlog(questionPath);
                                                                        // ;
                                                                    } else {
                                                                        refreshedChoiche =
                                                                            questionTreeNode.choiches
                                                                                .filter(ch => ch.choiche_id === choiche.choiche_id)
                                                                                .reduce(getLastElementOr, null)
                                                                        ;
                                                                    }


                                                                    return (
                                                                        <div key={choicheIndex} className={"sirating-admin-choiche-area"}>

                                                                            {/*<Form>*/}

                                                                            <div>


                                                                                {
                                                                                    (!!question.isMultipleAnswer)
                                                                                        ? ( // MULTIPLE ANSWER --------------------------------------------------------------------------------------
                                                                                            <FormGroup className={"choiche-question"}>
                                                                                                <CustomInput
                                                                                                    type="checkbox"
                                                                                                    name={"reason-" + choiche.choiche_id}
                                                                                                    id={"reason-" + choiche.choiche_id}
                                                                                                    label={ choiche.choiche_name[0].charAt(0).toUpperCase() + choiche.choiche_name[0].slice(1) }
                                                                                                    className={

                                                                                                        (!!choiche.checked)
                                                                                                            ? QUESTION_TEXT_COLOR_POLICY.edit.class
                                                                                                            : (
                                                                                                                (!!choiche.answers.reduce(getLastElementOr, null))
                                                                                                                    ? QUESTION_TEXT_COLOR_POLICY.complete.class
                                                                                                                    : ""
                                                                                                            )


                                                                                                    }
                                                                                                    onClick={clickEvent => {

                                                                                                        if (!checkedChoiche) {
                                                                                                            // da attivare
                                                                                                            refreshedChoiche.checked = true;
                                                                                                            refreshedChoiche.active = true;

                                                                                                            /*
                                                                                                            refreshedChoiche.answers.push({
                                                                                                                choiche_id: refreshedChoiche.choiche_id,
                                                                                                                questions_structure_id: question.questionsStructureId,
                                                                                                                score: refreshedChoiche.score
                                                                                                            });
                                                                                                             */

                                                                                                            refreshedChoiche.answers =
                                                                                                                [
                                                                                                                    ...refreshedChoiche.answers.filter( (a, i) => i < choicheIndex),
                                                                                                                    {
                                                                                                                        choiche_id: refreshedChoiche.choiche_id,
                                                                                                                        questions_structure_id: question.questionsStructureId,
                                                                                                                        score: refreshedChoiche.score
                                                                                                                    },
                                                                                                                    ...refreshedChoiche.answers.filter( (a, i) => i > choicheIndex)
                                                                                                                ]
                                                                                                            ;

                                                                                                            if (!!choicheDOM) {
                                                                                                                choicheDOM.checked = true;
                                                                                                            }



                                                                                                            // controllo disattivazione altre scelte
                                                                                                            /*
                                                                                                            La scelta si abilita
                                                                                                            Se la scelta corrente ha isNoneOfTheAbove=true
                                                                                                                si disattivano tutte le altre scelte
                                                                                                            Se la scelta corrente ha isNoneOfTheAbove=false
                                                                                                                si controllano tutte le altre scelte
                                                                                                                se una scelta ha isNoneOfTheAbove=true
                                                                                                                    la si disabilita
                                                                                                             */
                                                                                                            if( !!refreshedChoiche.isNoneOfTheAbove ) {
                                                                                                                questionTreeNode.choiches
                                                                                                                    .filter( ch => ch.choiche_id !== refreshedChoiche.choiche_id )
                                                                                                                    .map(ch => {
                                                                                                                        let dom = document.getElementById("reason-" + ch.choiche_id);
                                                                                                                        if (!!dom) {
                                                                                                                            dom.checked = false;
                                                                                                                        }

                                                                                                                        // active
                                                                                                                        ch.active = false;
                                                                                                                        ch.checked = false;

                                                                                                                        // text
                                                                                                                        delete ch.text;

                                                                                                                        // attachment
                                                                                                                        delete ch.attachment;

                                                                                                                        // answer
                                                                                                                        ch.answers = [];

                                                                                                                    })
                                                                                                                ;
                                                                                                            }
                                                                                                            else {
                                                                                                                questionTreeNode.choiches
                                                                                                                    .filter( ch => !!ch.isNoneOfTheAbove )
                                                                                                                    .map(ch => {
                                                                                                                        let dom = document.getElementById("reason-" + ch.choiche_id);
                                                                                                                        if (!!dom) {
                                                                                                                            dom.checked = false;
                                                                                                                        }

                                                                                                                        // active
                                                                                                                        ch.active = false;
                                                                                                                        ch.checked = false;

                                                                                                                        // text
                                                                                                                        delete ch.text;

                                                                                                                        // attachment
                                                                                                                        delete ch.attachment;

                                                                                                                        // answer
                                                                                                                        ch.answers = [];

                                                                                                                    })
                                                                                                                ;
                                                                                                            }






                                                                                                        } else {
                                                                                                            // da disattivare
                                                                                                            refreshedChoiche.checked = false;
                                                                                                            refreshedChoiche.active = false;
                                                                                                            // delete refreshedChoiche.answers;
                                                                                                            refreshedChoiche.answers =
                                                                                                                refreshedChoiche.answers
                                                                                                                    .filter(ans => ans.choiche_id !== choiche.choiche_id)
                                                                                                            ;
                                                                                                            if (!!choicheDOM) {
                                                                                                                choicheDOM.checked = false;
                                                                                                            }
                                                                                                        }


                                                                                                        this.setState({
                                                                                                                ...this.state,
                                                                                                                tree: treeTmp,
                                                                                                                editAnswer: [
                                                                                                                    ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                    question.id
                                                                                                                ],
                                                                                                                subQuestionId: choiche.choiche_id,
                                                                                                                // subQuestionWait: choiche.choiche_id
                                                                                                            },

                                                                                                            () => {


                                                                                                            }
                                                                                                        );

                                                                                                    }}
                                                                                                    defaultChecked={!!checkedChoiche}
                                                                                                    disabled={this.isRatingClosed()}
                                                                                                />
                                                                                            </FormGroup>

                                                                                        )
                                                                                        : ( // SINGLE ANSWER ------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                            <FormGroup className={"choiche-question"}>
                                                                                                <CustomInput
                                                                                                    type="radio"
                                                                                                    name={"reason-" + choiche.choiche_id}
                                                                                                    id={"reason-" + choiche.choiche_id}
                                                                                                    label={ choiche.choiche_name[0].charAt(0).toUpperCase() + choiche.choiche_name[0].slice(1) }
                                                                                                    disabled={this.isRatingClosed()}
                                                                                                    onClick={clickEvent => {

                                                                                                        /**
                                                                                                         * é necessario perchè lato server le sotto-domande vengono cancellate,
                                                                                                         * con questo flag evitiamo di cancellare delle sotto-domande che fanno parte
                                                                                                         * del sistema
                                                                                                         *
                                                                                                         * @type {boolean}
                                                                                                         */
                                                                                                        questionTreeNode.isChangedAnswer = !choiche.active;
                                                                                                        // questionTreeNode.isChangedAnswer = ( ( refreshedChoiche.choiche_id === choiche.choiche_id ) && !choiche.active );


                                                                                                        showlog("controllo bugfix 3");
                                                                                                        beautifyLog(questionTreeNode.choiches);
                                                                                                        // debugger;


                                                                                                        if (!checkedSingleChoiche) {


                                                                                                            questionTreeNode.choiches
                                                                                                                .map(ch => {
                                                                                                                    ch.previousChoicheAnswer = (!!ch.answers ? ch.answers[0] : null);
                                                                                                                })
                                                                                                            ;


                                                                                                            // reset di tutte le choiches
                                                                                                            questionTreeNode.choiches =
                                                                                                                questionTreeNode.choiches
                                                                                                                    .map(ch => {
                                                                                                                        if (!!choicheDOM) {
                                                                                                                            choicheDOM.checked = false;
                                                                                                                        }

                                                                                                                        // active
                                                                                                                        ch.active = false;
                                                                                                                        ch.checked = false;

                                                                                                                        // text
                                                                                                                        delete ch.text;

                                                                                                                        // attachment
                                                                                                                        delete ch.attachment;

                                                                                                                        // answer
                                                                                                                        ch.answers = [];

                                                                                                                        return ch;
                                                                                                                    })
                                                                                                            ;
                                                                                                            // refreshedChoiche.active = (clickEvent.target.value === "on" ? false : true);
                                                                                                            refreshedChoiche.checked = true;
                                                                                                            refreshedChoiche.active = true;


                                                                                                            // da inserire dopo
                                                                                                            // if( !!choiche.isRequiredTextBox ) answer.motivation =

                                                                                                            refreshedChoiche.answers = [{
                                                                                                                choiche_id: refreshedChoiche.choiche_id,
                                                                                                                questions_structure_id: question.questionsStructureId
                                                                                                            }];


                                                                                                            questionTreeNode.choiches = [
                                                                                                                ...questionTreeNode.choiches.filter(ch => ch.choiche_id !== refreshedChoiche.choiche_id),
                                                                                                                refreshedChoiche
                                                                                                            ];


                                                                                                            // questionTreeNode.isChangedAnswer = false;


                                                                                                            // questionTreeNode.child = [{tmp: true}];
                                                                                                            refreshedChoiche.inWait = true;
                                                                                                            choiche.inWait = true;



                                                                                                            this.setState({
                                                                                                                    ...this.state,
                                                                                                                    tree: treeTmp,
                                                                                                                    editAnswer: [
                                                                                                                        ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                        question.id
                                                                                                                    ],
                                                                                                                    subQuestionId: choiche.choiche_id,
                                                                                                                    subQuestionWait: choiche.choiche_id
                                                                                                                },

                                                                                                                () => {


                                                                                                                    showlog("chiamata 1", !!choiche.isOpeningQuestions);


                                                                                                                    // if(!!choiche.isOpeningQuestions && (!!!isNotEmptyArray(question.child) || (question.child.length === 1 && !!question.child[0].tmp ))) {
                                                                                                                    if (!!choiche.isOpeningQuestions) {

                                                                                                                        // ;
                                                                                                                        // chiamata al click
                                                                                                                        loadAnswers(this.getRatingId(), this.state.activeTab || 1, choiche.choiche_id)

                                                                                                                            // editTree ----------------------------------------------
                                                                                                                            .then(subQuestions => {



                                                                                                                                if (!!!isNotEmptyArray(subQuestions)) {
                                                                                                                                    // non arrivano le sotto-domande
                                                                                                                                    // si verifica se c'è un errore lato server

                                                                                                                                    questionTreeNode.child = [{ status: "server-error" }]
                                                                                                                                    return Promise.reject();
                                                                                                                                }

                                                                                                                                return (

                                                                                                                                    convertSIRatingSubQuestionTableToSubQuestionChild(subQuestions, getQuestionPath(treeTmp, question.id))
                                                                                                                                        .then(nodes => {
                                                                                                                                            questionTreeNode.open = true;
                                                                                                                                            questionTreeNode.child =
                                                                                                                                                nodes
                                                                                                                                                    .map(qc => {
                                                                                                                                                        qc.open = true;
                                                                                                                                                        return qc;
                                                                                                                                                    })
                                                                                                                                            ;



                                                                                                                                            return questionTreeNode;
                                                                                                                                        })
                                                                                                                                        .catch(e => {
                                                                                                                                            errorlog("errore",e);
                                                                                                                                            return Promise.reject(e);
                                                                                                                                        })

                                                                                                                                );

                                                                                                                            })


                                                                                                                            // re-computing all questions numbers ----------------------
                                                                                                                            .then(questionTreeNode => {


                                                                                                                                showlog("sotto-domande: controllami");
                                                                                                                                showlog(questionTreeNode);



                                                                                                                                return (
                                                                                                                                    loadProgress(this.getRatingId())
                                                                                                                                        .then(result => {



                                                                                                                                            return ({
                                                                                                                                                questionTreeNode: questionTreeNode,
                                                                                                                                                metaData: { info: result }
                                                                                                                                            })
                                                                                                                                        })
                                                                                                                                        .catch(e => {
                                                                                                                                            errorlog("errore",e);
                                                                                                                                            return Promise.reject(e);
                                                                                                                                        })
                                                                                                                                );

                                                                                                                            })

                                                                                                                            // finish ---------------------------------------------------
                                                                                                                            .then(({
                                                                                                                                       questionTreeNode,
                                                                                                                                       metaData
                                                                                                                                   }) => {

                                                                                                                                choiche.inWait = false;
                                                                                                                                refreshedChoiche.inWait = false;

                                                                                                                                cloneOpenNodes( this.state.tree, treeTmp );
                                                                                                                                // showlog( this.state.tree );
                                                                                                                                // showlog( treeTmp );
                                                                                                                                // debugger;



                                                                                                                                this.setState({
                                                                                                                                    ...this.state,
                                                                                                                                    tree: treeTmp,
                                                                                                                                    subQuestionWait: null,
                                                                                                                                    metaData: Object.assign({}, this.state.metaData, metaData)
                                                                                                                                })
                                                                                                                            })
                                                                                                                            .catch(e => {
                                                                                                                                errorlog("errore",e);
                                                                                                                                /*
                                                                                                                                this.setState({
                                                                                                                                    ...this.state,
                                                                                                                                    subQuestionWait: null
                                                                                                                                })
                                                                                                                                */
                                                                                                                            })

                                                                                                                    } else {

                                                                                                                        delete questionTreeNode.child;
                                                                                                                        this.setState({
                                                                                                                            ...this.state,
                                                                                                                            tree: treeTmp
                                                                                                                        })


                                                                                                                    }


                                                                                                                }
                                                                                                            );
                                                                                                        } else {

                                                                                                            // questionTreeNode.isChangedAnswer = true;

                                                                                                        }


                                                                                                        showlog("controllo cambio risposta");
                                                                                                        showlog(questionTreeNode);
                                                                                                        showlog(question);
                                                                                                        // ;


                                                                                                        let newChoicheId =
                                                                                                            questionTreeNode.choiches
                                                                                                                .filter( ch => (ch.answers.length > 0) )
                                                                                                                .map( ch => ch.choiche_id )
                                                                                                                .reduce( getLastElementOr, null )
                                                                                                        ;



                                                                                                    }}
                                                                                                    checked={
                                                                                                        !!checkedSingleChoiche
                                                                                                        // !!choiche.checked
                                                                                                    }
                                                                                                    //disabled={this.isRatingClosed()}
                                                                                                />
                                                                                            </FormGroup>

                                                                                        )
                                                                                }


                                                                                { // TEXTBOX -------------------------------------------------------------------------------------------------------------------------------------------
                                                                                    (!!choiche.isRequiredTextBox /*&& !!choiche.active*/ && (!!checkedSingleChoiche || !!checkedChoiche))
                                                                                        ? (

                                                                                            <FormGroup
                                                                                                className={"choiche-question-reason-box"}>

                                                                                                <Row>
                                                                                                    <Col sm={3}>
                                                                                                        <Label for={""}>Motivazione</Label>
                                                                                                        <Label
                                                                                                            className={"material-themes-info-fathername single-um-label"}>Fornire
                                                                                                            una motivazione per la seguente
                                                                                                            scelta</Label>
                                                                                                    </Col>
                                                                                                    <Col sm={6}>
                                                                                                        <Input
                                                                                                            className={"sector-question-text standard-text-area audit-choiche-question-textarea"}
                                                                                                            type="textarea"
                                                                                                            row={64}
                                                                                                            name={"reason-" + choiche.choiche_id}
                                                                                                            id={""}
                                                                                                            /*
                                                                                                            onChange={changeEvent => {

                                                                                                                let editQuestion = {
                                                                                                                    id: question.id,
                                                                                                                    choiches: [{
                                                                                                                        ...choiche,
                                                                                                                        text: changeEvent.target.value
                                                                                                                    }]
                                                                                                                };


                                                                                                                refreshedChoiche.answers =
                                                                                                                    refreshedChoiche.answers
                                                                                                                        .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                        .map( ans => ({
                                                                                                                            ...ans,
                                                                                                                            motivation: changeEvent.target.value
                                                                                                                        }))
                                                                                                                ;



                                                                                                                // ;
                                                                                                                this.setState({
                                                                                                                    ...this.state,
                                                                                                                    tree: treeTmp,
                                                                                                                    clientQuestions: [
                                                                                                                        ...this.state.clientQuestions.filter(cq => cq.id !== editQuestion.id),
                                                                                                                        editQuestion
                                                                                                                    ],
                                                                                                                    editAnswer: [
                                                                                                                        ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                        question.id
                                                                                                                    ]
                                                                                                                },
                                                                                                                    () => {
                                                                                                                        // ;
                                                                                                                    }
                                                                                                                )
                                                                                                            }}
                                                                                                            */
                                                                                                            defaultValue={
                                                                                                                choiche.answers
                                                                                                                    .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                    .map(ans => ans.motivation)
                                                                                                                    .reduce(getLastElementOr, "")
                                                                                                            }
                                                                                                            required
                                                                                                            disabled={this.isRatingClosed()}
                                                                                                            onBlur={blurEvent => {
                                                                                                                showlog(blurEvent.target.value);
                                                                                                                // ;


                                                                                                                let editQuestion = {
                                                                                                                    id: question.id,
                                                                                                                    choiches: [{
                                                                                                                        ...choiche,
                                                                                                                        text: blurEvent.target.value
                                                                                                                    }]
                                                                                                                };


                                                                                                                refreshedChoiche.answers =
                                                                                                                    refreshedChoiche.answers
                                                                                                                        .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                        .map(ans => ({
                                                                                                                            ...ans,
                                                                                                                            motivation: blurEvent.target.value
                                                                                                                        }))
                                                                                                                ;


                                                                                                                // ;
                                                                                                                this.setState({
                                                                                                                        ...this.state,
                                                                                                                        tree: treeTmp,
                                                                                                                        clientQuestions: [
                                                                                                                            ...this.state.clientQuestions.filter(cq => cq.id !== editQuestion.id),
                                                                                                                            editQuestion
                                                                                                                        ],
                                                                                                                        editAnswer: [
                                                                                                                            ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                            question.id
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    () => {
                                                                                                                        // ;
                                                                                                                    }
                                                                                                                )


                                                                                                            }}
                                                                                                        />

                                                                                                    </Col>



                                                                                                </Row>

                                                                                            </FormGroup>

                                                                                        )
                                                                                        : null
                                                                                }


                                                                                { // SUB-QUESTION -----------------------------------------------------------------------------------------------------------------------------------------

                                                                                    (!!choiche.isOpeningQuestions)
                                                                                        ? (
                                                                                            (!!this.state.subQuestionWait && this.state.subQuestionWait === choiche.choiche_id)
                                                                                                ? (
                                                                                                    <WaitingOrNodataMessage
                                                                                                        waiting={true}
                                                                                                        nodataMessage={"in caricamento..."}
                                                                                                    />
                                                                                                )
                                                                                                : (
                                                                                                    // (!!choiche.active)
                                                                                                    (!!checkedSingleChoiche)
                                                                                                        // (!!choiche.active || !!checkedSingleChoiche)
                                                                                                        ? (
                                                                                                            <span
                                                                                                                className={"big-block-marginer shadow"}>
                                                                                                                {this.buildQuestions(question.child || [], true)}
                                                                                                            </span>
                                                                                                        )
                                                                                                        : null
                                                                                                )
                                                                                        )
                                                                                        : null
                                                                                }


                                                                                {/* // SCORE ------------------------------------------------------------------------------------------------------------------------------------------------

                                                                        */}


                                                                                {
                                                                                    (!!hasPermission(permissions.EDIT_ANSWER_SCORE) && !!checkedChoiche)
                                                                                        ? (
                                                                                            <React.Fragment
                                                                                                key={"question-score-" + question.id}>
                                                                                                <Label
                                                                                                    className={"audit-score-edit-label"}>Punteggio</Label>
                                                                                                <Input
                                                                                                    className={"choiche-question-reason-box audit-score-edit"}
                                                                                                    type="number"
                                                                                                    name={"choiche-question-score-" + choiche.choiche_id}
                                                                                                    id={"choiche-question-score-" + choiche.choiche_id}
                                                                                                    value={


                                                                                                        (
                                                                                                            choiche.answers
                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                .map(ans => ans.score)
                                                                                                                .reduce(getLastElementOr, null) !== null
                                                                                                            &&
                                                                                                            choiche.answers
                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                .map(ans => ans.score)
                                                                                                                .reduce(getLastElementOr, null) !== undefined

                                                                                                                ? (
                                                                                                                    ""+ (choiche.answers
                                                                                                                        .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                        .map(ans => ans.score)
                                                                                                                        .reduce(getLastElementOr, "0"))
                                                                                                                )
                                                                                                                : null
                                                                                                        )



                                                                                                        || (!!choiche.previousChoicheAnswer && !isNaN(choiche.previousChoicheAnswer.score) && choiche.previousChoicheAnswer.score)
                                                                                                        || parseFloat(choiche.score)
                                                                                                    }
                                                                                                    onChange={changeEvent => {

                                                                                                        let editQuestion = {
                                                                                                            id: question.id,
                                                                                                            choiches: [{
                                                                                                                ...choiche,
                                                                                                                score: parseFloat(changeEvent.target.value)
                                                                                                            }]
                                                                                                        };


                                                                                                        refreshedChoiche.answers =
                                                                                                            refreshedChoiche.answers
                                                                                                                .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                .map(ans => ({
                                                                                                                    ...ans,
                                                                                                                    // score: parseFloat(changeEvent.target.value)
                                                                                                                    score: changeEvent.target.value
                                                                                                                }))
                                                                                                        ;


                                                                                                        // ;
                                                                                                        this.setState({
                                                                                                                ...this.state,
                                                                                                                tree: treeTmp,
                                                                                                                clientQuestions: [
                                                                                                                    ...this.state.clientQuestions.filter(cq => cq.id !== editQuestion.id),
                                                                                                                    editQuestion
                                                                                                                ],
                                                                                                                editAnswer: [
                                                                                                                    ...this.state.editAnswer.filter(ea => ea !== question.id),
                                                                                                                    question.id
                                                                                                                ]
                                                                                                            },
                                                                                                            () => {
                                                                                                                // ;
                                                                                                            }
                                                                                                        )
                                                                                                    }}
                                                                                                    required
                                                                                                    disabled={this.isRatingClosed()}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        )
                                                                                        : (
                                                                                            ((!hasPermission(permissions.EDIT_ANSWER_SCORE) || !checkedChoiche) && !!hasPermission( permissions.SEE_RATING_ANSWER_SCORE ))
                                                                                                ? <span className={"choiche-question-score choiche-question-score-top"}>(&nbsp;&nbsp;{

                                                                                                    (
                                                                                                        choiche.answers
                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                            .map(ans => ans.score)
                                                                                                            .reduce(getLastElementOr, null) !== null
                                                                                                        &&
                                                                                                        choiche.answers
                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                            .map(ans => ans.score)
                                                                                                            .reduce(getLastElementOr, null) !== undefined

                                                                                                            ? (
                                                                                                                ""+ (choiche.answers
                                                                                                                    .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                    .map(ans => ans.score)
                                                                                                                    .reduce(getLastElementOr, "0"))
                                                                                                            )
                                                                                                            : null
                                                                                                    )



                                                                                                    || (!!choiche.previousChoicheAnswer && !isNaN(choiche.previousChoicheAnswer.score) && choiche.previousChoicheAnswer.score)
                                                                                                    || parseFloat(choiche.score)


                                                                                                }&nbsp;&nbsp;)</span>
                                                                                                : (
                                                                                                    !!hasPermission( permissions.SEE_RATING_ANSWER_SCORE )
                                                                                                        ? (
                                                                                                            <span className={"choiche-question-score choiche-question-score-top"}>
                                                                                                        &nbsp;&nbsp;(&nbsp;&nbsp;
                                                                                                                {
                                                                                                                    (
                                                                                                                        choiche.answers
                                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                            .map(ans => ans.score)
                                                                                                                            .reduce(getLastElementOr, null) !== null
                                                                                                                        &&
                                                                                                                        choiche.answers
                                                                                                                            .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                            .map(ans => ans.score)
                                                                                                                            .reduce(getLastElementOr, null) !== undefined

                                                                                                                            ? (
                                                                                                                                ""+ (choiche.answers
                                                                                                                                    .filter(ans => ans.choiche_id === choiche.choiche_id)
                                                                                                                                    .map(ans => ans.score)
                                                                                                                                    .reduce(getLastElementOr, "0"))
                                                                                                                            )
                                                                                                                            : null
                                                                                                                    )

                                                                                                                    || (!!choiche.previousChoicheAnswer && !isNaN(choiche.previousChoicheAnswer.score) && choiche.previousChoicheAnswer.score)
                                                                                                                    || parseFloat(choiche.score)
                                                                                                                }
                                                                                                                &nbsp;&nbsp;)&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                        )
                                                                                                        : null
                                                                                                )
                                                                                        )

                                                                                }



                                                                            </div>
                                                                            {/*</Form>*/}
                                                                        </div>

                                                                    );
                                                                })
                                                        ) // choices



                                                }
                                            </div>


                                        </div>




                                        {
                                            (!!question.isOpenAnswer)
                                                ? ( // save button for open answer ---------------------------------------------------------------------------------
                                                    <ActionButton
                                                        visible={ !this.isRatingClosed() }
                                                        promise={ () => {
                                                            let answer = (
                                                                !!document.getElementById(`openAnswerValue-${question.id}`)
                                                                    ? document.getElementById(`openAnswerValue-${question.id}`).value
                                                                    : ""
                                                            );


                                                            // question in wait
                                                            showlog("controllo domanda");
                                                            showlog( question );



                                                            // controllo sotto-domanda
                                                            if( !!question.fatherQuestionId ) {

                                                                let father = getQuestionTreeNodeByPath(
                                                                    cloneObject( this.state.tree ),
                                                                    [
                                                                        ...question.path
                                                                            .filter( (p, i) => i < ( question.path.length -2 ) ),
                                                                        question.fatherQuestionId
                                                                    ]
                                                                );

                                                                let currentChoiche =
                                                                    father.choiches
                                                                        .filter( ch => !!isNotEmptyArray( ch.answers ) )
                                                                        .reduce( getLastElementOr, null )
                                                                ;

                                                                showlog("father choiche");
                                                                showlog( currentChoiche );
                                                                showlog("padre");
                                                                showlog( father );


                                                                let fatherAnswer = document.getElementById("choiche-question-score-" + currentChoiche.choiche_id);
                                                                if (!!fatherAnswer && typeof fatherAnswer.value !== "undefined") fatherAnswer = fatherAnswer.value;
                                                                else {
                                                                    fatherAnswer =
                                                                        father.choiches
                                                                            .filter(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                            .map(ch => {
                                                                                if( !!ch.answers && ch.answers.length > 0 ) {
                                                                                    if( typeof ch.answers[0].score !== "undefined" ) {
                                                                                        return ch.answers[0].score;
                                                                                    }
                                                                                }
                                                                                return ch.score;
                                                                            })
                                                                            .reduce(getLastElementOr,  0)
                                                                    ;
                                                                }

                                                                return (
                                                                    saveAnswer( father, this.getRatingId(), fatherAnswer )
                                                                        .then( result => {


                                                                            return (
                                                                                new Promise((s, f) => {

                                                                                    let refreshedTree = cloneObject(this.state.tree);
                                                                                    let refreshedQuestion =
                                                                                        getQuestionTreeNodeByPath(
                                                                                            refreshedTree,   // albero
                                                                                            father.path
                                                                                        )
                                                                                    ;
                                                                                    refreshedQuestion.status = QUESTION_STATUS.finished.id;

                                                                                    this.setState({
                                                                                            ...this.state,
                                                                                            tree: refreshedTree,
                                                                                            editAnswer: this.state.editAnswer.filter( ea => ea !== father.id )
                                                                                        },
                                                                                        () => {

                                                                                            s (
                                                                                                Promise.all(
                                                                                                    father.child
                                                                                                        .map( sq => {
                                                                                                            let answer = null;
                                                                                                            if( !!sq.isOpenAnswer ) {
                                                                                                                // le sotto-domande sono a risposta aperta
                                                                                                                answer = sq.scoreOpenQuestion;
                                                                                                                if(!!document.getElementById(`openAnswerValue-${sq.id}`)) {
                                                                                                                    answer = document.getElementById(`openAnswerValue-${sq.id}`).value;
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                // le sotto-domande hanno le scelte
                                                                                                            }
                                                                                                            return saveSiScoringOpenAnswer( this.getRatingId(), sq.id, sq.questionsStructureId, answer )
                                                                                                        })
                                                                                                )
                                                                                                    .then( resultList => {

                                                                                                        return (
                                                                                                            new Promise((s, f) => {

                                                                                                                father.child
                                                                                                                    .map( sq => {
                                                                                                                        let refreshedSubQuestion =
                                                                                                                            getQuestionTreeNodeByPath(
                                                                                                                                refreshedTree,   // albero
                                                                                                                                sq.path
                                                                                                                            )
                                                                                                                        ;
                                                                                                                        refreshedSubQuestion.status = QUESTION_STATUS.finished.id;
                                                                                                                        // return refreshedSubQuestion
                                                                                                                    })
                                                                                                                ;


                                                                                                                this.setState({
                                                                                                                        ...this.state,
                                                                                                                        tree: refreshedTree,
                                                                                                                        editAnswer: this.state.editAnswer.filter( ea => !father.child.map( sq => sq.id ).includes( ea ) )
                                                                                                                    },
                                                                                                                    () => {
                                                                                                                        s();
                                                                                                                    }
                                                                                                                )

                                                                                                            })
                                                                                                        )



                                                                                                        return true;
                                                                                                    })
                                                                                                    .catch(e => Promise.reject( e ))
                                                                                            )

                                                                                        }
                                                                                    )

                                                                                })
                                                                            );




                                                                        })
                                                                        .catch(e => {
                                                                            errorlog("20220708", e);
                                                                        })
                                                                )

                                                            }
                                                            else {

                                                                // domanda normale
                                                                return (
                                                                    Promise.all([
                                                                        new Promise((success, fail) => {
                                                                            this.setState({
                                                                                    ...this.state,
                                                                                    waitingQuestions: [...this.state.waitingQuestions, question.id ]
                                                                                },
                                                                                () => {
                                                                                    success()
                                                                                })
                                                                            ;
                                                                        }),
                                                                        saveSiScoringOpenAnswer( this.getRatingId(), question.id, question.questionsStructureId, answer )
                                                                    ])
                                                                        .then( resultList => {
                                                                            return resultList[1];
                                                                        })
                                                                        .catch( e => Promise.reject( e ) )
                                                                )
                                                            }


                                                        }}
                                                        onResult={ result => {


                                                            let refreshedTree = cloneObject(this.state.tree);
                                                            let refreshedQuestion =
                                                                getQuestionTreeNodeByPath(
                                                                    refreshedTree,   // albero
                                                                    question.path
                                                                )
                                                            ;
                                                            refreshedQuestion.status = QUESTION_STATUS.finished.id;

                                                            // controllo tutte le domande risposte
                                                            let isRatingCompleted = true;
                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            isRatingCompleted &= th.questions.every( q => q.status === QUESTION_STATUS.finished.id )
                                                                        })
                                                                })
                                                            ;

                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            showlog( tab.id, th.id, th.questions.map( q => q.status ), th.questions.every( q => q.status === QUESTION_STATUS.finished.id ));
                                                                        })
                                                                })
                                                            ;



                                                            loadProgress(this.getRatingId())
                                                                .then(result => {



                                                                    this.setState({
                                                                            ...this.state,
                                                                            // tree: refreshedTree,
                                                                            // editAnswer: editAnswwers,
                                                                            // waitingQuestions: this.state.waitingQuestions.filter( wq => wq !== question.id ),
                                                                            editAnswer: this.state.editAnswer.filter( ea => ea !== refreshedQuestion.id ),
                                                                            // waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                            metaData: {
                                                                                ...this.state.metaData,
                                                                                info: result
                                                                            }
                                                                        },
                                                                        () => {
                                                                            showlog("edit answers vuoto?", !isNotEmptyArray( this.state.editAnswer ));
                                                                            showlog("domande con risposte");
                                                                            showlog(
                                                                                question.id,
                                                                                question.choiches
                                                                                    .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                                                    .filter( onlyNotNull )
                                                                                    .reduce( getLastElementOr, null )
                                                                            )
                                                                            ;


                                                                            if( !!isNotEmptyArray( this.state.waitingQuestions ) ) {
                                                                                // if( !isNotEmptyArray( this.state.editAnswer ) ) {
                                                                                // non ci sono più domande in edit



                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                                })

                                                                                /*
                                                                                this.loadData( cloneObject( this.state.tree ) )
                                                                                    .then( res => {
                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                                        })
                                                                                    })
                                                                                    .catch(e => {
                                                                                        errorlog("load adata", e);
                                                                                    })
                                                                                ;
                                                                                */
                                                                            }
                                                                        }
                                                                    )

                                                                })
                                                                .catch(e => {
                                                                    errorlog("errore",e);
                                                                    return Promise.reject(e);
                                                                })
                                                            ;


                                                        }}
                                                        onError={ e => {
                                                            errorlog("Salvataggio domande risposta aperta", e);
                                                            let eMessage = extractErrorMessage( e );
                                                            this.setState({
                                                                ...this.state,
                                                                // errorMessage: (!!e.error ? errorMessage.GENERIC_FRONTEND_ERROR_MESSAGE.msg : e),
                                                                errorMessage: eMessage,
                                                                waitingQuestions: this.state.waitingQuestions.filter( wq => wq === question.id )
                                                            })

                                                        }}
                                                        className={"question-save-button"}
                                                    />
                                                )
                                                : ( // save button for choiches ---------------------------------------------------------------------------------
                                                    <ActionButton
                                                        buttonText={translate("Save")}
                                                        visible={

                                                            (
                                                                !!question.choiches
                                                                    .map(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                    .filter(onlyNotNull)
                                                                    .reduce(getLastElementOr, null)

                                                                && !this.isRatingClosed()
                                                            )

                                                        }
                                                        promise={() => {


                                                            return (
                                                                new Promise((succ, fail) => {

                                                                    this.setState({
                                                                            ...this.state,
                                                                            waitingQuestions: [...this.state.waitingQuestions, question.id ]
                                                                        },
                                                                        () => {

                                                                            // nel caso di scelta multipla ----------------------------------------------------------------------------------------------------------------
                                                                            let ncAnswer = null;
                                                                            if( !!question.isMultipleAnswer ) {
                                                                                let currentChoiches =
                                                                                    question.choiches
                                                                                        .filter(ch => !!(ch.answers.reduce(getLastElementOr, null)))
                                                                                        .map( answeredCh => {


                                                                                            let scoreAnswer = document.getElementById("choiche-question-score-" + answeredCh.choiche_id);
                                                                                            if (!!scoreAnswer && typeof scoreAnswer.value !== "undefined") scoreAnswer = scoreAnswer.value;
                                                                                            else {

                                                                                                // nel caso non sia presente l'input
                                                                                                // scoreAnswer = answeredCh.score;
                                                                                                scoreAnswer =
                                                                                                    question.choiches
                                                                                                        .filter(ch => (ch.choiche_id === answeredCh.choiche_id))
                                                                                                        .map(ch => {
                                                                                                            if( !!ch.answers && ch.answers.length > 0 ) {
                                                                                                                if( typeof ch.answers[0].score !== "undefined" ) {
                                                                                                                    return ch.answers[0].score;
                                                                                                                }
                                                                                                            }
                                                                                                            return answeredCh.score;
                                                                                                        })
                                                                                                        .reduce(getLastElementOr,  0)
                                                                                                ;
                                                                                            }


                                                                                            showlog("answeredCh");
                                                                                            showlog(answeredCh);
                                                                                            // debugger;

                                                                                            answeredCh.answers[0].score = scoreAnswer;  // aggiustamento
                                                                                            return scoreAnswer;
                                                                                        })
                                                                                ;


                                                                            }
                                                                            else {
                                                                                let currentChoiche =
                                                                                    question.choiches
                                                                                        .map(ch => (ch.answers.reduce(getLastElementOr, null)))
                                                                                        .filter(onlyNotNull)
                                                                                        .reduce(getLastElementOr, null)
                                                                                ;
                                                                                showlog("choiche id");
                                                                                showlog( currentChoiche )
                                                                                // debugger;


                                                                                // let ncAnswer = document.getElementById("nc-answer_" + question.id);
                                                                                ncAnswer = document.getElementById("choiche-question-score-" + currentChoiche.choiche_id);
                                                                                if (!!ncAnswer && typeof ncAnswer.value !== "undefined") ncAnswer = ncAnswer.value;
                                                                                else {
                                                                                    ncAnswer =
                                                                                        question.choiches
                                                                                            .filter(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                                            // .map(ch => ch.score)
                                                                                            .map(ch => {
                                                                                                if( !!ch.answers && ch.answers.length > 0 ) {
                                                                                                    if( typeof ch.answers[0].score !== "undefined" ) {
                                                                                                        return ch.answers[0].score;
                                                                                                    }
                                                                                                }
                                                                                                return ch.score;
                                                                                            })
                                                                                            .reduce(getLastElementOr,  0)
                                                                                    ;
                                                                                }

                                                                                showlog("ncAnswer");
                                                                                showlog(ncAnswer);
                                                                                showlog( document.getElementById("choiche-question-score-" + currentChoiche.choiche_id) );


                                                                                showlog(
                                                                                    question.choiches
                                                                                        .filter(ch => (!!ch.answers.reduce(getLastElementOr, null)))
                                                                                        .map(ch => ch.score)
                                                                                        .reduce(getLastElementOr,  0)
                                                                                );



                                                                            }



                                                                            // showlog( document.getElementById("choiche-question-score-" + currentChoiche.choiche_id).value );
                                                                            // debugger;

                                                                            // ;
                                                                            if (!!isSubQuestion) {
                                                                                let fatherQuestion = getQuestionTreeNodeByPath(this.state.tree, question.path.filter((p, i) => i < question.path.length - 1));
                                                                                // return processAnswers( fatherQuestion, this.getRatingId() );
                                                                                processAnswers_syncChain(fatherQuestion, this.getRatingId(), ncAnswer)
                                                                                    .then( result => {
                                                                                        succ( result );
                                                                                    })
                                                                                    .catch( e => {
                                                                                        errorlog("process answer sync chain with father", e);
                                                                                        fail( e );
                                                                                    })
                                                                                ;
                                                                            }
                                                                            else {

                                                                                processAnswers_syncChain(question, this.getRatingId(), ncAnswer, true)
                                                                                    .then( result => {
                                                                                        succ( result );
                                                                                    })
                                                                                    .catch( e => {
                                                                                        errorlog("process answer sync chain", e);
                                                                                        fail( e );
                                                                                    })
                                                                                ;

                                                                            }



                                                                        })


                                                                })
                                                                .then( result => {
                                                                    question.status = QUESTION_STATUS.finished.id;
                                                                    return result;
                                                                })
                                                                .catch( e => Promise.reject( e ) )
                                                            );

                                                        }}
                                                        onResult={result => {

                                                            showlog("fine salvataggio domanda con scelta", question.id);
                                                            showlog( result );
                                                            showlog( this.state.editAnswer );
                                                            showlog( this.state.waitingQuestions );
                                                            showlog("\n\n");

                                                            showlog("domande con risposte");
                                                            showlog(
                                                                question.id,
                                                                question.choiches
                                                                    .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                                    .filter( onlyNotNull )
                                                                    .reduce( getLastElementOr, null )
                                                            )
                                                            ;
                                                            //debugger;



                                                            let refreshedTree = cloneObject(this.state.tree);
                                                            let refreshedQuestion =
                                                                getQuestionTreeNodeByPath(
                                                                    refreshedTree,   // albero
                                                                    question.path
                                                                )
                                                            ;
                                                            refreshedQuestion.status = QUESTION_STATUS.finished.id;

                                                            // controllo tutte le domande risposte
                                                            let isRatingCompleted = true;
                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            isRatingCompleted &= th.questions.every( q => q.status === QUESTION_STATUS.finished.id )
                                                                        })
                                                                })
                                                            ;

                                                            refreshedTree.tabs
                                                                .forEach( tab => {
                                                                    tab.themes
                                                                        .forEach( th => {
                                                                            showlog( tab.id, th.id, th.questions.map( q => q.status ), th.questions.every( q => q.status === QUESTION_STATUS.finished.id ));
                                                                        })
                                                                })
                                                            ;



                                                            // loadProgress(this.getRatingId())
                                                            //     .then(result => {

                                                                    this.setState({
                                                                            ...this.state,
                                                                            // tree: refreshedTree,
                                                                            // editAnswer: editAnswwers,
                                                                            // waitingQuestions: this.state.waitingQuestions.filter( wq => wq !== question.id ),
                                                                            editAnswer: this.state.editAnswer.filter( ea => ea !== refreshedQuestion.id ),
                                                                            // waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                            metaData: {
                                                                                ...this.state.metaData,
                                                                                info: result
                                                                            }
                                                                        },
                                                                        () => {
                                                                            showlog("edit answers vuoto?", !isNotEmptyArray( this.state.editAnswer ));
                                                                            showlog("domande con risposte");
                                                                            showlog(
                                                                                question.id,
                                                                                question.choiches
                                                                                    .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                                                    .filter( onlyNotNull )
                                                                                    .reduce( getLastElementOr, null )
                                                                            )
                                                                            ;
                                                                            // debugger;


                                                                            //if( !isNotEmptyArray( this.state.waitingQuestions ) ) {
                                                                                // non ci sono più domande in edit
                                                                                showlog("albero al momento");
                                                                                showlog( cloneObject( this.state.tree ) )



                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                                })


                                                                                /*  refresh
                                                                                this.loadData( cloneObject( this.state.tree ) )
                                                                                    .then( res => {
                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            waitingQuestions: this.state.waitingQuestions.filter( ea => ea !== refreshedQuestion.id ),
                                                                                        })
                                                                                    })
                                                                                ;
                                                                                 */




                                                                            //}
                                                                        }
                                                                    )

                                                                // })
                                                                // .catch(e => {
                                                                //     errorlog("errore",e);
                                                                //     return Promise.reject(e);
                                                                // })
                                                            ;


                                                        }}
                                                        onError={e => {
                                                            errorlog("errore al salvataggio",e);
                                                            let eMessage = extractErrorMessage( e );
                                                            this.setState({
                                                                ...this.state,
                                                                // errorMessage: (!!e.error ? errorMessage.GENERIC_FRONTEND_ERROR_MESSAGE.msg : e),
                                                                errorMessage: eMessage,
                                                                waitingQuestions: this.state.waitingQuestions.filter( wq => wq === question.id )
                                                            })
                                                        }}
                                                        className={"question-save-button"}
                                                    />
                                                )
                                        }






                                    </div>

                                </CardBody>
                            </Collapse>
                        </Card>
                    );
                })
        );
    }

    loadData(clonedCurrentTree) {

        return (
            // loadAnswers(this.getRatingId(), this.state.activeTab || 1)
            loadAnswers(this.getRatingId())

                // conversion ------------------------------------------------------------------------------------------
                .then(data => {

                    let list =
                        data.dataset
                            // .map( )
                    ;
                    let metaData = {
                        info: data.questionCompleted
                    }

                    return (
                        convertSIRatingQuestionTableToTree(list)
                            .then(tree => {
                                showlog(tree);

                                // controllo dati
                                if (tree.tabs.map(tab => tab.themes.length > 0).filter(onlyNotNull).length > 0) {
                                    let csv = exportSeedTableToCSV(list);
                                    showlog(csv);
                                }

                                let treeString = questionTreeLog(tree, false);
                                // showlog(...treeString);

                                return ({
                                    seed: list,
                                    tree: tree,
                                    metaData: metaData
                                });

                            })
                    );

                })

                // extras   --------------------------------------------------------------------------------------------
                .then(({ seed, tree, metaData }) => {

                    return (
                        loadEvaluations( RATING_TYPE.siscoring )
                            .then(list => {

                                // ;

                                return ({
                                    seed: seed,
                                    tree: tree,
                                    metaData:
                                        Object.assign(
                                            {},
                                            metaData,
                                            list
                                                .filter(ev => ev.id === parseInt(this.getRatingId(), 10))
                                                .reduce(getLastElementOr, null)
                                        )

                                });
                            })
                    );

                })


                /* non necessario -----------------------------------
                // rating info
                .then( data => {
                    return (
                        loadRataingInfo( this.getRatingId() )
                            .then( rawInfos => {

                                let infos = rawInfos;

                                return ({
                                    seed: data.seed,
                                    tree: data.tree,
                                    metaData:
                                        Object.assign(
                                            data.metaData,
                                            { info: infos }
                                        )
                                });

                            })
                    );
                })
                */

                // finish ----------------------------------------------------------------------------------------------
                .then(({ seed, tree, metaData }) => {


                    // debugger;

                    return (
                        loadProgress(this.getRatingId())
                            .then(result => {

                                metaData = Object.assign({}, this.state.metaData, metaData, { info: result })
                                return ({
                                    seed: seed,
                                    tree: tree,
                                    metaData: metaData
                                });

                            })
                            .catch(e => {
                                errorlog("errore",e);
                                return Promise.reject(e);
                            })
                    );


                })

                // finish ----------------------------------------------------------------------------------------------
                .then(({ seed, tree, metaData }) => {


                    // let refreshedTree = cloneObject( this.state.tree );
                    // ;

                    // showlog( clonedCurrentTree, tree );
                    // ;

                    let treeTmp = cloneObject(tree);
                    if (!!clonedCurrentTree) {

                        cloneOpenNodes(clonedCurrentTree, treeTmp);

                        // ripristino delle edit answer
                        clonedCurrentTree
                            .tabs
                            .map( tab => {
                                tab.themes
                                    .map( th => {
                                        th.questions
                                            .map( question => {

                                                let answeredChoiche =
                                                    question.choiches
                                                        .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                        .filter( onlyNotNull )
                                                        .reduce( getLastElementOr, isNotEmptyArray(question.choiches) ? question.choiches[0] : null )
                                                ;
                                                let nodeTmp = question;
                                                if( !!answeredChoiche ) {
                                                    nodeTmp = getQuestionTreeNodeByPath(treeTmp, question.path) || question;
                                                    nodeTmp.choiches = question.choiches;
                                                }

                                            })
                                    })
                            })
                        ;

                        // ripristino delle edit answer in ritardo
                        this.state.tree
                            .tabs
                            .map( tab => {
                                tab.themes
                                    .map( th => {
                                        th.questions
                                            .map( question => {

                                                let answeredChoiche =
                                                    question.choiches
                                                        .map((choiche, choicheIndex) => choiche.answers.reduce(getLastElementOr, null) )
                                                        .filter( onlyNotNull )
                                                        .reduce( getLastElementOr, isNotEmptyArray(question.choiches) ? question.choiches[0] : null )
                                                ;
                                                let nodeTmp = question;
                                                if( !!answeredChoiche ) {
                                                    nodeTmp = getQuestionTreeNodeByPath(treeTmp, question.path) || question;
                                                    nodeTmp.choiches = question.choiches;
                                                }

                                            })
                                    })
                            })
                        ;




                    }
                    // cloneOpenNodes(clonedCurrentTree, tree);

                    showlog("loadData - clonedCurrentTree");
                    showlog( clonedCurrentTree );
                    showlog("loadData - tree");
                    showlog( tree );
                    showlog("loadData - treeTmp");
                    showlog( treeTmp );
                    // debugger;


                    let tabToOpen = treeTmp.tabs[0];
                    if (!!this.state.activeTab) {
                        tabToOpen = getQuestionTreeNodeByPath(treeTmp, [this.state.activeTab]);
                    }

                    tabToOpen.open = true;


                    /*
                    metaData.info = [
                        {
                            macroareaId: 1
                            questionAnswered: {
                                count: 1
                                thematicList: (3) […]
                            }
                            questionAttachmentToValidate: {…}
                            questionAttachmentWaiting: {…}
                            questionAuditReady: {…}
                            questionCompleted: {…}
                            questionGraveNc: {…}
                            questionMildNc: {…}
                            questionToAnswer: {…}
                            questionToAudit: {…}
                            questionTotal: {…}
                            questionValidate: {…}
                            questionWaitNc: {…}
                        },
                        {…}
                        {…}
                     */




                    let progress = 0;
                    let tabInfo = { questionCompletedNumber: 0, questionTotalNumber: 0 };
                    tabInfo =
                        metaData.info
                            .reduce((final, tabData) => {
                                final.questionCompletedNumber += tabData.questionAnswered.count;
                                // final.questionTotalNumber += tabData.questionToAnswer.count;
                                final.questionTotalNumber += tabData.questionTotal.count;
                                return final;
                            }, { questionCompletedNumber: 0, questionTotalNumber: 0 })
                        ;
                    progress = tabInfo.questionCompletedNumber / tabInfo.questionTotalNumber;
                    progress *= 100;
                    progress = Math.floor(progress);
                    progress = parseInt(progress, 10);




                    showlog("load data ");
                    showlog({
                        ...this.state,
                        seed: seed,
                        tree: treeTmp,
                        activeTab: tabToOpen.id,
                        metaData: metaData,
                        ready: (progress === 100),
                        progress: progress
                    });

                    showlog( tabInfo );

                    // showlog("STOP #04");
                    // debugger;

                    // ;
                    return (

                        Promise.all([
                            new Promise(( s, f ) => {
                                this.setState({
                                        ...this.state,
                                        seed: seed,
                                        tree: treeTmp,
                                        activeTab: tabToOpen.id,
                                        metaData: metaData,
                                        ready: (progress === 100),
                                        tabInfo: tabInfo,
                                        wait: false,
                                        editRatingNameInWait: false
                                    },
                                    () => {
                                        s();
                                    }
                                )
                            }),

                            new Promise(( ss, ff ) => {

                                let allChoiches = [];
                                // let questionList = extractAllQuestions(this.state.tree);
                                let questionList = extractAllQuestions(treeTmp);
                                questionList.map(q => {

                                    q.choiches.map(c => {

                                        allChoiches.push({ q: q, cs: c });

                                    })


                                })


                                // ss();

                                // qui non abbiamo sotto-domande
                                this.subQuestionRequest(allChoiches)
                                    .then( resultSS => {
                                        // showlog("%c  1.1  ", "background:#18f; color: #fff; font-size: 3em;", this.state);
                                        ss( resultSS );
                                    })
                                    .catch(e => {
                                        errorlog("Load subQuestionRequest", e);
                                        ff( e );
                                    })
                                ;



                            })
                        ])
                        .then( resultList => {
                            return true;
                        })

                        .catch(e => {
                            errorlog("promise all", e);
                            return Promise.reject( e );
                        })

                    );

                })



                .then(result => {

                    // showlog("%c  1.2  ", "background:#18f; color: #fff; font-size: 3em;", this.state);
                    return true;

                    // .then( () => {
                    // 	showlog("fatto")
                    // })


                })


                .catch(e => {
                    errorlog("LoadData di SiRatingAdmin single Evaluation page",e);
                    window.location.href = window.location.href;
                    // this.setState({
                    //     ...this.state,
                    //     loadDataError: e
                    // });
                    // return Promise.reject(e);
                })
        );


    }


    componentDidUpdate(prevProps, prevState, snapshot) {

    }


}

export default withRouter(SiScoringEvaluationPage);
