/**
 * Controlla se lìelemento passato è un un array pieno
 * @param array
 * @returns {boolean}
 */
export const isNotEmptyArray = array => {
    return ( !!array && array instanceof Array && array.length > 0 );
};

export const onlyNotNull = x => !!x;

export const onlyFirst = (x, i) => (i === 0);

export const pop = (item, index, list) => index < (list.length -1);

export const decapitate = (item, index) => index > 0;

export const getLastElementOr = (final, current) => current;

export const sortById = (x, y) => {
    if( x.id > y.id ) return 1;
    return -1;
};

export const sortByName = (x, y) => {
    if( x.name > y.name) return 1;
    return -1;
};

export const inverseSortById = (x, y) => {
    if( x.id > y.id ) return -1;
    return 1;
};

