import React, {useState} from "react";
import {DATE_FORMAT_IT} from "../../../conf/siroad";
import {translate} from "../../i18n/translate-function";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {errorlog, getLastElementOr} from "../../../utils";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import moment from "moment";
import {getSectorQuestion} from "../../../business-logic/question-sectors";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {LANGUAGES} from "../../../conf/languages";

const {SearchBar} = Search;

const SiratingSectorQuestionLinksModal = props => {

    const [list, setList] = useState(() => {
        return null;
    })
    const [errors, setErrors] = useState();

    let content = (
        <WaitingOrNodataMessage
            waiting={ !(!!list && list.length === 0) }
            fullHeight={ true }
            spinnerColor={"primary"}
            nodataMessage={ translate("NoData") }
            waitingMessag={ translate("Loading") }
        />
    );

    if( !list ) {
        getSectorQuestion()
            .then( sectorQuestions => {
                setList( sectorQuestions );
                setErrors( undefined );
            })
            .catch( e => {
                errorlog("Caricamento Lista domande di settore", e);
                setList([]);
                setErrors([...errors, translate("Error in getting sectorQuestion list")]);
            })
        ;
    }
    else {
        content = (
            <ToolkitProvider
                bootstrap4
                keyField="id"
                search
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                {props => (
                    <React.Fragment>

                        <div className={"search-and-commands-over-table"}>
                            <SearchBar {...props.searchProps} />
                        </div>

                        <BootstrapTable
                            bootstrap4
                            striped
                            {...props.baseProps}
                            size="sm"
                            responsive
                            keyField={ Object.keys(list.reduce(getLastElementOr, null))[0] }
                            data={
                                list
                                    .map( (sq, index) => {
                                        sq.linked = (
                                            <span
                                                className={"dis-link-text"}
                                                onClick={clickEvent => {
                                                    // TODO
                                                }}
                                            >
                                                { translate("associa") }
                                            </span>
                                        );
                                        return sq;
                                    })
                                    .filter( sq => true ) // inutile al momento
                            }
                            columns={
                                ( Object.keys( list.reduce( getLastElementOr, null ) ) )
                                    .map( field => {
                                        return ({
                                            dataField: field,
                                            text: (
                                                {
                                                    name_it: translate("Name"),
                                                    name_en: translate("Name"),
                                                    name_de: translate("Name"),
                                                    name_es: translate("Name"),
                                                    name_fr: translate("Name"),
                                                    // sector_name: translate("Sector"),
                                                    code: translate("Code"),
                                                    linked: translate("associa")
                                                }[ field ]
                                                || field
                                            ),
                                            // sort: sortableFields.includes( field )
                                        });
                                    })
                                    // custom date time sort -----------------------------------------------------------------------------------
                                    .map( column => {
                                        if( ["date", "time", "creation"].includes( column.dataField ) ) {
                                            column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                                                if (order === 'asc') {
                                                    return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                                                }
                                                return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                                            }
                                        }
                                        return column;
                                    })
                                    .filter(
                                        ff => ![
                                            "id"
                                            , "alive"
                                            // , "code"
                                            , "transparency"
                                            , "resilience"
                                            , "improvement"
                                            , "ordering"
                                            , "macroarea_id"
                                            , "macroarea_name"
                                            , "thematic_id"
                                            , "thematic_name"
                                            , "subthematic_id"
                                            , "subthematic_name"
                                            , "srSectorId"
                                            , "score"
                                            , "sector_name"
                                            , "isMultipleAnswer"
                                            , "attachmentsGuidelines"
                                            , "questionGuidelines"
                                            , "companySize"
                                            , "companies"
                                            ,
                                                ...(
                                                    LANGUAGES
                                                        .filter( lan => lan.code !== localStorage.getItem("language") )
                                                        .map( lan => `name_${ lan.code.substr(0, 2).toLowerCase() }`)
                                            )
                                        ].includes(ff.dataField)
                                    )
                            }
                            defaultSorted={[
                                {
                                    dataField: 'moment',
                                    order: 'desc',
                                }
                            ]}
                            pagination={
                                paginationFactory({
                                    paginationSize: 5,
                                    pageStartIndex: 1,
                                    firstPageText: translate('First'),
                                    prePageText: translate('backward'),
                                    nextPageText: translate('forward'),
                                    lastPageText: translate('Last'),
                                    nextPageTitle: translate('first page'),
                                    prePageTitle: translate('previous page'),
                                    firstPageTitle: translate('next page'),
                                    lastPageTitle: translate('last page'),
                                    showTotal: true,
                                    paginationTotalRenderer: (from, to, size) => (
                                        <span className="react-bootstrap-table-pagination-total ml-2">
                                            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Sector Questions")}
                                        </span>
                                    ),
                                    sizePerPageList: [
                                        {
                                            text: '5',
                                            value: 5,
                                        },
                                        {
                                            text: '10',
                                            value: 10,
                                        },
                                        {
                                            text: '20',
                                            value: 20,
                                        }
                                    ]
                                })
                            }
                            wrapperClasses="table-responsive"
                            rowClasses={ (row, rowIndex) => {
                                if( !row.alive ) return "disabled-row";
                                return "";
                            }}
                        />

                    </React.Fragment>
                )}
            </ToolkitProvider>
        );
    }


    return (
        <Modal
            isOpen={ true }
            toggle={ closeEvent => props.onClose() }
            className={"file-upload-modal"}
        >
            <ModalHeader toggle={ closeEvent => props.onClose() } >
                { translate("SectorQuestions") }
            </ModalHeader>
            <ModalBody>
                { content }
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>

    );

}


export default SiratingSectorQuestionLinksModal;
