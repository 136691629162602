import {translate} from "../components/i18n/translate-function";

export const TITLES_NAME = {
    MR:     { it: "Sig.", en: "Mr."     },
    DOCT:   { it: "Dott.", en: "Doct."  },
    ENG:    { it: "Ing.", en: "Eng."    }
};


export const TITLES = {
    MR: "Sig.",
    DOCT: "Dott.",
    ENG: "Ing."
};




export const TABS = {
    // generalAlt:     { id: 5, name: { it: "GENERALE" }, 		siratingId: 0, chapters: [], className: "general-tab", activeClassName: "general-tab-active", lightClassName: "general-light", selectClassName: "general-select", goalClassName: "general-tab-goal" 	},
    general:        { id: 1, name:  translate("GENERAL") , 		siratingId: 0, chapters: [], className: "general-tab", activeClassName: "general-tab-active", lightClassName: "general-light", selectClassName: "general-select", goalClassName: "general-tab-goal" 	},
    economic:       { id: 2, name:  translate("ECONOMIC") , 	siratingId: 3, chapters: [], className: "economic-tab", activeClassName: "economic-tab-active", lightClassName: "economic-light", selectClassName: "economic-select", goalClassName: "economic-tab-goal" 	},
    environment:    { id: 3, name:  translate("ENVIRONMENTAL"), 	siratingId: 1, chapters: [], className: "environment-tab", activeClassName: "environment-tab-active", lightClassName: "environment-light", selectClassName: "environment-select", goalClassName: "environment-tab-goal" 	},
    social:         { id: 4, name:  translate("SOCIAL") , 		siratingId: 2, chapters: [], className: "social-tab", activeClassName: "social-tab-active", lightClassName: "social-light", selectClassName: "social-select", goalClassName: "social-tab-goal" 	}
};




export const ITALIAN_NAMES_MONTHS = [
    translate("Gennaio"),
    translate("Febbraio"),
    translate("Marzo"),
    translate("Aprile"),
    translate("Maggio"),
    translate("Giugno"),
    translate("Luglio"),
    translate("Agosto"),
    translate("Settembre"),
    translate("Ottobre"),
    translate("Novembre"),
    translate("Dicembre")
];


export const EMAIL_REGEX                        = /^([0-9a-zA-Z]+[0-9a-zA-Z\.\-_]*)(@){1}([0-9a-zA-Z]+[a-zA-Z0-9\.\-_]+[a-zA-Z0-9]+[\.]{1}[a-zA-Z]{2,5})$/;
export const USERNAME_REGEX                     = /([a-zA-Z]+[a-zA-Z0-9\-_\!\.@\$%àèéìòù]*)/;
export const NAME_REGEX                         = /([a-zA-Z]+[àèéìòù]*)/;
export const USERNAME_MIN_LEN                   = 3;
export const PHONE_REGEX						= /^(\+){0,1}([0-9]+)(:)*([\s\.\-0-9])+$/;
export const IT_PHONE_REGEX                     = /^([0-9\+]{1})([0-9\s]{6,})$/;
export const PASSWORD_REGEX                     = /[0-9]+[a-z]+[A-Z]+/;
export const PWD_MIN_LEN                        = 6;
export const URL_REGEX                          = /^([THPWShtpws])+(:\/\/){1}([a-zA-Z0-9\-\+_])+([a-zA-Z0-9\-\+_\/\.])+(:){0,1}([0-9])*([a-zA-Z0-9\-\+_\/\.])+$/;
export const IPv4_REGEX                         = /^([THPWShtpws]){2,5}(:\/\/){1}([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})(:[0-9]+)*$/;

export const UPPERCASE_AT_LEAST_ONE				= /[A-Z]/;
export const LOWERCASE_AT_LEAST_ONE				= /[a-z]/;
export const NUMBER_AT_LEAST_ONE				= /[0-9]/;
export const SPECIAL_CHARACTER_AT_LEAST_ONE		= /[\!@#\*\$\%\^\&\(\)\{\}\[\]\:\;\<\>,\.\?\/\~_\+\-\=\|\\]/;
export const SPACE_CHARACTER_AT_LEAST_ONE		= /[\s]+/;


export const DATE_FORMAT_IT_REGEX               = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/;
export const DATE_FORMAT_IT                     = "DD/MM/YYYY";
export const DATETIME_FORMAT_IT                 = "D/MM/YYYY HH:mm:ss";
export const SERVICE_DATE_FORMAT_IT             = "YYYY-MM-DD";
export const CALENDAR_DATE_FORMAT_IT            = "dd/MM/yyyy";
export const CALENDAR_MOMENT_DATE_FORMAT_IT     = "DD-MM-YYYY";
export const CALENDAR_MOMENT_DATE_FORMAT_YEARS  = "YYYY-MM-DD";
export const MOMENT_JS_DATE_FORMAT_WORDS_IT     = "LL";
export const FLOAT_NUMBER_REGEX                 = /^[-+]?[0-9]*\.?[0-9]*$/;
export const STATUS_SUCCESS                     = "success";
export const MAX_UPLOADING_FILE_SIZE            = 50000000;
export const SIRATING_SERVICE_NAME              = "SiRating";
export const MOMENT_JS_INVALID_DATE_MSG         = "Invalid date";
export const START_MODERN_ISO_TIME              = "1970-01-01T00:00:00.0";
export const HTTP_CALL_SECONDS_WAIT_ALLOWED     = 180;
export const MAX_FILE_NAME_LENGTH               = 30;
export const MAX_COLLECTION_NAME_LENGTH         = 50;

export const PORT = "/";
export const API_ENDPOINT = "";
export const PATH_PREFIX = "";
export const SERVER_FOLDER_AS_BASE_PATH = "";

export const COMPANY_RANGE = [
    { id: 1, name: "Micro" },
    { id: 2, name: "Piccola" },
    { id: 3, name: "Media" },
    { id: 4, name: "Grande" }
    // { id: 5, name: "5" },
    // { id: 6, name: "6" }
];

export const COMPANY_EMPLOYEE = [
    { id: 1, name: "1-9" },
    { id: 2, name: "10-49" },
    { id: 3, name: "50-249" },
    { id: 4, name: ">=250" }
    // { id: 5, name: "5" },
    // { id: 6, name: "6" }
];


export const SIGNUP_STEP = {
    LANDING: -2,
    LOGIN: -1,
    PROFILE: 1,
    PROFILE_DONE: 5,
    COMPANY: 10,
    COMPANY_ADDRESS: 12,
    COMPANY_DONE: 15,
    REGISTERED_OFFICE: 16,
    RESET_PASSWORD: 30,
    RESET_PASSWORD_DONE: 31,
    SUBSCRIPTION: 40,
    SUBSCRIPTION_DONE: 45
};


