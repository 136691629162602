import {permissions, role} from "../env";
import {getLastElementOr} from "./array-utils";


export const getCurrentRoleLabel = () => (
    Object.keys(role)
        .filter(r => role[r].name === localStorage.getItem("role"))
        .map( r => role[r] )
        .reduce( getLastElementOr, ({label: "Nessun Ruolo"}) )
        .label
)


export const getCurrentRoleId = () => (
    Object.keys(role)
        .filter(r => role[r].name === localStorage.getItem("role"))
        .map( r => role[r] )
        .reduce( getLastElementOr, ({id: -1}) )
        .id
)


export const getRoleLabel = currentRole => {
}

export const getAllCurrentPermissions = () => {
    return (
        Object.keys(permissions)
            .map( key => permissions[ key ] )
            .filter( perm => (
                Object.keys(role)
                    .filter(r => role[r].name === localStorage.getItem("role"))
                    .map( r => role[r] )
                    .reduce( getLastElementOr, { permissions: [] } )
                    .permissions
                    .includes( perm.id )
            ))
    );
}
