import React, {Component} from "react";
import {Col, Input, Modal, ModalBody, ModalHeader, Row, Table} from 'reactstrap';
import {renderlog, showlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../env";
import "../../../style/collect-report-modal.css";
import {translate} from "../../i18n/translate-function";

// const { SearchBar } = Search;
//
// const hiddenFields = [];
//
// const fieldMap = {
//     name: "Name",
//     desciption: "Descrizione"
// };
//
//
// function priceFormatter(column, colIndex) {
//     return (
//         <React.Fragment>
//             <div> {column.filter} </div>
//         <h5> { column.text } </h5>
//         </React.Fragment>
//     );
// }
//
// const formatColumns = columnList => {
//     return (
//         columnList
//             .map(field => {
//
//                 if(field=="name"){
//
//                     return ({
//                         dataField: field,
//                         text: fieldMap[field] || field,
//                         //sort: sortableFields.includes(field)
//                         filter: textFilter({
//                             style: {
//                                 backgroundColor: 'yellow'
//                             }
//                         }),
//                         headerFormatter: priceFormatter()
//                     })
//
//                 }else{
//
//                     return ({
//                         dataField: field,
//                         text: fieldMap[field] || field,
//                     });
//                }
//             })
//             .filter(ff => !hiddenFields.includes(ff.dataField))
//     );
// }
//
// const defaultSorted = [
//     {
//         dataField: 'name',
//         order: 'asc',
//     },
// ];

export default class GlossarioCollectModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

        this.toggle = this.toggle.bind(this);

    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }

    componentDidMount(){}

    render() {

        renderlog("GlossarioCollectModal", this.state, this.props, COLOR_POLICY.validated);


        // let context2 = (
        //
        //     <ToolkitProvider
        //         bootstrap4
        //         keyField="name"
        //         search
        //         columns={ formatColumns(Object.keys(this.props.data[0])) }
        //
        //         data={ this.props.data }
        //         // exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        //     >
        //         {
        //             props => (
        //
        //
        //                 <div>
        //
        //                    {/*// <SearchBar { ...props.searchProps } />*/}
        //
        //                 <BootstrapTable
        //
        //                     /* Tabella completa */
        //                     bootstrap4
        //                     striped
        //                     {...props.baseProps}
        //                     filter={ filterFactory()}
        //                     size="sm"
        //                     responsive
        //                     keyField="name"
        //                     // data={ this.props.data }
        //                     //columns={ formatColumns(Object.keys(this.props.data[0])) }
        //                     defaultSorted={ defaultSorted }
        //                     wrapperClasses="table-responsive table2"
        //                     headerWrapperClasses="headerTabella"
        //
        //
        //                 />
        //                 </div>
        //
        //             )
        //         }
        //
        //     </ToolkitProvider>
        //
        //
        //
        //
        //
        // )




        let context = (

            <div className={"tabella"}>
                <Row>
                    <Col>
                        {/*<br />*/}

                        <Table>
                            <thead >

                                <Input onChange={changeEvent => {

                                    this.setState({
                                        ...this.state,
                                        changeEvent: changeEvent.target.value

                                    }, ()=>{showlog("CHANGEEVENT", this.state.changeEvent)})
                                }}
                                placeholder={"Search..."}
                                       style={{marginBottom: "1vh", width:"25vh"}}
                                />


                            </thead>
                            <tbody>



                                        <tr>
                                            <th style={{ background: '#dad8fd' }}>Nome</th>
                                            <th style={{ background: '#dad8fd'}} className={"tableBorderLeft2"}>Descrizione</th>
                                        </tr>


                                        {

                                            (!this.state.changeEvent) ?
                                            (
                                                this.props.data.map((data, i) => {

                                                    return (

                                                        <tr key={i}>
                                                            <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("")} style={{alignContent: 'left',textAlign: 'left'}}>{data.name}</td>
                                                            <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("tableBorderLeft2")} style={{alignContent: 'left',textAlign: 'left'}}>{data.desciption}</td>
                                                        </tr>

                                                    )
                                                })
                                            )
                                            :
                                            (
                                                    this.props.data.filter(data => data.name.includes(this.state.changeEvent) )
                                                    .map((data, i) => {

                                                            return (

                                                            <tr key={i}>
                                                            <td id={i} className={(i == 0 || (i % 2 == 0))?("rigascura"):("")} style={{alignContent: 'left', textAlign: 'left'}}>{data.name}</td>
                                                            <td id={i} className={(i == 0 || (i % 2 == 0))?("rigascura"):("")} style={{alignContent: 'left', textAlign: 'left'}}>{data.desciption}</td>
                                                            </tr>

                                                            )
                                                        }
                                                    )


                                            )

                                        }


                            </tbody>
                        </Table>

                    </Col>
                </Row>

            </div>
        );


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    { translate("GRI Glossary") }
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        let contentNodata = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    { translate("GRI Glossary") }
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    Nessun dato
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );


        if (!!this.props.data) {
            return content;
        } else {
            return contentNodata;
        }


    }

}
