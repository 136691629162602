import React, {Component} from "react";
import {Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../../../components/PageTitle";
import {DATE_FORMAT_IT, PATH_PREFIX, role} from "../../../env";
import {isUserRole} from "../../../business-logic/auth";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import Select from 'react-select';
import moment from "moment";
import {translate} from "../../../components/i18n/translate-function";
import SiRatingLogoDarkText from "../../../assets/images/SiRating_logo_dark_text_2.svg";
import ActionButton from "../../../components/save-button";
import EvaluationAssociatedFilterModal from "./evaluation-associated-filter-modal";
import SectorQuestionAssociatedFilter2modal from "./sector-question-associated-filter2modal";
import {getSectorQuestionLinkedToEvaluations} from "../../../business-logic/question-sectors";
import {errorlog, getLastElementOr, renderlog, showlog} from "../../../utils";
import {associationEvaluationSectorQuestion} from "../../../services/sector-question-service";


const {SearchBar} = Search;
//const { ExportCSVButton } = CSVExport;

export default class SectorQuestionsManagement extends Component {

    state = {
        data: [],
        sectorQuestionID: 0,
        evaluationID: 0
    }

    constructor(props) {
        super(props);
        this.ratingFilterComponent = this.ratingFilterComponent.bind(this);
        this.sectorQuestionComander = this.sectorQuestionComander.bind(this);

        this.fieldMap = {
            id: "id",
            versionComponent: "Versione",
            codCliFor: "companyId",
            name: translate("Name"),
            evaluationVersion: translate("EvaluationVersion"),
            dateCreate: translate("Creation Date"),
            company_name: "Ragione Sociale",
            dimensionName: "Dimensione",
            state: "Status",
            scoreButton: translate("Score"),
            userRoles: "Ruoli",
            //sendMailNcQuestions: "Invio Domande NC Lievi",
            manager: "PM",
            assignee: "Assignee",
            files: translate("Attachments"),
            status: "status",
            mainSectorComander: "Domande di settore associate",
            questionVersion: translate("QuestionVersion"),
            code: translate("Code"),
            question_description: translate("QuestionDescription"),
            sector_name: translate("Sector"),
            comand: " "
        };
        this.hiddenFields = [
            "compani_id",
            "dimensionId",
            "dimensionName",
            "Dimensione",
            "dimension",
            "companySize",
            "undefined",
            "status",
            isUserRole(role.PROJECT_MANAGER) ? "" : "Ruoli",
            isUserRole(role.ARB_OPERATOR) ? "" : "PM",
            isUserRole(role.ARB_OPERATOR) ? "" : "Assignee",
            isUserRole(role.PROJECT_MANAGER) ? "" : "id",
            "version",
            "question_id",
            "sector_id"
        ];
        this.dateTimeFields = ["dateCreate", "Creazione", translate("Creation Date")];

        this.sortableFields = [
            translate("Name"),
            translate("Creation Date"),
            "Ragione Sociale",
            "Dimensione",
            "Status",
        ];

    }

    ratingFilterComponent() {
        return (
            <React.Fragment>
                <Label for="rating-filter">{ translate("Evaluation") }</Label>
                &nbsp;&nbsp;
                <ActionButton
                    buttonText={
                        <React.Fragment>
                            <i className={"mdi mdi-format-align-justify tree-node-controller-text-button-icon"}/>
                            <span
                                className={"tree-node-controller-text-button" + (!!this.state.evaluationFilter ? "" : " tree-node-controller-text-button-empty")}>

                                {
                                    (!!this.state.evaluationFilter)
                                        ? (this.state.evaluationFilter)
                                        : translate("Select")
                                }

                            </span>
                        </React.Fragment>
                    }
                    color={(!!this.state.evaluationFilter) ? "success" : "primary"}
                    className={"tree-node-controller-button"}
                    promise={ () => {
                        return (
                            new Promise((onEnd, onFail) => {
                                this.setState({
                                        ...this.state,
                                        evaluationModal: true,
                                        errors: undefined
                                    },
                                    () => {
                                        onEnd();
                                    }
                                )
                            })
                        );
                    }}
                    onResult={ () => {}}
                    onError={e => {
                        errorlog("aggiornamento state", e);
                        this.setState({
                            ...this.state,
                            errors: [...this.state.errors, `${translate("something_went_wrong")}`]
                        });
                    }}
                />
            </React.Fragment>
        );
    }

    sectorQuestionComander() {
        return (
            <React.Fragment>
                <Label style={{marginLeft: "10px", marginRight: "10px"}} for="rating-filter">{ translate("SectorQuestions") }</Label>
                <ActionButton
                    buttonText={
                        <React.Fragment>
                            <i className={"mdi mdi-format-list-bulleted tree-node-controller-text-button-icon"}/>
                            <span
                                className={"tree-node-controller-text-button" + (!!this.state.sectorQuestionFilter ? "" : " tree-node-controller-text-button-empty")}
                            >

                                {
                                    (!!this.state.sectorQuestionFilter)
                                        ? (this.state.sectorQuestionFilter)
                                        : translate("Select")
                                }

                            </span>
                        </React.Fragment>
                    }
                    color={(!!this.state.sectorQuestionFilter) ? "success" : "primary"}
                    className={"tree-node-controller-button"}
                    promise={ () => {
                        return (
                            new Promise((onEnd, onFail) => {
                                this.setState({
                                    ...this.state,
                                    sectorQuestionModal: true,
                                    errors: undefined
                                },
                                () => {
                                        onEnd();
                                    }
                                )
                            })
                        );
                    }}
                    onResult={ () => {}}
                    onError={e => {
                        errorlog("aggiornamento state", e);
                        this.setState({
                            ...this.state,
                            errors: [...this.state.errors, `${translate("something_went_wrong")}`]
                        });
                    }}
                />
            </React.Fragment>
        );
    }


    render() {

        renderlog("Sector Questions Management Page", this.state, this.props);


        return (
            <React.Fragment>

                <PageTitle
                    breadCrumbItems={[
                        {
                            label: "SI Rating"
                            , path: PATH_PREFIX + "/welcome"
                        },
                        {
                            label: "Sector Questions Management",
                            path: PATH_PREFIX + '/super-admin/si-rating/sector-questions-management',
                            active: true
                        }
                    ]}
                    title={
                        <img
                            src={SiRatingLogoDarkText}
                            style={{
                                marginRight: '10px',
                                height: "60px"
                            }}
                        />
                    }
                />


                {
                    !!this.state.inWait
                        ? (
                            <div className={"light-white-backpan blur-backpan"}>
                                <WaitingOrNodataMessage
                                    waiting={ true }
                                    // nodataMessage={}
                                    waitMessage={ translate("Loading_") }
                                    spinnerColor={ "primary" }
                                    fullHeight={ true }
                                    // className={}
                                    // spinnerClass={}
                                />
                            </div>
                        )
                        : null
                }




                {
                    (!!this.state.evaluationModal) ?
                        (
                            <EvaluationAssociatedFilterModal
                                onClose={closeEvent => {

                                    showlog("closeEvent", closeEvent)

                                    if (!!closeEvent) {

                                        this.setState({
                                            ...this.state,
                                            inWait: true,
                                            errors: undefined,
                                            evaluationID: closeEvent.split(" - ").reverse().reduce( getLastElementOr, 0 )
                                        },
                                            () => {

                                                getSectorQuestionLinkedToEvaluations( this.state.sectorQuestionID, this.state.evaluationID )
                                                    .then(list => {


                                                        /*
                                                        viene fatto lo svuotamento perchè
                                                        nel caso in cui arrivano id duplicati la
                                                        tabella bootstrap non è in grado di eliminare
                                                        i duplicati
                                                         */
                                                        this.setState({
                                                            ...this.state,
                                                            data: [],
                                                            evaluationModal: false,
                                                            evaluationFilter: closeEvent,
                                                            inWait: false,
                                                            errors: undefined
                                                        },

                                                            () => {

                                                                this.setState({
                                                                    ...this.state,
                                                                    evaluationModal: false,
                                                                    evaluationFilter: closeEvent,
                                                                    data:
                                                                        list
                                                                            .map( row => {
                                                                                row.comand = (
                                                                                    <ActionButton
                                                                                        buttonText={ translate("disassocia") }
                                                                                        color={"link"}
                                                                                        className={""}
                                                                                        promise={ () => Promise.resolve() }
                                                                                        onResult={ () => {
                                                                                            this.setState({
                                                                                                ...this.state,
                                                                                                confirmDeAssociation:  (this.state.sectorQuestionID || row.code.split(".").reduce(getLastElementOr, 0) ) +"::"+ (this.state.evaluationID || row.id)
                                                                                            })
                                                                                        }}
                                                                                        onError={e => {
                                                                                            errorlog("aggiornamento state", e);
                                                                                            // TODO
                                                                                        }}
                                                                                    />
                                                                                );
                                                                                return row;
                                                                            })
                                                                    ,
                                                                    inWait: false,
                                                                    errors: undefined
                                                                })


                                                            }

                                                        )




                                                    })
                                                    .catch( e => {
                                                        errorlog("getSectorQuestionLinkedToEvaluations", e);
                                                        this.setState({
                                                            ...this.state,
                                                            errors: [...this.state.errors, `${translate("something_went_wrong")} in ${ translate("SectorQuestions") }`]
                                                        });
                                                    })
                                            }
                                        )




                                    } else {
                                        this.setState({
                                            ...this.state,
                                            evaluationModal: false,
                                            //data: [],
                                            inWait: false,
                                            errors: undefined
                                        })
                                    }

                                }}
                            />
                        )
                        :
                        null
                }

                {
                    !!this.state.confirmDeAssociation
                        ? (
                            <Modal
                                isOpen={true}
                                toggle={ () => {
                                    this.setState({
                                        ...this.state,
                                        confirmDeAssociation: null
                                    })
                                }}
                            >
                                <ModalHeader
                                    toggle={ () => {
                                        this.setState({
                                            ...this.state,
                                            confirmDeAssociation: null
                                        })
                                    }}
                                >
                                    { translate("Alert") }
                                </ModalHeader>
                                <ModalBody>
                                    <span>{ `${ translate("Are you sure you want to disassociate the question from the assessment") }` }</span>
                                </ModalBody>
                                <ModalFooter>
                                    {
                                        // this.state.sectorQuestionID +"::"+ this.state.evaluationID
                                        // associationEvaluationSectorQuestion( secID, evID, false)
                                        <React.Fragment>

                                            <ActionButton
                                                buttonText={ translate("button_text_cancel") }
                                                color={"link"}
                                                className={""}
                                                promise={ () => Promise.resolve() }
                                                onResult={ () => {
                                                    this.setState({
                                                        ...this.state,
                                                        confirmDeAssociation: undefined
                                                    })
                                                }}
                                                onError={e => {
                                                    errorlog("123", e);
                                                    // TODO
                                                }}
                                            />
                                            <ActionButton
                                                buttonText={ translate("disassocia") }
                                                color={"primary"}
                                                className={""}
                                                promise={ () => {
                                                    showlog("aaaaaa",this.state.confirmDeAssociation)
                                                    return associationEvaluationSectorQuestion(this.state.confirmDeAssociation.split("::")[0], this.state.confirmDeAssociation.split("::")[1], false)
                                                }}
                                                onResult={ () => {

                                                            this.setState({
                                                                    ...this.state,
                                                                    inWait: true,
                                                                    errors: undefined,
                                                                    confirmDeAssociation: undefined
                                                                },
                                                                () => {

                                                                    getSectorQuestionLinkedToEvaluations( this.state.sectorQuestionID, this.state.evaluationID )
                                                                        .then( list => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                sectorQuestionModal: false,
                                                                                data:
                                                                                    list
                                                                                        .map( row => {
                                                                                            row.comand = (
                                                                                                <ActionButton
                                                                                                    buttonText={ translate("disassocia") }
                                                                                                    color={"link"}
                                                                                                    className={""}
                                                                                                    // promise={ () => associationEvaluationSectorQuestion( secID, evID, false) }
                                                                                                    promise={ () => Promise.resolve() }
                                                                                                    onResult={ () => {
                                                                                                        this.setState({
                                                                                                            ...this.state,
                                                                                                            confirmDeAssociation: (this.state.sectorQuestionID || row.code.split(".").reduce(getLastElementOr, 0) ) +"::"+ (this.state.evaluationID || row.id)
                                                                                                        })
                                                                                                    }}
                                                                                                    onError={e => {
                                                                                                        errorlog("aggiornamento state", e);
                                                                                                        // TODO
                                                                                                    }}
                                                                                                />
                                                                                            );
                                                                                            return row;
                                                                                        })
                                                                                ,
                                                                                inWait: false,
                                                                                confirmDeAssociation: undefined
                                                                            });
                                                                        })
                                                                        .catch( e => {
                                                                            errorlog("getSectorQuestionLinkedToEvaluations", e);
                                                                            this.setState({
                                                                                ...this.state,
                                                                                errors: [...this.state.errors, `${translate("something_went_wrong")} in ${ translate("SectorQuestions") }`]
                                                                            });
                                                                        })

                                                                }
                                                            )

                                                }}
                                                onError={e => {
                                                    errorlog("aggiornamento state", e);
                                                    // TODO
                                                }}
                                            />

                                        </React.Fragment>
                                    }
                                </ModalFooter>
                            </Modal>
                        )
                        : null
                }


                {
                    !!this.state.confirmAssociation
                        ? (
                            <Modal
                                isOpen={true}
                                toggle={ () => {
                                    this.setState({
                                        ...this.state,
                                        confirmAssociation: null
                                    })
                                }}
                            >
                                <ModalHeader
                                    toggle={ () => {
                                        this.setState({
                                            ...this.state,
                                            confirmAssociation: null
                                        })
                                    }}
                                >
                                    { translate("Alert") }
                                </ModalHeader>
                                <ModalBody>
                                    <span>{ `${ translate("Are you sure you want to associate the question from the assessment") }` }</span>
                                </ModalBody>
                                <ModalFooter>
                                    {
                                        // this.state.sectorQuestionID +"::"+ this.state.evaluationID
                                        // associationEvaluationSectorQuestion( secID, evID, false)
                                        <React.Fragment>

                                            <ActionButton
                                                buttonText={ translate("button_text_cancel") }
                                                color={"link"}
                                                className={""}
                                                promise={ () => Promise.resolve() }
                                                onResult={ () => {
                                                    this.setState({
                                                        ...this.state,
                                                        confirmAssociation: undefined
                                                    })
                                                }}
                                                onError={e => {
                                                    errorlog("123", e);
                                                    // TODO
                                                }}
                                            />
                                            <ActionButton
                                                buttonText={ translate("associa") }
                                                color={"primary"}
                                                className={""}
                                                promise={ () => associationEvaluationSectorQuestion( this.state.sectorQuestionID, this.state.evaluationID, true) }
                                                onResult={ () => {

                                                    this.setState({
                                                            ...this.state,
                                                            inWait: true,
                                                            errors: undefined,
                                                            confirmAssociation: undefined
                                                        },
                                                        () => {

                                                            getSectorQuestionLinkedToEvaluations( this.state.sectorQuestionID, this.state.evaluationID )
                                                                .then( list => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        sectorQuestionModal: false,
                                                                        data:
                                                                            list
                                                                                .map( row => {
                                                                                    row.comand = (
                                                                                        <ActionButton
                                                                                            buttonText={ translate("disassocia") }
                                                                                            color={"link"}
                                                                                            className={""}
                                                                                            // promise={ () => associationEvaluationSectorQuestion( secID, evID, false) }
                                                                                            promise={ () => Promise.resolve() }
                                                                                            onResult={ () => {
                                                                                                this.setState({
                                                                                                    ...this.state,
                                                                                                    confirmDeAssociation: (this.state.sectorQuestionID || row.code.split(".").reduce(getLastElementOr, 0) ) +"::"+ (this.state.evaluationID || row.id)
                                                                                                })
                                                                                            }}
                                                                                            onError={e => {
                                                                                                errorlog("aggiornamento state", e);
                                                                                                // TODO
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                    return row;
                                                                                })
                                                                        ,
                                                                        inWait: false,
                                                                        confirmDeAssociation: undefined,
                                                                        confirmAssociation: undefined
                                                                    });
                                                                })
                                                                .catch( e => {
                                                                    errorlog("getSectorQuestionLinkedToEvaluations", e);
                                                                    this.setState({
                                                                        ...this.state,
                                                                        errors: [...this.state.errors, `${translate("something_went_wrong")} in ${ translate("SectorQuestions") }`]
                                                                    });
                                                                })

                                                        }
                                                    )

                                                }}
                                                onError={e => {
                                                    errorlog("aggiornamento state", e);
                                                    // TODO
                                                }}
                                            />

                                        </React.Fragment>
                                    }
                                </ModalFooter>
                            </Modal>
                        )
                        : null
                }


                {
                    (!!this.state.sectorQuestionModal) ?
                        (
                            <SectorQuestionAssociatedFilter2modal
                                onClose={closeEvent => {

                                    showlog("closeEvent", closeEvent)

                                    if (!!closeEvent) {

                                        this.setState({
                                            ...this.state,
                                            inWait: true,
                                            errors: undefined,
                                            sectorQuestionID: closeEvent.split("SQ.").reduce( getLastElementOr, 0 )
                                        },
                                            () => {

                                                getSectorQuestionLinkedToEvaluations( this.state.sectorQuestionID, this.state.evaluationID )
                                                    .then( list => {
                                                        this.setState({
                                                            ...this.state,
                                                            sectorQuestionModal: false,
                                                            sectorQuestionFilter: closeEvent,
                                                            data:
                                                                list
                                                                    .map( row => {
                                                                        row.comand = (
                                                                            <ActionButton
                                                                                buttonText={ translate("disassocia") }
                                                                                color={"link"}
                                                                                className={""}
                                                                                // promise={ () => associationEvaluationSectorQuestion( secID, evID, false) }
                                                                                promise={ () => Promise.resolve() }
                                                                                onResult={ () => {
                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        confirmDeAssociation: (this.state.sectorQuestionID || row.code.split(".").reduce(getLastElementOr, 0) ) +"::"+ (this.state.evaluationID || row.id)
                                                                                    })
                                                                                }}
                                                                                onError={e => {
                                                                                    errorlog("aggiornamento state", e);
                                                                                    // TODO
                                                                                }}
                                                                            />
                                                                        );
                                                                        return row;
                                                                    })
                                                            ,
                                                            inWait: false
                                                        });
                                                    })
                                                    .catch( e => {
                                                        errorlog("getSectorQuestionLinkedToEvaluations", e);
                                                        this.setState({
                                                            ...this.state,
                                                            errors: [...this.state.errors, `${translate("something_went_wrong")} in ${ translate("SectorQuestions") }`]
                                                        });
                                                    })

                                            }
                                        )




                                    } else {
                                        this.setState({
                                            ...this.state,
                                            sectorQuestionModal: false,
                                            //data: [],
                                            inWait: false,
                                            errors: undefined
                                        })
                                    }

                                }}
                            />
                        )
                        :
                        null
                }


                <Card>
                    <CardBody>

                        <Row>
                            <Col>
                                <div className="page-title-box">
                                    <h4 className="page-title">Sector Question Management</h4>
                                </div>
                            </Col>
                        </Row>


                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            search
                            //exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                        >
                            {props => (
                                <React.Fragment>


                                    <div className={"rating-filters-and-comander"}>

                                        <div className={"rating-filters"}>
                                            <div className={""}>{this.ratingFilterComponent()}</div>
                                            {(!!this.state.evaluationID) ?
                                                (
                                                    <ActionButton
                                                        className={"filterButton2"}
                                                        buttonText={ `${ translate("remove") } ${ translate("Filter") } ` }
                                                        promise={ () => getSectorQuestionLinkedToEvaluations( this.state.sectorQuestionID, 0 ) }
                                                        onResult={ list => {



                                                            this.setState({
                                                                ...this.state,
                                                                data: (
                                                                    (!this.state.sectorQuestionID)
                                                                        ? []
                                                                        : (
                                                                            list
                                                                                .map( row => {
                                                                                    row.comand = (
                                                                                        <ActionButton
                                                                                            buttonText={ translate("disassocia") }
                                                                                            color={"link"}
                                                                                            className={""}
                                                                                            // promise={ () => associationEvaluationSectorQuestion( secID, evID, false) }
                                                                                            promise={ () => Promise.resolve() }
                                                                                            onResult={ () => {

                                                                                                this.setState({
                                                                                                    ...this.state,
                                                                                                    confirmDeAssociation: (this.state.sectorQuestionID || row.code.split(".").reduce(getLastElementOr, 0) ) +"::"+ (this.state.evaluationID || row.id)
                                                                                                })
                                                                                            }}
                                                                                            onError={e => {
                                                                                                errorlog("aggiornamento state", e);
                                                                                                // TODO
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                    return row;
                                                                                })
                                                                    )

                                                                ),
                                                                evaluationID: 0,
                                                                evaluationFilter: null,
                                                                inWait: false,
                                                                errors: undefined
                                                            });

                                                        }}
                                                        onError={err => {
                                                            errorlog("errore ricerca filtri sector question management", err);
                                                            this.setState({
                                                                ...this.state,
                                                                errors: [...this.state.errors, `${translate("something_went_wrong")}`]
                                                            })
                                                        }}
                                                    />
                                                )
                                                : null}


                                            <div className={""}>
                                                {
                                                    // !!this.state.evaluationID
                                                    true
                                                        ? this.sectorQuestionComander()
                                                        : null
                                                }
                                            </div>
                                            {(!!this.state.sectorQuestionID) ?
                                                (
                                                    <ActionButton
                                                        className={"filterButton2"}
                                                        buttonText={`${ translate("remove") } ${ translate("Filter") } `}
                                                        promise={ () => getSectorQuestionLinkedToEvaluations( 0, this.state.evaluationID ) }
                                                        onResult={list => {
                                                            this.setState({
                                                                ...this.state,
                                                                sectorQuestionID: 0,
                                                                data: (
                                                                    (!this.state.evaluationID)
                                                                        ? []
                                                                        : (
                                                                            list
                                                                                .map( row => {
                                                                                    row.comand = (
                                                                                        <ActionButton
                                                                                            buttonText={ translate("disassocia") }
                                                                                            color={"link"}
                                                                                            className={""}
                                                                                            promise={ () => Promise.resolve() }
                                                                                            onResult={ () => {
                                                                                                this.setState({
                                                                                                    ...this.state,
                                                                                                    confirmDeAssociation:  (this.state.sectorQuestionID || row.code.split(".").reduce(getLastElementOr, 0) ) +"::"+ (this.state.evaluationID || row.id )
                                                                                                })
                                                                                            }}
                                                                                            onError={e => {
                                                                                                errorlog("aggiornamento state", e);
                                                                                                // TODO
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                    return row;
                                                                                })
                                                                        )
                                                                ),
                                                                sectorQuestionFilter: null,
                                                                errors: undefined
                                                            })
                                                        }}
                                                        onError={err => {
                                                            errorlog("errore ricerca filtri sector question management", err);
                                                            this.setState({
                                                                ...this.state,
                                                                errors: [...this.state.errors, `${translate("something_went_wrong")}`]
                                                            })
                                                        }}
                                                    />
                                                )
                                                : null}




                                            {
                                                (!!this.state.data && (this.state.data.length === 0) && !!this.state.sectorQuestionID && !!this.state.evaluationID)
                                                    ? (
                                                        <ActionButton
                                                            buttonText={ translate("associa") }
                                                            color={"primary"}
                                                            className={""}
                                                            promise={ () => Promise.resolve() }
                                                            onResult={ () => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    confirmAssociation: this.state.sectorQuestionID +"::"+ this.state.evaluationID
                                                                })
                                                            }}
                                                            onError={e => {
                                                                errorlog("aggiornamento state", e);
                                                                // TODO
                                                            }}
                                                        />
                                                    )
                                                    : null
                                            }


                                        </div>

                                    </div>





                                    {
                                        (!!this.state.data && this.state.data.length > 0)
                                            ? (

                                                <BootstrapTable
                                                    bootstrap4
                                                    striped
                                                    {...props.baseProps}
                                                    size="sm"
                                                    responsive
                                                    keyField="id"
                                                    data={ this.state.data }
                                                    selectRow={{
                                                        mode:"checkbox",
                                                        clickToSelect: true,
                                                        hideSelectColumn: true,

                                                        onSelect: row => {
                                                                showlog("RIGAAA", row)
                                                            showlog("RIGAAA", row.code.split(".")[1])
                                                            showlog("RIGAAA", row.id)


                                                        }

                                                    }}
                                                    columns={

                                                        ( Object.keys( this.state.data.reduce( getLastElementOr, null ) ) )
                                                            .map(field => {
                                                                return ({
                                                                    dataField: field,
                                                                    text: this.fieldMap[field] || field,
                                                                    sort: this.sortableFields.includes(field)
                                                                });
                                                            })
                                                            // custom date time sort -----------------------------------------------------------------------------------
                                                            .map(column => {
                                                                if (this.dateTimeFields.includes(column.dataField)) {
                                                                    column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                                                                        if (order === 'asc') {
                                                                            return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                                                                        }
                                                                        return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                                                                    }
                                                                }
                                                                return column;
                                                            })
                                                            .filter(ff => !this.hiddenFields.includes(ff.dataField))

                                                    }

                                                    defaultSorted={[
                                                        {
                                                            dataField: "Id",
                                                            order: "desc",
                                                        },
                                                    ]}
                                                    pagination={
                                                        paginationFactory({ // si potrebbe rendere unica per tutto il sistema
                                                            paginationSize: 5,
                                                            pageStartIndex: 1,
                                                            firstPageText: translate("first page"),
                                                            prePageText: translate("backward"),
                                                            nextPageText: translate("forward"),
                                                            lastPageText: translate("Last"),
                                                            nextPageTitle: translate("first page"),
                                                            prePageTitle: translate("previous page"),
                                                            firstPageTitle: translate("next page"),
                                                            lastPageTitle: translate("last page"),
                                                            showTotal: true,
                                                            paginationTotalRenderer: (from, to, size) => (
                                                                <span className="react-bootstrap-table-pagination-total ml-2">
                                                                    {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Rating")}
                                                                </span>
                                                            ),
                                                            sizePerPageList: [
                                                                {
                                                                    text: "pochi",
                                                                    value: 25,
                                                                },
                                                                {
                                                                    text: "normale",
                                                                    value: 50,
                                                                },
                                                                {
                                                                    text: "molti",
                                                                    value: 250,
                                                                }
                                                            ]
                                                        })
                                                    }
                                                    wrapperClasses="table-responsive sub-node-element"
                                                />

                                            )
                                            : (

                                                (!!this.state.data && this.state.data.length === 0)
                                                    ?
                                                    (
                                                        (
                                                            <WaitingOrNodataMessage
                                                                waiting={!(!!this.state.data && this.state.data.length === 0)}
                                                                fullHeight={true}
                                                                nodataMessage={"Nessun dato da visualizzare"}
                                                            />
                                                        )
                                                    )
                                                    :
                                                    (
                                                        (!!this.state.error) ?

                                                            (
                                                                <WaitingOrNodataMessage
                                                                    waiting={false}
                                                                    fullHeight={true}
                                                                    nodataMessage={"Errore ottenimento dati"}
                                                                />
                                                            )
                                                            :
                                                            null
                                                    )

                                            )
                                    }


                                </React.Fragment>
                            )}
                        </ToolkitProvider>


                        {/*
                        <code>raw data</code>
                        <pre>
                            { beautify( this.state.data ) }
                        </pre>
                        */}

                    </CardBody>
                </Card>

                {
                    (!!this.state.errors)
                        ? (
                            <ErrorMessageModalComponent
                                message={this.state.errors.join(".\n")}
                                contentClassName={"big-font"} // choiche-status sirating-question-attachment-modal-icon comander-icon
                                noheader={true}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        errors: undefined
                                    })
                                }}
                            />
                        )
                        : null
                }

            </React.Fragment>
        );
    }

    componentDidMount() {
    }

}
