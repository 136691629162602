import React, {Component} from "react";
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../env";
// import { Score_Get } from "../../../../src/services/request-manager";
import {getCriteriaScore} from "../../../business-logic/score";
import {translate} from "../../i18n/translate-function";


const fieldSdgMap = {
    thematic_name: "Tematica/Sottotematica",
    subthematic_name: "Sottotematica",
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    materiality: "Livello di Materialità",
    percentage: "%",
    sdg: "SDG"
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];


const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];


const hiddenSdgFields2 = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];


export default class CriteriaModal extends Component {


    importData = () => {


        //return Score_Get(this.props.evaluation_id, 0).then(r => {
        return (
            getCriteriaScore(this.props.evaluation_id)
                .then(r => {

                    console.log("rrrrrrrrrrrrrrrrr", r);
                    return (r.data || r);

                })

                .catch(e => {
                    console.log("errore");
                    console.log(e);
                })

        );


    }


    loadData() {

        this.importData()
            .then(data => {


                console.log("dasdgsaydguyds", data);

                // debugger
                this.setState({
                    data: true,
                    Transparency: data.transparency,
                    Resilience: data.resilience,
                    Improvement: data.improvement,
                    genderGAP: data.gendergap
                })

            });


    }


    state = {
        data: false,
        Transparency: 0,
        Resilience: 0,
        Improvement: 0
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }


    render() {

        renderlog("CriteriaModal", this.state, this.props, COLOR_POLICY.validated);

        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm"/>
                <span className={"loading-text-alignment"}>Caricamento...</span>
            </React.Fragment>
        );


        if (!!this.state.data) {
            context = (

                <React.Fragment style={{alignContent: 'center'}}>
                    <Row style={{alignContent: 'center', textAlign: 'center'}}>
                        <Col>
                            <br/>
                            {/* <SearchBar {...props.searchProps} /> */}

                            <Label
                                className={"non-compliant-switch"}
                                onClick={e => {
                                    this.thematicFilter(3)
                                }}
                                style={{marginRight: '30px', fontSize: '16px'}}
                            >
                                <strong style={{color: this.state.colorGov}}>GENDER GAP: {this.state.genderGAP}% </strong>
                            </Label>


                            <Label
                                className={"non-compliant-switch"}
                                onClick={e => {
                                    this.thematicFilter(1)
                                }}
                                style={{
                                    marginRight: '35px',
                                    fontSize: '16px',
                                    color: this.state.colorEnv
                                }}
                            >
                                <strong style={{color: this.state.colorEnv}}>{translate("TRANSPARENCY")}: {this.state.Transparency}% </strong>
                            </Label>

                            <br/>

                            <Label
                                className={"non-compliant-switch"}
                                onClick={e => {
                                    this.thematicFilter(2)
                                }}
                                style={{marginRight: '35px', fontSize: '16px'}}
                            >
                                <strong style={{color: this.state.colorSoc}}>{translate("RESILIENCE")}: {this.state.Resilience}% </strong>
                            </Label>



                            <Label
                                className={"non-compliant-switch"}
                                onClick={e => {
                                    this.thematicFilter(3)
                                }}
                                style={{fontSize: '16px'}}
                            >
                                <strong style={{marginRight: '35px', color: this.state.colorGov}}>{translate("IMPROVEMENT")}: {this.state.Improvement}% </strong>
                            </Label>




                            <br/><br/>
                        </Col>
                    </Row>

                </React.Fragment>
            );
        }


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    {translate("Score")}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }


}




