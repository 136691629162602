import {isNumber} from './codes-utils';
import {MAX_FILE_NAME_LENGTH} from "../env";
import {FILE_ICON} from "../file-icons";
import {getLastElementOr, onlyNotNull} from "./array-utils";
import React from "react";

/**
 * <em>Non finito ancora</em>
 * decodifica da un url-encoded
 * @param str
 * @returns {string|*}
 */
export const urlDecode = str => {
    if( isNumber( str ) ) return str.toString();
    if( !str ) return "";
    if( typeof str !== "string" ) return str;
    return (
        str
            .split("%27")
            .join("'")
    );
};


/**
 * Tronca il nome del file, lasciando l'estensione
 *
 * ATTENZIONE ai file senza estensione ma con un punto nel nome
 *
 * @param fileName
 * @returns {string}
 */
export const truncateFileName = fileName => {
    if(!fileName) return "";
    if(typeof fileName === "undefined") return "";
    let name = (new String(fileName)).toString();
    return (
        [
            name
                .split(".")
                .reverse()
                .filter( (name, index) => index > 0)
                .reverse()
                .join(".")
                .substr(0, MAX_FILE_NAME_LENGTH)
            ,
            name
                .split(".")
                .reduce( getLastElementOr, "")
        ]
        .join(".")
    );
}

/**
 * Da la classe che rappresenta l'icona del tipo di file, in base al suo nome
 * @param fileName
 * @returns {string|*|string}
 */
export const getMaterialClassIconByFileName = fileName => {
    if(!fileName) return "";
    if(typeof fileName === "undefined") return "";
    let name = (new String(fileName)).toString();

    let ext =
        name
            .split(".")
            .reduce( getLastElementOr, "")
            .toLowerCase()
    ;

    return FILE_ICON[ ext ] || FILE_ICON.generic;
};

export const emoj = emo => {
    if(!emo) return "";
    // if( isNaN(emo) ) return "";
    if( emo.split(",").length > 1 ) {
        // return String.fromCodePoint( emo.split(",").reduce( getLastElementOr, emo.split(",")[0] ) );
        return String.fromCodePoint( emo.split(",")[0] );
    }
    return String.fromCodePoint( emo );
};



export const capitalize = s => {
    if( !s ) return "";
    if( typeof s === "string" ) return s.substr(0, 1).toUpperCase() + s.substr(1);
    return s;
};



export const extractErrorMessage = e => {
    let errorMessage = e || "Errore Generico";
    if( !!e ) {
        if( !!e.message ) {
            errorMessage = e.message;
        }
        else {
            if( !!e.error ) {
                // errorMessage = e.error;
                if( !!e.error.message ) errorMessage = e.error.message;
                else {
                    if( !!e.error.msg ) errorMessage = e.error.msg;
                    if( !!e.error.error ) {
                        // errorMessage = e.error.error;
                        if( !!e.error.error.msg ) errorMessage = e.error.error.msg;
                        if( !!e.error.error.message ) errorMessage = e.error.error.message;
                    }
                }
            }
        }
    }
    return errorMessage;
}


/**
 * Rende un testo formattato in pseudo-mark-down presentabile
 *
 * @param text
 * @param options
 *      - noSpan: boolean; fa il wrap dei testi dentro un tag span
 * @returns {*|JSX.Element|string}
 */
export const decodeMarkDown = (text, options = { noSpan: false }) => {

    if( !text || typeof text !== "string" ) return text;

    let isPureString = true;
    const styleMarkers = ["*", "_"];

    // divisione per linee
    let lines =

        text
            .split("\n")
            .filter( onlyNotNull )

            // controllo titoli
            .map( line => {

                if( line.substr(0, 2) === "# ") {
                    isPureString = false;
                    line = <h1>{ line.substr(2) }</h1>;
                }
                else {
                    if( line.substr(0, 3) === "## ") {
                        isPureString = false;
                        line = <h2>{ line.substr(3) }</h2>;
                    }
                    else {
                        if( line.substr(0, 4) === "### ") {
                            isPureString = false;
                            line = <h3>{ line.substr(4) }</h3>;
                        }
                        else {
                            if( line.substr(0, 5) === "#### ") {
                                isPureString = false;
                                line = <h4>{ line.substr(5) }</h4>;
                            }
                            else {
                                if( line.substr(0, 6) === "##### ") {
                                    isPureString = false;
                                    line = <h5>{ line.substr(6) }</h5>;
                                }
                                else {
                                    if( line.substr(0, 7) === "###### ") {
                                        isPureString = false;
                                        line = <h6>{ line.substr(7) }</h6>;
                                    }
                                    else {
                                        if( line.substr(0, 8) === "####### ") {
                                            isPureString = false;
                                            line = <h7>{ line.substr(8) }</h7>;
                                        }
                                        else {
                                            if( line.substr(0, 9) === "######## ") {
                                                isPureString = false;
                                                line = <h8>{ line.substr(9) }</h8>;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return line;
            })

            // style
            .map( line => {
                let words = line;
                if( typeof line === "string" ) {

                    let chuncks = [];
                    let strong = false;
                    let em = false;
                    let code = false;
                    words =
                        line
                            .split(" ")
                            .map( word => {

                                let currentStrong = strong;
                                let currentEm = em;
                                let currentCode = code;

                                if( word.length > 1 ) {

                                    // inizia con 2 marker
                                    if( (word.substr(0, 2) === "**") || (word.substr(0, 2) === "__") ) {
                                        strong = true;
                                        currentStrong = true;
                                        isPureString = false;
                                        word = word.substr(2);
                                    }

                                    // finisce con 2 marker
                                    if( (word.substr(word.length -2, 2) === "**") || (word.substr(word.length -2, 2) === "__") ) {
                                        strong = false;
                                        word = word.substr(0, word.length -2);
                                    }

                                    // inizia con 1 marker
                                    if( (word.substr(0, 1) === "*") || (word.substr(0, 1) === "_") ) {
                                        em = true;
                                        currentEm = true;
                                        isPureString = false;
                                        word = word.substr(1);
                                    }

                                    // finisce con 1 marker
                                    if( (word.substr(word.length -1, 1) === "*") || (word.substr(word.length -1, 1) === "_") ) {
                                        em = false;
                                        word = word.substr(0, word.length -1);
                                    }

                                    // code
                                    if( word.substr(0, 1) === "`" ) {
                                        code = true;
                                        currentCode = true;
                                        isPureString = false;
                                        word = word.substr(1);
                                    }

                                    if( word.substr(word.length -1, 1) === "`" ) {
                                        code = false;
                                        word = word.substr(0, word.length -1);
                                    }

                                }

                                return ({
                                    content: word,
                                    style: {
                                        strong: currentStrong,
                                        em: currentEm,
                                        code: currentCode
                                    }
                                })

                            })


                            .map( word => {

                                let htmlWord = word.content;
                                if( !!word.style.strong )   htmlWord = <strong>{ htmlWord }</strong>;
                                if( !!word.style.em )       htmlWord = <em>{ htmlWord }</em>;
                                if( !!word.style.code )     htmlWord = <code>{ htmlWord }</code>;
                                return htmlWord;

                            })

                    ;

                }

                if( !isPureString && words instanceof Array ) {
                    // showlog("wrap della linea");
                    // showlog( words );
                    if( !!options.noSpan ) {
                        // TODO da sistemare, ancora non va bene
                        // return <React.Fragment>{ words.join(" ") }</React.Fragment>;
                        // return words.map(word => <React.Fragment>{word}</React.Fragment>);
                        return (
                            words
                                .map( word => {
                                    if( typeof( word ) === "string" ) return word +" ";
                                    return <React.Fragment>{word}<span>&nbsp;</span></React.Fragment>;
                                })
                        );
                    }
                    else {
                        return words.map( word => <span className={"simple-markdown-word-spacer"}>{ word }</span> );
                    }
                }
                return words;
            })

            .map( line => {
                return line;
            })

    ;

    if( !!isPureString ) return text;

    return (
        <React.Fragment>
            { lines }
        </React.Fragment>
    );
}





export const decodeUriEncodedString = str => (
    str
        .split("%0a").join("").split("%0A").join("")
        .split("%09").join("")
        .split("%20").join(" ")
        .split("%21").join("!")
        .split("%22").join(":")
        .split("%23").join("#")
        .split("%24").join("$")
        .split("%25").join("#")
        .split("%26").join("&")
        .split("%27").join("'")
        .split("%28").join("(")
        .split("%29").join(")")
        .split("%2a").join("*").split("%2A").join("*")
        .split("%2b").join("+").split("%2B").join("+")
        .split("%2c").join(",").split("%2C").join(",")
        .split("%2d").join("-").split("%2D").join("-")
        .split("%2e").join(".").split("%2E").join(".")
        .split("%2f").join("/").split("%2F").join("/")
        .split("%3a").join(":").split("%3A").join(":")
        .split("%3b").join(";").split("%3B").join(";")
        .split("%3c").join("<").split("%3C").join("<")
        .split("%3d").join("=").split("%3D").join("=")
        .split("%3e").join(">").split("%3E").join(">")
        .split("%3f").join("?").split("%3F").join("?")
        .split("%40").join("@")
        .split("%5b").join("[").split("%5B").join("[")
        .split("%5c").join("\\").split("%5C").join("\\")
        .split("%5d").join("]").split("%5D").join("]")
        .split("%5e").join("^").split("%5E").join("^")
        .split("%5f").join("_").split("%5F").join("_")
        .split("%60").join("`")
        .split("%7b").join("{").split("%7B").join("{")
        .split("%7c").join("|").split("%7C").join("|")
        .split("%7d").join("}").split("%7D").join("}")
        .split("%7e").join("~").split("%7E").join("~")
        .split("%80").join("€")
);




