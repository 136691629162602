import React from "react";
import {Component} from "react";
import {Col, CustomInput, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import ActionButton from "../../save-button";
import {downloadSIRatingRatingFile, saveRatingPdfParameters, setEvaluationStatus} from "../../../business-logic/rating";
import {cloneObject, emoj, errorlog, getLastElementOr, onlyNotNull, renderlog, showlog} from "../../../utils";
import {addRowHistoryVersion, getVersionHistory, modifyRowHistoryVersion} from "../../../business-logic/versioning";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {translate} from "../../i18n/translate-function";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Spinner from "../../Spinner";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {isUserRole} from "../../../business-logic/auth";
import {role} from "../../../conf/permissions";
import {EMOJ} from "../../../emoj";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import {LANGUAGES} from "../../../conf/languages";
import moment from "moment";
import {CALENDAR_DATE_FORMAT_IT, DATE_FORMAT_IT, START_MODERN_ISO_TIME} from "../../../conf/siroad";
import HyperDatepicker from "../../Datepicker";

const fieldMap = {
    version:"Versione",
    internalDescription:"Descrizione Interna",
    clientDescription:"Descrizione",
    datetime:"Data Modifica",
    " ": " "
};


const hiddenFields = [
    (!isUserRole( role.PROJECT_MANAGER ))?("internalDescription"):(""),
    (!isUserRole( role.PROJECT_MANAGER ))?(" "):(""),
    "id"
];

const defaultSorted = [
    {
        dataField: 'datetime',
        order: 'desc',
    },
];

const sortableFields = [
    "version",
    "internalDescription",
    "clientDescription",
    "datetime"
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

export default class VersionModal extends Component{

  state = {
      waiting:true,
      data: [],
      isClientVisible: false,
      missingFields:[]

  }

  constructor(props) {
      super(props);

    this.toggle = this.toggle.bind(this);
    this.loadData = this.loadData.bind(this);

  }

  toggle = () => {
    if (!!this.props.onClose) this.props.onClose();
  }

  render(){

      renderlog("Storico Version Modal", this.state, this.props)


        let waitingContent = (
            <Modal isOpen={true} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Storico versioni {this.props.tool.split("-")[0]}
              </ModalHeader>
              <ModalBody className={this.state.bodyClass}>
                  <WaitingOrNodataMessage
                      waiting={ true }
                      waitMessage={translate("Loading_")}
                  />
              </ModalBody>
              {/*<ModalFooter>*/}

              {/*</ModalFooter>*/}
            </Modal>
        )

      let contentNoData = (
          <Modal isOpen={true} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                  Storico versioni {this.props.tool.split("-")[0]}
              </ModalHeader>
              <ModalBody className={this.state.bodyClass}>
                  Non sono presenti informazioni riguardanti lo storico delle versioni
              </ModalBody>
              {/*<ModalFooter>*/}

              {/*</ModalFooter>*/}
          </Modal>
      )

      let errorContent = (
          <Modal isOpen={true} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                  Storico versioni {this.props.tool.split("-")[0]}
              </ModalHeader>
              <ModalBody className={this.state.bodyClass}>
                  Errore ottenimento storico versioni
              </ModalBody>
              {/*<ModalFooter>*/}

              {/*</ModalFooter>*/}
          </Modal>
      )

      let content = (
          <Modal isOpen={true} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                  Storico versioni {this.props.tool.split("-")[0]}
              </ModalHeader>
              <ModalBody className={this.state.bodyClass}>

                      <ToolkitProvider
                          bootstrap4
                          keyField="id"
                          search
                      >
                          {props => (
                              <React.Fragment>
                                  <BootstrapTable
                                      bootstrap4
                                      striped
                                      {...props.baseProps}
                                      size="sm"
                                      responsive
                                      keyField="id"
                                      bordered={ true }
                                      data={ this.state.data }
                                      columns={ formatColumns(Object.keys(this.state.data[0])) }
                                      defaultSorted={ defaultSorted }
                                  />
                              </React.Fragment>
                          )}
                      </ToolkitProvider>

              </ModalBody>

              {
                  ( (!!isUserRole(role.PROJECT_MANAGER)) )?
                      (
                          <ModalFooter>

                              {

                                  ((!!isUserRole(role.PROJECT_MANAGER)) && !this.state.addRowSection && !this.state.selectedRow)?
                                      (
                                          <span className={"in-audit-header-text float-right"}>
                                    <ActionButton
                                        buttonText={"Aggiungi Riga"}
                                        promise={ () => Promise.resolve() }
                                        onResult={result => {
                                            this.setState({
                                                ...this.state,
                                                addRowSection: true
                                            })
                                        }}
                                        onError={e => {
                                            errorlog("errore inserimento versione",e);
                                            this.setState({
                                                ...this.state,
                                                errorMessage: e
                                            })
                                        }}
                                    />
                                </span>
                                      )
                                      :
                                      null

                              }


                              {

                                  (!!this.state.addRowSection)?
                                      (
                                          <div className={"aaa"} style={{width:"100%"}}>

                                              <div className={"bbb"} style={{float:"left"}}>
                                                  <AvForm>

                                                      <Row>
                                                          <Col>
                                                              {/*     DATA  -------------------------------------------------------------------------------- */}
                                                              {/*<AvGroup>*/}
                                                              {/*    <Label for="newdatetime">Data</Label>*/}
                                                              {/*    <AvInput*/}
                                                              {/*        type="text"*/}
                                                              {/*        name="newdatetime"*/}
                                                              {/*        id="newdatetime"*/}
                                                              {/*        value={ "" }*/}
                                                              {/*        onChange={ changeTextEvent => {*/}
                                                              {/*            this.setState({*/}
                                                              {/*                   ...this.state,*/}
                                                              {/*                newDateTime: changeTextEvent.target.value*/}
                                                              {/*                })*/}
                                                              {/*        }}*/}
                                                              {/*        required*/}
                                                              {/*    />*/}
                                                              {/*</AvGroup>*/}

                                                              {/*     VERSIONE  -------------------------------------------------------------------------------- */}
                                                              <AvGroup>
                                                                  <Label for="version">Versione</Label>
                                                                  <AvInput
                                                                      type="text"
                                                                      name="version"
                                                                      id="version"
                                                                      value={ "" }
                                                                      onChange={ changeTextEvent => {
                                                                          this.setState({
                                                                              ...this.state,
                                                                              newVersion: changeTextEvent.target.value,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                     // required
                                                                  />
                                                              </AvGroup>

                                                              {/*     CLIENT DESCRIPTION  -------------------------------------------------------------------------------- */}
                                                              <AvGroup>
                                                                  <Label for="clientdescription">Descrizione</Label>
                                                                  <AvInput
                                                                      type="text"
                                                                      name="clientdescription"
                                                                      id="clientdescription"
                                                                      value={ "" }
                                                                      onChange={ changeTextEvent => {
                                                                          this.setState({
                                                                              ...this.state,
                                                                              newClientDescription: changeTextEvent.target.value,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                      //required
                                                                  />
                                                              </AvGroup>

                                                          </Col>
                                                          <Col>

                                                              {/*     INTERNAL DESCRIPION  -------------------------------------------------------------------------------- */}
                                                              <AvGroup>
                                                                  <Label for="internaldescription">Descrizione Interna</Label>
                                                                  <AvInput
                                                                      type="text"
                                                                      name="internaldescription"
                                                                      id="internaldescription"
                                                                      value={ "" }
                                                                      onChange={ changeTextEvent => {
                                                                          this.setState({
                                                                              ...this.state,
                                                                              newInternalDescription: changeTextEvent.target.value,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                    //  required
                                                                  />
                                                              </AvGroup>
                                                          </Col>

                                                          <Col>
                                                              <Row style={{marginLeft: "40px", marginTop: "37px"}}>
                                                                  <div className={""} style={{marginRight:"14px",fontWeight:"600"}}>- Visibile al cliente </div>
                                                                  <div><CustomInput
                                                                      type="switch"
                                                                      id={"isclientvisible"}
                                                                      name={"isclientvisible"}
                                                                      //label={""}
                                                                      className={"pmAuthorizationToggler"}
                                                                      onClick={clickEvent => {
                                                                          this.setState({
                                                                              ...this.state,
                                                                              isClientVisible: !this.state.isClientVisible,
                                                                              datiMancanti: false
                                                                          })
                                                                      }}
                                                                      checked={ !!this.state.isClientVisible }
                                                                      defaultChecked={ !!this.state.isClientVisible }
                                                                      valid={ !!this.state.isClientVisible }
                                                                  />
                                                                  </div>
                                                              </Row>


                                                              <span
                                                                  className={"history-version-cancel-button"}
                                                                  onClick={clickEvent => {
                                                                      this.setState({
                                                                          ...this.state,
                                                                          addRowSection: false
                                                                      })
                                                                  }}
                                                              > Annulla </span>


                                                              <ActionButton
                                                                  className={"history-version-confirm-add-button2"}
                                                                  buttonText={"Conferma"}
                                                                  promise={ () =>
                                                                  {
                                                                      if(!!this.state.newVersion && (!!this.state.newClientDescription || !!this.state.newInternalDescription) )
                                                                      {
                                                                                  return addRowHistoryVersion({
                                                                                      Datetime: moment().format("YYYY-MM-DDTHH:mm:ss.ms"),
                                                                                      Tool: this .props.tool.split("-")[1],
                                                                                      Version: this.state.newVersion,
                                                                                      ClientDescription: this.state.newClientDescription,
                                                                                      InternalDescription: this.state.newInternalDescription,
                                                                                      isClientVisible: this.state.isClientVisible
                                                                                  })
                                                                              }else{
                                                                                  return Promise.reject("dati mancanti")
                                                                              }
                                                                      }
                                                                  }
                                                                  onResult={result => {
                                                                      this.setState({
                                                                          ...this.state,
                                                                          addRowSection: false
                                                                      },()=>{
                                                                          this.loadData()
                                                                      })
                                                                  }}
                                                                  onError={e => {
                                                                      if(e ==="dati mancanti" && !this.state.datiMancanti){

                                                                          if(!this.state.newVersion) {
                                                                              (this.state.missingFields.push("Versione"))
                                                                          }
                                                                          if(!this.state.newClientDescription && !this.state.newInternalDescription) {
                                                                              (this.state.missingFields.push("almeno uno tra Descrizione interna e Descrizione"))
                                                                          }

                                                                          this.setState({
                                                                              ...this.state,
                                                                              datiMancanti: true
                                                                          })

                                                                      }else{
                                                                          errorlog("errore inserimento versione", e );
                                                                          this.setState({
                                                                              ...this.state,
                                                                              error: e
                                                                          })
                                                                      }
                                                                  }}
                                                              />



                                                          </Col>


                                                      </Row>
                                                  </AvForm>
                                              </div>




                                          </div>
                                      )
                                      :
                                      null

                              }


                              {

                                  (!!this.state.selectedRow)?
                                      (
                                          <div className={"aaa"} style={{width:"100%"}}>

                                              <div className={"bbb"} style={{float:"left"}}>
                                                  <AvForm>

                                                      <Row>
                                                          <Col>
                                                              {/*DATA  -------------------------------------------------------------------------------- */}
                                                              {/*<AvGroup>*/}
                                                              {/*    <Label for="newdatetime">Data</Label>*/}
                                                              {/*    <AvInput*/}
                                                              {/*        type="text"*/}
                                                              {/*        name="newdatetime"*/}
                                                              {/*        id="newdatetime"*/}
                                                              {/*        value={ this.state.selectedRow.datetime }*/}
                                                              {/*        onChange={ changeTextEvent => {*/}
                                                              {/*            this.setState({*/}
                                                              {/*                ...this.state,*/}
                                                              {/*                newDateTime: changeTextEvent.target.value,*/}
                                                              {/*                datiMancanti: false,*/}
                                                              {/*                missingFields: []*/}
                                                              {/*            })*/}
                                                              {/*        }}*/}
                                                              {/*        //required*/}
                                                              {/*    />*/}
                                                              {/*</AvGroup>*/}

                                                              <AvGroup>
                                                                  <Label>Data</Label>
                                                                  <HyperDatepicker
                                                                      whiteBackground={true}
                                                                      hideAddon={ false }
                                                                      dateFormat={ CALENDAR_DATE_FORMAT_IT }
                                                                      onSelect={ clickEvent => {

                                                                          this.setState({
                                                                              ...this.state,
                                                                              startTime: moment( clickEvent ).format( DATE_FORMAT_IT ),
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                      value={ this.state.startTime || this.state.selectedRow.datetime }
                                                                  />
                                                              </AvGroup>



                                                              {/*     VERSIONE  -------------------------------------------------------------------------------- */}
                                                              <AvGroup>
                                                                  <Label for="version">Versione</Label>
                                                                  <AvInput
                                                                      type="text"
                                                                      name="version"
                                                                      id="version"
                                                                      value={ this.state.selectedRow.version }
                                                                      onChange={ changeTextEvent => {
                                                                          this.state.selectedRow.version = null
                                                                          this.setState({
                                                                              ...this.state,
                                                                              newVersion: changeTextEvent.target.value,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                     // required
                                                                  />
                                                              </AvGroup>

                                                          </Col>
                                                          <Col>

                                                              {/*     INTERNAL DESCRIPION  -------------------------------------------------------------------------------- */}
                                                              <AvGroup>
                                                                  <Label for="internaldescription">Descrizione Interna</Label>
                                                                  <AvInput
                                                                      type="text"
                                                                      name="internaldescription"
                                                                      id="internaldescription"
                                                                      value={ this.state.selectedRow.internalDescription }
                                                                      onChange={ changeTextEvent => {
                                                                          this.state.selectedRow.internalDescription = null
                                                                          this.setState({
                                                                              ...this.state,
                                                                              newInternalDescription: changeTextEvent.target.value,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                      //required
                                                                  />
                                                              </AvGroup>

                                                              {/*     CLIENT DESCRIPTION  -------------------------------------------------------------------------------- */}
                                                              <AvGroup>
                                                                  <Label for="clientdescription">Descrizione</Label>
                                                                  <AvInput
                                                                      type="text"
                                                                      name="clientdescription"
                                                                      id="clientdescription"
                                                                      value={ this.state.selectedRow.clientDescription }
                                                                      onChange={ changeTextEvent => {
                                                                          this.state.selectedRow.clientDescription = null
                                                                          this.setState({
                                                                              ...this.state,
                                                                              newClientDescription: changeTextEvent.target.value,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                      // required
                                                                  />
                                                              </AvGroup>

                                                          </Col>

                                                          <Col>
                                                              <Row style={{marginLeft: "40px", marginTop: "37px"}}>
                                                                  <div className={""} style={{marginRight:"14px",fontWeight:"600"}}>- Visibile al cliente </div>
                                                                  <div><CustomInput
                                                                      type="switch"
                                                                      id={"isclientvisible"}
                                                                      name={"isclientvisible"}
                                                                      //label={""}
                                                                      className={"pmAuthorizationToggler"}
                                                                      onClick={clickEvent => {
                                                                          this.setState({
                                                                              ...this.state,
                                                                              isClientVisible: !this.state.isClientVisible,
                                                                              datiMancanti: false,
                                                                              missingFields: []
                                                                          })
                                                                      }}
                                                                      checked={ !!this.state.isClientVisible }
                                                                      defaultChecked={ !!this.state.isClientVisible }
                                                                      valid={ !!this.state.isClientVisible }
                                                                  />
                                                                  </div>
                                                              </Row>


                                                              <span
                                                                  className={"history-version-cancel-button"}
                                                                  onClick={clickEvent => {
                                                                      this.setState({
                                                                          ...this.state,
                                                                          selectedRow: false
                                                                      })
                                                                  }}
                                                              > Annulla </span>


                                                              <ActionButton
                                                                  className={"history-version-confirm-add-button2"}
                                                                  buttonText={"Conferma"}
                                                                  promise={ () =>
                                                                  {
                                                                      if(
                                                                          (!!this.state.newVersion || !!this.state.selectedRow.version) &&
                                                                          (
                                                                              (!!this.state.newClientDescription || !!this.state.selectedRow.clientDescription) ||
                                                                              (!!this.state.newInternalDescription || !!this.state.selectedRow.internalDescription)
                                                                          )
                                                                      )
                                                                      {
                                                                          return modifyRowHistoryVersion({
                                                                              Id: this.state.selectedRow.id,
                                                                              Datetime: (!!this.state.startTime) ?
                                                                                  ((this.state.startTime.split("/")[2] + "-" + this.state.startTime.split("/")[1] + "-" + this.state.startTime.split("/")[0] + "T" + moment().format("YYYY-MM-DDTHH:mm:ss.ms").split("T")[1]))
                                                                                  : (this.state.selectedRow.datetime + "T" + moment().format("YYYY-MM-DDTHH:mm:ss.ms").split("T")[1]),
                                                                              Tool: this.props.tool.split("-")[1],
                                                                              Version: this.state.newVersion || this.state.selectedRow.version,
                                                                              ClientDescription: this.state.newClientDescription || this.state.selectedRow.clientDescription,
                                                                              InternalDescription: this.state.newInternalDescription || this.state.selectedRow.internalDescription,
                                                                              isClientVisible: this.state.isClientVisible
                                                                          })
                                                                      }else{
                                                                          return Promise.reject("dati mancanti")
                                                                      }
                                                                  }
                                                              }
                                                                  onResult={result => {
                                                                      this.setState({
                                                                          ...this.state,
                                                                          startTime: false,
                                                                          selectedRow: false
                                                                      },()=>{
                                                                          this.loadData()
                                                                      })
                                                                  }}
                                                                  onError={e => {
                                                                      if(e ==="dati mancanti" && !this.state.datiMancanti){

                                                                          if(!this.state.newVersion && !this.state.selectedRow.version)
                                                                          {
                                                                              (this.state.missingFields.push("Versione"))
                                                                          }
                                                                          if( (!this.state.newClientDescription && !this.state.selectedRow.clientDescription) &&
                                                                              (!this.state.newInternalDescription && !this.state.selectedRow.internalDescription))
                                                                          {
                                                                              (this.state.missingFields.push("almeno uno tra Descrizione interna e Descrizione"))
                                                                          }

                                                                          this.setState({
                                                                              ...this.state,
                                                                              datiMancanti: true
                                                                          })

                                                                      }else{
                                                                          errorlog("errore modifica versione", e );
                                                                          this.setState({
                                                                              ...this.state,
                                                                              error: e
                                                                          })
                                                                      }
                                                                  }}
                                                              />


                                                          </Col>
                                                      </Row>
                                                  </AvForm>
                                              </div>
                                          </div>
                                      )
                                      :
                                      null

                              }

                              {
                                  (!!this.state.datiMancanti)?
                                      (
                                          <span style={{color: "#f33", fontStyle: "italic"}}>
                                              I seguenti campi sono obbligatori:
                                              {" "}
                                              {this.state.missingFields.join(", ")}
                                          </span>)
                                      :
                                          null
                              }



                          </ModalFooter>
                      ):
                      null
              }

          </Modal>
      )

      if(!!this.state.waiting){
          return waitingContent
      }else if(!this.state.waiting && this.state.data.length === 0){
          return contentNoData
      }else if(!this.state.waiting && this.state.data.length > 0){
          return content
      }else{
          return errorContent
      }

  }

  componentDidMount() {
      this.loadData()
  }

  loadData(){
      getVersionHistory(this.props.tool.split("-")[1])
          .then(result =>{

              result.map( row =>{

                  row.datetime = row.datetime.split("T")[0]
                  row.version = row.version.trim()
                  row[" "] = (
                      <i
                          className="mdi mdi-pencil"
                          style={{marginLeft:"15px"}}
                          onClick={ clickEvent => {
                              this.setState({
                                  ...this.state,
                                  addRowSection: false,
                                  selectedRow: row,
                                  datiMancanti:false,
                                  missingFields: []
                              });
                          }}
                      />
                  )

              })

              return result

          })
          .then( result => {
              result.map( (row,index) => {
                  if(index===0){

                      if(this.props.tool.split("-")[1] === "1"){
                          localStorage.setItem("srToolVersion", row.version.trim())
                      }
                      else if(this.props.tool.split("-")[1] === "2"){
                          localStorage.setItem("siRatingVersion", row.version.trim())
                      }
                      else if(this.props.tool.split("-")[1] === "3"){
                          localStorage.setItem("siScoringVersion", row.version.trim())
                      }
                      else if(this.props.tool.split("-")[1] === "4"){
                          localStorage.setItem("paritaGenereVersion", row.version.trim())
                      }
                  }
              })
              return result
          })
          .then(result =>{
              this.setState({
                  ...this.state,
                  data: result,
                  waiting:false
              })
          })
          .catch(e => {
              errorlog("load data version history", e);
              this.setState({
                  ...this.state,
                  error: e
              })
          })
  }
}
