import React, {Component} from "react";


export default class PublicLayout extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        // get the child view which we would like to render
        const children = this.props.children || null;

        return (
            <div className="app">
                { children }
                {/*
                <div className="wrapper">
                    <div className="content-page">
                        <div className="content">
                            <Container fluid>
                                {children}
                            </Container>
                        </div>
                    </div>
                </div>
                */}
            </div>
        );
    }


}
