import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {errorlog, showlog} from "../utils";
import ActionButton from "./save-button";
import {PATH_PREFIX} from "../conf/siroad";
import {archiveRating} from "../business-logic/rating";
import {translate} from "./i18n/translate-function";


export const ArchiveRatingConfirmModal = props => {

	const [inWait, inWaiting] = useState( false );

	useEffect( () => {
		// callBack di cambio state
		if( !!inWait ) showlog("...in attesa");
	},
		[inWait]
	);


		let content = (
			<Modal isOpen={ true } >
				<ModalHeader className={""}>
					<span>{translate("Alert")}</span>
				</ModalHeader>
				<ModalBody >
					<span>{translate("Are you sure you want to archive evaluation")} { props.name } ?</span>
				</ModalBody>
				<ModalFooter>

					<ActionButton
						buttonText={"OK"}
						promise={ () => archiveRating(props.id) }
						onProcess={ () => {
							inWaiting( true );
						}}
						onResult={ result => {
							window.location.href = PATH_PREFIX + "/" + "si-scoring" + "/pm/" + translate("evaluations");
						}}
						onError={ error => {
							errorlog("Audit con commento", error );
						}}
					/>

					{' '}

					<Button
						color="link"
						onClick={ clickEvent => {
							props.onClose();
						}}
					>
						{translate("Cancell")}
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;

}
