import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {WaitingOrNodataMessage} from "../components/waiting-or-nodata-message";
import {PATH_PREFIX, TABS} from "../conf/siroad";
import PageTitle from "../components/PageTitle";
import {Card, CardBody, CardHeader, Collapse, Input, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {cloneObject, decodeMarkDown, errorlog, getLastElementOr, renderlog, showlog} from "../utils";
import {COLOR_POLICY} from "../conf/color-policy";
import {SIRATING_TABS} from "../conf/sirating";
import {loadAllFlatQuestions} from "../business-logic/rating";
import "../style/edit-question.css";
import GotoTopComponent from "../components/goto-top-component";
import {translate} from "../components/i18n/translate-function";
import SiScoringLogoDarkText from "../assets/images/Si_Scoring_logo_dark_text_2.svg";
import "../style/sr-tool-edit-page.css";
import Spinner from "../components/Spinner";
import ActionButton from "../components/save-button";
import {FullEditQuestionModal} from "../components/edit-question-modal_v2";
import VersionModal from "../components/area/super-admin/version-modal";


class EditSiScoringQuestionPage extends Component {

	state = {
		activeTab: 1
	}

	constructor( props ) {
		super( props );

		this.loadData = this.loadData.bind( this );
	}

	render() {

		renderlog("SiScoring EditQuestionPage", this.state, this.props, COLOR_POLICY.select)

		let languageSelector = localStorage.getItem("language").substr(0, 2).toLowerCase();

		let content = (

			(!!this.state.data && this.state.data.length > 0)
				? (
					this.state.data
						.map( question => (
							<Card className={"srtool-edit-page-card"} key={ question.id }>
								<CardHeader
									// className={"tooltip-icon-cursor"+ (!!question.open ? (" open-question cp-"+ this.state.activeTab) : "")}
									className={"srtool-edit-page-card-header"}
									onClick={ clickEvent => {
										this.setState({
											...this.state,
											data:
												this.state.data.map( d => {
													if ( d.id === question.id ) d.open = !question.open;
													return d;
												})
										})
									}}
								>
									<div className={"editable-q-a-element"} id={"question-"+ question.id}>
										<div
											className={
												(!!this.state.deletingWait && (this.state.deletingWait === `question-${ question.id }`))
													? " in-deleting-wait"
													: ""
											}
										>
											<code>{ question.thematic_code }</code> &nbsp;&nbsp; <code><strong>{ question.code }</strong></code> &nbsp;&nbsp; { decodeMarkDown( question.text[ languageSelector ], {noSpan: true} ) }<br/>
											<span className={"score-label no-margin-left"}>{translate("Max Score")}: <strong>{question.scoreMax}</strong></span>
											{
												(!!this.state.deletingQuestionError && (this.state.deletingQuestionError === `question-${ question.id }`))
													? (<span className={"error-message-label"}><br/><br/>{ this.state.questionError }</span>)
													: null
											}
										</div>
										<div className={"editable-q-a-comander-container siscoring"}>

											{
												(!!this.state.deletingWait && (this.state.deletingWait === `question-${ question.id }`))
													? <Spinner color={ "danger" } className="spinner-border-sm" />
													: (
														<React.Fragment>
															<i
																className="mdi mdi-pencil tooltip-icon-cursor"
																onClick={ clickEvent => {
																	clickEvent.stopPropagation();
																	this.setState({
																		...this.state,
																		questionModal: question
																	})
																}}
															/>
															{/*  TRASH ICON --------------------------------------------------------------------------------------------------
															<i
																className="mdi mdi-trash-can-outline edit-question-icon-trash comander-icon-trash"
																onClick={ clickEvent => {
																	showlog("elimina ", question);
																	clickEvent.stopPropagation();
																	clickEvent.preventDefault();

																	let deleteDecision = window.confirm( translate("sure_want_delete_question") );

																	if( !!deleteDecision ) {

																		// deletingWait
																		this.setState({
																				...this.state,
																				deletingWait: `question-${ question.id }`,
																				deletingQuestionError: null
																			},
																			() => {

																				deleteSiScoringQuestion( question.id )
																					.then( result => {
																						// stop deletingWait
																						this.setState({
																							...this.state,
																							fullData:
																								this.state.fullData
																									.filter( row => row.id !== question.id )
																							,
																							data:
																								this.state.data
																									.filter( row => row.id !== question.id )
																							,
																							deletingWait: null
																						});
																					})
																					.catch(e => {
																						errorlog("delete siscoring question", e);
																						this.setState({
																							...this.state,
																							deletingQuestionError: `question-${ question.id }`,
																							questionError: extractErrorMessage( e ),
																							deletingWait: null
																						});
																					})


																			}
																		)
																	}

																	// this.setState({...this.state, deletePopup: row});
																}}
															/>
															*/}
														</React.Fragment>
													)
											}



										</div>
									</div>
								</CardHeader>
								<Collapse isOpen={ !!question.open }>
									<CardBody>


										<ActionButton
											buttonText={
												<React.Fragment>
													<i className={"mdi mdi-plus-circle"}/>
													<span>{ translate("add_choiche") }</span>
												</React.Fragment>
											}
											promise={ () => {
												return (new Promise((onEnd, onFail) => {

													this.setState({
															...this.state,
															questionModal: this.state.fullData.reduce( getLastElementOr, {choiches: []} ).choiches.reduce( getLastElementOr, {} ),
															isNewQuestion: true,
															isChoiche: true,
															fatherQuestion: question
														},
														() => {
															onEnd();
														}
													)
												}))
											}}
											onResult={ result => {
											}}
											onError={ error => {
												errorlog("Salvataggio edit domanda", error)
											}}
											className={'srtool-edit-modal-button srtool-edit-modal-add-button'}
										/>

										<ul className={"editable-answer-container cp-"+ this.state.activeTab}>
											{

												!!question.isOpenQuestion
													? null
													: (
														question.choiches
															.map( (ch, chIndex) => (
																<li className={"editable-q-a-element editable-single-answer cpa-"+ this.state.activeTab} id={"choiche-"+ ch.id} key={ question.id +"."+ chIndex }>
																	<div
																		className={
																			(!!this.state.deletingChoicheWait && (this.state.deletingChoicheWait === `choiche-${ ch.id }`))
																				? " in-deleting-wait"
																				: ""
																		}
																	>
																		{ ch.text[ languageSelector ] +` ( ${ ch.score } )` }
																		{
																			(!!this.state.deletingQuestionError && (this.state.deletingQuestionError === `choiche-${ ch.id }`))
																				? (<span className={"error-message-label"}><br/><br/>{ this.state.questionError }</span>)
																				: null
																		}
																	</div>
																	<div>

																		{
																			(!!this.state.deletingChoicheWait && (this.state.deletingChoicheWait === `choiche-${ ch.id }`))
																				? <Spinner color={ "danger" } className="spinner-border-sm" />
																				: (
																					<React.Fragment>
																						<i
																							className="mdi mdi-pencil tooltip-icon-cursor"
																							onClick={ clickEvent => {
																								clickEvent.stopPropagation();
																								this.setState({
																									...this.state,
																									questionModal: ch,
																									isChoiche: true,
																									isNewQuestion: false
																								})
																							}}
																						/>
																						{/*  TRASH ICON --------------------------------------------------------------------------------------------------
																						<i
																							className="mdi mdi-trash-can-outline edit-question-icon-trash comander-icon-trash"
																							onClick={ clickEvent => {
																								showlog("elimina ", ch);
																								clickEvent.stopPropagation();
																								clickEvent.preventDefault();

																								let deleteDecision = window.confirm( translate("sure_want_delete_answer") );

																								if( !!deleteDecision ) {

																									// deletingWait
																									this.setState({
																											...this.state,
																											deletingChoicheWait: `choiche-${ ch.id }`,
																											deletingQuestionError: null
																										},
																										() => {


																											eraseAnswer( ch.id )
																												.then( result => {
																													this.setState({
																														...this.state,
																														// eliminazione scelta
																														fullData:
																															this.state.fullData
																																.map( q => {
																																	q.choiches =
																																		q.choiches
																																			.filter( ans => ans !== ch.id )
																																	;
																																	return q;
																																})
																														,
																														data:
																															this.state.data
																																.map( q => {
																																	q.choiches =
																																		q.choiches
																																			.filter( ans => ans !== ch.id )
																																	;
																																	return q;
																																})
																														,
																														// stop deletingWait
																														deletingChoicheWait: null
																													});
																												})
																												.catch(e => {
																													errorlog("delete siscoring question", e);
																													this.setState({
																														...this.state,
																														deletingQuestionError: `choiche-${ ch.id }`,
																														questionError: extractErrorMessage( e ),
																														deletingChoicheWait: null
																													});
																												})

																										})
																									;

																								}




																							}}
																						/>
																						*/}
																					</React.Fragment>
																				)
																		}


																	</div>
																</li>
															))
													)
											}
										</ul>
									</CardBody>
								</Collapse>
							</Card>
						))
				)
				: (
					<WaitingOrNodataMessage
						waiting={ !this.state.data }
						fullHeight={true}
						nodataMessage={"Nessuna Domanda"}
					/>
				)

		);


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{label: 'SI Scoring', path: '' + PATH_PREFIX + '/sirating/' + translate("edit-question")},
						{label: translate("Edit Questions"), active: true}
					]}
					title={
						<React.Fragment>
							<img
								src={ SiScoringLogoDarkText }
								style={{
									marginRight:'10px',
									height: "60px",
									float: "left",
									marginTop: "10px"
								}}
							/>

							<p style={{float:"left", marginTop: "27px",
								marginBottom: "-23px",
								fontSize: "14px",cursor:"pointer",height:"0px"}}
							   onClick={clickEvent => {
								   this.setState({
									   versionModal: true
								   })
							   }
							   }> v. {(localStorage.getItem("siScoringVersion")).trim()} </p>
						</React.Fragment>
					}
				/>

				{
					!!this.state.versionModal
						? (
							<VersionModal
								tool={"SI Scoring-3"}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										versionModal: false
									})
								}}/>
						)
						:
						null
				}

				{
					!!this.state.questionModal
						? (
							<FullEditQuestionModal
								avoidToolbar={ true }
								question={ this.state.questionModal }
								fatherQuestion={ this.state.fatherQuestion }
								isNew={ !!this.state.isNewQuestion }
								isChoiche={ !!this.state.isChoiche }
								tab={ this.state.activeTab }
								themes={ this.state.themes }
								isGL={ this.state.modalIsGL }
								onClose={ toggleEvent => {
									let questionsTmp = cloneObject(this.state.fullData);
									if( !!toggleEvent ) this.loadData( questionsTmp );
									else {
										this.setState({
											...this.state,
											questionModal: null,
											modalIsGL: null,
											isChoiche: null,
											isNewQuestion: false,
											fatherQuestion: null
										})
									}
								}}
							/>
						)
						: null
				}

				<Card>
					<CardHeader>

						<Nav tabs>
							{
								SIRATING_TABS
									.map((tab, index) => {

										return (
											<NavItem key={index}>
												<NavLink
													id={tab.id}
													href="#"
													className={
														Object.keys(TABS)
															.map( tabKey => TABS[tabKey] )
															.filter( t => t.id === tab.id )
															.reduce( getLastElementOr, { className: "" } )
															.className
														+ (( this.state.activeTab === tab.id) ? " tab-active" : "")
														+ " edit-srtool-tab"
													}
													onClick={() => {

														this.setState({
																...this.state,
																activeTab: tab.id,
																data: null
															},
															() => {
																this.loadData();
															}
														);
													}}>
													<span className="d-none d-lg-block">{ tab.name }</span>
													{/*
														( this.state.activeTab === tab.id)
															? (
																<i
																	className="mdi mdi-pencil tooltip-icon-cursor"
																	onClick={ clickEvent => {
																		clickEvent.stopPropagation();
																		// setPopupNode( tab );
																	}}
																/>
															)
															: null
													*/}
												</NavLink>
											</NavItem>
										);

									})
							}

							<div className={"search-container"}>
								<Input
									type={"text"}
									placeholder={"cerca"}
									className={"rating-name-input"}
									onChange={ changeEvent => {
										let searchText = changeEvent.target.value;



										if( !!searchText ) {
											this.setState({
												...this.state,
												data:
													this.state.fullData
														.filter( question => (!!question && !!question.text && !!question.text[ languageSelector ]))
														.filter( question => (

															Object.keys( question.text )
																.map( lan => question.text[ lan ] )
																.map( lanText => (lanText.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1))
																.some( match => !!match )

															||

															question.choiches
																.some( ch => (
																	Object.keys( ch.text )
																		.map( lan => ch.text[ lan ] )
																		.map( lanText => (lanText.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1))
																		.some( match => !!match )
																))

														))
											})
										}
										else {
											this.setState({
												...this.state,
												data: this.state.fullData
											})
										}

									}}
								/>
							</div>
						</Nav>


						<TabContent activeTab={this.state.activeTab}>
							{
								SIRATING_TABS
									.map((tab, index) => {
										return (
											<TabPane
												tabId={tab.id}
												key={index}
											>

												<CardBody>

													<ActionButton
														buttonText={
															<React.Fragment>
																<i className={"mdi mdi-plus-circle"}/>
																<span>{ translate("new_question") }</span>
															</React.Fragment>
														}
														promise={ () => {

															return (new Promise((onEnd, onFail) => {

																this.setState({
																	...this.state,
																	questionModal: this.state.fullData.reduce( getLastElementOr, {} ),
																	isNewQuestion: true
																},
																	() => {
																		onEnd();
																	}
																)


															}))

														}}
														onResult={ result => {
														}}
														onError={ error => {
															errorlog("Salvataggio edit domanda", error)
														}}
														className={'srtool-edit-modal-button srtool-edit-modal-add-button'}
													/>

													{ content }
												</CardBody>

											</TabPane>
										)
									})
							}

							<GotoTopComponent/>

						</TabContent>

					</CardHeader>
				</Card>

			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
	}

	loadData( questionList ) {
		return (

			Promise.resolve()

				.then( () => {
					return loadAllFlatQuestions( this.state.activeTab +3 );
				})

				.then( list => {

					showlog("LIST",list)

					let thematicCodes =   ([
						... new Map( list.map( data => [ data.thematic_code, data ]) ).values()
					].map( data => data.thematic_code))
					showlog("THEMATIC CODES" , thematicCodes)
					let lista = []

					for ( let i = 0; i<thematicCodes.length; i++){

						lista = lista.concat(list.filter(q => q.thematic_code.split(".")[1] == thematicCodes[i].split(".")[1] ).sort((a,b) => {
							return (a.code.split(".")[1] - b.code.split(".")[1])
						}))

						// let listamonentanea = list.filter(q => q.thematic_code.split(".")[1] == thematicCodes[i].split(".")[1] ).sort((a,b) => {
						// 	return (a.code.split(".")[1] - b.code.split(".")[1])
						// })
						//
						// showlog("LIST"+ i, listamonentanea)

					}

					showlog("LISTA",lista)



					//
					// let list1 = list.filter(q => q.thematic_code.split(".")[1] == 1 ).sort((a,b) => {
					// 	return (a.code.split(".")[1] - b.code.split(".")[1])
					// })
					// showlog("LIST1",list1)
					//
					//
					// let list2 = list.filter(q => q.thematic_code.split(".")[1] == 2 ).sort((a,b) => {
					// 	return (a.code.split(".")[1] - b.code.split(".")[1])
					// })
					// showlog("LIST2",list2)
					//
					//
					// let list3 = list.filter(q => q.thematic_code.split(".")[1] == 3 ).sort((a,b) => {
					// 	return (a.code.split(".")[1] - b.code.split(".")[1])
					// })
					// showlog("LIST3",list3)
					//
					//
					// let lista = list1.concat(list2).concat(list3)
					// showlog("LISTA", lista )


					list =
						lista
							// .sort((a, b) => {
							// 	if(a.thematic_code < b.thematic_code)
							// 	{
							// 		return -1
							// 	}
							// 	if(a.thematic_code > b.thematic_code)
							// 	{
							// 		return 1
							// 	}
							// 	if (a.thematic_code === b.thematic)
							// 	{
							// 		return a.code.split(".")[1] - b.code.split(".")[1]
							// 	}
							// })
							.map( q => {

								if( !!questionList ) {
									let clonedQuestion =
										questionList
											.filter( ql => ql.id === q.id )
											.reduce( getLastElementOr, { open: false } )
									;
									q.open = !!clonedQuestion.open;
								}

								return q;
							})
					;



					return (

						new Promise((success, fail) => {
							this.setState({
								...this.state,
								data: list,
								fullData: list,
								themes:
									[
										...new Map(
											list
												.map( row => {
													if( !!row.subThematic && !!row.subThematic.name ) {
														return [row.subThematic.id, row.subThematic];
													}
													else {
														if( !!row.thematic && !!row.thematic.name ) {
															return [row.thematic.id, row.thematic];
														}
													}
												})
										)
										.values()
									]
								,
								questionModal: null,
								modalIsGL: null,
								isChoiche: null,
								isNewQuestion: false,
								fatherQuestion: null
							},
								() => {
									success();
								}
							);
						})

					);

				})

				.catch(e => {
					errorlog("caricamento piatto delle domande", e);
					return Promise.reject( e );
				})

		);
	}
}

export default withRouter(EditSiScoringQuestionPage);
