import React, {Component} from "react";
import {
    Col,
    Modal,
    ModalBody,
    ModalHeader, Nav, NavItem, NavLink,
    PopoverBody,
    PopoverHeader,
    Row, TabContent,
    Table, TabPane,
    UncontrolledPopover
} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog, showlog} from "../../../utils/dev-utils";
import {isNotEmptyArray, onlyNotNull} from "../../../utils/array-utils";
import {COLOR_POLICY} from "../../../env";
import {QuestionReport_Get} from "../../../services/request-manager";
import "../../../style/collect-report-modal.css";
import {getCollectReport} from "../../../business-logic/get-collect-report";


export default class CollectQuestionReport extends Component {

    constructor(props) {
        super(props);

        this.state = {

            activeTab : "5",
            className : [

                {
                    id : "5",
                    name:"general-tab"
                }
                , {
                    id : "2",
                    name:"economic-tab"
                },
                {
                    id : "3",
                    name:"environment-tab"
                },
                {
                    id : "4",
                    name:"social-tab"
                }

            ],
            activeTabStyle : [

                {
                    id : "5",
                    name:"general-tab-active"
                }
                , {
                    id : "2",
                    name:"economic-tab-active"
                },
                {
                    id : "3",
                    name:"environment-tab-active"
                },
                {
                    id : "4",
                    name:"social-tab-active"
                }

            ],
            tab : [
                {
                    id : "5",
                    name:"GENERALE"
                }
                , {
                    id : "2",
                    name:"ECONOMICO"
                },
                {
                    id : "3",
                    name:"AMBIENTALE"
                },
                {
                    id : "4",
                    name:"SOCIALE"
                }
            ]
        }

        this.toggle = this.toggle.bind(this);

    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }

    componentDidMount(){}

    render() {

    renderlog("CollectQuestionReport", this.state, this.props, COLOR_POLICY.validated);

           let context = (

                <div>
                    <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                        <Col>
                            {/*<br />*/}


                            <Table>
                                <thead >

                                    <Col className={"colPadding"}>

                                    <tr >
                                        {
                                            this.state.tab
                                                .map(tab => {

                                                        return (

                                                        <th>
                                                            <Nav tabs>
                                                                <NavItem key={`key_tab-${tab.id}`} style={{marginLeft: "-0.9vh"}}>
                                                                    <NavLink
                                                                       className={ this.state.className.filter( className =>  className.id == tab.id ).map( className => className.name ) }
                                                                        id={tab.id}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                activeTab: tab.id
                                                                            }, ()=>{showlog("TAB ID", tab.id, (this.state.className.filter( className =>  className.id == tab.id ).map( className => ( className.name).toString() )) )});
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={"srtool-comander-container" + ( (this.state.activeTab == tab.id) ? (" " + this.state.activeTabStyle.filter( activeTabStyle =>  activeTabStyle.id == tab.id ).map( className => className.name )) : "" ) }>{tab.name}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>

                                                        </th>

                                                        )

                                                    }

                                                )
                                        }

                                        <th>
                                        <span>
                                            {"Completamento informative: " + this.props.percentage + "%"}
                                        </span>
                                        </th>

                                    </tr>
                                    </Col>

                                </thead>
                                <tbody>


                                        {

                                            this.state.tab
                                            .map(tab => {

                                                return (

                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId={tab.id}>
<div className={"tabella"}>

                                                            {
                                                                ((this.props.tabToFilter).includes(parseInt(tab.id)))
                                                                    ?
                                                                   (   <tr>
                                                                       <th style={{ background: '#dad8fd' }}>Capitolo</th>
                                                                       <th style={{ background: '#dad8fd' }} className={"tableBorderLeft2"}>Informativa</th>
                                                                       <th style={{ background: '#dad8fd' }} className={"tableBorderLeft2"}>Requisito</th>
                                                                       <th style={{ background: '#dad8fd' }} className={"tableBorderLeft2"}>Sottorequisito</th>
                                                                       <th style={{ background: '#dad8fd' }} className={"tableBorderLeft2"}>Sottosottorequisito</th>
                                                                   </tr>  )
                                                                    : (
                                                                        <div style={{marginTop: "10vh",alignContent: 'center', textAlign: 'center'}}>
                                                                        MACROAREA NON APPLICATA
                                                                        </div>
                                                                    )

                                                            }



                                                            {
                                                                this.props.data.filter((data) => data.tabId == tab.id)
                                                                .map((data,i) => {

                                                                        return (

                                                                            <tr key={i}>
                                                                                <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("")} style={{alignContent: 'left', textAlign: 'left'}}>{data.chapterName}</td>
                                                                                <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("tableBorderLeft2")}>{data.informativeCode}</td>
                                                                                <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("tableBorderLeft2")}>{data.requirement}</td>
                                                                                <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("tableBorderLeft2")}>{data.requirement_sub1}</td>
                                                                                <td id={i} className={(i != 0 && (i % 2 != 0))?("rigascura tableBorderLeft2"):("tableBorderLeft2")}>{data.requirement_sub2}</td>
                                                                            </tr>

                                                                        )
                                                                    }
                                                                )
                                                            }

</div>

                                                        </TabPane>
                                                    </TabContent>

                                                )
                                            }
                                            )
                                        }

                                </tbody>
                            </Table>

                        </Col>
                    </Row>

                </div>
            );


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Report Compilazione Raccolta
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        let contentNodata = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Report Compilazione Raccolta
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    Nessun dato
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );


        if (!!this.props.data) {
            return content;
        } else {
            return contentNodata;
        }


    }

}
