import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import React from "react";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {GRI_ADMIN_TREE_DEEP_LEVELS_NAME} from "../../../env";
import {saveNewZone, saveTreeNodeValue} from "../../../business-logic/gri";
import Spinner from "../../Spinner";
import classnames from 'classnames';
import {renderlog} from "../../../utils/dev-utils";


export default class StandBySystemModalComponent extends React.Component {

	state = {}

	constructor( props ) {
		super( props );
	}

	render() {

		renderlog(
			"StandBySystemModalComponent",
			this.state,
			this.props
		);

		return (
			<Modal isOpen={ true } className={"waiting-modal"}>
				<ModalBody className={this.state.bodyClass}>
					<div className="d-flex justify-content-center">
						<Spinner color={"secondary"} />
					</div>
				</ModalBody>
			</Modal>
		);

	}

	componentDidMount() {
		if( typeof this.props.didMount === "function" ) this.props.didMount();
	}

};
