import React, {Component} from 'react';
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from '../../../env';
import ActionButton from '../../../components/save-button';
import {validationSuperAdminNewUser} from '../../../business-logic/companies';
import {editUserBySuperAdmin, saveNewUserBySuperAdmin} from '../../../business-logic/users';

export default class ConfirmNewUserModal extends Component {

    state = {

    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

    }

    toggle = () => {
        this.props.onClose();
    }


    render() {


        renderlog(
            "ConfirmNewUserModal",
            this.state,
            this.props,
            COLOR_POLICY.warning
        );
        showlog(" (this.props.esternalProps.match.params.id || this.props.esternalProps.id) SECONDO", this.props.esternalPropsUser, this.props.esternalStateUser);


        let content = (
            <Modal isOpen={true} toggle={this.toggle} className={"confirm-arb-modal"}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    {
						(!!!this.props.isNewUser)
							? "Modifica Utente"
							: "Nuovo Utente"
					}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {
						(!!!this.props.isNewUser)
							? "Confermi di voler modificare il nuovo utente?"
							: "Confermi di voler creare il nuovo utente?"
					}
				</ModalBody>
                <ModalFooter className={this.state.bodyClass}>
                    <Row>
                        <Col>
                            <ActionButton
                                buttonText={"Conferma"}
                                //promise={ () => acceptEUPA( props.match.params.id || props.id ) }
                                //onResult={ () => setGoAhead( true ) }
                                //onError={ error => {
                                //	setErrorMessage( error );
                                //	// setGoAhead( true );	// test
                                //}}
                                promise={() => {

                                    return (

                                        // validationSuperAdminNewUser(this.props.esternalStateUser, !!this.props.esternalPropsUser)
                                        validationSuperAdminNewUser(this.props.esternalStateUser, !this.props.isNewUser)
                                            .then(() => {


                                                // showlog(this.state);

                                                showlog("preif")
                                                if (!!!this.props.isNewUser) {


                                                    showlog("edit user");
                                                    showlog(this.props.esternalStateUser);
                                                    return editUserBySuperAdmin(this.props.esternalStateUser);
                                                }
                                                else {

                                                    showlog("add user");
                                                    saveNewUserBySuperAdmin(this.props.esternalStateUser)
                                                        .then(() => {

                                                            return true;
                                                        })
                                                        .catch(e => {
                                                            errorlog("errore all'inserimento");
                                                            showlog(e);

                                                            let errorMessage = "";
                                                            if (typeof e === "string") errorMessage = e;
                                                            else {
                                                                if (typeof e === "object" && !!e.msg) errorMessage = e.msg;
                                                                else {
                                                                    if (typeof e === "object") errorMessage = JSON.stringify(e);
                                                                }
                                                            }

                                                            return (
                                                                new Promise((success, fail) => {

                                                                    this.setState({
                                                                        ...this.state,
                                                                        errors: {
                                                                            ...this.state.errors,
                                                                            email: errorMessage
                                                                        }
                                                                    },
                                                                        () => {
                                                                            success();
                                                                        }
                                                                    )

                                                                })
                                                            );



                                                        })



                                                }
                                            })
                                            .catch(e => {
                                                // debugger;
                                                showlog("errore catch", e)
                                                return Promise.reject(e);
                                            })

                                            )

                                }
                                }
                                onResult={() => {

                                    this.props.onClose( !!this.props.isNewUser )


                                }}
                            />
                        </Col>
                        <Row>
                            <Col>
                                <ActionButton
                                    // buttonText={"Torna indietro"}
                                    color={"link"}
                                    buttonText={"Annulla"}

                                    //promise={ () => Promise.resolve() }
                                    //onResult={ () => {
                                    //	setSegnalationPopUp(true)
                                    //} }
                                    //onError={ error => {
                                    //	setErrorMessage( error );
                                    //	// setGoAhead( true );	// test
                                    //}}
                                    promise={() => Promise.resolve()}
                                    onResult={() => {

                                        this.props.onClose()

                                    }}
                                />
                            </Col></Row>
                    </Row>
                </ModalFooter>
            </Modal>
        );

        return content;
    }

}
