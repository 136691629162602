import React from "react";
import {renderlog, showlog} from "../../../utils";
import {COLOR_POLICY} from "../../../conf/color-policy";
import {Modal, ModalBody} from "reactstrap";
import SRToolCollectChartFilters from "./srtool-collect-chart-filters";
import SuperAdminChartFilter from "./super-admin-chart-filter";
import {setSuperAdminDashboardFilter} from "../../../business-logic/super-admin-dashboard";

export const SuperAdminChartSettingsModal = props => {
	
	renderlog("ChartSettingsModal", null, props, COLOR_POLICY.disabled);
	
	const toggle = () => {
		if(!!true) props.onClose();
	}
	
	
	
	let content = (
		<Modal isOpen={ true } toggle={toggle} className="rounded-modal-container">
			<ModalBody className={""}>
				<SuperAdminChartFilter
					conf={ props.configuration }
					tab={ props.tab }
					arg={ data => {
						showlog("uscita filtri");
						showlog( data );
						// this.setState({arg:data})
					}}
					onSave={ data => {
						
						/*
						
						chartId: undefined
						collectionCompare_select: 0
						graphType_select: 0
						id: 97
						selectedCollect: 100
						selectedCollectName: "prova111"
						timing_select: 0
						type: null
						
						thematic_id: 2
						chapter_id: 8
						informative_id: 62
						requirement_id: 97
						subrequirement: undefined
						subsubrequirement: undefined
						props.chart
						
						
						Chiamata
						/GriReports/setFilter/{GraphId}

						{
						  "griThematicId": 0,
						  "griChapterId": 0,
						  "griInformativeId": 0,
						  "griInformativeRequirement": 0,
						  "griInformativeRequirementSub1": 0,
						  "griInformativeRequirementSub2": 0,
						  }
						 */
						
						
						let payload = {
							griThematicId: data.thematic_id || 0,
							griChapterId: data.chapter_id || 0,
							griInformativeId: data.informative_id || 0,
							griInformativeRequirement: data.requirement_id || 0,
							griInformativeRequirementSub1: data.subrequirement || 0,
							griInformativeRequirementSub2: data.subsubrequirement || 0
						};
						
						showlog("data per la chiamata");
						showlog(payload);
						
						setSuperAdminDashboardFilter( props.chart, props.year, payload)
							.then( result => {
								props.onSaveConfiguration( payload );
							})
							.catch( e => {
								props.onClose( e );
							})
						
						// debugger;
						
					}}
				/>
			</ModalBody>
		</Modal>
	);
	
	
	return content;
	
}
