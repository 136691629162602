import React, {Component} from "react";
import {Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {translate} from "../i18n/translate-function";
const { SearchBar } = Search;


/*
const fieldMap = {
	partitaIva: "Partita Iva",
	ragioneSociale: "Ragione Sociale",
	localita: "Città",
	provincia: "Provincia",
	indirizzo: "Indirizzo",
	tipologiaCommerciale: "Tipologia Commerciale",
	classeContabile: "Classe Contabile",
	categoria: "Categoria",
	contatti: "Contatti",
	indirizzi: "Indirizzi"
};
*/

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Utenti
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];



export default class TableDataModal extends Component {

	state = {
		show: true,
		inWait: true,
		data: null
	}

	constructor( props ) {
		super( props );

		// console.log("TableDataModal props");
		// console.log(this.props);


		this.toggle = this.toggle.bind( this );
		this.formatColumns = this.formatColumns.bind( this );
	}

	formatColumns(columnList) {
		return (
			columnList
				.map( field => {
					return ({
						dataField: field,
						text: this.props.fieldMap[ field ] || field,
						sort: ( !!this.props.sortableFields ? this.props.sortableFields.includes( field ) : false )
					});
				})
		);
	}

	toggle = forceRefresh => {
		this.props.close( forceRefresh );
	}

	render() {

		console.log(
			"%c RENDER %c TableDataModal ",
			"background:#38f; color:#ddd; border-radius:3px 0px 0px 3px; padding:3px;",
			"background:#38f8; color:#fff; border-radius:0px 3px 3px 0px; padding:3px;"
		);
		console.log(this.state);
		console.log(this.props);
		// console.log("visible password");


		let context = (
			<Card>
				<CardBody>

					<ToolkitProvider
						bootstrap4
						keyField="Id"
						search
						exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
						{props => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
									</Col>
									{/*
													<Col className="text-right">
														<ExportCSVButton {...props.csvProps} className="btn btn-primary">
															Export CSV
														</ExportCSVButton>
													</Col>
													*/}
								</Row>


								{
									(!!this.props.data && this.props.data.length > 0)
										? (
											<BootstrapTable
												bootstrap4
												striped
												{...props.baseProps}
												// size="sm"
												responsive
												keyField="Id"
												data={ this.props.data }
												columns={ this.formatColumns( Object.keys( this.props.data[0] ) ) }
												defaultSorted={ defaultSorted }
												pagination={ paginationFactory(paginationOptions) }
												wrapperClasses="table-responsive"
											/>
										)
										: <span>ATTESA</span>
								}




							</React.Fragment>
						)}
					</ToolkitProvider>

				</CardBody>
			</Card>
		)





		let content = (
			<Modal isOpen={ true } toggle={this.toggle} size={"lg"} className={"modal-full-width"}>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{ this.props.title || "" }
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
			</Modal>


		);


		return content;

	}


	componentDidMount() {


	}



};
