// @flow
import React, {Component} from 'react';
import Chart from 'react-apexcharts';
import {Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,} from 'reactstrap';
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import {errorlog, loadDatalog, mountedlog, renderlog, showlog, updatelog} from "../../../../utils/dev-utils";
import {extractBase64ImageFromSvgChart, getSRToolChartData} from "../../../../business-logic/charts";
import {saveAs} from 'file-saver';
import {COLOR_POLICY, DATE_FORMAT_IT, MAGNITUDE_DARK} from "../../../../env";
import {exportSeedTableToCSV} from "../../../../business-logic/tree-manager";
import {getLastElementOr, isNotEmptyArray, onlyNotNull} from "../../../../utils/array-utils";
import {NoDataChart} from "../../../../components/area/gri-admin/no-data-chart";
import moment from "moment";
import {translate} from "../../../../components/i18n/translate-function";


const labels =
    [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ]
;

const min = 20;
const max = 55;
const fileName = "themes-chart-infos";

const FIELDS_TO_AVOID = ["id", "data", "forceLoad"];

const colors =
    MAGNITUDE_DARK
        .sort( (x, y) => {
            if( x.color < y.color ) return -1;
            return 1;
        })
        .map( c => c.color )
;


const smallColorSet =
    [
        COLOR_POLICY.validated.color,
        COLOR_POLICY.disabled.color
    ]
;

export default class SRToolInformativeChart extends Component {

    state = {
        // data: [],
        ...this.props.arg
        // valueGoal: this.props.valueGoal,
        // valueStartGoal: this.props.valueStartGoal
    }

    constructor(props) {
        super( props );
        this.loadData = this.loadData.bind( this );
    }

    render() {

        renderlog("Informative Chart Block "+ this.state.chartId, this.state, this.props, COLOR_POLICY.edit);


        let beginGoal = {
            y: (!!this.state.valueStartGoal ? parseFloat( this.state.valueStartGoal ) : 0),
            borderColor: COLOR_POLICY.virgin.color,
            label: {
                borderColor: COLOR_POLICY.virgin.color,
                style: {
                    color: '#fff',
                    background: COLOR_POLICY.virgin.color,
                },
                text: 'Inizio '+ this.state.dateStartGoal,
            },
        };

        let endGoal = {
            y: (!!this.state.valueGoal ? parseFloat( this.state.valueGoal ) : 0),
            borderColor: COLOR_POLICY.error.color,
            label: {
                borderColor: COLOR_POLICY.error.color,
                style: {
                    color: '#fff',
                    background: COLOR_POLICY.error.color,
                },
                text: 'Fine '+ this.state.dateGoal,
            },
        }


        // da controllare
        let yAxis = [];
        if( !this.props.arg || !this.props.arg.collectionCompare_select) {
            yAxis = ((!!this.state.valueStartGoal) ?  [ beginGoal, endGoal ] : [])
        }

        const apexBarChartOpts = {
            annotations: {
                yaxis: yAxis
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0,
                },
            },
            chart: {
                height: 309,
                type: 'area',
                parentHeightOffset: 0,
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',    // straight, smooth, stepline
                width: 4,
            },
            title: {
                text: (!!this.props.arg) ? (this.props.arg.UnitMeasurment || "") : "",
                align: 'left',
            },
            zoom: {
                enabled: true,
            },
            legend: {
                show: true,
                offsetY: -10,
            },
            colors: (!!this.state.data && this.state.data.length === 2) ? smallColorSet : colors,
            xaxis: {
                type: 'string',
                categories: labels.map( (_, index) => index +1 ),
                tooltip: {
                    enabled: false,
                },
                axisBorder: {
                    show: false,
                },
                labels: {},
            },
            yaxis: {
                labels: {
                    formatter: val => (val + ""),
                    offsetX: -15,
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    type: 'vertical',
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.45,
                    opacityTo: 0.1,
                    stops: [45, 100],
                },
            },
        };

        const apexBarChartData = [
            {
                name: 'Valore',
                data: (!!this.state.data && this.state.data.length > 0) ? this.state.data[0] : null
            },
            {
                name: translate("Goal"),
                type: "straight",
                data: (!!this.state.data && this.state.data.length > 0) ? this.state.data[1] : null
            },
        ];



        let defaultContent =
            (!!this.state.data && this.state.data.length > 0)
                ? (
                    <Card>
                        <CardBody>

                            <span className={"chart-title chart-collect-name"}>{
                                (!!this.props.arg && !!this.props.arg.data)
                                    ? (
                                        // this.props.arg.data.map( d => d.name ).join("  VS  ")
                                        (
                                            this.props.arg.data.length === 1
                                                ? this.props.arg.data.map( d => d.name ).reduce( getLastElementOr, "" )
                                                : ""
                                        )
                                    )
                                    : (

                                        !!this.props.arg.selectedCollectName
                                            ? (
                                                !!this.props.arg.selectedCollectName.name
                                                    ? this.props.arg.selectedCollectName.name
                                                    : this.props.arg.selectedCollectName
                                            )
                                            : ""

                                    )
                            }</span>
                            <span className={"chart-title"} dangerouslySetInnerHTML={{ __html: this.state.name }}/>

                            <UncontrolledButtonDropdown className="float-right">


                                {
                                    (this.props.chartId !== 5)
                                        ? (
                                            <i
                                                className={"mdi mdi-refresh comander-icon"}
                                                onClick={ clickEvent => {
                                                    this.setState({
                                                        ...this.state,
                                                        forceLoad: true
                                                    },
                                                        () => {
                                                            this.loadData();
                                                        }
                                                    )
                                                }}
                                            />
                                        )
                                        : null
                                }
                                <DropdownToggle tag="button" className="btn btn-link arrow-none card-drop p-0">
                                    <i className="mdi mdi-dots-vertical"/>
                                </DropdownToggle>


                                <DropdownMenu right>
                                    <DropdownItem
                                        onClick={clickEvent => {
                                            let root = document.getElementById("themes-chart-infos-1");
                                            let svgArea = root.childNodes[0].childNodes[0];
                                            extractBase64ImageFromSvgChart(svgArea)
                                                .then(data => {
                                                    saveAs(data, fileName);
                                                })
                                        }}
                                    >
                                        Download Image
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={clickEvent => {

                                            /*
                                            // in verticale
                                            let csvV =
                                                exportSeedTableToCSV(
                                                    data
                                                        .reduce( getLastElementOr, {data: []} )
                                                        .data
                                                        .map( (value, index) => {
                                                            return ({
                                                                label: labels[ index ],
                                                                value: value
                                                            });
                                                        })
                                                )
                                            ;
                                            */

                                            // in orizzontale
                                            let csvH =
                                                this.state.data
                                                    // .map( set => set.data )
                                                    .map((value, index) => {
                                                        return (
                                                            Object.keys(value)
                                                                .map((x, i) => {
                                                                    return ({
                                                                        [labels[i]]: value[x]
                                                                    })
                                                                })
                                                        );
                                                    })
                                                    .map(set => (
                                                        set.reduce((final, current) => {
                                                                final = Object.assign(final, current);
                                                                return final;
                                                            },
                                                            {}
                                                        )
                                                    ))
                                            ;

                                            // showlog( csvH );
                                            // console.table( csvH );

                                            let blob =
                                                new Blob(
                                                    [exportSeedTableToCSV(csvH)],
                                                    {type: "text/csv;charset=utf-8"}
                                                )
                                            ;
                                            saveAs(blob, "themes-chart-infos.csv");

                                        }}
                                    >
                                        Export Report
                                    </DropdownItem>

                                    {
                                        (this.props.chartId !== 5)
                                            ? (
                                                <DropdownItem
                                                    onClick={clickEvent => {
                                                        this.props.openChartSettings();
                                                    }}
                                                >
                                                    Filtri
                                                </DropdownItem>
                                            )
                                            : null
                                    }

                                </DropdownMenu>
                            </UncontrolledButtonDropdown>


                            <h4 className="header-title mb-3"> { this.props.arg.title }</h4>


                            <Chart
                                options={apexBarChartOpts}
                                // series={apexBarChartData}
                                series={ this.state.data }
                                type="area"
                                className="apex-charts mt-3"
                                height={308}
                                id={"themes-chart-infos-1"}
                            />
                        </CardBody>
                    </Card>
                )
                : (
                    <WaitingOrNodataMessage
                        waiting={(!!this.state.data && this.state.data.length > 0)}
                        nodataMessage={
                            <NoDataChart
                                id={ this.props.chartId }
                                title={ this.props.title }
                                openSettings={ () => {
                                    this.props.openChartSettings();
                                }}
                            />
                        }
                        className={""}
                    />
                );


        if(!!this.props.arg && !!this.props.arg.requirement_id) {
            return defaultContent;
        }

        return (
            <NoDataChart
                id={ this.props.chartId }
                title={ this.props.title }
                openSettings={ () => {
                    this.props.openChartSettings();
                }}
                className={"no-chart-data-card"}
            />
        );

    }


    componentDidMount() {
        mountedlog("Informative Chart Block "+ this.state.chartId, this.state, this.props, COLOR_POLICY.evident);
        if(!!this.props.arg /*&& !!this.props.arg.forceLoad*/) {
            this.loadData();    // probabilmente non accade MAI !!!
        }

    }


    componentDidUpdate( prevProps, prevState, prevContext ) {



        // debugger;

        updatelog("Informative Chart Block "+ this.state.chartId, this.state, this.props, COLOR_POLICY.complete);
        if( !!this.props.arg ) {

            Object.keys(this.props.arg || {arg:{}})
                .filter( key => !FIELDS_TO_AVOID.includes( key ) )
                .forEach( key => {
                    if( (this.state[ key ] || 0) !== (this.props.arg[ key ] || 0) ) {
                        showlog(key +"\t%c  UPDATE  ", "border-radius: 2px; color: #222; background:#0f0;");
                    }
                    else {
                        // showlog( "%c"+ key, "color:#0008;" );
                    }
                })


            if(
                Object.keys(this.props.arg || {arg:{}})
                    .filter( key => !FIELDS_TO_AVOID.includes( key ) )
                    .filter( key => (this.state[ key ] || 0) !== (this.props.arg[ key ] || 0) )
                    .length
                    > 0
                // || this.state.data.length === 0
            ) {

                showlog("%cUPDATE "+ this.state.chartId, "color:#0f0;");
                // debugger;

                this.setState({
                    ...this.state,
                    ...this.props.arg,
                    valueGoal: !!this.props.goals ? this.props.goals.valueGoal : null,
                    valueStartGoal: !!this.props.goals ? this.props.goals.valueStartGoal : null,
                    dateStartGoal: !!this.props.goals ? moment( this.props.goals.dateStartGoal ).format( DATE_FORMAT_IT ) : null,
                    dateGoal: !!this.props.goals ? moment( this.props.goals.dateGoal ).format( DATE_FORMAT_IT ) : null,
                        // data: []
                },
                    () => {
                        // debugger;
                        this.loadData();
                    }
                )
            }

        }


    }





    loadData() {


        // debugger;
        if(!!this.props.arg && !!this.state.forceLoad) {

            loadDatalog("Informative Chart Block "+ this.state.chartId, COLOR_POLICY.finished );

            getSRToolChartData(
                this.props.arg.thematic_id,
                this.props.arg.chapter_id,
                this.props.arg.informative_id,
                this.props.arg.requirement_id,
                this.props.arg.subrequirement,
                this.props.arg.subsubrequirement,
                this.props.arg.type,
                this.props.arg.id,
                this.props.arg.timing_select,
                this.props.arg.graphType_select,
                this.props.arg.collectionCompare_select,
                this.props.arg.chartId,
                this.props.arg.selectedCollect,
                this.props.arg.selectedCollectName
            )
                .then(result => {


                    if (!!isNotEmptyArray(result)) {

                        // debugger;

                        let dataValues =
                            result[0].payload
                                .map( (datas, dIndex) => ({
                                    name: datas.collection_name || dIndex,
                                    data:
                                        datas.values
                                            .map( val => val.valueDecimal )
                                }))
                        ;

                        this.setState({
                            data: [
                                ...dataValues,
                                /*
                                ( dataValues.length === 1 )
                                    ? ({
                                        name: "Obiettivo",
                                        type: "straight",
                                        data:
                                            Array(12)
                                                .fill(true)
                                                .map((el, index) => parseFloat( result[0].payload[0].valueStartGoal ) + parseFloat(parseFloat(parseFloat(result[0].payload[0].valueGoal - result[0].payload[0].valueStartGoal) / 11) * index))
                                    })
                                    : null
                                */
                            ]
                            .filter( onlyNotNull ),
                            goals:
                                {
                                    chartId:        result[0].metadata.graphId,
                                    valueGoal:      (!!result[0].payload[0]) ? result[0].payload[0].valueGoal       : 0,
                                    valueStartGoal: (!!result[0].payload[0]) ? result[0].payload[0].valueStartGoal  : 0,
                                    dateStartGoal: (!!result[0].payload[0]) ? moment( result[0].payload[0].dateStartGoal ).format( DATE_FORMAT_IT ) : 0,
                                    dateGoal: (!!result[0].payload[0]) ? moment( result[0].payload[0].dateGoal ).format( DATE_FORMAT_IT ) : 0
                                }
                            ,
                            valueGoal:      (!!result[0].payload[0]) ? result[0].payload[0].valueGoal       : 0,
                            valueStartGoal: (!!result[0].payload[0]) ? result[0].payload[0].valueStartGoal  : 0,
                            dateStartGoal: (!!result[0].payload[0]) ? moment( result[0].payload[0].dateStartGoal ).format( DATE_FORMAT_IT ) : 0,
                            dateGoal: (!!result[0].payload[0]) ? moment( result[0].payload[0].dateGoal ).format( DATE_FORMAT_IT ) : 0,
                            forceLoad: false,
                            name: result[0].metadata.graphName
                        })
                    }
                    else {
                        this.setState({
                            data: [
                                // data = [
                                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
                                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]
                            ],
                            forceLoad: false
                        });
                    }

                })
                .catch( e => {
                    errorlog("errore al caricamento dei dati", e);
                })
            ;

        }
        else {

                    this.setState({
                        ...this.state,
                        ...this.props.arg,
                        valueGoal: !!this.props.goals ? this.props.goals.valueGoal : null,
                        valueStartGoal: !!this.props.goals ? this.props.goals.valueStartGoal : null,
                        dateStartGoal: !!this.props.goals ? moment( this.props.goals.dateStartGoal ).format( DATE_FORMAT_IT ) : null,
                        dateGoal: !!this.props.goals ? moment( this.props.goals.dateGoal ).format( DATE_FORMAT_IT ) : null,
                    })



            // setTimeout(
            //     function() {
                // }.bind( this ),
                // 2000
            // )

        }

    }


};


