// @flow
import React, {Component} from 'react';
import Routes from './routes/Routes';

// setup fake backend
// Themes
import './assets/scss/Saas.scss';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';
// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';
// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';
// configure fake backend
// configureFakeBackend();
import "./style/index.css";
import "./style/audit.css";
import "./style/color-policy.css"
import {HOST, role} from "./env";
import {WelcomePage_Get} from "./services/request-manager";
import {errorlog, showlog} from "./utils";
import {translate} from "./components/i18n/translate-function";
import {isUserRole} from "./business-logic/auth";

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {


    render() {

        let logo = HOST +"/assets/Loghi/SiRoad_Sidebar.png";
        let logoSmall = HOST +"/assets/Loghi/SiRoad_Sidebar.png"; // immagine senza testo

        return (
            <Routes
                logo={ logo }
                logoSmall={ logoSmall }
                currentRoute={ window.currentRoute }
            />
        );
    }

    componentDidMount() {

        // Eliminazione del se il servizio non e attivo
        let companyID = localStorage.getItem("company_id") || 0;

        if( companyID > 0 ) {
            WelcomePage_Get(companyID)
                .then(response => {
                  //  showlog("response", response)
                    if( !!isUserRole(role.ADMIN) ){

                        if( !response.hasSiScoringServiceActive ) {//if per eliminare manuale utente dai figli del menu si scoring se non si ha il servizio attivo
                            let menuItems = document.getElementsByClassName("metismenu side-nav");
                            for( let node of menuItems[0].childNodes ) {
                                //showlog("NODO", node)
                                if( node.nodeType == 1 ) {
                                    if( !!node.childNodes[0].hasChildNodes() && !!node.childNodes[0].childNodes[1] ) {
                                        if( node.childNodes[0].childNodes[1].innerHTML.indexOf("SI Scoring") !== -1 ) {
                                            //let targetMenuElement = node.childNodes[0];
                                            //targetMenuElement.style.cssText = "; display: none;";
                                            //targetMenuElement.parentNode.removeChild( targetMenuElement );

                                            //showlog("nodo interessato", node.childNodes[1])
                                            if(!!node.childNodes[1].hasChildNodes() && !!node.childNodes[1].childNodes){
                                                for ( let innerNode of node.childNodes[1].childNodes){
                                                    //showlog("nodo interno interessato", innerNode)
                                                    if( innerNode.innerHTML.indexOf(translate("user_manual")) !== -1 ) {
                                                        let targetMenuElement = innerNode;
                                                        targetMenuElement.style.cssText = "; display: none;";
                                                        targetMenuElement.parentNode.removeChild( targetMenuElement );
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            };
                        }
                        if( !response.hasSrToolServiceActive ) {//if per eliminare dashboard dai figli del menu sr tool se non si ha il servizio attivo
                            let menuItems = document.getElementsByClassName("metismenu side-nav");
                            for( let node of menuItems[0].childNodes ) {
                                //showlog("NODO", node)
                                if( node.nodeType == 1 ) {
                                    if( !!node.childNodes[0].hasChildNodes() && !!node.childNodes[0].childNodes[1] ) {
                                        if( node.childNodes[0].childNodes[1].innerHTML.indexOf("SR Tool") !== -1 ) {
                                            //let targetMenuElement = node.childNodes[0];
                                            //targetMenuElement.style.cssText = "; display: none;";
                                            //targetMenuElement.parentNode.removeChild( targetMenuElement );

                                            //showlog("nodo interessato", node.childNodes[1])
                                            if(!!node.childNodes[1].hasChildNodes() && !!node.childNodes[1].childNodes){
                                                for ( let innerNode of node.childNodes[1].childNodes){
                                                    //showlog("nodo interno interessato", innerNode)
                                                    if( innerNode.innerHTML.indexOf("Dashboard") !== -1 ) {
                                                        let targetMenuElement = innerNode;
                                                        targetMenuElement.style.cssText = "; display: none;";
                                                        targetMenuElement.parentNode.removeChild( targetMenuElement );
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            };
                        }
                    }
                    else if( !!isUserRole(role.SI_Scoring_Admin) ){

                        if( !response.hasSiScoringServiceActive ) {//if per eliminare manuale utente dai figli del menu si scoring se non si ha il servizio attivo
                            let menuItems = document.getElementsByClassName("metismenu side-nav");
                            for( let node of menuItems[0].childNodes ) {
                                showlog("NODO", node)
                                if( node.nodeType == 1 ) {
                                    if( !!node.childNodes[0].hasChildNodes() && !!node.childNodes[0].childNodes[1] ) {
                                        if( node.childNodes[0].childNodes[1].innerHTML.indexOf("SI Scoring") !== -1 ) {
                                            //let targetMenuElement = node.childNodes[0];
                                            //targetMenuElement.style.cssText = "; display: none;";
                                            //targetMenuElement.parentNode.removeChild( targetMenuElement );

                                            //showlog("nodo interessato", node.childNodes[1])
                                            if(!!node.childNodes[1].hasChildNodes() && !!node.childNodes[1].childNodes){
                                                for ( let innerNode of node.childNodes[1].childNodes){
                                                    //showlog("nodo interno interessato", innerNode)
                                                    if( innerNode.innerHTML.indexOf(translate("user_manual")) !== -1 ) {
                                                        let targetMenuElement = innerNode;
                                                        targetMenuElement.style.cssText = "; display: none;";
                                                        targetMenuElement.parentNode.removeChild( targetMenuElement );
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            };
                        }
                    }
                    else if(!!isUserRole(role.SRTool_ADMIN) ){

                        if( !response.hasSrToolServiceActive ) {//if per eliminare dashboard dai figli del menu sr tool se non si ha il servizio attivo
                            let menuItems = document.getElementsByClassName("metismenu side-nav");
                            for( let node of menuItems[0].childNodes ) {
                                showlog("NODO", node)
                                if( node.nodeType == 1 ) {
                                    if( !!node.childNodes[0].hasChildNodes() && !!node.childNodes[0].childNodes[1] ) {
                                        if( node.childNodes[0].childNodes[1].innerHTML.indexOf("SR Tool") !== -1 ) {
                                            //let targetMenuElement = node.childNodes[0];
                                            //targetMenuElement.style.cssText = "; display: none;";
                                            //targetMenuElement.parentNode.removeChild( targetMenuElement );

                                            //showlog("nodo interessato", node.childNodes[1])
                                            if(!!node.childNodes[1].hasChildNodes() && !!node.childNodes[1].childNodes){
                                                for ( let innerNode of node.childNodes[1].childNodes){
                                                    //showlog("nodo interno interessato", innerNode)
                                                    if( innerNode.innerHTML.indexOf("Dashboard") !== -1 ) {
                                                        let targetMenuElement = innerNode;
                                                        targetMenuElement.style.cssText = "; display: none;";
                                                        targetMenuElement.parentNode.removeChild( targetMenuElement );
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            };
                        }

                    }


                })
                .catch(e => {
                    errorlog("chiamata di welcome", e);
                })
        }

        //let title = document.getElementsByName("title")
        let title = document.getElementsByTagName("title")
        showlog("title", title[0].innerHTML)
        title[0].innerHTML = translate("metaTitle")
        showlog("title2", title[0].innerHTML)
        let description = document.getElementsByName("description")
        showlog("description", description[0].content)
        description[0].content = translate("metaDescription")
        showlog("description2", description[0].content)

    }

}

// proxy on routes

window.currentRoute = { path: "/" };

export default App;
