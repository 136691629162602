import React, {Component} from "react";
import {Button, Card, CardBody, Col, Label, Row, UncontrolledTooltip} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../../../components/PageTitle";
import {DATE_FORMAT_IT, HOST, PATH_PREFIX, permissions, PRODUCTION_HOST, RATING_STATUS, role} from "../../../env";
import {getRatingAttachments, loadActiveService, loadEvaluations} from "../../../business-logic/active-service";
import {
	emoj,
	errorlog,
	getLastElementOr,
	getMaterialClassIconByFileName,
	inverseSortById,
	renderlog,
	showlog
} from "../../../utils";
import {EMOJ} from "../../../emoj";
import {Redirect} from "react-router-dom";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import AddRatingModal from "../../../components/area/super-admin/new-rating-modal";
import SiRatingRatingAttachmentModal from "./sirating-rating-attachment-modal";
import {downloadSIRatingRatingFile} from "../../../business-logic/rating";
import ScoreModal from "../../../components/area/super-admin/score-modal";
import SdgScoreModal from "../../../components/area/super-admin/sdg-score-modal";
import CriteriaModal from "../../../components/area/super-admin/criteria-modal";
import StateModal from "../../../components/area/super-admin/state-modal";
import RatingPdfParametersModal from "../../../components/area/super-admin/rating-pdf-parameters-modal";
import Select from 'react-select';
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import moment from "moment";
import {RATING_TYPE} from "../../../conf/siscoring";
import {translate} from "../../../components/i18n/translate-function";
import VersionModal from "../../../components/area/super-admin/version-modal";


const hiddenFields = [
	"companyId",
	"dimensionId",
	"dimensionName",
	"dimension",
	"companySize",
	"undefined",
	"id",
	"Ragione Sociale",
	"Dimensione",
	"status"
];

const fieldMap = {
	id: "id",
	codCliFor: "companyId",
	name: translate("Name"),
	dateCreate: translate("Creation Date"),
	ragioneSociale: "Ragione Sociale",
	dimensionName: "Dimensione",
	state: "Status",
	scoreButton: translate("Score"),
	files: translate("Attachments"),
	status: "status"
};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Rating")}
        </span>
	),
	sizePerPageList: [
		{
			text: "pochi",
			value: 25,
		},
		{
			text: "normale",
			value: 50,
		},
		{
			text: "molti",
			value: 250,
		}
	]
};

const defaultSorted = [
	{
		dataField: "Id",
		order: "asc",
	},
];

const sortableFields = [
	translate("Name"),
	translate("Creation Date"),
	"Ragione Sociale",
	"Dimensione",
	"Status"
];

const formatColumns = columnList => {
	return (
		columnList
			.filter( ff => ff !== "undefined" )
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			// custom date time sort -----------------------------------------------------------------------------------
			.map( column => {
				if( [translate("Creation Date")].includes( column.dataField ) ) {
					column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
						if (order === 'asc') {
							return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
						}
						return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
					}
				}
				return column;
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SiRatingAdminEvaluationsPage extends Component {

	state = {
		data: null,
		activeServices: []
	}

	constructor(props) {
		super( props );
		this.loadData = this.loadData.bind( this );
		this.ratingFilterComponent = this.ratingFilterComponent.bind( this );
	}


	canDisplayScoreElements = rowStatusId => (
		(
			// se *Admin solo al audit finito
			(
				isUserRole( role.ADMIN ) || isUserRole( role.SR_ADMIN ) || isUserRole( role.SRTool_ADMIN ) /*|| isUserRole( role.SUPER_ADMIN )*/
			) && rowStatusId === RATING_STATUS.auditDone.id
		) ||
		(
			// altri titpi di utenza
			isUserRole( role.SUPER_ADMIN ) || isUserRole( role.ARB_OPERATOR ) || isUserRole( role.PROJECT_MANAGER )
		)
	)

	ratingFilterComponent() {
		return (
			<React.Fragment>
				<Label for="rating-filter">{translate("Filter")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
				<Select
					className="react-select question-filter-select"
					classNamePrefix="react-select"
					name="rating-filter"
					id="rating-filter"
					onChange={ changeEvent => {

						if( changeEvent.value === -1 ) {
							this.setState({
								...this.state,
								data: this.state.allData,
								activeFilter: changeEvent.value
							})
						}
						else {
							this.setState({
								...this.state,
								data: this.state.allData.filter( d => d.status === changeEvent.value ),
								activeFilter: changeEvent.value
							})
						}
					}}
					options={
						[
							{ value: -1, label: translate("All") },
							...Object.keys( RATING_STATUS )
								.map( status => RATING_STATUS[ status ] )
								.map( ratingStatus => ({
									value: ratingStatus.id,
									label: ratingStatus.name
								}))
								.sort( inverseSortById )
						]
					}
					placeholder={ <span className={"select-placeholder"}>{translate("Select filter")}</span> }
				/>
			</React.Fragment>
		);
	}




	render() {

		renderlog(
			"SiRatingAdminEvaluationsPage",
			this.state,
			this.props
		);

		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{ label: "SI Rating", path: PATH_PREFIX +"/sirating-admin/" + translate("evaluations") },
						{ label: translate("Evaluations"), active: true }
					]}
					title={
						<React.Fragment>
							<img
								src={ PRODUCTION_HOST +"/assets/Loghi/SiRating.png" }
								alt={"gender-gap-logo"}
								height="30"
								style={{ height:"80px",float:"left"}}
							/>

							<p style={{float:"left", marginTop: "27px",
								marginBottom: "-23px",
								fontSize: "14px",cursor:"pointer",height:"0px"}} onClick={clickEvent => {
								this.setState({
									versionModal: true
								})
							}
							}> v. {(localStorage.getItem("siRatingVersion")).trim()} </p>
							{/*<span>SI Rating</span>*/}
							{/*<span>Valutazioni</span>*/}
						</React.Fragment>
					}
				/>


				{
					!!this.state.versionModal
						? (
							<VersionModal
								tool={"SI Rating-2"}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										versionModal: false
									})
								}}/>
						)
						:
						null
				}


				{
					(!!this.state.attachmentModal)
						? (
							<SiRatingRatingAttachmentModal
								questionId={ 0 }
								files={ this.state.ratingAttachments }
								ratingId={ this.state.attachmentModal }
								onAttachmentUploaded={ attachments => {
									/*
									// ATENZIONE non prende i cancellati
									let treeTmp = cloneObject(this.state.tree);
									let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, getQuestionPath(treeTmp, this.state.attachmentModalQuestionId));
									refreshedQuestion.attachments = [...refreshedQuestion.attachments, ...attachments];
									this.setState({
										...this.state,
										tree: treeTmp
									})
									*/
								}}
								onAttachmentDelete={ attachmentIds => {
									/*
									let treeTmp = cloneObject(this.state.tree);
									let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, getQuestionPath(treeTmp, this.state.attachmentModalQuestionId));
									refreshedQuestion.attachments = refreshedQuestion.attachments.filter( a => !!!attachmentIds.includes( a.attachment_id ) )
									this.setState({
										...this.state,
										tree: treeTmp
									})
									*/
								}}
								onError={ e => {
									errorlog("errore nel caricamento dell'allegato", e);
								}}
								onClose={ () => {
									this.setState({
										...this.state,
										attachmentModal: null
									})
								}}
							/>
						)
						: null
				}




					<Card>
						<CardBody>

							<Row>
								<Col>
									<div className="page-title-box">
										<div className="page-title-right">

											{
												(!!hasPermission(permissions.ADD_RATING))
													? (
														<Button
															color={"success"}
															onClick={ clickEvent => {

																this.setState({
																	...this.state,
																	newRatingModal: true
																});
															}}
														>
															<i className="uil-user"/> Nuova Valutazione
														</Button>
													)
													: null
											}


										</div>
										{/*<img src={ HOST +"/assets/Loghi/SiRating.png" } className={"sirating-logo"}/>*/}
										<h4 className="page-title"> {translate("Ratings List")} </h4>
									</div>
								</Col>
							</Row>


							<ToolkitProvider
								bootstrap4
								keyField="id"
								search
								exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
								{props => (
									<React.Fragment>

										{
											(!!this.state.data && this.state.data.length > 0 )
												? (
													<Row>

														<Col md={ 4 }>
															{ this.ratingFilterComponent() }
														</Col>
														<Col md={ 6 }>&nbsp;</Col>
														<Col md={ 2 }>
															<span className={"float-right"}>
																<SearchBar {...props.searchProps} />
															</span>
														</Col>

														{/*
														<Col className="text-right">
															<ExportCSVButton {...props.csvProps} className="btn btn-primary">
																Export CSV
															</ExportCSVButton>
														</Col>
														*/}
													</Row>
												)
												: null
										}



										{
											(!!this.state.data && this.state.data.length > 0 )
												? (


													<BootstrapTable
														bootstrap4
														striped
														{...props.baseProps}
														size="sm"
														responsive
														keyField="id"
														data={ this.state.data }
														columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
														defaultSorted={ defaultSorted }
														pagination={ paginationFactory(paginationOptions) }
														wrapperClasses="table-responsive"
														expandRow={{
															renderer: row => {
																showlog("riga selezionata");
																delete row.files;
																delete row[translate("Attachments")];
																delete row[ translate("Score") ];


																showlog(row);

																let rowStatusId =
																	Object.keys( RATING_STATUS )
																		.map( status => RATING_STATUS[ status ] )
																		.filter( ratingStatus => ratingStatus.name === row.Status )
																		.map( ratingStatus => ratingStatus.id )
																		.reduce( getLastElementOr, 100 )
																;


																// showlog(rowStatusId);
																// showlog( RATING_STATUS.perimetralQuestionAccepted.id )
																// showlog( rowStatusId >= RATING_STATUS.perimetralQuestionAccepted.id )
																// debugger;

																if( rowStatusId >= RATING_STATUS.perimetralQuestionAccepted.id ) {
																	return (
																		<Redirect
																			to={{
																				pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ row.id,
																				state: row
																			}}
																		/>
																	);
																}

																return (
																	<Redirect
																		to={{
																			pathname: PATH_PREFIX +"/sirating-admin/" + translate("eupa") + "/"+ row.id,
																			state: row
																		}}
																	/>
																);





															},
															showExpandColumn: false,
															onlyOneExpanding: true
														}}

													/>
												)
												: (
													<WaitingOrNodataMessage
														waiting={ !(!!this.state.data && this.state.data.length === 0) }
														fullHeight={ true }
														nodataMessage={(this.state.activeServices.includes(2) ) ?("Nessuna Valutazione"):("Servizio Non Attivo")}
													/>
												)
										}

									</React.Fragment>
								)}
							</ToolkitProvider>

						</CardBody>
					</Card>




				{
					(!!this.state.newRatingModal)
						? (
							<AddRatingModal
								onClose={ errorMessage => {

									this.setState(
										{
											...this.state,
											newRatingModal: false,
											errorMessage: (!!errorMessage ? emoj( EMOJ.sad_but_relieved_face ) +"  "+ errorMessage : null)
										},
										() => {
											if( !errorMessage ) this.loadData();
										}
									);


								}}
							/>
						)
						: null
				}



				{
					(!!this.state.scoreModal)
						? (
							<ScoreModal
								questionId={ this.state.scoreModal }
								evaluation_id={ this.state.evaluation_id }
								onClose={ () => {
									this.setState({
										...this.state,
										scoreModal: null
									})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.sgdScoreModal)
						? (
							<SdgScoreModal
								questionId={ this.state.scoreModal }
								evaluation_id={ this.state.evaluation_id }
								onClose={ () => {
									this.setState({
										...this.state,
										sgdScoreModal: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.criteriaModal)
						? (
							<CriteriaModal
								evaluation_id={ this.state.criteriaModal }
								onClose={ () => {
									this.setState({
										...this.state,
										criteriaModal: null
									})
								}}
							/>
						)
						: null
				}



				{
					(!!this.state.stateModal)
						? (
							<StateModal
								evaluation_id={ this.state.stateModal }
								onClose={ () => {
									this.setState({
										...this.state,
										stateModal: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.pdfModal)
						? (
							<RatingPdfParametersModal
								id={ this.state.pdfModal }
								name={ this.state.pdfName }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										pdfModal: null
									});
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.redirect)
						? (
							<Redirect
								to={{
									pathname: this.state.redirect,
									state: {
										ratingId: this.state.ratingId,
										pdfName: this.state.pdfName
									}
								}}
							/>
						)
						: null
				}


			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
		loadActiveService()
			.then( result =>

				// showlog("aaaaa",result)
				result.map( service =>
					service.service_id
				)).then(r=> this.setState({
				...this.state,
				activeServices : r
			})
		)
	}


	loadData() {
		loadEvaluations( RATING_TYPE.sirating )

			// logs   -----------------------------------------------------------------------------------------------
			.then( list => {
				showlog("active services");
				showlog( list );
				return list
			})





			// extras ---------------------------------------------------------------------------------------------------
			.then( list => {


				showlog("lista");
				showlog(list);

				list =
					list
						.map( row => {
							row.files = (
								<i
									className={"mdi mdi-attachment sirating-question-attachment-icon rating-info-icon"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
										showlog("modale degli allegati");


										getRatingAttachments( row.id )
											.then( attachments => {
												showlog("allegati");
												showlog(attachments);

												// name, id
												// attachment_name, attachment_id
												this.setState({
													...this.state,
													ratingAttachments:
														attachments
															.map( att => ({attachment_name: att.name, attachment_id: att.attachment_id })),
													attachmentModal: row.id
												})


											})
											.catch( e => {
												errorlog("errore al caricamento degli allegati della valutazione", e);
											})

									}}
								/>
							);



							row.scoreButton = (
								<React.Fragment>

									{

										(
											this.canDisplayScoreElements(
												Object.keys( RATING_STATUS )
													.map( status => RATING_STATUS[ status ] )
													// .filter( ratingStatus => ratingStatus.name === (row.Status || row.status) )
													.filter( ratingStatus => ratingStatus.id === (row.Status || row.status) )
													.map( ratingStatus => ratingStatus.id )
													.reduce( getLastElementOr, 100 )
											)

										)
											? (
												<React.Fragment>

													{
														!!hasPermission( permissions.READ_SCORE_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'rating_'+ row.id}
																		src={HOST + "/assets/icons_evaluations/rating.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				scoreModal: 15,
																				evaluation_id: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'rating_'+ row.id}>
																		<span className="">{ translate("ESG_Performance") }</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}

													{
														!!hasPermission( permissions.READ_SGD_SCORE_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'sdgs_'+ row.id}
																		src={HOST + "/assets/icons_evaluations/sdgs.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				sgdScoreModal: row.id,
																				evaluation_id: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'sdgs_'+ row.id}>
																		<span className="">SDG</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}

													{
														!!hasPermission( permissions.READ_CRITERIA_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'resilienza_'+ row.id}
																		src={HOST + "/assets/icons_evaluations/resilienza.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				// sgdScoreModal: 15,
																				criteriaModal: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'resilienza_'+ row.id}>
																		<span className="">{ translate('Score') }</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}


													{
														!!hasPermission( permissions.READ_STATUS_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'support_'+ row.id}
																		src={HOST + "/assets/icons_evaluations/support.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				// sgdScoreModal: 15,
																				stateModal: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'support_'+ row.id}>
																		<span className="">{translate("Operator Actions History")}</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}



													{
														!!hasPermission( permissions.ACCESS_PDF_PARAMETER_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={"pdf_"+ row.id}
																		src={HOST + "/assets/icons_evaluations/pdf.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		// className={ getMaterialClassIconByFileName("x.pdf") +" rating-comander-icon dark-red-text"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();


																			// if( isUserRole( role.PROJECT_MANAGER ) || isUserRole( role.ARB_OPERATOR ) ) {
																			if( !!hasPermission( permissions.ACCESS_PDF_PARAMETER_MODAL ) ) {
																				this.setState({
																					...this.state,
																					redirect: PATH_PREFIX +"/super-admin/si-rating/" + translate("evaluations")  + "/" + translate("report-parameters") + "/" + row.id,
																					ratingId: row.id,
																					pdfName: row.name.trim() +".pdf"
																				})
																			}
																			else {
																				downloadSIRatingRatingFile( row.id, row.name.trim() +".pdf" )
																					.then( result => {

																					})
																					.catch(e => {
																						errorlog("errore", e);

																					})
																			}


																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'pdf_'+ row.id}>
																		<span className="">PDF</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}



												</React.Fragment>
											)
											: null
									}







								</React.Fragment>

							);






							return row;
						})

				return list;
			})



			// field sort   -------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})




			// field map ----------------------------------------------------------------------------------------------
			.then( list => {


				return (
					list
						.map( company => {
							let dto = {};
							Object.keys( company )
								.forEach( rawField => {
									dto[ fieldMap[ rawField ] ] = company[ rawField ];
								})
							;
							return dto;
						})
				);

			})


			// finish --------------------------------------------------------------------------------------------------
			.then( data => {

				// debugger;

				this.setState({
					...this.state,
					data: data,
					allData: data
				})
			})

	}






}
