import React, {Component} from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	CustomInput,
	FormGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from "reactstrap";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {Redirect, withRouter} from "react-router-dom";
import { PATH_PREFIX } from '../../../env';
import ActionButton from '../../../components/save-button';
import { toggleCollectPmAuthorized } from '../../../business-logic/gri';

export default class ConfirmArbModal extends Component {

    state = {

    }

    constructor( props ) {
		super( props );   
    
		this.toggle = this.toggle.bind( this );
	 
    }

    toggle = () => {
        this.props.onClose();
    }

     
        render(){ 
            

            renderlog(
                "ConfirmArbModal",
            );
            // showlog(" (this.props.esternalProps.match.params.id || this.props.esternalProps.id)",  (this.props.esternalProps.match.params.id || this.props.esternalProps.id));


            let content = (
                <Modal isOpen={ true } toggle={this.toggle} className={"confirm-arb-modal"}>
                    <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                        {
                            !!this.props.esternalCollectIsPmAuthorized
                                ? "Confermi di voler autorizzare ARB spa?"
                                : "Confermi di non voler autorizzare ARB spa?"
                        }
                    </ModalHeader>
                    <ModalFooter className={this.state.bodyClass}>
                    <Row>
							<Col>
								<ActionButton
									buttonText={ "Conferma" }
									//promise={ () => acceptEUPA( props.match.params.id || props.id ) }
									//onResult={ () => setGoAhead( true ) }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
                                    promise={ () => toggleCollectPmAuthorized( this.props.esternalCollectId, !!this.props.esternalCollectIsPmAuthorized )}
									onResult={ result => {
                                        
                                        this.props.onClose(result)
                                        
                                    
                                    } }
								/>
							</Col>
                            <Row>
							<Col>
								<ActionButton
									buttonText={ "Torna indietro" }
									//promise={ () => Promise.resolve() }
									//onResult={ () => {
									//	setSegnalationPopUp(true)
									//} }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
                                    promise={ () => Promise.resolve() }
									onResult={ () => {

                                        this.props.onClose()
										
                                    } }
								/>
							</Col></Row>
						</Row>
                    </ModalFooter>
                </Modal>
            );
            
            return content;
        }

}