import React from "react";
import "./reccomendation-icon-svg-component.css";
export const ReccomendationIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            clickEvent.stopPropagation();
            clickEvent.preventDefault();
        }}
    >
        <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 97.68">
            <path className="cls-1"
                  d="M103.28,60.49A23.17,23.17,0,0,0,90.16,45.33a1.86,1.86,0,0,1-1.3-2c0-9.57-.08-19.14.08-28.7.06-3.38-.85-5.88-4-7.36H24.9c-3.1,1.5-4,4-3.95,7.37.14,14.07.05,28.13.06,42.2a5.58,5.58,0,0,0,5.88,5.91c1.22,0,2.45,0,3.81,0,0,3,0,5.82,0,8.62,0,1.42.13,2.72,1.57,3.45s2.58.16,3.77-.61c5.34-3.47,10.76-6.84,16-10.39a7,7,0,0,1,5.26-.94c0,2.64-.32,5.26.06,7.78a23.48,23.48,0,0,0,45.14,4.67c.66-1.75,1.06-3.6,1.59-5.4V63.74C103.85,62.66,103.59,61.57,103.28,60.49ZM33.7,59.81l-6.59,0c-2.28,0-3.24-1-3.24-3.25q0-21.66,0-43.32c0-2.26.9-3.17,3.13-3.17H83c2.07,0,3,.92,3,2.94q0,15,0,30a6.26,6.26,0,0,1-.11.76c-6.88-1.36-13.16-.29-18.84,3.64a21.88,21.88,0,0,0-8.42,10.82,2.1,2.1,0,0,1-2.46,1.61H51.84l-3.79,3.16L33.68,72.3V62.73Zm46.86,27.7a20.69,20.69,0,1,1,20.7-20.69A20.57,20.57,0,0,1,80.56,87.51Z"/>
            <path className="cls-2"
                  d="M79.34,79.22a2,2,0,0,0,1.24,1.32c.44,0,1-.65,1.9-1.33-.94-.57-1.48-1.16-2-1.13S79.27,78.93,79.34,79.22Z"/>
            <rect className="cls-1" x="78.18" y="52.87" width="5.45" height="17.74" rx="1.93"/>
            <circle className="cls-1" cx="80.91" cy="76.43" r="2.88"/>
            <line className="cls-3" x1="31.86" y1="19.85" x2="77.83" y2="19.85"/>
            <line className="cls-3" x1="31.86" y1="28.04" x2="77.83" y2="28.04"/>
            <line className="cls-3" x1="31.86" y1="36.23" x2="77.83" y2="36.23"/>
            <line className="cls-3" x1="31.86" y1="45.41" x2="58.48" y2="45.41"/>
        </svg>
    </div>
);
