import {errorMessage, HTTP_CALL_cURL_EXPORT} from "../env";
import {showlog} from "../utils";

let originalFetch = window.fetch.bind (window);
(window.fetch = (...request) => {


	if( request.length > 1 ) {

		if( !!!request[1].headers ) request[1].headers = {};

		let auth = {};
		if( !!localStorage.getItem("jwtToken") ) {
			auth = { Authorization: "Bearer " + localStorage.getItem("jwtToken") };
		}

		if( !!request[1].headers.Authorization ) {
			auth = { Authorization: request[1].headers.Authorization };
		}
		else {
			request[1].credentials = "include";
		}

		Object.assign(
			request[1].headers,
			{
				Cache: 'no-cache',
				Pragma: "no-cache",
				"Cache-Control": "no-cache"
			},
			auth
		);


		if( request[1].method !== "OPTION" && !!HTTP_CALL_cURL_EXPORT ) {

			let curl = "curl";
			curl +=
				( request[1].body )
					? ("-d '"+ JSON.stringify( request[1].body ) +"' ")
					: " "

			// headers
			curl +=
				Object.keys( request[1].headers )
					.map( h => "-H '"+ h +": "+ request[1].headers[h] +"'")
					.join(", ")
			;
			curl += " ";

			curl += (
				( request[1].method === "POST" )
					? "-X "
					: (
						( request[1].method === "GET" )
							? ""
							: (
								( request[1].method === "PUT" )
									? "-X "
									: (
										( request[1].method === "DELETE" )
											? "-X "
											: ""
									)
							)
					)
			)
			curl += request[1].method +" "+ request[0];

			showlog("%cfor http call export", "color:#f85;");
			showlog( "%c"+ curl, "background:#a53; color:#fff; font-size:0.75em; padding:2px; border-radius:3px; margin: 5px; padding-left:10px; padding-right:10px;");


		}

	}


	let startCallTime = (new Date()).getTime();
	return (
		originalFetch
			.apply(originalFetch, request)
			.then(response =>  {


				if( !!request && !!request[1] && request[1].method !== "OPTION" && !!HTTP_CALL_cURL_EXPORT ) {
					showlog("%ccall time %c " + (((new Date()).getTime()) - startCallTime) + "ms", "color:#f85; font-size:0.75em;", "background:#a53; color:#fff; font-size:0.8em; padding:2px; border-radius:3px; margin: 5px; padding-left:10px; padding-right:10px;");
				}


				if( response.status !== errorMessage.HTTP_OK_200.code ) {
					// probabilmente il token è scaduto

					let error =
						Object.keys(errorMessage)
							.filter( field => errorMessage[field].code === response.status )
					;
					let errObj = {};
					if( !!error && error.length > 0 ) errObj = errorMessage[error[0]];

					return (
						response.text()
							.then( text => {

								/*
								throw ({
									status: "error",
									error: {
										...errObj,
										...JSON.parse( text )
									}
								});
								*/
								return Promise.reject({
									status: "error",
									error: {
										...errObj,
										...JSON.parse( text )
									}
								});

							})
							.catch(error => {
								return Promise.reject(error);
							})
					)

					;

					// throw ({ status: "error", error: errObj });
					// return response;

				}
				else {
					return response;
				}

			})

			.catch(error => {
				return Promise.reject(error);
			})


	);



// })(fetch)
})
;

