/*eslint no-unused-expressions: "error"*/


let w = 500, h = 500;

let bk = false;
let scene = null;
let camera = null;
let tiles = null;
let geometry = null;
let material = null;
let cube = null;
let light = null;
// let rotWorldMatrix = null;
let dummy = null;
let renderer = null;

let cubeSide = 4;   // 4
let tileSide = 4;   // 4
let tileHeight = 1; // 1

let step = 0.1;    // 0.1
//let counter = Math.PI / 2;
let counter = Math.PI;

export let motionCubeIsRunning = false;

export let MotionCubeTheme = {
    FULL: 0,
    FULL_COLOR: 1,
    FULL_2: 5,
    COLOR_BLUE_EMISSION: 2,
    RED: 3,
    RED_2: 6,
    FULL_MEDIUM_COLOR: 4,
    PASTELLO: 7,
    VIOLETTE_LIGHT_GREEN: 8,
    INVERSE_FULL: 9,
    FULL_MEDIUM_COLOR_2: 10,
    FULL_MEDIUM_COLOR_3: 11

};
let currentTheme = 4;



let I;
let F;
let M;
let N;
let K;
let R;
let G;
let B;
let r;
let g;
let b;


// TODO
let useValidMCTheme = function( colorTheme ) {

    // currentTheme = MotionCubeTheme.FULL_2;
    for( let i in MotionCubeTheme ) {
        if( MotionCubeTheme[i] === colorTheme ) currentTheme = MotionCubeTheme[i];
    }

};

/**
 * ATTENZIONE
 * modificato
 *
 */
let prepare = function( colorTheme ) {


    //useValidMCTheme( colorTheme );
    for( let i in MotionCubeTheme ) {
        if( MotionCubeTheme[i] === colorTheme ) currentTheme = MotionCubeTheme[i];
    }

    // settings

    renderer = null;
    scene = null;
    camera = null;
    dummy = null;

    let container = document.getElementById("movingCubeAnimation");
    for( let i in container.childNodes ){
        if( container.childNodes[i].id === "animationCanvas" ) container.removeChild( container.childNodes[i] );
    }

    renderer = new window.THREE.WebGLRenderer({ alpha: true });
    renderer.setSize( w, h );
    renderer.domElement.id = "animationCanvas";

    document.getElementById("movingCubeAnimation").appendChild( renderer.domElement );

    scene = new window.THREE.Scene();
    camera = new window.THREE.PerspectiveCamera(75,(w/h),0.1, 1000);
    camera.position.set(20, 20, 20);    // 40, 40, 40
    camera.lookAt(scene.position);
    tiles = [];

    new plane(-tileSide,    0,  0,          0xcccccc,   0x111111);
    new plane(-2*tileSide,  0,  tileSide,   0xcccccc,   0x111111);
    new plane(-tileSide,    0,  tileSide,      0x888888,    0x111111);
    new plane(-2*tileSide,  0,  0,       0x888888,  0x111111);

    dummy = new window.THREE.Object3D();
    dummy.position.x = -6;      //- 3 * cubeSide / 2;	// -6
    dummy.position.y = 0.25;    //-(cubeSide / 2) -( tileHeight / 2 );	// 0.25
    dummy.position.z = 2;       //tileSide;	// 2
    scene.add( dummy );

    geometry = new window.THREE.CubeGeometry( cubeSide, cubeSide, cubeSide );
    // material = new window.THREE.MeshLambertMaterial( { color: 0x00FB9F, shading:  window.THREE.NoShading, emissive: 0x444444 } );
    material = new window.THREE.MeshLambertMaterial( { color: 0x009FFB, flatShading: window.THREE.FlatShading, emissive: 0x444444 } );
    cube = new window.THREE.Mesh( geometry, material );
    geometry.applyMatrix( new window.THREE.Matrix4().makeTranslation( -2, 2, 2 ) );
    dummy.add( cube );
    dummy.rotation.x = Math.PI + (Math.PI/2);

    light = new window.THREE.PointLight( 0xffffff );
    light.position.set( 10, 10, 10 );
    scene.add( light );
    //rotWorldMatrix;


};


function plane(x,y,z,emissiveColor, color){
    if(emissiveColor === undefined){
        let emissiveColor = 0x555555;
    }

    if(color === undefined){
        let color = 0xffffff;
    }


    this.x = x;
    this.y = y;
    this.z = z;
    this.emissiveColor = emissiveColor;
    this.color = color;

    var geometry = new window.THREE.CubeGeometry( tileSide, tileHeight, tileSide );
    var material = new window.THREE.MeshLambertMaterial( { color: this.color, shading:  window.THREE.NoShading, emissive: this.emissiveColor } );
    geometry.applyMatrix( new window.THREE.Matrix4().makeTranslation( this.x, this.y, this.z ) );
    var tile = new window.THREE.Mesh( geometry, material );

    scene.add( tile );
    tiles.push(tile);
}

function rotateAroundWorldAxis( object, axis, radians ) {

    var rotationMatrix = new window.THREE.Matrix4();

    rotationMatrix.makeRotationAxis( axis.normalize(), radians );
    rotationMatrix.multiply( object.matrix );                       // pre-multiply
    object.matrix = rotationMatrix;
    object.rotation.setEulerFromRotationMatrix( object.matrix );
}



let renderScene = function() {
    if( motionCubeIsRunning ) requestAnimationFrame(renderScene);
    if(dummy.rotation.x >= Math.PI*2 || bk === true){
        if(dummy.rotation.z <= -(Math.PI/2)){
            bk = true;
            if(dummy.rotation.x <= (Math.PI + (Math.PI/2))){
                if(dummy.rotation.y <= -(Math.PI/2)){
                    bk = false;
                    dummy.rotation.x = Math.PI + (Math.PI/2);
                    dummy.rotation.y = 0;
                    dummy.rotation.z = 0;
                }
                else{
                    dummy.rotation.y -= step
                }
            }
            else{
                dummy.rotation.x -= step;
            }
        }
        else{
            dummy.rotation.z -= step
        }
    }
    else{
        if(bk === true){

        }
        else{
            dummy.rotation.x += step;
        }
    }


    let themeColors = setTheme( counter );
    // let colorEmissive = themeColors.colorEmissive;  //0x6666F0;
    // let color = themeColors.color;  // 0x1188FF;
    cube.material.color.set( themeColors.color );
    cube.material.emissive.set( themeColors.colorEmissive );

    counter += 0.01;

    renderer.render(scene, camera);
};





export let setTheme = function( i ) {

    let _colorEmissive;
    let _color;
    let _colorArray = [];
    let _colorEmissiveArray = [];

    switch (currentTheme) {
        case MotionCubeTheme.FULL:
            _colorEmissiveArray = [
                (1/2) + (Math.cos(  3 * i ) / 2 ) ,          // color
                (4/5) + (Math.cos( 3 * i / 10 ) / 5) ,    // saturation
                (3/5) + (Math.cos( 3 * i / 5 ) / 5)       // light
            ];
            _colorArray = [
                0,
                0,
                (4/5) + (Math.cos(  (Math.PI + (3 * i)) ) / 5 )
            ];
            break;


        case MotionCubeTheme.FULL_2:
            _colorEmissiveArray = [
                (1/2) + (Math.cos(  i ) / 2 ) ,        // color
                (2/3) + (Math.cos( i / 5  ) / 3) ,  // saturation
                (4/5) + (Math.cos( i * 2 ) / 5)     // light
            ];
            _colorArray = [
                0,
                0,
                (4/5) + (Math.cos(  (Math.PI + i) ) / 5 )
            ];
            break;


        case MotionCubeTheme.PASTELLO:
            _colorArray = [
                (1/2) + (Math.cos(  i ) / 2 ) ,        // color
                (2/3) + (Math.cos( i / 5  ) / 3) ,  // saturation
                (4/5) + (Math.cos( i * 2 ) / 5)     // light
            ];
            _colorEmissiveArray = [
                0,
                0,
                (2/5) + (Math.cos(  (Math.PI + i) ) / 5 )
            ];
            break;


        case MotionCubeTheme.RED:
            _colorEmissiveArray = [0, 0, 0.3333];
            _colorArray = [
                (1/20) + (Math.cos(  (i) ) / 20 ),
                1,
                1
            ];
            break;

        case MotionCubeTheme.RED_2:
            _colorEmissiveArray = [0, 0, 0.3333];
            _colorArray = [
                (1/50) + (Math.cos( i ) / 50 ),
                (3/5) + (Math.cos( i * 5 ) / 5 ),
                (4/5) + (Math.cos( i / 5 ) / 5 )
            ];
            break;

        case MotionCubeTheme.COLOR_BLUE_EMISSION:

            _colorEmissiveArray = [0.6039, 0.3812, 0.8745];
            _colorArray = [
                (1/2) + (Math.cos(  (i) ) / 2 ),
                0.8,
                1
            ];

            break;

        case MotionCubeTheme.FULL_COLOR:

            _colorEmissiveArray = [0, 0, 0.3333];
            _colorArray = [
                (1/2) + (Math.cos(  (i) ) / 2 ),
                1,
                1
            ];

            break;

        // buono
        case MotionCubeTheme.FULL_MEDIUM_COLOR:

            _colorEmissiveArray = [0, 0, 0.3333];
            _colorArray = [
                (1/2) + (Math.cos(  (i) ) / 2 ),
                0.85,
                0.85
            ];

            break;

        case MotionCubeTheme.FULL_MEDIUM_COLOR_2:

            // con 32x sembra illuminarsi quando tocca
            // il pavimento
            _colorEmissiveArray = [
                0,
                0,
                // (3/10) + (Math.cos( 32 * i ) / 10 )
                (1/5) + (Math.cos( 75 * i ) / 5 )
            ];
            _colorArray = [
                (1/3) + (Math.cos( i ) / 3 ),
                (4/5) + (Math.cos( 7 * i ) / 5 ),
                (5/6) + (Math.cos( 11 * i ) / 6 )
            ];

            break;

        case MotionCubeTheme.FULL_MEDIUM_COLOR_3:

            // con 32x sembra illuminarsi quando tocca
            // il pavimento
            _colorEmissiveArray = [
                (1/3) + (Math.cos( i ) / 3 ),
                (4/5) + (Math.cos( 7 * i ) / 5 ),
                // (3/10) + (Math.cos( 32 * i ) / 10 )
                (1/5) + (Math.cos( 75 * i ) / 5 )
            ];
            _colorArray = [
                (1/3) + (Math.cos( i ) / 3 ),
                (4/5) + (Math.cos( 7 * i ) / 5 ),
                (5/6) + (Math.cos( 11 * i ) / 6 )
            ];

            break;


        case MotionCubeTheme.VIOLETTE_LIGHT_GREEN:
            _colorEmissiveArray = [0, 0, 0.25];
            _colorArray = [
                (3/5) + (Math.cos( i ) / 5 ),
                (3/4) + (Math.cos( i ) / 6 ),
                (4/5) + (Math.cos( i *2 ) / 5 )
            ];
            break;


        case MotionCubeTheme.INVERSE_FULL:
            _colorEmissiveArray = [
                0,
                0,
                (3/5) + (Math.cos( i * 5 ) / 5 )
            ];
            _colorArray = [
                (1/2) + (Math.cos(  i ) / 2 ) ,        // color
                (2/3) + (Math.cos( i / 5  ) / 3) ,  // saturation
                (4/5) + (Math.cos( i * 2 ) / 5)     // light
            ];
            break;




    }

    _colorEmissive  = "rgb(" + HSVtoRGB(..._colorEmissiveArray).join() +")";
    _color          = "rgb("+ HSVtoRGB(..._colorArray).join(",") +")";


    return {
        color: _color,
        colorEmissive: _colorEmissive,
        colorArray: _colorArray,
        colorEmissiveArray: _colorEmissiveArray
    };

};

// colori
function HSVtoRGB(H,S,V) {
    H *= 6;
    I = parseInt(H, 10);
    F = H - I;
    M = V * (1 - S);
    N = V * (1 - S * F);
    K = V * (1 - S * (1 - F));
    switch (I) {
        case 0:
            R = V;
            G = K;
            B = M;
            break;
        case 1:
            R = N;
            G = V;
            B = M;
            break;
        case 2:
            R = M;
            G = V;
            B = K;
            break;
        case 3:
            R = M;
            G = N;
            B = V;
            break;
        case 4:
            R = K;
            G = M;
            B = V;
            break;
        case 5:
        case 6: //for when H=1 is given
            R = V;
            G = M;
            B = N;
            break;
    }
    r = parseInt( 255 * R, 10 );
    g = parseInt( 255 * G, 10 );
    b = parseInt( 255 * B, 10 );
    return [r, g, b];
}


export let MotionCube = {
    play: function( colorTheme, restart ) {

        if( !motionCubeIsRunning || !!restart ) {
            motionCubeIsRunning = true;
            prepare( colorTheme );
            // if( !restart ) renderScene();
            renderScene();
        }

    },
    /*
    useTheme: function( colorTheme ) {
        useValidMCTheme( colorTheme );
        // motionCubeIsRunning = false;
        // setTimeout(function(){
        // motionCubeIsRunning = true;
        // }, 5);
    }
     */
};


