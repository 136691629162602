import React from "react";
import "./sirating-menu-logo-svg-component.css";
export const SiRatingMenuLogoSvgComponent = props => (
    <div
        id={ props.id }
        className={"logo-container-srml "+ props.className}
        onClick={ props.onClick }
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 97.68">
            <defs>
            </defs>
            <g id="Livello_2" data-name="Livello 2">
                <path className="srml-1"
                      d="M95.41,85.32c-3.86,1.51-8.31.14-12.22-2.71-.43-.31-.85-.65-1.27-1-6.2-5.19-10.61-14-8.2-20,2-5.09,11.31-10.8,20.77-14.47a67.52,67.52,0,0,1,9.59-3c5.67-1.28,10.37-1.31,12.14.62C121.64,50.68,106,81.17,95.41,85.32Z"/>
                <path className="srml-2"
                      d="M104.84,53.67C105.09,37.9,44,9.45,25.39,18S4.59,72,24.39,81,104.59,69.43,104.84,53.67Z"/>
            </g>
        </svg>
    </div>
);
