import {
    AssuranceRequirement_PUT,
    CollectInsurers_Get,
    CompanyInsurers_Get, DownloadCollectCSVFile, DownloadMaterialityCSVFile,
    GriTimings_Update,
    Insurer_DELETE,
    Insurer_PUT,
    SetInformativeAssurance,
    StartAssurance
} from "./request-manager";
import {errorlog, showlog} from "../utils";
import {saveAs} from 'file-saver';


export const getInsurer = (companyId, collectId) => {

    return (
        CompanyInsurers_Get( companyId )
            .then( list => {

                return (
                    CollectInsurers_Get( collectId )
                        .then( activeList => {
                            return ({
                                list: list,
                                active: activeList
                            })
                        })
                        .catch( e => {
                            return Promise.reject( e );
                        })
                );


            })
            .catch( e => {
                return Promise.reject( e );
            })
    );

}


export const eraseInsurer = (id, cid) => {
    return Insurer_DELETE( id, cid );
}



export const editCurrentCollect = (id, payload) => {
    return GriTimings_Update(payload, id);
}


export const saveInsurerList = (collectId, insurerList) => {
    return Insurer_PUT(collectId, insurerList);
}


export const toggleAssuranceRequirement = (collectId, isRequired) => {
    return AssuranceRequirement_PUT(collectId, isRequired);
}


export const setAssurance = (cid, state) => {
    return StartAssurance( cid, state );
}



export const saveSRToolInformativeAudit = ( collectID, informativeID, payload ) => {
    return SetInformativeAssurance( collectID, informativeID, payload );
}

export const getMaterialityCollFile = (rid, fileName,macroarea, cod) => {
    return (
        DownloadCollectCSVFile(rid,macroarea)
            .then(fileContent => {
                if(cod == "csv"){
                    let file = new Blob([fileContent]);
                    saveAs(file, fileName);
                    return true;
                }else if(cod == "xls"){
                    let file = new Blob(["\uFEFF"+fileContent], {encoding:"ANSI", type: "text/csv;charset=ANSI" , charset:"ansi" });
                    saveAs(file, fileName);
                    return true;
                }

            })
            .catch(e => {
                errorlog("errore", e);
                return Promise.reject(e);
            })
    );
}

