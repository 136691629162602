import {
    loadTranslationForSRToolCompilationRequirementsNode,
    loadTranslationForSRToolNode,
    loadTranslationForSRToolNodeGL,
    loadTranslationForSRToolRaccomandationNode,
    saveTranslationForSRToolCompilationRequirementsNode,
    saveTranslationForSRToolNode,
    saveTranslationForSRToolNodeGL,
    saveTranslationForSRToolRaccomandationNode
} from "../services/srtool-service";
import {cloneObject, showlog} from "../utils";
import {HIDDEN_LANGUAGES, LANGUAGES} from "../conf/languages";
import {setFlagToLanguage} from "../components/i18n/translate-function";

export const getTranslationForSRToolNode = (level, nodeID) => {
    return (
        loadTranslationForSRToolNode( level, nodeID )
            .then( texts => {
                let fullDataText = {};
                let clonedLamguagesForTexts =
                    cloneObject(
                        LANGUAGES
                            .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    )
                        .filter( lan => Object.keys( texts ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = texts[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                        .map( lan => {
                            fullDataText[lan.code.substr(0, 2).toLowerCase()] = lan;
                            return lan;
                        })
                ;

                showlog( "lista lingue" );
                showlog( clonedLamguagesForTexts );
                showlog( fullDataText );
                showlog( texts );
                showlog( LANGUAGES );
                // debugger;

                // return fullDataText;
                return clonedLamguagesForTexts;


            })
            .catch(e => Promise.reject( e ))
    );
}

export const getTranslationForSRToolNodeGL = (level, nodeID) => {
    return (
        loadTranslationForSRToolNodeGL( level, nodeID )
            .then( texts => {
                let fullDataText = {};
                let clonedLamguagesForTexts =
                    cloneObject(
                        LANGUAGES
                            .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    )
                        .filter( lan => Object.keys( texts ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = texts[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                        .map( lan => {
                            fullDataText[lan.code.substr(0, 2).toLowerCase()] = lan;
                            return lan;
                        })
                ;

                showlog( "lista lingue" );
                showlog( clonedLamguagesForTexts );
                showlog( fullDataText );
                showlog( texts );
                showlog( LANGUAGES );
                // debugger;

                // return fullDataText;
                return clonedLamguagesForTexts;


            })
            .catch(e => Promise.reject( e ))
    );
}



export const getTranslationForSRToolRaccomandationNode = (level, nodeID) => {
    return (
        loadTranslationForSRToolRaccomandationNode( level, nodeID )
            .then( texts => {
                let fullDataText = {};
                let clonedLamguagesForTexts =
                    cloneObject(
                        LANGUAGES
                            .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    )
                        .filter( lan => Object.keys( texts ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = texts[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                        .map( lan => {
                            fullDataText[lan.code.substr(0, 2).toLowerCase()] = lan;
                            return lan;
                        })
                ;

                return clonedLamguagesForTexts;

            })
            .catch(e => Promise.reject( e ))
    );
}



export const getTranslationForSRToolCompilationRequirementsNode = (level, nodeID) => {
    return (
        loadTranslationForSRToolCompilationRequirementsNode( level, nodeID )
            .then( texts => {
                let fullDataText = {};
                let clonedLamguagesForTexts =
                    cloneObject(
                        LANGUAGES
                            .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    )
                        .filter( lan => Object.keys( texts ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = texts[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                        .map( lan => {
                            fullDataText[lan.code.substr(0, 2).toLowerCase()] = lan;
                            return lan;
                        })
                ;

                return clonedLamguagesForTexts;

            })
            .catch(e => Promise.reject( e ))
    );
}



export const saveSrtoolNode = (level, nodeID, languagesData) => {

    let payload = {};
    languagesData
        .map( langData => {
            Object.keys( langData )
                .filter( field => !['code', 'value', 'id', 'label'].includes( field ))
                .forEach( lanField => payload[ lanField ] = langData[ lanField ])
        })
    ;

    return saveTranslationForSRToolNode( level, nodeID, payload );
}


export const saveSrtoolNodeGL = (level, nodeID, languagesData) => {

    let payload = {};
    languagesData
        .map( langData => {
            Object.keys( langData )
                .filter( field => !['code', 'value', 'id', 'label'].includes( field ))
                .forEach( lanField => payload[ lanField ] = langData[ lanField ])
        })
    ;

    return saveTranslationForSRToolNodeGL( level, nodeID, payload );
}


export const saveSrtoolRaccomandationNode = (level, nodeID, languagesData) => {

    let payload = {};
    languagesData
        .map( langData => {
            Object.keys( langData )
                .filter( field => !['code', 'value', 'id', 'label'].includes( field ))
                .forEach( lanField => payload[ lanField ] = langData[ lanField ])
        })
    ;

    return saveTranslationForSRToolRaccomandationNode( level, nodeID, payload );
}

export const saveSrtoolCompilationRequirementsNode = (level, nodeID, languagesData) => {

    let payload = {};
    languagesData
        .map( langData => {
            Object.keys( langData )
                .filter( field => !['code', 'value', 'id', 'label'].includes( field ))
                .forEach( lanField => payload[ lanField ] = langData[ lanField ])
        })
    ;

    return saveTranslationForSRToolCompilationRequirementsNode( level, nodeID, payload );
}

