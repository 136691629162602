import React, {Component} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {loadEvaluationUsersRole, setUsersRole} from "../../../business-logic/companies";
import {getLastElementOr} from "../../../utils/array-utils";
import {cloneObject} from "../../../utils/object-utils";
import ActionButton from "../../save-button";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";

const hiddenFields = [
	"id",
	"Selezionato",
	"isMain",
	"sector_id",
	"sector_name",
	"selected"
];

const fieldMap = {
	sector_id: "id",
	sector_name: "Settore",
	selected: "Selezionato",
	main: " "
};


const LIST_LIMIT = 8;
const paginationOptions = {
	paginationSize: 2,
	pageStartIndex: 1,
	showTotal: false,
	hidePageListOnlyOnePage: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
	),
	sizePerPageList: [ LIST_LIMIT ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'id',
		order: 'asc',
	},
];

const sortableFields = [
	"Settore",
	"Selezionato"
];


const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
// const { ExportCSVButton } = CSVExport;


export default class SectorListPopup extends Component {

	state = {
		selected: []
	}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
		this.loadData = this.loadData.bind( this );
		this.rowSelection = this.rowSelection.bind( this );
		this.activeFlagComponent = this.activeFlagComponent.bind( this );
		this.defaultFlagComponent = this.defaultFlagComponent.bind( this );
		this.flagComponent = this.flagComponent.bind( this );
	}


	activeFlagComponent = keyId => (<i id={"starFlag-"+ keyId} className={"mdi mdi-star activation-flag-active"}/>);

	defaultFlagComponent = keyId => (<i id={"starFlag-"+ keyId} className={"mdi mdi-star activation-flag-active-default"}/>);

	flagComponent = (keyId, sec) => (
		<i
			id={"starFlag-"+ keyId}
			className={"mdi mdi-star-outline low-visibility activation-flag"}
			onClick={ clickEvent => {
				clickEvent.stopPropagation();
				this.rowSelection( sec, true );
			}}
		/>
	);


	rowSelection = (row, isMain = false) => {

		showlog("riga selezionata");
		showlog(row);
		showlog("isMain: "+ isMain);
		// debugger;


		// ---- start process ----------------------------------------------------------
		new Promise((success, fail) => {


			// attesa nella riga
			let dataTmp =
				cloneObject( this.state.data )
					.map( sector => ({
						id: sector.id,
						Settore: sector.Settore,
						Selezionato: sector.Selezionato,
						isMain: sector.isMain
					}))
			;

			if (!!this.state.selected && !!this.state.selected.some(s => s === row.id)) {



				if( !!isMain ) {

					// Starring ----------------------------------------------------------------------------------------
					dataTmp =
						dataTmp

							.map( (sec, index) => {
								sec.main = this.flagComponent( index, sec );
								sec.isMain = false;
								sec.Settore = sec.Settore +"  ";
								return sec;
							})

							.map( (sec, index) => {
								if( sec.id === row.id ) {
									sec.main = this.activeFlagComponent( index );
									sec.isMain = true;
								}
								return sec;
							})
					;

					this.setState({
							...this.state,
							data: dataTmp,
							main: row.id
						},
						() => {
							success( false );
						}
					);

				}
				else {

					// Deselezione -------------------------------------------------------------------------------------
					dataTmp =
						dataTmp
							.map( currentRefereshed => {
								currentRefereshed =
									this.state.data
										.filter( sec => sec.id === currentRefereshed.id )
										.map( sec => {
											if( sec.id === row.id ) {
												sec.Selezionato = !!row.isMain;
											}
											return sec;
										})
										.reduce( getLastElementOr, currentRefereshed )
								;
								return currentRefereshed;
							})
							.map( (sec, index) => {
								if( sec.id === this.state.main ) {
									sec.main = this.activeFlagComponent( index );
								}
								if( !!isMain ) {
									if( sec.id === row.id ) {
										sec.main = this.activeFlagComponent( index );
									}
								}
								return sec;
							})
					;

					this.setState({
							...this.state,
							data: dataTmp,
							main: ( !!isMain ) ? row.id : this.state.main,
							selected:
								(!!!row.isMain)
									? (
										this.state.selected
											.filter(s => s !== row.id)
											// .map( s => s.id )
									)
									: this.state.selected
						},
						() => {
							success( false );
						}
					);

				}




			} else {


				// Selezione -------------------------------------------------------------------------------------------
				dataTmp =
					dataTmp
						.map( currentRefereshed => {
							currentRefereshed =
								this.state.data
									.filter( sec => sec.id === currentRefereshed.id )
									.map( sec => {
										if( sec.id === row.id ) {
											sec.Selezionato = true;
										}
										return sec;
									})
									.reduce( getLastElementOr, currentRefereshed )
							;
							return currentRefereshed;
						})
						.map( (sec, index) => {
							if( !!isMain ) {
								sec.main = this.flagComponent( index, sec );
								sec.isMain = false;
							};
							sec.Settore = sec.Settore +"  ";
							return sec;
						})
						.map( (sec, index) => {
							if( !!isMain ) {
								if( sec.id === row.id ) {
									sec.main = this.activeFlagComponent( index );
									sec.isMain = true;

									// lo si deve per fare per un problema di refresh del browser
									sec.Settore = sec.Settore +" ";
								}
							}
							return sec;
						})
				;

				let refreshedRow =
					dataTmp
						.filter( r => r.id === row.id )
						.map( sector => {
							return ({
								id: sector.id,
								Settore: sector.Settore,
								Selezionato: sector.Selezionato,
								isMain: sector.isMain
							});
						})
						.reduce( getLastElementOr, {id: 0} )
				;


				this.setState({
						...this.state,
						data: dataTmp,
						main: ( !!isMain ) ? row.id : this.state.main,
						selected: [...this.state.selected, refreshedRow.id]
					},
					() => {

						success( true );
					}
				);

			}



		})

			.then( isAddToSelections => {
				if( !!isAddToSelections ) showlog("Aggiunta alla selezione");
				else showlog("Eliminazione dalla selezione");
			})





	}


	toggle = forceRefresh => {
		this.props.onClose( forceRefresh );
	}

	render() {


		renderlog("SectorListModal SectorListPopup", this.state, this.props);



		let context = (
			<React.Fragment>
				<Spinner className="spinner-border-sm" />
				<span className={"loading-text-alignment"}>Caricamento...</span>
			</React.Fragment>
		);


		// if( !!this.state.data ) {
		if(!!this.state.data && this.state.data.length > 0) {




			showlog("controllo su data");
			showlog( this.state.data );


			context = (
				<ToolkitProvider
					bootstrap4
					keyField="Settore"
					search
					// exportCSV={{ onlyExportFiltered: true, exportAll: false }}
				>
					{props => (
						<React.Fragment>

							<Row>
								<Col>
									<SearchBar {...props.searchProps} />
								</Col>
							</Row>

							<BootstrapTable
								id={"sectorlist-table"}
								bootstrap4
								striped

								{...props.baseProps}
								size="sm"
								responsive
								keyField="Settore"
								bordered={ false }
								data={ this.state.data }
								columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
								defaultSorted={ defaultSorted }
								pagination={ paginationFactory(paginationOptions) }
								wrapperClasses="table-responsive"
								selectRow={{
									mode: 'checkbox',
									clickToSelect: true,

									onSelect: row => {
										this.rowSelection( row );
									},

									selectionRenderer: row => {


										let currentRow = (
											this.state.data
												.filter( sec => sec.Settore === row.rowKey /*&& !!row.checked && !row.disabled*/ )
												.reduce(getLastElementOr, null)
										);

										row.checked = !!(
											this.state.data
												.filter( sec => sec.Settore === row.rowKey /*&& !!row.checked && !row.disabled*/ )
												.filter( sec => !!sec.Selezionato )
												.reduce(getLastElementOr, null)
										);



										return (
											<div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													checked={row.checked}
													disabled={row.disabled}
												/>
												<label
													className="custom-control-label"
													onClick={e => {
														e.preventDefault();
													}}
												/>
											</div>
										);

									},
									selectionHeaderRenderer: ({indeterminate, ...rest}) => {
										return null;
									}
								}}

							/>


						</React.Fragment>
					)}
				</ToolkitProvider>
			);
		}
		else {
			context = (
				<WaitingOrNodataMessage
					waiting={ !(!!this.state.data && this.state.data.length === 0) }
					nodataMessage={"Nessun utente"}
				/>
			)
		}



		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>

					{(() => {

					switch (this.props.type) {
						case 1:
							return (
							"PM"
							)
						case 2:
							return (
								"Assignee"
							)

					}

					})()}


				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>



				<ModalFooter>

					<ActionButton
						promise={() => {
							//return setCompanySector( this.props.companyId, this.state.selected.map( s => s ), this.state.main );
							return setUsersRole( this.props.evaluationId, this.state.selected.map( s => s ),this.props.type);
						}}
					/>


				</ModalFooter>



			</Modal>


		);


		return content;
	}

	componentDidMount() {
		this.loadData();
	}






	loadData( tmp ) {

		return (
			loadEvaluationUsersRole( this.props.evaluationId,this.props.type)
				// log -----------------------------------------------------------------------------------------------------
				.then( responseData => {

					showlog("prima di mettere i dati nello state | start");
					showlog(responseData);

					return responseData;
				})



				// field sort ----------------------------------------------------------------------------------------------
				.then( responseData => {

					return (
						responseData
							.map( row => {

								let sortedRow = {};
								Object.keys(fieldMap)
									.map( field => {
										sortedRow[fieldMap[field]] = row[field];
									})
								;
								Object.assign(sortedRow, row);
								return sortedRow;

							})
					);
				})

				// row sort ------------------------------------------------------------------------------------------------
				.then( responseData => {

					return (
						responseData
							.sort((x,y) => {
								if( x.Settore > y.Settore ) return 1;
								return -1;
							})
					)
				})

				// adjust --------------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( row => {
								if( !!row.Settore ) row.Settore = row.Settore.trim();
								return row;
							})
					);
				})

/*
				// additional fields ---------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( (row, index) => {
								row.main = (


									(!!this.state.main && this.state.main === row.id)
										? this.activeFlagComponent( index )
										: (
											(!!row.isMain)
												? this.defaultFlagComponent( index )
												: this.flagComponent( index, row )
										)

								);
								return row;
							})
					);
				})
*/


				// finish --------------------------------------------------------------------------------------------------
				.then( responseData => {

					// controllo inWait
					if( !!tmp ) {
						responseData
							.map( row => {
								let currentTmp =
									tmp
										.filter( r => r.id === row.id )
										.reduce( getLastElementOr, null )
								;
								if( !!currentTmp ) {
									row.inWait = currentTmp.inWait;
								}
							})
					}


					this.setState(
						{
							...this.state,
							data: responseData,
							selected:
								responseData
									.filter( sector => !!sector.Selezionato )
									.map( sector => sector.id )
						},
						() => {
							return true;
						}
					);
				})
				.catch( e => {
					errorlog("loadEvaluationUsersRole", e );
				})
		);


	}

}
