import React, {Component} from 'react';
import {Card, CardBody, Col, CustomInput, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {
	COLLECT_STATUS,
	COLOR_POLICY,
	DATE_FORMAT_IT,
	GRI_VERSION,
	PATH_PREFIX,
	permissions,
	role,
	TREE_TYPE
} from "../../../env";
import moment from "moment";
import {loadCollect} from "../../../business-logic/gri";
import AddGriCollectModal from "../../../components/area/gri-admin/add-gri-collect-modal";
import Spinner from "../../../components/Spinner";
import {Redirect} from "react-router-dom";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {emoj, errorlog, getLastElementOr, inverseSortById, onlyNotNull, renderlog, showlog} from "../../../utils";
import {loadActiveService, loadOrganizzationActiveService} from "../../../business-logic/active-service";
import {getAllServices} from "../../../services/active-service";
import ActionButton from "../../../components/save-button";
import {setAssuranceRequirement} from "../../../business-logic/collect";
import {EMOJ} from "../../../emoj";
import AssuranceDoneAttachmentModal from "../si-rating-admin/assurance-done-attachment-modal";
import {SRToolInfoBox} from "../../../components/srtool-info-box";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import ConfirmArbModal from './confirm-arb-modal';
import {translate} from '../../../components/i18n/translate-function';
import SRToolLogoDarkText from "../../../assets/images/Sr_tool_logo_dark_text_2.svg";
import VersionModal from "../../../components/area/super-admin/version-modal";


const hiddenFields = [
	"id",
	"isPmauthorized",
	(
		(
			(!!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.SRTool_MANAGER ))
			|| !( !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.SUPER_ADMIN )  || !!isUserRole( role.ADMIN ) )
		)
			? "isPmAuthorized"
			: null
	),
	"isAssuranceRequirement",
	( (!hasPermission( permissions.SHOW_ASSURANCE )) ? "assuranceSwitch" : null ),
	( (!hasPermission( permissions.SHOW_ASSURANCE )) ? "state" : null ),
	( (!hasPermission( permissions.SHOW_ASSURANCE )) ? "status" : null ),
	"companyId",
	"isAssuranceRequirement",
	"timing",
	"reportType",
	"userCreate",
	"userUpdate",
	"dateCreate",
	"dateUpdate",
	"step",
	"alive",
	"serviceActiveId",
	"company",
	// "attachments",
	"dashboards",
	"griIndicatorsStructure",
	"griInformative103",
	"griInformativesNote",
	"griMaterialThematics",
	"griStructure",
	"griUnitMeasurementsTimings",
	"originalName",
	translate("Start"),
	translate("End"),
	(
		(
			!!isUserRole( role.SUPER_ADMIN )
			|| !!isUserRole( role.ARB_OPERATOR )
			|| !!isUserRole( role.PROJECT_MANAGER )
			|| !!isUserRole( role.ASSURANCER )
		)
			? null
			: "companyName"
	),
	"state",
	"isValidateAssuranceActive",
	"canIopen",
	// ( !!isUserRole( role.ASSURANCER ) ? "" : "attachments" )
	"hasAttachments"
	]
	.filter( onlyNotNull )
;

const fieldMap = {
	id: "Id",
	name: translate("Name"),
	year: translate("Year"),
	dateStart:  translate("Start"),
	dateEnd:  translate("End"),
	version: translate("Version"),
	companyName: translate("Companies"),
	status: "Status",
	isPmAuthorized: translate("Authorizes ARB spa"),
	assuranceSwitch: "Assurance",
	// dateCreate: "Creata",
	// isAssuranceRequirement: "Assurance",
	// dateUpdate: "Aggiornata",
	// reportType: "Tipo Report",
	// timing: "Annuale",
	// userCreate: "Utente creato",
	// userUpdate: "Utente Aggiornato"
	// conf: "  ",
	// values: " "
	// " ": " "
	// wait: " "
	attachments: translate("Assurance Document")
};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Collects")}
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'Id',
		order: 'asc',
	},
];

const sortableFields = [
	"dateStart",
	"dateEnd",
	"name"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			// custom date time sort -----------------------------------------------------------------------------------
			.map( column => {
				if( ["dateStart", "dateEnd"].includes( column.dataField ) ) {
					column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
						if (order === 'asc') {
							return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
						}
						return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
					}
				}
				return column;
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;



export default class GriAdminCollects extends Component {

	state = {
		data: null,
		tabToggle: null,
		navigationSteps: [
			{ label: 'SR Tool', path: PATH_PREFIX +'/welcome' },
			{ label: translate("Collects"), active: true }
		],
		treeType: TREE_TYPE.SET.id,
		waitForNewCollect: true,
		waitingRow: [],
		openConfirmArbModal: false,
		collectId: null,
		collectPmAuthorized: null,
		activeServices: [],
		activeServicesLoaded: false
	}

	constructor(props) {
		super( props );

		this.loadData = this.loadData.bind( this );
		this.waitingRow = this.waitingRow.bind( this );


	}



	openTabs = {
		renderer: row => {


			// ATTENZIONE temporaneo
			// if( !!isUserRole( role.SRTool_MANAGER ) ) return true;

			if(!!row.isPmAuthorized) 	delete row.isPmAuthorized;
			if(!!row.assuranceSwitch) 	delete row.assuranceSwitch;
			if(!!row.hasAttachments) 	delete row.hasAttachments;
			if(!!row.attachments) 	delete row.attachments;

			// showlog( isUserRole( role.ASSURANCER ));
			showlog(this.state);
			showlog(this.props);
			showlog( row );
			// debugger;


			if(
				// row.state !== COLLECT_STATUS.inAssurance.id         // non è in fase di assurance in corso
				// && row.state !== COLLECT_STATUS.assuranceDone.id    // non è assurance finita
				!row.canIopen
				&& !!isUserRole( role.ASSURANCER )                  // Operatore Assurance
			) {
				let message = "Non ci sono informative da validare"; // TODO da tradurre
				if( row.state === COLLECT_STATUS.virgin.id ) {
					message = "La raccolta è ancoa in fase di configurazione"; // TODO da tradurre
				}
				return (
					<code className={ COLOR_POLICY.error.textClass }>&nbsp;&nbsp;{ message }&nbsp;&nbsp;</code>
				);
			}

			return (
				<Redirect
					to={{
						pathname: PATH_PREFIX +"/srtool/" + translate("collect") + "/"+ row.id,
						state: row
					}}
				/>
			);

		},
		showExpandColumn: false,
		onlyOneExpanding: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
			// return null;
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
			// return null;
		},
	};


	/**
	 * NON funziona bene
	 *
	 * @param collectID
	 * @returns {Promise<unknown>}
	 */
	waitingRow( collectID ) {
		return (
			new Promise((success, fail) => {

				this.setState(
					{
						...this.state,
						data: this.state.data.map( c => {
							if( c.id === collectID ) {
								showlog( c );
								c.name = c.originalName + (
									<div className={"pmAuthorization-waiting"}>
										<Spinner color={ "primary" } className="spinner-border-sm" />
										<span className={"loading-text-alignment"}>in attesa...</span>
									</div>
								);
							}
							return c;
						}),
						waitingRow: [
							...this.state.waitingRow,
							collectID
						]
					},
					() => {
						success();
					}
				)

			})
		)
	}



	render() {


		renderlog(
			"GriAdminCollects",
			this.state,
			this.props
		);


		if( !!this.state.forceRedirect ) {
			delete this.state.data[0].isPmAuthorized;
			delete this.state.data[0].assuranceSwitch;
			delete this.state.data[0].attachments;
			return (
				<Redirect
					to={{
						pathname: PATH_PREFIX +"/srtool/" + translate("collect") + "/"+ this.state.data[0].id,
						state: this.state.data[0]
					}}
				/>
			);
		}

		if( !!this.state.openConfirmArbModal ) {

			return (
				<ConfirmArbModal
					esternalCollectId= {this.state.collectId}
					esternalCollectIsPmAuthorized = {!this.state.collectPmAuthorized}
					onClose={closeEvent => {
						if(!!closeEvent){
							this.loadData()
						}else{
							this.setState({
							...this.state,
							openConfirmArbModal: false
						})
						}


					}}
				/>
			);
		}



		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={ this.state.navigationSteps }
					title={
						<React.Fragment>
							{/*<img src={ PRODUCTION_HOST +"/assets/Loghi/SR_Tool.png" } alt="SR Tool" height="60" style={{marginRight:'10px'}} />*/}

							<img
								// src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
								src={ SRToolLogoDarkText }
								style={{
									marginRight:'10px',
									height: "60px",
									float: "left",
									marginTop: "10px"
								}}
							/>

							<p style={{float:"left", marginTop: "27px",
								marginBottom: "-23px",
								fontSize: "14px",cursor:"pointer",height:"0px"}}
							   onClick={clickEvent => {
								   this.setState({
									   versionModal: true
								   })
							   }
							   }> v. {(localStorage.getItem("srToolVersion")).trim()} </p>

							{/*"Raccolte"*/}
							<SRToolInfoBox
								className={"srtool-info-box"}
							/>
						</React.Fragment>
					}


				/>

				{
					!!this.state.versionModal
						? (
							<VersionModal
								tool={"SR Tool-1"}
							onClose={ closeEvent => {
								this.setState({
									...this.state,
									versionModal: false
								})
							}}
							/>
						)
						:
						null
				}


				{
					!!this.state.assuranceDoneAttachmentModal
						? (
							<AssuranceDoneAttachmentModal
								collect={{ id: this.state.assuranceDoneAttachmentModal }}
								onClose={() => {
									this.setState({
										...this.state,
										assuranceDoneAttachmentModal: null
									})
								}}
							/>
						)
						: null
				}

				{
					!!this.state.assuranceAttachmentMessage
						? (
							<ErrorMessageModalComponent
								className={"green-notific"}
								message={ "Lettera finale non ancora disponibile" }
								noheader={ true }
								noHazard={ true }
								onClose={ () => {
									this.setState({
										...this.state,
										assuranceAttachmentMessage: null
									})
								}}
							/>
						)
						: null
				}

				{
					(this.state.activeServicesLoaded === false)
						?
						((
							<WaitingOrNodataMessage
								waiting={true}
								fullHeight={true}
							/>
						))
						:
						null
				}

				{

					(!!this.state.activeServicesLoaded)?
						(
							(this.state.activeServices.includes(1))?
								(<Card>
									<CardBody>


										<Row>
											<Col>
												<div className="page-title-box">
													<div className="page-title-right">


														{
															( !!hasPermission( permissions.CREATE_NEW_COLLECT ) && !!this.state.createCollectEnabled )
																? (

																	<ActionButton
																		buttonText={
																			<React.Fragment>
																				<i className="mdi mdi-playlist-plus"/> {translate("New Collect")}
																			</React.Fragment>
																		}
																		color={"success"}
																		promise={ () => {
																			return Promise.resolve();
																		}}
																		onResult={ result => {
																			this.setState({
																					...this.state,
																					newCollectModal: true,
																					tree: null,
																					waitForNewCollect: true
																				},
																				this.loadData
																			);
																		}}
																	/>
																)
																: null
														}


													</div>
													<h4 className="page-title">{translate("Collects List")}</h4>
												</div>
											</Col>
										</Row>



										<ToolkitProvider
											bootstrap4
											keyField="Id"
											search
											exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
											{props => (
												<React.Fragment>
													<Row>
														<Col sm={10}>
															<SearchBar {...props.searchProps} />
														</Col>
														<Col sm={2} className={"flex-right-align"}>
															{
																!!hasPermission( permissions.GET_ARCHIVED_COLLECTS )
																	? (
																		<CustomInput
																			type="switch"
																			id={"archived-collect-toggler"}
																			name={"archived-collect-toggler"}
																			label={
																				<span>Archivio</span>
																			}
																			className={""}
																			onClick={clickEvent => {

																				if( !!document.getElementById( "archived-collect-toggler" ) ) {
																					document.getElementById( "archived-collect-toggler" ).checked = !this.state.isArchived;
																				}

																				// let isArchived = !this.state.isArchived;
																				this.setState({
																						...this.state,
																						data: null,
																						isArchived: !this.state.isArchived
																					},
																					() => {
																						this.loadData()
																							.then( result => {
																								// this.setState({
																								// 	...this.state,
																								// 	isArchived: isArchived
																								// });
																							})
																							.catch(e => {
																								errorlog("switch to archived collects", e);
																							})
																						;
																					}
																				)


																			}}
																			defaultChecked={ !!this.state.isArchived }
																			valid={ !!this.state.isArchived && !!this.state.data }
																		/>
																	)
																	: null
															}

														</Col>
													</Row>


													{
														(!!this.state.data && this.state.data.length > 0)
															? (
																<BootstrapTable
																	bootstrap4
																	striped
																	{...props.baseProps}
																	size="sm"
																	responsive
																	keyField="id"
																	data={ this.state.data }
																	columns={
																		formatColumns(
																			Object.keys( this.state.data[0] )
																			// .filter( field => Object.keys(fieldMap).includes( field ) )
																		)
																	}
																	defaultSorted={ defaultSorted }
																	pagination={ paginationFactory(paginationOptions) }
																	wrapperClasses="table-responsive"
																	expandRow={ this.openTabs }
																	parentClassName={"collect-list-table"}
																	className={"collect-list"}
																/>
															)
															: (
																<WaitingOrNodataMessage
																	waiting={ !(!!this.state.data && this.state.data.length === 0) }
																	fullHeight={ true }
																	nodataMessage={ !!this.state.noDataMessage ? this.state.noDataMessage : translate("No Collect")}
																	spinnerColor={"primary"}
																/>
															)
													}



												</React.Fragment>
											)}
										</ToolkitProvider>

									</CardBody>
								</Card>)
								:
								(
									<Card>
										<CardBody>
											<h4 className="page-title">Servizio Scaduto</h4>
											<br/>
											<br/>
											<span><a href="/support" target="_blank"> Support </a></span>
											<br/>
											<span><a href="https://arbspa.it/index.php/contatti/" target="_blank"> Contact</a></span>
										</CardBody>
									</Card>
								)
						)
							: null
				}

				{
					(!!this.state.newCollectModal)
						? (
							<AddGriCollectModal
								allCollect={ this.state.data }
								onClose={ (result, e) => {
									this.setState({
										...this.state,
										newCollectModal: null,
											newCollectErrorMessage: (!!e ? e : null)
									},
									() => {
											// this.loadData();

											showlog("ritorno dal salvataggio della raccolta");
											showlog( result );



											this.loadData( !!result );

										}
									)
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.newCollectErrorMessage)
						? (
							<ErrorMessageModalComponent
								message={this.state.newCollectErrorMessage}
								noheader={true}
								noHazard={true}
								onClose={() => {
									this.setState({
										...this.state,
										newCollectErrorMessage: null
									})
								}}
							/>
						)
						: null
				}


			</React.Fragment>
		);
	}



	componentDidMount() {
		this.loadData();
		loadActiveService()
			.then( result =>

				// showlog("aaaaa",result)
				result.map( service =>
					service.service_id
				)).then(r=> this.setState({
				...this.state,
				activeServices: r,
				activeServicesLoaded: true
			})
		)
	}



	canCreateNewCollect = () => {

		// debugger;

		return (
			new Promise((success, fail) => {

				if( isUserRole( role.SRTool_ADMIN ) || isUserRole( role.SRTool_MANAGER ) || isUserRole( role.ARB_OPERATOR ) || isUserRole( role.ASSURANCER ) ) {
					success();
				}
				else {
					// controllo servizio attivo
					Promise.all([
						loadActiveService(),
						( !isUserRole( role.ADMIN ) && !isUserRole( role.SRTool_MANAGER ) ) ? loadOrganizzationActiveService() : Promise.resolve([])
					])
						.then( serviceList => {

							// debugger;

							let allServices = [];
							serviceList
								.map( services => {
									allServices = [ ...allServices, ...services ];
								})
							;

							return allServices;
						})
						.then( allServices => {

							// debugger;

							getAllServices()
								.then( allNominalServies => {



									if(
										allServices
											.map( service => service.service_id )
											.filter( service => (
												allNominalServies
													.map( ans => ans.id )
													.includes( service )
											))
											.filter( onlyNotNull )
											.some( service => service === 1 )

									) {

										if( !!hasPermission( permissions.GET_COLLECT ) ) {
											success();
										}
									}
									else {
										fail("SRTool service is not active");
									}
								})

							;
						})
						.catch(e => {

							// debugger;

							// debugger;
							errorlog("Caricamento Servizi Attivi come aziende e organizzazioni", e);
							fail( e );
						})
				}
			})
		);

	}



	loadData( forceRedirect ) {


		return (

			this.canCreateNewCollect()
				.then( () => {

					showlog("2");



					return (
						new Promise((success, fail) => {

							if( !!hasPermission( permissions.GET_COLLECT ) ) {

								showlog("3");

								loadCollect( 0, !!this.state.isArchived )

									// time format  -------------------------------------------------------------------------------------------
									.then( collects => {

										showlog("4");

										return (
											collects
												.map(collect => {

													collect.dateCreate = moment(collect.dateCreate).format(DATE_FORMAT_IT);
													collect.dateEnd = moment(collect.dateEnd).format(DATE_FORMAT_IT);
													collect.dateStart = moment(collect.dateStart).format(DATE_FORMAT_IT);
													collect.dateUpdate = moment(collect.dateUpdate).format(DATE_FORMAT_IT);
													collect.userCreate = moment(collect.userCreate).format(DATE_FORMAT_IT);
													collect.userUpdate = moment(collect.userUpdate).format(DATE_FORMAT_IT);

													// collect.Inizio = moment(collect.dateStart.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);
													// collect.Fine = moment(collect.dateEnd.split("T")[0], CALENDAR_MOMENT_DATE_FORMAT_YEARS).format(DATE_FORMAT_IT);

													// collect.Inizio = moment(collect.dateStart).format(DATE_FORMAT_IT);
													// collect.Fine = moment(collect.dateEnd).format(DATE_FORMAT_IT);
													collect[translate("Start")] = collect.dateStart;
													collect[translate("End")] = collect.dateEnd;


													// collect.id = collect.id;


													if( !!isUserRole( role.SRTool_ADMIN ) || !!isUserRole( role.SUPER_ADMIN )  || !!isUserRole( role.ADMIN ) ) {

														collect.isPmAuthorized = (
															<div
																className={""}
																onClick={ clickEvent => {
																	clickEvent.stopPropagation();
																}}
															>
																<CustomInput
																	type="switch"
																	id={"isPmAuthorized-"+ collect.id}
																	name={"isPmAuthorized-"+ collect.id}
																	label={""}
																	className={"pmAuthorizationToggler"}
																	onClick={clickEvent => {
																		this.setState({
																			...this.state,
																			openConfirmArbModal: true,
																			collectId: collect.id,
																			collectPmAuthorized: collect.isPmauthorized
																		})

																	/*	clickEvent.stopPropagation();
																		if( !!document.getElementById( "isPmAuthorized-"+ collect.id ) ) {
																			document.getElementById( "isPmAuthorized-"+ collect.id ).checked = !collect.isPmauthorized;
																			toggleCollectPmAuthorized( collect.id, !collect.isPmauthorized )
																				.then( result => {
																					this.loadData();
																				})
																				.catch( e => {
																			 		errorlog("pm authorized", e);
																				})
																			;
																		} */
																	}}
																	valid={ !!collect.isPmauthorized }
																	defaultChecked={ !!collect.isPmauthorized }

																/>
															</div>
														);

													}


													if( !!hasPermission( permissions.SHOW_ASSURANCE ) ) {

														if( hasPermission( permissions.SET_ASSURANCE ) ) {
															collect.assuranceSwitch = (
																<div
																	className={"inline-container"}
																	onClick={ clickEvent => {
																		clickEvent.stopPropagation();
																	}}
																>
																	<CustomInput
																		type="switch"
																		id={"haveAssurance-"+ collect.id}
																		name={"haveAssurance-"+ collect.id}
																		label={""}
																		className={"pmAuthorizationToggler"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();

																			setAssuranceRequirement( collect.id, !collect.isAssuranceRequirement )
																				.then( result => {
																					if (!!document.getElementById("haveAssurance-"+ collect.id)) {
																						document.getElementById("haveAssurance-"+ collect.id).checked = !collect.isAssuranceRequirement;
																					}
																					this.loadData();
																				})
																				.catch( e => {
																					errorlog("set assurance requirement", e);
																					if (!!document.getElementById("haveAssurance-"+ collect.id)) {
																						document.getElementById("haveAssurance-"+ collect.id).checked = collect.isAssuranceRequirement;
																					}
																				})
																			;


																		}}
																		defaultChecked={ !!collect.isAssuranceRequirement }
																		valid={ !!collect.isAssuranceRequirement }
																		disabled={ collect.state >= COLLECT_STATUS.inAssurance.id }
																	/>
																</div>
															);
														}
														else collect.assuranceSwitch = (

															(collect.state === COLLECT_STATUS.assuranceDone.id)
																? (
																	<span>
																		{ emoj(EMOJ.check_mark_button) }
																	</span>
																)
																: (
																	// !!collect.isAssuranceRequirement
																	collect.state === COLLECT_STATUS.inAssurance.id
																		? (
																			<span>
																				{/*{ emoj(EMOJ.check_box_with_check) }*/}
																				{ emoj(EMOJ.green_circle) }
																			</span>
																		)
																		: ""
																)


														);

													}




													collect.originalName = collect.name;


													collect.status =
														Object.keys( COLLECT_STATUS )
															.map( field => COLLECT_STATUS[ field ] )
															.filter( cs => cs.id === collect.state )
															.reduce( getLastElementOr, COLLECT_STATUS.virgin )
															.label
													;

													/*
													collect.version = (
														!!collect.version
															? "2021"
															: "2019"
													);
													 */

													collect.version =
														Object.keys(GRI_VERSION)
															.map( field => GRI_VERSION[ field ] )
															.filter( gri => gri.id === collect.version )
															.reduce( getLastElementOr, GRI_VERSION.oldVersion )
															.label
													;

													collect.attachments =
														(
															<i
																className={"mdi mdi-note-outline assurance-attachment-icon adjust-icon-rotation "+ (!!collect.hasAttachments ? "green-color": "") +" cursor-pointer"}
																onClick={ clickEvent => {
																	clickEvent.stopPropagation();

																	/*
																	tutti quelli che vedono l'icona dell'allegato possono vedere
																	gli allegati se ci sono ( icona verde ) altrimenti se non ci
																	sono allegati ( icona grigia ) viene mostrato un messaggio

																	ATTENZIONE
																	se si è un ASSURANCE_OPERATOR invece al click sull'icona
																	viene mostrato sempre la modale degli allegati
																	 */
																	let isToOpenModal = false;
																	if( !!isUserRole( role.ASSURANCER ) ) {
																		isToOpenModal = true;
																	}
																	else {
																		if( !collect.hasAttachments ) {
																			// icona grigia = nessun allegato e ruolo NON assurance_operator
																		}
																		else {
																			// icona verde = ci sono allegati e ruolo NON assurance_operator
																			isToOpenModal = true;
																		}
																	}

																	if( !!isToOpenModal ) {
																		this.setState({
																			...this.state,
																			assuranceDoneAttachmentModal: collect.id
																		})
																	}
																	else {
																		// messaggio
																		this.setState({
																			...this.state,
																			assuranceAttachmentMessage: collect
																		})
																	}

																}}
															/>
														)


													return collect;
												})
										);
									})




									// additional fields ------------------------------------------------------------------------------------------
									/*
                                    // buttons
                                    */

									// field sort   -------------------------------------------------------------------------------------------
									.then( responseData => {
										showlog("5");
										// debugger;
										return (
											responseData
												.map( row => {

													let sortedRow = {};
													Object.keys(fieldMap)
														.map( field => {
															sortedRow[field] = row[field];
														})
													;
													Object.assign(sortedRow, row);
													return sortedRow;

												})
										);
									})

									// row sort -----------------------------------------------------------------------------------------------
									.then( responseData => {
										showlog("6");
										return responseData.sort( inverseSortById );
									})

									// finish   -----------------------------------------------------------------------------------------------
									.then( responseData => {

										showlog("7");

										showlog("load collect");
										showlog(responseData);

										this.setState(
											{
												...this.state,
												data: responseData,
												waitForNewCollect: null,
												forceRedirect: !!forceRedirect,
												createCollectEnabled: true,
												openConfirmArbModal: false
											},
											() => {
												success();
											}
										);
									})
									.catch( e => {
										errorlog("Caricamento Raccolte", e);
										fail( e );
									});
							}
							else fail("PERMISSION DENIED");


						})
					);





				})
				.catch( e => {


					showlog("8");
					showlog( e );


					this.setState(
					{
						...this.state,
						data: [],
						waitForNewCollect: null,
						forceRedirect: !!forceRedirect,
						noDataMessage: ( !!e.indexOf && e.indexOf("is not active") !== -1 ) ? "Servizio Non Attivo" : null
					});
				})

		);







	}


}
