import React, {useEffect, useState} from "react";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {emoj, extractErrorMessage, getLastElementOr, isNotEmptyArray, renderlog, showlog, sortById} from "../utils";
import {EMOJ} from "../emoj";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {WaitingOrNodataMessage} from "./waiting-or-nodata-message";
import {loadCompaniesData} from "../business-logic/companies";
import {translate} from "./i18n/translate-function";


const hiddenFields = [
    "codCliFor"
    , "indirizzo"
    , "provincia"
    , "provincia_descrizione"
    , "tipologiaCommerciale"
    , "classeContabile"
    , "categoria"
    , "leadingCompany"
    , "dimension"
    , "dimensioni"
    , "id"
];

const fieldMap = {
    partitaIva: "Partita IVA",
    ragioneSociale: "Ragione Sociale",
    localita: "Località",
    indirEmail: "Email"
};


const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Aziende
        </span>
    ),
    sizePerPageList: [
        {
            text: 'pochi',
            value: 10,
        },
        {
            text: 'normale',
            value: 50,
        },
        {
            text: 'molti',
            value: 100,
        }
    ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];

const sortableFields = [
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;


export const CompaniesFilterModal = props => {


    const [companies, setCompanies] = useState();
    const [selected, setSelected] = useState([]);
    const [allChecked, toggleAll] = useState( false );
    const [error, setError] = useState();
    useEffect(() => {
        if( typeof props.onSelection === "function" ) {
            props.onSelection( selected );
        }
        return () => {
            // accade prima del cambio
            return selected;
        };
    }, [selected]);

    // useEffect(() => {
    //     if( !!allChecked )    setSelected( companies );
    //     else                  setSelected([]);
    //     return () => {
    //         //
    //     };
    // }, [allChecked]);


    renderlog("CompaniesFilterModal", {companies:companies, selected:selected, allChecked:allChecked});
    let content = (
        <Modal isOpen={ true } toggle={ () => props.onClose() }>
            <ModalHeader toggle={ () => props.onClose() }>
            <span className={""}>
                { emoj( EMOJ.clipboard ) }
            </span>
            <span className={"req-button-label"}>
                Aziende
            </span>
            </ModalHeader>
            <ModalBody>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    search
                >
                    {
                        props => (
                            <React.Fragment>

                                <Row>
                                    <Col sm={6}>
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                </Row>


                                {
                                    isNotEmptyArray( companies )
                                        ? (
                                            <BootstrapTable
                                                bootstrap4
                                                striped
                                                {...props.baseProps}
                                                size="sm"
                                                responsive
                                                keyField="id"
                                                data={ companies }
                                                columns={ formatColumns( Object.keys( companies.reduce( getLastElementOr, null ) ) ) }
                                                defaultSorted={ defaultSorted }
                                                pagination={ paginationFactory( paginationOptions ) }
                                                wrapperClasses="table-responsive"
                                                selectRow={{
                                                    mode: 'checkbox',
                                                    clickToSelect: true,

                                                    onSelect: row => {
                                                        showlog("riga selezionata");
                                                        showlog( row );

                                                        if( !!selected.filter( sel => sel.id === row.id ).reduce( getLastElementOr, null ) ) {
                                                            // deselezione
                                                            setSelected( selected.filter( sel => sel.id !== row.id ) )
                                                        }
                                                        else {
                                                            // selezione
                                                            setSelected([
                                                                ...selected,
                                                                row
                                                            ])
                                                        }

                                                    },

                                                    selectionRenderer: row => {

                                                        // showlog("selectionRenderer");
                                                        // showlog(JSON.stringify(selected));
                                                        // showlog(row);
                                                        // showlog("--------------------------------");
                                                        let currentRow =
                                                            selected
                                                                .filter( as => as.id === row.rowKey )
                                                                .reduce( getLastElementOr, null )
                                                        ;
                                                        row.checked = !!currentRow;
                                                        if( !!allChecked ) row.checked = true;

                                                        return (
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    checked={row.checked}
                                                                    disabled={row.disabled}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                    }}/>
                                                            </div>
                                                        );
                                                    },

                                                    selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                                        // showlog("selectionHeaderRenderer");
                                                        // showlog(rest);
                                                        // showlog(...rest);
                                                        return (
                                                            <div className={""}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={ "selectAllCompanies" }
                                                                    className=""
                                                                    onClick={clickEvent => {

                                                                        let checkbox = clickEvent.target;
                                                                        let isOn = !!checkbox.checked;
                                                                        toggleAll( isOn );
                                                                        if( !!checkbox.checked )    setSelected( companies );
                                                                        else                        setSelected([]);

                                                                    }}
                                                                    disabled={ false }
                                                                    checked={ allChecked }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                }}


                                            />

                                        )
                                        : (
                                            (
                                                !error
                                                    ? (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !companies }
                                                            nodataMessage={ "Nessuna Azienda"}
                                                            spinnerColor={"primary"}
                                                            className={"middle-height"}
                                                        />
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ false }
                                                            nodataMessage={ error }
                                                            spinnerColor={"primary"}
                                                            className={"middle-height"}
                                                        />
                                                    )
                                            )

                                        )
                                }


                            </React.Fragment>

                        )
                    }

                </ToolkitProvider>
            </ModalBody>
        </Modal>
    );

    // retrieve companies list
    if( !isNotEmptyArray(companies) ) {
        loadCompaniesData()

            // adjust    -------------------------------------------------------------------------------------------
            .then( list => {
                return (
                    list
                        .map( company => {
                            company.dimensioni = company.dimension.name;
                            return company;
                        })
                )
            })


            // field sort   -------------------------------------------------------------------------------------------
            .then( responseData => {

                return (
                    responseData
                        .map( row => {

                            let sortedRow = {};
                            Object.keys(fieldMap)
                                .map( field => {
                                    sortedRow[field] = row[field];
                                })
                            ;
                            Object.assign(sortedRow, row);
                            return sortedRow;

                        })
                );
            })

            // row sort -----------------------------------------------------------------------------------------------
            .then( responseData => {
                return responseData.sort( sortById );
            })

            // finish   -----------------------------------------------------------------------------------------------
            .then( data => setCompanies( data ) )

            .catch( e => {

                setError( extractErrorMessage( e ) );
                props.onError(e)
            })
        ;
    }

    return content;
}
