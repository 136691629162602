/**
 * Organizzation CRUD - BL
 */

import {
    eraseCompanyOrganizzations,
    eraseOrganizzations,
    getOrganizzations,
    newOrganizzation,
    editOrganizzation
} from "../services/organizzations";


export const loadOrganizzations = () => {
    return getOrganizzations();
};

// export const createOrganizzations = () => {
//     return newOrganizzation();
// };

// export const modifyOrganizzations = () => {
//     return editOrganizzations();
// };

export const deleteOrganizzations = oid => {
    return eraseOrganizzations( oid );
};

export const deleteCompanyOrganizzations = oid => {
    return eraseCompanyOrganizzations( oid );
};


export const saveOrganizzation = (oid, oData) => {
    let data = {
        name: oData.name
    };
    if( !oid ) return newOrganizzation( data );
    else       return editOrganizzation( oid, data );
}

