import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ActionButton from "./save-button";
import {errorlog, extractErrorMessage, renderlog, showlog} from "../utils";
import SimpleMDE from "react-simplemde-editor";
import {WaitingOrNodataMessage} from "./waiting-or-nodata-message";
import '../style/sr-tool-edit-page.css';
import {LANGUAGES} from "../conf/languages";

import {COLOR_POLICY} from "../conf/color-policy";
import {translate} from "./i18n/translate-function";
import {
    getTranslationForSRToolCompilationRequirementsNode,
    getTranslationForSRToolNode,
    getTranslationForSRToolNodeGL,
    getTranslationForSRToolRaccomandationNode,
    saveSrtoolCompilationRequirementsNode,
    saveSrtoolNode,
    saveSrtoolNodeGL,
    saveSrtoolRaccomandationNode
} from "../business-logic/srtool";

const NODE_TYPES = [
    translate("tab"),
    translate("chapter"),
    translate("informative"),
    translate("requirement"),
    translate("sub-requirement"),
    translate("sub-sub-requirement")
];

const SrtoolEditNodesModal = props => {

    const [inWait, wait] = useState( true );
    const [error, setError] = useState(() => []);
    const [nodeLanguages, setNodeLanguages] =
        useState( () => {

            // showlog("controllo node");
            // showlog( props.node );
            // debugger;

            return (
                LANGUAGES
                    // .filter( (e, i) => i === 0 )
                    .map( lan => {
                        let newLangObject = lan;
                        if(!!props.node.name) {
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = props.node.name;
                        }
                        if(!!props.node.description) {
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = props.node.description;
                        }
                        return newLangObject;
                    })
            );

        })
    ;



    useEffect(() => {
        showlog("ComponentDidMount equivalent");


        if( !!props.isGL ) {
            getTranslationForSRToolNodeGL( props.node.path.length -1, props.node.id )
                .then( result => {
                    setNodeLanguages( result );
                    wait( false );
                })
                .catch(e => {
                    errorlog( "errore e", e );
                    let em = extractErrorMessage( e );
                    setError([...error, em]);
                    wait( false );
                })
        }
        else {

            if( !!props.isRaccomandation ) {
                getTranslationForSRToolRaccomandationNode( props.node.path.length -1, props.node.id )
                    .then( result => {
                        setNodeLanguages( result );
                        wait( false );
                    })
                    .catch(e => {
                        errorlog( "errore e", e );
                        let em = extractErrorMessage( e );
                        setError([...error, em]);
                        wait( false );
                    })
            }
            else {

                if( !!props.isCompilationRequirements ) {
                    getTranslationForSRToolCompilationRequirementsNode( props.node.path.length -1, props.node.id )
                        .then( result => {
                            setNodeLanguages( result );
                            wait( false );
                        })
                        .catch(e => {
                            errorlog( "errore e", e );
                            let em = extractErrorMessage( e );
                            setError([...error, em]);
                            wait( false );
                        })
                }
                else {
                    getTranslationForSRToolNode( props.node.path.length -1, props.node.id )
                        .then( result => {
                            setNodeLanguages( result );
                            wait( false );
                        })
                        .catch(e => {
                            errorlog( "errore e", e );
                            let em = extractErrorMessage( e );
                            setError([...error, em]);
                            wait( false );
                        })
                }

            }

        }

        return () => {
            // showlog("ComponentDidMount equivalent RETURN");
            return null;
        };
    }, []);


    let content = (
        <Modal isOpen={ true } toggle={ () => props.onClose() }>
            <ModalHeader className={"question-edit-modal-header-container"}>
                <span>
                    { `${ NODE_TYPES[props.node.path.length - 1] } - ${ props.node.id }` }
                </span>
                <div
                    className={""}
                >
                    <ActionButton
                        color={"link"}
                        buttonText={"annulla"}
                        promise={ () => {
                            return Promise.resolve();
                        }}
                        onResult={  toggleEvent => props.onClose() }
                    />
                    {
                        (!error || (error.length === 0))
                            ? (
                                <ActionButton
                                    promise={ () => {
                                        wait( true );
                                        if( !!props.isGL ) {
                                            return saveSrtoolNodeGL( props.node.path.length -1, props.node.id, nodeLanguages);
                                        }
                                        if( !!props.isRaccomandation ) {
                                            return saveSrtoolRaccomandationNode( props.node.path.length -1, props.node.id, nodeLanguages);
                                        }
                                        if( !!props.isCompilationRequirements ) {
                                            return saveSrtoolCompilationRequirementsNode( props.node.path.length -1, props.node.id, nodeLanguages);
                                        }
                                        return saveSrtoolNode( props.node.path.length -1, props.node.id, nodeLanguages);
                                    }}
                                    onResult={ result => {
                                        wait( false );
                                        props.onClose( nodeLanguages )
                                    }}
                                    onError={ err => {
                                        errorlog("Salvataggio edit domanda", err)
                                        let em = extractErrorMessage( err );
                                        setError([...error, em]);
                                        wait( false );
                                    }}
                                    className={'srtool-edit-modal-button'}
                                />
                            )
                            : null
                    }

                </div>
            </ModalHeader>
            <ModalBody>
                {
                    ((!error || (error.length === 0)) && !!nodeLanguages)
                        ? (
                            nodeLanguages
                                .map( (lang, lanIndex) => {

                                    return (
                                        <div className={"lan-block"} key={ lanIndex }>
                                            <div className={"lan-header"}>
                                                <div className={"lan-flag-container"}>
                                                    <img
                                                        src={ lang.flag }
                                                        alt={ lang.label }
                                                    />
                                                </div>
                                                <div className={"lan-name"}>{ lang.label }</div>
                                            </div>
                                            <div className={"lan-editor-container"}>
                                                <SimpleMDE
                                                    value={ lang[lang.code.substr(0, 2).toLowerCase()] }
                                                    className={"lan-editor"}
                                                    onChange={ newText => {

                                                        nodeLanguages
                                                            .forEach( fullDataLan => {
                                                                if( Object.keys(fullDataLan).includes( lang.code.substr(0, 2).toLowerCase() ) ) {
                                                                    fullDataLan[ lang.code.substr(0, 2).toLowerCase() ] = newText;
                                                                }
                                                            })
                                                        ;
                                                        // setNodeLanguages( nodeLanguages );   // evitiamo il re-render

                                                    }}
                                                    options={{
                                                        toolbar: [],
                                                        autofocus: true,
                                                        spellChecker: false
                                                    }}

                                                />
                                            </div>
                                        </div>
                                    );

                                })
                        )
                        : (
                            error
                                .map( e => (
                                    <code className={"error-message-label"}>{ e }</code>
                                ))
                        )


                }
                {
                    !!inWait
                        ? (
                            <WaitingOrNodataMessage
                                waiting={ true }
                                waitMessage={" "}
                                className={"single-circle-waiting edit-question-in-wait"}
                            />
                        )
                        : null
                }
            </ModalBody>
        </Modal>
    );

    renderlog('SrtoolEditNodesModal', {inWait, error, nodeLanguages}, props, COLOR_POLICY.select);
    return content;

}

export default SrtoolEditNodesModal;
