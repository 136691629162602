import {cloneObject} from "../utils/object-utils";
import {DATE_FORMAT_IT} from "../env";
import {errorlog, showlog} from "../utils/dev-utils";
import {sha256} from "js-sha256";
import * as TREE_STRUCTURE from "../tree-structure";
import * as CHAPTER_FLAGS from "../tree-structure-chapters-flags";
import * as INFORMATIVES_FLAGS from "../tree-structure-informatives-flags";
import {getLastElementOr, isNotEmptyArray, onlyNotNull, sortById} from "../utils/array-utils";
import moment from "moment";


/**
 * A partire da una lista di oggetti json viene creata una struttura dati
 * ad albero
 *
 *
 * Formato Albero
 * data
 *  ├── tab
 *  │     │
 *  │     ├── chapter
 *  │     │     │
 *  │     │     ├── informativa
 *  │     │     │     │
 *  │     │     │     ├── requisito
 *  │     │     │     │     │
 *  │     │     │     │     ├── sottorequisito
 *  │     │     │     │     │    │
 *  │     │     │     │     │    └── sottosottorequisito
 *  │     │     │     │     │
 *  │     │     │     │     └── sottorequisito
 *  │     │     │     │
 *  │     │     │     └── requisito
 *  │     │     │
 *  │     │     └── informativa
 *  │     │           │
 *  │     │           └── requisito
 *  │     │
 *  │     └── chapter
 *  │           │
 *  │           └── informativa
 *  │
 *  └── tab
 *
 *
 *  data
 *   ├── tab
 *   │     │
 *   │     ├── chapter
 *   │     │     │
 *   │     │     ├── informativa
 *   │     │     │     │
 *   │     │     │     ├── requisito
 *   │     │     │     │     │
 *   │     │     │     │     ├── sottorequisito
 *   │     │     │     │     │    │
 *   │     │     │     │     │    └── sottosottorequisito
 *   │     │     │     │     │
 *   │     │     │     │     └── sottorequisito
 *   │     │     │     │
 *   │     │     │     └── requisito
 *   │     │     │
 *   │     │     └── informativa
 *   │     │           │
 *   │     │           └── requisito
 *   │     │
 *   │     └── chapter
 *   │           │
 *   │           └── informativa
 *   │
 *   └── tab
 *
 *
 *  // Esempio ------------------------------------------------------------------
 *  data
 *   └── ECONOMICO
 *         │
 *         └── Economic performance
 *               │
 *               ├── Informativa 201-1
 *               │     │
 *               │     ├── Se i dati vengono presentati secondo il criterio ...
 *               │     │     │
 *               │     │     ├── ii. valore economico distribuito:
 *               │     │     │    │
 *               │     │     │    └── vestimenti nella comunità
 *               │     │     │
 *               │     │     ├── sottorequisito
 *               │     │     │
 *
 *  			 ...
 *
 *               │
 *               ├── Informativa 201-2
 *               │
 *               └── Informativa 203-1
 *
 *
 * @param list - lista di oggetti
 * @return {Promise<{tabs: Array} | never>} - oggetto relativa alla struttura dati ad albero
 */
export const convertTableToTree = list => {



	let tree = { tabs: [] };
	return (
		// start
		new Promise((success, fail) => {
			success( list );
		})

		// tabs --------------------------------------------------------------------------------------------------------
		.then( list => {

			let tabs =
				[
					...new Map(
						list
							.filter( row => row.griChapterId === 0 || !row.griChapterId )
							.map( item => [
								item.griTabId,
								{
									id: item.griTabId,
									// name: {it: item.griTabName_it, en: item.griTabName_en },
									name: item.griTabName,
									chapters:[],
									userManager: item.userManager,
									userManagerName: item.userManagerName || "NessunNome-"+ item.userManager,
									structureId: item.id || 0,
									path: [ item.id ],
									applicability: item.applicability,
									material: item.material,
									timing: item.timingDefault
								}
							])
					).values()
				]
				.sort( sortById )
			;


			tree.tabs = tabs;
			tree.tabs.forEach( t => t.open = true );    // sempre tutti aperti // non dovrebbe funzionare


			// ridondante
			tree.tabs =
				tree.tabs
					.sort( sortById )
			;


			return tabs;

		})


		// chapters ----------------------------------------------------------------------------------------------------
		.then( tabs => {

			let chapters =
				[
					... new Map(
						list
							.filter( row => row.griChapterId !== 0 && !!row.griChapterId )
							.filter( row => row.griInformativeId === 0 || !row.griInformativeId )
							.map( item => [
								item.griChapterId,
								{
									id: item.griChapterId,
									// name: {it: item.griChapterName_it, en: item.griChapterName_en},
									name: item.griChapterName,
									infos: [],
									timing: item.timingDefault
								}
							])
					).values()
				]
			;


			chapters
				.map( ch => {

					let tabReferiment =

						list

							// elemento della lista corrispondente al capitolo corrente
							.filter( li => li.griChapterId === ch.id && li.griInformativeId === 0 )
							.reduce( getLastElementOr, null )

							// riferimento al padre
							.griTabId

					;

					/*
					let referiment =

						list

							// elemento della lista corrispondente al informativa corrente
							.filter( li => li.griChapterId === ch.id )//[0]
							.filter( row => row.griInformativeId === 0 || !row.griInformativeId )
							.filter( onlyFirst )
							.reduce( getLastElementOr, null )
					;
					*/

					let referiment =
						list
							.filter( li => li.griChapterId === ch.id && li.griInformativeId === 0 )
							.reduce( getLastElementOr, null )
					;



					ch.open = false;

					ch.structureId = list.filter( li => li.griChapterId === ch.id )[0].id || 0;

					ch.material =
						list
							.filter( li => li.griChapterId === ch.id )[0]
							.material
					;

					tree.tabs.filter( t => t.id === tabReferiment )[0].chapters.push( ch );



					// showlog("referiment");
					// showlog(referiment);
					// debugger;

					Object.assign(
						ch,
						{
							applicability: referiment.applicability,
							userManager: referiment.userManager || 0,
							userManagerName: referiment.userManagerName || "NessunManager",
							path: [ tree.tabs.filter( t => t.id === tabReferiment )[0].path[0], ch.structureId ],
							is103Completed: !!referiment.is103Completed
						}
					);



					// chapters sorting
					tree.tabs
						.map( t => {
							t.chapters =
								t.chapters
									.sort( sortById )
							;
						})
					;

				})

			;


			return chapters;

		})

		// infos -------------------------------------------------------------------------------------------------------
		.then( x => {

			let infos =
				[
					... new Map(
						list
							.filter( item => item.griInformativeRequrimentId === 0 )
							.map( item => [
								item.griInformativeId,
								{
									id: item.griInformativeId,
									// name: {
									// 	it: item.griInformativeName,
									// 	en: item.griInformativeName,
									// 	translationIdIt: item.traslation_id || 0
									// },
									name: item.griInformativeName,
									requirements: [],
									applicability: item.applicability,
									timing: item.timingDefault
								}
							])
					).values()
				]
				.filter( i => !!i.id && i.id !== "0")
				.sort( sortById )
			;



			infos
				.forEach( info => {



					let referiment =

						list

							// elemento della lista corrispondente al informativa corrente
							.filter( li => li.griInformativeId === info.id && li.griInformativeRequrimentId === 0 )
							.reduce( getLastElementOr, null )
					;




					let chapterReferiment = referiment.griChapterId;

					Object.assign(
						info,
						{
							open: false,
							applicability: referiment.applicability,
							material: referiment.material,
							userManager: referiment.userManager || 0,
							userManagerName: referiment.userManagerName || "NessunNome-"+ info.userManager,
							structureId: referiment.id || 0,
							subRequirementsRepeating: referiment.subRequirementsRepeating || 0,
							// reccomandation: {
							// 	it: referiment.griReportingRecommendationName_it,
							// 	en: referiment.griReportingRecommendationName_en
							// },
							// guideLines: {
							// 	it: referiment.griInformativeGuidelineName_it,
							// 	en: referiment.griInformativeGuidelineName_en
							// },
							// description: {
							// 	it: referiment.griInformativeDescription_it,
							// 	en: referiment.griInformativeDescription_en
							// },
							// additionalRequirement: {
							// 	it: referiment.griAdditionalRequirementName_it || "", // "nel mezzo del cammin di nostra vita #####-A mi ritrovai per una selva scura che la dritta via era smarrita. Ahi quant'onesta pare la donna mia quand'ella altrui saluta",
							// 	en: referiment.griAdditionalRequirementName_en || ""
							// },
							reccomandation: referiment.griReportingRecommendationName,
							guideLines: referiment.griInformativeGuidelineName,
							description: referiment.griInformativeDescription,
							additionalRequirement: referiment.griAdditionalRequirementName,
							core: referiment.core,
							attachmentsNumber: referiment.attachmentsNumber,
							status: referiment.griInformativeStatus,
							hasNote: referiment.griInformativeIsNote,
							hasAdminNote: referiment.hasInternalNote,
							nonCompliantText: referiment.griInformativeComment
						}
					);

					tree.tabs
						.forEach( tab => {
							tab.chapters
								.forEach( chapter => {
									if( chapter.id === chapterReferiment ) {
										info.path = [ ...chapter.path, referiment.id ];
										chapter.infos.push( info );
									}
								})
						})


				})
			;



			return infos;


		})

		// req ---------------------------------------------------------------------------------------------------------
		.then( x => {

			let requirements =
				[
					... new Map(
						list
							.filter( item => item.griInformativeRequrimentSub1Id === 0 )
							.map( item => [
								item.griInformativeRequrimentId,
								{
									id: item.griInformativeRequrimentId,
									// name: {
									// 	it: item.griInformativeRequrimentName_it,
									// 	en: item.griInformativeRequrimentName_en,
									// 	translationIdIt: item.traslation_id || 0
									// },
									name: item.griInformativeRequrimentName,
									subReqs: [],
									griInformativeRequrimentSub1Id: item.griInformativeRequrimentSub1Id
								}
							])
					).values()
				]
				.filter( req => !!req.id && req.id !== "0")
			;

			requirements
				.forEach( (req, reqIndex) => {

					let referiment =

						list

							// elemento della lista corrispondente al informativa corrente
							.filter( li => li.griInformativeRequrimentId === req.id && li.griInformativeRequrimentSub1Id === 0)
							.reduce( getLastElementOr, null )
					;



					let infoReferiment = referiment.griInformativeId;



					// showlog("controllo requisiti" );
					// if(!referiment.timingDefault) {
					// 	showlog( referiment );
					// }


					Object.assign(
						req,
						{
							open: false,
							applicability: referiment.applicability,
							material: referiment.material,
							userManager: referiment.userManager || 0,
							userManagerName: referiment.userManagerName || "NessunNome-"+ req.userManager,

							measureUnitList: referiment.unitMeasurement.griUnitMeasurementsList || [referiment.unitMeasurement],
							measureUnit: referiment.unitMeasurement,
							measureUnitIsString: !(referiment.unitMeasurement.isValueString === false),
							isString: !(referiment.unitMeasurement.isValueString === false),

							timing: referiment.timingDefault,
							structureId: referiment.id || 0,
							subRequirementsRepeating: referiment.subRequirementsRepeating || 0,
							evaluate: !!referiment.evaluate || false,
							griRepeatingValueId: referiment.griRepeatingValueId || null,
							guideLines: referiment.griRequirementGuidelineName,
							compilationRequirements: referiment.griRequirementCompilationsRequirementName,
							// additionalRequirement: {
							// 	it: referiment.griAdditionalRequirementName_it || "",
							// 	en: referiment.griAdditionalRequirementName_en || ""
							// },

							additionalRequirement: referiment.griAdditionalRequirementName,

							value:
								[
									...(new Map(
										list
											.filter( li => li.griInformativeRequrimentId === req.id && !!!li.griInformativeRequrimentSub1Id && !!!li.griInformativeRequrimentSub2Id )
											.map( li =>
												(!!referiment.unitMeasurement.isValueString)
													? ({value: li.valueString, id: li.monthNumber})
													: ({value: li.valueDecimal, id: li.monthNumber})
											)
											// .filter( li => li.value !== 0 )
											.map( item => [ item.id, item ] )
									).values())
								]
								.sort( sortById )
								.map( val => val.value )
							,
							isValueActive:
								list
									.filter( li => li.griInformativeRequrimentId === req.id && !!!li.griInformativeRequrimentSub1Id && !!!li.griInformativeRequrimentSub2Id )
									.reverse()
									.map( val => val.isValueActive )
									.reduce( getLastElementOr, false )
							,

							dateGoal: referiment.dateGoal,
							dateStartGoal: referiment.dateStartGoal,
							valueGoal: referiment.valueGoal,
							valueStartGoal: referiment.valueStartGoal,
							descriptionGoal: referiment.descriptionGoal,
							lastUpdate:
								!!referiment.dateUpdate
									? moment( referiment.dateUpdate ).format( DATE_FORMAT_IT )
									: "nessuna"
						}
					);


					if( !!referiment.subRequirementsRepeating ) {
						// showlog("check ripetizione subRequirementsRepeating");
						// showlog(req);
						// showlog(referiment);
					}


					if( !!referiment.griRepeatingValueId ) {
						// showlog("check ripetizione");
						// showlog(req);
						// showlog(referiment);
					}

					if( infoReferiment.structureId === 51291 ) {
						// showlog( infoReferiment );
						// showlog( referiment );
						// debugger;
					}


					tree.tabs
						.forEach( tab => {
							tab.chapters
								.forEach( chapter => {
									chapter.infos
										.forEach( info => {
											if( info.id === infoReferiment ) {
												req.path = [ ...info.path, referiment.id ];
												info.requirements.push( req );
											}
										})
								})
						})


				})
			;


			return requirements;


		})

		// subReq ------------------------------------------------------------------------------------------------------
		.then( x => {

			let subReqs =
				[
					... new Map(
						list
							.filter( item => item.griInformativeRequrimentSub2Id === 0 )
							.map( item => [
								item.griInformativeRequrimentSub1Id,
								{
									id: item.griInformativeRequrimentSub1Id,
									// name: {
									// 	it: item.griInformativeRequrimentSub1Name_it,
									// 	en: item.griInformativeRequrimentSub1Name_en,
									// 	translationIdIt: item.traslation_id || 0
									// },
									name: item.griInformativeRequrimentSub1Name,
									subSubReqs: []
								}
							])
					).values()
				]
				.filter( subreq => !!subreq.id && subreq.id !== 0 && subreq.id !== "0")
			;


			subReqs
				.forEach( subReq => {

					let referiment =

						list

							// elemento della lista corrispondente al informativa corrente
							.filter( li => li.griInformativeRequrimentSub1Id === subReq.id  && li.griInformativeRequrimentSub2Id === 0)
							.reduce( getLastElementOr, null )
					;

					let reqReferiment = referiment.griInformativeRequrimentId;
					Object.assign(
						subReq,
						{
							open: false,
							applicability: referiment.applicability,
							material: referiment.material,
							userManager: referiment.userManager || 0,
							userManagerName: referiment.userManagerName || "NessunNome-"+ subReq.userManager,

							measureUnitList: referiment.unitMeasurement.griUnitMeasurementsList || [referiment.unitMeasurement],
							measureUnit: referiment.unitMeasurement,
							measureUnitIsString: !(referiment.unitMeasurement.isValueString === false),
							isString: !(referiment.unitMeasurement.isValueString === false),

							timing: referiment.timingDefault,
							structureId: referiment.id || 0,
							subRequirementsRepeating: referiment.subRequirementsRepeating || 0,
							evaluate: !!referiment.evaluate || false,
							griRepeatingValueId: referiment.griRepeatingValueId || null,
							compilationRequirements: referiment.griRequirementSubCompilationsRequirementName,
							// additionalRequirement: {
							// 	it: referiment.griAdditionalRequirementName_it || "",
							// 	en: referiment.griAdditionalRequirementName_en || ""
							// },
							additionalRequirement: referiment.griAdditionalRequirementName,

							value:
								[
									...(new Map(
										list
											.filter( li => li.griInformativeRequrimentSub1Id === subReq.id )
											// .filter( li => li.griInformativeRequrimentSub2Id === 0 && li.griInformativeRequrimentSub2Id === "0" )
											.map( li => (!!referiment.unitMeasurement.isValueString) ? ({value: li.valueString, id: li.monthNumber}) : ({value: li.valueDecimal, id: li.monthNumber}))
											// .filter( li => li.value !== 0 )
											.map( item => [ item.id, item ])
									).values())
								]
								.sort( sortById )
								.map( val => val.value )
							,
							isValueActive:
								list
									.filter( li => li.griInformativeRequrimentSub1Id === subReq.id )
									.reverse()
									.map( val => val.isValueActive )
									.reduce( getLastElementOr, false )
							,
							test: "trallalerotrallalla",

							dateGoal: referiment.dateGoal,
							dateStartGoal: referiment.dateStartGoal,
							valueGoal: referiment.valueGoal,
							valueStartGoal: referiment.valueStartGoal,
							descriptionGoal: referiment.descriptionGoal,
							lastUpdate:
								!!referiment.dateUpdate
									? moment( referiment.dateUpdate ).format( DATE_FORMAT_IT )
									: "nessuna"
						}
					);


					tree.tabs
						.forEach( tab => {
							tab.chapters
								.forEach( chapter => {
									chapter.infos
										.forEach( info => {
											info.requirements
												.forEach( req => {
													if( req.id === reqReferiment ) {
														subReq.path = [ ...req.path, referiment.id ];
														req.subReqs.push( subReq );
													}
												})
										})
								})
						})


					showlog(`check ${subReq.structureId}`);
					/*
					showlog(
						[
							...(new Map(
								list
									.filter( li => li.griInformativeRequrimentSub1Id === subReq.id )
									.map( item => [ item.id, item ])
							).values())
						]
					);
					showlog(
						[
							...(new Map(
								list
									.filter( li => li.griInformativeRequrimentSub1Id === subReq.id )
									.map( item => [ item.id, item ])
							).values())
						]
						.reverse()
						.map( val => val.isValueActive )
					);
					*/
					showlog(
						list
							.filter( li => li.griInformativeRequrimentSub1Id === subReq.id )
							.reverse()
							.map( val => val.isValueActive )
							.reduce( getLastElementOr, false )
					);
					// --------------------------------------------------------------------------------


				})
			;


			return subReqs;


		})

		// subSubReq ---------------------------------------------------------------------------------------------------
		.then( x => {

			let subSubReqs =
				[
					... new Map(
						list
							.map( item => [
								item.griInformativeRequrimentSub2Id,
								{
									id: item.griInformativeRequrimentSub2Id,
									// name: {
									// 	it: item.griInformativeRequrimentSub2Name_it,
									// 	en: item.griInformativeRequrimentSub2Name_en,
									// 	translationIdIt: item.traslation_id || 0
									// }
									name: item.griInformativeRequrimentSub2Name
								}
							])
					).values()
				]
				.filter( subsubreq => !!subsubreq.id && subsubreq.id !== 0 && subsubreq.id !== "0")
			;


			subSubReqs
				.forEach( (subSubReq, subSubReqIndex) => {

					let referiment =

						list

							// elemento della lista corrispondente al informativa corrente
							.filter( li => li.griInformativeRequrimentSub2Id === subSubReq.id )
							.reduce( getLastElementOr, null )
					;

					let subReqReferiment = referiment.griInformativeRequrimentSub1Id;
					Object.assign(
						subSubReq,
						{
							open: false,
							applicability: referiment.applicability,
							material: referiment.material,
							userManager: referiment.userManager || 0,
							userManagerName: referiment.userManagerName || "NessunNome-"+ subSubReq.userManager,

							measureUnitList: referiment.unitMeasurement.griUnitMeasurementsList || [referiment.unitMeasurement],
							measureUnit: referiment.unitMeasurement,
							measureUnitIsString: !(referiment.unitMeasurement.isValueString === false),
							isString: !(referiment.unitMeasurement.isValueString === false),

							timing: referiment.timingDefault,
							structureId: referiment.id || 0,
							subRequirementsRepeating: referiment.subRequirementsRepeating || 0,
							evaluate: !!referiment.evaluate || false,
							griRepeatingValueId: referiment.griRepeatingValueId || null,
							compilationRequirements: referiment.griRequirementSub2CompilationsRequirementName,
							// additionalRequirement: {
							// 	it: referiment.griAdditionalRequirementName_it || "",
							// 	en: referiment.griAdditionalRequirementName_en || ""
							// },
							additionalRequirement: referiment.griAdditionalRequirementName,


							value:
								[
									...(new Map(
										list
											.filter( li => li.griInformativeRequrimentSub2Id === subSubReq.id )
											// .filter( li => li.griInformativeRequrimentSub2Id === 0 && li.griInformativeRequrimentSub2Id === "0" )
											.map( li => (!!referiment.unitMeasurement.isValueString) ? ({value: li.valueString, id: li.monthNumber}) : ({value: li.valueDecimal, id: li.monthNumber}))
											// .filter( li => li.value !== 0 )
											.map( item => [ item.id, item ])
									).values())
								]
								.sort( sortById )
								.map( val => val.value )
							,
							isValueActive:
								list
									.filter( li => li.griInformativeRequrimentSub2Id === subSubReq.id )
									.reverse()
									.map( val => val.isValueActive )
									.reduce( getLastElementOr, false )
							,



							// value: (
							// 	(!!referiment.timingDefault)
							// 		? (
							// 			list
							// 				.filter( li => li.griInformativeRequrimentSub2Id === subSubReq.id )
							// 				.map( li => (!!referiment.unitMeasurement.isValueString) ? ({value: li.valueString, id: li.monthNumber}) : ({value: li.valueDecimal, id: li.monthNumber}))
							// 				.sort( (x, y) => {
							// 					if( x.monthNumber < y.monthNumber ) return 1;
							// 					return -1
							// 				})
							// 				.map( val => val.value )
							// 				.filter( (_,i) => i < 12 )
							// 		)
							// 		: (!!referiment.unitMeasurement.isValueString) ? [referiment.valueString] : [referiment.valueDecimal]
							// ),

							dateGoal: referiment.dateGoal,
							dateStartGoal: referiment.dateStartGoal,
							valueGoal: referiment.valueGoal,
							valueStartGoal: referiment.valueStartGoal,
							descriptionGoal: referiment.descriptionGoal,
							lastUpdate:
								!!referiment.dateUpdate
									? moment( referiment.dateUpdate ).format( DATE_FORMAT_IT )
									: "nessuna"
						}
					);





					if( subSubReq.structureId === 49182) {
						showlog("sotto-sotto-req");
						showlog(referiment);
						// debugger;
					}



					tree.tabs
						.forEach( tab => {
							tab.chapters
								.forEach( chapter => {
									chapter.infos
										.forEach( info => {
											info.requirements
												.forEach( req => {
													req.subReqs
														.forEach( sReq => {
															if( sReq.id === subReqReferiment ) {
																subSubReq.path = [ ...sReq.path, referiment.id ];
																sReq.subSubReqs.push( subSubReq );
															}
														})
												})
										})
								})
						})


				})
			;



			return subSubReqs;


		})


		// methods
		.then( () => {
			tree.getNodeByPath = path => {
				return getTreeNodeByPath( tree, path );
			};
			return tree;
		})


		// end
		.then( ssReqs => {

			return tree;
		})

	);


};




/**
 * Crea una struttura dati a d albero ma con un livello aggiuntivo ( ghost level ) che serve per
 * gestire i nodi ripetuti ( virtual nodes )
 *
 *
 * @param tree - struttura dati ad albero
 * @param seed - tabella ( lista di oggetti ) da cui viene generato l'albero
 * @return {any} - struttura dati ad albero
 */
export const convertToVirtualTree = ( tree, seed ) => {

	// taglio al livello dei requisiti  -----------------------------------------------------------------------------------------------
	// let treeTmp = JSON.parse( JSON.stringify( tree ) );
	let treeTmp = cloneObject( tree )

	// let cutedTree =
	treeTmp.tabs
		// .sort( sortById )
		.map( t => {
			t.chapters = (
				t.chapters
					.map(ch => {
						ch.infos = (
							ch.infos
								.map( i => {
									i.requirements = (
										i.requirements
											.map( req => {


												// showlog("requisito [tab "+ t.id +"|"+ ch.id +"|"+ i.id +"]", !!req.subRequirementsRepeating, req);


												// if( !!req.subRequirementsRepeating ) req.subReqs = [];   // spostato dopo
												return req;
											})
									);
									return i;
								})
						);
						return ch;
					})
			);
			return t;
		})
	;
	let cutedTree = treeTmp;

	// treeLog( cutedTree );

	// debugger;
	// nuovo livello delle zone -------------------------------------------------------------------------------------------------------
	cutedTree.tabs
		// .sort( sortById )
		.map( t => {
			return (
				t.chapters
					.map(ch => {
						return (
							ch.infos
								.map( i => {


									i.requirements = (
										i.requirements

											// .filter( req => !!req.evaluate )		// filtro tolto
											.map( req => {


												if( !!req.subRequirementsRepeating ) {

													showlog(`requisito ${req.id}|${req.structureId} ha ripetizione`);
													showlog( req );

													// if( req.id === 288 ) {
													// 	debugger;
													// }


													let allRawZones =
														seed
															.filter( s => s.griRepeatingValueId !== 0 )
															.filter( s => s.id === req.structureId )
															.filter( s => !!s.griRepeatingValueName )
													;

													// tutte le "aree" che ripetono i sotto-requisiti
													req.zones = [
														... new Map(
															allRawZones
																.map( item => [ item.griRepeatingValueId, ({id:item.griRepeatingValueId, name:item.griRepeatingValueName, child:[]}) ])
														).values()
													];

													if( !!req.zones && req.zones.length > 0 ) showlog("Ripetizioni", req.zones);

													// riempimento dei nodi sotto-requisiti nelle varie aree del requisito
													seed
														.map( s => {

															if( !!req.zones && (req.zones.length > 0) && !!s.griSubrequirementId) {

																let childIdToFind = s.griSubrequirementId;

																let currentZone =
																	req.zones
																		.filter( z => z.id === s.griRepeatingValueId )
																		.reduce( getLastElementOr, null)
																;
																if( !!currentZone ) {

																	// showlog("accoppiamento di ", currentZone);
																	// showlog(s.griSubrequirementId, req.zones, req.subReqs);

																	currentZone
																		.child
																		.push(
																			req.subReqs
																				.filter( subReq => subReq.id === childIdToFind )
																				.filter( subReq => !!subReq.evaluate )
																				.reduce( getLastElementOr, null )  // (#01)
																		)
																	;
																}
															}

														})
													;


													if( isNotEmptyArray( req.zones ) ) {
														// debugger;
														req.zones =
															req.zones
																.map( z => {

																	/*
																	 * Scommentare questa riga fa in modo che non si abbia una
																	 * blank-page in caso di errori sui dati del DB nelle ripetizioni
																	 * ma la lasciamo commentata per poter accorgerci se c'è un errore
																	 * in modo da non uscire pazzi e cadere malati
																	 *
																	 * PS
																	 * Bisogna cercare soluzioni, non colpevoli
																	 */
																	// z.child = z.child.filter( onlyNotNull ); // relativo a (#01)

																	if( !!isNotEmptyArray( z.child ) && z.child.every( c => !!c ) ) {
																		z.child =
																			z.child
																				.sort(sortById)
																		;
																	}
																	return z; // .filter( onlyNotNull );
																})
														;
													}






													// distinct
													seed
														.map( s => {

															if( !!req.zones && req.zones.length > 0 ) {

																let childIdToFind = s.griSubrequirementId;
																let currentZone =
																	req.zones
																		.filter( z => z.id === s.griRepeatingValueId )
																		.reduce( getLastElementOr, null)
																;
																if( !!currentZone ) {

																	// if( currentZone.id === 5424 ) debugger;

																	currentZone.child =
																		[
																			... new Map(
																				currentZone.child
																					.map( item => [ item.id, item ])
																			).values()
																		];

																	;
																}
															}

														})
													;



													req.subReqs = [];

													// --------------------------------------------------------------------------------------------------------------------------------------------


												}

												return req;

											})
									);
									return i;
								})
						);
					})
			);
		})
	;




	return cutedTree;
};



/**
 * Converte una lista di oggetti ( equivalente ad una tabella ) in un contenuto di un file csv
 * il file ha i campi mappati
 *
 * @param list - lista di oggetti, equivalente di una tabella
 * @return {Promise<string | never>} - ritorna il contenuto di un file CSV
 */
export const exportSeedTableToBusinessCSV = list => {
	return (
		new Promise((success, fail) => {
			// success(exportSeedTableToCSV( list ));

				let data =
					list

						// entità eliminate
						.filter( row => !row.griRepeatingValueName )

						.map( row => {
							return ({
								"MacroAree": row.griTabName || "",
								Capitoli: row.griChapterName || "",
								Informative: row.griInformativeName || "",
								"Descrizione Informativa": row.griInformativeDescription || "",
								Requisiti: row.griInformativeRequrimentName || "",
								"Sottorequisiti": row.griInformativeRequrimentSub1Name || "",
								"Sotto-sottorequisiti": row.griInformativeRequrimentSub2Name || "",
								Raccomandazioni: row.griReportingRecommendationName || "",
								"Linee guida informativa": row.griInformativeGuidelineName || "",
								"Linee guida requisito": row.griRequirementGuidelineName || "",
								"Linee guida sotto requisito": row.griRequirementSubGuidelineName || "",
								"Linee guida sotto-sotto requisito": row.griRequirementSub2GuidelineName || "",
								"Manager": row.userManagerName || "",
								"Valori obiettivo": row.valueGoal || "",
								"Date obiettivo": moment(row.dateGoal).format( DATE_FORMAT_IT ) || "",
								"Valori iniziale obiettivo": row.valueStartGoal || "",
								"Date iniziale obiettivo": moment( row.dateStartGoal ).format( DATE_FORMAT_IT ) || "",
								"Unità di misura": (
									!!row.unitMeasurement
										? (
											!!row.unitMeasurement.name
												// : (JSON.stringify(row.unitMeasurement) || "")
												? (
													((row.unitMeasurement.name === "description") || (row.unitMeasurement.name === "numero"))
														? ""
														: row.unitMeasurement.name
												)
												: ""
										)
										: ""
								),
								Valori: (!row.isValueString ? row.valueDecimal : row.valueString ),
								// "Entità": row.griRepeatingValueName || "",
								fingerprint: sha256(
									// row.griTabName_it +
									// row.griChapterName_it +
									row.griTabName +
									row.griChapterName +
									row.griInformativeName +
								    row.griInformativeDescription +
									// row.griInformativeRequrimentName_it +
									row.griInformativeRequrimentName +
									// row.griInformativeRequrimentSub1Name_it +
									// row.griInformativeRequrimentSub2Name_it +
									row.griInformativeRequrimentSub1Name +
									row.griInformativeRequrimentSub2Name +
									row.griReportingRecommendationName +
									row.griInformativeGuidelineName +
									row.griRequirementGuidelineName +
									row.griRequirementSubGuidelineName +
									row.griRequirementSub2GuidelineName +
									row.userManagerName +
									row.valueGoal +
									row.dateGoal +
									row.valueStartGoal +
									row.dateStartGoal +
									row.unitMeasurement +
									(!row.isValueString ? row.valueDecimal : row.valueString ) +
									row.griRepeatingValueName
								)
							});
						})
				;

				success(data);



		})
			.then( data => {

				return (
					[
						... new Map(
							data.map( item => [ item.fingerprint, item ])
						).values()
					]
						.filter( i => !!i )
				);

			})

			.then( data => {
				// showlog("prima di trasformare la lista in csv");
				// showlog( data );
				return (
					data
						.map( row => {
							delete row.fingerprint;
							return row;
						})
				);
			})

			.then( data => {



				return ( exportSeedTableToCSV( data ) );
			})
	);



}


const SHORT_CSV_FILTER = [
	"id"
	, "griChapterId"
	, "griInformativeId"
	, "griInformativeRequrimentId"
	, "griInformativeRequrimentSub1Id"
	, "griInformativeRequrimentSub2Id"
	, "timingDefault"
	, "evaluate"
	, "valueType"
	, "valueDecimal"
	, "valueString"
	, "subRequirementsRepeating"
	, "griRepeatingValueId"
	, "griRepeatingValueName"
	, "monthNumber"
	, "griSubrequirementId"
];


/**
 * Converte una lista di oggetti ( equivalente ad una tabella ) in un contenuto di un file csv
 *
 * @param list - lista di oggetti, equivalente di una tabella
 * @return string - ritorna il contenuto di un file CSV
 */
export const exportSeedTableToCSV = (list, isShort) => {

	if( !isNotEmptyArray( list ) ) return "";

	// let SEPARATOR = "|";
	let SEPARATOR = ";";
	let toRet = "#" + SEPARATOR;
	let fields =
		Object.keys( list[0] )
			.filter( field => {
				if( !!isShort ) {
					return ( SHORT_CSV_FILTER.includes( field ) );
				}
				return true;
			})
			// .map( field => {
			// 	showlog( field );
			// 	return field;
			// })
	;
	toRet += fields.join( SEPARATOR );
	toRet += "\n";


	list.forEach( (row, index) => {
		toRet += (index +1) + SEPARATOR;
		fields
			.filter( field => {
				if( !!isShort ) {
					return ( SHORT_CSV_FILTER.includes( field ) );
				}
				return true;
			})
			.forEach( field => {
				if( typeof row[field] === "number" ) toRet += row[field];
				else {
					if( typeof row[field] === "undefined" ) toRet += " ";
					else {
						if( !!!row[field] ) toRet += " ";
						else {
							toRet +=
								row[field]
									.toString()
									.trim()
									.split("\n")        .join(" ")
									.split("\r")        .join(" ")
									.split( SEPARATOR ) .join(" ")
									.split("'")         .join(" ")
									.split("\"")        .join(" ")
									.split(";")         .join(" ")
									.replace(/[\x00-\x20]/g, " ")
									.replace(/[\x81-\xFF]/g, " ")
									// .substr(0, 64)
							;
						}
					}
				}
				toRet += SEPARATOR;
			})
		;
		toRet += "\n";
	})

	return toRet;

}

let treeLogStr = "";
let deep = 0;
let openNodes = 0;
const scanTreeNode = node => {

	if( !!node ) {



		if (!!node.open) openNodes++;

		Object.keys(node)
			.forEach(nk => {
				if (node[nk] instanceof Array && nk !== "measureUnitList" && nk !== "value" && nk !== "path") {
					deep++;
					node[nk]
						.filter( onlyNotNull )
						.forEach((ni, i) => {
							if (i === node[nk].length -1) treeLogStr += "\n" + (Array(deep).fill("│\t\t").join("")) + "└───";
							else treeLogStr += "\n" + (Array(deep).fill("│\t\t").join("")) + "├───";
							let nodeName =
								(!!ni)
									? (
										!!ni.name
											? (
												/*
												!!ni.name.it
													? ni.name.it
													: ni.name
												*/
												ni.name
											)
											: ""+ ni.toString()
									)
									: "node-no-name"
							;

							if(!!ni && !!ni.open) treeLogStr += "%c";

							treeLogStr += "["+ ni.id +"."+ ni.structureId +"]";

							if(!!ni && !!ni.open) treeLogStr += "%c";

							treeLogStr += " "+ ((typeof nodeName === "string") ? nodeName.trim().substr(0, 24 ) : "");

							if(!!ni && !!ni.open) treeLogStr += " %c";



							if( !ni.structureId ) {
								// AREAs or ZONEs
								// treeLogStr += " "+ ni.name;
								// treeLogStr += " "+ JSON.stringify(Object.keys(ni));
							}
							else {

								if( !!ni.seedId ) {

									// virtual nodes
									treeLogStr += " [ "+ ni.griSubrequirementId +" ]";
									treeLogStr += " A("+ ni.griRepeatingValueName +")";
									treeLogStr += " "+ ni.griSubrequirementIt.text.substr(0, 10);
									treeLogStr += " "+ ( (typeof ni.timing === "undefined") ? "NO-TIME" : (!!ni.timing ? " mensile" : "annuale"));

								}
								else {

									treeLogStr += " "+ (!!ni.material ? "[M]" : "[ ]");
									treeLogStr += "-"+ (!!ni.applicability ? "[A]" : "[ ]");
									treeLogStr += " "+ (!!ni.timing ? " mensile" : "annuale");
									// treeLogStr += " U["+ ni.measureUnit +" "+ ( (!!ni.measureUnitList && ni.measureUnitList.length === 1 && !isMeasureUnitNumeric(ni.measureUnitList[0]?.name)) ? "abc" : "123" ) +"]";
									if( !!ni.measureUnit ) treeLogStr += " U["+ ni.measureUnit.name +" "+ ( ni.isValueString ? "abc" : "123" ) +"]";
									treeLogStr += " m"+ ni.userManager;
									treeLogStr += " "+ (!!ni.evaluate ? " * " : "[_]");
									treeLogStr += " "+ (!!ni.subRequirementsRepeating ? "(+)" : "(-)");
									// valori mesi

								}
							}


							if(!!ni && !!ni.open) treeLogStr += " %c";

							scanTreeNode(ni);
						});
					deep--;
				}
			})
		;

	}



	return treeLogStr;

}

/**
 * scrive il log della rappresentazione dell'albero
 *
 *
 *	data
 *	 └── ECONOMICO
 *	       │
 *	       └── Economic performance
 *	             │
 *	             ├── Informativa 201-1
 *	             │     │
 *	             │     ├── Se i dati vengono presentati secondo il criterio ...
 *	             │     │     │
 *	             │     │     ├── ii. valore economico distribuito:
 *	             │     │     │    │
 *	             │     │     │    └── vestimenti nella comunità
 *	             │     │     │
 *	             │     │     ├── sottorequisito
 *	             │     │     │
 *
 *				 ...
 *
 *	             │
 *	             ├── Informativa 201-2
 *	             │
 *	             └── Informativa 203-1
 *
 *
 *
 * @param tree
 */
export const treeLog = tree => {

	treeLogStr = "";
	deep = 1;
	openNodes = 0;
	scanTreeNode( tree );


	let csss = [];
	Array(openNodes)
		.fill("background: #ff0; padding: 2px; padding-right: 2px; border-radius: 2px 0px 0px 2px; color:#222;")
		.forEach( t => {
			csss.push(t);
			csss.push("background: #ff08; padding: 2px; padding-right: 2px; color:#222;");
			csss.push("background: #ff03; padding: 2px; padding-right: 2px; border-radius: 0px 2px 2px 0px; color:#222;");
			csss.push("");
		})
	;



}


/**
 * Da la lista di tutte le informative dell'albero
 *
 * @param tree - struttura dati ad albero ( oggetto JSON )
 * @return {Array} - lista di tutti i nodi del livello delle informative
 */
export const getAllTreeInfo = tree => {

	let allInfos = [];
	tree.tabs
		.forEach( t => {
			t.chapters
				.forEach(ch => {
					ch.infos
						.forEach( i => allInfos.push( i ))
					;
				})
			;
		})
	;
	return allInfos;

};




const openNode = node => {

	// showlog("open node", node);
	if( !!node ) {
		if( typeof node.open !== "undefined" || node.open !== undefined ) node.open = true;
		if( node instanceof Array || node instanceof Object ) {
			Object.keys(node)
				.forEach(nk => {
					// showlog("\t\t", nk, node[nk]);
					if( !!isNotEmptyArray( node[nk] ) ) {
						node[nk]
							.forEach(nn => {
								openNode( nn );
							})
					}
					else {
						if( !!node[nk] ) openNode( node[nk] );
					}
				})
			;
		}

	}


}

const closeNode = node => {
	// showlog(typeof node, node instanceof Array, node instanceof Object, node);
	if( !!node ) {
		if( typeof node.open !== "undefined" || node.open !== undefined ) node.open = false;
		if( node instanceof Array || node instanceof Object ) {
			Object.keys(node)
				.forEach(nk => {
					if( !!isNotEmptyArray( node[nk] ) ) {
						node[nk]
							.forEach(nn => {
								closeNode( nn );
							})
					}
					else {
						if( !!node[nk] ) closeNode( node[nk] );
					}
				})
			;
		}
	}


}

export const openAllNodes = originalTree => {
	return (
		new Promise((success, fail) => {
			let tree = cloneObject( originalTree );
			openNode( tree );
			success( tree );
		})
		.then( tree => tree )
		.catch(e => {
			errorlog("Apertura nodi sotto-albero");
			showlog(e);
		})
	);
	// return tree;
};

export const closeAllNodes = originalTree => {
	return (
		new Promise((succes, fail) => {
			let tree = cloneObject( originalTree );
			closeNode( tree );
			succes(tree);
		})
		.then( tree => tree )
		.catch(e => {
			errorlog("Chiusura nodi sotto-albero");
			showlog(e);
		})
	);
};




const getOpenNodesList = tree => {
	return [];
}


const getOpenNodesSubTree = tree => {
	return {};
}


const saveOpenNodesSubTree = subTree => {}

const loadOpenNodesSubTree = () => {}


/**
 * Dall'albero sorgente viene presa l'informazione <code>open</code>
 * e riportata ad un albero clonato dall'albero di destinazione
 *
 * @param {object} sTree - struttura dati ad albero sorgente
 * @param {object} dTree - struttura dati ad albero destinazione
 * @return {object} - struttura dati ad albero
 */
export const cloneOpenNodes = (sTree, dTree) => {

	let srcTree = JSON.parse( JSON.stringify( sTree ) );
	let dstTree = JSON.parse( JSON.stringify( dTree ) );

	srcTree
		.tabs.forEach( sTab => {

			// TAB
			dstTree
				.tabs
				.filter( dTab => dTab.id === sTab.id )
				.map( dTab => {
					dTab.open = sTab.open;
				})
			;

			// CHAPTER
			sTab.chapters
				.forEach( sCh => {
					dstTree
						.tabs
						.map( dTab => {
							dTab.chapters
								.filter( dCh => dCh.id === sCh.id )
								.map( dCh => dCh.open = sCh.open )
						})
					;
				})
			;


			// INFO
			sTab.chapters
				.forEach( sCh => {
					sCh.infos
						.forEach( sInf => {
							dstTree.tabs
								.map( dTab => {
									dTab.chapters
										.map( dCh => {
											dCh.infos
												.filter( dInf => dInf.id === sInf.id )
												.map( dInf => {
													dInf.open = sInf.open;
												})
										})
								})
							;
						})
				})
			;

			// REQ
			sTab.chapters
				.forEach( sCh => {
					sCh.infos
						.forEach( sInf => {
							sInf.requirements
								.map( sReq => {
									dstTree.tabs
										.map( dTab => {
											dTab.chapters
												.map( dCh => {
													dCh.infos
														.map( dInf => {
															dInf.requirements
																.filter( dReq => dReq.id === sReq.id )
																.map( dReq => {
																	dReq.open = sReq.open;
																})
														})
												})
										})
									;
								})
						})
				})
			;


			// SUBREQ
			sTab.chapters
				.forEach( sCh => {
					sCh.infos
						.forEach( sInf => {
							sInf.requirements
								.map( sReq => {
									sReq.subReqs
										.map( sSub => {
											dstTree.tabs
												.map( dTab => {
													dTab.chapters
														.map( dCh => {
															dCh.infos
																.map( dInf => {
																	dInf.requirements
																		.map( dReq => {
																			dReq.subReqs
																				.filter( dSub => dSub.id === sSub.id )
																				.map( dSub => {
																					dSub.open = sSub.open;
																				})
																		})
																})
														})
												})
											;
										})
								})
						})
				})
			;


			// SUBSUBREQ
			sTab.chapters
				.forEach( sCh => {
					sCh.infos
						.forEach( sInf => {
							sInf.requirements
								.map( sReq => {
									sReq.subReqs
										.map( sSub => {
											sSub.subSubReqs
												.map( sSubSub => {
													dstTree.tabs
														.map( dTab => {
															dTab.chapters
																.map( dCh => {
																	dCh.infos
																		.map( dInf => {
																			dInf.requirements
																				.map( dReq => {
																					dReq.subReqs
																						.map( dSub => {
																							dSub.subSubReqs
																								.filter( dSubSub => dSubSub.id === sSubSub.id)
																								.map( dSubSub => {
																									dSubSub.open = sSubSub.open;
																								})
																						})
																				})
																		})
																})
														})
													;
												})
										})
								})
						})
				})
			;

	})


	return dstTree;


}


/**
 * Da un nodo in base al suo percorso. Il nodo è una parte dell'albero, non è una copia senza referenza
 * Nel caso di nodi virtuali la parte del path relativo al sotto-requisito viene preso il nodo dalla
 * prima ripetizione
 *
 *
 * @param treeRef - struttura dati ad albero
 * @param path - lista di id che formano il percorso
 * @return {Object} - nodo
 */
export const getTreeNodeByPath = (treeRef, path) => {
	let node = null;
	let children = null;
	path
		.forEach( (id, level) => {
			if( level == 0 ) children = treeRef.tabs;
			if( level == 1 ) children = node.chapters;
			if( level == 2 ) children = node.infos;
			if( level == 3 ) children = node.requirements;
			if( level == 4 ) {
				if( !isNotEmptyArray(node.subReqs) ) {
					// ghost level ------------------------------------------------------
					if( !!isNotEmptyArray(node.zones) ) {
						debugger;
						children = node.zones[0].child;
					}
				}
				else {
					// normal level ------------------------------------------------------
					children = node.subReqs;
				}
			}
			if( level == 5 ) children = node.subSubReqs;
			node = children.filter( n => n.structureId === id || n.id === id ).reduce( getLastElementOr, null);
		})
	;
	return node;
}




export const openSubTreeFromPath = (srcTree, path) => {
	return openSubTree( getTreeNodeByPath( srcTree, path ) );
}

export const openSubTree = node => {
	return openAllNodes( node );
}




/**
 * Se il nodo passato ha un unità di misura "description" ha sicuramente
 * il flag isString impostato a true, quindi non ha un obiettivo
 *
 * @param node
 * @returns {boolean}
 */
export const hasGoal = node => !node.isString;



/**
 * controllo se l'informativa ha il flag dell'obiettivo acceso
 * @param infoName - nome dell'informativa
 * @return {boolean}
 */
export const hasInformativeGoal = infoName => {
	if(
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length === 0
	) return true;

	return (
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.filter( inf => inf.flags & INFORMATIVES_FLAGS.goal )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length > 0
	);
};


/**
 * controllo se l'informativa ha il flag dell'applicabilita acceso
 * @param infoName - nome dell'informativa
 * @return {boolean}
 */
export const isInformativeApplicability = infoName => {

	/*
	showlog("controllo applicabilita informativa "+ infoName);
	showlog((
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length === 0
	));
	*/

	if(
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length === 0
	) return true;


	return (
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.filter( inf => inf.flags & INFORMATIVES_FLAGS.applicability )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length > 0
	)};


/**
 * controllo se l'informativa ha il flag del timing acceso
 * @param infoName - nome dell'informativa
 * @return {boolean}
 */
export const canInformativeChangeTiming = (infoName, debug) => {


	if(
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length === 0
	) return true;



	return (
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.map( ch => {
				return (
					ch.INFORMATIVES
						.filter( inf => inf.name === infoName )
						.filter( inf => inf.flags & INFORMATIVES_FLAGS.yearly )
						.reduce( getLastElementOr, null)
				)
			})
			.filter( onlyNotNull )
			.length === 0
)};

/**
 * controllo se il capitolo ha il flag dell'applicabilita acceso
 * @param infoName - nome dell'informativa
 * @return {boolean}
 */
export const isChapterApplicability = chapterID => {
	if(
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.filter( ch => ch.id === chapterID )
			.length === 0
	) return true;

	return (
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.filter( ch => ch.id === chapterID )
			.filter( ch => ch.flags & CHAPTER_FLAGS.applicability )
			.reduce( getLastElementOr, null)
	);
};


export const isSubTreeYearly = node => {
	if( node instanceof Object ) {


		let allMonthly =
			(
				node.timing !== undefined
				&& typeof node.timing !== "undefined"
			)
				? !!node.timing
				: true
		;


		// per tutte le proprietà che sono array
		Object.keys(node)
			.forEach( nk => {
				if( !!isNotEmptyArray( node[nk] ) ) {

					allMonthly &= (

						node[nk]

							// material
							.map( child => isSubTreeYearly( child ) )

							// .map( (childResult, childResultIndex) => {
							// 	if( !childResult ) showlog("\t\t----> child", childResult, node[nk][childResultIndex]);
							// 	return !!childResult;
							// })

							// tutti true o tutti false
							.map( isMon => !!(allMonthly & isMon))

							.reduce( (isMonthly, isCurrentMonthlyl) => !!(isCurrentMonthlyl & isMonthly), true )

					)

				}
			})
		return allMonthly;
	}
	else return true;
}

export const isNodeAndSubTree_material = node => {
	if( node instanceof Object ) {

		let allMaterial =
			(
				node.material !== undefined
				&& typeof node.material !== "undefined"
			)
				? !!node.material
				: true
		;

		// per tutte le proprietà che sono array
		Object.keys(node)
			.forEach( nk => {
				if( !!isNotEmptyArray( node[nk] ) ) {

					allMaterial &= (

						node[nk]

							// material
							.map( child => isNodeAndSubTree_material( child ) )

							// .map( childResult => {
							// 	showlog("child", childResult);
							// 	return childResult;
							// })

							// tutti true o tutti false
							.map( isMat => (allMaterial & isMat))

							.reduce( (isMaterial, isCurrentMaterial) => (isCurrentMaterial & isMaterial), true )

					)

				}
			})
		return allMaterial;
	}
	else return null;
}



/**
 * controllo se il capitolo ha il flag della materialita acceso
 * @param infoName - nome dell'informativa
 * @return {boolean}
 */
export const isChapterMaterial = chapterID => {

	return (

		TREE_STRUCTURE
			.TABS
			.map( tab => (
				tab.CHAPTERS
					.filter( ch => ch.id === chapterID )
					.filter( ch => ch.flags & CHAPTER_FLAGS.material )
					.reduce( getLastElementOr, null)
			))
			.filter( onlyNotNull )
			.reduce( getLastElementOr, null)

		/*
		TREE_STRUCTURE.TABS[0].CHAPTERS
			.filter( ch => ch.id === chapterID )
			.filter( ch => ch.flags & CHAPTER_FLAGS.material )
			.reduce( getLastElementOr, null)
		*/
	)
};







