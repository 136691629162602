import React, {useState} from "react";
import {translate} from "../../../components/i18n/translate-function";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {loadAssociatedSectorQuestion, loadAssociatedSectors} from "../../../business-logic/question-sectors";
import {renderlog} from "../../../utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";

const fieldMap = {
    sector_id: "Id",
    isMain: "Principale/Secondario",
    sector_name: "Settore"
};


const hiddenFields = [
];


const sortableFields = [
    "sector_id",
    "isMain",
    "sector_name"
];

const defaultSorted = [
    {
    dataField: 'sector_id',
    order: 'asc',
}
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const AssociatedSectorModal = props => {

    renderlog("ASSOCIATED SECTOR MODAL")

    const [sectorList, setSectorList] = useState();
    const [errors, setErrors] = useState();

    let content = (
        <div className={"white-backpan blur-backpan"}>
            <WaitingOrNodataMessage
                waiting={ true }
                // nodataMessage={}
                waitMessage={ translate("Loading_") }
                spinnerColor={ "primary" /*"#35f"*/ }
                fullHeight={ true }
                // className={}
                // spinnerClass={}
            />
        </div>
    )

    if( !sectorList ) {
        loadAssociatedSectors( props.evaluation.id )
            .then(list => {

                list = list.map((row, index) => {

                    if( row.isMain === true ) {
                        row.isMain = "Principale"
                    }else{
                        row.isMain = "Secondario"
                    }
                    return row
                })
                return list

            })
            .then(list => {
                setSectorList( list );
            })
            .catch( e => {
                setSectorList([]);
                setErrors([translate("Error while getting sector questions list")])
            })
    }
    else {

        if(!!errors){

            content =  (

                <React.Fragment>
                    <ModalHeader  toggle={ toggleEvent => { props.onClose() } } >
                        Errore ottenimento settori associati alla valutazione " {props.evaluation.name} "
                    </ModalHeader>
                    <ModalBody >
                        {errors}
                    </ModalBody>
                </React.Fragment>

            )

        }else if( sectorList.length === 0 ) {

            content =  (

                <ModalHeader  toggle={ toggleEvent => { props.onClose() } } >
                    Non sono presenti settori associati alla valutazione " {props.evaluation.name} "
                </ModalHeader>

            )


        }else{

            content = (

                <React.Fragment>
                    <ModalHeader toggle={ toggleEvent => { props.onClose() }} >
                        Settori associati alla valutazione " {props.evaluation.name} "
                    </ModalHeader>
                    <ModalBody className={"body"} style={{maxHeight: "837px", overflowY: "auto"}}>

                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            search
                        >
                            {props => (
                                <React.Fragment>

                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <SearchBar {...props.searchProps} />*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}

                                    <BootstrapTable
                                        //id={"id"}
                                        bootstrap4
                                        striped
                                        {...props.baseProps}
                                        size="sm"
                                        responsive
                                        keyField="id"
                                        bordered={ false }
                                        data={ sectorList }
                                        columns={ formatColumns( Object.keys( sectorList[0] ) ) }
                                        defaultSorted={ defaultSorted }
                                        //pagination={ paginationFactory(paginationOptions) }
                                        wrapperClasses="table-responsive"

                                    />


                                </React.Fragment>
                            )}
                        </ToolkitProvider>

                    </ModalBody>
                </React.Fragment>

            )
        }


        content = (
            <Modal
                isOpen={ true }
                toggle={ toggleEvent => { props.onClose() }}
                //className={"large-modal"}
            >
                { content }
            </Modal>
        );

    }

    return content;

}


export default AssociatedSectorModal;
