/**
 * Organizzation CRUD - Service
 */

import {
    company_organizzation_DELETE,
    organizzation_DELETE
    , organizzation_GET
    , organizzation_POST
    , organizzation_PUT
} from "./request-manager";

export const getOrganizzations = () => {
    return organizzation_GET();
};

export const editOrganizzation = (oid, oData) => {
    return organizzation_PUT(oid, oData);
};

export const newOrganizzation = oData => {
    return organizzation_POST( oData );
};

export const eraseOrganizzations = oid => {
    return organizzation_DELETE( oid );
};

export const eraseCompanyOrganizzations = oid => {
    return company_organizzation_DELETE( oid );
};
