import React, {Component} from "react";
import {COLOR_POLICY} from "../../../env";
import {
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import "../modal.css";
import {errorlog, renderlog} from "../../../utils";
import ActionButton from "../../save-button";
import classnames from "classnames";
import {saveOrganizzation} from "../../../business-logic/organizzations";


export default class OrganizzationDetailsModal extends Component {


    constructor( props ) {
        super( props );


        this.state = {
            activeTab: "anagraphics-tab",
            errors: {}
        }

    }



    render() {

        renderlog("OrganizzationModal", this.state, this.props, COLOR_POLICY.validated);


        let context = (
            <AvForm>



                <Nav tabs>

                    <NavItem key={0}>
                        <NavLink
                            id={"anagraphics-tab"}
                            href="#"
                            className={classnames({ active: this.state.activeTab === "anagraphics-tab" })}
                            onClick={() => {

                                this.setState({
                                    ...this.state,
                                    activeTab: "anagraphics-tab"
                                });
                            }}>
                            <i
                                className={classnames(
                                    'd-lg-none',
                                    'd-block',
                                    'mr-1'
                                )} />
                            <span className="d-none d-lg-block">Anagrafica</span>
                        </NavLink>
                    </NavItem>

                    {/* <NavItem key={1}>
						<NavLink
							id={"address-tab"}
							href="#"
							className={classnames({ active: this.state.activeTab === "address-tab" })}
							onClick={() => {

								this.setState({
									...this.state,
									activeTab: "address-tab"
								});
							}}>
							<i
								className={classnames(
									'd-lg-none',
									'd-block',
									'mr-1'
								)} />
							<span className="d-none d-lg-block">Indirizzo</span>
						</NavLink>
					</NavItem> */}

                </Nav>


                <TabContent activeTab={this.state.activeTab}>


                    <TabPane
                        tabId={"anagraphics-tab"}
                        key={0}
                        className={"custom-tab-pane"}
                    >

                        {/*     NOME  -------------------------------------------------------------------------------- */}
                        <AvGroup>
                            <Label for="name">Ragione Sociale</Label>
                            <AvInput
                                type="text"
                                name="name"
                                id="name"
                                value={ this.state.name || this.props.details.name }
                                onChange={ changeTextEvent => {
                                    this.setState({
                                        ...this.state,
                                        name: changeTextEvent.target.value
                                    })
                                }}
                                required
                            />
                            {
                                ( !!this.state.errors && !!this.state.errors.name )
                                    ? <label className="error-message-label">{ this.state.errors.name }</label>
                                    : null
                            }
                        </AvGroup>

                        {/*<GotoTopComponent/>*/}

                    </TabPane>


                    <TabPane
                        tabId={"address-tab"}
                        key={1}
                        className={"custom-tab-pane"}
                    >
                        {/*     INDIRIZZO  -------------------------------------------------------------------------------- */}
                        <AvGroup>
                            <Label for="name">Indirizzo</Label>
                            <AvInput
                                type="text"
                                name="address"
                                id="address"
                                value={ this.state.address }
                                onChange={ changeTextEvent => {
                                    this.setState({
                                        ...this.state,
                                        address: changeTextEvent.target.value
                                    })
                                }}
                                required
                            />
                            {
                                ( !!this.state.errors && !!this.state.errors.address )
                                    ? <label className="error-message-label">{ this.state.errors.address }</label>
                                    : null
                            }
                        </AvGroup>

                        {/*<GotoTopComponent/>*/}

                    </TabPane>


                </TabContent>

            </AvForm>
        )





        let content = (
            <Modal isOpen={ true } toggle={ () => this.props.onClose() } >
                <ModalHeader toggle={ () => this.props.onClose() } >
                    {
                        ( !!this.props.details && typeof this.props.details === "object" && JSON.stringify( this.props.details ) !== "{}" )
                            ? "Modifica Organizzazione"
                            : "Nuova Organizzazione"
                    }
                </ModalHeader>
                <ModalBody>
                    { context }
                </ModalBody>
                <ModalFooter>

                    <ActionButton
                        color={"link"}
                        buttonText={"annulla"}
                        promise={ () => {
                            return Promise.resolve();
                        }}
                        onResult={  toggleEvent => this.props.onClose() }
                    />

                    <ActionButton
                        promise={ () => {
                            return saveOrganizzation( this.props.details.id, this.state );
                        }}
                        onResult={ result => {
                            this.props.onClose( true );
                        }}
                        onError={ e => {
                            errorlog("errore al salvataggio di una organizzazione", e);
                            this.props.onClose()
                        }}
                    />

                </ModalFooter>
            </Modal>


        );


        return content;

    }


    componentDidMount() {


    }



};
