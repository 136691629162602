import {GRI_ADMIN_TREE_DEEP_LEVELS_NAME, TREE_REF, TREE_STATUS_PATH} from "../env";
import {
	confTreeMeasureUnitPost,
	evaluationStatus_GET,
	getAllGriMaterialThematicInformatives_Get,
	GriInformative103_Get,
	GriInformative103_Update,
	GriInformative103_UpdateChapter,
	GriMaterialThematicInformatives_Add,
	GriMaterialThematicInformatives_Delete,
	GriMaterialThematicInformatives_Edit,
	GriMaterialThematicInformatives_griTimings_Get,
	GriMaterialThematics_Create,
	GriMaterialThematics_Delete,
	GriMaterialThematics_Update,
	GriRepeatingValues_Delete,
	GriRepeatingValues_Update,
	GriStructure_Confirm,
	GriStructure_EditApplicability,
	GriStructure_EditInformative,
	GriStructure_EditManager,
	GriStructure_EditMaterial,
	GriStructure_EditRequirementGoal,
	GriStructure_EditRequirementTiming,
	GriStructure_editRequirementUnitMeasurement,
	GriStructure_Get,
	GriStructure_GetValues,
	GriTimings_Create,
	GriTimings_CreateBy,
	GriTimings_Delete,
	GriTimings_Get,
	GriValues_GetData,
	GriValues_GetPrevious,
	GriValues_GetRepeatingValues,
	GriValues_Set,
	GriValues_SetRepeatingValues,
	GriValues_SetZone,
	InformativeNote_GET,
	InformativeNote_SAVE,
	measureUnitListGet,
	SRToolCollectPMAuthorization,
	SRToolInformativeAttachments
} from "./request-manager";
import {showlog} from "../utils/dev-utils";


export const updateTreeStatusPath = ( treeId, node, id ) => {
	let deepLevel =
		GRI_ADMIN_TREE_DEEP_LEVELS_NAME
			.map( (nodeName, index) => {
				if( nodeName === node ) return index;
				return -1;
			})
			.map( ind => {
				showlog(ind);
				return ind;
			})
			.filter( n => n > -1 )
			.map( ind => {
				showlog("\t"+ ind);
				return ind;
			})
	;
	if( deepLevel.length === 0 ) deepLevel = -1;
	else deepLevel = deepLevel[0];

	let treeStatusPath_stored = "W10=";
	if( !!localStorage.getItem(TREE_STATUS_PATH) ) treeStatusPath_stored = localStorage.getItem(TREE_STATUS_PATH);

	// showlog("treeStatusPath_stored");
	// showlog(treeStatusPath_stored);
	// showlog("deepLevel");
	// showlog(node, deepLevel);

	let treeStatusPath = JSON.parse( atob( treeStatusPath_stored ) ) || [null];
	if( treeStatusPath.length === deepLevel ) treeStatusPath.push( id );
	else {
		if( treeStatusPath.length > deepLevel ) {
			treeStatusPath[ deepLevel ] = id;
			while( treeStatusPath.length > deepLevel +1 ) treeStatusPath.pop();
		}
		else {
			// ATTENZIONE inconsistenza di dati!!!
			console.error("ATTENZIONE", "Inconsistenza di dati nello storage dello status dell'albero");
			showlog(treeStatusPath_stored);
			showlog(atob( treeStatusPath_stored ));
			showlog(JSON.parse( atob( treeStatusPath_stored ) ));
			localStorage.removeItem(TREE_STATUS_PATH);
			treeStatusPath = [id];
		}
	}
	localStorage.setItem(TREE_STATUS_PATH, btoa( JSON.stringify( treeStatusPath.filter(tsp => !!tsp) ) ));
	localStorage.setItem(TREE_REF, btoa( treeId ));

	showlog("TreeStatusPath updated");
	showlog(treeStatusPath.filter(tsp => !!tsp));
};

export const loadTreeStatusPath = () => {
	let treeStatusPath_stored = "W10=";
	if( !!localStorage.getItem(TREE_STATUS_PATH) ) treeStatusPath_stored = localStorage.getItem(TREE_STATUS_PATH);
	return JSON.parse( atob( treeStatusPath_stored ) ) || null;
};

export const getTreeRef = () => {
	let rawData = localStorage.getItem(TREE_REF) || "MA==";
	return JSON.parse( atob( rawData ) ) || null;
}

export const getCollects = (id, isAlive) => {
	return GriTimings_Get( id, isAlive );
}

export const setNewCollect = data => {
	// test propagazione errore
	// return Promise.reject("errore simulato");
	 return GriTimings_Create( data );
}


export const setNewCollectCopiedFrom = (data, copyCollectFromCollectId, valuesIncluded) => {
	return GriTimings_CreateBy( data, copyCollectFromCollectId, (!!valuesIncluded ? 1 : 0) );
}


export const eraseCollect = id => {
	return GriTimings_Delete( id );
}

export const getGriStructure = (id, tabId, tmId, isAll) => {
	return GriStructure_Get( id, tabId, tmId, !!isAll );
}

export const getGriStructureValues = (id, tabId, tmId, isAll) => {
	return GriStructure_GetValues( id, tabId, tmId, !!isAll );
}

export const saveGriGoal = (data, cid, level, rid) => {

	showlog("SERVICE - SaveGRIGoal");
	showlog(data);

	return GriStructure_EditRequirementGoal(data, cid, level, rid);
}


export const setGriInfo = ( data, timingId, infoId ) => {
	showlog("setGriInfo");
	showlog( data );
	return GriStructure_EditInformative( data, timingId, infoId );
}

export const setGriChapter = ( applicability, timingId, chId, level ) => {
	let body = {
		Applicability: (!!applicability ? 1 : 0),
		Id: chId
	};
	return GriStructure_EditApplicability( body, timingId, level );
}


export const setManager = ( userManager, timingId, level ) => {
	return GriStructure_EditManager( userManager, timingId, level );
}


export const setChapterMaterial = ( dto, timingId, level ) => {
	return GriStructure_EditMaterial( dto, timingId, level );
};

export const setTiming = ( userManager, timingId, level, reqId ) => {
	// debugger;
	return GriStructure_EditRequirementTiming( userManager, timingId, level, reqId );
}

export const setMeasureUnit = ( userManager, timingId, level, reqId ) => {
	return GriStructure_editRequirementUnitMeasurement( userManager, timingId, level, reqId );
}



export const setInfoHundredThree = ( data, timingId, infoId ) => {
	return GriInformative103_Update( data, timingId, infoId );
}

export const setChapterHundredThree = ( data, timingId, chapterId ) => {
	return GriInformative103_UpdateChapter( data, timingId, chapterId );
}


export const setTreeNodeValue = ( structureId, months ) => {
	return GriValues_Set( months, structureId );
};


export const setVirtualTreeNodeValue = ( fatherGriStructureId, ms, nodeId, areaId ) => {
	return GriValues_SetRepeatingValues( ms, fatherGriStructureId, nodeId, areaId );
};



// subRequirementId, fatherStructureId
export const getVirtualTreeNodeValue = ( subRequirementId, fatherStructureId, areaId ) => {
	return GriValues_GetRepeatingValues( subRequirementId, fatherStructureId, areaId );
};




export const getMonthlyData = ( structureId, repeatingValueId ) => {

	return (
		new Promise((success, fail) => {
			setTimeout(
				() => {
					success( GriValues_GetData( structureId, repeatingValueId ) );
				},
				3
			)
		})
	);

	// return GriValues_GetData( structureId, repeatingValueId );
};

/**
 *
 * @param structureId
 * @returns {Promise<*>}
 */
export const getPreviousMonthlyData = structureId => GriValues_GetPrevious( structureId );
/*
export const getPreviousMonthlyData = ( structureId ) => {
	return (
		new Promise(( onEnd, onFail) => {
			onEnd(
				{
					YearPrevius1: [
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": 100,
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						}
					],
					YearPrevius2: [
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": 100,
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						}
					],
					YearPrevius3: [
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": 100,
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						},
						{
							"valueDecimal": Math.floor( (Math.random() * 100) ),
							"valueString": "",
							"isValueActive": true
						}
					]
				}
			);
		})
	);
};
*/

export const getAllChildValues = (reqId, reqStructureId, zoneId, childList) => {
	return Promise.resolve([]);
	// return GriValues_GetRepeatingValues( fatherGriStructureId, nodeId, GriRepeatingValueId );
}


export const setTreeNodeSingleValue = ( structureId, yearValue ) => {
	return GriValues_Set( yearValue, structureId );
};

/**
 * @deprecated
 * @param data
 * @returns {Promise<*>}
 */
export const saveTreeSet = (data) => {
	return GriStructure_Confirm( data );
};

export const addNewZone = data => {
	return GriValues_SetZone( data );
};

export const updateMaterialThemesTabName = (id, name) => {
	let body = { name: name };
	return GriMaterialThematics_Update( body, id );
}

export const eraseMaterialTheme = id => {
	return GriMaterialThematics_Delete( id );
}

export const getMaterialThemes = (id, isAll) => {
	return GriMaterialThematicInformatives_griTimings_Get( id );
}

export const loadAllMaterialThemesInformatives = (id, isAll) => {
	return getAllGriMaterialThematicInformatives_Get( id );
}


export const createMaterialThemes = (id, name) => {
	return GriMaterialThematics_Create({GriTimingId: id, name: name});
};



export const moveInfoToMaterialThemeFromNotCategorized = data => {
	return GriMaterialThematicInformatives_Add( data );
}

export const moveInfoToMaterialThemeFromOtherMaterialTheme = (ref, data) => {
	return GriMaterialThematicInformatives_Edit( data, ref );
}

export const removeInfoFromMaterialThemes = ref => {
	return GriMaterialThematicInformatives_Delete( ref );
}


export const getHundredValues = (collectId, chId, infoId) => {
	return GriInformative103_Get( collectId, chId, infoId );
};


export const getHundredReasons = () => {
	return (
		Promise.resolve([
			{id: 0, name: "Non Selezionato"},
			{id: 1, name: "Non applicabile"},
			{id: 2, name: "Limiti di riservatezza"},
			{id: 3, name: "Divieti legali specifici"},
			{id: 4, name: "Informazioni non disponibili"}
		])
	);
}


export const deleteRepeatingZone = id => {
	return GriRepeatingValues_Delete( id );
}

export const updateRepeatingZoneName = (id, newName) => {
	return GriRepeatingValues_Update({Name: newName}, id);
}

export const getMeasureUnitList = collectId => {
	return measureUnitListGet( collectId );
}


export const setConfTreeMeasureUnit = (collectId, fid, id) => {
	return confTreeMeasureUnitPost(collectId, fid, id);
}


export const getInformativeNote = (ratingId, qId) => {
	return InformativeNote_GET( ratingId, qId );
}

export const saveInformativeNote = (ratingId, qId, note) => {
	return InformativeNote_SAVE( ratingId, qId, note );
}


export const getSRToolAttachments = (collectId, informativeId) => {
	return SRToolInformativeAttachments(collectId, informativeId);
}



export const setCollectPmAuthorized = (cid, data) => {
	return (
		new Promise((suc, fail) => {
			setTimeout(
				function () {
					suc( SRToolCollectPMAuthorization( cid, data ) );
				},
				5
			);
		})
	)
	// return SRToolCollectPMAuthorization( cid, data );
}




export const loadEvaluationStatus = eid => {
	return evaluationStatus_GET( eid );
}


