import {
	addNewZone,
	createMaterialThemes,
	deleteRepeatingZone,
	eraseCollect,
	eraseMaterialTheme,
	getAllChildValues,
	getCollects,
	getGriStructure,
	getGriStructureValues,
	getHundredReasons,
	getHundredValues,
	getMaterialThemes,
	getMeasureUnitList,
	getMonthlyData,
	getPreviousMonthlyData,
	getSRToolAttachments,
	getVirtualTreeNodeValue,
	loadAllMaterialThemesInformatives,
	moveInfoToMaterialThemeFromNotCategorized,
	moveInfoToMaterialThemeFromOtherMaterialTheme,
	removeInfoFromMaterialThemes,
	saveGriGoal,
	setChapterHundredThree,
	setChapterMaterial,
	setCollectPmAuthorized,
	setConfTreeMeasureUnit,
	setGriChapter,
	setGriInfo,
	setInfoHundredThree,
	setManager,
	setMeasureUnit,
	setNewCollect,
	setNewCollectCopiedFrom,
	setTiming,
	setTreeNodeSingleValue,
	setTreeNodeValue,
	setVirtualTreeNodeValue,
	updateMaterialThemesTabName,
	updateRepeatingZoneName
} from "../services/gri-admin-tree";
import moment from "moment";
import {CALENDAR_MOMENT_DATE_FORMAT_YEARS, COLLECT_STATUS, DATE_FORMAT_IT, NEW_ZONE_REQUIREMENT_TYPE} from "../env";
import {showlog} from "../utils/dev-utils";
import {getLastElementOr} from "../utils/array-utils";
import {setAssurance} from "../services/collect";
import {translate} from "../components/i18n/translate-function";

/**
 * Carica la lista delle raccolte
 * @return {Promise<Array>} - lista di raccolte
 * */
export const loadCollect = (id, isArchived) => {
	let isAlive = (!!isArchived ? 0 : 1);
	if( !!id ) {
		return (
			getCollects( id, isAlive )
				.then( list => {
					return (list.reduce( getLastElementOr, null ));
				})
				.catch( e => {
					return Promise.reject( e );
				})
		);
	}
	else return getCollects( 0, isAlive );
}


export const loadGriStructure = (id, tabId, tmId, isAll) => {
	return getGriStructure( id, tabId || 0, tmId, !!isAll );
}

export const loadGriStructureValue = (id, tabId, tmId, isAll) => {
	return getGriStructureValues( id, tabId || 0, tmId, !!isAll );
}


export const saveNewCollect = (
	startTime
	, year
	, name
	, copyCollectFromCollect
	, companyId
	, isPMAuthorized
	, valuesIncluded
	, isInternalAssurance
	, isDefaultGRI
) => {

	if(!!copyCollectFromCollect) {
		return setNewCollectCopiedFrom({
			dateStart:
				moment(startTime, DATE_FORMAT_IT)
					.format( CALENDAR_MOMENT_DATE_FORMAT_YEARS ),
			name: name,
			companyId: companyId || 0,
			isPMAuthorized: isPMAuthorized,
			year: year,
			version: (!!isDefaultGRI ? 1 : 0)
		}, copyCollectFromCollect.id, valuesIncluded);
	}
	else {
		return setNewCollect({
			dateStart:
				moment(startTime, DATE_FORMAT_IT)
					.format( CALENDAR_MOMENT_DATE_FORMAT_YEARS ),
			name: name,
			companyId: companyId || 0,
			isPMAuthorized: isPMAuthorized,
			year: year,
			version: (!!isDefaultGRI ? 1 : 0)
		});
	}


}







/**
 * Elimina una raccolta
 * @param {Numeric} id - id della raccolta da eliminare
 * @return {Promise<Object>} - esito cancellazione
 */
export const deleteCollect = id => {
	return eraseCollect( id );
}


export const saveGoal = (data, collect, level) => {

	let dataDTO = {
		// Timing:             (data.timing === 1) ? true : false,
		DateGoal:           (!!data.endTime) ? moment(data.endTime, DATE_FORMAT_IT).format( CALENDAR_MOMENT_DATE_FORMAT_YEARS ) : moment("01/01/1970", DATE_FORMAT_IT).format( CALENDAR_MOMENT_DATE_FORMAT_YEARS ),
		ValueGoal:          (!!data.endValue) ? data.endValue : "",
		DateStartGoal:      (!!data.startTime) ? moment(data.startTime, DATE_FORMAT_IT).format( CALENDAR_MOMENT_DATE_FORMAT_YEARS ) : moment("01/01/1970", DATE_FORMAT_IT).format( CALENDAR_MOMENT_DATE_FORMAT_YEARS ),
		ValueStartGoal:     (!!data.startValue) ? data.startValue : "",

		DescriptionGoal: data.note || ""

		// Evaluate: true,
		// UnitMeasurement:    data.griUnitMeasurements    // ATTENZIONE utilizzare measureUnit
		// UnitMeasurement:    (!!data.measureUnit) ? data.measureUnit : data.griUnitMeasurements[0]
		// UnitMeasurement:    (!!data.measureUnit) ? data.measureUnit : data.measureUnitList[0].name
	};

	return saveGriGoal( dataDTO, collect, level, data.id );
}


export const validationGriAdminGoal = dataField => {

	showlog("validationGriAdminGoal");
	showlog(dataField);

	return (
		new Promise((success, fail) => {


			let errors = [];


			// start date
			if( !dataField.startValue ) errors.push({ startValue: "Manca il valore" });
			if( !dataField.endValue ) errors.push({ endValue: "Manca il valore" });

			// validità
			if( moment(dataField.startTime, DATE_FORMAT_IT) >= moment(dataField.endTime, DATE_FORMAT_IT) ) errors.push({ endTime: "Range di date errato" });

			if( errors.length > 0 ) fail( errors );
			else success();

		})


	);
}


export const setInfo = ( data, collectId, infoId ) => {
	data.Materiality =      data.Materiality || 0;
	data.Applicability =    data.Applicability || 0;
	return setGriInfo( data, collectId, infoId );
}

export const setChapterProperties = ( data, collectId, chId, level ) => {
	return setGriChapter( data.Applicability || false, collectId, chId, level );
}



export const saveManager = ( userManager, timingId, level, nodeId ) => {
	let dto = { UserManager: userManager, id: nodeId };

	showlog("BL - SaveManager");

	// debugger;
	return setManager( dto, timingId, level );
}

export const saveChapterMaterial = ( material, timingId, nodeId ) => {
	let dto = { Materiality: material, Id: nodeId };
	// debugger;
	return setChapterMaterial( dto, timingId, 1 );
}


export const saveTabMaterial = ( material, timingId, nodeId ) => {
	// debugger;
	let dto = { Materiality: material, Id: nodeId };
	return setChapterMaterial( dto, timingId, 0 );
}


export const saveTiming = (data, timingId, level, nodeId ) => {
	showlog("save timing");
	showlog(data, timingId, level, nodeId );


	let dto = { Timing: data };
	// debugger;
	return setTiming( dto, timingId, level, nodeId );
}


export const saveMeasureUnit = (data, timingId, level, nodeId ) => {
	showlog("saveMeasureUnit");
	showlog(data, timingId, level, nodeId );


	/*

	arriva
	{
		griUnitMeasurements: "€"
		id: 2
		manager: 0
		name: {it: "a. Se applicabile ", en: "If applicable, indicate "}
		open: false
		subReqs: []
	}


	voluto
	{
		"Timing":true,
		"DateGoal":"2021-01-01",
		"ValueGoal":"2021-01-01",
		"DateStartGoal":"2021-01-01",
		"ValueStartGoal":"2021-01-01",
		"Evaluate":true,
		"UnitMeasurement":"Mt"
	}


	 */
	let dto = { UnitMeasurementId: data };

	return setMeasureUnit( dto, timingId, level, nodeId );
}




export const saveInfoHundredThree = ( data, collectId, infoId ) => {


	showlog("i-103");
	showlog( data );

	/*
	arriva
	{
		ht_1: "abc"
		ht_2: "123"
		ht_3: "def99"
		reason_2: "motivo 2"
		reason_3: "motivo 3"
	}

	voluto
	{
		_1031: "text",
		_1032: "text",
		_1033: "text",
		Reason2: "text",
		Reason3: "text"
	}
	*/

	let dto = {
		_1031: data.ht_1 || "",
		_1032: data.ht_2 || "",
		_1033: data.ht_3 || "",
		Reason2: data.reason_2,
		Reason3: data.reason_3,
		Reason3s: data.reason3s,
		Reason2s: data.reason2s
	};

	// return Promise.resolve( true );
	return setInfoHundredThree( dto, collectId, infoId );
}



export const saveChapterHundredThree = ( data, collectId, chapterId ) => {

	let dto = {
		_1031: data.ht_1 || "",
		_1032: data.ht_2 || "",
		_1033: data.ht_3 || "",
		Reason2: data.reason_2,
		Reason3: data.reason_3,
		Reason3s: data.reason3s,
		Reason2s: data.reason2s
	};

	// return Promise.resolve( true );
	return setChapterHundredThree( dto, collectId, chapterId );
}




export const saveNewZone = (zoneName, timing, nodeId, structureId) => {
	let dto =  {
		timing: timing,
		requirementType: NEW_ZONE_REQUIREMENT_TYPE,
		id: parseInt(nodeId, 10),
		GriStructureId: structureId,
		Name: zoneName
	};
	return addNewZone( dto );
};


export const saveTreeNodeValue = ( structureId, level, nodeId, months, isString ) => {

	let payload =
		months
			.map( m => ({
				ValueDecimal: m.value,
				isValueActive: m.active,
				ValueString: ""
			}))
	;

	let mDto =
		{
			ValueType: false,
			GriValuesList: payload
		}
	;

	return setTreeNodeValue( structureId, mDto );
};


/**
 *
 * Se annuale l'array è composto da soltanto un elemento
 * Se mensile da 12
 *
 * @param months
 * @param fatherStructureId
 * @param virtualNodeId
 * @param areaId
 * @returns {Promise<never>}
 */
export const saveVirtualTreeNodeValue = ( months, fatherStructureId, virtualNodeId, areaId ) => {

	let mDto =
		{
			ValueType: typeof months[0].value === "string"
				// !!months[0].value
				// 	? false
				// 	: (isNaN(months[0]) ? true : false)
				,
			GriValuesList:
				months
					// .filter( m => ( !!m && !!m.active ))
					.map( m => {
						if ( typeof m === "object" && !!m.active ) return m.value;
						return null;
					})
					// .filter( onlyNotNull )

					.map( m => ({
						ValueDecimal:

							(
								(
										( typeof m === "string" )
											? 0
											: ((!!m && m !== 0 && m !== "0" ) ? m : 0)
									)
							),

						// isValueActive: ( typeof m === "string" ) ? true : m,
						isValueActive: (!!m && m !== 0 && m !== "0" ),
						ValueString: (
							(
									( typeof m === "string" )
										? m
										: ""
								)
						)
					}))

					/*
					.map( m => {
						return ({
							ValueDecimal:   isNaN(m) ? 0    : parseFloat(m),
							ValueString:    isNaN(m) ? (!!m ? m : "")    : "",
							isValueActive: ( !!m && m !== 0 )
						})
					})
					 */

		}
	;


	// let isValid =
	// 	months
	// 		.filter( m => isNaN(m) === isNaN(months[0]))
	// 		.filter( m => !!m )
	// 		.length
	// 	=== months.length
	// ;
	// if( !isValid ) return Promise.reject("I campi devono essere tutti dello stesso tipo ( Numnerico / Testo )");


	//
	// showlog("dato da salvare");
	// showlog( months );
	// showlog( isNaN(months[0]) ? true : false );
	// showlog( typeof months[0] );
	// debugger;


	return setVirtualTreeNodeValue( fatherStructureId, mDto, virtualNodeId, areaId );
}

// reqId, structureId, areaId
export const loadVirtualTreeNodeValue = ( subRequirementId, fatherStructureId, areaId ) => {
	// return getVirtualTreeNodeValue( fatherGriStructureId, areaId );
	return getVirtualTreeNodeValue( subRequirementId, fatherStructureId, areaId );
}

export const loadMonthlyData = ( structureId ) => {
	return getMonthlyData( structureId, 0 );
}


/**
 *
 * Risposta della chiamata
 * {
 * 	"data": {
 * 		"yearPrevius0": {
 * 			"griValueElementList": [
 * 				{
 * 					"valueDecimal": 0,
 * 					"valueString": "",
 * 					"isValueActive": false,
 * 					"repeating_id": 0,
 * 					"repeating_name": null
 * 				}
 * 			],
 * 			"isValueString": true
 * 		},
 * 		"yearPrevius1": null,
 * 		"yearPrevius2": null,
 * 		"yearPrevius3": null
 * 	}
 * }
 *
 * @param structureId
 * @returns {Promise<{month: *}[]>}
 */
export const loadPreviousMonthlyData = structureId => {
	return (
		getPreviousMonthlyData( structureId )
			.then( result => {

				// TODO da spostare
				let MONTHS = [
					translate("Gennaio"),
					translate("Febbraio"),
					translate("Marzo"),
					translate("Aprile"),
					translate("Maggio"),
					translate("Giugno"),
					translate("Luglio"),
					translate("Agosto"),
					translate("Settembre"),
					translate("Ottobre"),
					translate("Novembre"),
					translate("Dicembre")
				]
				let year0 = `${ translate("Year") } ${ (new Date()).getFullYear() }`;
				let year1 = `${ translate("Year") } ${ (new Date()).getFullYear() - 1 }`;
				let year2 = `${ translate("Year") } ${ (new Date()).getFullYear() - 2 }`;
				let year3 = `${ translate("Year") } ${ (new Date()).getFullYear() - 3 }`;


				// adjust ------------------------------------------------------------------------
				if( !result.yearPrevius0 ) {
					result.yearPrevius0 = {
						griValueElementList:
							Array( 13 )
								.fill({
									valueDecimal: 0,
									valueString: "",
									isValueActive: false,
									repeating_id: 0,
									repeating_name: null
								})
								.map( row => row )
						,
						isValueString: true
					}
				}
				if( !result.yearPrevius1 ) {
					result.yearPrevius1 = {
						griValueElementList:
							Array( result.yearPrevius0.griValueElementList.length )
								.fill({
									valueDecimal: 0,
									valueString: "",
									isValueActive: false,
									repeating_id: 0,
									repeating_name: null
								})
								.map( row => row )
						,
						isValueString: true
					}
				}
				if( !result.yearPrevius2 ) {
					result.yearPrevius2 = {
						griValueElementList:
							Array( result.yearPrevius0.griValueElementList.length )
								.fill({
									valueDecimal: 0,
									valueString: "",
									isValueActive: false,
									repeating_id: 0,
									repeating_name: null
								})
								.map( row => row )
						,
						isValueString: true
					}
				}
				if( !result.yearPrevius3 ) {
					result.yearPrevius3 = {
						griValueElementList:
							Array( result.yearPrevius0.griValueElementList.length )
								.fill({
									valueDecimal: 0,
									valueString: "",
									isValueActive: false,
									repeating_id: 0,
									repeating_name: null
								})
								.map( row => row )
						,
						isValueString: true
					}
				}

				if( result.yearPrevius0.griValueElementList.length === 1 ) {
					result.yearPrevius0 = {
						griValueElementList:
							[
								result.yearPrevius0.griValueElementList[0],
								...Array( 12 )
									.fill({
										valueDecimal: 0,
										valueString: "",
										isValueActive: false,
										repeating_id: 0,
										repeating_name: null
									})
									.map( row => row )
							]
						,
						isValueString: result.yearPrevius0.isValueString
					}
				}
				if( result.yearPrevius1.griValueElementList.length === 1 ) {
					result.yearPrevius1 = {
						griValueElementList:
							[
								result.yearPrevius1.griValueElementList[0],
								...Array( 12 )
									.fill({
										valueDecimal: 0,
										valueString: "",
										isValueActive: false,
										repeating_id: 0,
										repeating_name: null
									})
									.map( row => row )
							]
						,
						isValueString: result.yearPrevius1.isValueString
					}
				}
				if( result.yearPrevius2.griValueElementList.length === 1 ) {
					result.yearPrevius2 = {
						griValueElementList:
							[
								result.yearPrevius2.griValueElementList[0],
								...Array( 12 )
									.fill({
										valueDecimal: 0,
										valueString: "",
										isValueActive: false,
										repeating_id: 0,
										repeating_name: null
									})
									.map( row => row )
							]
						,
						isValueString: result.yearPrevius2.isValueString
					}
				}
				if( result.yearPrevius3.griValueElementList.length === 1 ) {
					result.yearPrevius3 = {
						griValueElementList:
							[
								result.yearPrevius3.griValueElementList[0],
								...Array( 12 )
									.fill({
										valueDecimal: 0,
										valueString: "",
										isValueActive: false,
										repeating_id: 0,
										repeating_name: null
									})
									.map( row => row )
							]
						,
						isValueString: result.yearPrevius3.isValueString
					}
				}
				// ------------------------------------------------------------------------ adjust


				showlog("result appattato");
				showlog( result );


				return (
					Array( 13 )
						.fill({})
						.map( (row, index) => ({
							month: ((index === 0) ? translate("Values") : MONTHS[index -1] )
						}))
						.map( (row, i) => {

							let index = i;

							showlog("controllo row");
							showlog(row);
							showlog( result.yearPrevius0.griValueElementList );

							row[ year0 ] = (
								!!result.yearPrevius0.griValueElementList[ index ].isValueActive
									? (
										!!result.yearPrevius0.isValueString
											? result.yearPrevius0.griValueElementList[ index ].valueString
											: result.yearPrevius0.griValueElementList[ index ].valueDecimal
									)
									: null
							);
							row[ year1 ] = (
								!!result.yearPrevius1.griValueElementList[ index ].isValueActive
									? (
										!!result.yearPrevius1.isValueString
											? result.yearPrevius1.griValueElementList[ index ].valueString
											: result.yearPrevius1.griValueElementList[ index ].valueDecimal
									)
									: null
							);
							row[ year2 ] = (
								!!result.yearPrevius2.griValueElementList[ index ].isValueActive
									? (
										!!result.yearPrevius2.isValueString
											? result.yearPrevius2.griValueElementList[ index ].valueString
											: result.yearPrevius2.griValueElementList[ index ].valueDecimal
									)
									: null
							);
							row[ year3 ] = (
								!!result.yearPrevius3.griValueElementList[ index ].isValueActive
									? (
										!!result.yearPrevius3.isValueString
											? result.yearPrevius3.griValueElementList[ index ].valueString
											: result.yearPrevius3.griValueElementList[ index ].valueDecimal
									)
									: null
							);

							return row;
						})
				);


			})
			.catch( e => Promise.reject( e ) )
	);
}



export const loadAllChildValues = (reqId, reqStructureId, zoneId, childList) => {
	return getAllChildValues(reqId, reqStructureId, zoneId, childList.join(","));
}


export const saveTreeNodeSingleValue = ( structureId, level, nodeId, yearValue, isString ) => {
	let yearDto =
		{
			// ValueType: isNaN(yearValue) ? true : false,
			ValueType: !!isString ? true : false,
			GriValuesList: [
				{
					ValueDecimal:   !!isString ? 0            : ( ( !yearValue || yearValue === undefined || yearValue === null || typeof yearValue === "undefined" ) ? 0 : parseFloat(yearValue)),
					ValueString:    !!isString ? yearValue    : "",
					isValueActive: ( !!yearValue && yearValue !== 0 )
				}
			]
		}
	;

	// if( !!(yearDto.GriValuesList.some( v => isNaN(v.ValueDecimal) ) ) ) return Promise.reject("Valore numerico errato!");
	return setTreeNodeSingleValue( structureId, yearDto );
};


export const treeSetConfirm = collectId => {
	// return saveTreeSet(  { GriTimingId: collectId } );
	return setAssurance( collectId, COLLECT_STATUS.confirmed.id );
};

export const treeSetComplete = collectId => {
	return setAssurance( collectId, COLLECT_STATUS.inAssurance.id );
};

export const editMaterialThemesTabName = (id, name) => {
	return updateMaterialThemesTabName(id, name);
}



export const deleteMaterialTheme = id => {
	return eraseMaterialTheme( id );
}

export const loadMaterialThemes = (collectId) => {

	return (
		getMaterialThemes( collectId )
			.then( list => {
				return (
					list
						.map( el => ({
							...el,
							id: el.griMaterialThematicsId,
							name: el.griMaterialThematicsName,
							ref: el.griMaterialThematicInformatives_id
						}))
				);
			})
			.catch(e => {
				return Promise.reject(e);
			})
	);

	/*
	return (
		getMaterialThemes( collectId )
			.then( list => {

				// debugger;
				/*
					griMaterialThematicsId: 84
					griMaterialThematicsName: "DUE"
					griTimingId: 56
					id: 0
					informativeId: 0
					informativeName: null
				 * /



				let tms = [];
				list
					.map( l => {
						if( tms.filter( t => l.griMaterialThematicsId === t.id ).length === 0 ) {
							tms.push({
								id: l.griMaterialThematicsId,
								name: l.griMaterialThematicsName,
								infos: [{
									id: l.informativeId,
									name: l.informativeName,
									ref: l.id
								}],
								open: false
							});
						}
						else {
							tms
								.filter( t => t.id === l.griMaterialThematicsId )
								.map( t => {
									t.infos.push({
										id: l.informativeId,
										ref: l.id
									})
								})
							;
						}
					})
				;

				tms
					.map( tm => {
						tm.infos =
							tm.infos.filter( i => i.id !== 0 )
					})
				;


				// showlog("albero delle TM e delle I");
				// showlog( tms );

				return tms;
			})
			.catch(e => Promise.reject( e ) )
	);
	*/

};

export const loadAllMTInformatives = (collectId) => {
	return loadAllMaterialThemesInformatives( collectId );
};

export const addNewMaterialThemes = (name, collectId) => {
	return createMaterialThemes( collectId, name );
}


export const moveInfoToMaterialThemes = (infoId, materialThemeId, refId, fromNotCategorized) => {

	showlog("moveInfoToMaterialThemes");
	showlog(infoId, materialThemeId, refId, fromNotCategorized);


	if(!!fromNotCategorized) {
		// refId qui non possiamo averlo
		return moveInfoToMaterialThemeFromNotCategorized({ GriGeneralThematicId: materialThemeId, GriInformativeId: infoId });
	}
	return moveInfoToMaterialThemeFromOtherMaterialTheme( refId, { GriGeneralThematicId: materialThemeId, GriInformativeId: infoId });
}


export const moveInfoFromMaterialThemesToNotCategorized = ref => {
	return removeInfoFromMaterialThemes( ref );
};


export const loadHundredValues = (collectId, id, isChapter) => {
	let chid = 0;
	let infoid = 0;
	if( !!isChapter )   chid = id;
	else                infoid = id;
	return getHundredValues(collectId, chid, infoid);
}


export const loadHundredThreeReasons = () => {
	return getHundredReasons();
}

export const removeRepeatingZone = id => {
	return deleteRepeatingZone( id );
};


export const changeRepeatingZoneName = (id, newName) => {
	return updateRepeatingZoneName( id, newName );
}



export const loadMeasureUnitList = collectId => {
	return (
		getMeasureUnitList( collectId )
			.then( flatList => {

				let categories =
					[
						... new Map(
							flatList
								.map( row => ({
									id: row.griUnitMeasurementsTipologyId,
									name: row.griUnitMeasurementsTipologyName,
									measureUnits: []
								}))
								.map( item => [ item.id, item ])
						).values()
					]
				;

				flatList
					.map( row => {

						let currentCategory =
							categories
								.filter( cat => cat.id === row.griUnitMeasurementsTipologyId )
								.reduce( getLastElementOr, null )
						;

						currentCategory.measureUnits =
							[
								...currentCategory.measureUnits,
								{
									id: row.griUnitMeasurementsListId,
									name: row.griUnitMeasurementsName,
									isSelected: row.isSelected
								}
							]
						;

					})
				;


				return categories;

			})
	);
}


export const setUnit = (collectId, familyMeasureUnitId, measureUnitId) => {
	return setConfTreeMeasureUnit( collectId, familyMeasureUnitId, measureUnitId );
}


/**
 * chiamata a Attachments/getGRI/this.props.collect.id/info.id
 *
 * @param collectId
 * @param informativeId
 * @returns {Promise<*|SimpleCacheConfigurator.never>}
 */
export const loadSRToolAttachments = (collectId, informativeId = 0) => {


	return (
		Promise.resolve()

			// chiamata
			.then( () => {
				return getSRToolAttachments(collectId, informativeId);
			})

			// trasformazione Entity > DTO
			.then( list => {

				/*
				Entity
				{
					approvedDate: null
					approvedUser: 0
					dateCreate: "2021-11-11T09:27:23.503"
					dateUpdate: null
					griInformative: null
					griInformativeId: 1
					griTiming: {
						alive: true
						attachments: Array []
						company: null
						companyId: 19
						dashboards: Array []
						dateCreate: "2021-10-08T12:41:48.423"
						dateEnd: "2022-10-07T00:00:00"
						dateStart: "2021-10-08T00:00:00"
						dateUpdate: "2021-10-08T12:41:48.423"
						griIndicatorsStructure: Array []
						griInformative103: Array []
						griInformativesNote: Array []
						griMaterialThematics: Array []
						griStructure: Array []
						griUnitMeasurementsTimings: Array []
						id: 56
						name: "Prova"
						reportType: 0
						step: 3
						timing: false
						userCreate: 166
						userUpdate: 166
					}
					griTimingId: 56
					id: 1611
					isApproved: false
					name: "WhatsApp Image 2021-10-06 at 0.jpeg"
					srEvaluation: null
					srEvaluationId: 0
					srQuestion: null
					srQuestionId: 0
					url: "C:\\webapi\\attachments\\19\\gri\\56\\1"
					userCreate: 26
					userUpdate: 0
				}

				DTO
				{
					id: 1611
					dateCreate: "2021-11-11T09:27:23.503"
					dateUpdate: null
					griInformativeId: 1
					name: "WhatsApp Image 2021-10-06 at 0.jpeg"
				}
				 */
				showlog( list );


				return (
					list
						.map( attachment => ({
							id: attachment.id,
							name: attachment.name,
							dateCreate: attachment.dateCreate,
							dateUpdate: attachment.dateUpdate,
							griInformativeId: attachment.griInformativeId
						}))
				);

			})
	);



};





export const toggleCollectPmAuthorized = (cid, value) => {
	return setCollectPmAuthorized(cid, { isPmauthorized: value });
}



