import React, {Component} from "react";
import {LANGUAGES, role} from "../../../env";
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import Select from 'react-select';
import "../modal.css";
import {validationAdminNewUser} from "../../../business-logic/companies";
import {editUserByAdmin, saveNewUserByAdmin} from "../../../business-logic/users";
import {getLastElementOr, onlyFirst} from "../../../utils/array-utils";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getAllNodesOfSubRoleTree, getSubRoleTree, scanRoleTree} from "../../../business-logic/role-tree-manager";
import {getCurrentRoleId} from "../../../utils/role-utils";
import {isUserRole} from "../../../business-logic/auth";
import ActionButton from "../../save-button";
import {translate} from "../../i18n/translate-function";


export default class AdminUserEditModalContent extends Component {

	state = {
		show: true,
		inWait: true,
		companies: [],
		user: this.props.user || {
			role: (!!this.props.isManager) ? role.SRTool_MANAGER.id : null
		}
	}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.close( forceRefresh );
	}

	render() {

		renderlog(
			"ADMIN UserEditModal",
			this.state,
			this.props
		);
		// console.log("visible password");


		let roleList = getAllNodesOfSubRoleTree( getSubRoleTree( getCurrentRoleId() ) );
		showlog("roleList");
		showlog( roleList );
		showlog( roleList.map( r => ({value: r.id, label: r.label}) ) );



		/*
		console.log("controllo titolo");
		console.log(
			TITLES_NAME[
				Object.keys(TITLES_NAME)
					.map( t => {
						console.log("title");
						console.log(this.state.user.title);
						console.log(t);
						console.log(TITLES_NAME[t]);
						console.log(TITLES_NAME[t].it);
						return t;
					})
					.filter(t => TITLES_NAME[t].it === this.state.user.title )
					.map( t => {
						console.log("title");
						console.log(t);
						return t;
					})
					.join("")
				]
		)
		;
		*/





		let roleIndex =
			Object.keys(role)
				.filter( r => r !== "PROJECT_MANAGER" && r !== "MASTER" )
				.map( r => role[r] )
				.sort((x, y) => {
					if( x.id < y.id ) return 1;
					return -1;
				})
				.map( (r, i) => {
					if( r.name === localStorage.getItem("role") ) {
						return r.id;
					}
					return null;
				})
				.filter(r => !!r)
				.filter( onlyFirst )
				.reduce( getLastElementOr, 0 )
			;



		let context = (
			<AvForm>

				{/*     TITOLO
				<FormGroup>
					<Label for="title">Titolo</Label>

					<Select
						className="react-select"
						classNamePrefix="react-select"
						name="title"
						id="title"
						onChange={ changeEvent => {

							this.setState({
								user: Object.assign(
									{},
									this.state.user,
									{ title: changeEvent.value }
								)
							})
						}}
						options={[
							{ value: TITLES.MR, label: TITLES_NAME.MR.it },
							{ value: TITLES.DOCT, label: TITLES_NAME.DOCT.it },
							{ value: TITLES.ENG, label: TITLES_NAME.ENG.it }
						]}
						value={

							(!!this.state.user.title)
								? (
									{
										value:
											Object.keys(TITLES)
												.filter(t => TITLES[t] === this.state.user.title )
												.join(""),
										label:
											TITLES_NAME[
												Object.keys(TITLES_NAME)
													.filter(t => TITLES_NAME[t].it === this.state.user.title )
													.join("")
												].it
									}
								)
								: null // { value: TITLES.MR, label: TITLES_NAME.MR.it }
						}
						placeholder={ <span className={"select-placeholder"}>Seleziona titolo</span> }
					/>
					{
						( !!this.state.errors && !!this.state.errors.title )
							? <label className="error-message-label">{ this.state.errors.title }</label>
							: null
					}
				</FormGroup>
				*/}

				{/*     COGNOME  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="lastName">Cognome</Label>
					<AvInput
						type="text"
						name="lastName"
						id="lastName"
						value={ this.state.user.lastName }
						onChange={ changeTextEvent => {

							// console.log("cambio lastName");
							this.setState(
								{
									user: Object.assign(
										{},
										this.state.user,
										{ lastName: changeTextEvent.target.value }
									)
								},
								newState => {
									// console.log("email cambiata");
									// console.log( this.state );
								}
							)
						}}
						required
					/>
					{
						( !!this.state.errors && !!this.state.errors.lastName )
							? <label className="error-message-label">{ this.state.errors.lastName }</label>
							: null
					}
				</AvGroup>

				{/*     NOME  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="firstName">Nome</Label>
					<AvInput
						type="text"
						name="firstName"
						id="firstName"
						value={ this.state.user.firstName }
						onChange={ changeTextEvent => {

							// console.log("cambio firstName");
							this.setState(
								{
									...this.state,
									user: Object.assign(
										{},
										this.state.user,
										{ firstName: changeTextEvent.target.value }
									)
								},
								newState => {
									// console.log("email cambiata");
									// console.log( this.state );
								}
							)
						}}
						required
					/>
					{
						( !!this.state.errors && !!this.state.errors.firstName )
							? <label className="error-message-label">{ this.state.errors.firstName }</label>
							: null
					}
				</AvGroup>



				{
					(!!this.props.isManager)
						? null
						: (

							<FormGroup>
								{/*     RUOLO  -------------------------------------------------------------------------------- */}
								<Label for="title">Ruolo</Label>
								<Select
									className="react-select"
									classNamePrefix="react-select"
									name="role"
									id="role"
									onChange={ changeEvent => {

										this.setState({
											user: Object.assign(
												{},
												this.state.user,
												{ role: changeEvent.label }
											)
										})

									}}
									options={

										( !!isUserRole(role.SRTool_ADMIN) )
											? (
												Object.keys(role)
													.map( r => role[ r ])
													.filter( r =>
														[
															// role.SRTool_MANAGER.id      ---- rimozione srTool scadenza GRI
														].includes( r.id )
													)
													.map( r => ({value: r.id, label: r.label}) )
											)
											: (
												Object.keys(role)
													.map( r => role[ r ])
													.filter( r =>
														[
															role.ADMIN.id,
															role.SR_ADMIN.id,
															role.SI_Scoring_Admin.id,
															role.Parità_Di_Genere_Admin.id,
															// role.SRTool_ADMIN.id, ---- rimozione srTool scadenza GRI
															// role.SRTool_MANAGER.id ---- rimozione srTool scadenza GRI
														].includes( r.id )
													)
													.map( r => ({value: r.id, label: r.label}) )
											)

									}
									value={
										(!!this.state.user.role)
											? (
												Object.keys(role)
													.filter( r => role[r].label === this.state.user.role )
													.map( r => ({value: role[r].id, label: role[r].label}) )
													.reduce( getLastElementOr, {label: "Nessun Ruolo", value: -1} )
											)
											: null
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona ruolo</span> }
								/>
								{
									( !!this.state.errors && !!this.state.errors.role )
										? <label className="error-message-label">{ this.state.errors.role }</label>
										: null
								}
							</FormGroup>

						)
				}



				{/*     EMAIL  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="email">Email</Label>
					<AvInput
						type="email"
						name="email"
						id="email"
						placeholder="example@mail.com"
						value={ this.state.user.email }
						onChange={ changeTextEvent => {

							// console.log("cambio email");
							this.setState(
								{
									user: Object.assign(
										{},
										this.state.user,
										{ email: changeTextEvent.target.value }
									)
								},
								newState => {
									// console.log("email cambiata");
									// console.log( this.state );
								}
							)
						}}
						required
					/>
					{
						( !!this.state.errors && !!this.state.errors.email )
							? <label className="error-message-label">{ this.state.errors.email }</label>
							: null
					}
				</AvGroup>


				{/* LINGUA  -----------------------------------*/}

				<FormGroup >

							   <Label for="language_id">Lingua</Label>

                                <Select
                                    className="react-select gri-type-select"
                                    classNamePrefix="react-select"
                                    name="gri-type-select"
                                    id="gri-type-select"

									onChange={ changeEvent => {


											this.setState({
												...this.state,
												user: Object.assign(
													{},
													this.state.user,
													{ language_id: changeEvent.value },
												),
												errors: {
													...this.state.errors,
													language_id: undefined
												}
											})

										}}
                                    options={LANGUAGES}
									value={
										(!!this.state.user.language_id)
											? (
												LANGUAGES
												.filter(ol => (ol.value === this.state.user.language_id ))
												.reduce(getLastElementOr , null)
											)
											: null
									}
                                    placeholder={
										<span className={"select-placeholder"}>Seleziona Lingua</span>
									 }

                                    />

									{
										( !!this.state.errors && !!this.state.errors.language_id )
											? <label className="error-message-label">{ this.state.errors.language_id }</label>
											: null
									}


                    </FormGroup>



			</AvForm>
		)





		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{
						// (!!this.state.user && JSON.stringify( this.state.user ) !== "{}")
						(!!this.props.user && JSON.stringify( this.props.user ) !== "{}")
							? "Modifica Utente"
							: "Nuovo Utente"
					}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>


					{
						(!!this.state.notValidPassword)
							? null
							: (

								<ActionButton
									buttonText={ (!!this.state.notValidPassword) ? " " : translate("Save") }
									promise={ () => {
										return validationAdminNewUser( this.state.user, !!this.props.user );
									}}
									onResult={ result => {
										if( !!this.props.user ) {
											editUserByAdmin( this.state.user )
												.then( () => {
													this.toggle( true );
												})
												.catch(e => {
													errorlog("errore al salvataggio", e);
													// let errorMessage = "";
													// if( typeof e === "string" ) errorMessage = e;
													// else {
													// 	if( typeof e === "object" && !!e.message ) errorMessage = e.message;
													// 	else {
													// 		if( typeof e === "object" ) errorMessage = JSON.stringify( e );
													// 	}
													// }
													//
													// if(  errorMessage.toLowerCase().indexOf("presente") !== -1 ) {
													// 	this.setState({
													// 		...this.state,
													// 		errors: {
													// 			...this.state.errors,
													// 			email: e
													// 		}
													// 	})
													// }
												})
										}
										else {
											saveNewUserByAdmin( this.state.user )
												.then( () => {
													this.toggle( true );
												})
												.catch(e => {
													errorlog("errore al salvataggio", e);

													let errorMessage = "";
													if( typeof e === "string" ) errorMessage = e;
													else {
														if( typeof e === "object" && !!e.message ) errorMessage = e.message;
														else {
															if( typeof e === "object" ) errorMessage = JSON.stringify( e );
														}
													}

													if(  errorMessage.toLowerCase().indexOf("presente") !== -1 ) {
														this.setState({
															...this.state,
															errors: {
																...this.state.errors,
																email: e
															}
														})
													}



												})
										}
									}}
									onError={ error => {
										errorlog("VALIDAZIONE UTENTE", error);
										let errors = {};
										error.map( kv => Object.assign( errors, kv ) );
										this.setState({
											...this.state,
											errors
										})
									}}
								/>

							)
					}



					<Button color="secondary" onClick={this.toggle}>
						{ translate("Close") }
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;

	}


	componentDidMount() {

		scanRoleTree();
		let subRoleTree =
			( !!isUserRole( role.CONSULTANT ) )
				? getSubRoleTree( role.ADMIN.id )
				: getSubRoleTree( getCurrentRoleId() )
		;
		let subTreeNodeList = getAllNodesOfSubRoleTree( subRoleTree );

		// showlog( "is consultant? ", !!isUserRole( role.CONSULTANT ));
		// showlog( getCurrentRoleId() );
		// showlog( subRoleTree );
		// showlog( subTreeNodeList );

		this.setState({
			...this.state,
			allPossibleRoles: subTreeNodeList
		});

	}



};
