import React, {Component} from 'react';
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {CALENDAR_DATE_FORMAT_IT, DATE_FORMAT_IT, permissions} from "../../../env";
import {FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import HyperDatepicker from "../../Datepicker";
import {
	editService,
	loadAllServices,
	saveNewService,
	validationSuperAdminNewService
} from "../../../business-logic/active-service";
import {getBaseCompaniesList, getBaseCompaniesList2} from "../../../business-logic/companies";
import Select from 'react-select';
import "../modal.css";
import moment from "moment";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getLastElementOr, onlyFirst, onlyNotNull} from "../../../utils/array-utils";
import {hasPermission} from "../../../business-logic/auth";
import {loadOrganizzations} from "../../../business-logic/organizzations";
import ActionButton from "../../save-button";
import {translate} from "../../i18n/translate-function";


export default class NewServiceModal extends Component {
	state = {
		id: this.props.service.id,
		service_id: this.props.service.service_id,
		organizzation_id: this.props.service.leading_id,
		organizzation_name: this.props.service.leading_name,
		company_id: this.props.service.company_id,
		company_name: this.props.service.company_name,
		startTime: this.props.service.date_start || moment().format( DATE_FORMAT_IT ),
		validity: this.props.service.date_monthsNumber,
		maxEvaluationsNumber: this.props.service.evaluationsMaxNumber,
		maxCollectionsNumber: this.props.service.collectionsMaxNumber,
		isOrganizzation: this.props.isOrganizzation,
		errors: {
			service_id: (!!this.props.service && !!this.props.service.service_id) ? null : "Nessun servizio attivo"
		}
	}

	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );
		this.loadServices   = this.loadServices.bind( this );
		this.loadCompanies  = this.loadCompanies.bind( this );
		// this.editService       = this.editService.bind( this );
		// this.addNewService       = this.addNewService.bind( this );
		// this.validateField  = this.validateField.bind( this );
	}

	toggle = () => {
		this.props.onClose();
	}

	render() {

		renderlog(
			"ServiceEditModal",
			this.state,
			this.props
		);


		let context = (
			<AvForm>

				{/*     SERVIZIO  -------------------------------------------------------------------------------- */}
				<FormGroup>
					<Label for="service">Servizio</Label>
					<Select
						className="react-select"
						classNamePrefix="react-select"
						name="service"
						id="service"
						onChange={ changeEvent => {

							this.setState(
								{
									...this.state,
									service_id: changeEvent.value
								},
								() => {
									validationSuperAdminNewService( this.state )
										.then( () => {

											this.setState(
												{
													...this.state,
													errors: {
														...this.state.errors,
														service_id: null
													}
												})
										})
										.catch(e => {
											let errors = {};
											e.map( kv => Object.assign( errors, kv ) );
											this.setState({
												...this.state,
												errors
											})
										})
									;
								}
							)
						}}
						options={ this.state.services }
						value={

							(!!this.state.service_id && this.state.services)
								? this.state.services.filter( s => s.value === this.state.service_id ).map( s => ({value: s.value, label: s.label}) )[0]
								: null

							/*
							(!!this.props.service.id)
								? ({ value: this.props.service.id, label: this.props.service.service_name })
								: (!!this.state.id)
									? this.state.services.filter( s => s.value === this.state.id ).map( s => ({value: s.value, label: s.label}) )[0]
									: null
							*/
						}
						placeholder={ <span className={"select-placeholder"}>Seleziona servizio</span> }
					/>
					{
						( !!this.state.errors && !!this.state.errors.service_id )
							? <label className="error-message-label">{ this.state.errors.service_id }</label>
							: null
					}
				</FormGroup>


				{/*     AZIENDA  -------------------------------------------------------------------------------- */}
				{
					(!!hasPermission( permissions.SELECT_COMPANY ) && !this.props.isOrganizzation )
						? (
							<FormGroup>
								<Label for="title">Azienda { (!this.state.companies) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
								<Select
									className="react-select"
									classNamePrefix="react-select"
									name="company"
									id="company"
									onChange={ changeEvent => {

										this.setState(
											{
												...this.state,
												company_id: parseInt(changeEvent.value, 10),
												company_name:
													this.state.companies
														.filter( company => company.id === parseInt(changeEvent.value, 10))
														.map( company => company.name )
														.filter( onlyFirst )
														.reduce( getLastElementOr, null )
											},
											() => {
												validationSuperAdminNewService(this.state)
													.then(() => {

														this.setState(
															{
																...this.state,
																errors: {
																	...this.state.errors,
																	company: null
																}
															})
													})
													.catch(e => {
														let errors = {};
														e.map(kv => Object.assign(errors, kv));
														this.setState({
															...this.state,
															errors
														})
													})
												;
											}
										);


									}}
									options={ (!!this.state.companies) ? this.state.companies.map( company => ({value: parseInt(company.id, 10), label: company.name })) : [] }
									value={
										(!!this.state.company_id)
											? (
												{
													value: this.state.company_id,
													label: this.state.company_name
												}
											)
											: null
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona Azienda</span> }
								/>
								{
									( !!this.state.errors && !!this.state.errors.company )
										? <label className="error-message-label">{ this.state.errors.company }</label>
										: null
								}
							</FormGroup>
						)
						: (
							( !!this.props.isOrganizzation )
								? (
									<FormGroup>
										<Label for="title">{ translate("Leading_companies") } { (!this.state.organizzations) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
										<Select
											className="react-select"
											classNamePrefix="react-select"
											name="organizzations"
											id="organizzations"
											onChange={ changeEvent => {

												this.setState(
													{
														...this.state,
														organizzation_id: parseInt(changeEvent.value, 10),
														organizzation_name:
															this.state.organizzations
																.filter( org => org.id === parseInt(changeEvent.value, 10))
																.map( org => org.name )
																.filter( onlyFirst )
																.reduce( getLastElementOr, null )
													},
													() => {
														validationSuperAdminNewService(this.state)
															.then(() => {

																this.setState(
																	{
																		...this.state,
																		errors: {
																			...this.state.errors,
																			organizzation: null
																		}
																	})
															})
															.catch(e => {
																let errors = {};
																e.map(kv => Object.assign(errors, kv));
																this.setState({
																	...this.state,
																	errors
																})
															})
														;
													}
												);


											}}
											options={ (!!this.state.organizzations) ? this.state.organizzations.map( org => ({value: parseInt(org.id, 10), label: org.name })) : [] }
											value={
												(!!this.state.organizzation_id)
													? (
														{
															value: this.state.organizzation_id,
															label: this.state.organizzation_name
														}
													)
													: null
											}
											placeholder={ <span className={"select-placeholder"}>Seleziona Organizzazione</span> }
										/>
										{
											( !!this.state.errors && !!this.state.errors.organizzation )
												? <label className="error-message-label">{ this.state.errors.organizzation }</label>
												: null
										}
									</FormGroup>
								)
								: null
						)
				}





				{/*     DATA_INIZIO  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label>Data inizio</Label>
					<HyperDatepicker
						hideAddon={ true }
						dateFormat={ CALENDAR_DATE_FORMAT_IT }
						onSelect={ clickEvent => {

							this.setState({
								...this.state,
								startTime: moment( clickEvent ).format( DATE_FORMAT_IT )
							})
						}}
						value={ this.state.startTime || this.props.service.date_start }
					/>
					{
						( !!this.state.errors && !!this.state.errors.startTime )
							? <label className="error-message-label">{ this.state.errors.startTime }</label>
							: null
					}
				</AvGroup>


				{/*     MESI_DI_VALIDITA  -------------------------------------------------------------------------------- */}
				<AvGroup className={"modal-form-label-input-inline"}>
					<Label for="validity">Mesi di validità</Label>
					<div className={"modal-form-input-group"}>
						<AvInput
							type="number"
							name="validity"
							id="validity"
							value={ this.props.service.date_monthsNumber || this.state.validity }
							onChange={ changeTextEvent => {

								this.setState(
									{
										...this.state,
										validity: changeTextEvent.target.value
									},
									() => {
										validationSuperAdminNewService(this.state)
											.then(() => {

												this.setState(
													{
														...this.state,
														errors: {
															...this.state.errors,
															validity: null
														}
													})
											})
											.catch(e => {
												let errors = {};
												e.map(kv => Object.assign(errors, kv));
												this.setState({
													...this.state,
													errors
												})
											})
									}
								)

							}}
							required
						/>
						{
							( !!this.state.errors && !!this.state.errors.validity )
								? <label className="error-message-label">{ this.state.errors.validity }</label>
								: null
						}
					</div>
				</AvGroup>







				{
					( !!this.props.isOrganizzation )
						? (
							<React.Fragment>

								{/*     NUMERO_MASSIMO_DI_VALUTAZIONI  -------------------------------------------------------------------------------- */}
								{
									((this.state.service_id === 2) || (this.state.service_id === 3) || (this.state.service_id === 4))
										? (
											<AvGroup className={"modal-form-label-input-inline"}>
												<Label for="maxEvaluationsNumber" >{translate("Evaluations")}</Label>
												<div className={"modal-form-input-group"}>
													<AvInput
														type="number"
														name="maxEvaluationsNumber"
														id="maxEvaluationsNumber"
														value={ this.state.maxEvaluationsNumber }
														onChange={ changeTextEvent => {

															this.setState(
																{
																	...this.state,
																	maxEvaluationsNumber: changeTextEvent.target.value
																},
																() => {
																	validationSuperAdminNewService(this.state)
																		.then(() => {

																			this.setState(
																				{
																					...this.state,
																					errors: {
																						...this.state.errors,
																						maxEvaluationsNumber: null
																					}
																				})
																		})
																		.catch(e => {
																			let errors = {};
																			e.map(kv => Object.assign(errors, kv));
																			this.setState({
																				...this.state,
																				errors
																			})
																		})
																	;
																}
															)

														}}
														required
													/>
													{
														( !!this.state.errors && !!this.state.errors.maxEvaluationsNumber )
															? <label className="error-message-label">{ this.state.errors.maxEvaluationsNumber }</label>
															: null
													}
												</div>
											</AvGroup>
										)
										: null
								}


								{/*     NUMERO_MASSIMO_DI_RACCOLTE  -------------------------------------------------------------------------------- */}
								{
									this.state.service_id === 1
										? (
											<AvGroup className={"modal-form-label-input-inline"}>
												<Label for="maxCollectionsNumber">Raccolte</Label>
												<div className={"modal-form-input-group"}>
													<AvInput
														type="number"
														name="maxCollectionsNumber"
														id="maxCollectionsNumber"
														value={ this.state.maxCollectionsNumber }
														onChange={ changeTextEvent => {

															this.setState({
																	...this.state,
																	maxCollectionsNumber: changeTextEvent.target.value
																},
																() => {
																	validationSuperAdminNewService(this.state)
																		.then(() => {


																			this.setState(
																				{
																					...this.state,
																					errors: {
																						...this.state.errors,
																						maxCollectionsNumber: null
																					}
																				})
																		})
																		.catch(e => {
																			let errors = {};
																			e.map(kv => Object.assign(errors, kv));
																			this.setState({
																				...this.state,
																				errors
																			})
																		})
																	;
																}
															)

														}}
														required
													/>
													{
														( !!this.state.errors && !!this.state.errors.maxCollectionsNumber )
															? <label className="error-message-label">{ this.state.errors.maxCollectionsNumber }</label>
															: null
													}
												</div>
											</AvGroup>
										)
										: null
								}


							</React.Fragment>
						)
						: null
				}




			</AvForm>
		);


		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{
						(!!this.props.service && JSON.stringify( this.props.service ) !== "{}")
							? "Nuovo Servizio"
							: "Modifica Servizio"
					}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>



					<ActionButton
						color={"link"}
						buttonText={"annulla"}
						promise={ () => {
							return Promise.resolve();
						}}
						onResult={  toggleEvent => this.toggle() }
					/>

					<ActionButton
						// visible={ !this.state.errors || JSON.stringify( this.state.errors ) === "{}" }
						color={

							(
								!this.state.errors ||
								(
									Object.keys( this.state.errors )
										.map( key => this.state.errors[ key ] )
										.filter( onlyNotNull )
										.length
									=== 0
								)
							)
								? "primary"
								: "light"

						}
						buttonText={

							(
								!this.state.errors ||
								(
									Object.keys( this.state.errors )
										.map( key => this.state.errors[ key ] )
										.filter( onlyNotNull )
										.length
									=== 0
								)
							)
								? <span>Salva</span>
								: <em>Salva</em>

						}
						promise={ () => {
							return (
								new Promise((success, fail) => {


										if(
											!this.state.errors ||
											(
												Object.keys( this.state.errors )
													.map( key => this.state.errors[ key ] )
													.filter( onlyNotNull )
													.length
												=== 0
											)
										) success();
										else fail( this.state.errors );


									})



									// non ci sono errori di validazione
									.then( () => {




										if( !!this.props.service.service_id ) {
											// edit

											return (

												editService( this.state, !!this.props.isOrganizzation )
													.then(() => {

														return true;

													})
													.catch(e => {
														errorlog("modifica di un servizio", e);
														return Promise.reject( e );
													})
											);
										}
										else {
											// salvataggio

											return (
												saveNewService( this.state, !!this.props.isOrganizzation )
													.then(() => {

														return true;

													})
													.catch(e => {
														errorlog("salvataggio di un servizio", e);
														return Promise.reject( e );

													})
											)

										}




									})
							);
						}}
						onResult={ result => {

							this.toggle();

						}}
						onError={ e => {
							this.setState({
								...this.state,
								errors: {
									...this.state.errors,
									...e
								}
							});
						}}
					/>


				</ModalFooter>
			</Modal>


		);

		return content;
	}

	componentDidMount() {

		this.loadServices()
			.then( services => {


				if( !!this.props.isOrganizzation ) {

					loadOrganizzations()
						.then(organizzations => {
							this.setState({
								organizzations: organizzations
							})
						})
						.catch(e => {
							errorlog("caricamento organizzazioni", e);
						})
				}
				else {

					this.loadCompanies()
						.then( companies => {
							showlog("COMPANIES", companies)
						})
						.catch(e => {
							errorlog("errore al caricamento delle aziende");

						})
				}


			})
			.catch(e => {
				errorlog("errore al caricamento dei servizi", e);
			})

	}


	loadServices() {

		return (
			new Promise((success, fail) => {

				loadAllServices()

					// dto
					.then( services => {
						return (
							services
								.map(service => {
									return ({
										value: service.id,
										label: service.name
									});
								})
						);
					})

					// storing
					.then( services => {
						this.setState(
							{
								...this.state,
								services: services.filter(service => service.label !== "SR Tool") // ---- rimozione srTool scadenza GRI     (prima era senza filter)
							},
							() => {
								success( services );
							}
						);
					})
					.catch(e => {
						errorlog("errore al caricamento dei servizi");
						console.log(e);
						fail(e);
					})

			})
		);



	}


	loadCompanies() {
		return (
			new Promise((success, fail) => {

				getBaseCompaniesList2()
					.then( list => {

						this.setState({
							...this.state,
							companies: list
						},
							() => {
							success( list );
						})

					})
					.catch(e => {
						errorlog("errore al caricamento delle aziende");
						console.log(e);
						fail(e);
					})
			})
		);

	}




}

