import {SuperAdminDashboard_DELETE, SuperAdminDashboard_GET, SuperAdminDashboard_SET} from "./request-manager";

export const getSuperAdminDashboardData = (year, macroArea) => {
	return SuperAdminDashboard_GET( year, macroArea );
}


export const setSADashboardFilter = (chartId, year, payload) => {
	return SuperAdminDashboard_SET( chartId, year, payload );
}


export const deleteSADashboardFilter = graphID => {
	return SuperAdminDashboard_DELETE( graphID );
}
