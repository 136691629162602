import React, {Component} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {DATE_FORMAT_IT, role} from "../../../env";
import {loadEvaluations} from "../../../business-logic/active-service";
import {errorlog, renderlog} from "../../../utils/dev-utils";
import {isUserRole} from "../../../business-logic/auth";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import moment from "moment";
import {RATING_TYPE} from "../../../conf/siscoring";
import {translate} from "../../../components/i18n/translate-function";


const hiddenFields = [
    "companyId",
    "dimensionId",
    "dimensionName",
    "Dimensione",
    "dimension",
    "companySize",
    "undefined",
    "status",
    isUserRole(role.PROJECT_MANAGER) ? "" : "Ruoli",
    isUserRole(role.ARB_OPERATOR) ? "" : "PM",
    isUserRole(role.ARB_OPERATOR) ? "" : "Assignee",
    isUserRole(role.PROJECT_MANAGER) ? "" : "id",
    "version"
];

const fieldMap = {
    id: "id",
    versionComponent: "Versione",
    name: translate("Name"),
    dateCreate: translate("Creation Date"),
    ragioneSociale: "Ragione Sociale",
    state: "Status"
};

const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "Primo",
    prePageText: "Indietro",
    nextPageText: "Avanti",
    lastPageText: "Ultimo",
    nextPageTitle: "Prima pagina",
    prePageTitle: "Pagina precedente",
    firstPageTitle: "Prossima pagina",
    lastPageTitle: "Ultima pagina",
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Rating")}
        </span>
    ),
    sizePerPageList: [
        {
            text: "pochi",
            value: 25,
        },
        {
            text: "normale",
            value: 50,
        },
        {
            text: "molti",
            value: 250,
        }
    ]
};

const defaultSorted = [
    {
        dataField: "Id",
        order: "desc",
    },
];

const sortableFields = [
    translate("Name"),
    translate("Creation Date"),
    "Ragione Sociale",
    "Dimensione",
    "Status",
    "id",
    "Versione"
];


const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            // custom date time sort -----------------------------------------------------------------------------------
            .map( column => {
                if( [translate("Creation Date")].includes( column.dataField ) ) {
                    column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                        if (order === 'asc') {
                            return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                        }
                        return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                    }
                }
                return column;
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;

export default class EvaluationAssociatedFilterModal extends Component {

    state = {
        data:[],
        waitData: true
    }

    constructor(props) {
        super( props );


        this.toggle = this.toggle.bind( this );

    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }



    render() {

        renderlog( "EvaluationAssociatedFilterModal", this.state, this.props);

        let contentWait = (
            <Modal isOpen={true} toggle={this.toggle} className={"large-modal"}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Valutazioni
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    <WaitingOrNodataMessage
                        waiting={ true }
                        fullHeight={ true }
                    />
                </ModalBody>
            </Modal>
        );

        let contentNoData = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                   Valutazioni
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {translate("NoData")}
                </ModalBody>
            </Modal>
        );

        let contentError = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Valutazioni
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    Errore ottenimento dati
                </ModalBody>
            </Modal>
        );

        let context= (

            <React.Fragment>

                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    search
                >
                    {props => (
                        <React.Fragment>

                            <div className={""} style={{marginLeft:"10px"}}><SearchBar {...props.searchProps} /></div>

                            {
                                (!!this.state.data && this.state.data.length > 0 )
                                    ? (

                                        <BootstrapTable
                                            bootstrap4
                                            striped
                                            {...props.baseProps}
                                            size="sm"
                                            responsive
                                            keyField="id"
                                            data={ this.state.data }
                                            columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
                                            defaultSorted={ defaultSorted }
                                            pagination={ paginationFactory(paginationOptions) }
                                            wrapperClasses="table-responsive"
                                            selectRow={{
                                                mode:"checkbox",
                                                clickToSelect: true,
                                                hideSelectColumn: true,

                                                onSelect: row => {
                                                    this.props.onClose( row.id + " - " + row["Ragione Sociale"] );
                                                }

                                            }}
                                        />

                                    )
                                    : null
                            }

                        </React.Fragment>
                    )}

                </ToolkitProvider>

            </React.Fragment>

            )


        let content = (
            <Modal isOpen={true} toggle={this.toggle} className={"large-modal"}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                   Valutazioni
                </ModalHeader>
                <ModalBody className={this.state.bodyClass} style={{maxHeight: "837px", overflowY: "auto"}}>
                    {context}
                </ModalBody>
            </Modal>
        );

        if (!!this.state.waitData)
        {return contentWait}
        else if( !!this.state.data && this.state.data.length > 0 )
        {return content}
        else if( !!this.state.data && this.state.data.length === 0 )
        {return contentNoData}
        else if( !!this.state.error )
        {return contentError}

    }

    componentDidMount() {
        this.loadData()
    }


    loadData() {
        loadEvaluations(RATING_TYPE.sirating, 0)

            // logs   -----------------------------------------------------------------------------------------------
            .then(list => {
                // showlog("evaluations");
                // showlog( list );
                return list
            })




            // extras ---------------------------------------------------------------------------------------------------
            .then(list => {


                // showlog("lista");
                // showlog(list);

                list =
                    list
                        .map((row, rowIndex) => {

                            // row.sendMailNcQuestions = (
                            // 	<React.Fragment>
                            // 	<i
                            // 	style={{textAlign:'center'}}
                            // 		className={"mdi mdi-email-outline info-icon"}
                            // 		onClick={ clickEvent => {
                            // 			clickEvent.stopPropagation();
                            // 			showlog("modale degli allegati");


                            // 			this.setState({
                            // 				...this.state,
                            // 				sendMailNcQuestions: row.id
                            // 			})

                            // 		}}
                            // 	/>

                            // 	</React.Fragment>


                            // );

                            row.versionComponent = (
                                <div style={{marginLeft: "45px"}}>
                                    {row.version}
                                </div>
                            )




                            return row;
                        })

                return list;
            })

            // field sort   -------------------------------------------------------------------------------------------
            .then(responseData => {
                return (
                    responseData
                        .map(row => {

                            let sortedRow = {};
                            Object.keys(fieldMap)
                                .map(field => {
                                    sortedRow[field] = row[field];
                                })
                            ;
                            Object.assign(sortedRow, row);
                            return sortedRow;

                        })
                );
            })

            // field map ----------------------------------------------------------------------------------------------
            .then(list => {


                return (
                    list
                        .map(company => {
                            let dto = {};
                            Object.keys(company)
                                .forEach(rawField => {
                                    dto[fieldMap[rawField]] = company[rawField];
                                })
                            ;
                            return dto;
                        })
                );

            })

            // filtri -------------------------------------------------------------------------------------------------
            .then(list => {

                // debugger;
                return (
                    list
                    // .filter( rat => rat.status === this.state.activeFilter )
                );

            })

            // finish --------------------------------------------------------------------------------------------------
            .then(data => {
                // showlog("data after field sort");
                // showlog( data );

                // debugger;

                this.setState({
                    ...this.state,
                    waitData: false,
                    data: data
                })
            })

            .catch(e => {
                errorlog("errore", e);
                this.setState({
                    ...this.state,
                    waitData: false,
                    data: [],
                    error: true
                })
            })
    }

}
