import React, {useState} from "react";
import PageTitle from "../../PageTitle";
import {DATE_FORMAT_IT, PATH_PREFIX} from "../../../conf/siroad";
import {translate} from "../../i18n/translate-function";
import SiRatingLogoDarkText from "../../../assets/images/SiRating_logo_dark_text_2.svg";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {errorlog, getLastElementOr} from "../../../utils";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import moment from "moment";
import {getSectorQuestion} from "../../../business-logic/question-sectors";
import {LANGUAGES} from "../../../conf/languages";
import ActionButton from "../../save-button";
import SiratingSectorQuestionLinksModal from "./sirating-sector-question-links-modal";

const {SearchBar} = Search;

const SiratingSectorQuestionLinksPage = props => {

    const [list, setList] = useState(() => {
        return null;
    })
    const [errors, setErrors] = useState();
    const [modal, setModal] = useState();

    let content = (
        <WaitingOrNodataMessage
            waiting={ !(!!list && list.length === 0) }
            fullHeight={ true }
            spinnerColor={"primary"}
            nodataMessage={ translate("NoData") }
            waitingMessag={ translate("Loading") }
        />
    );

    if( !list ) {
        getSectorQuestion()
            .then( sectorQuestions => {
                setList( sectorQuestions );
                setErrors( undefined );
            })
            .catch( e => {
                errorlog("Caricamento Lista domande di settore", e);
                setList([]);
                setErrors([...errors, translate("Error in getting sectorQuestion list")]);
            })
        ;
    }
    else {
        content = (
            <ToolkitProvider
                bootstrap4
                keyField="id"
                search
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                {props => (
                    <React.Fragment>

                        <div className={"search-and-commands-over-table"}>
                            <SearchBar {...props.searchProps} />
                            <ActionButton
                                buttonText={ translate("associa") }
                                promise={ () => {
                                    return (
                                        new Promise((onEnd, onFail) => {
                                            setTimeout(
                                                () => {
                                                    onEnd();
                                                },
                                                2
                                            )
                                        })
                                    )
                                }}
                                onResult={ result => {
                                    setModal( true )
                                }}
                                onError={ error => {

                                }}
                            />
                        </div>

                        <BootstrapTable
                            bootstrap4
                            striped
                            {...props.baseProps}
                            size="sm"
                            responsive
                            keyField={ Object.keys(list.reduce(getLastElementOr, null))[0] }
                            data={
                                list
                                    .map( (sq, index) => {
                                        sq.linked = (
                                            <span
                                                className={"dis-link-text"}
                                                onClick={clickEvent => {
                                                    // TODO
                                                }}
                                            >
                                                { translate("disassocia") }
                                            </span>
                                        );
                                        return sq;
                                    })
                                    .filter( sq => true ) // inutile al momento
                            }
                            columns={
                                ( Object.keys( list.reduce( getLastElementOr, null ) ) )
                                    .map( field => {
                                        return ({
                                            dataField: field,
                                            text: (
                                                {
                                                    name_it: translate("Name"),
                                                    name_en: translate("Name"),
                                                    name_de: translate("Name"),
                                                    name_es: translate("Name"),
                                                    name_fr: translate("Name"),
                                                    // sector_name: translate("Sector"),
                                                    code: translate("Code"),
                                                    linked: translate("disassocia")
                                                }[ field ]
                                                || field
                                            ),
                                            // sort: sortableFields.includes( field )
                                        });
                                    })
                                    // custom date time sort -----------------------------------------------------------------------------------
                                    .map( column => {
                                        if( ["date", "time", "creation"].includes( column.dataField ) ) {
                                            column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                                                if (order === 'asc') {
                                                    return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                                                }
                                                return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                                            }
                                        }
                                        return column;
                                    })
                                    .filter(
                                        ff => ![
                                            "id"
                                            , "alive"
                                            // , "code"
                                            , "transparency"
                                            , "resilience"
                                            , "improvement"
                                            , "ordering"
                                            , "macroarea_id"
                                            , "macroarea_name"
                                            , "thematic_id"
                                            , "thematic_name"
                                            , "subthematic_id"
                                            , "subthematic_name"
                                            , "srSectorId"
                                            , "score"
                                            , "sector_name"
                                            , "isMultipleAnswer"
                                            , "attachmentsGuidelines"
                                            , "questionGuidelines"
                                            , "companySize"
                                            , "companies"
                                            ,
                                                ...(
                                                    LANGUAGES
                                                        .filter( lan => lan.code !== localStorage.getItem("language") )
                                                        .map( lan => `name_${ lan.code.substr(0, 2).toLowerCase() }`)
                                            )
                                        ].includes(ff.dataField)
                                    )
                            }
                            defaultSorted={[
                                {
                                    dataField: 'moment',
                                    order: 'desc',
                                }
                            ]}
                            pagination={
                                paginationFactory({
                                    paginationSize: 5,
                                    pageStartIndex: 1,
                                    firstPageText: translate('First'),
                                    prePageText: translate('backward'),
                                    nextPageText: translate('forward'),
                                    lastPageText: translate('Last'),
                                    nextPageTitle: translate('first page'),
                                    prePageTitle: translate('previous page'),
                                    firstPageTitle: translate('next page'),
                                    lastPageTitle: translate('last page'),
                                    showTotal: true,
                                    paginationTotalRenderer: (from, to, size) => (
                                        <span className="react-bootstrap-table-pagination-total ml-2">
                                            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Sector Questions")}
                                        </span>
                                    ),
                                    sizePerPageList: [
                                        {
                                            text: '25',
                                            value: 25,
                                        },
                                        {
                                            text: '50',
                                            value: 50,
                                        },
                                        {
                                            text: '100',
                                            value: 100,
                                        }
                                    ]
                                })
                            }
                            wrapperClasses="table-responsive"
                            rowClasses={ (row, rowIndex) => {
                                if( !row.alive ) return "disabled-row";
                                return "";
                            }}
                        />

                    </React.Fragment>
                )}
            </ToolkitProvider>
        );
    }


    return (
        <div>


            <PageTitle
                breadCrumbItems={[
                    {
                        label:"SI Rating"
                        , path: PATH_PREFIX +"/welcome" },
                    { label: translate("Sector Questions"), active: true }
                ]}
                title={
                    <React.Fragment>
                        <img
                            src={ SiRatingLogoDarkText }
                            style={{
                                marginRight:'10px',
                                height: "60px"
                            }}
                        />
                    </React.Fragment>
                }
            />

            {
                !!modal
                    ? (
                        <SiratingSectorQuestionLinksModal
                            onClose={ closeReason => {
                                setModal( false );
                            }}
                        />
                    )
                    : null
            }

            { content }

        </div>
    );

}


export default SiratingSectorQuestionLinksPage;
