import React from "react";
import {Component} from "react";
import {
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Button,
    CardHeader,
    CardBody,
    Card,
    NavLink,
    UncontrolledCollapse,
    Col,
    Row,
    Nav,
    NavItem,
    TabContent,
    TabPane, Form, Input
} from 'reactstrap';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {
    loadAllComapnyAllowedDimensions,
    loadAllSectors, loadAllThemes, loadCompaniesData
} from "../../../business-logic/companies";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import {
    addNewQuestionSector,
    deleteSectorQuestion,
    downloadSIRatingRatingFile,
    editNewQuestionSector, loadRatingPdfParameters, saveRatingPdfParameters
} from "../../../business-logic/rating";
import ActionButton from "../../save-button";
import {cloneObject} from "../../../utils/object-utils";
import {getSectorCompanies, loadSectorQuestionsDimensions} from "../../../business-logic/question-sectors";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {
    COLOR_POLICY,
    errorMessage,
    permissions,
    QUESTION_TEXT_COLOR_POLICY,
    SIRATING_TREE_DEEP_LEVELS_NAME
} from "../../../env";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {hasPermission} from "../../../business-logic/auth";
import {loadSdg} from "../../../business-logic/sdg";
import classnames from "classnames";
import {getQuestionFatherTreeNodeByPath} from "../../../business-logic/question-tree-manager";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";

const allRequiredFields = [
    "q_it",
    "q_en",
    "score",
    "selectedSector",
    "selectedThemes"
];

const VOID_PDF_PARAMETERS = {
    text1: "",
    text2: "",
    text3: ""
}

export default class RatingPdfParametersModal extends Component {

    state = {
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose && typeof this.props.onClose === "function" && !!this.state.data ) this.props.onClose();
    }



    render() {


        renderlog("RatingPdfParametersModal", this.state, this.props, COLOR_POLICY.validated);


        let context =
            (!!this.state.data && this.state.data.length > 0)
                ? (
                    <Form>
                        <FormGroup>
                            <Label for={"parameter-a"}>Testo 1</Label>
                            <Input
                                className={"sector-question-text standard-text-area"}
                                type="textarea"
                                row={64}
                                name={"parameter-a"}
                                id={"parameter-a"}
                                onChange={ changeEvent => {
                                    this.setState({
                                        ...this.state,
                                        textA: changeEvent.target.value
                                    })
                                }}
                                defaultValue={ this.state.data.reduce( getLastElementOr, VOID_PDF_PARAMETERS ).text1 }
                                value={ this.state.textA || this.state.data.reduce( getLastElementOr, VOID_PDF_PARAMETERS ).text1 }
                                // required
                                disabled={ false }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={"parameter-b"}>Testo 2</Label>
                            <Input
                                className={"sector-question-text standard-text-area"}
                                type="textarea"
                                row={64}
                                name={"parameter-b"}
                                id={"parameter-b"}
                                onChange={ changeEvent => {
                                    this.setState({
                                        ...this.state,
                                        textB: changeEvent.target.value
                                    })
                                }}
                                defaultValue={ this.state.data.reduce( getLastElementOr, VOID_PDF_PARAMETERS ).text2 }
                                value={ this.state.textB || this.state.data.reduce( getLastElementOr, VOID_PDF_PARAMETERS ).text2 }
                                // required
                                disabled={ false }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={"parameter-c"}>Testo 3</Label>
                            <Input
                                className={"sector-question-text standard-text-area"}
                                type="textarea"
                                row={64}
                                name={"parameter-c"}
                                id={"parameter-c"}
                                onChange={ changeEvent => {
                                    this.setState({
                                        ...this.state,
                                        textC: changeEvent.target.value
                                    })
                                }}
                                defaultValue={ this.state.data.reduce( getLastElementOr, VOID_PDF_PARAMETERS ).text3 }
                                value={ this.state.textC || this.state.data.reduce( getLastElementOr, VOID_PDF_PARAMETERS ).text3 }
                                // required
                                disabled={ false }
                            />
                        </FormGroup>
                    </Form>
                )
                : (
                    <WaitingOrNodataMessage
                        waiting={ !(!!this.state.data && this.state.data.length === 0) }
                        nodataMessage={ emoj(EMOJ.sad_but_relieved_face) +"    Nessun Dato" }
                        className={"bottom-margin-alignment"}
                    />
                )
        ;



        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    PDF
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    { context }
                </ModalBody>
                <ModalFooter>

                    {
                        (!!this.state.data && this.state.data.length > 0)
                            ? (
                                <ActionButton
                                    promise={ () => { return saveRatingPdfParameters( this.props.id, this.state ); }}

                                    onResult={ result => {
                                        downloadSIRatingRatingFile( this.props.id, this.props.name +".pdf" )
                                            .then( result => {
                                                if (!!this.props.onClose && typeof this.props.onClose === "function" ) this.props.onClose();
                                            })
                                            .catch(e => {
                                                errorlog("errore",e);
                                                if (!!this.props.onClose && typeof this.props.onClose === "function" ) this.props.onClose( e );
                                            })
                                    }}
                                    onError={ e => {
                                        errorlog("errore",e);
                                        if (!!this.props.onClose && typeof this.props.onClose === "function" ) this.props.onClose( e );
                                    }}
                                />
                            )
                            : null
                    }

                </ModalFooter>

            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }


    loadData() {

        loadRatingPdfParameters( this.props.id )

            .then( data => {
                this.setState({
                    ...this.state,
                    data: data
                })
            })

            .catch(e => {
                errorlog("errore",e);
            })


    }

}
