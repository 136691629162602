import {
	PreviewAttachmentsQuestions_Get,
	PreviewNcQuestions_Get,
	PreviewNewAttachmentsInserted_Get,
	Score_Get
} from "../services/request-manager"
import { SdgScore_Get } from "../services/request-manager"
import { CriteriaScore_Get } from "../services/request-manager"
import { QuestionReport_Get } from "../services/request-manager"
import { EvaluationStatesReport_Get } from "../services/request-manager"
import { SendNcQuestions_Get } from "../services/request-manager"
import { SendAttachmentsQuestions_Get } from "../services/request-manager"
import { SendNewAttachmentsInserted_Get } from "../services/request-manager"
import { MaterialityReport_Get } from "../services/request-manager"



export const getScore = (SrEvalutationId, MacroareaId) => {
	return Score_Get(SrEvalutationId, MacroareaId);
}

export const getSdgScore = (SrEvalutationId ) => {
	return SdgScore_Get(SrEvalutationId);
}

export const getCriteriaScore = (SrEvalutationId ) => {
	return CriteriaScore_Get(SrEvalutationId);
}
// export const getQuestionReport = (SrEvalutationId ) => {
// 	return QuestionReport_Get(SrEvalutationId);
// }
export const getMaterialityReport = (SrEvalutationId ) => {
	return MaterialityReport_Get(SrEvalutationId);
}
export const getSendNcQuestions = (SrEvalutationId ) => {
	return SendNcQuestions_Get(SrEvalutationId);
}
export const getPreviewNcQuestions = (SrEvalutationId ) => {
	return PreviewNcQuestions_Get(SrEvalutationId);
}

export const getSendAttachmentsQuestions = (SrEvalutationId ) => {
	return SendAttachmentsQuestions_Get(SrEvalutationId);
}
export const getPreviewAttachmentsQuestions = (SrEvalutationId ) => {
	return PreviewAttachmentsQuestions_Get(SrEvalutationId);
}

export const getSendNewAttachmentsInserted = (SrEvalutationId ) => {
	return SendNewAttachmentsInserted_Get(SrEvalutationId);
}
export const getPreviewNewAttachmentsInserted = (SrEvalutationId ) => {
	return PreviewNewAttachmentsInserted_Get(SrEvalutationId);
}

export const getEvalutaionStatesReport = (SrEvalutationId ) => {
	return EvaluationStatesReport_Get(SrEvalutationId);
}

