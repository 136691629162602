import React from "react";
import {Button} from "reactstrap";
import {httpTimer} from "../utils/timer-utils";
import {showlog} from "../utils/dev-utils";
import Spinner from "./Spinner";
import {translate} from "./i18n/translate-function";


/**
 *
 */
export default class ActionButton extends React.Component {

	state = {
		waiting: false
	};

	/**
	 *
	 * @param props
	 */
	constructor( props ) {
		super( props );
	}


	/**
	 *
	 * @returns {JSX.Element|null}
	 */
	render() {

		return (
			(this.props.visible === true || this.props.visible === undefined )
				? (
					<Button
						color={ (!!this.props.color) ? this.props.color : "primary" }
						className={ (!!this.props.className) ? this.props.className : "" }
						onClick={ clickEvent => {

							clickEvent.stopPropagation();
							clickEvent.preventDefault();

							if( !!!this.state.waiting ) {
								if( typeof this.props.onProcess === "function" ) this.props.onProcess();
								this.setState({
										...this.state,
										waiting: true
									},
									() => {
										Promise.race([
											httpTimer.start(),
											this.props.promise()
												.then( result => result )
												.catch(e => Promise.reject(e))
										])
											.then(result => {
												showlog("race finito");
												showlog(result);
												this.setState({
														...this.state,
														waiting: false
													},
													() => {
														if( typeof this.props.onResult === "function" ) this.props.onResult( result );
													})
											})
											.catch(e => {
												console.error("error");
												showlog(e);
												this.setState({
														...this.state,
														waiting: false
													},
													() => {
														if( typeof this.props.onError === "function" ) this.props.onError( e );
													})
											})
										;
									}
								)
							}

						}}
					>
						{
							(!!this.state.waiting)
								? (
									<React.Fragment>
										<Spinner color={"#fffa"} className="spinner-border-sm mr-1" />
										<span>{
											(!!this.props.waitingMessage)
												? String( this.props.waitingMessage )
												: translate("Loading_")
										}</span>
									</React.Fragment>
								)
								: (
									(!!this.props.buttonText)
										? this.props.buttonText
										: "Salva"
								)
						}
					</Button>
				)
				: null
		);

	}

}
