// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import CardBody from "reactstrap/es/CardBody";
import {Alert, Button, Card, Col, Label, Row, UncontrolledTooltip} from "reactstrap";
import {PATH_PREFIX} from "../env";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Spinner from "../components/Spinner";
import {translate} from '../components/i18n/translate-function';
import {errorlog, showlog} from "../utils";
import SupportAttachmentModal from "./support-attachment-modal";
import {support_UploadFile} from "../business-logic/support";


export default class SupportPage extends React.Component {

    state = {
        content: "",
        successMessage: false,
        isButtonShow: false,
        attachedFiles: []
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (



            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: translate("Support"), path: '' + PATH_PREFIX + '/support', active: true }
                    ]}
                    title={translate("Support")}
                />

                <Row>
                    <Col sm="12">
                        <Card className="mb-0" key={"welcome-page"}>
                           {/* /* <CardHeader>
                                <h5 className="m-0">
                                    <div className="custom-accordion-title d-block pt-2 pb-2">
                                        Pagina di supporto
                                    </div>
                                </h5>
                            </CardHeader> */}
                            <CardBody>

                                {
                                    (!!this.state.attachmentModal)
                                        ? (
                                            <SupportAttachmentModal

                                                files = { this.state.attachedFiles }

                                                // questionId={1}
                                                // question={1}
                                                // ratingId={1}
                                                //
                                                // files={ this.state.files || [] }
                                                // disableUpload={false}
                                                // disableDelete={false}
                                                /* onAttachmentUploaded={ attachments => {
                                                            showlog("onattachmentuploade", attachments)
                                                }}
                                                 onAttachmentDelete={ attachmentIds => {
                                                     showlog("onattachmentdelete", attachmentIds)
                                                 }}*/
                                                 onError={ e => {
                                                     errorlog("errore nel caricamento dell'allegato");
                                                    showlog( e );
                                                 }}
                                                 onClose={ files => {


                                                     showlog("files");
                                                     showlog(files);

                                                          this.setState({
                                                         ...this.state,
                                                             attachmentModal: false,
                                                              attachedFiles : files
                                                           }, () => {
                                                              showlog("this.state.files", this.state.attachedFiles)
                                                          })


                                                 }}
                                            />
                                        )
                                        : null
                                }


                                <Row>
                                    <Col sm="6">

                                        {

                                            (!!(this.state.successMessage && this.state.content == ""))
                                                ? null
                                                : (
                                                    <React.Fragment>
                                                        <span className={"support-page-text"}>
                                                            {translate("describe_problem_text")}
                                                        </span>

                                                        <AvForm>

                                                            <AvGroup>
                                                                <Label for="lastName">{translate("Subject")}</Label>
                                                                <AvInput
                                                                    value={this.state.subject}
                                                                    onChange={e => {

                                                                        this.setState({ subject: e.target.value },

                                                                            () => {
                                                                                if ((this.state.subject.length >= 3) && (this.state.content.length >= 3)) {
                                                                                    this.setState({ isButtonShow: true })

                                                                                } else {
                                                                                    this.setState({ isButtonShow: false })

                                                                                }

                                                                            }

                                                                        )





                                                                    }}
                                                                    type="text"
                                                                    name="helpMessageSubject"
                                                                    id="helpMessageSubject"
                                                                    required

                                                                />
                                                            </AvGroup>

                                                            <AvGroup>
                                                                <Label for="lastName">{translate("Description")}</Label>
                                                                <AvInput
                                                                    value={this.state.content}
                                                                    onChange={e => {

                                                                        this.setState({ content: e.target.value },

                                                                            () => {
                                                                                if ((this.state.subject.length >= 3) && (this.state.content.length >= 3)) {
                                                                                    this.setState({ isButtonShow: true })

                                                                                } else {
                                                                                    this.setState({ isButtonShow: false })

                                                                                }

                                                                            }

                                                                        )





                                                                    }}
                                                                    type="textarea"
                                                                    row={32}
                                                                    name="helpMessage"
                                                                    id="helpMessage"
                                                                    required

                                                                />
                                                            </AvGroup>

                                                        </AvForm>
                                                    </React.Fragment>

                                                )



                                        }

                                        <Row>

                                        {

                                            (!!(this.state.successMessage && this.state.content == ""))
                                                ? null
                                                :
                                           ( <i
                                                id={"allegati"}
                                                className={"mdi mdi-attachment sirating-question-attachment-icon cursor-pointer"}
                                                onClick={clickEvent => {
                                                    showlog("modale degli allegati");
                                                    this.setState({
                                                        ...this.state,
                                                        attachmentModal: true
                                                    });
                                                }}
                                            />)

                                        }

                                        {<UncontrolledTooltip placement="top" target={"allegati"}>
                                            <span className="">{translate("Attachments")}</span>
                                            </UncontrolledTooltip>}





                                        {


                                            (!!(this.state.successMessage && this.state.content == ""))
                                                ? null
                                                : (
                                                    <Button
                                                        color="primary"
                                                        onClick={clickEvent => {



                                                            this.setState({
                                                                    ...this.state,
                                                                    waiting: true
                                                                },
                                                                () => {

                                                               let  totalUpload = {
                                                                    "ObjectString": this.state.subject,
                                                                    "BodyString": this.state.content
                                                                }

                                                                this.state.attachedFiles.map( f => {
                                                                    totalUpload["file"] = f
                                                                })


                                                                    Promise.all(

                                                                        [


                                                                                support_UploadFile(totalUpload)
                                                                           /* ,
                                                                            Supportsendemail_Post([this.state.subject +": "+ this.state.content])
*/
                                                                        ]


                                                                    )

                                                                        .then(x => {
                                                                        // debugger
                                                                        this.setState({
                                                                            ...this.state,
                                                                            waiting: null,
                                                                            content: "",
                                                                        }, () => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                successMessage: true

                                                                            });
                                                                        })


                                                                    });



                                                                })
                                                        }}
                                                    >


                                                        {
                                                            (!!this.state.waiting)
                                                                ? (
                                                                    <React.Fragment>
                                                                        <Spinner color={"primary"} className="spinner-border-sm mr-1" />
                                                                        <span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
                                                                    </React.Fragment>
                                                                )
                                                                : translate("Send")
                                                        }
                                                    </Button>
                                                )


                                            // this.state.isButtonShow &&

                                        }
                                        </Row>
                                    </Col>
                                </Row>
                                <br /><br />
                                <Col sm="6">
                                    <Alert color="success" isOpen={this.state.successMessage && this.state.content == ""}>L'email è stata inoltrata correttamente. Verrai ricontattato al più presto da uno dei nostri operatori </Alert>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }


};

