// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import CardBody from "reactstrap/es/CardBody";
import { Button, Card, CardHeader, Col, Label, Row, Alert } from "reactstrap";
import { PATH_PREFIX, TITLES, TITLES_NAME } from "../env";
import { loadCollect } from "../business-logic/gri";
import { Link } from "react-router-dom";
import Select from "../components/area/admin/user-edit-modal";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import Spinner from "../components/Spinner";
import { Supportsendemail_Post } from "../services/request-manager"


export default class SupportPage extends React.Component {

    state = {
        content: "",
        successMessage: false,
        isButtonShow: false
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: 'Pagina di informazioni', path: '' + PATH_PREFIX + '/about', active: true }
                    ]}
                    title={'Informazioni'}
                />

                <Row>
                    <Col sm="12">
                        <Card className="mb-0" key={"welcome-page"}>
                            <CardHeader>
                                <h5 className="m-0">
                                    <div className="custom-accordion-title d-block pt-2 pb-2">
                                        Pagina di informazioni
                                    </div>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <p>Descrizione della piattaforma</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }


};

