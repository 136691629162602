import {
	Evaluations_Get,
	EvaluationsDashboard_Get,
	getSuperAdminRatingDashboard,
	OrganizzationServiceActives_GetAll,
	ServiceActives_Create,
	ServiceActives_Delete,
	ServiceActives_GetAll,
	ServiceActives_Update
} from "./request-manager";
import {showlog} from "../utils/dev-utils";


export const getActiveServices = () => {
	return (ServiceActives_GetAll());
};

export const getOrganizzationActiveServices = () => {
	return OrganizzationServiceActives_GetAll();
};

export const deleteServiceBySuperAdmin = sid => {
	return ServiceActives_Delete( sid );
};

export const getAllServices = () => {
	return (
		Promise.resolve([
			{ id: 1, name: "SR Tool" },
			{ id: 2, name: "Si Rating" },
			{ id: 3, name: "Si Scoring" },
			{ id: 4, name: "Parità di genere" }
		])
	);
};

export const getCompanyAllowedDimension = () => {
	return (
		Promise.resolve([
			// { id: 0, name: "Non definita" },
			{ id: 1, name: "Piccola/Media" },
			{ id: 2, name: "Grande" }
		])
	);
}



export const getEvaluations = (ratingType, isAlive) => {
	return Evaluations_Get(ratingType, isAlive);
}

export const getEvaluationsDashboard = () => {
	return EvaluationsDashboard_Get();
}


export const loadSuperAdminRatingDashboard = () => {
	return getSuperAdminRatingDashboard();
}


export const addNewService = data => {


	showlog("salvataggio nuovo servizio");
	showlog( data );

	return ServiceActives_Create( data );
	// return Promise.resolve({ status: STATUS_SUCCESS });
}


export const updateService = (data, id) => {
	return ServiceActives_Update( data, id );
}



