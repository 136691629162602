import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import QuestionReport from "./area/super-admin/questionReport-modal";

export const RatingLegendModal = props => (
	<Modal isOpen={ true } toggle={ props.onClose }>
		<ModalHeader>Report Stati Domande</ModalHeader>
		<ModalBody className={""}>
			<QuestionReport
				isAudit={true}
				evaluation_id={ props.ratingId }
				NoModal={true}
				data={ props.metadata }
			/>
		</ModalBody>
	</Modal>
);


/*
da history


import React from "react";
import QuestionReport from "./area/super-admin/questionReport-modal";
import {Modal, ModalBody} from "reactstrap";

export const RatingLegendModal = props => (
	<Modal isOpen={ true } toggle={ props.onClose }>
		<ModalBody className={""}>
			<QuestionReport
				isAudit={true}
				evaluation_id={ props.ratingId }
				NoModal={true}
				data={ props.metadata }
				
				
				
				
				
				
				
				
				
				
			<InformativeStateReport
				isAudit={true}
				evaluation_id={ props.ratingId }
				NoModal={true}
				data={ props.metadata }


 */
