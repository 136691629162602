import React, {useEffect, useState} from "react";
import {
	CustomInput,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink, Row,
	TabContent,
	TabPane
} from "reactstrap";
import "easymde/dist/easymde.min.css";

import ActionButton from "./save-button";
import {cloneObject, errorlog, extractErrorMessage, getLastElementOr, renderlog, showlog} from "../utils";
import {
	editAndSaveChoiche,
	editAndSaveQuestion,
	getQuestion,
	loadSiScoringChoiche,
	saveChoiche,
	saveQuestion
} from "../business-logic/rating";
import {WaitingOrNodataMessage} from "./waiting-or-nodata-message";

import Select from "react-select";
import {setFlagToLanguage, translate} from "./i18n/translate-function";
import {LANGUAGES} from "../conf/languages";


export const FullEditQuestionModal = props => {

	const [ question, setQuestion ] =
		useState( () => {
			return null;
		})
	;
	const [ inWait, wait ] = useState( true );
	const [ activeTab, setActiveTab ] = useState( (props.tab || 1) );
	const [ error, setError ] = useState( () => {
		return null;
	} );



	useEffect(() => {
		showlog("ComponentDidMount equivalent");
		showlog(props);

		if( !!props.isChoiche ) {

			if( !!props.question.id ) {

				loadSiScoringChoiche( props.question.id )
					.then( result => {


						if( !!props.isNew ) {
							let emptyObj = {
								score: 0,
								isRequiredTextBox: false,
								isRequiredAttachment: false
							};

							emptyObj.description = {
								langs:
									result.description.langs
										.map( l => {
											let ll = cloneObject( l );
											ll[ l.code.substr(0, 2).toLowerCase() ] = '';
											return ll;
										})
							};
							Object.keys( result.description )
								.forEach( field => {
									if( field !== "langs" ) {
										emptyObj.description[ field ] = "";
									}
								})
							;

							setQuestion( emptyObj );
						}
						else {
							setQuestion( result );
						}


						wait( false );
					})
					.catch(e => {
						errorlog( "get_question", e );
						let em = extractErrorMessage( e );
						setError([...error, em]);
						wait( false );
					})
				;
			}
			else {
				let emptyObj = {
					score: 0,
					isRequiredTextBox: false,
					isRequiredAttachment: false
				};

				emptyObj.description = {
					langs:
						LANGUAGES
							.map( l => {
								let ll = cloneObject( l );
								ll[ l.code.substr(0, 2).toLowerCase() ] = '';
								return ll;
							})
							.map( lang => setFlagToLanguage( lang ) )
				};

				LANGUAGES
					.forEach( l => {
						emptyObj.description[ l.code.substr(0, 2).toLowerCase() ] = '';
					})
				;


				setQuestion( emptyObj );
				wait( false );
			}


		}
		else {

			getQuestion( props.question.id, props.isSR )
				.then( result => {

					if( !!props.isNew ) {

						let emptyObj = {
							code: '',
							companySize: 0,
							alive: true,
							enabled: true,
							id: 0,
							isMultipleAnswer: false,
							transparency: false,
							improvement: false,
							resilience: false,
							gendergap: false,
							score: 0,
							sdgList:
								result.sdgList
									.map( sdg => {
										sdg.enable = false;
										return sdg;
									})
							,
							srMacroareaId: result.srMacroareaId,
							srSubThematicId: 0,
							srThematicId: 0,
							thematicsQuestionList:
								result.thematicsQuestionList
									.map( th => {
										th.isBelongThematic = false;
										th.isBelongSubThematic = false;
										return th;
									})
							,
						};

						emptyObj.description = {
							langs:
								result.description.langs
									.map( l => {
										let ll = cloneObject( l );
										ll[ l.code.substr(0, 2).toLowerCase() ] = '';
										return ll;
									})
						};
						Object.keys( result.description )
							.forEach( field => {
								if( field !== "langs" ) {
									emptyObj.description[ field ] = "";
								}
							})
						;

						emptyObj.guidelines = {
							langs:
								result.guidelines.langs
									.map( l => {
										let ll = cloneObject( l );
										ll[ l.code.substr(0, 2).toLowerCase() ] = '';
										return ll;
									})
						};
						Object.keys( result.guidelines )
							.forEach( field => {
								if( field !== "langs" ) {
									emptyObj.guidelines[ field ] = "";
								}
							})
						;

						setQuestion( emptyObj );
					}
					else {
						setQuestion( result );
					}

					wait( false );
				})
				.catch(e => {
					errorlog( "get_question", e );
					let em = extractErrorMessage( e );
					setError([...error, em]);
					wait( false );
				})

		}


		return () => {
			// showlog("ComponentDidMount equivalent RETURN");
			return null;
		};
	}, []);



	useEffect(() => {

		setError( null );

		return () => {
			// showlog("ComponentDidMount equivalent RETURN");
			return null;
		};
	}, [question]);




	renderlog("EditQuestionModal", {activeTab, question, wait}, props);



	return (
		<Modal isOpen={ true } toggle={ () => props.onClose() }>
			<ModalHeader className={"question-edit-modal-header-container"}>
				<span>{ translate("Edit") }</span>

				<div>
					<ActionButton
						color={"link"}
						buttonText={translate("Cancel")}
						promise={ () => {
							return Promise.resolve();
						}}
						onResult={  toggleEvent => props.onClose(  ) }
					/>
					<ActionButton
						buttonText={translate("Save")}
						promise={ () => {
							wait( true );
							if( !!props.isChoiche ) {
								if( !!props.isNew ) {
									return saveChoiche( props.fatherQuestion.id, question );
								}
								return editAndSaveChoiche( props.question.id, question );
							}
							else {
								if( !!props.isNew ) {
									if( !question.score ) {
										return Promise.reject( translate("cannot_insert_zero_score") );
									}
									else {

										if( !question.code ) {
											return Promise.reject( translate("code_is_needed") );
										}
										else {

											// tutti i campi di testo descrizione sono vuoti
											if(
												Object.keys(question.description)
													.filter( field => !["langs"].includes( field ) )
													.map( field => question.description[ field ] )
													.every( lan => !lan )
											) {
												return Promise.reject( translate("text_is_empty") );
											}
											else {
												setError( null );

												LANGUAGES.map( lang => {

													if( question.description[lang.code.split("-")[0].toLowerCase()] === null )
													{
														question.description[lang.code.split("-")[0].toLowerCase()] = ""
													}

												}
												)

												LANGUAGES.map( lang => {

														if( question.guidelines[lang.code.split("-")[0].toLowerCase()] === null )
														{
															question.guidelines[lang.code.split("-")[0].toLowerCase()] = ""
														}

													}
												)

												// if(question.description["de"]===null){
												// 	question.description["de"]=""
												// }
												// if(question.description["en"]===null){
												// 	question.description["en"]=""
												// }
												// if(question.description["es"]===null)
												// {
												// 	question.description["es"]=""
												// }
												// if(question.description["it"]===null){
												// 	question.description["it"]=""
												// }
												//
												// if(question.guidelines["de"]===null){
												// 	question.guidelines["de"]=""
												// }
												// if(question.guidelines["en"]===null){
												// 	question.guidelines["en"]=""
												// }
												// if(question.guidelines["es"]===null)
												// {
												// 	question.guidelines["es"]=""
												// }
												// if(question.guidelines["it"]===null){
												// 	question.guidelines["it"]=""
												// }

												return saveQuestion( question );
											}
										}

									}
								}
								setError( null );

								LANGUAGES.map( lang => {

										if( question.description[lang.code.split("-")[0].toLowerCase()] === null )
										{
											question.description[lang.code.split("-")[0].toLowerCase()] = ""
										}

									}
								)

								LANGUAGES.map( lang => {

										if( question.guidelines[lang.code.split("-")[0].toLowerCase()] === null )
										{
											question.guidelines[lang.code.split("-")[0].toLowerCase()] = ""
										}

									}
								)

								// if(question.description["de"]===null){
								// 	question.description["de"]=""
								// }
								// if(question.description["en"]===null){
								// 	question.description["en"]=""
								// }
								// if(question.description["es"]===null)
								// {
								// 	question.description["es"]=""
								// }
								// if(question.description["it"]===null){
								// 	question.description["it"]=""
								// }
								//
								// if(question.guidelines["de"]===null){
								// 	question.guidelines["de"]=""
								// }
								// if(question.guidelines["en"]===null){
								// 	question.guidelines["en"]=""
								// }
								// if(question.guidelines["es"]===null)
								// {
								// 	question.guidelines["es"]=""
								// }
								// if(question.guidelines["it"]===null){
								// 	question.guidelines["it"]=""
								// }

								return editAndSaveQuestion( question );
							}
						}}
						onResult={ result => {
							// wait( false );
							props.onClose( true );
						}}
						onError={ err => {
							errorlog("Salvataggio edit domanda", err);
							setError( extractErrorMessage( err ) );
							wait( false );
						}}
						className={'srtool-edit-modal-button'}
					/>
				</div>

			</ModalHeader>
			<ModalBody>


				{
					!question
						? (
							<div>
								<WaitingOrNodataMessage
									waiting={ true }
									waitMessage={ translate("waiting") }
									className={"single-circle-waiting edit-question-in-wait new-edit-question"}
								/>
							</div>
						)
						: (
							<React.Fragment>
								<Nav tabs>

									<NavItem key={`texts`}>
										<NavLink
											id={"texts"}
											className={
												(( activeTab === 'texts') ? " tab-active" : "")
												+ " edit-srtool-tab"
											}
											onClick={() => {
												setActiveTab( "texts" );

												setError( null );

											}}
										>
											<span className="d-none d-lg-block">{ translate("texts") }</span>
										</NavLink>
									</NavItem>

									{
										!!question.guidelines
											? (
												<NavItem key={`glTexts`}>
													<NavLink
														id={"glTexts"}
														className={
															(( activeTab === 'glTexts') ? " tab-active" : "")
															+ " edit-srtool-tab"
														}
														onClick={() => {
															setActiveTab( "glTexts" );
															setError( null );
														}}
													>
														<span className="d-none d-lg-block">{ translate("Guide_linees") }</span>
													</NavLink>
												</NavItem>
											)
											: null
									}


									<NavItem key={`settings`}>
										<NavLink
											id={"settings"}
											className={
												(( activeTab === 'settings') ? " tab-active" : "")
												+ " edit-srtool-tab"
											}
											onClick={() => {
												setActiveTab( "settings" );
												setError( null );
											}}
										>
											<span className="d-none d-lg-block">{ translate("settings") }</span>
										</NavLink>
									</NavItem>


									{
										!!question.sdgList
											? (
												<NavItem key={`sdg`}>
													<NavLink
														id={"sdg"}
														className={
															(( activeTab === 'sdg') ? " tab-active" : "")
															+ " edit-srtool-tab"
														}
														onClick={() => {
															setActiveTab( "sdg" );
															setError( null );
														}}
													>
														<span className="d-none d-lg-block">SDG</span>
													</NavLink>
												</NavItem>
											)
											: null
									}


												<NavItem key={`KPI`}>
													<NavLink
														id={"KPI"}
														className={
															(( activeTab === 'KPI') ? " tab-active" : "")
															+ " edit-srtool-tab"
														}
														onClick={() => {
															setActiveTab( "KPI" );

															setError( null );

														}}
													>
														<span className="d-none d-lg-block">KPI</span>
													</NavLink>
												</NavItem>
								</Nav>


								{
									!!inWait
										? null
										: (
											<TabContent activeTab={activeTab}>

												<TabPane
													tabId={"aTexts"}
													key={1}
												>

													Testi

												</TabPane>

												<TabPane
													tabId={"texts"}
													key={2}
												>

													<div className={"tab-pane-content"}>
														{
															question.description.langs

																// ATTENZIONE temporaneo
																.filter( lang => {
																	if( !!props.isSR ) {
																		if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
																		if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
																		return true;
																	}
																	return true;
																})

																.map( (lang, lanIndex) => {
																	return (
																		<div className={"lan-block"} key={ lanIndex }>
																			<div className={"lan-header"}>
																				<div className={"lan-flag-container"}>
																					<img
																						src={ lang.flag }
																						alt={ lang.label }
																					/>
																				</div>
																				<div className={"lan-name"}>{ lang.label }</div>
																			</div>
																			<div className={"lan-editor-container"}>
																				<Input
																					type="textarea"
																					row={ 64 }
																					name={"note-" + props.question.id}
																					id={"note-" + props.question.id}
																					placeholder={translate("Add a note")}
																					className={"lan-editor"}
																					defaultValue={ lang[lang.code.substr(0, 2).toLowerCase()] }
																					onChange={ newText => {

																						let clonedQuestion = cloneObject( question );
																						clonedQuestion.description[ lang.code.substr(0, 2).toLowerCase() ] = newText.target.value;
																						setQuestion( clonedQuestion );

																					}}
																				/>
																				{/*
																				<SimpleMDE
																					value={ lang[lang.code.substr(0, 2).toLowerCase()] }
																					className={"lan-editor"}
																					onChange={ newText => {

																						let clonedQuestion = cloneObject( question );
																						clonedQuestion.description[ lang.code.substr(0, 2).toLowerCase() ] = newText;
																						setQuestion( clonedQuestion );

																					}}
																					options={{
																						toolbar: [],
																						autofocus: true,
																						spellChecker: false
																					}}

																				/>
																				*/}
																			</div>
																		</div>
																	);

																})
														}
													</div>

												</TabPane>




												{
													!!question.guidelines
														? (
															<TabPane
																tabId={"glTexts"}
																key={3}
															>

																<div className={"tab-pane-content"}>
																	{
																		question.guidelines.langs

																			// ATTENZIONE temporaneo
																			.filter( lang => {
																				if( !!props.isSR ) {
																					if( lang.code.substr(0, 2).toLowerCase() === "de" ) return false;
																					if( lang.code.substr(0, 2).toLowerCase() === "es" ) return false;
																					return true;
																				}
																				return true;
																			})


																			.map( (lang, lanIndex) => {
																				return (
																					<div className={"lan-block"} key={ lanIndex }>
																						<div className={"lan-header"}>
																							<div className={"lan-flag-container"}>
																								<img
																									src={ lang.flag }
																									alt={ lang.label }
																								/>
																							</div>
																							<div className={"lan-name"}>{ lang.label }</div>
																						</div>
																						<div className={"lan-editor-container"}>


																							<Input
																								type="textarea"
																								row={ 32 }
																								name={"note-" + props.question.id}
																								id={"note-" + props.question.id}
																								placeholder={translate("Add a note")}
																								className={"lan-editor"}
																								defaultValue={ lang[lang.code.substr(0, 2).toLowerCase()] }
																								onChange={ newText => {

																									let clonedQuestion = cloneObject( question );
																									clonedQuestion.guidelines[ lang.code.substr(0, 2).toLowerCase() ] = newText.target.value;
																									setQuestion( clonedQuestion );

																								}}
																							/>

																							{/*
																							<SimpleMDE
																								value={ lang[lang.code.substr(0, 2).toLowerCase()] }
																								className={"lan-editor"}
																								onChange={ newText => {

																									let clonedQuestion = cloneObject( question );
																									clonedQuestion.guidelines[ lang.code.substr(0, 2).toLowerCase() ] = newText;
																									setQuestion( clonedQuestion );

																								}}
																								options={{
																									toolbar: [],
																									autofocus: true,
																									spellChecker: false
																								}}

																							/>
																							*/}
																						</div>
																					</div>
																				);

																			})
																	}
																</div>

															</TabPane>
														)
														: null
												}



												<TabPane
													tabId={"settings"}
													key={2}
												>

													<div className={"tab-pane-content tab-pane-row"}>

														<div className={"tab-pane-left"}>


															<div>
																<div>{ translate("Score") }</div>
																<div>
																	<Input
																		id={`score`}
																		name={`score`}
																		className={"single-open-answer-value"}
																		type="number"
																		defaultValue={ question.scoreMax || question.score || 0 }
																		onChange={ changeTextEvent => {

																			let clonedQuestion = cloneObject( question );
																			clonedQuestion.score = changeTextEvent.target.value;
																			setQuestion( clonedQuestion );


																		}}
																		required
																	/>
																</div>
															</div>


															{
																!props.isChoiche
																	? (
																		<React.Fragment>
																			<div>
																				<div>{ translate("Code") }</div>
																				<div>
																					<Input
																						id={`code`}
																						name={`code`}
																						className={"single-open-answer-value"}
																						type="text"
																						defaultValue={ question.code }
																						onChange={ changeTextEvent => {
																							showlog("cambio di codice");
																							showlog( changeTextEvent );

																							let clonedQuestion = cloneObject( question );
																							clonedQuestion.code = changeTextEvent.target.value;
																							setQuestion( clonedQuestion );


																						}}
																						required
																					/>
																				</div>
																			</div>



																						<div>
																							<div>{ translate("Topic-editquestion") }</div>
																							<div>
																								<Select
																									className="react-select gri-type-select tab-pane-row-select"
																									classNamePrefix="react-select"
																									name="thematiche-select"
																									id="thematiche-select"
																									value={
																										question.thematicsQuestionList
																											.map( th => ({
																												...th,
																												value: (!!th.subthematic_id ? th.subthematic_id : th.thematic_id),
																												label: (!!th.subthematic_name ? th.subthematic_name : th.thematic_name)
																											}))
																											.filter( th => ((th.subthematic_id === question.srSubthematicId) && (th.thematic_id === question.srThematicId)))
																											.reduce( getLastElementOr, null )
																									}
																									onChange={ changeEvent => {
																										showlog("selezione");
																										showlog( changeEvent );


																										let cloned = cloneObject( question );

																										// non serve più
																										let clonedThList = cloned.thematicsQuestionList;
																										clonedThList =
																											clonedThList
																												.map( cth => {
																													cth.isBelongSubthematic = false;
																													cth.isBelongThematic = false;
																													return cth;
																												})
																										;

																										cloned.srThematicId = changeEvent.thematic_id;
																										cloned.srSubthematicId = changeEvent.subthematic_id;

																										setQuestion( cloned );

																									}}
																									options={
																										question.thematicsQuestionList
																											.map( th => ({
																												...th,
																												value: (th.subthematic_id || th.thematic_id),
																												label: (th.subthematic_name || th.thematic_name)
																											}))
																									}
																									placeholder={ <span className={"select-placeholder"}>{ translate("Thematics") }</span> }
																								/>
																							</div>
																						</div>


																			{
																				!!question.companySize
																					? (
																						<div>
																							<div>{ translate("companySize") }</div>
																							<div>
																								<Select
																									className="react-select gri-type-select tab-pane-row-select"
																									classNamePrefix="react-select"
																									name="csize-select"
																									id="csize-select"
																									onChange={ changeEvent => {
																										showlog("selezione");
																										showlog( changeEvent );

																									}}
																									options={[]}
																									placeholder={ <span className={"select-placeholder"}>{ translate("companySize") }</span> }
																								/>
																							</div>
																						</div>
																					)
																					: null
																			}

																		</React.Fragment>
																	)
																	: null
															}



														</div>


														{
															!props.isChoiche
																? (
																	<div>
																		<div>
																			<div>{ translate("Enabled") }</div>
																			<div>
																				<CustomInput
																					type="switch"
																					id={"enabled"}
																					name={"enabled"}
																					label={""}
																					className={"pmAuthorizationToggler"}
																					onClick={clickEvent => {

																						let clonedQuestion = cloneObject( question );
																						clonedQuestion.enabled = !clonedQuestion.enabled;
																						clonedQuestion.alive = !clonedQuestion.alive;
																						setQuestion( clonedQuestion );

																					}}
																					checked={ !!question.enabled }
																					defaultChecked={ !!question.enabled }
																					valid={ !!question.enabled }
																				/>
																			</div>
																		</div>

																		{/*<div>*/}
																		{/*	<div>{ translate("Multiple_Answer") }</div>*/}
																		{/*	<div>*/}
																		{/*		<CustomInput*/}
																		{/*			type="switch"*/}
																		{/*			id={"isMultipleAnswer"}*/}
																		{/*			name={"isMultipleAnswer"}*/}
																		{/*			label={""}*/}
																		{/*			className={"pmAuthorizationToggler"}*/}
																		{/*			onClick={clickEvent => {*/}

																		{/*				let clonedQuestion = cloneObject( question );*/}
																		{/*				clonedQuestion.isMultipleAnswer = !clonedQuestion.isMultipleAnswer;*/}
																		{/*				setQuestion( clonedQuestion );*/}

																		{/*			}}*/}
																		{/*			checked={ !!question.isMultipleAnswer }*/}
																		{/*			defaultChecked={ !!question.isMultipleAnswer }*/}
																		{/*			valid={ !!question.isMultipleAnswer }*/}
																		{/*		/>*/}
																		{/*	</div>*/}
																		{/*</div>*/}

																		{/*<div>*/}
																		{/*	<div>{ translate("Transparency") }</div>*/}
																		{/*	<div>*/}
																		{/*		<CustomInput*/}
																		{/*			type="switch"*/}
																		{/*			id={"Transparency"}*/}
																		{/*			name={"Transparency"}*/}
																		{/*			label={""}*/}
																		{/*			className={"pmAuthorizationToggler"}*/}
																		{/*			onClick={clickEvent => {*/}

																		{/*				let clonedQuestion = cloneObject( question );*/}
																		{/*				clonedQuestion.transparency = !clonedQuestion.transparency;*/}
																		{/*				setQuestion( clonedQuestion );*/}

																		{/*			}}*/}
																		{/*			checked={ !!question.transparency }*/}
																		{/*			defaultChecked={ !!question.transparency }*/}
																		{/*			valid={ !!question.transparency }*/}
																		{/*		/>*/}
																		{/*	</div>*/}
																		{/*</div>*/}

																		{/*<div>*/}
																		{/*	<div>{ translate("Resilience") }</div>*/}
																		{/*	<div>*/}
																		{/*		<CustomInput*/}
																		{/*			type="switch"*/}
																		{/*			id={"Resilience"}*/}
																		{/*			name={"Resilience"}*/}
																		{/*			label={""}*/}
																		{/*			className={"pmAuthorizationToggler"}*/}
																		{/*			onClick={clickEvent => {*/}

																		{/*				let clonedQuestion = cloneObject( question );*/}
																		{/*				clonedQuestion.resilience = !clonedQuestion.resilience;*/}
																		{/*				setQuestion( clonedQuestion );*/}

																		{/*			}}*/}
																		{/*			checked={ !!question.resilience }*/}
																		{/*			defaultChecked={ !!question.resilience }*/}
																		{/*			valid={ !!question.resilience }*/}
																		{/*		/>*/}
																		{/*	</div>*/}
																		{/*</div>*/}

																		{/*<div>*/}
																		{/*	<div>{ translate("Improvement") }</div>*/}
																		{/*	<div>*/}
																		{/*		<CustomInput*/}
																		{/*			type="switch"*/}
																		{/*			id={"Improvement"}*/}
																		{/*			name={"Improvement"}*/}
																		{/*			label={""}*/}
																		{/*			className={"pmAuthorizationToggler"}*/}
																		{/*			onClick={clickEvent => {*/}

																		{/*				let clonedQuestion = cloneObject( question );*/}
																		{/*				clonedQuestion.improvement = !clonedQuestion.improvement;*/}
																		{/*				setQuestion( clonedQuestion );*/}

																		{/*			}}*/}
																		{/*			checked={ !!question.improvement }*/}
																		{/*			defaultChecked={ !!question.improvement }*/}
																		{/*			valid={ !!question.improvement }*/}
																		{/*		/>*/}
																		{/*	</div>*/}
																		{/*</div>*/}

																	</div>
																)
																: null
														}








													</div>

												</TabPane>



												{
													!!question.sdgList
														? (
															<TabPane
																tabId={"sdg"}
																key={3}
															>

																<div className={"tab-pane-content tab-pane-row"}>

																	<div>
																		{
																			question.sdgList
																				.filter( (sdg, sgdIndex) => sgdIndex < 9 )
																				.map( row => (
																					<div>
																						<div>{ row.id + "-" + row.name }</div>
																						<div>
																							<CustomInput
																								type="switch"
																								id={`row-${ row.id }`}
																								name={`row-${ row.id }`}
																								label={""}
																								className={"pmAuthorizationToggler"}
																								onClick={clickEvent => {

																									let clonedQuestion = cloneObject( question );
																									let clonedCurrentSdg =
																										clonedQuestion.sdgList
																											.filter( sdg => sdg.id === row.id )
																											.reduce( getLastElementOr, null )
																									;
																									clonedCurrentSdg.enable = !clonedCurrentSdg.enable;
																									setQuestion( clonedQuestion );

																								}}
																								checked={ !!row.enable }
																								defaultChecked={ !!row.enable }
																								valid={ !!row.enable }
																							/>
																						</div>
																					</div>
																				))
																		}
																	</div>

																	<div>
																		{
																			question.sdgList
																				.filter( (sdg, sgdIndex) => sgdIndex > 8 )
																				.map( row => (
																					<div>
																						<div>{ row.id + "-" + row.name }</div>
																						<div>
																							<CustomInput
																								type="switch"
																								id={`row-${ row.id }`}
																								name={`row-${ row.id }`}
																								label={""}
																								className={"pmAuthorizationToggler"}
																								onClick={clickEvent => {
																									let clonedQuestion = cloneObject( question );
																									let clonedCurrentSdg =
																										clonedQuestion.sdgList
																											.filter( sdg => sdg.id === row.id )
																											.reduce( getLastElementOr, null )
																									;
																									clonedCurrentSdg.enable = !clonedCurrentSdg.enable;
																									setQuestion( clonedQuestion );
																								}}
																								checked={ !!row.enable }
																								defaultChecked={ !!row.enable }
																								valid={ !!row.enable }
																							/>
																						</div>
																					</div>
																				))
																		}
																	</div>

																</div>

															</TabPane>
														)
														: null
												}


															<TabPane
																tabId={"KPI"}
																key={3}
															>
																<div className={"tab-pane-content"}>

																	<Row>
																		<div className={"kpi-content-width"}>- {translate("TRANSPARENCY")} </div>
																		<div><CustomInput
																				type="switch"
																				id={"row-transparency"}
																				name={"row-transparency"}
																				//label={""}
																				className={"pmAuthorizationToggler"}
																				onClick={clickEvent => {

																					let clonedQuestion = cloneObject( question );
																					clonedQuestion.transparency = !clonedQuestion.transparency;
																					setQuestion( clonedQuestion );

																				}}
																				checked={ !!question.transparency }
																				defaultChecked={ !!question.transparency }
																				valid={ !!question.transparency }
																			/>
																		</div>
																	</Row>

																	<Row>
																		<div className={"kpi-content-width"}>- {translate("RESILIENCE")}</div>
																		<div><CustomInput
																				type="switch"
																				id={"row-resilience"}
																				name={"row-resilience"}
																				label={""}
																				className={"pmAuthorizationToggler"}
																				onClick={clickEvent => {

																					let clonedQuestion = cloneObject( question );
																					clonedQuestion.resilience = !clonedQuestion.resilience;
																					setQuestion( clonedQuestion );

																				}}
																				checked={ !!question.resilience }
																				defaultChecked={ !!question.resilience }
																				valid={ !!question.resilience }
																			/>
																		</div>
																	</Row>

																	<Row>
																		<div className={"kpi-content-width"}>- {translate("IMPROVEMENT")}</div>
																		<div><CustomInput
																				type="switch"
																				id={"row-improvement"}
																				name={"row-improvement"}
																				label={""}
																				className={"pmAuthorizationToggler"}
																				onClick={clickEvent => {

																					let clonedQuestion = cloneObject( question );
																					clonedQuestion.improvement = !clonedQuestion.improvement;
																					setQuestion( clonedQuestion );

																				}}
																				checked={ !!question.improvement }
																				defaultChecked={ !!question.improvement }
																				valid={ !!question.improvement }
																			/>
																		</div>
																	</Row>

																	<Row>
																		<div className={"kpi-content-width"}>- GENDER GAP</div>
																		<div><CustomInput
																				type="switch"
																				id={"row-gendergap"}
																				name={"row-gendergap"}
																				label={""}
																				className={"pmAuthorizationToggler"}
																				onClick={clickEvent => {

																					let clonedQuestion = cloneObject( question );
																					clonedQuestion.gendergap = !clonedQuestion.gendergap;
																					setQuestion( clonedQuestion );

																				}}
																				checked={ !!question.gendergap }
																				defaultChecked={ !!question.gendergap }
																				valid={ !!question.gendergap }
																			/>
																		</div>
																	</Row>

																</div>
															</TabPane>
											</TabContent>
										)
								}

								{
									!!inWait
										? (
											<WaitingOrNodataMessage
												waiting={ true }
												waitMessage={" "}
												className={"single-circle-waiting edit-question-in-wait new-edit-question"}
											/>
										)
										: null
								}
							</React.Fragment>
						)
				}


			</ModalBody>
			{
				!!error
					? (
						<ModalFooter className={"edit-question-modal-footer"}>
							<span className={"error-message-label"}>{ error }</span>
						</ModalFooter>
					)
					: null
			}
		</Modal>
	);
}

