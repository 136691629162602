import React, { Component } from "react";
import PageTitle from "../../../components/PageTitle";
import {hasPermission} from "../../../business-logic/auth";
import {Button, Card, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink} from "reactstrap";
import CardBody from "reactstrap/es/CardBody";
import {role, permissions, PATH_PREFIX, COLOR_POLICY} from "../../../env";
import CompanyServicePage from "../super-admin/company-service-page";
import {getLastElementOr, renderlog} from "../../../utils";
import {translate} from "../../../components/i18n/translate-function";
// import OrganizzationServicePage from "./organizzation-service-page";


export default class AdminActiveService extends Component {

	state = {
		activeTab: "companies"
	}

	constructor(props) {
		super( props );

	}


	render() {

		renderlog("AdminActiveService", this.state, this.props, COLOR_POLICY.warning);

		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						/*{
							label:
								Object.keys( role )
									.map( roleObjectName => role[ roleObjectName ] )
									.filter( roleObject => roleObject.name === localStorage.getItem("role") )
									.reduce( getLastElementOr, "Nessun Ruolo" )
									.label,
							path: PATH_PREFIX +"/welcome"
						}, */
						{ label: "Servizi Attivi", active: true }
					]}
					title={translate("Services")}
				/>



					<Card>
						<CardBody>

							<Row>
								<Col>
									<div className="page-title-box">
										<h4 className="page-title">{translate("Services List")}</h4>
									</div>
								</Col>
							</Row>

							<CompanyServicePage/>

							{/*
								!!hasPermission(permissions.READ_ORGANIZATION_ACTIVE_SERVICES)
									? (
										<React.Fragment>
											<Nav tabs>

												<NavItem key={"companies"}>
													<NavLink
														id={ "companies-nav-tab" }
														href="#"
														className={ (this.state.activeTab === "companies") ? "active-tab" : "" }
														onClick={() => {
															this.setState({activeTab: "companies"});
														}}
													>
														<span className="d-none d-lg-block">Aziende</span>
													</NavLink>
												</NavItem>


												<NavItem key={"organizzations"}>
													<NavLink
														id={ "organizzations-nav-tab" }
														href="#"
														className={ (this.state.activeTab === "organizzations") ? "active-tab" : "" }
														onClick={() => {
															this.setState({activeTab: "organizzations"});
														}}
													>
														<span className="d-none d-lg-block">Organizzazioni</span>
													</NavLink>
												</NavItem>


											</Nav>


											<TabContent activeTab={this.state.activeTab}>

												<TabPane
													tabId={"companies"}
													key={"companies"}
													className={"custom-tab-pane"}
												>
													<CompanyServicePage/>
												</TabPane>

												<TabPane
													tabId={"organizzations"}
													key={"organizzations"}
													className={"custom-tab-pane"}
												>
													<OrganizzationServicePage/>
												</TabPane>


											</TabContent>
										</React.Fragment>

									)
									: <CompanyServicePage/>
							*/}


						</CardBody>
					</Card>


			</React.Fragment>
		);
	}







}
