import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import React from "react";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {COLOR_POLICY, ITALIAN_NAMES_MONTHS} from "../../../env";
import {
	loadMonthlyData,
	loadVirtualTreeNodeValue,
	saveTreeNodeValue,
	saveVirtualTreeNodeValue
} from "../../../business-logic/gri";
import "../../../style/index.css";
import {isNotEmptyArray} from "../../../utils/array-utils";
import {errorlog, mountedlog, renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../save-button";
import {isNumber} from "../../../utils/codes-utils";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {translate} from "../../i18n/translate-function";


export default class TreeDataEntryValueModalComponent extends React.Component {

	state = {
		strId:
			(!!this.props && !!this.props.id && this.props.id.split("-").length > 2)
				? this.props.id.split("-")[2]
				: 0
		,
		m:
			Array( this.props.node.value.length  )
				.map( (sm, i) => ({active: true, value: sm}))
		,
		ready: false
	};

	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );
	}

	toggle = () => {
		this.props.onClose();
	}

	render() {

		renderlog(
			"TreeDataEntryValueModalComponent",
			this.state,
			this.props
		);



		let context = (
			// (isNotEmptyArray(this.state.m))
			(!!this.state.ready)
				? (
					<AvForm>


						{
							this.state.m
								.map( (month, mIndex) => {
									month.name = ITALIAN_NAMES_MONTHS[ (parseInt( this.props.collect.Inizio.split("/")[1], 10) + mIndex -1) % ITALIAN_NAMES_MONTHS.length ];
									return month;
								})
								.map( (month, mIndex) => {
									return (
										<AvGroup key={mIndex}>
											<Label for={ this.props.id +"-m"+ mIndex } className={"tree-data-entry-value-label tree-data-entry-value-label-12-m"}>{ month.name }</Label>
											<AvInput
												// type={ !!this.props.node.measureUnit.isValueString ? "text" : "number" }
												type={ "number" }
												name={ this.props.id +"-m"+ mIndex }
												id={ this.props.id +"-m"+ mIndex }
												value={
													!!month.active
														? (""+ month.value)
														: ""
												}
												className={"tree-data-entry-value-input"}
												onChange={ changeTextEvent => {

													if(
														isNumber( changeTextEvent.target.value ) &&
														changeTextEvent.target.value.indexOf("e") === -1 &&
														changeTextEvent.target.value.indexOf("E") === -1
													) {


														this.setState({
															...this.state,
															m:
																[
																	...this.state.m.filter((_,i) => i < mIndex),
																	{active: true, value: parseInt(changeTextEvent.target.value, 10)},
																	...this.state.m.filter((_,i) => i > mIndex)
																]
														})

													}
													else {
														this.setState({
															...this.state,
															m:
																[
																	...this.state.m.filter((_,i) => i < mIndex),
																	{active: false, value: 0},
																	...this.state.m.filter((_,i) => i > mIndex)
																]
														})
													}


												}}
												disabled={ this.props.isDisabled }
											/>
										</AvGroup>
									);
								})
						}


					</AvForm>
				)
				: (
					<WaitingOrNodataMessage
						waiting={ true }
						spinnerColor={"success"}
						waitingMessage={"in caricamento..."}
						nodataMessage={"C'è qualche problema"}
					/>
				)
		);




		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{translate("Entering Monthly Values")}
					{
						// "virtualNode_"+ req.id +"."+ req.structureId +"-"+ node.id +"_"+ node.griSubrequirementId
						// virtualNode_2034.9422-19_35
						// type_fatherId.fatherStructureId-areaId_virtualNodeId
						( this.props.id.indexOf("virtualNode_") !== -1 )
							? (
								// " per il sotto requisito #"+ this.props.id.split("_")[2] +" dell'area "+ this.props.id.split("_")[1].split("-")[1]
								" per il sotto requisito "
							)
							: null
					}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>

					{
						!!this.props.isDisabled
							? null
							: (
								<ActionButton
									buttonText={translate("Save")}
									promise={ () => {
										return (
											new Promise((success, fail) => {
												// if( this.state.m.filter(m => !!m).length > 0 ) {

												// debugger;

												if( this.props.id.indexOf("virtualNode_") === -1 ) {

													saveTreeNodeValue(
														// this.props.id.split("-")[2],
														this.state.strId,
														null,
														null,
														this.state.m, // .filter(m => typeof m !== "undefined"),
														// !!this.props.node.measureUnit.isValueString
														false
													)
														.then( () => {
															// success( this.props.onConfirm() );
															this.props.onConfirm()
																.then( () => {
																	success();
																})
															;
														})
														.catch(e => {
															errorlog("error", e);
															fail( e );
														})

												}
												else {
													// nodi virtuali
													let structureId = this.props.id.split(".")[1].split("-")[0];
													let virtualNodeId = this.props.id.split("_")[2];
													let areaId = this.props.id.split("-")[1].split("_")[0];

													debugger;


													saveVirtualTreeNodeValue( this.state.m, structureId, virtualNodeId, areaId )
														.then( () => {
															success( this.props.onConfirm() );
														})
														.catch(e => {
															errorlog("error", e);
															fail( e );
														})
												}

												// }
											})
										);
									}}
									onProcess={ () => {

									}}
									onResult={ result => {
										showlog("finished");
										// debugger;
									}}
									onError={ error => {
										errorlog( "E", error );
										this.props.onConfirm()
									}}
								/>
							)
					}


					<Button color="link" onClick={this.toggle}>
						{ translate("Close") }
					</Button>
				</ModalFooter>
			</Modal>


		);

		return content;
	}

	componentDidMount() {

		let areaId = (!!this.props.id ) ? this.props.id.split("-")[1].split("_")[0] : null;
		let virtualNodeId = (!!this.props.id ) ? this.props.id.split("_")[2] : null;
		let reqId = (!!this.props.id ) ? this.props.id.split(".")[0].split("_")[1] : null;
		let structureId = (!!this.props.id && !!this.props.id.split(".")[1]) ? this.props.id.split(".")[1].split("-")[0] : null;

		// console.log("presa dei valori");
		// console.log( this.props.id );


		// debugger;

		mountedlog("TreeDataEntryValueModalComponent", this.state, this.props, COLOR_POLICY.select);


		if( !!structureId ) {
			// relativo alle ripetizioni
			// console.log( reqId, structureId, areaId, virtualNodeId );
		}
		else {
			// senza ripetizioni
			// this.props.id: 7-req-10242
		}



		if( !!structureId ) {
			// subRequirementId, fatherStructureId, areaId
			loadVirtualTreeNodeValue( /*reqId*/virtualNodeId, structureId, areaId, virtualNodeId  )
				.then( result => {
					// console.log("risultato chiamata presa dei valori");
					// console.log( result );


					// debugger;

					if( !!isNotEmptyArray( result ) ) {

						this.setState({
							...this.state,
							m:
								result
									.filter( (v, i) => i > 0 )
									.map( (m, i) => ({
										active: m.isValueActive,
										value: m.valueDecimal
									})),
							ready: true

						})

					}


				})
				.catch(e => {
					errorlog("errore", e);
				})


		}
		else {

			loadMonthlyData( this.state.strId )
				.then( data => {
					showlog(data);
					// debugger;
					this.setState({
						...this.state,
						m:
							data
								// .filter( (v, i) => i > 0 )
								.map( (m, i) => ({
									active: m.isValueActive,
									value: m.valueDecimal
								})),
						ready: true
					})
				})
				.catch(e => {
					errorlog("GET Dati mensili", e);
				})
		}


	}


};
