import React, {useEffect, useState} from "react";
import {loadCollect, loadGriStructure} from "../../../business-logic/gri";
import {
    cloneObject,
    errorlog,
    extractErrorMessage,
    getLastElementOr,
    renderlog,
    showlog,
    sortById, sortByName
} from "../../../utils";
import {convertTableToTree, getTreeNodeByPath} from "../../../business-logic/tree-manager";
import {PATH_PREFIX, TABS} from "../../../conf/siroad";
import {translate} from "../../../components/i18n/translate-function";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import SRToolLogoDarkText from "../../../assets/images/Sr_tool_logo_dark_text_2.svg";
import {SRToolInfoBox} from "../../../components/srtool-info-box";
import PageTitle from "../../../components/PageTitle";
import {
    Card,
    CardBody,
    CardHeader,
    Collapse, Modal, ModalBody, ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import "../../../style/sr-tool-edit-page.css";
import SrtoolEditNodesModal from "../../../components/srtool-edit-nodes-modal";
import CompilationRequirementsIcon from "../../../assets/images/CompilazioneRequisiti.png";
import Raccomandations from "../../../assets/images/Recommendations.png";
import ActionButton from "../../../components/save-button";
import {archiveRating} from "../../../business-logic/rating";
import ConfirmSaveModal from "../../../components/confirm-save-modal";
import VersionModal from "../../../components/area/super-admin/version-modal";


const toggleNodeOfTree = (node, tree) => {
    let treeTmp = cloneObject( tree );
    let nodeRef = getTreeNodeByPath( treeTmp, node.path );
    nodeRef.open = !nodeRef.open;
    return treeTmp;
}


const SrToolEditPage = props => {

    const [tree, setTree] = useState();
    const [activeTab, setActiveTab] =
        useState( () => {
            return TABS.general.id;
        })
    ;
    const [collectId, setCollectId] = useState();
    const [error, setError] = useState(() => []);
    const [popupNode, setPopupNode] = useState();
    const [popupGlNode, setPopupNodeGL] = useState();
    const [popupRaccomandationNode, setPopupNodeRaccomandation] = useState();
    const [popupCompilationRequirementsNode, setPopupNodeCompilationRequirements] = useState();
    const [successEditPopUp, setSuccessEditPopUp] = useState()
    const [versionModal, setVersionModal] = useState()

    useEffect(() => {

        if( error.length === 0 && !tree ) {

            new Promise((success, fail) => {
                if( !!collectId ) success( collectId );
                else {
                    loadCollect()
                        .then( collects => {

                            let collectID =
                                collects
                                    .filter(collect => collect.version === 1)
                                    .reduce(getLastElementOr, {id: 0})
                                    .id
                            ;
                            success( collectID );

                        })
                        .catch(e4 => {
                            errorlog("errore e4", e4);
                            fail(e4);
                        })
                }
            })

            .then( collectID => {

                loadGriStructure( collectID, activeTab )
                    .then(resultObj => {

                        convertTableToTree(resultObj)
                            .then(newTree => {

                                newTree.tabs =
                                    [
                                        ...newTree.tabs,
                                        ...Object.keys(TABS)
                                            .map(field => TABS[ field ])
                                            .filter( tab => tab.id !== newTree.tabs[0].id )
                                    ]
                                    .sort( sortById )
                                ;

                                newTree.tabs.map( tab => {

                                        tab.chapters.map(  chapters => {

                                            chapters.infos.map( infos => {

                                                infos.requirements.sort( sortById )

                                            })
                                        })
                                })

                                setTree( newTree );
                                setError([]);
                                setCollectId( collectID );

                            })
                            .catch(e2 => {
                                errorlog( "errore e2", e2 );
                                throw e2;
                            })
                    })
                    .catch(e1 => {
                        errorlog( "errore e1", e1 );
                        throw e1;
                    })

                })
                .catch(e => {
                    errorlog( "errore e", e );
                    let em = extractErrorMessage( e );
                    setError([...error, em]);
                })
            ;

        }
        else {
            if( error.length > 0 ){}
            if( !!tree ){}
        }

        return () => {
            return null;
        };
    }, [error, activeTab]);



    useEffect(() => {
        return () => {
            return null;
        };
    }, [tree]);


    let context = (
        <WaitingOrNodataMessage
            waiting={ !tree }
            waitMessage={ translate("waiting") }
            fullHeight={ true }
            nodataMessage={ "" /*translate("NoData")*/ }
            spinnerColor={"primary"}
        />
    );

    if( !!tree ){
        context = (
            <TabContent activeTab={activeTab}>
                {
                    tree.tabs
                        .map((tab, index) => (
                            <TabPane
                                tabId={tab.id}
                                key={index}
                            >
                                {
                                    !!tab.chapters
                                        ? (
                                            tab.chapters
                                                .map( ch => (
                                                    <Card className={"srtool-edit-page-card"}>
                                                        <CardHeader className={"srtool-edit-page-card-header" + (!!ch.isEdited ? " srtool-node-edited" : "")}
                                                            onClick={ clickEvent => {
                                                                let refreshedTree = toggleNodeOfTree( ch, tree );
                                                                setTree( refreshedTree );
                                                            }}
                                                        >

                                                            {/*
                                                            <pre>{beautify({
                                                                id: ch.id,
                                                                isEdited: ch.isEdited,
                                                                isGLEdited: ch.isGLEdited,
                                                                isRaccomandationEdited: ch.isRaccomandationEdited,
                                                                isCompilationRequirementsdited: ch.isCompilationRequirementsdited2
                                                            })}</pre>
                                                            */}

                                                            { ch.name }

                                                            <div>
                                                                <i
                                                                    className="mdi mdi-pencil tooltip-icon-cursor"
                                                                    style={{ color: `${ !!ch.isEdited ? "#0acf97" : "" }` }}
                                                                    onClick={ clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        setPopupNode( ch );
                                                                    }}
                                                                />
                                                                <i
                                                                    className="mdi mdi-information-outline tooltip-icon-cursor"
                                                                    style={{ color: `${ !!ch.isGLEdited ? "#0acf97" : "" }` }}
                                                                    onClick={ clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        setPopupNodeGL( ch );
                                                                    }}
                                                                />
                                                                {/* raccomandation */}
                                                                {
                                                                    (!!ch.infos && ch.infos.length > 0)
                                                                        ? <i className={ !!ch.open ? "dripicons-chevron-down" : "dripicons-chevron-right" }/>
                                                                        : <i className={ "dripicons-dot semi-visible" }/>
                                                                }
                                                            </div>
                                                        </CardHeader>
                                                        <Collapse isOpen={ !!ch.open }>
                                                            <CardBody>
                                                                {
                                                                    ch.infos
                                                                        .map( info => (
                                                                            <Card className={"srtool-edit-page-card"}>
                                                                                <CardHeader className={"srtool-edit-page-card-header" + (!!info.isEdited ? " srtool-node-edited" : "")}
                                                                                    onClick={ clickEvent => {
                                                                                        let refreshedTree = toggleNodeOfTree( info, tree );
                                                                                        setTree( refreshedTree );
                                                                                    }}
                                                                                >
                                                                                    { info.name +" "+ info.description }
                                                                                    <div>
                                                                                        <i
                                                                                            className={"mdi mdi-pencil tooltip-icon-cursor"}
                                                                                            style={{ color: `${ !!info.isEdited ? "#0acf97" : "" }` }}
                                                                                            onClick={ clickEvent => {
                                                                                                clickEvent.stopPropagation();
                                                                                                setPopupNode({ ...info, name: info.description });
                                                                                            }}
                                                                                        />
                                                                                        <i
                                                                                            className="mdi mdi-information-outline tooltip-icon-cursor"
                                                                                            style={{ color: `${ !!info.isGLEdited ? "#0acf97" : "" }` }}
                                                                                            onClick={ clickEvent => {
                                                                                                clickEvent.stopPropagation();
                                                                                                setPopupNodeGL( info );
                                                                                            }}
                                                                                        />



                                                                                        <img
                                                                                            src={ Raccomandations }
                                                                                            className={"tooltip-icon-cursor icon-in-srtool-edit" +(!!info.isRaccomandationEdited ? " edit" : "")}
                                                                                            onClick={ clickEvent => {
                                                                                                clickEvent.stopPropagation();
                                                                                                setPopupNodeRaccomandation( info );
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            !!info.isRaccomandationEdited
                                                                                                ? <div className={"icon-edit-in-srtool-editing"}/>
                                                                                                : null
                                                                                        }

                                                                                        {/*
                                                                                        <i
                                                                                            className="dripicons-align-justify tooltip-icon-cursor"
                                                                                            onClick={ clickEvent => {
                                                                                                clickEvent.stopPropagation();
                                                                                                setPopupNodeRaccomandation( info );
                                                                                            }}
                                                                                        />
                                                                                        */}
                                                                                        {
                                                                                            (!!info.requirements && info.requirements.length > 0)
                                                                                                ? <i className={ !!info.open ? "dripicons-chevron-down" : "dripicons-chevron-right" }/>
                                                                                                : <i className={ "dripicons-dot semi-visible" }/>
                                                                                        }
                                                                                    </div>
                                                                                </CardHeader>
                                                                                <Collapse isOpen={ !!info.open }>
                                                                                    <CardBody>
                                                                                        {
                                                                                            info.requirements
                                                                                                .map( req => (
                                                                                                    <Card className={"srtool-edit-page-card"}>
                                                                                                        <CardHeader className={"srtool-edit-page-card-header" + (!!req.isEdited ? " srtool-node-edited" : "")}
                                                                                                            onClick={ clickEvent => {
                                                                                                                let refreshedTree = toggleNodeOfTree( req, tree );
                                                                                                                setTree( refreshedTree );
                                                                                                            }}
                                                                                                        >
                                                                                                            { req.name }
                                                                                                            <div>
                                                                                                                <i
                                                                                                                    className="mdi mdi-pencil tooltip-icon-cursor"
                                                                                                                    style={{ color: `${ !!req.isEdited ? "#0acf97" : "" }` }}
                                                                                                                    onClick={ clickEvent => {
                                                                                                                        clickEvent.stopPropagation();
                                                                                                                        setPopupNode( req );
                                                                                                                    }}
                                                                                                                />
                                                                                                                <i
                                                                                                                    className="mdi mdi-information-outline tooltip-icon-cursor"
                                                                                                                    style={{ color: `${ !!req.isGLEdited ? "#0acf97" : "" }` }}
                                                                                                                    onClick={ clickEvent => {
                                                                                                                        clickEvent.stopPropagation();
                                                                                                                        setPopupNodeGL( req );
                                                                                                                    }}
                                                                                                                />
                                                                                                                {/* raccomandation */}
                                                                                                                <img
                                                                                                                    src={ CompilationRequirementsIcon }
                                                                                                                    className={"tooltip-icon-cursor icon-in-srtool-edit" +(!!req.isCompilationRequirementsdited ? " edit" : "")}
                                                                                                                    onClick={ clickEvent => {
                                                                                                                        clickEvent.stopPropagation();
                                                                                                                        setPopupNodeCompilationRequirements( req );
                                                                                                                    }}
                                                                                                                />
                                                                                                                {
                                                                                                                    !!req.isCompilationRequirementsdited
                                                                                                                        ? <div className={"icon-edit-in-srtool-editing"}/>
                                                                                                                        : null
                                                                                                                }

                                                                                                                {/*}
                                                                                                                <i
                                                                                                                    className="dripicons-align-justify tooltip-icon-cursor"
                                                                                                                    onClick={ clickEvent => {
                                                                                                                        clickEvent.stopPropagation();
                                                                                                                        setPopupNodeCompilationRequirements( req );
                                                                                                                    }}
                                                                                                                />
                                                                                                                */}
                                                                                                                {
                                                                                                                    (!!req.subReqs && req.subReqs.length > 0)
                                                                                                                        ? <i className={ !!req.open ? "dripicons-chevron-down" : "dripicons-chevron-right" }/>
                                                                                                                        : <i className={ "dripicons-dot semi-visible" }/>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </CardHeader>
                                                                                                        {
                                                                                                            (!!req.subReqs && req.subReqs.length > 0)
                                                                                                                ? (
                                                                                                                    <Collapse isOpen={ !!req.open }>
                                                                                                                        <CardBody>
                                                                                                                            {
                                                                                                                                req.subReqs
                                                                                                                                    .map( sub => (
                                                                                                                                        <Card className={"srtool-edit-page-card"}>
                                                                                                                                            <CardHeader className={"srtool-edit-page-card-header" + (!!sub.isEdited ? " srtool-node-edited" : "")}
                                                                                                                                                onClick={ clickEvent => {
                                                                                                                                                    let refreshedTree = toggleNodeOfTree( sub, tree );
                                                                                                                                                    setTree( refreshedTree );
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                { sub.name }
                                                                                                                                                <div>
                                                                                                                                                    <i
                                                                                                                                                        className="mdi mdi-pencil tooltip-icon-cursor"
                                                                                                                                                        style={{ color: `${ !!sub.isEdited ? "#0acf97" : "" }` }}
                                                                                                                                                        onClick={ clickEvent => {
                                                                                                                                                            clickEvent.stopPropagation();
                                                                                                                                                            setPopupNode( sub );
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                    <i
                                                                                                                                                        className="mdi mdi-information-outline tooltip-icon-cursor"
                                                                                                                                                        style={{ color: `${ !!sub.isGLEdited ? "#0acf97" : "" }` }}
                                                                                                                                                        onClick={ clickEvent => {
                                                                                                                                                            clickEvent.stopPropagation();
                                                                                                                                                            setPopupNodeGL( sub );
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                    {/* raccomandation */}
                                                                                                                                                    <img
                                                                                                                                                        src={ CompilationRequirementsIcon }
                                                                                                                                                        className={"tooltip-icon-cursor icon-in-srtool-edit" +(!!sub.isCompilationRequirementsdited ? " edit" : "")}
                                                                                                                                                        onClick={ clickEvent => {
                                                                                                                                                            clickEvent.stopPropagation();
                                                                                                                                                            setPopupNodeCompilationRequirements( sub );
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                    {
                                                                                                                                                        !!sub.isCompilationRequirementsdited
                                                                                                                                                            ? <div className={"icon-edit-in-srtool-editing"}/>
                                                                                                                                                            : null
                                                                                                                                                    }
                                                                                                                                                    {/*}
                                                                                                                                                    <i
                                                                                                                                                        className="dripicons-align-justify tooltip-icon-cursor"
                                                                                                                                                        onClick={ clickEvent => {
                                                                                                                                                            clickEvent.stopPropagation();
                                                                                                                                                            setPopupNodeCompilationRequirements( sub );
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                    */}
                                                                                                                                                    {
                                                                                                                                                        (!!sub.subSubReqs && sub.subSubReqs.length > 0)
                                                                                                                                                            ? <i className={ !!sub.open ? "dripicons-chevron-down" : "dripicons-chevron-right" }/>
                                                                                                                                                            : <i className={ "dripicons-dot semi-visible" }/>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </CardHeader>
                                                                                                                                            {
                                                                                                                                                (!!sub.subSubReqs && sub.subSubReqs.length > 0)
                                                                                                                                                    ? (
                                                                                                                                                        <Collapse isOpen={ !!sub.open }>
                                                                                                                                                            <CardBody>
                                                                                                                                                                {
                                                                                                                                                                    sub.subSubReqs
                                                                                                                                                                        .map( ssr => (
                                                                                                                                                                            <Card className={"srtool-edit-page-card"}>
                                                                                                                                                                                <CardHeader className={"srtool-edit-page-card-header" + (!!ssr.isEdited ? " srtool-node-edited" : "")}>
                                                                                                                                                                                    { ssr.name }
                                                                                                                                                                                    <div>
                                                                                                                                                                                        <i
                                                                                                                                                                                            className="mdi mdi-pencil tooltip-icon-cursor"
                                                                                                                                                                                            style={{ color: `${ !!ssr.isEdited ? "#0acf97" : "" }` }}
                                                                                                                                                                                            onClick={ clickEvent => {
                                                                                                                                                                                                clickEvent.stopPropagation();
                                                                                                                                                                                                setPopupNode( ssr );
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                        <i
                                                                                                                                                                                            className="mdi mdi-information-outline tooltip-icon-cursor"
                                                                                                                                                                                            style={{ color: `${ !!ssr.isGLEdited ? "#0acf97" : "" }` }}
                                                                                                                                                                                            onClick={ clickEvent => {
                                                                                                                                                                                                clickEvent.stopPropagation();
                                                                                                                                                                                                setPopupNodeGL( ssr );
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                        {/* raccomandation */}
                                                                                                                                                                                        <img
                                                                                                                                                                                            src={ CompilationRequirementsIcon }
                                                                                                                                                                                            className={"tooltip-icon-cursor icon-in-srtool-edit" +(!!ssr.isCompilationRequirementsdited ? " edit" : "")}
                                                                                                                                                                                            onClick={ clickEvent => {
                                                                                                                                                                                                clickEvent.stopPropagation();
                                                                                                                                                                                                setPopupNodeCompilationRequirements( ssr );
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                        {
                                                                                                                                                                                            !!ssr.isCompilationRequirementsdited
                                                                                                                                                                                                ? <div className={"icon-edit-in-srtool-editing"}/>
                                                                                                                                                                                                : null
                                                                                                                                                                                        }
                                                                                                                                                                                        {/*}
                                                                                                                                                                                        <i
                                                                                                                                                                                            className="dripicons-align-justify tooltip-icon-cursor"
                                                                                                                                                                                            onClick={ clickEvent => {
                                                                                                                                                                                                clickEvent.stopPropagation();
                                                                                                                                                                                                setPopupNodeCompilationRequirements( ssr );
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                        */}
                                                                                                                                                                                    </div>
                                                                                                                                                                                </CardHeader>
                                                                                                                                                                            </Card>
                                                                                                                                                                        ))
                                                                                                                                                                }
                                                                                                                                                            </CardBody>
                                                                                                                                                        </Collapse>
                                                                                                                                                    )
                                                                                                                                                    : null
                                                                                                                                            }
                                                                                                                                        </Card>
                                                                                                                                    ))
                                                                                                                            }
                                                                                                                        </CardBody>
                                                                                                                    </Collapse>
                                                                                                                )
                                                                                                                : null
                                                                                                        }

                                                                                                    </Card>
                                                                                                ))
                                                                                        }
                                                                                    </CardBody>
                                                                                </Collapse>
                                                                            </Card>
                                                                        ))
                                                                }
                                                            </CardBody>
                                                        </Collapse>
                                                    </Card>
                                                ))
                                        )
                                        : null
                                }
                            </TabPane>
                        ))
                }
            </TabContent>
        );
    }




    let content = (
        <div>
            <PageTitle
                breadCrumbItems={[]}
                title={
                    <React.Fragment>
                        <img
                            src={ SRToolLogoDarkText }
                            style={{
                                marginRight:'10px',
                                height: "60px",
                                float: "left",
                                marginTop: "10px"
                            }}
                        />

                        <p style={{float:"left", marginTop: "27px",
                            marginBottom: "-23px",
                            fontSize: "14px",cursor:"pointer",height:"0px"}}
                           onClick={clickEvent => {
                               setVersionModal(true)
                           }
                           }> v. {(localStorage.getItem("srToolVersion")).trim()} </p>


                        <SRToolInfoBox
                            className={"srtool-info-box"}
                        />
                    </React.Fragment>
                }
            />

            <div>
                <Nav tabs>

                    {
                        (!!tree && !!tree.tabs)
                            ? (
                                tree.tabs
                                    .map(tab => (
                                        <NavItem key={`key_tab-${ tab.id }`}>
                                            <NavLink
                                                id={tab.id}
                                                href="#"
                                                className={
                                                    Object.keys(TABS)
                                                        .map( tabKey => TABS[tabKey] )
                                                        .filter( t => t.id === tab.id )
                                                        .reduce( getLastElementOr, { className: "" } )
                                                        .className
                                                    + (( activeTab === tab.id) ? " tab-active" : "")
                                                    + " edit-srtool-tab"
                                                }
                                                onClick={() => {
                                                    setActiveTab( tab.id );
                                                    // setTree({ tabs: Object.keys(TABS).map(f => TABS[ f ]) });
                                                    setTree();
                                                }}
                                            >
                                                <span className="d-none d-lg-block">{tab.name}</span>
                                                {
                                                    ( activeTab === tab.id)
                                                        ? (
                                                            <i
                                                                className="mdi mdi-pencil tooltip-icon-cursor"
                                                                onClick={ clickEvent => {
                                                                    clickEvent.stopPropagation();
                                                                    setPopupNode( tab );
                                                                }}
                                                            />
                                                        )
                                                        : null
                                                }
                                            </NavLink>
                                        </NavItem>
                                    ))
                            )
                            : null /*translate("NoData")*/
                    }

                </Nav>
                { context }
                {
                    (!!popupNode || !!popupGlNode || !!popupRaccomandationNode || !!popupCompilationRequirementsNode)
                        ? (
                            <SrtoolEditNodesModal
                                node={ popupNode || popupGlNode || popupRaccomandationNode || popupCompilationRequirementsNode }
                                isGL={ !!popupGlNode }
                                isRaccomandation={ !!popupRaccomandationNode }
                                isCompilationRequirements={ !!popupCompilationRequirementsNode }
                                onClose={ editedData => {

                                    /*
                                    al momento al salvatagiio di qualcosa che no sia
                                    il testo della domanda la variabile popupNode e' undefined
                                     */



                                    if( !!editedData ) {
                                        // chiusura non da x o da taso ESC o da cick fuori modale o da tasto annulla
                                        // chiusura dopo salvataggio
                                        let clonedTree = cloneObject( tree );
                                        let currentNode = popupNode || popupGlNode || popupRaccomandationNode || popupCompilationRequirementsNode;

                                        let currentLangCode = localStorage.getItem("language") || "IT-it";
                                        let lanObj =
                                            editedData
                                                .filter( l => l.code === currentLangCode )
                                                .reduce( getLastElementOr, null )
                                        ;
                                        let nodeRef = getTreeNodeByPath( clonedTree, currentNode.path );
                                        nodeRef.isEdited = !!popupNode;
                                        nodeRef.isGLEdited = !!popupGlNode;
                                        nodeRef.isRaccomandationEdited = !!popupRaccomandationNode;
                                        nodeRef.isCompilationRequirementsdited = !!popupCompilationRequirementsNode;


                                        if( !!popupNode /*|| !!popupGlNode || !!popupRaccomandationNode || !!popupCompilationRequirementsNode*/ ) {

                                            if( nodeRef.path.length === 3 ) {
                                                nodeRef.description = lanObj[currentLangCode.substr(0, 2).toLowerCase()];
                                            }
                                            else {
                                                nodeRef.name = lanObj[currentLangCode.substr(0, 2).toLowerCase()];
                                            }


                                        }

                                        setTree( clonedTree );
                                        setSuccessEditPopUp(true)
                                    }


                                    setPopupNode( undefined );
                                    setPopupNodeGL( undefined );
                                    setPopupNodeRaccomandation( undefined );
                                    setPopupNodeCompilationRequirements( undefined );

                                    // if( !!editedData) {
                                    //     setSuccessEditPopUp(true)
                                    // }

                                }}
                            />
                        )
                        : null
                }


                {
                    (!!successEditPopUp)
                    ?
                        (

                            <ConfirmSaveModal
                            onClose={closeEvent => {
                                setSuccessEditPopUp(false)
                            }}
                            />

                        )
                        :
                        null
                }


                {
                    !!versionModal
                        ? (
                            <VersionModal
                                tool={"SR Tool-1"}
                                onClose={ closeEvent => {
                                   setVersionModal(false)
                                }}/>
                        )
                        :
                        null
                }

            </div>
        </div>
    );

    renderlog("SrToolEditPage", {tree, collectId, activeTab, error}, props);
    return content;

}

export default SrToolEditPage;
