// GriRequirementGoalModal
import React, {Component} from 'react';
import {COLOR_POLICY, MAX_UPLOADING_FILE_SIZE, permissions} from "../../../env";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
// import "../modal.css";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import FileUploader from "../../../components/FileUploader";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {sha256} from "js-sha256";
import {getMaterialClassIconByFileName, truncateFileName} from "../../../utils/string-utils";
import Spinner from "../../../components/Spinner";
import {
	downloadSIRatingAdminQuestionAnswerAttachment,
	removeSIRatingAdminQuestionAnswerAttachment,
	uploadAttachmentProcessing
} from "../../../business-logic/rating";
import {hasPermission} from "../../../business-logic/auth";
import {getLastElementOr, onlyNotNull} from "../../../utils/array-utils";
import {translate} from "../../../components/i18n/translate-function";


const fieldMap = {
	preview: " ",
	name: "File",
	formattedSize: "Dimensione"
};


const hiddenFields = [
	"id",
	"toPreview",
	"inWait",
	"serverId",
	"originalName",
	"formattedSize",
	"Dimensione",
	"fingerprint"
];

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} File
        </span>
	),
	sizePerPageList: [5], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'name',
		order: 'asc',
	},
];


const sortableFields = [
	"File",
	"Dimensione"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}



export default class SiRatingQuestionAttachmentModal extends Component {

	state = {
		showMessageSuccess:false,
		showMessageError:false,
		selected: [],
		files: this.props.files || [],
		data:
			(!!this.props.files)
				? (
					this.props.files
						.map( f => ({
							id: this.setFileId({name: f.attachment_name, formattedSize: "---KB"}),
							serverId: f.attachment_id,
							preview: <i className={ getMaterialClassIconByFileName( f.attachment_name ) + " sirating-question-attachment-modal-icon" } />,
							name: <span className={"sirating-question-attachment-modal-text"}>{ truncateFileName( f.attachment_name ) }</span>,
							originalName: f.attachment_name,
							formattedSize: <span className={"sirating-question-attachment-modal-text"}>---</span>
						}))
						.filter( onlyNotNull )
						.sort((x, y) => {
							if( x.id < y.id ) return -1;
							return 1;
						})
						.map( row => {
							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							Object.assign(sortedRow, row);
							return sortedRow;
						})
				)
				: []
	}


	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );
		this.setFileId      = this.setFileId.bind( this );
		this.uploadFiles    = this.uploadFiles.bind( this );
	}

	toggle = () => {
		this.props.onClose();
	}


	setFileId( f ) {
		return (truncateFileName( f.name ) +"@"+ f.lastModified +"#"+ f.formattedSize).split(" ").join("-").split(".").join("_")
	}



	uploadFiles( fileList ) {

		return (new Promise((resolve, reject) => {

			showlog("vado a caricare i files");
			showlog( fileList );
			showlog( this.state );

			// debugger
			let fileToUpload = [];
			fileList
				.forEach( f => {

					fileToUpload.push(
						this.state.data
							.filter( fileData => fileData.id === this.setFileId( f ) )
							.map( fileData => (
									Object.assign(
										{},
										fileData,
										{
											inWait: true,
											toPreview: fileData.preview,
											preview:  <span className={"sirating-question-attachment-modal-text"}><Spinner color={"primary"} className="spinner-border-sm mr-1" /></span>
										}
									)
								)
							)
							.reduce( getLastElementOr, null)
					);

				})
			;

			let refreshed =
				[
					...this.state.data
						.filter( fileData => !fileToUpload.some( ftu => ftu.id === fileData.id ) )
					,
					...fileToUpload
				]
				.filter( onlyNotNull )
				.sort((x, y) => {
					if( x.id < y.id ) return -1;
					return 1;
				})
			;


			showlog("file to upload");
			showlog(fileToUpload);
			showlog("files gia presenti");
			showlog(
				this.state.data
					.filter( fileData => !fileToUpload.some( ftu => ftu.id === fileData.id ) )
			);
			showlog("files");
			showlog(refreshed);

			this.setState({
				...this.state,
				data: refreshed
			},

				() => {

					// i file presi dalla lista fileToUpload
					let fs =
						fileList
							.filter( file => fileToUpload.some( ftu => ftu.id === this.setFileId( file ) ) )
					;

					showlog("file concreti da inviare");
					showlog( fs );
					showlog(this.props.ratingId, this.props.questionId);



					uploadAttachmentProcessing( fs, this.props.ratingId, this.props.questionId )
						.then( list => {
							showlog("ritorno in UI");
							showlog(list);

							if( list.every( result => !!result.status ) ) {


								let uploaded = [];
								fs
									.forEach( (f, findex) => {

										uploaded.push(
											this.state.data
												.filter( fileData => fileData.id === this.setFileId( f ) )
												.map( fileData => (
														Object.assign(
															{},
															fileData,
															{
																preview: fileData.toPreview,
																serverId: parseInt(list[ findex ].message, 10)
															}
														)
													)
												)
												.reduce( getLastElementOr, null)
										);


									})
								;

								let refreshed =
									[
										...this.state.data
											.filter( fileData => !uploaded.some( ftu => ftu.id === fileData.id ) )
										,
										...uploaded
									]
										.filter( onlyNotNull )
										.sort((x, y) => {
											if( x.id < y.id ) return -1;
											return 1;
										})
								;


								showlog("STOP 2", refreshed);

								this.setState({
									...this.state,
									data: refreshed
								},

									() => {

										if( typeof this.props.onAttachmentUploaded === "function" ) {
											this.props.onAttachmentUploaded(
												list.map( (sid, sindex) => ({
														attachment_id: parseInt(sid.message, 10),
														attachment_name: fs[ sindex ].name
													})
												)
											);

										}
										resolve();

									}


									)



							}
							else {
								errorlog("errore nel ritorno in UI", list );
								if( typeof this.props.onError === "function" ) this.props.onError( list.map( result => result.message ).filter( onlyNotNull ).join(",") );
								reject( list );
							}

						})
						.catch(e => {
							showlog("eccezione nel ritorno in UI");
							showlog(e);
							reject( e );
						})

					;




				}


			);
		})



	);
	}


	render() {

		renderlog("SiRatingQuestionAttachmentModal", this.state, this.props, COLOR_POLICY.validated)

		let context = (

			<React.Fragment>

				<Row>
					<Col>
						{
							this.state.showMessageSuccess &&
							(
								<div className="alert alert-success" role="alert">
									<strong>Allegato inserito con successo</strong>
								</div>
							)
						}
						{
							this.state.showMessageError &&
							(
								<div className="danger alert-danger" role="alert">
									<strong>Errore nell'inserimento dell'allegato</strong>
								</div>
							)
						}
						<Card>
							<CardBody>
								<h4 className="header-title mb-3">{translate("Attachment management")}</h4>

								{
									(!!hasPermission( permissions.QUESTION_ATTACHMENT_UPLOAD ) && !this.props.disableUpload)
										? (

											<React.Fragment>

												{
													(!!this.props.question.attachmentsGuidelines)
														? (
															<span>
																<strong>Linea Guida per la gestione dell'allegato:</strong><br/><br/>
																<span>
																	{
																		this.props.question.attachmentsGuidelines
																	}
																</span>
															</span>
														)
														: null
												}

												<FileUploader
													showPreview={ false }
													onFileUpload={files => {



														files =
															files

																// file entro una certa dimensione
																.filter( f => f.size < MAX_UPLOADING_FILE_SIZE )


																.filter( f => !(
																	this.state.data
																		.filter( fd => fd.id === this.setFileId( f ) )
																		.reduce( getLastElementOr, null )
																))

														;

														showlog("files");
														showlog(files);
														showlog(files.map( f => f.size));
														showlog(files.map( f => f.formattedSize));

														this.setState({
																...this.state,
																//showMessageSuccess:true,
																files: [
																	...this.state.files,
																	...files
																],
																data: [
																	...this.state.data,
																	...files
																		.map( f => ({
																			id: this.setFileId( f ),
																			name: <span className={"sirating-question-attachment-modal-text"}>{ truncateFileName( f.name ) }</span>,
																			originalName: f.name,
																			formattedSize: <span className={"sirating-question-attachment-modal-text"}>{ f.formattedSize }</span>,
																			preview: (
																				(!!f.preview)
																					? (
																						<img
																							data-dz-thumbnail=""
																							className="avatar-sm rounded bg-light"
																							alt={ f.name }
																							src={ f.preview }
																						/>
																					)
																					: <i className={ getMaterialClassIconByFileName( f.name ) + " sirating-question-attachment-modal-icon" } />
																			),
																			fingerprint: sha256( this.setFileId( f ) )
																		}))
																]
																	.filter( onlyNotNull )
																	.sort((x, y) => {
																		if( x.id < y.id ) return -1;
																		return 1;
																	})
																	.map( row => {
																		let sortedRow = {};
																		Object.keys(fieldMap)
																			.map( field => {
																				sortedRow[field] = row[field];
																			})
																		;
																		Object.assign(sortedRow, row);
																		return sortedRow;
																	})
															},
															() => {


																this.uploadFiles( files )
																	.then( result => {

																		this.setState({...this.state,	showMessageSuccess:true})
																	})
																	.catch(e => {
																		// debugger

																		showlog("errore in upload");
																		showlog( e );

																		this.setState({...this.state,	showMessageError:true})

																	})
																;

															}
														);

													}}
												/>
											</React.Fragment>


										)
										: null
								}


							</CardBody>
						</Card>
					</Col>
				</Row>



				{
					( !!this.state.data && this.state.data.length > 0 )
						? (


							(
								(!!hasPermission( permissions.QUESTION_ATTACHMENT_DISPLAY ))
									? (
										<ToolkitProvider
											bootstrap4
											keyField="File"
										>
											{props => (

												<React.Fragment>

													<BootstrapTable
														bootstrap4
														striped
														{...props.baseProps}
														size="sm"
														responsive
														keyField="originalName"
														bordered={ false }
														data={ this.state.data }
														columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
														defaultSorted={ defaultSorted }
														pagination={ paginationFactory(paginationOptions) }
														wrapperClasses="table-responsive"
														selectRow={{
															mode: 'checkbox',
															clickToSelect: true,


															onSelect: row => {
																showlog("riga selezionata su onSelect");
																showlog( row );



																if( !!this.state.selected.some( s => s === row.serverId) ) {
																	this.setState({
																		...this.state,
																		selected: this.state.selected.filter( s => s !== row.serverId)
																	});
																}
																else {
																	this.setState({
																		...this.state,
																		selected: [...this.state.selected, row.serverId ]
																	});
																}



															},


															selectionRenderer: row => {


																return (
																	<div className="custom-control custom-checkbox sirating-question-attachment-modal-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			checked={row.checked}
																			disabled={row.disabled}
																		/>
																		<label
																			className="custom-control-label"
																			onClick={e => {
																				e.preventDefault();
																			}}/>
																	</div>
																);

															},
															selectionHeaderRenderer: ({indeterminate, ...rest}) => {
																return null;
															}
														}}

													/>



												</React.Fragment>

											)}

										</ToolkitProvider>
									)
									: null
							)




						)
						: null
				}






			</React.Fragment>
		);


		let content = (
			<Modal isOpen={ true } toggle={this.toggle} className={"file-upload-modal"}>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{translate("Attachments")}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>

				{
					( !!this.state.data && this.state.data.length > 0 )
						? (
							<ModalFooter className={this.state.bodyClass}>
								<Button color="link" onClick={this.toggle}>
									{ translate("Close") }
								</Button>

								{
									(!!this.state.selected && this.state.selected.length > 0)
										? (
											<React.Fragment>

												{
													(!!hasPermission( permissions.QUESTION_ATTACHMENT_DELETE ) && !this.props.disableDelete)
														? (
															<Button
																color="danger"
																className={"danger-trash"}
																onClick={ clickEvent => {


																	this.setState({
																			...this.state,
																			inDeleteWait: true
																		},

																		() => {

																			// per ogni file selezionato
																			Promise.all(
																				this.state.selected
																					.map( sid => removeSIRatingAdminQuestionAnswerAttachment( sid ) )
																			)
																				.then( resultList => {
																					showlog("risultato eliminazione");
																					showlog( resultList );


																					let refreshedData =
																						this.state.data
																							.filter( d => !!!this.state.selected.some( sid => d.serverId === sid ) )
																					;


																					if( typeof this.props.onAttachmentDelete === "function" ) {
																						this.props.onAttachmentDelete( this.state.selected );
																					}

																					this.setState({
																						...this.state,
																						inDeleteWait: false,
																						data: refreshedData,
																						selected: []
																					})
																				})
																				.catch( e => {
																					errorlog("errore all'eliminazione del file", e);
																				})

																		}

																	)



																}}
															>
																{
																	(!!this.state.inDeleteWait)
																		? (
																			<React.Fragment>
																				<span>In attesa&nbsp;&nbsp;&nbsp;&nbsp;</span>
																				<Spinner color={"#fff"} className="spinner-border-sm mr-1" />
																			</React.Fragment>
																		)
																		: (
																			<React.Fragment>
																				<span>Elimina&nbsp;&nbsp;&nbsp;&nbsp;</span>
																				<i className="mdi mdi-delete" />
																			</React.Fragment>
																		)
																}
															</Button>
														)
														: null
												}

												{
													(!!hasPermission( permissions.QUESTION_ATTACHMENT_DOWNLOAD ))
														? (
															<Button
																color="success"
																onClick={ clickEvent => {
																	// TODO
																	// Attachments/download_sr/<id>
																	this.setState({
																			...this.state,
																			inDownloadWait: true
																		},
																		() => {

																			Promise.all(
																				this.state.selected

																				// nome file associato all'id
																					.map( sid => {

																						return ({
																							id: sid,
																							name:
																								this.state.data
																									.filter( d => d.serverId === sid )
																									.map( d => d.originalName )
																									.reduce( getLastElementOr, null )
																						});

																					})

																					.map( fileData => downloadSIRatingAdminQuestionAnswerAttachment( fileData.id, fileData.name ) )
																			)
																				.then( resultList => {
																					showlog("risultato download dei file");
																					showlog( resultList );


																					this.setState({
																						...this.state,
																						inDownloadWait: false
																					})
																				})
																				.catch( e => {
																					errorlog("errore al downoad del file", e);
																				})


																		}
																	)
																}}
															>

																{
																	(!!this.state.inDownloadWait)
																		? (
																			<React.Fragment>
																				<span>Downloading...&nbsp;&nbsp;&nbsp;&nbsp;</span>
																				<Spinner color={"#fff"} className="spinner-border-sm mr-1" />
																			</React.Fragment>
																		)
																		: (
																			<React.Fragment>
																				<span>Download&nbsp;&nbsp;&nbsp;&nbsp;</span>
																				<i className="dripicons-cloud-download" />
																			</React.Fragment>
																		)
																}

															</Button>
														)
														: null
												}


											</React.Fragment>
										)
										: null
								}


							</ModalFooter>
						)
						: null
				}


			</Modal>


		);

		return content;
	}

	componentDidMount() {}


	componentWillUpdate(nextProps, nextState, nextContext) {

		let noFileChange = true;

		if (
			!!nextProps.files
			&& nextProps.files.length === this.state.files.length
		) {

			if(
				nextProps.files
					.map( nf => this.state.data.some( f => f.attachment_name === nf.name ) )
					.filter( onlyNotNull )
					.length
					<=
					this.state.data.length
			) {

			}
			else noFileChange = false;

		}
		else noFileChange = false;

		/*
		if( !noFileChange ) {
			this.setState({
				files: this.props.files || [],
				data:
					(!!this.props.files)
						? (
							this.props.files
								.map( f => ({
									id: this.setFileId({name: f.attachment_name, formattedSize: "---KB"}),
									serverId: f.attachment_id,
									preview: <i className={ getMaterialClassIconByFileName( f.attachment_name ) + " sirating-question-attachment-modal-icon" } />,
									name: <span className={"sirating-question-attachment-modal-text"}>{ truncateFileName( f.attachment_name ) }</span>,
									originalName: f.attachment_name,
									formattedSize: <span className={"sirating-question-attachment-modal-text"}>---</span>
								}))
								.filter( onlyNotNull )
								.sort((x, y) => {
									if( x.id < y.id ) return -1;
									return 1;
								})
								.map( row => {
									let sortedRow = {};
									Object.keys(fieldMap)
										.map( field => {
											sortedRow[field] = row[field];
										})
									;
									Object.assign(sortedRow, row);
									return sortedRow;
								})
						)
						: []
			});
		}
		*/



	}


}

