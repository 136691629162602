import React, {useState} from "react";
import {TREE_TYPE} from "../../../../env";
import {Button, Col, Row} from "reactstrap";
import TreeGriCollectComponent from "../tree-component";
import TreeGriCollectReadOnlyComponent from "../tree-component-readonly";
import ActionButton from "../../../save-button";
import SetTreeConfirmModal from "../tree-set-confirm-modal";




export default class SetTreeStep extends React.Component {

	state = {
		tree: this.props.tree
	}

	constructor(props) {
		super(props);
	}

	render() {

		// const [tree, setTree] = useState( props.tree );
		// console.log("componente set dell'albero");


		let component = (
			<TreeGriCollectComponent
				tree={this.state.tree}
				collect={this.props.collect}
				fullDataCollect={this.props.fullDataCollect}
				forceReload={this.props.forceReload}
				onTreeUpdate={ tree => {

					// console.log("aggiorno albero");

					// setTree( tree );

					this.setState({
						...this.state,
						tree: tree
					})

				}}
				reloadTree={ (tree, tabId) => {
					return this.props.onUpdate( tree, tabId );
				}}
			/>
		);

		if(this.props.step !== TREE_TYPE.SET.step || !!this.props.readOnly ) {
			component = (
				<TreeGriCollectReadOnlyComponent
					tree={this.props.tree}
					collect={this.props.collect}
					fullDataCollect={this.props.fullDataCollect}
					onTreeUpdate={ tree => {
						this.setState({
							...this.state,
							tree: tree
						});
					}}
					reloadTree={ (tree, tabId) => {
						return this.props.onUpdate( tree, tabId );
					}}
				/>
			);
		}


		return (

			<React.Fragment>


				{
					(!!this.state.confirmPrompt)
						? (
							<SetTreeConfirmModal
								collect={ this.props.collect }
								onClose={ status => {
									if( !!status ) {
										this.props.nextStep();
									}
									else {
										this.setState({
											...this.state,
											confirmPrompt: false
										})
									}
								}}
							/>
						)
						: null
				}



				<Row>
					<Col>
						<div className="page-title-box">
							<div className="page-title-right">

								<ActionButton
									buttonText={
										(this.props.step !== TREE_TYPE.SET.step || !!this.props.readOnly )
											? "Avanti"
											: "Conferma"
									}
									promise={ () => {
										return Promise.resolve( );
									}}
									onResult={ result => {
										if( this.props.step !== TREE_TYPE.SET.step || !!this.props.readOnly ) {
											this.props.nextStep();
										}
										else {
											this.setState({
												...this.state,
												confirmPrompt: true
											})
										}
									}}
								/>


								{/*
								<Button
									color={"primary"}
									onClick={clickEvent => {

										if(this.props.step !== TREE_TYPE.SET.step || !!this.props.readOnly ) {
											this.props.nextStep();
										}
										else {

											this.setState({
												...this.state,
												inWait: true
											})

											treeSetConfirm(this.props.collect)
												.then(result => {
													this.props.nextStep(  );
												})
												.catch(e => {
													errorlog("error",e);
												})
										}

									}}
								>
									{
										(this.props.step !== TREE_TYPE.SET.step || !!this.props.readOnly )
											? "Avanti"
											: (
												!!this.state.inWait
													? (
														<React.Fragment>
															<Spinner color={"#fffa"} className="spinner-border-sm mr-1" />
															<span>&nbsp;&nbsp;elaborazione...&nbsp;&nbsp;</span>
														</React.Fragment>
													)
													: "Conferma"
											)
									}
								</Button>
								*/}

							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>

						{ component }

					</Col>
				</Row>

			</React.Fragment>


		)
	}


};
