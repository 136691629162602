// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import {PATH_PREFIX, permissions, role} from "../env";
import {showlog, emoj, renderlog} from "../utils";
import {EMOJ} from "../emoj";
import { MotionCubeTheme, motionCubeIsRunning } from "../components/movingCubeWaitingAnimation/moving-cube-core";
import MovingCubeAnimation from "../components/movingCubeWaitingAnimation/moving-cube";
import {hasPermission} from "../business-logic/auth";


export default class TestPage extends React.Component {

    state = {}

    constructor(props) {
        super( props );
    }

    render() {

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        {label: 'Welcome Page', path: '' + PATH_PREFIX + '/welcome'},
                        {label: 'Test', active: true}
                    ]}
                    title={'Test'}
                />


                <div className={"centered-block almost-full-height"}>
                    <MovingCubeAnimation
                        theme={ MotionCubeTheme.FULL_MEDIUM_COLOR_3 }
                        onLoaded={ () => {
                            showlog("MotionCube animation loaded!");
                        }}
                    />
                    {
                        ( !!hasPermission( permissions.TEST_PAGE ) )
                            ? <span>test</span>
                            : <span>Non hai il permesso di test &nbsp;&nbsp;{ emoj(EMOJ.sad_but_relieved_face) }</span>
                    }
                </div>





            </React.Fragment>
        );
    }

    componentDidMount() {



    }

};

