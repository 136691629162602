import React, {Component} from "react";
import {COLOR_POLICY, QUESTION_STATUS} from "../../../env";
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import ActionButton from "../../save-button";
import {questionAudit} from "../../../business-logic/rating";
import {translate} from "../../i18n/translate-function";


export default class NCMessageModal extends Component {

	state = {}

	constructor( props ) {
		super( props );
		this.toggle = this.toggle.bind( this );
	}

	toggle = () => {
		if(!this.state.waiting) this.props.onClose();
	}

	render() {
		renderlog("NCMessageModal", this.state, this.props, COLOR_POLICY.edit)

		let	context = (
			<React.Fragment>
				<Row>
					<Col>
						{ this.props.message }
					</Col>
				</Row>
				{

					( this.props.status === QUESTION_STATUS.lowNonCompliant.id || this.props.status === QUESTION_STATUS.waitNonCompliant.id )
						? (
							<Row>
								<Col>
									<AvForm>
										<AvGroup>
											<div className={"non-compliant-answer-container-labeltextarea"}>
												<Label for={"audit-non-compliant-reason-"+ this.props.question.id}>{translate("Answer")}</Label>
												<AvInput
													className={"sector-question-text"}
													type="textarea"
													row={64}
													name={"audit-non-compliant-reason-"+ this.props.question.id}
													id={"audit-non-compliant-reason-"+ this.props.question.id}
													onChange={ text => {
														this.setState({
															answer: text.target.value
														})
													}}
													required
												/>

											</div>

										</AvGroup>

										<ActionButton
											buttonText={translate("Confirm")}
											promise={ () => questionAudit(
												this.props.ratingId,
												this.props.question.id,
												// QUESTION_STATUS.lowNonCompliant.id,
												//this.props.question.status,
												QUESTION_STATUS.waitNonCompliant.id,
												this.state.answer,
												//this.props.question.nonCompliantReason,
												this.props.question.nonCompliantExpiration || this.state.nonCompliantDefaultExp
												// this.state.nonCompliantReason.filter( ncr => ncr.id === question.id ).map( ncr => ncr.text ).reduce( getLastElementOr, "" ) // question.nonCompliantReason
												// document.getElementById( "audit-non-compliant-reason-"+ question.id ).value
											)}
											onProcess={ () => {
												showlog("start audit");
											}}
											onResult={ result => {
												this.props.onClose( true );
											}}
											onError={ error => {
												errorlog("Audit con commento", error);
											}}
										/>

									</AvForm>
								</Col>
							</Row>
						)
						: null

				}
			</React.Fragment>
		);

		let title =
			( this.props.status === QUESTION_STATUS.lowNonCompliant.id || this.props.status === QUESTION_STATUS.waitNonCompliant.id )
				? (
					<span><span className={"nc-message-title-icon"}>{ emoj( EMOJ.construction ) }</span> {translate("Please reply to the following request for additional information:")}</span>
				)
				: (
					( this.props.status === QUESTION_STATUS.highNonCompliant.id )
						? (
							<span><span className={"nc-message-title-icon"}>{ emoj( EMOJ.police_car_light ) }</span> NON CONFORMITA'</span>
						)
						: translate("Please attach the requested document:")
				)
		;

		let headerClassName =
			( this.props.status === QUESTION_STATUS.lowNonCompliant.id || this.props.status === QUESTION_STATUS.waitNonCompliant.id )
				? "nc-low-message-header"
				: (
					( this.props.status === QUESTION_STATUS.highNonCompliant.id )
						? "nc-high-message-header"
						: "Messaggio"
				)
		;


		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName +" "+ headerClassName}>
					{ title }
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
			</Modal>
		);


		return content;
	}



}
