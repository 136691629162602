// @flow
import React from 'react';
import {Col, Row} from 'reactstrap';
import {translate} from "./i18n/translate-function";

const Footer = props => {
    const currentYear = new Date().getFullYear();
    return (
        <React.Fragment>
            <footer className={`footer ${!props.isLogged ? "not-logged-user" : ""}`}>
                <div className="container-fluid">
                    <Row>
                        {/* <Col md={2}>
                            {currentYear}
                            &nbsp;
                            <span
                                onClick={ clickEvent => {
                                    showlog("buffalipa cliccato");
                                }}
                            >

                            </span>
                        </Col> */}

                        <Col md={12}>
                            <div className="text-md-center footer-links d-none d-md-block">
                            {/*<span>Copyright © ARB S.B.P.A. 2023 |</span>*/}
                            {/*<span>Copyright © ARB S.B. 2024 |</span>*/}
                            <span>Copyright © {translate("business name")} |</span>
                                <span><a href="https://www.arbsb.it/contatti-2/" target="_blank"> {translate("footer_contact")}</a></span> |
                                <span><a href="/support" target={"_blank"}> {translate("footer_support")} </a></span> |
                                <span> <a href="/privacy-policy" target={"_blank"}> Privacy </a></span> |
                                <span> <a href="/cookie-policy" target={"_blank"}> Cookie Policy </a></span> |
                                <span><a href="https://www.arbsb.it/" target={"_blank"}> {translate("footer_aboutcompany")} </a> </span>
                            </div>
                        </Col>

                        {/* <Col md={2}>
                            <div className="text-md-right footer-links d-none d-md-block">
                                {/*<a href="/about">About</a>*/} {/*
                                <a href="/support">Support</a>
                                <a href="/contactus">Contact Us</a>
                            </div>
                        </Col>  */}

                    </Row>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;

