import React from "react";
import "./srtool-menu-logo-svg-component.css";
export const SRToolMenuLogoSvgComponent = props => (
    <div
        id={ props.id }
        className={"logo-container-srtml "+ props.className}
        onClick={ props.onClick }
    >
        <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.85 395.39">
            <defs>
                <clipPath id="clip-path">
                    <path className="srtml" d="M173.76,160.57h14.33a.1.1,0,0,1,0,.07Z"/>
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect className="srtml" x="168.76" y="155.57" width="24.33" height="10.07"/>
                </clipPath>
            </defs>
            <path className="srtml" d="M286.31,340.93l0,0h0Z"/>
            <path className="srtml-2" d="M59,198l.08-.17c0-.05,0-.1-.07-.1Z"/>
            <path className="srtml"
                  d="M293.19,248.68a16.12,16.12,0,0,1-16.1,16.1l-20.25,0V232.73l20.25-.13a16,16,0,0,1,9,2.77,14.7,14.7,0,0,1,2.37,1.93A16.1,16.1,0,0,1,293.19,248.68Z"/>
            <path className="srtml"
                  d="M201.14,128.39a143.23,143.23,0,0,0-13,32.18H173.72a16.08,16.08,0,0,1-16-16.52c.22-8.84,8-15.66,16.83-15.66Z"/>
            <path className="srtml-2"
                  d="M313.29,123.62a69.27,69.27,0,0,0-8.71,2.16C307.31,125.24,310.26,124.54,313.29,123.62Z"/>
            <path className="srtml-3"
                  d="M368.34,250.92a90.38,90.38,0,0,1-82,90c.66-.44,19.42-12.69,19.35-50.81-.06-27.16-5.11-42.26-17.23-52.8a14.7,14.7,0,0,0-2.37-1.93,16,16,0,0,0-9-2.77l-20.25.13-73.73.56-12.43.09c-49.92,0-87.93-39-87.93-88.91,0-2.86.16-5.69.42-8.48-1.73,24.36,42.57,23.65,44.32,24.25.71.24,21.11.24,46.23.33h0l14.31.07a.1.1,0,0,0,0-.07l75.89,0,14,0A90.4,90.4,0,0,1,368.34,250.92Z"/>
            <g className="srtml-4">
                <g className="srtml-5">
                    <image width="52" height="22" transform="translate(168.59 155.24) scale(0.48)"
                           xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAWCAYAAACPHL/WAAAACXBIWXMAABcRAAAXEQHKJvM/AAABLUlEQVRYR92YsYrCQBCGf0cwCAYLH+LAMml9BsFKy7xA2mB32AVbX+DKuyrgMwQ7LSyEPISFKAQDIRZh1yS6EZszs1+1TCbFx2SHmbSyLMtQQxqFSA5hXcq/0Z3MX6WgpRI6Lca47rbPHn0c03GVcg9CceDj/LN6mtwkDMtGb+ah/TUqxUtCTa6KisHyryRF4hAHPjsZADh6U6TR/Y4TkF98Dp+ZisvvUp6pGuDIdbeVVSIR4I4oCsWB/yKVB6IosinoglZCaRTqJZQcNBPqTuZ6CQEAdYblWYgrpuMCAKg63HGHgLsdVwzLlusEAfllMiy79qUm05t58iybQv97zVJKuT4AuSknKdNx6xc8QRqFOHrTargxvLWCFyn+IEn2m49O5aJxdYajh6oUuQEdq3SVPbbIPwAAAABJRU5ErkJggg=="/>
                </g>
            </g>
            <path className="srtml-6"
                  d="M324.13,54.17a144,144,0,0,0-123,74.22H174.51c-8.85,0-16.61,6.82-16.83,15.66a16.08,16.08,0,0,0,16,16.52c-25.12-.09-45.52-.09-46.23-.33-1.75-.6-46,.11-44.32-24.25a90.4,90.4,0,0,1,90-81.91l51.37.24Z"/>
            <path className="srtml-2" d="M364.83,54.19s0-.1-.07-.1v.26Z"/>
            <path className="srtml-6"
                  d="M305.7,290.1c.07,38.12-18.69,50.37-19.35,50.81h-.05c-2.74.24-5.54.4-8.35.4l-28.44-.24-140.36.24s9-19.25,20.76-46c4.23-9.66,12.51-16.15,21.37-20.51,13.13-6.45,27.53-8.22,31.83-8.62.82-.09,1.28-.11,1.28-.11s6.49-.06,15.57-.2l1.58,0c14.35-.18,34.37-.49,46.22-.91h0c1.73-.06,3.3-.11,4.66-.17h4.4l20.25,0a16.09,16.09,0,0,0,11.38-27.48C300.59,247.84,305.64,262.94,305.7,290.1Z"/>
            <path className="srtml-3"
                  d="M256.84,232.73v32.08h-4.4c-1.36.06-2.93.11-4.66.17h0c-11.85.42-31.87.73-46.22.91l-1.58,0c-9.08.14-15.54.2-15.57.2s-.46,0-1.28.11V233.29Z"/>
            <path d="M327.23,54.08c-1,0-2.06.05-3.1.08l40.63-.07Z"/>
            <path className="srtml-3"
                  d="M364.75,54.08v.26c-1,2.22-9.65,20.67-20.62,45.72-5.76,13.11-18.91,20-30.85,23.57a71.74,71.74,0,0,0-8.7,2.14A68.16,68.16,0,0,0,264,160.55l-75.89,0a144.1,144.1,0,0,1,136-106.4Z"/>
        </svg>
    </div>
);
