/**
 * @author:     Vito Lipari
 * @modified:   2019.05.30
 * @version:    1.4.0
 */

import React, { Component } from "react";
import {MotionCube} from "./moving-cube-core";

import "./moving-cube.css";
import {emoj} from "../../utils/string-utils";
import {EMOJ} from "../../emoj";



/**
 * ATTENZIONE modificato
 */
export default class MovingCubeAnimation extends Component {


    state = {
        motionReady: false
    };



    constructor( props ) {
        super(props);

    }

    render() {

        // console.log(this.state);

        let motionObject = (
            <span className={"tmp"}>{ emoj( EMOJ.smiling_face ) }</span>
        );


        if( this.state.motionReady ) {
            motionObject = null;
            MotionCube.play( this.props.theme || 0 );
            if( !!this.props.onLoaded ) this.props.onLoaded();
        }

        return(
            <div id={"movingCubeAnimation"} >
                { motionObject }
            </div>
        );
    }


    componentDidMount() {

        // loading lib
        if( !!document.getElementById("three_lib") ) {
            setTimeout(function(){
                this.setState({motionReady: true});
            }.bind(this), 5);
        }
        else {

            let ref = this;
            let scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.src = "./three.min.js";
            scriptTag.id = "three_lib";
            scriptTag.addEventListener('load', function (e) {
                setTimeout(function() {
                    ref.setState({motionReady: true});
                }.bind(this), 5);
            });
            document.head.appendChild(scriptTag);
        }

    }


}
