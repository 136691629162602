import React, {Suspense} from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	PopoverBody,
	PopoverHeader,
	Row,
	TabContent,
	TabPane,
	UncontrolledPopover
} from 'reactstrap';
import {
	CALENDAR_DATE_FORMAT_IT,
	COLLECT_STATUS,
	COLOR_POLICY,
	DATE_FORMAT_IT,
	GRI_ADMIN_TREE_DEEP_LEVELS_NAME,
	HELPER,
	HOST,
	INFORMATIVE_STATUS,
	MEASURE_UNIT_ID_DATE,
	permissions,
	role,
	TABS,
	TREE_SECTION
} from "../../../env";
import "../../../services/interceptor";
import classnames from 'classnames';
import {
	cloneOpenNodes,
	convertTableToTree,
	convertToVirtualTree,
	exportSeedTableToCSV,
	getAllTreeInfo,
	getTreeNodeByPath,
	hasGoal,
	hasInformativeGoal,
	openSubTree,
	treeLog
} from "../../../business-logic/tree-manager";
import Select from 'react-select';
import GriRequirementGoalModal from "./gri-requirement-goal-modal";
import {
	changeRepeatingZoneName,
	loadGriStructureValue,
	loadVirtualTreeNodeValue,
	saveManager,
	saveTreeNodeSingleValue,
	saveVirtualTreeNodeValue
} from "../../../business-logic/gri";
import GriInfoHundredThreeModal from "./gri-info-hundred-three-modal";
import TreeDataEntryValueBoxComponent from "./tree-dataentry-valuebox-component";
import TreeDataEntryValueModalComponent from "./tree-dataentry-valuemodal-component";
import TreeDataEntryNewZoneModalComponent from "./tree-dataentry-new-zone-modal-component";
import TreeDataEntryZoneDeleteModal from "./tree-dataentry-zone-delete-modal";

import ErrorMessageModalComponent from "../../error-message-modal-component";
import StandBySystemModalComponent from "./standby-system-modal";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {EMOJ} from "../../../emoj";
import ActionButton from "../../save-button";
import {TreeToggler} from "../../tree-toggler";
import {withRouter} from "react-router-dom";
import {GuideLineIconSvgComponent} from "../../svg/guide-line-icon-svg-component";
// import "../../../style/sr-tool-data.css";
import "../../../style/sr-tool-conf.css";
import {hasPermission, isUserRole} from "../../../business-logic/auth";

import {
	beautify,
	cloneObject,
	emoj,
	errorlog,
	getLastElementOr,
	getTreeFingerPrint,
	getTreeObjectHash,
	getTreeRawSeedsFingerPrint,
	hasHundredThreeInformative,
	isNotEmptyArray,
	loadDatalog,
	mountedlog,
	onlyNotNull,
	pop,
	renderlog,
	showlog,
	sortById,
	updatelog
} from "../../../utils";
import GriManagerListPopup from "./gri-manager-list-popup";
import AdminUserEditModalContent from "../admin/user-edit-modal";
import {MissingGoalSvgComponent} from "../../svg/missing-goal-svg-component";
import {GoalSvgComponent} from "../../svg/goal-svg-component";
import {QuestionNoteModal} from "./question-note-modal";
import moment from "moment";
import HyperDatepicker from "../../Datepicker";
import {NoteIconSvgComponent} from "../../svg/note-icon-svg-component";
import SRToolInformativeAttachmentModal from "../../../pages/area/si-rating-admin/sr-tool-informative-attachment-modal";
import {AdditionalRequirements} from "../../svg/additional-requirements";
import {OverWaitingPannel} from "./over-waiting-panel";
import {getNodeByPath, setSRToolAudit} from "../../../business-logic/collect";
import GotoTopComponent from "../../goto-top-component";
import {translate} from "../../i18n/translate-function";
import CompilationRequirementsIcon from "../../../assets/images/CompilazioneRequisiti.png";
import Raccomandations from "../../../assets/images/Recommendations.png";
import {TreeDataEntryMonthlyVersion} from "./tree-dataentry-monthly-version";

const GUIDELINES_POST_IT = (
	<span
		className={""}
	>
		{ translate("GUIDE_LINEES_POST_TEXT") }
		&nbsp;<a href={"https://www.globalreporting.org/how-to-use-the-gri-standards/resource-center/"} target={"_blank"}>link</a>
	</span>
);




class TreeGriCollectDataEntryComponent extends React.Component {

	state = {
		activeTab: 1,
		currentTab: TABS.general,
		tree: this.props.tree || { tabs: [] },
		virtualTree: {},
		settedNode: [],
		waitingNode: [],
		tm: [],
		step: this.props.collect.step || 1,
		errors: [],
		dateValues: [],
		materialThemesWait: true,
		assuranceInformativeWait: [],
		assuranceInformative: [],
		srtoolAuditError: []
	}

	constructor( props ) {
		super( props );
		this.inWait                     = this.inWait.bind( this );
		this.toggle                     = this.toggle.bind( this );
		this.getSubTreeChapter          = this.getSubTreeChapter.bind( this );
		this.loadData                   = this.loadData.bind( this );
		this.standby                    = this.standby.bind( this );
		this.toggleNode                 = this.toggleNode.bind( this );	// non necessario
		this.helperNode                 = this.helperNode.bind( this );	// non necessario
		this.isNodeDisabled             = this.isNodeDisabled.bind( this );	// non necessario

	}

	inWait() {
		return (
			<div>Wait</div>
		);
	}

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	toggleNode = node => {

		// sotto-sotto-requisiti non hanno niente da aprire/chiudere
		if( node.path.length === 5 ) return;


		let treeTmp = cloneObject( this.state.tree );
		let nodeTmp = getTreeNodeByPath( treeTmp, node.path );
		nodeTmp.open = !getTreeNodeByPath( this.state.tree, node.path ).open;

		// apertura sotto-albero
		// openSubTreeFromPath( treeTmp, info.path )

		if( !!nodeTmp.open ) {
			openSubTree( nodeTmp )
				.then( subTreeByNode => {

					// nodeTmp.requirements = subTreeByNode.requirements;	// work
					// nodeTmp = cloneObject( subTreeByNode );					// doesn't work
					Object.keys(nodeTmp)
						.map( keyNode => {
							nodeTmp[ keyNode ] = subTreeByNode[ keyNode ];
						})
					;

					let processedTree = cloneOpenNodes( treeTmp, this.state.tree );

					this.setState({
						...this.state,
						// tree: treeTmp
						tree: processedTree
					});

				})
			;
		}
		else {
			this.setState({
				...this.state,
				tree: treeTmp
			});
		}
	}


	standby( isStop = false ) {

		return (
			new Promise((success, fail) => {
				try {

					this.setState({
							...this.state,
							isSwitchingTab: !isStop,
							tmFilter: 0, // temporaneo
							assuranceFilter: null
						},
						newState => {
							showlog("exit stand-by");
							// setTimeout( success, 250 );
							// return success();
							success();
						})
					;

				}
				catch( e ) {
					errorlog("errore allo stand-by");
					showlog( e );
					fail();
				}
			})
		);

	}



	helperNode = node => (

		(!!HELPER && (window.location.href.indexOf("help") !== -1))
			? (
				<span
					className={"question-info-icon helper-icon"}
					onClick={ clickEvent => {
						clickEvent.stopPropagation();
						this.setState({
							...this.state,
							helper: node
						})
					}}
				>
					<span>{ emoj( EMOJ.ambulance ) }</span>
					<code>{ node.structureId }</code>
				</span>
			)
			: null

	)


	/**
	 * Il nodo viene disabilitato o abilitato se:
	 * - status del nodo vale 20 e non si è operatore Assurance = ABILITATO
	 * - si è un operatore Assurance 							= disabilitato
	 * - status del nodo vale 10 								= disabilitato
	 *
	 * - altrimenti la disabilitazione segue il valore di isCollectDone
	 *
	 * @param node
	 * @returns {boolean|*}
	 */
	isNodeDisabled = node => {
		if( (node.status === INFORMATIVE_STATUS.notCompliant.id) && !isUserRole( role.ASSURANCER ) ) return false;
		if( !!isUserRole( role.ASSURANCER ) ) return true;
		if( node.status === INFORMATIVE_STATUS.validated.id ) return true;
		return this.props.isCollectDone;
	}


	render() {

		renderlog("Values Component", this.state, this.props, { color: "#00afff", contrastColor: "#ffffff" });
		// this.props.history.push({search: "section="+ TREE_SECTION.DATA_ENTRY});


		if( this.state.step === 1 && !isUserRole( role.SRTool_MANAGER ) ) {
			return (
				<div align="center" style={{fontWeight:'510',color:'#313a46'}}>

					<img src={ HOST +"/assets/Loghi/gri.png" } alt="logo" height="100" />
					<br/><br/><br/>
					<p style={{fontSize:"27px"}}>The Global Reporting Initiative (GRI)</p>

					<p  style={{fontSize:"23px",marginLeft:'24%',marginRight:'24%', textAlign:'left'}}>
						<i class="mdi mdi-check"/> Ti aiuta a comunicare i tuoi impatti sui principali problemi di sostenibilità con un'ampia gamma di parti di interesse<br/>
						<i class="mdi mdi-check"/> E' applicabile in tutti settori, paesi, dimensioni aziendali, aziende pubbliche e private<br/>
						<i class="mdi mdi-check"/> Sono i primi e più ampiamente adottati standard per la rendicondazione di sostenibilità livello globale<br/>
					</p>
					<br/>
					<Button
						style= {{fontSize:'20px',background:'rgba(11, 80, 155, 0.43)',color:'rgb(1, 51, 106)',borderColor: 'rgb(1, 51, 106)', borderRadius:'10px',height:'60px'}}
						color="primary"
						onClick={ clickEvent => {
							window.location.href = "?section=conf";
								}
						}
					>
						<span style={{marginTop:'10px',marginBottom:'10px'}}><i class="mdi mdi-wrench"/> Configura la raccolta</span>
					</Button>

					<br/><br/><br/>
				</div>
			);
		}



		return (
			<Suspense fallback={this.inWait()}>


				{
					(!!this.state.materialThemesWait)
						? (
							<OverWaitingPannel
								className={"over-waiting-panel-white"}
							/>
						)
						: null
				}

				{
					(!!this.state.attachmentModal)
						? (
							<SRToolInformativeAttachmentModal
								info={ this.state.attachmentModal }
								collect={ this.props.collect }
								// files={this.state.attachmentModal}
								disableUpload={
									!isUserRole( role.ASSURANCER )
										? (
											// se non ASSURANCE OPERATOR se assurance finito
											!!this.isNodeDisabled( this.state.attachmentModal )
										)
										: true // se ASSURANCER upload disabilitato
								}
								disableDelete={
									!isUserRole( role.ASSURANCER )
										? (
											// se non ASSURANCE OPERATOR se assurance finito
											!!this.isNodeDisabled( this.state.attachmentModal )
										)
										: true // se ASSURANCER delete disabilitato
								}
								onAttachmentUploaded={attachments => {
									let treeTmp = cloneObject(this.state.tree);
									treeTmp = cloneOpenNodes( this.state.tree, treeTmp );
									getNodeByPath(
										treeTmp,
										this.state.attachmentModal.path,
										node => {
											node.attachmentsNumber += attachments.length;
											this.setState({
												...this.state,
												tree: treeTmp
											});
										}
									);
								}}
								onAttachmentDelete={attachmentIds => {
									let treeTmp = cloneObject(this.state.tree);
									treeTmp = cloneOpenNodes( this.state.tree, treeTmp );
									getNodeByPath(
										treeTmp,
										this.state.attachmentModal.path,
										node => {
											node.attachmentsNumber -= attachmentIds.length;
											this.setState({
												...this.state,
												tree: treeTmp
											});
										}
									);
								}}
								onClose={() => {
									this.setState({
										...this.state,
										attachmentModal: null
									})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.helper)
						? (
							<div
								className={"helper-pannel-backdrop"}
								onClick={ clickEvent => {
									clickEvent.stopPropagation();
									this.setState({
										...this.state,
										helper: null
									})
								}}
							>
								<div
									className={"helper-pannel"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
									}}
								>
									<code>Helper</code>
									<pre
										onClick={ clickEvent => {
											clickEvent.stopPropagation();
										}}
									>
										{

											beautify(
												{
													id: this.state.helper.id,
													name: this.state.helper.name,
													open: this.state.helper.open,
													applicability: this.state.helper.applicability,
													material: this.state.helper.material,
													userManager: this.state.helper.userManager,
													userManagerName: this.state.helper.userManagerName,
													measureUnitList: this.state.helper.measureUnitList,
													measureUnit: this.state.helper.measureUnit,
													measureUnitIsString: this.state.helper.measureUnitIsString,
													isString: this.state.helper.isString,
													timing: this.state.helper.timing,
													structureId: this.state.helper.structureId,
													subRequirementsRepeating: this.state.helper.subRequirementsRepeating,
													evaluate: this.state.helper.evaluate,
													griRepeatingValueId: this.state.helper.griRepeatingValueId,
													value: this.state.helper.value,
													isValueActive: this.state.helper.isValueActive,
													dateGoal: this.state.helper.dateGoal,
													dateStartGoal: this.state.helper.dateStartGoal,
													valueGoal: this.state.helper.valueGoal,
													valueStartGoal: this.state.helper.valueStartGoal,
													path: this.state.helper.path,
													status: this.state.helper.status,
													hasNote: this.state.helper.hasNote,
													hasAdminNote: this.state.helper.hasAdminNote,
													nonCompliantText: this.state.helper.nonCompliantText,
													zones: this.state.helper.zones,
													// ...this.state.helper,
													raw: this.state.seeds.filter( s => parseInt(s.id, 10) === parseInt(this.state.helper.structureId, 10) )
												}
											)

										}
									</pre>
								</div>
							</div>
						)
						: null
				}

			{/*
			<Row>
				{
					!!this.props.isCollectDone
						? (

								<Col sm={4}>
									<Card>
										<CardBody>
											<h6>Informative</h6>
											<Select
												className="react-select"
												classNamePrefix="react-select"
												name="assurance-filter"
												id="assurance-filter"
												onChange={ changeEvent => {


													// debugger;

													if( !changeEvent.value || changeEvent.value === 0 ) {

														// debugger;

														this.setState({
															...this.state,
															treeCopy: null, // this.state.tree,
															tree: this.state.treeCopy || this.state.tree,
															inWaiting: false,
															assuranceFilter: null
														})
													}
													else {


														let allInfos = getAllTreeInfo( this.state.treeCopy || this.state.tree );



														let displayInformatives =
															allInfos
																.filter( inf => inf.status === changeEvent.id )
														;


														// all chapters owns to-display informative
														let treeTmp = cloneObject( this.state.treeCopy || this.state.tree );


														// filtro sui capitoli
														treeTmp.tabs[0].chapters =
															treeTmp
																.tabs
																.reduce( getLastElementOr, {chapters: []})
																.chapters
																.map( ch => {
																	if(
																		displayInformatives
																			.filter( di => (
																				ch.infos
																					.map( inf => inf.id )
																					.includes( di.id )
																			))
																			.length === 0
																	) {
																		ch.hide = true;
																	}
																	return ch;
																})
														;

														// filtro sulle info dei capitoli filtrati
														treeTmp
															.tabs
															.reduce( getLastElementOr, {chapters: []})
															.chapters
															.map( ch => {
																ch.infos =
																	ch.infos
																		.map( inf => {
																			inf =
																				displayInformatives
																					.map( dinf => {
																						if( !!inf && (inf.id === dinf.id) ) return inf;
																						else {
																							// inf = null;
																							return null;
																						}
																					})
																					.filter( onlyNotNull )
																					.reduce( getLastElementOr, null )
																			;
																			return inf;
																		})
																		.filter( onlyNotNull )
																;
															})

														;


														this.setState({
															...this.state,
															treeCopy: this.state.treeCopy || this.state.tree,
															tree: treeTmp,
															inWaiting: false,
															assuranceFilter: changeEvent.value
														})
													}





												}}
												options={[
													{value: 0, label: "Tutte"},
													...Object.keys(INFORMATIVE_STATUS)
														.map( field => INFORMATIVE_STATUS[ field ] )
														.filter( is => is.id > INFORMATIVE_STATUS.virgin.id )
														.map( is => ({ ...is, value: is.id}))
														.map( is => {
															if( is.id === INFORMATIVE_STATUS.completed.id ) is.label = "Da Validare";
															return is;
														})
												]}
												value={
													[
														{value: 0, label: "Tutte"},
														...Object.keys(INFORMATIVE_STATUS)
															.map( field => INFORMATIVE_STATUS[ field ] )
															.filter( is => is.id > INFORMATIVE_STATUS.virgin.id )
															.map( is => ({ ...is, value: is.id}))
															.map( is => {
																if( is.id === INFORMATIVE_STATUS.completed.id ) is.label = "Da Validare";
																return is;
															})
													]
													.filter( is => is.id === this.state.assuranceFilter )
													.reduce( getLastElementOr, {value: 0, label: "Tutte"})
												}
												placeholder={ <span className={"select-placeholder"}>Tutte</span> }
											/>
										</CardBody>

									</Card>
								</Col>

						)
						: null
				}


				{
					(!!this.state.materialthemes && this.state.materialthemes.length > 0 )
						? (


								<Col sm={4}>
									<Card>

										<CardBody>
											<h6>Tematiche Materiali</h6>
											<Select
												className="react-select"
												classNamePrefix="react-select"
												name="tm-filter"
												id="tm-filter"
												onChange={ changeEvent => {


													showlog("tematica selezionata");
													showlog(changeEvent);


													if( !changeEvent.value || changeEvent.value === 0 ) {

														// debugger;

														this.setState({
															...this.state,
															treeCopy: null, // this.state.tree,
															tree: this.state.treeCopy || this.state.tree,
															inWaiting: false,
															tmFilter: changeEvent.value
														})
													}
													else {


														// materialThemes quella che è stata scelta ( in bvase all id )
														// la materialThemes scelta ha la lista delle infos come id
														// tutte le infos

														let allInfos = getAllTreeInfo( this.state.treeCopy || this.state.tree );

														// showlog("tutte le info dell'albero");
														// showlog( allInfos );

														// showlog("tematiche materiali nello state");
														// showlog( this.state.materialthemes );


														let displayInformatives =
															allInfos
																.filter( inf => (

																	this.state.materialthemes

																		// tematica selezionata ------------------------
																		.filter(mt => mt.id === changeEvent.value)

																		// elemento ------------------------------------
																		.reduce( getLastElementOr, { informative: [] } )

																		// lista di id ---------------------------------
																		.informative
																			.map( i => i.informativeId )

																		// controllo inclusione ------------------------
																		.includes( inf.id )

																))
														;

														// showlog("displayInformatives");
														// showlog( displayInformatives );


														// all chapters owns to-display informative
														let treeTmp = cloneObject( this.state.treeCopy || this.state.tree );


														// filtro sui capitoli
														treeTmp.tabs[0].chapters =
															treeTmp
																.tabs
																.reduce( getLastElementOr, {chapters: []})
																.chapters
																	.map( ch => {
																		if(
																			displayInformatives
																				.filter( di => (
																					ch.infos
																						.map( inf => inf.id )
																						.includes( di.id )
																				))
																				.length === 0
																		) {
																			ch.hide = true;
																		}
																		return ch;
																	})
														;

														// filtro sulle info dei capitoli filtrati
														treeTmp
															.tabs
															.reduce( getLastElementOr, {chapters: []})
															.chapters
															.map( ch => {
																ch.infos =
																	ch.infos
																		.map( inf => {
																			inf =
																				displayInformatives
																					.map( dinf => {
																						if( !!inf && (inf.id === dinf.id) ) return inf;
																						else {
																							// inf = null;
																							return null;
																						}
																					})
																					.filter( onlyNotNull )
																					.reduce( getLastElementOr, null )
																			;
																			return inf;
																		})
																		.filter( onlyNotNull )
																;
															})

														;


														this.setState({
															...this.state,
															treeCopy: this.state.treeCopy || this.state.tree,
															tree: treeTmp,
															inWaiting: false,
															tmFilter: changeEvent.value
														})

													}


												}}
												options={[
													{value: 0, label: "nessuna tematica materiale"},
													...this.state.materialthemes
														.map( mt => ({value: mt.id, label: mt.name}))
												]}
												placeholder={ <span className={"select-placeholder"}>Seleziona Tematica Materiale</span> }
											/>
										</CardBody>

									</Card>
								</Col>


						)
						: (
							<WaitingOrNodataMessage
								waiting={ !(!!this.state.materialthemes && this.state.materialthemes.length === 0) }
								waitMessage={"Caricamento Tematiche Materiali"}
								nodataMessage={""}
								className={"bottom-margin-alignment left-alignment"}
								spinnerColor={"success"}
							/>
						)
				}
			</Row>
			*/}



{/*		Handling Managers ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}

				{
					(!!this.state.getManagerListPopup)
						? (
							<GriManagerListPopup

								collectId={ (this.props.match.params.id || this.props.id) }
								currentUserManager={ this.state.currentUserManager }

								onClose={ () => {
									this.setState({
										...this.state,
										getManagerListPopup: null
									})
								}}

								onDeselect={ userManager => {

									showlog("manager rimosso");

									let treeTmp = cloneObject( this.state.tree );

									let activeTab = this.state.activeTab;

									this.standby()
										.then( () => {
											let pathId =
												this.state
													.getManagerListPopup
													.split("-")
													.map( id => parseInt(id, 10) )
													.filter(id => !!id)
											;

											showlog("paths");
											showlog(pathId);

											let node = getTreeNodeByPath(treeTmp, pathId);

											// managerID, collectID, level, nodeID
											saveManager( 0, this.props.collect.id, pathId.length -1,  ( pathId.length === 0 ) ? node.id : pathId[ pathId.length -1 ] )
												.then( result => {
													this.loadData()
														.then( refreshedTree => {


															this.props.onTreeUpdate( refreshedTree, activeTab )
																.then( () => {
																	this.setState({
																		...this.state,
																		isSwitchingTab: false,
																		getManagerListPopup: null
																	})
																})

															;
														})
													;
												})

										})


								}}

								onChoose={ userManager => {

									showlog("manager scelto");

									let treeTmp = cloneObject( this.state.tree );

									this.standby()
										.then( () => {
											let pathId =
												this.state
													.getManagerListPopup
													.split("-")
													.map( id => parseInt(id, 10) )
													.filter(id => !!id)
											;

											showlog("paths");
											showlog(pathId);

											let node = getTreeNodeByPath(treeTmp, pathId);

											node.userManager = userManager.id;
											node.userManagerName = userManager.firstName +" "+ userManager.lastName;

											// managerID, collectID, level, nodeID
											saveManager( userManager.id, this.props.collect.id, pathId.length -1,  ( pathId.length === 0 ) ? node.id : pathId[ pathId.length -1 ] )
												.then( result => {
													this.loadData()
														.then( refreshedTree => {
															this.props.onTreeUpdate( refreshedTree )
																.then( () => {
																	this.setState({
																		...this.state,
																		isSwitchingTab: false,
																		getManagerListPopup: null
																	})
																})
															;
														})
													;
												})
										})
								}}


								addManager={ () => {
									this.setState({
										...this.state,
										getManagerListPopup: null,
										addManagerModal: true
									})
								}}

							/>
						)
						: null
				}


				{
					(!!this.state.addManagerModal)
						? (
							<AdminUserEditModalContent
								isManager={ true }
								currentUserManager={ this.state.currentUserManager }
								close={ forceRefresh => {
									this.setState(
										{
											...this.state,
											getManagerListPopup: null,
											addManagerModal: null
										},
										() => {
											// if( !!forceRefresh ) this.loadData();
										}
									);
								}}
							/>
						)
						: null
				}


{/*		Fine Handling Managers ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}


				<Nav tabs>
					{
						// this.state.tree.tabs
						Object.keys(TABS)
							.map( tabKey => TABS[tabKey] )

							.map((tab, index) => {
								return (
									<NavItem key={index}>
										<NavLink
											id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0] +"-"+ tab.id}
											href="#"
											// className={classnames({ active: this.state.activeTab === tab.id })}
											className={ (this.state.activeTab === tab.id) ? tab.activeClassName : tab.className }

											onClick={() => {

												this.standby()
													.then( () => {



														loadGriStructureValue( this.props.collect.id, tab.id, this.state.tmFilter )

															.then(resultObj => {

																showlog("resultObj");
																showlog(resultObj);


																if( !resultObj || resultObj.length === 0 ) {



																	// this.standby(null, false )
																	// 	.then(() => {


																	this.props.history.push({
																		search: "section="+ TREE_SECTION.DATA_ENTRY +"&area="+ (
																			Object.keys(TABS)
																				.map( tabKey => TABS[tabKey] )
																				.filter( t => t.id === tab.id )
																				.map( t => t.id )
																				.reduce( getLastElementOr, TABS.general.id )
																		)
																	});


																	showlog("standby dovrebbe essere staccato", this.state);


																	this.standby( true )
																		.then( () => {
																			this.setState({
																				...this.state,
																				message: "Nessun dato da mostrare"
																			})
																		})

																	// });

																}
																else {

																	// console.table(resultObj);
																	showlog( exportSeedTableToCSV(resultObj, true) );

																	convertTableToTree( resultObj )
																		.then( tree => {



																			let virtualTree = convertToVirtualTree( tree, resultObj );



																			showlog("controllo virtual tree al click sul tab");
																			showlog(virtualTree);
																			// beautifyLog(virtualTree);



																			// let freshTree = cloneOpenNodes( virtualTree, tree );
																			let freshTree = cloneOpenNodes( tree, virtualTree );
																			// let freshTree = tree;


																			showlog("controllo freshTree al click sul tab");
																			showlog(freshTree);
																			// beautifyLog(virtualTree);



																			this.props.history.push({
																				search: "section="+ TREE_SECTION.DATA_ENTRY +"&area="+ (
																					Object.keys(TABS)
																						.map( tabKey => TABS[tabKey] )
																						.filter( t => t.id === tab.id )
																						.map( t => t.id )
																						.reduce( getLastElementOr, TABS.general.id )
																				)
																			});



																			this.setState({
																					...this.state,
																					tree: freshTree,
																					treeSeed: resultObj,
																					currentTab: tab,
																					activeTab: tab.id,
																					params: {
																						...this.state.params,
																						area: tab.id
																					}
																				},
																				() => {
																					// updateTreeStatusPath(this.props.collect.id, GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0], tab.id);
																					// this.toggle(tab.id);

																					this.props.onTreeUpdate( freshTree, tab.id )
																						.then( resultFatherData => {

																							this.standby( true );


																						})
																					;
																				})


																			;



																		})
																		.catch(e => {
																			errorlog("errore",e);
																			// this.standby( { message: "Si è verificato un errore" }, false );
																		})
																	;

																}



															})
															.catch(e => {
																console.error("error");
																showlog(e);
																// this.standby( { message: "Si è verificato un errore" }, false );
															})



													}) // fine



											}}
										>
											<i
												className={classnames(
													tab.icon,
													'd-lg-none',
													'd-block',
													'mr-1'
												)}
											/>
											<span className="d-none d-lg-block">{tab.name}</span>
										</NavLink>
										{/*
										<UncontrolledTooltip placement="top" target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0] +"-"+ tab.id}>
											<span className="d-none d-lg-block">{tab.name.en}</span>
										</UncontrolledTooltip>
										*/}
									</NavItem>
								);
							})
					}



					{/*<span className={"nav-tab-spacer-wide"}/>*/}


					<TreeToggler
												tree={ this.state.tree }
												onFinish={ newTree => {
													this.setState({
														...this.state,
														tree: newTree
													})
												}}
												className={"tree-toggler-in-srtool-dataentry"}
											/>


					{/*     filtri      */}
					<div className={"srtool-filter-container"}>

					{
						// !!this.props.isCollectDone
						!!isUserRole( role.ASSURANCER )
							? (

								<div className={"srtool-informative-filter"}>
											<h6>Informative</h6>
											<Select
												className="react-select"
												classNamePrefix="react-select"
												name="assurance-filter"
												id="assurance-filter"
												onChange={ changeEvent => {


													// debugger;

													if( !changeEvent.value || changeEvent.value === 0 ) {

														// debugger;

														this.setState({
															...this.state,
															treeCopy: null, // this.state.tree,
															tree: this.state.treeCopy || this.state.tree,
															inWaiting: false,
															assuranceFilter: null
														})
													}
													else {


														let allInfos = getAllTreeInfo( this.state.treeCopy || this.state.tree );



														let displayInformatives =
															allInfos
																.filter( inf => inf.status === changeEvent.id )
														;


														// all chapters owns to-display informative
														let treeTmp = cloneObject( this.state.treeCopy || this.state.tree );


														// filtro sui capitoli
														treeTmp.tabs[0].chapters =
															treeTmp
																.tabs
																.reduce( getLastElementOr, {chapters: []})
																.chapters
																.map( ch => {
																	if(
																		displayInformatives
																			.filter( di => (
																				ch.infos
																					.map( inf => inf.id )
																					.includes( di.id )
																			))
																			.length === 0
																	) {
																		ch.hide = true;
																	}
																	return ch;
																})
														;

														// filtro sulle info dei capitoli filtrati
														treeTmp
															.tabs
															.reduce( getLastElementOr, {chapters: []})
															.chapters
															.map( ch => {
																ch.infos =
																	ch.infos
																		.map( inf => {
																			inf =
																				displayInformatives
																					.map( dinf => {
																						if( !!inf && (inf.id === dinf.id) ) return inf;
																						else {
																							// inf = null;
																							return null;
																						}
																					})
																					.filter( onlyNotNull )
																					.reduce( getLastElementOr, null )
																			;
																			return inf;
																		})
																		.filter( onlyNotNull )
																;
															})

														;


														this.setState({
															...this.state,
															treeCopy: this.state.treeCopy || this.state.tree,
															tree: treeTmp,
															inWaiting: false,
															assuranceFilter: changeEvent.value
														})
													}





												}}
												options={[
													{value: 0, label: "Tutte"},
													...Object.keys(INFORMATIVE_STATUS)
														.map( field => INFORMATIVE_STATUS[ field ] )
														.filter( is => is.id > INFORMATIVE_STATUS.virgin.id )
														.map( is => ({ ...is, value: is.id}))
														.map( is => {
															if( is.id === INFORMATIVE_STATUS.completed.id ) is.label = "Da Validare";
															// if( (is.id === INFORMATIVE_STATUS.completed.id) && !isUserRole( role.ASSURANCER )) is.label = INFORMATIVE_STATUS.completed.adminLabel;
															return is;
														})
												]}
												value={
													[
														{value: 0, label: "Tutte"},
														...Object.keys(INFORMATIVE_STATUS)
															.map( field => INFORMATIVE_STATUS[ field ] )
															.filter( is => is.id > INFORMATIVE_STATUS.virgin.id )
															.map( is => ({ ...is, value: is.id}))
															.map( is => {
																if( is.id === INFORMATIVE_STATUS.completed.id ) is.label = "Da Validare";
																// if( (is.id === INFORMATIVE_STATUS.completed.id) && !isUserRole( role.ASSURANCER )) is.label = INFORMATIVE_STATUS.completed.adminLabel;
																return is;
															})
													]
														.filter( is => is.id === this.state.assuranceFilter )
														.reduce( getLastElementOr, {value: 0, label: "Tutte"})
												}
												placeholder={ <span className={"select-placeholder"}>Tutte</span> }
											/>
										</div>

							)
							: null
					}




					{
						(!!this.state.materialthemes && this.state.materialthemes.length > 0 )
							? (


								<div className={"srtool-informative-filter"}>
											<h6>Tematiche Materiali</h6>
											<Select
												className="react-select"
												classNamePrefix="react-select"
												name="tm-filter"
												id="tm-filter"
												onChange={ changeEvent => {


													showlog("tematica selezionata");
													showlog(changeEvent);


													if( !changeEvent.value || changeEvent.value === 0 ) {

														// debugger;

														this.setState({
															...this.state,
															treeCopy: null, // this.state.tree,
															tree: this.state.treeCopy || this.state.tree,
															inWaiting: false,
															tmFilter: changeEvent.value
														})
													}
													else {


														// materialThemes quella che è stata scelta ( in bvase all id )
														// la materialThemes scelta ha la lista delle infos come id
														// tutte le infos

														let allInfos = getAllTreeInfo( this.state.treeCopy || this.state.tree );

														// showlog("tutte le info dell'albero");
														// showlog( allInfos );

														// showlog("tematiche materiali nello state");
														// showlog( this.state.materialthemes );


														let displayInformatives =
															allInfos
																.filter( inf => (

																	this.state.materialthemes

																		// tematica selezionata ------------------------
																		.filter(mt => mt.id === changeEvent.value)

																		// elemento ------------------------------------
																		.reduce( getLastElementOr, { informative: [] } )

																		// lista di id ---------------------------------
																		.informative
																		.map( i => i.informativeId )

																		// controllo inclusione ------------------------
																		.includes( inf.id )

																))
														;

														// showlog("displayInformatives");
														// showlog( displayInformatives );


														// all chapters owns to-display informative
														let treeTmp = cloneObject( this.state.treeCopy || this.state.tree );


														// filtro sui capitoli
														treeTmp.tabs[0].chapters =
															treeTmp
																.tabs
																.reduce( getLastElementOr, {chapters: []})
																.chapters
																.map( ch => {
																	if(
																		displayInformatives
																			.filter( di => (
																				ch.infos
																					.map( inf => inf.id )
																					.includes( di.id )
																			))
																			.length === 0
																	) {
																		ch.hide = true;
																	}
																	return ch;
																})
														;

														// filtro sulle info dei capitoli filtrati
														treeTmp
															.tabs
															.reduce( getLastElementOr, {chapters: []})
															.chapters
															.map( ch => {
																ch.infos =
																	ch.infos
																		.map( inf => {
																			inf =
																				displayInformatives
																					.map( dinf => {
																						if( !!inf && (inf.id === dinf.id) ) return inf;
																						else {
																							// inf = null;
																							return null;
																						}
																					})
																					.filter( onlyNotNull )
																					.reduce( getLastElementOr, null )
																			;
																			return inf;
																		})
																		.filter( onlyNotNull )
																;
															})

														;


														this.setState({
															...this.state,
															treeCopy: this.state.treeCopy || this.state.tree,
															tree: treeTmp,
															inWaiting: false,
															tmFilter: changeEvent.value
														})

													}


												}}
												options={[
													{value: 0, label: "nessuna tematica materiale"},
													...this.state.materialthemes
														.map( mt => ({value: mt.id, label: mt.name}))
												]}
												placeholder={ <span className={"select-placeholder"}>Seleziona Tematica Materiale</span> }
											/>
										</div>


							)
							: (
								null
								/*
								<WaitingOrNodataMessage
									waiting={ !(!!this.state.materialthemes && this.state.materialthemes.length === 0) }
									waitMessage={"Caricamento Tematiche Materiali"}
									nodataMessage={""}
									className={"bottom-margin-alignment left-alignment"}
									spinnerColor={"success"}
								/>
								 */
							)
					}





					</div>



					{/*		Tasto export
					<ActionButton
						buttonText={"Export CSV"}
						promise={ () => {
							return (
								loadGriStructureValue( this.props.collect.id, 0 )
									.then(data => {
										return (
											exportSeedTableToBusinessCSV(data)
												.then( fileContent => {
													let file = new Blob([fileContent], {type: "text/csv;charset=utf-8"});
													saveAs( file, "Raccolta "+ this.props.collect.name );
													return true;
												})
										);
									})
									.catch( e => {
										errorlog("load gri-structure");
										showlog(e);
										return Promise.reject( e );
									})
							);
						}}
						onResult={ result => {
							showlog("download done");
						}}
						className={"tab-comander-button"}
					/>
					*/}



				</Nav>



				{
					(!!this.state.isSwitchingTab)
						? (
							<React.Fragment>
								{/*
								<pre>switching... [{ !!this.state.isSwitchingTab ? <code>true</code> : <code>false</code> }]</pre>
								*/}
								<WaitingOrNodataMessage
									waiting={ true }
									waitMessage={translate("Waiting")}
									nodataMessage={translate("No Data")}
									fullHeight={ true }
									spinnerColor={"success"}
								/>
							</React.Fragment>
						)
						: (

							<React.Fragment>
								{/*
								<pre>switching... [{ !!this.state.isSwitchingTab ? <code>true</code> : <code>false</code> }]</pre>
								<pre>
									{
										beautify( this.state.tree.tabs )
									}
								</pre>
								*/}
								<TabContent activeTab={this.state.activeTab} className={"srtool-target-page"}>
									{
										this.state.tree.tabs
											.map((tab, index) => {
												return (
													<TabPane
														id={`tab-${tab.id}`}
														tabId={tab.id}
														key={index}
													>

														{/*
														<span>
															<code>TABS</code><br/>
															<pre>
																{
																	beautify({
																		notWaitingComponent: (!!tab.chapters && tab.chapters.length > 0),
																		waiting: !(!!tab.chapters && tab.chapters.length === 0)
																	})
																}
															</pre>
														</span>
														*/}

														{

															(!!tab.chapters && tab.chapters.length > 0)
																? (
																	<Row>
																		<Col sm="12" id={`tab-${ tab.id }-content`}>
																			<div style={{ padding: "20px" }}>
																				{
																					(!!!tab.userManagerName || tab.userManagerName.indexOf("NessunNome") !== -1)
																						? null
																						: (
																							<React.Fragment>
																								<span>&nbsp;&nbsp;{ translate("Manager Responsible") }&nbsp;&nbsp;</span>
																								<i className="mdi mdi-account" />
																								<span className={"info-gri-manager-name-label req-button-label"} >{ tab.userManagerName }</span>
																							</React.Fragment>
																						)
																				}
																			</div>
																			{ this.getSubTreeChapter( tab.chapters ) }
																		</Col>
																	</Row>
																)
																: (
																	<React.Fragment>
																		<WaitingOrNodataMessage
																			waiting={ false }
																			nodataMessage={ <p className="mt-3">TAB senza contenuti</p> }
																		/>
																		{/*<OverWaitingPannel
																			className={"over-waiting-panel-white"}
																		/>*/}
																	</React.Fragment>
																)

														}

														<GotoTopComponent
															propagateClickEvent={ false }
															domElement={ document.getElementById(`tab-${tab.id}-content`) }
															alwaysVisible={ false }
															className={"srtool-scroll-to-top-button"}
														/>



													</TabPane>
												);
											})
									}
								</TabContent>


							</React.Fragment>

						)
				}



				{/* HORROR VACUI */}
				{
					(this.state.tree.tabs.map(t => t.chapters.map(ch => ch.id).join(",")).join("|").trim() === "")
						? (
							<WaitingOrNodataMessage
								waiting={ false }
								nodataMessage={ <p className="mt-3">{translate("Waiting")}</p> }
							/>
						)
						: null
				}






				{
					(!!this.state.inWaiting)
						? <StandBySystemModalComponent message={ this.state.processingMessage } />
						: null
				}


				{
					(!!this.state.message)
						? (
							<ErrorMessageModalComponent
								noheader={ true }
								noHazard={ true }
								message={ this.state.message }
								onClose={ () => {
									// al click o dopo 5sec

									this.setState({
										...this.state,
										message: null
									})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.infoHundredThreePopup)
						? (
							<GriInfoHundredThreeModal
								collect={ this.props.collect }
								info={ this.state.infoHundredThreePopup.id }
								isChapter={ !!this.state.isChapter }
								onClose={ saved => {

									// debugger;
									if( !!saved ) {
										let treeTmp = cloneObject(this.state.tree);
										treeTmp = cloneOpenNodes( this.state.tree, treeTmp );
										getNodeByPath(
											treeTmp,
											this.state.infoHundredThreePopup.path,
											node => {
												node.is103Completed = !!saved;
												this.setState({
													...this.state,
													tree: treeTmp,
													infoHundredThreePopup: null
												});
											}
										);
									}
									else {
										this.setState({
											...this.state,
											infoHundredThreePopup: null
										});
									}

								}}
							/>
						)
						: null
				}


				{
					(!!this.state.previousMonthlyValuesPopup)
                        ? (
							<TreeDataEntryMonthlyVersion
								structureId={ this.state.previousMonthlyValuesPopup }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										previousMonthlyValuesPopup: null
									});
								}}
							/>
						)
						: null
                }

				{
					(!!this.state.monthlyTimingPopup)
						? (
							<TreeDataEntryValueModalComponent
								id={ this.state.monthlyTimingPopup }
								node={ this.state.node }
								collect={ this.props.collect }
								isDisabled={ !!this.isNodeDisabled( this.state.nodeInformative ) }
								onConfirm={ data => {

									return (
										new Promise((success, fail) => {

											let activeTab = this.state.activeTab;
											this.loadData()
												.then( refreshedTree => {

													this.props.onTreeUpdate( refreshedTree, activeTab )
														.then( freshTree => {

															this.setState({
																...this.state,
																// activeTab: activeTab,
																monthlyTimingPopup: null
															},
																() => {
																	success();
																}
															)
														})
													;
												})
											;

										})
									);
									;

								}}
								onClose={ data => {
									this.setState({
										...this.state,
										monthlyTimingPopup: null
									})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.newZoneModal)
						? (
							<TreeDataEntryNewZoneModalComponent
								id={ this.state.newZoneModal.split("-")[0] }
								timing={ this.state.newZoneModal.split("-")[1] }
								structureId={ parseInt(this.state.newZoneModal.split("-")[2], 10) }
								collect={ this.props.collect }
								virtualNode={ this.state.reqVirtualNode }
								onConfirm={ data => {

									showlog("posizione y");
									// showlog( window.scrollY );
									// showlog( document.getElementById(`tab-${ this.state.activeTab }-content`) );
									// showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).scrollY );
									// showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).scrollTop );
									// showlog( document.getElementById(`tab-${ this.state.activeTab }`).scrollY );
									// showlog( document.getElementById(`tab-${ this.state.activeTab }`).scrollTop );
									showlog( document.getElementById(`tab-${ this.state.activeTab }`).parentElement.parentElement.parentElement.scrollTop );
									// let positionY = window.scrollY;
									let positionY = document.getElementById(`tab-${ this.state.activeTab }`).parentElement.parentElement.parentElement.scrollTop;

									this.standby()
										.then( () => {
											loadGriStructureValue( this.props.collect.id, this.state.activeTab )
												.then(resultObj => {
													convertTableToTree( resultObj )
														.then( tree => {
															let virtualTree = convertToVirtualTree( tree, resultObj );
															let freshTree = cloneOpenNodes( this.state.tree || tree, virtualTree );

															this.standby( true )
																.then( () => {

																	this.setState({
																		...this.state,
																		tree: freshTree,
																		// newZoneModal: null
																	},
																		() => {
																			window.scrollTo(0, positionY);

																			let refreshedReqVirtualNode = getTreeNodeByPath( virtualTree, this.state.reqVirtualNode.path );


																			/*/
																			LEGGIMI
																			c'era una stranissima anomalia,
																			quando si creava l'albero e i nodi dopo il refresh dopo avere
																			creato una nuova entità, il nodo antecedente al nodo che aveva creato
																			la nuova entità veniva visualizzato in un  posto errato, per poi
																			aggiustarsi immediatamente subito dopo aver
																			fatto qualcosa ( tipo l'apertura di un nodo )

																			La causa potrebbe essere una serie di circostanze
																			tra cui lo scroll

																			In questa maniera ( settando di nuovo lo state con newZoneModal a null )
																			l'anomalia sparisce
																			 */
																			this.setState({
																				...this.state,
																				newZoneModal: null,
																				virtualTree: {
																					...this.state.virtualTree,
																					// focus al nuovo tab
																					zone:
																						refreshedReqVirtualNode.zones
																							.sort( sortById )
																							.reduce( getLastElementOr, {id: 0} )
																							.id
																				}
																			},
																				() => {

																					// window.scrollTo(0, positionY);
																					// document.getElementById(`tab-${ this.state.activeTab }-content`).scrollIntoView();
																					// document.getElementById(`tab-${ this.state.activeTab }-content`).scrollTo(0, positionY);
																					document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.parentElement.scrollTo(0, this.state.pointToScroll);
																					showlog("scrolled to "+ positionY);
																					showlog("scrolled to "+ this.state.pointToScroll);
																				}
																			)


																		}
																	)
																})
															;

														})
														.catch(e => {
															errorlog("errore",e);
															// this.standby( { message: "Si è verificato un errore", newZoneModal: null }, false );
														})
													;

												})
												.catch(e => {
													console.error("error");
													showlog(e);
													// this.standby( { message: "Si è verificato un errore", newZoneModal: null }, false );
												})
										})

								}}
								onClose={ data => {
									this.setState({
										...this.state,
										newZoneModal: null
									})
								}}
							/>
						)
						: null
				}



				{
					(!!this.state.goalPopupOpen)
						? (
							<GriRequirementGoalModal
								requirement={ this.state.goalPopupOpen }
								// disabled={ !!this.isNodeDisabled( this.state.goalPopupOpen ) }
								isDisabled={ !!this.isNodeDisabled( this.state.nodeInformative ) }
								collect={ this.props.collect }
								level={ this.state.goalPopupLevel }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										goalPopupOpen: false
									});
								}}
								onSave={ closeEvent => {

									this.setState({
										...this.state,
										goalPopupOpen: false,
										activeTab: this.state.activeTab
									},

										() => {

											let activeTab = this.state.activeTab;
											this.loadData()
												.then( refreshedTree => {

													this.props.onTreeUpdate( refreshedTree )
														.then( freshTree => {

														})
													;
												})
											;
										}

									)

								}}
							/>
						)
						: null
				}


				{
					!!this.state.compilationRequirementNode
						? (
							<Modal
								isOpen={ true }
								toggle={ () => {
									this.setState({
										...this.state,
										compilationRequirementNode: null
									})
								}}
							>
								<ModalHeader
									toggle={ () => {
										this.setState({
											...this.state,
											compilationRequirementNode: null
										})
									}}
								>
									<h4>{ translate("Compilation_Requirements") }</h4>
								</ModalHeader>
								<ModalBody>
									<div>{ this.state.compilationRequirementNode }</div>
								</ModalBody>
							</Modal>
						)
						: null
				}

				{
					(!!this.state.notePopup)
						? (
							<QuestionNoteModal
								ratingId={ this.props.collect.id }
								question={ this.state.notePopup }	// in questo caso una info
								// isInAudit={ !!this.isNodeDisabled( this.state.notePopup ) }
								isInAudit={
									!!isUserRole( role.ASSURANCER ) ||
									(this.props.collect.raw.state === COLLECT_STATUS.assuranceDone.id)
								} // disabilitazione
								isSRTool={ true }
								onSave={ result => {
									let treeTmp = cloneObject(this.state.tree);
									treeTmp = cloneOpenNodes( this.state.tree, treeTmp );
									getNodeByPath(
										treeTmp,
										this.state.notePopup.path,
										node => {
											node.hasNote = !!result;
											this.setState({
												...this.state,
												tree: treeTmp
											});
										}
									);

									/*
									let treeTmp = cloneObject(this.state.tree);
									let nodeTmp = getTreeNodeByPath(treeTmp, this.state.notePopup.path);
									nodeTmp.hasNote = true;
									this.setState({
										...this.state,
										tree: treeTmp
									});
									*/

								}}
								onClose={ () => {
									this.setState({
										...this.state,
										notePopup: null
									})
								}}
							/>
						)
						: null
				}




				{
					((window.location.href.indexOf("help") !== -1) && (window.location.href.indexOf("rawdataresponse") !== -1))
						? (

							<div className={"rawdataresponse"}>
								Dati chiamata
								<table>
									<tbody>
										{
											!!this.state.seeds
												? (
													this.state.seeds
														.filter( (row, index) => index < 500 )
														.map( (row, index) => {

															if( index % 20 === 0 ) {
																return (
																	<tr>
																		{
																			Object.keys(row)
																				.map( th => (<td style={{border: "solid 1px #ddd", margin: "5px", padding: "5px", fontWeight: "bold"}}>{ th }</td>))
																		}
																	</tr>
																);
															}
															else {
																return (
																	<tr>
																		{
																			Object.keys( row )
																				.map( field => (<td style={{border: "solid 1px #ddd", margin: "5px", padding: "5px"}}>{ JSON.stringify( row[ field ] ).substr(0, 32) }</td>))
																		}
																	</tr>
																)
															}
														})
												)
												: null
										}
									</tbody>
								</table>
							</div>

						)
						: null
				}


			</Suspense>
		);
	}



	getSubTreeChapter( chapters ) {


		let chapterColorClassInfo =
			Object.keys(TABS)
				.map( tabKey => TABS[tabKey] )
				.filter( tab => tab.id === this.state.activeTab )
				.reduce( getLastElementOr, { lightClassName: "" } )
				.className
		;

		let colorClassInfo =
			Object.keys(TABS)
				.map( tabKey => TABS[tabKey] )
				.filter( tab => tab.id === this.state.activeTab )
				.reduce( getLastElementOr, { lightClassName: "" } )
				.lightClassName
		;

		let colorClassRequirement =
			Object.keys(TABS)
				.map( tabKey => TABS[tabKey] )
				.filter( tab => tab.id === this.state.activeTab )
				.reduce( getLastElementOr, { lighterClassName: "" } )
				.lighterClassName
		;

		return (
			chapters

				.filter( ch => !!ch.infos.some( inf => !!inf.applicability) )
				.map( (ch, chIndex) => {
					return (
						<Card className={"mb-0" + (!!ch.hide ? " card-hide" : "")} key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id} >
							<CardHeader
								className={

									[
										chapterColorClassInfo
										, "tree-node-chatper"
									]
									.join(" ")

										}
									>
										<NavLink
											id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] + "-" + ch.id}
											href="#"
											onClick={clickEvent => {
												let treeTmp = cloneObject(this.state.tree);
												let nodeTmp = getTreeNodeByPath(treeTmp, ch.path);
												nodeTmp.open = !getTreeNodeByPath(this.state.tree, ch.path).open;
												this.setState({
													...this.state,
													tree: treeTmp
												});
											}}
											className={"custom-accordion-title-dataentry"}
										>

									<div
										className="d-block pt-2 pb-2"
									>
										GRI { ch.infos.reduce( getLastElementOr, null).name.split("-")[0] +" - "+ ch.name }

										{

											(this.state.activeTab === TABS.general.id)
												? null
												: (
													(!!ch.material)
														? <span className={"req-button-label"} >{translate("Material Topic")}</span>
														: <span className={"req-button-label"} >{translate("Non Material Topic")}</span>
												)

										}

									</div>






								</NavLink>

										<span className="float-right color-policy-text-color-evident">

											<NavLink
												id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}
												href="#"
												onClick={ clickEvent => {
													let treeTmp = cloneObject( this.state.tree );
													let nodeTmp = getTreeNodeByPath( treeTmp, ch.path );
													nodeTmp.open = !getTreeNodeByPath( this.state.tree, ch.path ).open;
													this.setState({
														...this.state,
														tree: treeTmp
													});
												}}
											>

												{
													// (!!getTreeNodeByPath( this.state.tree, ch.path ).open)
													(!!ch.open)
														? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow"/>
														: <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow"/>
												}

											</NavLink>
			                            </span>


										<span className="float-right sirating-themes-accordion-title button-hundred">
										{
											(
												!!hasHundredThreeInformative(getTreeNodeByPath(this.state.tree, ch.path.filter(pop)).id)
												&& !this.props.isCollectDone
												&& (this.props.collect.raw.version !== 1)
											)
												? (
													<ActionButton
														buttonText={
															<React.Fragment>
																<span>&nbsp;&nbsp;</span>
																<i className={"mdi mdi-script"}/>
																<span className={"button-label-when-icon"}>&nbsp;{ !!this.props.collect.raw.version ? "GRI 3" : "103"  }&nbsp;&nbsp;</span>
															</React.Fragment>
														}
														color={ !!ch.is103Completed ? "primary" : "disabled" }
														className={"hundred-three-button" + (!!ch.is103Completed ? " green-background" : " red-background")}
														promise={() => Promise.resolve()}
														onResult={() => {
															this.setState({
																...this.state,
																infoHundredThreePopup: ch,
																isChapter: true
															})
														}}
													/>
												)
												: null
										}



										</span>

									</CardHeader>
									{/*<UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}>*/}
									<Collapse isOpen={!!ch.open}
											  toggler={"#" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] + "-" + ch.id}>
										<CardBody>


									{   // informativa --------------------------------------------------------------------------------------------------------------------------------------------------------------------
										ch.infos
											.filter( info => !!info.applicability )
											.map( (info, infoIndex) => {

												if( !ch.open ) return null;

												// immagini
												let additionalRequirement_IT = info.additionalRequirement;
												if( !!info.additionalRequirement ) {

															additionalRequirement_IT =
																info.additionalRequirement
																	.split(" #####")
																	.map((part, partIndex) => {
																		if (partIndex > 0) {
																			if (part[0] !== " ") {
																				return (
																					<React.Fragment>
																						<img
																							src={HOST + "/assets/formula/ra/" + info.name + "" + part.split(" ")[0] + ".png"}
																							alt={"Formula " + part.split(" ")[0]}
																							className={""}/>
																						<span>{part.split(" ").filter((p, i) => i > 0).join(" ")}</span>
																					</React.Fragment>
																				);
																			} else {
																				return (
																					<React.Fragment>
																						<img
																							src={HOST + "/assets/formula/ra/" + info.name + ".png"}
																							alt={"Formula"}
																							className={""}/>
																						<span>{part}</span>
																					</React.Fragment>
																				);
																			}
																		}
																		return (
																			<span>{part}</span>
																		);
																	})
															;


														}


														let informativeStatus =
															Object.keys(INFORMATIVE_STATUS)
																.map( field => INFORMATIVE_STATUS[ field ] )
																.filter( is => !!is.operator )
																.map( is => ({...is, value: is.id}))
																.filter( is => is.id === info.status )
																.reduce( getLastElementOr, null )
														;


														let informativeStatusStyle =
															Object.keys(INFORMATIVE_STATUS)
																.map( field => INFORMATIVE_STATUS[ field ] )
																.filter( is => is.id === info.status )
																.map( is => {
																	if( is.id === INFORMATIVE_STATUS.validated.id ) {
																		return ({
																			background: "#1f8",
																			color: "#444"
																		});
																	}
																	return ({
																		background: "#18f",
																		color: "#fff"
																	});
																})
																.reduce( getLastElementOr, {} )


														return (
															<Card className="mb-0"
																  key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id}>


																<CardHeader
																	className={
																		[
																			colorClassInfo
																			, "tree-node-header-container"
																		]
																			.join(" ")
																	}
																	id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.structureId}
																>
																	<Row className={"tree-node-header"}>
																		<Col
																			sm={8}
																			className={"tree-node-header-body"}
																			onClick={clickEvent => this.toggleNode(info)}
																		>

																	{ this.helperNode( info ) }
																	<strong>{ info.name }</strong>




																	{
																		(!!info.guideLines && !!info.guideLines)
																			? (
																				<React.Fragment>

																					<GuideLineIconSvgComponent
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-guidelinees"}
																						className={"svg-icon-container info-lg-svg-icon-container tooltip-icon-cursor"}
																					/>

																					<UncontrolledPopover
																						placement="right"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-guidelinees"}
																						trigger="legacy"
																						className={"info-guide-lines-popover"}
																					>
																						<PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
																						<PopoverBody>
																							<div>{ info.guideLines }</div>
																							{ GUIDELINES_POST_IT }
																						</PopoverBody>
																					</UncontrolledPopover>

																				</React.Fragment>
																			)
																			: null
																	}

																	{
																		(!!info.reccomandation && !!info.reccomandation)
																			? (

																				<React.Fragment>
																					{/*}
																					<ReccomendationIconSvgComponent
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-reccomandation"}
																						className={"svg-icon-container info-recc-svg-icon-container tooltip-icon-cursor"}
																					/>
																					*/}
																					<img
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-reccomandation"}
																						src={ Raccomandations }
																						className="tooltip-icon-cursor icon-in-srtool"
																					/>

																					<UncontrolledPopover
																						placement="top"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-reccomandation"}
																						trigger="legacy"
																						className={"info-guide-lines-popover"}
																					>
																						<PopoverHeader>{translate("Recommendations")}</PopoverHeader>
																						<PopoverBody>
																							<span className="d-none d-lg-block">{ info.reccomandation }</span>
																							{ GUIDELINES_POST_IT }
																						</PopoverBody>
																					</UncontrolledPopover>
																				</React.Fragment>

																			)
																			: null
																	}


																	{
																		(!!info.additionalRequirement && !!info.additionalRequirement)
																			? (

																				<React.Fragment>
																					<AdditionalRequirements
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-additional-requirements"}
																						className={"svg-icon-container info-recc-svg-icon-container tooltip-icon-cursor"}
																					/>
																					<UncontrolledPopover
																						placement="top"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-additional-requirements"}
																						trigger="legacy"
																						className={"info-guide-lines-popover"}
																					>
																						<PopoverHeader>Requisiti Aggiuntivi</PopoverHeader>
																						<PopoverBody>
																							<span className="d-none d-lg-block">{ additionalRequirement_IT }</span>
																						</PopoverBody>
																					</UncontrolledPopover>
																				</React.Fragment>

																					)
																					: null
																			}


																			{ /* ------------   in Wait --------------------------------------------------------------------* /
																				(
																					!!this.props.isCollectDone
																					&& (
																						isUserRole( role.SRTool_ADMIN )
																						|| isUserRole( role.SRTool_MANAGER )
																					)
																					&& info.status === INFORMATIVE_STATUS.inWait.id
																				)
																					? (
																						<div className={"svg-icon-container"}>
																							<div className={"tree-node-controller srtool-warning-controller"}>
																								<span className={""}>{ emoj( EMOJ.two_o_clock ) }  in attesa di validazione</span>
																								{/*<span>{ info.nonCompliantText }</span>* /}
																							</div>
																						</div>
																					)
																					: null
																			*/}


																			{/* visibile sempre */}
																						<span className={"tag"} style={ informativeStatusStyle }>
																							{
																								Object.keys(INFORMATIVE_STATUS)
																									.map( field => INFORMATIVE_STATUS[ field ] )
																									.filter( is => is.id === info.status )
																									.map( is => {
																										if( isUserRole( role.ASSURANCER ) && is.id === INFORMATIVE_STATUS.completed.id ) {
																											is.label = "Da Validare";
																										}
																										if( !isUserRole( role.ASSURANCER ) ) is.label = is.adminLabel;

																										return is;
																									})
																									.map( is => is.label )
																									.reduce( getLastElementOr, null )
																							}
																						</span>



																	<br/>
																	{ info.description }

																</Col>
																<Col sm={4} className={"tree-node-header-commands"}>


																			{/*
																				(!!info.applicability && !ch.material)
																					? (
																						<React.Fragment>
																							<span className={"tag not-material"} >(Topic NON Materiale)</span>
																							<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
																						</React.Fragment>
																					)
																					: null
																			*/}






																	{
																		(
																			((info.status === INFORMATIVE_STATUS.virgin.id) || (info.status === INFORMATIVE_STATUS.completed.id))
																			&& hasPermission( permissions.SET_SRTOOL_INFORMATIVE_COMPLETE )
																			&& (this.props.collect.raw.state !== COLLECT_STATUS.assuranceDone.id)
																		)
																			? (
																				<div className={"informative-status-select-container"}>
																					<Select
																						className="react-select informative-status-select"
																						classNamePrefix="react-select"
																						name="informative-completed-status"
																						id="informative-completed-status"
																						onChange={ changeEvent => {

																							showlog(changeEvent);


																							this.setState({
																									...this.state,
																									assuranceInformativeWait: [
																										...this.state.assuranceInformativeWait.filter( ai => ai.id !== info.id ),
																										{ id: info.id, status: changeEvent.value }
																									]
																								},
																								() => {



																											setSRToolAudit(
																												this.props.collect.id,
																												info.id,
																												changeEvent.value
																											)
																												.then(result => {

																													let treeTmp = cloneObject(this.state.tree);
																													getNodeByPath(
																														treeTmp,
																														info.path,
																														node => {
																															node.status = changeEvent.value;
																															this.setState({
																																...this.state,
																																assuranceInformativeWait: this.state.assuranceInformativeWait.filter( ai => ai.id !== info.id ),
																																tree: treeTmp
																															})

																														}
																													);

																												})
																												.catch(e => {
																													errorlog("set SRTool change status", e);
																													this.setState({
																														...this.state,
																														assuranceInformativeWait: this.state.assuranceInformativeWait.filter( ai => ai.id !== info.id )
																													})
																												})
																											;


																							})


																						}}
																						options={[
																							{ value: INFORMATIVE_STATUS.virgin.id, label: INFORMATIVE_STATUS.virgin.adminLabel },
																							{ value: INFORMATIVE_STATUS.completed.id, label: INFORMATIVE_STATUS.completed.adminLabel }
																						]}
																						value={
																							Object.keys(INFORMATIVE_STATUS)
																								.map( field => INFORMATIVE_STATUS[ field ] )
																								.filter( is => is.id === info.status )
																								.map( is => ({value: is.id, ...is}))
																								.map( is => {
																									if( !isUserRole( role.ASSURANCER ) ) is.label = is.adminLabel;
																									return is;
																								})
																								.reduce( getLastElementOr, null )
																						}
																						placeholder={ <span className={"select-placeholder"}>Seleziona Status</span> }
																					/>
																					{
																						(!!(this.state.assuranceInformativeWait.filter(infoInWait => infoInWait.id === info.id).reduce( getLastElementOr, null )))
																							? (
																								<WaitingOrNodataMessage
																									waiting={ true }
																									waitMessage={" "}
																									spinnerColor={"success"}
																									className={"srtool-audit-sectioned-waiting"}
																								/>
																							)
																							: <span className={"wait-wheel-horror-vacui"}/>
																					}
																				</div>
																			)
																			: null
																	}



																			<i
																				className={"mdi mdi-attachment sirating-question-attachment-icon srtool-info-attachment cursor-pointer"}
																				onClick={clickEvent => {
																					showlog("modale degli allegati");
																					this.setState({
																						...this.state,
																						attachmentModal: info
																					});
																				}}
																			/>
																			{
																				// (!!info.attachments && info.attachments.length > 0)
																				!!info.attachmentsNumber
																					? (
																						<span
																							className={"srtool-attachment-magnitude "}
																						>
																							{
																								(info.attachmentsNumber > 99)
																									? "+99"
																									: info.attachmentsNumber
																							}
																						</span>
																					)
																					: null
																			}





																			<span
																				className={"cursor-pointer "+ (!!info.hasNote ? "srtool-noted-icon" : "srtool-note-icon")}
																				onClick={clickEvent => {
																					clickEvent.stopPropagation();
																					this.setState({
																						...this.state,
																						notePopup: info
																					})
																				}}
																			>
																				{
																					!!info.hasNote
																						? <div className={"srtool-note-present-status"}/>
																						: null
																				}
																				<NoteIconSvgComponent/>
																			</span>

																	{/*
																		(!!hasHundredThreeInformative(getTreeNodeByPath(this.state.tree, info.path.filter(pop)).id))
																			? (
																				<ActionButton
																					buttonText={
																						<React.Fragment>
																							<span>&nbsp;&nbsp;</span>
																							<i className={"mdi mdi-script"}/>
																							<span className={"button-label-when-icon"} >
																								&nbsp;103&nbsp;&nbsp;
																							</span>
																						</React.Fragment>
																					}
																					color="primary"
																					className={"hundred-three-button"}
																					promise={() => Promise.resolve()}
																					onResult={() => {
																						this.setState({
																							...this.state,
																							infoHundredThreePopup: info.id,
																							isChapter: false
																						})
																					}}
																				/>
																			)
																			: null
																	*/}




																	{
																		(!!info.open)
																			? <i className="mdi mdi-minus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( info ) }/>
																			: <i className="mdi mdi-plus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( info ) }/>
																	}
																</Col>
															</Row>

																	<Row className={"tree-node-controllers tree-node-controllers-informative"}>

																		<div className={"tree-node-controllers-group"}>
																			{
																				(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																					? (

																						<div
																							className={"tree-node-controller"}
																						>

																							<Label
																								className={"tree-node-controller-label"}
																							>
																								Manager
																							</Label>

																							{
																								!!this.isNodeDisabled(info)
																									? (
																										<span
																											className={"tree-node-controller-text-button" + (!!info.userManager ? "" : " tree-node-controller-text-button-empty")}>
																												{
																													(!!info.userManager)
																														? info.userManagerName
																														: "Nessun Manager"
																												}
																											</span>
																									)
																									: (
																										<ActionButton
																											buttonText={
																												<React.Fragment>
																													<i className="mdi mdi-account tree-node-controller-text-button-icon"/>
																													<span
																														className={"tree-node-controller-text-button" + (!!info.userManager ? "" : " tree-node-controller-text-button-empty")}>
																														{
																															(!!info.userManager)
																																? info.userManagerName
																																: translate("Select")
																														}
																													</span>
																												</React.Fragment>
																											}
																											color={(!!info.userManager) ? "success" : "primary"}
																											className={"tree-node-controller-button"}
																											promise={() => {
																												return Promise.resolve();
																											}}
																											onResult={result => {

																												if (!!hasPermission(permissions.SET_COLLECT_MANAGER)) {
																													this.setState({
																														...this.state,
																														getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.activeTab).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id,
																														currentUserManager: info.userManager
																													})
																												}

																											}}
																											onError={e => {

																											}}
																										/>
																									)
																							}

																						</div>
																					)
																					: null
																			}

																			<div className={"tree-node-controller"}>
																				{/*
																					<Label className={"tree-node-controller-label"}>Timing</Label>
																					<span>
																						{
																							(info.timing === 1)
																								? "Mensile"
																								: (
																									(info.timing === 0)
																										? "Annuale"
																										: "Mensile"
																								)
																						}
																					</span>
																				*/}
																			</div>
																		</div>



																		{/*
																		<div className={"tree-node-controllers-group"}>
																			<div className={"tree-node-controller tree-node-inline-multi-controller"}>
																				<code>status: <pre>{ beautify( info.status ) }</pre></code>
																				<code>comment: <pre>{ info.nonCompliantText }</pre></code>
																			</div>
																		</div>
																		*/}








																		{ /* ------------   Answer --------------------------------------------------------------------*/
																			(
																				// !!this.props.isCollectDone
																				// (
																				// 	isUserRole( role.SRTool_ADMIN )
																				// 	|| isUserRole( role.SRTool_MANAGER )
																				// 	|| isUserRole( role.ADMIN )
																				// 	|| isUserRole( role.PROJECT_MANAGER )
																				// 	|| isUserRole( role.ARB_OPERATOR )
																				// 	|| isUserRole( role.SUPER_ADMIN )
																				// ) &&
																				!!hasPermission( permissions.ANSWER_SRToolINFORMATIVE_REQUEST )
																				&& info.status === INFORMATIVE_STATUS.notCompliant.id
																			)
																				? (
																					<div className={"tree-node-controllers-group"}>
																						<div className={"tree-node-controller srtool-warning-controller"}>
																							<span className={"srtool-warning "+ COLOR_POLICY.warning.textClass}>{ emoj( EMOJ.warning ) }  ATTENZIONE</span>
																							<span>{ info.nonCompliantText }</span>

																							<Input
																								type={"textarea"}
																								rows={ 5 }
																								name={"non-compliant-info-answer-"+ info.id}
																								id={"non-compliant-info-answer-"+ info.id}
																								placeholder={"Risposta"}
																								className={""}
																							/>

																							<ActionButton
																								buttonText={"Richiedi validazione"}
																								promise={ () => {

																									return (
																										new Promise((success, fail) => {
																											setSRToolAudit(
																												this.props.collect.id,
																												info.id,
																												INFORMATIVE_STATUS.inWait.id,
																												document.getElementById("non-compliant-info-answer-" + info.id).value
																											)
																												.then(result => {


																													let treeTmp = cloneObject(this.state.tree);
																													getNodeByPath(
																														treeTmp,
																														info.path,
																														node => {
																															// showlog("getNodeByPath callback", node);
																															// showlog( treeTmp );
																															// showlog( this.state.tree );
																															node.status = INFORMATIVE_STATUS.inWait.id;
																															success( treeTmp );
																														}
																													);



																												})
																												.catch(e => {
																													errorlog("set SRTool Audit answer", e);
																													fail();
																												})
																										})
																									)

																								}}
																								onResult={ refreshedTree => {
																									showlog("salvataggio risposta non-compliant info andato con successo");
																									showlog( refreshedTree );
																									this.setState({
																										...this.state,
																										tree: refreshedTree
																									})
																								}}
																								onError={ error => {
																									errorlog("send non-compliant answer informative message", error);
																								}}
																							/>
																						</div>
																					</div>
																				)
																				: null
																		}


																		{ /* -------- Assurance ---------------- */
																			(/*!!this.props.isCollectDone &&*/ isUserRole( role.ASSURANCER ) && (this.props.collect.raw.state !== COLLECT_STATUS.assuranceDone.id) )
																				? (
																					<div className={"tree-node-controllers-group"}>

																						<div className={"tree-node-controller "+ ((info.status === INFORMATIVE_STATUS.inWait.id) ? "" : "tree-node-inline-multi-controller")}>


																							{/* -------- Answer ---------*/}
																							{
																								(info.status === INFORMATIVE_STATUS.inWait.id)
																									? (
																										<span>{ info.nonCompliantText }</span>
																									)
																									: null
																							}


																							<Select
																								className={"react-select non-compliant-select"}
																								classNamePrefix="react-select"
																								name={"audit-informative-" + info.id}
																								id={"audit-informative-" + info.id}
																								onChange={changeEvent => {


																									this.setState({
																										...this.state,
																										assuranceInformativeWait: [...this.state.assuranceInformativeWait, info.id],
																										assuranceInformative: [
																											...this.state.assuranceInformative.filter( ai => ai.id !== info.id ),
																											{ id: info.id, status: changeEvent.id }
																										]
																									},
																										() => {

																											new Promise((onEnd, onError) => {


																												if( changeEvent.id === INFORMATIVE_STATUS.notCompliant.id ) {
																													onEnd({refreshedTree: null, error: null, isValidateAssuranceActive: this.props.collect.isValidateAssuranceActive});
																												}
																												else {
																													setSRToolAudit( this.props.collect.id, info.id, changeEvent.id )
																														.then( result => {


																															showlog( "result of set status" );
																															showlog( result );


																															let treeTmp = cloneObject(this.state.tree);
																															// let treeTmpp = cloneObject(this.state.tree);

																															getNodeByPath(
																																treeTmp,
																																info.path,
																																node => {


																																	showlog("getNodeByPath callback", node);
																																	// showlog( treeTmpp );
																																	showlog( treeTmp );
																																	showlog( this.state.tree );
																																	node.status = changeEvent.id;
																																	// info.status = changeEvent.id;



																																	onEnd({refreshedTree: treeTmp, error: null, isValidateAssuranceActive: result.isValidateAssuranceActive});

																																}
																															);
																														})
																														.catch( e => {
																															errorlog("set SRTool Audit", e);
																															onEnd({refreshedTree: null, error: e});
																														})
																												}



																											})
																											.then( ({refreshedTree, error, isValidateAssuranceActive}) => {
																												this.setState({
																													...this.state,
																													tree: refreshedTree || this.state.tree,
																													assuranceInformativeWait: this.state.assuranceInformativeWait.filter(ai => ai !== info.id),
																													srtoolAuditError: [
																														...this.state.srtoolAuditError.filter( ai => ai.id !== info.id ),
																														(!!error ? { id: info.id, error: error } : null)
																													]
																													.filter( onlyNotNull ),
																													isValidateAssuranceActive: isValidateAssuranceActive
																												},
																													() => {
																														// non strettamente necessaria
																														// this.loadData();

																														if( typeof this.props.assuraceIsActive === "function" ) this.props.assuraceIsActive( !!isValidateAssuranceActive );
																													})
																											})

																										}
																									)


																								}}
																								options={
																									[
																										// { id: 0, label: <span className={"select-placeholder"}>Seleziona validità</span> },
																										...Object.keys(INFORMATIVE_STATUS)
																											.map( field => INFORMATIVE_STATUS[ field ] )
																											.filter( is => !!is.operator )
																											.map(qs => ({...qs, value: qs.id }))
																									]
																								}
																								defaultValue={ informativeStatus }
																								placeholder={<span className={"select-placeholder"}>Seleziona validità</span>}
																							/>
																							{
																								(!!(this.state.assuranceInformativeWait.filter(id => id === info.id).reduce( getLastElementOr, null )))
																									? (
																										<WaitingOrNodataMessage
																											waiting={ true }
																											waitMessage={" "}
																											spinnerColor={"success"}
																											className={"srtool-audit-sectioned-waiting"}
																										/>
																									)
																									: null
																							}
																						</div>
																						{

																							(
																								// selezione
																								!!isNotEmptyArray(this.state.srtoolAuditError) && !!this.state.srtoolAuditError.filter(ai => ai.id === info.id).reduce( getLastElementOr, null )
																							)
																								? (
																									<div className={"tree-node-controller"}>
																										{/* Qualcosa è andato storto */}
																									</div>
																								)
																								: (
																									(
																										// selezione
																										!!(this.state.assuranceInformative.filter(ai => ((ai.id === info.id) && (ai.status === INFORMATIVE_STATUS.notCompliant.id))).reduce( getLastElementOr, null ))

																										// default
																										|| (!!informativeStatus && (informativeStatus.id === INFORMATIVE_STATUS.notCompliant.id))
																									)
																										? (
																											<div className={"tree-node-controller"}>
																												<Input
																													type={"textarea"}
																													rows={5}
																													name={"non-compliant-info-"+ info.id}
																													id={"non-compliant-info-"+ info.id}
																													defaultValue={
																														(!!info.nonCompliantText)
																															? info.nonCompliantText
																															: null
																													}
																													placeholder={"Inserisci una motivazione"}
																													className={""}
																													onFocus={focusEvent => {

																													}}
																													onEnded={ endedEvent => {}}
																													onBlur={ blurEvent => {
																														this.setState({
																															...this.state,
																															assuranceInformative: [
																																...this.state.assuranceInformative.filter( ai => ai.id !== info.id ),
																																{ id: info.id, status: INFORMATIVE_STATUS.notCompliant.id, text: blurEvent.target.value }
																															]
																														})
																													}}
																												/>
																												<ActionButton
																													promise={ () => {


																														return (
																															new Promise((success, fail) => {

																																this.setState({
																																		...this.state,
																																		assuranceInformativeWait: [...this.state.assuranceInformativeWait, info.id]
																																	},
																																	() => {

																																		new Promise((onEnd, onError) => {
																																			setSRToolAudit(
																																				this.props.collect.id,
																																				info.id,
																																				INFORMATIVE_STATUS.notCompliant.id,
																																				this.state.assuranceInformative.filter( ai => ai.id === info.id ).reduce( getLastElementOr, {text: null}).text
																																			)
																																				.then( result => {
																																					onEnd();
																																				})
																																				.catch( e => {
																																					errorlog("set SRTool Audit", e);
																																					onEnd( e );
																																				})
																																		})
																																			.then( e => {
																																				this.setState({
																																						...this.state,
																																						assuranceInformativeWait: this.state.assuranceInformativeWait.filter(ai => ai !== info.id),
																																						srtoolAuditError: [
																																							...this.state.srtoolAuditError.filter( ai => ai.id !== info.id ),
																																							(!!e ? { id: info.id, error: e } : null)
																																						]
																																						.filter( onlyNotNull ),
																																						isValidateAssuranceActive: false
																																					},
																																					() => {
																																						// non strettamente necessaria
																																						// this.loadData();
																																						success();
																																					})
																																			})

																																			.catch( e => {
																																				fail( e );
																																			})

																																	}
																																)

																															})


																															// refresh della pagina ------------------------------------------------------------------------------------
																															.then( () => {
																																this.standby()
																																	.then( () => {
																																		loadGriStructureValue( this.props.collect.id, this.state.activeTab, this.state.tmFilter )
																																			.then(resultObj => {
																																				convertTableToTree( resultObj )
																																					.then( tree => {
																																						let virtualTree = convertToVirtualTree( tree, resultObj );
																																						let freshTree = cloneOpenNodes( this.state.tree, virtualTree );
																																						this.setState({
																																							...this.state,
																																							tree: freshTree,
																																							treeSeed: resultObj,
																																							isSwitchingTab: false,
																																							tmFilter: 0, // temporaneo
																																							assuranceFilter: null
																																						})
																																						;
																																					})
																																					.catch(e => {
																																						errorlog("errore",e);
																																						this.setState({
																																							...this.state,
																																							isSwitchingTab: false,
																																							tmFilter: 0, // temporaneo
																																							assuranceFilter: null
																																						})
																																						;
																																					})
																																				;
																																			})
																																			.catch(e => {
																																				errorlog("load gri structure values", e);
																																				return Promise.reject( e );
																																			})
																																	}) // fine
																															})
																															// -----------------------------------------------------------------------------------------------------------



																														);




																													}}
																													onResult={ result => {
																														showlog("salvataggio non-compliant info andato con successo");
																														if( typeof this.props.assuraceIsActive === "function" ) this.props.assuraceIsActive( false );
																													}}
																													onError={ error => {
																														errorlog("send non-compliant informative message", error);
																													}}
																												/>
																											</div>
																										)
																										: null
																								)


																						}

																					</div>
																				)
																				: null
																		}


																	</Row>


														</CardHeader>


														<Collapse isOpen={ !!info.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id}>
															<CardBody>


																{ // requisito --------------------------------------------------------------------------------------------------------------------------------------------
																	info.requirements
																		.sort( sortById )
																		.map( (req, reIndex) => {


																			// showlog("controllo requisito albero dei valori");
																			// showlog(req);


																			return (
																				<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>



																					<CardHeader
																						className={
																							[
																								// colore ---------------------------------------------
																								// colorClassRequirement
																								// ,
																								"requirement-color",
																								"tree-node-header-container"
																							]
																								.join(" ")
																						}
																						id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.structureId }
																					>
																						<Row className={"tree-node-header"}>
																							<Col sm={9} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( req ) }>

																								{ this.helperNode( req ) }
																								{ req.name }

																							</Col>
																							<Col sm={3} className={"tree-node-header-single-command"}>



																								{
																									(!!req.evaluate)
																										? (
																											<React.Fragment>
																												<UncontrolledPopover
																													placement="top"
																													target={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id }
																													trigger="legacy"
																													className={""}
																												>
																													<PopoverHeader>Storico</PopoverHeader>
																													<PopoverBody>Ultima modifica { req.lastUpdate }</PopoverBody>
																												</UncontrolledPopover>

																												<i
																													className={"mdi mdi-history srtool-question-history-button cursor-pointer"}
																													id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id }
																												/>


																												{/*		linee guida da inserire */}
																												{
																													(!!req.guideLines && !!req.guideLines)
																														? (
																															<React.Fragment>

																																<GuideLineIconSvgComponent
																																	id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +"-guidelinees"}
																																	className={"svg-icon-container req-lg-svg-icon-container tooltip-icon-cursor"}
																																/>

																																<UncontrolledPopover
																																	placement="right"
																																	target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +"-guidelinees"}
																																	trigger="legacy"
																																	className={"info-guide-lines-popover"}
																																>
																																	<PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
																																	<PopoverBody>
																																		<div>{ req.guideLines }</div>
																																		{ GUIDELINES_POST_IT }
																																	</PopoverBody>
																																</UncontrolledPopover>

																															</React.Fragment>
																														)
																														: null
																												}


																												{
																													(!!req.compilationRequirements && !!req.compilationRequirements)
																														? (
																															<React.Fragment>

																																<img
																																	src={ CompilationRequirementsIcon }
																																	className="tooltip-icon-cursor icon-in-srtool-edit icon-in-srtool"
																																	id={`completitionRequirements-${ req.id }`}
																																/>

																																{/*}
																																<i
																																	className={`dripicons-align-justify srtool-question-history-button cursor-pointer`}
																																	id={`completitionRequirements-${ req.id }`}
																																/>
																																*/}

																																<UncontrolledPopover
																																	placement="right"
																																	target={`completitionRequirements-${ req.id }`}
																																	trigger="legacy"
																																	className={"info-guide-lines-popover"}
																																>
																																	<PopoverHeader>{ translate("compilationRequirements") }</PopoverHeader>
																																	<PopoverBody>
																																		<div>{ req.compilationRequirements }</div>
																																	</PopoverBody>
																																</UncontrolledPopover>

																															</React.Fragment>
																														)
																														: null
																												}


																												{/*
																													!!this.state.compilationRequirement
																														? <Spinner color={"#18f"} className="compilation-requirements-wait srtool-question-history-button "/>
																														: (
																															<React.Fragment>


																																<i
																																	className={`dripicons-align-justify srtool-question-history-button cursor-pointer`}
																																	id={`completitionRequirements-${ req.id }`}
																																	onClick={ clickEvent => {
																																		this.setState({
																																			...this.state,
																																			compilationRequirement: req.id
																																		},
																																	() => {
																																			// chiamata eliminata
																																			getTranslationForSRToolCompilationRequirementsNode( req.path.length -1, req.id )
																																				.then( result => {

																																					let text =
																																						result
																																							.filter( l => l.code === (localStorage.getItem("language") || LANGUAGES[0].code) )
																																							.map( l => l[ (localStorage.getItem("language") || LANGUAGES[0].code).substr(0, 2).toLowerCase() ] )
																																							.reduce( getLastElementOr, " " )
																																					;


																																					this.setState({
																																						...this.state,
																																						compilationRequirement: null,
																																						compilationRequirementNode: text || " "
																																					})

																																				})
																																				.catch(e => {
																																					errorlog( "errore e", e );
																																					let em = extractErrorMessage( e );

																																					this.setState({
																																						...this.state,
																																						compilationRequirement: null,
																																						compilationRequirementNode: em
																																					})

																																				})
																																		})
																																	}}
																																/>

																															</React.Fragment>
																														)
																												*/}





																												{/*
																												<RecapStatusIconSvgComponent
																													className={"srtool-question-history-button cursor-pointer"}
																													id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id }
																												/>
																												*/}
																											</React.Fragment>
																										)
																										: null
																								}


																								{
																									(req.subReqs.length > 0)
																										? (
																											(!!req.open)
																												? <i className="mdi mdi-minus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( req ) }/>
																												: <i className="mdi mdi-plus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( req ) }/>
																										)
																										: (
																											(!!req.zones && req.zones.length > 0)
																												? (
																													// (!!this.state.virtualTree.zone)
																													(!!req.open)
																														? <i className="dripicons-minus font-18 accordion-arrow repetition-plus-button" onClick={ clickEvent => this.toggleNode( req ) }/>
																														: <i className="mdi mdi-timeline-plus font-18 accordion-arrow red-color big-font repetition-plus-button" onClick={ clickEvent => this.toggleNode( req ) }/>
																												)
																												: null
																										)
																								}
																							</Col>
																						</Row>

																								<Row
																									className={"tree-node-controllers"}>
																									<div
																										className={"tree-node-controllers-group"}>
																										{
																											(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																												? (
																													<div
																														className={"tree-node-controller"}>
																														<Label
																															className={"tree-node-controller-label"}>Manager</Label>

																														{
																															// !!this.isNodeDisabled(req)
																															!!this.isNodeDisabled(info)
																																? (
																																	<span
																																		className={"tree-node-controller-text-button" + (!!req.userManager ? "" : " tree-node-controller-text-button-empty")}>
																																			{
																																				(!!req.userManager)
																																					? req.userManagerName
																																					: "Nessun Manager"
																																			}
																																		</span>
																																)
																																: (
																																	<ActionButton
																																		buttonText={
																																			<React.Fragment>
																																				<i className="mdi mdi-account tree-node-controller-text-button-icon"/>
																																				<span
																																					className={"tree-node-controller-text-button" + (!!req.userManager ? "" : " tree-node-controller-text-button-empty")}>
																																			{
																																				(!!req.userManager)
																																					? req.userManagerName
																																					: translate("Select")
																																			}
																																		</span>
																																			</React.Fragment>
																																		}
																																		color={(!!req.userManager) ? "success" : "primary"}
																																		className={"tree-node-controller-button"}
																																		promise={() => {
																																			return Promise.resolve();
																																		}}
																																		onResult={result => {

																														if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																															this.setState({
																																...this.state,
																																getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.activeTab).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id,
																																currentUserManager: req.userManager
																															})
																														}

																													}}
																													onError={ e => {

																																		}}
																																	/>
																																)
																														}

																													</div>
																												)
																												: null
																										}

																								<div className={"tree-node-controller"}>
																									{/*
																									<Label className={"tree-node-controller-label"}>Timing</Label>
																									<span className={"tree-node-controller-value"}>
																										{
																											(!!req.timing)
																												? "Mensile"
																												: "Annuale"
																										}
																									</span>
																									*/}
																								</div>

																										<div
																											className={"tree-node-controller"}>
																											{
																												(!!req.subRequirementsRepeating && hasPermission(permissions.ADD_REPETITIONS_ENTITY) /* || (parseInt( req.subRequirementsRepeating, 10 ) !== 0)*/)
																													? (

																														!!this.isNodeDisabled(info)
																															? null
																															: (
																																<ActionButton
																																	buttonText={
																																		<span
																																			className={"tree-node-controller-text-button"}>Aggiungi Entità</span>
																																	}
																																	color="success"
																																	className="tree-node-controller-button tree-node-controller-goal-text-button"
																																	promise={() => Promise.resolve()}
																																	onResult={result => {


																																		showlog("posizione y al click del button");
																																		// showlog( window.scrollY );
																																		// showlog( document.getElementById(`tab-${ this.state.activeTab }-content`) );
																																		// showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).scrollY );
																																		// showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).scrollTop );
																																		// showlog( document.getElementById(`tab-${ this.state.activeTab }`) );
																																		// showlog( document.getElementById(`tab-${ this.state.activeTab }`).scrollY );
																																		// showlog( document.getElementById(`tab-${ this.state.activeTab }`).scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }`).parentElement.parentElement.parentElement.parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }`).parentElement.parentElement.parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }`).parentElement.parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }`).parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }`).scrollTop );

																																		showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.parentElement.parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.scrollTop );
																																		showlog( document.getElementById(`tab-${ this.state.activeTab }-content`).scrollTop );

																																		// debugger;

																																		this.setState({
																																			...this.state,
																																			newZoneModal: req.id + "-" + (!!req.timing) + "-" + req.structureId,
																																			reqVirtualNode: req,
																																			pointToScroll: document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.parentElement.scrollTop
																																		})
																																	}}
																																/>
																															)

																													)
																													: null
																											}
																										</div>


																							</div>


																								{
																									(!!req.evaluate)
																										? (
																											<div className={"tree-node-controllers-group"}>
																												<div className={"tree-node-controller"}>
																													{
																														!!!req.isString
																															? <Label className={"tree-node-controller-label"}>{ translate("MeasureUnit") }</Label>
																															: null
																													}

																													<span className={"tree-node-controller-value"}>
																														{
																															!!req.measureUnit
																																? (
																																	!!!req.isString
																																		?
																																		(
																																			(req.measureUnit.name === "numeric")
																																				?
																																				(
																																					translate("numeric")
																																				)
																																				:
																																					req.measureUnit.name
																																		)
																																		: null
																																)
																																: "Nessuna"
																														}
																													</span>
																												</div>
																												<div className={"tree-node-controller"}>
																													{
																														(!!hasInformativeGoal(info.name) && hasGoal( req ) )
																															? (
																																<ActionButton
																																	buttonText={
																																		<React.Fragment>
																																			{
																																				!!req.valueGoal
																																					? <GoalSvgComponent className={"goal-icon-component"}/>
																																					: <MissingGoalSvgComponent className={"goal-icon-component"}/>
																																			}
																																			<span className={"tree-node-controller-text-button tree-node-controller-goal-text-button"}>&nbsp;&nbsp;{ translate("Goal") }</span>
																																		</React.Fragment>
																																	}
																																	color={ "link" }
																																	className={
																																		"tree-node-controller-button "+
																																		(
																																			!!req.valueGoal
																																				? Object.keys(TABS)
																																					.map( t => TABS[t] )
																																					.filter( t => t.id === this.state.activeTab )
																																					.reduce( getLastElementOr, {goalClassName: ""})
																																					.goalClassName
																																				: "goal-button-disabled"
																																		)
																																	}
																																	promise={ () => Promise.resolve() }
																																	onResult={ clickEvent => {
																																		this.setState({
																																			...this.state,
																																			goalPopupOpen: req,
																																			goalPopupLevel: 3,
																																			nodeInformative: info
																																		});
																																	}}
																																/>
																															)
																															: null
																													}
																												</div>
																											</div>
																										)
																										: null
																								}


																									{
																										// if caso in cui non c'è text area
																										(!req.isString || req.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)?
																											(
																												<div className={"tree-node-controllers-group "+ (!!req.isString ? "tree-node-controller-group-textarea-container" : "")}>
																													<div className={"tree-node-controller "+ ( req.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE ? "tree-node-controller-date" : "tree-node-controller-textarea")}>
																														{
																															(!!req.evaluate)
																																? (
																																	<React.Fragment>
																																		{

																																			// se il timing è mensile ( true )
																																			// allora tasto per pertura modale
																																			// altrimenti input con tasto invio
																																			(!!!req.timing)
																																				// (!!req.isString)
																																				? (

																																					<React.Fragment>
																																						{/*	tree-node-data-entry-value-box-component	*/}
																																						<FormGroup
																																							className={"" + ((!req.isString) ? " description-textarea-container-value" : "") + (
																																								(this.state.settedNode.filter(sn => sn === (req.id + "." + req.structureId)).length > 0)
																																									? " setted-value2"
																																									: ""
																																							)}
																																						>


																																							{
																																								(req.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)
																																									? (
																																										<React.Fragment>
																																											<HyperDatepicker
																																												hideAddon={false}
																																												dateFormat={CALENDAR_DATE_FORMAT_IT}
																																												onSelect={clickEvent => {

																																													this.setState({
																																														...this.state,
																																														dateValues: [
																																															...this.state.dateValues
																																																.filter(dv => dv.id !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value")),
																																															{
																																																id: (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"),
																																																value: moment(clickEvent).format(DATE_FORMAT_IT)
																																															}
																																														]
																																													})

																																												}}
																																												value={
																																													(!!isNotEmptyArray(this.state.dateValues))
																																														? (
																																															this.state.dateValues
																																																.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																																.map(dv => dv.value)
																																																.reduce(getLastElementOr, null)
																																														)
																																														: (
																																															(!!req.value && !!req.value[0])
																																																? req.value[0]
																																																: moment().format(DATE_FORMAT_IT)
																																														)
																																												}
																																												selected={
																																													this.state.dateValues
																																														.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																														.map(dv => dv.value)
																																														.reduce(getLastElementOr, null)
																																												}
																																												peekNextMonth
																																												showMonthDropdown
																																												showYearDropdown
																																												dropdownMode="select"
																																												yearDropdownItemNumber={15}
																																												todayButton="Oggi"
																																												// minDate={new Date()}
																																												// maxDate={addDays(new Date(), 5)}
																																												locale={"IT"}
																																												highlightDates={[new Date()]}
																																												showWeekNumbers
																																												disabled={!!this.isNodeDisabled(info)}
																																											/>
																																											<input
																																												type={"hidden"}
																																												id={(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value")}
																																												value={
																																													this.state.dateValues
																																														.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																														.map(dv => dv.value)
																																														.reduce(getLastElementOr, null)
																																												}
																																												disabled={!!this.isNodeDisabled(info)}
																																											/>
																																										</React.Fragment>
																																									)
																																									: (
																																										<React.Fragment>


																																											<Input
																																												// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
																																												type={ !!req.isString ? "textarea" : "number" }
																																												rows={ !!req.isString ? 5 : 1 }
																																												name={  GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value" }
																																												id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value" }
																																												resize={ "true" }
																																												defaultValue={
																																													/*
																																													(!!req && !!req.value)
																																														? req.value[0]
																																														: (
																																															( !req.isString )
																																																? 0
																																																: ""
																																														)
																																													*/

																																													(!!req.isValueActive)
																																														? (!!req && !!req.value)
																																															? req.value[0]
																																															: (
																																																( !req.isString )
																																																	? 0
																																																	: ""
																																															)
																																														: null

																																												}
																																												className={

																																													(
																																														!!req.isString
																																															? "tree-node-data-entry-value-box-component-textarea "
																																															: "tree-node-data-entry-value-box-component-input "
																																													)
																																													+
																																													(
																																														(this.state.settedNode.filter(sn => sn === (req.id + "." + req.structureId)).length > 0)
																																															? "setted-value"
																																															: ""
																																													)
																																													+ " small-button"

																																												}
																																												onFocus={focusEvent => {
																																													this.setState({
																																														...this.state,
																																														errors: !!isNotEmptyArray(this.state.errors) ? this.state.errors.filter(e => e.target !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value")) : []
																																													});
																																												}}
																																												disabled={!!this.isNodeDisabled(info)}
																																											/>
																																											{
																																												(
																																													!!isNotEmptyArray(this.state.errors) &&
																																													!!this.state.errors
																																														.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																														.reduce(getLastElementOr, false)
																																												)
																																													? (
																																														<label className="error-message-label">
																																															{
																																																this.state.errors
																																																	.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																																	.reduce(getLastElementOr, {message: ""})
																																																	.message
																																															}
																																														</label>
																																													)
																																													: null
																																											}



																																											{
																																												!req.isString
																																													? (
																																														<div
																																															className={"set-value-button-container"}>
																																															<ActionButton
																																																color={"primary"}
																																																promise={ () => Promise.resolve() }
																																																buttonText={translate("monthly values")}
																																																onResult={clickEvent => {
																																																	// clickEvent.stopPropagation();
																																																	this.setState({
																																																		...this.state,
																																																		monthlyTimingPopup: req.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.structureId,
																																																		node: req,
																																																		nodeInformative: info
																																																	})
																																																}}
																																															/>
																																															<ActionButton
																																																promise={ () => Promise.resolve() }
																																																// buttonText={ translate("OldValues") }
																																																buttonText={ <i className={"mdi mdi-history srtool-question-history-button cursor-pointer"} /> }
																																																className={"no-button-frame"}
																																																onResult={clickEvent => {
																																																	// clickEvent.stopPropagation();
																																																	this.setState({
																																																		...this.state,
																																																		previousMonthlyValuesPopup: req.structureId
																																																	})
																																																}}
																																															/>
																																														</div>
																																													)
																																													: null
																																											}



																																										</React.Fragment>

																																									)
																																							}




																																						</FormGroup>

																																						{
																																							!!this.isNodeDisabled(info)
																																								? null
																																								: (
																																									<ActionButton
																																										promise={() => {

																																											return (

																																												new Promise((success, fail) => {

																																													this.setState({
																																															...this.state,
																																															waitingNode: [
																																																...this.state.waitingNode,
																																																(req.id + "." + req.structureId)
																																															]
																																														},

																																														() => {


																																															success(
																																																saveTreeNodeSingleValue(
																																																	req.structureId,
																																																	3,
																																																	req.id,
																																																	document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value").value,
																																																	!!req.isString
																																																)
																																															);
																																														}
																																													)

																																												})


																																											);


																																										}}
																																										onResult={result => {

																																											let activeTab = this.state.activeTab;

																																											this.setState({
																																													...this.state,
																																													settedNode: [
																																														...this.state.settedNode,
																																														(req.id + "." + req.structureId)
																																													],
																																													waitingNode: this.state.waitingNode.filter(wn => wn !== (req.id + "." + req.structureId))
																																												},
																																												() => {
																																													/*
                                                                                                                                                                            this.loadData()
                                                                                                                                                                                .then( refreshedTree => {
                                                                                                                                                                                    this.props.onTreeUpdate( refreshedTree, activeTab );
                                                                                                                                                                                })
                                                                                                                                                                            ;
                                                                                                                                                                            */
																																												}
																																											)

																																										}}
																																										onError={error => {
																																											errorlog(error);
																																											this.setState({
																																												...this.state,
																																												errors: [
																																													...this.state.errors,
																																													{
																																														target: GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value",
																																														message: error
																																													}
																																												]
																																											});
																																										}}
																																										className={"small-button tree-node-controller-bottom-button"}
																																										buttonText={ translate("Save") }
																																									/>
																																								)
																																						}


																																					</React.Fragment>

																																				)
																																				: (
																																					(!!!req.subRequirementsRepeating)
																																						? (
																																							<div
																																								className={"set-value-button-container"}>
																																								<ActionButton
																																									color={"primary"}
																																									promise={ () => Promise.resolve() }
																																									buttonText={translate("monthly values")}
																																									onResult={clickEvent => {
																																										// clickEvent.stopPropagation();
																																										this.setState({
																																											...this.state,
																																											monthlyTimingPopup: req.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.structureId,
																																											node: req,
																																											nodeInformative: info
																																										})
																																									}}
																																								/>
																																							</div>
																																						)
																																						: null
																																				)
																																		}


																																	</React.Fragment>

																																) // se è evaluate
																																: null

																														}
																													</div>
																												</div>
																											)
																											:
																											null
																										//se è null, quinid non è stringa entra nell'if successivo
																									}






																								</Row>


																						<Row >

																									{   //if condizione text area
																										(!!req.isString && req.measureUnit.unitMeasurementId !== MEASURE_UNIT_ID_DATE) ?// caso text area

																											(
																											<div className={"tree-node-controllers-group tree-node-controller-group-textarea-container2" }>
																												<div className={"tree-node-controller tree-node-controller-textarea2"}>
																													{
																														(!!req.evaluate)
																															? (
																																<React.Fragment>
																																	{

																																		// se il timing è mensile ( true )
																																		// allora tasto per pertura modale
																																		// altrimenti input con tasto invio
																																		(!!!req.timing)
																																			// (!!req.isString)
																																			? (

																																				<React.Fragment>
																																					{/*	tree-node-data-entry-value-box-component	*/}
																																					<FormGroup
																																						className={"" + ((!req.isString) ? " description-textarea-container-value" : "") + (
																																							(this.state.settedNode.filter(sn => sn === (req.id + "." + req.structureId)).length > 0)
																																								? " setted-value2"
																																								: ""
																																						)}
																																					>


																																						{
																																							(req.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)
																																								? (
																																									<React.Fragment>
																																										<HyperDatepicker
																																											hideAddon={false}
																																											dateFormat={CALENDAR_DATE_FORMAT_IT}
																																											onSelect={clickEvent => {

																																												this.setState({
																																													...this.state,
																																													dateValues: [
																																														...this.state.dateValues
																																															.filter(dv => dv.id !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value")),
																																														{
																																															id: (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"),
																																															value: moment(clickEvent).format(DATE_FORMAT_IT)
																																														}
																																													]
																																												})

																																											}}
																																											value={
																																												(!!isNotEmptyArray(this.state.dateValues))
																																													? (
																																														this.state.dateValues
																																															.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																															.map(dv => dv.value)
																																															.reduce(getLastElementOr, null)
																																													)
																																													: (
																																														(!!req.value && !!req.value[0])
																																															? req.value[0]
																																															: moment().format(DATE_FORMAT_IT)
																																													)
																																											}
																																											selected={
																																												this.state.dateValues
																																													.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																													.map(dv => dv.value)
																																													.reduce(getLastElementOr, null)
																																											}
																																											peekNextMonth
																																											showMonthDropdown
																																											showYearDropdown
																																											dropdownMode="select"
																																											yearDropdownItemNumber={15}
																																											todayButton="Oggi"
																																											// minDate={new Date()}
																																											// maxDate={addDays(new Date(), 5)}
																																											locale={"IT"}
																																											highlightDates={[new Date()]}
																																											showWeekNumbers
																																											disabled={!!this.isNodeDisabled(info)}
																																										/>
																																										<input
																																											type={"hidden"}
																																											id={(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value")}
																																											value={
																																												this.state.dateValues
																																													.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																													.map(dv => dv.value)
																																													.reduce(getLastElementOr, null)
																																											}
																																											disabled={!!this.isNodeDisabled(info)}
																																										/>
																																									</React.Fragment>
																																								)
																																								: (
																																									<React.Fragment>


																																										<Input
																																											// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
																																											type={ !!req.isString ? "textarea" : "number" }
																																											rows={ !!req.isString ? 5 : 1 }
																																											name={  GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value" }
																																											id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value" }
																																											resize={ "true" }
																																											defaultValue={
																																												(!!req.isValueActive)
																																													? (!!req && !!req.value)
																																														? req.value[0]
																																														: (
																																															( !req.isString )
																																																? 0
																																																: ""
																																														)
																																													: null
																																											}
																																											className={

																																												(
																																													!!req.isString
																																														? "tree-node-data-entry-value-box-component-textarea2 "
																																														: "tree-node-data-entry-value-box-component-input "
																																												)
																																												+
																																												(
																																													(this.state.settedNode.filter(sn => sn === (req.id + "." + req.structureId)).length > 0)
																																														? "setted-value"
																																														: ""
																																												)
																																												+ " small-button"

																																											}
																																											onFocus={focusEvent => {
																																												this.setState({
																																													...this.state,
																																													errors: !!isNotEmptyArray(this.state.errors) ? this.state.errors.filter(e => e.target !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value")) : []
																																												});
																																											}}
																																											disabled={!!this.isNodeDisabled(info)}
																																										/>
																																										{
																																											(
																																												!!isNotEmptyArray(this.state.errors) &&
																																												!!this.state.errors
																																													.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																													.reduce(getLastElementOr, false)
																																											)
																																												? (
																																													<label className="error-message-label">
																																														{
																																															this.state.errors
																																																.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value"))
																																																.reduce(getLastElementOr, {message: ""})
																																																.message
																																														}
																																													</label>
																																												)
																																												: null
																																										}



																																										{
																																											!req.isString
																																												? (
																																													<div
																																														className={"set-value-button-container"}>
																																														<ActionButton
																																															color={"primary"}
																																															promise={ () => Promise.resolve() }
																																															buttonText={translate("monthly values")}
																																															onResult={clickEvent => {
																																																// clickEvent.stopPropagation();
																																																this.setState({
																																																	...this.state,
																																																	monthlyTimingPopup: req.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.structureId,
																																																	node: req,
																																																	nodeInformative: info
																																																})
																																															}}
																																														/>

																																														<ActionButton
																																															promise={ () => Promise.resolve() }
																																															// buttonText={ translate("OldValues") }
																																															buttonText={ <i className={"mdi mdi-history srtool-question-history-button cursor-pointer"} /> }
																																															className={"no-button-frame"}
																																															onResult={clickEvent => {
																																																// clickEvent.stopPropagation();
																																																this.setState({
																																																	...this.state,
																																																	previousMonthlyValuesPopup: req.structureId
																																																})
																																															}}
																																														/>
																																													</div>
																																												)
																																												: null
																																										}



																																									</React.Fragment>

																																								)
																																						}




																																					</FormGroup>

																																					{
																																						!!this.isNodeDisabled(info)
																																							? null
																																							: (
																																								<ActionButton
																																									promise={() => {

																																										return (

																																											new Promise((success, fail) => {

																																												this.setState({
																																														...this.state,
																																														waitingNode: [
																																															...this.state.waitingNode,
																																															(req.id + "." + req.structureId)
																																														]
																																													},

																																													() => {


																																														success(
																																															saveTreeNodeSingleValue(
																																																req.structureId,
																																																3,
																																																req.id,
																																																document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value").value,
																																																!!req.isString
																																															)
																																														);
																																													}
																																												)

																																											})


																																										);


																																									}}
																																									onResult={result => {

																																										let activeTab = this.state.activeTab;

																																										this.setState({
																																												...this.state,
																																												settedNode: [
																																													...this.state.settedNode,
																																													(req.id + "." + req.structureId)
																																												],
																																												waitingNode: this.state.waitingNode.filter(wn => wn !== (req.id + "." + req.structureId))
																																											},
																																											() => {
																																												/*
                                                                                                                                                                        this.loadData()
                                                                                                                                                                            .then( refreshedTree => {
                                                                                                                                                                                this.props.onTreeUpdate( refreshedTree, activeTab );
                                                                                                                                                                            })
                                                                                                                                                                        ;
                                                                                                                                                                        */
																																											}
																																										)

																																									}}
																																									onError={error => {
																																										errorlog(error);
																																										this.setState({
																																											...this.state,
																																											errors: [
																																												...this.state.errors,
																																												{
																																													target: GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.id + "-value",
																																													message: error
																																												}
																																											]
																																										});
																																									}}
																																									className={"small-button tree-node-controller-bottom-button"}
																																									buttonText={ translate("Save") }
																																								/>
																																							)
																																					}


																																				</React.Fragment>

																																			)
																																			: (
																																				(!!!req.subRequirementsRepeating)
																																					? (
																																						<div
																																							className={"set-value-button-container"}>
																																							<ActionButton
																																								color={"primary"}
																																								promise={ () => Promise.resolve() }
																																								buttonText={translate("monthly values")}
																																								onResult={clickEvent => {
																																									// clickEvent.stopPropagation();
																																									this.setState({
																																										...this.state,
																																										monthlyTimingPopup: req.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] + "-" + req.structureId,
																																										node: req,
																																										nodeInformative: info
																																									})
																																								}}
																																							/>
																																						</div>
																																					)
																																					: null
																																			)
																																	}


																																</React.Fragment>

																															) // se è evaluate
																															: null

																													}
																												</div>
																											</div>

																											)
																											: //secondo caso text area
																											null
																									}	{/*chiusura if*/}

																						</Row>


																					</CardHeader>





																					{ // sotto-requisito ------------------------------------------------------------------------------------------------------------------
																						(req.subReqs.length > 0)
																							? (
																								// <UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																								<Collapse isOpen={ !!req.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																									<CardBody>
																										{
																											req.subReqs
																												.map( sub => {
																													return (
																														<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>




																															<CardHeader
																																className={
																																	[
																																		// colore ---------------------------------------------
																																		// colorClassRequirement
																																		// ,
																																		"tree-node-header-container"
																																	]
																																		.join(" ")
																																}
																																id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.structureId }
																															>
																																<Row className={"tree-node-header"}  >
																																	<Col sm={9} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( sub ) }>

																																		{ this.helperNode( sub ) }
																																		{ sub.name }

																																	</Col>
																																	<Col sm={3} className={"tree-node-header-single-command"}>



																																		{
																																			(!!sub.evaluate)
																																				? (
																																					<React.Fragment>
																																						<UncontrolledPopover
																																							placement="top"
																																							target={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id }
																																							trigger="legacy"
																																							className={""}
																																						>
																																							<PopoverHeader>Storico</PopoverHeader>
																																							<PopoverBody>Ultima modifica { sub.lastUpdate }</PopoverBody>
																																						</UncontrolledPopover>
																																						<i
																																							className={"mdi mdi-history srtool-question-history-button cursor-pointer"}
																																							id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id }
																																						/>


																																						{
																																							(!!sub.compilationRequirements && !!sub.compilationRequirements)
																																								? (
																																									<React.Fragment>

																																										<i
																																											className={`dripicons-align-justify srtool-question-history-button cursor-pointer`}
																																											id={`completitionRequirements-${ sub.id }`}
																																										/>

																																										<UncontrolledPopover
																																											placement="right"
																																											target={`completitionRequirements-${ sub.id }`}
																																											trigger="legacy"
																																											className={"info-guide-lines-popover"}
																																										>
																																											<PopoverHeader>{ translate("compilationRequirements") }</PopoverHeader>
																																											<PopoverBody>
																																												<div>{ sub.compilationRequirements }</div>
																																											</PopoverBody>
																																										</UncontrolledPopover>

																																									</React.Fragment>
																																								)
																																								: null
																																						}

																																						{/*
																																						<RecapStatusIconSvgComponent
																																							className={"srtool-question-history-button cursor-pointer"}
																																							id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id }
																																						/>
																																						*/}
																																					</React.Fragment>
																																				)
																																				: null
																																		}



																																		{
																																			(sub.subSubReqs.length > 0)
																																				? (
																																					(!!sub.open)
																																						? <i className="mdi mdi-minus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( sub ) }/>
																																						: <i className="mdi mdi-plus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( sub ) }/>
																																				)
																																				: null
																																		}
																																	</Col>
																																</Row>

																																<Row className={"tree-node-controllers"}>
																																	<div className={"tree-node-controllers-group"}>
																																		{/*
																																			(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																																				? (
																																					<div className={"tree-node-controller"}>
																																						<Label className={"tree-node-controller-label"}>Manager</Label>
																																						<ActionButton
																																							buttonText={
																																								<React.Fragment>
																																									<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																																									<span className={"tree-node-controller-text-button" + (!!sub.userManager ? "" : " tree-node-controller-text-button-empty")} >
																																										{
																																											(!!sub.userManager)
																																												? sub.userManagerName
																																												: translate("Select")
																																										}
																																									</span>
																																								</React.Fragment>
																																							}
																																							color={ (!!sub.userManager) ? "success" : "primary" }
																																							className={ "tree-node-controller-button" }
																																							promise={ () => {
																																								return Promise.resolve( );
																																							}}
																																							onResult={ result => {

																																								if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																									this.setState({
																																										...this.state,
																																										getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.activeTab).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + sub.id,
																																										currentUserManager: sub.userManager
																																									})
																																								}

																																							}}
																																							onError={ e => {

																																							}}
																																						/>
																																					</div>
																																				)
																																				: null
																																		*/}

																																		<div className={"tree-node-controller"}>
																																			{/*
																																			<Label className={"tree-node-controller-label"}>Timing</Label>
																																			<span className={"tree-node-controller-value"}>
																																				{
																																					(!!sub.timing)
																																						? "Mensile"
																																						: "Annuale"
																																				}
																																			</span>
																																			*/}
																																		</div>
																																	</div>
																																	<div className={"tree-node-controllers-group"}>

																																		{
																																			(!!sub.evaluate)
																																				? (
																																					<div className={"tree-node-controller"}>
																																						{
																																							!!!sub.isString
																																								? <Label className={"tree-node-controller-label"}>{ translate("MeasureUnit") }</Label>
																																								: null
																																						}

																																						<span className={"tree-node-controller-value"}>
																																							{
																																								!!sub.measureUnit
																																									? (
																																										!!!sub.isString
																																											?
																																											(
																																												(sub.measureUnit.name === "numeric")
																																													?
																																													(
																																														translate("numeric")
																																													)
																																													:
																																													sub.measureUnit.name
																																											)
																																											: null
																																									)
																																									: "Nessuna"
																																							}
																																						</span>
																																					</div>
																																				)
																																				: null
																																		}


																																		<div className={"tree-node-controller"}>
																																			{
																																				(!!hasInformativeGoal(info.name) && hasGoal( sub ) )
																																					? (
																																						<ActionButton
																																							buttonText={
																																								<React.Fragment>
																																									{
																																										!!sub.valueGoal
																																											? <GoalSvgComponent className={"goal-icon-component"}/>
																																											: <MissingGoalSvgComponent className={"goal-icon-component"}/>
																																									}
																																									<span className={"tree-node-controller-text-button tree-node-controller-goal-text-button"}>&nbsp;&nbsp;{ translate("Goal") }</span>
																																								</React.Fragment>
																																							}
																																							color={ "link" }
																																							className={
																																								"tree-node-controller-button "+
																																								(
																																									!!sub.valueGoal
																																										? Object.keys(TABS)
																																											.map( t => TABS[t] )
																																											.filter( t => t.id === this.state.activeTab )
																																											.reduce( getLastElementOr, {goalClassName: ""})
																																											.goalClassName
																																										: "goal-button-disabled"
																																								)
																																							}
																																							promise={ () => Promise.resolve() }
																																							onResult={ clickEvent => {
																																								this.setState({
																																									...this.state,
																																									goalPopupOpen: sub,
																																									goalPopupLevel: 4,
																																									nodeInformative: info
																																								});
																																							}}
																																						/>
																																					)
																																					: null
																																			}
																																		</div>
																																	</div>

																																	{ //caso in cui sub non è textarea
																																		(!sub.isString || sub.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)?
																																			(
																																				<div className={"tree-node-controllers-group "+ (!!sub.isString ? "tree-node-controller-group-textarea-container" : "")}>
																																					<div className={"tree-node-controller tree-node-controller-textarea"}>
																																						{
																																							(!!sub.evaluate)
																																								? (
																																									<React.Fragment>
																																										{

																																											// se il timing è mensile ( true )
																																											// allora tasto per pertura modale
																																											// altrimenti input con tasto invio
																																											(!!!sub.timing)
																																												// (!!sub.isString)
																																												? (

																																													<React.Fragment>
																																														{/*	tree-node-data-entry-value-box-component	*/}
																																														<FormGroup
																																															className={"" + ((!sub.isString) ? " description-textarea-container-value" : "") + (
																																																(this.state.settedNode.filter(sn => sn === (sub.id + "." + sub.structureId)).length > 0)
																																																	? " setted-value2"
																																																	: ""
																																															)}
																																														>

																																															{
																																																(sub.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)
																																																	? (
																																																		<React.Fragment>
																																																			<HyperDatepicker
																																																				hideAddon={ false }
																																																				dateFormat={ CALENDAR_DATE_FORMAT_IT }
																																																				onSelect={ clickEvent => {

																																																					this.setState({
																																																						...this.state,
																																																						dateValues: [
																																																							...this.state.dateValues
																																																								.filter( dv => dv.id !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value") ),
																																																							{
																																																								id: (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"),
																																																								value: moment( clickEvent ).format( DATE_FORMAT_IT )
																																																							}
																																																						]
																																																					})

																																																				}}
																																																				value={
																																																					(!!isNotEmptyArray(this.state.dateValues))
																																																						? (
																																																							this.state.dateValues
																																																								.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																								.map(dv => dv.value)
																																																								.reduce(getLastElementOr, null)
																																																						)
																																																						: (
																																																							(!!sub.value && !!sub.value[0])
																																																								? sub.value[0]
																																																								: moment().format(DATE_FORMAT_IT)
																																																						)
																																																				}
																																																				selected={
																																																					this.state.dateValues
																																																						.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																						.map(dv => dv.value)
																																																						.reduce(getLastElementOr, null)
																																																				}
																																																				peekNextMonth
																																																				showMonthDropdown
																																																				showYearDropdown
																																																				dropdownMode="select"
																																																				yearDropdownItemNumber={15}
																																																				todayButton="Oggi"
																																																				// minDate={new Date()}
																																																				// maxDate={addDays(new Date(), 5)}
																																																				locale={"IT"}
																																																				highlightDates={[new Date()]}
																																																				showWeekNumbers
																																																			/>
																																																			<input
																																																				type={"hidden"}
																																																				id={(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value")}
																																																				value={
																																																					this.state.dateValues
																																																						.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																						.map(dv => dv.value)
																																																						.reduce(getLastElementOr, null)
																																																				}
																																																				disabled={!!this.isNodeDisabled(info)}
																																																			/>
																																																		</React.Fragment>
																																																	)
																																																	: (
																																																		<React.Fragment>
																																																			<Input
																																																				// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
																																																				type={!!sub.isString ? "textarea" : "number"}
																																																				rows={!!sub.isString ? 5 : 1}
																																																				name={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"}
																																																				id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"}
																																																				defaultValue={
																																																					/*
																																																					(!!sub && !!sub.value)
																																																						? sub.value[0]
																																																						: (
																																																							(!sub.isString)
																																																								? 0
																																																								: ""
																																																						)
																																																					 */

																																																					(!!sub.isValueActive)
																																																						? (!!sub && !!sub.value)
																																																							? sub.value[0]
																																																							: (
																																																								( !sub.isString )
																																																									? 0
																																																									: ""
																																																							)
																																																						: null

																																																				}
																																																				className={

																																																					(
																																																						!!sub.isString
																																																							? "tree-node-data-entry-value-box-component-textarea "
																																																							: "tree-node-data-entry-value-box-component-input "
																																																					)
																																																					+
																																																					(
																																																						(this.state.settedNode.filter(sn => sn === (sub.id + "." + sub.structureId)).length > 0)
																																																							? "setted-value"
																																																							: ""
																																																					)

																																																				}
																																																				onFocus={focusEvent => {
																																																					this.setState({
																																																						...this.state,
																																																						errors: !!isNotEmptyArray(this.state.errors) ? this.state.errors.filter(e => e.target !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value")) : []
																																																					});
																																																				}}
																																																				disabled={!!this.isNodeDisabled(info)}
																																																			/>
																																																			{
																																																				(
																																																					!!isNotEmptyArray(this.state.errors) &&
																																																					!!this.state.errors
																																																						.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																						.reduce(getLastElementOr, false)
																																																				)
																																																					? (
																																																						<label
																																																							className="error-message-label">
																																																							{
																																																								this.state.errors
																																																									.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																									.reduce(getLastElementOr, {message: ""})
																																																									.message
																																																							}
																																																						</label>
																																																					)
																																																					: null
																																																			}





																																																			{
																																																				!sub.isString
																																																					? (
																																																						<div
																																																							className={"set-value-button-container"}>
																																																							<ActionButton
																																																								color={"primary"}
																																																								promise={ () => Promise.resolve() }
																																																								buttonText={translate("monthly values")}
																																																								onResult={clickEvent => {
																																																									// clickEvent.stopPropagation();
																																																									this.setState({
																																																										...this.state,
																																																										monthlyTimingPopup: sub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.structureId,
																																																										node: sub,
																																																										nodeInformative: info
																																																									})
																																																								}}
																																																							/>

																																																							<ActionButton
																																																								promise={ () => Promise.resolve() }
																																																								// buttonText={ translate("OldValues") }
																																																								buttonText={ <i className={"mdi mdi-history srtool-question-history-button cursor-pointer"} /> }
																																																								className={"no-button-frame"}
																																																								onResult={clickEvent => {
																																																									// clickEvent.stopPropagation();
																																																									this.setState({
																																																										...this.state,
																																																										previousMonthlyValuesPopup: sub.structureId
																																																									})
																																																								}}
																																																							/>
																																																						</div>
																																																					)
																																																					: null
																																																			}






																																																		</React.Fragment>
																																																	)

																																															}

																																														</FormGroup>

																																														{
																																															!!this.isNodeDisabled(info)
																																																? null
																																																: (
																																																	<ActionButton
																																																		promise={() => {

																																																			return (

																																																				new Promise((success, fail) => {

																																																					this.setState({
																																																							...this.state,
																																																							waitingNode: [
																																																								...this.state.waitingNode,
																																																								(sub.id + "." + sub.structureId)
																																																							]
																																																						},

																																																						() => {

																																																							success(
																																																								saveTreeNodeSingleValue(
																																																									sub.structureId,
																																																									4,
																																																									sub.id,
																																																									document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value").value,
																																																									!!sub.isString
																																																								)
																																																							);
																																																						}
																																																					)

																																																				})


																																																			);


																																																		}}
																																																		onResult={result => {

																																																			let activeTab = this.state.activeTab;
																																																			this.setState({
																																																					...this.state,
																																																					settedNode: [
																																																						...this.state.settedNode,
																																																						(sub.id + "." + sub.structureId)
																																																					],
																																																					waitingNode: this.state.waitingNode.filter(wn => wn !== (sub.id + "." + sub.structureId))
																																																				},
																																																				() => {
																																																					this.loadData()
																																																						.then(refreshedTree => {

																																																							this.props.onTreeUpdate(refreshedTree, activeTab);
																																																						})
																																																					;
																																																				}
																																																			)

																																																		}}
																																																		onError={error => {
																																																			errorlog(error);
																																																			this.setState({
																																																				...this.state,
																																																				errors: [
																																																					...this.state.errors,
																																																					{
																																																						target: GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value",
																																																						message: error
																																																					}
																																																				]
																																																			});
																																																		}}
																																																		className={"small-button tree-node-controller-bottom-button"}
																																																		buttonText={ translate("Save") }
																																																	/>
																																																)
																																														}


																																													</React.Fragment>

																																												)
																																												: (
																																													(!!!req.subRequirementsRepeating)
																																														? (
																																															<div
																																																className={"set-value-button-container"}>
																																																<ActionButton
																																																	color={"primary"}
																																																	promise={ () => Promise.resolve() }
																																																	buttonText={translate("monthly values")}
																																																	onResult={clickEvent => {
																																																		// clickEvent.stopPropagation();
																																																		this.setState({
																																																			...this.state,
																																																			monthlyTimingPopup: sub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.structureId,
																																																			node: sub,
																																																			nodeInformative: info
																																																		})
																																																	}}
																																																/>
																																															</div>
																																														)
																																														: (
																																															<div
																																																className={"set-value-button-container"}>
																																																<ActionButton
																																																	color={"primary"}
																																																	promise={ () => Promise.resolve() }
																																																	buttonText={translate("monthly values")}
																																																	onResult={clickEvent => {
																																																		// clickEvent.stopPropagation();
																																																		this.setState({
																																																			...this.state,
																																																			// "virtualNode_"+ req.id +"."+ req.structureId +"-"+ node.id +"_"+ node.griSubrequirementId
																																																			monthlyTimingPopup: sub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.structureId,
																																																			node: sub,
																																																			nodeInformative: info
																																																		})
																																																	}}
																																																/>
																																															</div>
																																														)
																																												)
																																										}


																																									</React.Fragment>

																																								) // se è evaluate
																																								: null

																																						}
																																					</div>
																																				</div>
																																			)
																																			:
																																			null
																																			//caso in cui è textarea, va nell'if successivo
																																	}



																												</Row>

																																<Row>


																																	{  //caso in cui sub è textarea
																																		(!!sub.isString && sub.measureUnit.unitMeasurementId !== MEASURE_UNIT_ID_DATE)?
																																			(

																																				<div className={"tree-node-controllers-group tree-node-controller-group-textarea-container2" }>
																																					<div className={"tree-node-controller tree-node-controller-textarea2"}>
																																						{
																																							(!!sub.evaluate)
																																								? (
																																									<React.Fragment>
																																										{

																																											// se il timing è mensile ( true )
																																											// allora tasto per pertura modale
																																											// altrimenti input con tasto invio
																																											(!!!sub.timing)
																																												// (!!sub.isString)
																																												? (

																																													<React.Fragment>
																																														{/*	tree-node-data-entry-value-box-component	*/}
																																														<FormGroup
																																															className={"" + ((!sub.isString) ? " description-textarea-container-value" : "") + (
																																																(this.state.settedNode.filter(sn => sn === (sub.id + "." + sub.structureId)).length > 0)
																																																	? " setted-value2"
																																																	: ""
																																															)}
																																														>

																																															{
																																																(sub.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)
																																																	? (
																																																		<React.Fragment>
																																																			<HyperDatepicker
																																																				hideAddon={ false }
																																																				dateFormat={ CALENDAR_DATE_FORMAT_IT }
																																																				onSelect={ clickEvent => {

																																																					this.setState({
																																																						...this.state,
																																																						dateValues: [
																																																							...this.state.dateValues
																																																								.filter( dv => dv.id !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value") ),
																																																							{
																																																								id: (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"),
																																																								value: moment( clickEvent ).format( DATE_FORMAT_IT )
																																																							}
																																																						]
																																																					})

																																																				}}
																																																				value={
																																																					(!!isNotEmptyArray(this.state.dateValues))
																																																						? (
																																																							this.state.dateValues
																																																								.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																								.map(dv => dv.value)
																																																								.reduce(getLastElementOr, null)
																																																						)
																																																						: (
																																																							(!!sub.value && !!sub.value[0])
																																																								? sub.value[0]
																																																								: moment().format(DATE_FORMAT_IT)
																																																						)
																																																				}
																																																				selected={
																																																					this.state.dateValues
																																																						.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																						.map(dv => dv.value)
																																																						.reduce(getLastElementOr, null)
																																																				}
																																																				peekNextMonth
																																																				showMonthDropdown
																																																				showYearDropdown
																																																				dropdownMode="select"
																																																				yearDropdownItemNumber={15}
																																																				todayButton="Oggi"
																																																				// minDate={new Date()}
																																																				// maxDate={addDays(new Date(), 5)}
																																																				locale={"IT"}
																																																				highlightDates={[new Date()]}
																																																				showWeekNumbers
																																																			/>
																																																			<input
																																																				type={"hidden"}
																																																				id={(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value")}
																																																				value={
																																																					this.state.dateValues
																																																						.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																						.map(dv => dv.value)
																																																						.reduce(getLastElementOr, null)
																																																				}
																																																				disabled={!!this.isNodeDisabled(info)}
																																																			/>
																																																		</React.Fragment>
																																																	)
																																																	: (
																																																		<React.Fragment>
																																																			<Input
																																																				// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
																																																				type={!!sub.isString ? "textarea" : "number"}
																																																				rows={!!sub.isString ? 5 : 1}
																																																				name={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"}
																																																				id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"}
																																																				defaultValue={
																																																					(!!sub.isValueActive)
																																																						? (!!sub && !!sub.value)
																																																							? sub.value[0]
																																																							: (
																																																								( !sub.isString )
																																																									? 0
																																																									: ""
																																																							)
																																																						: null
																																																				}
																																																				className={

																																																					(
																																																						!!sub.isString
																																																							? "tree-node-data-entry-value-box-component-textarea2 "
																																																							: "tree-node-data-entry-value-box-component-input "
																																																					)
																																																					+
																																																					(
																																																						(this.state.settedNode.filter(sn => sn === (sub.id + "." + sub.structureId)).length > 0)
																																																							? "setted-value"
																																																							: ""
																																																					)

																																																				}
																																																				onFocus={focusEvent => {
																																																					this.setState({
																																																						...this.state,
																																																						errors: !!isNotEmptyArray(this.state.errors) ? this.state.errors.filter(e => e.target !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value")) : []
																																																					});
																																																				}}
																																																				disabled={!!this.isNodeDisabled(info)}
																																																			/>
																																																			{
																																																				(
																																																					!!isNotEmptyArray(this.state.errors) &&
																																																					!!this.state.errors
																																																						.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																						.reduce(getLastElementOr, false)
																																																				)
																																																					? (
																																																						<label
																																																							className="error-message-label">
																																																							{
																																																								this.state.errors
																																																									.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value"))
																																																									.reduce(getLastElementOr, {message: ""})
																																																									.message
																																																							}
																																																						</label>
																																																					)
																																																					: null
																																																			}





																																																			{
																																																				!sub.isString
																																																					? (
																																																						<div
																																																							className={"set-value-button-container"}>
																																																							<ActionButton
																																																								color={"primary"}
																																																								promise={ () => Promise.resolve() }
																																																								buttonText={translate("monthly values")}
																																																								onResult={clickEvent => {
																																																									// clickEvent.stopPropagation();
																																																									this.setState({
																																																										...this.state,
																																																										monthlyTimingPopup: sub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.structureId,
																																																										node: sub,
																																																										nodeInformative: info
																																																									})
																																																								}}
																																																							/>
																																																							<ActionButton
																																																								promise={ () => Promise.resolve() }
																																																								// buttonText={ translate("OldValues") }
																																																								buttonText={ <i className={"mdi mdi-history srtool-question-history-button cursor-pointer"} /> }
																																																								className={"no-button-frame"}
																																																								onResult={clickEvent => {
																																																									// clickEvent.stopPropagation();
																																																									this.setState({
																																																										...this.state,
																																																										previousMonthlyValuesPopup: sub.structureId
																																																									})
																																																								}}
																																																							/>
																																																						</div>
																																																					)
																																																					: null
																																																			}






																																																		</React.Fragment>
																																																	)

																																															}

																																														</FormGroup>

																																														{
																																															!!this.isNodeDisabled(info)
																																																? null
																																																: (
																																																	<ActionButton
																																																		promise={() => {

																																																			return (

																																																				new Promise((success, fail) => {

																																																					this.setState({
																																																							...this.state,
																																																							waitingNode: [
																																																								...this.state.waitingNode,
																																																								(sub.id + "." + sub.structureId)
																																																							]
																																																						},

																																																						() => {

																																																							success(
																																																								saveTreeNodeSingleValue(
																																																									sub.structureId,
																																																									4,
																																																									sub.id,
																																																									document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value").value,
																																																									!!sub.isString
																																																								)
																																																							);
																																																						}
																																																					)

																																																				})


																																																			);


																																																		}}
																																																		onResult={result => {

																																																			let activeTab = this.state.activeTab;
																																																			this.setState({
																																																					...this.state,
																																																					settedNode: [
																																																						...this.state.settedNode,
																																																						(sub.id + "." + sub.structureId)
																																																					],
																																																					waitingNode: this.state.waitingNode.filter(wn => wn !== (sub.id + "." + sub.structureId))
																																																				},
																																																				() => {
																																																					this.loadData()
																																																						.then(refreshedTree => {

																																																							this.props.onTreeUpdate(refreshedTree, activeTab);
																																																						})
																																																					;
																																																				}
																																																			)

																																																		}}
																																																		onError={error => {
																																																			errorlog(error);
																																																			this.setState({
																																																				...this.state,
																																																				errors: [
																																																					...this.state.errors,
																																																					{
																																																						target: GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.id + "-value",
																																																						message: error
																																																					}
																																																				]
																																																			});
																																																		}}
																																																		className={"small-button tree-node-controller-bottom-button"}
																																																		buttonText={ translate("Save") }
																																																	/>
																																																)
																																														}


																																													</React.Fragment>

																																												)
																																												: (
																																													(!!!req.subRequirementsRepeating)
																																														? (
																																															<div
																																																className={"set-value-button-container"}>
																																																<ActionButton
																																																	color={"primary"}
																																																	promise={ () => Promise.resolve() }
																																																	buttonText={translate("monthly values")}
																																																	onResult={clickEvent => {
																																																		// clickEvent.stopPropagation();
																																																		this.setState({
																																																			...this.state,
																																																			monthlyTimingPopup: sub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.structureId,
																																																			node: sub,
																																																			nodeInformative: info
																																																		})
																																																	}}
																																																/>
																																															</div>
																																														)
																																														: (
																																															<div
																																																className={"set-value-button-container"}>
																																																<ActionButton
																																																	color={"primary"}
																																																	promise={ () => Promise.resolve() }
																																																	buttonText={translate("monthly values")}
																																																	onResult={clickEvent => {
																																																		// clickEvent.stopPropagation();
																																																		this.setState({
																																																			...this.state,
																																																			// "virtualNode_"+ req.id +"."+ req.structureId +"-"+ node.id +"_"+ node.griSubrequirementId
																																																			monthlyTimingPopup: sub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + sub.structureId,
																																																			node: sub,
																																																			nodeInformative: info
																																																		})
																																																	}}
																																																/>
																																															</div>
																																														)
																																												)
																																										}


																																									</React.Fragment>

																																								) // se è evaluate
																																								: null

																																						}
																																					</div>
																																				</div>

																																			)
																																			:
																																			null
																																	}




																																</Row>

																												</CardHeader>

																													{
																														(sub.subSubReqs.length > 0)
																															? (
																																// <UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																																<Collapse isOpen={ !!sub.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																																	<CardBody>

																																		{
																																			(sub.subSubReqs.length > 0)
																																				? (
																																					sub.subSubReqs
																																						.map(subSub => {
																																							return (
																																								<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-" + subSub.id}>





																																									<CardHeader
																																										className={
																																											[
																																												// colore ---------------------------------------------
																																												// colorClassRequirement
																																												// ,
																																												"tree-node-header-container"
																																											]
																																												.join(" ")
																																										}
																																										id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-" + subSub.structureId}
																																									>
																																										<Row className={"tree-node-header"}>
																																											<Col sm={9} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( subSub ) }>

																																												{ this.helperNode( subSub ) }
																																												{ subSub.name }

																																											</Col>

																																											<Col sm={3} className={"tree-node-header-single-command"}>

																																												{
																																													(!!subSub.evaluate)
																																														? (
																																															<React.Fragment>
																																																<UncontrolledPopover
																																																	placement="top"
																																																	target={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id }
																																																	trigger="legacy"
																																																	className={""}
																																																>
																																																	<PopoverHeader>Storico</PopoverHeader>
																																																	<PopoverBody>Ultima modifica { subSub.lastUpdate }</PopoverBody>
																																																</UncontrolledPopover>
																																																<i
																																																	className={"mdi mdi-history srtool-question-history-button cursor-pointer"}
																																																	id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id }
																																																/>



																																																{
																																																	(!!subSub.compilationRequirements && !!subSub.compilationRequirements)
																																																		? (
																																																			<React.Fragment>

																																																				<i
																																																					className={`dripicons-align-justify srtool-question-history-button cursor-pointer`}
																																																					id={`completitionRequirements-${ subSub.id }`}
																																																				/>

																																																				<UncontrolledPopover
																																																					placement="right"
																																																					target={`completitionRequirements-${ subSub.id }`}
																																																					trigger="legacy"
																																																					className={"info-guide-lines-popover"}
																																																				>
																																																					<PopoverHeader>{ translate("compilationRequirements") }</PopoverHeader>
																																																					<PopoverBody>
																																																						<div>{ subSub.compilationRequirements }</div>
																																																					</PopoverBody>
																																																				</UncontrolledPopover>

																																																			</React.Fragment>
																																																		)
																																																		: null
																																																}


																																																{/*
																																																		<RecapStatusIconSvgComponent
																																																			className={"srtool-question-history-button cursor-pointer"}
																																																			id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id }
																																																		/>
																																																		*/}
																																															</React.Fragment>
																																														)
																																														: null
																																												}

																																											</Col>

																																										</Row>

																																										<Row className={"tree-node-controllers"}>
																																											<div className={"tree-node-controllers-group"}>
																																												{/*
																																															(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																																																? (
																																																	<div className={"tree-node-controller"}>
																																																		<Label className={"tree-node-controller-label"}>Manager</Label>
																																																		<ActionButton
																																																			buttonText={
																																																				<React.Fragment>
																																																					<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																																																					<span className={"tree-node-controller-text-button" + (!!subSub.userManager ? "" : " tree-node-controller-text-button-empty")} >
																																																						{
																																																							(!!subSub.userManager)
																																																								? subSub.userManagerName
																																																								: translate("Select")
																																																						}
																																																					</span>
																																																				</React.Fragment>
																																																			}
																																																			color={ (!!subSub.userManager) ? "success" : "primary" }
																																																			className={ "tree-node-controller-button" }
																																																			promise={ () => {
																																																				return Promise.resolve( );
																																																			}}
																																																			onResult={ result => {

																																																				if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																																					this.setState({
																																																						...this.state,
																																																						getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.activeTab).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + sub.id + "-" + subSub.id,
																																																						currentUserManager: subSub.userManager
																																																					})
																																																				}

																																																			}}
																																																			onError={ e => {

																																																			}}
																																																		/>
																																																	</div>
																																																)
																																																: null
																																														*/}

																																												<div className={"tree-node-controller"}>
																																													{/*
																																															<Label className={"tree-node-controller-label"}>Timing</Label>
																																															<span className={"tree-node-controller-value"}>
																																																	{
																																																		(!!subSub.timing)
																																																			? "Mensile"
																																																			: "Annuale"
																																																	}
																																															</span>
																																															*/}
																																												</div>

																																											</div>
																																											<div className={"tree-node-controllers-group"}>

																																												{
																																													(!!subSub.evaluate)
																																														? (
																																															<div className={"tree-node-controller"}>
																																																{
																																																	!!!subSub.isString
																																																		? <Label className={"tree-node-controller-label"}>{ translate("MeasureUnit") }</Label>
																																																		: null
																																																}

																																																<span className={"tree-node-controller-value"}>
																																																			{
																																																				!!subSub.measureUnit
																																																					? (
																																																						!!!subSub.isString
																																																							?
																																																							(
																																																								(subSub.measureUnit.name === "numeric")
																																																									?
																																																									(
																																																										translate("numeric")
																																																									)
																																																									:
																																																									subSub.measureUnit.name
																																																							)
																																																							: null
																																																					)
																																																					: "Nessuna"
																																																			}
																																																		</span>
																																															</div>
																																														)
																																														: null
																																												}


																																												<div className={"tree-node-controller"}>
																																													{
																																														(!!hasInformativeGoal(info.name) && hasGoal( subSub ) )
																																															? (
																																																<ActionButton
																																																	buttonText={
																																																		<React.Fragment>
																																																			{
																																																				// ? <i className={"mdi mdi-target tree-node-controller-text-button-icon"}/>
																																																				!!subSub.valueGoal
																																																					? <GoalSvgComponent className={"goal-icon-component"}/>
																																																					: <MissingGoalSvgComponent className={"goal-icon-component"}/>
																																																			}
																																																			<span className={"tree-node-controller-text-button tree-node-controller-goal-text-button"}>&nbsp;&nbsp;{ translate("Goal") }</span>
																																																		</React.Fragment>
																																																	}
																																																	color={ "link" }
																																																	className={
																																																		"tree-node-controller-button "+
																																																		(
																																																			!!subSub.valueGoal
																																																				? Object.keys(TABS)
																																																					.map( t => TABS[t] )
																																																					.filter( t => t.id === this.state.activeTab )
																																																					.reduce( getLastElementOr, {goalClassName: ""})
																																																					.goalClassName
																																																				: "goal-button-disabled"
																																																		)
																																																	}
																																																	promise={ () => Promise.resolve() }
																																																	onResult={ clickEvent => {
																																																		this.setState({
																																																			...this.state,
																																																			goalPopupOpen: subSub,
																																																			goalPopupLevel: 5,
																																																			nodeInformative: info
																																																		});
																																																	}}
																																																/>
																																															)
																																															: null
																																													}
																																												</div>
																																											</div>


																																											{ //caso in cui subsub non è textarea

																																												(!subSub.isString || subSub.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE) ?
																																													(

																																														<div className={"tree-node-controllers-group "+ (!!subSub.isString ? "tree-node-controller-group-textarea-container" : "")}>
																																															<div className={"tree-node-controller tree-node-controller-textarea"}>

																																																{
																																																	(!!subSub.evaluate)
																																																		? (
																																																			<React.Fragment>
																																																				{

																																																					// se il timing è mensile ( true )
																																																					// allora tasto per pertura modale
																																																					// altrimenti input con tasto invio
																																																					(!!!subSub.timing)
																																																						? (

																																																							<React.Fragment>
																																																								{/*	tree-node-data-entry-value-box-component	*/}
																																																								<FormGroup
																																																									className={"" + ((!subSub.isString) ? " description-textarea-container-value" : "") + (
																																																										(this.state.settedNode.filter(sn => sn === (subSub.id + "." + subSub.structureId)).length > 0)
																																																											? " setted-value2"
																																																											: ""
																																																									)}
																																																								>


																																																									{
																																																										(subSub.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)
																																																											? (
																																																												<React.Fragment>
																																																													<HyperDatepicker
																																																														hideAddon={false}
																																																														dateFormat={CALENDAR_DATE_FORMAT_IT}
																																																														onSelect={clickEvent => {

																																																															this.setState({
																																																																...this.state,
																																																																dateValues: [
																																																																	...this.state.dateValues
																																																																		.filter(dv => dv.id !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value")),
																																																																	{
																																																																		id: (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"),
																																																																		value: moment(clickEvent).format(DATE_FORMAT_IT)
																																																																	}
																																																																]
																																																															})

																																																														}}
																																																														value={
																																																															(!!isNotEmptyArray(this.state.dateValues))
																																																																? (
																																																																	this.state.dateValues
																																																																		.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																		.map(dv => dv.value)
																																																																		.reduce(getLastElementOr, null)
																																																																)
																																																																: (
																																																																	(!!subSub.value && !!subSub.value[0])
																																																																		? subSub.value[0]
																																																																		: moment().format(DATE_FORMAT_IT)
																																																																)
																																																														}
																																																														selected={
																																																															this.state.dateValues
																																																																.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																.map(dv => dv.value)
																																																																.reduce(getLastElementOr, null)
																																																														}
																																																														peekNextMonth
																																																														showMonthDropdown
																																																														showYearDropdown
																																																														dropdownMode="select"
																																																														yearDropdownItemNumber={15}
																																																														todayButton="Oggi"
																																																														// minDate={new Date()}
																																																														// maxDate={addDays(new Date(), 5)}
																																																														locale={"IT"}
																																																														highlightDates={[new Date()]}
																																																														showWeekNumbers
																																																													/>
																																																													<input
																																																														type={"hidden"}
																																																														id={(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value")}
																																																														value={
																																																															this.state.dateValues
																																																																.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																.map(dv => dv.value)
																																																																.reduce(getLastElementOr, null)
																																																														}
																																																														disabled={!!this.isNodeDisabled(info)}
																																																													/>
																																																												</React.Fragment>
																																																											)
																																																											: (
																																																												<React.Fragment>
																																																													<Input
																																																														// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
																																																														type={!!subSub.isString ? "textarea" : "number"}
																																																														rows={!!subSub.isString ? 5 : 1}
																																																														name={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"}
																																																														id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"}
																																																														defaultValue={
																																																															/*
																																																															(!!subSub && !!subSub.value)
																																																																? subSub.value[0]
																																																																: (
																																																																	(!subSub.isString)
																																																																		? 0
																																																																		: ""
																																																																)
																																																															 */

																																																															(!!subSub.isValueActive)
																																																																? (!!subSub && !!subSub.value)
																																																																	? subSub.value[0]
																																																																	: (
																																																																		( !subSub.isString )
																																																																			? 0
																																																																			: ""
																																																																	)
																																																																: null
																																																														}
																																																														className={

																																																															(
																																																																!!subSub.isString
																																																																	? "tree-node-data-entry-value-box-component-textarea "
																																																																	: "tree-node-data-entry-value-box-component-input "
																																																															)
																																																															+
																																																															(
																																																																(this.state.settedNode.filter(sn => sn === (subSub.id + "." + subSub.structureId)).length > 0)
																																																																	? "setted-value"
																																																																	: ""
																																																															)

																																																														}
																																																														onClick={clickEvent => {
																																																															// clickEvent.stopPropagation();
																																																														}}
																																																														onFocus={focusEvent => {
																																																															this.setState({
																																																																...this.state,
																																																																errors: !!isNotEmptyArray(this.state.errors) ? this.state.errors.filter(e => e.target !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value")) : []
																																																															});
																																																														}}
																																																														disabled={!!this.isNodeDisabled(info)}
																																																													/>
																																																													{
																																																														(
																																																															!!isNotEmptyArray(this.state.errors) &&
																																																															!!this.state.errors
																																																																.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																.reduce(getLastElementOr, false)
																																																														)
																																																															? (
																																																																<label
																																																																	className="error-message-label">
																																																																	{
																																																																		this.state.errors
																																																																			.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																			.reduce(getLastElementOr, {message: ""})
																																																																			.message
																																																																	}
																																																																</label>
																																																															)
																																																															: null
																																																													}




																																																													{
																																																														!subSub.isString
																																																															? (
																																																																<div
																																																																	className={"set-value-button-container"}>
																																																																	<ActionButton
																																																																		color={"primary"}
																																																																		promise={ () => Promise.resolve() }
																																																																		buttonText={translate("monthly values")}
																																																																		onResult={clickEvent => {
																																																																			// clickEvent.stopPropagation();
																																																																			this.setState({
																																																																				...this.state,
																																																																				monthlyTimingPopup: subSub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.structureId,
																																																																				node: subSub,
																																																																				nodeInformative: info
																																																																			})
																																																																		}}
																																																																	/>

																																																																	<ActionButton
																																																																		promise={ () => Promise.resolve() }
																																																																		// buttonText={ translate("OldValues") }
																																																																		buttonText={ <i className={"mdi mdi-history srtool-question-history-button cursor-pointer"} /> }
																																																																		className={"no-button-frame"}
																																																																		onResult={clickEvent => {
																																																																			// clickEvent.stopPropagation();
																																																																			this.setState({
																																																																				...this.state,
																																																																				previousMonthlyValuesPopup: subSub.structureId
																																																																			})
																																																																		}}
																																																																	/>
																																																																</div>
																																																															)
																																																															: null
																																																													}


																																																												</React.Fragment>
																																																											)
																																																									}


																																																								</FormGroup>

																																																								{
																																																									!!this.isNodeDisabled(info)
																																																										? null
																																																										: (
																																																											<ActionButton
																																																												promise={() => {

																																																													return (

																																																														new Promise((success, fail) => {

																																																															this.setState({
																																																																	...this.state,
																																																																	waitingNode: [
																																																																		...this.state.waitingNode,
																																																																		(subSub.id + "." + subSub.structureId)
																																																																	]
																																																																},

																																																																() => {

																																																																	success(
																																																																		saveTreeNodeSingleValue(
																																																																			subSub.structureId,
																																																																			5,
																																																																			subSub.id,
																																																																			document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value").value,
																																																																			!!subSub.isString
																																																																		)
																																																																	);
																																																																}
																																																															)

																																																														})


																																																													);


																																																												}}
																																																												onResult={ result => {

																																																													let activeTab = this.state.activeTab;

																																																													this.setState({
																																																															...this.state,
																																																															settedNode: [
																																																																...this.state.settedNode,
																																																																(subSub.id + "." + subSub.structureId)
																																																															],
																																																															waitingNode: this.state.waitingNode.filter( wn => wn !== (subSub.id + "." + subSub.structureId) )
																																																														},
																																																														() => {
																																																															this.loadData()
																																																																.then( refreshedTree => {

																																																																	this.props.onTreeUpdate( refreshedTree, activeTab );
																																																																})
																																																															;
																																																														}
																																																													)

																																																												}}
																																																												onError={ error => {

																																																													errorlog( error );
																																																													this.setState({
																																																														...this.state,
																																																														errors: [
																																																															...this.state.errors,
																																																															{
																																																																target: GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value",
																																																																message: error
																																																															}
																																																														]
																																																													});


																																																												}}
																																																												className={"small-button tree-node-controller-bottom-button"}
																																																												buttonText={ translate("Save") }
																																																											/>
																																																										)

																																																								}


																																																							</React.Fragment>

																																																						)
																																																						: (
																																																							(!!!subSub.subRequirementsRepeating)
																																																								? (
																																																									<div
																																																										className={"set-value-button-container"}>
																																																										<ActionButton
																																																											color={"primary"}
																																																											promise={ () => Promise.resolve() }
																																																											buttonText={translate("monthly values")}
																																																											onResult={clickEvent => {
																																																												// clickEvent.stopPropagation();
																																																												this.setState({
																																																													...this.state,
																																																													monthlyTimingPopup: subSub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.structureId,
																																																													node: subSub,
																																																													nodeInformative: info
																																																												})
																																																											}}
																																																										/>
																																																									</div>
																																																								)
																																																								: null
																																																						)
																																																				}


																																																			</React.Fragment>

																																																		) // se è evaluate
																																																		: null

																																																}

																																															</div>
																																														</div>


																																													)
																																														:
																																														null


																																											}

																																										</Row>



																																										<Row>


																																											{ //caso in cui subsub è textarea
																																												(!!subSub.isString && subSub.measureUnit.unitMeasurementId !== MEASURE_UNIT_ID_DATE)?
																																													(

																																														<div className={"tree-node-controllers-group tree-node-controller-group-textarea-container2"}>
																																															<div className={"tree-node-controller tree-node-controller-textarea2"}>

																																																{
																																																	(!!subSub.evaluate)
																																																		? (
																																																			<React.Fragment>
																																																				{

																																																					// se il timing è mensile ( true )
																																																					// allora tasto per pertura modale
																																																					// altrimenti input con tasto invio
																																																					(!!!subSub.timing)
																																																						? (

																																																							<React.Fragment>
																																																								{/*	tree-node-data-entry-value-box-component	*/}
																																																								<FormGroup
																																																									className={"" + ((!subSub.isString) ? " description-textarea-container-value" : "") + (
																																																										(this.state.settedNode.filter(sn => sn === (subSub.id + "." + subSub.structureId)).length > 0)
																																																											? " setted-value2"
																																																											: ""
																																																									)}
																																																								>


																																																									{
																																																										(subSub.measureUnit.unitMeasurementId === MEASURE_UNIT_ID_DATE)
																																																											? (
																																																												<React.Fragment>
																																																													<HyperDatepicker
																																																														hideAddon={false}
																																																														dateFormat={CALENDAR_DATE_FORMAT_IT}
																																																														onSelect={clickEvent => {

																																																															this.setState({
																																																																...this.state,
																																																																dateValues: [
																																																																	...this.state.dateValues
																																																																		.filter(dv => dv.id !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value")),
																																																																	{
																																																																		id: (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"),
																																																																		value: moment(clickEvent).format(DATE_FORMAT_IT)
																																																																	}
																																																																]
																																																															})

																																																														}}
																																																														value={
																																																															(!!isNotEmptyArray(this.state.dateValues))
																																																																? (
																																																																	this.state.dateValues
																																																																		.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																		.map(dv => dv.value)
																																																																		.reduce(getLastElementOr, null)
																																																																)
																																																																: (
																																																																	(!!subSub.value && !!subSub.value[0])
																																																																		? subSub.value[0]
																																																																		: moment().format(DATE_FORMAT_IT)
																																																																)
																																																														}
																																																														selected={
																																																															this.state.dateValues
																																																																.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																.map(dv => dv.value)
																																																																.reduce(getLastElementOr, null)
																																																														}
																																																														peekNextMonth
																																																														showMonthDropdown
																																																														showYearDropdown
																																																														dropdownMode="select"
																																																														yearDropdownItemNumber={15}
																																																														todayButton="Oggi"
																																																														// minDate={new Date()}
																																																														// maxDate={addDays(new Date(), 5)}
																																																														locale={"IT"}
																																																														highlightDates={[new Date()]}
																																																														showWeekNumbers
																																																													/>
																																																													<input
																																																														type={"hidden"}
																																																														id={(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value")}
																																																														value={
																																																															this.state.dateValues
																																																																.filter(dv => dv.id === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																.map(dv => dv.value)
																																																																.reduce(getLastElementOr, null)
																																																														}
																																																														disabled={!!this.isNodeDisabled(info)}
																																																													/>
																																																												</React.Fragment>
																																																											)
																																																											: (
																																																												<React.Fragment>
																																																													<Input
																																																														// type={ (!!this.props.node.measureUnitIsString) ? "text" : "number" }
																																																														type={!!subSub.isString ? "textarea" : "number"}
																																																														rows={!!subSub.isString ? 5 : 1}
																																																														name={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"}
																																																														id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"}
																																																														defaultValue={
																																																															/*
																																																															(!!subSub && !!subSub.value)
																																																																? subSub.value[0]
																																																																: (
																																																																	(!subSub.isString)
																																																																		? 0
																																																																		: ""
																																																																)
																																																																*/
																																																															(!!subSub.isValueActive)
																																																																? (!!subSub && !!subSub.value)
																																																																	? subSub.value[0]
																																																																	: (
																																																																		( !subSub.isString )
																																																																			? 0
																																																																			: ""
																																																																	)
																																																																: null
																																																														}
																																																														className={

																																																															(
																																																																!!subSub.isString
																																																																	? "tree-node-data-entry-value-box-component-textarea2 "
																																																																	: "tree-node-data-entry-value-box-component-input "
																																																															)
																																																															+
																																																															(
																																																																(this.state.settedNode.filter(sn => sn === (subSub.id + "." + subSub.structureId)).length > 0)
																																																																	? "setted-value"
																																																																	: ""
																																																															)

																																																														}
																																																														onClick={clickEvent => {
																																																															// clickEvent.stopPropagation();
																																																														}}
																																																														onFocus={focusEvent => {
																																																															this.setState({
																																																																...this.state,
																																																																errors: !!isNotEmptyArray(this.state.errors) ? this.state.errors.filter(e => e.target !== (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value")) : []
																																																															});
																																																														}}
																																																														disabled={!!this.isNodeDisabled(info)}
																																																													/>
																																																													{
																																																														(
																																																															!!isNotEmptyArray(this.state.errors) &&
																																																															!!this.state.errors
																																																																.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																.reduce(getLastElementOr, false)
																																																														)
																																																															? (
																																																																<label
																																																																	className="error-message-label">
																																																																	{
																																																																		this.state.errors
																																																																			.filter(e => e.target === (GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value"))
																																																																			.reduce(getLastElementOr, {message: ""})
																																																																			.message
																																																																	}
																																																																</label>
																																																															)
																																																															: null
																																																													}




																																																													{
																																																														!subSub.isString
																																																															? (
																																																																<div
																																																																	className={"set-value-button-container"}>
																																																																	<ActionButton
																																																																		color={"primary"}
																																																																		promise={ () => Promise.resolve() }
																																																																		buttonText={translate("monthly values")}
																																																																		onResult={clickEvent => {
																																																																			// clickEvent.stopPropagation();
																																																																			this.setState({
																																																																				...this.state,
																																																																				monthlyTimingPopup: subSub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.structureId,
																																																																				node: subSub,
																																																																				nodeInformative: info
																																																																			})
																																																																		}}
																																																																	/>
																																																																</div>
																																																															)
																																																															: null
																																																													}


																																																												</React.Fragment>
																																																											)
																																																									}


																																																								</FormGroup>

																																																								{
																																																									!!this.isNodeDisabled(info)
																																																										? null
																																																										: (
																																																											<ActionButton
																																																												promise={() => {

																																																													return (

																																																														new Promise((success, fail) => {

																																																															this.setState({
																																																																	...this.state,
																																																																	waitingNode: [
																																																																		...this.state.waitingNode,
																																																																		(subSub.id + "." + subSub.structureId)
																																																																	]
																																																																},

																																																																() => {

																																																																	success(
																																																																		saveTreeNodeSingleValue(
																																																																			subSub.structureId,
																																																																			5,
																																																																			subSub.id,
																																																																			document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value").value,
																																																																			!!subSub.isString
																																																																		)
																																																																	);
																																																																}
																																																															)

																																																														})


																																																													);


																																																												}}
																																																												onResult={ result => {

																																																													let activeTab = this.state.activeTab;

																																																													this.setState({
																																																															...this.state,
																																																															settedNode: [
																																																																...this.state.settedNode,
																																																																(subSub.id + "." + subSub.structureId)
																																																															],
																																																															waitingNode: this.state.waitingNode.filter( wn => wn !== (subSub.id + "." + subSub.structureId) )
																																																														},
																																																														() => {
																																																															this.loadData()
																																																																.then( refreshedTree => {

																																																																	this.props.onTreeUpdate( refreshedTree, activeTab );
																																																																})
																																																															;
																																																														}
																																																													)

																																																												}}
																																																												onError={ error => {

																																																													errorlog( error );
																																																													this.setState({
																																																														...this.state,
																																																														errors: [
																																																															...this.state.errors,
																																																															{
																																																																target: GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.id + "-value",
																																																																message: error
																																																															}
																																																														]
																																																													});


																																																												}}
																																																												className={"small-button tree-node-controller-bottom-button"}
																																																												buttonText={ translate("Save") }
																																																											/>
																																																										)

																																																								}


																																																							</React.Fragment>

																																																						)
																																																						: (
																																																							(!!!subSub.subRequirementsRepeating)
																																																								? (
																																																									<div
																																																										className={"set-value-button-container"}>
																																																										<ActionButton
																																																											color={"primary"}
																																																											promise={ () => Promise.resolve() }
																																																											buttonText={translate("monthly values")}
																																																											onResult={clickEvent => {
																																																												// clickEvent.stopPropagation();
																																																												this.setState({
																																																													...this.state,
																																																													monthlyTimingPopup: subSub.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] + "-" + subSub.structureId,
																																																													node: subSub,
																																																													nodeInformative: info
																																																												})
																																																											}}
																																																										/>
																																																									</div>
																																																								)
																																																								: null
																																																						)
																																																				}


																																																			</React.Fragment>

																																																		) // se è evaluate
																																																		: null

																																																}

																																															</div>
																																														</div>

																																													)
																																													:
																																													null


																																											}





																																										</Row>




																																									</CardHeader>



																																								</Card>
																																							);
																																						})
																																				)
																																				: null
																																		}


																																	</CardBody>
																																</Collapse>
																															)
																															: null
																													}

																												</Card>
																												);
																												})
																										}




																									</CardBody>
																								</Collapse>
																							)
																							: (

																								// zone ------------------------------------------------------------------------------------------------------------------
																								(!!req.zones && req.zones.length > 0)
																									? (
																										<Collapse isOpen={ !!req.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																											<CardBody>
																												<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																													<CardHeader className={"tree-node-chatper"}>

																														<Nav tabs>
																															{
																																req.zones
																																	.map((zone, zoneIndex) => {
																																		return (
																																			<NavItem key={zoneIndex}>
																																				<NavLink
																																					id={ "zone-"+ zone.id }
																																					href="#"
																																					className={classnames({ active: (zone.id === this.state.virtualTree.zone) })}

																																					onClick={() => {


																																						showlog(zone.child);


																																						// this.setState({
																																						// 	...this.state,
																																						// 	virtualTree: {
																																						// 		zone: zone.id,
																																						// 		// currentValues: values
																																						// 	}
																																						// })




																																						// wait
																																						// subRequirementId, fatherStructureId, areaId
																																						loadVirtualTreeNodeValue( 0, req.structureId, zone.id  )
																																							.then( values => {

																																								this.setState({
																																									...this.state,
																																									virtualTree: {
																																										zone: zone.id,
																																										currentValues: values
																																									},
																																									// forceUpdate: true
																																								},
																																									() => {

																																									}
																																								)

																																							})
																																							.catch( e => {
																																								errorlog("caricamento valori delle ripetizioni", e );
																																							})



																																					}}
																																				>

																																					{/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}


																																					{
																																						(!!this.state.editTabName && this.state.editTabName === zone.id)
																																							? (
																																								<Input
																																									id={"zoneTabNameInput-"+ zone.id}
																																									defaultValue={ zone.name }
																																									onChange={ textInsertEvent => {
																																										showlog("inserimento testo");
																																										// showlog(textInsertEvent);
																																										showlog(textInsertEvent.target.value);

																																										/*
																																										let newTabs = cloneObject( this.state.tree ) );
																																										newTabs =
																																											newTabs
																																												.map( t => {
																																													if( t.id === zone.id ) t.name = textInsertEvent.target.value;
																																													return t;
																																												})
																																										;
																																										*/

																																										let treeTmp = cloneObject( this.state.tree );
																																										// treeTmp.tabs
																																										// 	.filter( t => !!t.open)
																																										// 	.map( t => {
																																										// 		if( t.id === zone.id ) t.name = textInsertEvent.target.value;
																																										// 		return t;
																																										// 	})
																																										// ;


																																										treeTmp
																																											.tabs.filter(t => t.open).reduce( getLastElementOr, { chapters: [] } )
																																											.chapters.filter(c => c.id === ch.id).reduce( getLastElementOr, { infos: [] } )
																																											.infos.filter(i => i.id === info.id).reduce( getLastElementOr, { requirements: [] } )
																																											.requirements.filter(r => r.id === req.id).reduce( getLastElementOr, { zones: [] } )
																																											.zones.filter( z => z.id === zone.id ).reduce( getLastElementOr, {} )
																																											.name
																																												= textInsertEvent.target.value
																																										;



																																										this.setState({
																																											...this.state,
																																											tree: treeTmp
																																										})


																																									}}



																																									onBlur={ blurEvent => {


																																										// NON FUNZIONA <-----------------------------------------------------------------------------------------------

																																										showlog("blur");
																																										this.setState(
																																											{
																																												...this.state,
																																												editTabName: null
																																											},
																																											() => {

																																												let newName =
																																													this.state.tree
																																														.tabs.filter(t => t.open)[0]
																																														.chapters.filter(c => c.id === ch.id)[0]
																																														.infos.filter(i => i.id === info.id)[0]
																																														.requirements.filter(r => r.id === req.id)[0]
																																														.zones.filter( z => z.id === zone.id )
																																														.map( z => z.name )
																																														.join("")
																																												;



																																												if( !!newName ) {
																																													if( newName === "virtualNode-selfCloned" || newName.trim() === "") {
																																														// newName = zone.child[0].griRepeatingValueName;
																																														newName = zone.name;
																																													}
																																													else {
																																														showlog("chiamata cambio nome alla ripetizione", zone.id, newName);

																																														changeRepeatingZoneName( zone.id, newName )
																																															.then(() => {

																																																this.loadData(); // controllami
																																															})
																																															.catch(e => {
																																																errorlog("errore al cambio nome della ripetizione", e );
																																															})
																																													}

																																												}


																																											}
																																										)
																																									}}



																																									onKeyUp={ keyUpEvent => {
																																										if( keyUpEvent.key === "Enter" ) {
																																											this.setState(
																																												{
																																													...this.state,
																																													editTabName: null
																																												},
																																												() => {


																																													showlog("controllo tabs");

																																													let newName =
																																														this.state.tree
																																															.tabs.filter(t => t.open)[0]
																																															.chapters.filter(c => c.id === ch.id)[0]
																																															.infos.filter(i => i.id === info.id)[0]
																																															.requirements.filter(r => r.id === req.id)[0]
																																															.zones.filter( z => z.id === zone.id )
																																															.map( z => z.name )
																																															.join("")
																																													;

																																													showlog("chiamata cambio nome alla ripetizione", zone.id, newName);

																																													changeRepeatingZoneName( zone.id, newName )
																																														.then(() => {

																																															this.loadData(); // controllami
																																														})
																																														.catch(e => {
																																															console.error("errore al cambio nome della ripetizione");
																																															showlog( e );
																																														})

																																												}
																																											)
																																										}
																																									}}

																																								/>
																																							)
																																							: <span className="material-themes-tab-name">{zone.name}</span>
																																					}



																																							{
																																								(!!this.state.editTabName && this.state.editTabName === zone.id)
																																									? null
																																									: (
																																										!!this.isNodeDisabled(info)
																																											? null
																																											: (
																																												<Button
																																													outline
																																													color={"link"}
																																													className={"material-themes-tab-name-icon-button"}
																																													onClick={clickEvent => {

																																														let positionY = window.scrollY;
																																														showlog("click on edit tab " + positionY);
																																														clickEvent.stopPropagation();

																																														this.setState({
																																																...this.state,
																																																editTabName: zone.id
																																															},
																																															() => {
																																																document.getElementById("zoneTabNameInput-" + zone.id).focus();

																																																setTimeout(
																																																	() => {
																																																		window.scrollTo(0, positionY);
																																																		showlog("scrolled to " + positionY);

																																																		document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.parentElement.scrollTo(0, this.state.pointToScroll || positionY || 0);

																																																	},
																																																	5
																																																)
																																															});
																																													}}
																																												>
																																													<i className="mdi mdi-pencil"/>
																																												</Button>
																																											)

																																									)
																																							}

																																					<span>&nbsp;&nbsp;</span>


																																							{
																																								(!!this.state.editTabName && this.state.editTabName === zone.id)
																																									? null
																																									: (
																																										!!this.isNodeDisabled(info)
																																											? null
																																											: (
																																												<i
																																													className="mdi mdi-delete danger-trash"
																																													onClick={clickEvent => {

																																														showlog("tentativo di cancellazione di ", zone);
																																														setTimeout(function () {

																																															this.setState({
																																																	...this.state,
																																																	deleteTabPopup: zone.id
																																																},
																																																() => {
																																																	showlog("dopo aver settato lo state per la cancellazione");
																																																	showlog(this.state);
																																																});

																																														}.bind(this), 500);

																																													}}
																																												/>
																																											)
																																									)
																																							}


																																				</NavLink>

																																			</NavItem>
																																		);
																																	})
																															}
																														</Nav>

																														<Row>
																															<Col sm={12}>
																																<div className={"ghost-level-header"}>
																																	<span>{/* Sotto Requisiti */}</span>
																																</div>
																															</Col>
																														</Row>


																													</CardHeader>
																													<CardBody>
																														<TabContent activeTab={ this.state.virtualTree.zone }>
																															{
																																req.zones
																																	.map((zone, zoneIndex) => {
																																		return (
																																			<TabPane
																																				tabId={zone.id}
																																				key={zoneIndex}
																																			>


																																				{
																																					zone.child
																																						.filter( onlyNotNull )
																																						.map( (node, nodeIndex) => {
																																							return (
																																								<Card className="mb-0" key={ "nodeZone_"+ req.id +"."+ req.structureId +"-"+ node.id +"_"+ node.id }>


																																											{/*		Nuovo stile   ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}


																																									<CardHeader
																																										className={
																																											[
																																												// colore ---------------------------------------------
																																												// colorClassRequirement
																																												// ,
																																												"tree-node-header-container"
																																											]
																																												.join(" ")
																																										}
																																										id={ "virtualNode-"+ node.id }
																																									>
																																										<Row className={"tree-node-header"}>
																																											<Col sm={12} className={"tree-node-header-body"}>

																																												{ this.helperNode( node ) }
																																												{ node.name }

																																											</Col>
																																										</Row>

																																										<Row className={"tree-node-controllers"}>
																																											<div className={"tree-node-controllers-group"}>
																																												<div className={"tree-node-controller"}>
																																													{/*
																																													<Label className={"tree-node-controller-label"}>Manager</Label>
																																													<ActionButton
																																														buttonText={
																																															<React.Fragment>
																																																<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																																																<span className={"tree-node-controller-text-button" + (!!node.userManager ? "" : " tree-node-controller-text-button-empty")} >
																																																	{
																																																		(!!node.userManager)
																																																			? node.userManagerName
																																																			: translate("Select")
																																																	}
																																																</span>
																																															</React.Fragment>
																																														}
																																														color={ (!!node.userManager) ? "success" : "primary" }
																																														className={ "tree-node-controller-button" }
																																														promise={ () => {
																																															return Promise.resolve( );
																																														}}
																																														onResult={ result => {

																																															if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																																this.setState({
																																																	...this.state,
																																																	getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.activeTab).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + node.id,
																																																	currentUserManager: node.userManager
																																																})
																																															}

																																														}}
																																														onError={ e => {

																																														}}
																																													/>
																																													*/}
																																												</div>
																																												<div className={"tree-node-controller"}>
																																													{/*
																																													<Label className={"tree-node-controller-label"}>Timing</Label>
																																													<span className={"tree-node-controller-value"}>
																																														{
																																															(!!node.timing)
																																																? "Mensile"
																																																: "Annuale"
																																														}
																																													</span>
																																													*/}
																																												</div>
																																											</div>
																																											<div className={"tree-node-controllers-group"}>
																																												<div className={"tree-node-controller"}>
																																													<Label className={"tree-node-controller-label"}>{ translate("MeasureUnit") }</Label>
																																													<span className={"tree-node-controller-value"}>
																																														{
																																															!!node.measureUnit
																																																?
																																																(
																																																	(node.measureUnit.name === "numeric")
																																																		?
																																																		(
																																																			translate("numeric")
																																																		)
																																																		:
																																																		node.measureUnit.name
																																																)
																																																: "Nessuna"
																																														}
																																													</span>
																																												</div>
																																												<div className={"tree-node-controller"}>
																																													{/*
																																														(!!hasInformativeGoal(info.name))
																																															? (
																																																<ActionButton
																																																	buttonText={
																																																		<React.Fragment>
																																																			<i className={"mdi mdi-target tree-node-controller-text-button-icon"}/>
																																																			<span className={"tree-node-controller-text-button tree-node-controller-goal-text-button"}>&nbsp;&nbsp;Obiettivo</span>
																																																		</React.Fragment>
																																																	}
																																																	color="info"
																																																	className="tree-node-controller-button"
																																																	promise={ () => Promise.resolve() }
																																																	onResult={ clickEvent => {
																																																		this.setState({
																																																			...this.state,
																																																			goalPopupOpen: node,
																																																			goalPopupLevel: 3
																																																		});
																																																	}}
																																																/>
																																															)
																																															: null
																																													*/}
																																												</div>
																																											</div>

																																											<div className={"tree-node-controllers-group"}>

																																												{/*
																																												<pre>
																																													{
																																														(!!this.state.virtualTree && !!this.state.virtualTree.currentValues)
																																															? beautify(
																																																this.state.virtualTree.currentValues
																																																	.filter( v => v.griSubrequirementId === node.id )
																																																	.filter( v => ( v.monthNumber === 0 ))
																																																	.filter( v => ( !!v.isValueActive ))
																																																	.map( v => ( !!v.valueType ? v.valueString : v.valueDecimal ))
																																																	.reduce( getLastElementOr, null )
																																															)
																																															: null
																																													}
																																												</pre>
																																												*/}

																																												<div className={"tree-node-controller tree-node-controller-textarea"}>
																																													{
																																														(!!node.evaluate)
																																															? (
																																																<React.Fragment>
																																																	{

																																																		// se il timing è mensile ( true )
																																																		// allora tasto per pertura modale
																																																		// altrimenti input con tasto invio
																																																		(!!!node.timing)
																																																			// (!!node.isString)
																																																			// 	? ()
																																																			// 	: ()


																																																			? (

																																																				<React.Fragment>

																																																					<TreeDataEntryValueBoxComponent
																																																						id={ "virtualNode-"+ node.id +"-value" }
																																																						node={ node }
																																																						req={ req }
																																																						zoneId={ zone.id }
																																																						// loadValueIsNeeded={ this.state.virtualTree.zone === zone.id }
																																																						loadValueIsNeeded={ false }
																																																						val={
																																																								(!!this.state.virtualTree && !!this.state.virtualTree.currentValues)
																																																									? (
																																																										this.state.virtualTree.currentValues
																																																											.filter( v => v.griSubrequirementId === node.id )
																																																											.filter( v => ( v.monthNumber === 0 ))
																																																											.filter( v => ( !!v.isValueActive ))
																																																											.map( v => ( !!v.valueType ? v.valueString : v.valueDecimal ) )
																																																											.reduce( getLastElementOr, null )
																																																									)
																																																									: null

																																																						}
																																																						userCreator={
																																																							(!!this.state.virtualTree && !!this.state.virtualTree.currentValues)
																																																								? (
																																																									this.state.virtualTree.currentValues
																																																										.filter( v => v.griSubrequirementId === node.id )
																																																										.map( v => v.userCreate )
																																																										.reduce( getLastElementOr, 99 )
																																																								)
																																																								: null
																																																						}
																																																						userEditor={
																																																							(!!this.state.virtualTree && !!this.state.virtualTree.currentValues)
																																																								? (
																																																									this.state.virtualTree.currentValues
																																																										.filter( v => v.griSubrequirementId === node.id )
																																																										.map( v => v.userUpdate )
																																																										.reduce( getLastElementOr, 99 )
																																																								)
																																																								: null
																																																						}
																																																						setted={ (this.state.settedNode.filter( sn => sn === (node.id +"."+ node.structureId +""+ node.id) ).length > 0)  }
																																																						waiting={ this.state.waitingNode.filter( wn => wn === (node.id +"."+ node.structureId +""+ node.id) ).reduce( getLastElementOr, null) }
																																																						onConfirm={ (data, isString) => {
																																																							// salvataggio valore




																																																									return (
																																																										new Promise((success, fail) => {
																																																											this.setState({
																																																													...this.state,
																																																													waitingNode: [
																																																														...this.state.waitingNode,
																																																														(node.id + "." + node.structureId + "" + node.id)
																																																													]
																																																												},


																																																												() => {

																																																													let d = [{
																																																														value: data,
																																																														active: true
																																																													}];
																																																													// let d = [data];
																																																													// if( typeof d !== "string" ) {
																																																													// 	d = [{ value: data, active: true }];
																																																													// }

																																																													saveVirtualTreeNodeValue(d, req.structureId, node.id, zone.id)
																																																														// saveVirtualTreeNodeValue( [data], node.structureId, req.id, zone.id )
																																																														.then(() => {


																																																															this.setState({
																																																																	...this.state,
																																																																	settedNode: [
																																																																		...this.state.settedNode,
																																																																		(node.id + "." + node.structureId + "" + node.id)
																																																																	],
																																																																	waitingNode: this.state.waitingNode.filter(wn => wn !== (node.id + "." + node.structureId + "" + node.id))
																																																																},
																																																																() => {
																																																																	success();

																																																																	// this.loadData()
																																																																	// 	.then(() => {
																																																																	// 		success();
																																																																	// 	})
																																																																	// ;


																																																																}
																																																															)


																																																														})
																																																														.catch(e => {
																																																															errorlog("error", e);
																																																															fail(e);
																																																														})

																																																												}
																																																											)
																																																										})
																																																									)


																																																								}}
																																																							/>


																																																							{
																																																								!node.isString
																																																									? (
																																																										<div
																																																											className={"set-value-button-container"}>
																																																											<ActionButton
																																																												color={"primary"}
																																																												promise={() => Promise.resolve()}
																																																												buttonText={translate("monthly values")}
																																																												onResult={clickEvent => {
																																																													// clickEvent.stopPropagation();


																																																													this.setState({
																																																														...this.state,
																																																														monthlyTimingPopup: "virtualNode_" + req.id + "." + req.structureId + "-" + zone.id + "_" + node.id,
																																																														// monthlyTimingPopup: node.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + node.structureId,
																																																														node: node,
																																																														nodeInformative: info
																																																													})
																																																												}}
																																																											/>
																																																										</div>
																																																									)
																																																									: null
																																																							}

																																																						</React.Fragment>



																																																			)
																																																			: (
																																																				<div
																																																					className={"set-value-button-container"}>
																																																					<ActionButton
																																																						color={"primary"}
																																																						promise={ () => Promise.resolve() }
																																																						buttonText={translate("monthly values")}
																																																						onResult={clickEvent => {
																																																							// clickEvent.stopPropagation();



																																																							this.setState({
																																																								...this.state,
																																																								monthlyTimingPopup: "virtualNode_"+ req.id +"."+ req.structureId +"-"+ zone.id +"_"+ node.id,
																																																								// monthlyTimingPopup: node.id + "-" + GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] + "-" + node.structureId,
																																																								node: node,
																																																								nodeInformative: info
																																																							})
																																																						}}
																																																					/>
																																																				</div>
																																																			)
																																																	}


																																																		</React.Fragment>

																																																	) // se è evaluate
																																																	: null

																																															}
																																														</div>
																																													</div>


																																												</Row>

																																											</CardHeader>


																																										</Card>
																																									);
																																								})
																																						}


																																						{/*<GotoTopComponent/>*/}

																																					</TabPane>
																																				);
																																			})
																																	}
																																</TabContent>

																																{
																																	(!!this.state.deleteTabPopup)
																																		? (
																																			<TreeDataEntryZoneDeleteModal
																																				id={this.state.deleteTabPopup}
																																				onClose={closeEvent => {
																																					/*
																																			let treeTmp = JSON.parse(JSON.stringify(this.state.tree));
																																			this.props.reloadTree( treeTmp )
																																				.then( refreshedTree => {
																																					this.setState(
																																						{
																																							tree: cloneOpenNodes( treeTmp, refreshedTree ),
																																							deleteTabPopup: null
																																						});
																																				})
																																				.catch(e => {
																																					console.error("errore all ultimo ritorno");
																																					showlog(e);
																																				})
																																			*/

																																			let positionY = window.scrollY;
																																			this.standby()
																																				.then(() => {
																																					loadGriStructureValue( this.props.collect.id, this.state.activeTab )
																																						.then(resultObj => {
																																							convertTableToTree( resultObj )
																																								.then( tree => {
																																									let virtualTree = convertToVirtualTree( tree, resultObj );
																																									let freshTree = cloneOpenNodes( this.state.tree || tree, virtualTree );

																																									this.standby( true )
																																										.then( () => {
																																											this.setState(
																																												{
																																													...this.state,
																																													tree: freshTree,
																																													newZoneModal: null,
																																													deleteTabPopup: null
																																												},
																																													() => {
																																														setTimeout(
																																															() => {
																																																window.scrollTo(0, positionY);
																																																showlog("scrolled to "+ positionY);

																																																document.getElementById(`tab-${ this.state.activeTab }-content`).parentElement.parentElement.parentElement.scrollTo(0, this.state.pointToScroll || positionY || 0);
																																															},
																																															5
																																														)
																																													}
																																												)
																																											;
																																										})


																																											/*
																																									this.setState({
																																										...this.state,
																																										tree: freshTree,
																																										newZoneModal: null,
																																										deleteTabPopup: null
																																									});
																																									*/
																																										})
																																										.catch(e => {
																																											errorlog("errore", e);
																																											// this.standby( { message: "Si è verificato un errore" }, false );
																																										})
																																									;

																																								})
																																								.catch(e => {
																																									console.error("error");
																																									showlog(e);
																																									// this.standby( { message: "Si è verificato un errore" }, false );
																																								})
																																						})

																																				}}
																																			/>
																																		)
																																		: null
																																}

																															</CardBody>
																														</Card>
																													</CardBody>
																												</Collapse>
																											)
																											: null


																									)
																							}

																						</Card>
																					);
																				})
																		}


																	</CardBody>
																</Collapse>
															</Card>
														);
													})
											}


										</CardBody>
									</Collapse>
								</Card>
							);
						})


		);

	}




	componentDidUpdate() {


		showlog("%ccontrollo aggiornamento", "color:#ff0;");

		showlog( this.state.tree );
		showlog( this.props.tree );


		showlog( getTreeObjectHash( this.state.tree ) );
		showlog( getTreeObjectHash( this.props.tree ) );


		showlog("filtro corrente", this.state.tmFilter);

		// showlog( "%c"+ getTreeFingerPrint( this.state.tree ), "color:#0f0;" );
		showlog( getTreeRawSeedsFingerPrint( this.props.tree ) );
		// showlog( getTreeRawFingerPrint( this.props.tree ) );
		// showlog( getTreeSeedsFingerPrint( this.props.tree ) );

		// showlog("ultimo controllo per l'aggiornamento");
		showlog( getTreeFingerPrint( this.props.tree ) );
		// showlog( getTreeFingerPrint( this.state.tree ) );

		/**
		 * Se il sotto-albero del tab corrente è diverso dal corrispondente
		 * arrivato dalle props allora si aggiorna
		 */
		if( ((getTreeFingerPrint( this.props.tree ) !== getTreeFingerPrint( this.state.tree ) && !this.state.tmFilter) || !!this.state.forceUpdate) ) {
			showlog( "%c"+ getTreeFingerPrint( this.state.tree ), "color:#f00;" );


			if( !!this.state.assuranceFilter ) {

			}
			else {

				updatelog("Value Component", this.state, this.props, { color: "#1155ff", contrastColor: "#ffffff" });
				this.setState({
					...this.state,
					tree: this.props.tree || this.state.tree,
					treeCopy: null, // this.props.tree || this.state.tree,
					fingerprint: getTreeFingerPrint( this.props.tree ),
					forceUpdate: false
				});

			}






		}
		else {
			showlog( "%c"+ getTreeFingerPrint( this.state.tree ), "color:#0f0;" );

			// controllo tab attivo
			if(
				this.state.tree.tabs
					.filter( t => t.id === this.state.activeTab )
					.reduce( getLastElementOr, { chapters: [] } )
						.chapters
						.length
							=== 0
			) {

				this.loadData()
					// .then( resultData => {
					// 	this.setState({
					// 		...this.state,
					// 		materialThemesWait: false
					// 	});
					// });




				/*
				this.setState({
					...this.state,
					activeTab:
						this.state.tree.tabs
							.filter( t => t.chapters.length > 0 )
							.reduce( getLastElementOr, { id: 1 } )
							.id
				})
				*/
			}


		}


	}


	componentDidMount() {

		mountedlog("Value Component", this.state, this.props, { color: "#115588", contrastColor: "#ffffff" });


		let params =
			(!!this.props && !!this.props.location && !!this.props.location.search)
				? (
					JSON.parse(
						("{"+ this.props.location.search +"}")
							.split("?").join("")
							.split("&").join(",")
							.split("=").join(":")

							.split(",").join("\",\"")
							.split(":").join("\":\"")
							.split("{").join("{\"")
							.split("}").join("\"}")
					)
				)
				: { section: TREE_SECTION.DATA_ENTRY, area: TABS.general.id }
		;
		showlog( this.props.location.search );
		showlog( params );



		if( this.props.collect.step > 1 ) {



			this.setState({
					...this.state,
					materialthemes: [],
					tree: this.props.tree || this.state.tree,
					seeds: this.props.seed || this.state.seeds,
					fingerprint: getTreeFingerPrint( this.props.tree ),
					params: params,
					activeTab: (parseInt(params.area || 1, 10) || 1),
					currentTab:
						Object.keys(TABS)
							.map( tabKey => TABS[tabKey] )
							.filter( t => t.id === parseInt(params.area || 1, 10) )
							.reduce( getLastElementOr, TABS.general )
				},
				() => {

					if( !!!this.props.collect.name ) {
						this.loadData()
							.then( resultData => {
								this.setState({
									...this.state,
									materialThemesWait: false
								});
							})
						;
					}
					else {
						this.setState({
							...this.state,
							materialThemesWait: false
						});
					}
				})



			/*
			showlog("chiamata tematiche materiali");
			loadMaterialThemes( this.props.collect.id )
				.then( materialThemesTree => {
					showlog("chiamata TM");
					showlog( materialThemesTree );

					this.setState({
						...this.state,
						materialthemes: [
							... new Map(
								materialThemesTree
									.map( item => [ item.name, item ])
							).values()
						],
						tree: this.props.tree || this.state.tree,
						seeds: this.props.seed || this.state.seeds,
						fingerprint: getTreeFingerPrint( this.props.tree ),
						params: params,
						activeTab: (parseInt(params.area || 1, 10) || 1),
						currentTab:
							Object.keys(TABS)
								.map( tabKey => TABS[tabKey] )
								.filter( t => t.id === parseInt(params.area || 1, 10) )
								.reduce( getLastElementOr, TABS.general )
					},
						() => {

						if( !!!this.props.collect.name ) {
							this.loadData()
								.then( resultData => {
									this.setState({
										...this.state,
										materialThemesWait: false
									});
								})
							;
						}
						else {
							this.setState({
								...this.state,
								materialThemesWait: false
							});
						}
					})


				})
				.catch(e => {

					errorlog("errore alla chiamata TM",e);
				})
			;
*/


		}
		else {
			if( !!!this.props.collect.name ) {
				this.loadData();
			}
			else {
				if( !!isUserRole( role.SRTool_MANAGER ) ) {
					this.loadData();
				}
			}
		}

	}



	loadData() {



		loadDatalog("CollectDataEntryComponent load data", { color: "#5555ff", contrastColor: "#ffffff" });
		showlog( this.props.collect, (this.state.activeTab !== -1) ? this.state.activeTab : 1 );


		return (new Promise((success, fail) => {



			loadGriStructureValue( this.props.collect.id, (this.state.activeTab !== -1) ? this.state.activeTab : 1 )

				.then(resultObj => {

					let seed = resultObj;

					showlog("risultato di loadGriStructure");
					showlog(resultObj);
					// console.table(resultObj);
					// showlog( exportSeedTableToCSV(resultObj) );



						convertTableToTree( resultObj )
							.then( tree => {


								showlog("conversione fatta");
								showlog( tree );


								// let virtualTree = convertToVirtualTree( tree, this.props.seed );
								let virtualTree = convertToVirtualTree( tree, seed );


								showlog("taglio dei nodi");
								showlog( virtualTree );


								// set to open dei tabs

								let treeTmp = cloneOpenNodes( this.state.tree, virtualTree );
								// let treeTmp = cloneOpenNodes( tree, virtualTree );



									this.setState({
											...this.state,
											// tree: virtualTree,
											tree: treeTmp,
											seeds: seed,
											treeCopy: treeTmp,
											activeTab: ((this.state.activeTab !== -1) ? this.state.activeTab : 1),
											currentTab: ((this.state.activeTab !== -1) ? this.state.activeTab : TABS.general),
											fingerprint: getTreeFingerPrint( treeTmp ),
											materialThemesWait: (!!isUserRole( role.SRTool_MANAGER ) ? false : this.state.materialThemesWait ),
											materialthemes: (!!isUserRole( role.SRTool_MANAGER ) ? [] : this.state.materialthemes ),
											infoHundredThreePopup: null
										},
										() => {
											showlog("set dell'albero");
											showlog(virtualTree);
											showlog(treeTmp);
											showlog(this.state.tree);

											treeLog( cloneObject( this.state.tree ) );

											success( treeTmp );

										}
									)
								;


							})
							.catch(e => {
								errorlog("errore",e);
								fail(e);
							})



				})
				.catch(e => {
					errorlog("errore",e);
					fail(e);
				})
		}));






	}



}



export default withRouter(TreeGriCollectDataEntryComponent);
