import React, {Component, Suspense} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import {
    disableQuestion,
    enableQuestion,
    getQuestions,
    setConfigurationQuestionThematicReason,
    setEvaluationStatus
} from "../../../business-logic/rating";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {cloneOpenNodes, getQuestionFatherTreeNodeByPath} from "../../../business-logic/question-tree-manager";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    CustomInput,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {
    COLOR_POLICY,
    HOST,
    MATERIALITY_REASON_LEVEL,
    PATH_PREFIX,
    permissions,
    RATING_STATUS,
    RATING_TYPE,
    role,
    SIRATING_TREE_DEEP_LEVELS_NAME
} from "../../../env";
import classnames from "classnames";
import StandBySystemModalComponent from "../../../components/area/gri-admin/standby-system-modal";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import {cloneObject} from "../../../utils/object-utils";
import Spinner from "../../../components/Spinner";
import Select from "react-select";
import PageTitle from "../../../components/PageTitle";
import ActionButton from "../../../components/save-button";
import {EMOJ} from "../../../emoj";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {getLastElementOr} from "../../../utils/array-utils";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {emoj} from "../../../utils/string-utils";
import SdgManagerModal from "../../../components/area/super-admin/sdg-manager-modal";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {TreeToggler} from "../../../components/tree-toggler";
import MaterailityReport from "../../../components/area/super-admin/materailityReport-modal";
import GotoTopComponent from "../../../components/goto-top-component";
import {translate} from '../../../components/i18n/translate-function';


class GenderGapConfigurationPage extends Component {

	constructor(props) {
		super(props);

		showlog("Costruttore");


		this.loadData = this.loadData.bind(this);
		this.inWait = this.inWait.bind(this);
		this.buildQuestions = this.buildQuestions.bind(this);
		this.buildThemes = this.buildThemes.bind(this);
		this.buildSubThemes = this.buildSubThemes.bind(this);
		this.isReadOnly = this.isReadOnly.bind(this);

		this.state = {
			render: false,
			isShowTextMotivation: false,
			nodeIdArray: false,
			textMotivation: false,
			nodeTextMotivationShowArray: [],
			textMotivationShowArray: [],
			enablingWait: []
		}

		this.nodeTextMotivationShowArray = [];
		this.textMotivationShowArray = [];
		this.materialityArray = [];
	}

	inWait() {
		return (
			<div>Wait</div>
		);
	}



	isReadOnly() {


		return !(
			(isUserRole(role.PROJECT_MANAGER)) ||
			(this.state.status < RATING_STATUS.configurationValidated.id && isUserRole(role.ARB_OPERATOR))
		);


	}



	render() {


		// debugger
		// console.log("this.nodeTextMotivationShowArray-5", this.nodeTextMotivationShowArray);
		renderlog(
			"GenderGapConfigurationPage",
			this.state,
			this.props,
			COLOR_POLICY.select
		);
		// debugger;

		if (!this.state.tree) {
			return (
				<WaitingOrNodataMessage
					waiting={!this.state.tree}
					fullHeight={true}
					nodataMessage={"Nessuna Valutazione"}
				/>
			);
		}


		if( !!this.state.redirectToPerimetral ) {
			return (
				<Redirect
					to={{
						pathname: PATH_PREFIX +"/si-rating/" + translate("perimetral") + "/"+ this.state.redirectToPerimetral,
						state: this.state
					}}
				/>
			);
		}


		let confirmingText = (
			!!this.state.confirmed
				? "    Confermata    "
				: (
					!!this.state.error
						? "    " + emoj(EMOJ.warning) + "  " + emoj(EMOJ.sad_but_relieved_face) + "    "
						: (
							this.state.status == RATING_STATUS.configurationComplete.id
								? "Valida Configurazione"
								: "Conferma Configurazione"
						)
				)
		)
			;



		return (
			<Suspense fallback={this.inWait()}>


				<PageTitle
					breadCrumbItems={[
					/*	{
							label:
								Object.keys(role)
									.map(roleObjectName => role[roleObjectName])
									.filter(roleObject => roleObject.name === localStorage.getItem("role"))
									.reduce(getLastElementOr, "Nessun Ruolo")
									.label
							, path: PATH_PREFIX + "/welcome"
						}, */
						// { label: "Parità di Genere", path: PATH_PREFIX + "/super-admin/si-rating/evaluations" },
						{ label: translate("Gender Gap"), path: PATH_PREFIX + `/${translate("gender-gap")}${ isUserRole( role.PROJECT_MANAGER ) ? "/pm": "" }/${translate("evaluations")}` },
						{ label: translate("Evaluations"), path: PATH_PREFIX + `/${translate("gender-gap")}${ isUserRole( role.PROJECT_MANAGER ) ? "/pm": "" }/${translate("evaluations")}` },
						// { label: "Valutazioni", path: PATH_PREFIX + "/super-admin/si-rating/evaluations" },
						// { label: this.props.match.params.id, active: true }
						{ label: this.state.ratingName, active: true }
					]}
					title={translate("Gender Gap")}
				/>

				{

					(!!this.state.confirmed || !!this.isReadOnly())
						? <Redirect to={PATH_PREFIX + "/" + translate("gender-gap") + "/pm/" +  translate("evaluations")} />
						: null
				}


				<Card>
					<CardHeader>
						{/*<h4>*/}
						<div className={"rating-header-name"}>
							Domande per la valutazione &nbsp;
							<strong className={""}>{
								(
									!!this.props.location.state
										? this.props.location.state.Nome
										: (
											!!this.state.rating
												? this.state.rating.Nome
												: ""
										)
								)
								|| this.props.match.params.id
							}</strong>



							{/*
								(!!hasPermission( permissions.NAVIGATE_TO_PERIMETRAL ) && this.state.status < RATING_STATUS.perimetralQuestionAccepted.id)
									? (
										<span className="float-right" style={{ marginLeft: "10px" }}>
											<ActionButton
												buttonText={"Modifica Profilazione"}
												promise={() => Promise.resolve()}
												onResult={result => {
													this.setState({
														...this.state,
														redirectToPerimetral: this.props.match.params.id
													})

												}}
											/>
										</span>
									)
									: null
							*/}



							{


								(!!hasPermission(permissions.SET_VALIDATION_CONFIGURATION) || !!hasPermission(permissions.SET_COMPLETATION_CONFIGURATION))
									? (

										(!!this.state.confirmed)
											? null
											: (
												( !this.isReadOnly() )
													? (
														<span className="float-right">
															<ActionButton
																buttonText={confirmingText}
																promise={() => setEvaluationStatus(this.props.match.params.id)}
																onResult={result => {

																	showlog("valutazione a step 1");
																	this.setState({
																		...this.state,
																		confirmed: true
																	})

																}}
																onError={e => {
																	errorlog("errore",e);
																}}
															/>
														</span>
													)
													: null
											)
									)
									: null



							}


							{


								(!!hasPermission(permissions.SET_VALIDATION_CONFIGURATION) || !!hasPermission(permissions.SET_COMPLETATION_CONFIGURATION))
									? (

										(!!this.state.confirmed)
											? null
											: (
												( !this.isReadOnly() )
													? (
														<span className="float-right" style={{ marginRight: "10px" }}>
															<ActionButton
																buttonText={"Materiality Map"}
																promise={() => Promise.resolve()}
																onResult={result => {
																	this.setState({
																		...this.state,
																		materialityReportModal: true
																	})

																}}
																onError={e => {
																	errorlog("errore",e);
																}}
															/>
														</span>
													)
													: null
											)
									)
									: null



							}


							{
								(!!this.state.materialityReportModal)
									? (
										<MaterailityReport
											evaluation_id={this.props.match.params.id}
											onClose={() => {
												this.setState({
													...this.state,
													materialityReportModal: null
												})
											}}
										/>
									)
									: null
							}



						</div>
						{/*</h4>*/}

					</CardHeader>
					<CardBody>
						<Nav tabs>
							{
								this.state.tree.tabs
									.map((tab, index) => {
										return (
											<NavItem key={index}>
												<NavLink
													id={SIRATING_TREE_DEEP_LEVELS_NAME[0] + "-" + tab.id}
													href="#"
													className={classnames({ active: this.state.activeTab === tab.id })}
													onClick={() => {


														let treeTmp = cloneObject(this.state.tree);
														let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, [tab.id]);
														nodeTmp.open = true;

														this.setState({
															...this.state,
															activeTab: tab.id,
															tree: treeTmp
														});
													}}>
													<i
														className={classnames(
															tab.icon,
															'd-lg-none',
															'd-block',
															'mr-1'
														)} />
													<span className="d-none d-lg-block">{tab.name}</span>
												</NavLink>
											</NavItem>
										);
									})
							}

							<TreeToggler
								tree={this.state.tree}
								onFinish={newTree => {
									this.setState({
										...this.state,
										tree: newTree
									})
								}}
							/>

						</Nav>

						<TabContent activeTab={this.state.activeTab}>

							{
								this.state.tree.tabs
									.map((tab, index) => {
										return (
											<TabPane
												tabId={tab.id}
												key={index}
											>
												<Row>
													<Col sm="12">

														{
															tab.themes
																.map((th, chIndex) => {
																	return (
																		<Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
																			<CardHeader>

																				<h5 className="m-0">
																					<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">
																						{
																							// th.code + " " +
																							th.name
																						}
																					</div>


																					{/*
																						( !this.isReadOnly() )
																							? (
																								<Select
																									className="react-select sirating-enabling-question"
																									classNamePrefix="react-select"
																									name={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id + "-comander"}
																									id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id + "-comander"}
																									onChange={changeEvent => {

																										showlog("seleziona scelta");

																										showlog(changeEvent);


																										this.setState({
																											...this.state,
																											inWaiting: true
																										})


																										if (!!changeEvent.value) {
																											enableAllQuestion(this.props.match.params.id, th.id, 0)
																												.then(result => {

																													let treeTmp = cloneObject(this.state.tree);
																													this.loadData()
																														.then(() => {
																															let refreshedTree = cloneObject(this.state.tree);
																															cloneOpenNodes(treeTmp, refreshedTree);
																															this.setState({
																																...this.state,
																																tree: refreshedTree,
																																inWaiting: false
																															})
																														})
																														.catch(e => {
																															errorlog(e);
																														});
																												})
																											;
																										}
																										else {
																											disableAllQuestion(this.props.match.params.id, th.id, 0)
																												.then(result => {
																													let treeTmp = cloneObject(this.state.tree);
																													this.loadData()
																														.then(() => {
																															let refreshedTree = cloneObject(this.state.tree);
																															cloneOpenNodes(treeTmp, refreshedTree);
																															this.setState({
																																...this.state,
																																tree: refreshedTree,
																																inWaiting: false
																															})
																														})
																														.catch(e => {
																															errorlog(e);
																														});
																												})
																											;
																										}


																									}}
																									options={[
																										{ value: true, label: "Abilita tutte" },
																										{ value: false, label: "Disabilita tutte" },
																									]}
																									placeholder={<span className={"select-placeholder"}>Abilita / Disabilita</span>}
																								/>
																							)
																							: null
																					*/}







																					{/*
																						(
																							!!!isNotEmptyArray(th.subThemes) &&
																							( !this.isReadOnly() )
																						)
																						? (

																							<React.Fragment>

																								<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title spaceTextInputButton">
																									Rilevanza
																								</div>
																								<Select
																									className="react-select sirating-enabling-question"
																									classNamePrefix="react-select"
																									name={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id + "-comander"}
																									id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id + "-comander"}
																									onChange={changeEvent => {

																										let treeTmp = cloneObject(this.state.tree);
																										let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, th.path);
																										// nodeTmp.showMotivation = (changeEvent.value !== 2);
																										nodeTmp.materiality = {
																											...nodeTmp.materiality,
																											value: changeEvent.value
																										}


																										this.setState({
																											...this.state,
																											tree: treeTmp,
																											inWaiting: true
																										},

																											() => {
																												if (changeEvent.value === 0) {

																													setConfigurationQuestionThematicReason(this.props.match.params.id, th.path[1], (th.path[2] || 0), { value: changeEvent.value, motivation: "" })
																														.then(result => {
																															let treeTmp = cloneObject(this.state.tree);
																															th.questions
																																.map(q => {
																																	if (!!document.getElementById("question-" + q.id)) {
																																		document.getElementById("question-" + q.id).checked = false;
																																	}
																																})
																																;
																															this.loadData()
																																.then(() => {
																																	let refreshedTree = cloneObject(this.state.tree);
																																	cloneOpenNodes(treeTmp, refreshedTree);
																																	this.setState({
																																		...this.state,
																																		tree: refreshedTree,
																																		inWaiting: false
																																	})
																																})
																																.catch(e => {
																																	errorlog(e);
																																})
																																;
																														})
																														.catch(e => {
																															errorlog(e);
																															return Promise.reject(e);
																														})
																												}
																												else {
																													this.setState({
																														...this.state,
																														inWaiting: false,
																														errorMessage: "Attenzione il livello di materialità verrà cambiato al click sul tasto SALVA"
																													})
																												}

																											})

																									}}
																									value={
																										( !!th.materiality && (!!th.materiality.value || th.materiality.value === 0))
																											? (
																												MATERIALITY_REASON_LEVEL
																													.filter(mrl => mrl.value === th.materiality.value)
																													.reduce(getLastElementOr, null)
																											)
																											: null
																									}
																									options={MATERIALITY_REASON_LEVEL}
																									placeholder={<span className={"select-placeholder"}>Imposta materialità</span>}
																								/>


																								<AvForm className={"conf-question-reason"}>
																									<AvGroup>
																										<Label for={`th_materialityReason-${th.id}`}>Motivazione</Label>
																										<AvInput
																											className={"conf-question-reason-value-input"}
																											type="text"
																											name={`th_materialityReason-${th.id}`}
																											id={`th_materialityReason-${th.id}`}
																											value={ !!th.materiality && th.materiality.motivation }

																											onBlur={blurEvent => {
																												// showlog("evento di blur");
																												// showlog(blurEvent);
																												// showlog(blurEvent.target.value);


																												let treeTmp = cloneObject(this.state.tree);
																												let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, th.path);
																												nodeTmp.materiality = {
																													...nodeTmp.materiality,
																													motivation: blurEvent.target.value
																												}
																												this.setState({
																													...this.state,
																													tree: treeTmp
																												});

																											}}

																										/>

																										<ActionButton
																											className={"spaceTextInputButton"}
																											promise={() => {

																												return (
																													new Promise((success, fail) => {

																														setConfigurationQuestionThematicReason(this.props.match.params.id, th.path[1], (th.path[2] || 0), { value: th.materiality.value, motivation: th.materiality.motivation })
																															.then(result => {
																																let treeTmp = cloneObject(this.state.tree);
																																this.loadData()
																																	.then(() => {
																																		let refreshedTree = cloneObject(this.state.tree);
																																		cloneOpenNodes(treeTmp, refreshedTree);
																																		this.setState({
																																			...this.state,
																																			tree: refreshedTree
																																		},
																																			() => {
																																				success();
																																			}
																																		)
																																	})
																																	.catch(e => {
																																		errorlog(e);
																																		// return Promise.reject( e );
																																		fail(e);
																																	})
																																	;
																															})
																															.catch(e => {
																																errorlog(e);
																																fail(e);
																															})

																													})
																												)

																											}}
																											onResult={() => {
																												// refresh
																												showlog("dopo il set della reason");
																												this.setState({
																													...this.state,
																													inWaiting: false
																												})
																											}}
																											onError={e => {
																												errorlog("errore al salvataggio della motivazione sulla tematica",e);
																												this.setState({
																													...this.state,
																													inWaiting: false
																												})
																											}}
																										/>


																									</AvGroup>
																								</AvForm>


																							</React.Fragment>
																						)

																						: null
																					*/}







																					<span className="float-right">
																						<NavLink
																							id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}
																							href="#"
																							onClick={clickEvent => {

																								let treeTmp = cloneObject(this.state.tree);
																								let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, th.path);
																								nodeTmp.open = !getQuestionFatherTreeNodeByPath(this.state.tree, th.path).open;
																								this.setState({
																									...this.state,
																									tree: treeTmp
																								});

																							}}
																						>

																							{
																								(!!th.open)
																									? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
																									: <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
																							}
																						</NavLink>
																					</span>
																				</h5>

																			</CardHeader>

																			<Collapse isOpen={!!th.open} toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
																				<CardBody>


																					{
																						// domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
																						this.buildQuestions(th.questions)
																					}

																					{   // sotto-tematica --------------------------------------------------------------------------------------------------------------------------------------------------------------------
																						this.buildSubThemes(th.subThemes)

																					}

																				</CardBody>
																			</Collapse>

																		</Card>
																	);

																})
														}

													</Col>
												</Row>
											</TabPane>
										);
									})
							}

							<GotoTopComponent/>

						</TabContent>

							<br/>
                            {

								(!!hasPermission(permissions.SET_VALIDATION_CONFIGURATION) || !!hasPermission(permissions.SET_COMPLETATION_CONFIGURATION))
									? (

										(!!this.state.confirmed)
											? null
											: (
												( !this.isReadOnly() )
													? (
														<span className="float-right" >
															<ActionButton
																buttonText={confirmingText}
																promise={() => setEvaluationStatus(this.props.match.params.id)}
																onResult={result => {

																	showlog("valutazione a step 1");
																	this.setState({
																		...this.state,
																		confirmed: true
																	})

																}}
																onError={e => {
																	errorlog("errore",e);
																}}

															/>
														</span>
													)
													: null
											)
									)
									: null



							}

					</CardBody>


				</Card>






				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={this.state.errorMessage}
								noheader={true}
								noHazard={true}
								onClose={() => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.sdgModalActive)
						? (
							<SdgManagerModal
								id={this.state.sdgModalActive}
								ratingId={this.props.match.params.id}
								onClose={() => {
									this.setState({
										...this.state,
										sdgModalActive: null
									});
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.inWaiting)
						? (
							<StandBySystemModalComponent
								message={this.state.processingMessage}
								didMount={() => {

									/*
									this.setState({
										...this.state,
										stopable: true
									})
									*/
								}}
							/>
						)
						: null
				}


			</Suspense>
		);
	}

	componentDidMount() {

		this.loadData();
	}

	// non necessario
	buildThemes(themes) { }

	buildSubThemes(subThemes) {

		return (
			subThemes
				.map((sth, infoIndex) => {

					// showlog(sth);
					// showlog(sth.materiality);
					// debugger;

					return (
						<Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
							<CardHeader>
								<h5 className="m-0">
									<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">
										{
											// sth.code + " " +
											sth.name
										}

									</div>

									{/*
										!this.isReadOnly()
											? (
												<Select
													className="react-select sirating-enabling-question"
													classNamePrefix="react-select"
													name={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id + "-comander"}
													id={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id + "-comander"}
													onChange={changeEvent => {


														this.setState({
																...this.state,
																inWaiting: true
															},
															() => {
																let fatherTheme = getQuestionFatherTreeNodeByPath(this.state.tree, sth.path.filter((p, i) => i < (sth.path.length - 1)));

																if (!!changeEvent.value) {
																	enableAllQuestion(this.props.match.params.id, fatherTheme.id, sth.id)

																		.then(result => {
																			let treeTmp = cloneObject(this.state.tree);

																			sth.questions
																				.map(q => {
																					if (!!document.getElementById("question-" + q.id)) {
																						document.getElementById("question-" + q.id).checked = true;
																					}
																				})
																			;

																			this.loadData()
																				.then(() => {
																					let refreshedTree = cloneObject(this.state.tree);
																					cloneOpenNodes(treeTmp, refreshedTree);
																					this.setState({
																						...this.state,
																						tree: refreshedTree,
																						inWaiting: false
																					})
																				})
																				.catch(e => {
																					errorlog(e);
																				})
																			;
																		})


																		.catch(e => {
																			// debugger;
																			showlog("errore");
																			showlog(e);
																			errorlog(e);
																		})


																}
																else {
																	disableAllQuestion(this.props.match.params.id, fatherTheme.id, sth.id)

																		// toggle switch
																		.then(result => {

																			sth.questions
																				.map(q => {
																					if (!!document.getElementById("question-" + q.id)) {
																						document.getElementById("question-" + q.id).checked = false;
																					}
																				})
																			;

																			return result;

																		})

																		// refresh
																		.then(result => {
																			let treeTmp = cloneObject(this.state.tree);
																			this.loadData()
																				.then(() => {
																					let refreshedTree = cloneObject(this.state.tree);
																					cloneOpenNodes(treeTmp, refreshedTree);
																					this.setState({
																						...this.state,
																						tree: refreshedTree,
																						inWaiting: false
																					})
																				})
																				.catch(e => {
																					errorlog(e);
																				})
																			;
																		})

																		.catch(e => {
																			errorlog(e);
																		})
																}
															}
														)





													}}
													options={[
														{ value: true, label: "Abilita tutte" },
														{ value: false, label: "Disabilita tutte" },
													]}
													placeholder={<span className={"select-placeholder"}>Abilita / Disabilita</span>}
												/>
											)
											: null
									*/}










									<React.Fragment>

										<div className={"custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title spaceTextInputButton"} >
											Livello di Materialità
										</div>



										{
											!this.isReadOnly()
												? (
													<React.Fragment>
														<Select
															className="react-select sirating-enabling-question"
															classNamePrefix="react-select"
															name={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + sth.id + "-comander"}
															id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + sth.id + "-comander"}
															onChange={changeEvent => {
																let treeTmp = cloneObject(this.state.tree);
																let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, sth.path);
																nodeTmp.showMotivation = (changeEvent.value !== 2);
																nodeTmp.materiality = {
																	...nodeTmp.materiality,
																	value: changeEvent.value
																}




																this.setState({
																		...this.state,
																		tree: treeTmp,
																		inWaiting: true
																	},
																	() => {

																		if (changeEvent.value === 0) {
																			let fatherTheme = getQuestionFatherTreeNodeByPath(this.state.tree, sth.path.filter((p, i) => i < (sth.path.length - 1)));


																			setConfigurationQuestionThematicReason(this.props.match.params.id, sth.path[1], (sth.path[2] || 0), { value: changeEvent.value, motivation: "" })
																				.then(result => {



																					let treeTmp = cloneObject(this.state.tree);

																					sth.questions
																						.map(q => {
																							if (!!document.getElementById("question-" + q.id)) {
																								document.getElementById("question-" + q.id).checked = false;
																							}
																						})
																					;

																					this.loadData()
																						.then(() => {
																							let refreshedTree = cloneObject(this.state.tree);
																							cloneOpenNodes(treeTmp, refreshedTree);
																							this.setState({
																								...this.state,
																								tree: refreshedTree,
																								inWaiting: false
																							})
																						})
																						.catch(e => {
																							errorlog(e);
																						})
																					;




																				})
																				.catch(e => {
																					errorlog(e);
																					return Promise.reject(e);
																				})




																		}
																		else {
																			this.setState({
																				...this.state,
																				inWaiting: false,
																				errorMessage: "Attenzione il livello di materialità verrà cambiato al click sul tasto SALVA"
																			})
																		}

																	}
																);

															}}
															value={
																(!!sth.materiality.value || sth.materiality.value === 0)
																	? (
																		MATERIALITY_REASON_LEVEL
																			.filter(mrl => mrl.value === sth.materiality.value)
																			.reduce(getLastElementOr, null)
																	)
																	: null
															}
															options={MATERIALITY_REASON_LEVEL}
															placeholder={<span className={"select-placeholder"}>Imposta materialità</span>}
														/>

														<AvForm className={"conf-question-reason"}>
															<AvGroup>
																<Label for={`sth_materialityReason-${sth.id}`}>Motivazione</Label>
																<AvInput
																	className={"conf-question-reason-value-input"}
																	type="text"
																	name={`sth_materialityReason-${sth.id}`}
																	id={`sth_materialityReason-${sth.id}`}
																	value={sth.materiality.motivation}
																	onBlur={changeTextEvent => {
																		let treeTmp = cloneObject(this.state.tree);
																		let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, sth.path);
																		nodeTmp.materiality = {
																			...nodeTmp.materiality,
																			motivation: changeTextEvent.target.value
																		}
																		this.setState({
																			...this.state,
																			tree: treeTmp
																		});
																	}}

																/>
																<ActionButton
																	className={"spaceTextInputButton"}
																	promise={() => {

																		return (
																			setConfigurationQuestionThematicReason(this.props.match.params.id, sth.path[1], (sth.path[2] || 0), { value: sth.materiality.value, motivation: sth.materiality.motivation })

																				.then(result => {

																					let treeTmp = cloneObject(this.state.tree);
																					this.loadData()
																						.then(() => {
																							let refreshedTree = cloneObject(this.state.tree);
																							cloneOpenNodes(treeTmp, refreshedTree);
																							this.setState({
																								...this.state,
																								tree: refreshedTree,
																								inWaiting: false
																							})
																						})
																						.catch(e => {
																							errorlog(e);
																						})
																					;

																				})
																				.catch(e => {
																					errorlog(e);
																					return Promise.reject(e);
																				})
																		);


																	}}
																	onResult={() => {
																		// refresh
																		showlog("dopo il set della reason");
																	}}
																	onError={e => {
																		errorlog("errore al salvataggio della motivazione sulla tematica",e);
																	}}
																/>
															</AvGroup>
														</AvForm>
													</React.Fragment>
												)
												: (
													<React.Fragment>
														<Label for={`sth_materialityReason-${sth.id}`}>Motivazione</Label>
														<span>&nbsp;&nbsp;{ sth.materiality.motivation }</span>
													</React.Fragment>
												)
										}





									</React.Fragment>




									<span className="float-right">
										<NavLink

											id={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}
											href="#"
											onClick={clickEvent => {

												let treeTmp = cloneObject(this.state.tree);
												let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, sth.path);
												nodeTmp.open = !getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open;
												this.setState({
													...this.state,
													tree: treeTmp
												});


											}}
										>
											{
												(!!sth.open)
													? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
													: <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
											}
										</NavLink>
									</span>
								</h5>

							</CardHeader>
							{/*<UncontrolledCollapse toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>*/}
							<Collapse isOpen={!!sth.open} toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
								<CardBody>

									{
										// domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
										this.buildQuestions(sth.questions)

									}

								</CardBody>
							</Collapse>
						</Card>
					);
				})
		);

	}


	buildQuestions(questions) {
		return (
			questions

				.map((question, qIndex) => {
					return (
						<Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>
							<CardHeader>
								<div className={"custom-accordion-title tree_comander pt-2 pb-2 " + (!!question.enabled ? "question-enabled-text" : "question-disabled-text")}>


									<div>


										{
											!this.isReadOnly()
												? (
													<CustomInput
														type="switch"
														id={"question-" + question.id}
														name={"question-" + question.id}
														label=""
														className={"conf-question-switch"}
														onClick={clickEvent => {

															/*
                                                            showlog("switch on question");
                                                            showlog( question );
                                                            showlog( getQuestionPath( this.state.tree, question.id ) );

                                                            let fatherTheme = getQuestionFatherTreeNodeByPath( this.state.tree, getQuestionPath( this.state.tree, question.id ) );
                                                            showlog( fatherTheme );


                                                            this.setState({
                                                                ...this.state,
                                                                lastEnabledQuestion: (!question.enabled ? question.id : null)
                                                            })
                                                            */


															this.setState({
																	...this.state,
																	enablingWait:
																		[
																			...this.state.enablingWait
																				.filter(eq => eq !== question.id),
																			question.id
																		]
																},
																() => {

																	if (!!document.getElementById("question-" + question.id)) {
																		document.getElementById("question-" + question.id).checked = !question.enabled;
																	}
																	clickEvent.stopPropagation();

																	if (!question.enabled) {
																		enableQuestion(this.props.match.params.id, question.id)
																			.then(result => {
																				let treeTmp = cloneObject(this.state.tree);
																				this.loadData()
																					.then(() => {
																						let refreshedTree = cloneObject(this.state.tree);
																						cloneOpenNodes(treeTmp, refreshedTree);
																						this.setState({
																							...this.state,
																							tree: refreshedTree,
																							inWaiting: false,
																							enablingWait: this.state.enablingWait.filter(eq => eq !== question.id)
																						})
																					})
																					.catch(e => {
																						errorlog(e);
																					});
																			})
																		;
																	}
																	else {
																		disableQuestion(this.props.match.params.id, question.id)
																			.then(result => {
																				let treeTmp = cloneObject(this.state.tree);
																				this.loadData()
																					.then(() => {
																						let refreshedTree = cloneObject(this.state.tree);
																						cloneOpenNodes(treeTmp, refreshedTree);
																						this.setState({
																							...this.state,
																							tree: refreshedTree,
																							inWaiting: false,
																							enablingWait: this.state.enablingWait.filter(eq => eq !== question.id)
																						})
																					})
																					.catch(e => {
																						errorlog(e);
																					});
																			})
																		;
																	}

																}
															)



														}}
														valid={!!question.enabled}
														defaultChecked={!!question.enabled}
														checked={!!question.enabled}
													/>
												)
												: null
										}







										<code>{question.code}</code>
										{
											(!!this.state.enablingWait.includes(question.id))
												? (
													<span className={"margin-left"}>
														<Spinner color={"success"} className="spinner-border-sm mr-1" />
													</span>
												)
												: null
										}

										<br />
									</div>


									{
										(!!question.isSectorQuestion)
											? (
												<React.Fragment>
													<code className={"flag-sector"}>
														{question.sectorName}
													</code>

													{
														(!!question.companySize)
															? (
																<em className={"question-sector-company-size"}>
																	{
																		this.state.comanySizes
																			.filter(cs => cs.id === question.companySize)
																			.reduce(getLastElementOr, { name: "---" })
																			.name
																	}
																</em>

															)
															: null
													}

													<br />
												</React.Fragment>
											)
											: null
									}
									{question.name}
								</div>




								{
									(!!question.enabled)
										? (
											<span className={"float-right"}>


												<img
													src={HOST + "/assets/SDG/logo.png"}
													className={"sdg-icon-file"}
													onClick={clickEvent => {
														this.setState({
															...this.state,
															sdgModalActive: question.id
														});
													}}
												/>

												{/*
												<span
													className={"tooltip-icon-cursor sdg-icon question-enabled-text"}
													onClick={ clickEvent => {
														this.setState({
															...this.state,
															sdgModalActive: question.id
														});
													}}
												>SDG</span>
												*/}
											</span>
										)
										: null
								}



							</CardHeader>

						</Card>
					);
				})
		);
	}


	loadData() {

		return (
			new Promise((success, fail) => {

				getQuestions(this.props.match.params.id, RATING_TYPE.gendergap)

					// finish ----------------------------------------------------------------------------------------------------------------
					.then(({ seed, tree, status, statusName, comanySizes, ratingName }) => {

						// showlog("controllo ultimo step");
						// showlog( seed, tree, status, statusName, comanySizes, ratingName );


						let activeTab = 0;

						if (typeof this.state.activeTab !== "undefined") {
							activeTab = this.state.activeTab;
						}
						else {
							if (!!this.state && !!this.state.tree) {
								activeTab =
									this.state.tree
										.tabs
										.filter(tab => tab.open)
										.reduce(getLastElementOr, this.state.tree.tabs[0])
										.id
									;
							}
							else {
								activeTab = tree.tabs[0].id || 0;
							}
						}


						// debugger;

						this.setState({
							...this.state,
							seed: seed,
							tree: tree,
							activeTab: activeTab,
							status: status,
							comanySizes: comanySizes,
							ratingName: ratingName
						},
							() => {
								success();
							}
						)
					})

					.catch(e => {
						errorlog("errore",e);
						fail(e);
					})

			})
		)

	}

}

export default withRouter(GenderGapConfigurationPage);
