
export const errorMessage = {
    UNKNOWN_ERROR       : { msg: "errore sconosciuto",              code: 99 },
    GENERIC             : { msg: "errore generico",                 code: 100 },
    INVALID_USERNAME    : { msg: "username non valido",             code: 101 },
    INVALID_PASSWORD    : { msg: "password non valida",             code: 102 },
    USER_NOT_FOUND      : { msg: "utenza non trovata",              code: 104 },
    ACCESS_DENIED       : { msg: "accesso negato",                  code: 105 },
    TIMEOUT_EXPIRED     : { msg: "tempo scaduto",                   code: 106 },
    WRONG_STATUS        : { msg: "status inconsistente",            code: 107 },
    NO_STATUS           : { msg: "status mancante",                 code: 108 },
    MISSING_STATUS      : { msg: "status mancante",                 code: 108 },
    NO_RESPONSE         : { msg: "nessuna risposta",                code: 109 },
    INVALID_FORMAT_URL  : { msg: "formato URL non valido",          code: 110 },
    PERMISSION_DENIED   : { msg: "permesso negato",                 code: 111 },
    SECTOR_QUESTION_WITHOUT_CHOICHES   	: { msg: "Ci sono domande di settore che non hanno opzioni di risposta",    code: 112 },
    GENERIC_FRONTEND_ERROR_MESSAGE 		: { msg: "Qualcosa è andato storto!",                 						code: 113 },
    SECTOR_QUESTION_DELETE_PERMISSION_DENIED 	: { msg: "Non è possibile eliminare la domanda di settore",                 		code: 114 },
    SECTOR_QUESTION_EDIT_PERMISSION_DENIED 		: { msg: "Solo alcuni parametri sono stati modificati", description: "Non è possibile modificare alcuni elementi della domanda di settore",      code: 115 },


    // HTTP ERRORS
    HTTP_OK_200         : { msg: "Ok",                              code: 200 },
    HTTP_ERROR_400      : { msg: "Bad Request",                     code: 400 },
    HTTP_ERROR_401      : { msg: "Unauthorized",                    code: 401 },
    HTTP_ERROR_402      : { msg: "Payment Required",                code: 402 },
    HTTP_ERROR_403      : { msg: "Forbidden",                       code: 403 },
    HTTP_ERROR_404      : { msg: "Not Found",                       code: 404 },
    HTTP_ERROR_405      : { msg: "Method Not Allowed",              code: 405 },
    HTTP_ERROR_406      : { msg: "Not Acceptable",                  code: 406 },
    HTTP_ERROR_407      : { msg: "Proxy Authentication Required",   code: 407 },
    HTTP_ERROR_408      : { msg: "Request Timeout",                 code: 408 },
    HTTP_ERROR_409      : { msg: "Conflict",                         code: 409 },
    HTTP_ERROR_410      : { msg: "Gone",                            code: 410 },
    HTTP_ERROR_411      : { msg: "Length Required",                 code: 411 },
    HTTP_ERROR_412      : { msg: "Precondition Failed",             code: 412 },
    HTTP_ERROR_413      : { msg: "Request Entity Too Large",        code: 413 },
    HTTP_ERROR_414      : { msg: "Request-URI Too Long",            code: 414 },
    HTTP_ERROR_415      : { msg: "Unsupported Media Type",          code: 415 },
    HTTP_ERROR_416      : { msg: "Requested Range Not Satisfiable",  code: 416 },
    HTTP_ERROR_417      : { msg: "Expectation Failed",              code: 417 },
    HTTP_ERROR_418      : { msg: "I'm a teapot",                    code: 418 },
    HTTP_ERROR_420      : { msg: "Enhance your calm",               code: 420 },
    HTTP_ERROR_422      : { msg: "Unprocessable Entity",            code: 422 },
    HTTP_ERROR_426      : { msg: "Upgrade Required",                code: 426 },
    HTTP_ERROR_429      : { msg: "Too many requests",               code: 429 },
    HTTP_ERROR_449      : { msg: "Retry With",                      code: 449 },
    HTTP_ERROR_451      : { msg: "Unavailable For Legal Reasons",   code: 451 },
    HTTP_ERROR_500      : { msg: "Internal Server Error",           code: 500 },
    HTTP_ERROR_501      : { msg: "Not Implemented",                 code: 501 },
    HTTP_ERROR_502      : { msg: "Bad Gateway",                     code: 502 },
    HTTP_ERROR_503      : { msg: "Service Unavailable",             code: 503 },
    HTTP_ERROR_504      : { msg: "Gateway Timeout",                 code: 504 },
    HTTP_ERROR_505      : { msg: "HTTP Version Not Supported",      code: 505 },
    HTTP_ERROR_509      : { msg: "Bandwidth Limit Exceeded",        code: 509 }
};
