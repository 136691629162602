import React, {Component, Suspense} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    CustomInput,
    FormGroup,
    Input,
    Label,
    NavLink,
    Popover,
    PopoverBody,
    PopoverHeader,
    Progress,
    Row
} from "reactstrap";
import {
    COLOR_POLICY,
    HELPER,
    MAGNITUDE_DARK,
    PATH_PREFIX,
    PERIMETRAL_QUESTION_TO_AVOID,
    permissions,
    RATING_STATUS,
    role
} from "../../../env";
import Select from "react-select";
import PageTitle from "../../../components/PageTitle";
import ActionButton from "../../../components/save-button";
import {getLastElementOr, onlyNotNull} from "../../../utils/array-utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import "../../../style/audit.css";
import {
    getEvaluationStatus,
    loadProfilingData,
    savePerimetral,
    saveProfilingAnswer
} from "../../../business-logic/rating";
import {beautify, cloneObject} from "../../../utils/object-utils";
import {loadEvaluations} from "../../../business-logic/active-service";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import SectorListPopup from "../../../components/area/super-admin/sector-list-modal";
import {emoj, extractErrorMessage} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {loadAllSectors} from "../../../business-logic/companies";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {TreeToggler} from "../../../components/tree-toggler";
import GotoTopComponent from "../../../components/goto-top-component";
import {translate} from '../../../components/i18n/translate-function';


const fieldMap = {
	id: "id",
	codCliFor: "companyId",
	name: "Nome",
	dateCreate: translate("Creation Date"),
	ragioneSociale: "Ragione Sociale",
	dimensionName: "Dimensione",
	state: "Status",
	scoreButton: "Punteggio",
	files: translate("Attachments")
};



const hiddenFields = [
	"id",
	"Selezionato",
	"isMain",
	"sector_id",
	"sector_name",
	"selected"
];

const sectorsFieldMap = {
	isMain: " ",
	sector_id: "id",
	sector_name: "Settore",
	selected: "Selezionato"
};


const LIST_LIMIT = 8;
const paginationOptions = {
	paginationSize: 2,
	pageStartIndex: 1,
	showTotal: false,
	hidePageListOnlyOnePage: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
	),
	sizePerPageList: [ LIST_LIMIT ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'id',
		order: 'asc',
	},
];

const sortableFields = [
	"Settore",
	"Selezionato"
];


const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: sectorsFieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;

class SIRatingPerimetralQuestionPage extends Component {

	state = {
		ready: false,
		progress: 0,
		alertOpen:false,
		alertMessage:""
	};

	constructor(props) {
		super(props);

		this.questionChildren = [];

		this.loadData 			    = this.loadData.bind(this);
		this.loadSectors		    = this.loadSectors.bind(this);
		this.inWaiting 			    = this.inWaiting.bind(this);
		this.helperNode 			= this.helperNode.bind(this); // non necessario
		this.getProgress 			= this.getProgress.bind(this); // non necessario
		this.isReadOnly 			= this.isReadOnly.bind(this);
	}

	inWaiting() {
		return (
			<div>Wait</div>
		);
	}



	getProgress = () => {
		let answered =
			this.state.data
				.filter( question => ( !PERIMETRAL_QUESTION_TO_AVOID.includes( question.question_id ) ) )
				.map( question => ( question.answers.length > 0 ) )
				.filter( onlyNotNull )
				.length
		;
		let progress = answered / (this.state.data.length -2);
		progress *= 100;
		progress = Math.floor( progress );
		progress = parseInt( progress, 10 );
		return progress;
	}


	helperNode = node => (

		(!!HELPER && (window.location.href.indexOf("help") !== -1))
			? (
				<span
					className={"question-info-icon helper-icon"}
					onClick={ clickEvent => {

						let positionY = window.scrollY;

						clickEvent.stopPropagation();
						this.setState({
							...this.state,
							helper: node
						},
							() => {
								setTimeout(
									() => {
										window.scrollTo(0, positionY);
									},
									5
								)
							}
						)
					}}
				>
					<span>{ emoj( EMOJ.ambulance ) }</span>
					<code>{ node.question_id }</code>
				</span>
			)
			: null

	)





	isReadOnly() {

		// permesso di chiudere la profilazione
		let closePermission = !!hasPermission( permissions.CLOSE_PERIMETRAL_QUESTION );

		// ARB OPERATOR and state 3
		let operatorAndState_done = (
			isUserRole( role.ARB_OPERATOR ) &&
			(
				Object.keys( RATING_STATUS )
					.map( key => RATING_STATUS[ key ] )
					.filter( status => status.name === this.state.rating.Status )
					.reduce( getLastElementOr, {id: 0} )
					.id !== RATING_STATUS.perimetralQuestionCompleted.id
			)
		);

		// Project Manager and state 5
		let pmAndState_done = (
			isUserRole( role.PROJECT_MANAGER ) &&
			(
				Object.keys( RATING_STATUS )
					.map( key => RATING_STATUS[ key ] )
					.filter( status => status.name === this.state.rating.Status )
					.reduce( getLastElementOr, {id: 0} )
					.id !== RATING_STATUS.perimetralQuestionValidated.id
			)
		);

		return ( !!closePermission && !( pmAndState_done || operatorAndState_done ) );

	}




	render() {

		renderlog("SIRatingPerimetralQuestionPage", this.state, this.props);


		if( !!this.state.goAway ) {
			return <Redirect to={ PATH_PREFIX + "/super-admin/si-rating/" + translate("evaluations") } />;
		}
		if( !!this.state.returnToEvaluation ) {
			return <Redirect to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ this.props.location.state.companyId} />;
		}
		if( !!this.state.returnToConfiguration ) {
			return <Redirect to={ PATH_PREFIX +"/si-rating/" + translate("evaluation") + "/"+ this.props.location.state.companyId} />;
		}

		// debugger;

		let progressColor =
			MAGNITUDE_DARK
				// .filter( (m, i) => (i % 2 === 1) )	// 4 step invece che 8
				.filter( (m, i) => {
					// let step = Math.floor( this.state.progress / (100 / MAGNITUDE.filter( (m, i) => (i % 2 === 1) ).length) ) - 1 + ( (this.state.progress % ( 100 / MAGNITUDE.filter( (m, i) => (i % 2 === 1) ).length )) > 0 ? 1 : 0 );
					let step = Math.floor( this.state.progress / (100 / MAGNITUDE_DARK.length) ) - 1 + ( (this.state.progress % ( 100 / MAGNITUDE_DARK.length )) > 0 ? 1 : 0 );
					return i === step;
				})
				.reduce( (final, current) => current.color, null )
		;



		return (


			<Suspense fallback={this.inWaiting()}>

				{
					!!this.state.alertOpen
						? (
							<Alert color="danger" isOpen={true}>
								<div>
									<code>Errore { this.state.alertMessage }</code>
								</div>
							</Alert>
						)
						: null
				}


				{
					(!!this.state.helper)
						? (
							<div
								className={"helper-pannel-backdrop"}
								onClick={ clickEvent => {
									clickEvent.stopPropagation();
									this.setState({
										...this.state,
										helper: null
									})
								}}
							>
								<div
									className={"helper-pannel"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
									}}
								>
									<code>
										Helper Domanda <strong>{ this.state.helper.question_id }</strong>
									</code>
									<span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;risposte:&nbsp;&nbsp;
										<span
											className={"classic-evidentiation"}
										>
                                            { this.state.helper.answers.length }
                                        </span>
                                    </span>
									<pre
										onClick={ clickEvent => {
											clickEvent.stopPropagation();
										}}
									>
										{ beautify( this.state.helper ) }
									</pre>
								</div>
							</div>
						)
						: null
				}


				<PageTitle
					breadCrumbItems={[
						{
							label:"SI Rating" /*
								Object.keys( role )
									.map( roleObjectName => role[ roleObjectName ] )
									.filter( roleObject => roleObject.name === localStorage.getItem("role") )
									.reduce( getLastElementOr, "Nessun Ruolo" )
									.label */
							, path: PATH_PREFIX + "/welcome" },
						{ label: translate("Evaluations"), path: PATH_PREFIX + "/super-admin/si-rating/" + translate("evaluations") },
						{ label: translate("Perimetral Questions"), active: true }
					]}
					title={"Profilazione"}
				/>


				{
					(!!this.state.sectorModal)
						? (
							<SectorListPopup
								companyId={ this.state.rating.companyId }
								onClose={ () => {
									this.setState({
										...this.state,
										sectorModal: null
									})
								}}
								onChoose={ list => {
									// this.setState({
									// 	...this.state,
									// 	sectorListModal: null
									// })
								}}
							/>
						)
						: null
				}


				<Card>
					<CardHeader>
						{/*<h4>*/}
							<div className={"rating-header-name"}>
								Domande per la valutazione &nbsp;
								<strong className={""}>{
									(
										!!this.props.location.state
											? this.props.location.state.Nome
											: (
												!!this.state.rating
													? this.state.rating.Nome
													: ""
											)
									)
									|| this.props.match.params.id
								}</strong>
							</div>
						{/*</h4>*/}

							<Label for="subThemes">{translate("Filter")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
							<Select
								className="react-select question-filter-select"
								classNamePrefix="react-select"
								name="question-filter"
								id="question-filter"
								onChange={ changeEvent => {
									this.setState({
										...this.state,
										activeFilter: changeEvent.value
									})
								}}
								options={[
									{ label: "Tutte", value: -1 },
									{ label: "Da rispondere", value: 0 },
									{ label: "Risposte", value: 1 }
								]}
								placeholder={ <span className={"select-placeholder"}>{translate("Select filter")}</span> }
							/>


							{/*
							treetoggler
							*/}




							<span className="float-right">

								{
									(!!this.state.ready)
									// (this.state.progress === 100)
										? (
											<ActionButton
												visible={ !this.isReadOnly() }
												buttonText={ !!isUserRole( role.ARB_OPERATOR ) ? "Conferma" : ( (!!this.props.location.state.comeFromStateConfigurationInProgress) ? "Torna alla configurazione" : ( (!!this.props.location.state.cameFromStatusHigherThan20) ?  "Torna alla valutazione" : "Valida")) }
												promise={ () => {

													if(!!this.props.location.state.cameFromStatusHigherThan20 || !!this.props.location.state.comeFromStateConfigurationInProgress){
														return Promise.resolve()
													}else{
														return savePerimetral( this.props.match.params.id || this.props.id, !!isUserRole( role.ARB_OPERATOR ) );
													}

												}}
												onResult={result => {
													if(!!this.props.location.state.cameFromStatusHigherThan20 || !!this.props.location.state.comeFromStateConfigurationInProgress){

														if(!!this.props.location.state.cameFromStatusHigherThan20){
															this.setState({
																...this.state,
																returnToEvaluation: true
															})
														}else if(!!this.props.location.state.comeFromStateConfigurationInProgress){
															this.setState({
																...this.state,
																returnToConfiguration: true
															})
														}

													}else{
														this.setState({
															...this.state,
															goAway: true
														})
													}

												}}
												onError={e => {
													errorlog("Validazione", e);

													let errorMessage = extractErrorMessage( e );

													// let errorMessage = e;
													// if( !!e ) {
													// 	if( !!e.error ) {
													// 		// errorMessage = e.error;
													// 		if( !!e.error.msg ) errorMessage = e.error.msg;
													// 		if( !!e.error.message ) errorMessage = e.error.message;
													// 		if( !!e.error.error ) {
													// 			// errorMessage = e.error.error;
													// 			if( !!e.error.error.msg ) errorMessage = e.error.error.msg;
													// 			if( !!e.error.error.message ) errorMessage = e.error.error.message;
													// 		}
													// 	}
													// }


													this.setState({
														...this.state,
													    alertMessage: errorMessage,
														alertOpen:true
													})

												}}
											/>
										)
										: (
											<React.Fragment>
												<div className={"tree_wizard_step_bar_label"}>
													<span>Domande Completate { this.state.progress }%</span>
												</div>
												<Progress
													animated
													striped
													color={ progressColor }
													value={ this.state.progress }
													className="mb-3 progress-sm"
													id={"questionProgress"}
												/>
											</React.Fragment>
										)
								}

							</span>

					</CardHeader>
					<CardBody>

						<TreeToggler
							tree={ this.state.data }
							onFinish={ newTree => {
								this.setState({
									...this.state,
									data: newTree
								})
							}}
							className={"perimetral-question-toggler"}
						/>


							{
								(!!this.state.data && this.state.data.length > 0 && this.state.status !== RATING_STATUS.configurationValidated.id)
									? (
										<Card className="mb-0">
											<CardHeader className={""}>
												<div
													className={"custom-accordion-title pt-2 pb-2 perimetral-question-text "}>
													{ this.state.data[0].question_name }

													<span className={"margin-left"}>
														<span
															id={"perimetralQuestionSectors"}
															className={"wa-icon-perimetral-question-sector-list"}
															onClick={ clickEvent => {
																this.setState({
																		...this.state,
																		sectorsOpen: true
																	},
																	() => this.loadSectors()
																)
															}}
														>
															{ emoj( EMOJ.clipboard ) }
														</span>


														<Popover
															placement="right"
															target={ "perimetralQuestionSectors" }
															// trigger="legacy"
															isOpen={ !!this.state.sectorsOpen }
															toggle={ tEvent => {
																this.setState({
																	...this.state,
																	sectorsOpen: !this.state.sectorsOpen
																});
																// return !this.state.sectorsOpen;
															}}
														>
															<PopoverHeader>
																<span>
																	Settore corrispondente SASB
																</span>
																{/*
																mdi mdi-close
																mdi mdi-close-circle
																mdi mdi-close-circle-outline
																*/}
																<i
																	className={"mdi mdi-close float-right tooltip-icon-cursor"}
																	onClick={ clickEvent => {
																		this.setState({
																			...this.state,
																			sectorsOpen: false
																		});
																	}}
																/>
															</PopoverHeader>
															<PopoverBody>
																{
																	(!!this.state.sectors && this.state.sectors.length > 0)
																		? (
																			<ToolkitProvider
																				bootstrap4
																				keyField="Settore"
																				search
																			>
																				{props => (
																					<React.Fragment>
																						<Row>
																							<Col>
																								<SearchBar {...props.searchProps} />
																							</Col>
																						</Row>
																						<BootstrapTable
																							id={"sectorlist-table"}
																							bootstrap4
																							striped
																							{...props.baseProps}
																							size="sm"
																							responsive
																							keyField="Settore"
																							bordered={ false }
																							data={ this.state.sectors }
																							columns={ formatColumns( Object.keys( this.state.sectors[0] ) ) }
																							defaultSorted={ defaultSorted }
																							pagination={ paginationFactory(paginationOptions) }
																							wrapperClasses="table-responsive"
																						/>
																					</React.Fragment>
																				)}
																			</ToolkitProvider>
																		)
																		: (
																			<WaitingOrNodataMessage
																				waiting={ !(!!this.state.sectors && this.state.sectors.length === 0) }
																				spinnerColor={"success"}
																				waitingMessage={""}
																				nodataMessage={"Nessun Settore"}
																			/>
																		)
																}
															</PopoverBody>
														</Popover>
													</span>




													<span className={"float-right"}>
														<ActionButton
															// color={"success"}
															buttonText={"Imposta"}
															promise={() => {
																return (
																	new Promise((success, fail) => {
																		this.setState({
																				...this.state,
																				sectorModal: true
																			},
																			() => {
																				success();
																			}
																		)
																	})
																);
															}}
														/>
													</span>


												</div>

											</CardHeader>
										</Card>
									)
									: null
							}





							{
								( !!this.state.data && this.state.data.length > 0 )
									? (
										this.state.data

											// filtro
											.filter( question => {

												// showlog("controllo filtro "+ this.state.activeFilter, question.answers.length, question);

												// da rispondere
												if( this.state.activeFilter === 0 ) return (question.answers.length === 0);

												// risposte
												if( this.state.activeFilter === 1 ) return (question.answers.length > 0);

												return true;
											})


											// .filter( (question, index) => ( index > 0 ))
											.filter( q => (q.question_id !== "0" && q.question_id !== 0 && !!q.question_id) )


											.map( (question, questionIndex) => {


												if(question.answers.length > 0 && !question.question_isMuliple) {
													// question.open = false;
												}



												let questionColorPolicyClass = (


													(question.answers.length > 0)
														? COLOR_POLICY.validated.textClass
														: (
															(!!question.inEdit)
																? COLOR_POLICY.edit.textClass
																: ""
														)


													// (!!question.inEdit)
													// 	? COLOR_POLICY.edit.textClass
													// 	: (
													// 		(question.answers.length > 0)
													// 			? COLOR_POLICY.validated.textClass
													// 			: ""
													// 	)


												);



												return (
													<Card className="mb-0">
														<CardHeader className={""}>
															<NavLink
																id={"profiling_question-" + question.question_id}
																className={"profiling_question-text"}
																href="#"
																onClick={clickEvent => {



																		let dataTmp = cloneObject( this.state.data );
																		let referenceQuestion =
																			dataTmp
																				.filter( rq => rq.question_id === question.question_id )
																				.reduce( getLastElementOr, null )
																		;

																		// if( question.question_id !== PERIMETRAL_QUESTION_SECTORS_BELONG ) {
																			referenceQuestion.open = !question.open;
																		// }


																		this.setState({
																			...this.state,
																			data: dataTmp
																		})



																}}
															>
																<div
																	className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 perimetral-question-text "+ questionColorPolicyClass}
																>
																	{ this.helperNode( question ) }
																	{ question.question_name }
																</div>


															</NavLink>


															{/*
															<span className={"float-right"}>

																<ActionButton
																	promise={() => {
																		// lista delle risposte per la domanda corrente
																		let currentQuestionAnswers =
																			[
																				{
																					id: question.question_id,
																					choicheList:
																						this.state.data
																							.filter( q => q.question_id === question.question_id )
																							.reduce( getLastElementOr, {choiche: null} )
																							.choiche
																							.filter( rc => rc.checked )
																							// .map( rc => ({id: rc.choiche_id, motivation: rc.motivation}))
																							.map( rc => ({id: rc.choiche_id, motivation: document.getElementById("reason-" + rc.choiche_id).value}))
																				}
																			]
																		;

																		showlog("controllo salvataggio");
																		showlog(currentQuestionAnswers);

																		return saveProfilingAnswer( (this.props.match.params.id || this.props.id), question.question_id, currentQuestionAnswers);

																	}}
																	onResult={result => {

																		this.loadData();

																	}}
																	onError={e => {
																		//
																	}}
																/>

															</span>
															*/}


														</CardHeader>
														<Collapse isOpen={ !!question.open } toggler={ "#profiling_question-" + question.question_id }>
															<CardBody className={"margin-left"}>



																{
																	(!!question.choiche)
																		? (
																			question.choiche
																				.map( choiche => {

																					// let checkedChoiche = !!choiche.checked;

																					choiche.checked = question.answers.some( ans => ans.choiche_id === choiche.choiche_id );
																					let dataTmp = cloneObject( this.state.data );
																					let currentQuestion =
																						dataTmp
																							.filter( rq => rq.question_id === question.question_id )
																							.reduce( getLastElementOr, question )
																					;


																					return (

																						<React.Fragment>



																							<Row>
																								<Col>
																									{
																										( !!question.question_isMuliple )
																											? ( // MULTIPLE ANSWER --------------------------------------------------------------------------------------
																												<FormGroup>
																													<CustomInput
																														type="checkbox"
																														name={"reason-" + choiche.choiche_id}
																														id={"reason-" + choiche.choiche_id}
																														label={choiche.name}
																														onClick={clickEvent => {
																															let referenceChoiche =
																																dataTmp
																																	.filter( rq => rq.question_id === question.question_id )
																																	.reduce( getLastElementOr, {choiche: null} )
																																	.choiche
																																		.filter( rc => rc.choiche_id === choiche.choiche_id )
																																		.reduce( getLastElementOr, null )
																															;
																															referenceChoiche.checked = !!!choiche.checked;
																															let choicheDOM = document.getElementById("reason-" + choiche.choiche_id);
																															if( !!choicheDOM ) choicheDOM.checked = referenceChoiche.checked;

																															currentQuestion.inEdit = true;

																															if( !!choiche.checked ) {
																																// eliminazione dalle answers
																																currentQuestion.answers =
																																	currentQuestion.answers
																																		.filter( ans => ans.choiche_id !== referenceChoiche.choiche_id )
																																;
																															}
																															else {
																																currentQuestion.answers = [ ...currentQuestion.answers, { choiche_id: referenceChoiche.choiche_id }];
																															}

																															this.setState({
																																...this.state,
																																data: dataTmp
																															},

																																() => {


																																	let currentQuestionAnswers =
																																		[
																																			{
																																				id: currentQuestion.question_id,
																																				choicheList:
																																					currentQuestion.choiche
																																						.filter( c => !!c.checked )
																																						.map( c => ({
																																							id: c.choiche_id,
																																							motivation: null
																																								// (!!document.getElementById( "reason-"+ c.choiche_id))
																																								// 	? (
																																								// 		(!!document.getElementById( "reason-"+ c.choiche_id).value)
																																								// 			? document.getElementById( "reason-"+ c.choiche_id).value
																																								// 			: c.motivation
																																								// 	)
																																								// 	: null
																																						}))
																																			}
																																		]
																																	;



																																	// debugger;
																																	showlog( (this.props.match.params.id || this.props.id), question.question_id, currentQuestion);


																																	if( !this.isReadOnly() ) {
																																		saveProfilingAnswer( (this.props.match.params.id || this.props.id), currentQuestion.question_id, currentQuestionAnswers)
																																			.then( result => {
																																				showlog( currentQuestion );
																																				currentQuestion.inEdit = false;
																																				// currentQuestion.open = false;



																																				let progress = this.getProgress();
																																				this.setState({
																																					...this.state,
																																					data: dataTmp,
																																					ready: progress >= 100,
																																					progress: progress
																																				})


																																			})
																																			.catch( err => {
																																				errorlog("Live Saving", err);
																																			})
																																		;
																																	}





																																}

																															)

																														}}
																														defaultChecked={ !!choiche.checked }
																														checked={ !!choiche.checked }
																													/>
																												</FormGroup>

																											)

																											: ( // SINGLE ANSWER ------------------------------------------------------------------------------------------------------------------------------------------------

																												<FormGroup>

																													{
																														(!!choiche.isRequiredMotivation)
																															? (
																																<Row>
																																	<Col md={ 3 }>
																																		<Input
																																			type="textarea"
																																			row={ 32 }
																																			name={"reason-" + choiche.choiche_id}
																																			id={"reason-" + choiche.choiche_id}
																																			placeholder={
																																				// question.answers
																																				// 	.filter( ans => ans.choiche_id === choiche.choiche_id )
																																				// 	.reduce( getLastElementOr, {motivation: null} )
																																				// 	.motivation
																																				choiche.motivation
																																			}
																																			className={""}

																																			onChange={ chengeEvent => {
																																				/*
																																				currentQuestion.inEdit = true;
																																				currentQuestion.answers = [choiche];

																																				let referenceChoiche =
																																					currentQuestion
																																						.choiche
																																							.filter( rc => rc.choiche_id === choiche.choiche_id )
																																							.reduce( getLastElementOr, null )
																																				;
																																				referenceChoiche.checked = true;

																																				referenceChoiche.motivation = chengeEvent.value;


																																				this.setState({
																																					...this.state,
																																					data: dataTmp
																																				})
																																				*/
																																			}}

																																			defaultValue={
																																				choiche.motivation
																																			}

																																			onBlur={ blurEvent => {



																																				showlog( "DOM" );
																																				showlog( blurEvent.target );
																																				showlog( document.getElementById( "reason-"+ currentQuestion.choiche[0].choiche_id) );

																																				showlog( blurEvent.target.value );
																																				showlog( document.getElementById( "reason-"+ currentQuestion.choiche[0].choiche_id).value );


																																				let blurEventTargetValue = blurEvent.target.value;

																																				currentQuestion.answers = [choiche];
																																				currentQuestion.inEdit = true;

																																				this.setState({
																																						...this.state,
																																						data: dataTmp
																																					},

																																					() => {


																																						let currentQuestionAnswers =
																																							[
																																								{
																																									id: currentQuestion.question_id,
																																									choicheList:
																																										currentQuestion.choiche
																																											.filter( c => !!c.checked )
																																											.map( c => ({
																																												id: c.choiche_id,
																																												motivation:
																																													blurEventTargetValue
																																													/*
																																													(!!document.getElementById( "reason-"+ c.choiche_id))
																																														? (
																																															(!!document.getElementById( "reason-"+ c.choiche_id).value)
																																																? document.getElementById( "reason-"+ c.choiche_id).value
																																																: (
																																																	!!blurEvent.target
																																																		? blurEvent.target.value
																																																		: c.motivation
																																																)
																																														)
																																														: null
																																													*/
																																											}))
																																								}
																																							]
																																						;



																																						// debugger;
																																						showlog( (this.props.match.params.id || this.props.id), question.question_id, currentQuestion);


																																						if( !this.isReadOnly() ) {
																																							saveProfilingAnswer( (this.props.match.params.id || this.props.id), currentQuestion.question_id, currentQuestionAnswers)
																																								.then( result => {
																																									showlog( currentQuestion );
																																									currentQuestion.inEdit = false;
																																									// currentQuestion.open = false;


																																									// controllo testo eliminato
																																									showlog("controllo testo cancellato");

																																									showlog( currentQuestion );
																																									showlog( currentQuestionAnswers );


																																									showlog(
																																										currentQuestion.choiche
																																											.filter( c => !!c.checked )
																																											.filter( c => !c.motivation )
																																									);
																																									if( !currentQuestionAnswers[0].choicheList[0].motivation ) {
																																										currentQuestion.answers = [];
																																									}




																																									let progress = this.getProgress();
																																									this.setState({
																																										...this.state,
																																										data: dataTmp,
																																										ready: progress >= 100,
																																										progress: progress
																																									})

																																								})
																																								.catch( err => {
																																									errorlog("Live Saving", err);
																																								})
																																							;
																																						}


																																					}

																																				)

																																			}}
																																			// value={ (choiche.name === PERIMETRAL_QUESTION_TEXT_ANSWER) ? "" : choiche.name }
																																			required
																																		/>
																																	</Col>
																																</Row>

																															)
																															: (
																																<CustomInput
																																	type="radio"
																																	name={"reason-" + choiche.choiche_id}
																																	id={"reason-" + choiche.choiche_id}
																																	label={choiche.name}
																																	onClick={clickEvent => {
																																		/*
																																		dataTmp
																																			.filter( rq => rq.question_id === question.question_id )
																																			.map( currentQuestion => {
																																				currentQuestion.answers = [choiche.choiche_id];
																																			})
																																		;
																																		*/

																																		currentQuestion.answers = [choiche];
																																		currentQuestion.inEdit = true;

																																		this.setState({
																																			...this.state,
																																			data: dataTmp
																																		},

																																			() => {


																																				let currentQuestionAnswers =
																																					[
																																						{
																																							id: currentQuestion.question_id,
																																							choicheList:
																																								currentQuestion.choiche
																																									.filter( c => !!c.checked )
																																									.map( c => ({
																																										id: c.choiche_id,
																																										motivation: null
																																											// (!!document.getElementById( "reason-"+ c.choiche_id))
																																											// 	? (
																																											// 		(!!document.getElementById( "reason-"+ c.choiche_id).value)
																																											// 			? document.getElementById( "reason-"+ c.choiche_id).value
																																											// 			: c.motivation
																																											// 	)
																																											// 	: null
																																									}))
																																						}
																																					]
																																				;



																																				// debugger;
																																				showlog( (this.props.match.params.id || this.props.id), question.question_id, currentQuestion);


																																				if( !this.isReadOnly() ) {
																																					saveProfilingAnswer( (this.props.match.params.id || this.props.id), currentQuestion.question_id, currentQuestionAnswers)
																																						.then( result => {
																																							showlog( currentQuestion );
																																							currentQuestion.inEdit = false;
																																							// currentQuestion.open = false;

																																							let progress = this.getProgress();
																																							this.setState({
																																								...this.state,
																																								data: dataTmp,
																																								ready: progress >= 100,
																																								progress: progress
																																							})

																																						})
																																						.catch( err => {
																																							errorlog("Live Saving", err );
																																						})
																																					;
																																				}



																																			}

																																		)

																																	}}
																																	defaultChecked={ !!choiche.checked }
																																	checked={ !!choiche.checked }
																																/>
																															)
																													}

																												</FormGroup>

																											)

																									}
																								</Col>
																							</Row>
																						</React.Fragment>


																					);
																				})
																		)
																		: null
																}
															</CardBody>
														</Collapse>
													</Card>
												);


											})
									)
									: (
										<WaitingOrNodataMessage
											waiting={ !(!!this.state.data && this.state.data.length === 0) }
											fullHeight={ true }
											nodataMessage={"Nessun Dato"}
										/>
									)
							}




						<GotoTopComponent/>
						<br/>

						<span className="float-right">

								{
									(!!this.state.ready)
									// (this.state.progress === 100)
										? (
											<ActionButton
												visible={ !this.isReadOnly() }
												buttonText={ !!isUserRole( role.ARB_OPERATOR ) ? "Conferma" :  ( (!!this.props.location.state.comeFromStateConfigurationInProgress) ? "Torna alla configurazione" : ( (!!this.props.location.state.cameFromStatusHigherThan20) ?  "Torna alla valutazione" : "Valida"))}
												promise={ () => {

													if(!!this.props.location.state.cameFromStatusHigherThan20 || !!this.props.location.state.comeFromStateConfigurationInProgress){
														return Promise.resolve()
													}else{
														return savePerimetral( this.props.match.params.id || this.props.id, !!isUserRole( role.ARB_OPERATOR ) );
													}

												}}
												onResult={result => {
													if(!!this.props.location.state.cameFromStatusHigherThan20 || !!this.props.location.state.comeFromStateConfigurationInProgress){

														if(!!this.props.location.state.cameFromStatusHigherThan20){
															this.setState({
																...this.state,
																returnToEvaluation: true
															})
														}else if(!!this.props.location.state.comeFromStateConfigurationInProgress){
															this.setState({
																...this.state,
																returnToConfiguration: true
															})
														}

													}else{
														this.setState({
															...this.state,
															goAway: true
														})
													}
												}}
												onError={e => {
													errorlog("Validazione", e);

													let errorMessage = extractErrorMessage( e );

													// let errorMessage = e;
													// if( !!e ) {
													// 	if( !!e.error ) {
													// 		// errorMessage = e.error;
													// 		if( !!e.error.msg ) errorMessage = e.error.msg;
													// 		if( !!e.error.message ) errorMessage = e.error.message;
													// 		if( !!e.error.error ) {
													// 			// errorMessage = e.error.error;
													// 			if( !!e.error.error.msg ) errorMessage = e.error.error.msg;
													// 			if( !!e.error.error.message ) errorMessage = e.error.error.message;
													// 		}
													// 	}
													// }


													this.setState({
														...this.state,
													    alertMessage: errorMessage,
														alertOpen:true
													})

												}}
											/>
										)
										: (
											<React.Fragment>
												<div className={"tree_wizard_step_bar_label"}>
													<span>Domande Completate { this.state.progress }%</span>
												</div>
												<Progress
													animated
													striped
													color={ progressColor }
													value={ this.state.progress }
													className="mb-3 progress-sm"
													id={"questionProgress"}
												/>
											</React.Fragment>
										)
								}

							</span>


					</CardBody>
					{/*
					<CardFooter>

						<span className={"float-right"}>
							<ActionButton
								promise={() => {
									// lista delle risposte per la domanda corrente
									let currentQuestionAnswers =
										this.state.data
											.filter( (q, i) => i > 0 )
											.map( question => ({
												id: question.question_id,
												choicheList:
													question.choiche
														.filter( c => !!c.checked  )
														.map( c => ({
															id: c.choiche_id,
															motivation:
																// c.motivation
																(!!document.getElementById( "reason-"+ c.choiche_id))
																	? (
																		(!!document.getElementById( "reason-"+ c.choiche_id).value)
																			? document.getElementById( "reason-"+ c.choiche_id).value
																			: c.motivation
																	)
																	: null
														}))
											}))
									;

									// showlog("controllo salvataggio");
									// showlog(
									// 	this.state.data
									// 		.filter( (q, i) => i > 0 )
									// 		.map( question => question.choiche )
									// );
									// showlog(currentQuestionAnswers);


									// return true;
									return saveProfilingAnswer( (this.props.match.params.id || this.props.id), 0, currentQuestionAnswers);

								}}
								onResult={result => {

									this.loadData();

								}}
								onError={e => {
									//
								}}
							/>
						</span>

					</CardFooter>
					*/}
				</Card>




			</Suspense>
		);
	}

	componentDidMount() {
		this.loadData();
	}




	loadSectors() {

		return (
			loadAllSectors( this.state.rating.companyId )

				// log -----------------------------------------------------------------------------------------------------
				.then( responseData => {

					showlog("prima di mettere i dati nello state | start");
					showlog(responseData);

					return responseData;
				})



				// field sort ----------------------------------------------------------------------------------------------
				.then( responseData => {

					return (
						responseData
							.map( row => {

								let sortedRow = {};
								Object.keys(sectorsFieldMap)
									.map( field => {
										sortedRow[sectorsFieldMap[field]] = row[field];
									})
								;
								Object.assign(sortedRow, row);
								return sortedRow;

							})
					);
				})

				// row sort ------------------------------------------------------------------------------------------------
				.then( responseData => {

					return (
						responseData
							.sort((x,y) => {
								if( x.Settore > y.Settore ) return 1;
								return -1;
							})
					)
				})

				// adjust --------------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( row => {
								if( !!row.Settore ) row.Settore = row.Settore.trim();
								return row;
							})
					);
				})


				// final ----------------------------------------------------------------------------------------------------
				.then( sectorsList => {

					return (
						new Promise( (finish, fail) => {

							this.setState({
								...this.state,
								sectors:
									sectorsList
										.filter( s => !!s.selected )
										.map( s => {

											s[" "] = (
												<span className={"star-flag"}>
													{
														!!s[" "]
															? emoj(EMOJ.star)
															: " "
													}
												</span>
											);

											return s;
										})
							},
								() => {
									finish()
								}
							)

						})
					);


				})

				.then( () => {
					// debugger;
					return true;
				})


				.catch(e => {
					errorlog("Load Data Perimetral - sectors section", e);
					return Promise.reject( e );
				})


		);


	}





	loadData() {

		return (

			Promise.all(
				[
				getEvaluationStatus( this.props.match.params.id || this.props.id ),

				new Promise((onCheck, fail) => {
					onCheck( !!!this.props.location.state );
				})


					.then( isMissingRatingData => {

						if( !!isMissingRatingData && !this.state.rating ) {

							// si arriva da url e non da navigazione
							return (
								loadEvaluations()
									.then( list => {
										return (
											list
												.filter( rating => rating.id == (this.props.match.params.id || this.props.id) )
												.reduce( getLastElementOr, null )
										)
									})

									// field map ----------------------------------------------------------------------------------------------
									.then( ratingData => {
										let dto = {};
										Object.keys( ratingData )
											.forEach( rawField => {
												dto[ fieldMap[ rawField ] ] = ratingData[ rawField ];
											})
										;
										return dto;
									})

									.then( ratingData => {
										return ({ ratingData: ratingData, isFromUrl: !!isMissingRatingData });
									})
							);
						}
						else {

							return ({
								ratingData:
									(!!this.state && !!this.state.rating)
										? this.state.rating
										: this.props.location.state
								,
								isFromUrl: !!isMissingRatingData
							});
						}
					})


					.then( ({ratingData, isFromUrl}) => {


						// debugger;

						return (
							loadProfilingData( this.props.match.params.id || this.props.id )

								.then( (questions, qIndex) => {
									return (
										questions
											.map(q => {
												// if( q.question_id !== PERIMETRAL_QUESTION_SECTORS_BELONG ) q.open = true;
												// else q.open = false;
												q.open = true;
												return q;
											})
									);
								})

								.then( qs => {
									return ({
										ratingData: ratingData,
										questionList: qs,
										isFromUrl: isFromUrl
									});

								})

								.catch(e => {
									errorlog("errore", e);
									return ({
										ratingData: ratingData,
										questionList: []
									});
								})

						)
							;

					})


					// controllo permessi --------------------------------------
					.then(({ratingData, questionList, isFromUrl}) => {

						let goAway = false;

						if( !!isFromUrl ) {
							// accesso in base allo stato
							let currentStatus =
								Object.keys( RATING_STATUS )
									.map( key => RATING_STATUS[ key ] )
									.filter( status => status.name === ratingData.Status )
									.reduce( getLastElementOr, null )
							;

							goAway = ( currentStatus.id !== RATING_STATUS.configurationRunning.id );

						}


						return ({
							ratingData: ratingData,
							questionList: questionList,
							isFromUrl: isFromUrl,
							goAway: goAway
						});

					})

				]
			)

			.then( resultList => {
				return ({
					status: resultList[0],
					...resultList[1]
				});
			})



			// new Promise((onCheck, fail) => {
			// 	onCheck( !!!this.props.location.state );
			// })
			//
			//
			// .then( isMissingRatingData => {
			//
			// 	if( !!isMissingRatingData && !this.state.rating ) {
			//
			// 		// si arriva da url e non da navigazione
			// 		return (
			// 			loadEvaluations()
			// 				.then( list => {
			// 					return (
			// 						list
			// 							.filter( rating => rating.id == (this.props.match.params.id || this.props.id) )
			// 							.reduce( getLastElementOr, null )
			// 					)
			// 				})
			//
			// 				// field map ----------------------------------------------------------------------------------------------
			// 				.then( ratingData => {
			// 					let dto = {};
			// 					Object.keys( ratingData )
			// 						.forEach( rawField => {
			// 							dto[ fieldMap[ rawField ] ] = ratingData[ rawField ];
			// 						})
			// 					;
			// 					return dto;
			// 				})
			//
			// 				.then( ratingData => {
			// 					return ({ ratingData: ratingData, isFromUrl: !!isMissingRatingData });
			// 				})
			// 		);
			// 	}
			// 	else {
			//
			// 		return ({
			// 			ratingData:
			// 				(!!this.state && !!this.state.rating)
			// 					? this.state.rating
			// 					: this.props.location.state
			// 			,
			// 			isFromUrl: !!isMissingRatingData
			// 		});
			// 	}
			// })
			//
			//
			// .then( ({ratingData, isFromUrl}) => {
			//
			//
			// 	// debugger;
			//
			// 	return (
			// 		loadProfilingData( this.props.match.params.id || this.props.id )
			//
			// 			.then( (questions, qIndex) => {
			// 				return (
			// 					questions
			// 						.map(q => {
			// 							// if( q.question_id !== PERIMETRAL_QUESTION_SECTORS_BELONG ) q.open = true;
			// 							// else q.open = false;
			// 							q.open = true;
			// 							return q;
			// 						})
			// 				);
			// 			})
			//
			// 			.then( qs => {
			// 				return ({
			// 					ratingData: ratingData,
			// 					questionList: qs,
			// 					isFromUrl: isFromUrl
			// 				});
			//
			// 			})
			//
			// 			.catch(e => {
			// 				errorlog("errore", e);
			// 				return ({
			// 					ratingData: ratingData,
			// 					questionList: []
			// 				});
			// 			})
			//
			// 	)
			// 	;
			//
			// })
			//
			//
			// // controllo permessi --------------------------------------
			// .then(({ratingData, questionList, isFromUrl}) => {
			//
			// 	let goAway = false;
			//
			// 	if( !!isFromUrl ) {
			// 		// accesso in base allo stato
			// 		let currentStatus =
			// 			Object.keys( RATING_STATUS )
			// 				.map( key => RATING_STATUS[ key ] )
			// 				.filter( status => status.name === ratingData.Status )
			// 				.reduce( getLastElementOr, null )
			// 		;
			//
			// 		goAway = ( currentStatus.id !== RATING_STATUS.configurationRunning.id );
			//
			// 	}
			//
			//
			// 	return ({
			// 		ratingData: ratingData,
			// 		questionList: questionList,
			// 		isFromUrl: isFromUrl,
			// 		goAway: goAway
			// 	});
			//
			// })
			//



			.then( ({ratingData, questionList, isFromUrl, goAway, status}) => {


				// debugger;

				let answered =
					questionList
						.filter( question => ( !PERIMETRAL_QUESTION_TO_AVOID.includes( question.question_id ) ) )
						.map( question => ( question.answers.length > 0 ) )
						.filter( onlyNotNull )
						.length
				;
				let progress = answered / (questionList.length -2);
				progress *= 100;
				progress = Math.floor( progress );
				progress = parseInt( progress, 10 );


				// if( status === RATING_STATUS.configurationValidated.id ) {
				// 	questionList =
				// 		questionList
				// 			.filter( (q, index) => index > 0 )
				// 	;
				// }


				this.setState({
					...this.state,
					data: [
						{
							answers: [],
							choiche: [],
							question_id: 0,
							question_isMuliple: false,
							question_name: "Settori"
						},
						...questionList
					],
					rating: ratingData,
					isFromUrl: isFromUrl,
					goAway: goAway,
					ready: progress >= 100,
					progress: progress,
					status: status
				})
			})


			.catch(e => {
				errorlog("Load Data Perimetral", e);
				return Promise.reject( e );
			})

		);

	}



}

export default withRouter(SIRatingPerimetralQuestionPage);
