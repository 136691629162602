import React from "react";
import {Col, Progress, Row} from "reactstrap";

export const WizardPanel = props => (
	<Row>
		<Col>
			<div className="page-title-box">
				<div className="page-title-right">
					
					<div className={"tree_wizard_step_bar_label"}>
						<span>{ props.progressLabel }</span>
					</div>
					<Progress
						animated
						striped
						color="success"
						value={ props.progress }
						className="mb-3 progress-sm"
					/>
				
				</div>
				<h4 className="page-title">{ props.title }</h4>
			
			
			</div>
		</Col>
	</Row>
);
