import {isNumber} from './codes-utils';
import {NO_MEASUREMENT_UNIT, NUMERIC_MEASUREMENT_UNIT, VOID_MEASUREMENT_UNIT} from "../env";

/**
 * Converte il numero passato con un numero di decimali definito nel parametro _len
 * @param raw
 * @param _len
 * @returns {string}
 */
export const formatDigitLength = (raw, _len) => {
    let len = _len || 2;
    const ZERO = 0;
    if( isNumber( raw ) ) return parseFloat(raw).toFixed(len);
    if( !raw || typeof raw === "undefined" || raw === null ) return parseFloat(ZERO).toFixed( len );
    return parseFloat(ZERO).toFixed( len );
};

/**
 * Controlla se il valore passato sia un unità di misura valida, cioè controlla se il valore passato non sia definito dalle costanti NO_MEASUREMENT_UNIT, NUMERIC_MEASUREMENT_UNIT, VOID_MEASUREMENT_UNIT
 * @param value
 * @returns {boolean}
 */
export const isMeasureUnit = value => {
    if( !value ) return false;
    return !(value.toLowerCase() === NO_MEASUREMENT_UNIT || value.toLowerCase() === NUMERIC_MEASUREMENT_UNIT || value.toLowerCase() === VOID_MEASUREMENT_UNIT)
}

/**
 * Controlla se il valore passato sia il valore numeric
 * @param value
 * @returns {boolean}
 */
export const isMeasureUnitNumeric = value => {
    if( !value ) return false;
    return !!(value.toLowerCase() === NUMERIC_MEASUREMENT_UNIT || (!(value.toLowerCase() === NO_MEASUREMENT_UNIT || value.toLowerCase() === VOID_MEASUREMENT_UNIT)))
}
