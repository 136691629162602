import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {errorlog, showlog} from "../../../utils/dev-utils";
import {setEvaluationStatus} from "../../../business-logic/rating";
import {PATH_PREFIX} from "../../../env";
import ActionButton from "../../save-button";
import {translate} from "../../i18n/translate-function";


export const CloseRatingConfirmModal = props => {

	const [inWait, inWaiting] = useState( false );

	useEffect( () => {
		// callBack di cambio state
		if( !!inWait ) showlog("...in attesa");
	},
		[inWait]
	);


		let content = (
			<Modal isOpen={ true } >
				<ModalHeader className={""}>
					<span>Attenzione</span>
				</ModalHeader>
				<ModalBody >
					{/*<span>Si sta per procedere alla sottomissione della valutazione. Una volta sottomessa, la valutazione non sarà più modificabile ed inizierà la fase di audit da parte del team ARB S.B.P.A.<br/><br/>Premere OK se si desidera procedere, oppure premere Annulla.</span>*/}
					<span>Si sta per procedere alla sottomissione della valutazione. Una volta sottomessa, la valutazione non sarà più modificabile ed inizierà la fase di audit da parte del team ARB SBRL a socio unico<br/><br/>Premere OK se si desidera procedere, oppure premere Annulla.</span>
				</ModalBody>
				<ModalFooter>

					<ActionButton
						buttonText={"OK"}
						promise={ () => setEvaluationStatus(props.id, true) }
						onProcess={ () => {
							inWaiting( true );
						}}
						onResult={ result => {
							if( !!props.isGenderGap ) {
								window.location.href = PATH_PREFIX + "/" + translate("gender-gap") + "/" + translate("evaluations");
							}
							else {
								window.location.href = PATH_PREFIX + "/sirating-admin/" + translate("evaluations");
							}
						}}
						onError={ error => {
							errorlog("Audit con commento", error );
						}}
					/>

					{' '}

					<Button
						color="link"
						onClick={ clickEvent => {
							props.onClose();
						}}
					>
						Annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;

}
