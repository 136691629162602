import React, {Component} from "react";
import {
    Col, Input,
    Modal,
    ModalBody,
    ModalHeader, Nav, NavItem, NavLink,
    PopoverBody,
    PopoverHeader,
    Row, TabContent,
    Table, TabPane,
    UncontrolledPopover
} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog, showlog} from "../../../utils/dev-utils";
import {isNotEmptyArray, onlyNotNull} from "../../../utils/array-utils";
import {COLOR_POLICY} from "../../../env";
import {QuestionReport_Get} from "../../../services/request-manager";
import "../../../style/collect-report-modal.css";
import {treeSetComplete} from "../../../business-logic/gri";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ActionButton from "../../save-button";


export default class ConfirmCompleteCollectModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

        this.toggle = this.toggle.bind(this);

    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }

    componentDidMount(){}

    render() {

        renderlog("GlossarioCollectModal", this.state, this.props, COLOR_POLICY.validated);

        let context1 = (

            <div>

                <Row style={{flexDirection: "row", justifyContent: "flex-start"}}>

                    <span style={{marginLeft:"1vh"}}>Le informative sono tutte completate, procedere al completamento della raccolta?</span>

                </Row>

                <br/>

                <Row style={{flexDirection: "row", justifyContent: "flex-end"}}>

                    <div style={{marginRight:"1vh"}}>
                        <ActionButton
                            buttonText={ "Conferma" }
                            promise={ () => treeSetComplete(this.props.id)}
                            onResult={ result => {
                                // window.location.href = window.location.href
                                this.props.onClose("completed")
                            }}

                        />
                    </div>
                    <div style={{marginRight:"1vh"}}>
                        <ActionButton
                            buttonText={ "Annulla" }
                            promise={ () => Promise.resolve()  }
                            onResult={ result => {
                                this.props.onClose()
                            }}
                        />
                    </div>

                </Row>


            </div>
        );

        let context2 = (

            <div>

                <Row style={{flexDirection: "row", justifyContent: "flex-start"}}>

                    <span style={{marginLeft:"1vh"}}>Sono presenti informative non completate, procedere ugualmente al completamento della raccolta? </span>

                </Row>

                <br/>

                <Row style={{flexDirection: "row", justifyContent: "flex-end"}}>

                        <div style={{marginRight:"1vh"}}>
                        <ActionButton
                            buttonText={ "Conferma" }
                            promise={ () => treeSetComplete(this.props.id)}
                            onResult={ result => {
                               // window.location.href = window.location.href
                                this.props.onClose("completed")
                            }}

                        />
                        </div>
                    <div style={{marginRight:"1vh"}}>
                        <ActionButton
                            buttonText={ "Annulla" }
                            promise={ () => Promise.resolve()  }
                            onResult={ result => {
                                this.props.onClose()
                            }}
                        />
                    </div>

                </Row>

            </div>

        )


        let content1 = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Completa Raccolta
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context1}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        let content2 = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Completa Raccolta
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context2}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );


        if (this.props.completed == true) {
            return content1;
        } else {
            return content2;
        }


    }

}
