import React from "react";
import "./download-icon-svg-component.css";

export const DownloadIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a"/>
            <g id="b">
                <g>
                    <path className="download-icon-c"
                          d="M62.25,96.27c-1,0-2.01-.38-2.78-1.13l-22.25-21.55c-1.59-1.54-1.63-4.07-.09-5.66,1.54-1.59,4.07-1.63,5.66-.09l19.47,18.85,19.47-18.84c1.59-1.54,4.12-1.5,5.66,.09,1.54,1.59,1.5,4.12-.09,5.66l-22.25,21.54c-.78,.75-1.78,1.13-2.78,1.13Z"/>
                    <path className="download-icon-c"
                          d="M62.35,92.3c-2.21,0-4-1.79-4-3.99l-.2-84.24c0-2.21,1.78-4.01,3.99-4.01h0c2.21,0,4,1.79,4,3.99l.2,84.24c0,2.21-1.78,4.01-3.99,4.01h0Z"/>
                </g>
                <path className="download-icon-c"
                      d="M121.09,125.08H4.07c-2.21,0-4-1.79-4-4V59.26c0-2.21,1.79-4,4-4s4,1.79,4,4v57.81H117.08V59.26c0-2.21,1.79-4,4-4s4,1.79,4,4v61.82c0,2.21-1.79,4-4,4Z"/>
            </g>
        </svg>
    </div>
);
