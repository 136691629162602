// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import CardBody from "reactstrap/es/CardBody";
import {Button, Card, CardHeader, Col, Row} from "reactstrap";
import {PATH_PREFIX, permissions, role} from "../env";
import {loadCollect} from "../business-logic/gri";
import {Link, Redirect} from "react-router-dom";
import {hasPermission, isUserRole} from "../business-logic/auth";
import AddGriCollectModal from "../components/area/gri-admin/add-gri-collect-modal";
import Spinner from "../components/Spinner";
import {errorlog, renderlog, showlog} from "../utils/dev-utils";
import {loadActiveService, loadOrganizzationActiveService} from "../business-logic/active-service";
import {onlyNotNull} from "../utils";
import {getAllServices} from "../services/active-service";
import {translate} from '../components/i18n/translate-function';


export default class WelcomePage extends React.Component {

    state = {
        SRToolServiceNotActive: true
    }

    constructor(props) {
        super( props );
    }

    render() {

        // showlog("render di welcome page");
        // showlog( this.state );


        renderlog("welcomepage", this.state, this.props);
        showlog( ( !!hasPermission( permissions.CREATE_NEW_COLLECT ) && !!!isUserRole( role.PROJECT_MANAGER ) ) );
        showlog( "permesso di creare una raccolta", ( !!hasPermission( permissions.CREATE_NEW_COLLECT ) /*&& !!!isUserRole( role.PROJECT_MANAGER )*/ && !this.state.SRToolServiceNotActive ) );
        showlog( "NON super admin", !!!isUserRole( role.PROJECT_MANAGER ) );



        if( !!this.state.redirect ) {
            delete this.state.collects[0].isPmAuthorized;
            return (
                <Redirect
                    to={{
                        pathname: PATH_PREFIX +"/srtool/" + translate("collect") + "/"+ this.state.collects[0].id,
                        state: this.state.collects[0]
                    }}
                />
            );
        }




        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                       // {label: 'Welcome Page', path: '' + PATH_PREFIX + '/welcome'},
                        {label: 'Home Page', active: true}
                    ]}
                    title={translate("Welcomes")}
                />



                <Row>
                    <Col>
                        <div className="page-title-box">
                            <div className="page-title-right">


                                {
                                    // ( !!hasPermission( permissions.CREATE_NEW_COLLECT ) /*&& !!!isUserRole( role.PROJECT_MANAGER )*/ && !this.state.SRToolServiceNotActive )
                                    //     ? (
                                    //
                                    //         <ActionButton
                                    //             buttonText={
                                    //                 <React.Fragment>
                                    //                     <i className="mdi mdi-playlist-plus"/> Nuova Raccolta
                                    //                 </React.Fragment>
                                    //             }
                                    //             color={"success"}
                                    //             promise={ () => {
                                    //                 return Promise.resolve();
                                    //             }}
                                    //             onResult={ result => {
                                    //                 this.setState({
                                    //                         ...this.state,
                                    //                         newCollectModal: true
                                    //                     }
                                    //                 );
                                    //             }}
                                    //         />
                                    //
                                    //     )
                                    //     : null
                                    null
                                }


                            </div>
                        </div>
                    </Col>
                </Row>

                <Row><Col>&nbsp;</Col></Row>


                <Row>
                    <Col sm="12">
                        <Card className="mb-0" key={"welcome-page"}>
                            <CardHeader style={{height:'500px'}}>
                                <h5 className="m-0">

                                        {/*Benvenuti!*/}



                                        <div align="center" style={{fontWeight:'510',color:'#313a46'}}>

                                            <br/><br/><br/>
                                            <p style={{fontSize:"25px"}}>
                                                {/*Congralutazioni!!<br/>*/}
                                                {/*Il vostro profilo aziendale è completo.<br/>*/}
                                                {/*Siete pronti per iniziare il vostro viaggio verso la sostenibilità con<br/>*/}
                                                {translate("Welcomes")+"!"}
                                            </p>
                                            <br/><br/>
                                            {/*<img src={ HOST +"/assets/Loghi/SiRoad_home.png" } alt="logo" height="100" />*/}
                                            <br/><br/><br/>

                                            {/*{*/}

                                            {/*    ( isUserRole(role.SRTool_ADMIN) )*/}

                                            {/*    &&*/}

                                            {/*    (*/}
                                            {/*        <React.Fragment>*/}
                                            {/*        <p style={{fontSize:"25px"}}>Cliccate qui per la gestione delle raccolte dati</p>*/}
                                            {/*        <br/>*/}
                                            {/*        <Button*/}
                                            {/*            color={"secondary"}*/}
                                            {/*            onClick={ clickEvent => {*/}
                                            {/*                window.location.href = "/srtool/" + translate("collects");*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            {*/}
                                            {/*                (!!this.state.waitForNewCollect)*/}
                                            {/*                    ? (<Spinner color={"success"} />)*/}
                                            {/*                    : (*/}
                                            {/*                        <React.Fragment>*/}
                                            {/*                            <i className="mdi mdi-format-list-bulleted"/> Raccolte Dati*/}
                                            {/*                        </React.Fragment>*/}
                                            {/*                    )*/}
                                            {/*            }*/}
                                            {/*        </Button>*/}
                                            {/*        </React.Fragment>*/}
                                            {/*    )*/}

                                            {/*}*/}
                                            {/*---- rimozione srTool scadenza GRI*/}


                                            {/*{*/}

                                            {/*    ( isUserRole(role.SRTool_MANAGER) )*/}

                                            {/*    &&*/}

                                            {/*    (*/}
                                            {/*        <React.Fragment>*/}
                                            {/*            <p style={{fontSize:"25px"}}>Cliccate qui per la gestione delle raccolte dati</p>*/}
                                            {/*            <br/>*/}
                                            {/*            <Button*/}
                                            {/*                color={"secondary"}*/}
                                            {/*                onClick={ clickEvent => {*/}
                                            {/*                    window.location.href = "/srtool/" + translate("collects");*/}
                                            {/*                }}*/}
                                            {/*            >*/}
                                            {/*                {*/}
                                            {/*                    (!!this.state.waitForNewCollect)*/}
                                            {/*                        ? (<Spinner color={"success"} />)*/}
                                            {/*                        : (*/}
                                            {/*                            <React.Fragment>*/}
                                            {/*                                <i className="mdi mdi-format-list-bulleted"/> Raccolte Dati*/}
                                            {/*                            </React.Fragment>*/}
                                            {/*                        )*/}
                                            {/*                }*/}
                                            {/*            </Button>*/}
                                            {/*        </React.Fragment>*/}
                                            {/*    )*/}

                                            {/*}*/}
                                            {/*---- rimozione srTool scadenza GRI*/}


                                            {

                                            ( isUserRole(role.SR_ADMIN) )

                                            &&

                                            (
                                                <React.Fragment>
                                                <p style={{fontSize:"25px"}}>Cliccate qui per la gestione delle valutazioni</p>
                                                <br/>
                                                <Button
                                                    color={"secondary"}
                                                    onClick={ clickEvent => {
                                                        window.location.href = "/sirating-admin/" + translate("evaluations") ;
                                                    }}
                                                >
                                                    {
                                                        (!!this.state.waitForNewCollect)
                                                            ? (<Spinner color={"success"} />)
                                                            : (
                                                                <React.Fragment>
                                                                    <i className="mdi mdi-format-list-bulleted"/> Elenco valutazioni
                                                                </React.Fragment>
                                                            )
                                                    }
                                                </Button>
                                                </React.Fragment>
                                            )

                                        }

                                            {

                                                ( isUserRole(role.SI_Scoring_Admin) )

                                                &&

                                                (
                                                    <React.Fragment>
                                                        <p style={{fontSize:"25px"}}>Cliccate qui per la gestione delle valutazioni</p>
                                                        <br/>
                                                        <Button
                                                            color={"secondary"}
                                                            onClick={ clickEvent => {
                                                                window.location.href = "/si-scoring/" + translate("evaluations") ;
                                                            }}
                                                        >
                                                            {
                                                                (!!this.state.waitForNewCollect)
                                                                    ? (<Spinner color={"success"} />)
                                                                    : (
                                                                        <React.Fragment>
                                                                            <i className="mdi mdi-format-list-bulleted"/> Elenco valutazioni
                                                                        </React.Fragment>
                                                                    )
                                                            }
                                                        </Button>
                                                    </React.Fragment>
                                                )

                                            }

                                            {

                                                ( isUserRole(role.Parità_Di_Genere_Admin) )

                                                &&

                                                (
                                                    <React.Fragment>
                                                        <p style={{fontSize:"25px"}}>Cliccate qui per la gestione delle valutazioni</p>
                                                        <br/>
                                                        <Button
                                                            color={"secondary"}
                                                            onClick={ clickEvent => {
                                                                window.location.href = "/" + translate("gender-gap") + "/" + translate("evaluations") ;
                                                            }}
                                                        >
                                                            {
                                                                (!!this.state.waitForNewCollect)
                                                                    ? (<Spinner color={"success"} />)
                                                                    : (
                                                                        <React.Fragment>
                                                                            <i className="mdi mdi-format-list-bulleted"/> Elenco valutazioni
                                                                        </React.Fragment>
                                                                    )
                                                            }
                                                        </Button>
                                                    </React.Fragment>
                                                )

                                            }



                                        </div>


                                </h5>
                            </CardHeader>
                            <CardBody>

                                {/*{*/}
                                {/*    (!!this.state.noCollects)*/}
                                {/*        ? (*/}
                                {/*            <span>*/}
                                {/*                <p>Al momento l’organizzazione non ha nessuna raccolta dati attiva.</p>*/}
                                {/*                <p>Per creare una nuova raccolta dati, cliccate sul menu Raccolte e poi clicca sul pulsante «Crea Nuova Raccolta».</p>*/}
                                {/*                <br/>*/}
                                {/*            </span>*/}
                                {/*        )*/}
                                {/*        : null*/}
                                {/*}*/}



                                <span>Necessiti supporto? Clicca <Link to={PATH_PREFIX +"/support"}>qui</Link></span>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                {
                    (!!this.state.newCollectModal)
                        ? (
                            <AddGriCollectModal
                                onClose={ result => {


                                    if( !!result ) {


                                        loadCollect()
                                            .then( collects => {
                                                this.setState({
                                                    redirect: true,
                                                    collects: collects
                                                })
                                            })


                                    }
                                    else {
                                        this.setState({
                                            ...this.state,
                                            newCollectModal: null
                                        })
                                    }



                                }}
                            />
                        )
                        : null
                }

            </React.Fragment>
        );
    }

    componentDidMount() {


        new Promise((success, fail) => {
            if( isUserRole( role.SRTool_ADMIN ) || isUserRole( role.SRTool_MANAGER ) ) {
                success();
            }
            else {
                // controllo servizio attivo
                Promise.all([
                    loadActiveService(),
                    loadOrganizzationActiveService()
                ])
                    .then( serviceList => {

                        let allServices = [];
                        serviceList
                            .map( services => {
                                allServices = [ ...allServices, ...services ];
                            })
                        ;

                        return allServices;
                    })
                    .then( allServices => {


                        getAllServices()
                            .then( allNominalServies => {


                                if(
                                    allServices
                                        .map( service => service.service_id )
                                        .filter( service => (
                                            allNominalServies
                                                .map( ans => ans.id )
                                                .includes( service )
                                        ))
                                        .filter( onlyNotNull )
                                        .some( service => service === 1 )

                                ) {

                                    if( !!hasPermission( permissions.GET_COLLECT ) ) {
                                        success();
                                    }
                                }
                                else {
                                    // Non hail il servizio
                                    this.setState({
                                        ...this.state,
                                        SRToolServiceNotActive: true
                                    });
                                }
                            })

                        ;
                    })
                    .catch(e => {
                        errorlog("Caricamento Servizi Attivi come aziende e organizzazioni", e);
                    })
            }
        })
        .then( () => {
            loadCollect()
                .then( collects => {
                    if( !!collects && collects.length > 0 ) {
                        this.setState({
                            ...this.state,
                            SRToolServiceNotActive: false
                        });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            noCollects: true,
                            SRToolServiceNotActive: false
                        });
                    }
                })
                .catch(e => {
                    errorlog("Caricamento lista Raccolte", e);
                    this.setState({
                        ...this.state,
                        noCollects: true
                    });
                })
            ;
        })


    }

};

