import React from "react";
import "./gender-gap-colored-icon-svg-component.css";

export const GenderGapColoredIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            clickEvent.stopPropagation();
            clickEvent.preventDefault();
        }}
    >
        <svg
            id="Parità_di_genere"
            xmlns="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/1999/xlink"
            viewBox="0 0 129.47 53"
            className={`${props.className}-svg`}
        >
            <defs>
                <clipPath id="clippath">
                    <path className="ggct-1" d="M23.06,.13h-.03V1.66c.01-.95,.03-1.52,.03-1.52Z"/>
                </clipPath>
                <clipPath id="clippath-1">
                    <rect className="ggct-1" x="18.03" y="-4.85" width="10.03" height="11.51"/>
                </clipPath>
                <linearGradient id="Sfumatura_senza_nome_23" x1="29.61" y1="26.57" x2="52.71" y2="26.57"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#004980"/>
                    <stop offset="1" stop-color="#e5007e"/>
                </linearGradient>
                <clipPath id="clippath-3">
                    <path className="ggct-1" d="M23.03,52.99h.03s-.02-.56-.03-1.51v1.51Z"/>
                </clipPath>
                <clipPath id="clippath-4">
                    <rect className="ggct-1" x="18.03" y="46.47" width="10.03" height="11.53"/>
                </clipPath>
                <linearGradient id="Sfumatura_senza_nome_23-2" x1="-.06" x2="23.03"
                                href="#Sfumatura_senza_nome_23"/>
                <linearGradient id="Sfumatura_senza_nome_14" x1="17.26" y1="22.02" x2="35.39" y2="22.02"
                                gradientUnits="userSpaceOnUse">
                    <stop offset=".07" stop-color="#004980"/>
                    <stop offset="1" stop-color="#e5007e"/>
                </linearGradient>
                <linearGradient id="Sfumatura_senza_nome_14-2" y1="32.42" y2="32.42"
                                href="#Sfumatura_senza_nome_14"/>
            </defs>
            <g>
                <g className="ggct-2">
                    <g className="ggct-5">
                        <image width="22" height="25" transform="translate(17.88 -5.28) scale(.48)"
                               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAZCAYAAADe1WXtAAAACXBIWXMAABcRAAAXEQHKJvM/AAADbElEQVRIibWUP29URxTFz52Zt2sTCXCgCaQiEgUNAQxBCMQiwfeIhGRR0CUVDabiE9Cko6ZHIIRsS05BQeRPQEWFEDIyWXvfe3Mvun927QW3jDSet573fnPumTMDOay1q8LtSLiDcPUuHM89hLuRSF079FNt89B2TeonCO9AeBfCEzi4PwBsY07f2RkdCiUjA+APtyB1HVQAFIAGAFJ0iq5vVn0ZkB5A72M6vgYajjBtBuWPj8Dbj4BGDEoNgAFA30IVFlCFSxfgjlDO8Dy03cqgRkxhGgqQQ22M1iRGDrgByZRqp+YWym+v7ZXSbt2GjAlQdRngDqChmFqhgIrPTRXaGh1B2gDrc78BPrmBdOwmaPyiEStTSx8IKItBkcQWsrlywE8BROFtgrTkvycKJaSjNzFYfoUiXxJEYTpR2D2duDKzJIVKClhHQCWIdoM6UL3ttzcxWAYKf0m2Gi0IiBSsilndBjU82zz3ksATAjhUEtn/pE2zefOUd7KpoD0xkJZNAwJlBkoAoZYgPEwQ7V0K0x0kewnChMmbxyj8qbh/QqBhjecEEIMogRaqm6kW1ALpM1CzqVT4VF2EyRYr/H/2TbCegOw/CALpBemI5qWCx8XN1Xc0w6owh5/qeyX7Vjet8HaBniny1LpXBlS/JPIpngItt9BsE4UTSCtL5BWqv5OEUreb/ahIrMieQ42XjJOVTIPq3i+yKyi+T2aLJUT8JLda/udGLxV7wUKs0D5i07tHujn6UVqokB2KdMBHVZ0jeknQnL2GwuNsu+YXBZl8A9mlEVGpHju2g8GuSjMco0M9ioNzVzVS6qmrM3g3HZP7ioDGI/UaNT0kfkeoLfZMMWc53ctengqdJAPItPya3CfxuMw+LHEwVF1Ps/KP373n0OGFP7D771svPYJtwM4z6JdtjArNCVQZ0mU/ID1Dsi8yvHRl/+p7d+qSQ0KdWVH9xEzjZnurR5dclUIsTmYFY+nvFSz9tWJQq/uXZ/84qM0WCanZoNb7DO7nf0tU4skgLFy5PAPOlOrD7uZ/eH/nvgXaFJvKhFnd+pdkX62WnBlHRr/j9PMnONh8hwAsXr+IX18+weKNi56Gb4B2rnQh9VfnmPDzg7vfAeeUHmzjjS2M17fwcfXpHFRXUWU/jc7jxMM/v4P9uAbgK43ldYnXFg5BAAAAAElFTkSuQmCC"/>
                    </g>
                </g>
                <path className="ggct-11"
                      d="M45.44,8.21c-.86-.91-1.78-1.77-2.77-2.55C38.99,2.72,34.51,.75,29.61,.13h0s.05,1.81,.05,4.29c0,1.33,.99,2.42,1.9,3.14,.54,.42,1.15,.73,1.78,.98,.77,.31,1.52,.67,2.24,1.07,5.9,3.3,9.9,9.65,9.9,16.94s-4,13.64-9.9,16.94c-.72,.4-1.47,.76-2.25,1.07-.63,.25-1.25,.56-1.78,.98-.92,.73-1.9,1.82-1.9,3.14,0,2.48-.05,4.29-.05,4.29,4.9-.62,9.37-2.59,13.06-5.53,.98-.78,1.9-1.64,2.77-2.55,4.5-4.78,7.27-11.24,7.27-18.35s-2.77-13.58-7.27-18.35Z"/>
                <g className="ggct-7">
                    <g className="ggct-6">
                        <image width="22" height="25" transform="translate(17.88 46.08) scale(.48)"
                               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAXCAYAAADk3wSdAAAACXBIWXMAABcRAAAXEQHKJvM/AAADaUlEQVQ4jWVVsYocRxB9Vd2zs7un4zgJBPZ9gAwKDJYCC2FxcqjI3+DEYPwFxkKHcG5HNjYY7NiRI4e6S4TAAilTJnBwAoGQkkPa2ZmuMlXVM7tn9dJ0z07369fvve4hVVXU8vr73/Dq/u/QQoASVKm+GVsFkQKkoKRYHn6MS3e/xOKzT7BdJtDTO1/j7fFTqBBQONoJdBtbQawAiwNTFhz8/RMWNzfADvrii6/w7uQJdDAwBgTQwsFWAjgYIlg6aABSY+CCD//6BfMb13wsrx49Rvf4H1CjoJkNKjE4ReWmgK1vINbmWsdxWYCkePPjzxPT9M1qOCovTn1l8PuMiBDb9K0WcLbFC7hRcFui3wrk5SkWn15HPjgA/Xv1qpoxOhAw1K2bDH1o6yrqZjHK1aiZALZ1I9KIk5rfuI7Lv/6BTIsBVKrT1vbkpKVLsUDPIHtnQMbSAGwj8+I749qagf2zh/4u807xCZAA1I69n9oCXbOzhvqc0Hxegq0BuaYhzyhfgO720RslaMkRXJKOXVNvDShVCZaD6TGZaymx1mQ6+/MH5LQ3OAu1XUlkUTuTAm6Urskl8InGclbPSjIJLCGIw5Krqa0g814frld2vpyDiwNPz8ZklcEXzJTIrg7J2ZP9UizGcwcdggVLsDW8mkdwAaUCagZon6CrBmgMjYFE0FU1rdWQrsqQ0/7gxvixq7o5INWQtwUoYvsAUYlUgN1E7BXXyyTiHDKYPJl3S+jjQBENA7aT5DmcjfQF6Mkb7U0S2RzldWVpPrqmuxFcc9LFHrPYRI64xSSLzdLOZhboioGimxutC51n17618Es4mutRNDCLSlsvjhQxRoYbp0sNA+cSsbOTZxlfAjDGnlNz08+zAWFi7eAzeGTGohYz0ch0UaCrzwNBVoR85W4koPnoO/COgqwuBbyrIKtLyyFAi626g/h/qeCFgi/UebXP+7c29+nw/HNodwwyZr59a1EvkPDOsuzXeR/Paq2lcYAbyPv3wJfvbUD17Qnk5W2XwQHHbUd6omo9cRoyGOAEyodIHzw4f/N7kLpjyJvbDhgR+x+olQrqhlWmoEPwxQ1gvSu3yvqBytmhyhlU3kFlDZUBKqVW69t/9s7HHJ2bPpZzX9OpyDGgJ3G54Gj7YwqyZ5uRQr/3CoD/AMOZGKDmcKbuAAAAAElFTkSuQmCC"/>
                    </g>
                </g>
                <path className="ggct-9"
                      d="M23.01,48.71c0-1.05-.62-1.95-1.33-2.64-.65-.63-1.43-1.11-2.27-1.44-.85-.33-1.68-.73-2.47-1.17-5.85-3.32-9.8-9.63-9.8-16.89s3.95-13.57,9.8-16.89c.79-.45,1.61-.84,2.47-1.17,.84-.33,1.62-.81,2.27-1.44,.71-.69,1.33-1.59,1.33-2.64s0-2.04,.02-2.77V.15h0c-4.92,.61-9.42,2.6-13.12,5.57-.94,.75-1.83,1.57-2.66,2.45C2.73,12.94-.06,19.43-.06,26.57s2.79,13.62,7.32,18.41c.83,.88,1.72,1.69,2.66,2.45,3.7,2.97,8.2,4.96,13.12,5.58h0v-1.52c-.01-.73-.02-1.69-.02-2.77Z"/>
                <path className="ggct-10"
                      d="M35.37,25.27c-.07-.17-.42-.98-.92-2.16-.22-.52-.46-1.11-.72-1.76-.93-2.3-4.75-2.51-4.75-2.51,0,0-4.45-.04-6.01-.1-.02,0-.05,0-.08,0-.03,0-.06,0-.08,0h-5.54s-.02,0-.02,0c0,0,0,.02,.02,.04,.07,.17,.42,.98,.92,2.16,.22,.52,.46,1.11,.72,1.76,.93,2.3,4.75,2.51,4.75,2.51,0,0,4.45,.04,6.01,.1,.02,0,.05,0,.08,0,.03,0,.06,0,.08,0h5.54s.02,0,.02,0c0,0,0-.02-.02-.04Z"/>
                <path className="ggct-8"
                      d="M17.27,29.16c.07,.17,.42,.98,.92,2.16,.22,.52,.46,1.11,.72,1.76,.93,2.3,4.75,2.51,4.75,2.51,0,0,4.45,.04,6.01,.1,.02,0,.05,0,.08,0,.03,0,.06,0,.08,0h5.54s.02,0,.02,0c0,0,0-.02-.02-.04-.07-.17-.42-.98-.92-2.16-.22-.52-.46-1.11-.72-1.76-.93-2.3-4.75-2.51-4.75-2.51,0,0-4.45-.04-6.01-.1-.02,0-.05,0-.08,0-.03,0-.06,0-.08,0h-5.54s-.02,0-.02,0c0,0,0,.02,.02,.04Z"/>
            </g>
            <g>
                <path className="ggct-4"
                      d="M65.21,25.49v-9.35h3.83c.4,0,.77,.08,1.1,.25,.33,.17,.62,.39,.87,.68,.24,.29,.43,.6,.57,.95,.14,.35,.21,.7,.21,1.06,0,.51-.11,.99-.33,1.44s-.53,.82-.93,1.1c-.4,.28-.87,.42-1.41,.42h-3v3.45h-.91Zm.91-4.27h2.96c.36,0,.68-.1,.95-.3s.48-.47,.63-.79c.15-.32,.22-.68,.22-1.05s-.09-.74-.26-1.07c-.17-.32-.4-.58-.68-.77-.28-.19-.59-.28-.93-.28h-2.88v4.27Z"/>
                <path className="ggct-4"
                      d="M75.56,16.14h.77l3.85,9.35h-.97l-1.16-2.92h-4.23l-1.16,2.92h-.98l3.87-9.35Zm2.21,5.7l-1.83-4.62-1.83,4.62h3.66Z"/>
                <path className="ggct-4"
                      d="M81.51,25.49v-9.35h3.91c.4,0,.77,.08,1.1,.25,.33,.17,.62,.39,.87,.68,.25,.29,.44,.6,.57,.95,.13,.35,.2,.7,.2,1.06,0,.45-.08,.87-.25,1.26-.17,.4-.41,.73-.72,1-.31,.27-.67,.45-1.09,.54l2.27,3.61h-1.03l-2.18-3.45h-2.74v3.45h-.91Zm.91-4.27h3.03c.37,0,.68-.1,.95-.3s.48-.47,.63-.8c.15-.33,.22-.68,.22-1.05s-.08-.73-.25-1.05c-.17-.32-.4-.58-.68-.78-.28-.2-.6-.3-.95-.3h-2.95v4.27Z"/>
                <path className="ggct-4" d="M90.02,25.49v-9.35h.91v9.35h-.91Z"/>
                <path className="ggct-4" d="M99.83,16.96h-3.3v8.54h-.91v-8.54h-3.3v-.82h7.51v.82Z"/>
                <path className="ggct-4"
                      d="M103.2,16.14h.77l3.85,9.35h-.97l-1.16-2.92h-4.23l-1.16,2.92h-.98l3.87-9.35Zm2.21,5.7l-1.83-4.62-1.83,4.62h3.66Zm-3.04-8.43h.93l.78,1.23-.57,.22-1.13-1.45Z"/>
                <path className="ggct-4"
                      d="M64.58,33.73c0-.65,.13-1.24,.4-1.78s.63-.97,1.1-1.29c.47-.32,1-.49,1.6-.49,.56,0,1.07,.15,1.52,.46,.45,.3,.8,.67,1.05,1.1v-4.23h.89v8.54c0,.12,.03,.21,.08,.27,.05,.06,.13,.09,.24,.1v.71c-.17,.03-.31,.04-.4,.03-.18,0-.34-.08-.48-.22-.14-.14-.21-.29-.21-.46v-.67c-.27,.45-.63,.8-1.1,1.07-.46,.26-.94,.4-1.44,.4-.47,0-.9-.1-1.3-.29-.4-.19-.74-.45-1.03-.79-.29-.33-.52-.71-.68-1.13-.16-.42-.24-.86-.24-1.32Zm5.67,1.05v-2.11c-.11-.31-.3-.6-.55-.86-.25-.26-.54-.46-.85-.62s-.63-.23-.95-.23c-.37,0-.7,.08-.99,.23-.3,.15-.55,.36-.76,.62s-.38,.56-.49,.89c-.11,.33-.17,.67-.17,1.02,0,.37,.06,.71,.19,1.05s.31,.63,.54,.88c.23,.26,.5,.46,.81,.59,.3,.14,.63,.21,.99,.21,.23,0,.46-.04,.7-.13,.24-.09,.48-.21,.7-.36,.22-.15,.41-.33,.55-.54,.15-.21,.24-.43,.27-.65Z"/>
                <path className="ggct-4" d="M73.14,28.83v-1.32h.89v1.32h-.89Zm0,8.3v-6.86h.89v6.86h-.89Z"/>
                <path className="ggct-4"
                      d="M83.21,37.19c-.62,0-1.19-.13-1.72-.4s-.99-.63-1.38-1.09c-.39-.46-.69-.97-.91-1.53-.21-.56-.32-1.14-.32-1.74,0-.63,.11-1.23,.32-1.8s.51-1.07,.89-1.51c.38-.44,.83-.79,1.36-1.04,.52-.25,1.09-.38,1.71-.38,.91,0,1.66,.19,2.23,.57,.57,.38,1.01,.87,1.3,1.48l-.7,.47c-.3-.58-.69-1.01-1.19-1.28-.5-.27-1.05-.41-1.66-.41-.51,0-.98,.11-1.39,.33-.41,.22-.77,.51-1.06,.88-.29,.37-.52,.79-.67,1.25-.15,.46-.23,.95-.23,1.45,0,.53,.09,1.03,.26,1.5,.17,.47,.42,.89,.74,1.25s.69,.65,1.12,.86c.42,.21,.88,.31,1.36,.31,.56,0,1.1-.14,1.6-.43,.5-.29,.99-.73,1.45-1.32v.97c-.88,1.07-1.92,1.61-3.12,1.61Zm2.97-3.95h-2.06v-.71h2.83v4.6h-.77v-3.89Z"/>
                <path className="ggct-4" d="M95.05,36.31v.82h-6.22v-9.35h6.1v.82h-5.19v3.37h4.53v.78h-4.53v3.57h5.31Z"/>
                <path className="ggct-4" d="M97.69,29.47v7.65h-.91v-9.35h.76l6.08,7.8v-7.79h.91v9.34h-.85l-5.99-7.65Z"/>
                <path className="ggct-4" d="M113.07,36.31v.82h-6.22v-9.35h6.1v.82h-5.19v3.37h4.53v.78h-4.53v3.57h5.31Z"/>
                <path className="ggct-4"
                      d="M114.79,37.13v-9.35h3.91c.4,0,.77,.08,1.1,.25s.62,.39,.87,.68,.44,.6,.57,.95,.2,.7,.2,1.06c0,.45-.08,.87-.25,1.26-.17,.39-.41,.73-.72,1s-.67,.45-1.09,.54l2.27,3.61h-1.03l-2.18-3.45h-2.74v3.45h-.91Zm.91-4.27h3.03c.37,0,.68-.1,.95-.3,.27-.2,.48-.47,.63-.8,.15-.33,.22-.68,.22-1.05s-.08-.73-.25-1.05c-.17-.32-.4-.58-.68-.78-.28-.2-.6-.3-.95-.3h-2.95v4.27Z"/>
                <path className="ggct-4" d="M129.52,36.31v.82h-6.22v-9.35h6.1v.82h-5.19v3.37h4.53v.78h-4.53v3.57h5.31Z"/>
            </g>
            <path className="ggct-3" d="M79.07,26.56s0-.02-.01-.02v.05s.01-.03,.01-.03Z"/>
        </svg>
    </div>
);
