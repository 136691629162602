import {showlog} from "../utils/dev-utils";
import {getLastElementOr, isNotEmptyArray, onlyNotNull, sortById} from "../utils/array-utils";
import {GENDER_GAP_VOID_TREE} from "../conf/gendergap";
import {cloneObject} from "../utils";

export const convertSAConfigurationQuestionTableToTree = list => {

	/*
	data
	 │
	 ├── tab
	 │     │
	 │     ├── theme
	 │     │     │
	 │     │     ├── subTheme
	 │     │     │     │
	 │     │     │     ├── questions
	 │     │     │     │
	 │     │     │     └── questions
	 │     │     │
	 │     │     ├── subTheme
	 │     │     │
	 │     │     ├── questions
	 │     │     │
	 │     │     └── questions
	 │     │
	 │     └── theme
	 │
	 └── tab



	macroarea_id
	macroarea_name
	question_id
	question_name
	subthematic_id
	subthematic_name
	thematic_id
	thematic_name



	 */



	let tree = { tabs: [] };
	return (
		// start
		new Promise((success, fail) => {
			success();
		})

			// macroarea --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {

				tree.tabs =
					[
						...new Map(
							list
								.map(item => [
									item.macroarea_id,
									{
										id: item.macroarea_id,
										name: item.macroarea_name,
										themes: [],
										open: false,
										path: [item.macroarea_id]
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
						.sort( sortById )
					;


				return tree;

			})


			// theme --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {

				// lista tematiche
				let themes =
					[
						... new Map(
							list
								.map(item => [
									item.thematic_id,
									{
										id: item.thematic_id,
										name: item.thematic_name,
										code: item.thematic_code,
										questions: [],
										subThemes: [],
										open: false
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
					;



				themes
					.map(th => {

						let tabReferiment =

							list

								// elemento della lista corrispondente alla tematica corrente
								.filter(li => li.thematic_id === th.id)

								// elemento unico
								.reduce(getLastElementOr, null)

								// riferimento al padre
								.macroarea_id



							;


						list
							.filter( row => row.thematic_id === th.id && !!!row.subthematic_id )
							.forEach(seed => {

								// if (!!!seed.subthematic_id) {
									th.questions.push({
										id: seed.question_id,
										name: seed.question_name,
										enabled: !!seed.enabled,
										isSectorQuestion: !!seed.isSectorQuestion,
										sectorName: seed.question_sectorName,
										companySize: seed.question_sector_company_size,
										ordering: seed.ordering,
										questionsStructureId: seed.questions_structure_id,
										fatherQuestionId: seed.srMasterquestionId,
										code: seed.question_code,
										hasNote: seed.hasNote,
										hasAdminNote: seed.hasInternalNote
									})
								// }

								th.materiality = seed.materiality_level;


							})
							;



						th.path = [
							tree.tabs
								.filter(t => t.id === tabReferiment)
								.reduce(getLastElementOr, null)
								.path
								.reduce(getLastElementOr, null),
							th.id
						]


						// attacco del figlio
						tree.tabs
							.filter(t => t.id === tabReferiment)
							.reduce((final, cursor) => cursor, null)
							.themes.push(th);


					})
					;




				return themes;

			})


			// subTheme --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {


				// lista sotto-tematiche
				let subThemes =
					[
						... new Map(
							list
								.map(item => [
									item.subthematic_id,
									{
										id: item.subthematic_id,
										name: item.subthematic_name,
										code: item.subthematic_code,
										questions: [],
										// child: [],
										open: false
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
					;


				subThemes
					.map(sth => {

						let themeReferimentId =

							list

								// elemento della lista corrispondente alla tematica corrente
								.filter(li => li.subthematic_id === sth.id)

								// elemento unico
								.reduce((final, cursor) => cursor, null)

								// riferimento al padre
								.thematic_id



							;



						list
							.filter(row => row.subthematic_id === sth.id)
							.forEach(seed => {

								sth.questions.push({
									id: seed.question_id,
									name: seed.question_name,
									enabled: !!seed.enabled,
									isSectorQuestion: !!seed.isSectorQuestion,
									sectorName: seed.question_sectorName,
									companySize: seed.question_sector_company_size,
									ordering: seed.ordering,
									questionsStructureId: seed.questions_structure_id,
									fatherQuestionId: seed.srMasterquestionId,
									code: seed.question_code,
									hasNote: seed.hasNote,
									hasAdminNote: seed.hasInternalNote
								});

								// materialityLevel
								sth.materiality = seed.materiality_level;

							})
							;


						let themeReferiment = null;
						tree.tabs
							.forEach(t => {
								t.themes
									.forEach(_th => {
										if (_th.id === themeReferimentId) themeReferiment = _th;
									})
							})
							;


						// path
						sth.path = [
							...themeReferiment.path,
							sth.id
						]


						// attacco del figlio
						themeReferiment.subThemes.push(sth);



					})
					;



				return subThemes;

			})





			// finish --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {


				// showlog("ultimo passo");
				// showlog( tree );
				return tree;

			})

			.catch(e => Promise.reject(e))

	);

};


/*
const setSubQuestion = (subQuestion) => {
	let fatherQuestion = getQuestionTreeNodeByPath(tree, getQuestionPath(tree, subQuestion.fatherQuestionId));
	fatherQuestion.child = subQuestion;
	debugger;
	subQuestion.path = getQuestionPath(tree, subQuestion.id);
	subQuestion.path =
		[
			...subQuestion.path.filter((p, i) => i < subQuestion.path.length - 1),
			fatherQuestion.id,
			subQuestion.path[subQuestion.path.length - 1]
		]
	;
	return subQuestion;
}
*/



export const convertSIRatingQuestionTableToTree = list => {

	let tree = {
		tabs: [
			{
				id: 1,
				macroarea_id: 1,
				name: "Environmental",
				themes: [],
				open: true,
				path: [1]
			},
			{
				id: 2,
				macroarea_id: 2,
				name: "Social",
				themes: [],
				open: true,
				path: [2]
			},
			{
				id: 3,
				macroarea_id: 3,
				name: "Governance",
				themes: [],
				open: true,
				path: [3]
			}
		]
	};
	return (
		// start
		new Promise((success, fail) => {
			success();
		})

			/*
			// macroarea --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {

				tree.tabs =
					[
						...tree.tabs,
						...new Map(
							list
								.map(item => [
									item.macroarea_id,
									{
										id: item.macroarea_id,
										name: item.macroarea_name,
										themes: [],
										open: false,
										path: [item.macroarea_id]
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter( x => !!x.id )
						.sort( sortById )
					;


				return tree;

			})
			*/

			// theme --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {

				// lista tematiche
				let themes =
					[
						... new Map(
							list
								.map(item => [
									item.thematic_id,
									{
										id: item.thematic_id,
										name: item.thematic_name,
										questions: [],
										subThemes: [],
										open: false,
										code: item.thematic_code,
										materiality: item.materiality_level
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
					;



				themes
					.map(th => {

						let tabReferiment =

							list

								// elemento della lista corrispondente alla tematica corrente
								.filter(li => li.thematic_id === th.id)

								// elemento unico
								.reduce((final, cursor) => cursor, null)

								// riferimento al padre // questo campo viene dalla lista grezza arrivata dalla risposta della chiamata al server
								.macroarea_id



							;



						th.path = [
							tree.tabs
								.filter(t => t.id === tabReferiment)
								.reduce((final, cursor) => cursor, null)
								.path
								.reduce((final, cursor) => cursor, null),
							th.id
						]


						list
							.filter(row => row.thematic_id === th.id)
							.forEach(seed => {

								if (!!!seed.subthematic_id) {

									th.questions.push({
										id: seed.question_id,
										name: seed.question_name,
										enabled: !!seed.enabled,
										isSectorQuestion: !!seed.isSectorQuestion,
										sectorName: seed.question_sectorName,
										companySize: seed.question_sector_company_size,
										ordering: seed.ordering,
										choiches: seed.choiches,
										isMultipleAnswer: seed.isMultipleAnswer,
										questionsStructureId: seed.questions_structure_id,
										attachments: seed.attachments,
										fatherQuestionId: seed.srMasterquestionId,
                                        isCompleted: seed.question_isCompleted,
                                        status: seed.question_state,
										ncMessage: seed.question_state_comment,
										expire: seed.question_state_expire,
										sdg: seed.sdgs || [],
										path: [...th.path, seed.question_id],
										code: seed.question_code,
										attachmentsGuidelines: seed.attachmentsGuidelines,
										// questionGuidelines: seed.questionGuidelines,
										questionGuidelines: seed.guidelines,
										scoreMax: seed.scoreMax,
										open: true,
										hasNote: seed.hasNote,
										hasAdminNote: seed.hasInternalNote,
										seed: seed,
										isOpenAnswer: seed.isOpenQuestion,
										answers: seed.answers,
										scoreOpenQuestion: seed.scoreOpenQuestion,
										openAnswers: seed.subquestions
									})


									if( !!seed.question_state_expire ) {
										showlog(
											"%c  CONTROLLO SCANDENZA #1  %c  nei dati  ",
											"background: #a00; color: #fff; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold; font-size: 2rem;",
											"background: #f00; color: #fff; border-radius:0px 3px 3px 0px; padding:3px; font-size: 2rem;"
										);
										showlog("%c"+ seed.question_id, "color:#f00;");
										showlog(seed.question_name);

									}

								}

							})
							;





						// attacco del figlio
						tree.tabs
							.filter(t => t.id === tabReferiment)
							.reduce((final, cursor) => cursor, null)
							.themes.push(th);


					})
					;




				return themes;

			})


			// subTheme --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {


				// lista sotto-tematiche
				let subThemes =
					[
						... new Map(
							list
								.map(item => [
									item.subthematic_id,
									{
										id: item.subthematic_id,
										name: item.subthematic_name,
										questions: [],
										// child: [],
										open: false,
										code: item.subthematic_code,
										materiality: item.materiality_level
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
					;


				subThemes
					.map(sth => {

						let themeReferimentId =

							list

								// elemento della lista corrispondente alla tematica corrente
								.filter(li => li.subthematic_id === sth.id)

								// elemento unico
								.reduce((final, cursor) => cursor, null)

								// riferimento al padre
								.thematic_id



							;






						let themeReferiment = null;
						tree.tabs
							.forEach(t => {
								t.themes
									.forEach(_th => {
										if (_th.id === themeReferimentId) themeReferiment = _th;
									})
							})
							;


						// path
						sth.path = [
							...themeReferiment.path,
							sth.id
						]



						list
							.filter(row => row.subthematic_id === sth.id)
							.forEach(seed => {

								sth.questions.push({
									id: seed.question_id,
									name: seed.question_name,
									enabled: !!seed.enabled,
									isSectorQuestion: !!seed.isSectorQuestion,
									sectorName: seed.question_sectorName,
									companySize: seed.question_sector_company_size,
									ordering: seed.ordering,
									choiches: seed.choiches,
									isMultipleAnswer: seed.isMultipleAnswer,
									questionsStructureId: seed.questions_structure_id,
									attachments: seed.attachments,
									fatherQuestionId: seed.srMasterquestionId,
									isCompleted: seed.question_isCompleted,
									status: seed.question_state,
									ncMessage: seed.question_state_comment,
									expire: seed.question_state_expire,
									sdg: seed.sdgs || [],
									path: [...sth.path, seed.question_id],
									code: seed.question_code,
									attachmentsGuidelines: seed.attachmentsGuidelines,
									// questionGuidelines: seed.questionGuidelines,
									questionGuidelines: seed.guidelines,
									scoreMax: seed.scoreMax,
									open: true,
									hasNote: seed.hasNote,
									hasAdminNote: seed.hasInternalNote,
									isOpenAnswer: seed.isOpenQuestion,
									answers: seed.answers,
									scoreOpenQuestion: seed.scoreOpenQuestion
								});


								if( !!seed.question_state_expire ) {
									showlog(
										"%c  CONTROLLO SCANDENZA #2  %c  nei dati  ",
										"background: #a00; color: #fff; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold; font-size: 2rem;",
										"background: #f00; color: #fff; border-radius:0px 3px 3px 0px; padding:3px; font-size: 2rem;"
									);
									showlog("%c"+ seed.question_id, "color:#f00;");
									showlog(seed.question_name);

								}

							})
						;


						// attacco del figlio
						themeReferiment.subThemes.push(sth);
						delete themeReferiment.materiality;



					})
					;



				return subThemes;

			})



			// subQuestion --------------------------------------------------------------------------------------------------------------------------
			.then( () => {

				// scansione della domanda
				tree.tabs
					.map( tab => {
						tab.themes
							.map( th => {
								if( !!isNotEmptyArray( th.questions ) ) {
									th.questions
										.filter( question => !!question.fatherQuestionId )
										.map( subQuestion => {
											let fatherQuestion = getQuestionTreeNodeByPath( tree, getQuestionPath( tree, subQuestion.fatherQuestionId ) );
											fatherQuestion.child = subQuestion;
											subQuestion.path = getQuestionPath( tree, subQuestion.id );
											subQuestion.path =
												[
													...subQuestion.path.filter((p, i) => i < subQuestion.path.length -1),
													fatherQuestion.id,
													subQuestion.path[ subQuestion.path.length -1 ]
												]
											;

											return subQuestion;
										})
										.map( subQuestion => null )
									;

									// eliminazione della sotto-domanda dalla lista delle domande
									th.questions =
										th.questions
											.filter( question => !!!question.fatherQuestionId )
									;

								}
								else {
									th.subThemes
										.map( sth => {
											if( !!isNotEmptyArray( sth.questions ) ) {
												sth.questions
													.filter( question => !!question.fatherQuestionId )
													.map( subQuestion => {
														let fatherQuestion = getQuestionTreeNodeByPath( tree, getQuestionPath( tree, subQuestion.fatherQuestionId ) );
														fatherQuestion.child = subQuestion;
														// debugger;
														subQuestion.path = getQuestionPath( tree, subQuestion.id );
														subQuestion.path =
															[
																...subQuestion.path.filter((p, i) => i < subQuestion.path.length -1),
																fatherQuestion.id,
																subQuestion.path[ subQuestion.path.length -1 ]
															]
														;
														return subQuestion;
													})
													.map( subQuestion => null )

												// eliminazione della sotto-domanda dalla lista delle domande
												sth.questions =
													sth.questions
														.filter( question => !!!question.fatherQuestionId )
												;

											}
										})
									;
								}
							})
					})
				;

			})


			// finish --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {


				showlog("ultimo passo alla creazione della struttura dati a d albero");
				showlog( tree );
				return tree;

			})

			.catch(e => Promise.reject(e))

	);

};



export const convertGenderGapQuestionTableToTree = list => {
	let tree = cloneObject( GENDER_GAP_VOID_TREE );
	return (
		// start
		new Promise((success, fail) => {
			success();
		})

			/*
			// macroarea --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {

				tree.tabs =
					[
						...tree.tabs,
						...new Map(
							list
								.map(item => [
									item.macroarea_id,
									{
										id: item.macroarea_id,
										name: item.macroarea_name,
										themes: [],
										open: false,
										path: [item.macroarea_id]
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter( x => !!x.id )
						.sort( sortById )
					;


				return tree;

			})
			*/

			// theme --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {

				// lista tematiche
				let themes =
					[
						... new Map(
							list
								.map(item => [
									item.thematic_id,
									{
										id: item.thematic_id,
										name: item.thematic_name,
										questions: [],
										subThemes: [],
										open: false,
										code: item.thematic_code,
										materiality: item.materiality_level
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
				;



				themes
					.map(th => {

						let tabReferiment =

							list

								// elemento della lista corrispondente alla tematica corrente
								.filter(li => li.thematic_id === th.id)

								// elemento unico
								.reduce((final, cursor) => cursor, null)

								// riferimento al padre // questo campo viene dalla lista grezza arrivata dalla risposta della chiamata al server
								.macroarea_id



						;


						th.path = [
							tree.tabs
								.filter(t => t.id === tabReferiment)
								.reduce((final, cursor) => cursor, null)
								.path
								.reduce((final, cursor) => cursor, null),
							th.id
						]


						list
							.filter(row => row.thematic_id === th.id)
							.forEach(seed => {

								if (!!!seed.subthematic_id) {

									th.questions.push({
										id: seed.question_id,
										name: seed.question_name,
										enabled: !!seed.enabled,
										isSectorQuestion: !!seed.isSectorQuestion,
										sectorName: seed.question_sectorName,
										companySize: seed.question_sector_company_size,
										ordering: seed.ordering,
										choiches: seed.choiches,
										isMultipleAnswer: seed.isMultipleAnswer,
										questionsStructureId: seed.questions_structure_id,
										attachments: seed.attachments,
										fatherQuestionId: seed.srMasterquestionId,
										isCompleted: seed.question_isCompleted,
										status: seed.question_state,
										ncMessage: seed.question_state_comment,
										expire: seed.question_state_expire,
										sdg: seed.sdgs || [],
										path: [...th.path, seed.question_id],
										code: seed.question_code,
										attachmentsGuidelines: seed.attachmentsGuidelines,
										// questionGuidelines: seed.questionGuidelines,
										questionGuidelines: seed.guidelines,
										scoreMax: seed.scoreMax,
										open: true,
										hasNote: seed.hasNote,
										hasAdminNote: seed.hasInternalNote,
										themes: {
											id: seed.thematic_id,
											code: seed.thematic_code,
											name: seed.thematic_name
										},
										subThemes: {
											id: seed.subthematic_id,
											name: seed.subthematic_name,
											code: seed.subthematic_code
										},
										isOpenAnswer: seed.isOpenQuestion,
										openAnswers: seed.subquestions,
										answers: seed.answers,
										scoreOpenQuestion: seed.scoreOpenQuestion,
										preventiveQuestions: seed.preventiveQuestions
									})


									if( !!seed.question_state_expire ) {
										showlog(
											"%c  CONTROLLO SCANDENZA #1  %c  nei dati  ",
											"background: #a00; color: #fff; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold; font-size: 2rem;",
											"background: #f00; color: #fff; border-radius:0px 3px 3px 0px; padding:3px; font-size: 2rem;"
										);
										showlog("%c"+ seed.question_id, "color:#f00;");
										showlog(seed.question_name);

									}

								}

							})
						;





						// attacco del figlio
						tree.tabs
							.filter(t => t.id === tabReferiment)
							.reduce((final, cursor) => cursor, null)
							.themes.push(th);


					})
				;




				return themes;

			})


			// subTheme --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {


				// lista sotto-tematiche
				let subThemes =
					[
						... new Map(
							list
								.map(item => [
									item.subthematic_id,
									{
										id: item.subthematic_id,
										name: item.subthematic_name,
										questions: [],
										// child: [],
										open: false,
										code: item.subthematic_code,
										materiality: item.materiality_level
									}
								])
						).values()
					]
						.filter( onlyNotNull )
						.filter(x => !!x.id)
				;


				subThemes
					.map(sth => {

						let themeReferimentId =

							list

								// elemento della lista corrispondente alla tematica corrente
								.filter(li => li.subthematic_id === sth.id)

								// elemento unico
								.reduce((final, cursor) => cursor, null)

								// riferimento al padre
								.thematic_id



						;






						let themeReferiment = null;
						tree.tabs
							.forEach(t => {
								t.themes
									.forEach(_th => {
										if (_th.id === themeReferimentId) themeReferiment = _th;
									})
							})
						;


						// path
						sth.path = [
							...themeReferiment.path,
							sth.id
						]



						list
							.filter(row => row.subthematic_id === sth.id)
							.forEach(seed => {

								sth.questions.push({
									id: seed.question_id,
									name: seed.question_name,
									enabled: !!seed.enabled,
									isSectorQuestion: !!seed.isSectorQuestion,
									sectorName: seed.question_sectorName,
									companySize: seed.question_sector_company_size,
									ordering: seed.ordering,
									choiches: seed.choiches,
									isMultipleAnswer: seed.isMultipleAnswer,
									questionsStructureId: seed.questions_structure_id,
									attachments: seed.attachments,
									fatherQuestionId: seed.srMasterquestionId,
									isCompleted: seed.question_isCompleted,
									status: seed.question_state,
									ncMessage: seed.question_state_comment,
									expire: seed.question_state_expire,
									sdg: seed.sdgs || [],
									path: [...sth.path, seed.question_id],
									code: seed.question_code,
									attachmentsGuidelines: seed.attachmentsGuidelines,
									// questionGuidelines: seed.questionGuidelines,
									questionGuidelines: seed.guidelines,
									scoreMax: seed.scoreMax,
									open: true,
									hasNote: seed.hasNote,
									hasAdminNote: seed.hasInternalNote,
									isOpenAnswer: seed.isOpenQuestion,
									answers: seed.answers,
									scoreOpenQuestion: seed.scoreOpenQuestion,
									preventiveQuestions: seed.preventiveQuestions
								});


								if( !!seed.question_state_expire ) {
									showlog(
										"%c  CONTROLLO SCANDENZA #2  %c  nei dati  ",
										"background: #a00; color: #fff; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold; font-size: 2rem;",
										"background: #f00; color: #fff; border-radius:0px 3px 3px 0px; padding:3px; font-size: 2rem;"
									);
									showlog("%c"+ seed.question_id, "color:#f00;");
									showlog(seed.question_name);

								}

							})
						;


						// attacco del figlio
						themeReferiment.subThemes.push(sth);
						delete themeReferiment.materiality;



					})
				;



				return subThemes;

			})



			// subQuestion --------------------------------------------------------------------------------------------------------------------------
			.then( () => {

				// scansione della domanda
				tree.tabs
					.map( tab => {
						tab.themes
							.map( th => {
								if( !!isNotEmptyArray( th.questions ) ) {
									th.questions
										.filter( question => !!question.fatherQuestionId )
										.map( subQuestion => {
											let fatherQuestion = getQuestionTreeNodeByPath( tree, getQuestionPath( tree, subQuestion.fatherQuestionId ) );
											fatherQuestion.child = subQuestion;
											subQuestion.path = getQuestionPath( tree, subQuestion.id );
											subQuestion.path =
												[
													...subQuestion.path.filter((p, i) => i < subQuestion.path.length -1),
													fatherQuestion.id,
													subQuestion.path[ subQuestion.path.length -1 ]
												]
											;

											return subQuestion;
										})
										.map( subQuestion => null )
									;

									// eliminazione della sotto-domanda dalla lista delle domande
									th.questions =
										th.questions
											.filter( question => !!!question.fatherQuestionId )
									;

								}
								else {
									th.subThemes
										.map( sth => {
											if( !!isNotEmptyArray( sth.questions ) ) {
												sth.questions
													.filter( question => !!question.fatherQuestionId )
													.map( subQuestion => {
														let fatherQuestion = getQuestionTreeNodeByPath( tree, getQuestionPath( tree, subQuestion.fatherQuestionId ) );
														fatherQuestion.child = subQuestion;
														// debugger;
														subQuestion.path = getQuestionPath( tree, subQuestion.id );
														subQuestion.path =
															[
																...subQuestion.path.filter((p, i) => i < subQuestion.path.length -1),
																fatherQuestion.id,
																subQuestion.path[ subQuestion.path.length -1 ]
															]
														;
														return subQuestion;
													})
													.map( subQuestion => null )

												// eliminazione della sotto-domanda dalla lista delle domande
												sth.questions =
													sth.questions
														.filter( question => !!!question.fatherQuestionId )
												;

											}
										})
									;
								}
							})
					})
				;

			})


			// finish --------------------------------------------------------------------------------------------------------------------------------
			.then(() => {


				showlog("ultimo passo alla creazione della struttura dati a d albero");
				showlog( tree );
				return tree;

			})

			.catch(e => Promise.reject(e))

	);
}


/**
 *
 * @param subQuestions array di question
 * @returns {Promise<Array>}
 */
export const convertSIRatingSubQuestionTableToSubQuestionChild = (subQuestions, masterQuestionPath) => {
	return (
		// start
		new Promise((success, fail) => {
			success();
		})

		// ---------------------------------------------------------------------------
		.then( () => {

			return (
				subQuestions
					.map( seed => {

						if( !!seed.question_state_expire ) {
							showlog(
								"%c  CONTROLLO SCANDENZA #3  %c  nei dati  ",
								"background: #a00; color: #fff; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold; font-size: 2rem;",
								"background: #f00; color: #fff; border-radius:0px 3px 3px 0px; padding:3px; font-size: 2rem;"
							);
							showlog("id:  %c"+ seed.question_id, "color:#f00;");
							showlog(seed.question_name);

						}

						return ({
							id: seed.question_id,
							name: seed.question_name,
							enabled: !!seed.enabled,
							isSectorQuestion: !!seed.isSectorQuestion,
							sectorName: seed.question_sectorName,
							companySize: seed.question_sector_company_size,
							ordering: seed.ordering,
							choiches: seed.choiches,
							isMultipleAnswer: seed.isMultipleAnswer,
							questionsStructureId: seed.questions_structure_id,
							attachments: seed.attachments,
							fatherQuestionId: seed.srMasterquestionId,
							isCompleted: seed.question_isCompleted,
							status: seed.question_state,
							ncMessage: seed.question_state_comment,
							expire: seed.question_state_expire,
							path: [...masterQuestionPath, seed.question_id],
							sdg: seed.sdgs || [],
							scoreMax: seed.scoreMax,
							code: seed.question_code,
							hasNote: seed.hasNote,
							hasAdminNote: seed.hasInternalNote,
							// open: false

							attachmentsGuidelines: seed.attachmentsGuidelines,
							// questionGuidelines: seed.questionGuidelines,
							questionGuidelines: seed.guidelines,
							open: true,
							isOpenAnswer: seed.isOpenQuestion,
							answers: seed.answers,
							scoreOpenQuestion: seed.scoreOpenQuestion

						});
					})
			);
		})

	)
}





let treeLogStr = "";
let deep = 0;
let openNodes = 0;
const scanQuestionTreeNode = node => {

	if (!!node) {


		/*
		code: "E.1"
		id: 1
		name: "Gestione Ambientale"
		open:
		 */


		if (!!node.open) openNodes++;

		Object.keys(node)
			.forEach(nk => {
				if (isNotEmptyArray(node[nk]) && nk !== "path") {
					deep++;
					node[nk]
						.forEach((ni, i) => {
							if (i === node[nk].length - 1) treeLogStr += "\n" + (Array(deep).fill("│\t  ").join("")) + "└──";
							else treeLogStr += "\n" + (Array(deep).fill("│\t  ").join("")) + "├──";
							let nodeName =
								(!!ni)
									? (
										!!ni.name
											? ni.name
											: ""+ (new String(ni)).toString()
									)
									: "node-no-name"
								;

							if( typeof ni === "object" && !!ni.name ) nodeName = " "+ JSON.stringify( ni );
							// if( nodeName === "[object Object]" ) nodeName = JSON.parse( ni );


							if (!!ni && !!ni.open) treeLogStr += "%c";


							if (nk === "questions") treeLogStr += "  -?-  ";

							if( !!ni.id ) treeLogStr += "[ " + ni.id + " ]";
							// treeLogStr += "[ ";
							// treeLogStr += ni.id + " ]";



							if (!!ni && !!ni.open) treeLogStr += "%c";

							// treeLogStr += " " + ((typeof nodeName === "string") ? nodeName.trim().substr(0, 24) : "");
							treeLogStr += nodeName.trim().substr(0, 24);


							// treeLogStr += " code(" + ni.code + ") ";
							// treeLogStr += " score(" + ni.score + ") ";
							// treeLogStr += " #(" + ni.ordering + ") ";
							treeLogStr += (!!ni.enabled) ? "  – " : "  • ";


							if (!!ni && !!ni.open) treeLogStr += " %c";

							// if (!!ni && !!ni.open) treeLogStr += " %c";

							scanQuestionTreeNode(ni);
						});
					deep--;
				}
			})
			;

	}



	return treeLogStr;

}

/**
 * scrive il log della rappresentazione dell'albero
 * funziona male
 *
 * @param tree
 */
export const questionTreeLog = (tree, show = true) => {



	treeLogStr = "";
	deep = 1;
	openNodes = 0;
	scanQuestionTreeNode(tree);


	let csss = [];
	Array(openNodes)
		.fill("background: #ff0; padding: 2px; padding-right: 2px; border-radius: 2px 0px 0px 2px; color:#222;")
		.forEach(t => {
			csss.push(t);
			csss.push("background: #ff08; padding: 2px; padding-right: 2px; color:#222;");
			csss.push("background: #ff03; padding: 2px; padding-right: 2px; border-radius: 0px 2px 2px 0px; color:#222;");
			csss.push("");
		})
		;


	if (!!show) showlog(treeLogStr, ...csss);


	return [treeLogStr, ...csss];

}




export const convertRawThemesToTree = rawList => {
	/*
	macroarea_id: 1
	macroarea_name: "Environmental"
	subthematic_id: 1
	subthematic_name: "Sistema di Gestione Ambientale"
	thematic_id: 1
	thematic_name: "Gestione ambientale"
	 */

	let themesTree = {};

	// lista delle macro aree ------------------------------------------------------------------------------------------
	themesTree.area =
		[
			...new Map(
				rawList
					.map(item => [
						item.macroarea_id,
						{
							id: item.macroarea_id,
							name: item.macroarea_name,
							themes: [],
							path: [item.macroarea_id]
						}
					])
			).values()
		]
			.filter( onlyNotNull )
			.filter(x => !!x.id)
			.sort( sortById )
		;

	// showlog("aree");
	// showlog(themesTree.area);
	// beautifyLog(themesTree.area);


	// lista delle tematiche -------------------------------------------------------------------------------------------
	let ths =
		[
			...new Map(
				rawList
					.map(item => [
						item.thematic_id,
						{
							id: item.thematic_id,
							name: item.thematic_name,
							code: item.thematic_code,
							subThemes: [],
							path: [item.thematic_id]
						}
					])
			).values()
		]
			.filter( onlyNotNull )
			.filter(x => !!x.id)
			.sort( sortById )
		;

	// showlog("tematiche");
	// showlog(ths);
	// beautifyLog(ths);



	ths
		.map(th => {

			let areaReferiment =

				rawList

					// elemento della lista corrispondente alla tematica corrente
					.filter(li => li.thematic_id === th.id)

					// elemento unico
					.reduce((final, cursor) => cursor, null)

					// riferimento al padre
					.macroarea_id

				;



			th.path = [
				themesTree.area
					.filter(t => t.id === areaReferiment)
					.reduce(getLastElementOr, null)
					.path
					.reduce(getLastElementOr, null),
				th.id
			];


			// attacco del figlio
			themesTree.area
				.filter(t => t.id === areaReferiment)
				.reduce(getLastElementOr, null)
				.themes.push(th)
				;

		})
		;



	// lista sotto-tematiche
	let subThemes =
		[
			... new Map(
				rawList
					.map(item => [
						item.subthematic_id,
						{
							id: item.subthematic_id,
							name: item.subthematic_name,
							code: item.subthematic_code
						}
					])
			).values()
		]
			.filter( onlyNotNull )
			.filter(x => !!x.id)
		;


	// showlog("sotto-tematiche");
	// showlog(subThemes);


	subThemes
		.map(sth => {

			let themeReferimentId =

				rawList

					// elemento della lista corrispondente alla tematica corrente
					.filter(li => li.subthematic_id === sth.id)

					// elemento unico
					.reduce(getLastElementOr, null)

					// riferimento al padre
					.thematic_id

				;



			// showlog("attacco la sotto-tematica alla tematica ");
			// showlog(sth);
			// showlog(themeReferimentId);


			let themeReferiment = null;
			ths
				.forEach(_th => {
					if (_th.id === themeReferimentId) themeReferiment = _th;
				})

				;

			// showlog("themeReferiment");
			// showlog(themeReferiment);
			// showlog(ths);


			if (!!themeReferiment) {

				// path
				sth.path = [
					...themeReferiment.path,
					sth.id
				];

				// attacco del figlio
				themeReferiment.subThemes.push(sth);
			}



		})
		;




	return themesTree;




}


export const getQuestionFatherTreeNodeByPath = (treeRef, path) => {
	let node = null;
	let children = null;
	path
		.forEach((id, level) => {
			if (level == 0) children = treeRef.tabs;
			if (level == 1) children = node.themes;
			if (level == 2) children = node.subThemes;
			node = children.filter(n => n.id === id).reduce( getLastElementOr, null);
		})
		;
	return node;
}


export const getQuestionTreeNodeByPath = (treeRef, path) => {
	let node = null;
	let children = null;


	// debugger;
	path
		.map((id, level) => {
			if (level === 0) node = treeRef.tabs;
			if (level === 1) node = node.themes;
			if (level === 2) {
				if( !!isNotEmptyArray( node.subThemes ) ) 	node = node.subThemes;
				else 										node = node.questions;
			}
			if (level === 3) {
				if( !!node && !!isNotEmptyArray( node.child ) ) {
					node =
						node.child
							.filter( c => c.id === id )
							.reduce( getLastElementOr, null )
					;
				}
				else {
					if( !!isNotEmptyArray( node.questions ) ) node = node.questions;
				}
			}
			if (level === 4) {

				if( !!node && !!isNotEmptyArray( node.child ) ) {
					node =
						node.child
							.filter( c => c.id === id )
							.reduce( getLastElementOr, null )
					;
				}
			}
			if( level < 4 && !!node && !!isNotEmptyArray( node ) ) node = node.filter(n => n.id === id).reduce( getLastElementOr, null );
		})
		;
	return node;
}


export const getQuestionPath = (tree, questionId) => {
	let path = [];
	tree.tabs
		.forEach(t => {
			t.themes
				.forEach(th => {
					if (
						!!th.questions
							.filter(q => q.id === questionId)
							.reduce( getLastElementOr, null)
					) {
						// domanda trovata
						path = [...th.path, questionId];
						return false;
					}
					else {

						let node =
							th.questions
								.filter( q => !!isNotEmptyArray(q.child) )
								.filter( onlyNotNull )
								.map( q => {
									return (
										q.child
											.filter( c => c.id === questionId )
											.filter( onlyNotNull )
											.map( c => q.id )
											.reduce( getLastElementOr, null)
									);
								})
								.filter( onlyNotNull )
								.reduce( getLastElementOr, null)
						;

						if( !!node ) {
							// sotto-domanda trovata
							path = [
							    ...th.path,
                                node,
								questionId
                            ];
							return false;
						}
					}
					th.subThemes
						.forEach(sth => {
							if (
								!!sth.questions
									.filter(q => q.id === questionId)
									.reduce( getLastElementOr, null)
							) {
								// domanda trovata
								path = [...sth.path, questionId];
								return false;
							}
							else {

								let node =
									sth.questions
										.filter( q => !!isNotEmptyArray(q.child) )
										.filter( onlyNotNull )
										.map( q => {
											// solo le domande che hanno sotto-domande
											return (
												q.child
													.filter( c => c.id === questionId )
													.filter( onlyNotNull )
													.map( c => q.id )
													.reduce( getLastElementOr, null)
											);
										})
										.filter( onlyNotNull )
										.reduce( getLastElementOr, null)
								;

								if( !!node ) {
									// sotto-domanda trovata
									path = [
										...sth.path,
										node,
										questionId
									];

								}

							}
						})
						;

				})
		})
		;
	return path;
}



/**
 * Dall'albero sorgente viene presa l'informazione <code>open</code>
 * e riportata ad un albero clonato dall'albero di destinazione
 *
 * @param {object} sorceTree - struttura dati ad albero sorgente
 * @param {object} destTree - struttura dati ad albero destinazione
 * @return {object} - struttura dati ad albero
 */
export const questionTreeCloneOpenNodes = (sorceTree, destTree) => {
	let srcTree = JSON.parse(JSON.stringify(sorceTree));
	let dstTree = JSON.parse(JSON.stringify(destTree));

	srcTree
		.tabs.forEach(sTab => {

			// TAB
			dstTree
				.tabs
				.filter(dTab => dTab.id === sTab.id)
				.map(dTab => {
					dTab.open = sTab.open;
				})
				;

			// THEMES
			sTab.themes
				.forEach(sCh => {
					dstTree
						.tabs
						.map(dTab => {
							dTab.themes
								.filter(dCh => dCh.id === sCh.id)
								.map(dCh => dCh.open = sCh.open)
						})
						;
				})
				;


			// SUB-THEMES
			sTab.themes
				.forEach(sCh => {
					sCh.subThemes
						.forEach(sInf => {
							dstTree.tabs
								.map(dTab => {
									dTab.themes
										.map(dCh => {
											dCh.subThemes
												.filter(dInf => dInf.id === sInf.id)
												.map(dInf => {
													dInf.open = sInf.open;
												})
										})
								})
								;
						})
				})

		})
		;



	return dstTree;


}

/**
 * porta l'informazione sull'apertura dei nodi
 * dall'albero sorgente all'albero destinatario
 * ( che può avere informazioni diverse ).
 * Viene modificata l'informazione direttamente
 * sull'albero destinatario
 *
 * @param sourceTree
 * @param dstTree
 */
export const cloneOpenNodes = (sourceTree, dstTree) => {

	if( !dstTree ) return null;
	if( !sourceTree ) return null;

	// controllo foglia
	if( !!sourceTree && typeof sourceTree.open !== "undefined" /*&& !!isNotEmptyArray( sourceTree.path )*/ ) {

		let dstNodeRef = null;
		if( !!sourceTree.path && (sourceTree.path.length === 1) ) {
			// tabs
			showlog("controllo tab in cloneOpenNodes", dstTree.tabs);
			if( !!dstTree.tabs && dstTree.tabs.length > 0 ) {
				dstNodeRef = dstTree.tabs.filter( t => t.id === sourceTree.path[0] ).reduce( getLastElementOr, dstTree.tabs[0] );
			}
			if( !dstNodeRef ) dstNodeRef = dstTree.tabs[0];

		}
		else {
			if( !!sourceTree.path && (sourceTree.path.length > 0) ) {
				dstNodeRef = getQuestionTreeNodeByPath(dstTree, sourceTree.path);
			}
		}

		// debugger;
		if( !!dstNodeRef ) dstNodeRef.open = sourceTree.open;

	}

	Object.keys(sourceTree)

		// tutti i campi della root
		.map( key => sourceTree[ key ] )

		// tutti i campi di tipo array
		// .filter( branch => !!isNotEmptyArray( branch ) )

		// recursion
		.map( node => {
			if( !!node && (!!isNotEmptyArray( node ) || typeof node === "object") ) cloneOpenNodes( node, dstTree );
		})

	;

}


export const extractAllQuestions = (tree, targetTab, targetTheme, targetSubTheme) => {
	let list = [];
	if( !!tree ) {
		tree

			.tabs
            .filter( tab => {
                if( !!targetTab && !!targetTab.id ) return ( tab.id === targetTab.id );
                return true;
            })
            .forEach(tab => {

				tab.themes
                    .filter( th => {
                        if( !!targetTheme && !!targetTheme.id ) return ( th.id === targetTheme.id );
                        return true;
                    })
                    .forEach(th => {

						if( !!isNotEmptyArray(th.questions) ) {
							th.questions.forEach(question => {
								list.push( question );
								if( !!isNotEmptyArray(question.child) ) {
									// showlog("controllo tutte le domande della tematica, ha figli? SI");
									// showlog( question.child );
									list = [
										...list,
										...question.child
									];
								}
							})
						}

						th.subThemes
                            .filter( sth => {
                                if( !!targetSubTheme && !!targetSubTheme.id ) return ( sth.id === targetSubTheme.id );
                                return true;
                            })
                            .forEach(sth => {

								if( !!isNotEmptyArray(sth.questions) ) {
									sth.questions.forEach(question => {
										list.push( question );
										if( !!isNotEmptyArray(question.child) ) {
											list = [
												...list,
												...question.child
											];
										}
									})
								}

							})
				})
		})

	}
	return list;
};
