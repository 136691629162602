import {editQuestionSdg, getQuestionSdg, getSdg} from "../services/sdg";


export const loadSdg = sectorQuestion => {
    return getSdg( sectorQuestion );
};

export const loadQuestionSdg = (ratingId, questionId) => {
    return getQuestionSdg(ratingId, questionId);
}

export const saveQuestionSdg = (ratingId, questionId, sdgIdList) => {
    return editQuestionSdg(ratingId, questionId, sdgIdList);
}

