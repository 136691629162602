import React from "react";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import "../../../style/sr-tool-conf.css";
import {translate} from "../../i18n/translate-function";

export const OverWaitingPannel = props => (
    <div className={ !!props.className ? props.className : "over-waiting-panel"}>
        <WaitingOrNodataMessage
            waiting={true}
            waitMessage={translate("Waiting")}
            nodataMessage={translate("No Data")}
            fullHeight={true}
            spinnerColor={"primary"}
            className={ !!props.className ? props.className +"-spinner" : "over-waiting-panel-spinner"}
        />
    </div>
);
