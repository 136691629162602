import {QuestionSDG_Get, QuestionSDG_Put, SDG_Get} from "./request-manager";


export const getSdg = sectorQuestion => {
    return SDG_Get( sectorQuestion );
};


export const getQuestionSdg = (ratingId, questionId) => {
    return QuestionSDG_Get(ratingId, questionId);
}

export const editQuestionSdg = (ratingId, questionId, payload) => {
    // body = { sdgIdList: [1, 2, 3] }
    return QuestionSDG_Put(ratingId, questionId, payload);
}
