// @flow
import React from 'react';
import CardBody from "reactstrap/es/CardBody";

import {Card, CardHeader, Col, Row,} from "reactstrap";
import classNames from 'classnames';
import "../style/home.css";
import {Link} from "react-router-dom";
import {translate} from "./i18n/translate-function";
// import {Link} from "react-router-dom";


export default class HomeServiceBlock extends React.Component {

    state = {
        SRToolServiceNotActive: true,
        user: {
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
        },
        pageNumber: 1,
        logo: this.props.Logo.a,
        title: this.props.Title.a,
        page: this.props.Pages.a,
        arrow1bg: 'white',
        arrow2bg: '#e9ecef',
        arrow1bg2: 'white',
        arrow2bg2: '#e9ecef',
        circle:
            (
                <>
                    <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #727cf5; border: solid  1px gray;"></div>
                    <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                    <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                    <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                </>
            )
    }


    constructor(props) {
        super(props);
    }

    next = () => {
        let pageNumbers = Object.keys(this.props.Pages).length;
        if (this.state.pageNumber < pageNumbers) {
            let pagNext = this.state.pageNumber + 1;
            this.UpdateValue(pagNext);
        }

    }

    prev = () => {
        if (this.state.pageNumber > 1) {
            let pagNext = this.state.pageNumber - 1;
            this.UpdateValue(pagNext);
        }
    }

    UpdateValue = (pagNext) => {
        switch (pagNext) {
            case 1:
                this.setState({
                    pageNumber: pagNext,
                    logo: this.props.Logo.a,
                    title: this.props.Title.a,
                    page: this.props.Pages.a,
                    arrow1bg: 'white',
                    arrow2bg: '#e9ecef',
                    arrow1bg2: 'white',
                    arrow2bg2: '#e9ecef',
                    circle:
                        (
                            <>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #727cf5; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                            </>
                        )
                });
                break;
            case 2:
                this.setState({
                    pageNumber: pagNext,
                    logo: this.props.Logo.b,
                    title: this.props.Title.b,
                    page: this.props.Pages.b,
                    arrow1bg: '#e9ecef',
                    arrow2bg: 'white',
                    arrow1bg2: '#e9ecef',
                    arrow2bg2: '#e9ecef',
                    circle:
                        (
                            <>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #727cf5; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                            </>
                        )
                });
                break;
            case 3:
                this.setState({
                    pageNumber: pagNext,
                    logo: this.props.Logo.c,
                    title: this.props.Title.c,
                    page: this.props.Pages.c,
                    arrow1bg2: '#e9ecef',
                    arrow2bg2: '#e9ecef',
                    circle:
                        (
                            <>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #727cf5; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                            </>
                        )
                });
                break;
            case 4:
                this.setState({
                    pageNumber: pagNext,
                    logo: this.props.Logo.d,
                    title: this.props.Title.d,
                    page: this.props.Pages.d,
                    arrow1bg2: '#e9ecef',
                    arrow2bg2: 'white',
                    circle:
                        (
                            <>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #fff; border: solid  1px gray;"></div>
                                <div Style="margin: auto;width: 7px; height: 7px;  line-height: 50px;  border-radius: 50%;  font-size: 10px;  color: gray;  text-align: center;  background: #727cf5; border: solid  1px gray;"></div>
                            </>
                        )
                });
                break;

            default:
                break;
        }
    }


    render() {

        return (

            <Card className="mb-0 ribbon-box" key={"welcome-page"} Style={"box-shadow: 5px 5px 5px #dedede; border-radius: " + this.props.Border + " 10px;border:solid " + this.props.Border + " 1px;height:450px"} style={this.props.stileAggiuntivo} >

                <CardHeader className={"cardheader"} style={{ height: "389px", textAlign: "center" }}>
                    <div style={{ height: '339px' }}>
                        {
                            this.props.Ribbon
                            &&
                            (
                                <div className={classNames('ribbon-two', 'ribbon-two-' + 'primary')}>
                                    <span style={{ fontSize: '65%' }}>{'COMING SOON'}</span>
                                </div>
                            )

                        }

                        <Row>
                            <Col sm="12">
                                <span className="topnav-logo-lg">



                                    <img src={this.state.logo} alt="logo" height="60%" className={ !!this.props.LogoClassName ? this.props.LogoClassName : ""}/>

                                    <br />


                                    <span style={{ color: "black" }}><b>  {this.state.title}</b></span>

                                </span>
                            </Col>

                        </Row>

                        {this.state.page}

                    </div>
                    <div style={{ height: '30px' }}>

                        {

                            this.props.Arrows.show &&

                            (

                                this.props.Arrows.type === 1
                                    ?

                                    (
                                        <Row>
                                            <Col sm="3"></Col>
                                            <Col sm="6">
                                                <div Style="display:flex">

                                                    <div onClick={this.prev} Style={"margin: auto;width: 23px; height: 23px; line-height: 3px; border-radius: 50%;  font-size: 10px;  color: #0acf97;  text-align: center;  background:" + this.state.arrow1bg + ";padding-top:1px;padding-left:1px;margin-right:5px;"}>
                                                        <i className="uil uil-angle-left" style={{ fontSize: '21px', color: '#626262' }}></i>
                                                    </div>

                                                    <div onClick={this.next} Style={"margin: auto;width: 23px; height: 23px; line-height: 3px; border-radius: 50%;  font-size: 10px;  color: #0acf97;  text-align: center;  background: " + this.state.arrow2bg + ";padding-left:1px;margin-left:5px;"}>
                                                        <i className="uil uil-angle-right" style={{ fontSize: '21px', color: '#626262' }}></i>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col></Col>

                                        </Row>
                                    )
                                    : (


                                        <Row>
                                            <Col sm="2"></Col>
                                            <Col sm="8">
                                                <div Style="display:flex">

                                                    <div onClick={this.prev} Style={"margin: auto;width: 23px; height: 23px; line-height: 3px; border-radius: 50%;  font-size: 10px;  color: #0acf97;  text-align: center;  background: " + this.state.arrow1bg2 + ";padding-top:1px;padding-left:1px;margin-right:5px;"}>
                                                        <i className="uil uil-angle-left" style={{ fontSize: '21px', color: '#626262' }}></i>
                                                    </div>
                                                    {this.state.circle}
                                                    <div onClick={this.next} Style={"margin: auto;width: 23px; height: 23px; line-height: 3px; border-radius: 50%;  font-size: 10px;  color: #0acf97;  text-align: center;  background: " + this.state.arrow2bg2 + ";padding-top:1px;padding-left:1px;margin-left:5px;"}>
                                                        <i className="uil uil-angle-right" style={{ fontSize: '21px', color: '#626262' }}></i>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col sm="2"></Col>

                                        </Row>

                                    )
                            )

                        }






                    </div>
                </CardHeader>

                <CardBody style={Object.assign({ height: "50px", textAlign: 'center'}, (this.props.Button.type === 1 ? {paddingTop: "14px"} : null) )}>
                    {
                        this.props.Button.type === 1
                            ? (
                                <React.Fragment>
                                    <Link to={ this.props.Button.link } >
                                        <p style={{ marginTop: '-8px', fontSize: '13px', background: "#727cf5", color: "#fff", borderRadius: "3px" }}><b>{this.props.Button.label}</b>
                                            <span style={{ marginLeft: '15px', marginTop: '3px' }}>
                                                <i className="uil uil-angle-right" style={{ fontSize: '21px', color: 'white', marginTop: '3px', position: 'relative', top: '2px' }}/>
                                            </span>
                                        </p>
                                    </Link>
                                </React.Fragment>
                            )
                            : (

                                <React.Fragment>
                                        <p
                                            style={{
                                                marginTop: '-10px',
                                                fontSize: '13px',
                                                border: "solid #727cf5 1px",
                                                padding: "5px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                color: "#727cf5"
                                            }}
                                            onClick={ clickEvent => {
                                                window.open(this.props.Button.link,'_newtab');
                                            }}
                                        >
                                            <b> {translate("getInformation")}</b>
                                        </p>
                                </React.Fragment>
                            )

                    }


                </CardBody>


            </Card>

        )
    }

}
