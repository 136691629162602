import {
    GetSRToolCompilationRequirementsLanguage,
    GetSRToolGlLanguage,
    GetSRToolLanguage,
    GetSRToolRaccomandationLanguage,
    PutSRToolCompilationRequirementsLanguage,
    PutSRToolLanguage,
    PutSRToolLanguageGL,
    PutSRToolRaccomandationLanguage
} from "./request-manager";

export const loadTranslationForSRToolNode = (level, nodeID) => {
    return GetSRToolLanguage(level, nodeID);
}

export const loadTranslationForSRToolNodeGL = (level, nodeID) => {
    return GetSRToolGlLanguage(level, nodeID);
}

export const loadTranslationForSRToolRaccomandationNode = (level, nodeID) => {
    return GetSRToolRaccomandationLanguage(level, nodeID);
}

export const loadTranslationForSRToolCompilationRequirementsNode = (level, nodeID) => {
    return GetSRToolCompilationRequirementsLanguage(level, nodeID);
}

export const saveTranslationForSRToolNode = (level, nodeID, payload) => {
    return PutSRToolLanguage(level, nodeID, payload);
}

export const saveTranslationForSRToolNodeGL = (level, nodeID, payload) => {
    return PutSRToolLanguageGL(level, nodeID, payload);
}

export const saveTranslationForSRToolRaccomandationNode = (level, nodeID, payload) => {
    return PutSRToolRaccomandationLanguage(level, nodeID, payload);
}

export const saveTranslationForSRToolCompilationRequirementsNode = (level, nodeID, payload) => {
    return PutSRToolCompilationRequirementsLanguage(level, nodeID, payload);
}

