import React, {Component} from "react";
import "./index.css";
import {showlog} from "../../utils";


const DELAY = 1200;
const THRESHOLD = 50;

export default class GotoTopComponent extends Component {

    state = {
        time: 0
    }

    constructor( props ) {
        super( props );
    }

    render() {

        // renderlog("GotoTopComponent", this.state, this.props, { color: "#0acf97", contrastColor: "#222222", negativeColor: "#f53068" });
        // showlog( window.document.body.offsetHeight );
        // showlog( screen.height );
        // showlog( parseInt(window.document.body.offsetHeight, 10) >= parseInt(screen.height, 10) );

        let elementHeight = (
            !!this.props.domElement
                ? parseInt(this.props.domElement.offsetHeight, 10)
                : parseInt(window.document.body.offsetHeight, 10)
        );
        // eslint-disable-next-line no-restricted-globals
        let screenHeight = parseInt(screen.height, 10);

        let isVisible = ( (elementHeight >= screenHeight) || !!this.props.alwaysVisible );

        return (
            !!isVisible
                ? (
                    <div
                        className={"goto-top-button"+ (!!this.props.className ? ` ${ this.props.className }` : "")}
                    >
                        <div
                            onClick={clickEvent => {

                                // click event propagation --------------------------------------------------------
                                if( !!this.props.propagateClickEvent ) {}
                                else {
                                    clickEvent.stopPropagation();
                                    clickEvent.preventDefault();
                                }

                                // element or window --------------------------------------------------------------
                                if( !!this.props.domElement ) {
                                    this.props.domElement.scrollIntoView();
                                }
                                else {
                                    window.scrollTo(0, 0);
                                }

                            }}
                        >
                            <i className={"dripicons-chevron-up"}/>
                        </div>
                    </div>
                )
                : null
        )

    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevState.time < ( (new Date()).getTime() - THRESHOLD - DELAY )) {
            setTimeout(
                function() {
                    showlog("GotoTopComponent Update");

                    /*
                    let elementHeight = (
                        !!this.props.domElement
                            ? parseInt(this.props.domElement.offsetHeight, 10)
                            : parseInt(window.document.body.offsetHeight, 10)
                    );
                    // eslint-disable-next-line no-restricted-globals
                    let screenHeight = parseInt(screen.height, 10);

                    let isVisible = ( (elementHeight >= screenHeight) || !!this.props.alwaysVisible );

                    showlog(elementHeight);
                    showlog(screenHeight);
                    showlog(isVisible);
                    */

                    this.setState({
                        ...this.state,
                        time: (new Date()).getTime() + DELAY
                    })
                }.bind( this ),
                DELAY
            )
        }
    }


};
