import React, {Component} from 'react';
import {Col, Modal, ModalFooter, ModalHeader, Row} from "reactstrap";
import {renderlog, showlog} from "../../../utils/dev-utils";
import ActionButton from "../../../components/save-button";
import {PATH_PREFIX} from '../../../env';
import {validAllQuestions} from '../../../business-logic/valid-all-questions';
import {translate} from '../../../components/i18n/translate-function';

export default class ConfirmAllValidationModal extends Component {

    state = {

    }

    constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );

    }

    toggle = () => {
        this.props.onClose();
    }


        render(){



            renderlog(
                "ConfirmAllValidationModal",
            );
            showlog(" (this.props.esternalProps.match.params.id || this.props.esternalProps.id)",  (this.props.esternalProps.match.params.id || this.props.esternalProps.id));


            let content = (
                <Modal isOpen={ true } toggle={this.toggle} className={"confirm-all-validation-modal"}>
                    <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                        Confermi di voler validare tutte le domande(solo quelle complete)?
                    </ModalHeader>
                    <ModalFooter className={this.state.bodyClass}>
                    <Row>
							<Col>
								<ActionButton
									buttonText={ "Valida tutte" }
									//promise={ () => acceptEUPA( props.match.params.id || props.id ) }
									//onResult={ () => setGoAhead( true ) }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
                                    promise={ () => Promise.resolve() }
									onResult={ () => {

                                        validAllQuestions( (this.props.esternalProps.match.params.id || this.props.esternalProps.id))
										.then(result => {
                                            window.location.href = PATH_PREFIX +"/" + translate("gender-gap") +"/" + translate("evaluation") + "/" +  (this.props.esternalProps.match.params.id || this.props.esternalProps.id)
                                        })
                                       /* .catch(err => {
                                            let errMessage = extractErrorMessage(err)
                                            showlog("errore mail", errMessage)

                                            this.setState({
                                                ...this.state,
                                                alertMessage: errMessage,
                                                alertOpen:true
                                            })
                                        }) */

                                    } }
								/>
							</Col>
                            <Row>
							<Col>
								<ActionButton
									buttonText={ "Torna indietro" }
									//promise={ () => Promise.resolve() }
									//onResult={ () => {
									//	setSegnalationPopUp(true)
									//} }
									//onError={ error => {
									//	setErrorMessage( error );
									//	// setGoAhead( true );	// test
									//}}
                                    promise={ () => Promise.resolve() }
									onResult={ () => {

                                        this.props.onClose()

                                    } }
								/>
							</Col></Row>
						</Row>
                    </ModalFooter>
                </Modal>
            );

            return content;
        }

}
