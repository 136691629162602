import React, {Component} from "react";
import {
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    PopoverBody,
    PopoverHeader,
    Row,
    Table,
    UncontrolledPopover
} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog} from "../../../utils/dev-utils";
import {isNotEmptyArray} from "../../../utils/array-utils";
import {COLOR_POLICY} from "../../../env";
import {informativeStateReport_Get} from "../../../../src/services/request-manager";
import {translate} from "../../i18n/translate-function";


const fieldSdgMap = {
    thematic_name: "Tematica/Sottotematica",
    subthematic_name: "Sottotematica",
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    materiality: "Livello di Materialità",
    percentage: "%",
    sdg: "SDG"
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];











const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];


const hiddenSdgFields2 = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore",
];

const htmlJoin = "<br/>"

export default class InformativeStateReport extends Component {





    constructor(props) {
        super(props);

        this.state = {
            // data: this.props.data || [],
            values: null,
            informativeAnswered: 0,
            //informativeAttachmentToValidate: 0,
            informativeToAnswer: 0,
            informativeMildNc: 0,
            informativeWaitNc: 0,
            informativeGraveNc: 0,
            informativeTotal: 0,
            informativeValidate: 0,
            informativeCancelled: 0
        }

        this.toggle = this.toggle.bind(this);
        // this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }

    attachThematicsList(id, count, list) {


        return (



            count != 0 ?

                (
                    <React.Fragment>
                        <div id={"popover-" + id} style={{ display: 'inline-block', marginLeft: '-6px' }}> <b style={{ color: "#727cf5", cursor: "pointer" }}>{count}</b> </div>
                        <div>
                            <UncontrolledPopover placement={"popover-" + id} target={"popover-" + id} trigger="legacy">
                                <PopoverHeader>Elenco capitoli</PopoverHeader>
                                <PopoverBody>
                                    {

                                        list.map(x => (<li className={"rightSpaceList"}>{x.chapter}</li>))
                                    }

                                </PopoverBody>
                            </UncontrolledPopover>

                        </div>

                    </React.Fragment>
                )
                :

                <span style={{ color: "gray", cursor: "pointer" }}>{count}</span>
        )



    }


    variableNameNumnberToList(variableName) {


        Object.keys(this.state).filter(x => x == variableName)

        let variableNameCoverted = "";
        variableNameCoverted = variableName.replace('0', 'List0');
        variableNameCoverted = variableName.replace('1', 'List1');
        variableNameCoverted = variableName.replace('2', 'List2');
        variableNameCoverted = variableName.replace('3', 'List3');

    }

    getStringNameobj(objName) {

        return Object.keys(this.state).filter(x => x === objName)[0];
    }


    getValues(data) {


        if (!isNotEmptyArray(data)) {
            return ({
                informativeAnsweredSum: 0,
                informativeToAnswerSum: 0,
                // informativeAttachmentWaitingSum: 0,
                // informativeAttachmentToValidateSum: 0,
                informativeMildNcSum: 0,
                informativeWaitNcSum: 0,
                //informativeGraveNcSum: 0,
                informativeTotalSum: 0,
                informativeValidateSum: 0,
                //informativeCancelledSum: 0,

                informativeAnswered0: 0,
                informativeToAnswer0: 0,
                // informativeAttachmentWaiting0: 0,
                // informativeAttachmentToValidate0: 0,
                informativeMildNc0: 0,
                informativeWaitNc0: 0,
                //informativeGraveNc0: 0,
                informativeTotal0: 0,
                informativeValidate0: 0,
                //informativeCancelled0: 0,


                informativeAnswered1: 0,
                informativeToAnswer1: 0,
                // informativeAttachmentWaiting1:    0,
                // informativeAttachmentToValidate1: 0,
                informativeMildNc1: 0,
                informativeWaitNc1: 0,
                //informativeGraveNc1: 0,
                informativeTotal1: 0,
                informativeValidate1: 0,
                //informativeCancelled1: 0,

                informativeAnswered2: 0,
                informativeToAnswer2: 0,
                // informativeAttachmentWaiting2: 0,
                // informativeAttachmentToValidate2: 0,
                informativeMildNc2: 0,
                informativeWaitNc2: 0,
                //informativeGraveNc2: 0,
                informativeTotal2: 0,
                informativeValidate2: 0,
                //informativeCancelled2: 0

                // informativeTotalList3: 0
            });
        }


        return ({
            informativeAnsweredSum: data[0].informativeAnswered.count + data[1].informativeAnswered.count + data[2].informativeAnswered.count + data[3].informativeAnswered.count,
            informativeToAnswerSum: data[0].informativeToAnswer.count + data[1].informativeToAnswer.count + data[2].informativeToAnswer.count + data[3].informativeToAnswer.count,
            // informativeAttachmentWaitingSum: data[0].informativeAttachmentWaiting.count + data[1].informativeAttachmentWaiting.count + data[2].informativeAttachmentWaiting.count,
            // informativeAttachmentToValidateSum: data[0].informativeAttachmentToValidate.count + data[1].informativeAttachmentToValidate.count + data[2].informativeAttachmentToValidate.count,
            informativeMildNcSum: data[0].informativeMildNc.count + data[1].informativeMildNc.count + data[2].informativeMildNc.count + data[3].informativeMildNc.count,
            informativeWaitNcSum: data[0].informativeWaitNc.count + data[1].informativeWaitNc.count + data[2].informativeWaitNc.count + data[3].informativeWaitNc.count,
            //informativeGraveNcSum: data[0].informativeGraveNc.count + data[1].informativeGraveNc.count + data[2].informativeGraveNc.count + data[3].informativeGraveNc.count,
            informativeTotalSum: data[0].informativeTotal.count + data[1].informativeTotal.count + data[2].informativeTotal.count + data[3].informativeTotal.count,
            informativeValidateSum: data[0].informativeValidate.count + data[1].informativeValidate.count + data[2].informativeValidate.count + data[3].informativeValidate.count,
            //informativeCancelledSum: data[0].informativeCancelled.count + data[1].informativeCancelled.count + data[2].informativeCancelled.count,

            informativeAnswered0: data[0].informativeAnswered.count, informativeAnsweredList0: data[0].informativeAnswered.chapterList,
            informativeToAnswer0: data[0].informativeToAnswer.count, informativeToAnswerList0: data[0].informativeToAnswer.chapterList,
            // informativeAttachmentWaiting0:    data[0].informativeAttachmentWaiting.count,    informativeAttachmentWaitingList0:       data[0].informativeAttachmentWaiting.chapterList,
            // informativeAttachmentToValidate0: data[0].informativeAttachmentToValidate.count, informativeAttachmentToValidateList0: data[0].informativeAttachmentToValidate.chapterList,
            informativeMildNc0: data[0].informativeMildNc.count, informativeMildNcList0: data[0].informativeMildNc.chapterList,
            informativeWaitNc0: data[0].informativeWaitNc.count, informativeWaitNcList0: data[0].informativeWaitNc.chapterList,
            //informativeGraveNc0: data[0].informativeGraveNc.count, informativeGraveNcList0: data[0].informativeGraveNc.chapterList,
            informativeTotal0: data[0].informativeTotal.count, informativeTotalList0: data[0].informativeTotal.chapterList,
            informativeValidate0: data[0].informativeValidate.count, informativeValidateList0: data[0].informativeValidate.chapterList,
            //informativeCancelled0: data[0].informativeCancelled.count, informativeCancelledList0: data[0].informativeCancelled.chapterList,


            informativeAnswered1: data[1].informativeAnswered.count, informativeAnsweredList1: data[1].informativeAnswered.chapterList,
            informativeToAnswer1: data[1].informativeToAnswer.count, informativeToAnswerList1: data[1].informativeToAnswer.chapterList,
            // informativeAttachmentWaiting1:    data[1].informativeAttachmentWaiting.count,    informativeAttachmentWaitingList1: data[1].informativeAttachmentWaiting.chapterList,
            // informativeAttachmentToValidate1: data[1].informativeAttachmentToValidate.count, informativeAttachmentToValidateList1: data[1].informativeAttachmentToValidate.chapterList,
            informativeMildNc1: data[1].informativeMildNc.count, informativeMildNcList1: data[1].informativeMildNc.chapterList,
            informativeWaitNc1: data[1].informativeWaitNc.count, informativeWaitNcList1: data[1].informativeWaitNc.chapterList,
            //informativeGraveNc1: data[1].informativeGraveNc.count, informativeGraveNcList1: data[1].informativeGraveNc.chapterList,
            informativeTotal1: data[1].informativeTotal.count, informativeTotalList1: data[1].informativeTotal.chapterList,
            informativeValidate1: data[1].informativeValidate.count, informativeValidateList1: data[1].informativeValidate.chapterList,
            //informativeCancelled1: data[1].informativeCancelled.count, informativeCancelledList1: data[1].informativeCancelled.chapterList,

            informativeAnswered2: data[2].informativeAnswered.count, informativeAnsweredList2: data[2].informativeAnswered.chapterList,
            informativeToAnswer2: data[2].informativeToAnswer.count, informativeToAnswerList2: data[2].informativeToAnswer.chapterList,
            // informativeAttachmentWaiting2:    data[2].informativeAttachmentWaiting.count,    informativeAttachmentWaitingList2: data[2].informativeAttachmentWaiting.chapterList,
            // informativeAttachmentToValidate2: data[2].informativeAttachmentToValidate.count, informativeAttachmentToValidateList2: data[2].informativeAttachmentToValidate.chapterList,
            informativeMildNc2: data[2].informativeMildNc.count, informativeMildNcList2: data[2].informativeMildNc.chapterList,
            informativeWaitNc2: data[2].informativeWaitNc.count, informativeWaitNcList2: data[2].informativeWaitNc.chapterList,
            //informativeGraveNc2: data[2].informativeGraveNc.count, informativeGraveNcList2: data[2].informativeGraveNc.chapterList,
            informativeTotal2: data[2].informativeTotal.count, informativeTotalList2: data[2].informativeTotal.chapterList,
            informativeValidate2: data[2].informativeValidate.count, informativeValidateList2: data[2].informativeValidate.chapterList,
            //informativeCancelled2: data[2].informativeCancelled.count, informativeCancelledList2: data[2].informativeCancelled.chapterList,

            informativeAnswered3: data[3].informativeAnswered.count, informativeAnsweredList3: data[3].informativeAnswered.chapterList,
            informativeToAnswer3: data[3].informativeToAnswer.count, informativeToAnswerList3: data[3].informativeToAnswer.chapterList,
            // informativeAttachmentWaiting2:    data[2].informativeAttachmentWaiting.count,    informativeAttachmentWaitingList2: data[2].informativeAttachmentWaiting.chapterList,
            // informativeAttachmentToValidate2: data[2].informativeAttachmentToValidate.count, informativeAttachmentToValidateList2: data[2].informativeAttachmentToValidate.chapterList,
            informativeMildNc3: data[3].informativeMildNc.count, informativeMildNcList3: data[3].informativeMildNc.chapterList,
            informativeWaitNc3: data[3].informativeWaitNc.count, informativeWaitNcList3: data[3].informativeWaitNc.chapterList,
            //informativeGraveNc3: data[3].informativeGraveNc.count, informativeGraveNcList2: data[3].informativeGraveNc.chapterList,
            informativeTotal3: data[3].informativeTotal.count, informativeTotalList3: data[3].informativeTotal.chapterList,
            informativeValidate3: data[3].informativeValidate.count, informativeValidateList3: data[3].informativeValidate.chapterList,
            //informativeCancelled2: data[2].informativeCancelled.count, informativeCancelledList2: data[2].informativeCancelled.chapterList,
        })

    }


    componentDidMount() {


        // if(!!this.props.data){
        //     this.setState({values:this.getValues(this.props.data)});

        // }else{

        informativeStateReport_Get(this.props.collect.id, 0).then(r => {

            this.setState({ values: this.getValues(r) });


        })
        // }


    }

    render() {


        renderlog("InformativeStateReportModal", this.state, this.props, COLOR_POLICY.validated);


        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm" />
                <span className={"loading-text-alignment"}>Caricamento...</span>
            </React.Fragment>
        );



        if (this.state.values) {

            // debugger
            context = (

                <React.Fragment>
                    <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                        <Col>
                            <br />
                            {/* <SearchBar {...props.searchProps} /> */} <br />



                            <Table>
                                <thead>
                                    <tr>
                                        <th>Stato dell'informativa </th>
                                        <th>Generale</th>
                                        <th>Economico</th>
                                        <th>Ambientale</th>
                                        <th>Sociale</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    <tr >
                                        <td style={{ background: '#dad8fd' }}><b>Tutte</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('informativeTotal0', this.state.values.informativeTotal0, this.state.values.informativeTotalList0)}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('informativeTotal1', this.state.values.informativeTotal1, this.state.values.informativeTotalList1)}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('informativeTotal2', this.state.values.informativeTotal2, this.state.values.informativeTotalList2)}</b></td>
                                        <td style={{ background: '#dad8fd' }}><b>{this.attachThematicsList('informativeTotal3', this.state.values.informativeTotal2, this.state.values.informativeTotalList3)}</b></td>
                                        <td style={{ background: '#dad8fd' }} className="tableBorderLeft">{this.state.values.informativeTotalSum}</td>
                                    </tr>

                                    {
                                        !!this.props.isAssurance
                                            ? null
                                            : (
                                                <tr>
                                                    <td>{ translate("To Complete") }</td>
                                                    <td>{this.attachThematicsList('informativeToAnswer0', this.state.values.informativeToAnswer0, this.state.values.informativeToAnswerList0,)}</td>
                                                    <td>{this.attachThematicsList('informativeToAnswer1', this.state.values.informativeToAnswer1, this.state.values.informativeToAnswerList1,)}</td>
                                                    <td>{this.attachThematicsList('informativeToAnswer2', this.state.values.informativeToAnswer2, this.state.values.informativeToAnswerList2,)}</td>
                                                    <td>{this.attachThematicsList('informativeToAnswer3', this.state.values.informativeToAnswer3, this.state.values.informativeToAnswerList3,)}</td>
                                                    <td className="tableBorderLeft">{this.state.values.informativeToAnswerSum}</td>
                                                </tr>
                                            )
                                    }


                                    <tr>
                                        <td>
                                            {
                                                !!this.props.isAssurance
                                                    ? "Da Validare"
                                                    : "Completate"
                                            }
                                        </td>
                                        <td>{this.attachThematicsList('informativeAnswered0', this.state.values.informativeAnswered0, this.state.values.informativeAnsweredList0,)}</td>
                                        <td>{this.attachThematicsList('informativeAnswered1', this.state.values.informativeAnswered1, this.state.values.informativeAnsweredList1,)}</td>
                                        <td>{this.attachThematicsList('informativeAnswered2', this.state.values.informativeAnswered2, this.state.values.informativeAnsweredList2,)}</td>
                                        <td>{this.attachThematicsList('informativeAnswered3', this.state.values.informativeAnswered3, this.state.values.informativeAnsweredList3,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.informativeAnsweredSum}</td>
                                    </tr>

                                    <tr>
                                        <td>Richiesta di chiarimento o modifiche</td>
                                        <td>{this.attachThematicsList('informativeMildNc0', this.state.values.informativeMildNc0, this.state.values.informativeMildNcList0,)}</td>
                                        <td>{this.attachThematicsList('informativeMildNc1', this.state.values.informativeMildNc1, this.state.values.informativeMildNcList1,)}</td>
                                        <td>{this.attachThematicsList('informativeMildNc2', this.state.values.informativeMildNc2, this.state.values.informativeMildNcList2,)}</td>
                                        <td>{this.attachThematicsList('informativeMildNc3', this.state.values.informativeMildNc3, this.state.values.informativeMildNcList3,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.informativeMildNcSum}</td>

                                    </tr>

                                    <tr>
                                        <td>In attesa di validazione</td>
                                        <td>{this.attachThematicsList('informativeWaitNc0', this.state.values.informativeWaitNc0, this.state.values.informativeWaitNcList0,)}</td>
                                        <td>{this.attachThematicsList('informativeWaitNc1', this.state.values.informativeWaitNc1, this.state.values.informativeWaitNcList1,)}</td>
                                        <td>{this.attachThematicsList('informativeWaitNc2', this.state.values.informativeWaitNc2, this.state.values.informativeWaitNcList2,)}</td>
                                        <td>{this.attachThematicsList('informativeWaitNc3', this.state.values.informativeWaitNc3, this.state.values.informativeWaitNcList3,)}</td>
                                        <td className="tableBorderLeft">{this.state.values.informativeWaitNcSum}</td>
                                    </tr>

                                    <tr>
                                        <td>Validate</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('informativeValidateNc0', this.state.values.informativeValidate0, this.state.values.informativeValidateList0,)}</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('informativeValidateNc1', this.state.values.informativeValidate1, this.state.values.informativeValidateList1,)}</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('informativeValidateNc2', this.state.values.informativeValidate2, this.state.values.informativeValidateList2,)}</td>
                                        <td className="tableBorderTop">{this.attachThematicsList('informativeValidateNc3', this.state.values.informativeValidate3, this.state.values.informativeValidateList3,)}</td>
                                        <td className="tableBorderLeft tableBorderTop">{this.state.values.informativeValidateSum}</td>
                                    </tr>

                                </tbody>
                            </Table>




                        </Col>
                    </Row>

                </React.Fragment>
            );
        }




        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Report Informative
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>


        );



        if (!!this.props.NoModal) {
            return context;
        } else {
            return content;
        }


    }








}




