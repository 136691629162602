import React from "react";
import {
	Row
	, Col
	, Card
	, CardBody
	, Form
	, FormGroup
	, Label
	, Input
	, Button
	, CustomInput
	, Progress
} from 'reactstrap';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { Wizard, Steps, Step } from 'react-albus';
import {TREE_SECTION, TREE_TYPE} from "../../../env";
import TreeGriCollectComponent from "./tree-component";
import TreeGriCollectReadOnlyComponent from "./tree-component-readonly";
import TreeGriCollectDataEntryComponent from "./tree-dataentry-component";
import {treeSetConfirm} from "../../../business-logic/gri";
import {WizardPanel} from "./tree-wizard/wizard-panel";
import SetTreeStep from "./tree-wizard/set-tree-step";
import {ZeroStep} from "./tree-wizard/zero-step";
import {MaterialThemesStep} from "./tree-wizard/material-themes-step";
import {ValueTreeStep} from "./tree-wizard/value-tree-step";
import {RecapStep} from "./tree-wizard/recap-step";
import {showlog} from "../../../utils/dev-utils";
import {withRouter} from "react-router-dom";
import Spinner from "../../Spinner";

// import {  } from "querystring";
// import {  } from 'react-router-dom';
// import { useHistory, useParams } from "react-router-dom";


class TreeWizard extends React.Component {
	
	state = {
		treeType: TREE_TYPE.SET.id,
		tree: this.props.tree,
		collect: this.props.collect,
		fullDataCollect: this.props.fullDataCollect
		
	}
	
	constructor( props ) {
		super( props );
		
		
		// this.history = useHistory();
		// const { params } = useParams();
		
		showlog("TreeWizard constructor");
		showlog(props);
		showlog(this.props);
		
	}
	
	render() {
		
		console.log(
			"%c RENDER %c TREE wizard ",
			"background:#38f; color:#ddd; border-radius:3px 0px 0px 3px; padding:3px;",
			"background:#38f8; color:#fff; border-radius:0px 3px 3px 0px; padding:3px;"
		);
		console.log(this.state);
		console.log(this.props);
		
		
		
		
		
		return (
			<Card>
				<CardBody>
					<Wizard
						render={({ step, steps }) => {
							
							
							console.log(
								"%c  INNER  %c  render wizard  ",
								"background:#3f8; color:#222; border-radius:3px 0px 0px 3px; padding:3px;",
								"background:#3f88; color:#444; border-radius:0px 3px 3px 0px; padding:3px;"
							);
							console.log(step);
							
							
								showlog(this.props.location.search);
								if( !!this.props.location.search.split("=")[1] ) {
									
									
									switch ( this.props.location.search.split("=")[1] ) {
										case TREE_SECTION.CONFIGURATION:
											break;
										case TREE_SECTION.READ_ONLY_CONFIGURATION:
											break;
										case TREE_SECTION.MATERIAL_THEMES:
											/*
											this.setState(
												{
													...this.state,
													treeType: TREE_TYPE.MATERIAL_THEMES.id
												},
												() => {
													step = { id: TREE_TYPE.MATERIAL_THEMES.id }
												}
											);
											*/
											break;
										case TREE_SECTION.DATA_ENTRY:
											/*
											showlog("parte navigazione a dataentry");
											this.setState(
												{
													...this.state,
													treeType: TREE_TYPE.VALUE_ENTRY.id
												},
												() => {
													showlog("navigazione a dataentry");
													// step = { id: TREE_TYPE.VALUE_ENTRY.id }
												}
											);
											// step = { id: TREE_TYPE.VALUE_ENTRY.id }
											
											*/
											
											break;
										case TREE_SECTION.RECAP:
											break;
										default:
										
											break;
									}
									
									
									
									
									/*
									if (step.id === TREE_TYPE.SET.name) {
										if(
											this.props.location.search.split("=")[1] !== TREE_SECTION.CONFIGURATION &&
											this.props.location.search.split("=")[1] !== TREE_SECTION.READ_ONLY_CONFIGURATION
										) {
											// bisogna andare avanti
											this.setState(
												{
													...this.state,
													treeType: TREE_TYPE.MATERIAL_THEMES.id
												},
												() => {
													step = { id: TREE_TYPE.MATERIAL_THEMES.id }
												}
											);
										}
									}
									if(step.id === TREE_TYPE.MATERIAL_THEMES.name) {
										if( this.props.location.search.split("=")[1] !== TREE_SECTION.MATERIAL_THEMES ) {
											// bisogna andare avanti
											this.setState(
												{
													...this.state,
													treeType: TREE_TYPE.VALUE_ENTRY.id
												},
												() => {
													step = { id: TREE_TYPE.VALUE_ENTRY.id }
												}
											);
											
										}
									}
									if(step.id === TREE_TYPE.VALUE_ENTRY.name) {
									
									}
									*/
									
								}
								
							
							
							return (
								<React.Fragment>
									
									
									<WizardPanel
										progress={ ((steps.indexOf(step) + 1) / steps.length) * 100 }
										progressLabel={ ((steps.indexOf(step) + 1) +" di "+ steps.length) }
										title={
											Object.keys(TREE_TYPE)
												.filter( type => this.state.treeType === TREE_TYPE[type].id )
												.map( type => {
													if( this.props.step !== 1 && TREE_TYPE[type].id === 1 ) {
														type = "READ_ONLY";
													}
													return type;
												})
												.map( treeType => TREE_TYPE[treeType].name )
												.join("")
										}
									/>
									
									
									
									<Steps>
										
										
										
										
										{/*----[    1   ]-----------------------------------------------------------------------------*/}
										<Step
											id={ TREE_TYPE.SET.name }
											render={ ({ next }) => {
												
												
												showlog("controllo parametro allo step 1");
												showlog( this.props );
												if( !this.props.location.search ) {
													if( this.props.step === 1 ) this.props.history.push({search: "section="+ TREE_SECTION.CONFIGURATION});
													else                        this.props.history.push({search: "section="+ TREE_SECTION.READ_ONLY_CONFIGURATION});
												}
												
												return  (
													<SetTreeStep
														step={ this.props.step }
														readOnly={ (this.state.treeType !== TREE_TYPE.SET.id) }
														nextStep={ updatedTree => {
															this.setState(
																{
																	...this.state,
																	tree: updatedTree || this.props.tree,
																	treeType: TREE_TYPE.MATERIAL_THEMES.id
																},
																() => {
																	this.props.history.push({search: "section="+ TREE_SECTION.MATERIAL_THEMES});
																	next();
																}
															);
														}}
														tree={ this.props.tree }
														collect={ this.state.collect }
														fullDataCollect={ this.state.fullDataCollect }
														onUpdate={ (tree, tabId) => {
															
															
															return this.props.onForceRefresh( tabId );
															
															/*
															this.setState({
																...this.state,
																tree: freshTree
															},
																() => {
																	this.props.onForceRefresh();
																}
															);
															*/
															
														}}
													/>
											)}}
										/>
				
				
										
										{/*-----[   2   ]----------------------------------------------------------------------------*/}
										<Step
											id={ TREE_TYPE.MATERIAL_THEMES.name }
											render={({ next, previous }) => {
												
												
												
												showlog("controllo parametro allo step 2");
												showlog( this.props );
												if( !this.props.location.search ) this.props.history.push({search: "section="+ TREE_SECTION.MATERIAL_THEMES});
												
												
												
												
												
												return (
													<MaterialThemesStep
														tree={ this.state.tree }
														collect={ this.state.collect }
														fullDataCollect={ this.state.fullDataCollect }
														seed={ this.props.seed }
														previousStep={ () => {
															this.setState({
																...this.state,
																treeType: TREE_TYPE.READ_ONLY.id
															},
																() => {
																	this.props.history.push({search: "section="+ TREE_SECTION.READ_ONLY_CONFIGURATION});
																	previous();
																}
															);
														}}
														nextStep={ () => {
															this.setState( {
																...this.state,
																treeType: TREE_TYPE.VALUE_ENTRY.id
															},
																() => {
																	this.props.history.push({search: "section="+ TREE_SECTION.DATA_ENTRY});
																	next();
																}
															);
														}}
													/>
											)}}
										/>
										
										{/*----[    3   ]-----------------------------------------------------------------------------*/}
										<Step
											id={ TREE_TYPE.VALUE_ENTRY.name }
											render={({ next, previous }) => {
												
												showlog("sezione valori");
												showlog(this.props);
												if( !this.props.location.search ) this.props.history.push({search: "section="+ TREE_SECTION.DATA_ENTRY});
												
												
												
												
												
												return (
												
													<ValueTreeStep
														tree={ this.state.tree }
														collect={ this.state.collect }
														fullDataCollect={ this.state.fullDataCollect }
														seed={ this.props.seed }
														nextStep={ () => {
															this.setState(
																{
																	...this.state,
																	treeType: 99
																},
																() => {
																	this.props.history.push({search: "section="+ TREE_SECTION.RECAP});
																	next();
																}
															);
														}}
														previousStep={ () => {
															this.setState({
																...this.state,
																treeType: TREE_TYPE.MATERIAL_THEMES.id
															},
																() => {
																	this.props.history.push({search: "section="+ TREE_SECTION.MATERIAL_THEMES});
																	previous();
																}
															);
														}}
														
														
														onUpdate={ (tree, tabId) => {
															return this.props.onForceRefresh( tabId );
														}}
														
													/>
												
												
											)}}
										/>
										
										{/*----[    4   ]   -----------------------------------------------------------------------------*/}
										<Step
											id={ "finish" }
											render={({ previous }) => {
												
												
												showlog("sezione recap");
												if( !this.props.location.search ) this.props.history.push({search: "section="+ TREE_SECTION.RECAP});
												
												return (
													<RecapStep
														collect={ this.state.collect }
														collectName={ this.state.fullDataCollect.name }
														previousStep={ () => {
															this.setState(
																{
																	...this.state,
																	treeType: TREE_TYPE.VALUE_ENTRY.id
																},
																() => {
																	this.props.history.push({search: "section="+ TREE_SECTION.DATA_ENTRY});
																	previous();
																}
															);
														}}
													/>
											)}}
										/>
									</Steps>
								</React.Fragment>
							)
							
							
						}}
					/>
				</CardBody>
			</Card>
		);
		
	}
	
	componentDidMount() {
	
		console.log("wizard did mount");
		
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		console.log(
			"%c UPDATE %c TREE wizard",
			"background:#38f; color:#ddd; border-radius:3px 0px 0px 3px; padding:3px;",
			"background:#38f8; color:#fff; border-radius:0px 3px 3px 0px; padding:3px;"
		);
		// console.log(this.state);
	}
	
}


export default withRouter( TreeWizard );
