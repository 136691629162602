// SIRatingChartPage
import React, {Component} from "react";
import {Card, CardBody, Col, Label, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../../../../components/PageTitle";
import {COLOR_POLICY, DATE_FORMAT_IT, PATH_PREFIX, permissions, RATING_STATUS, role} from "../../../../env";
import {loadEvaluationsDashboard, progressBar} from "../../../../business-logic/active-service";
import {errorlog, renderlog, showlog} from "../../../../utils/dev-utils";
import {Link, Redirect} from "react-router-dom";
import {hasPermission, isUserRole} from "../../../../business-logic/auth";
import AddRatingModal from "../../../../components/area/super-admin/new-rating-modal";
import {getLastElementOr, inverseSortById, onlyNotNull} from "../../../../utils/array-utils";
import {emoj} from "../../../../utils/string-utils";
import {EMOJ} from "../../../../emoj";
import ErrorMessageModalComponent from "../../../../components/error-message-modal-component";
import QuestionReport from "../../../../components/area/super-admin/questionReport-modal";

import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import StateModal from "../../../../components/area/super-admin/state-modal";
import {getAllCurrentPermissions} from "../../../../utils/role-utils";
import Select from 'react-select';
import EvaluationRolesArbModal from "../../../../components/area/super-admin/evaluationRoles-list-modal";
import moment from "moment";
import {translate} from "../../../../components/i18n/translate-function";
import SiRatingLogoDarkText from "../../../../assets/images/SiRating_logo_dark_text_2.svg";

const hiddenFields = [
    "companyId",
    "dimensionId",
    "dimensionName",
    "dimension",
    "companySize",
    "codCliFor",
    "undefined",
    "files",
    "id",
    "status",
    "userRoles",
    "lastModifiedStatus",
    "profilingAcceptDate",
    "expirationDate",
    "attachmentsToValidate",

    (!!isUserRole( role.SUPER_ADMIN ) ? "manager" : null),
    (!!isUserRole( role.SUPER_ADMIN ) ? "assignee" : null),
    (!!isUserRole( role.SUPER_ADMIN ) ? "evaluationButton" : null)
]
.filter( onlyNotNull)
;

const fieldMap = {
    id: "id",
    codCliFor: "companyId",
    name: translate("Name"),
    dateCreate: translate("Creation Date"),
    ragioneSociale: translate("Company Name"),
    dimensionName: "Dimensione",
    state: translate("Status"),
    lastModifiedStatus:"Ultima Varizione di Stato",
    profilingAcceptDate:"Data Accettazione Profilazione",
    expirationPercentage: translate("Expiry Date"),
    nc: "NC " +  "( " + translate("Mild") + " / " + translate("Waiting") +" )",
    attachmentsPending: translate("Attachments") + " ( " + translate("Required") + " / " + translate("To Be Validated") + " )",
    scoreButton: translate("Questions Report"),
    userRoles: "Ruoli",
    files: translate("Attachments"),
    status: "status",
    manager:"PM",
    assignee:"Assignee",
    evaluationButton: translate('Operator Actions History')

};

const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Rating")}
        </span>
    ),
    sizePerPageList: [
        {
            text: "pochi",
            value: 25,
        },
        {
            text: "normale",
            value: 50,
        },
        {
            text: "molti",
            value: 250,
        }
    ]
};

const defaultSorted = [
    {
        dataField: "Id",
        order: "asc",
    },
];

const sortableFields = [
    translate("Name"),
    translate("Creation Date"),
    translate("Company Name"),
    "Dimensione",
    "Status",
    "Ultima Varizione di Stato"
];


const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SIRatingChartPage extends Component {

    state = {
        // data: null
    }

    constructor(props) {
        super( props );
        this.loadData = this.loadData.bind( this );
        this.permissionDenied = this.permissionDenied.bind( this );
        this.ratingFilterComponent = this.ratingFilterComponent.bind( this );
    }


    permissionDenied( permission ) {
        return (
            <div className={"table-inner-pannel card-permission-denied-bg"}>
                <Row>
                    { emoj( EMOJ.police_car_light ) }
                    <code className={ COLOR_POLICY.error.textClass }>&nbsp;&nbsp;PERMESSO NEGATO&nbsp;&nbsp;</code>
                    { emoj( EMOJ.police_car_light ) }
                </Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>
                    <em>{ permission.label }</em>
                </Row>
            </div>
        )
    }


    ratingFilterComponent() {
        return (
            <React.Fragment>
                <Label for="rating-filter">{translate("Filter")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                <Select
                    className="react-select question-filter-select"
                    classNamePrefix="react-select"
                    name="rating-filter"
                    id="rating-filter"
                    onChange={ changeEvent => {

                        if( changeEvent.value === -1 ) {
                            this.setState({
                                ...this.state,
                                data: this.state.allData,
                                activeFilter: changeEvent.value
                            })
                        }
                        else {
                            this.setState({
                                ...this.state,
                                data: this.state.allData.filter( d => d.status === changeEvent.value ),
                                activeFilter: changeEvent.value
                            })
                        }
                    }}
                    options={
                        [
                            { value: -1, label: translate("All") },
                            ...Object.keys( RATING_STATUS )
                                .map( status => RATING_STATUS[ status ] )
                                .map( ratingStatus => ({
                                    value: ratingStatus.id,
                                    label: ratingStatus.name
                                }))
                                .sort( inverseSortById )
                        ]
                    }
                    placeholder={ <span className={"select-placeholder"}>{translate("Select filter")}</span> }
                />
            </React.Fragment>
        );
    }


    render() {

        renderlog( "SIRating Chart", this.state, this.props);
        showlog("permessi correnti", getAllCurrentPermissions() );

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                       {
                            label: "SI Rating" /*
                            Object.keys( role )
                                .map( roleObjectName => role[ roleObjectName ] )
                                .filter( roleObject => roleObject.name === localStorage.getItem("role") )
                                .reduce( getLastElementOr, "Nessun Ruolo" )
                                .label*/
                            , path: PATH_PREFIX +"/welcome" },
                        { label: translate("Evaluations Dashboard"), active: true }
                    ]}
                    title={
                        <React.Fragment>
                            {/*
                            <img
                                src={ PRODUCTION_HOST +"/assets/Loghi/SiRating.png" }
                                alt={"Si Rating"}
                                height="30"
                                style={{marginRight:'10px'}}
                            />
                            */}
                            <img
                                // src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
                                src={ SiRatingLogoDarkText }
                                style={{
                                    marginRight:'10px',
                                    height: "60px"
                                }}
                            />
                            {/*<span>Dashboard</span>*/}
                        </React.Fragment>
                    }
                />


                {
                    (!!this.state.userRolesSa)
                        ? (
                            <EvaluationRolesArbModal
                                type={1}
                                evaluationId= { this.state.userRolesSa}
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        userRolesSa: null
                                    })
                                }}
                                onChoose={ list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        userRolesSa: null
                                    })

                                }}
                            />
                        )
                        : null
                }
                {
                    (!!this.state.userRolesArb)
                        ? (
                            <EvaluationRolesArbModal
                                type={2}
                                evaluationId= {this.state.userRolesArb}
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        userRolesArb: null
                                    })
                                }}
                                onChoose={ list => {
                                    showlog("lista dei settori scelti");
                                    showlog(list);

                                    this.setState({
                                        ...this.state,
                                        userRolesArb: null
                                    })

                                }}
                            />
                        )
                        : null
                }







                {
                    (!!this.state.qustionReportModal)
                        ? (
                            <QuestionReport
                                isAudit={false}
                                evaluation_id={ this.state.evaluation_id }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        qustionReportModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.evaluationReportModal)
                        ? (
                            <StateModal
                                evaluation_id={ this.state.evaluation_id }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        evaluationReportModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }




                <Card>
                    <CardBody>




                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            search
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                            {props => (
                                <React.Fragment>
                                    <Row>

                                        <Col md={ 4 }>
                                            { this.ratingFilterComponent() }
                                        </Col>
                                        <Col md={ 6 }>&nbsp;</Col>
                                        <Col md={ 2 }>
												<span className={"float-right"}>
													<SearchBar {...props.searchProps} />
												</span>
                                        </Col>
                                        {/*
											<Col className="text-right">
												<ExportCSVButton {...props.csvProps} className="btn btn-primary">
													Export CSV
												</ExportCSVButton>
											</Col>
											*/}
                                    </Row>


                                    {


                                        (!!this.state.data && this.state.data.length > 0 )
                                            ? (

                                                <BootstrapTable
                                                    bootstrap4
                                                    striped
                                                    {...props.baseProps}
                                                    size="sm"
                                                    responsive
                                                    keyField="id"
                                                    data={ this.state.data }
                                                    columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
                                                    defaultSorted={ defaultSorted }
                                                    pagination={ paginationFactory(paginationOptions) }
                                                    wrapperClasses="table-responsive"

                                                    expandRow={{
                                                        renderer: row => {


                                                            if(
                                                                row.status === RATING_STATUS.configurationValidated.id
                                                                && !isUserRole( role.SUPER_ADMIN )
                                                                && !!isUserRole( role.ARB_OPERATOR )
                                                            ) {

                                                                return (
                                                                    <span>
                                                                        Valutazione in attesa di validazione della profilazione da parte del cliente
                                                                    </span>
                                                                );

                                                            }
                                                            return null;


                                                        },
                                                        showExpandColumn: false,
                                                        onlyOneExpanding: true,
                                                        // expanded: [1, 3] // should be a row keys array
                                                        // nonExpandable: [1, 3 ,5]
                                                        // onExpand: (row, isExpand, rowIndex, e) => {}
                                                        // onExpandAll: (isExpandAll, results, e) => {}
                                                        // showExpandColumn: true
                                                        // expandByColumnOnly: true

                                                        className: (isExpanded, row, rowIndex) => {
                                                            if (!!isExpanded) return 'inner-row';
                                                            return '';
                                                        },

                                                        parentClassName: (isExpanded, row, rowIndex) => {
                                                            if (!!isExpanded) return 'inner-row-active';
                                                            return '';
                                                        }
                                                    }}

                                                />

                                            )
                                            : (
                                                <WaitingOrNodataMessage
                                                    waiting={ !(!!this.state.data && this.state.data.length === 0) }
                                                    fullHeight={ true }
                                                    waitMessage={<span>Caricamento ... <br/><em style={{fontSize: "0.95rem", marginLeft: "1rem"}}>potrebbe volerci un pò</em></span>}
                                                    nodataMessage={"Nessuna Valutazione"}
                                                />
                                            )
                                    }


                                </React.Fragment>
                            )}
                        </ToolkitProvider>

                    </CardBody>
                </Card>


                {
                    (!!this.state.newRatingModal)
                        ? (
                            <AddRatingModal
                                ratingList={ this.state.data }
                                onClose={ errorMessage => {

                                    this.setState(
                                        {
                                            ...this.state,
                                            newRatingModal: false,
                                            errorMessage: (!!errorMessage ? emoj( EMOJ.sad_but_relieved_face ) +"  "+ errorMessage : null)
                                        },
                                        () => {
                                            if( !errorMessage ) this.loadData();
                                            else {
                                                // c'è stato un errore

                                            }
                                        }
                                    );


                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.redirect)
                        ? (
                            <Redirect
                                to={{
                                    pathname: this.state.redirect,
                                    state: {
                                        ratingId: this.state.ratingId,
                                        pdfName: this.state.pdfName
                                    }
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.errorMessage)
                        ? (
                            <ErrorMessageModalComponent
                                message={this.state.errorMessage}
                                contentClassName={"big-font"} // choiche-status sirating-question-attachment-modal-icon comander-icon
                                noheader={true}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        errorMessage: null
                                    })
                                }}
                            />
                        )
                        : null
                }


            </React.Fragment>
        );
    }

    componentDidMount() {

        // errorlog("prova");
        // warninglog("Prova");

        this.loadData();
    }


    loadData() {
        loadEvaluationsDashboard()



            // adjust ---------------------------------------------------------------------------------------------------
            .then(list => {
                return (
                    list
                        .map((evaluation,i) => {



                            showlog("valutazione", evaluation);



                            switch ( parseInt(evaluation.state, 10) ) {

                                // 0  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.configurationRunning.id:
                                    evaluation.name = <Link key={i} to={ PATH_PREFIX +"/si-rating/" + translate("perimetral") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    break;

                                // 3  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.perimetralQuestionCompleted.id:
                                    evaluation.name = <Link key={i} to={ PATH_PREFIX +"/si-rating/" + translate("perimetral") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    break;

                                // 5  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.perimetralQuestionValidated.id:
                                    if( !isUserRole( role.SUPER_ADMIN ) ) {
                                        evaluation.name = <Link key={i} to={ PATH_PREFIX +"/si-rating/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    }
                                    else {
                                        // mancante

                                    }
                                    break;

                                // 10  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.configurationComplete.id:
                                    if( !!hasPermission( permissions.GO_TO_RATING_CONFIGURATION_AFTER_COMPLETATION ) ) {
                                        evaluation.name = <Link key={i} to={ PATH_PREFIX +"/si-rating/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    }
                                    else {
                                        evaluation.name = <Link key={i} to={ PATH_PREFIX +"/super-admin/" + translate("configuration") + "/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    }
                                    break;

                                // 20  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.configurationValidated.id:

                                    if( !isUserRole( role.SUPER_ADMIN ) ) {

                                        if( !!isUserRole( role.ARB_OPERATOR ) ) {
                                            evaluation.name = (
                                                <span
                                                    className={"all-rating-dashboard-link cursor-pointer"}
                                                    onClick={ clickEvent => {
                                                        // clickEvent.stopPropagation();
                                                        // alert("Valutazione in attesa di validazione della profilazione da parte del cliente");
                                                    }}
                                                    id={ evaluation.id +"-"+ RATING_STATUS.configurationValidated.id +"_"+ RATING_STATUS.configurationValidated.name }
                                                >
                                                    <strong>{ evaluation.name }</strong>
                                                </span>
                                            );
                                        }
                                        else {
                                            evaluation.name = <Link key={i} to={ PATH_PREFIX +"/si-rating/" +  translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                        }

                                    }
                                    break;

                                // 25  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.perimetralQuestionAccepted.id:

                                   evaluation.name = (
                                        <Link key={i} to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ evaluation.id }>
                                                <span
                                                    className={"all-rating-dashboard-link"}
                                                    id={ evaluation.id +"-"+ RATING_STATUS.perimetralQuestionAccepted.id +"_"+ RATING_STATUS.perimetralQuestionAccepted.name }
                                                >
                                                    { evaluation.name }
                                                </span>
                                        </Link>
                                    );

                                    /*
                                    if( !!isUserRole( role.ADMIN ) || !!isUserRole( role.SR_ADMIN ) || !!isUserRole( role.SUPER_ADMIN ) ) {
                                        evaluation.name = (
                                            <Link key={i} to={ PATH_PREFIX +"/sirating-admin/evaluation/"+ evaluation.id }>
                                                <span
                                                    className={"all-rating-dashboard-link"}
                                                    id={ evaluation.id +"-"+ RATING_STATUS.perimetralQuestionAccepted.id +"_"+ RATING_STATUS.perimetralQuestionAccepted.name }
                                                >
                                                    { evaluation.name }
                                                </span>
                                            </Link>
                                        );
                                    }
                                    else {
                                        evaluation.name = (
                                            <Link key={i} to={ PATH_PREFIX +"/super-admin/configuration/evaluation/"+ evaluation.id }>
                                                <span
                                                    className={"all-rating-dashboard-link"}
                                                    id={ evaluation.id +"-"+ RATING_STATUS.perimetralQuestionAccepted.id +"_"+ RATING_STATUS.perimetralQuestionAccepted.name }
                                                >
                                                    { evaluation.name }
                                                </span>
                                            </Link>
                                        );
                                    }
                                    */



                                    break;

                                // 30  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.ratingComplete.id:
                                    if( !!hasPermission( permissions.RATING_AUDIT ) ) {
                                        evaluation.name = <Link key={i} to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    }
                                    else {
                                        evaluation.name = <Link key={i} to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    }
                                    break;

                                // 35  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.auditComplete.id:
                                    evaluation.name = <Link key={i} to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    break;

                                // 40  ------------------------------------------------------------------------------------------------
                                case RATING_STATUS.auditDone.id:
                                    if( !!hasPermission( permissions.SHOW_RATING_AUDITED ) ) {
                                        evaluation.name = <Link key={i} to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                    }
                                    else {

                                        if( !!isUserRole( role.SUPER_ADMIN ) ) {
                                            evaluation.name = <Link key={i} to={ PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ evaluation.id }><span className={"all-rating-dashboard-link"} >	{evaluation.name} </span></Link>;
                                        }
                                        else {
                                            // non hai il permesso di cliccare il link
                                            // this.permissionDenied( permissions.RATING_AUDIT );

                                        }

                                    }
                                    break;
                                default:

                                    evaluation.name = <Link  key={i} to={"./sirating-admin/" + translate("evaluation") +  "/"+evaluation.id}><span style={{color:'#727cf5',fontWeight:'bold'}}>	{evaluation.name} </span></Link>;
                                    break;
                            }



                            // showlog("controllo rating status", evaluation.status, !isUserRole( role.SUPER_ADMIN ), !!isUserRole( role.ARB_OPERATOR ) );
                            // showlog( evaluation.status, evaluation.state );
                            // showlog( evaluation );
                            // beautifyLog( evaluation );





                            //evaluation.name = evaluation.name;
                            evaluation.dateCreate = moment(evaluation.dateCreate).format(DATE_FORMAT_IT);
                            evaluation.lastModifiedStatus = moment(evaluation.lastModifiedStatus).format(DATE_FORMAT_IT);
                            evaluation.profilingAcceptDate = moment(evaluation.profilingAcceptDate).format(DATE_FORMAT_IT);
                            evaluation.expirationDate = moment(evaluation.expirationDate).format(DATE_FORMAT_IT);
                            evaluation.dimensionId = evaluation.dimension.id;
                            evaluation.dimensionName = evaluation.dimension.name;
                            evaluation.dimensionName = evaluation.dimension.name;
                            //evaluation.state = <div>{evaluation.state}<br/>{moment(evaluation.lastModifiedStatus).format(DATE_FORMAT_IT)}</div>;
                            //evaluation.state = <div>ciao</div>;


                            evaluation.nc = <div style={{ textAlign: 'center' }}> {evaluation.nc} </div>;
                            evaluation.attachmentsPending = <div style={{ textAlign: 'center' }}> {evaluation.attachmentsPending +" / "+evaluation.attachmentsToValidate} </div>;
                            evaluation.assignee = <div style={{ textAlign: 'center' }}>

                                {evaluation.assignee.map((x,y) =>

                                    <React.Fragment>
                                        <div id={`popover-${i+"-"+y}`} className={"profile-picture-in-list picture-alternative"} style={{ display: 'inline-block', marginLeft: '-6px' }}> {x.acronym} </div>
                                        <div>
                                            <UncontrolledPopover placement={`popover-${i+"-"+y}`} target={`popover-${i+"-"+y}`}>
                                                <PopoverBody>{x.name}</PopoverBody>
                                            </UncontrolledPopover>

                                        </div>
                                    </React.Fragment>

                                )
                                }
                            </div>

                            evaluation.manager = <div style={{ textAlign: 'center' }}>

                                {evaluation.manager.map((x,y) =>

                                    <React.Fragment>
                                        <div id={`popover2-${i+"-"+y}`} className={"profile-picture-in-list picture-alternative"} style={{ display: 'inline-block', marginLeft: '-6px' }}> {x.acronym} </div>
                                        <div>
                                            <UncontrolledPopover placement={`popover2-${i+"-"+y}`} target={`popover2-${i+"-"+y}`}>
                                                <PopoverBody>{x.name}</PopoverBody>
                                            </UncontrolledPopover>

                                        </div>
                                    </React.Fragment>

                                )
                                }
                            </div>


                            evaluation.expirationPercentage =
                                <div style={{ textAlign: 'center' }}>
                                    {

                                        (evaluation.expirationPercentage > 1000) ? "" :
                                            ((evaluation.expirationPercentage > 100) ? progressBar(100) : progressBar(evaluation.expirationPercentage))
                                    }

                                    {
                                        evaluation.profilingAcceptDate == "Invalid date" ? "" : "dal " + evaluation.profilingAcceptDate
                                    }
                                    <br />

                                    <p>
                                        {
                                            evaluation.expirationDate == "Invalid date" ? "" :

                                                ((evaluation.expirationDate == "01/01/0001") ? "" : "al " + evaluation.expirationDate)
                                        }
                                    </p>

                                </div>
                            return evaluation;
                        })
                );
            })




            // status -------------------------------------------------------------------------------------------------
            .then(list => {
                list
                    .map(r => {

                        // attenzione provvisorio
                        // if( r.id === 1058 ) r.state = 20;

                        r.status = r.state;
                        r.state =
                            Object.keys(RATING_STATUS)
                                .map(status => RATING_STATUS[status])
                                .filter(rs => rs.id === r.state)
                                .map(rs => rs.name)
                                .reduce(getLastElementOr, "Nessun Status")
                        ;



                    })
                return list;

            })

            .then(list => {
                list
                    .map(r => {

                        r.state = <div>{r.state}<br />{(r.lastModifiedStatus == "Invalid date") ? "" : r.lastModifiedStatus}</div>;
                        r.profilingAcceptDate = r.profilingAcceptDate == "Invalid date" ? "" : r.profilingAcceptDate;
                    })
                return list;

            })




            // logs   -----------------------------------------------------------------------------------------------
            .then( list => {
                // showlog("evaluations");
                // showlog( list );
                return list
            })




            // extras ---------------------------------------------------------------------------------------------------
            .then( list => {


                // showlog("lista");
                // showlog(list);

                list =
                    list
                        .map( (row, rowIndex) => {

                            // row.userRoles = (
                            //     <React.Fragment>
                            //         <i
                            //             style={{display: 'inline-block', marginRight:'10px'}}
                            //             className={"mdi mdi-account-star info-icon"}
                            //             onClick={ clickEvent => {
                            //                 clickEvent.stopPropagation();
                            //                 showlog("modale degli allegati");


                            //                 this.setState({
                            //                     ...this.state,
                            //                     userRolesSa: row.id
                            //                 })

                            //             }}
                            //         />



                            //         <i
                            //             style={{display: 'inline-block'}}
                            //             className={"mdi mdi-account-alert info-icon"}
                            //             onClick={ clickEvent => {
                            //                 clickEvent.stopPropagation();
                            //                 showlog("modale degli allegati");


                            //                 this.setState({
                            //                     ...this.state,
                            //                     // sgdScoreModal: 15,
                            //                     userRolesArb: row.id
                            //                 })

                            //             }}
                            //         />


                            //     </React.Fragment>


                            // );





                            let rowStatusId =
                                Object.keys( RATING_STATUS )
                                    .map( status => RATING_STATUS[ status ] )
                                    // .filter( ratingStatus => ratingStatus.name === (row.Status || row.status) )
                                    .filter( ratingStatus => ratingStatus.id === (row.Status || row.status) )
                                    .map( ratingStatus => ratingStatus.id )
                                    .reduce( getLastElementOr, 100 )
                            ;


                            // colonna Provvisoria
                            row.scoreButton = (
                                <React.Fragment>

                                    {

                                        (
                                            rowStatusId === RATING_STATUS.auditDone.id || !!isUserRole( role.PROJECT_MANAGER )
                                        )
                                            ? (
                                                <React.Fragment>

                                                    {
                                                        !!hasPermission( permissions.READ_SCORE_MODAL )
                                                            ? (
                                                                <span
                                                                    className={"mdi mdi-format-list-bulleted-type info-icon"}
                                                                    //style={{fontSize:"18px"}}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        this.setState({
                                                                            ...this.state,
                                                                            qustionReportModal: 15,
                                                                            evaluation_id: row.id
                                                                        })
                                                                    }}
                                                                >

																</span>
                                                            )
                                                            : null
                                                    }






                                                </React.Fragment>
                                            )
                                            : null
                                    }







                                </React.Fragment>

                            );

                            // colonna Provvisoria
                            row.evaluationButton = (
                                <React.Fragment>

                                    {

                                        (
                                            rowStatusId === RATING_STATUS.auditDone.id || !!isUserRole( role.PROJECT_MANAGER )
                                        )
                                            ? (
                                                <React.Fragment>

                                                    {
                                                        !!hasPermission( permissions.READ_SCORE_MODAL )
                                                            ? (
                                                                <span
                                                                    className={"mdi mdi-account-key info-icon"}
                                                                    //style={{fontSize:"18px"}}
                                                                    onClick={clickEvent => {
                                                                        clickEvent.stopPropagation();
                                                                        this.setState({
                                                                            ...this.state,
                                                                            evaluationReportModal: 15,
                                                                            evaluation_id: row.id
                                                                        })
                                                                    }}
                                                                >

                                                                </span>
                                                            )
                                                            : null
                                                    }






                                                </React.Fragment>
                                            )
                                            : null
                                    }







                                </React.Fragment>

                            );


                            return row;
                        })

                return list;
            })




            // field sort   -------------------------------------------------------------------------------------------
            .then( responseData => {
                return (
                    responseData
                        .map( row => {

                            let sortedRow = {};
                            Object.keys(fieldMap)
                                .map( field => {
                                    sortedRow[field] = row[field];
                                })
                            ;
                            Object.assign(sortedRow, row);
                            return sortedRow;

                        })
                );
            })


            /*
            // Dimensions label ----------------------------------------------------------------------------
            .then( list => {
                list
                    .map( r => {

                        switch ( r.dimensionId ) {
                            case 0:
                                r.dimensionName = r.dimensionName;
                                break;
                            case 1:
                                r.dimensionName = "Piccola/Media";
                                break;
                            case 2:
                                r.dimensionName = "Grande";
                                break;
                        }

                        return r;

                    })
                return list;

            })



            // field map ----------------------------------------------------------------------------------------------
            .then( list => {

                // console.log("data before field map");
                // console.log( list );

                return (
                    list
                        .map( company => {
                            let dto = {};
                            Object.keys( company )
                                .forEach( rawField => {
                                    dto[ fieldMap[ rawField ] ] = company[ rawField ];
                                })
                            ;
                            return dto;
                        })
                );

            })

*/




            // filtri -------------------------------------------------------------------------------------------------
            .then( list => {

                // debugger;
                return (
                    list
                    // .filter( rat => rat.status === this.state.activeFilter )
                );

            })

            // finish --------------------------------------------------------------------------------------------------
            .then( data => {
                // showlog("data after field sort");
                // showlog( data );

                this.setState({
                    ...this.state,
                    data: data,
                    allData: data
                })
            })

            .catch(e => {
                errorlog("errore",e);
            })

    }






}
