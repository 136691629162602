import {LANGUAGES, role} from "../env";
import {
	addNewUserByAdmin,
	addNewUserBySuperAdmin,
	getSelf,
	getUsers,
	updateUserByAdmin,
	updateUserBySuperAdmin
} from "../services/users-service";
import {errorlog, getLastElementOr, isNotEmptyArray, showlog} from "../utils";


export const setNewInsurer = userData => {
	return addNewUserByAdmin( userData );
}

export const saveNewUserBySuperAdmin = userData => {
	let convertedRole =
		Object.keys(role)
			.map( r => role[r])
			.filter( r => userData.role === r.label )
			// .filter( r => userData.role === r.name)
			.map( r => r.name)
			.reduce( getLastElementOr, null )
	;

	if( !!!convertedRole ) return Promise.reject({msg: "Inconsistent Role", raw: userData});
	userData.role = convertedRole;
	userData.company = userData.companyId;
	userData.title = 1;
	delete userData.companyId;

	// userData.password = sha256( userData.password );
	// userData.confirmPassword = sha256( userData.confirmPassword );

	if( !!userData.organizzation ) userData.leading = userData.organizzation.value;




	return addNewUserBySuperAdmin( userData );
};


export const editUserBySuperAdmin = userData => {
	let convertedRole =
		Object.keys(role)
			.map(r => role[r])
			.filter(r => userData.role === r.label)
			.map(r => r.name)
	;

	// console.log("converted role");
	// console.log(convertedRole);
	// console.log(userData);
	// console.log(role);

	if (convertedRole.length === 0) return Promise.reject({msg: "Inconsistent Role", raw: userData});
	userData.role = convertedRole[0];
	userData.company = userData.companyId;

	if( !!userData.organizzation ) userData.leading = userData.organizzation.value;

	delete userData.companyId;

	showlog("BL - editUserBySuperAdmin");
	showlog( userData );

	return updateUserBySuperAdmin(userData);
}

export const saveNewUserByAdmin = userData => {


	console.log("saveNewUserByAdmin BL");
	console.log(userData);



	let convertedRole =
		Object.keys(role)
			.map( r => role[r])
			.filter( r => userData.role === r.label )
			.map( r => r.name)
			.reduce( getLastElementOr, null )
	;

	if( !convertedRole ) {
		convertedRole =
			Object.keys(role)
				.map( r => role[r])
				.filter( r => userData.role === r.id )
				.map( r => r.name)
				.reduce( getLastElementOr, null )
		;
	}

	if( !convertedRole ) {
		convertedRole =
			Object.keys(role)
				.map( r => role[r])
				.filter( r => userData.role === r.name )
				.map( r => r.name)
				.reduce( getLastElementOr, null )
		;
	}



	/*
	let convertedRole =
		Object.keys(role)
			.map( r => role[r])
			.filter( r => userData.role === r.id)
			// .filter( r => userData.role === r.name)
			.map( r => r.name)
	;
	*/

	if( convertedRole.length === 0 ) return Promise.reject({msg: "Inconsistent Role", raw: userData});
	userData.role = convertedRole;
	userData.company = parseInt(localStorage.getItem("company_id"), 10);
	delete userData.companyId;


	if( !!userData.organizzation ) userData.leading = userData.organizzation.value;

	// userData.password = sha256( userData.password );
	// userData.confirmPassword = sha256( userData.confirmPassword );
	return addNewUserByAdmin( userData );
};



export const editUserByAdmin = userData => {

	/*
	console.log(Object.keys(role) );
	console.log(
		Object.keys(role)
			.map(r => role[r])
	);
	console.log(
		Object.keys(role)
			.map(r => role[r])
			.filter(r => userData.role === r.id)
	);
	console.log(
		Object.keys(role)
			.map(r => role[r])
			.filter(r => userData.role === r.id)
			.map(r => r.name)
	);
	*/

	let convertedRole =
		Object.keys(role)
			.map( r => role[r])
			.filter( r => userData.role === r.label )
			// .filter( r => userData.role === r.name)
			.map( r => r.name)
			.reduce( getLastElementOr, null )
	;
	if (convertedRole.length === 0) return Promise.reject({msg: "Inconsistent Role", raw: userData});
	userData.role = convertedRole;
	userData.company = parseInt(localStorage.getItem("company_id"), 10);
	delete userData.companyId;
	return updateUserByAdmin(userData);
}


export const loadUserById = id => {
	return (
		getUsers()
			.then( userList => {
				let user =
					userList
						.filter( u => u.id === id )
				;
				if( !!user && user.length > 0 ) user = user[0];
				else                            user = null;

				return user;
			})
			.catch(e => {
				errorlog("errore", e);
			})
	);
}

export const loadUsers = () => {
	return (
		getUsers()
			// Role mapping --------------------------------------------------------------------------------------------
			.then( responseData => {

				if( !isNotEmptyArray( responseData ) ) return [];
				responseData
					.map( row => {

						Object.keys(role)
							.map( roleData => {
								if( role[ roleData ].id === row.role ) row.role = role[ roleData ].label;
							})
						;

						return row;

					})
				;
				return responseData;
			})


			// languages -----------------------------------------------------------------------------------------------
			.then( responseData => {


				return (
					responseData
						.map( user => {
							// set della lingua

							let lang =
								LANGUAGES
									.filter( language => language.value === user.language_id )
									.reduce( getLastElementOr, LANGUAGES[0])
									.label
							;
							user.language = lang;

							return user;
						})
				);

			})

			// row sort ------------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.sort((x,y)=> {
							if( x.lastName > y.lastName ) return 1;
							return -1;
						})
				)
			})

			.catch( e => Promise.reject( e ) )
	);
}


export const loadSelf = companyID => {
	return getSelf( companyID );
}



