export const GENDER_GAP_TAB_ID = 7;
export const GENDER_GAP_VOID_TREE = {
    tabs: [
        {
            id: GENDER_GAP_TAB_ID,
            macroarea_id: GENDER_GAP_TAB_ID,
            name: "Gender GAP",
            themes: [],
            open: true,
            path: [ GENDER_GAP_TAB_ID ]
        }
    ]
};
