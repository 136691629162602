import React, {Component} from "react";
import {Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {errorlog, renderlog, showlog} from "../../utils/dev-utils";
import {WaitingOrNodataMessage} from "../waiting-or-nodata-message";
import {emoj} from "../../utils/string-utils";
import {EMOJ} from "../../emoj";
import {loadEvaluations} from "../../business-logic/active-service";
import {RATING_STATUS} from "../../env";
import {getLastElementOr} from "../../utils/array-utils";
import {translate} from "../i18n/translate-function";


const hiddenFields = [
    "codCliFor",
    "companySize",
    "dimensionId",
    "dimensionName",
    "status",
    "dimension"
];

const fieldMap = {
    id:             "Id",
    name:           "Nome",
    dateCreate:     "Creazione",
    state:          "Stato",
    ragioneSociale: "Azienda"
};


const LIST_LIMIT = 8;
const paginationOptions = {
    paginationSize: 2,
    pageStartIndex: 1,
    showTotal: false,
    hidePageListOnlyOnePage: false,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [ LIST_LIMIT ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];

const sortableFields = [
];


const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;


export default class MultiRatingListModal extends Component {

    state = {}

    constructor( props ) {
        super( props );

        this.toggle = this.toggle.bind( this );
        this.loadData = this.loadData.bind( this );
        this.rowSelection = this.rowSelection.bind( this );
    }


    rowSelection = (row, isMain = false) => {


    }


    toggle = forceRefresh => {
        this.props.onClose( forceRefresh );
    }

    render() {


        renderlog("MultiRatingListModal", this.state, this.props);



        let context = null;


        // if( !!this.state.data ) {
        if(!!this.state.data && this.state.data.length > 0) {


            showlog("controllo su data");
            showlog( this.state.data );


            context = (
                <ToolkitProvider
                    bootstrap4
                    keyField="Settore"
                    search
                    // exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                >
                    {props => (
                        <React.Fragment>

                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                            </Row>

                            <BootstrapTable
                                id={"sectorlist-table"}
                                bootstrap4
                                striped

                                {...props.baseProps}
                                size="sm"
                                responsive
                                keyField="Settore"
                                bordered={ false }
                                data={ this.state.data }
                                columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
                                defaultSorted={ defaultSorted }
                                pagination={ paginationFactory(paginationOptions) }
                                wrapperClasses="table-responsive"
                                selectRow={{
                                    mode: 'checkbox',
                                    clickToSelect: true,

                                    onSelect: row => {
                                        this.rowSelection( row );
                                    },

                                    selectionRenderer: row => {


                                        let currentRow = (
                                            this.state.data
                                                .filter( sec => sec.Settore === row.rowKey /*&& !!row.checked && !row.disabled*/ )
                                                .reduce(getLastElementOr, null)
                                        );

                                        row.checked = !!(
                                            this.state.data
                                                .filter( sec => sec.Settore === row.rowKey /*&& !!row.checked && !row.disabled*/ )
                                                .filter( sec => !!sec.Selezionato )
                                                .reduce(getLastElementOr, null)
                                        );



                                        return (
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={row.checked}
                                                    disabled={row.disabled}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </div>
                                        );

                                    },
                                    selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                        return null;
                                    }
                                }}

                            />


                        </React.Fragment>
                    )}
                </ToolkitProvider>
            );
        }
        else {
            context = (
                <WaitingOrNodataMessage
                    waiting={ !(!!this.state.data && this.state.data.length === 0) }
                    nodataMessage={emoj(EMOJ.sad_but_relieved_face) +"    Nessuna Valutazione"}
                />
            )
        }



        let content = (
            <Modal isOpen={ true } toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    {translate("Evaluations")}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    { context }
                </ModalBody>

            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }



    loadData() {

        return (

            loadEvaluations()

                // extras & adjust -------------------------------------------------------------------------------------------
                .then( list => {
                    return (
                        list
                            .filter( d => d.status === RATING_STATUS.auditDone.id )
                            .map( rat => {
                                rat.dimension = rat.dimension.name;
                                return rat;
                            })
                    );
                })


                // field sort & map  -------------------------------------------------------------------------------------------
                .then( responseData => {
                    return (
                        responseData
                            .map( row => {

                                let sortedRow = {};
                                Object.keys(fieldMap)
                                    .map( field => {
                                        sortedRow[field] = row[field];
                                    })
                                ;
                                Object.assign(sortedRow, row);
                                return sortedRow;

                            })
                    );
                })


                // filter --------------------------------------------------------------------------------------------------
                // .then( responseData => responseData.filter( d => d.status === RATING_STATUS.auditDone.id ) )


                // finish --------------------------------------------------------------------------------------------------
                .then( data => {
                    this.setState({
                        ...this.state,
                        data: data
                    })
                })


                .catch( e => {
                    errorlog("Caricamento Valutazioni", e);
                })
        );


    }

}
