import React from "react";
import "./missing-goal-svg-component.css";
export const MissingGoalSvgComponent = props => (
    <div
        id={ props.id }
        className={"missing-goal-svg-container "+ props.className}
        onClick={ props.onClick }
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 97.68">
            <g id="Livello_2" data-name="Livello 2">
                <path className="mg-1"
                      d="M95.53,40.21a2.5,2.5,0,0,0-4.83,1.3,28.24,28.24,0,0,1,.85,4.83H86.94a2.5,2.5,0,0,0,0,5h4.62A28,28,0,0,1,66.22,76.67V72.05a2.5,2.5,0,1,0-5,0v4.62a27.6,27.6,0,0,1-6.83-1.48,2.5,2.5,0,0,0-1.67,4.72,33,33,0,0,0,8.5,1.78v4.84a2.5,2.5,0,0,0,5,0V81.68A33,33,0,0,0,96.57,51.34h4.84a2.5,2.5,0,0,0,0-5H96.57A33.28,33.28,0,0,0,95.53,40.21Z"/>
                <path className="mg-1"
                      d="M26,51.34h4.84a33,33,0,0,0,9.79,21l-5.16,5.16a2.5,2.5,0,0,0,3.54,3.54L96.82,23.28a2.5,2.5,0,1,0-3.53-3.54l-6,6a32.89,32.89,0,0,0-21-9.78V11.16a2.5,2.5,0,1,0-5,0V16A33,33,0,0,0,30.88,46.34H26a2.5,2.5,0,1,0,0,5ZM61.22,21v4.62a2.5,2.5,0,0,0,5,0V21a28,28,0,0,1,17.5,8.3L44.2,68.83a28.05,28.05,0,0,1-8.3-17.49h4.62a2.5,2.5,0,1,0,0-5H35.9A28,28,0,0,1,61.22,21Z"/>
            </g>
        </svg>
    </div>
);



