import React, {Component} from "react";
import {COLOR_POLICY, DATETIME_FORMAT_IT} from "../../../env";
import {Col, Modal, ModalBody, ModalHeader, PopoverBody, PopoverHeader, Row, UncontrolledPopover,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {inverseSortById} from "../../../utils/array-utils";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {loadQuestionHistory} from "../../../business-logic/rating";
import moment from "moment";
import {getQuestionStatus} from "../../../utils/question-utils";
import {translate} from "../../i18n/translate-function";

const hiddenFields = [
	"user",
	"date",
	"state",
	//"Stato",
	"comment",
	//"Status"
];

const fieldMap = {
	user: translate("Operator"),
	date: translate("Date"),
	Stato: translate("Status"),
	comment: " ",
};


const LIST_LIMIT = 8;
const paginationOptions = {
	paginationSize: 2,
	pageStartIndex: 1,
	showTotal: false,
	hidePageListOnlyOnePage: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
	),
	sizePerPageList: [ LIST_LIMIT ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'id',
		order: 'asc',
	},
];

const sortableFields = [

];


const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
// const { ExportCSVButton } = CSVExport;


export default class SiRatingQuestionHistoryModal extends Component {

	state = {
		selected: []
	}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
		this.loadData = this.loadData.bind( this );
	}





	toggle = forceRefresh => {
		this.props.onClose( forceRefresh );
	}

	render() {


		renderlog("SiRatingQuestionHistoryModal", this.state, this.props, COLOR_POLICY.evident);



		let context = (
			<React.Fragment>
				<Spinner className="spinner-border-sm" />
				<span className={"loading-text-alignment"}>Caricamento...</span>
			</React.Fragment>
		);


		// if( !!this.state.data ) {
		if(!!this.state.data && this.state.data.length > 0) {




			showlog("controllo su data");
			showlog( this.state.data );


			context = (
				<ToolkitProvider
					bootstrap4
					keyField="Settore"
					search
					// exportCSV={{ onlyExportFiltered: true, exportAll: false }}
				>
					{props => (
						<React.Fragment>

							{/*
							<Row>
								<Col>
									{ JSON.stringify(props) }
								</Col>
							</Row>
							*/}

							<Row>
								<Col>
									<SearchBar {...props.searchProps} />
								</Col>
							</Row>

							<BootstrapTable
								id={"sectorlist-table"}
								bootstrap4
								striped

								{...props.baseProps}
								size="sm"
								responsive
								keyField="Settore"
								bordered={ false }
								data={ this.state.data }
								columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
								defaultSorted={ defaultSorted }
								pagination={ paginationFactory(paginationOptions) }
								wrapperClasses="table-responsive"

							/>


						</React.Fragment>
					)}
				</ToolkitProvider>
			);
		}
		else {
			context = (
				<WaitingOrNodataMessage
					waiting={ !(!!this.state.data && this.state.data.length === 0) }
					nodataMessage={emoj(EMOJ.sad_but_relieved_face) +"    Nessuno storico"}
				/>
			)
		}



		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{translate("Chronology")}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
			</Modal>


		);


		return content;
	}

	componentDidMount() {
		this.loadData();
	}






	loadData( tmp ) {

		return (
			loadQuestionHistory( this.props.ratingId, this.props.questionId )

				// log -------------------------------------------------------------------------------------------------
				.then( responseData => {

					showlog("prima di mettere i dati nello state | start");
					showlog(responseData);

					return responseData;
				})



				// field sort -------------------------------------------------------------------------------------------
				.then( responseData => {

					return (
						responseData
							.map( row => {

								let sortedRow = {};
								Object.keys(fieldMap)
									.map( field => {
										sortedRow[fieldMap[field]] = row[field];
									})
								;
								Object.assign(sortedRow, row);
								return sortedRow;

							})
					);
				})

				// row sort --------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.sort( inverseSortById )
					)
				})


				// adjust ----------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map((row, index) => {
								row[translate("Date")] = moment( row[translate("Date")] ).format( DATETIME_FORMAT_IT );

								let statusIdTmp = row.state;
								// row[translate("Status")] =
								//
								// 	getQuestionStatus( statusIdTmp )
								// 		.label
								// 		// .split( QUESTION_STATUS.lowNonCompliant.name )
								// 		// .join( "Non Comformità Lieve" )
								// ;

								row[translate("Status")] =
									getQuestionStatus( statusIdTmp )
										.name
										// .split( QUESTION_STATUS.lowNonCompliant.name )
										// .join( "Non Comformità Lieve" )
								;





								let msgTmp = row[" "];
								row[" "] = (
									!!row[" "]
										? (
											<React.Fragment>
												<span id={"message-"+ index} className={"tooltip-icon-cursor"}>{ emoj( EMOJ.speech_balloon ) }</span>
												<UncontrolledPopover
													placement="bottom-end"
													target={"message-"+ index}
													trigger="legacy"
												>
													<PopoverHeader className={"pop-over-max-width"}>Messaggio</PopoverHeader>
													<PopoverBody className={"pop-over-max-width-heigth"}>{ msgTmp }</PopoverBody>
												</UncontrolledPopover>
											</React.Fragment>
										)
										: null
								);

								return row;
							})
					);
				})


				// additional fields ------------------------------------------------------------------------------------


				// finish -----------------------------------------------------------------------------------------------
				.then( responseData => {

					this.setState(
						{
							...this.state,
							data: responseData
						},
						() => {
							return true;
						}
					);
				})
				.catch( e => {
					errorlog("loadQuestionHistory", e);
				})
		);


	}

}
