const {
    REACT_APP_API_PORT,
    REACT_APP_DEBUG,
    REACT_APP_HELPER,
    REACT_APP_HOST,
    REACT_APP_REMOTE_LOG,
    REACT_APP_LOGS_PORTALADDRESS,
    REACT_APP_LOGIN_FORM,
    REACT_APP_HTTP_CALL_cURL_EXPORT,
    REACT_APP_REMOTE_LOGS_ENDPOINT,
    REACT_APP_LOGS_PORTAL_TOKEN,
    REACT_APP_PRODUCTION_HOST
} = process.env;

export const DEBUG                  = JSON.parse(REACT_APP_DEBUG);
export const LOGIN_FORM             = JSON.parse(REACT_APP_LOGIN_FORM);
export const HELPER                 = JSON.parse(REACT_APP_HELPER);
export const REMOTE_LOG             = JSON.parse(REACT_APP_REMOTE_LOG);
export const HTTP_CALL_cURL_EXPORT  = JSON.parse(REACT_APP_HTTP_CALL_cURL_EXPORT);
export const REMOTE_LOGS_ENDPOINT   = REACT_APP_REMOTE_LOGS_ENDPOINT;
export const LOGS_PORTALADDRESS     = REACT_APP_LOGS_PORTALADDRESS;
export const LOSG_PORTAL_TOKEN      = REACT_APP_LOGS_PORTAL_TOKEN;
export const API_PORT               = REACT_APP_API_PORT
export const HOST                   = REACT_APP_HOST;
export const PRODUCTION_HOST        = REACT_APP_PRODUCTION_HOST;

export { permissions, role, roleTree } from "./conf/permissions";
export { errorMessage } from "./conf/errors";
export { COLOR_POLICY, COLOR_SCALE, MAGNITUDE_DARK, MAGNITUDE } from "./conf/color-policy";

export * from "./conf/siroad";
export * from "./conf/srtool";
export * from "./conf/sirating";
export * from "./conf/siscoring";
export * from "./conf/gendergap";
export * from "./conf/languages";
