import * as ITit from "./IT-it.json"
import * as ENgb from "./EN-gb.json"

import itFlag from "./pic/png/IT@3x.png";
import enFlag from "./pic/png/GB@3x.png";
import deFlag from "./pic/png/DE@3x.png";
import frFlag from './pic/png/FR@3x.png';
import esFlag from './pic/png/ES@3x.png';


export let translate = (key) => {

    let lang = localStorage.getItem("language") || "IT-it";
    let languagepack = ITit.default;


    switch(lang){
        case "IT-it": languagepack = ITit.default;       break;
        case "EN-gb": languagepack = ENgb.default;       break;
        case "EN-us": languagepack = ENgb.default;       break;
        case "DE-de": languagepack = ENgb.default;       break;
        case "ES-es": languagepack = ENgb.default;       break;
        case "FR-fr": languagepack = ENgb.default;       break;
    }

    let text = languagepack[key];

    if( !!text ) return text;
    return ITit.default[key] || `{{${ key }-noTEXT}}`;

}


export const setFlagToLanguage = lang => {
    lang.flag = itFlag;
    switch (lang.code) {
        case 'IT-it':
            lang.flag = itFlag;
            break;
        case 'EN-gb':
            lang.flag = enFlag;
            break;
        case 'DE-de':
            lang.flag = deFlag;
            break;
        case 'FR-fr':
            lang.flag = frFlag;
            break;
        case 'ES-es':
            lang.flag = esFlag;
            break;
        // <-------- altre lingue
        default:
            lang.flag = itFlag;
            break
    }


    return lang;
}
