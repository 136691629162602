import {
	Companies_GetAddress,
	Companies_GetAll,
	Companies_GetContacts,
	CompaniesAlloweds_Update,
	ConsultantCompaniesAlloweds_Get, DeleteCompany,
	EvaluationUsersRole_Get,
	Provinces,
	saveCompanyData, SectorHistory_Get,
	Sectors_Get,
	Sectors_Put,
	Thematics_GET,
	updateCompanyData,
	UsersRole_Put
} from "./request-manager";
import {showlog} from "../utils/dev-utils";


export const getCompanies = organizzationId => {
	return (
		Companies_GetAll( organizzationId )
			.then( companiesList => {
				// showlog("lista companies");
				// showlog(companiesList);
				return companiesList
			})
			.catch(e => {
				showlog("service error on getCompanies");
				showlog(e);
				throw e;
			})
	);
};


export const getConsulerCompanies = id => {
	return ConsultantCompaniesAlloweds_Get( id );
}

export const getCompanyContacts = cid => {
	return Companies_GetContacts( cid );
}

export const getCompanyAddress = cid => {
	return Companies_GetAddress( cid );
}


export const assignCompaniesToConsuler = body => {
	return CompaniesAlloweds_Update( body )
}


export const getSectors = companyId => {
	let cid = companyId || 0;
	return Sectors_Get( cid );
};

export const getSectorHistory = companyId => {
	return SectorHistory_Get( companyId );
};

export const getEvaluationUsersRole = (evaluationId,type) => {
	return EvaluationUsersRole_Get( evaluationId,type );
};


export const getAllThemes = () => {
	return Thematics_GET();
}


// selezione singola
export const setSectors = ( cid, sectorList, main ) => {
	return Sectors_Put( sectorList, cid, main );
}

export const updateUsersRole = ( cid, sectorList, type ) => {
	return UsersRole_Put( sectorList, cid, type );
}


/*
export const setSectors = ( cid, sectorList ) => {

	let body = { selected: true };
	let parameters = cid +"/"+ sectorList.reduce( getLastElementOr, 0);

	return Sectors_Put( body, parameters );
}
*/



export const setCompany = data => {
	return saveCompanyData( data );
}

export const editCompany = (data, cid) => {
	return updateCompanyData( data, cid );
}



export const getAllProvinces = () => {
	return Provinces();
}

export const ConfDeleteCompany = id => {
	return DeleteCompany(id);
}
