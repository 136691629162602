import {Component} from "react";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteMaterialTheme} from "../../../business-logic/gri";
import {errorlog, showlog} from "../../../utils/dev-utils";


export default class TreeMaterialThemesDeleteModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.onClose();
	}

	render() {

		let context = (
			"Sicuro di voler eliminare la tematica materiale ?"
		);

		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={ "modal-colored-header bg-danger" }>
					ATTENZIONE
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {

							deleteMaterialTheme( this.props.id )
								.then( () => {
									this.props.onClose();
								})
								.catch(e => {
									errorlog("errore", e);

									this.props.onClose();
								})
						}}
					>
						OK
					</Button>{' '}
					<Button color="link" onClick={this.toggle}>
						annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;
	}

	componentDidMount() {}
}
