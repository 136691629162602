import React, {Suspense} from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Nav,
	NavItem,
	NavLink,
	PopoverBody,
	PopoverHeader,
	Row,
	TabContent,
	TabPane,
	UncontrolledCollapse,
	UncontrolledPopover,
} from 'reactstrap';
import {GRI_ADMIN_TREE_DEEP_LEVELS_NAME, permissions, TABS} from "../../../env";
import "../../../services/interceptor";
import classnames from 'classnames';
import {updateTreeStatusPath} from "../../../services/gri-admin-tree";
import GriManagerListPopup from "./gri-manager-list-popup";
import {cloneOpenNodes, convertTableToTree, getTreeNodeByPath} from "../../../business-logic/tree-manager";
import {loadGriStructure, saveManager} from "../../../business-logic/gri";
import AdminUserEditModalContent from "../admin/user-edit-modal";
import StandBySystemModalComponent from "./standby-system-modal";
import {cloneObject} from "../../../utils/object-utils";
import {errorlog, renderlog} from "../../../utils/dev-utils";
import {isMeasureUnit} from "../../../utils/number-utils";
import {getLastElementOr} from "../../../utils/array-utils";
import {hasPermission} from "../../../business-logic/auth";
import ActionButton from "../../save-button";


export default class TreeGriCollectReadOnlyComponent extends React.Component {

	state = {
		activeTab: -1,
		// tree: {
		// 	tabs: []
		// }
		tree: this.props.tree
	}

	constructor( props ) {
		super( props );
		this.inWait                     = this.inWait.bind( this );
		this.toggle                     = this.toggle.bind( this );
		this.getSubTreeChapter          = this.getSubTreeChapter.bind( this );


	}

	inWait() {
		return (
			<div>Wait</div>
		);
	}

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	standby( properties = {}, active = true ) {

		if( !active ) {
			this.setState({
				...this.state,
				...properties,
				stop: false
			});
		}
		else {
			if( !this.state.stop ) {
				return (new Promise((success, fail) => {
					this.setState(
						{
							...this.state,
							stop: !!active
						},
						newState => {
							success( newState );
							// setTimeout( success, 500 );
						}
					)
				}));
			}
		}
		return Promise.resolve();

	}

	render() {

		renderlog("config READ-ONLY TREE", this.state, this.props, { color: "#1188dd", contrastColor: "#dddddd" });
		// treeLog( cloneObject( this.state.tree ) );

		return (
			<Suspense fallback={this.inWait()}>


				<Nav tabs>
					{
						// this.state.tree.tabs
						Object.keys(TABS)
							.map( tabKey => TABS[tabKey] )


							.map((tab, index) => {
								return (
									<NavItem key={index}>
										<NavLink
											id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0] +"-"+ tab.id}
											href="#"
											className={classnames({ active: this.state.activeTab === tab.id })}

											onClick={() => {


												this.standby()
													.then( () => {

														loadGriStructure( this.props.collect, tab.id )

															.then(resultObj => {


																convertTableToTree( resultObj )
																	.then( tree => {

																		// let freshTree = (!!previousTree) ? cloneOpenNodes( previousTree, tree ) : tree;
																		let freshTree = tree;


																		this.standby(
																			{
																					tree: freshTree,
																					treeSeed: resultObj,
																					currentTab: tab
																				},
																				false
																			)
																			.then( () => {
																				updateTreeStatusPath(this.props.collect, GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0], tab.id);
																				this.toggle(tab.id);
																			})
																			.catch(e => {
																				errorlog("errore al set standby", e);
																				this.standby( { errorMessage: "Si è verificato un errore" }, false );
																			})
																		;



																	})
																	.catch(e => {
																		errorlog("errore al convert table to tree", e);
																		this.standby( { errorMessage: "Si è verificato un errore" }, false );
																	})
																;

															})
															.catch(e => {
																console.error("error al load gri structure");
																console.log(e);
																this.standby( { errorMessage: "Si è verificato un errore" }, false );
															})



													})
													.catch(e => {
														errorlog("error al set dello standby", e);
													})



											}}
										>
											<i
												className={classnames(
													tab.icon,
													'd-lg-none',
													'd-block',
													'mr-1'
												)}/>
											<span className="d-none d-lg-block">{tab.name}</span>
										</NavLink>
										{/*
										<UncontrolledTooltip placement="top" target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0] +"-"+ tab.id}>
											<span className="d-none d-lg-block">{tab.name.en}</span>
										</UncontrolledTooltip>
										*/}
									</NavItem>
								);
							})
					}
				</Nav>

				<TabContent activeTab={this.state.activeTab}>
					{
						this.state.tree.tabs
							.map((tab, index) => {
								return (
									<TabPane
										tabId={tab.id}
										key={index}
									>
										<Row>
											<Col sm="12">

												{
													(tab.chapters.length === 0)
														? <p className="mt-3">TAB senza contenuti</p>
														: this.getSubTreeChapter( tab.chapters )
												}

												{/*<GotoTopComponent/>*/}

											</Col>
										</Row>
									</TabPane>
								);
							})
					}
				</TabContent>



				{
					(!!this.state.getManagerListPopup)
						? (
							<GriManagerListPopup
								collectId={ (this.props.match.params.id || this.props.id) }
								currentUserManager={ this.state.currentUserManager }
								onClose={ () => {
									this.setState({
										...this.state,
										getManagerListPopup: null
									})
								}}
								onChoose={ userManager => {


									let level = (""+ this.state.getManagerListPopup).split("-").length -1;
									let nodeId = (""+ this.state.getManagerListPopup).split("-")[ (""+ this.state.getManagerListPopup).split("-").length -1 ];


									this.standby()
										.then( () => {

											// manager, timingId, level, nodeId
											saveManager( userManager.id, this.props.collect, level, nodeId )
												/*
												.then( result => {

													showlog("result");
													showlog(result);



													this.props.reloadTree( cloneObject( this.state.tree ) )
														.then( refreshedTree => {
															this.standby(
																{
																	...this.state,
																	getManagerListPopup: null,
																	tree: cloneOpenNodes( cloneObject( this.state.tree ), refreshedTree )
																},
																false
															);
														})
														.catch(e => {
															console.error("errore all ultimo ritorno");
															console.log(e);
														})


													/!*
													this.setState({
															...this.state,
															getManagerListPopup: null,
															forceInjectedTree: true  //(!this.state.forceInjectedTree) ? (new Date()).getTime() : null
														},
														() => {
															this.standby( false );
															this.props.onTreeUpdate( this.state.tree );
														}
													);
													*!/


												})
												.catch(e => {
													console.error("errore al cambio manager nell'albero di sola lettura");
													console.log(e);
												})
												*/

												.then( result => {
													this.props.reloadTree( cloneObject( this.state.tree ), this.state.currentTab.id || TABS.general.id )
														.then( refreshedTree => {
															this.standby(
																{
																	tree: cloneOpenNodes( cloneObject( this.state.tree ), refreshedTree ),
																	getManagerListPopup: null
																},
																false
															);
														})
														.catch(e => {
															console.error("errore all ultimo ritorno");
															console.log(e);
															this.standby( { errorMessage: "Si è verificato un errore" }, false );
														})
												})

										});


								}}

								onDeselect={ userManager => {


									let level = (""+ this.state.getManagerListPopup).split("-").length -1;
									let nodeId = (""+ this.state.getManagerListPopup).split("-")[ (""+ this.state.getManagerListPopup).split("-").length -1 ];

									this.standby()
										.then( () => {

											// manager, timingId, level, nodeId
											saveManager( 0, this.props.collect, level, nodeId )

												.then( result => {
													this.props.reloadTree( cloneObject( this.state.tree ), this.state.currentTab.id || TABS.general.id )
														.then( refreshedTree => {
															this.standby(
																{
																	tree: cloneOpenNodes( cloneObject( this.state.tree ), refreshedTree ),
																	getManagerListPopup: null
																},
																false
															);
														})
														.catch(e => {
															console.error("errore all ultimo ritorno");
															console.log(e);
															this.standby( { errorMessage: "Si è verificato un errore" }, false );
														})
												})

										});


								}}

								addManager={ () => {
									this.setState({
										...this.state,
										getManagerListPopup: null,
										addManagerModal: true
									})
								}}

							/>
						)
						: null
				}



				{
					(!!this.state.addManagerModal)
						? (
							<AdminUserEditModalContent
								isManager={ true }
								currentUserManager={ this.state.currentUserManager }
								close={ forceRefresh => {
									this.setState(
										{
											...this.state,
											getManagerListPopup: null,
											addManagerModal: null
										},
										() => {
											// if( !!forceRefresh ) this.loadData();
										}
									);
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.stop)
						? (
							<StandBySystemModalComponent
								didMount={ () => {

									/*
									this.setState({
										...this.state,
										stopable: true
									})
									*/
								}}
							/>
						)
						: null
				}

			</Suspense>
		);
	}



	getSubTreeChapter( chapters ) {


		// showlog("controllo navigazione albero");
		// let paths = [110942, 110746, 110765, 110766, 110770, 110772];
		// showlog("node by path "+ paths);
		// let node = this.state.tree.getNodeByPath( paths );
		// showlog( node );


		return (
			chapters
				.map( (ch, chIndex) => {
					return (
						<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}>
							<CardHeader>
								<h5 className="m-0">
									<div
										className="custom-accordion-title d-block pt-2 pb-2"
									>
										{ ch.infos.reduce( getLastElementOr, null).name.split("-")[0] +" - "+ ch.name }

										{
											(!!ch.material)
												? <span>&nbsp;&nbsp;Material</span>
												: null
										}

											{/*
											<Button
												color="success"
												className="card-action info-button req-button-label btn btn-success"
												onClick={ clickEvent => {

													if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
														this.setState({
															...this.state,
															getManagerListPopup: (this.state.tree.tabs.filter( t => t.open).reduce( getLastElementOr, null)).id +"-"+ ch.id
														})
													}

												}}
											>
												{
													(!!ch.userManager)
														? (
															<React.Fragment>
																<i className="mdi mdi-account" />
																<span className={"info-gri-manager-name-label req-button-label"} >
																	{ ch.userManagerName }
																</span>
															</React.Fragment>
														)
														: (
															<i className="mdi mdi-account-plus" />
														)
												}
											</Button>
										*/}
										{
											( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
												? (
													<ActionButton
														buttonText={
															<React.Fragment>
																<i className="mdi mdi-account" />
																<span className={"info-gri-manager-name-label req-button-label"} >
																	{
																		(!!ch.userManager)
																			? ch.userManagerName
																			: ""
																	}
																</span>
															</React.Fragment>
														}
														color={ (!!ch.userManager) ? "success" : "primary" }
														className="card-action info-button req-button-label btn manager-button"
														promise={ () => {
															return Promise.resolve( );
														}}
														onResult={ result => {
															this.setState({
																...this.state,
																getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id,
																currentUserManager: ch.userManager
															})
														}}
														onError={ e => {

														}}
													/>
												)
												: null
										}

										<span className="float-right">
											<NavLink
												id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}
												href="#"


												onClick={ clickEvent => {

													let treeTmp = cloneObject( this.state.tree );
													if( !!treeTmp.tabs.filter( t => t.open ).reduce( getLastElementOr, null) ) {
														treeTmp
															.tabs.filter( t => t.open ).reduce( getLastElementOr, null)
															.chapters.filter( c => c.id === ch.id ).reduce( getLastElementOr, null)
															.open =
																!this.state.tree
																	.tabs.filter( t => t.open).reduce( getLastElementOr, null)
																	.chapters.filter( c => c.id === ch.id ).reduce( getLastElementOr, null)
																	.open
														;
														this.setState({
																...this.state,
																tree: treeTmp
															},
															() => {
																this.props.onTreeUpdate( treeTmp );
															}
														)
													}

												}}


											>
												{
													(
														!!(this.state.tree.tabs.filter( t => t.open).reduce( getLastElementOr, null) )
														&& !!(
															this.state.tree
																.tabs.filter( t => t.open).reduce( getLastElementOr, null)
																?.chapters.filter( c => c.id === ch.id ).reduce( getLastElementOr, null)
																?.open
														)
													)
														? <i className="dripicons-minus font-18 accordion-arrow"/>
														: <i className="mdi mdi-timeline-plus font-18 accordion-arrow"/>
												}
											</NavLink>
			                            </span>
									</div>
									{/*
									<UncontrolledTooltip placement="top" target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}>
										<span className="d-none d-lg-block">{ch.name.en}</span>
									</UncontrolledTooltip>
									*/}
								</h5>
							</CardHeader>
							<UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}>
								<CardBody>


									{   // informativa --------------------------------------------------------------------------------------------------------------------------------------------------------------------
										ch.infos
											.map( (info, infoIndex) => {
												return (
													<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id}>
														<CardHeader>
															<h5 className="m-0">
																<div
																	className="custom-accordion-title d-block pt-2 pb-2"
																>
																	{
																		(!!info.reccomandation && !!info.reccomandation.it)
																			? (
																				<i
																					id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-reccomandation"}
																					className={"dripicons-question tooltip-icon tooltip-icon-cursor"}
																				/>
																			)
																			: null
																	}


																	Inf { info.name }<br/>
																	{
																		(!!info.description && !!info.description.it)
																			? <span className={"font-weight-normal"}>{info.description.it}</span>
																			: ""
																	}


																	{
																		(!!info.reccomandation && !!info.reccomandation.it)
																			? (
																				<UncontrolledPopover
																					placement="top"
																					target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-reccomandation"}
																					trigger="legacy"
																				>
																					<PopoverHeader>Raccomandazioni</PopoverHeader>
																					<PopoverBody>
																						<span className="d-none d-lg-block">{info.reccomandation.it}</span>
																					</PopoverBody>
																				</UncontrolledPopover>
																			)
																			: null
																	}




																	<span className={"req-button-label"} >
																		{
																			(!!info.applicability)
																				? "Applicabile"
																				: null
																		}
																	</span>

																	<span className={"req-button-label"} >
																		{
																			(!!info.material)
																				? "Materiale"
																				: null
																		}
																	</span>


																	{
																		( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
																			? (
																				<ActionButton
																					buttonText={
																						<React.Fragment>
																							<i className="mdi mdi-account" />
																							<span className={"info-gri-manager-name-label req-button-label"} >
																								{
																									(!!info.userManager)
																										? info.userManagerName
																										: ""
																								}
																							</span>
																						</React.Fragment>
																					}
																					color={ (!!info.userManager) ? "success" : "primary" }
																					className="card-action info-button req-button-label btn manager-button"
																					promise={ () => {
																						return Promise.resolve( );
																					}}
																					onResult={ result => {
																						this.setState({
																							...this.state,
																							getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id,
																							currentUserManager: info.userManager
																						})
																					}}
																					onError={ e => {

																					}}
																				/>
																			)
																			: null
																	}


																	{
																		(!!info.guideLines && !!info.guideLines.it)
																			? (
																				<div className={"tooltip-icon-container"}>
																					<i id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-guidelinees"} className={"mdi mdi-information-outline tooltip-icon-cursor"}/>

																					<UncontrolledPopover
																						placement="top"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-guidelinees"}
																						trigger="legacy"
																					>
																						<PopoverHeader>Linee Guida</PopoverHeader>
																						<PopoverBody>
																							<span className="d-none d-lg-block">{info.guideLines.it}</span>
																						</PopoverBody>
																					</UncontrolledPopover>
																				</div>
																			)
																			: null
																	}




																	<span className="float-right">
																		<NavLink
																			id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id}
																			href="#"

																			onClick={() => {
																				let treeTmp = cloneObject( this.state.tree );
																				if( !!treeTmp.tabs.filter( t => t.open ).reduce( getLastElementOr, null) ) {
																					treeTmp
																						.tabs.filter(t => t.open).reduce( getLastElementOr, null)
																						.chapters.filter(c => c.id === ch.id).reduce( getLastElementOr, null)
																						.infos.filter(i => i.id === info.id).reduce( getLastElementOr, null)
																						.open =
																						!this.state.tree
																							.tabs.filter(t => t.open).reduce( getLastElementOr, null)
																							.chapters.filter(c => c.id === ch.id).reduce( getLastElementOr, null)
																							.infos.filter(i => i.id === info.id).reduce( getLastElementOr, null)
																							.open
																					;
																					this.setState({
																						...this.state,
																						tree: treeTmp
																					})
																				}
																			}}




																		>
																			{
																				(
																					!!(this.state.tree.tabs.filter( t => t.open).reduce( getLastElementOr, null))
																					&& !!(
																						this.state.tree
																							.tabs.filter( t => t.open).reduce( getLastElementOr, null)
																							?.chapters.filter( c => c.id === ch.id ).reduce( getLastElementOr, null)
																							?.infos.filter(i => i.id === info.id ).reduce( getLastElementOr, null)
																							.open
																					)
																				)
																					? <i className="dripicons-minus font-18 accordion-arrow"></i>
																					: <i className="mdi mdi-timeline-plus font-18 accordion-arrow"></i>
																			}
																		</NavLink>
										                            </span>
																</div>

															</h5>
														</CardHeader>
														<UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id}>
															<CardBody>


																{ // requisito --------------------------------------------------------------------------------------------------------------------------------------------
																	info.requirements
																		.map( (req, reIndex) => {
																			return (
																				<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																					<CardHeader>
																						<h5 className="m-0">
																							<div
																								className="custom-accordion-title d-block pt-2 pb-2"
																							>
																								<div className={"req-left-comander"}>{ req.name }</div>
																								<div className={"req-right-comander req-right-comander-readonly-tree"} >

																									<div>
																										{/*
																										<Button
																											color="success"
																											className="card-action info-button req-button-label"
																											onClick={ clickEvent => {
																												onsole.log("modifica responsabile in req");
																												if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																													this.setState({
																														...this.state,
																														getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id
																													})
																												}
																											}}
																										>
																											{
																												(!!req.userManager)
																													? (
																														<React.Fragment>
																															<i className="mdi mdi-account" ></i>
																															<span className={"info-gri-manager-name-label req-button-label"} >
																																{ req.userManagerName }
																															</span>
																														</React.Fragment>
																													)
																													: (
																														<i className="mdi mdi-account-plus" ></i>
																													)
																											}
																										</Button>
																										*/}

																										{
																											( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
																												? (
																													<ActionButton
																														buttonText={
																															<React.Fragment>
																																<i className="mdi mdi-account" />
																																<span className={"info-gri-manager-name-label req-button-label"} >
																																	{
																																		(!!req.userManager)
																																			? req.userManagerName
																																			: ""
																																	}
																																</span>
																															</React.Fragment>
																														}
																														color={ (!!req.userManager) ? "success" : "primary" }
																														className="card-action info-button req-button-label btn manager-button"
																														promise={ () => {
																															return Promise.resolve( );
																														}}
																														onResult={ result => {
																															this.setState({
																																...this.state,
																																getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id,
																																currentUserManager: req.userManager
																															})
																														}}
																														onError={ e => {

																														}}
																													/>
																												)
																												: null
																										}
																									</div>

																									<div>
																										<span className={"req-button-label"} >
																											Timing: {
																											(!!req.timing)
																												? "Mensile"
																												: "Annuale"
																										}
																										</span>
																									</div>


																									{
																										(isMeasureUnit(req.measureUnit))
																											? (
																												<div>
																													<span className={"req-button-label"} >{ "Unità di Misura: "+ req.measureUnit }</span>
																												</div>
																											)
																											: null
																									}


																								</div>

																								{
																									(true/*req.subReqs.length > 0*/)
																										? (
																											<span className="float-right">
																												<NavLink

																													id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}
																													href="#"

																													onClick={() => {
																														let treeTmp = cloneObject( this.state.tree );
																														let reqTmp = getTreeNodeByPath( treeTmp, req.path ); // da verificare
																														reqTmp.open = !getTreeNodeByPath( this.state.tree, req.path ).open;
																														this.setState({
																															...this.state,
																															tree: treeTmp
																														});

																													}}



																												>
																													{
																														(req.subReqs.length > 0)
																															? (
																																(!!getTreeNodeByPath( this.state.tree, req.path ).open)
																																	? <i className="dripicons-minus font-18 accordion-arrow"></i>
																																	: <i className="mdi mdi-timeline-plus font-18 accordion-arrow"></i>
																															)
																															: null
																													}
																												</NavLink>
																				                            </span>
																										)
																										: null
																								}




																							</div>
																							{/*
																							<UncontrolledTooltip placement="top" target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																								<span className="d-none d-lg-block">{req.name.en}</span>
																							</UncontrolledTooltip>
																							*/}
																						</h5>
																					</CardHeader>

																					{ // sotto-requisito ------------------------------------------------------------------------------------------------------------------
																						(req.subReqs.length > 0)
																							? (
																								<UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																									<CardBody>

																										{
																											req.subReqs
																												.map( sub => {
																													return (
																														<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																															<CardHeader>
																																<h5 className="m-0">
																																	<div
																																		className="custom-accordion-title d-block pt-2 pb-2"

																																	>
																																		<div className={"req-left-comander"}>{ sub.name }</div>


																																		<div className={"req-right-comander req-right-comander-readonly-tree"} >

																																			<div>
																																				{/*}
																																				<Button
																																					color="success"
																																					className="card-action info-button req-button-label"
																																					onClick={ clickEvent => {

																																						if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																							this.setState({
																																								...this.state,
																																								getManagerListPopup: (this.state.tree.tabs.filter(t => t.open)[0]).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + sub.id
																																							})
																																						}
																																					}}
																																				>
																																					{
																																						(!!sub.userManager)
																																							? (
																																								<React.Fragment>
																																									<i className="mdi mdi-account" ></i>
																																									<span className={"info-gri-manager-name-label req-button-label"} >
																																										{ sub.userManagerName }
																																									</span>
																																								</React.Fragment>
																																							)
																																							: (
																																								<i className="mdi mdi-account-plus" ></i>
																																							)
																																					}
																																				</Button>
																																				*/}


																																				{
																																					( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
																																						? (
																																							<ActionButton
																																								buttonText={
																																									<React.Fragment>
																																										<i className="mdi mdi-account" />
																																										<span className={"info-gri-manager-name-label req-button-label"} >
																																											{
																																												(!!sub.userManager)
																																													? sub.userManagerName
																																													: ""
																																											}
																																										</span>
																																									</React.Fragment>
																																								}
																																								color={ (!!sub.userManager) ? "success" : "primary" }
																																								className="card-action info-button req-button-label btn manager-button"
																																								promise={ () => {
																																									return Promise.resolve( );
																																								}}
																																								onResult={ result => {
																																									this.setState({
																																										...this.state,
																																										getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + sub.id,
																																										currentUserManager: sub.userManager
																																									})
																																								}}
																																								onError={ e => {

																																								}}
																																							/>
																																						)
																																						: null
																																				}


																																			</div>

																																			<div>
																																				<span className={"req-button-label"} >
																																					Timing: {
																																					(!!sub.timing)
																																						? "Mensile"
																																						: "Annuale"
																																				}
																																				</span>
																																			</div>

																																			{
																																				(isMeasureUnit(sub.measureUnit))
																																					? (
																																						<div>
																																							<span className={"req-button-label"} >{ "Unità di Misura: "+ sub.measureUnit }</span>
																																						</div>
																																					)
																																					: null
																																			}

																																		</div>



																																		<NavLink
																																			id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}
																																			href="#"

																																			onClick={() => {
																																				let treeTmp = cloneObject( this.state.tree );
																																				let reqTmp = getTreeNodeByPath( treeTmp, sub.path );
																																				reqTmp.open = !getTreeNodeByPath( this.state.tree, sub.path ).open;
																																				this.setState({
																																					...this.state,
																																					tree: treeTmp
																																				})
																																			}}

																																		>
																																			{
																																				(sub.subSubReqs.length > 0)
																																					? (
																																						<span className="float-right">
																																							{
																																								(!!getTreeNodeByPath( this.state.tree, sub.path ).open)
																																									? <i className="dripicons-minus font-18 accordion-arrow"></i>
																																									: <i className="mdi mdi-timeline-plus font-18 accordion-arrow"></i>
																																							}
																															                            </span>
																																					)
																																					: null
																																			}
																																		</NavLink>
																																	</div>
																																	{/*
																																	<UncontrolledTooltip placement="top" target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																																		<span className="d-none d-lg-block">{sub.name.en}</span>
																																	</UncontrolledTooltip>
																																	*/}
																																</h5>
																															</CardHeader>

																															{
																																(sub.subSubReqs.length > 0)
																																	? (
																																		<UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																																			<CardBody>

																																				{
																																					(sub.subSubReqs.length > 0)
																																						? (
																																							sub.subSubReqs
																																								.map(subSub => {
																																									return (
																																										<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-" + subSub.id}>
																																											<CardHeader>
																																												<h5 className="m-0">
																																													<div
																																														className="custom-accordion-title d-block pt-2 pb-2"
																																														id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-" + subSub.id}
																																														href="#"

																																													>
																																														<div className={"req-left-comander"}>{ subSub.name }</div>

																																														<div className={"req-right-comander req-right-comander-readonly-tree"} >

																																															<div>
																																																{/*}
																																																<Button
																																																	color="success"
																																																	className="card-action info-button req-button-label"
																																																	onClick={ clickEvent => {

																																																		if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																																			this.setState({
																																																				...this.state,
																																																				getManagerListPopup: (this.state.tree.tabs.filter(t => t.open)[0]).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + sub.id + "-" + subSub.id
																																																			})
																																																		}
																																																	}}
																																																>
																																																	{
																																																		(!!subSub.userManager)
																																																			? (
																																																				<React.Fragment>
																																																					<i className="mdi mdi-account" />
																																																					<span className={"info-gri-manager-name-label req-button-label"} >
																																																						{ subSub.userManagerName }
																																																					</span>
																																																				</React.Fragment>
																																																			)
																																																			: (
																																																				<i className="mdi mdi-account-plus" />
																																																			)
																																																	}
																																																</Button>
																																																*/}

																																																{
																																																	( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
																																																		? (
																																																			<ActionButton
																																																				buttonText={
																																																					<React.Fragment>
																																																						<i className="mdi mdi-account" />
																																																						<span className={"info-gri-manager-name-label req-button-label"} >
																																																							{
																																																								(!!subSub.userManager)
																																																									? sub.userManagerName
																																																									: ""
																																																							}
																																																						</span>
																																																					</React.Fragment>
																																																				}
																																																				color={ (!!subSub.userManager) ? "success" : "primary" }
																																																				className="card-action info-button req-button-label btn manager-button"
																																																				promise={ () => {
																																																					return Promise.resolve( );
																																																				}}
																																																				onResult={ result => {
																																																					this.setState({
																																																						...this.state,
																																																						getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id + "-" + req.id + "-" + sub.id + "-" + subSub.id,
																																																						currentUserManager: subSub.userManager
																																																					})
																																																				}}
																																																				onError={ e => {

																																																				}}
																																																			/>
																																																		)
																																																		: null
																																																}
																																															</div>

																																															<div>
																																																<span className={"req-button-label"} >
																																																	Timing: {
																																																	(!!subSub.timing)
																																																		? "Mensile"
																																																		: "Annuale"
																																																}
																																																</span>
																																															</div>

																																															{
																																																(isMeasureUnit(subSub.measureUnit))
																																																	? (
																																																		<div>
																																																			<span className={"req-button-label"} >{ "Unità di Misura: "+ subSub.measureUnit }</span>
																																																		</div>
																																																	)
																																																	: null
																																															}

																																														</div>

																																													</div>
																																													{/*
																																													<UncontrolledTooltip placement="top" target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id}>
																																														<span className="d-none d-lg-block">{subSub.name.en}</span>
																																													</UncontrolledTooltip>
																																													*/}
																																												</h5>
																																											</CardHeader>
																																										</Card>
																																									);
																																								})
																																						)
																																						: null
																																				}


																																			</CardBody>
																																		</UncontrolledCollapse>
																																	)
																																	: null
																															}

																														</Card>
																													);
																												})
																										}






																									</CardBody>
																								</UncontrolledCollapse>
																							)
																							: null
																					}

																				</Card>
																			);
																		})
																}



															</CardBody>
														</UncontrolledCollapse>
													</Card>
												);
											})
									}





								</CardBody>
							</UncontrolledCollapse>
						</Card>
					);
				})





		);

	}


	componentDidMount() {

		this.standby()
			.then( () => {

				loadGriStructure( this.props.collect, TABS.general.id )

					.then(resultObj => {

						// console.log("resultObj");
						// console.log(resultObj);
						// console.table(resultObj);
						// console.log( exportSeedTableToCSV(resultObj) );

						convertTableToTree( resultObj )
							.then( tree => {

								// let freshTree = (!!previousTree) ? cloneOpenNodes( previousTree, tree ) : tree;
								let freshTree = tree;


								this.standby(
									{
										tree: freshTree,
										treeSeed: resultObj,
										currentTab: TABS.general
									},
									false
								)
									.then( () => {
										updateTreeStatusPath(this.props.collect, GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0], TABS.general.id);
										this.toggle(TABS.general.id);
									})
									.catch(e => {
										errorlog("errore al set standby", e);
										this.standby( { errorMessage: "Si è verificato un errore" }, false );
									})
								;



							})
							.catch(e => {
								errorlog("errore al convert table to tree", e);
								this.standby( { errorMessage: "Si è verificato un errore" }, false );
							})
						;

					})
					.catch(e => {
						console.error("error al load gri structure");
						console.log(e);
						this.standby( { errorMessage: "Si è verificato un errore" }, false );
					})



			})
			.catch(e => {
				errorlog("error al set dello standby",e);
			})


	}



}
