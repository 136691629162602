import React, {useState} from "react";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {translate} from "../../i18n/translate-function";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {errorlog, getLastElementOr, showlog} from "../../../utils";
import {loadPreviousMonthlyData} from "../../../business-logic/gri";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from "moment";
import {DATE_FORMAT_IT} from "../../../conf/siroad";


const fieldMap = {
    month: translate("Mese")
};


export const TreeDataEntryMonthlyVersion = props => {

    const [data, setData] = useState();
    const [activeTab, activateTab] = useState( () => "tab-content-0" );

    if( !data ) {
        loadPreviousMonthlyData( props.structureId )
            .then( result => {
                showlog( "result data" );
                showlog( result );
                setData( result );
            })
            .catch(e => {
                errorlog("GET Dati mensili", e);
            })
    };

    let context = (
        <WaitingOrNodataMessage
            waiting={ !data }
            className={ "score-modal-small-text" }
            nodataMessage={ translate("NoData") }
        />
    );

    if( !!data && (data.length > 0 ) ) {
        context = (
            <div>

                <h4>{ translate("yearlyVersionValuesTitle") }</h4>
                <ToolkitProvider
                    bootstrap4
                    keyField={ Object.keys(data.reduce(getLastElementOr, null))[0] }
                >
                    {
                        props => (

                            <React.Fragment>
                                {
                                    (!!data && (data.length > 0))
                                        ? (
                                            <BootstrapTable
                                                /* Tabella completa */
                                                bootstrap4
                                                striped
                                                {...props.baseProps}
                                                size="sm"
                                                responsive
                                                keyField={ Object.keys(data[0])[1] }  // "month"
                                                data={
                                                    data
                                                        .filter((_,i) => i === 0)
                                                        .map( r => {

                                                            let newRow = {};

                                                            Object.keys( r )
                                                                .map( field => {
                                                                    if( isNaN( r[field] ) ) {
                                                                        newRow[field] = r[field];
                                                                    }
                                                                    else {
                                                                        newRow[ field ] = <span className={"number-in-table-align"}>{ r[ field ] }</span>
                                                                    }
                                                                    return r;
                                                                })
                                                            ;

                                                            return newRow;

                                                        })
                                                }
                                                columns={

                                                    ( Object.keys( data[0] ) )
                                                        // .filter( (f, i) => i > 0 )
                                                        .map( field => {
                                                            return ({
                                                                dataField: field,
                                                                // lo stile è una pezza messa per fare veloce
                                                                text: ((field === "month") ? <span style={{minWidth: "62px", display: "block", minHeight: "20px"}}>{"  "}</span> : (fieldMap[ field ] || field)),
                                                                // sort: sortableFields.includes( field )
                                                            });
                                                        })
                                                        // custom date time sort -----------------------------------------------------------------------------------
                                                        .map( column => {
                                                            if( ["create", "date"].includes( column.dataField ) ) {
                                                                column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                                                                    if (order === 'asc') {
                                                                        return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                                                                    }
                                                                    return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                                                                }
                                                            }
                                                            return column;
                                                        })
                                                        .filter( ff => !["raw", "row"].includes(ff.dataField) )

                                                }
                                                defaultSorted={[
                                                    {
                                                        dataField: 'moment',
                                                        order: 'desc',
                                                    }
                                                ]}
                                                wrapperClasses="table-responsive"
                                            />
                                        )
                                        : null
                                }


                            </React.Fragment>

                        )
                    }

                </ToolkitProvider>


                <h4>{ translate("monthlyVersionValuesTitle") }</h4>
                <ToolkitProvider
                    bootstrap4
                    keyField={ Object.keys(data.reduce(getLastElementOr, null))[0] }
                >
                    {
                        props => (

                            <React.Fragment>
                                {
                                    (!!data && (data.length > 0))
                                        ? (
                                            <BootstrapTable
                                                /* Tabella completa */
                                                bootstrap4
                                                striped
                                                {...props.baseProps}
                                                size="sm"
                                                responsive
                                                keyField={ Object.keys(data.reduce(getLastElementOr, null))[0] }  // "month"
                                                data={
                                                    data
                                                        .filter((_,i) => i > 0)
                                                        .map( r => {

                                                            let newRow = {};

                                                            Object.keys( r )
                                                                .map( field => {
                                                                    if( isNaN( r[field] ) ) {
                                                                        newRow[field] = r[field];
                                                                    }
                                                                    else {
                                                                        newRow[ field ] = <span className={"number-in-table-align"}>{ r[ field ] }</span>
                                                                    }
                                                                    return r;
                                                                })
                                                            ;

                                                            return newRow;

                                                        })
                                                }
                                                columns={

                                                    ( Object.keys( data.reduce( getLastElementOr, null ) ) )
                                                        .map( field => {
                                                            return ({
                                                                dataField: field,
                                                                text: fieldMap[ field ] || field,
                                                                // sort: sortableFields.includes( field )
                                                            });
                                                        })
                                                        // custom date time sort -----------------------------------------------------------------------------------
                                                        .map( column => {
                                                            if( ["create", "date"].includes( column.dataField ) ) {
                                                                column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                                                                    if (order === 'asc') {
                                                                        return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                                                                    }
                                                                    return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                                                                }
                                                            }
                                                            return column;
                                                        })
                                                        .filter( ff => !["raw", "row"].includes(ff.dataField) )

                                                }
                                                defaultSorted={[
                                                    {
                                                        dataField: 'moment',
                                                        order: 'desc',
                                                    }
                                                ]}
                                                wrapperClasses="table-responsive"
                                            />
                                        )
                                        : null
                                }


                            </React.Fragment>

                        )
                    }

                </ToolkitProvider>



            </div>
        );
    }

    let content = (
        <Modal
            isOpen={ true }
            toggle={ () => {
                props.onClose()
            }}
        >
            <ModalHeader
                toggle={ () => {
                    props.onClose()
                }}
            >
                { translate("Values") }
            </ModalHeader>
            <ModalBody>
                { context }
            </ModalBody>
        </Modal>
    );

    return content;
}
