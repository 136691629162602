import {API_ENDPOINT, API_PORT, EMAIL_REGEX, errorMessage, HOST, PORT} from "../env";
import {Cookies} from 'react-cookie';
import jwtDecode from 'jwt-decode';
import {Account_EditPassword, PasswordSetting, SendResetPassword, SignupNewCustomer} from "./request-manager";
import {errorlog, showlog} from "../utils/dev-utils";

export const loginService = (email, password) => {



	if( !email ) return Promise.reject({ message: "Manca l'email", code: 200 });
	else {
		if( !!email && typeof email !== "string" ) return Promise.reject({ message: "Formato valore email errato", code: 200 });
		else {
			if( !!email && !EMAIL_REGEX.test( email ) ) return Promise.reject({ message: "email non valida", code: 200 });
		}
	}


	let endpoint = "/accounts/authenticate";
	let requestOptions = {
		method: 'POST',
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({"email": email,"password": password}),
		redirect: 'follow',
		credentials: 'include'
	};
	let url = HOST + API_PORT + API_ENDPOINT + endpoint;

	return (
		fetch(url, requestOptions)
			.then(response => response.json())
			.then(
				result => {

					if( !!result.status ) {
						if( result.code === 200 ) {


							// set cookies
							let now = new Date();
							const cookies = new Cookies();
							cookies.set(
								"user",
								btoa(JSON.stringify(result.data)),
								{
									path: "/",
									expires: new Date( (parseInt(jwtDecode(result.data.jwtToken).exp, 10) * 1000) + (364 * 24 * 3600000) )  // scadenza: 1 anno
								}
							);

							// localStorage
							localStorage.setItem("jwtToken", result.data.jwtToken);
							localStorage.setItem("id", result.data.id);
							localStorage.setItem("role", result.data.role);
							localStorage.setItem("company_id", result.data.company_id);
							localStorage.setItem("company_name", result.data.company_name);
							localStorage.setItem("companyName", result.data.companyName);
							localStorage.setItem("title", result.data.title);
							localStorage.setItem("email", result.data.email);
							localStorage.setItem("firstName", result.data.firstName);
							localStorage.setItem("lastName", result.data.lastName);
							localStorage.setItem("siRoadVersion", result.data.platformVersions.siRoad);
							localStorage.setItem("siRatingVersion", result.data.platformVersions.siRating);
							localStorage.setItem("siScoringVersion", result.data.platformVersions.siScoring);
							localStorage.setItem("srToolVersion", result.data.platformVersions.srTool);
							localStorage.setItem("paritaGenereVersion", result.data.platformVersions.paritaGenere);

							return result.data;

						}
						else {
							showlog("Errore al login code NOT 200");
							showlog(result);
							return Promise.reject(result);
						}
					}
					else {
						showlog("Errore al login nessuno status");
						showlog(result);
						return Promise.reject(result);
					}


					return Promise.reject("Strange Error");


				}
			)
			.catch( e => {
				showlog("Eccezione al login");
				showlog(e);
				// alert( e.error.msg );
				// alert("Errore al login");
				return Promise.reject(e);
			})
	);

};


export const refreshToken = () => {
	try {


		showlog("refresh token");
		showlog("utente", localStorage.getItem("role") );



		let token = localStorage.getItem("jwtToken");
		if(
			// token
			!!token

			// token format
			&& token.split(".").length === 3
			&& JSON.parse( window.atob( token.split(".")[0] ) )
			&& JSON.parse( window.atob( token.split(".")[1] ) )

			// token times
			&& !!JSON.parse( window.atob( token.split(".")[1] ) ).exp
			&& !!JSON.parse( window.atob( token.split(".")[1] ) ).iat
		) {


			let jwt = JSON.parse( window.atob( token.split(".")[1] ) );
			// let delta = (parseInt(jwt.exp, 10) * 1000) - (parseInt(jwt.iat, 10) * 1000);
			let delta = (parseInt(jwt.exp, 10) * 1000) - (new Date()).getTime() -1000;
			if( delta < 100 ) delta = 100;

			showlog("il prossimo refresh a "+ delta);

			setTimeout(
				() => {



					fetch(
						HOST + API_PORT + API_ENDPOINT +"/accounts/refresh-token",
						{
							method: 'POST',
							headers: {
								Origin: HOST + PORT ,
								Host: HOST + PORT ,
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwtToken"),
								Cache: 'no-cache'
							},
							redirect: 'follow',
							credentials: 'include'
						})
						.then(response => {

							if( !!response ) {
								if( response.status !== errorMessage.HTTP_OK_200.code ) {
									let error =
										Object.keys(errorMessage)
											.filter( field => errorMessage[field].code === response.status )
									;
									let errObj = {};
									if( !!error && error.length > 0 ) errObj = errorMessage[error[0]];
									throw ({ status: "error", error: errObj });
								}
								return response.json();
							}
							else return ({jwtToken: null});

						})
						.then(resultObj => {
							localStorage.setItem( "jwtToken", resultObj.jwtToken );


							showlog("controllo refresh token");


							if( !!window.isInLogin ) window.location.href = window.location.href;
							else {
								window.isInLogin = false;
								refreshToken();
							}


						})
						.catch(error => {
							showlog('error alla richiesta refresh-token');
							showlog(error);
							showlog(JSON.stringify(error));
							showlog(error.valueOf());
							// alert("stop 3");
						})
				},
				delta
			)
		}
		else {

			// setTimeout( refreshToken, 900 );
		}

	}
	catch (e) {
		errorlog("errore al get token",e);
		showlog(e.valueOf());

		// setTimeout( refreshToken, 900 );
	}


};


export const editNewPassword = data => {
	return Account_EditPassword( data );
}


export const enableUserByPasswordReset = (email, payload) => {
	/*
	{
		email:"enzobuffa@gmail.com",
		password:"qwerty",
		confirmPassword:"qwerty",
		token:"qwerty",
		isNew:true
	}
	*/
	return PasswordSetting( Object.assign({}, payload, { email: email }) );
}


/*
	return (
		new Promise( (success, fail) => {
			setTimeout( () => {
				if( Math.floor(Math.random() * 100) % 2 === 0 ) success();
				else                                               fail();
			},
				300000
			)
		})
	);
 */


export const requestResetPassword = email => {
	if(!email) return Promise.reject("manca l'email");
	return SendResetPassword({ email: email });
}



export const signupCustomer = data => SignupNewCustomer( data );
