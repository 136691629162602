import React from "react";
import "./settings-collect-svg-icon-component.css";

export const SettingsCollectSvgIconComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a">
                <path className="setting-collect-icon-c"
                      d="M108.7,33c-1.17-3.79-3.17-4.25-6.02-1.41-3.93,3.92-7.89,7.82-11.76,11.8-.82,.84-1.56,1.03-2.66,.81q-6.1-1.24-7.28-7.36c-.2-1.02-.04-1.73,.75-2.51,4.1-4.01,8.14-8.08,12.18-12.14,2.44-2.46,1.87-4.53-1.46-5.63-3.92-1.3-7.87-1.66-11.98-1.09-14.35,1.99-24.48,15.94-21.66,30.11,.36,1.82-.04,2.9-1.32,4.17-11.67,11.56-23.27,23.18-34.86,34.81-5.4,5.41-5.82,12.44-1.19,18.02,1.91,2.3,4.54,3.36,7.24,4.33h4.66c4.2-1.16,7.12-4.1,10.07-7.07,10.85-10.92,21.76-21.79,32.64-32.67,.68-.68,1.22-1.25,2.43-.99,12.48,2.69,24.33-3.33,29.39-15.02,1-2.31,1.45-4.87,2.16-7.31v-6.09c-.44-1.58-.84-3.18-1.32-4.75Zm-21.05,28.07c-3.25,.45-6.41,.35-9.52-.68-1.74-.57-3.04-.12-4.32,1.18-12.21,12.27-24.53,24.43-36.66,36.78-4.13,4.2-8.69,3.49-11.22,1-2.52-2.48-3.35-7.1,.98-11.35,12.28-12.03,24.34-24.29,36.53-36.4,1.44-1.43,1.87-2.78,1.32-4.77-3.27-11.77,3.34-22.97,15.2-25.66,2.25-.51,4.65-.37,6.98-.53l.35,.74c-1.97,1.87-3.96,3.72-5.91,5.61-1.71,1.66-3.38,3.38-5.07,5.06-1.1,1.09-1.52,2.27-1.12,3.88,.75,3,1.33,6.04,1.92,9.08,.34,1.72,1.25,2.74,3.01,3.08,2.98,.58,5.97,1.12,8.9,1.89,1.89,.5,3.2-.09,4.47-1.38,3.22-3.28,6.49-6.51,9.74-9.76,.24-.24,.51-.46,.95-.86,1.98,10.67-6.03,21.64-16.52,23.09Z"/>
            </g>
            <g id="b"/>
        </svg>
    </div>
);
