import {
    AdminQuestionNote_GET,
    AdminQuestionNote_SAVE,
    Answer_DeleteFile,
    Answer_UploadFile,
    Answers_Put,
    Attachment_Set,
    ChoicheQuestions_Create,
    ChoicheQuestions_Delete,
    ChoicheQuestions_Get,
    ChoicheQuestions_Update,
    criteriaChart,
    DisableSectorQuestions_PUT,
    downloadAnswersDataFile,
    DownloadGapAnalysisCSVFile,
    downloadGapAnalysisDataFile,
    DownloadMaterialityCSVFile,
    DownloadMaterialityXMLFile,
    downloadProfilingDataFile,
    DownloadRatingFile,
    DownloadRatingQuestionAnswersFile,
    DownloadSGDXMLFile,
    editRatingName_Put,
    Evaluations_Add,
    Evaluations_Status,
    flatAnswer_DELETE,
    flatAnswer_PUT,
    flatQuestion_GET,
    flatQuestion_PUT,
    flatQuestionGL_PUT,
    getAnswerProgress, GetSiRatingQuestionInfo,
    PdfParameters_Get,
    PdfParameters_Put,
    PerimeterData_Get,
    PerimeterData_Put,
    Question_DownloadFile,
    QuestionAudit,
    QuestionHistory_Get,
    QuestionNote_GET,
    QuestionNote_SAVE,
    QuestionsStructure_Get,
    QuestionsStructure_GetAnswer,
    QuestionsStructure_Put,
    Rating_Delete,
    RatingInfo_Get,
    RatingStatus_Get,
    SectorQuestions_Create,
    SectorQuestions_Delete,
    SectorQuestions_Get,
    SectorQuestions_Update,
    SiScoringChoicheQuestions_Get,
    SRToolInformative_DeleteFile,
    SRToolInformative_UploadFile,
    SRToolInfromativeAttachment_DownloadFile,
    Thematics_Update,
    themesChart,
    ValidAllAudit
} from "./request-manager";
import {errorlog, showlog} from "../utils/dev-utils";
import {truncateFileName} from "../utils/string-utils";
import {saveAs} from 'file-saver';


export const createNewRating = (companyId, dimensionId, name, cloneConfFrom, ratingType) => {
    let body = {
        CompanyId: companyId,
        CompanySize: dimensionId,
        name: name,
        evaluationId: cloneConfFrom,
        ratingType: ratingType
    };
    return Evaluations_Add(body);
};



export const loadQuestions = (ratingId, macroAreaId) => {
    let maid = !!macroAreaId ? macroAreaId : 0;
    return QuestionsStructure_Get(ratingId, maid);
}


export const getSectorQuestions = () => {
    return SectorQuestions_Get();
}


export const createNewQuestionSector = data => {
    return SectorQuestions_Create(data);
}


export const updateNewQuestionSector = (data, id) => {
    return SectorQuestions_Update(data, id);
}


export const getChoiche = sectorQuestionId => {
    return ChoicheQuestions_Get(sectorQuestionId);
}

export const getSiScoringChoiche = chId => SiScoringChoicheQuestions_Get( chId );


export const createAnswerChoiche = (questionId, data) => {
    showlog("service della creazione della domanda");
    showlog(questionId, data);
    // return Promise.resolve();

    delete data.isNewer;
    delete data.isEdit;

    return ChoicheQuestions_Create(questionId, data);
}


export const editAnswerChoiche = data => {
    delete data.isNewer;
    delete data.isEdit;
    return ChoicheQuestions_Update(data.id, data);
}


export const eraseSectorQuestion = id => {
    return SectorQuestions_Delete(id);
}


export const setDisabledSectorQuestion = (id) => {
    return DisableSectorQuestions_PUT(id);
}


export const eraseAnswerChoice = id => {
    return ChoicheQuestions_Delete(id);
}


// Gruppo Manara S.p.A. - Stoccaggio


export const editRatingName = (id, name) => {
    let payload = {
        name: name
    };
    return editRatingName_Put( id, payload );
    // return (
    //     new Promise((success, fail) => {
    //         setTimeout(function() {
    //             success()
    //         }.bind( this ), 2000);
    //     })
    // );
}


export const enableQuestionByThemes = (ratingId, themeId, subThemeId) => {
    let pathUrl = [ratingId, themeId, subThemeId || 0].join("/");
    return QuestionsStructure_Put({ enable: true }, pathUrl);
}

export const disableQuestionByThemes = (ratingId, themeId, subThemeId) => {
    let pathUrl = [ratingId, themeId, subThemeId || 0].join("/");
    return QuestionsStructure_Put({ enable: false }, pathUrl);
}



export const enableQuestionById = (ratingId, qid) => {
    let pathUrl = [ratingId, qid].join("/");
    return QuestionsStructure_Put({ enable: true }, pathUrl);
}

export const disableQuestionById = (ratingId, qid) => {
    let pathUrl = [ratingId, qid].join("/");
    return QuestionsStructure_Put({ enable: false }, pathUrl);
}



// RATING_STATUS
export const passToEvaluationStatus = (eid, status) => {
    return Evaluations_Status(eid, status);
}


// path parameters:	ratingId / tabId / choicheId
export const getAnswer = ratingId => {
    return QuestionsStructure_GetAnswer(ratingId);
}


export const getAllFlatQuestions = id => {
    return flatQuestion_GET( id );
}


export const editQuestion = (id, payload) => {
    return flatQuestion_PUT(id, payload);
}

export const editQuestionGL = (id, payload) => {
    return flatQuestionGL_PUT(id, payload);
}

export const editAnswer = (id, payload) => {
    return flatAnswer_PUT(id, payload);
}

export const deleteAnswer = ansID => flatAnswer_DELETE( ansID );

export const setAnswer = (payload, parameters, isSiScoring) => {
    return Answers_Put(payload, parameters, isSiScoring);
};


export const uploadSRToolInformativeAttachment = (data, fileName, collectId, informativeId = 0 ) => {
    let truncatedFileName = truncateFileName(fileName);
    truncatedFileName = truncatedFileName.split("#").join("_");

    let urlPath = [
        truncatedFileName,
        collectId,
        (!!informativeId ? informativeId : "0")
    ].join("/");

    // debugger;
    return SRToolInformative_UploadFile(data, urlPath, truncatedFileName);
}



export const uploadQuestionAnswerAttachment = (data, fileName, ratingId, questionId) => {
    let truncatedFileName = truncateFileName(fileName);
    truncatedFileName = truncatedFileName.split("#").join("_");

    let urlPath = [
        truncatedFileName,
        ratingId,
        questionId
    ].join("/");

    // showlog("controllo errore al upload del file");
    // throw ({status: "fail", error: { code: "th-2", message: "errore al upload dei file" }});


    return Answer_UploadFile(data, urlPath, truncatedFileName);
}

export const deleteQuestionAnswerAttachment = aid => {
    return Answer_DeleteFile(aid);
}

export const deleteSRToolInformativeAttachment = aid => {
    return SRToolInformative_DeleteFile(aid);
}

export const dowloadQuestionAnswerAttachment = (aid, fileName) => {
    return Question_DownloadFile(aid, fileName);
}

export const dowloadSRToolInformativeAttachment = (aid, fileName) => {
    return SRToolInfromativeAttachment_DownloadFile(aid, fileName);
}

export const dowloadRatingFile = (rid, ratingName) => {
    return DownloadRatingFile(rid, ratingName);
}

export const dowloadRatingQuestionAnswersFile = (rid, ratingName) => {
    return DownloadRatingQuestionAnswersFile(rid, ratingName);
}

export const getSgdXMLFile = (rid, fileName) => {
    return (
        DownloadSGDXMLFile(rid)
        .then(fileContent => {
            let file = new Blob([fileContent], { type: "text/xml;charset=utf-8" });
            saveAs(file, fileName);
            return true;
        })
        .catch(e => {
            errorlog("errore", e);
            return Promise.reject(e);
        })
    );
}

export const getMaterialityXMLFile = (rid, fileName) => {
    return (
        DownloadMaterialityXMLFile(rid)
        .then(fileContent => {
            let file = new Blob([fileContent], { type: "text/xml;charset=utf-8" });
            saveAs(file, fileName);
            return true;
        })
        .catch(e => {
            errorlog("errore", e);
            return Promise.reject(e);
        })
    );
}


export const getMaterialityCSVFile = (rid, fileName) => {
    return (
        DownloadMaterialityCSVFile(rid)
        .then(fileContent => {
            let file = new Blob([fileContent], { type: "text/csv;charset=utf-8" });
            saveAs(file, fileName);
            return true;
        })
        .catch(e => {
            errorlog("errore", e);
            return Promise.reject(e);
        })
    );
}

export const getGapAnalysisCSVFile = (rid, fileName) => {
    return (
        DownloadGapAnalysisCSVFile(rid)
        .then(fileContent => {
            let file = new Blob([fileContent], { type: "text/csv;charset=utf-8" });
            saveAs(file, fileName);
            return true;
        })
        .catch(e => {
            errorlog("errore", e);
            return Promise.reject(e);
        })
    );
}


// non usata
export const getRataingInfo = id => {
    return RatingInfo_Get(id);
}

export const saveConfigurationQuestionThematicReason = (id, data) => {
    return Thematics_Update(id, data);
}



export const setQuestionAudit = (ratingId, questionId, payload) => {
    return QuestionAudit(ratingId, questionId, payload);
}


export const getRatingStatus = id => {
    return RatingStatus_Get(id);
}


export const setAttachmentAprovingService = (attachemntId, value) => {
    return Attachment_Set(attachemntId, value);
}


export const getRatingPdfParameters = id => {
    return PdfParameters_Get(id);
}

export const setRatingPdfParameters = (id, payload) => {
    return PdfParameters_Put(id, payload);
}


export const getProfilingData = ratingId => {
    return PerimeterData_Get(ratingId);
}

export const getProfilingDataFile = ratingId => {
    return downloadProfilingDataFile(ratingId);
}


export const getAnswersDataFile = ratingId => {
    return downloadAnswersDataFile(ratingId);
}

export const getGapAnalysisDataFile = ratingId => {
    return downloadGapAnalysisDataFile(ratingId);
}

export const setProfilingData = (ratingId, questionId, payload) => {
    return PerimeterData_Put(ratingId, questionId, payload);
}


export const getQuestionHistory = (rid, qid) => {
    return QuestionHistory_Get(rid, qid);
}



export const getSiRatingAdminScoreThemesChartData = (ratingData, isMultipleData) => {
    return (
        new Promise((success, fail) => {


            setTimeout(
                function() {
                    if (!!isMultipleData) {
                        success([{
                                name: '2021',
                                data: Array(12)
                                    .fill(true)
                                    .map(v => Math.floor(Math.random() * 100))
                            },
                            {
                                name: '2020',
                                data: Array(12)
                                    .fill(true)
                                    .map(v => Math.floor(Math.random() * 100))
                            },
                            {
                                name: '2019',
                                data: Array(12)
                                    .fill(true)
                                    .map(v => Math.floor(Math.random() * 100))
                            },
                        ]);
                    } else {
                        success([{
                            name: '2021',
                            data: Array(12)
                                .fill(true)
                                .map(v => Math.floor(Math.random() * 100))
                        }]);
                    }
                },
                500 + Math.floor(Math.random() * 1500)
            )
        })
    );
}



export const loadThemesChart = (ratingId, macroAreaId) => {
    return themesChart(ratingId, macroAreaId);
}

export const loadCriteriaChart = (ratingId) => {
    return criteriaChart(ratingId);
}

export const getProgress = ratingId => {
    return getAnswerProgress(ratingId);
}



export const getQuestionNote = (ratingId, qId) => {
    showlog("servizio presa della nota");
    return QuestionNote_GET(ratingId, qId);
}

export const getAdminQuestionNote = (ratingId, qId) => {
    return AdminQuestionNote_GET(ratingId, qId);
}


export const saveQuestionNote = (ratingId, qId, note) => {
    return QuestionNote_SAVE(ratingId, qId, note);
}

export const saveAdminQuestionNote = (ratingId, qId, note) => {
    return AdminQuestionNote_SAVE(ratingId, qId, note);
}

export const eraseRating = id => {
    return Rating_Delete( id );
}

export const validAllAudit = id => {
    return ValidAllAudit(id);
}

export const getSiratingQuestionInfo = id => {
    return GetSiRatingQuestionInfo(id);
}