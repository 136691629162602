import React, {Component} from "react";
import {Button, Card, CardBody, Col, CustomInput, Label, Row, UncontrolledTooltip} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../../../components/PageTitle";
import {COLOR_POLICY, DATE_FORMAT_IT, HOST, PATH_PREFIX, permissions, RATING_STATUS, role} from "../../../env";
import {getRatingAttachments, loadEvaluations} from "../../../business-logic/active-service";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getLastElementOr, inverseSortById} from "../../../utils/array-utils";
import {emoj} from "../../../utils/string-utils";
import {getAllCurrentPermissions} from "../../../utils/role-utils";
import {Redirect} from "react-router-dom";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import AddRatingModal from "../../../components/area/super-admin/new-rating-modal";
import SiRatingRatingAttachmentModal from "../si-rating-admin/sirating-rating-attachment-modal";
import {EMOJ} from "../../../emoj";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import ScoreModal from "../../../components/area/super-admin/score-modal";
import SdgScoreModal from "../../../components/area/super-admin/sdg-score-modal.js";
import CriteriaModal from "../../../components/area/super-admin/criteria-modal";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import StateModal from "../../../components/area/super-admin/state-modal";
import {downloadSIRatingRatingFile} from "../../../business-logic/rating";
import RatingPdfParametersModal from "../../../components/area/super-admin/rating-pdf-parameters-modal";
import Select from 'react-select';
import EvaluationRolesArbModal from "../../../components/area/super-admin/evaluationRoles-list-modal";
import moment from "moment";
import {RATING_TYPE} from "../../../conf/siscoring";
import {translate} from "../../../components/i18n/translate-function";
import SiRatingLogoDarkText from "../../../assets/images/SiRating_logo_dark_text_2.svg";
import AssociatedSectorQuestionModal from "./associated-sector-question-modal";
import {loadAllSectors} from "../../../business-logic/companies";
import VersionModal from "../../../components/area/super-admin/version-modal";
import AssociatedSectorModal from "./associated-sector-modal";
// SiRating_logo_dark_text_2

const hiddenFields = [
	"companyId",
	"dimensionId",
	"dimensionName",
	"Dimensione",
	"dimension",
	"companySize",
	"undefined",
	"status",
	isUserRole(role.PROJECT_MANAGER) ? "" : "Ruoli",
	isUserRole(role.ARB_OPERATOR) ? "" : "PM",
	isUserRole(role.ARB_OPERATOR) ? "" : "Assignee",
	isUserRole(role.PROJECT_MANAGER) ? "" : "id",
	"version",
	"sectorList"
];

const fieldMap = {
	id: "id",
	versionComponent: translate("Version"),
	codCliFor: "companyId",
	name: translate("Name"),
	dateCreate: translate("Creation Date"),
	ragioneSociale: "Ragione Sociale",
	dimensionName: "Dimensione",
	state: "Status",
	scoreButton: translate("Score"),
	userRoles: "Ruoli",
	//sendMailNcQuestions: "Invio Domande NC Lievi",
	manager:"PM",
    assignee:"Assignee",
	files: translate("Attachments"),
	status: "status",
	mainSectorComander: "Domande di settore associate",
	sectorList:"sectorList",
	associatedSector:"Settori"


};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Rating")}
        </span>
	),
	sizePerPageList: [
		{
			text: "pochi",
			value: 25,
		},
		{
			text: "normale",
			value: 50,
		},
		{
			text: "molti",
			value: 250,
		}
	]
};

const defaultSorted = [
	{
		dataField: "Id",
		order: "desc",
	},
];

const sortableFields = [
	translate("Name"),
	translate("Creation Date"),
	"Ragione Sociale",
	"Dimensione",
	"Status",
];


const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			// custom date time sort -----------------------------------------------------------------------------------
			.map( column => {
				if( [translate("Creation Date")].includes( column.dataField ) ) {
					column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
						if (order === 'asc') {
							return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
						}
						return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
					}
				}
				return column;
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SIRatingPage extends Component {

	state = {
		allSectors: [],
		activeStatusFilter: -1,
		activeSectorFilter: -1
	}

	constructor(props) {
		super( props );
		this.loadData = this.loadData.bind( this );
		this.permissionDenied = this.permissionDenied.bind( this );
		this.ratingStatusFilterComponent = this.ratingStatusFilterComponent.bind( this );
		this.ratingSectorFilterComponent = this.ratingSectorFilterComponent.bind( this );
	}


	permissionDenied( permission ) {
		return (
			<div className={"table-inner-pannel card-permission-denied-bg"}>
				<Row>
					{ emoj( EMOJ.police_car_light ) }
					<code className={ COLOR_POLICY.error.textClass }>&nbsp;&nbsp;PERMESSO NEGATO&nbsp;&nbsp;</code>
					{ emoj( EMOJ.police_car_light ) }
				</Row>
				<Row>&nbsp;</Row>
				<Row>&nbsp;</Row>
				<Row>
					<em>{ permission.label }</em>
				</Row>
			</div>
		)
	}


	ratingStatusFilterComponent() {
		return (
			<React.Fragment>
				<Label for="rating-filter">{translate("Status")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
				<Select
					className="react-select question-filter-select"
					classNamePrefix="react-select"
					name="rating-filter"
					id="rating-filter"
					onChange={ changeEvent => {

						if( changeEvent.value == -1){

							if( this.state.activeSectorFilter == -1 ) {
								showlog("-1 -1")
								this.setState({
									...this.state,
									data: this.state.allData,
									activeStatusFilter: changeEvent.value
								})
							}
							else {
								showlog("-1 !-1")
								this.setState({
									...this.state,
									data: this.state.allData.filter( d => d.sectorList.map( r => r.sector_id).includes(this.state.activeSectorFilter) ),
									activeStatusFilter: changeEvent.value
								})
							}

						}else{

							if( this.state.activeSectorFilter == -1 ) {
								showlog("!-1 -1")
								this.setState({
									...this.state,
									data: this.state.allData.filter( d => d.status === changeEvent.value ),
									activeStatusFilter: changeEvent.value
								})
							}
							else {
								showlog("!-1 !-1")
								this.setState({
									...this.state,
									data: this.state.allData.filter( d => d.status === changeEvent.value ).filter( d => d.sectorList.map( r => r.sector_id).includes(this.state.activeSectorFilter) ),
									activeStatusFilter: changeEvent.value
								})
							}
						}


					}}
					options={
						[
							{ value: -1, label: translate("All")},
							...Object.keys( RATING_STATUS )
								.map( status => RATING_STATUS[ status ] )
								.map( ratingStatus => ({
									value: ratingStatus.id,
									label: ratingStatus.name
								}))
								.sort( inverseSortById )
						]
					}
					placeholder={ <span className={"select-placeholder"}>{translate("Select filter")}</span> }
				/>
			</React.Fragment>
		);
	}
	ratingSectorFilterComponent() {
		return (
			<React.Fragment>
				<Label for="rating-filter">{translate("SASB Sector")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
				<Select
					className="react-select question-filter-select"
					classNamePrefix="react-select"
					name="rating-filter"
					id="rating-filter"
					onChange={ changeEvent => {

						if(changeEvent.value == -1){

							if( this.state.activeStatusFilter == -1 ) {
								showlog("-1 -1")
								this.setState({
									...this.state,
									data: this.state.allData,
									activeSectorFilter: changeEvent.value
								})
							}
							else {
								showlog("!-1 -1")
								this.setState({
									...this.state,
									data: this.state.allData.filter( d => d.status === this.state.activeStatusFilter ),
									activeSectorFilter: changeEvent.value
								})
							}
						}
						else {

							if( this.state.activeStatusFilter == -1 ) {
								showlog("-1 !-1")
								this.setState({
									...this.state,
									data: this.state.allData.filter( d => d.sectorList.map( r => r.sector_id).includes(changeEvent.value) ),
									activeSectorFilter: changeEvent.value
								})
							}
							else {
								showlog("!-1 !-1")
								this.setState({
									...this.state,
									data: this.state.data.filter( d => d.sectorList.map( r => r.sector_id).includes(changeEvent.value) ).filter( d => d.status === this.state.activeStatusFilter ),
									activeSectorFilter: changeEvent.value
								})
							}

						}


					}}
					options={
						[
							{ value: -1, label: translate("All")},
							...this.state.allSectors
								.map( sector => ({
									value: sector.sector_id,
									label: sector.sector_name
								}))
						]
					}
					placeholder={ <span className={"select-placeholder"}>{translate("Select filter")}</span> }
				/>
			</React.Fragment>
		);
	}


	render() {

		renderlog( "SIRatingPage", this.state, this.props);
		showlog("permessi correnti", getAllCurrentPermissions() );

		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{
							label: "SI Rating"
							/* Object.keys( role )
								.map( roleObjectName => role[ roleObjectName ] )
								.filter( roleObject => roleObject.name === localStorage.getItem("role") )
								.reduce( getLastElementOr, "Nessun Ruolo" )
								.label */
							, path: PATH_PREFIX +"/welcome" },
						{ label: translate("Evaluations"), path: PATH_PREFIX +"/super-admin/si-rating/" + translate("evaluations"), active: true }
					]}
					title={
						<React.Fragment>
							{/*
							<SiRatingSvgIconComponent
								className={"logo-over-list"}
							/>
							<img
								src={ PRODUCTION_HOST +"/assets/Loghi/SiRating.png" }
								alt={"Si Rating"}
								// height="30"
								style={{marginRight:'10px', height: "80px"}}
							/>
							*/}
							<img
								// src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
								src={ SiRatingLogoDarkText }
								style={{
									marginRight:'10px',
									height: "60px",
									float: "left",
									marginTop: "10px"
								}}
							/>

							<p style={{float:"left", marginTop: "27px",
								marginBottom: "-23px",
								fontSize: "14px",cursor:"pointer",height:"0px"}} onClick={clickEvent => {
								this.setState({
									versionModal: true
								})
							}
							}> v. {(localStorage.getItem("siRatingVersion")).trim()} </p>
							{/*<span>SI Rating</span>*/}
							{/*<span>Valutazioni</span>*/}
						</React.Fragment>
					}
				/>

				{
					!!this.state.versionModal
						? (
							<VersionModal
								tool={"SI Rating-2"}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										versionModal: false
									})
								}}/>
						)
						:
						null
				}


				{
					(!!this.state.userRolesSa)
						? (
							<EvaluationRolesArbModal
								type={1}
								evaluationId= { this.state.userRolesSa}
								onClose={ () => {
									this.setState({
										...this.state,
										userRolesSa: null
									})
								}}
								onChoose={ list => {
									showlog("lista dei settori scelti");
									showlog(list);

									this.setState({
										...this.state,
										userRolesSa: null
									})

								}}
							/>
						)
						: null
				}
				{
					(!!this.state.userRolesArb)
						? (
							<EvaluationRolesArbModal
								type={2}
								evaluationId= {this.state.userRolesArb}
								onClose={ () => {
									this.setState({
										...this.state,
										userRolesArb: null
									})
								}}
								onChoose={ list => {
									showlog("lista dei settori scelti");
									showlog(list);

									this.setState({
										...this.state,
										userRolesArb: null
									})

								}}
							/>
						)
						: null
				}

				{
					(!!this.state.attachmentModal)
						? (
							<SiRatingRatingAttachmentModal
								isFromSA={ isUserRole( role.PROJECT_MANAGER ) }
								questionId={ 0 }
								files={ this.state.ratingAttachments }
								ratingId={ this.state.attachmentModal }
								onAttachmentUploaded={ attachments => {
									/*
									// ATENZIONE non prende i cancellati
									let treeTmp = cloneObject(this.state.tree);
									let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, getQuestionPath(treeTmp, this.state.attachmentModalQuestionId));
									refreshedQuestion.attachments = [...refreshedQuestion.attachments, ...attachments];
									this.setState({
										...this.state,
										tree: treeTmp
									})
									*/
								}}
								onAttachmentDelete={ attachmentIds => {
									/*
									let treeTmp = cloneObject(this.state.tree);
									let refreshedQuestion = getQuestionTreeNodeByPath(treeTmp, getQuestionPath(treeTmp, this.state.attachmentModalQuestionId));
									refreshedQuestion.attachments = refreshedQuestion.attachments.filter( a => !!!attachmentIds.includes( a.attachment_id ) )
									this.setState({
										...this.state,
										tree: treeTmp
									})
									*/
								}}
								onError={ e => {
									errorlog("errore nel caricamento dell'allegato");
									showlog( e );
								}}
								onClose={ () => {
									this.setState({
										...this.state,
										attachmentModal: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.scoreModal)
						? (
							<ScoreModal
								questionId={ this.state.scoreModal }
								evaluation_id={ this.state.evaluation_id }
								onClose={ () => {
									this.setState({
										...this.state,
										scoreModal: null
									})
								}}
							/>
						)
						: null
				}

				{
					// (!!this.state.sendMailNcQuestions)
					// 	? (
					// 		<SendMailNcQuestions
					// 			questionId={ this.state.scoreModal }
					// 			evaluation_id={ this.state.sendMailNcQuestions }
					// 			onClose={ () => {
					// 				this.setState({
					// 					...this.state,
					// 					sendMailNcQuestions: null
					// 				})
					// 			}}
					// 		/>
					// 	)
					// 	: null
				}

				{
					(!!this.state.sgdScoreModal)
						? (
							<SdgScoreModal
								questionId={ this.state.scoreModal }
								evaluation_id={ this.state.evaluation_id }
								onClose={ () => {
									this.setState({
										...this.state,
										sgdScoreModal: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.criteriaModal)
						? (
							<CriteriaModal
								evaluation_id={ this.state.criteriaModal }
								onClose={ () => {
									this.setState({
										...this.state,
										criteriaModal: null
									})
								}}
							/>
						)
						: null
				}



				{
					(!!this.state.stateModal)
						? (
							<StateModal
								evaluation_id={ this.state.stateModal }
								onClose={ () => {
									this.setState({
										...this.state,
										stateModal: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.pdfModal)
						? (
							<RatingPdfParametersModal
								id={ this.state.pdfModal }
								name={ this.state.pdfName }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										pdfModal: null
									});
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.associatedSectorQuestionModal)
						? (
							<AssociatedSectorQuestionModal
								evaluation={ this.state.associatedSectorQuestionModal }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										associatedSectorQuestionModal: false
									})
								} }
							/>
						)
						: null
				}

				{
					(!!this.state.associatedSector)
						? (
							<AssociatedSectorModal
								evaluation={ this.state.associatedSector }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										associatedSector: false
									})
								} }
							/>
						)
						: null
				}


				<Card>
					<CardBody>

						<Row>
							<Col>
								<div className="page-title-box">
									<div className="page-title-right">

										{
											(!!hasPermission(permissions.ADD_RATING))
												? (
													<Button
														color={"success"}
														onClick={ clickEvent => {
															this.setState({
																...this.state,
																newRatingModal: true
															});
														}}
													>
														<i className="uil-user"/> Nuova Valutazione
													</Button>
												)
												: null
										}


									</div>
									<h4 className="page-title">{translate("Ratings List")}</h4>
								</div>
							</Col>
						</Row>



							<ToolkitProvider
								bootstrap4
								keyField="id"
								search
								exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
								{props => (
									<React.Fragment>


										<div className={"rating-filters-and-comander"}>

											<div className={"rating-filters"}>
												<div className={""}>{ this.ratingStatusFilterComponent() }</div>
												<div className={""} style={{marginLeft:"10px", marginRight:"10px"}}>{ this.ratingSectorFilterComponent() }</div>
												<div className={""}><SearchBar {...props.searchProps} /></div>
											</div>
											<div className={"rating-comander"}>
												{
													!!hasPermission( permissions.GET_ARCHIVED_RATINGS )
														? (
															<CustomInput
																type="switch"
																id={"archived-rating-toggler"}
																name={"archived-rating-toggler"}
																label={
																	<span>{translate("Archive")}</span>
																}
																className={""}
																onClick={clickEvent => {

																	if( !!document.getElementById( "archived-rating-toggler" ) ) {
																		document.getElementById( "archived-rating-toggler" ).checked = !this.state.isArchived;
																	}

																	// let isArchived = !this.state.isArchived;
																	this.setState({
																			...this.state,
																			data: null,
																			isArchived: !this.state.isArchived
																		},
																		() => {
																			this.loadData();
																		}
																	)


																}}
																defaultChecked={ !!this.state.isArchived }
																valid={ !!this.state.isArchived && !!this.state.data }
															/>
														)
														: null
												}
											</div>



											{/*
												<Col className="text-right">
													<ExportCSVButton {...props.csvProps} className="btn btn-primary">
														Export CSV
													</ExportCSVButton>
												</Col>
											*/}
										</div>


										{


											(!!this.state.data && this.state.data.length > 0 )
												? (

													<BootstrapTable
														bootstrap4
														striped
														{...props.baseProps}
														size="sm"
														responsive
														keyField="id"
														data={ this.state.data }
														columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
														defaultSorted={ defaultSorted }
														pagination={ paginationFactory(paginationOptions) }
														wrapperClasses="table-responsive"

														expandRow={{
															renderer: row => {
																showlog("riga selezionata");
																showlog(row);

																let rowStatusId =
																	Object.keys( RATING_STATUS )
																		.map( status => RATING_STATUS[ status ] )
																		.filter( ratingStatus => ratingStatus.name === row.Status )
																		.map( ratingStatus => ratingStatus.id )
																		.reduce( getLastElementOr, 100 )
																;

																//debugger;


																delete row[translate("Attachments")];
																delete row[translate("Score")];
																delete row.Ruoli;
																delete row.sendMailNcQuestions;
																delete row["Invio Domande NC Lievi"];
																// delete row.userRoles;
																delete row.files;
																delete row.PM;
																delete row.Assignee;
																// delete row.scoreButton;
																delete row["Domande di settore associate"];
																delete row[translate("Version")];
																delete row["Settori"];


																switch ( rowStatusId ) {

																	//	---- 0 ------------------------------------------------------------------------------------
																	case RATING_STATUS.configurationRunning.id :
																		break;

																	//	---- 3 -----------------------------------------------------------------------------------
																	case RATING_STATUS.perimetralQuestionCompleted.id :
																		/*
																		if( !!hasPermission( permissions.SHOW_COMPLETED_READONLY_PERIMETRAL_QUESTION ) ) {
																			return (
																				<PerimeterQuestionReadOnlyPage
																					id={ row.id }
																					isFromAuditer={ true }
																				/>
																			);
																		}
																		else {
																			return this.permissionDenied( permissions.SHOW_COMPLETED_READONLY_PERIMETRAL_QUESTION );
																		}
																		*/
																		break;

																	//	---- 5 -----------------------------------------------------------------------------------
																	case RATING_STATUS.perimetralQuestionValidated.id :
																		break;

																	//	---- 10 -----------------------------------------------------------------------------------
																	case RATING_STATUS.configurationComplete.id :
																		if( !!hasPermission( permissions.GO_TO_RATING_CONFIGURATION_AFTER_COMPLETATION ) ) {
																			// vado liscio

																			return (
																				<Redirect
																					to={{
																						pathname: PATH_PREFIX +"/si-rating/" + translate("evaluation")  + "/"+ row.id,
																						state: row
																					}}
																				/>
																			);



																		}
																		else {

																			/*
																			return <SIRatingReadOnlyEvalutationPage id={ row.id } title={ RATING_STATUS.configurationComplete.name }/>;
																			*/

																			return (
																				<Redirect
																					to={{
																						pathname: PATH_PREFIX +"/" + translate("super-admin") + "/" + translate("configuration") + "/" + translate("evaluation") + "/"+ row.id,
																						state: row
																					}}
																				/>
																			);


																		}
																		break;


																	//	---- 20 ------------------------------------------------------------------------------------
																	case RATING_STATUS.configurationValidated.id :
																		// return <SIRatingReadOnlyEvalutationPage id={ row.id } title={ RATING_STATUS.configurationValidated.name } />;
																		/*
																		return (
																			<Redirect
																				to={{
																					pathname: PATH_PREFIX +"/super-admin/configuration/evaluation/"+ row.id,
																					state: row
																				}}
																			/>
																		);
																		 */


																		showlog("click allo stato 20");
																		showlog("super admin: "+ isUserRole( role.SUPER_ADMIN ));


																		// test http://localhost:3000/super-admin/configuration/evaluation/33


																		if( !isUserRole( role.SUPER_ADMIN ) ) {



																			if( !!isUserRole( role.ARB_OPERATOR ) ) {
																				return (<span>Valutazione in attesa di validazione della profilazione da parte del cliente</span>);

																			}
																			else {
																				return (
																					<Redirect
																						to={{
																							pathname: PATH_PREFIX +"/si-rating/" + translate("evaluation") + "/"+ row.id,
																							state: row
																						}}
																					/>
																				);
																			}





																		}
																		break;


																	//	---- 25 -----------------------------------------------------------------------------------
																	case RATING_STATUS.perimetralQuestionAccepted.id :

																		if(
																			!!isUserRole( role.ADMIN ) ||
																			!!isUserRole( role.SR_ADMIN ) ||
																			!!isUserRole( role.SUPER_ADMIN ) ||
																			!!isUserRole( role.PROJECT_MANAGER ) ||
																			!!isUserRole( role.ARB_OPERATOR )
																		) {
																			return (
																				<Redirect
																					to={{
																						pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ row.id,
																						state: row
																					}}
																				/>
																			);
																		}
																		else {
																			//return <SIRatingReadOnlyEvalutationPage id={ row.id } title={ RATING_STATUS.configurationComplete.name }/>;


																			/* ***************************************************************************
																			Questa modifica va decommentata
																			se vogliamo che il PM atterri alla configurazione


																			if( !!isUserRole( role.PROJECT_MANAGER ) ) {
																				return (
																					<Redirect
																						to={{
																							pathname: PATH_PREFIX +"/si-rating/" + translate("evaluation") + "/"+ row.id,
																							state: row
																						}}
																					/>
																				);
																			}
																			else {


																				return (
																					<Redirect
																						to={{
																							pathname: PATH_PREFIX +"/super-admin/" + translate("configuration") + "/" + translate("evaluation") + "/"+ row.id,
																							state: row
																						}}
																					/>
																				);

																			}
																			****************************************************************************/

																			return (
																				<Redirect
																					to={{
																						pathname: PATH_PREFIX +"/super-admin/" + translate("configuration") + "/" + translate("evaluation") + "/"+ row.id,
																						state: row
																					}}
																				/>
																			);




																		}


																		break;


																	//	---- 30 ------------------------------------------------------------------------------------
																	case RATING_STATUS.ratingComplete.id :



																		showlog("controllo");
																		showlog( !!hasPermission( permissions.RATING_AUDIT ) );
																		showlog( !!isUserRole( role.SUPER_ADMIN ) );
																		// debugger;


																		if( !!hasPermission( permissions.RATING_AUDIT ) ) {
																			/*
																			return (
																				<SiRatingAdminSingleEvaluationPage
																					id={ row.id }
																					isFromAuditer={ true }
																				/>
																			);
																			 */


																			return (
																				<Redirect
																					to={{
																						pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ row.id,
																						state: Object.assign({}, row, {isFromAuditer: true})
																					}}
																				/>
																			);


																		}
																		else {

																			if( !!isUserRole( role.SUPER_ADMIN ) ) {
																				return (
																					<Redirect
																						to={{
																							pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ row.id,
																							state: row
																						}}
																					/>
																				);
																			}


																			return this.permissionDenied( permissions.RATING_AUDIT );
																		}


																	//	---- 35 ------------------------------------------------------------------------------------
																	case RATING_STATUS.auditComplete.id :
																		/*
																		return (
																			<SiRatingAdminSingleEvaluationPage
																				id={ row.id }
																				isFromAuditer={ true }
																			/>
																		);
																		 */
																		/*
																		if( !!hasPermission( permissions.RATING_AUDIT_CONFIRM ) ) {
																			return (
																				<SiRatingAdminSingleEvaluationPage
																					id={ row.id }
																					isFromAuditer={ true }
																				/>
																			);
																		}
																		else return this.permissionDenied( permissions.RATING_AUDIT_CONFIRM );
																		*/


																		return (
																			<Redirect
																				to={{
																					pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") +  "/"+ row.id,
																					state: Object.assign({}, row, {isFromAuditer: true})
																				}}
																			/>
																		);

																	//	---- 40 ------------------------------------------------------------------------------------
																	case RATING_STATUS.auditDone.id :
																		if( !!hasPermission( permissions.SHOW_RATING_AUDITED ) ) {
																			/*
																			return (
																				<SiRatingAdminSingleEvaluationPage
																					id={ row.id }
																					isFromAuditer={ true }
																				/>
																			);
																			*/

																			return (
																				<Redirect
																					to={{
																						pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ row.id,
																						state: Object.assign({}, row, {isFromAuditer: true})
																					}}
																				/>
																			);
																		}
																		else {

																			if( !!isUserRole( role.SUPER_ADMIN ) ) {
																				return (
																					<Redirect
																						to={{
																							pathname: PATH_PREFIX +"/sirating-admin/" + translate("evaluation") + "/"+ row.id,
																							state: row
																						}}
																					/>
																				);
																			}

																			return this.permissionDenied( permissions.RATING_AUDIT );
																		}


																	default :
																		return this.permissionDenied({ label: "Status inconsistente" });
																}



																// stati 0, 3, 5
																if( rowStatusId === RATING_STATUS.perimetralQuestionValidated.id && !isUserRole( role.SUPER_ADMIN ) ) {
																	return (
																		<Redirect
																			to={{
																				pathname: PATH_PREFIX +"/si-rating/"+ translate("evaluation") + "/"+ row.id,
																				state: row
																			}}
																		/>
																	);
																}

																if( !isUserRole( role.SUPER_ADMIN ) ) {

																	if( !!isUserRole( role.ARB_OPERATOR ) && rowStatusId === RATING_STATUS.configurationValidated.id ) {
																		// this.setState({ nounce: (new Date()).getTime() });
																	}
																	else {

																		return (
																			<Redirect
																				to={{
																					pathname: PATH_PREFIX +"/si-rating/" + translate("perimetral") + "/"+ row.id,
																					state: row
																				}}
																			/>
																		);
																	}



																}

																return null;


															},
															showExpandColumn: false,
															onlyOneExpanding: true,
															// expanded: [1, 3] // should be a row keys array
															// nonExpandable: [1, 3 ,5]
															// onExpand: (row, isExpand, rowIndex, e) => {}
															// onExpandAll: (isExpandAll, results, e) => {}
															// showExpandColumn: true
															// expandByColumnOnly: true

															className: (isExpanded, row, rowIndex) => {
																if (!!isExpanded) return 'inner-row';
																return '';
															},

															parentClassName: (isExpanded, row, rowIndex) => {
																if (!!isExpanded) return 'inner-row-active';
																return '';
															}
														}}

													/>

												)
												: (
													<WaitingOrNodataMessage
														waiting={ !(!!this.state.data && this.state.data.length === 0) }
														fullHeight={ true }
														nodataMessage={"Nessuna Valutazione"}
													/>
												)
										}


									</React.Fragment>
								)}
							</ToolkitProvider>

					</CardBody>
				</Card>


				{
					(!!this.state.newRatingModal)
						? (
							<AddRatingModal
								ratingList={ this.state.data }
								onClose={ errorMessage => {

									this.setState(
										{
											...this.state,
											newRatingModal: false,
											errorMessage: (!!errorMessage ? emoj( EMOJ.sad_but_relieved_face ) +"  "+ errorMessage : null)
										},
										() => {
											if( !errorMessage ) this.loadData();
											else {
												// c'è stato un errore

											}
										}
									);


								}}
							/>
						)
						: null
				}

				{
					(!!this.state.redirect)
						? (
							<Redirect
								to={{
									pathname: this.state.redirect,
									state: {
										ratingId: this.state.ratingId,
										pdfName: this.state.pdfName
									}
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={this.state.errorMessage}
								contentClassName={"big-font"} // choiche-status sirating-question-attachment-modal-icon comander-icon
								noheader={true}
								onClose={() => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}


			</React.Fragment>
		);
	}

	componentDidMount() {

		// errorlog("prova");
		// warninglog("Prova");

		// debugger;

		this.loadData();
	}


	loadData() {
		loadEvaluations( RATING_TYPE.sirating, !!this.state.isArchived )

			// logs   -----------------------------------------------------------------------------------------------
			.then( list => {
				// showlog("evaluations");
				// showlog( list );
				return list
			})




			// extras ---------------------------------------------------------------------------------------------------
			.then( list => {


				// showlog("lista");
				// showlog(list);

				list =
					list
						.map( (row, rowIndex) => {

							// row.sendMailNcQuestions = (
							// 	<React.Fragment>
							// 	<i
							// 	style={{textAlign:'center'}}
							// 		className={"mdi mdi-email-outline info-icon"}
							// 		onClick={ clickEvent => {
							// 			clickEvent.stopPropagation();
							// 			showlog("modale degli allegati");


							// 			this.setState({
							// 				...this.state,
							// 				sendMailNcQuestions: row.id
							// 			})

							// 		}}
							// 	/>

							// 	</React.Fragment>


							// );


							row.userRoles = (
								<React.Fragment>
								<i
								style={{display: 'inline-block', marginRight:"-60px"}}
									className={"mdi mdi-account-star info-icon"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
										showlog("modale degli allegati");


										this.setState({
											...this.state,
											userRolesSa: row.id
										})

									}}
								/>



								<i
								style={{display: 'inline-block', marginLeft:"70px"}}
									className={"mdi mdi-account-alert info-icon"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
										showlog("modale degli allegati");


										this.setState({
											...this.state,
											// sgdScoreModal: 15,
											userRolesArb: row.id
										})

									}}
								/>


								</React.Fragment>


							);


							row.files = (
								<i
									className={"mdi mdi-attachment sirating-question-attachment-icon info-icon"}
									onClick={ clickEvent => {
										clickEvent.stopPropagation();
										showlog("modale degli allegati");


										getRatingAttachments( row.id )
											.then( attachments => {
												showlog("allegati");
												showlog(attachments);

												// name, id
												// attachment_name, attachment_id
												this.setState({
													...this.state,
													ratingAttachments:
														attachments
															.map( att => ({attachment_name: att.name, attachment_id: att.attachment_id, isApproved: att.isApproved, approverUserName: att.approvedUserName, userCreate: att.userCreate })),
													attachmentModal: row.id
												})


											})
											.catch( e => {
												errorlog("errore al caricamento degli allegati della valutazione", e );
											})

									}}
								/>
							);

							row.versionComponent  = (
								<div style={{marginLeft: "45px"}}>
									{row.version}
								</div>
							)



							let rowStatusId =
								Object.keys( RATING_STATUS )
									.map( status => RATING_STATUS[ status ] )
									// .filter( ratingStatus => ratingStatus.name === (row.Status || row.status) )
									.filter( ratingStatus => ratingStatus.id === (row.Status || row.status) )
									.map( ratingStatus => ratingStatus.id )
									.reduce( getLastElementOr, 100 )
							;



							row.mainSectorComander = (

								<React.Fragment>

									{
										(rowStatusId >= 5) ?
										(

											<i
												className="mdi mdi-eye"
												style={{marginLeft: "70px", fontSize: "1.5rem", color: "var(--primary)"}}
												onClick={clickEvent => {
													this.setState({
														...this.state,
														associatedSectorQuestionModal: row
													})
												}}
											/>

										)
										:
											null

									}

								</React.Fragment>
							);

							row.associatedSector = (

								<React.Fragment>

									{
										(rowStatusId >= 5) ?
											(

												<i
													className="mdi mdi-eye"
													style={{marginLeft: "12px", fontSize: "1.5rem", color: "var(--primary)"}}
													onClick={clickEvent => {
														this.setState({
															...this.state,
															associatedSector: row
														})
													}}
												/>

											)
											:
											null

									}

								</React.Fragment>

							);


							// colonna Provvisoria
							row.scoreButton = (
								<React.Fragment>

									{

										(
											rowStatusId === RATING_STATUS.auditDone.id || ( !!isUserRole( role.PROJECT_MANAGER ) || !!isUserRole( role.ARB_OPERATOR ))
										)
											? (
												<React.Fragment>

													{
														!!hasPermission( permissions.READ_SCORE_MODAL )


															? (
																<React.Fragment>
																	<img
																		id={`rating_${ row.id }`}
																	    src={HOST + "/assets/icons_evaluations/rating.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				scoreModal: 15,
																				evaluation_id: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={`rating_${ row.id }`}>
																		<span className="">{ translate('ESG_Performance') }</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null


													}

													{
														!!hasPermission( permissions.READ_SGD_SCORE_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'sdgs_'+ row.id}
																	   src={HOST + "/assets/icons_evaluations/sdgs.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				sgdScoreModal: row.id,
																				evaluation_id: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'sdgs_'+ row.id}>
																		<span className="">SDG</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}


{
														!!hasPermission( permissions.READ_SGD_SCORE_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'resilienza_'+ row.id}
																	   src={HOST + "/assets/icons_evaluations/resilienza.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				// sgdScoreModal: 15,
																				criteriaModal: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'resilienza_'+ row.id}>
																		<span className="">{ translate('Score') }</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}




													{
														!!hasPermission( permissions.READ_SGD_SCORE_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'support_'+ row.id}
																	   src={HOST + "/assets/icons_evaluations/support.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();
																			this.setState({
																				...this.state,
																				// sgdScoreModal: 15,
																				stateModal: row.id
																			})
																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'support_'+ row.id}>
																		<span className="">{translate("Operator Actions History")}</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}




													{
														!!hasPermission( permissions.ACCESS_PDF_PARAMETER_MODAL )
															? (
																<React.Fragment>
																	<img
																		id={'pdf_'+ row.id}
																	    src={HOST + "/assets/icons_evaluations/pdf.svg"}
																		alt={"SDG"}
																		className={"sdg-icon-file2-modified"}
																		onClick={clickEvent => {
																			clickEvent.stopPropagation();


																			// if( isUserRole( role.PROJECT_MANAGER ) || isUserRole( role.ARB_OPERATOR ) ) {
																			if( !!hasPermission( permissions.ACCESS_PDF_PARAMETER_MODAL ) ) {
																				this.setState({
																					...this.state,
																					redirect: PATH_PREFIX +"/super-admin/si-rating/" + translate("evaluations") + "/" + translate("report-parameters") + "/" + row.id,
																					ratingId: row.id,
																					pdfName: row.name.trim() +".pdf"
																				})
																			}
																			else {
																				downloadSIRatingRatingFile( row.id, row.name.trim() +".pdf" )
																					.then( result => {

																					})
																					.catch(e => {
																						errorlog("errore",e);

																					})
																			}


																		}}
																	/>
																	<UncontrolledTooltip placement="top" target={'pdf_'+ row.id}>
																		<span className="">PDF</span>
																	</UncontrolledTooltip>
																</React.Fragment>
															)
															: null
													}



												</React.Fragment>
											)
											: null
									}







								</React.Fragment>

							);

							return row;
						})

				return list;
			})




			// field sort   -------------------------------------------------------------------------------------------
			.then( responseData => {
				return (
					responseData
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})


			// Dimensions label ----------------------------------------------------------------------------
			.then( list => {
				list
					.map( r => {

						switch ( r.dimensionId ) {
							case 0:
								r.dimensionName = r.dimensionName;
								break;
							case 1:
								r.dimensionName = "Piccola/Media";
								break;
							case 2:
								r.dimensionName = "Grande";
								break;
						}

						return r;

					})
				return list;

			})



			// field map ----------------------------------------------------------------------------------------------
			.then( list => {


				return (
					list
						.map( company => {
							let dto = {};
							Object.keys( company )
								.forEach( rawField => {
									dto[ fieldMap[ rawField ] ] = company[ rawField ];
								})
							;
							return dto;
						})
				);

			})






			// filtri -------------------------------------------------------------------------------------------------
			.then( list => {

				// debugger;
				return (
					list
						// .filter( rat => rat.status === this.state.activeStatusFilter )
				);

			})

			// finish --------------------------------------------------------------------------------------------------
			.then( data => {
				// showlog("data after field sort");
				// showlog( data );

				// debugger;

				this.setState({
					...this.state,
					data: data,
					allData: data
				})
			})

			.catch(e => {
				errorlog("errore",e);
				this.setState({
					...this.state,
					data: []
				})
			})

		loadAllSectors()
			.then(list => {
				showlog("Sectorlist", list)
				return list
			})
			.then(list => {

				list =
				list.map( row => {
					return ( {"sector_id": row.id, "sector_name": row.name.trim()} )
				})

				return list
			})
			.then( list => {
				showlog("Sectorlist2", list)
				this.setState({
					...this.state,
					allSectors: list
				})
			})
			.catch(e => {
				errorlog("errore lista settori",e);
				this.setState({
					...this.state,
					allSectors: []
				})
			})

	}






}
