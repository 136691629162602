import React, {Component} from "react";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {emoj, errorlog, getLastElementOr, isNotEmptyArray, onlyNotNull, renderlog, showlog} from "../../../utils";
import {loadSuperAdminRatingDashboardData} from "../../../business-logic/active-service";
import {EMOJ} from "../../../emoj";
import PageTitle from "../../../components/PageTitle";
import {PATH_PREFIX} from "../../../conf/siroad";
import {Card, Col, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap";
import CardBody from "reactstrap/es/CardBody";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import "../../../style/index.css";
import classnames from "classnames";
import {translate} from "../../../components/i18n/translate-function";

const fieldMap = {
    partitaIva: "Partita Iva",
};


const hiddenFields = [
]
    .filter(onlyNotNull);

const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Aziende
        </span>
    ),
    sizePerPageList: [
        {
            text: 'pochi',
            value: 25,
        },
        {
            text: 'normale',
            value: 50,
        },
        {
            text: 'molti',
            value: 250,
        }
    ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
    {
        dataField: 'Id',
        order: 'asc',
    },
];


const sortableFields = [
    "Ragione Sociale",
    "Città",
    "Provincia",
    "Settore",
    "Dimensione",
    "Tipologia Commerciale",
    "Classe Contabile",
    "Categoria",
    "leadingCompany"
];

const formatColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldMap[field] || field,
                    sort: sortableFields.includes(field)
                });
            })
            .filter(ff => !hiddenFields.includes(ff.dataField))
    );
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SuperAdminRatingDashboard extends Component {

    state = {
        noDataMessage: translate("No Evaluation Completed"),
        activeTab: "ALL"
    }

    constructor(props) {
        super(props);
    }

    render() {

        renderlog("SuperAdminRatingDashboard", this.state, this.props);


        let content = (






            <React.Fragment>

                <PageTitle
                    breadCrumbItems={[
                        {
                            label: "SI Rating" /*
                                Object.keys(role)
                                    .map(roleObjectName => role[roleObjectName])
                                    .filter(roleObject => roleObject.name === localStorage.getItem("role"))
                                    .reduce(getLastElementOr, "Nessun Ruolo")
                                    .label
                                    */
                                   ,path: PATH_PREFIX + "/welcome"
                        },
                        { label: translate("Rating Dashboard"), active: true }
                    ]}
                    title={translate("Rating Dashboard")}
                />











                <Card>
                    <CardBody>



                        {
                            (!!this.state.data && this.state.data.length > 0)
                                ? (
                                    <React.Fragment>
                                        <Nav tabs>

                                        <NavItem key={""}>
                                                <NavLink
                                                    id={"ALL"}
                                                    // href={"#ALL"}
                                                    className={ classnames({active: this.state.activeTab === "ALL"}) + ((this.state.activeTab === "") ? " active-tab" : "")}
                                                    onClick={() => {
                                                        this.setState({ activeTab: "ALL" });
                                                    }}
                                                >
                                                    <span className="d-none d-lg-block">{"TUTTE"}</span>
                                                </NavLink>
                                            </NavItem>


                                            {

                                                this.state.data
                                                    .map(company => (
                                                        <NavItem key={""}>
                                                            <NavLink
                                                                id={company.company.id}
                                                                // href={"#" + company.company.id}
                                                                className={ classnames({active: this.state.activeTab === company.company.id}) + ((this.state.activeTab === "") ? " active-tab" : "")}
                                                                onClick={() => {
                                                                    this.setState({ activeTab: company.company.id });
                                                                }}
                                                            >
                                                                <span className="d-none d-lg-block">{company.company.name}</span>
                                                            </NavLink>
                                                        </NavItem>

                                                    ))

                                            }




                                        </Nav>


                                        <TabContent activeTab={""+ this.state.activeTab}>
                                        <TabPane
                                                tabId={"ALL"}
                                                key={"ALL"}
                                                // className={"custom-tab-pane"}
                                            >
                                                <React.Fragment>

                                                    <Row align="center">
                                                        <Col md={12}>



                                                            <Card style={{ overflowX: 'auto' }}>
                                                            <h3 style={{textAlign:'left',marginTop:'20px',marginLeft:'25px',marginBottom:'-10px'}}>Punteggi Generali</h3>
                                                                <CardBody>



                                                                    {
                                                                        (!!this.state.data && this.state.data.length > 0)
                                                                            ? (






                                                                                <React.Fragment>
                                                                                     <Table className="RatingTable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{textAlign:'left'}}>Azienda</th>
                                                                                                <th>Rating</th>
                                                                                                <th>Enviromental</th>
                                                                                                <th>Governance</th>
                                                                                                <th>Social</th>
                                                                                                <th>Materiality Enviromental</th>
                                                                                                <th>Materiality Governance</th>
                                                                                                <th>Materiality Social</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            {
                                                                                                this.state.data
                                                                                                    .map(company => (

                                                                                                        (
                                                                                                            <tr>
                                                                                                                <td style={{textAlign:'left'}}>{company.company.name}</td>
                                                                                                                <td>{company.srReportsRatingCriteria.rating} %</td>
                                                                                                                <td>{company.srReportsRatingCriteria.environmental} %</td>
                                                                                                                <td>{company.srReportsRatingCriteria.governance} %</td>
                                                                                                                <td>{company.srReportsRatingCriteria.social} % </td>
                                                                                                                <td>{company.srReportsRatingCriteria.environmentalMateriality}</td>
                                                                                                                <td>{company.srReportsRatingCriteria.governanceMateriality}</td>
                                                                                                                <td> {company.srReportsRatingCriteria.socialMateriality}</td>
                                                                                                            </tr>


                                                                                                        )

                                                                                                    )
                                                                                                    )

                                                                                            }

                                                                                        </tbody>
                                                                                     </Table>

                                                                                </React.Fragment>
                                                                            )
                                                                            : (
                                                                                <WaitingOrNodataMessage
                                                                                    waiting={!(!!this.state.data && this.state.data.length === 0)}
                                                                                    waitMessage={"Caricamento..."}
                                                                                    nodataMessage={this.state.noDataMessage}
                                                                                    className={""}
                                                                                    fullHeight={true}
                                                                                    spinnerColor={"primary"}
                                                                                />
                                                                            )
                                                                    }

                                                                </CardBody>
                                                            </Card>

                                                            <Card style={{ overflowX: 'auto' }}>
                                                                <h3 style={{textAlign:'left',marginTop:'20px',marginLeft:'25px',marginBottom:'-10px'}}>Criteri</h3>
                                                                <CardBody>



                                                                    {
                                                                        (!!this.state.data && this.state.data.length > 0)
                                                                            ? (






                                                                                <React.Fragment>
                                                                                    <Table className="RatingTable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{textAlign:'left'}}>Azienda</th>
                                                                                                <th>Resilience</th>
                                                                                                <th>Transparency</th>
                                                                                                <th>Improvement</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            {
                                                                                                this.state.data
                                                                                                    .map(company => (

                                                                                                        (
                                                                                                            <tr>
                                                                                                                <td style={{textAlign:'left'}} >{company.company.name}</td>
                                                                                                                <td>{company.srReportsRatingCriteria.resilience} %</td>
                                                                                                                <td>{company.srReportsRatingCriteria.transparency} %</td>
                                                                                                                <td>{company.srReportsRatingCriteria.improvement} %</td>
                                                                                                            </tr>


                                                                                                        )

                                                                                                    )
                                                                                                    )

                                                                                            }

                                                                                        </tbody>
                                                                                    </Table>

                                                                                </React.Fragment>
                                                                            )
                                                                            : (
                                                                                <WaitingOrNodataMessage
                                                                                    waiting={!(!!this.state.data && this.state.data.length === 0)}
                                                                                    waitMessage={"Caricamento..."}
                                                                                    nodataMessage={this.state.noDataMessage}
                                                                                    className={""}
                                                                                    fullHeight={true}
                                                                                    spinnerColor={"primary"}
                                                                                />
                                                                            )
                                                                    }

                                                                </CardBody>
                                                            </Card>

                                                            <Card style={{ overflowX: 'auto' }}>

                                                            <h3 style={{textAlign:'left',marginTop:'20px',marginLeft:'25px',marginBottom:'-10px'}}>Punteggi Specifici - Area Enviromental</h3>
                                                                <CardBody>

                                                                    {
                                                                        !!isNotEmptyArray( this.state.macroAreaTables )
                                                                            ? (
                                                                                <ToolkitProvider
                                                                                    bootstrap4
                                                                                    keyField="id"
                                                                                >
                                                                                    {
                                                                                        props => (

                                                                                            <BootstrapTable
                                                                                                bootstrap4
                                                                                                striped
                                                                                                {...props.baseProps}
                                                                                                size="sm"
                                                                                                responsive
                                                                                                keyField="id"
                                                                                                data={ this.state.macroAreaTables[ 0 ].table }
                                                                                                columns={ formatColumns( Object.keys( this.state.macroAreaTables[ 0 ].table.reduce( getLastElementOr, null ) ) ) }
                                                                                                // columns={ this.state.columns }
                                                                                                defaultSorted={ defaultSorted }
                                                                                                // pagination={ paginationFactory( paginationOptions ) }
                                                                                                wrapperClasses="table-responsive long-names-headers-table"
                                                                                            />

                                                                                        )
                                                                                    }

                                                                                </ToolkitProvider>

                                                                            )
                                                                            : (
                                                                                <WaitingOrNodataMessage
                                                                                    waiting={!(!!this.state.macroAreaTables && this.state.macroAreaTables.length === 0)}
                                                                                    waitMessage={"Caricamento..."}
                                                                                    nodataMessage={this.state.noDataMessage}
                                                                                    className={""}
                                                                                    fullHeight={true}
                                                                                    spinnerColor={"primary"}
                                                                                />
                                                                            )
                                                                    }

                                                                </CardBody>
                                                            </Card>

                                                            <Card style={{ overflowX: 'auto' }}>
                                                            <h3 style={{textAlign:'left',marginTop:'20px',marginLeft:'25px',marginBottom:'-10px'}}>Punteggi Specifici - Area Social</h3>
                                                                <CardBody>

                                                                    {
                                                                        !!isNotEmptyArray( this.state.macroAreaTables )
                                                                            ? (
                                                                                <ToolkitProvider
                                                                                    bootstrap4
                                                                                    keyField="id"
                                                                                >
                                                                                    {
                                                                                        props => (

                                                                                            <BootstrapTable
                                                                                                bootstrap4
                                                                                                striped
                                                                                                {...props.baseProps}
                                                                                                size="sm"
                                                                                                responsive
                                                                                                keyField="id"
                                                                                                data={ this.state.macroAreaTables[ 1 ].table }
                                                                                                columns={ formatColumns( Object.keys( this.state.macroAreaTables[ 1 ].table.reduce( getLastElementOr, null ) ) ) }
                                                                                                // columns={ this.state.columns }
                                                                                                defaultSorted={ defaultSorted }
                                                                                                // pagination={ paginationFactory( paginationOptions ) }
                                                                                                wrapperClasses="table-responsive long-names-headers-table"
                                                                                            />

                                                                                        )
                                                                                    }

                                                                                </ToolkitProvider>

                                                                            )
                                                                            : (
                                                                                <WaitingOrNodataMessage
                                                                                    waiting={!(!!this.state.macroAreaTables && this.state.macroAreaTables.length === 0)}
                                                                                    waitMessage={"Caricamento..."}
                                                                                    nodataMessage={this.state.noDataMessage}
                                                                                    className={""}
                                                                                    fullHeight={true}
                                                                                    spinnerColor={"primary"}
                                                                                />
                                                                            )
                                                                    }

                                                                </CardBody>
                                                            </Card>

                                                            <Card style={{ overflowX: 'auto' }}>
                                                            <h3 style={{textAlign:'left',marginTop:'20px',marginLeft:'25px',marginBottom:'-10px'}}>Punteggi Specifici - Area Governance</h3>
                                                                <CardBody>

                                                                    {
                                                                        !!isNotEmptyArray( this.state.macroAreaTables )
                                                                            ? (
                                                                                <ToolkitProvider
                                                                                    bootstrap4
                                                                                    keyField="id"
                                                                                >
                                                                                    {
                                                                                        props => (

                                                                                            <BootstrapTable
                                                                                                bootstrap4
                                                                                                striped
                                                                                                {...props.baseProps}
                                                                                                size="sm"
                                                                                                responsive
                                                                                                keyField="id"
                                                                                                data={ this.state.macroAreaTables[ 2 ].table }
                                                                                                columns={ formatColumns( Object.keys( this.state.macroAreaTables[ 2 ].table.reduce( getLastElementOr, null ) ) ) }
                                                                                                // columns={ this.state.columns }
                                                                                                defaultSorted={ defaultSorted }
                                                                                                // pagination={ paginationFactory( paginationOptions ) }
                                                                                                wrapperClasses="table-responsive long-names-headers-table"
                                                                                            />

                                                                                        )
                                                                                    }

                                                                                </ToolkitProvider>

                                                                            )
                                                                            : (
                                                                                <WaitingOrNodataMessage
                                                                                    waiting={!(!!this.state.macroAreaTables && this.state.macroAreaTables.length === 0)}
                                                                                    waitMessage={"Caricamento..."}
                                                                                    nodataMessage={this.state.noDataMessage}
                                                                                    className={""}
                                                                                    fullHeight={true}
                                                                                    spinnerColor={"primary"}
                                                                                />
                                                                            )
                                                                    }

                                                                </CardBody>
                                                            </Card>

                                                            <Card style={{ overflowX: 'auto' }}>
                                                            <h3 style={{textAlign:'left',marginTop:'20px',marginLeft:'25px',marginBottom:'-10px'}}>Punteggi SGDs </h3>
                                                                <CardBody>



                                                                    {
                                                                        (!!this.state.data && this.state.data.length > 0)
                                                                            ? (






                                                                                <React.Fragment>

                                                                                     <Table className="RatingTable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{textAlign:'left'}}>Azienda</th>
                                                                                                {
                                                                                                    this.state.data[0].srReportsSdg
                                                                                                        .map(company => (

                                                                                                            <th style={{ fontSize: '10px' }}>{company.name}</th>
                                                                                                        ))

                                                                                                }


                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            {
                                                                                                this.state.data
                                                                                                    .map(company => (

                                                                                                        (
                                                                                                            <tr>
                                                                                                                <td style={{textAlign:'left'}}>{company.company.name}</td>
                                                                                                                {
                                                                                                                    company.srReportsSdg.map(x => (

                                                                                                                        <td>{x.performance} %</td>
                                                                                                                    ))

                                                                                                                }
                                                                                                            </tr>


                                                                                                        )

                                                                                                    )
                                                                                                    )

                                                                                            }

                                                                                        </tbody>
                                                                                    </Table>




                                                                                </React.Fragment>
                                                                            )
                                                                            : (
                                                                                <WaitingOrNodataMessage
                                                                                    waiting={!(!!this.state.data && this.state.data.length === 0)}
                                                                                    waitMessage={"Caricamento..."}
                                                                                    nodataMessage={this.state.noDataMessage}
                                                                                    className={""}
                                                                                    fullHeight={true}
                                                                                    spinnerColor={"primary"}
                                                                                />
                                                                            )
                                                                    }

                                                                </CardBody>
                                                            </Card>





                                                        </Col>

                                                    </Row>
                                                </React.Fragment>

                                                {/*<GotoTopComponent/>*/}

                                            </TabPane>

                                            {
                                                this.state.data
                                                    .map(company => (
                                                        <TabPane
                                                            tabId={""+ company.company.id}
                                                            key={company.company.id}
                                                            className={"custom-tab-pane"}
                                                        >
                                                            <React.Fragment>

                                                                <Row align="center">
                                                                    <Col md={4}>

                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Valutazione</b>: {company.evaluationName}
                                                                    </Col>
                                                                    <Col md={4}>

                                                                    </Col>

                                                                </Row>
                                                                <br />
                                                                <Row align="center">
                                                                    <Col md={4}>

                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Rating</b>: {company.srReportsRatingCriteria.rating} %<br />
                                                                    </Col>
                                                                    <Col md={4}>

                                                                    </Col>

                                                                </Row>
                                                                <br />
                                                                <Row align="center">
                                                                    <Col md={4}>
                                                                        <b>Environmental Rating</b>: {company.srReportsRatingCriteria.environmental} %<br />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Governance Rating</b>: {company.srReportsRatingCriteria.governance} %<br />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Social Rating</b>: {company.srReportsRatingCriteria.social} %<br />
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                                <Row align="center">
                                                                    <Col md={4}>
                                                                        <b>Resilience</b>: {company.srReportsRatingCriteria.resilience} %<br />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Transparency</b>: {company.srReportsRatingCriteria.transparency} %<br />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Improvement:</b>: {company.srReportsRatingCriteria.improvement} %<br />
                                                                    </Col>

                                                                </Row>
                                                                <br />
                                                                <Row align="center">
                                                                    <Col md={4}>
                                                                        <b>Environmental Materiality</b>: {company.srReportsRatingCriteria.environmentalMateriality}<br />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Governance Materiality</b>: {company.srReportsRatingCriteria.governanceMateriality}<br />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <b>Social Materiality</b>: {company.srReportsRatingCriteria.socialMateriality}<br />
                                                                    </Col>
                                                                </Row>
                                                                <br />



                                                            </React.Fragment>

                                                            <Row>

                                                                <br /><br />

                                                                <ToolkitProvider
                                                                    bootstrap4
                                                                    keyField="Id"
                                                                    // search
                                                                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                                                                    {
                                                                        props => (
                                                                            <React.Fragment>
                                                                                {/* <Row>
                                                                                    <Col>
                                                                                        <SearchBar {...props.searchProps} />
                                                                                    </Col>
                                                                                </Row> */}


                                                                                <BootstrapTable
                                                                                    bootstrap4
                                                                                    striped
                                                                                    {...props.baseProps}
                                                                                    size="sm"
                                                                                    responsive
                                                                                    keyField="Id"
                                                                                    data={

                                                                                        company.srReportsPerformance.map(x => (
                                                                                            {
                                                                                                //Id:x.id,
                                                                                                Tematica: x.srThematicName,
                                                                                                Sottotematica: x.srSubthematicname,
                                                                                                Materialità: x.materiality,
                                                                                                Performance: x.performance + " %",

                                                                                            }))

                                                                                    }
                                                                                    columns={formatColumns(['Tematica', 'Sottotematica', 'Materialità', 'Performance'])}
                                                                                    defaultSorted={defaultSorted}
                                                                                    wrapperClasses="table-responsive"
                                                                                />


                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                </ToolkitProvider>
                                                            </Row>

                                                            <br /><br />

                                                            <Row>
                                                                <ToolkitProvider
                                                                    bootstrap4
                                                                    keyField="Id"
                                                                    // search
                                                                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                                                                    {
                                                                        props => (
                                                                            <React.Fragment>
                                                                                {/* <Row>
                                                                                    <Col>
                                                                                        <SearchBar {...props.searchProps} />
                                                                                    </Col>
                                                                                </Row> */}



                                                                                <BootstrapTable
                                                                                    bootstrap4
                                                                                    striped
                                                                                    {...props.baseProps}
                                                                                    size="sm"
                                                                                    responsive
                                                                                    keyField="Id"
                                                                                    data={

                                                                                        company.srReportsSdg.map(x => (
                                                                                            {
                                                                                                SDG: x.name,
                                                                                                Performance: x.performance + " %",
                                                                                            }))

                                                                                    }
                                                                                    columns={formatColumns(['SDG', 'Performance'])}
                                                                                    defaultSorted={defaultSorted}
                                                                                    wrapperClasses="table-responsive"
                                                                                />

                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                </ToolkitProvider>
                                                            </Row>
                                                        </TabPane>
                                                    ))
                                            }

                                            {/*<GotoTopComponent/>*/}

                                        </TabContent>



                                    </React.Fragment>
                                )
                                : (
                                    <WaitingOrNodataMessage
                                        waiting={!(!!this.state.data && this.state.data.length === 0)}
                                        waitMessage={"Caricamento..."}
                                        nodataMessage={this.state.noDataMessage}
                                        className={""}
                                        fullHeight={true}
                                        spinnerColor={"primary"}
                                    />
                                )
                        }

                    </CardBody>
                </Card>




            </React.Fragment>
        );




        ;


        return content;
    }



    componentDidMount() {
        loadSuperAdminRatingDashboardData()


            // // dati falsi -------------------------------------------------------------------------
            // .then( result => {
            //     debugger
            //     return result;
            //     // return ([
            //     //     {
            //     //         "company": {
            //     //             "id": 20,
            //     //             "name": "Veronafiere S.p.A."
            //     //         },
            //     //         "srReportsRatingCriteria": {
            //     //             "id": 6,
            //     //             "srEvaluationId": 7,
            //     //             "rating": 57,
            //     //             "environmental": 50,
            //     //             "governance": 57,
            //     //             "social": 68,
            //     //             "resilience": 60,
            //     //             "transparency": 42,
            //     //             "improvement": 61,
            //     //             "srSectorId": 62,
            //     //             "environmentalMateriality": 2.04,
            //     //             "governanceMateriality": 1.5,
            //     //             "socialMateriality": 1.39,
            //     //             "srEvaluation": null
            //     //         },
            //     //         "srReportsSdg": [
            //     //             {
            //     //                 "id": 103,
            //     //                 "srEvaluationId": 7,
            //     //                 "srSdgId": 15,
            //     //                 "performance": 38,
            //     //                 "srEvaluation": null,
            //     //                 "srSdg": null
            //     //             }
            //     //         ],
            //     //         "srReportsPerformance": [
            //     //             {
            //     //                 "id": 78,
            //     //                 "srEvaluationId": 7,
            //     //                 "srThematicId": 1,
            //     //                 "srSubthematic": 1,
            //     //                 "materiality": 0.75,
            //     //                 "performance": 24,
            //     //                 "srEvaluation": null,
            //     //                 "srSubthematicNavigation": null,
            //     //                 "srThematic": null
            //     //             }
            //     //         ]
            //     //     }
            //     // ]);
            // })// ----------------------------------------------------------------------------------



            .then(result => {


                showlog("%cdashoboard result", "color:#ff0;");
                showlog(result);

                let macroAreas = [
                    ... new Map(
                        result.performanceTablelist
                            .map( item => ([ item.macroareaId, {id: item.macroareaId, list: []} ]) )
                    ).values()
                ];

                // per ogni macroarea
                let tables =
                    macroAreas
                        .map( area => {

                            let rawMacroareaData = result.performanceTablelist.filter( item => item.macroareaId === area.id );
                            rawMacroareaData
                                .map( raw => {

                                    area.list = [
                                        ...area.list,
                                        ...raw.value
                                            .map( (adv, index) => {

                                                let obj = {};
                                                obj[raw.thematic] = adv;
                                                obj.company = result.companyList[index]

                                                return obj;
                                            })
                                    ];


                                    // raggruppamento per company
                                    area.table = [];
                                    area.list
                                        .map( col => {

                                            // se area.table non ha la company
                                            if( !area.table.map( at => at.company ).includes( col.company ) ) {
                                                area.table.push( col );
                                            }
                                            else {
                                                // area.table ha la company

                                                // area.table corrispondente
                                                area.table
                                                    .map( at => {
                                                        if( at.company === col.company ) {
                                                            at = Object.assign( at, col );
                                                        }
                                                        return at;
                                                    })
                                                ;

                                            }

                                        })

                                })

                            return area;
                        })
                ;

                showlog("tables");
                showlog(tables);

                return ({ data: result.reportSaDasboardClass, macroAreaTables: tables })
            })



            .then(({data, macroAreaTables}) => {

                let tables =
                    macroAreaTables
                        .map( mat => {
                            mat.table =
                                mat.table
                                    .map( t => ({
                                        Azienda: t.company,
                                        ...t
                                    }))
                                    .map( t => {
                                        delete t.company;
                                        Object.keys( t )
                                            .map( field => {
                                                if( !!t[ field ] && (t[ field ].trim() !== "") && (field !== "Azienda") ) t[ field ] += " %";

                                                // adjust field name
                                                t[ field.split("###").join( "\n" ) ] = t[ field ];
                                                if(field !== "Azienda") delete t[ field ];
                                            })
                                        ;
                                        return t;
                                    })
                            ;
                            return mat;
                        })
                ;

                return ({ data: data, macroAreaTables: tables })
            })


            .then(({data, macroAreaTables}) => {

                delete macroAreaTables.list;

                this.setState({
                    data: (!!data ? data : []),
                    macroAreaTables: macroAreaTables
                });

            })
            .catch(e => {
                errorlog("Caricamento dati SuperAdmin Rating Dashboard", e);

                this.setState({
                    data: [],
                    noDataMessage: " " + emoj(EMOJ.sad_but_relieved_face) + "  Qualcosa è andato storto!"
                });

            })
            ;
    }

}



/*

result
TAB per ogni company <----------------------------------------------------------

{
    "0": {
        "company": {
            "id": 20,
            "name": "Veronafiere S.p.A."
        },
        "srReportsRatingCriteria": {
            "id": 6,
            "srEvaluationId": 7,
            "rating": 57,
            "environmental": 50,
            "governance": 57,
            "social": 68,
            "resilience": 60,
            "transparency": 42,
            "improvement": 61,
            "srSectorId": 62,
            "environmentalMateriality": 2.04,
            "governanceMateriality": 1.5,
            "socialMateriality": 1.39,
            "srEvaluation": null
        },
        "srReportsSdg": [
            {
                "id": 103,
                "srEvaluationId": 7,
                "srSdgId": 15,
                "performance": 38,
                "srEvaluation": null,
                "srSdg": null
            },
            ...
        ],
        "srReportsPerformance": [
            {
                "id": 78,
                "srEvaluationId": 7,
                "srThematicId": 1,
                "srSubthematic": 1,
                "materiality": 0.75,
                "performance": 24,
                "srEvaluation": null,
                "srSubthematicNavigation": null,
                "srThematic": null
            },
            ...
        ]
    }
}
 */
