import React, {useState} from "react";
import {getLastElementOr, renderlog, showlog} from "../../../../utils";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import ActionButton from "../../../../components/save-button";
import {EMAIL_REGEX} from "../../../../conf/siroad";
import {requestResetPassword} from "../../../../services/auth-service";
import {HOST, LANGUAGES, PRODUCTION_HOST} from "../../../../env";
import ARB_SPA_W from "../../../../assets/images/ARB_S.B.P.A_white.svg";
import {translate} from "../../../../components/i18n/translate-function";


// ATTENZIONE ridondanza
const ARBLogoComponent = props => (
    <a
        className={"arblogo-component"}
        href={"https://arbspa.it"}
        target={"_blank"}
    >
        <span className={ !!props.whiteLogo ? "white-text" : "" }>powered by</span>
        <img
            src={ !!props.whiteLogo ? ARB_SPA_W : PRODUCTION_HOST + "/assets/Loghi/ARB.svg" }
            id={"arbsbpa-logo"}
            alt={"ARB SPA"}
        />
    </a>
);



export const ResetPasswordPanelContent = props => {
    const [email, setEmail] = useState();

    renderlog(
        "Forgot Password",
        {state: email},
        props,
        {
            color: "#00ffff",
            contrastColor: "#222222",
            negativeColor: "#f53068"
        }
    );
    return (
        <div className={"signup-panel-profile-container"}>
            <div className={"signup-panel-profile-element"} id={"signup-panel-profile-title"}>Reimposta la Password</div>
            <span className={"signup-panel-profile-element"} id={"signup-panel-profile-subtitle"}>
                Si prega si inserire l'indirizzo email associato all'accounto SI Road.<br/>
                Riceverà un'email con un link per reimpostare la password.
            </span>
            <div
                className={"signup-panel-profile-element"}
                id={"signup-panel-profile-form"}
            >
                <AvForm
                    className={"signup-panel-profile-form-container"}
                    onValidSubmit={(event, values) => {
                        showlog("onValidSubmit");
                        showlog(event);
                        showlog(values);
                    }}
                >
                    {/* -------------------------------------------------------------------------- */}
                    <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                        <AvInput
                            type={"email"}
                            name={"email"}
                            id={"signupProfile_email"}
                            className={"signupInput"}
                            label={""}
                            value={email}
                            defaultValue={email}
                            placeholder={"Email"}
                            onChange={changeEvent => {
                                setEmail( changeEvent.target.value );
                            }}
                            disabled={false}
                            required
                        />
                        <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                    </AvGroup>

                    <div className={"signup-panel-company-button-container"}>
                        <ActionButton
                            color={"#d00"}
                            className={"signup-panel-company-button-disabled"}
                            buttonText={"ANNULLA"}
                            promise={ () => {
                                return Promise.resolve();
                            }}
                            onResult={ result => {
                                props.previous()
                            }}
                        />
                        <ActionButton
                            visible={ !!email && !!(email.match( EMAIL_REGEX )) }
                            buttonText={"INVIA"}
                            className={"signup-panel-profile-button "}
                            promise={ () => requestResetPassword( email ) }
                            onResult={ result => {
                                alert("Ti è stata inviata una email all'indirizzo "+ email );
                                setTimeout( function() { props.next() }, 5000)
                            }}
                            onError={ error => {
                                alert("C'è stato un problema nell'invio della email");
                                setTimeout( function() { props.next() }, 5000)
                            }}
                        />
                    </div>

                </AvForm>
            </div>
            <div className={"signup-panel-profile-element"} id={"signup-panel-profile-alternative"}>
                <span>
                    Non hai ancora un account?&nbsp;
                    <span
                        // className={"signup-link"}
                        className={"disabled-link"}
                        onClick={clickEvent => {
                            // props.onSignup();
                        }}
                    >
                        Iscriviti qui
                    </span>
                </span>
            </div>
            <div className={"signup-panel-profile-element"} id={"signup-panel-profile-footer"}>
                <img src={HOST + "/assets/Loghi/ARB.svg"} alt={"ARB SPA"} onClick={ clickEvent => { window.location.href = "https://arbspa.it"; }}/>
            </div>
        </div>
    );
};



export const ForgotPasswordPanel = props => {

    const [email, setEmail] = useState();
    const [language, setLanguage] = useState(
        LANGUAGES
            .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )
            .reduce( getLastElementOr, LANGUAGES[0] )
    );

    return (
        <React.Fragment>

            <div className={"panel desktop"}>
                <div className={"signup-panel-profile-container"}>

                    {/* tolto
                    <LanguageSelector
                        onChange={ language => {
                            setLanguage( language );
                        }}
                        className={"language-selector-in-panel"}
                    />
                    */}

                    <div className={"signup-panel-profile-logo-container desktop"}>
                        <img
                            // src={SiRoadLogoWhiteText}
                            src={ PRODUCTION_HOST +"/assets/Loghi/SiRoad_home.png" }
                            alt={"SI Road"}
                            id={"siroad-logo-small-screen"}
                            width={"180px"}
                        />
                        <div className={"signup-panel-profile-element signup-panel-profile-title"}>{ translate("welcome_again_label") }</div>
                    </div>
                    <div
                        className={"signup-panel-profile-element no-alt"}
                        id={"signup-panel-profile-form"}
                    >

                        <AvForm
                            className={"signup-panel-profile-form-container"}
                            onValidSubmit={(event, values) => {
                                showlog("onValidSubmit");
                                showlog(event);
                                showlog(values);
                            }}
                        >
                            {/* -------------------------------------------------------------------------- */}
                            <AvGroup className={"signup-panel-profile-input-container signupInput_fullLength"}>
                                <AvInput
                                    type={"email"}
                                    name={"email"}
                                    id={"signupProfile_email"}
                                    className={"signupInput"}
                                    label={""}
                                    value={email}
                                    defaultValue={email}
                                    placeholder={"Email"}
                                    onChange={changeEvent => {
                                        setEmail( changeEvent.target.value );
                                    }}
                                    disabled={false}
                                    required
                                />
                                <AvFeedback>{ translate("required_field_label") }</AvFeedback>
                            </AvGroup>

                            <div className={"signup-panel-company-button-container"}>
                                <ActionButton
                                    color={"#d00"}
                                    className={"signup-panel-company-button-disabled"}
                                    buttonText={"ANNULLA"}
                                    promise={ () => {
                                        return Promise.resolve();
                                    }}
                                    onResult={ result => {
                                        // setForgotPasswordForm( false );
                                        props.onClose();
                                    }}
                                />
                                <ActionButton
                                    visible={ !!email && !!(email.match( EMAIL_REGEX )) }
                                    buttonText={"INVIA"}
                                    className={"signup-panel-profile-button "}
                                    promise={ () => requestResetPassword( email ) }
                                    onResult={ result => {
                                        alert("Ti è stata inviata una email all'indirizzo "+ email );
                                        setTimeout( function() { props.next() }, 5000)
                                    }}
                                    onError={ error => {
                                        alert("C'è stato un problema nell'invio della email");
                                        setTimeout( function() { props.next() }, 5000)
                                    }}
                                />
                            </div>

                        </AvForm>

                    </div>
                    <div className={"signup-panel-profile-element"} id={"signup-panel-profile-footer"}>
                        <ARBLogoComponent/>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}
