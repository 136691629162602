// @flow
import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import {Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from 'reactstrap';
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import {extractBase64ImageFromSvgChart} from "../../../../business-logic/charts";
import {saveAs} from 'file-saver';
import {
    SIRATING_ADMIN_CHART_SDG,
    SIRATING_ADMIN_CHART_THEMATICS_ENV,
    SIRATING_ADMIN_CHART_THEMATICS_GOV,
    SIRATING_ADMIN_CHART_THEMATICS_SOC
} from "../../../../env";
import {exportSeedTableToCSV} from "../../../../business-logic/tree-manager";
import {translate} from "../../../../components/i18n/translate-function";


export const SiRatingAdminThemesChart = props => {


    const [colorBar, setColorBar] = useState(['#f00']);
    const [data, setData] = useState();
    const [labels, setLabels] =
        useState([
            'Gennaio',
            'Febbraio',
            'Marzo',
            'Aprile',
            'Maggio',
            'Giugno',
            'Luglio',
            'Agosto',
            'Settembre',
            'Ottobre',
            'Novembre',
            'Dicembre'
        ])
    ;
    const [inWait, wait] = useState( true );



    useEffect(() => {


        if( !!props.rating ) {

            setData([]);

            let resultData=props.rating;



                    switch ( props.type ) {

                        case SIRATING_ADMIN_CHART_THEMATICS_ENV:

                            setLabels( resultData.map( d => d.label ) );
                            setData(
                                [{
                                    name: "Environmental",
                                    data: resultData.map( d => d.value )
                                }]
                            );
                            setColorBar(['#2CA175']);

                            break;

                        case SIRATING_ADMIN_CHART_THEMATICS_SOC:
                            setLabels( resultData.map( d => d.label ) );
                            setData(
                                [{
                                    name: "Social",
                                    data: resultData.map( d => d.value )
                                }]
                            );
                            setColorBar(['#FC6D29']);
                            break;

                        case SIRATING_ADMIN_CHART_THEMATICS_GOV:
                            setLabels( resultData.map( d => d.label ) );
                            setData(
                                [{
                                    name: "Governance",
                                    data: resultData.map( d => d.value )
                                }]
                            );
                            setColorBar(['#5A2793']);
                            break;

                        case SIRATING_ADMIN_CHART_SDG:

                            //showlog( "sdg chart data" );
                            //showlog(resultData);


                            setData([{
                                name: resultData.name,
                                data: resultData.data.map( d => d.value+"%" )
                            }]);

                            setLabels( resultData.data.map( d => d.label/*.replace(/^([0-9]{1,2}\s\-\s)/, "")*/ ) );
                            break;

                        default:
                            setData( resultData );

                    }


            ;
        }

    }, [props.rating]);










    const apexBarChartOpts = {


        chart: {
            type: 'bar',
            height: 380
          },
          plotOptions: {
            bar: {
              barHeight: '65%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          legend: {
            show: false
          },
          colors:colorBar,
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: labels,
            colors: ['#5A2793']
          },
          yaxis: {
            labels: {
              show: false
            }
          },

          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          }
    };

    let defaultContent =

            (
                <Card>
                    <CardBody>
                        <UncontrolledButtonDropdown className="float-right">
                            <DropdownToggle tag="button" className="btn btn-link arrow-none card-drop p-0">
                                <i className="mdi mdi-dots-vertical"/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem
                                    onClick={ clickEvent => {
                                        let root = document.getElementById("themes-chart-"+ props.title);
                                        let svgArea = root.childNodes[0].childNodes[0];
                                        extractBase64ImageFromSvgChart( svgArea )
                                            .then( data => {
                                                saveAs( data, "themes-chart-"+ props.title);
                                            })
                                    }}
                                >
                                    Download Image
                                </DropdownItem>
                                <DropdownItem
                                    onClick={ clickEvent => {
                                        //showlog( "export CSV" );
                                        //showlog( data );

                                        /*
                                        // in verticale
                                        let csvV =
                                            exportSeedTableToCSV(
                                                data
                                                    .reduce( getLastElementOr, {data: []} )
                                                    .data
                                                    .map( (value, index) => {
                                                        return ({
                                                            label: labels[ index ],
                                                            value: value
                                                        });
                                                    })
                                            )
                                        ;
                                        */

                                        // in orizzontale
                                        let csvH =
                                                data
                                                    .map( set => set.data )
                                                    .map( (value, index) => {
                                                        return (
                                                            Object.keys( value )
                                                                .map( (x, i) => {
                                                                    return ({
                                                                        [labels[ i ]]: value[x]
                                                                    })
                                                                })
                                                        );
                                                    })
                                                    .map( set => (
                                                        set.reduce( (final, current) => {
                                                                final = Object.assign(final, current);
                                                                return final;
                                                            },
                                                            {}
                                                        )
                                                    ))
                                        ;

                                        // //showlog( csvH );
                                        // console.table( csvH );

                                        let blob =
                                            new Blob(
                                                [ exportSeedTableToCSV( csvH ) ],
                                                { type: "text/csv;charset=utf-8" }
                                            )
                                        ;
                                        saveAs( blob, "themes-chart-"+ props.title +".csv" );

                                    }}
                                >
                                    Export Report
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <h4 className="header-title mb-3">{ props.title || "Punteggi" }</h4>

                        {
                            (!!data && data.length > 0)
                                ? (
                                    <Chart
                                        options={ apexBarChartOpts }
                                        series={ data }
                                        type="bar"
                                        className="apex-charts"
                                        height={560}
                                        id={ "themes-chart-"+ props.title }
                                    />
                                )
                                : (
                                    <WaitingOrNodataMessage
                                        waiting={ (!!data && data.length === 0) }
                                        nodataMessage={ translate("No Data") }
                                        className={"bottom-margin-alignment"}
                                    />
                                )
                        }

                    </CardBody>
                </Card>
            )
    ;




    return defaultContent;



};

