import {
	assignCompaniesToConsuler, ConfDeleteCompany,
	editCompany,
	getAllProvinces,
	getAllThemes,
	getCompanies,
	getCompanyAddress,
	getCompanyContacts,
	getConsulerCompanies,
	getEvaluationUsersRole, getSectorHistory,
	getSectors,
	setCompany,
	setSectors,
	updateUsersRole
} from "../services/companies-service";
import {EMAIL_REGEX, role, USERNAME_MIN_LEN, USERNAME_REGEX} from "../env";
import {errorlog, showlog} from "../utils/dev-utils";
import {
	Attachments_GETEvaulations,
	Companies_Edit,
	Companies_EditCurrentCompany, DeleteCompany,
	WelcomePage_Get
} from "../services/request-manager";
import {getCompanyAllowedDimension} from "../services/active-service";
import {convertRawThemesToTree} from "./question-tree-manager";
import {getLastElementOr, onlyNotNull} from "../utils/array-utils";

export const getBaseCompaniesList = () => {
	return (
		getCompanies()
			.then( rawList => {
				return (
					rawList
						.map( raw => ({id: raw.codCliFor, name: raw.ragioneSociale}))
						.sort( (x, y) => {
							if( x.name > y.name ) return 1;
							return -1;
						})
				);
			})
			.catch(e => {
				console.error( e );
			})
	);
};

export const getBaseCompaniesList2 = () => {
	return (
		getCompanies()
			.then( rawList => {
				return (
					rawList
						.filter( raw => raw.leadingCompany === null)
						.map( raw => ({id: raw.codCliFor, name: raw.ragioneSociale}))
						.sort( (x, y) => {
							if( x.name > y.name ) return 1;
							return -1;
						})
				);
			})
			.catch(e => {
				console.error( e );
			})
	);
};

export const getAllCompanies = () => {
	return (
		getCompanies()
			.then( rawList => {


				/*
				categoria: "CATEGORIA1"
				classeContabile: "CLASSE CONTABILE1"
				codCliFor: 6
				indirizzo: "VIA DELLO SBARCO 96"
				localita: "MARSALA"
				partitaIva: "04802500829"
				provincia: "TP"
				provincia_descrizione: "Trapani"
				ragioneSociale: "CLS"
				tipologiaCommerciale: "TIPOLOGIA1"
				 */


				let dto =
					rawList
						.map( raw => raw )
				;
				return dto;
			})
			.catch(e => {
				console.error( e );
			})
	);
};


export const loadConsulterCompaniesData = (fieldMap, consulerId) => {
	return (
		getConsulerCompanies( consulerId )
			.then( list => {
				return ([
						... new Map(
							list
								.filter( onlyNotNull )
								.sort((x, y) => {
									if( x.companyId > y.companyId ) return 1;
									return -1;
								})
								.map( item => [ item.companyId, item ])
						).values()
					])
				;
			})
			.catch(e => {
				errorlog("caricamento aziende dal consulente", e)
				return Promise.reject(e);
			})
	);
}

export const changeConsultantCompany = dstCompanyId => {
	return Companies_EditCurrentCompany({ UserId: parseInt(localStorage.getItem("id"), 10), CompanyId: dstCompanyId });
}




export const loadAllComapnyAllowedDimensions = () => {
	return getCompanyAllowedDimension();
}



export const updateCompany = (companyId, data) => {
	return Companies_Edit( data, companyId );
}



export const loadRatingAttachments = ratingId => {
	return Attachments_GETEvaulations( ratingId );
}


/**
 *
 * @return {Promise<Array>} - lista di dati di aziende
 * */
export const loadCompaniesData = organizzationId => {

	return (
		getCompanies( organizzationId )
			.then( list => {


				return (
					getCompanyAllowedDimension()
						.then( dimensionList => {
							// debugger;
							return (
								list
									.map( companyRow => {
										// debugger;
										companyRow.id = companyRow.codCliFor;
										companyRow.dimension = dimensionList.filter( d => d.id === companyRow.dimensioni ).reduce( getLastElementOr, null);
										return companyRow;
									})
							);
						})
						.catch(e => Promise.reject(e))
				);

			})
	);


}



export const loadAllSectors = companyId => {
	return getSectors( companyId );
}

export const loadSectorHistory = companyId => {
	return getSectorHistory( companyId );
}

export const loadEvaluationUsersRole = (evaluationId,type) => {
	return getEvaluationUsersRole( evaluationId,type );
}


export const loadAllThemes = () => {
	return (
		getAllThemes()
			.then( rawThemes => convertRawThemesToTree( rawThemes ) )
			.catch( e => Promise.reject(e) )
	);
}



/*
// selezione multipla
export const setCompanySectors = ( companyId, selectedSectorsId ) => {
	return setSectors( companyId, selectedSectorsId );
}
*/

// selezione singola
export const setCompanySector = ( cid, sectorList, main ) => {
	return setSectors( cid, sectorList, main );
}

export const setUsersRole = ( cid, sectorList, type ) => {
	return updateUsersRole( cid, sectorList, type );
}


export const loadCompanyContacts = cid => {
	return getCompanyContacts( cid );
}


export const loadCompanyAddress = cid => {
	return getCompanyAddress( cid );
}


export const validationSuperAdminNewUser = (dataField, isEdit) => {
	return (
		new Promise((success, fail) => {


			let errors = [];

			// company ---------------------------------------------------------------------------------------------


			if( dataField.role === role.SUPER_ADMIN.label || dataField.role === role.ARB_OPERATOR.label || dataField.role === role.ASSURANCER.label ) {

			}
			else {
				if( !dataField.companyId ) errors.push({ company: "Manca la compagnia" });
				else {
					if( !!!dataField.companyId && dataField.companyId !== 0 ) errors.push({ company: "Formato id compagnia errato" });
					else {
						if(
							!!(
								(new String( dataField.companyId )).toString().trim()       // conversione in stringa non vuota
							) &&
							!isNaN( dataField.companyId )   // è un numero
						) {}
						else errors.push({ company: "Formato id compagnia errato" });
					}
				}
			}





			// title -----------------------------------------------------------------------------------------------
			// if( !dataField.title ) errors.push({ title: "Manca il titolo" });
			// else {
			// 	if( !!dataField.title && typeof dataField.title !== "string" ) errors.push({ title: "Formato valore titolo errato" });
			// 	else {
			// 		if(
			// 			!!dataField.title
			// 			&&
			// 				!Object.keys(TITLES)
			// 					.map( t => TITLES[t] )
			// 					.includes( dataField.title )
			// 		) {
			// 			errors.push({ title: "Titolo non valido" });
			// 		}
			// 	}
			// }


			// email -----------------------------------------------------------------------------------------------
			if( !dataField.email ) errors.push({ email: "Manca l'email" });
			else {
				if( !!dataField.email && typeof dataField.email !== "string" ) errors.push({ email: "Formato valore email errato" });
				else {
					if( !!dataField.email && !EMAIL_REGEX.test( dataField.email ) ) errors.push({ email: "email non valida" });
				}
			}


			// firstName -------------------------------------------------------------------------------------------
			if( !dataField.firstName ) errors.push({ firstName: "Manca il nome" });
			else {
				if( !!dataField.firstName && typeof dataField.firstName !== "string" ) errors.push({ firstName: "Formato valore nome errato" });
				else {
					if( !!dataField.firstName && !USERNAME_REGEX.test( dataField.firstName ) ) errors.push({ firstName: "Nome non valido" });
					else {
						if( !!dataField.firstName && dataField.firstName.length < USERNAME_MIN_LEN ) errors.push({ firstName: "Nome non valido" });
					}
				}
			}


			// role -------------------------------------------------------------------------------------------
			if( !dataField.role ) errors.push({ role: "Manca il ruolo" });


			//lingua ------------------------------------------------------------------------------------------
			if( !dataField.language_id ) errors.push({ language_id: "Manca la lingua"})

			// lastName --------------------------------------------------------------------------------------------
			if( !dataField.lastName ) errors.push({ lastName: "Manca il cognome" });
			else {
				if( !!dataField.lastName && typeof dataField.lastName !== "string" ) errors.push({ lastName: "Formato valore cognome errato" });
				else {
					if( !!dataField.lastName && !USERNAME_REGEX.test( dataField.lastName ) ) errors.push({ lastName: "Cognome non valido" });
					else {
						if( !!dataField.lastName && dataField.lastName.length < USERNAME_MIN_LEN ) errors.push({ lastName: "Cognome non valido" });
					}
				}
			}

			if( !isEdit ) {

				/*
				// password --------------------------------------------------------------------------------------------
				if( !dataField.password ) errors.push({ password: "Manca la password" });
				else {
					if( !!dataField.password && typeof dataField.password !== "string" ) errors.push({ password: "Formato valore password errato" });
					else {
						if( !!dataField.lastName && dataField.password.length < PWD_MIN_LEN ) errors.push({ password: "Password troppo corta" });
					}
				}

				// confirmPassword -------------------------------------------------------------------------------------
				if( dataField.confirmPassword !== dataField.password ) errors.push({ confirmPassword: "La password non corrisponde" });
				*/
			}


			showlog("unni semo?")


			if( errors.length > 0 ) fail( errors );
			else success();

		})


	);
}


export const validationAdminNewUser = (dataField, isEdit) => {
	return (
		new Promise((success, fail) => {


			let errors = [];


			/*
			// title -----------------------------------------------------------------------------------------------
			if( !dataField.title ) errors.push({ title: "Manca il titolo" });
			else {
				if( !!dataField.title && typeof dataField.title !== "string" ) errors.push({ title: "Formato valore titolo errato" });
				else {
					if(
						!!dataField.title
						&&
							!Object.keys(TITLES)
								.map( t => TITLES[t] )
								.includes( dataField.title )
					) {
						errors.push({ title: "Titolo non valido" });
					}
				}
			}
			*/


			// email -----------------------------------------------------------------------------------------------
			if( !dataField.email ) errors.push({ email: "Manca l'email" });
			else {
				if( !!dataField.email && typeof dataField.email !== "string" ) errors.push({ email: "Formato valore email errato" });
				else {
					if( !!dataField.email && !EMAIL_REGEX.test( dataField.email ) ) errors.push({ email: "email non valida" });
				}
			}


			// firstName -------------------------------------------------------------------------------------------
			if( !dataField.firstName ) errors.push({ firstName: "Manca il nome" });
			else {
				if( !!dataField.firstName && typeof dataField.firstName !== "string" ) errors.push({ firstName: "Formato valore nome errato" });
				else {
					if( !!dataField.firstName && !USERNAME_REGEX.test( dataField.firstName ) ) errors.push({ firstName: "Nome non valido" });
					else {
						if( !!dataField.firstName && dataField.firstName.length < USERNAME_MIN_LEN ) errors.push({ firstName: "Nome non valido" });
					}
				}
			}

			// lingua-----------------------------------
			if( !dataField.language_id ) errors.push({ language_id: "Manca la lingua"})

			// role -------------------------------------------------------------------------------------------
			if( dataField.role === null ) errors.push({ role: "Manca il ruolo" });

			// lastName --------------------------------------------------------------------------------------------
			if( !dataField.lastName ) errors.push({ lastName: "Manca il cognome" });
			else {
				if( !!dataField.lastName && typeof dataField.lastName !== "string" ) errors.push({ lastName: "Formato valore cognome errato" });
				else {
					if( !!dataField.lastName && !USERNAME_REGEX.test( dataField.lastName ) ) errors.push({ lastName: "Cognome non valido" });
					else {
						if( !!dataField.lastName && dataField.lastName.length < USERNAME_MIN_LEN ) errors.push({ lastName: "Cognome non valido" });
					}
				}
			}

			if( !isEdit ) {
				/*
				// password --------------------------------------------------------------------------------------------
				if( !dataField.password ) errors.push({ password: "Manca la password" });
				else {
					if( !!dataField.password && typeof dataField.password !== "string" ) errors.push({ password: "Formato valore password errato" });
					else {
						if( !!dataField.lastName && dataField.password.length < PWD_MIN_LEN ) errors.push({ password: "Password troppo corta" });
					}
				}

				// confirmPassword -------------------------------------------------------------------------------------
				if( dataField.confirmPassword !== dataField.password ) errors.push({ confirmPassword: "La password non corrisponde" });
				*/
			}


			if( errors.length > 0 ) fail( errors );
			else success();

		})


	);
}




export const assignToConsuler = (companiesId, consulerId) => {
	let payload = { UserId: consulerId, CompanyId: companiesId };
	return assignCompaniesToConsuler( payload );
}



export const saveCompany = (data, oid) => {

	let companyData = {
		PartitaIva: data.piva,
		Dimensioni:
			!!data.dim.value
				? data.dim.value
				: (
					!!data.dim
						? data.dim
						: 1
				)
		,
		RagSoc: data.name,
		Indirizzo: data.address,
		Localita: data.city,
		Cap: data.cap,
		Provincia: ((!!data.province && !!data.province.value) ? data.province.value : data.province),
		NumTel: data.phone1,
		NumTel2: data.phone2,
		IndirEmail: data.email,
		LeadingCompanyId: (!!oid ? oid : 0),
		employeesNumbersLevel: data.employeesNumbersLevel,
		CodiceSdi: data.codiceUnivoco,
		IndirEmailPecsdi: data.pec,
		Riferimento: data.datiLegale,
		CodFiscale: data.codiceFiscale,
		Sector_id: data.sector
		
	};
	


	return setCompany( companyData );
}



export const editUpdateCompany = (data, cid) => {

	let companyData = {
		PartitaIva: data.piva || "",
		Dimensioni:
			( !!data.dim && !!data.dim.value )
				? data.dim.value
				: data.dim
				|| "",
		RagSoc: data.name || "",
		Indirizzo: data.address || "",
		Localita: data.city || "",
		Cap: data.cap || "",
		Provincia: ((!!data.province && !!data.province.value) ? data.province.value : data.province) || "",
		NumTel: data.phone1 || "",
		NumTel2: data.phone2 || "",
		IndirEmail: data.email || "",
		employeesNumbersLevel: data.employeesNumbersLevel || "",
		CodiceSdi: data.codiceUnivoco || "",
		IndirEmailPecsdi: data.pec || "",
		Riferimento: data.datiLegale || "",
		CodFiscale: data.codiceFiscale || "",
		Sector_id: data.sector || ""
	};

	// return Promise.resolve();
	return editCompany( companyData, cid );
}




export const loadAllProvinces = () => getAllProvinces();


export const getCompanyInfoByWelcome = companyID => {
	return (
		WelcomePage_Get( companyID || 0 )
			.then( response => {

				showlog("response di WelcomePage_Get");
				showlog(response.companies);
				showlog(response.companies.companies);			// lista companies
				showlog(response.companies.companySelected);	// info company

				let dto = {
					user: {
						firstName: localStorage.getItem("firstName"),
						lastName: localStorage.getItem("lastName"),
						company: localStorage.getItem("companyName"),
						email: localStorage.getItem("email")
					},
					company: response.companies.companySelected,
					companies: response.companies.companies || []
				};

				return dto;
			})
			.catch( e => Promise.reject( e ) )
	);
}

export const ConfirmDeleteCompany = id => {
	return ConfDeleteCompany(id);
}
