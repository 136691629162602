import React, { Component } from 'react';
import { COLOR_POLICY } from "../../../env";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "../modal.css";
import ActionButton from "../../save-button";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import {EMOJ} from "../../../emoj";
import { errorlog, renderlog, showlog, emoj } from "../../../utils";
registerLocale("it", it); // register it with the name you want


export const ChangeTimingPrompt = props => {

	renderlog("ChangeTimingPrompt", null, props, COLOR_POLICY.disabled);

	if( !!props.isNoDataScenario ) {
		props.onChange();
		return null;
	}

	return (
		<Modal isOpen={ true } toggle={ toggleEvent => props.onClose() } className="">
            <ModalHeader toggle={ toggleEvent => props.onClose() } className={"chart-data-settings-header"}>
                <span className={""}>{ emoj( EMOJ.warning ) }</span> &nbsp;&nbsp; Attenzione
            </ModalHeader>
			<ModalBody className={""}>
				<span>
					Cambiando il timimng potrebbero perdersi alcuni dati già impostati precedentemente
				</span>
			</ModalBody>
			<ModalFooter>

				<ActionButton
					color={"link"}
					buttonText={"annulla"}
					promise={ () => {
						return Promise.resolve();
					}}
					onResult={  toggleEvent => props.onClose() }
				/>

				<ActionButton
					promise={ () => {
						return Promise.resolve();
					}}
					onResult={ result => {
						props.onChange();
					}}
					onError={ e => {
						errorlog("errore al cambio timing");
						this.setState({
							...this.state,
							errors: {
								...this.state.error,
								...e
							}
						});
					}}
				/>


			</ModalFooter>
		</Modal>
	);

}

