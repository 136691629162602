import {getLastElementOr, showlog} from "../utils";
import {setOpenAnswer, setPreventiveQuestion} from "../services/gender-gap";

/**
 * Crea una lista di elementi strutturati con i seguenti campi: id, text, value
 *
 * Se la domanda ha il campo isOpenAnswer allora consideriamo le openAnswer altrimenti
 * prendiamo in considerazione le scelte
 *
 * @param question
 * @returns {{id: number, text: *, value: *}[]|null|*[]}
 */
export const createOpenAnswerData = (question, additionalAnswer) => {

    if( !question ) return [];
    if( !question.isOpenAnswer ) return [];

    // if( !isNotEmptyArray( question.openAnswers ) ) return [];
    // if( !question.answers ) return [];
    // if( !isNotEmptyArray( question.answers ) ) return [];

    return (
        Object.keys(question.openAnswers)
            .filter( openAnswerField => ((question.openAnswers[ openAnswerField ] !== null) && (question.openAnswers[ openAnswerField ] !== "")) )
            .map( openAnswerField => ({
                id: parseInt( openAnswerField.split("text").join(""), 10 ),
                text: question.openAnswers[ openAnswerField ],
                value:
                    question.answers
                        .reduce( getLastElementOr, {} )
                        [
                        Object.keys(question.answers.reduce( getLastElementOr, {} ))
                            .filter( ansField => (ansField === `value${ parseInt( openAnswerField.split("text").join(""), 10 ) }`) )
                            .reduce( getLastElementOr, null )
                        ]
            }))
            .map( openAnswer => {
                if( !!additionalAnswer ) {
                    let referimentAdditionAnswer =
                        additionalAnswer
                            .filter( addAns => addAns.id === openAnswer.id )
                            .reduce( getLastElementOr, openAnswer )
                    ;
                    openAnswer.value = referimentAdditionAnswer.value;
                }
                return openAnswer;
            })
    );

}



export const saveOpenAnswer = ( ratingID, questionID, openAnswers ) => {
    let dto = {};
    showlog( "openAnswers" );
    showlog( openAnswers );
    openAnswers.openAnswers
        .forEach( oa => {
            Object.assign( dto, JSON.parse(`{"value${ oa.id }":${ oa.value || 0 }}`))
        })
    ;
    showlog(dto);
    return setOpenAnswer( ratingID, questionID, dto );
}


export const savePreventiveQuestion = (ratingID, questionID, choicheID) => setPreventiveQuestion( ratingID, questionID, choicheID );
