import React, {Suspense} from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	CustomInput,
	Label,
	Nav,
	NavItem,
	NavLink,
	PopoverBody,
	PopoverHeader,
	Row,
	TabContent,
	TabPane,
	UncontrolledPopover,
} from 'reactstrap';
import {GRI_ADMIN_TREE_DEEP_LEVELS_NAME, HELPER, HOST, permissions, TABS, TREE_SECTION} from "../../../env";
import "../../../services/interceptor";
import GriManagerListPopup from "./gri-manager-list-popup";
import {
	cloneOpenNodes,
	convertTableToTree,
	getAllTreeInfo,
	getTreeNodeByPath,
	isChapterMaterial,
	openSubTree,
	treeLog
} from "../../../business-logic/tree-manager";
import Select from 'react-select';
import {
	loadGriStructure,
	saveChapterMaterial,
	saveManager,
	saveMeasureUnit,
	saveTabMaterial,
	saveTiming,
	setChapterProperties,
	setInfo
} from "../../../business-logic/gri";
import StandBySystemModalComponent from "./standby-system-modal";
import AdminUserEditModalContent from "../admin/user-edit-modal";
import {beautify, cloneObject} from "../../../utils/object-utils";
import {errorlog, loadDatalog, mountedlog, renderlog, showlog} from "../../../utils/dev-utils";
import ErrorMessageModalComponent from "../../error-message-modal-component";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {hasPermission} from "../../../business-logic/auth";
import ActionButton from "../../save-button";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {TreeToggler} from "../../tree-toggler";
import "../../../style/srtool-color-policy.css";
import {withRouter} from "react-router-dom";
import {MeasureUnitModal} from "../super-admin/measure-unit-modal";
import {GuideLineIconSvgComponent} from "../../svg/guide-line-icon-svg-component";
import {ReccomendationIconSvgComponent} from "../../svg/reccomendation-icon-svg-component";
import "../../../style/sr-tool-conf.css";
import {OverWaitingPannel} from "./over-waiting-panel";
import {getTreeFingerPrint} from "../../../utils";
import {ChangeTimingPrompt} from "./change-timing-prompt";
import {AdditionalRequirements} from "../../svg/additional-requirements";
import GotoTopComponent from "../../goto-top-component";
import {translate} from "../../i18n/translate-function";


const GUIDELINES_POST_IT = (
	<span
		className={""}
	>
		{ translate("GUIDE_LINEES_POST_TEXT") }
		<a href={"https://www.globalreporting.org/how-to-use-the-gri-standards/resource-center/"} target={"_blank"}>link</a>
	</span>
);


class TreeGriCollectComponent extends React.Component {

	state = {
		activeTab: 1,
		currentTab: TABS.general,
		tree: this.props.tree,
		processing: false,
		processingMessage: "Elaborazione in corso...",
		...this.props.collect,
		inWait: true
	}

	constructor( props ) {
		super( props );
		this.inWait                     = this.inWait.bind( this );
		this.toggle                     = this.toggle.bind( this );
		this.getSubTreeChapter          = this.getSubTreeChapter.bind( this );
		this.standby                    = this.standby.bind( this );
		this.refreshData                = this.refreshData.bind( this );
		this.toggleNode                 = this.toggleNode.bind( this );		// non necessario
		this.helperNode                 = this.helperNode.bind( this );		// non necessario


	}

	inWait() {
		return (
			<div>Wait</div>
		);
	}

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};


	toggleNode = node => {


		// sotto-sotto-requisiti non hanno niente da aprire/chiudere
		if( node.path.length === 5 ) return;

		let treeTmp = cloneObject( this.state.tree );
		let nodeTmp = getTreeNodeByPath( treeTmp, node.path );
		nodeTmp.open = !getTreeNodeByPath( this.state.tree, node.path ).open;

		// apertura sotto-albero
		// openSubTreeFromPath( treeTmp, info.path )

		if( !!nodeTmp.open ) {
			openSubTree( nodeTmp )
				.then( subTreeByNode => {

					// nodeTmp.requirements = subTreeByNode.requirements;	// work
					// nodeTmp = cloneObject( subTreeByNode );					// doesn't work
					Object.keys(nodeTmp)
						.map( keyNode => {
							nodeTmp[ keyNode ] = subTreeByNode[ keyNode ];
						})
					;

					let processedTree = cloneOpenNodes( treeTmp, this.state.tree );

					this.setState({
						...this.state,
						// tree: treeTmp
						tree: processedTree
					});

				})
			;
		}
		else {
			this.setState({
				...this.state,
				tree: treeTmp
			});
		}
	}

	standby( isStop = false ) {

		return (
			new Promise((success, fail) => {
				try {

					if( typeof this.props.componentInWait === "function" ) this.props.componentInWait();

					this.setState({
							...this.state,
							isSwitchingTab: !isStop
						},
						newState => {
							showlog("exit stand-by");
							// setTimeout( success, 250 );
							// return success();
							success();
						})
					;

				}
				catch( e ) {
					errorlog("errore allo stand-by", e);
					fail();
				}
			})
		);

	}


	helperNode = node => (

		(!!HELPER && (window.location.href.indexOf("help") !== -1))
			? (
				<span
					className={"question-info-icon helper-icon"}
					onClick={ clickEvent => {
						clickEvent.stopPropagation();
						this.setState({
							...this.state,
							helper: node
						})
					}}
				>
					<span>{ emoj( EMOJ.ambulance ) }</span>
					<code>{ node.structureId }</code>
				</span>
			)
			: null

	)


	render() {

		renderlog(
			"configuration TREE",
			this.state,
			this.props,
			{ color: "#00ffaf", contrastColor: "#222222" }
		);

		if( !!this.state.inWait ) {
			return (
				<WaitingOrNodataMessage
					waiting={ !!this.state.inWait }
					nodataMessage={translate("No Data")}
					fullHeight={ true }
				/>
			);
		}


		return (
			<Suspense fallback={this.inWait()}>



				{
					(!!this.state.helper)
						? (
							<div
								className={"helper-pannel-backdrop"}
								onClick={ clickEvent => {
									clickEvent.stopPropagation();
									this.setState({
										...this.state,
										helper: null
									})
								}}
							>
								<div
									className={"helper-pannel"}
								>
									<code>Helper</code>
									<pre>
										{
											beautify(
												{
													id: this.state.helper.id,
													name: this.state.helper.name,
													open: this.state.helper.open,
													applicability: this.state.helper.applicability,
													material: this.state.helper.material,
													core: this.state.helper.core,
													userManager: this.state.helper.userManager,
													userManagerName: this.state.helper.userManagerName,
													measureUnitList: this.state.helper.measureUnitList,
													measureUnit: this.state.helper.measureUnit,
													measureUnitIsString: this.state.helper.measureUnitIsString,
													isString: this.state.helper.isString,
													timing: this.state.helper.timing,
													structureId: this.state.helper.structureId,
													subRequirementsRepeating: this.state.helper.subRequirementsRepeating,
													evaluate: this.state.helper.evaluate,
													griRepeatingValueId: this.state.helper.griRepeatingValueId,
													value: this.state.helper.value,
													dateGoal: this.state.helper.dateGoal,
													dateStartGoal: this.state.helper.dateStartGoal,
													valueGoal: this.state.helper.valueGoal,
													valueStartGoal: this.state.helper.valueStartGoal,
													path: this.state.helper.path,
													raw: this.state.seeds
												}
											)
										}
									</pre>
								</div>
							</div>
						)
						: null
				}



				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={ this.state.errorMessage }
								noheader={ true }
								onClose={ () => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.umModal)
						? (
							<MeasureUnitModal
								collect={ this.props.collect }
								onClose={ isToRefresh => {

									if( !!isToRefresh ) this.refreshData();
									else {
										this.setState({
											...this.state,
											umModal: null
										})
									}

								}}
							/>
						)
						: null
				}


				{
					(!!this.state.changeTimingPrompt)
						? (
							<ChangeTimingPrompt
								isNoDataScenario={ this.props.isNoDataScenario }
								onClose={ () => {
									this.setState({
										...this.state,
										changeTimingPrompt: null
									})
								}}
								onChange={ () => {
									let treeTmp = cloneObject( this.state.tree );
									let nodeTmp = getTreeNodeByPath( treeTmp, this.state.changeTimingPrompt.node.path );
									nodeTmp.timing = this.state.changeTimingPrompt.changeEvent.value;

									let changeTimingPrompt = cloneObject( this.state.changeTimingPrompt );

									this.setState({
											...this.state,
											isTreeInWait: true,
											changeTimingPrompt: null
										},
										() => {

											// debugger;

											saveTiming( changeTimingPrompt.changeEvent.value, this.props.collect.id, changeTimingPrompt.level, changeTimingPrompt.node.id )
												.then( () => {


													this.refreshData()
														.then( refreshed => {
															showlog("done!");
														})
														.catch( e => {
															throw e;
														})
													;

													/*
													this.setState({
														...this.state,
														changeTimingPrompt: null
													},
														() => {

															this.refreshData()
																.then( refreshed => {

																})
																.catch( e => {
																	throw e;
																})
															;

														}
													)
													*/

												})
												.catch(e => {
													errorlog("errore al cambio timimng", e);
													//this.standby( { errorMessage: "Si è verificato un errore" }, false );
												})

										}
									)
								}}
							/>
						)
						: null
				}


				<Nav tabs>
					{
						// this.state.tree.tabs
						Object.keys(TABS)
							.map( tabKey => TABS[tabKey] )

							.map((tab, index) => {
								return (
									<NavItem key={index}>
										<NavLink
											id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[0] +"-"+ tab.id}
											href="#"
											// className={classnames({ active: this.state.activeTab === tab.id })}
											className={
												"tab-with-profile-button-container " +
												(
													(this.state.activeTab === tab.id)
														? tab.activeClassName
														: tab.className
												)
											}
											onClick={() => {

												// debugger;

												if( !this.state.isSwitchingTab ) {

													if( typeof this.props.componentInWait === "function" ) this.props.componentInWait();

													this.standby()
														.then( () => {
															this.setState({
																	...this.state,
																	currentTab: tab,
																	activeTab: tab.id,
																	params: {
																		...this.state.params,
																		area: tab.id
																	}
																},
																() => {

																	this.props.history.push({
																		search: "section="+ TREE_SECTION.CONFIGURATION +"&area="+ (
																			Object.keys(TABS)
																				.map( tabKey => TABS[tabKey] )
																				.filter( t => t.id === tab.id )
																				.map( t => t.id )
																				.reduce( getLastElementOr, TABS.general.id )
																		)
																	});

																	// debugger;
																	this.refreshData();
																}
															)
														})

												}





											}}>

											<span className="d-none d-lg-block">{tab.name}</span>
											<i className={"mdi mdi-account tab-profile-button "+ (!!(this.state.tree.tabs.filter( t => t.id === tab.id).reduce( getLastElementOr, {userManager: ""}).userManager) ? "tab-manager-present" : "")} />
										</NavLink>
									</NavItem>
								);
							})
					}

					{/*<div className={"tab-comander-container"}/>*/}

					<TreeToggler
						tree={ this.state.tree }
						onFinish={ newTree => {
							this.setState({
								...this.state,
								tree: newTree
							})
						}}
						className={"tree-toggler-in-srtool-conf"}
					/>

				</Nav>


				{
					(!!this.state.isSwitchingTab)
						? (
							<React.Fragment>
								{/*
								<pre>switching... [{ !!this.state.isSwitchingTab ? <code>true</code> : <code>false</code> }]</pre>
								*/}
								<WaitingOrNodataMessage
									waiting={true}
									waitMessage={translate("Waiting")}
									nodataMessage={translate("No Data")}
									fullHeight={true}
									spinnerColor={"success"}
								/>
							</React.Fragment>
						)
						: (
							<React.Fragment>


								{
									(!!this.state.isTreeInWait)
										? (
											<OverWaitingPannel/>
										)
										: null
								}


								<TabContent activeTab={this.state.activeTab || 1} className={"srtool-target-page"}>
									{
										this.state.tree.tabs
											.map((tab, index) => {
												return (
													<TabPane
														tabId={tab.id}
														key={index}
													>

														<Row>
															<Col sm="12">
																{/*<span className={"kindly-h-marginer conf-tree-comanders"+ (parseInt(this.props.collect.step, 10) > 1 ? "-single" : "")}>*/}
																<span className={"kindly-h-marginer conf-tree-comanders"}>

														{
															// (parseInt(this.props.collect.step, 10) === 1)
															(true)
																? (
																	<React.Fragment>

																		{/*		checkbox ai tab	*/}
																		{
																			(tab.id === TABS.general.id)
																				? null
																				: (
																					<span>
																						<input
																							type="checkbox"
																							id={ "tab-"+ tab.id +"-app"}
																							className="conftree-high-level-comander-checkbox"
																							onClick={clickEvent => {
																								clickEvent.stopPropagation();
																								showlog("cliccato checkbox input");



																								let DOMElement = clickEvent.target;
																								this.setState({
																										...this.state,
																										isTreeInWait: true
																									},
																									() => {
																										document.getElementById("tab-"+ tab.id +"-app-label").innerHTML = "... in attesa";

																										setChapterProperties( { Applicability: (!!(DOMElement.checked)) ? 1 : 0  }, this.props.collect.id, tab.id, 0 )
																											.then( result => {
																												return this.refreshData();
																											})
																											.then(newTree => {


																												// debugger;

																												if( !!newTree ) {
																													newTree
																														.tabs.filter( t => t.id === this.state.currentTab.id ).reduce( getLastElementOr, null)
																														.chapters.map( c => {
																														c.infos.forEach( i => {
																															if( !!document.getElementById( GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ i.id +"-applicabilita" ) ) document.getElementById( GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ i.id +"-applicabilita" ).checked = !!i.applicability;
																														})
																													})
																													;
																												}
																											})
																									}
																								)




																							}}
																							disabled={ false }
																							path={ JSON.stringify(tab.path) }
																							value={ JSON.stringify(tab.path) }
																						/>
																						<Label
																							id={"tab-"+ tab.id +"-app-label"}
																							onClick={clickEvent => {
																								clickEvent.stopPropagation();
																							}}
																						>
																							Applicabile
																						</Label>
																					</span>
																				)
																		}



																		{
																			(tab.id === TABS.general.id)
																				? null
																				: (
																					<span>
																						<input
																							type="checkbox"
																							id={ "tab-"+ tab.id +"-mat"}
																							className="conftree-high-level-comander-checkbox"
																							onClick={clickEvent => {
																								clickEvent.stopPropagation();


																								let DOMElement = clickEvent.target;
																								this.setState({
																										...this.state,
																										isTreeInWait: true
																									},
																									() => {
																										showlog("cliccato checkbox input");
																										document.getElementById("tab-"+ tab.id +"-mat-label").innerHTML = "... in attesa";

																										// saveTabMaterial( !!!tab.material, this.props.collect.id, tab.id )
																										// debugger;
																										saveTabMaterial( (!!tab.material ? 0 : 1), this.props.collect.id, tab.id )
																											.then( result => {
																												// debugger;
																												return this.refreshData();
																											})
																											.then(newTree => {

																											})

																									})




																							}}
																							disabled={ false }
																							path={ JSON.stringify(tab.path) }
																							value={ JSON.stringify(tab.path) }
																							// checked={ !!tab.material }
																						/>
																						<Label
																							id={"tab-"+ tab.id +"-mat-label"}
																							onClick={clickEvent => {
																								clickEvent.stopPropagation();
																							}}
																						>
																							Materiale
																						</Label>
																					</span>
																				)
																		}

																	</React.Fragment>
																)
																: null
														}





														{
															( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
																? (
																	<div className={"info-gri-manager-name-label"}>
																		<ActionButton
																			buttonText={
																				<React.Fragment>
																					<i className="mdi mdi-account tree-node-controller-text-button-icon"/>
																					{/*<span className={"info-gri-manager-name-label req-button-label"} >*/}
																					<span className={"tree-node-controller-text-button" + (!!tab.userManager ? "" : " tree-node-controller-text-button-empty")} >
																						{
																							(!!tab.userManager)
																								? tab.userManagerName
																								: translate("Select")
																						}
																					</span>
																				</React.Fragment>
																			}
																			color={ (!!tab.userManager) ? "success" : "primary" }
																			// className={"card-action info-button req-button-label" + ((!tab.userManager) ? " btn manager-button" : " conf-chapter-man") }
																			className={"tree-node-controller-button"}
																			promise={ () => {
																				return Promise.resolve( );
																			}}
																			onResult={ result => {
																				this.setState({
																					...this.state,
																					// getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + tab.id,
																					getManagerListPopup: tab.id +"-0",
																					currentUserManager: tab.userManager
																				})
																			}}
																			onError={ e => {

																			}}
																		/>
																	</div>
																)
																: null
														}

													</span>
															</Col>
														</Row>



														<Row>
															<Col sm="12" id={`tab-${ tab.id }-content`}>
																{

																	(!!tab.chapters && tab.chapters.length > 0)
																		? this.getSubTreeChapter( tab.chapters )
																		: (
																			<WaitingOrNodataMessage
																				// waiting={ !(!!this.state.data && this.state.data.length === 0) }
																				waiting={ false }
																				nodataMessage={ <p className="mt-3">TAB senza contenuti</p> }
																				className={"bottom-margin"}
																				fullHeight={ true }
																				spinnerColor={"success"}
																			/>
																		)

																}

																{/*<GotoTopComponent/>*/}

															</Col>
														</Row>


														<GotoTopComponent
															propagateClickEvent={ false }
															domElement={ document.getElementById(`tab-${tab.id}-content`) }
															alwaysVisible={ false }
															className={"srtool-scroll-to-top-button"}
														/>


													</TabPane>
												);
											})
									}


									{/* HORROR VACUI */}
									{
										(this.state.tree.tabs.map(t => t.chapters.map(ch => ch.id).join(",")).join("|").trim() === "")
											? (
												<WaitingOrNodataMessage
													waiting={ false }
													nodataMessage={ <p className="mt-3">{translate("Waiting")}</p> }
												/>
											)
											: null
									}

								</TabContent>




							</React.Fragment>



						)
				}








				{
					(!!this.state.getManagerListPopup)
						? (
							<GriManagerListPopup

								collectId={ (this.props.match.params.id || this.props.id) }

								currentUserManager={ this.state.currentUserManager }

								onClose={ () => {
									this.setState({
										...this.state,
										getManagerListPopup: null
									})
								}}

								onDeselect={ userManager => {

									showlog("manager rimosso");

									let treeTmp = cloneObject( this.state.tree );

									this.standby()
										.then( () => {
											let pathId =
												this.state
													.getManagerListPopup
													.split("-")
													.map( id => parseInt(id, 10) )
													.filter(id => !!id)
											;

											showlog("paths");
											showlog(pathId);

											let node = getTreeNodeByPath(treeTmp, pathId);

											// managerID, collectID, level, nodeID
											saveManager( 0, this.props.collect.id, pathId.length -1,  ( pathId.length === 0 ) ? node.id : pathId[ pathId.length -1 ] )
												.then( result => {
													this.refreshData();
												})

										})


								}}

								onChoose={ userManager => {

									showlog("manager scelto");

									let treeTmp = cloneObject( this.state.tree );

									this.standby()
										.then( () => {
											let pathId =
												this.state
													.getManagerListPopup
													.split("-")
													.map( id => parseInt(id, 10) )
													.filter(id => !!id)
											;

											showlog("paths");
											showlog(pathId);

											let node = getTreeNodeByPath(treeTmp, pathId);

											// debugger;

											node.userManager = userManager.id;
											node.userManagerName = userManager.firstName +" "+ userManager.lastName;

											// managerID, collectID, level, nodeID
											saveManager( userManager.id, this.props.collect.id, pathId.length -1,  ( pathId.length === 0 ) ? node.id : pathId[ pathId.length -1 ] )
												.then( result => {
													this.refreshData();
												})
										})
								}}


								addManager={ () => {
									this.setState({
										...this.state,
										getManagerListPopup: null,
										addManagerModal: true
									})
								}}

							/>
						)
						: null
				}


				{
					(!!this.state.addManagerModal)
						? (
							<AdminUserEditModalContent
								isManager={ true }
								currentUserManager={ this.state.currentUserManager }
								close={ forceRefresh => {
									this.setState(
										{
											...this.state,
											getManagerListPopup: null,
											addManagerModal: null
										},
										() => {
											// if( !!forceRefresh ) this.loadData();
										}
									);
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.inWaiting)
						? (
							<StandBySystemModalComponent
								message={ this.state.processingMessage }
							/>
							)
						: null
				}


			</Suspense>
		);
	}



	getSubTreeChapter( chapters ) {


		let colorClassInfo =
			Object.keys(TABS)
				.map( tabKey => TABS[tabKey] )
				.filter( tab => tab.id === this.state.activeTab )
				.reduce( getLastElementOr, { lightClassName: "" } )
				.lightClassName
		;

		let colorClassRequirement =
			Object.keys(TABS)
				.map( tabKey => TABS[tabKey] )
				.filter( tab => tab.id === this.state.activeTab )
				.reduce( getLastElementOr, { lighterClassName: "" } )
				.lighterClassName
		;

		return (
			chapters
				.map( (ch, chIndex) => {
					return (
						<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}>
							<CardHeader
								className={
									Object.keys(TABS)
										.map( tabKey => TABS[tabKey] )
										.filter( tab => tab.id === this.state.activeTab )
										.reduce( getLastElementOr, { activeClassName: null } )
										.activeClassName
									|| TABS.general.activeClassName
								}
							>
								<NavLink
									id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}
									href="#"
									onClick={ clickEvent => {


										let treeTmp = cloneObject( this.state.tree );
										let nodeTmp = getTreeNodeByPath( treeTmp, ch.path );
										nodeTmp.open = !getTreeNodeByPath( this.state.tree, ch.path ).open;
										this.setState({
											...this.state,
											tree: treeTmp
										});

									}}
								>
									<h5 className="custom-accordion-title m-0">
										{/*<h5 className="custom-accordion-title d-block pt-2 pb-2" >*/}
											<span className={"conf-chapter-name"}>{ ch.infos.reduce( getLastElementOr, {name: ""}).name.split("-")[0] +" - "+ ch.name }</span>
										{/*</h5>*/}
									</h5>


								</NavLink>



										<span className={"conf-tree-comanders"}>


											{
												(!ch.infos.every( info => info.core == 1 ))
													? (
														<span>
															<input
																type="checkbox"
																id={ "ch-"+ ch.id +"-app" }
																className="conftree-high-level-comander-checkbox"
																onClick={clickEvent => {
																	// clickEvent.stopPropagation();


																	document.getElementById("ch-"+ ch.id +"-app-label").innerHTML = "... in attesa";
																	let DOMElement = clickEvent.target;


																	this.setState({
																			...this.state,
																			isTreeInWait: true
																		},
																		() => {

																			// funzionano
																			// clickEvent.target.checked = clickEvent.target.value === "on" && clickEvent.target.checked;

																			// TODO
																			// clickEvent.target.indeterminate = !(clickEvent.target.value === "on" && clickEvent.target.checked);

																			document.getElementById("ch-"+ ch.id +"-app-label").innerHTML = "... in attesa";

																			// if( !!ch.applicability ) {
																			setChapterProperties( { Applicability: ((!!(DOMElement.checked) ? 1 : 0)) }, this.props.collect.id, ch.id, 1 )
																				.then( result => {

																					// debugger;

																					return this.refreshData();
																				})
																				.then(newTree => {


																					// debugger;


																					if( !!newTree ) {
																						newTree
																							.tabs.filter( t => t.id === this.state.currentTab.id ).reduce( getLastElementOr, {chapters: []})
																							.chapters.filter( c => c.id === ch.id ).reduce( getLastElementOr, {infos: []})
																							.infos.forEach( i => {
																								if( !!document.getElementById( GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ i.id +"-applicabilita" ) ) document.getElementById( GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ i.id +"-applicabilita" ).checked = !!i.applicability;
																							})
																							;
																					}
																				})
																			// }
																		});









																}}
																disabled={ false }
																path={ JSON.stringify(ch.path) }
																value={ JSON.stringify(ch.path) }
															/>
															<Label
																id={ "ch-"+ ch.id +"-app-label" }
																onClick={clickEvent => {
																	// clickEvent.stopPropagation();
																}}
															>
																Applicabile
															</Label>
														</span>
													)
													: null
											}





											{
												( this.state.activeTab === TABS.general.id )
													? null
													: (
														<span>
															<input
																type="checkbox"
																id={ "ch-"+ ch.id +"-mat"}
																className="conftree-high-level-comander-checkbox"
																onClick={clickEvent => {
																	clickEvent.stopPropagation();
																	showlog("cliccato checkbox input");

																	// funzionano
																	// clickEvent.target.checked = clickEvent.target.value === "on" && clickEvent.target.checked;

																	// TODO
																	// clickEvent.target.indeterminate = !(clickEvent.target.value === "on" && clickEvent.target.checked);


																	// showlog(ch);


																	let DOMElement = clickEvent.target;
																	this.setState({
																			...this.state,
																			isTreeInWait: true
																		},
																		() => {
																			document.getElementById("ch-"+ ch.id +"-mat-label").innerHTML = "... in attesa";

																			saveChapterMaterial( !!ch.material ? 0 : 1, this.props.collect.id, ch.id )
																				.then( result => {
																					return this.refreshData();
																				})
																				.then(newTree => {
																					newTree
																						.tabs.filter( t => t.id === this.state.currentTab.id ).reduce( getLastElementOr, null)
																						.chapters.filter( c => c.id === ch.id ).reduce( getLastElementOr, null)
																						.infos.forEach( i => {
																						if( !!document.getElementById( GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ i.id +"-materiale" ) ) document.getElementById( GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ i.id +"-materiale" ).checked = !isChapterMaterial( ch.id );

																						// document.getElementById("ch-"+ ch.id +"-mat-label").innerHTML = "Materiale";

																					})
																					;
																				})
																		})





																}}
																disabled={ false }
																path={ JSON.stringify(ch.path) }
																value={ JSON.stringify(ch.path) }
																// checked={ !!ch.material }
															/>
															<Label
																id={"ch-"+ ch.id +"-mat-label"}
																onClick={clickEvent => {
																	clickEvent.stopPropagation();
																}}
															>
																Materiale
															</Label>
														</span>
													)
											}




											{/*
											<span>
												Tutto il sotto-albero è mensile? { !!isSubTreeYearly( ch ) ? "Si" : "No" } &nbsp;
											</span>
											*/}

											{/*
											<Select
												className={
													"react-select req-right-comander-select chapter-select-box conf-tree-single-comander " +
													Object.keys(TABS)
														.map( tabKey => TABS[tabKey] )
														.filter( tab => tab.id === this.state.activeTab )
														.reduce( getLastElementOr, { selectClassName: "" } )
														.selectClassName
												}
												classNamePrefix="react-select"
												name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id +"-timing" }
												id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id +"-timing" }
												onClick={ clickEvent => {
													clickEvent.stopPropagation();
												}}
												onChange={ changeEvent => {



													this.setState({
														...this.state,
														changeTimingPrompt: {
															node: ch,
															level: 1,
															changeEvent: changeEvent
														}
													});


												}}
												value={
													(ch.timing === 1)
														? { value: true, label: "Mensile" }
														: (
															(ch.timing === 0)
																? { value: false, label: "Annuale" }
																: null
														)
												}
												options={[
													{ value: 1, label: "Mensile" },
													{ value: 0, label: "Annuale" }
												]}

												placeholder={ <span className={"select-placeholder"}>Mensile / Annuale</span> }
											/>
											*/}

											{
												( !!hasPermission( permissions.SET_COLLECT_MANAGER ) )
													? (
														<div className={"info-gri-manager-name-label"}>
															<ActionButton
																buttonText={
																	<React.Fragment>
																		{/*<i className="mdi mdi-account" />*/}
																		<i className="mdi mdi-account tree-node-controller-text-button-icon"/>
																		{/*<span className={"info-gri-manager-name-label req-button-label"} >*/}
																		<span className={"tree-node-controller-text-button" + (!!ch.userManager ? "" : " tree-node-controller-text-button-empty")} >
																			{
																				(!!ch.userManager)
																					? ch.userManagerName
																					: translate("Select")
																			}
																		</span>
																	</React.Fragment>
																}
																color={ (!!ch.userManager) ? "success" : "primary" }
																// className={"card-action info-button req-button-label" + ((!ch.userManager) ? " btn manager-button" : " conf-chapter-man") + " conf-tree-single-comander"}
																className={"tree-node-controller-button"}
																promise={ () => {
																	return Promise.resolve( );
																}}
																onResult={ result => {
																	this.setState({
																		...this.state,
																		// getManagerListPopup: (this.state.tree.tabs.filter(t => t.open).reduce(getLastElementOr, null)).id + "-" + ch.id,
																		getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.currentTab.id).reduce(getLastElementOr, null)).id + "-" + ch.id,
																		currentUserManager: ch.userManager
																	})
																}}
																onError={ e => {

																}}
															/>
														</div>
													)
													: null
											}

										</span>





										<NavLink
											id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}
											href="#"
											onClick={ clickEvent => {


												let treeTmp = cloneObject( this.state.tree );
												let nodeTmp = getTreeNodeByPath( treeTmp, ch.path );
												nodeTmp.open = !getTreeNodeByPath( this.state.tree, ch.path ).open;
												this.setState({
													...this.state,
													tree: treeTmp
												});

											}}
										>
											<span className="float-right">
												{
													// (!!getTreeNodeByPath( this.state.tree, ch.path ).open)
													(!!ch.open)
														? <i className="dripicons-minus font-18 accordion-arrow"/>
														: <i className="mdi mdi-timeline-plus font-18 accordion-arrow"/>
												}
			                            	</span>
										</NavLink>


									{/*</h5>*/}


								{/*</NavLink>*/}
							</CardHeader>
							<Collapse isOpen={ !!ch.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[1] +"-"+ ch.id}>
								<CardBody>


									{   // informativa --------------------------------------------------------------------------------------------------------------------------------------------------------------------
										ch.infos
											.map( (info, infoIndex) => {

												if( !ch.open ) return null;

												// immagini
												let additionalRequirement_IT = info.additionalRequirement;
												if( !!info.additionalRequirement ) {

													additionalRequirement_IT =
														info.additionalRequirement
															.split(" #####")
															.map( (part, partIndex) => {
																if( partIndex > 0 ) {
																	if( part[0] !== " " ) {
																		return (
																			<React.Fragment>
																				<img src={HOST +"/assets/formula/ra/"+ info.name +""+ part.split(" ")[0] +".png"} alt={"Formula "+ part.split(" ")[0]} className={""}/>
																				<span>{ part.split(" ").filter((p,i) => i > 0).join(" ") }</span>
																			</React.Fragment>
																		);
																	}
																	else {
																		return (
																			<React.Fragment>
																				<img src={HOST +"/assets/formula/ra/"+ info.name +".png"} alt={"Formula"} className={""}/>
																				<span>{ part }</span>
																			</React.Fragment>
																		);
																	}
																}
																return (
																	<span>{ part }</span>
																);
															})
													;


												}





												return (
													<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id}>


														<CardHeader
															className={
																[
																	colorClassInfo
																	, "tree-node-header-container"
																]
																.join(" ")
															}
															id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.structureId }
														>

															<Row className={"tree-node-header"}>
																<Col sm={10} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( info ) }>


																	{ this.helperNode( info ) }
																	<strong>{ info.name }</strong>

																	{
																		(!!info.guideLines && !!info.guideLines)
																			? (
																				<React.Fragment>

																					<GuideLineIconSvgComponent
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-guidelinees"}
																						className={"svg-icon-container info-lg-svg-icon-container tooltip-icon-cursor"}
																					/>

																					<UncontrolledPopover
																						placement="right"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-guidelinees"}
																						trigger="legacy"
																						className={"info-guide-lines-popover"}
																					>
																						<PopoverHeader>{ translate("Guide_Lines") }</PopoverHeader>
																						<PopoverBody>
																							<div>{ info.guideLines }</div>
																							{ GUIDELINES_POST_IT }
																						</PopoverBody>
																					</UncontrolledPopover>

																				</React.Fragment>
																			)
																			: null
																	}

																	{
																		(!!info.reccomandation && !!info.reccomandation)
																			? (

																				<React.Fragment>
																					<ReccomendationIconSvgComponent
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-reccomandation"}
																						className={"svg-icon-container info-recc-svg-icon-container tooltip-icon-cursor"}
																					/>
																					<UncontrolledPopover
																						placement="top"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-reccomandation"}
																						trigger="legacy"
																						className={"info-guide-lines-popover"}
																					>
																						<PopoverHeader>Raccomandazioni</PopoverHeader>
																						<PopoverBody>
																							<span className="d-none d-lg-block">{ info.reccomandation }
																							{ GUIDELINES_POST_IT }</span>
																						</PopoverBody>
																					</UncontrolledPopover>
																				</React.Fragment>

																			)
																			: null
																	}

																	{
																		(!!info.additionalRequirement && !!info.additionalRequirement)
																			? (

																				<React.Fragment>
																					<AdditionalRequirements
																						id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-additional-requirements"}
																						className={"svg-icon-container info-recc-svg-icon-container tooltip-icon-cursor"}
																					/>
																					<UncontrolledPopover
																						placement="top"
																						target={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-additional-requirements"}
																						trigger="legacy"
																						className={"info-guide-lines-popover"}
																					>
																						<PopoverHeader>Requisiti Aggiuntivi</PopoverHeader>
																						<PopoverBody>
																							<span className="d-none d-lg-block">{ additionalRequirement_IT }</span>
																						</PopoverBody>
																					</UncontrolledPopover>
																				</React.Fragment>

																			)
																			: null
																	}


																	<br/>
																	{ info.description }

																</Col>
																<Col sm={2} className={"tree-node-header-commands"}>



																	{
																		(!!info.open)
																			? <i className="mdi mdi-minus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( info ) }/>
																			: <i className="mdi mdi-plus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( info ) }/>
																	}
																</Col>
															</Row>

															<Row className={"tree-node-controllers"}>
																<div className={"tree-node-controllers-group"}>
																	{
																		// (!!isInformativeApplicability(info.name))
																		(info.core !== 1 && info.core !== "1")
																			? (
																				<CustomInput
																					type="switch"
																					id={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-applicabilita"}
																					name={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-applicabilita"}
																					label="Applicabile"
																					className={"tree-node-controller"}
																					onClick={clickEvent => {

																						clickEvent.stopPropagation();

																						if (!!document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-applicabilita")) document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-applicabilita").checked = !info.applicability;
																						// clickEvent.stopPropagation();
																						// clickEvent.preventDefault();

																						this.setState({
																								...this.state,
																								isTreeInWait: true
																							},
																							() => {
																								let data = {
																									Applicability: (!!info.applicability) ? 0 : 1,
																									Materiality: (!!info.material ? 1 : 0)
																								};
																								setInfo(data, this.props.collect.id, info.id)
																									.then(result => {
																										this.refreshData();
																									})
																							}
																						)

																					}}
																					valid={!!info.applicability}
																					defaultChecked={!!info.applicability}
																					checked={!!info.applicability}
																				/>
																			)
																			: null
																	}
																</div>
																<div className={"tree-node-controllers-group"}>
																	{
																		(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																			? (
																				<div className={"tree-node-controller"}>
																					<Label className={"tree-node-controller-label"}>Manager</Label>
																					<ActionButton
																						buttonText={
																							<React.Fragment>
																								<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																								<span className={"tree-node-controller-text-button" + (!!info.userManager ? "" : " tree-node-controller-text-button-empty")} >
																					{
																						(!!info.userManager)
																							? info.userManagerName
																							: translate("Select")
																					}
																				</span>
																							</React.Fragment>
																						}
																						color={ (!!info.userManager) ? "success" : "primary" }
																						className={ "tree-node-controller-button" }
																						promise={ () => {
																							return Promise.resolve( );
																						}}
																						onResult={ result => {

																							if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																								this.setState({
																									...this.state,
																									getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.currentTab.id).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id,
																									currentUserManager: info.userManager
																								})
																							}

																						}}
																						onError={ e => {

																						}}
																					/>
																				</div>
																			)
																			: null
																	}

																	<div className={"tree-node-controller"}>
																		{/*}
																		<Label className={"tree-node-controller-label"}>Timing</Label>
																		{
																			(!!canInformativeChangeTiming(info.name))
																				? (
																					<Select
																						className={
																							[
																								colorClassInfo
																								, "react-select"
																								, "tree-node-controller-select"
																							]
																								.join(" ")
																						}
																						classNamePrefix="react-select"
																						name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-timing" }
																						id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id +"-timing" }
																						onClick={ clickEvent => {
																							// clickEvent.stopPropagation();
																							// clickEvent.preventDefault();
																						}}
																						onChange={ changeEvent => {


																							// debugger;

																							this.setState({
																								...this.state,
																								changeTimingPrompt: {
																									node: info,
																									level: 2,
																									changeEvent: changeEvent
																								}
																							});


																						}}
																						value={
																							(info.timing === 1)
																								? { value: true, label: "Mensile" }
																								: (
																									(info.timing === 0)
																										? { value: false, label: "Annuale" }
																										: null
																								)
																						}
																						options={[
																							{ value: 1, label: "Mensile" },
																							{ value: 0, label: "Annuale" }
																						]}

																						placeholder={ <span className={"select-placeholder"}>Mensile / Annuale</span> }
																					/>
																				)
																				: "Annuale"
																		}
																		*/}
																	</div>
																</div>
															</Row>


														</CardHeader>


														<Collapse isOpen={ !!info.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] +"-"+ info.id}>
															<CardBody>


																{ // requisito --------------------------------------------------------------------------------------------------------------------------------------------
																	info.requirements
																		.map( (req, reIndex) => {
																			if( !info.open ) return null;
																			return  (
																				<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>



																					<CardHeader
																						className={
																							[
																								// colore ---------------------------------------------
																								// colorClassRequirement
																								// ,
																								"requirement-color",
																								"tree-node-header-container"
																							]
																							.join(" ")
																						}
																					>
																						{/*	riga superiore --------------------------------------- */}
																						<Row className={"tree-node-header"}>
																							<Col sm={10} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( req ) }>

																								{ this.helperNode( req ) }
																								{ req.name }

																							</Col>
																							<Col sm={2} className={"tree-node-header-single-command"}>
																								{
																									(req.subReqs.length > 0)
																										? (
																											(!!req.open)
																												? <i className="mdi mdi-minus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( req ) }/>
																												: <i className="mdi mdi-plus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( req ) }/>
																										)
																										: null
																								}
																							</Col>
																						</Row>

																						{/*	riga inferiore --------------------------------------- */}
																						<Row className={"tree-node-controllers"}>
																							<div className={"tree-node-controllers-group"}>
																								{
																									(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																										? (
																											<div className={"tree-node-controller"}>
																												<Label className={"tree-node-controller-label"}>Manager</Label>
																												<ActionButton
																													buttonText={
																														<React.Fragment>
																															<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																															<span className={"tree-node-controller-text-button" + (!!req.userManager ? "" : " tree-node-controller-text-button-empty")} >
																													{
																														(!!req.userManager)
																															? req.userManagerName
																															: translate("Select")
																													}
																												</span>
																														</React.Fragment>
																													}
																													color={ (!!req.userManager) ? "success" : "primary" }
																													className={ "tree-node-controller-button" }
																													promise={ () => {
																														return Promise.resolve( );
																													}}
																													onResult={ result => {

																														if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																															this.setState({
																																...this.state,
																																getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.currentTab.id).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id +"-"+ req.id,
																																currentUserManager: req.userManager
																															})
																														}

																													}}
																													onError={ e => {

																													}}
																												/>
																											</div>
																										)
																										: null
																								}

																								<div className={"tree-node-controller"}>
																									{/*
																									<Label className={"tree-node-controller-label"}>Timing</Label>

																									{
																										(!!req.isString)
																											? "Annuale"
																											: (

																												(!!canInformativeChangeTiming(info.name))
																													? (
																														<Select
																															className={
																																[
																																	// color -------------------------------------------
																																	colorClassInfo
																																	, "react-select"
																																	, "tree-node-controller-select"
																																]
																																	.join(" ")
																															}
																															classNamePrefix="react-select"
																															name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +"-timing" }
																															id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +"-timing" }
																															onClick={ clickEvent => {
																																// clickEvent.stopPropagation();
																																// clickEvent.preventDefault();
																															}}
																															onChange={ changeEvent => {


																																// debugger;


																																this.setState({
																																	...this.state,
																																	changeTimingPrompt: {
																																		node: req,
																																		level: 3,
																																		changeEvent: changeEvent
																																	}
																																});


																															}}
																															value={
																																(req.timing === 1)
																																	? { value: true, label: "Mensile" }
																																	: (
																																		(req.timing === 0)
																																			? { value: false, label: "Annuale" }
																																			: null
																																	)
																															}
																															options={[
																																{ value: 1, label: "Mensile" },
																																{ value: 0, label: "Annuale" }
																															]}

																															placeholder={ <span className={"select-placeholder"}>Mensile / Annuale</span> }
																														/>
																													)
																													: "Annuale"
																											)
																									}

																									*/}

																								</div>
																							</div>
																							<div className={"tree-node-controllers-group"}>

																								{
																									(!!req.evaluate)
																										? (
																											<div className={"tree-node-controller"}>
																												{
																													!!!req.isString
																														? <Label className={"tree-node-controller-label"}>Unita di Misura</Label>
																														: null
																												}
																												{
																													(req.measureUnitList.length > 1)
																														? (
																															<Select
																																className={
																																	[
																																		colorClassInfo
																																		, "react-select"
																																		, "tree-node-controller-select"
																																	]
																																		.join(" ")
																																}
																																classNamePrefix="react-select"
																																name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +"-measureUnit" }
																																id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +"-measureUnit" }
																																onChange={ changeEvent => {

																																	let treeTmp = cloneObject( this.state.tree );
																																	let nodeTmp = getTreeNodeByPath( treeTmp, req.path );
																																	nodeTmp.measureUnit.unitMeasurementId =
																																		req.measureUnitList
																																			.filter( mu => mu.id === changeEvent.value )
																																			.map( mu => (mu.id))
																																			.reduce( getLastElementOr, 0 )
																																	;


																																	saveMeasureUnit(
																																		req.measureUnitList
																																			.filter( mu => mu.id === changeEvent.value )
																																			.map( mu => (mu.id))
																																			.reduce( getLastElementOr, 0 ),
																																		this.props.collect.id,
																																		3,
																																		req.id
																																	)
																																		.then( () => {
																																			this.setState(
																																				{
																																					...this.state,
																																					tree: treeTmp
																																				},
																																				() => {
																																					showlog("unita di misura di "+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id +" salvata in remoto e in locale");

																																					this.props.onTreeUpdate( treeTmp );


																																				}
																																			);
																																		})
																																		.catch(e => {
																																			errorlog("errore", e);
																																		})
																																	;

																																}}
																																options={
																																	(!!isNotEmptyArray(req.measureUnitList))
																																		? (
																																			req.measureUnitList
																																				.map( mu => ({value: mu.id, label: mu.name }))
																																		)
																																		: null
																																}
																																value={
																																	(!!isNotEmptyArray( req.measureUnitList ))
																																		? (
																																			req.measureUnitList
																																				.filter( mu => mu.id === req.measureUnit.unitMeasurementId )
																																				.map( mu => ({value: mu.id, label: mu.name }))
																																				.reduce( getLastElementOr, null )
																																		)
																																		: null
																																}
																																placeholder={ <span className={"select-placeholder"}>Unita di misura</span> }
																															/>
																														)
																														: (

																															( !!req.isString )
																																? null
																																: (
																																	<span className={"single-um-label sub-node-element"}>{
																																		req.measureUnitList
																																			.map( mu => mu.name )
																																			.join("")
																																	}</span>
																																)


																														)
																												}
																											</div>
																										)
																										: null
																								}

																							</div>
																						</Row>


																					</CardHeader>


																					{ // sotto-requisito ------------------------------------------------------------------------------------------------------------------
																						(req.subReqs.length > 0)
																							? (
																								// <UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																								<Collapse isOpen={ !!req.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[3] +"-"+ req.id}>
																									<CardBody>

																										{
																											req.subReqs
																												.map( sub => {
																													if( !sub.open ) return null;
																													return  (
																														<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>



																															<CardHeader
																																className={
																																	[
																																		// colore ---------------------------------------------
																																		// colorClassRequirement
																																		// ,
																																		"tree-node-header-container"
																																	]
																																		.join(" ")
																																}
																															>
																																<Row className={"tree-node-header"}>
																																	<Col sm={10} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( sub ) }>

																																		{ this.helperNode( sub ) }
																																		{ sub.name }

																																	</Col>
																																	<Col sm={2} className={"tree-node-header-single-command"}>
																																		{
																																			(sub.subSubReqs.length > 0)
																																				? (
																																					(!!sub.open)
																																						? <i className="mdi mdi-minus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( sub ) }/>
																																						: <i className="mdi mdi-plus-box-multiple font-18" onClick={ clickEvent => this.toggleNode( sub ) }/>
																																				)
																																				: null
																																		}
																																	</Col>
																																</Row>

																																<Row className={"tree-node-controllers"}>
																																	<div className={"tree-node-controllers-group"}>

																																		{/*
																																			(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																																				? (
																																					<div className={"tree-node-controller"}>
																																						<Label className={"tree-node-controller-label"}>Manager</Label>
																																						<ActionButton
																																							buttonText={
																																								<React.Fragment>
																																									<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																																									<span className={"tree-node-controller-text-button" + (!!sub.userManager ? "" : " tree-node-controller-text-button-empty")} >
																																										{
																																											(!!sub.userManager)
																																												? sub.userManagerName
																																												: "Seleziona"
																																										}
																																									</span>
																																								</React.Fragment>
																																							}
																																							color={ (!!sub.userManager) ? "success" : "primary" }
																																							className={ "tree-node-controller-button" }
																																							promise={ () => {
																																								return Promise.resolve( );
																																							}}
																																							onResult={ result => {

																																								if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																									this.setState({
																																										...this.state,
																																										getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.currentTab.id).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id +"-"+ req.id + "-" + sub.id,
																																										currentUserManager: sub.userManager
																																									})
																																								}

																																							}}
																																							onError={ e => {

																																							}}
																																						/>
																																					</div>

																																				)
																																				: null
																																		*/}


																																		<div className={"tree-node-controller"}>
																																			{/*
																																			<Label className={"tree-node-controller-label"}>Timing</Label>

																																			{
																																				(!!sub.isString)
																																					? "Annuale"
																																					: (

																																						// (!!canInformativeChangeTiming(info.name))
																																						// 	? (
																																								<Select
																																									className={
																																										[
																																											// color -------------------------------------------
																																											colorClassInfo
																																											, "react-select"
																																											, "tree-node-controller-select"
																																										]
																																										.join(" ")
																																									}
																																									classNamePrefix="react-select"
																																									name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id +"-timing" }
																																									id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id +"-timing" }
																																									onClick={ clickEvent => {
																																										// clickEvent.stopPropagation();
																																										// clickEvent.preventDefault();
																																									}}
																																									onChange={ changeEvent => {



																																										this.setState({
																																											...this.state,
																																											changeTimingPrompt: {
																																												node: sub,
																																												level: 4,
																																												changeEvent: changeEvent
																																											}
																																										});

																																									}}
																																									value={
																																										(sub.timing === 1)
																																											? { value: true, label: "Mensile" }
																																											: (
																																												(sub.timing === 0)
																																													? { value: false, label: "Annuale" }
																																													: null
																																											)
																																									}
																																									options={[
																																										{ value: 1, label: "Mensile" },
																																										{ value: 0, label: "Annuale" }
																																									]}

																																									placeholder={ <span className={"select-placeholder"}>Mensile / Annuale</span> }
																																								/>
																																							// )
																																							// : "Annuale"
																																					)
																																			}
																																			*/}

																																		</div>
																																	</div>
																																	<div className={"tree-node-controllers-group"}>


																																		{
																																			(!!sub.evaluate)
																																				? (
																																					<div className={"tree-node-controller"}>
																																						{
																																							!!!sub.isString
																																								? <Label className={"tree-node-controller-label"}>Unita di Misura</Label>
																																								: null
																																						}

																																						{
																																							(sub.measureUnitList.length > 1)
																																								? (
																																									<Select
																																										className={
																																											[
																																												colorClassInfo
																																												, "react-select"
																																												, "tree-node-controller-select"
																																											]
																																												.join(" ")
																																										}
																																										classNamePrefix="react-select"
																																										name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id +"-measureUnit" }
																																										id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id +"-measureUnit" }
																																										onChange={ changeEvent => {

																																											let treeTmp = cloneObject( this.state.tree );
																																											let nodeTmp = getTreeNodeByPath( treeTmp, sub.path );
																																											nodeTmp.measureUnit.unitMeasurementId =
																																												sub.measureUnitList
																																													.filter( mu => mu.id === changeEvent.value )
																																													.map( mu => (mu.id))
																																													.reduce( getLastElementOr, 0 )
																																											;


																																											saveMeasureUnit(
																																												sub.measureUnitList
																																													.filter( mu => mu.id === changeEvent.value )
																																													.map( mu => (mu.id))
																																													.reduce( getLastElementOr, 0 ),
																																												this.props.collect.id,
																																												4,
																																												sub.id
																																											)
																																												.then( () => {
																																													this.setState(
																																														{
																																															...this.state,
																																															tree: treeTmp
																																														},
																																														() => {
																																															showlog("unita di misura di "+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id +" salvata in remoto e in locale");

																																															this.props.onTreeUpdate( treeTmp );


																																														}
																																													);
																																												})
																																												.catch(e => {
																																													errorlog("errore", e);
																																												})
																																											;

																																										}}
																																										options={
																																											(!!isNotEmptyArray(sub.measureUnitList))
																																												? (
																																													sub.measureUnitList
																																														.map( mu => ({value: mu.id, label: mu.name }))
																																												)
																																												: null
																																										}
																																										value={
																																											(!!isNotEmptyArray(sub.measureUnitList))
																																												? (
																																													sub.measureUnitList
																																														.filter( mu => mu.id === sub.measureUnit.unitMeasurementId )
																																														.map( mu => ({value: mu.id, label: mu.name }))
																																														.reduce( getLastElementOr, null )
																																												)
																																												: null
																																										}
																																										placeholder={ <span className={"select-placeholder"}>Unita di misura</span> }
																																									/>
																																								)
																																								: (

																																									( !!sub.isString )
																																										? null
																																										: (
																																											<span className={"single-um-label sub-node-element"}>{
																																												sub.measureUnitList
																																													.map( mu => mu.name )
																																													.join("")
																																											}</span>
																																										)


																																								)
																																						}

																																					</div>
																																				)
																																				: null
																																		}



																																	</div>
																																</Row>

																															</CardHeader>


																															{
																																(sub.subSubReqs.length > 0)
																																	? (
																																		// <UncontrolledCollapse toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																																		<Collapse isOpen={ !!sub.open } toggler={"#"+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[4] +"-"+ sub.id}>
																																			<CardBody>

																																				{
																																					(sub.subSubReqs.length > 0 && !!sub.open)
																																						? (
																																							sub.subSubReqs
																																								.map(subSub => {
																																									return (
																																										<Card className="mb-0" key={GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-" + subSub.id}>


																																											<CardHeader
																																												className={
																																													[
																																														// colore ---------------------------------------------
																																														// colorClassRequirement
																																														// ,
																																														"tree-node-header-container"
																																													]
																																														.join(" ")
																																												}
																																											>
																																												<Row className={"tree-node-header"}>
																																													<Col sm={12} className={"tree-node-header-body"} onClick={ clickEvent => this.toggleNode( subSub ) }>

																																														{ this.helperNode( subSub ) }
																																														{ subSub.name }

																																													</Col>

																																												</Row>

																																												<Row className={"tree-node-controllers"}>
																																													<div className={"tree-node-controllers-group"}>

																																														{/*
																																															(!!hasPermission(permissions.SET_COLLECT_MANAGER))
																																																? (
																																																	<div className={"tree-node-controller"}>
																																																		<Label className={"tree-node-controller-label"}>Manager</Label>
																																																		<ActionButton
																																																			buttonText={
																																																				<React.Fragment>
																																																					<i className="mdi mdi-account tree-node-controller-text-button-icon" />
																																																					<span className={"tree-node-controller-text-button" + (!!subSub.userManager ? "" : " tree-node-controller-text-button-empty")} >
																																																			{
																																																				(!!subSub.userManager)
																																																					? subSub.userManagerName
																																																					: "Seleziona"
																																																			}
																																																		</span>
																																																				</React.Fragment>
																																																			}
																																																			color={ (!!subSub.userManager) ? "success" : "primary" }
																																																			className={ "tree-node-controller-button" }
																																																			promise={ () => {
																																																				return Promise.resolve( );
																																																			}}
																																																			onResult={ result => {

																																																				if( !!hasPermission( permissions.SET_COLLECT_MANAGER ) ) {
																																																					this.setState({
																																																						...this.state,
																																																						getManagerListPopup: (this.state.tree.tabs.filter(t => t.id === this.state.currentTab.id).reduce(getLastElementOr, null)).id + "-" + ch.id + "-" + info.id +"-"+ req.id + "-" + sub.id + "-" + subSub.id,
																																																						currentUserManager: subSub.userManager
																																																					})
																																																				}

																																																			}}
																																																			onError={ e => {

																																																			}}
																																																		/>
																																																	</div>
																																																)
																																																: null
																																														*/}



																																														<div className={"tree-node-controller"}>
																																															{/*
																																															<Label className={"tree-node-controller-label"}>Timing</Label>

																																															{
																																																(!!subSub.isString)
																																																	? "Annuale"
																																																	: (

																																																		(!!canInformativeChangeTiming(info.name))
																																																			? (
																																																				<Select
																																																					className={
																																																						[
																																																							// color -------------------------------------------
																																																							colorClassInfo
																																																							, "react-select"
																																																							, "tree-node-controller-select"
																																																						]
																																																							.join(" ")
																																																					}
																																																					classNamePrefix="react-select"
																																																					name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id +"-timing" }
																																																					id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id +"-timing" }
																																																					onClick={ clickEvent => {
																																																						// clickEvent.stopPropagation();
																																																						// clickEvent.preventDefault();
																																																					}}
																																																					onChange={ changeEvent => {


																																																						this.setState({
																																																							...this.state,
																																																							changeTimingPrompt: {
																																																								node: subSub,
																																																								level: 5,
																																																								changeEvent: changeEvent
																																																							}
																																																						});

																																																					}}
																																																					value={
																																																						(subSub.timing === 1)
																																																							? { value: true, label: "Mensile" }
																																																							: (
																																																								(subSub.timing === 0)
																																																									? { value: false, label: "Annuale" }
																																																									: null
																																																							)
																																																					}
																																																					options={[
																																																						{ value: 1, label: "Mensile" },
																																																						{ value: 0, label: "Annuale" }
																																																					]}

																																																					placeholder={ <span className={"select-placeholder"}>Mensile / Annuale</span> }
																																																				/>
																																																			)
																																																			: "Annuale"
																																																	)
																																															}
																																															*/}

																																														</div>
																																													</div>
																																													<div className={"tree-node-controllers-group"}>


																																														{
																																															(!!subSub.evaluate)
																																																? (
																																																	<div className={"tree-node-controller"}>
																																																		{
																																																			!!!subSub.isString
																																																				? <Label className={"tree-node-controller-label"}>Unita di Misura</Label>
																																																				: null
																																																		}

																																																		{
																																																			(subSub.measureUnitList.length > 1)
																																																				? (
																																																					<Select
																																																						className={
																																																							[
																																																								colorClassInfo
																																																								, "react-select"
																																																								, "tree-node-controller-select"
																																																							]
																																																								.join(" ")
																																																						}
																																																						classNamePrefix="react-select"
																																																						name={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id +"-measureUnit" }
																																																						id={ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id +"-measureUnit" }
																																																						onChange={ changeEvent => {

																																																							let treeTmp = cloneObject( this.state.tree );
																																																							let nodeTmp = getTreeNodeByPath( treeTmp, subSub.path );
																																																							nodeTmp.measureUnit.unitMeasurementId =
																																																								subSub.measureUnitList
																																																									.filter( mu => mu.id === changeEvent.value )
																																																									.map( mu => (mu.id))
																																																									.reduce( getLastElementOr, 0 )
																																																							;


																																																							saveMeasureUnit(
																																																								subSub.measureUnitList
																																																									.filter( mu => mu.id === changeEvent.value )
																																																									.map( mu => (mu.id))
																																																									.reduce( getLastElementOr, 0 ),
																																																								this.props.collect.id,
																																																								5,
																																																								subSub.id
																																																							)
																																																								.then( () => {
																																																									this.setState(
																																																										{
																																																											...this.state,
																																																											tree: treeTmp
																																																										},
																																																										() => {
																																																											showlog("unita di misura di "+ GRI_ADMIN_TREE_DEEP_LEVELS_NAME[5] +"-"+ subSub.id +" salvata in remoto e in locale");

																																																											this.props.onTreeUpdate( treeTmp );


																																																										}
																																																									);
																																																								})
																																																								.catch(e => {
																																																									errorlog("errore", e);
																																																								})
																																																							;

																																																						}}
																																																						options={
																																																							(!!isNotEmptyArray(subSub.measureUnitList))
																																																								? (
																																																									subSub.measureUnitList
																																																										.map( mu => ({value: mu.id, label: mu.name }))
																																																								)
																																																								: null
																																																						}
																																																						value={
																																																							(!!isNotEmptyArray(subSub.measureUnitList))
																																																								? (
																																																									subSub.measureUnitList
																																																										.filter( mu => mu.id === subSub.measureUnit.unitMeasurementId )
																																																										.map( mu => ({value: mu.id, label: mu.name }))
																																																										.reduce( getLastElementOr, null )
																																																								)
																																																								: null

																																																						}
																																																						placeholder={ <span className={"select-placeholder"}>Unita di misura</span> }
																																																					/>
																																																				)
																																																				: (

																																																					( !!subSub.isString )
																																																						? null
																																																						: (
																																																							<span className={"single-um-label subSub-node-element"}>{
																																																								subSub.measureUnitList
																																																									.map( mu => mu.name )
																																																									.join("")
																																																							}</span>
																																																						)


																																																				)
																																																		}

																																																	</div>
																																																)
																																																: null
																																														}


																																													</div>
																																												</Row>


																																											</CardHeader>


																																										</Card>
																																									);
																																								})
																																						)
																																						: null
																																				}


																																			</CardBody>
																																		</Collapse>
																																	)
																																	: null
																															}

																														</Card>
																													);
																												})
																										}



																									</CardBody>
																								</Collapse>
																							)
																							: null
																					}


																				</Card>
																			);
																		})
																}



															</CardBody>
														</Collapse>
													</Card>
												);
											})
									}


								</CardBody>
							</Collapse>
						</Card>
					);
				})


		);

	}



	refreshData( others ) {

		loadDatalog("confTree", { color: "#ffffaa", contrastColor: "#222222" });

		let startTime = (new Date()).getTime();
		return (
			new Promise((success, fail) => {

				loadGriStructure( this.props.collect.id, this.state.currentTab.id )
					.then(resultObj => {

						// showlog( exportSeedTableToCSV( resultObj ) );
						// debugger;
						let now = (new Date()).getTime();

						convertTableToTree( resultObj )
							.then( tree => {
								this.setState({
									...this.state,
									tree:
										{
											tabs:
												[
													...Object.keys(TABS)
														.map( key => TABS[key] )
														.filter( t => t.id !== this.state.currentTab.id ),
													...cloneOpenNodes(this.state.tree, tree).tabs
												]
										},
									treeSeed: resultObj,
									getManagerListPopup: null,
									// inWaiting: false,
									// isSwitchingTab: false,
									// isTreeInWait: false,
									isRefresh: true,

									inWaiting: false,
									isSwitchingTab: false,
									isTreeInWait: false,
									umModal: null,

									...others
								},
									() => {
										treeLog({
											tabs:
												[
													...Object.keys(TABS)
														.map( key => TABS[key] )
														.filter( t => t.id !== this.state.currentTab.id ),
													...cloneOpenNodes(this.state.tree, tree).tabs
												]
										});


										if( typeof this.props.componentReady === "function" ) this.props.componentReady();

										showlog("%ctimer processo: "+ ( (new Date()).getTime() - now ) +"ms", "font-size:2.5em;");
										showlog("%ctimer completo: "+ ( ((new Date()).getTime() - startTime ) / 1000) +" sec", "font-size:2.5em; color:#0af;");

										success({
											tabs:
												[
													...Object.keys(TABS)
														.map( key => TABS[key] )
														.filter( t => t.id !== this.state.currentTab.id ),
													...cloneOpenNodes(this.state.tree, tree).tabs
												]
										});

									}
								);

							})
							.catch(e => {
								errorlog("errore", e);
								fail( e );
							})
						;

					})
					.catch(e => {
						errorlog("errore", e);
						fail( e );
					})

			})
		)



	}



	componentDidMount() {


		mountedlog(
			"configuration TREE | did mount",
			this.state,
			this.props,
			{ color: "#afff00", contrastColor: "#222222" }
		);



		let params =
			(!!this.props && !!this.props.location && !!this.props.location.search)
				? (
					JSON.parse(
						("{"+ this.props.location.search +"}")
							.split("?").join("")
							.split("&").join(",")
							.split("=").join(":")

							.split(",").join("\",\"")
							.split(":").join("\":\"")
							.split("{").join("{\"")
							.split("}").join("\"}")
					)
				)
				: { section: TREE_SECTION.DATA_ENTRY, area: TABS.general.id }
		;


		// introduciamo un ritardo a causa dei tanti dati
		setTimeout(
			function() {
				this.setState({
					...this.state,
					inWait: false,
					fullInitTree: this.props.tree,
					tree:
						{
							tabs:
								this.state.tree
									.tabs
									.map( tab => {
										if( tab.id !== this.state.currentTab.id ) {
											tab.chapters = [];
										}
										return tab;
									})
						},
					params: params,
					activeTab: (parseInt(params.area || 1, 10) || 1),
					currentTab:
						Object.keys(TABS)
							.map( tabKey => TABS[tabKey] )
							.filter( t => t.id === parseInt(params.area || 1, 10) )
							.reduce( getLastElementOr, TABS.general )
				})
			}.bind( this ),
			500
		)


	}



	componentDidUpdate(prevProps, prevState, snapshot) {


		showlog("TreeGriCollectComponent update");
		// debugger;

		// aggiornamento degli stati indeterminate nelle checkbox
		Promise.resolve()

		.then( () => {

			let appCheckboxList = document.getElementsByClassName("conftree-high-level-comander-checkbox");
			if( !!appCheckboxList ) {

				for( let checkBox of appCheckboxList ) {

					// presa del nodo corrispondente
					// showlog("controllo nodi per le checkBox");
					// showlog( checkBox );

					if( !!checkBox.value ) {

						let node = getTreeNodeByPath( this.state.tree, JSON.parse(checkBox.value) );
						// showlog( node );
						// showlog( ((""+ checkBox.id).trim().toLowerCase().split("-")/*.filter( onlyFirst )*/.reduce( getLastElementOr, 0 )) );

						if( !!node ) {
							checkBox.indeterminate = false;
							switch ((""+ checkBox.id).trim().toLowerCase().split("-").reduce( getLastElementOr, 0 )) {
								case "app":
									// showlog("controllo applicability", (node.path || null), node.applicability, node);
									// showlog("controllo material", node.material, node);
									checkBox.checked = !!node.applicability;
									if( node.applicability === 2 || node.applicability === "2" ) {
										checkBox.indeterminate = true;
									}
									if( !!document.getElementById(checkBox.id +"-label") ) {

										//showlog("document.getElementById(checkBox.id +\"-label\").innerHTML", document.getElementById(checkBox.id+"-label").outerHTML.match('<label id="tab') )
										if(!!  document.getElementById(checkBox.id+"-label").outerHTML.match('<label id="tab'))
										{
											document.getElementById(checkBox.id +"-label").innerHTML = "Rendi applicabile tutta la macroarea";
										}else{
											document.getElementById(checkBox.id +"-label").innerHTML = translate("Make the whole subject applicable")
										}
										// this.setState({
										// 	...this.state,
										// 	isTreeInWait: false
										// })
									}
									break;
								case "mat":
									checkBox.checked = !!node.material;
									// if( node.material === undefined || node.material === null || typeof node.material === "undefined" ) {
									if( node.material === 2 || node.material === "2" ) {
										checkBox.indeterminate = true;
									}
									if( !!document.getElementById(checkBox.id +"-label") ) {

										//showlog("document.getElementById(checkBox.id +\"-label\").innerHTML", document.getElementById(checkBox.id+"-label").outerHTML.match('<label id="tab') )
										if(!!  document.getElementById(checkBox.id+"-label").outerHTML.match('<label id="tab'))
										{
											document.getElementById(checkBox.id +"-label").innerHTML = "Rendi materiale la macroarea";
										}else{
											document.getElementById(checkBox.id +"-label").innerHTML = translate("Make the subject matter material");
										}
										// this.setState({
										// 	...this.state,
										// 	isTreeInWait: false
										// })
									}
									break;
							}
						}


					}

				};

			}

		})
		.then( () => {

			let infoList = getAllTreeInfo( this.state.tree );
			infoList
				.forEach( info => {
					if (!!document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-applicabilita")) {
						document.getElementById(GRI_ADMIN_TREE_DEEP_LEVELS_NAME[2] + "-" + info.id + "-applicabilita").checked = !!(info.applicability === 1 || info.applicability === "1");
					}
				})
			;

			// debugger;


			// controllo tab attivo
			if(
				this.state.tree.tabs
					.filter( t => t.id === this.state.activeTab )
					.reduce( getLastElementOr, { chapters: [] } )
					.chapters
					.length
						=== 0
			) {

				if(
					!!!this.state.tree.tabs
						.filter( t => t.chapters.length > 0 )
						.reduce( getLastElementOr, null )
				) {
					/*
					showlog("update ");
					showlog("nel caso in cui il tab è vuoto ( senza capitoli )");
					*/
					// debugger;
					this.refreshData();
				}
				else {
					this.setState({
						...this.state,
						activeTab:
							this.state.tree.tabs
								.filter( t => t.chapters.length > 0 )
								.reduce( getLastElementOr, { id: 1 } )
								.id
					},
						() => {
							// if( typeof this.props.componentReady === "function" ) this.props.componentReady();
						}
					)
				}


			}



			// controllo props.tree con state.tree
			let fingerprintIsEgual = (getTreeFingerPrint( this.props.tree ) !== getTreeFingerPrint( this.state.tree ));
			let isTreeToUpdate = ( !!fingerprintIsEgual && !this.state.isRefresh );

			showlog("state.tree:\t%c"+ getTreeFingerPrint( this.state.tree ), "color: #ff0;");
			showlog("props.tree:\t%c"+ getTreeFingerPrint( this.props.tree ), `color: #${ !!fingerprintIsEgual ? "f53" : ( !!isTreeToUpdate ? "5f3" : "38f" ) };`);

			if( !!isTreeToUpdate ) {
				// showlog("%cTree is to update", "color:#13f;");
				this.setState({
					...this.state,
					tree: this.props.tree
				});
			}


		})


	}

}



export default withRouter(TreeGriCollectComponent);
