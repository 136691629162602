import React, {Component} from "react";
import {errorlog, renderlog} from "../../../utils";
import {COLOR_POLICY} from "../../../conf/color-policy";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ConfirmDeleteCompany} from "../../../business-logic/companies";
import ActionButton from "../../../components/save-button";

export default class CompanyDeleteModal extends Component {

    constructor( props ) {
        super( props );

        this.state = {

        }

        this.toggle = this.toggle.bind( this );
    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }

    componentDidMount(){

    }

    render() {


        renderlog("CompanyDeleteModal", this.state, this.props, COLOR_POLICY.validated);

        let content = (



            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    {"Elimina Azienda"}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {"Confermi di voler eliminare l'azienda"} {this.props.companyRagioneSociale + " ?"}

                </ModalBody>
                <ModalFooter className={this.state.bodyClass}>

                    <ActionButton
                        buttonText={"Conferma"}

                        promise={() => ConfirmDeleteCompany(this.props.companyId) }
                        onResult={() => {
                            this.props.onClose("refresh")
                        }}
                        onError={err => {
                            errorlog("Errore eliminazione azienda",err.error.message );
                            this.props.onClose(err.error.message)
                        }}
                    />

                    <ActionButton
                        buttonText={"Annulla"}

                        promise={() => Promise.resolve() }
                        onResult={() => {

                            this.props.onClose()

                        }}
                    />

                </ModalFooter>
            </Modal>
        )

        return content
    }
}