import {getGriValues} from "../services/dashboard-service";
import {errorlog, showlog} from "../utils/dev-utils";
import React from "react";
import {getDashboardInit, RequirementValues} from "../services/request-manager";

export const loadDataChart = par => {

	return (
		getGriValues( par )
			.then( entities => {
				// processing DTO ...

				return entities;
			})
			.catch(e => {
				errorlog("errore in loadDataChart", e);

				// uno dei due dipende come si vuole gestire
				// l'eccezione
				return Promise.reject( e );
				// return [];
			})
	);

}



const _extractFromSvgChart = (svgArea) => {

	return (
		new Promise((success, fail) => {

			let width = Math.floor( svgArea.getBoundingClientRect().width );
			let height = Math.floor( svgArea.getBoundingClientRect().height );
			showlog( svgArea, width, height );

			let outerHTML = svgArea.outerHTML;
			let blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
			let URL = window.URL || window.webkitURL || window;
			let blobURL = URL.createObjectURL(blob);

			let image = new Image( width, height );
			image.crossOrigin = "Anonymous";
			image.origin = 'Anonymous';
			image.onload = () => {
				let canvas = document.createElement('canvas');
				canvas.widht = width;
				canvas.height = height;
				canvas.style.cssText = `width: ${ width }px; height: ${ height }px;`;
				canvas.setAttribute("width", width)
				canvas.setAttribute("height", height)
				let context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, width, height );
				let png = canvas.toDataURL();

				success({
					base64: png,
					blob: blob,
					blobURL: blobURL
				})

			};
			image.src = blobURL;

		})
	);




}


const extractFromSvgChart = (svgArea) => {

	return (
		new Promise((success, fail) => {

			let width = Math.floor( svgArea.getBoundingClientRect().width );
			let height = Math.floor( svgArea.getBoundingClientRect().height );
			showlog( svgArea, width, height );

			let outerHTML = svgArea.outerHTML;
			let imageSrc = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(outerHTML);
			let blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
			let URL = window.URL || window.webkitURL || window;
			let blobURL = URL.createObjectURL(blob);

			let image = new Image( width, height );
			image.crossOrigin = "anonymous";
			image.origin = 'anonymous';
			image.onload = () => {
				let canvas = document.createElement('canvas');
				canvas.widht = width;
				canvas.height = height;
				canvas.style.cssText = `width: ${ width }px; height: ${ height }px;`;
				canvas.setAttribute("width", width)
				canvas.setAttribute("height", height)
				let context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, width, height );
				let png = canvas.toDataURL();

				success({
					base64: png,
					blob: blob,
					blobURL: blobURL
				})

			};
			image.src = imageSrc;

		})
	);




}


export const extractDataImageFromSvgChart = svgArea => {
	return (
		extractFromSvgChart( svgArea )
			.then(({blob}) => {
				return blob;
			})
	);
}

export const extractBase64ImageFromSvgChart = svgArea => {
	return (
		extractFromSvgChart( svgArea )
			.then(({base64}) => {
				return base64;
			})
	);
}

export const extractBlobURLImageFromSvgChart = svgArea => {
	return (
		extractFromSvgChart( svgArea )
			.then(({blobURL}) => {
				return blobURL;
			})
	);
}

export const extractHtmlImageFromSvgChart = svgArea => {
	extractFromSvgChart( svgArea )
		.then(({base64}) => {
			return (<img src={ base64 } alt={"imageFromSVG"}/>);
		})

}



export const getSRToolChartData = (thematic_id, chapter_id, informative_id, requirement_id, subrequirement, subsubrequirement, type, id, timing_select, graphType_select, collectionCompare_select, chartId, collectId) => {

	return (
		RequirementValues(thematic_id, chapter_id, informative_id, requirement_id, subrequirement, subsubrequirement, type, id, timing_select, graphType_select, collectionCompare_select, chartId, collectId)
			.then( result => {
				// debugger;
				return result;
			})
	);
}




export const loadDashboardInit = () => {
	return getDashboardInit()
}
