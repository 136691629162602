import React, {Component} from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import Spinner from '../../Spinner';
import {renderlog} from "../../../utils/dev-utils";
import {COLOR_POLICY} from "../../../env";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
// import { Score_Get } from "../../../../src/services/request-manager";
import {getSdgScore} from "../../../business-logic/score";
import {translate} from "../../i18n/translate-function";


const fieldSdgMap = {
    // questionId: "id",
    thematic_name: "Tematica/Sottotematica",
    subthematic_name: "Sottotematica",
    sumScore: "Punteggio Ottenuto",
    maxScore: "Punteggio Massimo",
    // materiality: "Livello di Materialità",
    percentage: "%",
    sdg: "SDGs",
    name: "SDGs"
};

const defaultSorted = [
    {
        dataField: 'questionId',
        order: 'asc',
    },
];











const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size}
        </span>
    ),
    sizePerPageList: [5]
};


const sortableSdgFields = [
    "name"
];


const hiddenSdgFields = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore", "questionId", "id", "scoreMax", "thematic_id", "subthematic_id", "materiality","macroarea"
];


const hiddenSdgFields2 = [
    "sdg_id", "score", "sdg_name", "sdg_id", "maxScore", "questionId", "id", "scoreMax", "thematic_id", "subthematic_id", "materiality","macroarea"
];




export default class ScoreModal extends Component {


    importData = () => {




        //return Score_Get(this.props.evaluation_id, 0).then(r => {
        return (
            getSdgScore(this.props.evaluation_id)
                .then(r => {

                    console.log("rrrrrrrrrrrrrrrrr",r);
                    // debugger;
                    return (r.data || r);
                    // return r;

                })

                .catch(e => {
                    console.log("errore");
                    console.log(e);
                })

        );


        //return data;
        // return (new Promise(function(resolve, reject) {
        //     if (data!=null) {
        //         resolve(data);
        //     } else {
        //         reject("");
        //     }
        // })




    }






    loadData() {

        this.importData()


            .then( list => {
                // debugger;
                return (
                    list
                        .sort((x, y) => {
                            if( x.questionId < y.questionId ) return -1;
                            return 1;
                        })
                        .sort((x, y) => {
                            if( x.id < y.id ) return -1;
                            return 1;
                        })
                );
            })

            .then(data => {

                this.setState({
                    data: data
                })

            });


    }


    state = {
        colorEnv:"#6c757d",
        colorSoc:"#6c757d",
        colorGov:"#6c757d",

    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }



    render() {

        renderlog("SDGScoreModal", this.state, this.props, COLOR_POLICY.validated);

        let context = (
            <React.Fragment>
                <Spinner className="spinner-border-sm" />
                <span className={"loading-text-alignment"}>{ translate("Loading_") }</span>
            </React.Fragment>
        );



        if (!!this.state.data) {
            context = (

                <React.Fragment>




                    <ToolkitProvider
                        bootstrap4
                        keyField="questionId"
                        search
                    >
                        {props => (

                            <React.Fragment style={{ alignContent: 'center' }}>

                {/* <span id={'pippo'} className={styles.pippo2} >ciao</span> */}
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    {...props.baseProps}
                                    size="sm"
                                    responsive
                                    keyField="questionId"
                                    bordered={false}
                                    data={
                                        this.state.data
                                            .map( d => {
                                                if( (d.percentage === -1) || (d.percentage === '-1') ) d.percentage = 'NA';
                                                return d;
                                            })
                                    }
                                    columns={formatSdgColumns(Object.keys(this.state.data[0]))}
                                    defaultSorted={defaultSorted}
                                    //pagination={paginationFactory(paginationOptions)}
                                    wrapperClasses="table-responsive"

                                />

                            </React.Fragment>

                        )}

                    </ToolkitProvider>

                </React.Fragment>
            );
        }




        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>{ translate("sgd_modal_title") }</ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }







}


const formatSdgColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields.includes(ff.dataField))
    );
}

const formatSdgColumns2 = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields2.includes(ff.dataField))
    );
}

const { SearchBar } = Search;

