import React, {Component} from 'react';
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {
	Button,
	Col,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	PopoverBody,
	PopoverHeader,
	Row,
	UncontrolledPopover
} from "reactstrap";
import "../modal.css";
import {
	loadHundredThreeReasons,
	loadHundredValues,
	saveChapterHundredThree,
	saveInfoHundredThree
} from "../../../business-logic/gri";
import Select from 'react-select';
import {HOST, PORT} from "../../../env";
import {getLastElementOr, onlyFirst} from "../../../utils/array-utils";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {errorlog, renderlog, showlog} from "../../../utils";
import ActionButton from "../../save-button";
import {translate} from "../../i18n/translate-function";

export default class GriInfoHundredThreeModal extends Component {
	state = {
		reasons: [],
		errors: {},
		inWait: true
	}

	constructor( props ) {
		super( props );
		this.toggle         = this.toggle.bind( this );
		this.loadData       = this.loadData.bind( this );
	}

	toggle = mustRefresh => {
		this.props.onClose( mustRefresh );
	}

	render() {

		renderlog(
			"GriInfoHundredThreeModal",
			this.state,
			this.props
		);



		let context = (

			<React.Fragment>

			{
				!!this.state.inWait
					? (
						<WaitingOrNodataMessage
							waiting={ true }
							waitMessage={" "}
							className={"single-circle-waiting"}
						/>
					)
					: null
			}

			<AvForm>

				{/*       -------------------------------------------------------------------------------- */}
				<div>
					<AvGroup>
						<Label className="d-block" for="ht-1-value">
							103-1<br/>Spiegazione del tema materiale e del relativo perimetro
							<i
								id={"info-103-1"}
								className={"dripicons-information clickable-info"}
								onClick={ clickEvent => {

								}}
							/>
							<UncontrolledPopover
								placement="right"
								target={"info-103-1"}
								trigger="legacy"
								className={"info-guide-lines-popover"}
							>
								<PopoverHeader>Info</PopoverHeader>
								<PopoverBody>
									<span className={"ul"}>
										<span className={"li"}><strong>a</strong>. la spiegazione dei motivi per i quali un tema è materiale;</span>
										<span className={"li"}><strong>b</strong>. il perimetro del tema materiale, che includa una descrizione:</span>
										<span className={"li-li"}><strong>i</strong>. di dove si verificano gli impatti;</span>
										<span className={"li-li"}><strong>ii</strong>. del coinvolgimento dell'organizzazione negli impatti.<br/> Ad esempio, se l'organizzazione ha provocato o contribuito agli impatti, o è direttamente correlata agli impatti tramite i suoi rapporti commerciali;</span>
										<span className={"li"}><strong>c</strong>. ciascuna limitazione specifica riguardante il perimetro del tema.</span>
									</span>
								</PopoverBody>
							</UncontrolledPopover>
						</Label>
						<AvInput
							type="textarea"
							row={ 32 }
							name="ht-1-value"
							id="ht-1-value"
							placeholder="valore 103_1"
							className={"tree-data-entry-value-input"}
							onChange={ changeEvent => {
								this.setState({
									...this.state,
									ht_1: changeEvent.target.value
								})
							}}
							value={ this.state.ht_1 }
							required
						/>
						{
							(!!this.state.errors.reason_1)
								? (
									<span className={"form-error-label"}>{ this.state.errors.reason_1 }</span>
								)
								: null
						}
					</AvGroup>
				</div>

				{/*       -------------------------------------------------------------------------------- */}
				<div>
					<AvGroup>
						<Label className="d-block"for="ht-2-value">
							103-2<br/>La modalità di gestione e le sue componenti
							<i
								id={"info-103-2"}
								className={"dripicons-information clickable-info"}
								onClick={ clickEvent => {

								}}
							/>
							<UncontrolledPopover
								placement="right"
								target={"info-103-2"}
								trigger="legacy"
								className={"info-guide-lines-popover"}
							>
								<PopoverHeader>Info</PopoverHeader>
								<PopoverBody>
									<span className={"ul"}>
										<span className={"li"}><strong>a</strong>. una spiegazione su come l'organizzazione gestisce il tema;</span>
										<span className={"li"}><strong>b</strong>. una dichiarazione delle finalità della modalità di gestione;</span>
										<span className={"li"}><strong>c</strong>. una descrizione dei seguenti elementi, se la modalità di gestione include tali componenti:</span>
										<span className={"li-li"}><strong>i</strong>. Politiche</span>
										<span className={"li-li"}><strong>ii</strong>. Impegni</span>
										<span className={"li-li"}><strong>iii</strong>. Obiettivi e target </span>
										<span className={"li-li"}><strong>iv</strong>. Responsabilità</span>
										<span className={"li-li"}><strong>v</strong>. Risorse</span>
										<span className={"li-li"}><strong>vi</strong>. Sistemi di gestione dei reclami</span>
										<span className={"li-li"}><strong>vii</strong>. Azioni specifiche, come processi, progetti, programmi e iniziative</span>
									</span>
								</PopoverBody>
							</UncontrolledPopover>
						</Label>
						<AvInput
							type="textarea"
							row={ 32 }
							name="ht-2-value"
							id="ht-2-value"
							placeholder="valore 103_2"
							className={"tree-data-entry-value-input"}
							onChange={ changeEvent => {
								this.setState({
									...this.state,
									ht_2: changeEvent.target.value
								})
							}}
							value={ this.state.ht_2 }

						/>
					</AvGroup>
				</div>

				{/*       -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label className="d-block"for="ht-3-value">
						103-3<br/>Valutazione delle modalità di gestione
						<i
							id={"info-103-3"}
							className={"dripicons-information clickable-info"}
							onClick={ clickEvent => {

							}}
						/>
						<UncontrolledPopover
							placement="right"
							target={"info-103-3"}
							trigger="legacy"
							className={"info-guide-lines-popover"}
						>
							<PopoverHeader>Info</PopoverHeader>
							<PopoverBody>
								<span>Una spiegazione di come l'organizzazione valuta la modalità di gestione, compresi:</span>
								<span className={"ul"}>
									<span className={"li"}><strong>i</strong>. i sistemi per valutare l'efficacia della modalità di gestione;</span>
									<span className={"li"}><strong>ii</strong>. i risultati della valutazione della modalità di gestione;</span>
									<span className={"li"}><strong>iii</strong>. qualsiasi adeguamento relativo alla modalità di gestione.</span>
								</span>
							</PopoverBody>
						</UncontrolledPopover>


					</Label>
					<AvInput
						type="textarea"
						row={ 32 }
						name="ht-3-value"
						id="ht-3-value"
						placeholder="valore 103_3"
						className={"tree-data-entry-value-input"}
						onChange={ changeEvent => {
							this.setState({
								...this.state,
								ht_3: changeEvent.target.value
							})
						}}
						value={ this.state.ht_3     }
					/>
				</AvGroup>
				<br/>
			</AvForm>



			<AvForm>

				{/*       -------------------------------------------------------------------------------- */}
				{
					(!!!this.state.ht_2)
						? (
							<AvGroup>
								<Label className="d-block"for="reason-2">Ragione di omissione per la 103-2</Label>

								<Select
									className="react-select material-themes-info-select hundredthree-info-select"
									classNamePrefix="react-select"
									name={"reason-2"}
									id={"reason-2"}
									onChange={ changeEvent => {
										this.setState({
											...this.state,
											reason2s: changeEvent.value
										});
									}}
									options={ this.state.reasons.map( t => ({ value: t.id, label: t.name })) }
									value={
										this.state.reasons
											.filter( r => r.id === this.state.reason2s )
											.map( t => ({ value: t.id, label: t.name }))
											.filter( onlyFirst )
											.reduce( getLastElementOr, null )
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona motivazione</span> }
								/>
								{
									(!!this.state.errors.reason_2s)
										? (
											<span className={"form-error-label"} style={{ textAlign: "left" }}>{ this.state.errors.reason_2s }</span>
										)
										: null
								}
								<Row><Col>&nbsp;</Col></Row>
								<AvInput
									type="textarea"
									name="reason-2"
									id="reason-2"
									value={ this.state.reason_2 }
									row={ 32 }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											reason_2: changeTextEvent.target.value
										})
									}}

								/>
								{
									(!!this.state.errors.reason_2)
										? (
											<span className={"form-error-label"}>{ this.state.errors.reason_2 }</span>
										)
										: null
								}
							</AvGroup>
						)
						: null
				}


				{/*       -------------------------------------------------------------------------------- */}
				{
					(!!!this.state.ht_3)
						? (
							<AvGroup>
								<Label className="d-block"for="reason-3">Ragione di omissione per la 103-3</Label>
								<Select
									className="react-select material-themes-info-select hundredthree-info-select"
									classNamePrefix="react-select"
									name={"reason-3"}
									id={"reason-3"}
									onChange={ changeEvent => {
										this.setState({
											...this.state,
											reason3s: changeEvent.value
										});
									}}
									options={ this.state.reasons.map( t => ({ value: t.id, label: t.name })) }
									value={
										this.state.reasons
											.filter( r => r.id === this.state.reason3s )
											.map( t => ({ value: t.id, label: t.name }))
											.filter( onlyFirst )
											.reduce( getLastElementOr, null )
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona motivazione</span> }
								/>
								{
									(!!this.state.errors.reason_3s)
										? (
											<span className={"form-error-label"} style={{ textAlign: "left" }}>{ this.state.errors.reason_3s }</span>
										)
										: null
								}
								<Row><Col>&nbsp;</Col></Row>
								<AvInput
									type="textarea"
									name="reason-3"
									id="reason-3"
									value={ this.state.reason_3 }
									row={ 32 }
									onChange={ changeTextEvent => {
										this.setState({
											...this.state,
											reason_3: changeTextEvent.target.value
										})
									}}

								/>
								{
									(!!this.state.errors.reason_3)
										? (
											<span className={"form-error-label"}>{ this.state.errors.reason_3 }</span>
										)
										: null
								}
							</AvGroup>
						)
						: null
				}

			</AvForm>

			<Button
				color="link"
				href={ HOST + PORT + "assets/pdf/IstruzioniRagionidiOmissione.pdf"}
				target={"_blank"}
				className={"form-error-label"}
				style={{ textAlign: "right" }}
			>
				Istruzioni Ragioni di Omissioni
			</Button>




			</React.Fragment>
		);


		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={ clickEvent => { this.toggle() }} className={this.state.headerClassName}>
					Standard 103
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>


					<ActionButton
						promise={ () => {

							return (
								new Promise((success, fail) => {
									// validazioni
									// ricerca campi mancanti
									let errors = {};
									if( !this.state.ht_1 ) errors.reason_1 = "Campo obbligatorio";
									if( !this.state.ht_2 && !this.state.reason_2 ) errors.reason_2 = "Specificare una motivazione";
									if( !this.state.ht_2 && !this.state.reason2s ) errors.reason_2s = "Selezionare una motivazione";
									if( !this.state.ht_3 && !this.state.reason_3 ) errors.reason_3 = "Specificare una motivazione";
									if( !this.state.ht_3 && !this.state.reason3s ) errors.reason_3s = "Selezionare una motivazione";

									// --------------------------------------------------------------------------------------------------------------------------------
									new Promise ((s, f) => {
										if( Object.keys(errors).length > 0 ) {
											errorlog("salvataggio 103", errors);
											this.setState({
												...this.state,
												errors: errors
											},
												() => {
													setTimeout( function() {
														s( true );
													}, 1000);
												}
											);
										}
										else {
											s();
										}
									})
									.then( isErrorsPresent => {
										if( !!this.props.isChapter ) {
											saveChapterHundredThree( this.state, this.props.collect.id, this.props.info )
												.then(() => {
													success( !isErrorsPresent );
												})
												.catch(e => {
													fail(e);
												})
										}
										else {
											saveInfoHundredThree( this.state, this.props.collect.id, this.props.info )
												.then(() => {
													success( !isErrorsPresent );
												})
												.catch(e => {
													fail(e);
												})
										}
									})
									// --------------------------------------------------------------------------------------------------------------------------------

								})
							)

						}}
						onResult={ result => {
							this.toggle( result );
						}}
						onError={ e => {

							errorlog("salvataggio 103", e);
							this.setState({
								...this.state,
								errors: e
							});

						}}
					/>

					<Button color="link" onClick={ clickEvent => { this.toggle() }}>
						{ translate("Close") }
					</Button>
				</ModalFooter>
			</Modal>


		);

		return content;
	}

	componentDidMount() {
		this.loadData()
	}

	loadData() {


			loadHundredValues( this.props.collect.id, this.props.info, !!this.props.isChapter )
				.then( values => {
					showlog("valori della 103");
					showlog( values );

					/*
						data: {
							griInformativeId: 1
							griTimingId: 3181
							id: 469
							reason2: ""
							reason3: ""
							reason3s: 0
							_1031: ""
							_1032: ""
							_1033: ""
						}
					 */


					return (
						loadHundredThreeReasons()
							.then( list => {
								return ({v: values[0], l: list});
							})
							.catch(e => {
								errorlog("loadHundredThreeReasons", e)
								return Promise.reject(e);
							})
					);

				})

				.then( data => {


					showlog("controllo dati prima del setState");
					showlog( data );

					data.v = data.v || {};

					this.setState(
						{
							...this.state,
							ht_1: data.v._1031,
							ht_2: data.v._1032,
							ht_3: data.v._1033,
							reason_2: data.v.reason2,
							reason_3: data.v.reason3,
							reason3s: data.v.reason3s,
							reason2s: data.v.reason2s,
							reasons: data.l,
							inWait: false
						},
						() => {

							// ready to start

						}
					)

				})

				.catch(e => {
					errorlog("errore al ritorno dei valori 103", e)
					return Promise.reject(e);
				})
		// }


	}



}

