import React, {Component, Suspense} from 'react';
import {withRouter} from "react-router-dom";
import {
	addNewAnswerChoicheSector,
	loadChoiche,
	loadSectorQuestion,
	setIsOnlySectorMain
} from "../../../business-logic/rating";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	CustomInput,
	Label,
	NavLink,
	PopoverBody,
	PopoverHeader,
	Row,
	UncontrolledCollapse,
	UncontrolledPopover
} from "reactstrap";
import {PATH_PREFIX, permissions} from "../../../env";
import StandBySystemModalComponent from "../../../components/area/gri-admin/standby-system-modal";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import {cloneObject} from "../../../utils/object-utils";
import {hasPermission} from "../../../business-logic/auth";
import ActionButton from "../../../components/save-button";
import AddSectorQuestionModal from "../../../components/area/super-admin/new-sector-question-modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import DeleteAnswerChoicheModal from "../../../components/area/super-admin/delete-answer-chioche-prompt-modal";
import PageTitle from "../../../components/PageTitle";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {CompaniesFilterModal} from "../../../components/companies-filter-modal";
import Select from "react-select";
import {translate} from '../../../components/i18n/translate-function';
import SiRatingLogoDarkText from "../../../assets/images/SiRating_logo_dark_text_2.svg";
import SectorQuestionModal from "./sector-question-modal";
import VersionModal from "../../../components/area/super-admin/version-modal";


const fieldMap = {
	id: "id",
	code: "Codice",
	name: "Domanda",
	macroarea_name: "Macroarea",
	// thematic_name: "Tematica",
	processedTheme: "Tematica",
	// subthematic_name: "Sottotematica",
	// score: "Punteggio Massimo",
	sector_name: "Settore",
	attachGuidelines: "Linee Guida Allegati",
	questionGL: "Linee Guida Domanda",
	dimension: "Dimensione",
	mainSectorComander: "Valutazioni associate",
	isOnlySectorMainComponent:"Associata solo settore principale",
	action: " "
};


const hiddenFields = [
	"undefined",
	"id",
	"isMultipleAnswer",
	"macroarea_id",
	"ordering",
	"srSectorId",
	"subthematic_id",
	"thematic_id",
	"name_en",
	"companySize",
	"attachmentsGuidelines",
	"questionGuidelines",
	"score",
	"subthematic_name",
	"thematic_name",
	"choiches",
	"transparency",
	"resilience",
	"improvement",
	"gendergap",
	"name_it",
	"alive",
	"companies",
	"isOnlySectorMain",
	"version"
];


const sortableFields = [
	"Codice",
	"name_it",
	"Domanda",
	"MacroArea",
	// "Tematica",
	// "SottoTematica",
	"Punteggio",
	"Settore",
	"macroarea_name",
	// "thematic_name",
	// "subthematic_name",
	// "score",
	"sector_name",
	// "processedTheme"
];

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Domande
        </span>
	),
	sizePerPageList: [
		{
			text: "pochi",
			value: 25,
		},
		{
			text: "normale",
			value: 50,
		},
		{
			text: "molti",
			value: 250,
		}
	]
};

const defaultSorted = [
	{
		dataField: "id",
		order: "asc",
	},
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
			.filter( ff => !hiddenFields.includes(ff.dataField) )
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;




class SIRatingSectorQuestioPage extends Component {


	state = {
		noSectorQuestion: false,
		selectedCompanies: []
	}


	constructor( props ) {
		super( props );

		this.loadData           = this.loadData.bind(this);
		this.inWait             = this.inWait.bind(this);
		this.buildAnswerSection = this.buildAnswerSection.bind(this);
	}

	inWait() {
		return (
			<div>Wait</div>
		);
	}




	buildAnswerSection( question ) {




		let content = (
			<WaitingOrNodataMessage
				waiting={ true }
				nodataMessage={ "Nessuna scelta possibile"}
			/>
		);




		if( !this.state.serverAnswerChoiches || this.state.currentQuestion !== question.id ) {

			loadChoiche( question.id )
				.then( choiche => {
					showlog("scelta");
					showlog(choiche);


					/*
					id: 4
					isRequiredAttachment: true
					isRequiredTextBox: true
					name_en: "blabla"
					name_it: "bla"
					score: 0
					srQuestionId: 29
					*/

					this.setState({
						...this.state,
						questions:
							this.state.questions
								.map( q => {
									if( q.id === question.id ) q.choiches = choiche;
									return q;
								}),
						serverAnswerChoiches: ( (!!choiche && choiche.length > 0) ? choiche : [{isNewer: true}] ),
						clientAnswerChoiches: ( (!!choiche && choiche.length > 0) ? choiche : [{isNewer: true}] ),
						currentQuestion: question.id
					})


				})
				.catch(e => {
					errorlog("errore",e);
				})

		}
		else {

			// debugger;

			content = (


				<Card className={"sector-question-answer-card"}>

					<CardHeader className={"sector-question-answer-card"}>
						<h5>
							<span>Risposte alla domanda</span>
							<span className="float-right">
								<Button
									onClick={ clickEvent => {

										let clonedServerChoiches = cloneObject( this.state.serverAnswerChoiches );
										clonedServerChoiches.push({});

										let clonedClientChoiches = cloneObject( this.state.clientAnswerChoiches );
										clonedClientChoiches.push({});

										this.setState({
											...this.state,
											// serverAnswerChoiches: clonedServerChoiches,
											clientAnswerChoiches: clonedClientChoiches
										});


									}}
								>
									Aggiungi risposta
								</Button>
							</span>
						</h5>
						<em>{ question.name }</em>



					</CardHeader>

					{
						this.state.clientAnswerChoiches
							.map( (clientAnswerSetting, setIndex) => {


								let id = clientAnswerSetting.id;
								let clonedCurrentChoiche = cloneObject( this.state.clientAnswerChoiches[setIndex] );


								if(clientAnswerSetting.id === undefined || typeof clientAnswerSetting.id === "undefined") {
									id = (100 + this.state.serverAnswerChoiches.length);
									clientAnswerSetting.isNewer = true;
									clientAnswerSetting.status = "Nuova";
								}
								else {
									// controllo modifica
									if(
										this.state.serverAnswerChoiches[setIndex].name_it !== clientAnswerSetting.name_it
										|| this.state.serverAnswerChoiches[setIndex].name_en !== clientAnswerSetting.name_en
										|| this.state.serverAnswerChoiches[setIndex].score !== clientAnswerSetting.score
										|| this.state.serverAnswerChoiches[setIndex].isRequiredAttachment !== clientAnswerSetting.isRequiredAttachment
										|| this.state.serverAnswerChoiches[setIndex].isRequiredTextBox !== clientAnswerSetting.isRequiredTextBox
										|| this.state.serverAnswerChoiches[setIndex].ordering !== clientAnswerSetting.ordering
									) {
										clientAnswerSetting.status = "in Modifica";
										clientAnswerSetting.isEdit = true;
									}
								}






								return (


									<CardBody>

										<h3 className="m-0">
											<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">
												{ "scelta # "+ (setIndex +1) }
												<span className={"choiche-status"}>
													<em>
														{ clientAnswerSetting.status }
													</em>
												</span>

												{
													(!!this.state.answerChoicheNotificMessage && this.state.answerChoicheNotificMessage.filter( acnm => acnm.id === id ).length > 0 )
														? (
															<span className={"choiche-status-error"}>
																{
																	this.state.answerChoicheNotificMessage
																		.filter( acnm => acnm.id === id )
																		.map( acnm => acnm.status )
																		.reduce( getLastElementOr, null)
																}
															</span>
														)
														: null
												}


											</div>

											<span className="float-right">
												<NavLink
													id={"sectorQuestionAnswerChoiche-"+ id +"_"+ setIndex}
													href="#"
													onClick={ clickEvent => {

														// let clonedCurrentChoiche = cloneObject( this.state.clientAnswerChoiches[setIndex] );
														clonedCurrentChoiche.open = !clientAnswerSetting.open;
														let refreshedSettings = [
															...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
															clonedCurrentChoiche,
															...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
														]
														.filter( ss => !!ss );

														this.setState({
															...this.state,
															clientAnswerChoiches: refreshedSettings
														});


													}}
												>
													{
														(!!clientAnswerSetting.open)
															? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow"/>
															: <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow"/>
													}
												</NavLink>
											</span>

										</h3>

										<UncontrolledCollapse toggler={"sectorQuestionAnswerChoiche-"+ id +"_"+ setIndex}>


											<Row>
												<Col md={6}>
													<h4>Requisiti</h4>

													<AvForm>

														{/*     Score  -------------------------------------------------------------------------------- */}
														<AvGroup>
															<Label for="answerScore" className={"small-inline-input-label"}>Punteggio risposta</Label>
															<AvInput
																className={"small-inline-input"}
																type="number"
																name="answerScore"
																id={"answerScore-"+ id +"_"+ setIndex}
																value={

																	(!!this.state.clientAnswerChoiches[ setIndex ] && !!this.state.clientAnswerChoiches[ setIndex ].score)
																		? this.state.clientAnswerChoiches[ setIndex ].score
																		: clientAnswerSetting.score || "0"
																		// : 0

																}
																onChange={ changeTextEvent => {

																	// let clonedCurrentChoiche = cloneObject( this.state.clientAnswerChoiches[setIndex] );

																	showlog("settaggio punteggio");


																	clonedCurrentChoiche.score = parseFloat(changeTextEvent.target.value);
																	let refreshedSettings = [
																		...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																		clonedCurrentChoiche,
																		...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																	]
																	.filter( ss => !!ss );

																	this.setState({
																		...this.state,
																		// serverAnswerChoiches: refreshedSettings,
																		clientAnswerChoiches: refreshedSettings
																	});


																}}
																required
															/>
															<AvFeedback tooltip>This is an error!</AvFeedback>
														</AvGroup>

														{/*     Ordine  -------------------------------------------------------------------------------- */}
														<AvGroup>
															<Label for="answerScore" className={"small-inline-input-label"}>Ordine di scelta</Label>
															<AvInput
																className={"small-inline-input"}
																type="number"
																name="answerOrder"
																id={"answerOrder-"+ id +"_"+ setIndex}
																value={

																	(!!this.state.clientAnswerChoiches[ setIndex ] && !!this.state.clientAnswerChoiches[ setIndex ].ordering)
																		? this.state.clientAnswerChoiches[ setIndex ].ordering
																		: clientAnswerSetting.ordering || "0"

																}
																onChange={ changeTextEvent => {


																	// controllo valore ordine deve essere diverso



																	// let clonedCurrentChoiche = cloneObject( this.state.clientAnswerChoiches[setIndex] );
																	clonedCurrentChoiche.ordering = parseFloat(changeTextEvent.target.value);

																	let refreshedSettings = [
																		...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																		clonedCurrentChoiche,
																		...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																	]
																	.filter( ss => !!ss );


																	this.setState({
																		...this.state,
																		// serverAnswerChoiches: refreshedSettings,
																		clientAnswerChoiches: refreshedSettings
																	});


																}}
																required
															/>
														</AvGroup>

													</AvForm>

													<AvForm>

														{/*     Switch text  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
														<AvGroup>
															<CustomInput
																type="switch"
																id={"sectorAnswerSwitch-"+ id +"_"+ setIndex}
																name={"sectorAnswerSwitch-"+ id +"_"+ setIndex}
																label="Motivazione"
																className={"sector-question-switch-box"}
																onClick={clickEvent => {


																	// let clonedCurrentChoiche = cloneObject( this.state.clientAnswerChoiches[setIndex] );

																	showlog("switch per il testo");
																	showlog(clientAnswerSetting);
																	showlog( clonedCurrentChoiche );
																	// showlog( clientAnswerSetting.isRequiredTextBox );
																	// showlog( document.getElementById("sectorAnswerSwitch-"+ id +"_"+ setIndex) );


																	if (!!document.getElementById("sectorAnswerSwitch-"+ id +"_"+ setIndex)) {
																		document.getElementById("sectorAnswerSwitch-"+ id +"_"+ setIndex).checked = !(clientAnswerSetting.isRequiredTextBox);
																	}

																	clonedCurrentChoiche.isRequiredTextBox = !clientAnswerSetting.isRequiredTextBox;
																	this.setState({
																		...this.state,
																		clientAnswerChoiches: [
																				...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																				clonedCurrentChoiche,
																				...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																			]
																			.filter( ss => !!ss )
																	});



																}}
																valid={
																	(!!this.state.serverAnswerChoiches && !!this.state.serverAnswerChoiches[setIndex] && JSON.stringify(this.state.serverAnswerChoiches[setIndex]) !== "{}")
																		? !!this.state.serverAnswerChoiches[setIndex].isRequiredTextBox
																		: false
																}
																defaultChecked={
																	(!!this.state.serverAnswerChoiches && !!this.state.serverAnswerChoiches[setIndex] && JSON.stringify(this.state.serverAnswerChoiches[setIndex]) !== "{}")
																		? !!this.state.serverAnswerChoiches[setIndex].isRequiredTextBox
																		: false
																}
															/>
														</AvGroup>


														{/*     Switch Allegato  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
														<AvGroup>
															<CustomInput
																type="switch"
																id={"sectorAnswerAttachmentSwitch-"+ id +"_"+ setIndex}
																name={"sectorAnswerAttachmentSwitch-"+ id +"_"+ setIndex}
																label="Allegato"
																className={"sector-question-switch-box"}
																onClick={clickEvent => {

																	if (!!document.getElementById("sectorAnswerAttachmentSwitch-"+ id +"_"+ setIndex)) document.getElementById("sectorAnswerAttachmentSwitch-"+ id +"_"+ setIndex).checked = !clientAnswerSetting.isRequiredAttachment;

																	clonedCurrentChoiche.isRequiredAttachment = !clientAnswerSetting.isRequiredAttachment;
																	this.setState({
																		...this.state,
																		clientAnswerChoiches: [
																				...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																				clonedCurrentChoiche,
																				...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																			]
																			.filter( ss => !!ss )
																	});


																}}
																valid={
																	(!!this.state.serverAnswerChoiches && !!this.state.serverAnswerChoiches[setIndex] && JSON.stringify(this.state.serverAnswerChoiches[setIndex]) !== "{}")
																		? !!this.state.serverAnswerChoiches[setIndex].isRequiredAttachment
																		: false
																}
																defaultChecked={
																	(!!this.state.serverAnswerChoiches && !!this.state.serverAnswerChoiches[setIndex] && JSON.stringify(this.state.serverAnswerChoiches[setIndex]) !== "{}")
																		? !!this.state.serverAnswerChoiches[setIndex].isRequiredAttachment
																		: false
																}
															/>
														</AvGroup>

													</AvForm>




												</Col>


												<Col md={6}>
													<h4>Testi</h4>

													<AvForm>
														<AvGroup>
															<Label for="answer_it">Italiano</Label>
															<AvInput
																className={"sector-question-text"}
																type="textarea"
																row={ 64 }
																name="answer_it"
																id={ "answer_it-"+ id +"_"+ setIndex }
																value={

																	(!!this.state.clientAnswerChoiches[ setIndex ] && !!this.state.clientAnswerChoiches[ setIndex ].name_it)
																		? this.state.clientAnswerChoiches[ setIndex ].name_it
																		: clientAnswerSetting.name_it

																}
																onChange={ changeTextEvent => {

																	// let clonedCurrentChoiche = cloneObject( this.state.clientAnswerChoiches[setIndex] );
																	clonedCurrentChoiche.name_it = changeTextEvent.target.value;

																	let refreshedSettings = [
																		...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																		clonedCurrentChoiche,
																		...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																	]
																	.filter( ss => !!ss );


																	/*
																	showlog("controllo situazione");
																	showlog("settaggi correnti");
																	beautifyLog(this.state.clientAnswerChoiches);
																	showlog("nuovi settaggi");
																	beautifyLog(clonedCurrentChoiche);
																	beautifyLog(
																		[
																			...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																			clonedCurrentChoiche,
																			...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																		]
																		.filter( ss => !!ss )
																	);
																	*/


																	this.setState({
																		...this.state,
																		clientAnswerChoiches: refreshedSettings
																	});


																}}
																required
															/>
														</AvGroup>
														<AvGroup>
															<Label for="answer_en">English</Label>
															<AvInput
																className={"sector-question-text"}
																type="textarea"
																row={ 64 }
																name="answer_en"
																id={"answer_en-"+ id +"_"+ setIndex}
																value={

																	(!!this.state.clientAnswerChoiches[ setIndex ] && !!this.state.clientAnswerChoiches[ setIndex ].name_it)
																		? this.state.clientAnswerChoiches[ setIndex ].name_en
																		: clientAnswerSetting.name_en

																}
																onChange={ changeTextEvent => {

																	clonedCurrentChoiche.name_en = changeTextEvent.target.value;
																	this.setState({
																		...this.state,
																		clientAnswerChoiches: [
																				...this.state.clientAnswerChoiches.filter( (ss, index) => index < setIndex ),
																				clonedCurrentChoiche,
																				...this.state.clientAnswerChoiches.filter( (ss, index) => index > setIndex ),
																			]
																			.filter( ss => !!ss )
																	});

																}}
																required
															/>
														</AvGroup>

													</AvForm>
												</Col>



											</Row>

											<Row>
												<Col>

													<div className={"accordion-inside-button"}>
														<Button
															color={"#d00"}
															className={"comander-icon-trash"}
															onClick={ () => {
																this.setState({...this.state, deleteAnswerChoichePopup: clientAnswerSetting});
															}}
														>
															<i className="mdi mdi-trash-can-outline comander-icon comander-icon-trash"/>
															<span className={"comander-icon-trash"}>Elimina risposta</span>
														</Button>
													</div>

												</Col>
											</Row>


										</UncontrolledCollapse>



									</CardBody>


								);
							})
					}


					<CardFooter className={"sector-question-answer-card"}>
						<div className={"accordion-inside-button"}>
							<ActionButton
								promise={ () => {

									// validazioni
									let payload =
										this.state.clientAnswerChoiches
											.filter( pack => (
												!!pack.name_it
												&& !!pack.name_en
												// && !!pack.score
												&& !!pack.ordering
											))
											.map( ch => ({
												id:                     ch.id || 0,
												name_it:                ch.name_it,
												name_en:                ch.name_en,
												score:                  ch.score || 0,
												isRequiredAttachment:   ch.isRequiredAttachment,
												isRequiredTextBox:      ch.isRequiredTextBox,
												ordering:               ch.ordering,
												isNewer:                ch.isNewer || false,
												isEdit:                 ch.isEdit || false
											}))
									;

									let exclusions =
										this.state.clientAnswerChoiches
											.filter( c => payload.filter( p => p.id === c.id ).length === 0 )
											.map( ch => ch.id || 0 )
											.filter( ch => !!ch )
									;

									showlog("exclusions");
									showlog(exclusions);

									if( exclusions.length > 0 ) {
										return Promise.resolve(exclusions.map( ex => ({ id: ex, status: "Manca qualche campo"})));
									}
									else {
										return addNewAnswerChoicheSector( question, this.state.clientAnswerChoiches );
									}

								}}
								onResult={ result => {
									showlog("ritorno dal save");
									showlog( result );


									let answerChoicheMessages =
										result
											.filter( r => !!r.status && r.status.indexOf("Manca") !== -1 )
									;

									showlog("answerChoicheMessages");
									showlog(answerChoicheMessages);


									this.setState({
										...this.state,
										serverAnswerChoiches: ( answerChoicheMessages.length === 0 ) ? null : this.state.serverAnswerChoiches,
										answerChoicheNotificMessage: answerChoicheMessages,
										errorMessage: ( answerChoicheMessages.length === 0 ) ? null : answerChoicheMessages.map(e => e.status).join(", ")
									},
										() => {
											if( answerChoicheMessages.length === 0 ) this.loadData();

										}
									)

								}}
								onError={ e => {
									errorlog("errore al salvataggio",e);

									this.setState({
										...this.state,
										errorMessage: "qualcosa è andato storto!"
									})

								}}
							/>
						</div>
					</CardFooter>
				</Card>
			);
		}



		return content;



	}


	render() {

		renderlog(
			"SIRatingSectorQuestionPage ",
			this.state,
			this.props
		);



		return (
			<Suspense fallback={this.inWait()}>


				<PageTitle
					breadCrumbItems={[
						{
							label:"SI Rating"
							/* Object.keys( role )
								.map( roleObjectName => role[ roleObjectName ] )
								.filter( roleObject => roleObject.name === localStorage.getItem("role") )
								.reduce( getLastElementOr, "Nessun Ruolo" )
								.label */
							, path: PATH_PREFIX +"/welcome" },
						// { label: "SI Rating", path: PATH_PREFIX +"/super-admin/si-rating/sector-questions" },
						{ label: translate("Sector Questions"), active: true }
					]}
					title={
						<React.Fragment>
							{/*
							<img
								src={ PRODUCTION_HOST +"/assets/Loghi/SiRating.png" }
								alt={"Si Rating"}
								height="30"
								style={{marginRight:'10px'}}
							/>
							*/}
							<img
								// src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
								src={ SiRatingLogoDarkText }
								style={{
									marginRight:'10px',
									height: "60px",
									float: "left",
									marginTop: "10px"
								}}
							/>

							<p style={{float:"left", marginTop: "27px",
								marginBottom: "-23px",
								fontSize: "14px",cursor:"pointer",height:"0px"}}
							   onClick={clickEvent => {
								   this.setState({
									   versionModal: true
								   })
							   }
							   }> v. {(localStorage.getItem("siRatingVersion")).trim()} </p>
							{/*<span>Domande di Settore</span>*/}
						</React.Fragment>
					}
				/>


				{
					hasPermission( permissions.GET_SECTOR_QUESTION )
						? (
							<Card>
								<CardBody>
									<Row>
										<Col>
											<div className="page-title-box">
												<div className="page-title-right">
													{
														hasPermission( permissions.ADD_SECTOR_QUESTION )
															? (
																<ActionButton
																	buttonText={"Nuova"}
																	promise={ () => Promise.resolve() }
																	onResult={ result => {
																		this.setState({
																			...this.state,
																			newQuestionModal: true
																		})
																	}}
																/>
															)
															: null
													}
												</div>
												<h6 className="page-title">Domande</h6>
											</div>
										</Col>
									</Row>

									<Row>
										<Col>

											<ToolkitProvider
												bootstrap4
												keyField="id"
												search
												exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
												{props => (
													<React.Fragment>
														<Row>
															<Col>
																<SearchBar {...props.searchProps} />


																{
																	(!!this.state.questions || !!this.state.allQuestions)
																		? (
																			<div className={"sector-question-filter-container"}>
																				<div className={"srtool-informative-filter sector-question-sector-block-filter"}>
																					<h6>Filtra per Settori</h6>
																					<Select
																						className="react-select"
																						classNamePrefix="react-select"
																						name="sector-filter"
																						id="sector-filter"
																						onChange={ changeEvent => {

																							this.setState({
																								...this.state,
																								sectorFilter: changeEvent.value,
																							})


																						}}
																						options={[
																							... new Map(
																								[
																									{value: 0, label: "Tutti"},
																									...this.state.allQuestions
																										.map( q => ({value: q.srSectorId, label: q.sector_name}))
																								]
																								.map( item => [ item.value, item ])
																							).values()
																						]}
																						value={
																							this.state.allQuestions
																								.map( q => ({value: q.srSectorId, label: q.sector_name}))
																								.filter( is => is.value === this.state.sectorFilter )
																								.reduce( getLastElementOr, {value: 0, label: "Tutti i settori"})
																						}
																						placeholder={ <span className={"select-placeholder"}>Tutti i Settori</span> }
																					/>
																					{
																						!!this.state.sectorFilter
																							? (
																								<i className={"mdi mdi-filter small-ok-component"} />
																							)
																							: null
																					}
																				</div>
																			</div>
																		)
																		: null
																}


																{/*
																	<OKSVGComponent
																		className={"small-ok-component"}
																	/>
																*/}

																<ActionButton
																	buttonText={"Filtra per Aziende ..."}
																	promise={ () => Promise.resolve() }
																	onResult={ result => {
																		this.setState({
																			...this.state,
																			companyFilterModal: true
																		})
																	}}
																	onError={ err => {

																	}}
																	className={"margin-left"}
																	color={ (!!isNotEmptyArray( this.state.selectedCompanies ) ? "success" : "primary" )}
																/>

															</Col>
														</Row>

														{
															(!!this.state.questions && this.state.questions.length > 0)
																? (

																	<BootstrapTable
																		bootstrap4
																		striped
																		{...props.baseProps}
																		size="sm"
																		responsive
																		keyField="id"
																		data={
																			// filtri correnti

																			this.state.allQuestions

																				// filtro su aziende selezionate da popup
																				.filter( q => {
																					if( isNotEmptyArray( this.state.selectedCompanies ) ) {
																						let qc = q.companies;
																						// lista di id delle company della domanda corrente
																						let currentQuestionCompaniesIdList = qc.map( questionCompany => questionCompany.id );

																						// lista di id delle company selezionate
																						let selectedIdList = this.state.selectedCompanies.map( c => c.id );

																						let isQuestionPresent =
																							selectedIdList
																								// per ogni id di selected
																								.some( si => (
																									currentQuestionCompaniesIdList
																										// per ogni id di company di questa question
																										.some( cur => (cur === si) )
																								))
																						;
																						return !!isQuestionPresent;
																					}
																					return true;

																				})

																				// filtro su settore
																				.filter( q => {
																					if( !!this.state.sectorFilter ) return (q.srSectorId === this.state.sectorFilter);
																					return true;
																				})


																		}
																		columns={ formatColumns( Object.keys( this.state.questions[0] ) ) }
																		defaultSorted={ defaultSorted }
																		pagination={ paginationFactory(paginationOptions) }
																		wrapperClasses="table-responsive"
																		rowClasses={ (row, rowIndex) => {
																			if( !row.alive ) return "disabled-row";
																			return "";
																		}}
																		expandRow={{
																			renderer: row => {
																				showlog("riga selezionata");
																				showlog(row);

																				return this.buildAnswerSection( row );

																			},
																			showExpandColumn: false,
																			onlyOneExpanding: true,
																			className: (isExpanded, row, rowIndex) => {
																				/*
																				showlog("expand row");
																				showlog( row );
																				if (!!isExpanded) return 'inner-row';
																				*/
																				return '';
																			},

																			parentClassName: (isExpanded, row, rowIndex) => {
																				/*
																				showlog("expand row - PARENT");
																				showlog( row );
																				if (!!isExpanded) return 'inner-row-active';
																				 */
																				return '';
																			}
																		}}

																	/>
																)
																: (
																	<WaitingOrNodataMessage
																		waiting={ !(!!this.state.questions && this.state.questions.length === 0) }
																		fullHeight={ true }
																		nodataMessage={ "Nessuna Domanda"}
																		spinnerColor={"primary"}
																	/>
																)

														}

													</React.Fragment>
												)}
											</ToolkitProvider>

										</Col>
									</Row>

								</CardBody>
							</Card>
						)
						: <span>Non puoi accedere a questa sezione</span>
				}






				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={ this.state.errorMessage }
								noheader={ true }
								noHazard={ !!this.state.errorMessageIsNotError }
								onClose={ () => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}



				{
					(!!this.state.inWaiting)
						? ( <StandBySystemModalComponent message={ this.state.processingMessage } /> )
						: null
				}

				{
					!!this.state.versionModal
						? (
							<VersionModal
								tool={"SI Rating-2"}
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										versionModal: false
									})
								}}/>
						)
						:
						null
				}


				{
					!!this.state.companyFilterModal
						? (
							<CompaniesFilterModal
								onClose={ () => {
									this.setState({
										...this.state,
										companyFilterModal: null
									})
								}}
								onSelection={ companies => {
									showlog("new selection", companies);
									this.setState({
										...this.state,
										selectedCompanies: companies
									})

								}}
								onError={ err => {
									errorlog("company filter modal", err);
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.newQuestionModal)
						? (
							<AddSectorQuestionModal
								onClose={ () => {
									this.setState({
										...this.state,
										newQuestionModal: null
									})
								}}
								onSuccess={ result => {

									this.setState({
										...this.state,
										newQuestionModal: null
									},
										() => this.loadData()
									)
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.editQuestionModal)
						? (
							<AddSectorQuestionModal
								question={ this.state.editQuestionModal }
								onClose={ errorMessage => {

									if( errorMessage === true ) {
										this.loadData();
									}
									else {
										this.setState({
											...this.state,
											editQuestionModal: null,
											errorMessage: errorMessage,
											errorMessageIsNotError: false
										})
									}

								}}
								onSuccess={ warningMessage => {

									this.setState({
										...this.state,
										editQuestionModal: null
									},
										() => {
											if( !!warningMessage ) {
												// if( typeof warningMessage === "string" ) {
												this.loadData()
													.then(() => {
														this.setState({
															...this.state,
															errorMessage: ( typeof warningMessage === "string" ) ? warningMessage : null,
															errorMessageIsNotError: true
														})
													})
											}
											else this.loadData();
										}
									)
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.deleteAnswerChoichePopup)
						? (
							<DeleteAnswerChoicheModal
								choiche={ this.state.deleteAnswerChoichePopup }
								onClose={() => {
									this.setState({deleteAnswerChoichePopup: null})
								}}
								onConfirm={ answerChoicheDeleted => {
									this.setState({...this.state, deleteAnswerChoichePopup: null}, () => {
										this.setState({
											...this.state,
											clientAnswerChoiches: this.state.clientAnswerChoiches.filter( cac => cac.id !== answerChoicheDeleted.id ),
											serverAnswerChoiches: this.state.serverAnswerChoiches.filter( sac => sac.id !== answerChoicheDeleted.id )
										})
									})
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.mainSectorPanel)
						? (
							<SectorQuestionModal
								question={ this.state.mainSectorPanel }
								onClose={ closeEvent => {
									this.setState({
										...this.state,
										mainSectorPanel: false
									})
								} }
							/>
						)
						: null
				}


			</Suspense>
		);
	}

	componentDidMount() {

		this.loadData();
	}



	loadData() {

		// debugger;

		return (
			loadSectorQuestion()

				// logs ----------------------------------------------------------------------------------------------------------------
				.then( list => {
					showlog("list");
					showlog(list);

					return list;
				})

				/*
				// Choiches ------------------------------------------------------------------------------------------------
				.then( ({list, dimensions}) => {
					return (
						Promise.all(
							list
								.map( question => {
									return (
										loadChoiche(question.id)
											.then(choiche => {
												/*
												id: 4
												isRequiredAttachment: true
												isRequiredTextBox: true
												name_en: "blabla"
												name_it: "bla"
												score: 0
												srQuestionId: 29
												* /
												// showlog("scelta", choiche);
												question.choiches = choiche;
												return question;
											})
											.catch(e => {
												errorlog("loadChoiche di sector page",e);
												return Promise.reject( e );
											})
										);
								})
						)
						.then( list => {
							return ({dimensions: dimensions, list: list});
						})
					);


				})
				*/



				// extras ----------------------------------------------------------------------------------------------------------------
				.then( ({list, dimensions}) => {
					showlog("LISTAAAA", list)
					return (
						list
							.map( row => {


								/*
								è commentata perchè altrimenti non partecipa alla ricerca del testo
								 */
								// row.name = <span className={""}>{ row.name_it }</span>;
								row.name = row.name_it;


								row.attachGuidelines = <span/>;

								if(!!row.attachmentsGuidelines) {
									row.attachGuidelines = (
										<React.Fragment>
											<i
												id={ "info-"+ row.id }
												className={"dripicons-information info-icon"}
												onClick={ clickEvent => {
													clickEvent.stopPropagation();
												}}
											/>
											<UncontrolledPopover
												placement="top"
												target={ "info-"+ row.id }
												trigger="legacy"
											>
												<PopoverHeader>Linee Guida Allegato</PopoverHeader>
												<PopoverBody>{ row.attachmentsGuidelines }</PopoverBody>
											</UncontrolledPopover>
										</React.Fragment>
									);
								}

								if(!!row.questionGuidelines) {
									row.questionGL = (
										<React.Fragment>
											<i
												id={ "qgl-"+ row.id }
												className={"dripicons-information info-icon"}
												onClick={ clickEvent => {
													clickEvent.stopPropagation();
												}}
											/>
											<UncontrolledPopover
												placement="top"
												target={ "qgl-"+ row.id }
												trigger="legacy"
											>
												<PopoverHeader>Linee Guida Domanda</PopoverHeader>
												<PopoverBody>{ row.questionGuidelines }</PopoverBody>
											</UncontrolledPopover>
										</React.Fragment>
									);
								}


								row.dimension = (
									<span>
										{
											dimensions
												.filter( d => d.id === row.companySize )
												.map( d => d.name )
												.reduce( getLastElementOr, "" )
										}
									</span>
								);
								row.action = (
									<div className={"action-column-in-sector-question-table"}>
										<i
											className="uil-edit-alt comander-icon"
											onClick={ clickEvent => {
												showlog("modifica ");
												showlog(row);
												clickEvent.stopPropagation();


												this.setState({...this.state, editQuestionModal: row});

											}}
										/>
									</div>
								);

								row.isOnlySectorMainComponent = (

									<div className={"custom-control custom-checkbox"}
										 style={{marginLeft:"45px"}}>

										<input
											type={"checkbox"}
											className={"custom-control-input"}
											checked= {row.isOnlySectorMain}
											//disabled={row.disabled}
										/>
										<label
											className={"custom-control-label"}
											style={{cursor:"pointer"}}
											onClick={e => {
												e.stopPropagation();
												setIsOnlySectorMain({IsOnlyMainSector: !row.isOnlySectorMain}, row.id)
													.then(r => this.loadData() )
											}}
										/>
									</div>

								)


								return row;
							})
					);
				})







				// subThemes -----------------------------------------------------------------------------------------------
				.then( sectorQuestions => {
					return (
						sectorQuestions
							.map( sq => {
								sq.processedTheme = (
									<React.Fragment>
										<div>{ sq.thematic_name }</div>
										{
											(!!sq.subthematic_name)
												? (
													<div>
														<i className={"dripicons-skip material-themes-tab-name"}/>
														{ sq.subthematic_name }
													</div>
												)
												: null
										}
									</React.Fragment>
								);
								return sq;
							})
					);
				})


				// Occhio ------------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( row => {

								row.mainSectorComander = (
									<i
										className="mdi mdi-eye"
										style={{marginLeft:"35px", fontSize: "1.5rem", color: "var(--primary)"}}
										onClick={ clickEvent => {
											this.setState({
												...this.state,
												mainSectorPanel: row
											})
										}}
									/>
								);

								return row;
							})
					);
				})


				// field sort   -------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( row => {

								let sortedRow = {};
								Object.keys(fieldMap)
									.map( field => {
										sortedRow[field] = row[field];
									})
								;
								Object.assign(sortedRow, row);
								return sortedRow;

							})
					);
				})





				// evidentiation   -------------------------------------------------------------------------------------------
				.then( responseData => {
					return (
						responseData
							.map( row => {
								if( !row.alive ) {

									/*
									Object.keys( row )
										.map( rfield => {
											row[ rfield ] = (<span className={"disabled-sector-question-cell"}>{ row[ rfield ] }</span>);
										})
									*/

									// row.name = <span className={"disabled-sector-question-cell"}>{ row.name }</span>;

									/*
									row.code = (
										<span className={"disabled-code-sector-question-cell"}>
											<span>{ emoj( EMOJ.no_entry ) }</span>
											<span>{ row.code }</span>
										</span>
									);
									row.code = (
										<span className={"disabled-code-sector-question-cell"}>
											{ emoj(EMOJ.no_entry) + " " + row.code }
										</span>
									)
									*/


								}
								return row;

							})
					);
				})





				// finish ----------------------------------------------------------------------------------------------------------------
				.then( sectorQuestions => {

					showlog("controllo ultimo step");
					showlog(sectorQuestions);


					return (
						this.setState({
							...this.state,
							questions: sectorQuestions,
							allQuestions: sectorQuestions,
							noSectorQuestion: (sectorQuestions.length === 0)
						},
							() => {
								return sectorQuestions;
							}
						)
					);

				})

				.catch(e => {
					errorlog("errore",e);
					window.location.href = window.location.href;
				})


		)

	}

}

export default withRouter( SIRatingSectorQuestioPage );
