import React from "react";
import "./completed-questions-icon-svg-component.css";

export const CompletedQuestionsIconSvgComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a"/>
            <g id="b">
                <g>
                    <path className="completed-question-icon-c"
                          d="M104.09,62.87c-1.63,0-2.96-1.32-2.96-2.95V29.93c0-1.63,1.32-2.95,2.96-2.95s2.96,1.32,2.96,2.95v29.99c0,1.63-1.32,2.95-2.96,2.95Z"/>
                    <path className="completed-question-icon-c"
                          d="M92.39,125.07H27.57c-8.08,0-14.66-7.02-14.66-15.65V15.65C12.92,7.02,19.49,0,27.57,0h48.14c1.63,0,2.96,1.32,2.96,2.95s-1.32,2.95-2.96,2.95H27.57c-4.82,0-8.74,4.37-8.74,9.74V109.42c0,5.37,3.92,9.74,8.74,9.74H92.39c4.82,0,8.74-4.37,8.74-9.74v-5.39c0-1.63,1.32-2.95,2.96-2.95s2.96,1.32,2.96,2.95v5.39c0,8.63-6.58,15.65-14.66,15.65Z"/>
                    <path className="completed-question-icon-c"
                          d="M75.72,32.91c-.75,0-1.46-.29-1.99-.82-.53-.53-.83-1.25-.83-2V2.95c0-1.13,.67-2.15,1.71-2.6,1.04-.45,2.25-.23,3.06,.55l28.37,26.98c.84,.79,1.1,2.02,.68,3.09-.42,1.07-1.46,1.78-2.61,1.78l-28.37,.15h-.02Zm2.83-23.36V27.24l18.51-.1-18.51-17.6Z"/>
                    <path className="completed-question-icon-c"
                          d="M61.17,55.33H29.46c-1.36,0-2.47-1.11-2.47-2.47s1.11-2.47,2.47-2.47h31.71c1.36,0,2.47,1.11,2.47,2.47s-1.11,2.47-2.47,2.47Z"/>
                    <path className="completed-question-icon-c"
                          d="M52.48,66.11H29.46c-1.36,0-2.47-1.11-2.47-2.47s1.11-2.47,2.47-2.47h23.03c1.36,0,2.47,1.11,2.47,2.47s-1.11,2.47-2.47,2.47Z"/>
                    <path className="completed-question-icon-c"
                          d="M75.72,45.26H29.46c-1.36,0-2.47-1.11-2.47-2.47s1.11-2.47,2.47-2.47h46.26c1.36,0,2.47,1.11,2.47,2.47s-1.11,2.47-2.47,2.47Z"/>
                    <g>
                        <g>
                            <path className="completed-question-icon-c"
                                  d="M87.82,110.47c-15.35,0-27.84-12.92-27.84-28.81s12.49-28.81,27.84-28.81c8.02,0,15.65,3.58,20.94,9.82,.77,.91,.68,2.29-.19,3.08-.88,.8-2.21,.71-2.98-.2-4.49-5.29-10.96-8.33-17.76-8.33-13.02,0-23.62,10.96-23.62,24.44s10.59,24.44,23.62,24.44,23.62-10.96,23.62-24.44c0-2.17-.28-4.33-.82-6.41-.3-1.17,.36-2.37,1.49-2.68,1.12-.32,2.29,.37,2.59,1.54,.64,2.45,.97,4.99,.97,7.55,0,15.88-12.49,28.81-27.84,28.81Z"/>
                            <path className="completed-question-icon-c"
                                  d="M87.82,111.3c-15.81,0-28.67-13.29-28.67-29.64s12.86-29.64,28.67-29.64c8.26,0,16.12,3.69,21.57,10.11,1.06,1.24,.93,3.14-.27,4.23-.59,.54-1.34,.8-2.14,.75-.79-.05-1.51-.42-2.03-1.03-4.33-5.11-10.57-8.04-17.13-8.04-12.56,0-22.79,10.59-22.79,23.61s10.22,23.61,22.79,23.61,22.79-10.59,22.79-23.61c0-2.1-.27-4.19-.79-6.2-.42-1.6,.51-3.25,2.07-3.69,.74-.21,1.53-.11,2.21,.27,.7,.4,1.2,1.06,1.41,1.85,.66,2.52,.99,5.13,.99,7.76,0,16.34-12.86,29.64-28.67,29.64Zm0-57.61c-14.89,0-27.01,12.55-27.01,27.98s12.12,27.98,27.01,27.98,27.01-12.55,27.01-27.98c0-2.49-.32-4.95-.94-7.34-.09-.36-.32-.66-.63-.83-.29-.16-.62-.21-.93-.12-.69,.19-1.1,.95-.91,1.68,.56,2.15,.85,4.37,.85,6.61,0,13.93-10.97,25.26-24.45,25.26s-24.45-11.33-24.45-25.26,10.97-25.26,24.45-25.26c7.04,0,13.75,3.14,18.39,8.62,.23,.27,.54,.43,.88,.45,.33,.03,.66-.09,.91-.33,.55-.5,.6-1.37,.12-1.93-5.13-6.05-12.53-9.53-20.3-9.53Z"/>
                        </g>
                        <g>
                            <path className="completed-question-icon-c"
                                  d="M86.96,88.24c-.56,0-1.1-.23-1.49-.64l-8.88-9.19c-.83-.85-.83-2.24,0-3.09s2.16-.85,2.99,0l7.38,7.64,26.63-27.56c.83-.85,2.16-.85,2.99,0,.83,.85,.83,2.24,0,3.09l-28.13,29.1c-.4,.41-.93,.64-1.49,.64Z"/>
                            <path className="completed-question-icon-c"
                                  d="M86.96,89.07c-.78,0-1.54-.33-2.09-.89l-8.88-9.19c-1.13-1.17-1.13-3.07,0-4.24,.56-.58,1.3-.89,2.09-.89h0c.79,0,1.53,.32,2.09,.89l6.79,7.02,26.03-26.94c1.11-1.15,3.07-1.15,4.18,0,1.13,1.17,1.13,3.07,0,4.24l-28.13,29.1c-.55,.57-1.31,.89-2.09,.89Zm-8.88-13.56c-.34,0-.65,.14-.9,.39-.52,.54-.52,1.41,0,1.94l8.88,9.19c.48,.5,1.31,.5,1.79,0l28.12-29.1c.52-.54,.52-1.41,0-1.94-.48-.5-1.31-.5-1.79,0l-27.23,28.17-7.98-8.26c-.24-.25-.56-.39-.9-.39h0Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        {/*
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a"/>
            <g id="b">
                <g>
                    <line className="completed-question-icon-d" x1="96.93" y1="61.25" x2="96.93" y2="36.94"/>
                    <path className="completed-question-icon-d"
                          d="M73.97,15.07H35c-5.23,0-9.47,4.61-9.47,10.29V101.39c0,5.68,4.24,10.29,9.47,10.29h52.46c5.23,0,9.47-4.61,9.47-10.29v-4.37"/>
                    <polygon className="completed-question-icon-c" points="73.97 37.07 96.93 36.94 73.97 15.07 73.97 37.07"/>
                    <line className="completed-question-icon-e" x1="36.52" y1="55.53" x2="62.19" y2="55.53"/>
                    <line className="completed-question-icon-e" x1="36.52" y1="64.27" x2="55.16" y2="64.27"/>
                    <line className="completed-question-icon-e" x1="36.52" y1="47.37" x2="73.97" y2="47.37"/>
                    <g>
                        <path className="completed-question-icon-f"
                              d="M83.77,102.24c-12.43,0-22.54-10.48-22.54-23.35s10.11-23.35,22.54-23.35c6.49,0,12.66,2.9,16.95,7.96,.62,.74,.55,1.85-.16,2.5-.71,.65-1.79,.57-2.41-.16-3.63-4.29-8.87-6.75-14.38-6.75-10.54,0-19.12,8.89-19.12,19.81s8.58,19.81,19.12,19.81,19.12-8.89,19.12-19.81c0-1.76-.22-3.51-.66-5.19-.25-.94,.29-1.92,1.2-2.17,.91-.26,1.85,.3,2.1,1.25,.52,1.99,.78,4.05,.78,6.12,0,12.88-10.11,23.35-22.54,23.35Z"/>
                        <path className="completed-question-icon-f"
                              d="M83.07,84.21c-.45,0-.89-.19-1.21-.52l-7.19-7.45c-.67-.69-.67-1.81,0-2.51s1.75-.69,2.42,0l5.98,6.19,21.56-22.34c.67-.69,1.75-.69,2.42,0s.67,1.81,0,2.51l-22.77,23.59c-.32,.33-.76,.52-1.21,.52Z"/>
                    </g>
                </g>
            </g>
        </svg>
        */}
    </div>
);
