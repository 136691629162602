// @flow
import React, {Component} from 'react';
import PageTitle from '../../../../components/PageTitle';
import {COLOR_POLICY, DATE_FORMAT_IT, HOST, PATH_PREFIX, permissions, RATING_STATUS, role} from "../../../../env";
import {errorlog, extractErrorMessage, getLastElementOr, renderlog, showlog} from "../../../../utils";
import moment from "moment";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import {Button, Card, CardBody, Col, CustomInput, Row, UncontrolledTooltip} from "reactstrap";
import {hasPermission, isUserRole} from "../../../../business-logic/auth";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import AddRatingModal from "../../../../components/area/super-admin/new-rating-modal";
import {Redirect} from "react-router-dom";
import ScoreModal from "../../../../components/area/super-admin/score-modal";
import SdgScoreModal from "../../../../components/area/super-admin/sdg-score-modal";
import ErrorMessageModalComponent from "../../../../components/error-message-modal-component";
import {loadAllScorings} from "../../../../business-logic/siscoring";
import {translate} from '../../../../components/i18n/translate-function';
import SiScoringLogoDarkText from "../../../../assets/images/Si_Scoring_logo_dark_text_2.svg";
import {loadActiveService} from "../../../../business-logic/active-service";
import VersionModal from "../../../../components/area/super-admin/version-modal";


const hiddenFields = [
    "alive",
    "er_Id",
    "er_type",
    "manager",
    "assignee",
    // "state",
    "companyId",
    "dimensionId",
    "dimensionName",
    "dimension",
    "companySize",
    "undefined",
    //"id",
    "Dimensione",
    // "status",
    "codCliFor",
    "version",
    "sectorList",
    "isBuyOnline"
];

const hiddenFieldsNOTPMARBOPERATOR = [
    "alive",
    "er_Id",
    "er_type",
    "manager",
    "assignee",
    // "state",
    "companyId",
    "dimensionId",
    "dimensionName",
    "dimension",
    "companySize",
    "undefined",
    "id",
    "Dimensione",
    // "status",
    "codCliFor",
    "version",
    "versionComponent",
    "sectorList",
    "isBuyOnline"
];


const fieldMap = {
    id: "Id",
    versionComponent:translate("Version"),
    name: translate("Name"),
    ragioneSociale: translate("Company Name"),
    dateCreate: translate("Creation Date"),
    // codCliFor: "companyId",
    // dimensionName: "Dimensione",
    state: "Status",
    // scoreButton: "Punteggio",
    // files: "Allegati",
    // status: "status"

};

const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate("Rating")}
        </span>
    ),
    sizePerPageList: [
        {
            text: "pochi",
            value: 25,
        },
        {
            text: "normale",
            value: 50,
        },
        {
            text: "molti",
            value: 250,
        }
    ]
};

const defaultSorted = [
    {
        dataField: "dateCreate",
        order: "asc",
    },
];

const sortableFields = [
    translate("Name"),
    translate("Creation Date"),
    translate("Company Name"),
    "Dimensione",
    "Status",
    "name",
    "ragioneSociale",
    "dateCreate",
    "state"
];

const formatColumns = columnList => {
    return (
        columnList
            .filter( ff => ff !== "undefined" )
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            // custom date time sort -----------------------------------------------------------------------------------
            .map( column => {
                if( ["dateCreate", translate("Creation Date")].includes( column.dataField ) ) {
                    column.sortFunc = (a, b, order, dataField, rowA, rowB) => {
                        if (order === 'asc') {
                            return (moment(b, DATE_FORMAT_IT).unix() - moment(a, DATE_FORMAT_IT).unix());
                        }
                        return (moment(a, DATE_FORMAT_IT).unix() - moment(b, DATE_FORMAT_IT).unix());
                    }
                }
                return column;
            })
            .filter( ff =>  ( (isUserRole( role.PROJECT_MANAGER ) || isUserRole( role.ARB_OPERATOR )) ) ? ( !hiddenFields.includes(ff.dataField)) : ( !hiddenFieldsNOTPMARBOPERATOR.includes(ff.dataField)) )
    );
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


export default class AllScoringPage extends Component {

    state = {
        activeServices:[],
        activeServicesLoaded: false

    }

    constructor(props) {
        super( props );
    }

    render() {

        if( !!this.state.navigateTo ) {
            let obj = this.state.navigateTo;
            delete obj.versionComponent;
            return (
                <Redirect to={
                    { pathname: '/si-scoring/' + translate("evaluation") + '/'+ this.state.navigateTo.id,
                        state: { rating: this.state.navigateTo} }}
                />
            );
        }

        if( !!this.state.navigateToPdf ) {
            showlog("navigazione a /si-scoring/report/pdf/"+ this.state.navigateToPdf.id );
            let obj = this.state.navigateToPdf;
            delete obj[translate("Score")];
            delete obj.versionComponent;
            return (
                <Redirect to={
                    { pathname: '/si-scoring/report/pdf/'+ this.state.navigateToPdf.id, state: { rating: obj } }}
                />
            );
        }

        renderlog("AllScoringPage", this.state, this.props, COLOR_POLICY.warning);

        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: 'SI Scoring', path: '' + PATH_PREFIX + `/si-scoring${ isUserRole( role.PROJECT_MANAGER ) ? "/pm": "" }/${translate("evaluations")}` },
                        { label: translate("Evaluations"), active: true }
                    ]}
                    title={
                        <React.Fragment>


                            {/*
                            <div
                                style={{
                                    marginRight:'10px',
                                    height: "60px"
                                }}
                            >
                                <img
                                    src={ SSLogo }
                                    // src={ siscoringw }
                                />
                            </div>
                            */}
                            {/*
                            <SiScoringSvgIconComponent
                                className={"logo-over-list"}
                            />
                            */}

                            <img
                                // src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
                                src={ SiScoringLogoDarkText }
                                style={{
                                    marginRight:'10px',
                                    height: "60px",
                                    float: "left",
                                    marginTop: "10px"
                                }}
                            />

                            <p style={{float:"left", marginTop: "27px",
                                marginBottom: "-23px",
                                fontSize: "14px",cursor:"pointer",height:"0px"}}
                               onClick={clickEvent => {
                                   this.setState({
                                       versionModal: true
                                   })
                               }
                               }> v. {(localStorage.getItem("siScoringVersion")).trim()} </p>

                            {/*<span>Si Scoring</span>*/}
                            {/*<span>Valutazioni</span>*/}
                        </React.Fragment>
                    }
                />


                {
                    !!this.state.versionModal
                        ? (
                            <VersionModal
                                tool={"SI Scoring-3"}
                                onClose={ closeEvent => {
                                    this.setState({
                                        ...this.state,
                                        versionModal: false
                                    })
                                }}/>
                        )
                        :
                        null
                }


                {
                    (!!this.state.errorMessage)
                        ? (
                            <ErrorMessageModalComponent
                                message={this.state.errorMessage}
                                contentClassName={"big-font"} // choiche-status sirating-question-attachment-modal-icon comander-icon
                                noheader={true}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        errorMessage: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.newRatingModal)
                        ? (
                            <AddRatingModal
                                isSiScoring={ true }
                                ratingList={ this.state.data }
                                onClose={ errorMessage => {

                                    let errMessage = null;
                                    if( !!errorMessage ) errMessage = extractErrorMessage( errorMessage );

                                    this.setState(
                                        {
                                            ...this.state,
                                            newRatingModal: false,
                                            // errorMessage: (!!errorMessage ? emoj( EMOJ.sad_but_relieved_face ) +"  "+ errorMessage : null)
                                            errorMessage: (!!errMessage) ? errMessage : null
                                        },
                                        () => {
                                            if( !errorMessage ) this.loadData();
                                            else {
                                                // c'è stato un errore
                                                // this.setState({
                                                //     ...this.state,
                                                //     errorMessage: errorMessage
                                                // });
                                            }
                                        }
                                    );


                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.errorMessage)
                        ? (
                            <ErrorMessageModalComponent
                                message={ this.state.errorMessage }
                                noheader={ true }
                                noHazard={ false }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        errorMessage: null
                                    })
                                }}
                            />
                        )
                        : null
                }


                {
                    (!!this.state.scoreModal)
                        ? (
                            <ScoreModal
                                questionId={ this.state.scoreModal }
                                evaluation_id={ this.state.evaluation_id }
                                isSiScoring={ true }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        scoreModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    (!!this.state.sgdScoreModal)
                        ? (
                            <SdgScoreModal
                                questionId={ this.state.scoreModal }
                                evaluation_id={ this.state.evaluation_id }
                                isSiScoring={ true }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        sgdScoreModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }

                {
                    ( this.state.activeServicesLoaded === false )
                    ?
                        ((
                            <WaitingOrNodataMessage
                                waiting={true}
                                fullHeight={true}
                            />
                        ))
                        :
                            null
                }


                {
                    (!!this.state.activeServicesLoaded)?
                        (
                            ( this.state.activeServices.includes(3) ) ? //controllo se il servizio si scoring è attivo
                                (
                                    <Card>
                                        <CardBody>

                                            <Row>
                                                <Col>
                                                    <div className="page-title-box">
                                                        <div className="page-title-right">

                                                            {
                                                                (!!hasPermission(permissions.ADD_NEW_SCORING_RATING))
                                                                    ? (
                                                                        <Button
                                                                            color={"success"}
                                                                            onClick={clickEvent => {

                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    newRatingModal: true
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="uil-user"/> {translate("New Evaluation")}
                                                                        </Button>
                                                                    )
                                                                    : null
                                                            }


                                                        </div>
                                                        <h4 className="page-title">{translate("Ratings List")}</h4>
                                                    </div>
                                                </Col>
                                            </Row>


                                            <ToolkitProvider
                                                bootstrap4
                                                keyField="id"
                                                search
                                                exportCSV={{onlyExportFiltered: true, exportAll: false}}>
                                                {props => (
                                                    <React.Fragment>


                                                        <div className={"rating-filters-and-comander"}>

                                                            <div className={"rating-filters"}>
                                                                <div className={""}><SearchBar {...props.searchProps} /></div>
                                                                <div className={""}>{ /*this.ratingFilterComponent()*/}</div>
                                                            </div>
                                                            <div className={"rating-comander"}>
                                                                {
                                                                    !!hasPermission(permissions.GET_ARCHIVED_RATINGS)
                                                                        ? (
                                                                            <CustomInput
                                                                                type="switch"
                                                                                id={"archived-rating-toggler"}
                                                                                name={"archived-rating-toggler"}
                                                                                label={
                                                                                    <span>{translate("Archive")}</span>
                                                                                }
                                                                                className={""}
                                                                                onClick={clickEvent => {

                                                                                    if (!!document.getElementById("archived-rating-toggler")) {
                                                                                        document.getElementById("archived-rating-toggler").checked = !this.state.isArchived;
                                                                                    }

                                                                                    // let isArchived = !this.state.isArchived;
                                                                                    this.setState({
                                                                                            ...this.state,
                                                                                            data: null,
                                                                                            isArchived: !this.state.isArchived
                                                                                        },
                                                                                        () => {
                                                                                            this.loadData();
                                                                                        }
                                                                                    )


                                                                                }}
                                                                                defaultChecked={!!this.state.isArchived}
                                                                                valid={!!this.state.isArchived && !!this.state.data}
                                                                            />
                                                                        )
                                                                        : null
                                                                }
                                                            </div>

                                                        </div>

                                                        {


                                                            (!!this.state.data && this.state.data.length > 0)
                                                                ? (

                                                                    <BootstrapTable
                                                                        bootstrap4
                                                                        striped
                                                                        {...props.baseProps}
                                                                        size="sm"
                                                                        responsive
                                                                        keyField="id"
                                                                        data={this.state.data}
                                                                        columns={formatColumns(Object.keys(this.state.data[0]))}
                                                                        defaultSorted={defaultSorted}
                                                                        pagination={paginationFactory(paginationOptions)}
                                                                        wrapperClasses="table-responsive"

                                                                        selectRow={{
                                                                            mode: 'row',
                                                                            clickToSelect: true,
                                                                            hideSelectColumn: true,

                                                                            onSelect: row => {
                                                                                showlog("riga selezionata");
                                                                                showlog(row);

                                                                                delete row[translate("Score")];

                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    navigateTo: row
                                                                                })

                                                                            },


                                                                            selectionRenderer: row => {

                                                                                return null;

                                                                            },
                                                                            selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                                                                return null;
                                                                            }
                                                                        }}


                                                                    />

                                                                )
                                                                : (
                                                                    <WaitingOrNodataMessage
                                                                        waiting={!(!!this.state.data && this.state.data.length === 0)}
                                                                        fullHeight={true}
                                                                        nodataMessage={

                                                                            (this.state.activeServices.includes(3)) ? ("Nessuna Valutazione") : ("Servizio Non Attivo")
                                                                        }
                                                                    />
                                                                )
                                                        }


                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>

                                        </CardBody>
                                    </Card>
                                )
                                :
                                (
                                    <Card>
                                        <CardBody>
                                            <h4 className="page-title">Servizio Scaduto</h4>
                                            <br/>
                                            <i className="mdi mdi-close"/> valutazioni in pending<br/>
                                            <i className="mdi mdi-close"/> valutazion in progress<br/>
                                            <i className="mdi mdi-close"/> valutazioni completate<br/>
                                            <br/>
                                            <span><a href="/support" target="_blank"> Support </a></span>
                                            <br/>
                                            <span><a href="https://arbspa.it/index.php/contatti/" target="_blank"> Contact</a></span>
                                        </CardBody>
                                    </Card>
                                )
                        )
                            :null
                }

            </React.Fragment>
        );
    }


    componentDidMount() {

        this.loadData();
        loadActiveService()
            .then( result =>

                // showlog("aaaaa",result)
                result.map( service =>
                    service.service_id
                )).then(r=> this.setState({
                    ...this.state,
                    activeServices : r,
                    activeServicesLoaded: true
                })
            )
    }

    loadData = () => (
        loadAllScorings( !!this.state.isArchived ? 0 : 1 )

            // field sort   -------------------------------------------------------------------------------------------
            .then( responseData => {
                return (
                    responseData
                        .map( row => {

                            let sortedRow = {};
                            Object.keys( fieldMap)
                                .map( field => {
                                    sortedRow[field] = row[field];
                                })
                            ;
                            Object.assign(sortedRow, row);
                            return sortedRow;

                        })
                );
            })


            /*
            // field map ----------------------------------------------------------------------------------------------
            .then( list => {


                return (
                    list
                        .map( company => {
                            let dto = {};
                            Object.keys( company )
                                .forEach( rawField => {
                                    dto[ fieldMap[ rawField ] ] = company[ rawField ];
                                })
                            ;
                            return dto;
                        })
                );

            })
            */

            // adjust --------------------------------------------------------------------------------------------------
            .then( list => {
                return (
                    list
                        .map( row => {
                            // row.dateCreate = moment( row["Data Creazione"] ).format( DATE_FORMAT_IT );
                            row.dateCreate = moment( row.dateCreate ).format( DATE_FORMAT_IT );
                            if( row.state === RATING_STATUS.perimetralQuestionAccepted.id ) {
                                row.state = translate("evaluation_in_progress");
                            }
                            if( row.state === RATING_STATUS.auditDone.id ) {
                                row.state = translate("evaluation_completed");
                            }

                            if( typeof row.state !== "string" ) {
                                row.state =
                                    Object.keys( RATING_STATUS )
                                        .map( status => RATING_STATUS[ status ])
                                        .filter( ratingStatus => ratingStatus.id === row.state )
                                        .reduce( getLastElementOr, RATING_STATUS.perimetralQuestionAccepted )
                                        .name
                                ;
                            }

                            return row;
                        })
                );
            })



            // extras --------------------------------------------------------------------------------------------------
            .then( list => {
                showlog( list );
                return (
                    list
                        .map( (row, id) => {
                            row[translate("Score")] = "";
                            if( (row.state === translate("evaluation_completed")) || ( (isUserRole( role.PROJECT_MANAGER)) || (isUserRole( role.ARB_OPERATOR)) )) {
                                row[translate("Score")] = (
                                    <React.Fragment>

                                        <i
                                            id={"punteggio" + id}
                                            className={"mdi mdi-star-outline sdg-icon-star"}
                                            alt={"SDG"}
                                            // className={"sdg-icon-file2"}
                                            onClick={clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    scoreModal: row,
                                                    evaluation_id: row.id
                                                })
                                            }}
                                        />
                                        <UncontrolledTooltip placement="top" target={"punteggio"+id}>
                                            <span className="">{translate("Final Score")}</span>
                                        </UncontrolledTooltip>

                                        {/*
                                        <img
                                            src={HOST + "/assets/icons_evaluations/rating.svg"}
                                            alt={"SDG"}
                                            className={"sdg-icon-file2"}
                                            onClick={clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    scoreModal: row,
                                                    evaluation_id: row.id
                                                })
                                            }}
                                        />
                                        */}
                                        <img
                                            id={"sdg" + id}
                                            src={HOST + "/assets/icons_evaluations/sdgs.svg"}
                                            alt={"SDG"}
                                            className={"sdg-icon-file2"}
                                            onClick={clickEvent => {
                                                clickEvent.stopPropagation();
                                                this.setState({
                                                    ...this.state,
                                                    sgdScoreModal: row,
                                                    evaluation_id: row.id
                                                })
                                            }}
                                        />
                                        <UncontrolledTooltip placement="top" target={"sdg"+id}>
                                            <span className=""> SDGs </span>
                                        </UncontrolledTooltip>


                                        <img
                                            id={"pdf" + id}
                                            src={HOST + "/assets/icons_evaluations/pdf.svg"}
                                            alt={"SDG"}
                                            className={"sdg-icon-file2 "+ ((row.state === translate("evaluation_completed")) ? "" : "disabled")}
                                            onClick={clickEvent => {
                                                clickEvent.stopPropagation();

                                                if(row.state === translate("evaluation_completed")) {
                                                    this.setState({
                                                        ...this.state,
                                                        navigateToPdf: row
                                                    })
                                                }

                                            }}
                                        />



                                        <UncontrolledTooltip placement="top" target={"pdf"+id}>
                                            <span className="">{"Score Card"}</span>
                                        </UncontrolledTooltip>

                                    </React.Fragment>
                                );
                            }

                            row.versionComponent= (
                                <div style={{ float:"right" }}>
                                    {row.version}
                                </div>
                            )

                            return row;
                        })
                );
            })


            // filtri ---------------------------------------------------------------------------------------------------
            .then( list => {

                // debugger;
                return (
                    list
                    // .filter( rat => rat.status === this.state.activeFilter )
                );

            })

            // finish ---------------------------------------------------------------------------------------------------
            .then( list => {
                return (
                    new Promise((success, _) => {
                        this.setState({
                            ...this.state,
                            data: list
                        },
                            () => {
                                success();
                            }
                        )
                    })
                )
            })
            .catch(e => {
                errorlog("siscoring list", e);

                return (
                    new Promise((_, fail) => {
                        this.setState({
                                ...this.state,
                                data: []
                            },
                            () => {
                                fail();
                            }
                        )
                    })
                )

                // return Promise.reject( e );
            })
    );


};

