/**
 *
 */
export const permissions = {
    LOGIN:                                          { id: 1,    name: "login",                                      label: "accesso" },
    CREATE_NEW_COLLECT:                             { id: 2,    name: "newCollect",                                 label: "creazione di una nuova raccolta" },
    ADD_MATERIAL_THEME:                             { id: 3,    name: "newMaterialTheme",                           label: "nuova tematica materiale" },
    EDIT_MATERIAL_THEME:                            { id: 4,    name: "editMaterialTheme",                          label: "modifica tematica mayeriale" },
    ADD_USER:                                       { id: 5,    name: "addUser",                                    label: "nuovo utente" },
    EDIT_USER:                                      { id: 6,    name: "editUser",                                   label: "modifica utente" },
    DELETE_USER:                                    { id: 7,    name: "deleteUser",                                 label: "elimina utente" },
    EDIT_COLLECT:                                   { id: 8,    name: "editCollect",                                label: "modifica raccolta" },
    DELETE_COLLECT:                                 { id: 9,    name: "deleteCollect",                              label: "elimina raccolta" },
    ADD_RATING:                                     { id: 10,   name: "addRating",                                  label: "nuova valutazione" },
    ADD_SECTOR_QUESTION:                            { id: 11,   name: "addSectorQuestion",                          label: "Nuova domanda di settore" },
    EDIT_SECTOR_QUESTION:                           { id: 12,   name: "editSectorQuestion",                         label: "modifica domanda di settore" },
    DELETE_SECTOR_QUESTION:                         { id: 14,   name: "deleteSectorQuestion",                       label: "eliminazione domanda di settore" },
    GET_SECTOR_QUESTION:                            { id: 15,   name: "getSectorQuestion",                          label: "lettura domanda di settore" },
    GET_COLLECT:                                    { id: 16,   name: "getCollects",                                label: "presa dei dati delle raccolte" },
    SET_VALIDATION_CONFIGURATION: 	                { id: 18,   name: "setValidationConfiguration", 	            label: "permesso di settare la configurazione delle domande come validata" },
    SET_COMPLETATION_CONFIGURATION:                 { id: 19,   name: "setCompletationConfiguration", 	            label: "permesso di settare la configurazione delle domande come completata" },
    QUESTION_ATTACHMENT_DISPLAY: 	                { id: 20,   name: "displayQuestionAttachment", 	                label: "permesso di vedere allegati della domanda" },
    QUESTION_ATTACHMENT_DOWNLOAD: 	                { id: 21,   name: "downloadQuestionAttachment", 	            label: "permesso di scaricare l'allegato della domanda" },
    QUESTION_ATTACHMENT_DELETE: 	                { id: 22,   name: "deleteQuestionAttachment", 	                label: "permesso di eliminare l'allegato della domanda" },
    QUESTION_ATTACHMENT_UPLOAD: 	                { id: 23,   name: "uploadQuestionAttachment", 	                label: "permesso di caricare l'allegato della domanda" },
    RATING_ATTACHMENT_DISPLAY: 		                { id: 24,   name: "displayRatingAttachment", 		            label: "permesso di vedere allegati della valutazione" },
    RATING_ATTACHMENT_DOWNLOAD: 	                { id: 25,   name: "downloadRatingAttachment", 	                label: "permesso di scaricare l'allegato della valutazione" },
    RATING_ATTACHMENT_DELETE: 		                { id: 26,   name: "deleteRatingAttachment", 		            label: "permesso di eliminare l'allegato della valutazione" },
    RATING_ATTACHMENT_UPLOAD: 		                { id: 27,   name: "uploadRatingAttachment", 		            label: "permesso di caricare l'allegato della valutazione" },
    GO_TO_RATING_CONFIGURATION_AFTER_VALIDATION: 	{ id: 28,   name: "goToRatingConfigurationAfterValidation", 	label: "permesso di configurare una valutazione dopo esser stata validata" },
    GO_TO_RATING_CONFIGURATION_AFTER_COMPLETATION: 	{ id: 29,   name: "goToRatingConfigurationAfterCompletation", 	label: "permesso di configurare una valutazione dopo esser stata completata" },
    RATING_AUDIT: 									{ id: 30,   name: "auditPermission", 							label: "permesso di far audit" },
    SHOW_RATING_AUDITED: 							{ id: 31,   name: "auditedReadPermission", 					    label: "permesso di vedere audit" },
    RATING_AUDIT_CONFIRM:							{ id: 32,   name: "auditConfirmPermission", 					label: "permesso di confermare l'audit" },
    RATING_AUDIT_CONTINUE_AFTER_CONFIRM:			{ id: 33,   name: "auditGoOnAfterConfirmPermission", 			label: "permesso di continuare l'audit dopo la conferma" },
    RATING_AUDIT_CONTINUE_AFTER_VALIDATION:			{ id: 34,   name: "auditGoOnAfterValidationPermission", 		label: "permesso di continuare l'audit dopo la validazione" },
    RATING_ATTACHMENT_APPROVING_DISPLAY:	        { id: 35,   name: "readApproveAttachmentPermission", 			label: "permesso di leggere lo status di approvazione di un allegato di una valutazione" },
    RATING_ATTACHMENT_APPROVING:			        { id: 36,   name: "approveAttachmentPermission", 				label: "permesso di approvare un allegato di una valutazione" },
    READ_COMPANY: 			                        { id: 37,   name: "readCompany", 			                    label: "permesso di lettura delle aziende" },
    SELECT_COMPANY: 		                        { id: 38,   name: "selectCompany", 		                        label: "permesso di selezione delle aziende" },
    EDIT_ACTIVE_SERVICE: 	                        { id: 39,   name: "editActiveService", 	                        label: "permesso di modifica sui servizi attivi" },
    SET_COLLECT_MANAGER: 	                        { id: 40,   name: "setCollectManager", 	                        label: "permesso di impostare un manager alla raccolta" },
    CREATE_ACTIVE_SERVICE:                          { id: 41,   name: "createActiveService", 	                    label: "permesso di creare un nuovo servizio attivo" },
    SHOW_COMPLETED_READONLY_PERIMETRAL_QUESTION: 	{ id: 42,   name: "showCompletedReadOnlyPerimetralQuestion", 	label: "permesso di vedere in sola lettura le domande di perimetro" },
    CLOSE_PERIMETRAL_QUESTION: 						{ id: 43,   name: "closePerimetralQuestion", 					label: "permesso di completare o di validare le domande di perimetro" },
    READ_SCORE_MODAL: 						        { id: 44,   name: "accessScoreModal",                           label: "acesso alla score modal" },
    READ_SGD_SCORE_MODAL: 					        { id: 45,   name: "accessSgdScoreModal",                        label: "aceesso alla score SDG modal" },
    READ_CRITERIA_MODAL: 					        { id: 46,   name: "accessCriteriaModal",                        label: "aceesso alla modale dei criteri" },
    READ_STATUS_MODAL: 						        { id: 47,   name: "accessStatusModal",                          label: "accesso alla state modal" },
    ACCESS_PDF_PARAMETER_MODAL: 			        { id: 48,   name: "accessPdfParametersModal",                   label: "accesso alla pagina dei parametri pdf" },
    DOWNLOAD_RATING_PDF:					        { id: 49,   name: "downloadRatingPdf",                          label: "download pdf rating" },
    DOWNLOAD_PROFILING_PDF: 				        { id: 50,   name: "downloadProfilingPdf",                       label: "download pdf profilazione" },
    DOWNLOAD_ANSWERS_PDF: 					        { id: 51,   name: "downloadAnswersPdf",                         label: "download pdf risposte" },
    DOWNLOAD_XML_MATERIALITY: 				        { id: 52,   name: "downloadMaterialityXml",                     label: "download xml di Materialità" },
    DOWNLOAD_XML_SDG: 						        { id: 53,   name: "downloadSDGXml",                             label: "download xml degli SDG" },
    SET_RATING_PDF_PARAMETERS:				        { id: 54,   name: "setRatingPdf",                               label: "impostare i parametri del pdf" },
    CREATE_COMPANY:  		                        { id: 55,   name: "createNewCompany", 	                        label: "permesso di creare una nuova azienda" },
    EDIT_COMPANY:  			                        { id: 56,   name: "editCompany", 			                    label: "permesso di modificare i dati di una azienda" },
    DELETE_COMPANY:  		                        { id: 57,   name: "deleteCompany", 		                        label: "permesso di eliminare una azienda" },
    EDIT_TEXT_ANSWER_SCORE:							{ id: 58,   name: "editTextAnswerScore", 						label: "permesso di cambiare il punteggio di una risposta testuale" },
    EDIT_ANSWER_SCORE:							    { id: 59,   name: "editAnswerScore", 						    label: "permesso di cambiare il punteggio di una risposta" },
    DISPLAY_RATING_LEGEND:                          { id: 60,   name: "displayRatingLegend", 	                    label: "permesso di visualizzare la legenda della valutazione" },
    CREATE_NEW_ORGANIZZATION:                       { id: 61,   name: "createNewOrganizzation",	                    label: "permesso di creazione di un organizzazione" },
    EDIT_ORGANIZZATION:                             { id: 62,   name: "editOrganizzation",	                        label: "permesso di modifica di un organizzazione" },
    GET_ORGANIZZATION:                              { id: 63,   name: "getOrganizzations",	                        label: "permesso di lettura di un organizzazione" },
    DELETE_ORGANIZZATION:                           { id: 64,   name: "deleteOrganizzation",	                    label: "permesso di eliminazione di un organizzazione" },
    SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD:           { id: 65,   name: "uploadCollectAttachment",	                label: "permesso di caricare l'allegato della raccolta" },
    SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY:          { id: 66,   name: "displayCollectAttachment",	                label: "permesso di visualizzare l'allegato della raccolta" },
    SRTOOL_INFORMATIVE_ATTACHMENT_DELETE:           { id: 67,   name: "deleteCollectAttachment",	                label: "permesso di eliminare l'allegato della raccolta" },
    SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD:         { id: 68,   name: "downloadCollectAttachment",	                label: "permesso di scaricare l'allegato della raccolta" },
    SIRATING_READ_MATERIALITY_LEVEL:                { id: 69,   name: "readMaterialityLevel",	                    label: "permesso di lettura livello di materialità" },
    READ_ORGANIZATION_ACTIVE_SERVICES:              { id: 70,   name: "readOrganizationActiveService",	            label: "permesso di lettura dei servizi attivi delle organizzazioni" },
    EDIT_SUPER_ADMIN_ROLE_USER:                     { id: 71,   name: "editSuperAdminRoleUser",	                    label: "permesso di modifica di un utente di tipo Super Admin" },
    NAVIGATE_TO_PERIMETRAL:                         { id: 72,   name: "navigateToPerimetral",	                    label: "permesso di navigazione alla sezione di perimetro" },
    SIRATING_EDIT_MATERIALITY_LEVEL:                { id: 73,   name: "editMaterialityLevel",	                    label: "permesso di modifica livello di materialità" },
    SET_COLLECT:                                    { id: 74,   name: "editCollect",        	                    label: "permesso di modifica la raccolta" },
    SET_ASSURANCE:                                  { id: 75,   name: "setAssurance",        	                    label: "permesso di abilitare l'assurance" },
    SHOW_ASSURANCE:                                 { id: 76,   name: "showAssurance",        	                    label: "permesso di sapere se è presente l'assurance" },
    ACTIVATE_ASSURANCE:                             { id: 77,   name: "activateAssurance",        	                label: "permesso di avviare l'assurance" },
    EDIT_COLLECT_YEAR:                              { id: 78,   name: "editCollectYear",        	                label: "permesso di cambiare anno della raccolta" },
    EDIT_COLLECT_START:                             { id: 79,   name: "editCollectStart",        	                label: "permesso di cambiare l'inizio della raccolta" },
    EDIT_COLLECT_END:                               { id: 80,   name: "editCollectEnd",        	                    label: "permesso di la fine della raccolta" },
    MANAGE_COLLECT:                                 { id: 81,   name: "manageCollect",        	                    label: "permesso di gestione della raccolta, configurazione, inserimento valori" },
    ADD_REPETITIONS_ENTITY:                         { id: 82,   name: "addRepetitionEntity",        	            label: "permesso di aggiungere una nuova entità per i requisiti che si ripetono" },
    DISPLAY_RATING_TABLE_LEGEND:                    { id: 83,   name: "displayRatingTableLegend", 	                label: "permesso di visualizzare la tabella-legenda della valutazione" },
    ACTIVATE_INTERNAL_ASSURANCE:                    { id: 84,   name: "activateInternalAssurance",                  label: "permesso di attivare l'assurance interno" },
    DISPLAY_INTERNAL_ASSURANCE:                     { id: 85,   name: "showInternalAssurance",                      label: "permesso di visualizzare i dati e gli stati dell'assurance interno" },
    SET_AND_MANAGE_INTERNAL_ASSURANCE:              { id: 86,   name: "setAndManageInternalAssurance",              label: "permesso di settare i dati e gli stati dell'assurance interno" },
    SET_SRTOOL_INFORMATIVE_COMPLETE:                { id: 87,   name: "setSRToolInformativeComplete",               label: "permesso di settare a completato o no, l'informativa" },
    ANSWER_SRToolINFORMATIVE_REQUEST:               { id: 88,   name: "answerSRToolInformativeRequest",             label: "permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool" },
    ASSURANCE_ATTACHMENT_UPLOAD:                    { id: 89,   name: "assuranceAttachmentUpload",                  label: "permesso di caricare allegati per fine assurance" },
    ASSURANCE_ATTACHMENT_DOWNLOAD:                  { id: 90,   name: "assuranceAttachmentDwonload",                label: "permesso di scaricare allegati per fine assurance" },
    ASSURANCE_ATTACHMENT_DELETE:                    { id: 91,   name: "assuranceAttachmentDelete",                  label: "permesso di eliminare allegati per fine assurance" },
    GET_ARCHIVED_COLLECTS:                          { id: 92,   name: "getArchivedCollect",                         label: "permesso di selezionare il toggler che mostra le raccolte archiviate" },
    GET_ARCHIVED_RATINGS:                           { id: 93,   name: "getArchivedRating",                          label: "permesso di selezionare il toggler che mostra le valutazioni archiviate" },
    SEE_RATING_ANSWER_SCORE:                        { id: 94,   name: "readRatingAnswerScore",                      label: "permesso di vedere il punteggio delle risposte delle domande delle valutazioni" },
    ADD_NEW_SCORING_RATING:                         { id: 95,   name: "addNewSoringRating",                         label: "permesso di aggiungere una nuova valutazione al SiScoring" },

    TEST_PAGE:              { id: 99,   name: "accessTestPage",     label: "accesso a apagina di test" },
};



const GOD_PERMISSION_FILTER =
    p => (
        [
            permissions.EDIT_COLLECT.id 		                            // 8    modifica raccolta
            , permissions.GO_TO_RATING_CONFIGURATION_AFTER_VALIDATION.id	// 28   permesso di configurare una valutazione dopo esser stata validata
            , permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION.id	        // 34   permesso di continuare l'audit dopo la validazione
            // , permissions.EDIT_COMPANY.id	                                // 56   permesso di modificare i dati di una azienda
            // , permissions.DELETE_COMPANY.id	                                // 57   permesso di eliminare una azienda
            , permissions.ASSURANCE_ATTACHMENT_UPLOAD.id                    // 89,  permesso di caricare allegati per fine assurance
            , permissions.ASSURANCE_ATTACHMENT_DELETE.id                    // 91,  permesso di eliminare allegati per fine assurance

            , permissions.TEST_PAGE.id	                                    // 99   test page
        ].every( godPermission => godPermission !== p )
    )
;

/**
 *
 * regex per copiare i permessi
 * ([A-Z_\-]+)(:\s+\{\s)(id)(:\s)([0-9]+)(,\s+name:\s)("[a-zA-Z]+",\s+label:\s")([a-zA-Z\s\-_'àèéìòù]+)("\s+\},)
 * permissions.$1.id, // $5, $8
 *
 */
export const role = {

    MASTER : {
        id: 100,
        name: "MASTER",
        label: "Master",
        permissions:
            Object.keys(permissions)
                .map( key => permissions[ key ] )
                .map( perm => perm.id )
    },

    PROJECT_MANAGER:    {
        id: 0,
        name: "PM",
        label: "Project Manager - ARB SBPA",
        permissions:
            Object.keys(permissions)
                .map( key => permissions[ key ] )
                .map( perm => perm.id )

                // GOD Permissions
                .filter( GOD_PERMISSION_FILTER )

                // Permissions Denied
                .filter( perm => (
                    !([
                        // permissions.SET_ASSURANCE.id,                   // 75, permesso di abilitare l'assurance
                        // permissions.ACTIVATE_ASSURANCE.id,              // 77, permesso di avviare l'assurance
                    ].includes( perm ))
                ))

    },

    ADMIN: {
        name: "Admin",
        id: 1,
        label: "SI Road Admin",
        permissions: [
            permissions.LOGIN.id, // 1, accesso
            permissions.CREATE_NEW_COLLECT.id,              // 2, creazione di una nuova raccolta
            permissions.ADD_MATERIAL_THEME.id,              // 3, nuova tematica materiale
            permissions.EDIT_MATERIAL_THEME.id,             // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                        // 5, nuovo utente
            permissions.EDIT_USER.id,                       // 6, modifica utente
            permissions.DELETE_USER.id,                     // 7, elimina utente
            permissions.DELETE_COLLECT.id,                  // 9, elimina raccolta
            permissions.GET_SECTOR_QUESTION.id,             // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                     // 16, presa dei dati delle raccolte
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
            permissions.SET_COLLECT_MANAGER.id,             // 40, permesso di impostare un manager alla raccolta
            permissions.READ_SCORE_MODAL.id,                // 44, acesso alla score modal
            permissions.READ_SGD_SCORE_MODAL.id,            // 45, aceesso alla score SDG modal
            permissions.READ_CRITERIA_MODAL.id,             // 46, aceesso alla modale dei criteri
            permissions.READ_STATUS_MODAL.id,               // 47, accesso alla state modal
            permissions.ACCESS_PDF_PARAMETER_MODAL.id,      // 48, accesso alla pagina dei parametri pdf
            permissions.DOWNLOAD_RATING_PDF.id,             // 49, download pdf rating
            permissions.DOWNLOAD_PROFILING_PDF.id,          // 50, download pdf profilazione
            // permissions.DISPLAY_RATING_LEGEND.id,        // 60, permesso di visualizzare la legenda della valutazione


            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id, // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id, // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id, // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id, // 68, permesso di scaricare l'allegato della raccolta

            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono

            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            permissions.EDIT_COLLECT_YEAR.id, // 78, permesso di cambiare anno della raccolta
            // permissions.EDIT_COLLECT_START.id, // 79, permesso di cambiare l'inizio della raccolta
            // permissions.EDIT_COLLECT_END.id, // 80, permesso di la fine della raccolta
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori

            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa


            permissions.ANSWER_SRToolINFORMATIVE_REQUEST.id, // 88, permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool

            permissions.ASSURANCE_ATTACHMENT_DOWNLOAD.id, // 90, permesso di scaricare allegati per fine assurance

            // permissions.ADD_NEW_SCORING_RATING.id, // 95, permesso di aggiungere una nuova valutazione al SiScoring

            // internal Assurance
            // permissions.ACTIVATE_INTERNAL_ASSURANCE.id,         // 84, permesso di attivare l'assurance interno
            // permissions.DISPLAY_INTERNAL_ASSURANCE.id,          // 85, permesso di visualizzare i dati e gli stati dell'assurance interno
            // permissions.SET_AND_MANAGE_INTERNAL_ASSURANCE.id,   // 86, permesso di settare i dati e gli stati dell'assurance interno


        ]
    },

    SRTool_ADMIN: {
        name: "GriAdmin",
        id: 2,
        label: "SR Tool Admin",
        permissions: [
            permissions.LOGIN.id,                                   // 1, accesso
            permissions.CREATE_NEW_COLLECT.id,                      // 2, creazione di una nuova raccolta
            permissions.ADD_MATERIAL_THEME.id,                      // 3, nuova tematica materiale
            permissions.EDIT_MATERIAL_THEME.id,                     // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                                // 5, nuovo utente
            permissions.EDIT_USER.id,                               // 6, modifica utente
            permissions.DELETE_USER.id,                             // 7, elimina utente
            permissions.EDIT_COLLECT.id,                            // 8, modifica raccolta
            permissions.DELETE_COLLECT.id,                          // 9, elimina raccolta
            permissions.GET_SECTOR_QUESTION.id,                     // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                             // 16, presa dei dati delle raccolte
            permissions.SET_COLLECT_MANAGER.id,                     // 40, permesso di impostare un manager alla raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id,    // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,   // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id,    // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,  // 68, permesso di scaricare l'allegato della raccolta
            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono
            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            permissions.EDIT_COLLECT_YEAR.id, // 78, permesso di cambiare anno della raccolta
            // permissions.EDIT_COLLECT_START.id, // 79, permesso di cambiare l'inizio della raccolta
            // permissions.EDIT_COLLECT_END.id, // 80, permesso di la fine della raccolta
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori
            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            permissions.ANSWER_SRToolINFORMATIVE_REQUEST.id, // 88, permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool

            permissions.ASSURANCE_ATTACHMENT_DOWNLOAD.id, // 90, permesso di scaricare allegati per fine assurance

            // internal Assurance
            // permissions.ACTIVATE_INTERNAL_ASSURANCE.id,             // 84, permesso di attivare l'assurance interno
            // permissions.DISPLAY_INTERNAL_ASSURANCE.id,              // 85, permesso di visualizzare i dati e gli stati dell'assurance interno
            // permissions.SET_AND_MANAGE_INTERNAL_ASSURANCE.id,       // 86, permesso di settare i dati e gli stati dell'assurance interno
        ]
    },

    SRTool_MANAGER: {
        name: "GriManager",
        id: 3,
        label: "SR Tool Manager",
        permissions: [
            permissions.LOGIN.id,                                   // 1, accesso
            permissions.GET_SECTOR_QUESTION.id,                     // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                             // 16, presa dei dati delle raccolte
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id,    // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,   // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id,    // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,  // 68, permesso di scaricare l'allegato della raccolta
            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono
            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa

            permissions.GET_COLLECT.id,                             // 16, presa dei dati delle raccolte
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id,    // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,   // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id,    // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,  // 68, permesso di scaricare l'allegato della raccolta
            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono
            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori
            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            permissions.ANSWER_SRToolINFORMATIVE_REQUEST.id, // 88, permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool
        ]
    },

    SR_ADMIN: {
        name: "SRAdmin",
        id: 4,
        label: "SI Rating Admin",
        permissions: [
            permissions.LOGIN.id,                           // 1, accesso
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
            permissions.ACCESS_PDF_PARAMETER_MODAL.id,      // 48, accesso alla pagina dei parametri pdf
            permissions.DOWNLOAD_PROFILING_PDF.id,          // 50, download pdf profilazione
            // permissions.DISPLAY_RATING_LEGEND.id,           // 60, permesso di visualizzare la legenda della valutazione
            permissions.SET_ASSURANCE.id,                   // 75, permesso di impostare l'assurance
        ]
    },

    SI_Scoring_Admin: {
        name: "SiScoringAdmin",
        id: 6,
        label: "SI Scoring Admin",
        permissions: [
            permissions.LOGIN.id,                           // 1, accesso
            //permissions.ADD_NEW_SCORING_RATING.id,             //95, permesso di aggiungere una nuova valutazione al SiScoring
            permissions.READ_SCORE_MODAL.id, 				    //44 acesso alla score modal
            permissions.READ_SGD_SCORE_MODAL.id,               //45 aceesso alla score SDG modal
            permissions.READ_CRITERIA_MODAL.id, 					        //46 aceesso alla modale dei criteri
            permissions.ACCESS_PDF_PARAMETER_MODAL.id, 		//48 accesso alla pagina dei parametri pdf
            //permissions.GET_ARCHIVED_RATINGS.id ,                  // 93  permesso di selezionare il toggler che mostra le valutazioni archiviate
            // ,permissions.ADD_USER.id,                        // 5, nuovo utente
            // permissions.EDIT_USER.id,                       // 6, modifica utente
            // permissions.DELETE_USER.id,                     // 7, elimina utente
            permissions.READ_STATUS_MODAL.id,               // 47, accesso alla state modal
            //permissions.SEE_RATING_ANSWER_SCORE.id,            //94  permesso di vedere il punteggio delle risposte delle domande delle valutazioni"
            //permissions.DISPLAY_RATING_LEGEND.id,              //60  permesso di visualizzare la legenda della valutazione"
            //permissions.DOWNLOAD_XML_SDG.id, 					//53  download xml degli SDG
            //permissions.DISPLAY_RATING_TABLE_LEGEND.id           83 permesso di visualizzare la tabella-legenda della valutazione
        ]
    },


    Parità_Di_Genere_Admin: {
        name: "ParitaGenereAdmin",
        id: 7,
        label: "Parità Di Genere Admin",
        permissions: [
            permissions.LOGIN.id,                           // 1, accesso
            // ,permissions.ADD_USER.id,                        // 5, nuovo utente
            // permissions.EDIT_USER.id,                       // 6, modifica utente
            // permissions.DELETE_USER.id,                     // 7, elimina utente
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
            permissions.READ_SCORE_MODAL.id, // 44, "acesso alla score modal" ,
            permissions.READ_SGD_SCORE_MODAL.id, // 45 , aceesso alla score SDG modal,
            permissions.ACCESS_PDF_PARAMETER_MODAL.id, // 48,   "accesso alla pagina dei parametri pdf"
            permissions.READ_CRITERIA_MODAL.id,             // 46, aceesso alla modale dei criteri
            permissions.READ_STATUS_MODAL.id,               // 47, accesso alla state modal


            //permissions.ADD_RATING.id,                         // 10, nuova valutazione
            // permissions.SET_VALIDATION_CONFIGURATION.id,      //18 , permesso di settare la configurazione delle domande come validata
            // permissions.SET_COMPLETATION_CONFIGURATION.id,   //19, "permesso di settare la configurazione delle domande come completata"
            // permissions.GO_TO_RATING_CONFIGURATION_AFTER_COMPLETATION.id, //29, permesso di configurare una valutazione dopo esser stata completata
            // permissions.RATING_AUDIT.id,  // 30, permesso di far audit
            // permissions.SHOW_RATING_AUDITED.id, //31,  permesso di vedere audit,
            // permissions.RATING_AUDIT_CONFIRM.id, //32,   npermesso di confermare l'audit
            // permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM.id, //33,  permesso di continuare l'audit dopo la conferma
            // permissions.RATING_AUDIT_CONTINUE_AFTER_VALIDATION.id, //34,   "permesso di continuare l'audit dopo la validazione"
            // permissions.EDIT_ANSWER_SCORE.id, // 59,  "permesso di cambiare il punteggio di una risposta"
            // permissions.DISPLAY_RATING_LEGEND.id , // 60, "permesso di visualizzare la legenda della valutazione"
            // permissions.SIRATING_READ_MATERIALITY_LEVEL.id,  // 69, "permesso di lettura livello di materialità"
            //permissions.NAVIGATE_TO_PERIMETRAL.id, //72,  "permesso di navigazione alla sezione di perimetro"
            // permissions.SIRATING_EDIT_MATERIALITY_LEVEL.id,  // 73,      "permesso di modifica livello di materialità"
            // permissions.DISPLAY_RATING_TABLE_LEGEND.id,  //: 83,   "permesso di visualizzare la tabella-legenda della valutazione"
            // permissions.GET_ARCHIVED_RATINGS.id, //93,   "permesso di selezionare il toggler che mostra le valutazioni archiviate"
            // permissions.SEE_RATING_ANSWER_SCORE.id, // 94,  "permesso di vedere il punteggio delle risposte delle domande delle valutazioni"
            // permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
        ]
    },

    ARB_OPERATOR: {
        name: "ArbOperator",
        id: 5,
        label: "ARB Operator - ARB SBPA",
        permissions: [
            permissions.LOGIN.id,                                           // 1, accesso
            permissions.ADD_SECTOR_QUESTION.id,                             // 11, Nuova domanda di settore
            permissions.EDIT_SECTOR_QUESTION.id,                            // 12, modifica domanda di settore
            permissions.DELETE_SECTOR_QUESTION.id,                          // 14, eliminazione domanda di settore
            permissions.GET_SECTOR_QUESTION.id,                             // 15, lettura domanda di settore
            permissions.SET_COMPLETATION_CONFIGURATION.id,                  // 19, permesso di settare la configurazione delle domande come completata
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,                     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,                    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,                      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,                      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,                       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,                      // 25, permesso di scaricare l'allegato della valutazione
            permissions.RATING_ATTACHMENT_DELETE.id,                        // 26, permesso di eliminare l'allegato della valutazione
            permissions.RATING_ATTACHMENT_UPLOAD.id,                        // 27, permesso di caricare l'allegato della valutazione
            permissions.RATING_AUDIT.id,                                    // 30, permesso di far audit
            permissions.SHOW_RATING_AUDITED.id,                             // 31, permesso di vedere audit
            permissions.RATING_AUDIT_CONFIRM.id,                            // 32, permesso di confermare l'audit
            permissions.SHOW_COMPLETED_READONLY_PERIMETRAL_QUESTION.id,     // 42, permesso di vedere in sola lettura le domande di perimetro
            permissions.CLOSE_PERIMETRAL_QUESTION.id,                       // 43, permesso di completare o di validare le domande di perimetro
            permissions.READ_SCORE_MODAL.id,                                // 44, acesso alla score modal
            permissions.READ_SGD_SCORE_MODAL.id,                            // 45, aceesso alla score SDG modal
            permissions.READ_CRITERIA_MODAL.id,                             // 46, aceesso alla modale dei criteri
            permissions.READ_STATUS_MODAL.id,                               // 47, accesso alla state modal
            permissions.ACCESS_PDF_PARAMETER_MODAL.id,                      // 48, accesso alla pagina dei parametri pdf
            permissions.DOWNLOAD_RATING_PDF.id,                             // 49, download pdf rating
            permissions.DOWNLOAD_PROFILING_PDF.id,                          // 50, download pdf profilazione
            permissions.DOWNLOAD_ANSWERS_PDF.id,                            // 51, download pdf risposte
            permissions.DOWNLOAD_XML_MATERIALITY.id,                        // 52, download xml di Materialità
            permissions.DOWNLOAD_XML_SDG.id,                                // 53, download xml degli SDG
            permissions.EDIT_TEXT_ANSWER_SCORE.id,                          // 58, permesso di cambiare il punteggio di una risposta testuale
            permissions.DISPLAY_RATING_LEGEND.id,                           // 60, permesso di visualizzare la legenda della valutazione
            permissions.DISPLAY_RATING_TABLE_LEGEND.id,                     // 83, permesso di visualizzare la tabella-legenda della valutazione
            permissions.SIRATING_READ_MATERIALITY_LEVEL.id,                 // 69, permesso di lettura livello di materialità
            permissions.SIRATING_EDIT_MATERIALITY_LEVEL.id,                 // 73, permesso di modifica livello di materialità
            permissions.GET_COLLECT.id,                                     // 16, presa dei dati delle raccolte
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,           // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,          // 68, permesso di scaricare l'allegato della raccolta
            permissions.SET_COLLECT_MANAGER.id,                             // 40, permesso di impostare un manager alla raccolta
            permissions.EDIT_COLLECT.id,                                    // 8, modifica raccolta
            // permissions.CREATE_NEW_COLLECT.id,                              // 2, creazione di una nuova raccolta

            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono

            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            permissions.EDIT_COLLECT_YEAR.id, // 78, permesso di cambiare anno della raccolta
            // permissions.EDIT_COLLECT_START.id, // 79, permesso di cambiare l'inizio della raccolta
            // permissions.EDIT_COLLECT_END.id, // 80, permesso di la fine della raccolta
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori

            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            permissions.ASSURANCE_ATTACHMENT_DOWNLOAD.id, // 90, permesso di scaricare allegati per fine assurance

            // internal Assurance
            // permissions.ACTIVATE_INTERNAL_ASSURANCE.id,             // 84, permesso di attivare l'assurance interno
            // permissions.DISPLAY_INTERNAL_ASSURANCE.id,              // 85, permesso di visualizzare i dati e gli stati dell'assurance interno
            // permissions.SET_AND_MANAGE_INTERNAL_ASSURANCE.id,       // 86, permesso di settare i dati e gli stati dell'assurance interno

            permissions.EDIT_COMPANY.id,                               // 56, permesso di modificare i dati di una azienda

            // copiati da SRToolAdmin
            permissions.LOGIN.id,                                   // 1, accesso
            permissions.CREATE_NEW_COLLECT.id,                      // 2, creazione di una nuova raccolta
            permissions.ADD_MATERIAL_THEME.id,                      // 3, nuova tematica materiale
            permissions.EDIT_MATERIAL_THEME.id,                     // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                                // 5, nuovo utente
            permissions.EDIT_USER.id,                               // 6, modifica utente
            permissions.DELETE_USER.id,                             // 7, elimina utente
            permissions.EDIT_COLLECT.id,                            // 8, modifica raccolta
            permissions.DELETE_COLLECT.id,                          // 9, elimina raccolta
            permissions.GET_SECTOR_QUESTION.id,                     // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                             // 16, presa dei dati delle raccolte
            permissions.SET_COLLECT_MANAGER.id,                     // 40, permesso di impostare un manager alla raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id,    // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,   // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id,    // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,  // 68, permesso di scaricare l'allegato della raccolta
            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono
            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            // permissions.EDIT_COLLECT_YEAR.id, // 78, permesso di cambiare anno della raccolta
            // permissions.EDIT_COLLECT_START.id, // 79, permesso di cambiare l'inizio della raccolta
            // permissions.EDIT_COLLECT_END.id, // 80, permesso di la fine della raccolta
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori
            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            // internal Assurance
            // permissions.ACTIVATE_INTERNAL_ASSURANCE.id,             // 84, permesso di attivare l'assurance interno
            // permissions.DISPLAY_INTERNAL_ASSURANCE.id,              // 85, permesso di visualizzare i dati e gli stati dell'assurance interno
            // permissions.SET_AND_MANAGE_INTERNAL_ASSURANCE.id,       // 86, permesso di settare i dati e gli stati dell'assurance interno
            permissions.ANSWER_SRToolINFORMATIVE_REQUEST.id, // 88, permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool


            permissions.GET_ARCHIVED_COLLECTS.id,   // 92, permesso di selezionare il toggler che mostra le raccolte archiviate
            permissions.GET_ARCHIVED_RATINGS.id,    // 93, permesso di selezionare il toggler che mostra le valutazioni archiviate


            permissions.SEE_RATING_ANSWER_SCORE.id, // 94, permesso di vedere il punteggio delle risposte delle domande delle valutazioni

]
    },

    SUPER_ADMIN: {
        name: "SuperAdmin",
        id: 9,
        label: "Super Admin",
        permissions: [
            permissions.LOGIN.id,                           // 1, accesso
            // permissions.ADD_MATERIAL_THEME.id,              // 3, nuova tematica materiale
            // permissions.EDIT_MATERIAL_THEME.id,             // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                        // 5, nuovo utente
            permissions.EDIT_USER.id,                       // 6, modifica utente
            permissions.DELETE_USER.id,                     // 7, elimina utente
            permissions.EDIT_COLLECT.id,                    // 8, modifica raccolta
            permissions.GET_SECTOR_QUESTION.id,             // 15, lettura domanda di settore
            permissions.CREATE_NEW_COLLECT.id,
            permissions.GET_COLLECT.id,                     // 16, presa dei dati delle raccolte
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id, // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id, // 68, permesso di scaricare l'allegato della raccolta
            permissions.SET_COLLECT_MANAGER.id,             // 40, permesso di impostare un manager alla raccolta
            permissions.READ_SCORE_MODAL.id,                // 44, acesso alla score modal
            permissions.READ_SGD_SCORE_MODAL.id,            // 45, aceesso alla score SDG modal
            permissions.READ_CRITERIA_MODAL.id,             // 46, aceesso alla modale dei criteri
            permissions.READ_STATUS_MODAL.id,               // 47, accesso alla state modal
            permissions.ACCESS_PDF_PARAMETER_MODAL.id,      // 48, accesso alla pagina dei parametri pdf
            permissions.DOWNLOAD_RATING_PDF.id,             // 49, download pdf rating
            permissions.DOWNLOAD_PROFILING_PDF.id,          // 50, download pdf profilazione
            permissions.DELETE_COMPANY.id,                  // 57, permesso di eliminare una azienda
            // permissions.DISPLAY_RATING_LEGEND.id,           // 60, permesso di visualizzare la legenda della valutazione
            // permissions.READ_ORGANIZATION_ACTIVE_SERVICES.id, // 70, permesso di lettura dei servizi attivi delle organizzazioni
            permissions.READ_COMPANY.id,                    // 37, permesso di lettura delle aziende
            permissions.CREATE_COMPANY.id,                  // 55, permesso di creare una nuova azienda
            // permissions.EDIT_SUPER_ADMIN_ROLE_USER.id,      // 71, permesso di modifica di un utente di tipo Super Admin

            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono

            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            permissions.EDIT_COLLECT_YEAR.id,                    // 78, permesso di cambiare anno della raccolta
            // permissions.EDIT_COLLECT_START.id,                   // 79, permesso di cambiare l'inizio della raccolta
            // permissions.EDIT_COLLECT_END.id,                     // 80, permesso di la fine della raccolta
            permissions.MANAGE_COLLECT.id,                          // 81, permesso di gestione della raccolta, configurazione, inserimento valori

            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            permissions.ASSURANCE_ATTACHMENT_DOWNLOAD.id, // 90, permesso di scaricare allegati per fine assurance

            // internal Assurance
            // permissions.ACTIVATE_INTERNAL_ASSURANCE.id,         // 84, permesso di attivare l'assurance interno
            // permissions.DISPLAY_INTERNAL_ASSURANCE.id,          // 85, permesso di visualizzare i dati e gli stati dell'assurance interno
            // permissions.SET_AND_MANAGE_INTERNAL_ASSURANCE.id,   // 86, permesso di settare i dati e gli stati dell'assurance interno


            // copiati da SRToolAdmin
            permissions.LOGIN.id,                                   // 1, accesso
            permissions.CREATE_NEW_COLLECT.id,                      // 2, creazione di una nuova raccolta
            permissions.ADD_MATERIAL_THEME.id,                      // 3, nuova tematica materiale
            permissions.EDIT_MATERIAL_THEME.id,                     // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                                // 5, nuovo utente
            permissions.EDIT_USER.id,                               // 6, modifica utente
            permissions.DELETE_USER.id,                             // 7, elimina utente
            permissions.EDIT_COLLECT.id,                            // 8, modifica raccolta
            permissions.DELETE_COLLECT.id,                          // 9, elimina raccolta
            permissions.GET_SECTOR_QUESTION.id,                     // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                             // 16, presa dei dati delle raccolte
            permissions.SET_COLLECT_MANAGER.id,                     // 40, permesso di impostare un manager alla raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id,    // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,   // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id,    // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,  // 68, permesso di scaricare l'allegato della raccolta
            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono
            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            // permissions.EDIT_COLLECT_YEAR.id, // 78, permesso di cambiare anno della raccolta
            // permissions.EDIT_COLLECT_START.id, // 79, permesso di cambiare l'inizio della raccolta
            // permissions.EDIT_COLLECT_END.id, // 80, permesso di la fine della raccolta
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori
            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            // internal Assurance
            // permissions.ACTIVATE_INTERNAL_ASSURANCE.id,             // 84, permesso di attivare l'assurance interno
            // permissions.DISPLAY_INTERNAL_ASSURANCE.id,              // 85, permesso di visualizzare i dati e gli stati dell'assurance interno
            // permissions.SET_AND_MANAGE_INTERNAL_ASSURANCE.id,       // 86, permesso di settare i dati e gli stati dell'assurance interno
            permissions.ANSWER_SRToolINFORMATIVE_REQUEST.id, // 88, permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool

            permissions.EDIT_COMPANY.id,  // 56   "permesso di modificare i dati di una azienda"
            permissions.DELETE_COMPANY.id, // 57 "permesso di eliminare una azienda"


            // copiati da SiRoadAdmin

            permissions.LOGIN.id, // 1, accesso
            permissions.CREATE_NEW_COLLECT.id,              // 2, creazione di una nuova raccolta
            permissions.ADD_MATERIAL_THEME.id,              // 3, nuova tematica materiale
            permissions.EDIT_MATERIAL_THEME.id,             // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                        // 5, nuovo utente
            permissions.EDIT_USER.id,                       // 6, modifica utente
            permissions.DELETE_USER.id,                     // 7, elimina utente
            permissions.DELETE_COLLECT.id,                  // 9, elimina raccolta
            permissions.GET_SECTOR_QUESTION.id,             // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                     // 16, presa dei dati delle raccolte
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
            permissions.SET_COLLECT_MANAGER.id,             // 40, permesso di impostare un manager alla raccolta
            permissions.READ_SCORE_MODAL.id,                // 44, acesso alla score modal
            permissions.READ_SGD_SCORE_MODAL.id,            // 45, aceesso alla score SDG modal
            permissions.READ_CRITERIA_MODAL.id,             // 46, aceesso alla modale dei criteri
            permissions.READ_STATUS_MODAL.id,               // 47, accesso alla state modal
            permissions.ACCESS_PDF_PARAMETER_MODAL.id,      // 48, accesso alla pagina dei parametri pdf
            permissions.DOWNLOAD_RATING_PDF.id,             // 49, download pdf rating
            permissions.DOWNLOAD_PROFILING_PDF.id,          // 50, download pdf profilazione
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_UPLOAD.id, // 65, permesso di caricare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id, // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DELETE.id, // 67, permesso di eliminare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id, // 68, permesso di scaricare l'allegato della raccolta
            permissions.ADD_REPETITIONS_ENTITY.id, // 82, permesso di aggiungere una nuova entità per i requisiti che si ripetono
            permissions.SHOW_ASSURANCE.id, // 76, permesso di sapere se è presente l'assurance
            permissions.SET_ASSURANCE.id,                           // 75, permesso di abilitare l'assurance
            permissions.ACTIVATE_ASSURANCE.id,                      // 77, permesso di avviare l'assurance
            permissions.EDIT_COLLECT_YEAR.id, // 78, permesso di cambiare anno della raccolta
            permissions.MANAGE_COLLECT.id,   // 81, permesso di gestione della raccolta, configurazione, inserimento valori
            permissions.SET_SRTOOL_INFORMATIVE_COMPLETE.id, // 87, permesso di settare a completato o no, l'informativa
            permissions.ANSWER_SRToolINFORMATIVE_REQUEST.id, // 88, permesso di rispondere alla richiesta di chiarimento per una informativa dell'SRTool
            permissions.ASSURANCE_ATTACHMENT_DOWNLOAD.id, // 90, permesso di scaricare allegati per fine assurance


        ]
    },


    ASSURANCER: {
        name: "Assurancer",
        id: 13,
        label: "Assurance Operator",
        permissions: [
            permissions.LOGIN.id,                                   // 1, accesso
            permissions.GET_COLLECT.id,                             // 16, presa dei dati delle raccolte
            permissions.SHOW_ASSURANCE.id,                          // 76, permesso di sapere se è presente l'assurance
            permissions.ASSURANCE_ATTACHMENT_UPLOAD.id,             // 89, permesso di caricare allegati per fine assurance
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DISPLAY.id,   // 66, permesso di visualizzare l'allegato della raccolta
            permissions.SRTOOL_INFORMATIVE_ATTACHMENT_DOWNLOAD.id,  // 68, permesso di scaricare l'allegato della raccolta
            permissions.ASSURANCE_ATTACHMENT_DOWNLOAD.id,           // 90, permesso di scaricare allegati per fine assurance
            permissions.ASSURANCE_ATTACHMENT_DELETE.id,             // 91, permesso di eliminare allegati per fine assurance

        ]
    },


    CONSULTANT: {
        name: "Consultant",
        id: 11,
        label: "Consulente",
        permissions: [
            permissions.LOGIN.id,                           // 1, accesso
            permissions.CREATE_NEW_COLLECT.id,              // 2, creazione di una nuova raccolta
            permissions.ADD_MATERIAL_THEME.id,              // 3, nuova tematica materiale
            permissions.EDIT_MATERIAL_THEME.id,             // 4, modifica tematica mayeriale
            permissions.ADD_USER.id,                        // 5, nuovo utente
            permissions.EDIT_USER.id,                       // 6, modifica utente
            permissions.DELETE_USER.id,                     // 7, elimina utente
            permissions.EDIT_COLLECT.id,                    // 8, modifica raccolta
            permissions.DELETE_COLLECT.id,                  // 9, elimina raccolta
            permissions.GET_SECTOR_QUESTION.id,             // 15, lettura domanda di settore
            permissions.GET_COLLECT.id,                     // 16, presa dei dati delle raccolte
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
            permissions.READ_SCORE_MODAL.id,                // 44, acesso alla score modal
            permissions.READ_SGD_SCORE_MODAL.id,            // 45, aceesso alla score SDG modal
            permissions.READ_CRITERIA_MODAL.id,             // 46, aceesso alla modale dei criteri
            permissions.READ_STATUS_MODAL.id,               // 47, accesso alla state modal
            permissions.ACCESS_PDF_PARAMETER_MODAL.id,      // 48, accesso alla pagina dei parametri pdf
            permissions.DOWNLOAD_PROFILING_PDF.id,          // 50, download pdf profilazione
            permissions.DOWNLOAD_ANSWERS_PDF.id,            // 51, download pdf risposte
            permissions.CREATE_COMPANY.id,                  // 55, permesso di creare una nuova azienda
            // permissions.EDIT_COMPANY.id,                    // 56, permesso di modificare i dati di una azienda
            permissions.DELETE_COMPANY.id,                  // 57, permesso di eliminare una azienda
        ]
    },

    SR_CONSULER: {
        name: "ConsultantSr",
        id: 12,
        label: "Consulente SR",
        permissions: [
            permissions.LOGIN.id,                           // 1, accesso
            permissions.QUESTION_ATTACHMENT_DISPLAY.id,     // 20, permesso di vedere allegati della domanda
            permissions.QUESTION_ATTACHMENT_DOWNLOAD.id,    // 21, permesso di scaricare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_DELETE.id,      // 22, permesso di eliminare l'allegato della domanda
            permissions.QUESTION_ATTACHMENT_UPLOAD.id,      // 23, permesso di caricare l'allegato della domanda
            permissions.RATING_ATTACHMENT_DISPLAY.id,       // 24, permesso di vedere allegati della valutazione
            permissions.RATING_ATTACHMENT_DOWNLOAD.id,      // 25, permesso di scaricare l'allegato della valutazione
        ]
    }

};

export const roleTree = {
    data: role.PROJECT_MANAGER,
    branches: [
        {
            data: role.SUPER_ADMIN,
            branches: [
                {
                    data: role.ADMIN,
                    branches: [
                        {
                            data: role.SRTool_ADMIN,
                            branches: [
                                {
                                    data: role.SRTool_MANAGER,
                                    branches: []
                                }
                            ]
                        },
                        {
                            data: role.SR_ADMIN,
                            branches: []
                        }
                    ]
                },
                {
                    data: role.CONSULTANT,
                    branches: []
                },
                {
                    data: role.ARB_OPERATOR,
                    branches: []
                }
            ]
        }
    ]
};
