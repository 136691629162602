import {Component} from "react";
import {validationSuperAdminNewUser} from "../../../business-logic/companies";
import {saveNewUserBySuperAdmin} from "../../../business-logic/users";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteUserBySuperAdmin} from "../../../services/users-service";
import {deleteServiceBySuperAdmin} from "../../../services/active-service";
import {errorlog, showlog} from "../../../utils/dev-utils";
import {deleteAnswerChoiche, deleteSectorQuestion} from "../../../business-logic/rating";

export default class DeleteAnswerChoicheModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.onClose( forceRefresh );
	}

	render() {


		showlog("modale di prompt di eleiminazione");
		showlog( this.props );



		let context = (
			<span>Sicuro di voler eliminare la risposta?</span>
		);

		if( !!this.props.message ) context = this.props.message;

		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				<ModalHeader toggle={this.toggle} className={ "modal-colored-header bg-danger" }>
					ATTENZIONE
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {
							console.log("click su conferma");

							deleteAnswerChoiche( this.props.choiche.id )
								.then( () => {
									this.props.onConfirm( this.props.choiche );
								})
								.catch(e => {
									errorlog("errore", e );
								})

						}}
					>
						OK
					</Button>{' '}
					<Button color="link" onClick={this.toggle}>
						annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;
	}

	componentDidMount() {}
}
