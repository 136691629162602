import React, {Component} from "react";
import {COLOR_POLICY, LANGUAGES, permissions, role} from "../../../env";
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import Select from 'react-select';
import "../modal.css";
import {getBaseCompaniesList, validationSuperAdminNewUser} from "../../../business-logic/companies";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getLastElementOr, onlyNotNull} from "../../../utils/array-utils";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import ActionButton from "../../save-button";
import {loadOrganizzations} from "../../../business-logic/organizzations";
import {translate} from "../../i18n/translate-function";


export default class SuperAdminUserEditModalContent extends Component {

	state = {
		show: true,
		inWait: true,
		user: {},
		companies: [],
		openConfirmNewUserModal: false
	}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		let refresh = false;
		if( typeof forceRefresh === "boolean" && !!forceRefresh ) refresh = true;
		this.props.close( refresh );
	}

	render() {

		renderlog("PROJECT_MANAGER UserEditModal", this.state, this.props, COLOR_POLICY.validated);

		let context = (
			<AvForm>

				{/*     TITOLO  --------------------------------------------------------------------------------
				<FormGroup>
					<Label for="title">Titolo</Label>

					<Select
						className="react-select"
						classNamePrefix="react-select"
						name="title"
						id="title"
						onChange={ changeEvent => {

							this.setState({
								user: Object.assign(
									{},
									this.state.user,
									{ title: changeEvent.value }
								)
							})
						}}
						options={[
							{ value: TITLES.MR, label: TITLES_NAME.MR.it },
							{ value: TITLES.DOCT, label: TITLES_NAME.DOCT.it },
							{ value: TITLES.ENG, label: TITLES_NAME.ENG.it }
						]}
						value={
							(!!this.state.user.title)
								? (
									{
										value:
											Object.keys(TITLES)
												.filter(t => TITLES[t] === this.state.user.title )
												.join(""),
										label:
											TITLES_NAME[
												Object.keys(TITLES_NAME)
													.filter(t => TITLES_NAME[t].it === this.state.user.title )
													.join("")
												].it
									}
								)
								: null // { value: TITLES.MR, label: TITLES_NAME.MR.it }
						}
						placeholder={ <span className={"select-placeholder"}>Seleziona titolo</span> }
					/>
					{
						( !!this.state.errors && !!this.state.errors.title )
							? <label className="error-message-label">{ this.state.errors.title }</label>
							: null
					}
				</FormGroup>
				*/}

				{/*     COGNOME  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="lastName">Cognome</Label>
					<AvInput
						type="text"
						name="lastName"
						id="lastName"
						value={ this.state.user.lastName }
						onChange={ changeTextEvent => {

							// showlog("cambio lastName");
							this.setState(
								{
									user: Object.assign(
										{},
										this.state.user,
										{ lastName: changeTextEvent.target.value }
									),
									errors: {
										...this.state.errors,
										lastName: undefined
									}
								},
								newState => {
									// showlog("email cambiata");
									// showlog( this.state );
								}
							)
						}}
						required
					/>
					{
						( !!this.state.errors && !!this.state.errors.lastName )
							? <label className="error-message-label">{ this.state.errors.lastName }</label>
							: null
					}
				</AvGroup>

				{/*     NOME  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="firstName">Nome</Label>
					<AvInput
						type="text"
						name="firstName"
						id="firstName"
						value={ this.state.user.firstName }
						onChange={ changeTextEvent => {

							// // showlog("cambio firstName");
							this.setState(
								{
									...this.state,
									user: Object.assign(
										{},
										this.state.user,
										{ firstName: changeTextEvent.target.value }
									),
									errors: {
										...this.state.errors,
										firstName: undefined
									}
								},
								newState => {
									// showlog("email cambiata");
									// showlog( this.state );
								}
							)
						}}
						required
					/>
					{
						( !!this.state.errors && !!this.state.errors.firstName )
							? <label className="error-message-label">{ this.state.errors.firstName }</label>
							: null
					}
				</AvGroup>

				{/*     RUOLO  -------------------------------------------------------------------------------- */}
				<FormGroup>
					<Label for="title">Ruolo</Label>
					<Select
						className="react-select"
						classNamePrefix="react-select"
						name="role"
						id="role"
						onChange={ changeEvent => {

							this.setState({
								user: Object.assign(
									{},
									this.state.user,
									{
										role: changeEvent.label,
										companyId: ( ( changeEvent.label === role.SUPER_ADMIN.label ) ? 0 : this.state.user.companyId )
									}
								),
								errors: {
									...this.state.errors,
									role: undefined
								}
							})

						}}
						options={
							Object.keys(role)
								.map( r => role[ r ])
								.filter( r => r.id !== role.PROJECT_MANAGER.id && r.id !== role.MASTER.id && r.id !== role.CONSULTANT.id && r.id !== role.SR_CONSULER.id )
								.filter( r => r.id !== role.SRTool_ADMIN.id && r.id !== role.SRTool_MANAGER.id && r.id !== role.ASSURANCER.id ) //---- rimozione srTool scadenza GRI
								.map( r => {
									if( !!isUserRole( role.SUPER_ADMIN ) ) {
										if(
											r.id === role.ARB_OPERATOR.id ||
											r.id === role.PROJECT_MANAGER.id ||
											r.id === role.ASSURANCER.id
										) {
											return null;
										}
									}
									return r;
								})
								.filter( onlyNotNull )
								.filter( r => !(!!isUserRole( role.SUPER_ADMIN ) && r.id === role.SUPER_ADMIN.id) )
								.map( r => ({value: r.id, label: r.label}) )
						}
						value={
							(!!this.state.user.role)
								? (
									Object.keys(role)
										.filter( r => role[r].label === this.state.user.role )
										.map( r => ({value: role[r].id, label: role[r].label}) )
										.reduce( getLastElementOr, {label: "Nessun Ruolo", value: -1} )
								)
								: null
						}
						placeholder={ <span className={"select-placeholder"}>Seleziona ruolo</span> }
					/>
					{
						( !!this.state.errors && !!this.state.errors.role )
							? <label className="error-message-label">{ this.state.errors.role }</label>
							: null
					}
				</FormGroup>






				{/*     AZIENDA  -------------------------------------------------------------------------------- */}

				{
					// ( this.state.user.role === role.PROJECT_MANAGER.id )
					( isUserRole( role.PROJECT_MANAGER ) )
						? (


							(
								(
									!!this.state.user &&
									!!this.state.user.role &&
									this.state.user.role === role.SUPER_ADMIN.label
								)
									? (
										<FormGroup>
											<Label for="title">Organizzazione { (!this.state.organizzations) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
											<Select
												className="react-select"
												classNamePrefix="react-select"
												name="organizzations"
												id="organizzations"
												onChange={ changeEvent => {

													showlog("organizzazione scelta");
													showlog( changeEvent );

													this.setState({
														...this.state,
														user: {
															...this.state.user,
															organizzation: changeEvent
														}
													})

												}}
												options={
													(!!this.state.organizzations)
														? (
															this.state.organizzations
																.map( org => ({value: org.id, label: org.name }))
														)
														: []
												}
												value={
													(!!this.state.user.organizzation)
														? this.state.user.organizzation
														: (
															(!!this.state.organizzations)
																? (
																	this.state.organizzations
																		// .filter( org => org.id === this.props.user.leadingcompany_id )
																		.filter( org => {

																			if( !!this.props.user ) {
																				return (org.id === this.props.user.leadingcompany_id);
																			}
																			else {
																				return (org.id === this.state.user.leadingcompany_id);
																			}

																		})
																		.map( org => ({value: org.id, label: org.name }) )
																		.reduce( getLastElementOr, null )
																)
																: null
														)
												}
												placeholder={ <span className={"select-placeholder"}>Seleziona Organizzazione</span> }
											/>
										</FormGroup>
									)
									: (

										(this.state.user.role !== role.ARB_OPERATOR.label)
											? (

												(
													(this.state.user.role === role.ASSURANCER.label)
														? null
														: (
															<FormGroup>
																<Label for="title">Azienda { (!this.state.companies) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
																<Select
																	className="react-select"
																	classNamePrefix="react-select"
																	name="company"
																	id="company"
																	onChange={ changeEvent => {

																		this.setState({
																			...this.state,
																			user: Object.assign(
																				{},
																				this.state.user,
																				{ companyId: parseInt(changeEvent.value, 10) },
																			),
																			errors: {
																				...this.state.errors,
																				company: undefined
																			}
																		})
																	}}
																	options={ (!!this.state.companies) ? this.state.companies.map( company => ({value: parseInt(company.id, 10), label: company.name })) : [] }
																	value={
																		(!!this.state.user.companyId)
																			? (
																				{
																					value: this.state.user.companyId,
																					label: this.state.companies.filter( c => c.id === this.state.user.companyId ).map(c => c.name).join(" ")
																				}
																			)
																			: null
																	}
																	placeholder={ <span className={"select-placeholder"}>Seleziona Azienda</span> }
																/>
																{
																	( !!this.state.errors && !!this.state.errors.company )
																		? <label className="error-message-label">{ this.state.errors.company }</label>
																		: null
																}
															</FormGroup>
														)
												)




											)
											: null



									)
							)



						)
						: null
				}



				{
					!!isUserRole( role.SUPER_ADMIN )
						? (
							<FormGroup>
								<Label for="title">Azienda { (!this.state.companies) ? <span className={"loading-wait-postfix"}>in caricamento...</span> : null}</Label>
								<Select
									className="react-select"
									classNamePrefix="react-select"
									name="company"
									id="company"
									onChange={ changeEvent => {

										// showlog("selezione azienda");
										/*
                                        showlog((
                                            {
                                                value: changeEvent.value,
                                                label: this.state.companies.filter( c => c.id === changeEvent.value ).map(c => c.name).join(" ")
                                            }
                                        ));
                                        */

										this.setState({
											...this.state,
											user: Object.assign(
												{},
												this.state.user,
												{ companyId: parseInt(changeEvent.value, 10) },
											),
											errors: {
												...this.state.errors,
												company: undefined
											}
										})
									}}
									options={ (!!this.state.companies) ? this.state.companies.map( company => ({value: parseInt(company.id, 10), label: company.name })) : [] }
									value={
										(!!this.state.user.companyId)
											? (
												{
													value: this.state.user.companyId,
													label: this.state.companies.filter( c => c.id === this.state.user.companyId ).map(c => c.name).join(" ")
												}
											)
											: null
									}
									placeholder={ <span className={"select-placeholder"}>Seleziona Azienda</span> }
								/>
								{
									( !!this.state.errors && !!this.state.errors.company )
										? <label className="error-message-label">{ this.state.errors.company }</label>
										: null
								}
							</FormGroup>
						)
						: null
				}



				{/*     EMAIL  -------------------------------------------------------------------------------- */}
				<AvGroup>
					<Label for="email">Email</Label>
					<AvInput
						type="email"
						name="email"
						id="email"
						placeholder="example@mail.com"
						value={ this.state.user.email }
						onChange={ changeTextEvent => {

							// showlog("cambio email");
							this.setState(
								{
									user: Object.assign(
										{},
										this.state.user,
										{ email: changeTextEvent.target.value }
									),
									errors: {
										...this.state.errors,
										email: undefined
									}
								},
								newState => {
									// showlog("email cambiata");
									// showlog( this.state );
								}
							)
						}}
						required
					/>
					{
						( !!this.state.errors && !!this.state.errors.email )
							? <label className="error-message-label">{ this.state.errors.email }</label>
							: null
					}
				</AvGroup>



				{/* LINGUA  */}

				<FormGroup >

							   <Label for="language_id">Lingua</Label>

                                <Select
                                    className="react-select gri-type-select"
                                    classNamePrefix="react-select"
                                    name="gri-type-select"
                                    id="gri-type-select"

									onChange={ changeEvent => {


											this.setState({
												...this.state,
												user: Object.assign(
													{},
													this.state.user,
													{ language_id: changeEvent.value },
												),
												errors: {
													...this.state.errors,
													language_id: undefined
												}
											})

										}}
                                    options={LANGUAGES}
									value={
										(!!this.state.user.language_id)
											? (
												LANGUAGES
												.filter(ol => (ol.value === this.state.user.language_id ))
												.reduce(getLastElementOr , null)
											)
											: null
									}
                                    placeholder={
										<span className={"select-placeholder"}>Seleziona Lingua</span>
									 }

                                    />

									{
										( !!this.state.errors && !!this.state.errors.language_id )
											? <label className="error-message-label">{ this.state.errors.language_id }</label>
											: null
									}


                    </FormGroup>



			</AvForm>
		)





		let content = (
			<Modal isOpen={ true }>
				<ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
					{
						// (!!this.state.user && JSON.stringify( this.state.user ) !== "{}")
						(!!this.props.isEdit)
							? "Modifica Utente"
							: "Nuovo Utente"
					}
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>

					{
						(!!this.state.notValidPassword)
							? null
							: (


								<React.Fragment>
								<ActionButton
									promise={
										() =>


											validationSuperAdminNewUser( this.state.user, !!this.props.user )

											 // Promise.resolve()
/*
											return (

												validationSuperAdminNewUser( this.state.user, !!this.props.user )
													.then( () => {


														// showlog(this.state);
														// debugger;

														if( !!this.props.user ) {


															showlog("edit user");
															showlog( this.state.user );
															return editUserBySuperAdmin( this.state.user );
														}
														else {
															saveNewUserBySuperAdmin( this.state.user )
																.then( () => {
																	// debugger;
																	return true;
																})
																.catch(e => {
																	errorlog("errore all'inserimento");
																	showlog(e);

																	let errorMessage = "";
																	if( typeof e === "string" ) errorMessage = e;
																	else {
																		if( typeof e === "object" && !!e.msg ) errorMessage = e.msg;
																		else {
																			if( typeof e === "object" ) errorMessage = JSON.stringify( e );
																		}
																	}

																	return (
																		new Promise((success, fail) => {

																			this.setState({
																				...this.state,
																				errors: {
																					...this.state.errors,
																					email: errorMessage
																				}
																			},
																				() => {
																				success();
																				}
																			)

																		})
																	);



																})
														}
													})
													.catch(e => {
														// debugger;
														return Promise.reject( e );
													})

											); */
											.then(
												showlog("USER", this.state.user)
											)

									}

									onResult={ result => {
										this.props.close({...this.state.user, isNewUser: !this.props.isEdit})

									}}
									onError={ e => {
										errorlog("salvataggio utente", e);
										let errors = {};
										e.map( kv => Object.assign( errors, kv ) );
										this.setState({
											...this.state,
											errors
										})
									}}
								/>


								{/*
								<Button
									color="primary"
									onClick={ clickEvent => {
										// showlog("click su salva");
										validationSuperAdminNewUser( this.state.user, !!this.props.user )
											.then( () => {
												// showlog("validazione passata");
												if( !!this.props.user ) {
													editUserBySuperAdmin( this.state.user )
														.then( () => {
															this.toggle( true );
														})
														.catch(e => {
															errorlog("errore al salvataggio",e);
															// alert( e.valueOf() );
														})
												}
												else {
													saveNewUserBySuperAdmin( this.state.user )
														.then( () => {
															this.toggle( true );
														})
														.catch(e => {
															errorlog("errore all'inserimento");
															showlog(e);

															let errorMessage = "";
															if( typeof e === "string" ) errorMessage = e;
															else {
																if( typeof e === "object" && !!e.msg ) errorMessage = e.msg;
																else {
																	if( typeof e === "object" ) errorMessage = JSON.stringify( e );
																}
															}

															// debugger;
															// if(  errorMessage.toLowerCase().indexOf("presente") !== -1 ) {
															// 	this.setState({
															// 		...this.state,
															// 		errors: {
															// 			...this.state.errors,
															// 			email: e
															// 		}
															// 	})
															// }


															this.setState({
																...this.state,
																errors: {
																	...this.state.errors,
																	email: errorMessage
																}
															})




														})
												}
											})
											.catch(e => {
												// showlog("catch alla validazione");
												// showlog(e);
												let errors = {};
												e.map( kv => Object.assign( errors, kv ) );
												this.setState({
													...this.state,
													errors
												})
											})
									}}
								>
									{
										// TODO cambiare con ActionButton
										(!!this.state.waiting)
											? (
												<React.Fragment>
													<Spinner color={"primary"} className="spinner-border-sm mr-1" />
													<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
												</React.Fragment>
											)
											: "Salva"
									}
								</Button>
								*/}
								</React.Fragment>
							)
					}

					<Button color="secondary" onClick={ clickEvent => this.props.close() }>
						{ translate("Close") }
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;

	}


	componentDidMount() {

		getBaseCompaniesList()
			.then( list => {

				this.setState({
					...this.state,
					companies: list,
					user: this.props.user || this.state.user
				});
			})
			.catch(e => {
				// errorlog("errore in super-admin/user-edit-modal > getAllCompanies");
			})


	}



	componentDidUpdate(prevProps, prevState, snapshot) {


		if(
			!!this.state.user &&
			JSON.stringify( this.state.user ) !== "{}" &&
			!!this.state.user.role &&
			(this.state.user.role === role.SUPER_ADMIN.label) &&
			!!hasPermission( permissions.GET_ORGANIZZATION ) &&
			!this.state.organizzations
		) {

			loadOrganizzations()
				.then(organizzations => {

					this.setState({
						organizzations: organizzations,
						user: {
							...this.state.user,
							organizzation:
								(
									(!!organizzations)
										? (
											organizzations
												.filter( org => {

													if( !!this.props.user ) {
														return (org.id === this.props.user.leadingcompany_id);
													}
													else {
														return (org.id === this.state.user.leadingcompany_id);
													}

												})
												.map( org => ({value: org.id, label: org.name }) )
												.reduce( getLastElementOr, null )
										)
										: null
								)
						}
					})


				})
				.catch(e => {
					errorlog("caricamento organizzazioni", e)
				})

		}


	}


};
