import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";

import PageTitle from "../../../../components/PageTitle";
import {
    PATH_PREFIX,
    RATING_STATUS,
    SIRATING_ADMIN_CHART_SDG,
    SIRATING_ADMIN_CHART_THEMATICS_ENV,
    SIRATING_ADMIN_CHART_THEMATICS_GOV,
    SIRATING_ADMIN_CHART_THEMATICS_SOC
} from "../../../../env";
import {errorlog, showlog} from "../../../../utils/dev-utils";
import {WaitingOrNodataMessage} from "../../../../components/waiting-or-nodata-message";
import {SiRatingAdminThemesChart} from "./thematics-chart";
import {SiRatingAdminSdgChart} from "./sdg-chart";
import CardHeader from "reactstrap/es/CardHeader";
import ActionButton from "../../../../components/save-button";
import RatingListModal from "../../../../components/area/rating-list-modal";
import {
    getRatingData,
    loadSiRatingAdminScoreThematicsChartData,
    loadSiRatingCrieriaChartData,
    storeRatingData
} from "../../../../business-logic/rating";
import {loadEvaluations} from "../../../../business-logic/active-service";
import {isNotEmptyArray} from "../../../../utils";
import {translate} from "../../../../components/i18n/translate-function";


export default class SIRatingAdminChartPage extends React.Component {


    state = {
        evaluation: "",
        environmental_materiality:"",
        environmental:"",
        governance:"",
        governance_materiality:"",
        social:"",
        social_materiality:"",
        score: null,
        evaluationModal: false,
        ratingValues:null,
        thematicScores:null,
        thematicChartValueEnv:null,
        thematicChartValueSoc:null,
        thematicChartValueGov:null,
        resilience: 0,
        transparency: 0,
        improvement: 6,
        rating:null,

    }

    constructor(props) {
        super( props );
        this.loadData = this.loadData.bind( this );
    }


    formatThematicsDate(data,macroarea_id){

        return (
            data
                .filter(f=>f.macroarea_id==macroarea_id)
                .map( th => ({
                    label: th.subthematic_name || th.thematic_name,
                    value: th.percentage
                }))
        );
    }



    render()
    {
        return (
            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: "SI Rating", path: PATH_PREFIX +"/welcome" },
                        { label: "Dashboard", active: true }
                    ]}
                    title={"Dashboard"}
                />




                {
                    (!!this.state.evaluationModal)
                        ? (
                            <RatingListModal
                                onChoose={ ratingData => {
                                    this.loadData( ratingData )
                                }}
                                onClose={ () => {
                                    this.setState({evaluationModal:false})
                                }}
                            />
                        )
                        : null
                }


                <Row>
                    <Col className={"sdg-flag-icon-container"}>
                        <h4>
                            {/*<span className={"tree-node-data-entry-value-box-component"}>Valutazione: </span>*/}
                            <ActionButton
                                buttonText={ translate("Select Rating") }

                                promise={ () => Promise.resolve() }
                                onResult={ () => {
                                    this.setState({evaluation:null,evaluationModal:true})
                                }}
                            />
                        </h4>
                    </Col>
                </Row>


                <Row>

                    <Col md={ 3 }>
                        <Card>
                            <CardHeader>
                                <h4>
                                    <span className={"tree-node-data-entry-value-box-component"}>{translate("Evaluation")}</span>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    !!this.state.evaluation
                                        ? (
                                            <React.Fragment>
                                                <Row><Col><strong>Azienda: </strong> { !!this.state.evaluation ? this.state.evaluation.name || "" : "" }</Col></Row>
                                                <Row><Col><strong>Nome: </strong>{ this.state.evaluation.ragioneSociale}</Col></Row>
                                                <Row><Col><strong>Data Creazione: </strong>{ this.state.evaluation.dateCreate }</Col></Row>
                                                <Row><Col><strong>Stato: </strong>{ this.state.evaluation.state }</Col></Row>
                                                {/* <Row><Col><strong>&nbsp;</strong>{}</Col></Row> */}
                                            </React.Fragment>
                                        )
                                        : null
                                }
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={ 6 }>
                        <Card>
                            <CardHeader>
                                <h4>
                                    <span className={"tree-node-data-entry-value-box-component"}>Rating</span>
                                </h4>
                            </CardHeader>
                            <CardBody>

                                {/*
                                    !!score
                                        ? (
                                            <pre>
                                                { beautify( score ) }
                                            </pre>
                                        )
                                        : null
                                */}
                                {
                                    (!!this.state.rating && this.state.rating !== -1) ?
                                    (
                                    <React.Fragment>

                                        <Row><Col><strong>Rating compessivo: </strong>{ this.state.rating } %</Col></Row>
                                        <Row><Col><strong>Rating ambientale: </strong>{ this.state.environmental+"% ("+this.state.environmental_materiality+ ")" }</Col></Row>
                                        <Row><Col><strong>Rating sociale: </strong>{ this.state.social +"% ("+this.state.social_materiality+ ")" }</Col></Row>
                                        <Row><Col><strong>Rating governativo: </strong>{this.state.governance  +"% ("+this.state.governance_materiality+ ")" }</Col></Row>
                                    </React.Fragment>
                                    ):
                                    (
                                        <WaitingOrNodataMessage
                                        waiting={ this.state.rating === -1 }
                                        spinnerColor={"success"}
                                        waitingMessage={"in caricamento..."}
                                        nodataMessage={translate("No Data")}
                                    />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={ 3 }>
                        <Card>
                            <CardHeader>
                                <h4>
                                    <span className={"tree-node-data-entry-value-box-component"}>{translate("Criteria")}</span>
                                </h4>
                            </CardHeader>
                            <CardBody>

                                {/*
                                    !!score
                                        ? (
                                            <pre>
                                                { beautify( score ) }
                                            </pre>
                                        )
                                        : null
                                */}
                                {
                                    (!!this.state.rating && this.state.rating !== -1) ?
                                    (
                                    <React.Fragment>

                                        <Row><Col><strong>Resilienza: </strong>{   this.state.resilience}%</Col></Row>
                                        <Row><Col><strong>Trasparenza: </strong>{  this.state.transparency }%</Col></Row>
                                        <Row><Col><strong>Miglioramento: </strong>{  this.state.improvement }%</Col></Row>
                                    </React.Fragment>
                                    ):
                                    (
                                        <WaitingOrNodataMessage
                                        waiting={ this.state.rating === -1 }
                                        spinnerColor={"success"}
                                        waitingMessage={"in caricamento..."}
                                        nodataMessage={translate("No Data")}
                                    />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>

                </Row>



                <Card>
                    <CardHeader>
                        <h4>{translate("Thematic")}</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                               {
                            (!!this.state.thematicChartValueEnv && this.state.thematicChartValueEnv !== -1) ?
                               (
                                <SiRatingAdminThemesChart
                                    title={"Environmental"}
                                    multipleData={ true }
                                    rating={ this.state.thematicChartValueEnv }
                                    type={ SIRATING_ADMIN_CHART_THEMATICS_ENV }
                                    onDataLoaded={ data => {
                                        // wait( false );
                                        //showlog("Environmental data loaded");
                                    }}
                                />
                               ):
                               (
                                    <WaitingOrNodataMessage
                                    waiting={ this.state.thematicChartValueEnv === -1 }
                                    spinnerColor={"success"}
                                    waitingMessage={"in caricamento..."}
                                    nodataMessage={translate("No Data")}
                                />
                                )
                               }


                            </Col>
                            <Col md={4}>
                                <SiRatingAdminThemesChart
                                    title={"Social"}
                                    rating={ this.state.thematicChartValueSoc }
                                    type={ SIRATING_ADMIN_CHART_THEMATICS_SOC }
                                    onDataLoaded={ data => {
                                        //showlog("Social data loaded");
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                                <SiRatingAdminThemesChart
                                    title={"Governance"}
                                    rating={ this.state.thematicChartValueGov }
                                    type={ SIRATING_ADMIN_CHART_THEMATICS_GOV }
                                    onDataLoaded={ data => {
                                        //showlog("Governance data loaded");
                                    }}
                                />
                            </Col>


                        </Row>
                    </CardBody>
                </Card>


                <Card>
                    <CardHeader>
                        <h4>SDG</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <SiRatingAdminSdgChart
                                    title={""}
                                    rating={ this.state.evaluation }
                                    type={ SIRATING_ADMIN_CHART_SDG }
                                    onDataLoaded={ data => {} }
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </React.Fragment>
        )


    }


    loadData( ratingData ) {
        this.setState(
            {
                evaluationModal:false,
                evaluation:ratingData,
                rating: -1,
                resilience:-1
            },
            () => {

                Promise.all([
                    loadSiRatingAdminScoreThematicsChartData( ratingData, false, 0 ),
                    loadSiRatingCrieriaChartData( ratingData ),
                    storeRatingData( ratingData )
                ])
                    .then( dataList => {

                        showlog("dataList");
                        showlog(dataList);
                        showlog(ratingData);

                        if( !dataList[1] ) {
                            dataList.push({resilience: -1, transparency: -1, improvement: -1})
                        }


                        this.setState({
                            thematicChartValueEnv: this.formatThematicsDate(dataList[0].thematicScores,1),
                            thematicChartValueSoc: this.formatThematicsDate(dataList[0].thematicScores,2),
                            thematicChartValueGov: this.formatThematicsDate(dataList[0].thematicScores,3),

                            environmental: dataList[0].macroareaScores.environmental,
                            environmental_materiality: dataList[0].macroareaScores.environmental_materiality,
                            governance: dataList[0].macroareaScores.governance,
                            governance_materiality: dataList[0].macroareaScores.governance_materiality,
                            rating: dataList[0].macroareaScores.rating,
                            social: dataList[0].macroareaScores.social,
                            social_materiality: dataList[0].macroareaScores.social_materiality,

                            resilience: dataList[1].resilience,
                            transparency: dataList[1].transparency,
                            improvement: dataList[1].improvement
                        });

                    })

            }
        )
    }


    componentDidMount() {

        if( !!getRatingData() && JSON.stringify( getRatingData() ) !== "{}" ) {
            this.loadData( getRatingData() );
        }
        else {
            loadEvaluations()
                .then( list => {
                    return list.filter( d => d.status === RATING_STATUS.auditDone.id );
                })
                .then( list => {
                    if( isNotEmptyArray( list ) && list.length === 1 ) {
                        let rating = list[0];
                        if( rating.status === RATING_STATUS.auditDone.id ) {
                            rating.dimension = rating.dimension.name;
                            this.loadData( rating );
                        }
                    }
                })
                .catch( e => {
                    errorlog("lista valutazioni", e);
                })
        }

    }


}
