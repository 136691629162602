import React, {Component} from 'react';
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import "../../area/modal.css";
import {errorlog, renderlog} from "../../../utils/dev-utils";
import ActionButton from "../../save-button";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {MATERIALITY_REASON_LEVEL} from "../../../env";
import {cloneObject} from "../../../utils/object-utils";
import {setConfigurationQuestionThematicReason} from "../../../business-logic/rating";
import {getLastElementOr} from "../../../utils/array-utils";
import Select from "react-select";
import {translate} from "../../i18n/translate-function";


export default class EditMaterialityModal extends Component {
    state = {
        node: this.props.node
    }

    constructor( props ) {
        super( props );
        this.toggle         = this.toggle.bind( this );
    }

    toggle = () => {
        if( !this.state.inWait ) this.props.onClose();
    }

    render() {

        renderlog(
            "Edit Materiality Modal",
            this.state,
            this.props,
            { color: "#88ffaa", contrastColor: "#222222" }
        );

        let content = (
            <AvForm>

                <Select
                    className="react-select sirating-enabling-question"
                    classNamePrefix="react-select"
                    name={this.props.node.id + "-comander"}
                    id={this.props.node.id + "-comander"}

                    onChange={changeEvent => {

                        this.setState({
                            ...this.state,
                            node: {
                                ...this.state.node,
                                materiality: {
                                    ...this.state.node.materiality,
                                    value: changeEvent.value
                                }
                            }
                        })


                    }}

                    value={
                        MATERIALITY_REASON_LEVEL
                            .filter( m => m.value === this.state.node.materiality.value )
                            .reduce( getLastElementOr, MATERIALITY_REASON_LEVEL[0] )
                    }
                    options={ MATERIALITY_REASON_LEVEL.filter( m => !!m.value ) }
                    placeholder={<span className={"select-placeholder"}>Imposta materialità</span>}
                />

                <br/>
                <br/>


                {/*     NOME  -------------------------------------------------------------------------------- */}
                <AvGroup>
                    <Label for="firstName">Motivo</Label>
                    <AvInput
                        type="textarea"
                        name="reason"
                        id="reason"
                        row={32}
                        value={ this.state.node.materiality.motivation }
                        onChange={ changeTextEvent => {

                            this.setState({
                                ...this.state,
                                node: {
                                    ...this.state.node,
                                    materiality: {
                                        ...this.state.node.materiality,
                                        motivation: changeTextEvent.target.value
                                    }
                                }
                            })


                        }}
                        // required
                    />
                </AvGroup>

            </AvForm>
        );

        let context = (
            <Modal isOpen={ true } toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} >
                    Modifica Materialità
                </ModalHeader>
                <ModalBody>
                    { content }
                    {/*
                        <div>
                            <pre>{beautify(this.state.node)}</pre>
                        </div>
                    */}
                </ModalBody>
                <ModalFooter>
                    <Button color="link" onClick={this.toggle}>
                        { translate("Close") }
                    </Button>
                    <ActionButton
                        buttonText={ "Salva" }
                        promise={ () => {
                            // this.props.node
                            return (
                                setConfigurationQuestionThematicReason(
                                    this.props.ratingId,
                                    this.state.node.path[1],
                                    (this.state.node.path[2] || 0),
                                    this.state.node.materiality
                                )
                            );
                        }}
                        onProcess={ () => {
                            this.setState({
                                ...this.state,
                                inWait: true
                            })
                        }}
                        onResult={ result => {
                            this.props.onClose( true );
                        }}
                        onError={ e => {
                            errorlog( e );
                        }}
                    />
                </ModalFooter>
            </Modal>


        );


        return context;
    }


    componentDidMount() {

        let node = cloneObject( this.props.node );
        delete node.questions;
        this.setState({
            ...this.state,
            node: node
        })

    }

}

