import {Component} from "react";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteCollect} from "../../../business-logic/gri";
import {errorlog, showlog} from "../../../utils/dev-utils";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";

export default class DeleteCollectQuestionModal extends Component {

	state = {}

	constructor( props ) {
		super( props );

		this.toggle = this.toggle.bind( this );
	}


	toggle = forceRefresh => {
		this.props.onClose( forceRefresh );
	}

	render() {

		let context = (
			emoj( EMOJ.wastebasket ) +
			"\t\t\t\tSicuro di voler eliminare la raccolta "+ this.props.name +" ?"
		);

		if( !!this.props.message ) context = this.props.message;

		let content = (
			<Modal isOpen={ true } toggle={this.toggle}>
				<ModalHeader toggle={this.toggle} className={ "modal-colored-header bg-danger" }>
					{ emoj( EMOJ.warning ) } &nbsp; ATTENZIONE
				</ModalHeader>
				<ModalBody className={this.state.bodyClass}>
					{ context }
				</ModalBody>
				<ModalFooter className={this.state.bodyClass}>
					<Button
						color="primary"
						onClick={ clickEvent => {
							console.log("click su conferma");
							deleteCollect( this.props.id )
								.then( () => {
									this.props.onConfirm( true );
								})
								.catch(e => {
									errorlog("errore", e);
								})
						}}
					>
						OK
					</Button>{' '}
					<Button color="link" onClick={this.toggle}>
						annulla
					</Button>
				</ModalFooter>
			</Modal>


		);


		return content;
	}

	componentDidMount() {}
}
