import {
	deleteSADashboardFilter,
	getSuperAdminDashboardData,
	setSADashboardFilter
} from "../services/super-admin-dashboard";
import {errorlog, isNotEmptyArray, showlog} from "../utils";

/**
 *
 * @param year
 * @returns {Promise<* | SimpleCacheConfigurator.never>}
 */
export const loadSuperAdminDashboardData = (year, tabId) => {
	return (
		getSuperAdminDashboardData( year, tabId )
			.then( result => {


				/*
				[
					{
						"company": {
							"id": 34,
							"name": "TH Gestioni S.p.A."
						},
						"data": 0
					},

					{
						"company": {
							"id": 50,
							"name": "TH Assisi - Casa Leonori"
						},
						"data": [
							{
								"unitMeasurement": "%",
								"valuesList": [
									{
										"structure_id": 98284,
										"valueGoal": "",
										"dateGoal": "1900-01-01T00:00:00",
										"dateStartGoal": "1900-01-01T01:01:01",
										"valueStartGoal": "",
										"monthNumber": 1,
										"valueType": false,
										"valueDecimal": 10,
										"valueString": ""
									},


									{
										"structure_id": 98284,
										"valueGoal": "",
										"dateGoal": "1900-01-01T00:00:00",
										"dateStartGoal": "1900-01-01T01:01:01",
										"valueStartGoal": "",
										"monthNumber": 12,
										"valueType": false,
										"valueDecimal": 100,
										"valueString": ""
									}
								],
								"graphId": 1,
								"columName": "<b>403-8 - Lavoratori coperti da un sistema di gestione della salute e sicurezza sul lavoro</b><br />Percentuale"
							},


							{} // per graph id
						]
					},
					{
						"company": {
							"id": 51,
							"name": "TH Tirrenia - Urban"
						},
						"data": 0
					},

				]
				 */


				result =
					result
						.map( packet => (
							{
								company_id: packet.company.id,
								company_name: packet.company.name,
								company_year: packet.company.year,
								company_collect: packet.company.collect,
								data: packet.data || []
							}
						))

						.sort( (x,y) => {
							if( x.company_name < y.company_name ) return -1;
							return 1;
						})



						.map( packet => {
							let subData = {};

							showlog("riga tabella");
							showlog(packet);


							packet.data
								.map( d => {

									if( !!isNotEmptyArray( d.valuesList ) ) {

										showlog("controllo mensili / annuali");
										showlog(d);
										showlog(d.valuesList);
										showlog(d.valuesList[0].isValueActive);
										if( !!d.valuesList[0].isValueActive ) {
											d.valuesList = [d.valuesList[0]];
										}

									}

								})
							;


							if( isNotEmptyArray( packet.data ) ) {

								packet.data
									.map( pd => {

										let fieldName =
											"chart_" +
											pd.graphId +
											" - " +
											pd.columName +
											"" +
											(
												(pd.unitMeasurement === "numero")
													? ""
													: (
														pd.valuesList.length > 0
															? (" ( "+ pd.unitMeasurement +" )")
															: ""
													)
											)
										;

										subData[ fieldName ] =
											"["+
												pd.valuesList
													.sort((x, y) => {
														if (x.monthNumber < y.monthNumber) return -1;
														return 1;
													})
													.map(v => ("{\"" + v.monthNumber + "\":\"" + ( !!v.isValueActive ? (!!v.valueType ? v.valueString : v.valueDecimal) : " " ) + "\"}"))
													.join(",") +
												"]"
									})


								;

							}
							else {
								/*
								{
										"0": {
											"unitMeasurement": "%",
											"valuesList": [
												{
													"structure_id": 98284,
													"valueGoal": "",
													"dateGoal": "1900-01-01T00:00:00",
													"dateStartGoal": "1900-01-01T01:01:01",
													"valueStartGoal": "",
													"monthNumber": 1,
													"valueType": false,
													"valueDecimal": 10,
													"valueString": ""
												},
											],
											"graphId": 1,
											"columName": "no-name"
										}
									}
								 */

							}


							// showlog("subData");
							// showlog(subData);

							return (
								Object.assign(
									{},
									{
										company_id: packet.company_id,
										company_name:
											packet.company_name +
											(!!packet.company_year ? (" ( "+ packet.company_year +" )") : "") +
											"-mark-"+ packet.company_collect
										// subData: subData
									},
									// packet,
									subData
								)
							);

						})



				// result
				showlog("result dashboard data");
				showlog( result );


				return result;
			})

			.catch(e => {
				errorlog("caricamento dati SADashboard", e);
			})

	);
	/*
	return (
		Promise.all([
			new Promise((success, fail) => {
				setTimeout( function() {
					success( true );
				}, 1000);
			}),
			new Promise((success, fail) => {
				let data = [
					{
						company_id: 1,
						company_name: "beta",
						chart_1: 10,
						chart_2: 20,
						chart_3: 30,
						chart_4: 40
					},
					{
						company_id: 1,
						company_name: "gamma",
						chart_1: 9,
						chart_2: 8,
						chart_3: 7,
						chart_4: 6
					},
					{
						company_id: 1,
						company_name: "alpha",
						chart_1: 1,
						chart_2: 2,
						chart_3: 3,
						chart_4: 4
					},
				]
				.sort( (x, y) => {
					if( x.company_name < y.company_name ) return -1;
					return 1;
				})
				;
				success(data);
			})
		])
		.then( resultList => {
			return resultList[1];
		})
	);
	*/
}




export const setSuperAdminDashboardFilter = (chartId, year, payload) => {
	return setSADashboardFilter(chartId, year, payload);
}


export const clearSADashboardFilter = graphId => {
	return deleteSADashboardFilter( graphId );
}
