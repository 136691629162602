import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Select from "react-select";
import {
    beautify,
    emoj,
    extractErrorMessage,
    getLastElementOr,
    renderlog,
    showlog,
    sortById
} from "../../../utils/index.js";
import {EMOJ} from "../../../emoj.js";
import ActionButton from "../../../components/save-button";
import {savePreventiveQuestion} from "../../../business-logic/gender-gap.js";
import {COLOR_POLICY} from "../../../env.js";
// import "../../../style/gender-gap.css";

export const PreventiveQuestionModal = props => {


    const [error, setError] = useState();
    const [inWait, wait] = useState( false );
    const [ready, updateReady] = useState( props.ready );
    const [selectedChoiche, selectChoiche] =
        useState(
            props.question.preventiveQuestions.choiches
                .filter( ch => (
                    ch.id === (
                        props.question.preventiveQuestions.answer
                            .reduce( getLastElementOr, {srPreventiveChoicheId:0} )
                            .srPreventiveChoicheId
                    )
                ))
                .map(ch => ({ ...ch, value: ch.id, label: ch.text }))
                .reduce( getLastElementOr, null )
        )
    ;

    if( !!inWait && (ready < props.ready) ) {
        showlog("stoppo attesa");
        wait( false );
    }


    renderlog("PreventiveQuestionModal", {error, inWait, ready, selectedChoiche}, props, COLOR_POLICY.validated)


    let context = (

        <React.Fragment>
            <span>{ props.question.preventiveQuestions.question[0] }</span>
            <Select
                className="react-select"
                classNamePrefix="react-select"
                name="scelte"
                id="preventive-question-chioches"
                onChange={ clickedChoiche => {
                    selectChoiche( clickedChoiche );
                    // setError( null );
                }}
                options={
                    props.question.preventiveQuestions.choiches
                        /* ordinamento alfabetico
                        .sort( (a, b) => {
                            if( a.text < b.text ) return -1;
                            return 1;
                        })
                        */
                        .sort( sortById )
                        .map(ch => ({ ...ch, value: ch.id, label: ch.text }))
                }
                value={ selectedChoiche }
                placeholder={<span className={"select-placeholder"}>Seleziona Risposta</span>}
            />

            <div id={"preventive-question-bottom-container"}>
                {
                    !!error
                        ? <span className={"upload-error-text" + (!!inWait ? " " : "")}>{ error }</span>
                        : <span className={""}/>
                }
                {
                    !!selectedChoiche
                        ? (
                            <ActionButton
                                promise={ clickEvent => {
                                    wait( true );
                                    return savePreventiveQuestion(props.rating, props.question.id, selectedChoiche.id)
                                }}
                                onProcess={ () => {
                                    // wait( true );
                                }}
                                onResult={ result => {
                                    setError( null );
                                    updateReady( (new Date()).getTime() )
                                    props.onChoose( selectedChoiche );
                                }}
                                onError={ e => {
                                    setError( extractErrorMessage( e ) );
                                }}
                                className={"preventive-question-button"}
                            />
                        )
                        : null
                }
            </div>


            {
                (window.location.href.indexOf("help=1") !== -1)
                    ? (
                        <React.Fragment>
                            <div className={"preventive-question-helper-container"}>
                                <span>{ emoj( EMOJ.ambulance ) }</span>
                                <code>&nbsp;&nbsp;DOMANDA { props.question.id }</code>
                            </div>
                            <pre>{ beautify( props.question ) }</pre>
                        </React.Fragment>
                    )
                    : null
            }
        </React.Fragment>

    );

    return (
        <Modal
            isOpen={ true }
            toggle={ () => {
                if( !!inWait /*&& !props.ready*/ ) {}
                else props.onClose()
            }}
            className={ ((!!inWait /*&& !props.ready*/) ? "modal-in-wait" : "") }
        >
            <ModalHeader
                toggle={ () => {
                    if( !!inWait /*&& !props.ready*/ ) {}
                    else props.onClose()
                }}
            >
                Domanda Preventiva
            </ModalHeader>
            <ModalBody>
                { context }
            </ModalBody>
        </Modal>
    );
}
