import React, {Component, Suspense} from 'react';
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {COLOR_POLICY, PATH_PREFIX} from "../../../env";
import classnames from "classnames";
import PageTitle from "../../../components/PageTitle";
import {getCurrentRoleLabel} from "../../../utils/role-utils";
import {translate} from '../../../components/i18n/translate-function';


export default class SiRatingAdminStatusLegendColorPolicyPage extends Component {


	state = {
		activeTab: "questionLegendStatus"
	}

	constructor(props) {
		super(props);


	}


	render() {
		let statusColorPolicy = "";

		return (
			<Suspense>


				<PageTitle
					breadCrumbItems={[
						{ label: getCurrentRoleLabel(), path: PATH_PREFIX + "/welcome" },
						{ label: translate("Evaluations"), path: PATH_PREFIX + "/sirating-admin/" + translate("evaluations") },
						{ label: "Legenda", active: true }
					]}
					title={"SI Rating"}
				/>

				<Card>
					<CardHeader>
						<h4>
							<span>Legenda Stati</span>
						</h4>
					</CardHeader>
					<CardBody>

						<CardBody>
							<Nav tabs>

								<NavItem key={"questionLegendStatus"}>
									<NavLink
										id={"questionLegendStatus"}
										href="#"
										className={classnames({ active: this.state.activeTab === "questionLegendStatus" })}
										onClick={() => {

											this.setState({
												...this.state,
												activeTab: "questionLegendStatus"
											});
										}}>
										<span className="d-none d-lg-block">Domande</span>
									</NavLink>
								</NavItem>

								<NavItem key={"confQuestionLegendStatus"}>
									<NavLink
										id={"confQuestionLegendStatus"}
										href="#"
										className={classnames({ active: this.state.activeTab === "confQuestionLegendStatus" })}
										onClick={() => {

											this.setState({
												...this.state,
												activeTab: "confQuestionLegendStatus"
											});
										}}>
										<span className="d-none d-lg-block">Configurazione Domande</span>
									</NavLink>
								</NavItem>

								<NavItem key={"buttonLegendStatus"}>
									<NavLink
										id={"buttonLegendStatus"}
										href="#"
										className={classnames({ active: this.state.activeTab === "buttonLegendStatus" })}
										onClick={() => {

											this.setState({
												...this.state,
												activeTab: "buttonLegendStatus"
											});
										}}>
										<span className="d-none d-lg-block">Bottoni & Switch</span>
									</NavLink>
								</NavItem>

							</Nav>

							<TabContent activeTab={this.state.activeTab}>

								<TabPane
									tabId={"questionLegendStatus"}
									key={"questionLegendStatus-key"}
								>
									<Row><Col sm="12">&nbsp;</Col></Row>
									<Row><Col sm="12">&nbsp;</Col></Row>

									{
										Object.keys(COLOR_POLICY)
											// .map( status => COLOR_POLICY[status].textClass )
											.map( status => {
												return (
													<Row>
														<Col sm="1">
															<div className={"status-ball-legend " + (COLOR_POLICY[status].textClass.split("text").join("background")) }></div>
														</Col>
														<Col sm="1">
															<strong className={ COLOR_POLICY[status].textClass }>{ COLOR_POLICY[status].name }</strong>
														</Col>
														<Col sm="10">
															<span>{ COLOR_POLICY[status].label }</span>
														</Col>
													</Row>
												)
											})
									}

									{/*<GotoTopComponent/>*/}

								</TabPane>


								<TabPane tabId={"confQuestionLegendStatus"} key={"confQuestionLegendStatus-key"}>
									<Row><Col sm="12">&nbsp;</Col></Row>
									<Row><Col sm="12">&nbsp;</Col></Row>

									<Row>
										<Col sm="1">
											<div className={"status-ball-legend " + (COLOR_POLICY.validated.textClass.split("text").join("background")) }></div>
										</Col>
										<Col sm="1">
											<strong className={ COLOR_POLICY.validated.textClass }>{ COLOR_POLICY.validated.name }</strong>
										</Col>
										<Col sm="10">
											<span>Domanda Abilitata</span>
										</Col>
									</Row>

									<Row>
										<Col sm="1">
											<div className={"status-ball-legend " + (COLOR_POLICY.error.textClass.split("text").join("background")) }></div>
										</Col>
										<Col sm="1">
											<strong className={ COLOR_POLICY.error.textClass }>{ COLOR_POLICY.error.name }</strong>
										</Col>
										<Col sm="10">
											<span>Domanda Disabilitata</span>
										</Col>
									</Row>

									{/*<GotoTopComponent/>*/}

								</TabPane>

								<TabPane tabId={"buttonLegendStatus"} key={"buttonLegendStatus-key"}>
									<Row><Col sm="12">&nbsp;</Col></Row>
									<Row><Col sm="12">&nbsp;</Col></Row>
									<Row>
										<Col sm="1">
											<div className={"status-ball-legend " + (COLOR_POLICY.edit.textClass.split("text").join("background")) }></div>
										</Col>
										<Col sm="1">
											<strong className={ COLOR_POLICY.edit.textClass }>{ COLOR_POLICY.edit.name }</strong>
										</Col>
										<Col sm="10">
											<span>Dato non ancora salvato <em>( Dato non presente nel sistema o in modifica )</em></span>
										</Col>
									</Row>
									<Row>
										<Col sm="1">
											<div className={"status-ball-legend " + (COLOR_POLICY.validated.textClass.split("text").join("background")) }></div>
										</Col>
										<Col sm="1">
											<strong className={ COLOR_POLICY.validated.textClass }>{ COLOR_POLICY.validated.name }</strong>
										</Col>
										<Col sm="10">
											<span>Dato presente nel sistema</span>
										</Col>
									</Row>

									{/*<GotoTopComponent/>*/}

								</TabPane>





							</TabContent>
						</CardBody>

					</CardBody>
				</Card>

			</Suspense>
		);
	}



}

