import React, {Component} from "react";
import {errorlog, renderlog} from "../../../utils/dev-utils";
import {COLOR_POLICY, PATH_PREFIX, permissions, role} from "../../../env";
import {Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {getLastElementOr} from "../../../utils/array-utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import ActionButton from "../../../components/save-button";
import {
    downloadAnswersData,
    downloadGapAnalysisData,
    downloadProfilingData,
    downloadSIRatingRatingFile,
    getGapAnalysisFile,
    getMaterialityCsvFile,
    getMaterialityFile,
    getSdgFile,
    loadRatingPdfParameters,
    saveRatingPdfParameters
} from "../../../business-logic/rating";
import PageTitle from "../../../components/PageTitle";
import {getCurrentRoleLabel} from "../../../utils/role-utils";
import {hasPermission, isUserRole} from "../../../business-logic/auth";
import {translate} from "../../../components/i18n/translate-function";

const VOID_PDF_PARAMETERS = {
    text1: "",
    text2: "",
    text3: ""
}



export default class RatingPdfParametersPage extends Component {

    state = {}

    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
    }


    render() {


        renderlog("RatingPdfParametersPage", this.state, this.props, COLOR_POLICY.validated);

        let context = null;

        if (!!isUserRole(role.PROJECT_MANAGER)) {
            context =
                (!!this.state.data && this.state.data.length > 0)
                    ? (

                        <React.Fragment>

                        <Row>
                            <Col sm={6}>
                                <Form>

                                    <FormGroup>
                                        <Label for={"field-7"}><b style={{color:"black"}}>La società</b></Label>
                                        <Input
                                            className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-a"}
                                            id={"field-7"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textG: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text7}
                                            value={this.state.textG || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text7}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for={"parameter-a"}><b style={{color:"black"}}>Gap Analysis Enviroment</b></Label>
                                        <Input
                                            className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-a"}
                                            id={"parameter-a"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textA: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text1}
                                            value={this.state.textA || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text1}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for={"parameter-b"}><b style={{color:"black"}}>Gap Analysis Social</b></Label>
                                        <Input
                                             className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-b"}
                                            id={"parameter-b"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textB: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text2}
                                            value={this.state.textB || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text2}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for={"parameter-c"}><b style={{color:"black"}}>Gap Analysis Governance</b></Label>
                                        <Input
                                             className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-c"}
                                            id={"parameter-c"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textC: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text3}
                                            value={this.state.textC || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text3}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>


                                </Form>
                            </Col>
                            <Col md={6}>
                                <Form>

                                    <FormGroup>
                                        <Label for={"field-8"}><b style={{color:"black"}}>Obiettivi della valutazione</b></Label>
                                        <Input
                                            className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-a"}
                                            id={"field-8"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textH: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text8}
                                            value={this.state.textH || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text8}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for={"parameter-d"}><b style={{color:"black"}}>Benefici Enviroment</b></Label>
                                        <Input
                                             className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-d"}
                                            id={"parameter-d"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textD: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text4}
                                            value={this.state.textD || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text4}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for={"parameter-e"}><b style={{color:"black"}}>Benefici Social</b></Label>
                                        <Input
                                              className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-e"}
                                            id={"parameter-e"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textE: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text5}
                                            value={this.state.textE || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text5}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for={"parameter-f"}><b style={{color:"black"}}>Benefici Governance</b></Label>
                                        <Input
                                             className={"sector-question-text standard-text-area-border"}
                                            type="textarea"
                                            row={64}
                                            name={"parameter-f"}
                                            id={"parameter-f"}
                                            onChange={changeEvent => {
                                                this.setState({
                                                    ...this.state,
                                                    textF: changeEvent.target.value
                                                })
                                            }}
                                            defaultValue={this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text6}
                                            value={this.state.textF || this.state.data.reduce(getLastElementOr, VOID_PDF_PARAMETERS).text6}
                                            // required
                                            disabled={!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)}
                                        />
                                    </FormGroup>


                                </Form>
                            </Col>
                        </Row>

                    <Row>
                        <ActionButton
                            promise={() => {
                                if (!!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)) {
                                    return saveRatingPdfParameters(this.props.match.params.id, this.state)
                                }
                                return Promise.resolve();
                            }}
                            onResult={result => {

                            }}
                            onError={e => {
                                errorlog("errore", e);
                                if (!!this.props.onClose && typeof this.props.onClose === "function") this.props.onClose(e);
                            }}
                        />
                    </Row>

            </React.Fragment>


                    )
                    : (
                        <WaitingOrNodataMessage
                            waiting={!(!!this.state.data && this.state.data.length === 0)}
                            nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                            fullHeight={true}
                            className={"bottom-margin-alignment"}
                        />
                    )
                ;
        }
        else {

        }





        let content = (


            <React.Fragment>
                <PageTitle
                    breadCrumbItems={[
                        { label: getCurrentRoleLabel(), path: PATH_PREFIX + "/welcome" },
                        {
                            label: translate("Evaluations"),
                            path:
                                !!this.props.location.state
                                    ? (
                                        !!this.props.location.state.isGenderGap
                                            ? PATH_PREFIX + "/" + translate("gender-gap") + "/" + translate("evaluations")
                                            : PATH_PREFIX + "/super-admin/si-rating/" + translate("evaluations")
                                    )
                                    : PATH_PREFIX + "/super-admin/si-rating/" + translate("evaluations")
                        },
                        { label: translate("Settings"), path: PATH_PREFIX + "/super-admin/si-rating/" +  translate("evaluations") + "/" + translate("report-parameters") , active: true }
                    ]}
                    title={"Download"}
                />

                <Card>
                    <CardBody>


                        {
                            (!!isUserRole(role.PROJECT_MANAGER))
                                ? (
                                    <Row>
                                        <Col>
                                            <div className="page-title-box">
                                                <h4 className="page-title">Parametri</h4>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                                : null
                        }


                        <Row>

                            {
                                (!!isUserRole(role.PROJECT_MANAGER))
                                    ? (
                                        <Col md={8}>
                                            <h5>Testi</h5>
                                            {context}
                                        </Col>
                                    )
                                    : null
                            }



                            <Col>
                                <div className="page-title-right">



                                    <div className={"stack-on-right"}>

                                        {
                                            !!hasPermission(permissions.DOWNLOAD_RATING_PDF)
                                                ? (
                                                    <ActionButton
                                                        buttonText={"Download PDF Performance "}
                                                        promise={() => {
                                                            return (
                                                                new Promise((onEnd, onFail) => {

                                                                    if (!!hasPermission(permissions.SET_RATING_PDF_PARAMETERS)) {
                                                                        saveRatingPdfParameters(this.props.match.params.id, this.state)
                                                                            .then( result => onEnd( result ) )
                                                                            .catch(e => onFail( e ))
                                                                    }
                                                                    else onEnd();

                                                                })
                                                                .then( result => {
                                                                    return (
                                                                        downloadSIRatingRatingFile(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName : "valutazione.pdf"))
                                                                            .then(res => {
                                                                                if (!!this.props.onClose && typeof this.props.onClose === "function") this.props.onClose();
                                                                                return res;
                                                                            })
                                                                            .catch(e => {
                                                                                errorlog("errore", e);
                                                                                if (!!this.props.onClose && typeof this.props.onClose === "function") this.props.onClose(e);
                                                                                return Promise.reject( e );
                                                                            })
                                                                    );
                                                                })
                                                                .catch(e => Promise.reject( e ))
                                                            );

                                                        }}
                                                        onResult={result => {
                                                            // downloadSIRatingRatingFile(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName : "valutazione.pdf"))
                                                            //     .then(result => {
                                                            //         if (!!this.props.onClose && typeof this.props.onClose === "function") this.props.onClose();
                                                            //     })
                                                            //     .catch(e => {
                                                            //         errorlog("errore", e);
                                                            //         if (!!this.props.onClose && typeof this.props.onClose === "function") this.props.onClose(e);
                                                            //     })
                                                        }}
                                                        onError={e => {
                                                            errorlog("errore", e);
                                                            if (!!this.props.onClose && typeof this.props.onClose === "function") this.props.onClose(e);
                                                        }}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />


                                        {
                                            (
                                                !!hasPermission(permissions.DOWNLOAD_PROFILING_PDF)     // -------------------------------- permesso di mostrare il button
                                                && (
                                                    !!this.props.location.state
                                                        ? (!!this.props.location.state.isGenderGap ? false : true)      // ---------------- se è presente, il flag deve essere a false
                                                        : true
                                                )
                                            )
                                                ? (
                                                    <ActionButton
                                                        buttonText={ translate('downloadProfilingPDFbuttonlabel') }
                                                        promise={() => downloadProfilingData(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "Materialita.xml")}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />

                                        {
                                            !!hasPermission(permissions.DOWNLOAD_ANSWERS_PDF)
                                                ? (
                                                    <ActionButton
                                                        buttonText={translate("downloadPDFquestionnaireanswers")}
                                                        promise={() => downloadAnswersData(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "Materialita.xml")}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />

                                        {
                                            !!hasPermission(permissions.DOWNLOAD_XML_MATERIALITY)
                                                ? (
                                                    <ActionButton
                                                        buttonText={"Download XML Materiality"}
                                                        promise={() => getMaterialityFile(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "Materialita.xml")}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />

                                        {
                                            !!hasPermission(permissions.DOWNLOAD_XML_SDG)
                                                ? (
                                                    <ActionButton
                                                        buttonText={"Download XML SDG Performance"}
                                                        promise={() => getSdgFile(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "SDG.xml")}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />

                                        {
                                            !!hasPermission(permissions.DOWNLOAD_ANSWERS_PDF)
                                                ? (
                                                    <ActionButton
                                                        buttonText={"Download PDF Gap Analysis"}
                                                        promise={() => downloadGapAnalysisData(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "Materialita.xml")}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />

                                        {
                                            !!hasPermission(permissions.DOWNLOAD_ANSWERS_PDF)
                                                ? (
                                                    <ActionButton
                                                        buttonText={"Download CSV Gap Analysis"}
                                                        promise={() => getGapAnalysisFile(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "GapAnalysis.csv")}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className={"h-spacer"} />
                                        {
                                            !!hasPermission(permissions.DOWNLOAD_ANSWERS_PDF)
                                                ? (
                                                    <ActionButton
                                                        buttonText={"Download CSV Materiality"}
                                                        promise={() => getMaterialityCsvFile(this.props.match.params.id, (!!this.props.location.state ? this.props.location.state.pdfName.split(".pdf").join("") : "valutazione") + "Materiality2.csv")}
                                                    />
                                                )
                                                : null
                                        }


                                    </div>




                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>

            </React.Fragment>



        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }



    loadData() {

        loadRatingPdfParameters(this.props.match.params.id)

            .then(data => {
                this.setState({
                    ...this.state,
                    data: data
                })
            })

            .catch(e => {
                errorlog("errore", e);
            })


    }

}
