import React, {Component} from "react";
import {errorlog, getLastElementOr, isNotEmptyArray, renderlog, showlog} from "../utils";
import {COLOR_POLICY} from "../conf/color-policy";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {loadSiRatingQuestionInfo} from "../business-logic/rating";
import {WaitingOrNodataMessage} from "./waiting-or-nodata-message";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// const defaultSorted = [
// ];


const fieldMap = {
    alive: "Attiva",
    version: "Versione",
    dateCreate :"Data Creazione",
    dateUpdate :"Data Disattivazione"
};


const hiddenFields = [
"answersValueFieldNumber",
"attachments",
"attachmentsGuidelines",
"code",
"companySize",
"employeesNumbersLevel",
"gendergap",
"id",
"improvement",
"isBenchmarkQuestion",
"isCompanyQuestion",
"isMultipleAnswer",
"isOnlyMainSector",
"isOpenQuestion",
"isPerimeterQuestion",
"isSectorQuestion",
"ordering",
"questionGuidelines",
"questionLevel",
"resilience",
"score",
"srAnswerType",
"srCalculationType",
"srCalculationTypeId",
"srChoicheQuestionsOpening",
"srEvaluationMaxscoreQuestions",
"srEvaluationSectorQuestionManuallyAssociated",
"srMacroareaId",
"srMasterquestionId",
"srPreventiveAnswers",
"srPreventiveQuestions",
"srQuestionCompany",
"srQuestionStates",
"srQuestionsChoice",
"srQuestionsComments",
"srQuestionsNote",
"srQuestionsStructure",
"srSdgQuestions",
"srSdgQuestionsStructure",
"srSector",
"srSectorId",
"srSubthematic",
"srSubthematicId",
"srThematic",
"srThematicId",
"state",
"threshold",
"transparency",
"traslations",
"userCreate",
"userUpdate"
];


// const sortableFields = [
// ];

const formatColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldMap[field] || field,
                    //sort: sortableFields.includes(field)
                });
            })
            .filter(ff => !hiddenFields.includes(ff.dataField))
    );
}


export default class SiRatingQuestionInfoModal extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            data:[],
            waitingData: true
        }

        this.toggle = this.toggle.bind( this );
    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();

    }

    componentDidMount(){
        this.loadData()
    }


    render() {

        renderlog("SiRatingQuestionInfomodal", this.state, this.props, COLOR_POLICY.validated);

        let context = (
            <React.Fragment>
                {/*     SDG  -------------------------------------------------------------------------------- */}

                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    search
                >
                    {props => (

                        <React.Fragment>

                                    <BootstrapTable
                                        bootstrap4
                                        striped
                                        {...props.baseProps}
                                        size="sm"
                                        responsive
                                        keyField="id"
                                        bordered={false}
                                        data={this.state.data}
                                        columns={formatColumns(Object.keys(this.state.data[0]))}
                                        // defaultSorted={defaultSorted}
                                        wrapperClasses="table-responsive"
                                    />

                        </React.Fragment>

                    )}

                </ToolkitProvider>

            </React.Fragment>

        )

        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Informazioni domanda {this.props.code}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        let contentNodata = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Informazioni domanda {this.props.code}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    Nessun dato
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        let errorContent = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Informazioni domanda {this.props.code}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {this.state.error}
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        let waitingContent = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    Informazioni domanda {this.props.code}
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    <WaitingOrNodataMessage
                        waiting={ true }
                        waitMessage={"In attesa..."}
                       // className={"single-circle-waiting"}
                    />
                </ModalBody>
                {/*
                <ModalFooter>
                </ModalFooter>
                */}
            </Modal>
        );

        if(!!this.state.waitingData){
            return waitingContent
        }
        else if(!!this.state.error){
            return errorContent
        }
        else if (!!this.state.data && isNotEmptyArray(this.state.data) ) {
            return content;
        } else{
            return contentNodata;
        }
    }

    loadData(){
        loadSiRatingQuestionInfo(this.props.id)

            .then( infos => {
                showlog("dataaaa", infos)

                if(infos.alive === true){
                    infos.alive = "Si";
                    delete infos.dateUpdate
                }else if( infos.alive === false){
                    infos.alive = "No"
                }

                if(infos.dateCreate === null){
                    infos.dateCreate = "Informazione non disponibile"
                }
                if(infos.dateUpdate === null){
                    infos.dateUpdate = "Informazione non disponibile"
                }

                this.setState({
                    ...this.state,
                    waitingData: false,
                    data: [infos]
                },()=>{
                    showlog("dataaaa state" , this.state.data)
                })

            })
            .catch(error => {
                errorlog("loadSiRatingQuestionInfo", error);
                this.setState({
                    ...this.state,
                    error: error
                })
            })
    }

}