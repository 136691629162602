import React from "react";
import "./missing-goal-svg-component.css";
export const GoalSvgComponent = props => (
    <div
        id={ props.id }
        className={"missing-goal-svg-container "+ props.className}
        onClick={ props.onClick }
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 97.68">
            <g id="Livello_2" data-name="Livello 2">
                <path className="mg-1"
                      d="M101.41,46.34H96.57a33.28,33.28,0,0,0-1-6.13,2.5,2.5,0,0,0-4.83,1.3,28.24,28.24,0,0,1,.85,4.83H86.94a2.5,2.5,0,0,0,0,5h4.62A28,28,0,0,1,66.22,76.67V72.05a2.5,2.5,0,1,0-5,0v4.62A28,28,0,0,1,35.9,51.34h4.62a2.5,2.5,0,1,0,0-5H35.9A28,28,0,0,1,61.22,21v4.62a2.5,2.5,0,0,0,5,0V21a28,28,0,0,1,17.5,8.3l-21,21L54,41.58a2.5,2.5,0,1,0-3.53,3.54l10.5,10.5a2.49,2.49,0,0,0,3.54,0L97.76,22.34a2.5,2.5,0,0,0-3.53-3.54l-7,7a32.89,32.89,0,0,0-21-9.78V11.16a2.5,2.5,0,1,0-5,0V16A33,33,0,0,0,30.88,46.34H26a2.5,2.5,0,1,0,0,5h4.84A33,33,0,0,0,61.22,81.68v4.85a2.5,2.5,0,0,0,5,0V81.68A33,33,0,0,0,96.57,51.34h4.84a2.5,2.5,0,0,0,0-5Z"/>
            </g>
        </svg>
    </div>
);



