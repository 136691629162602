import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";

import PageTitle from "../../../../components/PageTitle";
import {
    PATH_PREFIX,
    SIRATING_ADMIN_CHART_SDG,
    SIRATING_ADMIN_CHART_THEMATICS_ENV,
    SIRATING_ADMIN_CHART_THEMATICS_GOV,
    SIRATING_ADMIN_CHART_THEMATICS_SOC
} from "../../../../env";
import {showlog} from "../../../../utils/dev-utils";
import {SiRatingAdminThemesChart} from "./thematics-chart";
import CardHeader from "reactstrap/es/CardHeader";
import ActionButton from "../../../../components/save-button";
import {beautify} from "../../../../utils/object-utils";
import MultiRatingListModal from "../../../../components/area/multi-rating-list-modal";
import {translate} from "../../../../components/i18n/translate-function";


export const SIRatingAdminComparationChartPage = props => {


    const [rating, setRating] = useState();
    const [score, setScore] = useState();
    const [ratingModal, toggleRatingModal] = useState( false );


    useEffect(() => {
        // toggleRatingModal( false );

        showlog("rating scelto");
        showlog( rating );

    }, [rating]);




    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    { label: "SI Rating", path: PATH_PREFIX +"/welcome" },
                    { label:  translate("Comparison") , active: true }
                ]}
                title={translate("Comparison")}
            />




            {
                (!!ratingModal)
                    ? (
                        <MultiRatingListModal
                            onChoose={ ratingData => {
                                toggleRatingModal( false );
                                setRating( ratingData );
                            }}
                            onClose={ () => {
                                toggleRatingModal( false );
                            }}
                        />
                    )
                    : null
            }


            <Row>
                <Col className={"sdg-flag-icon-container"}>
                    <h4>
                        {/*<span className={"tree-node-data-entry-value-box-component"}>Valutazione: </span>*/}
                        <ActionButton
                            buttonText={ translate("Select Rating") }

                            promise={ () => Promise.resolve() }
                            onResult={ () => {
                                setRating( null );
                                toggleRatingModal( true );
                            }}
                        />
                    </h4>
                </Col>
            </Row>


            <Row>

                <Col md={ 6 }>
                    <Card>
                        <CardHeader>
                            <h4>
                                <span className={"tree-node-data-entry-value-box-component"}>{translate("Evaluation")}: { !!rating ? rating.name || "" : "" }</span>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                !!rating
                                    ? (
                                        <React.Fragment>
                                            <Row><Col><strong>Azienda: </strong>{ rating.ragioneSociale }</Col></Row>
                                            <Row><Col><strong>Data Creazione: </strong>{ rating.dateCreate }</Col></Row>
                                            <Row><Col><strong>Stato: </strong>{ rating.state }</Col></Row>
                                            <Row><Col><strong>&nbsp;</strong>{}</Col></Row>
                                            <Row><Col><strong>&nbsp;</strong>{}</Col></Row>
                                            <Row><Col><strong>&nbsp;</strong>{}</Col></Row>
                                            <Row><Col><strong>&nbsp;</strong>{}</Col></Row>
                                        </React.Fragment>
                                    )
                                    : null
                            }
                        </CardBody>
                    </Card>
                </Col>

                <Col md={ 6 }>
                    <Card>
                        <CardHeader>
                            <h4>
                                <span className={"tree-node-data-entry-value-box-component"}>Rating</span>
                            </h4>
                        </CardHeader>
                        <CardBody>

                            {
                                !!score
                                    ? (
                                        <pre>
                                            { beautify( score ) }
                                        </pre>
                                    )
                                    : null
                            }

                            <React.Fragment>
                                <Row><Col><strong>{translate("Overall Rating:")} </strong>{  }</Col></Row>
                                <Row><Col><strong>{translate("Environmental Rating:")} </strong>{  }</Col></Row>
                                <Row><Col><strong>{translate("Social Rating:")} </strong>{  }</Col></Row>
                                <Row><Col><strong>{translate("Governance Rating:")} </strong>{  }</Col></Row>
                                <Row><Col><strong>{translate("Resilience:")} </strong>{  }</Col></Row>
                                <Row><Col><strong>{translate("Transparency:")} </strong>{  }</Col></Row>
                                <Row><Col><strong>{translate("Improvement:")} </strong>{  }</Col></Row>
                            </React.Fragment>

                        </CardBody>
                    </Card>
                </Col>

            </Row>




            <Card>
                <CardHeader>
                    <h4>{translate("Thematic")}</h4>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <SiRatingAdminThemesChart
                                title={"Environmental"}
                                multipleData={ true }
                                rating={ rating }
                                what={ SIRATING_ADMIN_CHART_THEMATICS_ENV }
                                onDataLoaded={ data => {
                                    // wait( false );
                                    showlog("Environmental data loaded");
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <SiRatingAdminThemesChart
                                title={"Social"}
                                rating={ rating }
                                what={ SIRATING_ADMIN_CHART_THEMATICS_SOC }
                                onDataLoaded={ data => {
                                    showlog("Social data loaded");
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <SiRatingAdminThemesChart
                                title={"Governance"}
                                rating={ rating }
                                what={ SIRATING_ADMIN_CHART_THEMATICS_GOV }
                                onDataLoaded={ data => {
                                    showlog("Governance data loaded");
                                }}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>


            <Card>
                <CardHeader>
                    <h4>SDG</h4>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <SiRatingAdminThemesChart
                                title={translate("Percentages")}
                                rating={ rating }
                                what={ SIRATING_ADMIN_CHART_SDG }
                                onDataLoaded={ data => {} }
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </React.Fragment>
    );


}
