import React from "react";
import "./gender-gap-icon-svg-component.css";

export const GenderGapIconSvgComponent = props => (
	<div
		id={ props.id }
		className={"logo-container-ssml "+ props.className}
		onClick={ clickEvent => {
			// clickEvent.stopPropagation();
			// clickEvent.preventDefault();
		}}
	>
		<svg
			id="Livello_1"
			xmlns="http://www.w3.org/2000/svg"
			// xmlns:xlink="http://www.w3.org/1999/xlink"
	        viewBox="0 0 453.54 453.54"
		>
			<defs>
				<clipPath id="clippath">
					<path className="gg-5" d="M204.63,49.05l-.2,.08v10.17c.1-6.38,.2-10.25,.2-10.25Z"/>
				</clipPath>
				<clipPath id="clippath-1">
					<rect className="gg-5" x="199.43" y="44.05" width="10.2" height="20.25"/>
				</clipPath>
				<clipPath id="clippath-3">
					<path className="gg-5" d="M204.43,404.41l.2,.08s-.11-3.88-.2-10.25v10.17Z"/>
				</clipPath>
				<clipPath id="clippath-4">
					<rect className="gg-5" x="199.43" y="389.24" width="10.2" height="20.25"/>
				</clipPath>
			</defs>
			<g className="gg-4">
				<g className="gg-2">
					<image width="22" height="43" transform="translate(199.1 43.68) scale(.48)"
					       href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAArCAYAAAB8UHhIAAAACXBIWXMAABcRAAAXEQHKJvM/AAAD90lEQVRIS42XzYseRRDGfzXvxA0Bk4sb0P9AXJUclKgERFA8CW/wouDND7zoRYWIdw9exIt/gAE9iMGbSCKBgKA5GSN61Iu4Lnjxsrsz0+Whqvpj5n0XC2b77e6qp55+unqmV1RV+R+Wfv0k/+4efOsETzPZBqwHt0m/fMh4cA1kkwf0963pHrqC7D62mNsIPN68zPT3BkAB5t4Kq/Nr+qe/al1rYD24zdF3j5fJAN7COJKoQn9+zWqvsG+AD7+QBYhsA8UAa+t31/TPGPMuBscba0hAkma5qtuf4mTPuH+N8cZlwBnr/o8cXb9YHDt37uoMbJckxQ8jtfPsD/QAh99ebIIkHCcBmYEvTBbjuv+9AZMowOLLlNxdmGqrfZbF2+mvm/Tpp4/bjAEaO86Mdbgl2KbN+OfXxlizRmRQ6ap+CvqRrQAuToH3exSYKCx942o5TrTZCjOwqhrjuQOmYz0slISbDmE90FvtmhwiLcqMhCtgy2gkmDMW6FFFh86W39ViV0FzAKGtpIZ6B5KMsY4+lvJBBEAktWBRYwGqoFpiRPBS7Og1YQchb1ZJr3Qtq3xyHCyYR7H4lGqWguIYpZSrRK1VyDUoWAk2q/HfnrvXCXSsJMjMg51kBfKJi34C6DbMu8YMQvtOEGOnpZunoLCr3C1GfXhFr0nRsaYRKKnouwFsc19yjFXFUEvhSUSKjqKZ0cLmPp1CEnqSosf3eJAWFrqyNm+enJzAASNJrwl0cJbatYKGSSqgOXGVYKVWsp3OpDjul4yiTQJ1PUP5rQ40+FiMC7Z5DOJnv5qBfJIaKQRAy1zlZ6VmsT2TuhQVAICoHVelfPvyxipaJdIOe5EFxgrX+Lh6qwfD0C9J+bhGYCys84HgIoomgRGrCoIxVJtXxqzOi0lTFdUK4wXe1XUcrOJpkKpxAc1gMydXiqT+aRpL8HxDsil2yuv3MLFhlZ/YHzvSk1SXDrPmrljvnQJdJVPo7Jsa5owjktKKIzVyqo1lElqx1SIF+SttvUxywxeKhDFNlBtfxdwkMYCdJy8YsE5VMFSV4dRjfMBAwz/L4CGeaOfCE3RnXrpijAcp7Yg9g8CRA02OMVT9wX3GKnYCVC3nzlOPFud4xgpswgKbPsTVIY8lYBTOvPy+AZ999V0bzIHOonlo+8lZRkKPP/fOGwDlRv/P2y9wdP0OYE7NRvq7AKiu6rS3/Q7olPt//qN1O/uasdbJQWOZzNrqCd/w3/38asC1/4MMd29x8OIrS8CwqO+wrrS7X37Gqb1LiykATu1d4oHffufcB6+3oEp7eCqZTj/3yAIUZoxrG+7e4t9PP+Lwmzubpjn9/MPc++Z7C8Cw/wCEmEEzMXJCNQAAAABJRU5ErkJggg=="/>
				</g>
			</g>
			<path className="gg-7"
			      d="M356.57,103.38c-5.85-6.15-12.12-11.9-18.79-17.17-25.01-19.77-55.43-33.01-88.67-37.15h0s.34,12.17,.34,28.87c0,8.91,6.69,16.24,12.93,21.14,3.63,2.85,7.8,4.91,12.1,6.61,5.26,2.07,10.35,4.48,15.24,7.19,40.08,22.19,67.24,64.86,67.24,113.92s-27.15,91.73-67.23,113.91c-4.9,2.71-9.99,5.12-15.25,7.19-4.3,1.69-8.47,3.75-12.1,6.61-6.24,4.9-12.93,12.23-12.93,21.14,0,16.69-.34,28.87-.34,28.87,33.24-4.14,63.65-17.38,88.67-37.15,6.67-5.27,12.94-11.02,18.79-17.17,30.57-32.12,49.35-75.55,49.35-123.39s-18.78-91.28-49.35-123.39Z"/>
			<g className="gg-6">
				<g className="gg-3">
					<image width="22" height="44" transform="translate(199.1 388.8) scale(.48)"
					       href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAsCAYAAABhVUjwAAAACXBIWXMAABcRAAAXEQHKJvM/AAAEDUlEQVRIS42XPY8cRRCGn+rp+5CQMxbOfwFhRAICJCSLyAlYtkyETAwREICEiLkAEmcQ28IJyKQOLIy0yJZs8eU7foBDOASBxXHH7U0RdFV3zewsXEmjmemufuutt6qnd0VVlRPYX1c/BuCxNz/6H89isgr4aHfOo88+4eDmztQ0m+fOcOrtD1h7+uXJ+SXgo905jz7/lIObD9rgOLS0x81zz3DqrfeXAgyAj3bn7F26DL0N+H2VpXaffXVtAF6BF7tz9l6/DEANFQOkcI+WQCyD2ZfXyAZegf9451UOb+1UsIFAkXkAliAJCUjK6Z2HzW2xO+fw9gNIahdIVxaK2HMXxuyZpJDt6hQE9r/YLkFVVf987zUObv/cojvD/2rEMVsfS3D6+4dkgIPvfoIueMZFJwGvRSzO+9e3yfvXt6EDJCB0gMqwaNV0WMAxOIAImSRFr04BCUXTBtix3BHj7gAkASiHP9wlIxTxtQxG4pW5QhFtpEuUTOxSOLz3owNLW6TmVQs4IXLsM5eqkgMEsiRB4iAUsK7cRAy7KGW4WgFWWSZhadLADUgAJNR13C3+HmTw94yAdH2Z6J3SKH0V1LWWwDhF+cwsSCYJrJsUMpbE6SriQTE/pABHmYJLlgSy0ZcCqM24c+pLFuKDERhIFtgL7YXslEwnBSCJpTiUoRRQUNXaDGoEJGktdK1yFkiu8Zpr6w6OaotHUohoY+cyJGMuiiQlSyfI+rGt6KEfbTPpW+pRKqH0rpsKrJV3qV2xcQR9Mi3/AU2ABfMCYWC9QBe1p80nrcEL46SQjguzFL44Kq2lIoDPiTLu3xJQydKBbCyKk4rJkUwCWtq+MIIQ5lsJQCEjgqyH80gANVAmGMVgOpqrGYlv6ZgjeGN6B8kYMABpDyKh0ID2yaTIgy/5wNr2bYFKWwF473vG5ZLcmxTZHFWXT4fKsq/K0CUb13Z3YMsoS6L2X8WLhfAixoXaGKouHyWljxOFsYOobampQg1WYxmUTNrZWDqqSNH1wTkw9DG3ullkGMifE0bOP/RdcBoxHXSGM9OGVZOJgQSyiCBRJmfY0cBZtqWkRlKVw3TM2B9TfLeW6GnfBKWdtTGS2mlXGbsMUxYz8XedcLdAoqr691XfOQzv/jqVa3Csp7hZ3jpv53NgOuxh2ooxNfuoo9K2vFm3dbYw1l/vcXjrhQbgh+VkrhNWGRfdN9/oywaWJ58nb523PjRnP37kBFf1U9afvWLLw3+QxTcXWfz29YDlQJoJi7/A8uwC+ZUbwOg3Y3fmw2nQVUyDT36igcKIsdvi24scj5gvaR1WdbML5LMNFFYAA+jefXTvDke/vLuygGtPXUFmLyGz55bmVgKPTffuo7/fRR5/cRJobP8CUga2mwyoXjwAAAAASUVORK5CYII="/>
				</g>
			</g>
			<path className="gg-7"
			      d="M204.29,375.62c0-7.05-4.18-13.1-9.01-17.74-4.41-4.24-9.71-7.49-15.42-9.69-5.8-2.24-11.39-4.88-16.75-7.89-39.69-22.29-66.53-64.76-66.53-113.53s26.84-91.24,66.53-113.53c5.36-3.01,10.95-5.65,16.75-7.88,5.71-2.2,11.01-5.45,15.42-9.69,4.83-4.64,9.01-10.7,9.01-17.74s.07-13.69,.14-18.61v-10.25c-33.43,4.16-64,17.54-89.09,37.5-6.38,5.08-12.4,10.58-18.03,16.47-30.76,32.15-49.68,75.73-49.68,123.75s18.92,91.6,49.68,123.75c5.63,5.89,11.65,11.39,18.03,16.47,25.1,19.96,55.66,33.34,89.09,37.5v-10.25c-.07-4.92-.14-11.34-.14-18.61Z"/>
			<path className="gg-1"
			      d="M288.22,218.06c-.5-1.14-2.88-6.56-6.23-14.53-1.47-3.5-3.13-7.48-4.9-11.81-6.32-15.45-32.27-16.89-32.27-16.89,0,0-30.2-.26-40.83-.7-.16,0-.36-.01-.52-.02-.18,0-.38,.02-.57,.02l-37.59-.03h-.11s.05,.1,.11,.25c.5,1.14,2.88,6.56,6.23,14.53,1.47,3.5,3.13,7.48,4.9,11.81,6.32,15.45,32.27,16.89,32.27,16.89,0,0,30.21,.26,40.83,.7,.16,0,.36,.01,.51,.02,.18,0,.38-.02,.56-.02l37.6,.03h.11s-.05-.1-.11-.25Z"/>
			<path className="gg-1"
			      d="M165.32,244.23c.5,1.14,2.88,6.56,6.23,14.53,1.47,3.5,3.13,7.48,4.9,11.81,6.32,15.45,32.27,16.89,32.27,16.89,0,0,30.2,.26,40.83,.7,.16,0,.36,.01,.52,.02,.18,0,.38-.02,.57-.02l37.59,.03h.11s-.05-.1-.11-.25c-.5-1.14-2.88-6.56-6.23-14.53-1.47-3.5-3.13-7.48-4.9-11.81-6.32-15.45-32.27-16.89-32.27-16.89,0,0-30.21-.26-40.83-.7-.16,0-.36-.01-.51-.02-.18,0-.38,.02-.56,.02l-37.6-.03h-.11s.05,.1,.11,.25Z"/>
		</svg>
	</div>
);
