/**
 * Documentazione utile
 * https://gist.github.com/robatron/5681424
 * https://medium.com/javascript-in-plain-english/lets-extend-console-log-8641bda035c3
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/apply
 * https://developer.mozilla.org/it/docs/Web/JavaScript/Reference/Global_Objects/Function/apply
 *
 */
import {COLOR_POLICY, DEBUG} from "../env";
import {beautify} from "./object-utils";
import {emoj} from "./string-utils";
import {EMOJ} from "../emoj";
import {remoteLog} from "../business-logic/logs";

/**
 * Log condizionale, viene comandato dalla costante DEBUG
 * @param msg
 */
export let showlog = (...msg) => {
    if( !!DEBUG ) console.log.apply( console, msg );
};



export const mountedlog = (
    componentName,
    state,
    props,
    colorPolicy = { color: "#3388ff", contrastColor: "#ffffff" }
) => {

    showlog(
        "%c DID MOUNT %c "+ componentName +" ",
        "background: "+ colorPolicy.color +"; color: "+ colorPolicy.contrastColor +"; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold;",
        "background: "+ colorPolicy.color +"88; color: "+ colorPolicy.contrastColor +"; border-radius:0px 3px 3px 0px; padding:3px;"
    );
    if( !!state ) showlog( "state", state );
    if( !!props ) showlog( "props", props );
}

export const updatelog = (
    componentName,
    state,
    props,
    colorPolicy = { color: "#3388ff", contrastColor: "#ffffff" }
) => {

    showlog(
        "%c DID UPDATE %c "+ componentName +" ",
        "background: "+ colorPolicy.color +"; color: "+ colorPolicy.contrastColor +"; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold;",
        "background: "+ colorPolicy.color +"88; color: "+ colorPolicy.contrastColor +"; border-radius:0px 3px 3px 0px; padding:3px;"
    );
    showlog( "state", state );
    showlog( "props", props );
}

export const loadDatalog = (
    componentName,
    colorPolicy = { color: "#3388ff", contrastColor: "#ffffff" }
) => {
    showlog(
        "%c LOAD %c "+ (componentName || "") +" ",
        "background: "+ colorPolicy.color +"; color: "+ colorPolicy.contrastColor +"; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold;",
        "background: "+ colorPolicy.color +"88; color: "+ colorPolicy.contrastColor +"; border-radius:0px 3px 3px 0px; padding:3px;"
    );
}





export const renderlog = (
    componentName,
    state,
    props,
    colorPolicy = { color: "#3388ff", contrastColor: "#ffffff" }
) => {

    showlog(
        "%c RENDER %c "+ componentName +" ",
        "background: "+ colorPolicy.color +"; color: "+ colorPolicy.contrastColor +"; border-radius:3px 0px 0px 3px; padding:3px; font-weight: bold;",
        "background: "+ colorPolicy.color +"88; color: "+ colorPolicy.contrastColor +"; border-radius:0px 3px 3px 0px; padding:3px;"
    );
    showlog( "state", state );
    showlog( "props", props );
}

export const warninglog = message => {
    showlog(
        "%c"+ emoj(EMOJ.grimacing_face) +" "+ message +" ",
        "background: "+ COLOR_POLICY.warning.color +"; color: "+ COLOR_POLICY.warning.contrastColor +"; border-radius:3px 3px 3px 3px; padding:3px; font-weight: bold; font-size: 20px;"
    );
}

export const errorlog = (message, error) => {
    showlog(
        // "%c"+ emoj(EMOJ.face_with_symbols_on_mouth) +" errore %c "+ message.toUpperCase() +" ",
        "%c"+ emoj(EMOJ.face_screaming_in_fear) +" errore %c "+ (!!message.toUpperCase ? message.toUpperCase() : message) +" ",
        "color: "+ COLOR_POLICY.error.color +"; font-size: 20px;",
        "background: "+ COLOR_POLICY.error.color +"; color: "+ COLOR_POLICY.error.contrastColor +"; border-radius:3px 3px 3px 3px; padding:3px; font-weight: bold; font-size: 20px;"
    );
    showlog(error);
    return (
        remoteLog(
            message,
            error,
            {
                id: localStorage.getItem("id"),
                role: localStorage.getItem("role"),
                companyId: localStorage.getItem("company_id"),
                token: localStorage.getItem("jwtToken")
            }
        )
    );
}

/**
 * fa il log in console dela stringa formattata dell'oggetto passato
 * @param obj
 */
export const beautifyLog = obj => {
    showlog( beautify( obj ) );
};

