import React from "react";
import {Component} from "react";
import {
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Button,
    CardHeader,
    CardBody,
    Card,
    NavLink,
    UncontrolledCollapse,
    Col,
    Row,
    Nav,
    NavItem,
    TabContent,
    TabPane
} from 'reactstrap';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {
    loadAllComapnyAllowedDimensions,
    loadAllSectors, loadAllThemes, loadCompaniesData
} from "../../../business-logic/companies";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import {
    addNewQuestionSector,
    deleteSectorQuestion,
    editNewQuestionSector,
    loadRatingStatus
} from "../../../business-logic/rating";
import ActionButton from "../../save-button";
import {cloneObject} from "../../../utils/object-utils";
import {getSectorCompanies, loadSectorQuestionsDimensions} from "../../../business-logic/question-sectors";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {
    COLOR_POLICY,
    errorMessage,
    permissions,
    QUESTION_TEXT_COLOR_POLICY, RATING_STATUS,
    SIRATING_TREE_DEEP_LEVELS_NAME
} from "../../../env";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {hasPermission} from "../../../business-logic/auth";
import {loadSdg} from "../../../business-logic/sdg";
import classnames from "classnames";
import {getQuestionFatherTreeNodeByPath} from "../../../business-logic/question-tree-manager";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import moment from "moment";
import { ConsultantCompaniesAlloweds_Get } from "../../../services/request-manager";
import {DATETIME_FORMAT_IT}  from "../../../../src/env";
import {translate} from "../../i18n/translate-function";


const fieldMap = {
    user: translate('Operator'),
    date: translate('Date'),
    state: translate('Action')
};


const hiddenFields = [];


const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('Next'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            {translate("Rows")} {from} {translate("to")} {to} {translate("of")} {size} {translate('Stati')}
        </span>
    ),
    sizePerPageList: [5]
};

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];


const sortableFields = [
    "ragioneSociale",
    "localita"
];




const formatColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldMap[field] || field,
                    sort: sortableFields.includes(field)
                });
            })
            .filter(ff => !hiddenFields.includes(ff.dataField))
    );
}




const fieldSdgMap = {
    id: "Id",
    name: "Nome",
};


const hiddenSdgFields = [
    "srSdgQuestions",
    "srSdgQuestionsStructure"
];



const sortableSdgFields = [
    "Nome"
];

const formatSdgColumns = columnList => {
    return (
        columnList
            .map(field => {
                return ({
                    dataField: field,
                    text: fieldSdgMap[field] || field,
                    sort: sortableSdgFields.includes(field)
                });
            })
            .filter(ff => !hiddenSdgFields.includes(ff.dataField))
    );
}

const {SearchBar} = Search;

export default class StateModal extends Component {

    state = {
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
    }


    toggle = () => {
        if (!this.state.waiting) this.props.onClose();
    }


    render() {


        renderlog("StateModal", this.state, this.props, COLOR_POLICY.validated);




        let context = (


            (!!this.state.data && this.state.data.length > 0)
                ? (
                    <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        search
                    >
                        {props => (

                            <React.Fragment>
                                <Row>
                                    <Col>
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    {...props.baseProps}
                                    size="sm"
                                    responsive
                                    keyField="id"
                                    bordered={false}
                                    data={this.state.data}
                                    columns={formatColumns(Object.keys(this.state.data[0]))}
                                    defaultSorted={defaultSorted}
                                    pagination={paginationFactory(paginationOptions)}
                                    wrapperClasses="table-responsive"
                                />


                            </React.Fragment>

                        )}

                    </ToolkitProvider>
                )
                : (
                    <WaitingOrNodataMessage
                        waiting={ !(!!this.state.data && this.state.data.length === 0) }
                        nodataMessage={ translate('NoStates') }
                    />
                )



        );


        let content = (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={this.state.headerClassName}>
                    { translate('Operator Actions History') }
                </ModalHeader>
                <ModalBody className={this.state.bodyClass}>
                    {context}
                </ModalBody>

            </Modal>


        );


        return content;
    }

    componentDidMount() {
        this.loadData();
    }


    loadData() {

        loadRatingStatus( this.props.evaluation_id )
            .then(data =>

                data.map( raw => {

                        let statusRaw = Object.keys(RATING_STATUS).map(r => {
                            if (RATING_STATUS[r].id === raw.state ){
                                return RATING_STATUS[r].name
                            }
                        })
                        raw.state = statusRaw
                        return raw
                    }
                )

            )

            // finish ----------------------------------------------------------------------------------------------------------------
            .then(data => {

                data.map(x=>x.date=moment(x.date).format(DATETIME_FORMAT_IT))

                this.setState({
                    ...this.state,
                    data: data
                })
            })

            .catch(e => {
                errorlog("errore",e);
            })


    }

}
