import React, {useState} from "react";
import {translate} from "../../../components/i18n/translate-function";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {loadAssociatedSectorQuestion} from "../../../business-logic/question-sectors";
import {renderlog} from "../../../utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";

const fieldMap = {
    code: translate("Code"),
    versionComponent:"Versione",
    question_description: "Domanda di settore",
    questionVersion: translate("QuestionVersion"),
    evaluationVersion: translate("EvaluationVersion"),
    "Domanda di settore": translate("SectorQuestion"),
    Versione: translate("Version"),
    company_name: translate("Company")
};


const hiddenFields = [
    "question_id",
    "version",
    "id",
    "name",
    "versionComponent",
    "compani_id"
];


const sortableFields = [
    "code",
    "versionComponent",
    "versionComponent",
    "question_description",
    "questionVersion",
    "evaluationVersion",
    "Domanda di settore",
    "Versione"
];

const defaultSorted = [
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const AssociatedSectorQuestionModal = props => {

    renderlog("ASSOCIATED SECTOR QUESTIONS MODAL")

    const [sectorQuestionList, setSectorQuestionList] = useState();
    const [errors, setErrors] = useState();

    let content = (
        <div className={"white-backpan blur-backpan"}>
            <WaitingOrNodataMessage
                waiting={ true }
                // nodataMessage={}
                waitMessage={ translate("Loading_") }
                spinnerColor={ "primary" /*"#35f"*/ }
                fullHeight={ true }
                // className={}
                // spinnerClass={}
            />
        </div>
    )

    if( !sectorQuestionList ) {
        loadAssociatedSectorQuestion( props.evaluation.id )
            .then(list => {

                list = list.map((row, index) => {

                    if(!!row.version) {
                        row.versionComponent = (
                            <div style={{marginLeft: "50px"}}>
                                {row.version}
                            </div>
                        )
                    }else{
                        row.versionComponent = (
                            <div>
                                    Versione non disponibile
                            </div>
                        )
                    }

                    return row
                })

                return list

            })
            .then(list => {
                setSectorQuestionList( list );
            })
            .catch( e => {
                setSectorQuestionList([]);
                setErrors([translate("Error while getting sector questions list")])
            })
    }
    else {

        if(!!errors){

            content =  (

                <React.Fragment>
                    <ModalHeader  toggle={ toggleEvent => { props.onClose() } } >
                        Errore ottenimento domande di settore associate alla valutazione " {props.evaluation.name} "
                    </ModalHeader>
                    <ModalBody >
                        {errors}
                    </ModalBody>
                </React.Fragment>

            )

        }else if( sectorQuestionList.length === 0 ) {

            content =  (

                <ModalHeader  toggle={ toggleEvent => { props.onClose() } } >
                    Non sono presenti domande di settore associate alla valutazione " {props.evaluation.name} "
                </ModalHeader>

            )


        }else{

            content = (

                <React.Fragment>
                    <ModalHeader toggle={ toggleEvent => { props.onClose() }} >
                        Domande di settore associate alla valutazione " {props.evaluation.name} "
                    </ModalHeader>
                    <ModalBody className={"body"} style={{maxHeight: "837px", overflowY: "auto"}}>

                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            search
                        >
                            {props => (
                                <React.Fragment>

                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <SearchBar {...props.searchProps} />*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}

                                    <BootstrapTable
                                        //id={"id"}
                                        bootstrap4
                                        striped
                                        {...props.baseProps}
                                        size="sm"
                                        responsive
                                        keyField="id"
                                        bordered={ false }
                                        data={ sectorQuestionList }
                                        columns={ formatColumns( Object.keys( sectorQuestionList[0] ) ) }
                                        defaultSorted={ defaultSorted }
                                        //pagination={ paginationFactory(paginationOptions) }
                                        wrapperClasses="table-responsive"

                                    />


                                </React.Fragment>
                            )}
                        </ToolkitProvider>

                    </ModalBody>
                </React.Fragment>

            )
        }


        content = (
            <Modal
                isOpen={ true }
                toggle={ toggleEvent => { props.onClose() }}
                className={"large-modal"}
            >
                { content }
            </Modal>
        );

    }

    return content;

}


export default AssociatedSectorQuestionModal;
