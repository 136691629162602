import {
    Evaluations_Get,
    SiScoringAnswer_POST,
    SiScoringReport_Delete,
    SiScoringReport_Get,
    SiScoringReport_POST,
    SiScoringUserManual_DOWNLOAD
} from "./request-manager";
import {RATING_TYPE} from "../conf/siscoring";

export const getAllScoring = isAlive => Evaluations_Get( RATING_TYPE.siscoring, isAlive );

export const getSiScoringPdfData = id => SiScoringReport_Get( id );

export const uploadPdfContent = data => SiScoringReport_POST( data );

export const setSiScoringOpenAnswer = ( ratingID, questionID, questionStructureID, payload ) => SiScoringAnswer_POST( ratingID, questionID, questionStructureID, payload );


export const eraseSiScoringQuestion = id => SiScoringReport_Delete( id );


export const downloadSiScoringManual = lang => SiScoringUserManual_DOWNLOAD( lang );
