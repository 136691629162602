import {showlog} from './dev-utils';
import {isNotEmptyArray} from "./array-utils";

/**
 * Clona un oggetto, eliminando di fatto il riferimento
 * @param obj
 * @returns {any}
 */
export const cloneObject = obj => JSON.parse( JSON.stringify( obj ) );


export const safeCloneReactObject = obj => {



    let safeFields =
        Object.keys( obj )
            .filter( k => !!obj[k] && isNotEmptyArray(Object.keys(obj[k])) && !Object.keys(obj[k]).includes("_owner") )
            .filter( k => !!obj[k] && isNotEmptyArray(Object.keys(obj[k])) && !Object.keys(obj[k]).includes("_store") )
    ;

    let newObj = {};
    safeFields
        .map( sf => {
            newObj[sf] = obj[sf];
        })
    ;

    let nullFields =
        Object.keys( obj )
            .filter( k => !obj[k] )
    ;

    let otherFields =
        Object.keys( obj )
            .filter( k => !!obj[k] && !isNotEmptyArray(Object.keys(obj[k])) )
    ;
    otherFields
        .map( sf => {
            newObj[sf] = obj[sf];
        })
    ;

    nullFields
        .map( sf => {
            newObj[sf] = null;
        })
    ;

    return newObj;


}


/**
 * Da la stringa formattata dell'oggetto passato
 * @param obj
 * @returns {string}
 */
export const beautify = obj => {
    return JSON.stringify( obj, null, "\t");
};


export const isEmptyObject = obj => {
    if( !obj ) return true;
    return JSON.stringify( obj ) === "{}";
}



export const isNotEmptyObject = obj => {
    if( !obj ) return false;
    return JSON.stringify( obj ) !== "{}";
}
