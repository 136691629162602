import React from "react";
import "./trash-svg-icon-component.css";

export const TrashSvgIconComponent = props => (
    <div
        id={ props.id }
        className={props.className}
        onClick={ clickEvent => {
            // clickEvent.stopPropagation();
            // clickEvent.preventDefault();
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.07 125.07">
            <g id="a">
                <g>
                    <path className="trash-icon-c"
                          d="M82.97,79c0-3.16,0-6.33,0-9.49,0-6.26,0-12.53,0-18.79,0,6.26,0,12.53,0,18.79,0,3.16,0,6.33,0,9.49Z"/>
                    <path className="trash-icon-c"
                          d="M45.05,20.29c0,.8,0,1.61,0,2.48-6.13,0-11.95-.04-17.76,.01-4.1,.04-7.65,3.06-8.21,7.11-.32,2.35-.34,4.77-.26,7.15,.07,2.22,1.25,3.14,3.54,3.18,.82,.01,1.63,0,2.45,0,.17,1.73,.34,3.2,.46,4.68,.7,8.62,1.36,17.25,2.07,25.87,.85,10.3,1.78,20.58,2.57,30.88,.4,5.26,3.9,8.77,9.13,8.77,15.64,.01,31.28,.01,46.92,0,5.24,0,8.76-3.51,9.13-8.77,.48-6.83,1.09-13.64,1.65-20.46,.73-8.88,1.47-17.76,2.21-26.64,.4-4.74,.8-9.48,1.22-14.33,.95,0,1.71,0,2.48,0,2.29-.03,3.47-.96,3.54-3.18,.08-2.38,.02-4.79-.26-7.15-.31-2.65-1.79-4.79-4.2-5.91-1.58-.73-3.44-1.12-5.18-1.17-5.47-.14-10.95-.05-16.49-.05-.1-1.72-.03-3.29-.31-4.78-.68-3.58-3.31-5.46-6.39-6.86h-21.72c-.19,.14-.36,.32-.57,.41-4.04,1.62-5.99,4.44-6.02,8.76Zm45.87,59.41c-.58,7.02-1.11,14.03-1.64,21.05-.21,2.78-1.23,3.84-4,3.85-15.19,.01-30.38,.01-45.58,0-2.76,0-3.75-1.06-3.97-3.87-.99-12.29-2.02-24.58-3.02-36.88-.63-7.79-1.23-15.58-1.86-23.5h63.36c-1.11,13.23-2.21,26.29-3.29,39.36Zm4.37-51.13c4.5,0,5.41,1.08,4.73,5.72H25.09c-1.07-4.13,.13-5.72,4.29-5.72,21.97,0,43.94,0,65.92,0Zm-39.53-11.64c4.45,0,8.89,0,13.34,0,4.47,0,5.37,1.02,4.9,5.76h-22.88c-.85-4.3,.34-5.75,4.64-5.76Z"/>
                    <path className="trash-icon-c"
                          d="M42.05,88.61c0,.76-.09,1.74,.32,2.24,.69,.86,1.75,1.94,2.66,1.95,.91,0,2.02-1.05,2.69-1.91,.44-.56,.33-1.61,.33-2.43,.01-3.13,.01-6.26,.01-9.4,0-3.13,0-6.26,0-9.4,0-3.2,0-6.39,0-9.59,0-3.2,0-6.39-.01-9.59,0-.76-.08-1.55-.26-2.29-.35-1.44-1.42-2.39-2.8-2.06-1,.24-1.96,1.19-2.64,2.06-.4,.52-.31,1.48-.31,2.25-.02,12.72-.02,25.44,0,38.16Z"/>
                    <path className="trash-icon-c"
                          d="M48.06,79.06c0-3.13,0-6.26,0-9.4,0-3.2,0-6.39,0-9.59,0,3.2,0,6.39,0,9.59,0,3.13,0,6.26,0,9.4Z"/>
                    <path className="trash-icon-c"
                          d="M59.49,69.4c0,6.33,0,12.66,0,18.99,0,3.13,.92,4.53,2.96,4.57,2.07,.04,3.06-1.39,3.06-4.46,0-12.66,0-25.31,0-37.97,0-.52,.03-1.04-.05-1.55-.27-1.88-1.39-2.95-3.01-2.92-1.6,.02-2.69,1.12-2.93,3.01-.07,.57-.04,1.16-.04,1.74,0,6.2,0,12.4,0,18.6Z"/>
                    <path className="trash-icon-c"
                          d="M59.49,50.8c0,6.2,0,12.4,0,18.6,0,6.33,0,12.66,0,18.99,0-6.33,0-12.66,0-18.99,0-6.2,0-12.4,0-18.6Z"/>
                    <path className="trash-icon-c"
                          d="M76.96,88.77c0,.76-.07,1.74,.35,2.24,.67,.81,1.74,1.84,2.59,1.8,.96-.05,2.04-1.03,2.72-1.89,.44-.55,.34-1.6,.34-2.43,.01-3.16,.01-6.33,.01-9.49,0-3.16,0-6.33,0-9.49,0-6.26,0-12.53,0-18.79,0-.58,.03-1.17-.05-1.74-.27-1.88-1.39-2.95-3-2.93-1.6,.02-2.68,1.12-2.93,3.01-.05,.38-.03,.77-.03,1.16,0,12.85,0,25.7,.01,38.55Z"/>
                </g>
            </g>
            <g id="b"/>
        </svg>
    </div>
);
