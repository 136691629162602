import {LANGUAGES} from "../../../conf/languages";
import {getLastElementOr} from "../../../utils";


let langSmallCode =
    LANGUAGES
        .filter( l => l.code === (localStorage.getItem("language") || "IT-it") )
        .reduce( getLastElementOr, LANGUAGES[0] )
        .code
            .substr(0, 2)
            .toLowerCase()
;

export const cssContent = `
HTML {
        padding: 0px;
        margin: 0px;
    }
    BODY {
        padding: 0px;
        margin: 0px;
    }
    body {
        padding: 0px;
        margin: 0px;
    }
    widows {
        padding: 0px;
        margin: 0px;
    }
    
    @page {
        size: A4 landscape;
        margin: 0px;
    }
    
    #pdf-container {
        padding: 0px;
        margin: 0px;
        position: fixed;
        top: 0px;
        left: 0px;
    }
    
    #pdf {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        
        
    }
    
    
    .pdf-page {
        min-width: 630mm;
        max-width: 630mm;
        min-height: 437mm;
        max-height: 437mm;
        
        background-size: cover;
        background-position: 0% 0%;

        margin-bottom: 20px;
        padding: 0px;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    
    #circleChartImage {
        position: absolute;
        top: 2283px;
        transform: scale(2.1, 2.1);
    }
    
    #siscoring-pdf-page_1 {
        background-image: url("file:///C:/inetpub/wwwroot/sirating/assets/PdfSiScoring/${ langSmallCode }/ScoreCard_${ langSmallCode }_01.png");
        
    }
    #siscoring-pdf-page_1 > SPAN {
        width: 100%;
        margin-top: 160px;
        text-align: center;
        font-family: Verdana;
        font-size: 50pt;
    }
    #siscoring-pdf-page_1 > SPAN.wait-container {}
    #siscoring-pdf-page_1 > SPAN.text-content {
        margin-top: 950px;
        margin-left: 330pt;
        width: 100%;
        font-size: 50pt;
        font-weight: 100;
        display: inline-block;
        color: #6b6c6b;
    }
    
    #siscoring-pdf-page_2 {
        background-image: url("file:///C:/inetpub/wwwroot/sirating/assets/PdfSiScoring/${ langSmallCode }/ScoreCard_${ langSmallCode }_02.png");
    
    }
    #siscoring-pdf-page_2 > SPAN {
        margin-top: -200pt;
        margin-left: 260pt;
        width: 570px;
        text-align: center;
    }
    
    
    #siscoring-pdf-page_3 {
        background-image: url("file:///C:/inetpub/wwwroot/sirating/assets/PdfSiScoring/${ langSmallCode }/ScoreCard_${ langSmallCode }_03.png");
    }
    
    #siscoring-pdf-page_4 {
        background-image: url("file:///C:/inetpub/wwwroot/sirating/assets/PdfSiScoring/${ langSmallCode }/ScoreCard_${ langSmallCode }_04.png");
    }
    
    #siscoring-pdf-page_5 {
        background-image: url("file:///C:/inetpub/wwwroot/sirating/assets/PdfSiScoring/${ langSmallCode }/ScoreCard_${ langSmallCode }_05.png");
    }
    
    #siscoring-pdf-page_6 {
        background-image: url("file:///C:/inetpub/wwwroot/sirating/assets/PdfSiScoring/${ langSmallCode }/ScoreCard_${ langSmallCode }_06.png");
    }
    
    .middle-line-text {
        font-size: 1.5pt;
        font-family: Lato;
    }
    
    #line-chart {
    
        width: 3500px;
        height: 1700px;
        
        position: relative;
        top: -16px;
        left: -560px;
        
    }
    
    #circleChart {
        position: absolute;
        top: 2283px !important;
        width: 210% !important;
        height: 210% !important;
        
    }
    
    
    #circleChartImage {
        position: absolute;
        top: 1825px !important;
        left: 0px;
        width: 2380px;
    }
    
    #companyData {
        width: 640px;
        min-height: 200px;
        position: absolute;
        top: 1250px;
        left: 250px;
        display: inline-block;
        color: #fff;
    }
    
    .companyData-element {
        margin-bottom: 2px;
        display: inline-block;
    }
    
    .companyData-element > LABEL {
        margin-bottom: 0px;
        font-weight: 700;
        text-shadow: 1px 3px 10px #0008;
        font-family: 'Raleway Medium', Lato, Verdana;
        font-size: 22pt;
        display: block;
    
    }
    
    .companyData-element > SPAN {
        text-shadow: 1px 3px 10px #0008;
        font-family: 'Raleway Medium', Lato, Verdana;
        font-size: 18pt;
    }
    
    
    #company-data-name {
      position: relative;
      top: -80px;
      left: -83px;
    }
    
    #company-data-address {
        top: -35px;
        position: relative;
        left: -83px;
        width: 100%;
    }
    
    #company-data-city {
        top: 0px;
        position: relative;
        left: -80px;
        width: 100%;
    }
    
    
    #company-data-vat {
        top: 40px;
        position: relative;
        left: -80px;
    }
    
    #company-data-release-date {
        top: 100px;
        position: relative;
        left: -80px;
        width: 100%;
    }
    
    
    #sdg-values-container {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 2325px;                
        left: 200px;
        width: 730px;
        max-width: 820px;
    }
    
    
    .sdg-value-perc {
        margin-right: 150px;
        margin-bottom: 130px;
        width: 70px;
        font-size: 28pt;
        float: left;
        font-family: Verdana;
    }
    
    
    #chartText {
    
    }
    
    .chart-small-text {
        color: #6b6c6b;
        font-size: 40pt;
        font-family: Verdana;
    }
    
    .blue-text {
        color: rgb(1, 65, 128) !important;
        position: absolute;
        top: 1935px;
        left: 2100px;
    }
    
    .green-text {
        color: rgb(1, 120, 58);
        position: absolute;
        top: 2400px;
        left: 1200px;
    }
    
    .red-text {
        color: rgb(240, 112, 76);
        position: absolute;
        top: 2360px;
        left: 2090px;
    }
    
    .main-text {
        font-size: 72pt;
        position: absolute;
        top: 2150px;
        left: 1428px;
    }
    
    
    
    
    .block-perc {
        color: #fff;
        font-size: 28pt;
        position: relative;
        font-family: Raleway, Lato, Verdana, Calibri, Arial;
    }
    
    #envAmbPerc {
        position: relative;
        left: 13%;
        top: 200mm;
        display: block-inline;
    }
    
    #envECPerc {
        position: relative;
        display: block-inline;
        left: 13%;
        top: 265mm;
    }
    
    #envRRPerc {
        position: relative;
        display: block-inline;
        left: 13%;
        top: 325mm;
    }
    
    
    
    
    
    
    #socCond {
        position: relative;
        display: block-inline;
        left: 45%;
        top: 120mm;
    }
    
    #socRCC {
        position: relative;
        display: block-inline;
        left: 45%;
        top: 160mm;
    }
    
    #socSQ {
        position: relative;
        display: block-inline;
        left: 45%;
        top: 275mm;
    }
    
    
    
    
    
    
    #govSTR {
        position: relative;
        display: block-inline;
        left: 77%;
        top: 130mm;
    }
    #govEt {
        position: relative;
        display: block-inline;
        left: 77%;
        top: 195mm;
    }
    
    #govInn {
        position: relative;
        display: block-inline;
        left: 77%;
        top: 250mm;
    }
    
    
    #chartImage {
        transform: scale(0.5, 0.5)
    }
    
    `;
