// @flow
import React from 'react';
import CardBody from "reactstrap/es/CardBody";
import {Card, CardHeader, Col, Progress, Row, Table} from "reactstrap";
import {translate} from "../components/i18n/translate-function";


export default class CookiePolicyPage extends React.Component {

    state = {
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div className={"page column-layout max-width-centered"}>

                <h3>Cookie Policy</h3>

                <Card
                    className={"mb-0 simple-page-card"}
                    Style={ "margin-bottom: 10rem !important;" } // l'unico modo per poterlo fare
                    key={"welcome-page"}
                >
                    {/*<CardHeader>*/}
                    {/*    <h5 className="m-0">*/}
                    {/*        <div className="custom-accordion-title d-block pt-2 pb-2">*/}
                    {/*            Cookie Policy del sito*/}
                    {/*        </div>*/}
                    {/*    </h5>*/}
                    {/*</CardHeader>*/}
                    <CardBody>

                        <Row>
                            <Col>

                                <h5 className={"custom-accordion-title d-block pt-2 pb-2"}> {translate("Extended information text on the use of cookies")} </h5>

                                <div className={"justify"}>
                                    {translate("cookie policy first paragraph")}
                                    <span> <a href="/privacy-policy" target={"_blank"}>https://siroad.it/privacy-policy</a></span>.
                                </div>

                                <br/>

                                <h5 className={"custom-accordion-title d-block pt-2 pb-2"}>  {translate("Use of Cookies")}  </h5>

                                <div className={"justify"}>
                                    {translate("cookie policy second paragraph")}
                                    <br/><br/>
                                    <ul>
                                        <li>
                                            {translate("Store the preferences you have entered.")}
                                        </li>
                                        <li>
                                            {translate("Avoid re-entering the same information several times during your visit, such as your username and password.")}
                                        </li>
                                        <li>
                                            {translate("Analyze the use of services and content provided by the Site to optimize the browsing experience and the services offered.")}
                                        </li>
                                    </ul>
                                </div>

                                <br/>

                                <h5 className={"custom-accordion-title d-block pt-2 pb-2"}> {translate("Types of cookies used")} </h5>

                                <div className={"justify"}>
                                    {translate("Below are the various types of cookies used by the Site according to their purpose of use")}.
                                </div>

                                <br/>

                                <h5 className={"custom-accordion-title d-block pt-2 pb-2"}>  {translate("Technical cookies")} </h5>

                                <div className={"justify"}>
                                    {translate("This type of cookie allows certain sections of the Site to function correctly. They are of two categories, persistent and session")}:
                                    <br/><br/>
                                    <ul>
                                        <li>
                                            {translate("persistent: once the browser is closed, they are not destroyed but remain until a preset expiry date.")}
                                        </li>
                                        <li>
                                            {translate("session: they are destroyed each time the browser is closed.")}
                                        </li>
                                    </ul>
                                    {translate("technical cookies second paragraph")}


                                    <br/><br/>
                                    <Table responsive className="table-sm table-centered mb-0 font-14">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>{translate("COOKIE NAME")}</th>
                                            <th>{translate("PURPOSE")}</th>
                                            <th>{translate("PERSISTENT/SESSION COOKIE")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>RefreshToken</td>
                                            <td>{translate("Technical cookie")}</td>
                                            <td>{translate("Session")}</td>
                                        </tr>
                                        <tr>
                                            <td>User</td>
                                            <td>{translate("Technical cookie")}</td>
                                            <td>{translate("Session")}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        </tbody>
                                    </Table>

                                </div>

                                <br/>

                                <h5 className={"custom-accordion-title d-block pt-2 pb-2"}> {translate("Third party websites and service")} </h5>

                                <div className={"justify"}>
                                    {translate("cookie policy last paragraph first part")}
                                </div>

                                <br/>

                                <div className={"justify"}>  {translate("How to disable cookies in the main browsers by configuration:")} </div>

                                <br/>

                                <div className={"justify"}>

                                    <ul>
                                        <li>
                                            Apple Safari macOS
                                        </li>
                                        <li>
                                            Apple Safari iOS
                                        </li>
                                        <li>
                                            Google Chrome
                                        </li>
                                        <li>
                                            Microsoft Edge e Internet Explorer
                                        </li>
                                        <li>
                                            Mozilla Firefox
                                        </li>
                                        <li>
                                            Opera
                                        </li>
                                    </ul>

                                    <br/>

                                    {translate("cookie policy last paragraph second part")}<span> <a href="http://www.youronlinechoices.com/it/le-tue-scelte" target={"_blank"}>http://www.youronlinechoices.com/it/le-tue-scelte</a></span>.
                                    <br/><br/>
                                    {translate("cookie policy last paragraph third part")}
                                </div>

                            </Col>
                        </Row>


                    </CardBody>
                </Card>


            </div>
        );
    }


};

