import React from "react";
import {withRouter} from 'react-router-dom';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Collapse,
    CustomInput,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import ActionButton from "./save-button";
import {WaitingOrNodataMessage} from "./waiting-or-nodata-message";
import classnames from "classnames";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {
    beautify,
    capitalize,
    cloneObject,
    emoj,
    errorlog,
    extractErrorMessage,
    getLastElementOr,
    renderlog,
    showlog
} from "../utils";
import {CALENDAR_DATE_FORMAT_IT, COLLECT_STATUS, COLOR_POLICY, DATE_FORMAT_IT, permissions, role} from "../env";
import {hasPermission, isUserRole} from "../business-logic/auth";
import {
    deleteInsurer,
    editCollect,
    loadInsurer,
    saveNewInsurer,
    setAssuranceRequirement,
    setInsurerList
} from "../business-logic/collect";
import {EMOJ} from "../emoj";
// import {ProfileGrid} from "./profiles-grid/profile-grid";
// import "./profiles-grid/profile-grid.css";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {loadMeasureUnitList, setUnit, toggleCollectPmAuthorized} from "../business-logic/gri";

// calendar
import HyperDatepicker from "./Datepicker";
import moment from "moment";
import {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import Select from "react-select";
import {translate} from "./i18n/translate-function";

registerLocale("it", it); // register it with the name you want



const hiddenFields = ["id", "active"];

const fieldMap = {
    name: "Nome",
    trash: " "
};


const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: translate('First'),
    prePageText: translate('backward'),
    nextPageText: translate('forward'),
    lastPageText: translate('Last'),
    nextPageTitle: translate('first page'),
    prePageTitle: translate('previous page'),
    firstPageTitle: translate('next page'),
    lastPageTitle: translate('last page'),
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Utenti
        </span>
    ),
    sizePerPageList: [
        {
            text: 'pochi',
            value: 25,
        },
        {
            text: 'normale',
            value: 50,
        },
        {
            text: 'molti',
            value: 250,
        }
    ], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];

const sortableFields = [
];

const formatColumns = columnList => {
    return (
        columnList
            .map( field => {
                return ({
                    dataField: field,
                    text: fieldMap[ field ] || field,
                    sort: sortableFields.includes( field )
                });
            })
            .filter( ff => !hiddenFields.includes(ff.dataField) )
    );
}

const { SearchBar } = Search;


class ManageCollectModal extends React.Component {

    state = {
        inWait: true,
        activeTab: !!this.props.isInAssurance ? "assurance" : "general",
        activeSection: null,
        // enabledSaveButton: false,
        enabledSaveButton: (
            !!this.props.collect.Inizio
            && !!this.props.collect.dateEnd
            && !!this.props.collect.name
            && !!this.props.collect.raw.year
        ),
        isPMAuthorized: !!this.props.collect.raw.isPmauthorized,
        haveAssurance: !!this.props.collect.raw.isAssuranceRequirement,

        start: this.props.collect.Inizio,
        end: this.props.collect.dateEnd,
        year: this.props.collect.raw.year,
        name: this.props.collect.name,

        dateEnd: this.props.collect.dateEnd,
        Inizio: this.props.collect.Inizio,
        // startTime: moment( new Date() ).format( DATE_FORMAT_IT ),
        startTime: moment( this.props.collect.Inizio, DATE_FORMAT_IT ).format( DATE_FORMAT_IT )
    }

    constructor( props ) {
        super( props );
        this.toggle = this.toggle.bind( this );
        this.buildComponent = this.buildComponent.bind( this );
        this.addConsulerContent = this.addConsulerContent.bind( this );
    }

    toggle = closeData => {
        if( typeof this.props.onClose === "function" ) this.props.onClose( closeData );
    }

    addConsulerContent = () => (
        <div
            className={"padded-content"}
        >
            <AvForm className={"manage-collect-modal-form"}>
                <AvGroup className={"audit-low-noncompliant-group"}>
                    <div className={"manage-collect-modal-form-group"}>
                        <Label for={"new-consuler-name"}>Nome</Label>
                        <AvInput
                            className={""}
                            type="text"
                            name={"new-consuler-name"}
                            id={"new-consuler-name"}
                            defaultValue={ this.state.newConsulerName }
                            onChange={ changeEvent => {
                                this.setState({
                                    ...this.state,
                                    enabledSaveButton: (!!this.state.newConsulerLastname && !!this.state.newConsulerEmail && changeEvent.target.value.trim())
                                });
                            }}
                            onBlur={ blurEvent => {
                                showlog("name", blurEvent.target.value);
                                this.setState({
                                    ...this.state,
                                    newConsulerName: blurEvent.target.value,
                                    enabledSaveButton: (!!this.state.newConsulerLastname && !!this.state.newConsulerEmail && blurEvent.target.value.trim())
                                });

                            }}

                            required
                        />
                    </div>
                    <div className={"manage-collect-modal-form-group"}>
                        <Label for={"new-consuler-name"}>Cognome</Label>
                        <AvInput
                            className={""}
                            type="text"
                            name={"new-consuler-lastname"}
                            id={"new-consuler-lastname"}
                            defaultValue={ this.state.newConsulerLastname }
                            onChange={ changeEvent => {
                                this.setState({
                                    ...this.state,
                                    enabledSaveButton: (!!this.state.newConsulerName && !!this.state.newConsulerEmail && changeEvent.target.value.trim())
                                });
                            }}
                            onBlur={ blurEvent => {
                                showlog("last-name", blurEvent.target.value);
                                this.setState({
                                    ...this.state,
                                    newConsulerLastname: blurEvent.target.value,
                                    enabledSaveButton: (!!this.state.newConsulerName && !!this.state.newConsulerEmail && blurEvent.target.value.trim())
                                });

                            }}

                            required
                        />
                    </div>
                    <div className={"manage-collect-modal-form-group"}>
                        <Label for={"new-consuler-email"}>Email</Label>
                        <AvInput
                            className={""}
                            type="text"
                            name={"new-consuler-email"}
                            id={"new-consuler-email"}
                            defaultValue={ this.state.newConsulerEmail }
                            onChange={ changeEvent => {
                                this.setState({
                                    ...this.state,
                                    enabledSaveButton: (!!this.state.newConsulerName && !!this.state.newConsulerLastname && changeEvent.target.value.trim())
                                });
                            }}
                            onBlur={ blurEvent => {
                                showlog("email", blurEvent.target.value);
                                this.setState({
                                    ...this.state,
                                    newConsulerEmail: blurEvent.target.value,
                                    enabledSaveButton: (!!this.state.newConsulerName && !!this.state.newConsulerLastname && blurEvent.target.value.trim())
                                });

                            }}

                            required
                        />
                    </div>
                </AvGroup>
            </AvForm>

        </div>
    );

    buildComponent = () => {
        let mainArea = (
            <React.Fragment>

                <Nav tabs>

                    {
                        !!this.props.isInAssurance
                            ? null
                            : (
                                <NavItem key={"general"}>
                                    <NavLink
                                        id={"general"}
                                        className={classnames({active: this.state.activeTab === "general"}) + " tooltip-icon-cursor" + ((this.state.activeTab === "general") ? " set-collect-modal-tab-active" : "")}
                                        onClick={() => {

                                            if (this.state.activeTab !== "general") {
                                                this.setState({
                                                    ...this.state,
                                                    activeTab: "general"
                                                })
                                            }

                                        }}
                                    >
                                        <span className={"tab"}>
                                            <i className={"tab-icon mdi mdi-wrench" + ((this.state.activeTab === "general") ? " tab-icon-active" : "")}/>
                                            Generale
                                        </span>
                                    </NavLink>
                                </NavItem>
                            )
                    }


                    {
                        !!this.state.haveAssurance
                            ? (
                                <NavItem key={"assurance"}>
                                    <NavLink
                                        id={"assurance"}

                                        className={classnames({active: this.state.activeTab === "assurance"}) + " tooltip-icon-cursor" + ((this.state.activeTab === "assurance") ? " set-collect-modal-tab-active" : "")}
                                        onClick={() => {

                                            if (this.state.activeTab !== "assurance") {
                                                this.setState({
                                                    ...this.state,
                                                    activeTab: "assurance"
                                                })
                                            }

                                        }}
                                    >
                                        <span className={"tab"}>
                                            {/*    mdi mdi-shield-star   mdi mdi-shield-check    mdi mdi-shield-sun-outline     mdi mdi-shield-check-outline  mdi mdi-shield-account  */}
                                            <i className={"tab-icon mdi mdi-shield-check" + ((this.state.activeTab === "assurance") ? " tab-icon-active" : "")}/>
                                            Assurances
                                        </span>
                                    </NavLink>
                                </NavItem>
                            )
                            : null
                    }


                    {
                        !!this.props.avoidMeasureUnits
                            ? null
                            : (
                                <NavItem key={"measureUnit"}>
                                    <NavLink
                                        id={"measureUnit"}

                                        className={classnames({active: this.state.activeTab === "measureUnit"}) + " tooltip-icon-cursor" + ((this.state.activeTab === "measureUnit") ? " set-collect-modal-tab-active" : "")}
                                        onClick={() => {

                                            if (this.state.activeTab !== "measureUnit") {
                                                this.setState({
                                                    ...this.state,
                                                    activeTab: "measureUnit"
                                                })
                                            }

                                        }}
                                    >
                                        <span className={"tab"}>
                                            <span className={"tab-icon" + ((this.state.activeTab === "measureUnit") ? " tab-icon-active" : "")}>{ emoj( EMOJ.thermometer ) }</span>
                                            { translate("MeasureUnit") }
                                        </span>
                                    </NavLink>
                                </NavItem>
                            )
                    }


                    {
                        !!this.props.urlparams.help
                            ? (
                                <NavItem key={"details"}>
                                    <NavLink
                                        id={"details"}

                                        className={classnames({active: this.state.activeTab === "details"}) + " tooltip-icon-cursor" + ((this.state.activeTab === "details") ? " set-collect-modal-tab-active" : "")}
                                        onClick={() => {

                                            if (this.state.activeTab !== "details") {
                                                this.setState({
                                                    ...this.state,
                                                    activeTab: "details"
                                                })
                                            }

                                        }}
                                    >
                                        <span className={"tab"}>
                                            <span className={"tab-icon" + ((this.state.activeTab === "details") ? " tab-icon-active" : "")}>{ emoj( EMOJ.ambulance ) }</span>
                                            Dettagli
                                        </span>
                                    </NavLink>
                                </NavItem>
                            )
                            : null
                    }


                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane
                        tabId={"general"}
                        key={"general"}
                        className={""}
                    >

                        <AvForm className={"manage-collect-modal-form"}>
                            <AvGroup className={"audit-low-noncompliant-group"}>
                                <div className={"manage-collect-modal-form-group"}>
                                    <Label for={"collect-name"}>Nome</Label>
                                    <AvInput
                                        className={""}
                                        type="text"
                                        name={"collect-name"}
                                        id={"collect-name"}

                                        defaultValue={this.props.collect.name}

                                        onChange={changeTextEvent => {

                                            if (!this.state.enabledSaveButton) {
                                                this.setState({
                                                    ...this.state,
                                                    // enabledSaveButton: true
                                                    enabledSaveButton: (!!this.state.Inizio && !!this.state.dateEnd && !!this.state.name && !!this.state.year)
                                                });
                                            }

                                        }}

                                        onBlur={blurEvent => {

                                            // TODO da controllare se va fatta l'attivazione anche qui

                                            this.setState({
                                                ...this.state,
                                                name: blurEvent.target.value
                                            });

                                        }}

                                        required
                                    />
                                </div>
                            </AvGroup>


                            <AvGroup className={"audit-low-noncompliant-group"}>
                                <div className={"manage-collect-modal-form-group-calendar"}>
                                    <Label for={"collect-year"}>Anno</Label>


                                    <Select
                                        className="react-select gri-type-select"
                                        classNamePrefix="react-select"
                                        name="gri-type-select"
                                        id="gri-type-select"
                                        value={{value: this.state.year, label: this.state.year}}
                                        onChange={ changeEvent => {
                                            this.setState({
                                                ...this.state,
                                                year: changeEvent.value
                                            })
                                        }}
                                        options={[
                                            { value: parseInt(this.state.startTime.substring(6), 10)+1, label: parseInt(this.state.startTime.substring(6), 10)+1 },
                                            { value: parseInt(this.state.startTime.substring(6)), label: parseInt(this.state.startTime.substring(6))  },
                                            { value: parseInt(this.state.startTime.substring(6), 10)-1, label: parseInt(this.state.startTime.substring(6), 10)-1 },
                                            { value: parseInt(this.state.startTime.substring(6), 10)-2, label: parseInt(this.state.startTime.substring(6), 10)-2 },
                                            { value: parseInt(this.state.startTime.substring(6), 10)-3, label: parseInt(this.state.startTime.substring(6), 10)-3 },
                                        ]}
                                        placeholder={ <span className={"select-placeholder"}>Seleziona Anno</span> }
                                    />



                                    {/* <AvInput
                                        className={""}
                                        type="number"
                                        name={"collect-year"}
                                        id={"collect-year"}
                                        defaultValue={this.props.collect.raw.year}
                                        disabled={ !hasPermission( permissions.EDIT_COLLECT_YEAR ) }
                                        onChange={changeTextEvent => {
                                            // if (!this.state.enabledSaveButton) {
                                                this.setState({
                                                    ...this.state,
                                                    year: changeTextEvent.target.value,
                                                    enabledSaveButton: true
                                                });
                                            // }
                                        }}
                                        onBlur={blurEvent => {
                                            this.setState({
                                                ...this.state,
                                                year: blurEvent.target.value
                                            });
                                        }}
                                    /> */}
                                </div>
                            </AvGroup>

                            <AvGroup className={"audit-low-noncompliant-group"}>
                                <div className={"manage-collect-modal-form-group-calendar"}>
                                    <Label for={"collect-start"}>Inizio</Label>

                                    <HyperDatepicker
                                        hideAddon={ false }
                                        dateFormat={ CALENDAR_DATE_FORMAT_IT }
                                        onSelect={clickEvent => {
                                            this.setState({
                                                ...this.state,
                                                Inizio: moment( clickEvent ).format( DATE_FORMAT_IT ),
                                                // enabledSaveButton: true
                                                enabledSaveButton: (!!this.state.Inizio && !!this.state.dateEnd && !!this.state.name && !!this.state.year)
                                            });
                                        }}
                                        value={ this.state.Inizio }
                                        dropdownMode="select"
                                        todayButton="Oggi"
                                        locale={"IT"}
                                        showMonthDropdown
                                        showYearDropdown
                                    />

                                </div>
                            </AvGroup>

                            <AvGroup className={"audit-low-noncompliant-group"}>
                                <div className={"manage-collect-modal-form-group-calendar"}>
                                    <Label for={"collect-end"}>Fine</Label>

                                    <HyperDatepicker
                                        hideAddon={ false }
                                        dateFormat={ CALENDAR_DATE_FORMAT_IT }
                                        onSelect={clickEvent => {
                                            this.setState({
                                                ...this.state,
                                                dateEnd: moment( clickEvent ).format( DATE_FORMAT_IT ),
                                                // enabledSaveButton: true
                                                enabledSaveButton: (!!this.state.Inizio && !!this.state.dateEnd && !!this.state.name && !!this.state.year)
                                            });
                                        }}
                                        value={ this.state.dateEnd }
                                        dropdownMode="select"
                                        todayButton="Oggi"
                                        locale={"IT"}
                                        showMonthDropdown
                                        showYearDropdown
                                    />

                                </div>
                            </AvGroup>


                            {
                                (!!isUserRole(role.PROJECT_MANAGER) || !!isUserRole(role.ARB_OPERATOR))
                                    ? null
                                    : (
                                        <AvGroup className={"audit-low-noncompliant-group"}>
                                            <div className={"manage-collect-modal-form-group"}>

                                                <CustomInput
                                                    type="switch"
                                                    id={"isPmAuthorized"}
                                                    name={"isPmAuthorized"}
                                                    label={"Autorizza ARB SBRL a socio unico"}
                                                    className={"pmAuthorizationToggler"}
                                                    onClick={clickEvent => {

                                                        if (!!document.getElementById("isPmAuthorized")) {
                                                            document.getElementById("isPmAuthorized").checked = !this.state.isPMAuthorized;
                                                        }


                                                        /*
                                                        setAssuranceRequirement( this.props.collect.id, !this.state.haveAssurance )
                                                            .then( result => {

                                                                this.loadData();


                                                            })
                                                            .catch( e => {
                                                                errorlog("pm authorized", e);
                                                            })
                                                        ;
                                                        */


                                                        toggleCollectPmAuthorized( this.props.collect.id, !this.state.isPMAuthorized )
                                                            .then( result => {
                                                                if( typeof this.props.refresh === "function" ) this.props.refresh()
                                                            })
                                                            .catch( e => {
                                                                errorlog("set assurance requirement", e);
                                                            })
                                                        ;
                                                        this.setState({
                                                            ...this.state,
                                                            isPMAuthorized: !this.state.isPMAuthorized,
                                                            AuthPmWarningMessage:
                                                                ( !this.state.isPMAuthorized )
                                                                    ? "ATTENZIONE: hai appena abilitato ARB a visualizzare i tuoi dati. Disattiva la funzionalità per revocare l'accesso"
                                                                    : ""
                                                        });



                                                    }}
                                                    checked={ !!this.state.isPMAuthorized }
                                                    defaultChecked={ !!this.state.isPMAuthorized }
                                                    valid={ !!this.props.collect.raw.isPmauthorized }
                                                />


                                            </div>
                                        </AvGroup>
                                    )
                            }


                            {
                                (!!hasPermission( permissions.SET_ASSURANCE ) /*&& (this.props.collect.raw.state === COLLECT_STATUS.confirmed.id)*/)
                                    ? (
                                        <AvGroup className={"audit-low-noncompliant-group"}>
                                            <div className={"manage-collect-modal-form-group"}>

                                                <CustomInput
                                                    type="switch"
                                                    id={"haveAssurance"}
                                                    name={"haveAssurance"}
                                                    label={"Assurance"}
                                                    className={"pmAuthorizationToggler"}
                                                    onClick={clickEvent => {

                                                        if (!!document.getElementById("haveAssurance")) {
                                                            document.getElementById("haveAssurance").checked = !this.state.haveAssurance;
                                                        }

                                                        setAssuranceRequirement( this.props.collect.id, !this.state.haveAssurance )
                                                            .then( result => {
                                                                this.setState({
                                                                        ...this.state,
                                                                        haveAssurance: !this.state.haveAssurance
                                                                    },
                                                                    () => {
                                                                        if( typeof this.props.refresh === "function" ) this.props.refresh()
                                                                    }
                                                                );
                                                            })
                                                            .catch( e => {
                                                                errorlog("set assurance requirement", e);
                                                                this.setState({
                                                                    ...this.state,
                                                                    haveAssurance: this.state.haveAssurance
                                                                });
                                                            })
                                                        ;


                                                    }}
                                                    defaultChecked={!!this.state.haveAssurance}
                                                    valid={!!this.props.collect.raw.isAssuranceRequirement}
                                                    disabled={ this.props.collect.raw.state !== COLLECT_STATUS.confirmed.id }
                                                />


                                            </div>
                                        </AvGroup>
                                    )
                                    : null
                            }



                        </AvForm>

                        <Row>
                            <Col sm={12}>
                                {
                                    !!this.state.AuthPmWarningMessage
                                        ? (
                                            <span className={"warningMessage"}>{ this.state.AuthPmWarningMessage }</span>
                                        )
                                        : null
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <br/>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={8}/>
                            <Col sm={4}>

                                <ActionButton
                                    color={"link"}
                                    buttonText={"annulla"}
                                    promise={ () => {
                                        return Promise.resolve();
                                    }}
                                    onResult={ toggleEvent => {
                                        if( !!this.state.addConsulerModal ) {
                                            this.setState({
                                                ...this.state,
                                                addConsulerModal: null
                                            });
                                        }
                                        else {
                                            this.toggle();
                                        }
                                    }}
                                    className={"float-right cancel-button-text"}
                                />

                                <ActionButton
                                    color={ !!this.state.enabledSaveButton ? "primary" : "none" }
                                    promise={ () => {

                                        return (
                                            new Promise((success, fail) => {
                                                if( this.state.activeTab === "assurance" ) {
                                                    setInsurerList( this.state )
                                                        .then( result => {
                                                            success( result );
                                                        })
                                                        .catch(e => {
                                                            fail( e );
                                                        })
                                                }
                                                else {
                                                    editCollect( this.state )
                                                        .then( result => {
                                                            success( result );
                                                        })
                                                        .catch(e => {
                                                            fail( e );
                                                        })
                                                }
                                            })
                                            .then( result => {
                                                // return null;
                                                if( typeof this.props.refresh === "function" ) {
                                                    return (
                                                        this.props.refresh()
                                                            .then( result => {
                                                                return null;
                                                            })
                                                    );
                                                }
                                                else return null;
                                            })
                                            .catch( e => {
                                                return Promise.reject( e );
                                            })
                                        );

                                    }}
                                    onResult={ toggleEvent => {
                                        // debugger;
                                        // if( typeof this.props.refresh === "function" ) this.props.refresh();
                                        // this.toggle( this.state )
                                    }}
                                    onError={ error => {
                                        errorlog("save collect data", error);
                                    }}
                                    className={ "float-right "+ ((!!this.state.enabledSaveButton) ? "" : "disabled-save-button") }
                                />


                            </Col>
                        </Row>

                        {/*<GotoTopComponent/>*/}


                    </TabPane>
                    <TabPane
                        tabId={"assurance"}
                        key={"assurance"}
                        className={""}
                    >

                        <Card>

                                {
                                    !!this.state.addConsulerModal
                                        ? this.addConsulerContent()
                                        : (
                                            <CardBody>

                                                <Row>
                                                    <Col sm={6}>
                                                        <Input
                                                            type={"search"}
                                                            className={"collect-modal-search"}
                                                            placeholder={"Cerca"}
                                                            onChange={ changeEvent => {
                                                                let val = changeEvent.target.value.toLowerCase();
                                                                if( !!val ) {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        data: this.state.copyData.filter( d => ((d.id === val) || (d.name.toLowerCase().indexOf( val ) !== -1)))
                                                                    })
                                                                }
                                                                else {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        data: this.state.copyData
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm={6}>
                                                        <ActionButton
                                                            buttonText={"Aggiungi"}
                                                            promise={() => {
                                                                return Promise.resolve();
                                                            }}
                                                            onResult={result => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    addConsulerModal: true,
                                                                    enabledSaveButton: false
                                                                })
                                                            }}
                                                            className={"float-right"}
                                                        />
                                                    </Col>
                                                </Row>

                                                {
                                                    !!this.state.notific
                                                        ? (
                                                            <Row className={"green-notific"}>{ this.state.notific }</Row>
                                                        )
                                                        : null
                                                }

                                                <Row>
                                                {
                                                    (!!this.state.data && this.state.data.length > 0)
                                                        ? (
                                                            <React.Fragment>
                                                                <ToolkitProvider
                                                                    bootstrap4
                                                                    keyField="id"
                                                                    search
                                                                >
                                                                    {
                                                                        props => (

                                                                            <BootstrapTable
                                                                                bootstrap4
                                                                                striped
                                                                                {...props.baseProps}
                                                                                size="sm"
                                                                                responsive
                                                                                keyField="id"
                                                                                data={ this.state.data }
                                                                                columns={ formatColumns( Object.keys( this.state.data.reduce( getLastElementOr, null ) ) ) }
                                                                                defaultSorted={ defaultSorted }
                                                                                // pagination={ paginationFactory( paginationOptions ) }
                                                                                wrapperClasses="table-responsive"
                                                                                selectRow={{
                                                                                    mode: 'checkbox',
                                                                                    clickToSelect: true,

                                                                                    onSelect: row => {
                                                                                        showlog("riga selezionata");
                                                                                        showlog( row );

                                                                                        let selectedList =
                                                                                            this.state.data
                                                                                                .filter( as => !!as.active )
                                                                                        ;
                                                                                        let refreshedData = this.state.data;

                                                                                        let currentRow =
                                                                                            this.state.data
                                                                                                .filter( as => as.id === row.id )
                                                                                                .reduce( getLastElementOr, null )
                                                                                        ;

                                                                                        if( !!currentRow.active ) {
                                                                                            // deselezione
                                                                                            selectedList =
                                                                                                this.state.data
                                                                                                    .filter( as => !!as.active )
                                                                                                    .filter( as => as.id !== currentRow.id )
                                                                                            ;
                                                                                            refreshedData =
                                                                                                this.state.data
                                                                                                    .map( as => {
                                                                                                        if( as.id === currentRow.id ) as.active = false;
                                                                                                        return as;
                                                                                                    })
                                                                                            ;
                                                                                        }
                                                                                        else {
                                                                                            // selezione
                                                                                            selectedList = [
                                                                                                ...selectedList,
                                                                                                currentRow
                                                                                            ]
                                                                                            refreshedData =
                                                                                                this.state.data
                                                                                                    .map( as => {
                                                                                                        if( selectedList.map( si => si.id ).includes( as.id ) ) as.active = true;
                                                                                                        return as;
                                                                                                    })
                                                                                            ;
                                                                                        }



                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            data: refreshedData,
                                                                                            enabledSaveButton: true,
                                                                                            assurancers: selectedList
                                                                                        });

                                                                                    },

                                                                                    selectionRenderer: row => {

                                                                                        showlog("selectionRenderer", row);
                                                                                        let currentRow =
                                                                                            this.state.data
                                                                                                .filter( as => as.id === row.rowKey )
                                                                                                .reduce( getLastElementOr, null )
                                                                                        ;
                                                                                        row.checked = !!currentRow.active;


                                                                                        return (
                                                                                            <div className="custom-control custom-checkbox">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="custom-control-input"
                                                                                                    checked={row.checked}
                                                                                                    disabled={row.disabled}
                                                                                                />
                                                                                                <label
                                                                                                    className="custom-control-label"
                                                                                                    onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                    }}/>
                                                                                            </div>
                                                                                        );
                                                                                    },

                                                                                    selectionHeaderRenderer: ({indeterminate, ...rest}) => {
                                                                                        return null;
                                                                                    }
                                                                                }}


                                                                            />

                                                                        )
                                                                    }
                                                                </ToolkitProvider>
                                                                {/*
                                                                <ProfileGrid
                                                                    list={
                                                                        this.state.data
                                                                            .map(item => Object.assign(
                                                                                {},
                                                                                item,
                                                                                {
                                                                                    id: item.id,
                                                                                    firstName: item.name.split(" ")[0],
                                                                                    lastName: item.name.split(" ")[1]
                                                                                })
                                                                            )
                                                                    }
                                                                    hidden={["id", "name", /*"firstName", "lastName",* / "active"]}
                                                                    tag={ false }
                                                                    checkbox={ true }
                                                                    fieldMap={{
                                                                        firstName: "nome",
                                                                        lastName: "cognome"
                                                                        // name: "nome"
                                                                    }}
                                                                    selectable={ true }
                                                                    onSelect={ selectedList => {
                                                                        showlog("selezionati");
                                                                        showlog(selectedList);
                                                                        this.setState({
                                                                            ...this.state,
                                                                            enabledSaveButton: true,
                                                                            assurancers: selectedList
                                                                        });
                                                                    }}
                                                                    trash={ true }
                                                                    visibleTrash={ false }
                                                                    waitComponent={
                                                                        <WaitingOrNodataMessage
                                                                            waiting={ true }
                                                                            spinnerColor={"success"}
                                                                            className={"profile-grid-waiting-component"}
                                                                        />
                                                                    }
                                                                    deleteWarningMessage={ null }
                                                                    onDelete={ selectedItem => {
                                                                        showlog("elimino", selectedItem);
                                                                        return (
                                                                            deleteInsurer(this.props.collect.raw.companyId, selectedItem.id)
                                                                                .then(list => {
                                                                                    return (
                                                                                        new Promise((success, fail) => {
                                                                                            loadInsurer(this.props.collect.raw.companyId, this.state.id)
                                                                                                .then(list => {
                                                                                                    this.setState({
                                                                                                        ...this.state,
                                                                                                        addConsulerModal: null,
                                                                                                        data: list,
                                                                                                        copyData: list
                                                                                                    },
                                                                                                        () => {
                                                                                                                success();
                                                                                                        }
                                                                                                    )
                                                                                                })
                                                                                                .catch(e => {
                                                                                                    this.setState({
                                                                                                        ...this.state,
                                                                                                        addConsulerModal: null,
                                                                                                        assurancersErrorMessage: " " + emoj(EMOJ.sad_but_relieved_face) + "  Qualcosa è andato storto!"
                                                                                                    },
                                                                                                        () => {
                                                                                                            fail( e );
                                                                                                        }
                                                                                                    )
                                                                                                    errorlog("Load Insurer", e);
                                                                                                })
                                                                                        })
                                                                                    )
                                                                                })
                                                                                .catch(e => {
                                                                                    return (
                                                                                        new Promise((success, fail) => {
                                                                                            this.setState({
                                                                                                ...this.state,
                                                                                                addConsulerModal: null,
                                                                                                assurancersErrorMessage: " " + emoj(EMOJ.sad_but_relieved_face) + "  Qualcosa è andato storto!"
                                                                                            },
                                                                                                () => {
                                                                                                    fail( e );
                                                                                                }
                                                                                            );
                                                                                            errorlog("delete Insurer", e);
                                                                                        })
                                                                                    );
                                                                                })
                                                                        )
                                                                    }}
                                                                />
                                                                */}
                                                            </React.Fragment>
                                                        )
                                                        : (
                                                            <WaitingOrNodataMessage
                                                                waiting={!(!!this.state.data && this.state.data.length === 0) && !this.state.assurancersErrorMessage}
                                                                nodataMessage={
                                                                    <span
                                                                        className={"padded-content collect-setting-modal-data-message"}
                                                                    >
                                                                        {
                                                                            !!this.state.assurancersErrorMessage
                                                                                ? this.state.assurancersErrorMessage
                                                                                : "Nessun Utente"
                                                                        }
                                                                    </span>
                                                                }
                                                            />
                                                        )
                                                }

                                            </Row>

                                            </CardBody>

                                        )
                                }



                            <CardFooter>

                                <Row>
                                    <Col sm={8}/>
                                    <Col sm={4}>

                                        <ActionButton
                                            color={"link"}
                                            buttonText={"annulla"}
                                            promise={ () => {
                                                return Promise.resolve();
                                            }}
                                            onResult={ toggleEvent => {
                                                if( !!this.state.addConsulerModal ) {
                                                    this.setState({
                                                        ...this.state,
                                                        addConsulerModal: null
                                                    });
                                                }
                                                else {
                                                    this.toggle();
                                                }
                                            }}
                                            className={"float-right cancel-button-text"}
                                        />

                                        <ActionButton
                                            color={ !!this.state.enabledSaveButton ? "primary" : "none" }
                                            buttonText={"Aggiorna"}
                                            promise={ () => {
                                                if( !!this.state.addConsulerModal && !!this.state.enabledSaveButton ) {
                                                    return (
                                                        saveNewInsurer( this.props.collect.raw.companyId, this.state )
                                                            .then( result => {

                                                                return (
                                                                    loadInsurer( this.props.collect.raw.companyId, this.props.collect.id )

                                                                        // trash icon
                                                                        .then(list => {
                                                                            return (
                                                                                list
                                                                                    .map( as => this.addTrashAssurancer( as ) )
                                                                            );
                                                                        })

                                                                        .then( list => {

                                                                            return (
                                                                                new Promise((success, fail) => {
                                                                                    this.setState({
                                                                                            ...this.state,
                                                                                            addConsulerModal: null,
                                                                                            data: list,
                                                                                            copyData: list
                                                                                        },
                                                                                        () => {
                                                                                            success();
                                                                                        }
                                                                                    )
                                                                                })
                                                                            );


                                                                        })
                                                                        .catch(e => {
                                                                            errorlog("Load Insurer after success save new Insurer", e);
                                                                            return Promise.reject( e );
                                                                        })
                                                                );

                                                            })
                                                            .catch( e => {
                                                                errorlog("save new Insurer", e);

                                                                alert( extractErrorMessage(e) );

                                                                return (
                                                                    new Promise((success, fail) => {
                                                                        this.setState({
                                                                                ...this.state,
                                                                                addConsulerModal: null
                                                                            },
                                                                            () => {
                                                                                success();
                                                                            }
                                                                        )
                                                                    })
                                                                );

                                                            })
                                                    );
                                                }
                                                else {
                                                    if( !this.state.enabledSaveButton ) return Promise.resolve( false );
                                                    return (
                                                        setInsurerList( this.state )
                                                            .then( result => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    enabledSaveButton: false,
                                                                    notific: "Operatori aggiornati"
                                                                },
                                                                    () => {
                                                                        setTimeout(
                                                                            function() {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    notific: null
                                                                                })
                                                                            }.bind( this ),
                                                                            5000
                                                                        )
                                                                    }
                                                                )
                                                            })
                                                            .catch( e => {
                                                                errorlog("errore 55", e);
                                                            })
                                                    );
                                                }
                                            }}
                                            onResult={ toggleEvent => {
                                                // debugger;
                                                // if( typeof this.props.refresh === "function" ) this.props.refresh()  // forse
                                                if( !!toggleEvent ) this.toggle( this.state );
                                            }}
                                            onError={ error => {
                                                errorlog("save collect data", error);
                                            }}
                                            className={ "float-right "+ ((!!this.state.enabledSaveButton) ? "" : "disabled-save-button") }
                                        />


                                    </Col>
                                </Row>
                            </CardFooter>

                        </Card>


                        {
                            !!this.props.urlparams.help
                                ? (
                                    <Card className={"collect-modal-accordion"}>
                                        <CardHeader>
                                            <NavLink
                                                id={"other-settings"}
                                                className={""}
                                                onClick={clickEvent => {
                                                    if (this.state.activeSection !== "other-settings") {
                                                        this.setState({
                                                            ...this.state,
                                                            activeSection: "other-settings"
                                                        })
                                                    } else {
                                                        this.setState({
                                                            ...this.state,
                                                            activeSection: null
                                                        })
                                                    }
                                                }}
                                            >

                                                <span className={"collect-modal-accordion-title"}>
                                                    Altre Impostazioni
                                                </span>

                                            </NavLink>
                                        </CardHeader>


                                        <Collapse isOpen={this.state.activeSection === "other-settings"}>
                                            <CardBody>

                                                <AvForm className={"manage-collect-modal-form"}>
                                                    <AvGroup className={"audit-low-noncompliant-group"}>
                                                        <div className={"manage-collect-modal-form-group"}>
                                                            <Label for={"collect-name"}>campo</Label>
                                                            <AvInput
                                                                className={""}
                                                                type="text"
                                                                name={"collect-name"}
                                                                id={"collect-name"}

                                                                defaultValue={"valore"}

                                                                onChange={changeTextEvent => {

                                                                    if (!this.state.enabledSaveButton) {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            enabledSaveButton: true
                                                                        });
                                                                    }

                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    </AvGroup>


                                                    <AvGroup className={"audit-low-noncompliant-group"}>
                                                        <div className={"manage-collect-modal-form-group"}>

                                                            <CustomInput
                                                                type="switch"
                                                                id={"switch-x"}
                                                                name={"switch-x"}
                                                                label={"Attivazione"}
                                                                className={"pmAuthorizationToggler"}
                                                                onClick={clickEvent => {

                                                                    if (!!document.getElementById("switch-x")) {
                                                                        document.getElementById("switch-x").checked = !this.state.switchX;
                                                                    }

                                                                    // if( !this.state.enabledSaveButton ) {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        enabledSaveButton: true,
                                                                        switchX: !this.state.switchX
                                                                    });
                                                                    // }


                                                                }}
                                                                checked={!!this.state.switchX}

                                                            />


                                                        </div>
                                                    </AvGroup>

                                                </AvForm>


                                            </CardBody>

                                        </Collapse>

                                    </Card>
                                )
                                : null
                        }


                        {/*<GotoTopComponent/>*/}

                    </TabPane>



                    <TabPane
                        tabId={"measureUnit"}
                        key={"measureUnit"}
                        className={""}
                    >
                        <Card>
                            <CardBody>
                                {
                                    (!!this.state.measureUnitData && this.state.measureUnitData.length > 0)
                                        ? (
                                            <AvForm>

                                                {
                                                    this.state.measureUnitData
                                                        .map( category => (
                                                            <Card className="mb-0">
                                                                <CardHeader className={"measure-unit-categories-list"}>
                                                                    <NavLink
                                                                        id={ "cat-"+ category.id }
                                                                        href="#"
                                                                        onClick={clickEvent => {
                                                                            if( this.state.measureUnitActiveSection === category.id ) {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    measureUnitActiveSection: 0
                                                                                })
                                                                            }
                                                                            else {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    measureUnitActiveSection: category.id
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={"custom-accordion-title question-name-text tree_comander pt-2 pb-2 small-accordion-title "}>
                                                                            <strong>
                                                                                {
                                                                                    capitalize( category.name )
                                                                                }
                                                                            </strong>
                                                                        </div>

                                                                    </NavLink>

                                                                    <ActionButton
                                                                        promise={ () => {
                                                                            // setWait( true ); // TODO

                                                                            let muSelected =
                                                                                this.state.measureUnitData
                                                                                    .filter( cat => cat.id === category.id )
                                                                                    .reduce( getLastElementOr, null )
                                                                                    .measureUnits
                                                                                        .filter( mu => !!mu.isSelected )
                                                                                        .reduce( getLastElementOr, null )
                                                                                            .id
                                                                            ;

                                                                            return setUnit(this.props.collect.id, category.id, muSelected);
                                                                        }}
                                                                        onResult={result => {
                                                                            // TODO
                                                                            // props.onClose( true );
                                                                        }}
                                                                        onError={e => {
                                                                            // TODO
                                                                            errorlog("set measure unit", e);
                                                                            // props.onClose();
                                                                        }}
                                                                        className={"header-action-button"}
                                                                    />


                                                                </CardHeader>
                                                                <Collapse
                                                                    isOpen={ (this.state.measureUnitActiveSection === category.id) }
                                                                    toggler={ "#cat-"+ category.id }
                                                                >
                                                                    <CardBody>
                                                                        {
                                                                            category.measureUnits
                                                                                .map( unit => (
                                                                                    <div className={""}>
                                                                                        <CustomInput
                                                                                            type="radio"
                                                                                            name={"measure-unit-" + unit.id}
                                                                                            id={"measure-unit-" + unit.id}
                                                                                            label={ unit.name }
                                                                                            onClick={clickEvent => {

                                                                                                let dataTmp = cloneObject( this.state.measureUnitData );
                                                                                                dataTmp
                                                                                                    .filter( cat => cat.id === category.id )
                                                                                                    .reduce( getLastElementOr, null )
                                                                                                    .measureUnits
                                                                                                    .map( un => {
                                                                                                        un.isSelected = false;
                                                                                                        if( un.id === unit.id ) un.isSelected = true;
                                                                                                    })
                                                                                                ;

                                                                                                // setData( dataTmp ); // TODO
                                                                                                this.setState({
                                                                                                    ...this.state,
                                                                                                    measureUnitData: dataTmp
                                                                                                })
                                                                                            }}
                                                                                            defaultChecked={ unit.isSelected }
                                                                                            checked={ unit.isSelected }
                                                                                        />
                                                                                    </div>
                                                                                ))
                                                                        }
                                                                    </CardBody>
                                                                </Collapse>
                                                            </Card>
                                                        ))
                                                }


                                            </AvForm>

                                        )
                                        : (
                                            <WaitingOrNodataMessage
                                                waiting={ !(!!this.state.measureUnitData && this.state.measureUnitData.length === 0) }
                                                waitMessage={"Caricamento Unità di Misura"}
                                                nodataMessage={"Nessuna Unità di Misura"}
                                                spinnerColor={"success"}
                                            />
                                        )
                                }
                            </CardBody>
                            <CardFooter>

                            </CardFooter>
                        </Card>

                        {/*<GotoTopComponent/>*/}

                    </TabPane>


                    <TabPane
                        tabId={"details"}
                        key={"details"}
                        className={""}
                    >

                        <code>Dettagli</code>
                        <pre>
                            {
                                beautify({
                                    id: this.props.collect.id,
                                    name: this.props.collect.name,
                                    inizio: this.props.collect.Inizio,
                                    fine: this.props.collect.dateEnd
                                })
                            }
                        </pre>

                        <code>Tematiche Materiali</code>
                        <pre>
                            {
                                beautify(this.state)
                            }
                        </pre>

                        {/*<GotoTopComponent/>*/}

                    </TabPane>


                </TabContent>

            </React.Fragment>
        );

        return mainArea;
    }

    render() {

        renderlog("ManageCollectModal", this.state, this.props, COLOR_POLICY.select);


        let context = (
            <WaitingOrNodataMessage
                waiting={ !!this.state.inWait }
                nodataMessage={"Qualcosa non sta andando..."}
                spinnerColor={"success"}
                className={""}
            />
        );


        if( !this.state.inWait ) {
            context = this.buildComponent();
        }


        let content = (
            <Modal isOpen={ true } toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className={""}>
                    Impostazioni Raccolta
                </ModalHeader>
                <ModalBody className={""}>
                    { context }
                </ModalBody>
            </Modal>
        );


        return content;
    }



    addTrashAssurancer = as => {
        as.trash = (
            <i
                id={"profile-grid-item-trash-"+ as.id}
                className={"mdi mdi-trash-can-outline assurancer-trash-icon"}
                onClick={ clickEvent => {
                    clickEvent.stopPropagation();

                    Promise.resolve()

                        .then( () => {
                            let isYes = window.confirm( "Sicuro di voler eliminare permanentemente l'operatore?" );
                            return !!isYes;
                        })

                        .then( isYes => {
                            if( !!isYes ) {
                                deleteInsurer(this.props.collect.raw.companyId, as.id)
                                    .then(list => {
                                        return (
                                            new Promise((success, fail) => {
                                                this.setState({
                                                        ...this.state,
                                                        data: this.state.data.filter( a => a.id !== as.id ),
                                                        copyData: this.state.data.filter( a => a.id !== as.id ),
                                                        inWait: false
                                                    },
                                                    () => {
                                                        success();
                                                    }
                                                )
                                            })
                                        )
                                    })
                                    .catch(e => {
                                        return (
                                            new Promise((success, fail) => {
                                                this.setState({
                                                        ...this.state,
                                                        addConsulerModal: null,
                                                        assurancersErrorMessage: " " + emoj(EMOJ.sad_but_relieved_face) + "  Qualcosa è andato storto!"
                                                    },
                                                    () => {
                                                        fail( e );
                                                    }
                                                );
                                                errorlog("delete Insurer", e);
                                            })
                                        );
                                    })
                            }
                        })
                }}
            />
        );
        return as;
    };

    componentDidMount() {


        Promise.all([
            loadMeasureUnitList( this.props.collect.id ),
            loadInsurer( this.props.collect.raw.companyId, this.props.collect.id )
        ])

            .then( resultList => {
                return ({
                    muList: resultList[0],
                    list: resultList[1]
                })
            })

            .then(({muList, list}) => {
                this.setState({
                    ...this.state,
                    id: this.props.collect.id,
                    addConsulerModal: null,
                    activeSection: "utenza",
                    data: list.map( as => this.addTrashAssurancer( as ) ),
                    copyData: list.map( as => this.addTrashAssurancer( as ) ),
                    measureUnitData: muList,
                    inWait: false
                })
            })

            .catch(e => {
                this.setState({
                    ...this.state,
                    addConsulerModal: null,
                    assurancersErrorMessage: " " + emoj(EMOJ.sad_but_relieved_face) + "  Qualcosa è andato storto!"
                })
                errorlog("Load Insurer", e);
            })


    }



}

export default withRouter( ManageCollectModal );

