import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteUserBySuperAdmin} from "../../../services/users-service";
import {errorlog} from "../../../utils";
import React from "react";
import ActionButton from "../../save-button";
import {deleteCompanyOrganizzations, deleteOrganizzations} from "../../../business-logic/organizzations";


export const DeleteOrganizzationCompanyPrompt = props => (
    <Modal isOpen={ true } toggle={toggleEvent => props.onClose()}>
        <ModalHeader toggle={toggleEvent => props.onClose()} className={ "modal-colored-header bg-danger" }>
            ATTENZIONE
        </ModalHeader>
        <ModalBody>
            Sicuro di eliminare l'azienda { props.org.ragioneSociale }
        </ModalBody>
        <ModalFooter>
            <ActionButton
                color={"link"}
                buttonText={"annulla"}
                promise={ () => {
                    return Promise.resolve();
                }}
                onResult={  toggleEvent => props.onClose() }
            />

            <ActionButton
                buttonText={"Elimina"}
                color={"danger"}
                promise={ () => {
                    return deleteCompanyOrganizzations( props.org.id );
                }}
                onResult={ result => {
                    props.onClose( true );
                }}
                onError={ e => {
                    if( typeof e === "string" && !!e && e.trim().length > 0 ) {
                        props.onDenied( e );
                    }
                    else {
                        errorlog("errore all'eliminazione di un'azienda", e);
                        props.onClose()
                    }
                }}
            />
        </ModalFooter>
    </Modal>
);
