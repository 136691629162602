import React, {Component} from "react";
import {Button, Card, CardBody, Col, Label, Row} from "reactstrap";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {
	LANGUAGES,
	LOWERCASE_AT_LEAST_ONE,
	NUMBER_AT_LEAST_ONE,
	SPACE_CHARACTER_AT_LEAST_ONE,
	SPECIAL_CHARACTER_AT_LEAST_ONE,
	UPPERCASE_AT_LEAST_ONE
} from "../../env";
import Spinner from "../../components/Spinner";
import StrengthPasswordIndicatorComponent from "../../components/strength-password-indicator-component";
import CardHeader from "reactstrap/es/CardHeader";
import {confirmUserByPasswordReset} from "../../business-logic/auth";
import ErrorMessageModalComponent from "../../components/error-message-modal-component";
import OKSVGComponent from "../../components/ok-component";
import {errorlog, extractErrorMessage, getLastElementOr, renderlog, showlog} from "../../utils";
import {translate} from "../../components/i18n/translate-function";
import "../../style/index.css";


export default class SetPasswordPage extends Component {

	state = {
		notValidPassword: true
	}

	constructor(props) {
		super(props);
	}


	render() {



		renderlog("SetPasswordPage", this.state);

		return (
			<React.Fragment>


				<Card className="fullscreen-height">
					<CardHeader>
						<h4>{translate("Set Password")}</h4>
					</CardHeader>
					<CardBody>
						<Row>
							<Col sm={4} className={"center-col"}>
								<Card>
									<CardBody>

										<AvForm>

											{/*     PASSWORD  -------------------------------------------------------------------------------- */}
											<AvGroup>
												<Label for="password">{translate("New Password")}</Label>
												<AvInput
													type={ (!!this.state.visiblePassword) ? "text" : "password" }
													name="password"
													id="password"
													value={ this.state.password }
													onChange={ changeTextEvent => {

														this.setState(
															{
																...this.state,
																password: changeTextEvent.target.value,
																errors: {
																	...this.state.errors,
																	confirmPassword: (this.state.confirmPassword !== changeTextEvent.target.value) ? translate("Passwords do not match") : ""
																}
															},
															newState => {
																showlog("email cambiata");
																showlog( this.state );
															}
														)
													}}
													required={ false }
													className={ (!!this.state.notValidPassword) ? "password-input-not-valid" : "" }
												/>
												{
													// className="uil uil-eye password-eye"
													<i
														className={"mdi mdi-eye" +( (!!this.state.visiblePassword) ? "" : "-off" )+ " password-eye"}
														onClick={ clickEvent => {
															this.setState(
																Object.assign(
																	{},
																	this.state,
																	{ visiblePassword: !!!this.state.visiblePassword }
																)
															)
														}}
													/>
												}
												{
													( !!this.state.errors && !!this.state.errors.password )
														? <label className="error-message-label">{ this.state.errors.password }</label>
														: null
												}
												<StrengthPasswordIndicatorComponent
													value={ this.state.password }
													blackList={[
														this.state.email
													]}
													result={ result => {

														showlog("uscita da StrengthPasswordIndicatorComponent", result);

														this.setState({
															...this.state,
															notValidPassword: !result.success
														})

													}}
												/>
											</AvGroup>

											{/*     CONFERMA_PASSWORD  -------------------------------------------------------------------------------- */}
											<AvGroup>
												<Label for="confirmPassword">{translate("Confirm Password")}</Label>
												<AvInput
													type="password"
													name="confirmPassword"
													id="confirmPassword"
													value={ this.state.confirmPassword }
													onChange={ changeTextEvent => {


														this.setState(
															{
																...this.state,
																confirmPassword: changeTextEvent.target.value,
																errors: {
																	...this.state.errors,
																	confirmPassword: (this.state.password !== changeTextEvent.target.value) ? translate("Passwords do not match") : ""
																}
															},
															newState => {
																showlog("email cambiata");
																showlog( this.state );
															}
														)
													}}
													required
												/>
												{
													( !!this.state.errors && !!this.state.errors.confirmPassword )
														? <label className="error-message-label">{ this.state.errors.confirmPassword }</label>
														: null
												}
											</AvGroup>

										</AvForm>




										<Row className="justify-content-center">
											<Col lg={8}/>
											<Col lg={4} >


												{
													(this.state.password === this.state.confirmPassword && !this.state.notValidPassword)
														? (
																<Button
																	color={ "primary" }
																	onClick={ clickEvent => {

																		this.setState(
																			{
																				...this.state,
																				waiting: true
																			},
																			() => {

																				confirmUserByPasswordReset( this.state.email, this.state.password, this.state.confirmPassword, this.state.token, this.state.mode )
																					.then(result => {


																						window.location.href =
																							window
																								.location
																								.href
																								.split("/")
																								.filter((_, i) => i < 4)
																								.filter( path => path !== "passwordsetting" )
																								.join("/")
																						;
																					})
																					.catch(e => {
																						errorlog("errore al reset della password", e);


																						let errorMessageString = extractErrorMessage( e );

																						this.setState({
																								...this.state,
																								waiting: false,
																								errorMessage: errorMessageString,
																								password: null,
																								confirmPassword: null
																							},
																							() => {
																								document.getElementById("confirmPassword").value = null;
																								document.getElementById("password").value = null;
																								// window.location.href = window.location.href;    // da testare il produzione
																							}
																						)
																					})
																			}
																		)



																	}}
																>
																	{
																		(!!this.state.waiting)
																			? (
																				<React.Fragment>
																					<Spinner color={"#fffa"} className="spinner-border-sm mr-1" />
																					<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
																				</React.Fragment>
																			)
																			: translate("Save")
																	}
																</Button>
														)
														: null

												}


											</Col>
										</Row>


										{/*
											!!process.env.REACT_APP_DEBUG
												? (
													<React.Fragment>
														<Row>
															<code>check</code>
														</Row>
														<Row>
															<pre>
																password: { this.state.password }
															</pre>
														</Row>
														<Row>
															<pre>
																confirm: { this.state.confirmPassword }
															</pre>
														</Row>
														<Row>
															<pre>
																valid: {
																!this.state.notValidPassword
																	? "YES"
																	: <span style={{ color: "#f00" }}>NO</span>
														 	}
															</pre>
														</Row>
													</React.Fragment>
												)
												: null
										*/}



										<Row>
											<div className={"edit-password-page-message"}>{ translate("set_password_label") }</div>
										</Row>


										{/*	// (this.state.password === this.state.confirmPassword && !this.state.notValidPassword)
												(!this.state.notValidPassword)
													? null
													: (
														*/}
														<Row>
															<Col lg={11}>
																<p>
																	<em>{translate("The password must contain")}
																		<ul>
																			<li
																				className={
																					(!!this.state.password && UPPERCASE_AT_LEAST_ONE.test(this.state.password) )
																						? ""
																						: "look-at-me-color"
																				}
																			>
																				{
																					(!!this.state.password && UPPERCASE_AT_LEAST_ONE.test(this.state.password) )
																						? (<OKSVGComponent customClass={"set-password-validation-ok-component"}/>)
																						: null
																				} {translate("at least one capital letter")} [A-Z]
																			</li>
																			<li
																				className={
																					(!!this.state.password && LOWERCASE_AT_LEAST_ONE.test(this.state.password) )
																						? ""
																						: "look-at-me-color"
																				}
																			>
																				{
																					(!!this.state.password && LOWERCASE_AT_LEAST_ONE.test(this.state.password) )
																						? (<OKSVGComponent customClass={"set-password-validation-ok-component"}/>)
																						: null
																				} {translate("at least one lowercase letter")} [a-z]
																			</li>
																			<li
																				className={
																					(!!this.state.password && NUMBER_AT_LEAST_ONE.test(this.state.password) )
																					? ""
																					: "look-at-me-color"
																				}
																			>
																				{
																					(!!this.state.password && NUMBER_AT_LEAST_ONE.test(this.state.password) )
																						? (<OKSVGComponent customClass={"set-password-validation-ok-component"}/>)
																						: null
																				} {translate("at least one number")} [0-9]
																			</li>
																			<li
																				className={
																					(!!this.state.password && SPECIAL_CHARACTER_AT_LEAST_ONE.test(this.state.password) )
																					? ""
																					: "look-at-me-color"
																				}
																			>
																				{
																					(!!this.state.password && SPECIAL_CHARACTER_AT_LEAST_ONE.test(this.state.password) )
																						? (<OKSVGComponent customClass={"set-password-validation-ok-component"}/>)
																						: null
																				} {translate("at least one special character")} [{"!@#*$%^&(){}[]:;<>,.?/~_+-=|\\"}]
																			</li>
																			<li
																				className={
																					(!!this.state.password && (this.state.password.length > 7) )
																					? ""
																					: "look-at-me-color"
																				}
																			>
																				{
																					(!!this.state.password && (this.state.password.length > 7) )
																						? (<OKSVGComponent customClass={"set-password-validation-ok-component"}/>)
																						: null
																				} {translate("at least 8 characters")}
																			</li>
																			<li
																				className={
																					(!!this.state.password && !SPACE_CHARACTER_AT_LEAST_ONE.test(this.state.password) )
																					? ""
																					: "look-at-me-color"
																				}
																			>
																				{
																					(!!this.state.password && !SPACE_CHARACTER_AT_LEAST_ONE.test(this.state.password) )
																						? (<OKSVGComponent customClass={"set-password-validation-ok-component"}/>)
																						: null
																				} {translate("no space")}
																			</li>
																		</ul>
																	</em>
																</p>
															</Col>
														</Row>


									</CardBody>
								</Card>
							</Col>
						</Row>

					</CardBody>
				</Card>




				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={ this.state.errorMessage }
								noheader={ true }
								onClose={ () => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}



			</React.Fragment>
		);
	}



	componentDidMount() {

		let token =
			this.props?.token ||
			this.props?.location?.state?.token ||
			this.props?.match?.params?.token
		;

		let email =
			this.props?.email ||
			this.props?.location?.state?.email ||
			this.props?.match?.params?.email
		;

		let mode =
			this.props?.mode ||
			this.props?.location?.state?.mode ||
			this.props?.match?.params?.mode
		;



		let outerLangID =
			window
				.location
				.href
				.split("/")
				.filter((_, i) => i > 2)
				.filter(p => !!p)
				.reverse()
				[0]
			|| this.props.lang
			|| 1
		;
		let lang =
			LANGUAGES
				.filter( l => l.value == outerLangID )
				.reduce( getLastElementOr, LANGUAGES[0] )
		;
		localStorage.setItem("language", lang.code);





		this.setState({
			...this.state,
			token: token,
			email: email,
			mode: mode,
			language: lang
		});

	}
}
