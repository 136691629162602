// SuperAdminAssignToConsuler
import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageTitle from "../../../components/PageTitle";
import {PATH_PREFIX, role} from "../../../env";
import {errorlog, showlog} from "../../../utils/dev-utils";
import TableDataModal from "./companies";
import {assignToConsuler, loadCompaniesData, loadConsulterCompaniesData} from "../../../business-logic/companies";
import Spinner from "../../../components/Spinner";
import {getLastElementOr, isNotEmptyArray, onlyNotNull} from "../../../utils/array-utils";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import {translate} from '../../../components/i18n/translate-function';


/*
const fieldMap = {
	companyId: "Id",
	companyName: "Ragione Sociale"
};
*/


const fieldMap = {
	codCliFor: "Id",
	partitaIva: "Partita Iva",
	ragioneSociale: "Ragione Sociale",
	localita: "Città",
	provincia: "Provincia",
	indirizzo: "Indirizzo",
	tipologiaCommerciale: "Tipologia Commerciale",
	classeContabile: "Classe Contabile",
	categoria: "Categoria"
};

const paginationOptions = {
	paginationSize: 5,
	pageStartIndex: 1,
	firstPageText: translate('First'),
	prePageText: translate('backward'),
	nextPageText: translate('forward'),
	lastPageText: translate('Last'),
	nextPageTitle: translate('first page'),
	prePageTitle: translate('previous page'),
	firstPageTitle: translate('next page'),
	lastPageTitle: translate('last page'),
	showTotal: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total ml-2">
            Righe da {from} a {to} di {size} Utenti
        </span>
	),
	sizePerPageList: [
		{
			text: 'pochi',
			value: 25,
		},
		{
			text: 'normale',
			value: 50,
		},
		{
			text: 'molti',
			value: 250,
		}
	], // A numeric array is also available. the purpose of above example is custom the text
};

const defaultSorted = [
	{
		dataField: 'lastName',
		order: 'asc',
	},
];

const sortableFields = [
	"Partita Iva",
	"Ragione Sociale",
	"Città",
	"Provincia",
	"Tipologia Commerciale",
	"Classe Contabile",
	"Categoria"
];

const formatColumns = columnList => {
	return (
		columnList
			.map( field => {
				return ({
					dataField: field,
					text: fieldMap[ field ] || field,
					sort: sortableFields.includes( field )
				});
			})
	);
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default class SuperAdminAssignToConsuler extends Component {

	state = {
		data: null,
		selectedCompanies: [],
		waiting: true
	}

	constructor(props) {
		super( props );

		this.assigned = [];

		this.loadData = this.loadData.bind( this );
	}


	render() {

		showlog("assign to consuler RENDER");
		showlog( this.state );


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{
							label:
							Object.keys( role )
								.map( roleObjectName => role[ roleObjectName ] )
								.filter( roleObject => roleObject.name === localStorage.getItem("role") )
								.reduce( getLastElementOr, "Nessun Ruolo" )
								.label
							, path: PATH_PREFIX +'/welcome' },
						{ label: translate("Companies"), active: true }
					]}
					title={'Aziende'}
				/>




				{
					(!!isNotEmptyArray( this.state.data ))
						? (
							<Card>
								<CardBody>

									<Row>
										<Col>
											<div className="page-title-box">
												<div className="page-title-right">
												</div>
												<h4 className="page-title">Lista aziende</h4>
											</div>
										</Col>
									</Row>




									<ToolkitProvider
										bootstrap4
										keyField="Id"
										search
										exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
										{props => (
											<React.Fragment>
												<Row>
													<Col>
														<SearchBar {...props.searchProps} />
													</Col>
													{/*
													<Col className="text-right">
														<ExportCSVButton {...props.csvProps} className="btn btn-primary">
															Export CSV
														</ExportCSVButton>
													</Col>
													*/}
												</Row>


												<BootstrapTable
													bootstrap4
													striped
													{...props.baseProps}
													size="sm"
													responsive
													keyField="Id"
													data={ this.state.data }
													columns={ formatColumns( Object.keys( this.state.data[0] ) ) }
													defaultSorted={ defaultSorted }
													pagination={ paginationFactory(paginationOptions) }
													wrapperClasses="table-responsive"
													selectRow={{
														mode: 'checkbox',
														clickToSelect: false,

														onSelect: row => {
															showlog("riga selezionata");
															showlog( row );


															let currentRow =
																this.state.data
																	.filter( company => company.Id === row.rowKey )
																	.reduce( (final, cursor) => Object.assign( {}, final, cursor ), {})
															;

															showlog( currentRow );

															/*
															this.setState({
																...this.state,
																selectedCompanies: [...this.state.selectedCompanies, row.Id]
															})
															*/

														},

														selectionRenderer: row => {


															if(
																this.state.data
																	.filter( (company, i) => i === row.rowIndex )
																	.map( company => {
																		return (
																			this.state.selectedCompanies
																				.filter( sc => sc === company["Ragione Sociale"] )
																				.reduce( (final, cursor) => cursor, null)
																		);
																	})
																	.filter( onlyNotNull )
																	.length > 0
															) row.checked = true;
															else row.checked = false;



															return (
																<div className="custom-control custom-checkbox">
																	<input
																		type="checkbox"
																		className={"custom-control-input"}
																		checked={row.checked}
																		disabled={row.disabled}
																		id={"row-"+ row.rowKey}
																	/>
																	<label
																		className="custom-control-label"
																		onClick={e => {

																			showlog("click al checkbox", row);

																			row.checked = !row.checked;


																			if( !row.checked ) {

																				showlog("aziende rimanenti");
																				showlog(
																					this.state.selectedCompanies
																						.filter( (sc, i) => sc === (
																							this.state.data
																								.filter( (company, i) => company.Id !== row.rowKey )
																								.map( company => company["Ragione Sociale"] )
																								.reduce( (final, cursor) => cursor, null)
																						))
																				);

																				this.setState({
																					...this.state,
																					selectedCompanies:
																						this.state.selectedCompanies
																							.filter( (sc, i) => sc === (
																								this.state.data
																									.filter( (company, i) => company.Id !== row.rowKey )
																									.map( company => company["Ragione Sociale"] )
																									.reduce( (final, cursor) => cursor, null)
																							))
																				});
																			}
																			else {


																				showlog("assegnazione", row);
																				showlog(
																					this.state.data
																						.filter( (company, i) => company.Id === row.rowKey )
																						.map( company => company["Ragione Sociale"] )
																						.reduce( (final, cursor) => cursor, null)
																				);



																				this.setState({
																					...this.state,
																					selectedCompanies: [
																						...this.state.selectedCompanies,
																						this.state.data
																							.filter( (company, i) => company.Id === row.rowKey )
																							.map( company => company["Ragione Sociale"] )
																							.reduce( (final, cursor) => cursor, null)
																					]
																				})
																			}

																			// e.preventDefault();
																		}}/>
																</div>
															);

														},

														selectionHeaderRenderer: ({indeterminate, ...rest}) => {
															return null;
														}

													}}
												/>


											</React.Fragment>
										)}
									</ToolkitProvider>


									<Button
										color="primary"
										onClick={ clickEvent => {
											console.log("click su salva");
											this.setState({
												...this.state,
												waiting: true
											},
											() => {
												showlog("salvataggio delle aziende");
												showlog(this.state.selectedCompanies);
												showlog(
													this.state.data
														.filter( c => (
															c["Ragione Sociale"] === (
																this.state.selectedCompanies
																	.filter( sc => sc === c["Ragione Sociale"])
																	.reduce(getLastElementOr, null)
															)
														))
														.map( c => c.Id )
												);

												assignToConsuler(
													this.state.data
														.filter( c => (
															c["Ragione Sociale"] === (
																this.state.selectedCompanies
																	.filter( sc => sc === c["Ragione Sociale"])
																	.reduce(getLastElementOr, null)
															)
														))
														.map( c => c.Id ),
													this.props.match.params.id
												)
													.then( result => {
														showlog("result");
														showlog(result);
														this.setState({
															...this.state,
															waiting: false
														})
													})
													.catch(e => {
														errorlog("errroe in assegnazione al consulente", e );
														this.setState({
															...this.state,
															waiting: false
														})
													})

											})
										}}
									>
										{
											(!!this.state.waiting)
												? (
													<React.Fragment>
														<Spinner color={"#fff"} className="spinner-border-sm mr-1" />
														<span>&nbsp;&nbsp;in attesa...&nbsp;&nbsp;</span>
													</React.Fragment>
												)
												: "Salva"
										}
									</Button>


								</CardBody>
							</Card>
						)
						: (

							<WaitingOrNodataMessage
								waiting={ !!this.state.waiting }
								nodataMessage={ emoj(EMOJ.sad_but_relieved_face) +"    Nessun azienda associata al consulente"}
							/>

						)
				}



				{
					(!!this.state.contactModal)
						? (
							<TableDataModal
								data={ this.state.contactList }
								fieldMap={ this.state.contactListFieldMap }
								sortableFields={[
									"Nome",
									"Cognome",
									"Email",
									"Ruolo",
									"Ufficio"
								]}
								close={() => {
									this.setState({contactModal: null})
								}}
							/>
						)
						: null
				}

				{
					(!!this.state.addressModal)
						? (
							<TableDataModal
								data={ this.state.addressList }
								fieldMap={ this.state.addressListFieldMap }
								sortableFields={[
									"Indirizzo",
									"Citta",
									"Provincia",
									"Categoria",
									"Classe Contabile",
									"Tipologia Commerciale"
								]}

								close={() => {
									this.setState({addressModal: null})
								}}
							/>
						)
						: null
				}

			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
	}


	loadData() {
		loadCompaniesData( fieldMap )


			// assignament
			.then( data => {

				return (
					loadConsulterCompaniesData( null, this.props.match.params.id )
						.then( assignedCompanies => {

								assignedCompanies
									.map( ac => {
										showlog("compagnie associate");
										showlog(ac);
										showlog(data);

										this.assigned = [...this.assigned, ac.companyName];

										data
											.filter( c => c.codCliFor === ac.companyId )
											.map( c => {
												c.assigned = true;
												return c;
											})


										return data;
									})


							return data;

						})
						.catch(e => {
							errorlog("errore", e);
							return [];
						})
				);



			})

			// field sort ---------------------------------------------------------------------------------------------
			.then( data => {

				return (
					data
						.map( row => {

							let sortedRow = {};
							Object.keys(fieldMap)
								.map( field => {
									sortedRow[field] = row[field];
								})
							;
							// Object.assign(sortedRow, row);
							return sortedRow;

						})
				);
			})

			// field map ----------------------------------------------------------------------------------------------
			.then( list => {


				return (
					list
						.map( company => {
							let dto = {};
							Object.keys( company )
								.forEach( rawField => {
									dto[ fieldMap[ rawField ] ] = company[ rawField ];
								})
							;
							return dto;
						})
				);

			})


			// finish -------------------------------------------------------------------------------------------------
			.then( data => {

				// debugger;

				this.setState({
					...this.state,
					data: data,
					selectedCompanies: this.assigned,
					waiting: false
				})
			})

	}






}
