
export const HIDDEN_LANGUAGES = [
    "FR-fr"
    // , "ES-es"
    // , "DE-de"
];

export const LANGUAGES = [
        { value:  1, label:  "Italiano", code: "IT-it" },
        { value:  2, label:  "English", code: "EN-gb"  },
        { value:  3, label:  "Deutch", code: "DE-de"  },
        { value:  4, label:  "Espanol", code: "ES-es"  },
        { value:  5, label:  "Francoise", code: "FR-fr"  }
]
.filter( l => !HIDDEN_LANGUAGES.includes( l.code ) )
;


