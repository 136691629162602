import React, {Fragment} from "react";
import {Component} from "react";
import {
    Modal,
    ModalHeader,
    ModalBody, Row, Col, Label, FormGroup, Input, Button
} from 'reactstrap';
import Spinner from '../../Spinner';
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getLastElementOr, isNotEmptyArray, onlyFirst} from "../../../utils/array-utils";
import {COLOR_POLICY, permissions, QUESTION_TEXT_COLOR_POLICY} from "../../../env";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {hasPermission} from "../../../business-logic/auth";
// import { Score_Get } from "../../../../src/services/request-manager";
import {cloneObject} from "../../../utils/object-utils";
import {getCriteriaScore} from "../../../business-logic/score";
import styles from "../../../../src/style/sdgTable.css";
import {RequirementFilter} from "../../../services/request-manager";
import {emoj} from "../../../utils/string-utils";
import {EMOJ} from "../../../emoj";
import {WaitingOrNodataMessage} from "../../waiting-or-nodata-message";
import ActionButton from "../../save-button";
import {loadCollect} from "../../../business-logic/gri";


export default class RequirementsFilter extends Component {



    constructor(props) {
        super(props);

        this.state = {
            data: false,
            timing_select: 0,
            graphType_select: 0,
            collectionCompare_select: 0,
            ...this.props.conf
        }


        this.toggle = this.toggle.bind(this);
        //this.loadData = this.loadData.bind(this);
    }

    getFilterDate = () => {
        //debugger
        let title = "";
        let timing_id = 0
        let type = 0;
        let id = 0;

        if (!!this.state.requirements) {
            type = 0;
            id = this.state.requirementSelected;

        }
        if (!!this.state.subrequirementSelected) {
            type = 1;
            id = this.state.subrequirementSelected;

        }
        if (!!this.state.subsubrequirementSelected) {
            type = 2;
            id = this.state.subsubrequirementSelected;
        }

        //debugger

        return (
            {
                title: " ",
                timing_id: 69,
                type: type,
                id: id,
                timing_select: this.state.timing_select,
                graphType_select: this.state.graphType_select,
                collectionCompare_select: this.state.collectionCompare_select,


                thematic_id: this.state.thematicSelected,
                chapter_id: this.state.chapterSelected,
                informative_id: this.state.informativeSelected,
                requirement_id: this.state.requirementSelected,
                subrequirement: this.state.subrequirementSelected,
                subsubrequirement: this.state.subsubrequirementSelected,

                // selectedCollect: this.state.selectedCollect,
                // selectedCollectName: this.state.selectedCollectName

                selectedCollect: (
                    !!this.state.selectedCollect
                        ? this.state.selectedCollect
                        : (
                            !!isNotEmptyArray( this.state.collects )
                                ? this.state.collects.filter( onlyFirst ).reduce( getLastElementOr, {id: 0} ).id
                                : 0
                        )
                ),
                selectedCollectName: (
                    !!this.state.selectedCollectName
                        ? this.state.selectedCollectName
                        : (
                            !!isNotEmptyArray( this.state.collects )
                                ? this.state.collects.filter( onlyFirst ).reduce( getLastElementOr, {name: " "} ).name
                                : " "
                        )
                ),

                // name: singleData.metadata.graphName

            }

        );
    }

    toggle = () => {
        if (!!this.props.onClose) this.props.onClose();
    }


    selectionAction = (level, id) => {


        switch (level) {
            case 0:

                this.setState(
                    {
                        thematicSelected: id,
                        chapters: null, chapterSelected: null,
                        informatives: null, informativeSelected: null,
                        requirements: null, requirementSelected: null,
                        subrequirements: null, subrequirementSelected: null,
                        subsubrequirements: null
                    })
                ;
                RequirementFilter(1, id)
                    .then(x => {
                        this.setState({chapters: x}/*, () => { this.props.arg( this.state ) }*/)
                    })
                ;
                break;

            case 1:
                this.setState(
                    {
                        chapterSelected: id,
                        informatives: null, informativeSelected: null,
                        requirements: null, requirementSelected: null,
                        subrequirements: null, subrequirementSelected: null,
                        subsubrequirements: null
                    })
                RequirementFilter(2, id).then(x => {
                    this.setState({informatives: x}/*, () => { this.props.arg( this.state ) }*/)
                });
                break;

            case 2:
                this.setState(
                    {
                        informativeSelected: id,
                        requirements: null, requirementSelected: null,
                        subrequirements: null, subrequirementSelected: null,
                        subsubrequirements: null
                    })
                RequirementFilter(3, id).then(x => {
                    this.setState({requirements: x}/*, () => { this.props.arg( this.state ) }*/)
                });
                break;

            case 3:
                this.setState(
                    {
                        requirementSelected: id,
                        subrequirements: null, subrequirementSelected: null,
                        subsubrequirements: null
                    })
                RequirementFilter(4, id).then(x => {
                    this.setState({subrequirements: x}/*, () => { this.props.arg( this.state ) }*/)
                });
                break;

            case 4:
                this.setState({subrequirementSelected: id})
                RequirementFilter(5, id).then(x => {
                    this.setState({subsubrequirements: x}/*, () => { this.props.arg( this.state ) }*/)
                });
                break;

              case 5:
                this.setState({subsubrequirementSelected:id})
                // RequirementFilter(6,id).then(x=>{ });
             break;

            default:

        }




    }


    render() {

        renderlog("DashboardChart Comanders", this.state, this.props, COLOR_POLICY.validated);

        let context = (

            <React.Fragment>


                <Row className="align-items-center">

                    {/*
                    <Col className="col-auto" sm="2">
                        <h4 className="header-title">Timing</h4>
                        <FormGroup style={{marginTop: "-21px"}}>
                            <Input type="select" name="timing_select" id="timing_select" className="custom-select mt-3"
                                   onChange={((x) => {
                                       this.setState({
                                           timing_select: x.target.value
                                       })
                                   })}>
                                <option value={0}>Annuale</option>
                                <option value={1}>Mensile</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    */}


                    <Col className="col-auto" sm="2">
                        <h4 className="header-title">Tipo Grafico</h4>
                        <FormGroup style={{marginTop: "-21px"}}>
                            <Input
                                type="select"
                                name="graphType_select"
                                id="graphType_select"
                                className="custom-select mt-3"
                                onChange={ x => {
                                    this.setState({ graphType_select: x.target.value })
                                }}
                            >
                                <option value={0} selected={ this.state.graphType_select === 0 }>Curve</option>
                                <option value={1} selected={ this.state.graphType_select === 1 }>Instogramma</option>
                                <option value={2} selected={ this.state.graphType_select === 2 }>Torta</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col className="col-auto" sm="2">
                        <h4 className="header-title">Confronta Raccolte</h4>
                        <FormGroup style={{marginTop: "-21px"}}>
                            <Input
                                type="select"
                                name="collectionCompare_select"
                                id="collectionCompare_select"
                                className="custom-select mt-3"
                                onChange={ x => {
                                    let choose = x.target.value;

                                    this.setState(
                                        {collectionCompare_select: choose},
                                        () => {

                                            if( !choose || choose === 0 || choose === "0" ) {
                                                // debugger

                                                if( !!hasPermission( permissions.GET_COLLECT ) ) {
                                                    loadCollect()

                                                        .then( collectList => {

                                                            return (
                                                                new Promise((setCollectListSuccess, setCollectListFail) => {
                                                                    this.setState(
                                                                        { collects: collectList },
                                                                        () => {
                                                                            setCollectListSuccess();
                                                                        }
                                                                    );
                                                                })
                                                            );

                                                        })

                                                        .catch( err => {
                                                            errorlog("Lista raccolte", err);
                                                            return Promise.reject( err );
                                                        })
                                                }
                                                else {
                                                    showlog("Non hai i permessi di accesso alle raccolte");
                                                }
                                            }
                                        }
                                    )
                                }}
                            >
                                <option value={0} selected={ this.state.collectionCompare_select === 0 }>Non confrontare</option>
                                <option value={1} selected={ this.state.collectionCompare_select === 1 }>Confronta</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    {
                        (!this.state.collectionCompare_select || this.state.collectionCompare_select === 0 || this.state.collectionCompare_select === "0")
                            ? (
                                <Col sm={2}>
                                    <h4 className="header-title">
                                        Raccolte
                                    </h4>
                                    <FormGroup style={{marginTop: "-21px"}}>
                                        <Input
                                            type="select"
                                            name="collectionCompare_select"
                                            id="collection_select"
                                            className="custom-select mt-3"
                                            onChange={ x => {

                                                showlog("controllo nome raccolta");
                                                showlog(
                                                    this.state.collects
                                                        .filter( c => c.id === parseInt(x.target.value, 10) )
                                                        .reduce( getLastElementOr, "" )
                                                );
                                                showlog(
                                                    this.state.collects
                                                        .filter( c => c.id === x.target.value )
                                                );
                                                showlog(
                                                    this.state.collects
                                                );
                                                showlog(x.target.value);
                                                // debugger;

                                                this.setState({
                                                    selectedCollect: x.target.value,
                                                    selectedCollectName:
                                                        this.state.collects
                                                            .filter( c => c.id === parseInt(x.target.value, 10) )
                                                            .reduce( getLastElementOr, "" )
                                                });
                                            }}
                                        >
                                            {
                                                (!!this.state.collects && this.state.collects.length > 0)
                                                    ? (
                                                        this.state.collects
                                                            .map( collect => (
                                                                <option
                                                                    value={ collect.id }
                                                                    selected={
                                                                        !!this.state.selectedCollect
                                                                            ? this.state.selectedCollect === collect.id
                                                                            : this.state.collects.filter( onlyFirst ).reduce( getLastElementOr, {id: 0} ).id === collect.id
                                                                    }
                                                                >{ collect.name }</option>
                                                            ))
                                                    )
                                                    : (
                                                        <WaitingOrNodataMessage
                                                            waiting={ !(!!this.state.collects && this.state.collects.length === 0) }
                                                            waitMessage={"Caricamento Raccolte"}
                                                            nodataMessage={"Nessuna Raccolta"}
                                                            // className={"bottom-margin"}
                                                            // fullHeight={ true }
                                                            spinnerColor={"success"}
                                                        />
                                                    )
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                            )
                            : null
                    }


                </Row>

                <Row className="align-items-center">

                    <Col className="col-auto" sm="2">

                        <h4 className="header-title">Tematiche</h4>


                        <FormGroup style={{marginTop: "-21px"}}>
                            {
                                !!this.state.thematics &&
                                (
                                    <Input type="select" name="select" id="chapter" className="custom-select mt-3"
                                           onChange={((x) => {
                                               this.selectionAction(0, x.target.value);

                                           })}
                                    >
                                        {
                                            !!this.state.thematics &&
                                            <option>Selezionare una tematica </option>
                                        }
                                        {
                                            !!this.state.thematics &&
                                            this.state.thematics.map(x => {
                                                return (
                                                    <option value={x.id} key={x.id} selected={ this.state.thematicSelected === x.id }>{x.it}</option>
                                                )
                                            })

                                        }
                                    </Input>
                                )
                            }
                            {
                                !this.state.thematics &&
                                (
                                    <Input type="select" name="select" id="chapter" className="custom-select mt-3">
                                        <option>Caricamento tematiche...</option>
                                    </Input>
                                )
                            }
                        </FormGroup>
                    </Col>


                    <Col className="col-auto" sm="2">
                        {!!this.state.thematicSelected &&
                        <h4 className="header-title">Capitolo</h4>
                        }

                        {
                            (!!this.state.thematicSelected && !this.state.chapters)
                                ? (
                                    <WaitingOrNodataMessage
                                        waiting={ !this.state.chapters }
                                        nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                                        fullHeight={ false }
                                        className={"top-margin-alignment select-waiting"}
                                    />
                                )
                                : (
                                    <FormGroup style={{marginTop: "-21px"}}>
                                        {
                                            !!this.state.chapters &&
                                            (
                                                <Input type="select" name="select" id="chapter" className="custom-select mt-3"
                                                       onChange={((x) => {
                                                           this.selectionAction(1, x.target.value);

                                                       })}
                                                >

                                                    {
                                                        !!this.state.chapters &&
                                                        <option>Selezionare un capitolo</option>
                                                    }

                                                    {
                                                        !!this.state.chapters && (


                                                            this.state.chapters.map(x => {
                                                                return (
                                                                    <option value={x.id} key={x.id} selected={ this.state.chapterSelected === x.id }>{x.it}</option>
                                                                )
                                                            })


                                                        )

                                                    }
                                                </Input>
                                            )
                                        }
                                        {
                                            (!this.state.chapters && !!this.state.thematicSelected) &&
                                            (
                                                <Input type="select" name="select" id="chapter" className="custom-select mt-3">
                                                    <option>Caricamento capitoli...</option>
                                                </Input>
                                            )
                                        }
                                    </FormGroup>
                                )
                        }




                    </Col>


                    <Col className="col-auto" sm="2">
                        {!!this.state.chapterSelected &&
                        <h4 className="header-title">Informativa</h4>
                        }


                        {
                            (!!this.state.chapterSelected && !this.state.informatives)
                                ? (
                                    <WaitingOrNodataMessage
                                        waiting={!this.state.informatives}
                                        nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                                        fullHeight={false}
                                        className={"top-margin-alignment select-waiting"}
                                    />
                                )
                                : (
                                    <FormGroup style={{marginTop: "-21px"}}>
                                        {
                                            !!this.state.informatives &&
                                            (
                                                <Input type="select" name="select" id="chapter" className="custom-select mt-3"
                                                       onChange={((x) => {
                                                           this.selectionAction(2, x.target.value);

                                                       })}
                                                >

                                                    {
                                                        !!this.state.informatives &&
                                                        <option>Selezionare un'informativa</option>
                                                    }
                                                    {
                                                        !!this.state.informatives &&
                                                        this.state.informatives.map(x => {
                                                            return (
                                                                <option value={x.id} key={x.id} selected={ this.state.informativeSelected === x.id }>{x.it}</option>
                                                            )
                                                        })

                                                    }
                                                </Input>
                                            )
                                        }
                                        {
                                            (!this.state.informatives && !!this.state.chapterSelected) &&
                                            (
                                                <Input type="select" name="select" id="chapter" className="custom-select mt-3">
                                                    <option>Caricamento informative...</option>
                                                </Input>
                                            )
                                        }
                                    </FormGroup>
                                )
                        }



                    </Col>


                    <Col className="col-auto" sm="2">
                        {!!this.state.informativeSelected &&
                        <h4 className="header-title">Requisito</h4>
                        }


                        {
                            (!!this.state.informativeSelected && !this.state.requirements)
                                ? (
                                    <WaitingOrNodataMessage
                                        waiting={!this.state.requirements}
                                        nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                                        fullHeight={false}
                                        className={"top-margin-alignment select-waiting"}
                                    />
                                )
                                : (
                                    <FormGroup style={{marginTop: "-21px"}}>
                                        {
                                            !!this.state.requirements &&
                                            (
                                                <Input type="select" name="select" id="chapter" className="custom-select mt-3"
                                                       onChange={((x) => {
                                                           this.selectionAction(3, x.target.value);

                                                       })}
                                                >
                                                    {
                                                        !!this.state.requirements &&
                                                        <option>Selezionare un requisito</option>
                                                    }

                                                    {
                                                        !!this.state.requirements &&
                                                        this.state.requirements.map(x => {
                                                            return (
                                                                <option value={x.id} key={x.id} selected={ this.state.requirementSelected === x.id }>{x.it}</option>
                                                            )
                                                        })

                                                    }
                                                </Input>
                                            )
                                        }
                                        {
                                            (!this.state.requirements && !!this.state.informativeSelected) &&
                                            (
                                                <Input type="select" name="select" id="chapter" className="custom-select mt-3">
                                                    <option>Caricamento requisiti...</option>
                                                </Input>
                                            )
                                        }
                                    </FormGroup>
                                )
                        }


                    </Col>

                    {

                        (!!this.state.requirementSelected)
                            ? (
                                // (!!this.state.subrequirements && this.state.subrequirements.length > 0)
                                (!!isNotEmptyArray( this.state.subrequirements ))
                                    ? (
                                        <Col className="col-auto" sm="2">
                                            {!!this.state.requirementSelected &&
                                            <h4 className="header-title">Sotto Requisito</h4>
                                            }

                                            {
                                                (!!this.state.requirementSelected && !this.state.subrequirements)
                                                    ? (
                                                        <WaitingOrNodataMessage
                                                            waiting={!this.state.subrequirements}
                                                            nodataMessage={emoj(EMOJ.sad_but_relieved_face) + "    Nessun Dato"}
                                                            fullHeight={false}
                                                            className={"top-margin-alignment select-waiting"}
                                                        />
                                                    )
                                                    : (
                                                        <FormGroup style={{marginTop: "-21px"}}>
                                                            {
                                                                !!this.state.subrequirements &&
                                                                (
                                                                    <Input type="select" name="select" id="chapter" className="custom-select mt-3"
                                                                           onChange={((x) => {
                                                                               this.selectionAction(4, x.target.value);

                                                                           })}
                                                                    >
                                                                        {
                                                                            !!this.state.subrequirements &&
                                                                            <option>Selezionare un sotto requisito</option>
                                                                        }
                                                                        {
                                                                            !!this.state.subrequirements &&
                                                                            this.state.subrequirements.map(x => {
                                                                                return (
                                                                                    <option value={x.id} key={x.id} selected={ this.state.subrequirementSelected === x.id }>{x.it}</option>
                                                                                )
                                                                            })

                                                                        }
                                                                    </Input>
                                                                )
                                                            }
                                                            {
                                                                (!this.state.subrequirements && !!this.state.requirementSelected) &&
                                                                (
                                                                    <Input type="select" name="select" id="chapter" className="custom-select mt-3">
                                                                        <option>Caricamento sotto requisiti...</option>
                                                                    </Input>
                                                                )
                                                            }
                                                        </FormGroup>
                                                    )
                                            }


                                        </Col>
                                    )
                                    : (
                                        <WaitingOrNodataMessage
                                            waiting={!this.state.subrequirements}
                                            nodataMessage={ emoj( EMOJ.prohibited ) }
                                            fullHeight={false}
                                            className={"top-margin-alignment select-waiting chart-comander-no-data"}
                                        />
                                    )
                            )
                            : null



                    }





                    {


                        (!!this.state.requirementSelected && !!this.state.subrequirementSelected)
                            ? (
                                // (!!this.state.subsubrequirements && this.state.subsubrequirements.length > 0)
                                (!!isNotEmptyArray( this.state.subsubrequirements ) && !!this.state.informativeSelected)
                                    ? (
                                        <Col className="col-auto" sm="2">
                                            {!!this.state.subrequirementSelected &&
                                            <h4 className="header-title">Sottosotto Requisito</h4>
                                            }


                                            {
                                                (!!this.state.subrequirementSelected && !isNotEmptyArray( this.state.subsubrequirements ) )
                                                    ? (
                                                        <WaitingOrNodataMessage
                                                            waiting={!this.state.subsubrequirements}
                                                            nodataMessage={" "}
                                                            fullHeight={false}
                                                            className={"top-margin-alignment select-waiting"}
                                                        />
                                                    )
                                                    : (
                                                        <FormGroup style={{marginTop: "-21px"}}>
                                                            {
                                                                !!this.state.subsubrequirements &&
                                                                (
                                                                    <Input type="select" name="select" id="chapter" className="custom-select mt-3"
                                                                           onChange={((x) => {
                                                                               this.selectionAction(5, x.target.value);

                                                                           })}
                                                                    >
                                                                        {
                                                                            !!this.state.subsubrequirements &&
                                                                            this.state.subsubrequirements.map(x => {
                                                                                return (
                                                                                    <option value={x.id} key={x.id} selected={ this.state.subsubrequirementSelected === x.id }>{x.it}</option>
                                                                                )
                                                                            })

                                                                        }
                                                                    </Input>
                                                                )
                                                            }
                                                            {
                                                                (!this.state.subsubrequirements && !!this.state.subrequirementSelected) &&
                                                                (
                                                                    <Input type="select" name="select" id="chapter" className="custom-select mt-3">
                                                                        <option>Caricamento sottosotto requisiti...</option>
                                                                    </Input>
                                                                )
                                                            }
                                                        </FormGroup>
                                                    )
                                            }


                                        </Col>
                                    )
                                    : (
                                        <WaitingOrNodataMessage
                                            waiting={ (!!this.state.subsubrequirements && this.state.subsubrequirements.length > 0) }
                                            nodataMessage={ " "/*emoj( EMOJ.prohibited )*/ }
                                            fullHeight={false}
                                            className={"top-margin-alignment select-waiting chart-comander-no-data"}
                                        />
                                    )
                            )
                            : null



                    }




                </Row>

                {
                    (!!this.state.requirementSelected) &&
                    (
                        <Fragment>
                            <br/>
                            <Row className="align-items-center">

                                <Col className="col-auto" sm="2">

                                    <ActionButton
                                        buttonText={(
                                            <span>Genera Grafico  <i className="mdi mdi-chart-areaspline"/></span>
                                        )}
                                        color={ "success" }
                                        className=""
                                        promise={ () => {
                                            return (
                                                Promise.resolve()
                                                    .then( () => {
                                                        return this.getFilterDate();
                                                    })
                                            );
                                        }}
                                        onResult={ result => {
                                            this.props.arg( result );
                                        }}
                                        onError={ error => {
                                            errorlog( "E", error );
                                        }}
                                    />

                                </Col>


                            </Row>
                        </Fragment>
                    )
                }


            </React.Fragment>


        );


        return context;
    }

    componentDidMount() {

        return (

            new Promise((success, fail) => {

                if( !isNotEmptyArray( this.state.thematics ) ) {
                    RequirementFilter(0, 0)

                        // set thematic ----------------------------------------------------------------------------------------
                        .then(x => {

                            return (
                                new Promise( (setStateSuccess, setStateFail) => {
                                    this.setState(
                                        {thematics: x},
                                        () => {
                                            success();
                                        }
                                    )
                                })
                            );

                        })

                        .catch( err => {
                            errorlog("Richiesta Filtri", err);
                            return Promise.reject( err );
                        })
                }
                else {
                    success();
                }

            })

            // get collect list ------------------------------------------------------------------------------------
            .then( () => {

                showlog("controllo condizione");
                showlog( !this.state.collectionCompare_select);
                showlog( this.state.collectionCompare_select === 0 );
                showlog( !this.state.collectionCompare_select || this.state.collectionCompare_select === 0 || this.state.collectionCompare_select === "0" );

                if( !this.state.collectionCompare_select || this.state.collectionCompare_select === 0 || this.state.collectionCompare_select === "0" ) {
                    // debugger

                    if( !!hasPermission( permissions.GET_COLLECT ) ) {
                        loadCollect()

                            .then( collectList => {

                                return (
                                    new Promise((setCollectListSuccess, setCollectListFail) => {
                                        this.setState(
                                            { collects: collectList },
                                            () => {
                                                setCollectListSuccess();
                                            }
                                        );
                                    })
                                );

                            })

                            .catch( err => {
                                errorlog("Lista raccolte", err);
                                return Promise.reject( err );
                            })
                    }
                    else {
                        showlog("Non hai i permessi di accesso alle raccolte");
                    }
                }
                return true;
            })


        );


    }


}


