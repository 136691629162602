import {
    createAnswerChoiche,
    createNewQuestionSector,
    createNewRating,
    deleteAnswer,
    deleteQuestionAnswerAttachment,
    deleteSRToolInformativeAttachment,
    disableQuestionById,
    disableQuestionByThemes,
    dowloadQuestionAnswerAttachment,
    dowloadRatingFile,
    dowloadRatingQuestionAnswersFile,
    dowloadSRToolInformativeAttachment,
    DownloadGapAnalysisCSVFile,
    editAnswer,
    editAnswerChoiche,
    editQuestion,
    editQuestionGL,
    editRatingName,
    enableQuestionById,
    enableQuestionByThemes,
    eraseAnswerChoice,
    eraseRating,
    eraseSectorQuestion,
    getAdminQuestionNote,
    getAllFlatQuestions,
    getAnswer,
    getAnswersDataFile,
    getChoiche,
    getGapAnalysisCSVFile,
    getGapAnalysisDataFile,
    getMaterialityCSVFile,
    getMaterialityXMLFile,
    getProfilingData,
    getProfilingDataFile,
    getProgress,
    getQuestionHistory,
    getQuestionNote,
    getRataingInfo,
    getRatingPdfParameters,
    getRatingStatus,
    getSectorQuestions,
    getSgdXMLFile,
    getSiRatingAdminScoreThemesChartData, getSiratingQuestionInfo,
    getSiScoringChoiche,
    loadCriteriaChart,
    loadQuestions,
    loadThemesChart,
    passToEvaluationStatus,
    saveAdminQuestionNote,
    saveConfigurationQuestionThematicReason,
    saveQuestionNote,
    setAnswer,
    setAttachmentAprovingService,
    setDisabledSectorQuestion,
    setProfilingData,
    setQuestionAudit,
    setRatingPdfParameters,
    updateNewQuestionSector,
    uploadQuestionAnswerAttachment,
    uploadSRToolInformativeAttachment,
    validAllAudit
} from "../services/rating";
import {SdgScore_Get} from "../services/request-manager";
import {errorlog, showlog} from "../utils/dev-utils";
import {
    HIDDEN_LANGUAGES,
    LANGUAGES,
    permissions,
    QUESTION_STATUS,
    RATING_STATUS,
    RATING_TYPE,
    SIRATING_ADMIN_CHART_SDG,
    SIRATING_ADMIN_CHART_THEMATICS_ENV,
    SIRATING_ADMIN_CHART_THEMATICS_GOV,
    SIRATING_ADMIN_CHART_THEMATICS_SOC
} from "../env";
import {hasPermission} from "./auth";
import React from "react";
import {getLastElementOr, isNotEmptyArray, onlyNotNull} from "../utils/array-utils";
import {convertSAConfigurationQuestionTableToTree, questionTreeLog} from "./question-tree-manager";
import {exportSeedTableToCSV} from "./tree-manager";
import {loadEvaluations} from "./active-service";
import {isOnlyMainSector, loadSectorQuestionsDimensions} from "./question-sectors";
import {isNumber} from "../utils/codes-utils";
import {getInformativeNote, loadEvaluationStatus, saveInformativeNote} from "../services/gri-admin-tree";
import {saveSiScoringOpenAnswer} from "./siscoring";
import {
    loadQuestion,
    saveNewChoiche,
    storeNewQuestion,
    updateChoiche,
    updateQuestion
} from "../services/question-service";
// import itFlag from "../components/i18n/pic/png/IT@3x.png";
// import enFlag from "../components/i18n/pic/png/GB@3x.png";
// import deFlag from "../components/i18n/pic/png/DE@3x.png";
import {cloneObject} from "../utils";
import {setFlagToLanguage} from "../components/i18n/translate-function";

export const addNewRating = (cid, dimensionID, name, cloneConfFrom, ratingType = RATING_TYPE.sirating ) => {
    return createNewRating(cid, dimensionID, name, cloneConfFrom, ratingType);
}



export const getQuestions = (rid, ratingType) => {
    return (
        loadQuestions(rid)
        .then(list => list.map(q => Object.assign({}, q.question, { enabled: q.enable })))


        // logs ----------------------------------------------------------------------------------------------------------------
        .then(list => {
            showlog("question list");
            showlog(list);

            let csv = exportSeedTableToCSV(list);
            showlog(csv);

            return list;
        })




        // conversion ----------------------------------------------------------------------------------------------------------------
        .then(list => {

            return (
                convertSAConfigurationQuestionTableToTree(list)
                .then(tree => {
                    showlog(tree);
                    // debugger
                    questionTreeLog(tree);
                    // debugger

                    return ({
                        seed: list,
                        // tree: questionTreeCloneOpenNodes( (!!this.state ? this.state.tree : tree), tree )
                        tree: tree // da attenzionare!
                    });

                })
            );

        })



        // recovery step ---------------------------------------------------------------------------------------------------------
        .then(({ seed, tree }) => {
            // debugger
            return (
                loadEvaluations( ratingType )

                // logs   -----------------------------------------------------------------------------------------------
                .then(list => {
                    // showlog("evaluations");
                    // showlog(list);
                    // debugger;
                    return list
                })


                // filter -------------------------------------------------------------------------------------------------
                .then(list => {
                    // debugger
                    return (
                        list
                        .filter(row => row.id === parseInt(rid, 10))
                        .reduce(getLastElementOr, null)
                    );
                })



                // finish   -----------------------------------------------------------------------------------------------
                .then(currentRating => {
                    return ({
                        seed: seed,
                        tree: tree,
                        status: currentRating.status,
                        statusName: currentRating.state,
                        ratingName: currentRating.name
                    });
                })
            );




        })



        // comapny size
        .then(({ seed, tree, status, statusName, ratingName }) => {

            return (
                loadSectorQuestionsDimensions()
                .then(companySizeList => {

                    return ({
                        seed: seed,
                        tree: tree,
                        status: status,
                        statusName: statusName,
                        comanySizes: companySizeList,
                        ratingName: ratingName
                    });


                })

            );

        })




        .catch(e => Promise.reject(e))
    );
}


/**
 *
 * @returns {Promise<any|never>}
 */
export const loadSectorQuestion = () => {
    return (
        getSectorQuestions()
        // extra info ----------------------------------------------------------------------------------------------------------------
        .then(data => {

            return (
                loadSectorQuestionsDimensions()
                .then(dimensions => {
                    return { list: data, dimensions: dimensions };
                })
                .catch(e => Promise.reject(e))
            );

        })

        .catch(e => Promise.reject(e))
    );
}

export const setIsOnlySectorMain = (obj,id) => {
    return isOnlyMainSector(obj,id)
}

export const addNewQuestionSector = data => {
    showlog("arriva a addNewQuestionSector");
    showlog(data);

    /*
    multiAnswer: false
    q_en: "english question"
    q_it: "domanda italiana"
    score: "9"
    rawSectors: (79) [{…}, {…}, {…}, ...]
    sectors: [
    	{label: "Abbigliamento, accessori e calzature", value: 1}
    	{label: "Produzione di elettrodomestici", value: 2}
    	{label: "Prodotti per l'edilizia e arredamento", value: 3}
    	{label: "Prodotti per la casa e personali", value: 4}
    	{label: "Giocattoli e articoli sportivi", value: 5}
    ]
    selectedSector: {label: "Produzione di elettrodomestici", value: 2}
    selectedThemes: {
    	id: 2
    	value: 2
    	label: "Qualità dell'aria"
    	name: "Qualità dell'aria"
    	path: [1, 2]
    	subThemes: []
    }
    themes: [
    	{
    		id: 1
    		value: 1
    		label: "Gestione ambientale"
    		name: "Gestione ambientale"
    		path: [1, 1]
    		subThemes: [
    			{
    				id: 1
    				name: "Sistema di Gestione Ambientale"
    				path: [1, 1, 1]
    			},
    			{id: 2, name: "Gestione delle emergenze ambientali", path: Array(3)}
    			{id: 3, name: "Beni durevoli", path: Array(3)}
    			{id: 4, name: "Supply chain", path: Array(3)}
    		]
    	},
    	{…}
    ]
    selectedSubThemes: {value: 2, label: "Gestione delle emergenze ambientali"}
    themesTree: {
    	area: [
    		{
    			id: 1
    			name: "Environmental"
    			path: [1]
    			themes: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
    		},
    		{id: 2, name: "Social", themes: Array(6), path: Array(1)},
    		{id: 3, name: "Governance", themes: Array(8), path: Array(1)}
    	]
    }
    */

    let deeperNode = (!!data.selectedSubThemes ? data.selectedSubThemes : data.selectedThemes);
    showlog("deeperNode");
    showlog(deeperNode);
    let dto = {
        SrMacroareaId: data.themesTree.area
            .filter(a => a.id === deeperNode.path[0])
            .map(a => a.id)
            .reduce(getLastElementOr, 0),
        SrThematicId: data.selectedThemes.id,
        SrSubthematicId: (!!data.selectedSubThemes ?
            data.selectedSubThemes.id :
            0
        ),
        Score: data.score,
        IsMultipleAnswer: !!data.multiAnswer,
        SrSectorId: data.selectedSector.value,
        code: data.code,
        name_it: data.q_it,
        name_en: data.q_en,
        companySize: data.selectedDimension.value,
        attachments_guidelines: data.attachmentGuideLines,
        companiesAllowed: data.selectedCompanies,
        sdgList: data.selectedSdg,
        transparency: data.transparency,
        resilience: data.resilience,
        improvement: data.improvement,
        gendergap: data.gendergap,
        question_guidelines: data.questionGuidelines
    };

    return createNewQuestionSector(dto);
}




export const editNewQuestionSector = (data, qid) => {
    showlog("arriva a editNewQuestionSector");
    showlog(data);


    let deeperNode = (!!data.selectedSubThemes ? data.selectedSubThemes : data.selectedThemes);
    showlog("deeperNode");
    showlog(deeperNode);
    let dto = {
        SrMacroareaId: data.themesTree.area
            .filter(a => a.id === deeperNode.path[0])
            .map(a => a.id)
            .reduce(getLastElementOr, 0),
        SrThematicId: data.selectedThemes.id,
        SrSubthematicId: (!!data.selectedSubThemes ?
            data.selectedSubThemes.id :
            0
        ),
        Score: data.score,
        IsMultipleAnswer: !!data.multiAnswer,
        SrSectorId: data.selectedSector.value,
        code: data.code,
        name_it: data.q_it,
        name_en: data.q_en,
        companySize: data.selectedDimension.value,
        attachments_guidelines: data.attachmentGuideLines,
        companiesAllowed: data.selectedCompanies,
        sdgList: data.selectedSdg,
        transparency: data.transparency,
        resilience: data.resilience,
        improvement: data.improvement,
        gendergap: data.gendergap,
        question_guidelines: data.questionGuidelines
    };

    return updateNewQuestionSector(dto, qid);
}



export const loadChoiche = sectorQuestionId => {
    return getChoiche(sectorQuestionId);
}



export const loadSiScoringChoiche = chID => {
    return (
        getSiScoringChoiche(chID)
            .then( question => {


                showlog("controllo choiche");
                showlog( question );

                // adjust
                question.enabled = question.alive;


                // testi
                let clonedLamguagesForTexts =
                    cloneObject(
                        LANGUAGES
                            .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    )
                ;
                question.description.langs =
                    clonedLamguagesForTexts
                        .filter( lan => Object.keys( question.description ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = question.description[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                ;


                return question;
            })
            .catch( e => Promise.reject( e ))
    );
};



export const addNewAnswerChoicheSector = (question, choichesList) => {

    showlog("invio delle scelte");
    showlog(question, choichesList);

    /*
    arriva choichelist---------------------------------------
    "id": 5,
    "open": true
    "name_it": "berewrla",
    "name_en": "erwr",
    "score": 0,
    "srQuestionId": 29,
    "isRequiredAttachment": true,
    "isRequiredTextBox": true,
    "ordering": 1,

    question ---------------------------------------
    id: 35
    isMultipleAnswer: true
    macroarea_id: 1
    macroarea_name: "Environmental"
    name: "Nuova Domanda"
    ordering: 0
    score: 33
    sector_name: "Produzione di elettrodomestici                                                                                                                                                                          "
    srSectorId: 2
    subthematic_id: 6
    subthematic_name: "Azioni di adattamento ai rischi fisici"
    thematic_id: 4
    thematic_name: "Cambiamenti Climatici"


    voluto ---------------------------------------
    "SrMacroareaId":1,
    "SrSectorId":1,
    "name_it":"bla",
    "name_en":"blabla"

    "score": 0,
    "isRequiredAttachment": true,
    "isRequiredTextBox": true,
    "ordering": 1,
    */

    choichesList =
        choichesList
        .map(c => Object.assign({ id: 0 }, c));



    let payload =
        choichesList
        .map(ch => ({
            id: ch.id || 0,
            // SrMacroareaId:          question.macroarea_id,
            // SrSectorId:             question.srSectorId,
            name_it: ch.name_it,
            name_en: ch.name_en,
            name_es: "",    // pezza
            name_de: "",    // pezza
            score: ch.score,
            isRequiredAttachment: ch.isRequiredAttachment,
            isRequiredTextBox: ch.isRequiredTextBox,
            ordering: ch.ordering,
            isNewer: ch.isNewer || false,
            isEdit: ch.isEdit || false
        }))
        .filter(pack => (!!pack.name_it &&
            !!pack.name_en
            // && !!pack.score
            &&
            (!!pack.score || pack.score === 0) &&
            !!pack.ordering
        ));


    if (payload.length === 0) {
        return (
            Promise.resolve(
                choichesList
                .map(ch => {
                    return ({
                        id: ch.id || 0,
                        status: "Manca almeno uno dei seguenti campi: testo italiano, testo inglese, ordine, punteggio"
                    });
                })
            )
        );
    }



    showlog(payload);
    showlog(
        ...choichesList
        .filter(c => payload.filter(p => p.id === c.id).length === 0)
    );

    return (
        Promise.all([
            ...payload
                .map(c => {

                    showlog("preparazione chiamata per", c);

                    if (!!c.isNewer) {
                        return (
                            createAnswerChoiche(question.id, c)
                                .then(result => {
                                    return ({ status: "Nuova scelta aggiunta" });
                                })
                                .catch(e => {
                                    errorlog("errore alla creazione della scelta", e);
                                    return Promise.reject(e);
                                })
                        );
                    } else {
                        if (!!c.isEdit) {
                            return (
                                editAnswerChoiche(c)
                                    .then(result => {
                                        return ({ id: c.id, status: "Scelta modificata" });
                                    })
                                    .catch(e => {
                                        errorlog("errore alla modifica della scelta " + c.id, e);
                                        return Promise.reject(e);
                                    })
                            );
                        } else return Promise.resolve({ id: c.id, status: "Nessuna operazione" });
                    }

                }),
            ...choichesList
                .filter(c => payload.filter(p => p.id === c.id).length === 0)
                .map(ch => {
                    return Promise.resolve({
                        id: ch.id || 0,
                        status: "Manca almeno uno dei seguenti campi: testo italiano, testo inglese, ordine, punteggio"
                    });
                })
        ])
        .then(resultList => {
            showlog("result della promise all");
            showlog(resultList);
            return resultList;
        })
        .catch(e => {
            errorlog("errore alla promise all", e);
            return Promise.reject(e);
        })
    );
}


export const deleteSectorQuestion = id => {
    return eraseSectorQuestion(id);
}

export const disableSectorQuestion = id => {
    return setDisabledSectorQuestion(id);
}



export const setNewRatingName = (ratingId, newName) => {
    return editRatingName( ratingId, newName );
}


export const deleteAnswerChoiche = id => {
    return eraseAnswerChoice(id);
}


export const enableAllQuestion = (ratingId, themeId, subThemeId) => {
    return enableQuestionByThemes(ratingId, themeId, subThemeId);
}

export const disableAllQuestion = (ratingId, themeId, subThemeId) => {
    return disableQuestionByThemes(ratingId, themeId, subThemeId);
}



export const enableQuestion = (ratingId, qid) => {
    return enableQuestionById(ratingId, qid);
}


export const disableQuestion = (ratingId, qid) => {
    return disableQuestionById(ratingId, qid);
}




export const setEvaluationStatus = (ratingId, isSRAdmin = false, auditDone = false) => {

    if (!!isSRAdmin) {
        return passToEvaluationStatus(ratingId, { state: RATING_STATUS.ratingComplete.id });
    }

    let statusPayload = { state: RATING_STATUS.configurationComplete.id };
    if (!!hasPermission(permissions.SET_VALIDATION_CONFIGURATION)) {
        // SA
        statusPayload.state = RATING_STATUS.configurationValidated.id;
    } else {
        if (!!hasPermission(permissions.SET_COMPLETATION_CONFIGURATION)) {
            // ARB OPERATOR
            statusPayload.state = RATING_STATUS.configurationComplete.id;
        }
    }

    if (!!auditDone) {
        if (!!hasPermission(permissions.RATING_AUDIT_CONFIRM) && !!hasPermission(permissions.RATING_AUDIT_CONTINUE_AFTER_CONFIRM)) {
            // SA
            statusPayload.state = RATING_STATUS.auditDone.id;
        } else {
            // return Promise.reject( errorMessage.PERMISSION_DENIED );
            // ARB OPERATOR
            statusPayload.state = RATING_STATUS.auditComplete.id;
        }
    }

    return passToEvaluationStatus(ratingId, statusPayload);
}


export const acceptEUPA = ratingId => {
    return passToEvaluationStatus(ratingId, { state: RATING_STATUS.perimetralQuestionAccepted.id });
}


export const loadAnswers = (ratingId, tabId = 0, choicheId = 0) => {
    return getAnswer(ratingId + "/" + tabId + "/" + choicheId);
}


export const getQuestion = (questionID, isSR) => {
    return (
        loadQuestion(questionID, isSR)
            .then( question => {


                showlog("controllo question");
                showlog( question );

                // adjust
                question.enabled = question.alive;


                // testi
                let clonedLamguagesForTexts = LANGUAGES;
                    // cloneObject(
                    //     LANGUAGES
                    //         .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    // )
                ;
                question.description.langs =
                    clonedLamguagesForTexts
                        .filter( lan => Object.keys( question.description ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = question.description[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                ;

                // linee guida
                let clonedLamguagesForGuideLines =
                    cloneObject(
                        LANGUAGES
                            // .filter( l => !HIDDEN_LANGUAGES.includes( l.code ) ) // lingue escluse
                    )
                ;
                question.guidelines.langs =
                    clonedLamguagesForGuideLines
                        .filter( lan => Object.keys( question.guidelines ).includes( lan.code.substr(0, 2).toLowerCase() ) )
                        .map( lan => {
                            let newLangObject = lan;
                            newLangObject[lan.code.substr(0, 2).toLowerCase()] = question.guidelines[lan.code.substr(0, 2).toLowerCase()];
                            return newLangObject;
                        })
                        .map( lang => setFlagToLanguage( lang ) )
                ;


                return question;
            })
            .catch( e => Promise.reject( e ))
    )
};


export const saveNewQuestion = (id, values, isQuestion, tab, score, themeID) => {
    values.score = score;
    values.tab = tab;
    values.thematic = themeID;
    if( !!isQuestion ) return editQuestion( id, values, tab, score );   // TODO
    else return editAnswer( id, values );
}

export const editAndSaveQuestion = question => {
    // delete question.langs;
    // delete question.description.langs;
    // delete question.guidelines.langs;
    return updateQuestion(question);
};

export const saveQuestion = question => {
    // delete question.langs;
    // delete question.description.langs;
    // delete question.guidelines.langs;
    return storeNewQuestion(question);
};

export const editAndSaveChoiche = (id, question) => updateChoiche( id, question );
export const saveChoiche = (fatherQuestionID, question) => saveNewChoiche(fatherQuestionID, question );


export const eraseAnswer = ansId => deleteAnswer( ansId );

export const saveNewQuestionGuideLine = (id, values, isQuestion) => {
    return editQuestionGL( id, values );
}

export const loadAllFlatQuestions = id => {
    return (
        getAllFlatQuestions( id )
            .then( list => {
                return (
                    list
                        .map( q => ({
                            id: q.question_id,
                            code: q.question_code,
                            text: {
                                it: q.question_name_it,
                                en: q.question_name_en
                            },
                            macroArea: {
                                id: q.macroarea_id,
                                name: q.macroarea_name
                            },
                            thematic: {
                                id: q.thematic_id,
                                code: q.thematic_code,
                                name: q.thematic_name
                            },
                            subThematic: {
                                id: q.subthematic_id,
                                code: q.subthematic_code,
                                name:q.subthematic_name,
                            },
                            guidelines: q.guidelines,
                            choiches:
                                q.choiches
                                    .map( ch => ({
                                        id: ch.choiche_id,
                                        text: {
                                            it: ch.choiche_name.it.reduce( getLastElementOr, "" ),
                                            en: ch.choiche_name.en.reduce( getLastElementOr, "" )
                                        },
                                        score: ch.score
                                    })),
                            scoreMax: q.scoreMax,
                            isOpenQuestion: q.isOpenQuestion,
                            thematic_code: q.thematic_code,
                            version: q.version
                        }))
                );
            })
    );
}


export const saveAnswer = (question, ratingId, ncAnswer, isSiScoring = false) => {



    showlog("save answer");
    showlog(question);
    showlog(ratingId);
    showlog(ncAnswer);


    // let parameter = question.questionsStructureId;
    let parameter = ratingId + "/" + question.id;

    // base payload
    let payload = {
        AnswersRequestList: question.choiches
            .map(ch => ch.answers.reduce(getLastElementOr, null))
            .filter(onlyNotNull)
            .map(ans => (
                Object.assign({
                    SrChoicheId: ans.choiche_id,
                    Motivation: (!!ans.motivation) ? ans.motivation : "",
                    isChanged: question.isChangedAnswer,
                    score:
                        (!!ncAnswer || ncAnswer === 0)
                            ? parseFloat(ncAnswer)
                            : (
                                isNumber(ans.score)
                                    ? parseFloat(ans.score)
                                    : 0
                            )
                })
            ))
    };



    // check ----------------------------------------------------------------------
    let check = false;
    let error = "";


    // se la scelta relativa alla risposta ha il flag isRequiredTextBox
    let choiche =
        question.choiches
        .map(ch => {
            if (!!ch.answers.reduce(getLastElementOr, null)) return ch;
            return null;
        })
        .filter(onlyNotNull)
        .reduce(getLastElementOr, null);

    // scenario: salvataggio risposta senza risposta
    // if (!!!choiche) return Promise.resolve();


    // if (!!check) return setAnswer(payload, parameter);
    // return Promise.reject(error);
    return (
        setAnswer(payload, parameter, isSiScoring)
            .then( result => {
               // debugger;
                return result;
            })
            .catch(e => Promise.reject( e ))
    );

}




const processSingleAnswer_syncChain = (questions, index, ratingId, ncAnswer) => {



    // se la domanda ha un input di testo prendi il suo valore, altrimenti lascia ncAnswer

    questions[index].choiches
        .map( ch => {
            if( !!ch.answers && !!isNotEmptyArray( ch.answers ) ) {

                ncAnswer = document.getElementById("choiche-question-score-" + ch.choiche_id);
                if (!!ncAnswer && typeof ncAnswer.value !== "undefined") ncAnswer = ncAnswer.value;

            }
        })

    return (
        saveAnswer(questions[index], ratingId, ncAnswer)
            .then(result => {
                // showlog(questions);
                // showlog(index, questions[ index ]);
                // debugger;
                if (index < (questions.length - 1)) {
                    // return processSingleAnswer_syncChain(questions, index + 1, ratingId, ncAnswer)
                    processSingleAnswer_syncChain(questions, index + 1, ratingId, ncAnswer);
                } else {
                    return Promise.resolve(questions.map(q => q.id))
                }
            })
            .catch(e => Promise.reject( e ))
    )

}

export const processAnswers_syncChain = (question, ratingId, ncAnswer, isSiScoring = false) => {
    return (
        new Promise((success, fail) => {
            success();
        })


        // salvataggio padre
        .then(() => {
            // showlog("bugfix 2", ncAnswer);
            showlog("salvataggio domanda", question, ncAnswer);
            let new_ncAnswer = ncAnswer;
            if (!!isNotEmptyArray(question.child)) {
                showlog("questa domanda è una domanda padre");
                question.choiches
                    .map( ch => {
                        if( !!ch.answers && !!isNotEmptyArray( ch.answers ) ) {
                            new_ncAnswer =
                                ch.answers.reduce( getLastElementOr, { score: null } )
                                    .score
                            ;
                            if( !new_ncAnswer && new_ncAnswer !== 0 ) new_ncAnswer = ncAnswer;
                        }
                    })
            }
            return saveAnswer(question, ratingId, new_ncAnswer, isSiScoring);
        })



        // salvataggio figli
        .then(result => {

            showlog( question );
            showlog( question.child );
            showlog( !!isNotEmptyArray(question.child) );
            //debugger;

            if (!!isNotEmptyArray(question.child)) {



                /*
                return (

                    new Promise((subSuccess, subFail) => {
                        processSingleAnswer_syncChain(question.child, 0, ratingId, ncAnswer)
                            .then(res => {
                                showlog("salvataggio figlio fatto!", res);
                                // debugger;
                                subSuccess(res);
                            })
                    })


                );
                */


                return (
                	Promise.all(
                		question.child
                			// .filter( questionChild => !this.status.editAnswer.includes( questionChild.id ) )
                			// .filter( questionChild => questionChild.status !== QUESTION_STATUS.virgin )
                			.map( questionChild => {


                                if( !!questionChild.isOpenAnswer ) {
                                     let answer = (
                                         !!document.getElementById(`openAnswerValue-${questionChild.id}`)
                                             ? document.getElementById(`openAnswerValue-${questionChild.id}`).value
                                             : ""
                                    );
                                    return saveSiScoringOpenAnswer( ratingId, questionChild.id, questionChild.questionsStructureId, answer );
                                }
                                else {
                                     questionChild.choiches
                                        .map( ch => {
                                            if( !!ch.answers && !!isNotEmptyArray( ch.answers ) ) {

                                                ncAnswer = document.getElementById("choiche-question-score-" + ch.choiche_id);
                                                if (!!ncAnswer && typeof ncAnswer.value !== "undefined") ncAnswer = ncAnswer.value;

                                            }
                                        })
                                    ;
                                    return saveAnswer( questionChild, ratingId, ncAnswer, isSiScoring );
                                }


                			})
                	)
                    .then( resultList => {
                        return resultList;
                    })
                )





            }
            else return result;

        })

        // fine
        .then(res => {
            //debugger;
            return res;
        })

        // errore
        .catch(e => {
            errorlog("errore al salvataggio della risposta", e);
            return Promise.reject(e);
        })


    );
}




export const processAnswers = (question, ratingId) => {
    return (
        new Promise((success, fail) => {
            success();
        })


        // salvataggio padre
        .then(() => {
            return saveAnswer(question, ratingId);
        })



        // salvataggio figli
        .then(() => {


            // debugger;

            if (!!isNotEmptyArray(question.child)) {
                return (
                    Promise.all(
                        question.child
                        // .filter( questionChild => !this.status.editAnswer.includes( questionChild.id ) )
                        .filter(questionChild => questionChild.status !== QUESTION_STATUS.virgin)
                        .map(questionChild => {
                            return saveAnswer(questionChild, ratingId);
                        })
                    )
                    .then(resultList => {
                        return resultList;
                    })
                )
            }
            return true;

        })

        // fine
        .then(() => {
            // debugger;
            return true;
        })

        // errore
        .catch(e => {
            errorlog("errore al salvataggio della risposta", e);
            return Promise.reject(e);
        })


    );
}



export const saveSIRatingAdminQuestionAnswerAttachment = (data, fileName, ratingId, questionId) => {
    return uploadQuestionAnswerAttachment(data, fileName, ratingId, questionId);
}

export const saveSRToolInformativeAttachment = (data, fileName, collectId, informativeId) => {
    return uploadSRToolInformativeAttachment(data, fileName, collectId, informativeId);
}


export const removeSIRatingAdminQuestionAnswerAttachment = attachmentId => {
    return deleteQuestionAnswerAttachment(attachmentId);
}

export const removeSRToolInformativeAttachment = attachmentId => {
    return deleteSRToolInformativeAttachment(attachmentId);
}



export const downloadSIRatingAdminQuestionAnswerAttachment = (attachmentId, fileName) => {
    showlog("downloadSIRatingAdminQuestionAnswerAttachment");
    return dowloadQuestionAnswerAttachment(attachmentId, fileName);
}

export const downloadSRToolInformativeAttachment = (attachmentId, fileName) => {
    return dowloadSRToolInformativeAttachment(attachmentId, fileName);
}

export const downloadSIRatingRatingFile = (rid, ratingName) => {
    return dowloadRatingFile(rid, ratingName);
}

export const downloadSIRatingRatingQuestionAnswersFile = (rid, ratingName) => {
    return dowloadRatingQuestionAnswersFile(rid, ratingName);
}

export const getMaterialityFile = (rid, fileName) => {
    return getMaterialityXMLFile(rid, fileName);
}

export const getGapAnalysisFile = (rid, fileName) => {
    return getGapAnalysisCSVFile(rid, fileName);
}

export const getMaterialityCsvFile = (rid, fileName) => {
    return getMaterialityCSVFile(rid, fileName);
}



export const getSdgFile = (rid, fileName) => {
    return getSgdXMLFile(rid, fileName);
}


// non usata
export const loadRataingInfo = ratingId => {
    return getRataingInfo(ratingId);
}


export const uploadAttachmentProcessing = (fileList, ratingId, questionId = 0, isSRTool) => {

    return (
        Promise.all(

            fileList
            .map(file => {

                return (
                    new Promise((success, fail) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => success({ base64: reader.result, file: file });
                        reader.onerror = e => fail(e);
                    })
                    .then(({ base64, file }) => {
                        showlog(base64);
                        showlog(file);
                        return file;
                    })
                    .catch(e => {
                        errorlog("errore nella conversione del file in base64", e);
                        return Promise.reject(e);
                    })
                );

            })


        )

        // tutti contenuti sono stati letti
        .then(contentList => {

            return (
                Promise.all(
                    contentList
                    .map(fc => {
                        if (!!isSRTool) {
                            return (
                                saveSRToolInformativeAttachment(fc, fc.name, ratingId, questionId)
                                .then(result => {
                                    if(!result.data) result.data = {};
                                    result.data.name = fc.name;
                                    showlog("result dell'upload");
                                    showlog(result);
                                    return result;
                                })
                                .catch(e => {
                                    errorlog("errore all'upload");
                                    showlog(e);
                                    return Promise.reject(e);
                                })
                            );
                        } else {
                            return (
                                saveSIRatingAdminQuestionAnswerAttachment(fc, fc.name, ratingId, questionId)
                                .then(result => {
                                    showlog("result dell'upload");
                                    showlog(result);
                                    return result;
                                })
                                .catch(e => {
                                    errorlog("errore all'upload");
                                    showlog(e);
                                    return Promise.reject(e);
                                })
                            );
                        }
                    })
                )

                // tutti i file sono stati caricati
                .then(resultList => {
                    showlog("resultList");
                    return resultList;
                })
                .catch(e => {
                    errorlog("errore al caricamento/lettura del file");
                    showlog(e);
                    return Promise.reject(e);
                })
            );

        })

        // Pronti per assegnare gli IDs
        .then(resultList => {
            showlog("resultList ultimo, pronti per assegnare gli id");
            return resultList;
        })

        .catch(e => {
            errorlog("errore alla lettura del file", e);
            return Promise.reject(e);
        })
    );




}


export const setConfigurationQuestionThematicReason = (id, thId, sthId, reason) => {
    let data = {
        SrThematicId: thId,
        SrSubthematicId: sthId,
        Value: reason.value,
        Motivation: reason.motivation
    };
    return saveConfigurationQuestionThematicReason(id, data);
}



export const questionAudit = (ratingId, questionId, stateId, reason, exp) => {
    let payload = {
            state: stateId,
            comment: reason,
            ExpirationDate: exp
        }
        // test
        // return Promise.reject ({ msg: "prova messaggio di errore", code: 999 });
    return setQuestionAudit(ratingId, questionId, payload);
}



export const loadRatingStatus = ratingId => {
    return getRatingStatus(ratingId);
}

export const setAttachmentApproving = (id, value) => {
    return setAttachmentAprovingService(id, { IsApproved: value });
}



export const loadRatingPdfParameters = id => {
    return getRatingPdfParameters(id);
}

export const saveRatingPdfParameters = (id, rawData) => {
    let payload = {
        text1: rawData.textA || rawData.data.reduce(getLastElementOr, { text1: "" }).text1 || "",
        text2: rawData.textB || rawData.data.reduce(getLastElementOr, { text2: "" }).text2 || "",
        text3: rawData.textC || rawData.data.reduce(getLastElementOr, { text3: "" }).text3 || "",
        text4: rawData.textD || rawData.data.reduce(getLastElementOr, { text4: "" }).text4 || "",
        text5: rawData.textE || rawData.data.reduce(getLastElementOr, { text5: "" }).text5 || "",
        text6: rawData.textF || rawData.data.reduce(getLastElementOr, { text6: "" }).text6 || "",
        text7: rawData.textG || rawData.data.reduce(getLastElementOr, { text7: "" }).text7 || "",
        text8: rawData.textH || rawData.data.reduce(getLastElementOr, { text8: "" }).text8 || ""
    }
    return setRatingPdfParameters(id, payload);
}



export const loadProfilingData = ratingId => {
    return getProfilingData(ratingId);
}

export const saveProfilingAnswer = (ratingId, questionId, payload) => {
    return setProfilingData(ratingId, questionId, payload);
}

export const savePerimetral = (ratingId, isOperator) => {
    let payload = { state: RATING_STATUS.perimetralQuestionValidated.id };
    if (!!isOperator) payload = { state: RATING_STATUS.perimetralQuestionCompleted.id };
    return passToEvaluationStatus(ratingId, payload);
}

export const downloadProfilingData = ratingId => {
    return getProfilingDataFile(ratingId);
}

export const downloadAnswersData = ratingId => {
    return getAnswersDataFile(ratingId);
}

export const downloadGapAnalysisData = ratingId => {
    return getGapAnalysisDataFile(ratingId);
}


export const loadQuestionHistory = (rid, qid) => {
    return getQuestionHistory(rid, qid);
}

export const getRatingData = () => {
    return loadLastRatinData();
}

export const storeRatingData = ratingData => {
    return saveLastRatingData( ratingData );
}

export const loadSiRatingCrieriaChartData = (ratingData) => {

    return (
        getCriteriaChart(ratingData.id)
        .then(data => {

            return data;
        })

        .catch(e => {
            errorlog("load themes chart data", e);

            return Promise.reject(e);
        })
    )
}




export const loadSiRatingAdminScoreThematicsChartData = (ratingData, isMultipleData, type) => {

    switch (type) {


        case 0:
            return (
                getThemesChart(ratingData.id, 0)

                .then(data => {

                    return data;


                })

                .catch(e => {
                    errorlog("load themes chart data", e);

                    return Promise.reject(e);
                })

            );


        case SIRATING_ADMIN_CHART_THEMATICS_ENV:
        case SIRATING_ADMIN_CHART_THEMATICS_SOC:
        case SIRATING_ADMIN_CHART_THEMATICS_GOV:

            return (
                getThemesChart(ratingData.id, type)
                // getScore( ratingData.id, type )
                .then(result => {

                    showlog("load themes chart data");
                    showlog(result);


                    if (!!result && !!result.data) return result.data;
                    if (!!result && !!result.thematicScores) return result.thematicScores;

                    return [];

                })


                .then(themes => {

                    return (
                        themes
                        .map(th => ({
                            label: th.subthematic_name || th.thematic_name,
                            value: th.percentage
                        }))
                    );


                })



                .catch(e => {
                    errorlog("load themes chart data", e);

                    return Promise.reject(e);
                })

            );

        case SIRATING_ADMIN_CHART_SDG:

            return (
                SdgScore_Get(ratingData.id)
                .then(list => {


                    // debugger;

                    return ({
                        name: "SDG",
                        data: list
                            .map(dat => {
                                return ({
                                    label: dat.name,
                                    value: (dat.percentage) //+ ( Math.random() * 100 )
                                });
                            })
                    })
                })
            );

        default:



    }

    return getSiRatingAdminScoreThemesChartData(ratingData, isMultipleData);
}



export const getThemesChart = (ratingId, macroAreaId) => {
    return loadThemesChart(ratingId, macroAreaId);
}

export const loadLastRatinData = () => {
    return JSON.parse( localStorage.getItem( "lastRatingData" ) );
}

export const saveLastRatingData = ratingData => {
    return (
        new Promise((success, fail) => {
            localStorage.setItem("lastRatingData", JSON.stringify( ratingData ) )
            setTimeout( () => { success() }, 500);
        })
        .then( () => {
            return true;
        })
    )
}

export const getCriteriaChart = (ratingId) => {
    return loadCriteriaChart(ratingId);
}

export const loadProgress = ratingId => {
    return getProgress(ratingId);
}



export const loadQuestionNote = (ratingId, questionId) => {
    showlog("bl question note");
    return getQuestionNote(ratingId, questionId);
}

export const loadAdminQuestionNote = (ratingId, questionId) => {
    showlog("bl question note");
    return getAdminQuestionNote(ratingId, questionId);
}

export const loadInformativeNote = (ratingId, questionId) => {
    return getInformativeNote(ratingId, questionId);
}


export const saveNote = (ratingId, questionId, note, isSRTool, isAdmin) => {

    return (
        !!isSRTool
            ? saveInformativeNote(ratingId, questionId, note)
            : (
                !!isAdmin
                    ? saveAdminQuestionNote(ratingId, questionId, note)
                    : saveQuestionNote(ratingId, questionId, note)
            )
    );

}



export const getEvaluationStatus = eid => {
    return (loadEvaluationStatus(eid));
}



export const archiveRating = id => {
    return eraseRating( id );
}

export const validateAllAudit = (id) =>{
    return validAllAudit(id);
}

export const loadSiRatingQuestionInfo = (id) => {
    return getSiratingQuestionInfo(id);
}