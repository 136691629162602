// @flow
import React from 'react';

import PageTitle from '../components/PageTitle';
import CardBody from "reactstrap/es/CardBody";

import {Card, CardHeader, Col, PopoverBody, Progress, Row, UncontrolledPopover,} from "reactstrap";
import {PATH_PREFIX, permissions, PRODUCTION_HOST, role} from "../env";
import {Redirect} from "react-router-dom";
import {hasPermission, isUserRole} from "../business-logic/auth";
import {renderlog, showlog} from "../utils/dev-utils";
import {WelcomePage_Get} from '../services/request-manager'
import HomeServiceBlock from '../components/HomeServiceBlock'
import "../style/home.css";
import {WaitingOrNodataMessage} from "../components/waiting-or-nodata-message";

import GG from "../assets/images/gg_dark_text.png";
import CompanyModal from "../components/area/super-admin/company-edit-modal";
import {translate} from '../components/i18n/translate-function';
import {decodeMarkDown} from "../utils";

export default class AdminWelcomePage extends React.Component {


    state = {
        SRToolServiceActive: true,
        SIratingServiceNotActive: true,
        User: {
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            company: localStorage.getItem("companyName"),
            email: localStorage.getItem("email"),

        },
        // Values:
        // {

        //     piva: "00000",
        //     sector: "",
        //     hasSrToolServiceActive: false,
        //     hasSiRatingServiceActive: false,

        //     questionCompletate_st: 0,
        //     questionCompletate_sr: 0,

        //     documentRequest: 0,
        //     reportedAnswers: 0,
        //     resolvedPositively: 0,
        //     closedNegatively: 0,

        //     gri100: 0,
        //     gri200: 0,
        //     gri300: 0,
        //     gri400: 0,

        //     news1: { title: "FashionUnited", body: "Salvatore Ferragamo rinnova il certificato sulla sostenibilità" },
        //     news2: { title: "Il Giornale d’Italia:", body: "Salvatore Ferragamo rinnova il certificato sulla sostenibilità" },

        // }

    }


    constructor(props) {
        super(props);
    }

    render() {

        // showlog("render di welcome page");
        // showlog( this.state );


        renderlog("SuperAdmin welcomepage", this.state, this.props);
        showlog((!!hasPermission(permissions.CREATE_NEW_COLLECT) && !!!isUserRole(role.PROJECT_MANAGER)));
        showlog("permesso di creare una raccolta", (!!hasPermission(permissions.CREATE_NEW_COLLECT) /*&& !!!isUserRole( role.PROJECT_MANAGER )*/ && !this.state.SRToolServiceNotActive));
        showlog("NON super admin", !!!isUserRole(role.PROJECT_MANAGER));


        if (!!this.state.redirect) {
            delete this.state.collects[0].isPmAuthorized;
            return (
                <Redirect
                    to={{
                        pathname: PATH_PREFIX + "/srtool/" + translate("collect") + "/" + this.state.collects[0].id,
                        state: this.state.collects[0]
                    }}
                />
            );
        }


        if (!!this.state && !!this.state.Values) {

            return (
                <React.Fragment>
                    <PageTitle
                        breadCrumbItems={[
                            //{ label: 'Welcome Page', path: '' + PATH_PREFIX + '/welcome' },
                            {label: 'Home Page', active: true}
                        ]}
                        title={translate("Welcome") + ", " + (this.state.User.firstName + " " + this.state.User.lastName).toUpperCase()}
                    />


                    {
                        !!this.state.companyEditModal
                            ? (
                                <CompanyModal
                                    name={this.state.companyEditModal.ragioneSociale}
                                    company={this.state.companyEditModal}
                                    onClose={isToRefresh => {
                                        this.setState({
                                            ...this.state,
                                            companyEditModal: null
                                        })
                                    }}
                                />
                            )
                            : null
                    }


                    <Row>
                        <Col sm="12">


                            <Row style={{marginLeft: "30px", marginRight: "30px"}}>

                                <Col sm="12">


                                    {/* Services Line ------------------------------------------------------------------------------------------------ */}
                                    <Row>
                                        <Card className="mb-0 sa-dashboard-line">
                                            <CardHeader>
                                                <span><strong> {translate("activeInstruments")} </strong></span>
                                                <i className="dripicons-question" id="UncontrolledPopover1"
                                                   type="button" color="primary" style={{
                                                    color: '#727cf5',
                                                    marginLeft: '7px',
                                                    fontSize: '15px',
                                                    marginTop: '-1px',
                                                    position: 'absolute'
                                                }}/>
                                                <UncontrolledPopover placement="right" target="UncontrolledPopover1"
                                                                     trigger="legacy">
                                                    <PopoverBody> {translate("informativewindow")} </PopoverBody>
                                                </UncontrolledPopover>
                                            </CardHeader>
                                            <CardBody className={"sa-dashboard-line-content"}>

                                                {/* Si Rating */}
                                                <div className={""}>
                                                    <img src={PRODUCTION_HOST + "/assets/Loghi/SI_Rating_logo.svg"}
                                                         alt="Si Rating" id={"si-rating-img"}/>
                                                    <div id={"sa-dashboard-line-status-sirating"}
                                                         className={`sa-dashboard-line-ball sa-dashboard-line-${!!this.state.Values.hasSiRatingServiceActive ? "filled" : "empty"}-ball`}/>
                                                </div>

                                                {/* Si Scoring */}
                                                <div className={""}>
                                                    <img src={PRODUCTION_HOST + "/assets/Loghi/SiScoringDashboard.png"}
                                                         alt={"Si Scoring"} id={"si-scoring-img"}/>
                                                    <div id={"sa-dashboard-line-status-siscoring"}
                                                         className={`sa-dashboard-line-ball sa-dashboard-line-${!!this.state.Values.hasSiScoringServiceActive ? "filled" : "empty"}-ball`}/>
                                                </div>


                                                {/* SR Tool */}
                                                {/*<div className={""}>*/}
                                                {/*    <img src={PRODUCTION_HOST + "/assets/Loghi/SR_Tool_logo.svg"}*/}
                                                {/*         alt="SR Tool" id={"sr-tool-img"}/>*/}
                                                {/*    <div id={"sa-dashboard-line-status-srtool"}*/}
                                                {/*         className={`sa-dashboard-line-ball sa-dashboard-line-${!!this.state.Values.hasSrToolServiceActive ? "filled" : "empty"}-ball`}/>*/}
                                                {/*</div>*/}
                                                {/*---- rimozione srTool scadenza GRI*/}

                                                {/* Gender Gap */}
                                                <div className={""}>
                                                    <img src={GG} alt={translate("gendergap")} id={"gg-img"}/>
                                                    <div id={"sa-dashboard-line-status-gg"}
                                                         className={`sa-dashboard-line-ball sa-dashboard-line-${!!this.state.Values.hasParitaDiGenereServiceActive ? "filled" : "empty"}-ball`}/>
                                                </div>

                                                {/* Custom Projects */}
                                                <div className={"sa-dashboard-line-text-container"}>
                                                    <div className={"sa-dashboard-line-text"}>
                                                        {translate("project")}<br/>{translate("customised")}
                                                    </div>
                                                    <div id={"sa-dashboard-line-status-cp"}
                                                         className={`sa-dashboard-line-ball sa-dashboard-line-${!!this.state.Values.hasPersonalProjects ? "filled" : "empty"}-ball`}/>
                                                </div>

                                            </CardBody>
                                        </Card>
                                    </Row>
                                    {/* ------------------------------------------------------------------------------------------------ */}


                                    {/* Blocks ------------------------------------------------------------------------------------------------ */}
                                    <div className={"sa-dashboard-block-content"}>


                                        {/* ---- 1 ------------------------------------------------------------------------------------------------ */
                                            this.state.Values.hasSiRatingServiceActive
                                                ? (
                                                    <HomeServiceBlock
                                                        Logo={{
                                                            a: PRODUCTION_HOST + "/assets/Loghi/SI_Rating_logo.svg",
                                                            b: PRODUCTION_HOST + "/assets/Loghi/SI_Rating_logo.svg"
                                                        }}
                                                        Title={{
                                                            a: <span> {translate("yourprofile")} </span>,
                                                            b: <span> {translate("requestAndAudit")} </span>
                                                        }}
                                                        Border="#0acf97"
                                                        // Button={{ type: 1, label: 'Si Rating', link: '/super-admin/si-rating/' + translate("evaluations") }}
                                                        Button={{
                                                            type: 1,
                                                            label: 'Si Rating',
                                                            link: '/sirating-admin/' + translate("evaluations")
                                                        }}
                                                        Arrows={{show: true, type: 1}}
                                                        Ribbon={false}
                                                        Pages={
                                                            {

                                                                a:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '4px'}}>
                                                                            <Col sm="6" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '97%'}}>{translate("Completed")}
                                                                                    <br/>{translate("Questions")}</span>
                                                                            </Col>
                                                                            <Col sm="6" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    fontWeight: 'bold',
                                                                                    color: 'black'
                                                                                }}><br/>{this.state.Values.questionCompletate_sirating}%</span>
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="12" style={{marginTop: '-5px'}}>
                                                                                <Progress
                                                                                    value={this.state.Values.questionCompletate_sirating}
                                                                                    className="mt-2 progress-sm progress-color-c"
                                                                                    color=""/>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>,
                                                                b:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '-18px'}}>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '87%'}}> {translate("documentRequest")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.documentRequest_sirating}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr style={{
                                                                            marginTop: '5px',
                                                                            marginBottom: '5px'
                                                                        }}/>
                                                                        <Row>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '87%'}}> {translate("nonconformity")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>

                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '87%'}}> {translate("segnaletedanswer")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.reportedAnswers_sirating}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row style={{marginTop: '15px'}}>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '85%'}}> {translate("positiveResulted")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '85%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.resolvedPositively_sirating}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '85%'}}> {translate("negativeResulted")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '85%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.closedNegatively_sirating}</span>
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>


                                                            }

                                                        }
                                                    />
                                                )
                                                : (
                                                    <HomeServiceBlock
                                                        Logo={{
                                                            a: PRODUCTION_HOST + "/assets/Loghi/SI_Rating_logo.svg",
                                                            b: PRODUCTION_HOST + "/assets/Loghi/SI_Rating_logo.svg"
                                                        }}
                                                        Title={{
                                                            a: <span>{translate("yourprofile")}</span>,
                                                            b: <span>Richieste <br/> Documentali e Audit</span>
                                                        }}
                                                        Border="#fa5c7c"
                                                        Button={{
                                                            type: 2,
                                                            label: 'Si Rating',
                                                            link: 'https://arbspa.it/index.php/si-rating/'
                                                        }}
                                                        Arrows={{show: false, type: 1}}
                                                        Ribbon={false}
                                                        Pages={
                                                            {
                                                                a:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '-10%'}}>
                                                                            <Col sm="12" style={{
                                                                                textAlign: "center",
                                                                                lineHeight: "120%"
                                                                            }}>
                                                                                <p style={{fontSize: '97%'}}>
                                                                                    {decodeMarkDown(translate("siratingAlgoritmDescription"), {noSpan: false})}
                                                                                </p>
                                                                                <br/>
                                                                                <p style={{
                                                                                    fontSize: '97%',
                                                                                    color: '#727cf5',
                                                                                    fontStyle: 'italic'
                                                                                }}>
                                                                                    <b> {translate("unActiveService")}</b>
                                                                                </p>
                                                                            </Col>

                                                                        </Row>
                                                                    </React.Fragment>


                                                            }

                                                        }
                                                    />
                                                )
                                        }


                                        {/* ---- 2 ------------------------------------------------------------------------------------------------ */
                                            this.state.Values.hasSiScoringServiceActive
                                                ? (
                                                    <HomeServiceBlock
                                                        Logo={{a: PRODUCTION_HOST + "/assets/Loghi/SiScoringDashboard.png"}} //, b: PRODUCTION_HOST +"/assets/Loghi/SiScoringDashboard.png"
                                                        LogoClassName={"sa-dashboard-si-scoring-big-logo"}
                                                        Title={{
                                                            a: <span>{translate("yourprofile")}</span>
                                                        }}  //, b: <span>{translate("requestAndAudit")}</span>
                                                        Border="#0acf97"
                                                        Button={{
                                                            type: 1,
                                                            label: 'Si Scoring',
                                                            link: '/si-scoring/' + translate("evaluations")
                                                        }}
                                                        Arrows={{show: false, type: 1}}
                                                        Ribbon={false}
                                                        Pages={
                                                            {

                                                                a:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '26%'}}>
                                                                            <Col sm="6" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '97%'}}>{translate("Completed")}
                                                                                    <br/>{translate("Questions")}</span>
                                                                            </Col>
                                                                            <Col sm="6" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    fontWeight: 'bold',
                                                                                    color: 'black'
                                                                                }}><br/>{this.state.Values.questionCompletate_siscoring}%</span>
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="12" style={{marginTop: '-5px'}}>
                                                                                <Progress
                                                                                    value={this.state.Values.questionCompletate_siscoring}
                                                                                    className="mt-2 progress-sm progress-color-c"
                                                                                    color=""/>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>,
                                                                // b:
                                                                //     <React.Fragment>
                                                                //         <Row style={{ marginTop: '50px' }}>
                                                                //             <Col sm="8" style={{ textAlign: "left", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '87%' }}>{translate("documentRequest")}</span>
                                                                //             </Col>
                                                                //             <Col sm="4" style={{ textAlign: "right", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '100%', color: 'black' }}>{this.state.Values.documentRequest_siscoring}</span>
                                                                //             </Col>
                                                                //         </Row>
                                                                //         <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                                                                //         <Row>
                                                                //             <Col sm="8" style={{ textAlign: "left", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '87%' }}>{translate("nonconformity")}</span>
                                                                //             </Col>
                                                                //             <Col sm="4" style={{ textAlign: "right", lineHeight: "100%" }}>
                                                                //
                                                                //             </Col>
                                                                //         </Row>
                                                                //         <Row>
                                                                //             <Col sm="8" style={{ textAlign: "left", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '87%' }}>{translate("segnaletedanswer")}</span>
                                                                //             </Col>
                                                                //             <Col sm="4" style={{ textAlign: "right", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '100%', color: 'black' }}>{this.state.Values.reportedAnswers_siscoring}</span>
                                                                //             </Col>
                                                                //         </Row>
                                                                //         <Row style={{ marginTop: '15px' }}>
                                                                //             <Col sm="8" style={{ textAlign: "left", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '85%' }}>{translate("positiveResulted")}</span>
                                                                //             </Col>
                                                                //             <Col sm="4" style={{ textAlign: "right", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '85%', color: 'black' }}>{this.state.Values.resolvedPositively_siscoring}</span>
                                                                //             </Col>
                                                                //         </Row>
                                                                //         <Row>
                                                                //             <Col sm="8" style={{ textAlign: "left", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '85%' }}>{translate("negativeResulted")}</span>
                                                                //             </Col>
                                                                //             <Col sm="4" style={{ textAlign: "right", lineHeight: "100%" }}>
                                                                //                 <span style={{ fontSize: '85%', color: 'black' }}>{this.state.Values.closedNegatively_siscoring}</span>
                                                                //             </Col>
                                                                //         </Row>
                                                                //
                                                                //     </React.Fragment>


                                                            }

                                                        }
                                                    />
                                                )
                                                : (
                                                    <HomeServiceBlock
                                                        Logo={{
                                                            a: PRODUCTION_HOST + "/assets/Loghi/SiScoringDashboard.png",
                                                            b: PRODUCTION_HOST + "/assets/Loghi/SiScoringDashboard.png"
                                                        }}
                                                        LogoClassName={"sa-dashboard-si-scoring-big-logo"}
                                                        Title={{
                                                            a: <span>{translate("yourprofile")}</span>,
                                                            b: <span>Richieste <br/> Documentali e Audit</span>
                                                        }}
                                                        Border="#fa5c7c"
                                                        Button={{
                                                            type: 2,
                                                            label: 'Si Scoring',
                                                            link: 'https://arbspa.it/index.php/si-scoring/'
                                                        }}
                                                        Arrows={{show: false, type: 1}}
                                                        Ribbon={false}
                                                        Pages={
                                                            {
                                                                a:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '14%'}}>
                                                                            <Col sm="12" style={{
                                                                                textAlign: "center",
                                                                                lineHeight: "120%"
                                                                            }}>
                                                                                <p style={{fontSize: '97%'}}>
                                                                                    {decodeMarkDown(translate("algoritmDescription"), {noSpan: false})}
                                                                                </p>
                                                                                <br/>
                                                                                <p style={{
                                                                                    fontSize: '97%',
                                                                                    color: '#727cf5',
                                                                                    fontStyle: 'italic'
                                                                                }}>
                                                                                    <b>{translate("unActiveService")}</b>
                                                                                </p>
                                                                            </Col>

                                                                        </Row>
                                                                    </React.Fragment>


                                                            }

                                                        }
                                                    />
                                                )
                                        }


                                        {/*{*/}
                                        {/*    ----- 3 ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                                        {/*    this.state.Values.hasSrToolServiceActive*/}
                                        {/*        ?*/}
                                        {/*        (*/}
                                        {/*            <HomeServiceBlock*/}
                                        {/*                Logo={{*/}
                                        {/*                    a: PRODUCTION_HOST + "/assets/Loghi/SR_Tool_logo.svg",*/}
                                        {/*                    b: PRODUCTION_HOST + "/assets/Loghi/SR_Tool_logo.svg"*/}
                                        {/*                }}*/}
                                        {/*                Title={{*/}
                                        {/*                    a: translate("yourprofile"),*/}
                                        {/*                    b: "Completamento GRI",*/}
                                        {/*                    c: translate("yourprofile"),*/}
                                        {/*                    d: translate("yourprofile"),*/}
                                        {/*                    e: translate("yourprofile")*/}
                                        {/*                }}*/}
                                        {/*                Button={{*/}
                                        {/*                    type: 1,*/}
                                        {/*                    label: 'SR Tool',*/}
                                        {/*                    link: '/srtool/' + translate("collects")*/}
                                        {/*                }}*/}
                                        {/*                Border="#0acf97"*/}
                                        {/*                Arrows={{show: true, type: 1}}*/}
                                        {/*                Ribbon={false}*/}
                                        {/*                Pages={*/}
                                        {/*                    {*/}
                                        {/*                        a:*/}
                                        {/*                            <React.Fragment>*/}
                                        {/*                                <Row>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "left",*/}
                                        {/*                                        lineHeight: "100%",*/}
                                        {/*                                        marginTop: "5px"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span*/}
                                        {/*                                            style={{fontSize: '97%'}}>{translate("Completed")}*/}
                                        {/*                                            <br/>{translate("Questions")}</span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "right",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '100%',*/}
                                        {/*                                            fontWeight: 'bold',*/}
                                        {/*                                            color: 'black'*/}
                                        {/*                                        }}><br/>{this.state.Values.questionCompletate_st}%</span>*/}
                                        {/*                                    </Col>*/}

                                        {/*                                </Row>*/}
                                        {/*                                <Row>*/}
                                        {/*                                    <Col sm="12" style={{marginTop: '-5px'}}>*/}
                                        {/*                                        <Progress*/}
                                        {/*                                            value={this.state.Values.questionCompletate_st}*/}
                                        {/*                                            className="mt-2 progress-sm progress-color-c"*/}
                                        {/*                                            color=""/>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}
                                        {/*                            </React.Fragment>,*/}
                                        {/*                        b:*/}
                                        {/*                            <React.Fragment>*/}
                                        {/*                                <Row style={{marginTop: "-25px"}}>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "left",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            color: 'black'*/}
                                        {/*                                        }}><b>GRI 100</b></span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "right",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            fontWeight: 'bold',*/}
                                        {/*                                            color: 'black'*/}
                                        {/*                                        }}>{this.state.Values.gri100}%</span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}
                                        {/*                                <Row>*/}
                                        {/*                                    <Col sm="12" style={{marginTop: '-7px'}}>*/}
                                        {/*                                        <Progress*/}
                                        {/*                                            value={this.state.Values.gri100}*/}
                                        {/*                                            className="mt-2 progress-sm progress-color-a"*/}
                                        {/*                                            color="orange"/>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}

                                        {/*                                <Row style={{marginTop: "15px"}}>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "left",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            color: '#543b80'*/}
                                        {/*                                        }}><b>GRI 200</b></span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "right",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            fontWeight: 'bold',*/}
                                        {/*                                            color: 'black'*/}
                                        {/*                                        }}>{this.state.Values.gri200}%</span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}
                                        {/*                                <Row>*/}
                                        {/*                                    <Col sm="12" style={{marginTop: '-7px'}}>*/}
                                        {/*                                        <Progress*/}
                                        {/*                                            value={this.state.Values.gri200}*/}
                                        {/*                                            className="mt-2 progress-sm progress-color-b"*/}
                                        {/*                                            color="orange"/>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}

                                        {/*                                <Row style={{marginTop: "15px"}}>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "left",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            color: 'rgb(0, 113, 81)'*/}
                                        {/*                                        }}><b>GRI 300</b></span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "right",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            fontWeight: 'bold',*/}
                                        {/*                                            color: 'black'*/}
                                        {/*                                        }}>{this.state.Values.gri300}%</span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}
                                        {/*                                <Row>*/}
                                        {/*                                    <Col sm="12" style={{marginTop: '-7px'}}>*/}
                                        {/*                                        <Progress*/}
                                        {/*                                            value={this.state.Values.gri300}*/}
                                        {/*                                            className="mt-2 progress-sm progress-color-c"*/}
                                        {/*                                            color="orange"/>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}

                                        {/*                                <Row style={{marginTop: "15px"}}>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "left",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            color: '#fd7e14'*/}
                                        {/*                                        }}><b>GRI 400</b></span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                    <Col sm="6" style={{*/}
                                        {/*                                        textAlign: "right",*/}
                                        {/*                                        lineHeight: "100%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <span style={{*/}
                                        {/*                                            fontSize: '95%',*/}
                                        {/*                                            fontWeight: 'bold',*/}
                                        {/*                                            color: 'black'*/}
                                        {/*                                        }}>{this.state.Values.gri400}%</span>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}

                                        {/*                                <Row>*/}
                                        {/*                                    <Col sm="12" style={{marginTop: '-7px'}}>*/}
                                        {/*                                        <Progress*/}
                                        {/*                                            value={this.state.Values.gri400}*/}
                                        {/*                                            className="mt-2 progress-sm progress-color-d"*/}
                                        {/*                                            color="orange"/>*/}
                                        {/*                                    </Col>*/}
                                        {/*                                </Row>*/}
                                        {/*                            </React.Fragment>*/}
                                        {/*                        ,*/}


                                        {/*                    }*/}

                                        {/*                }*/}
                                        {/*            />*/}
                                        {/*        )*/}
                                        {/*        :*/}
                                        {/*        (*/}
                                        {/*            <HomeServiceBlock*/}
                                        {/*                Logo={{*/}
                                        {/*                    a: PRODUCTION_HOST + "/assets/Loghi/SR_Tool_logo.svg",*/}
                                        {/*                    b: PRODUCTION_HOST + "/assets/Loghi/SR_Tool_logo.svg"*/}
                                        {/*                }}*/}
                                        {/*                Title={{*/}
                                        {/*                    a: translate("yourprofile"),*/}
                                        {/*                    b: translate("yourprofile"),*/}
                                        {/*                    c: translate("yourprofile"),*/}
                                        {/*                    d: translate("yourprofile"),*/}
                                        {/*                    e: translate("yourprofile")*/}
                                        {/*                }}*/}
                                        {/*                Button={{*/}
                                        {/*                    type: 2,*/}
                                        {/*                    label: 'SR Tool',*/}
                                        {/*                    link: 'https://arbspa.it/index.php/sr-tool/'*/}
                                        {/*                }}*/}
                                        {/*                Border="#fa5c7c"*/}
                                        {/*                Arrows={{show: false, type: 1}}*/}
                                        {/*                Ribbon={false}*/}
                                        {/*                Pages={*/}
                                        {/*                    {*/}
                                        {/*                        a:*/}
                                        {/*                            <React.Fragment>*/}
                                        {/*                                <Row style={{marginTop: '-10%'}}>*/}
                                        {/*                                    <Col sm="12" style={{*/}
                                        {/*                                        textAlign: "center",*/}
                                        {/*                                        lineHeight: "120%"*/}
                                        {/*                                    }}>*/}
                                        {/*                                        <p style={{fontSize: '97%'}}>*/}
                                        {/*                                            {translate("management")}*/}
                                        {/*                                        </p>*/}
                                        {/*                                        <br/>*/}
                                        {/*                                        <p style={{*/}
                                        {/*                                            fontSize: '97%',*/}
                                        {/*                                            color: '#727cf5',*/}
                                        {/*                                            fontStyle: 'italic'*/}
                                        {/*                                        }}>*/}
                                        {/*                                            <b>{translate("unActiveService")}</b>*/}
                                        {/*                                        </p>*/}
                                        {/*                                    </Col>*/}

                                        {/*                                </Row>*/}
                                        {/*                            </React.Fragment>*/}

                                        {/*                    }*/}

                                        {/*                }*/}
                                        {/*            />*/}


                                        {/*        )*/}
                                        {/*}*/}
                                        {/*---- rimozione srTool scadenza GRI*/}

                                        {/* ---- 4 ------------------------------------------------------------------------------------------------ */
                                            this.state.Values.hasParitaDiGenereServiceActive
                                                ? (
                                                    <HomeServiceBlock
                                                        Logo={{a: GG, b: GG}}
                                                        LogoClassName={"gglogo"}
                                                        Title={{
                                                            a: <span> {translate("yourprofile")} </span>,
                                                            b: <span> {translate("requestAndAudit")} </span>
                                                        }}
                                                        Border="#0acf97"
                                                        // Button={{ type: 1, label: 'Si Rating', link: '/super-admin/si-rating/' + translate("evaluations") }}
                                                        Button={{
                                                            type: 1,
                                                            label: translate("Gender Gap"),
                                                            link: '/' + translate("gender-gap") + '/' + translate("evaluations")
                                                        }}
                                                        Arrows={{show: true, type: 1}}
                                                        Ribbon={false}
                                                        Pages={
                                                            {

                                                                a:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '67px'}}>
                                                                            <Col sm="6" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '97%'}}>{translate("Completed")}
                                                                                    <br/>{translate("Questions")}</span>
                                                                            </Col>
                                                                            <Col sm="6" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    fontWeight: 'bold',
                                                                                    color: 'black'
                                                                                }}><br/>{this.state.Values.questionCompletate_paritadigenere}%</span>
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="12" style={{marginTop: '-5px'}}>
                                                                                <Progress
                                                                                    value={this.state.Values.questionCompletate_paritadigenere}
                                                                                    className="mt-2 progress-sm progress-color-c"
                                                                                    color=""/>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>,
                                                                b:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '45px'}}>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '87%'}}> {translate("documentRequest")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.documentRequest_paritadigenere}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr style={{
                                                                            marginTop: '5px',
                                                                            marginBottom: '5px'
                                                                        }}/>
                                                                        <Row>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '87%'}}> {translate("nonconformity")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>

                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '87%'}}> {translate("segnaletedanswer")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '100%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.reportedAnswers_paritadigenere}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row style={{marginTop: '15px'}}>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '85%'}}> {translate("positiveResulted")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '85%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.resolvedPositively_paritadigenere}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="8" style={{
                                                                                textAlign: "left",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span
                                                                                    style={{fontSize: '85%'}}> {translate("negativeResulted")} </span>
                                                                            </Col>
                                                                            <Col sm="4" style={{
                                                                                textAlign: "right",
                                                                                lineHeight: "100%"
                                                                            }}>
                                                                                <span style={{
                                                                                    fontSize: '85%',
                                                                                    color: 'black'
                                                                                }}>{this.state.Values.closedNegatively_paritadigenere}</span>
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>


                                                            }

                                                        }
                                                    />
                                                )
                                                : (
                                                    <HomeServiceBlock
                                                        Logo={{a: GG, b: GG}}
                                                        LogoClassName={"gglogo"}
                                                        Title={{
                                                            a: <span>{translate("yourprofile")}</span>,
                                                            b: <span>Richieste <br/> Documentali e Audit</span>
                                                        }}
                                                        Border="#fa5c7c"
                                                        Button={{
                                                            type: 2,
                                                            label: translate("Gender Gap"),
                                                            link: 'https://arbspa.it/index.php/parita-di-genere/'
                                                        }}
                                                        Arrows={{show: false, type: 1}}
                                                        Ribbon={false}
                                                        Pages={
                                                            {
                                                                a:
                                                                    <React.Fragment>
                                                                        <Row style={{marginTop: '13%'}}>
                                                                            <Col sm="12" style={{
                                                                                textAlign: "center",
                                                                                lineHeight: "120%"
                                                                            }}>
                                                                                <p style={{fontSize: '97%'}}>
                                                                                    {decodeMarkDown(translate("ggAlgoritmDescription"), {noSpan: false})}
                                                                                </p>
                                                                                <br/>
                                                                                <p style={{
                                                                                    fontSize: '97%',
                                                                                    color: '#727cf5',
                                                                                    fontStyle: 'italic'
                                                                                }}>
                                                                                    <b> {translate("unActiveService")}</b>
                                                                                </p>
                                                                            </Col>

                                                                        </Row>
                                                                    </React.Fragment>


                                                            }

                                                        }
                                                    />
                                                )
                                        }


                                        {/*
                                        <div className={"sa-dashboard-right-panel"}>

                                            <div className={"sa-dashboard-panel-title"}>
                                                <label className={"sa-dashboard-panel-title"}>
                                                    Le tue informazioni&nbsp;&nbsp;
                                                    <i className="dripicons-question" id="UncontrolledPopover2" type="button" color="primary" />
                                                    <UncontrolledPopover placement="right" target="UncontrolledPopover2" trigger="legacy">
                                                        <PopoverBody>Questa finestra informativa ti mostra le informazioni della tua azienda.</PopoverBody>
                                                    </UncontrolledPopover>
                                                </label>
                                            </div>
                                            <div className={""}>
                                                <label className={""}>Ragione Sociale</label>

                                                {
                                                    isUserRole(role.SUPER_ADMIN)
                                                        ? (
                                                            <Select
                                                                className="react-select sa-dashboard-panel-select"
                                                                classNamePrefix="react-select"
                                                                name="credentials"
                                                                id="credentials-select"
                                                                onChange={changeEvent => {

                                                                    this.setState({Values:false});
                                                                    WelcomePage_Get(changeEvent.value)
                                                                        .then(response => {

                                                                            this.setState({ Values: response })
                                                                        })
                                                                    ;
                                                                }}
                                                                value={{value: this.state.Values.companies.companySelected.companyId, label: this.state.Values.companies.companySelected.companyName }}
                                                                options={
                                                                    this.state.Values.companies.companies.map(x => ({ ...x, value: x.id, label: x.name }))
                                                                }
                                                                placeholder={<span className={"select-placeholder"}>Seleziona Azienda</span>}
                                                            />
                                                        )
                                                        : <span className={""}>{ this.state.Values.companies.companySelected.companyName }</span>
                                                }
                                            </div>
                                            <div className={""}>
                                                <label className={""}>Indirizzo</label>
                                                <span className={""}>{ this.state.Values.companies.companySelected.indirizzo }</span>
                                            </div>
                                            <div className={""}>
                                                <label className={""}>Città / CAP</label>
                                                <span className={""}>{ this.state.Values.companies.companySelected.localita +"-"+ this.state.Values.companies.companySelected.cap }</span>
                                            </div>
                                            <div className={""}>
                                                <label className={""}>P.IVA</label>
                                                <span className={""}>{ this.state.Values.companies.companySelected.piva }</span>
                                            </div>
                                            <div className={""}>
                                                <label className={""}>Settore</label>
                                                <span className={""}>{ this.state.Values.companies.companySelected.sector || " " }</span>
                                            </div>
                                            <div className={""}>
                                                <label className={""}>Titolare Account</label>
                                                <span className={""}>{ this.state.User.email }</span>
                                            </div>
                                            {/*
                                                <div className={""}>
                                                    <span
                                                        onClick={ clickEvent => {

                                                            this.setState({
                                                                ...this.state,
                                                                companyEditModal: {
                                                                    ragioneSociale:         this.state.Values.companies.companySelected.companyName,
                                                                    partitaIva:             this.state.Values.companies.companySelected.piva,
                                                                    dimension: {
                                                                        id: this.state.Values.companies.companySelected.dimensioni
                                                                    },
                                                                    employeesNumbersLevel:  this.state.Values.companies.companySelected.employeesNumbersLevel,
                                                                    indirEmail:             this.state.Values.companies.companySelected.email,
                                                                    cap:                    this.state.Values.companies.companySelected.cap,
                                                                    localita:               this.state.Values.companies.companySelected.localita,
                                                                    indirizzo:              this.state.Values.companies.companySelected.indirizzo
                                                                }
                                                            })
                                                        }}
                                                        className={"sa-dashboard-empty-button"}
                                                    >
                                                        Modifica Informazioni
                                                    </span>
                                                </div>
                                            * /}

                                        </div>
                                        */}


                                    </div>
                                    {/* ------------------------------------------------------------------------------------------------------- */}


                                </Col>


                            </Row>


                        </Col>
                    </Row>


                </React.Fragment>
            );

        } else {
            return (
                <WaitingOrNodataMessage
                    waiting={true}
                    waitMessage={"Caricamento..."}
                    nodataMessage={"Nessuna Raccolta"}
                    fullHeight={true}

                    spinnerColor={"success"}
                />
            )
        }


    }

    componentDidMount() {


        let companyID = localStorage.getItem("company_id") || 0;

        WelcomePage_Get(companyID)
            .then(response => {

                this.setState({Values: response})
            })
        ;


    }

};

