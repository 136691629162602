import React, {useState} from "react";
import "./siscoring-waiting-download-modal.css";
import {Modal, ModalBody, ModalHeader, Progress} from "reactstrap";
import {decodeMarkDown, errorlog, extractErrorMessage, renderlog, showlog} from "../../../utils";
import {saveAs} from 'file-saver';
import ActionButton from "../../../components/save-button";
import {PRODUCTION_HOST} from "../../../env";
import {translate} from "../../../components/i18n/translate-function";

export const SiscoringWaitingDownloadModal = props => {

    const [perc, setPerc] = useState( parseInt(props.perc, 10) || 1 );
    const [errMsg, setErrMsg] = useState();

    renderlog("SiscoringWaitingDownloadModal", { perc }, props);

    let allContext = [
        (
            <React.Fragment>
                <h5>{ decodeMarkDown( translate("whats_siscoring"), {noSpan: true} ) }</h5>
                <span>{ decodeMarkDown( translate("siscoring_download_text"), {noSpan: true} ) }</span>
            </React.Fragment>
        ),
        /*
        (
            <React.Fragment>
                <h5>Cos'è Si Rating?</h5>
                <span>
                    Lo strumento, sviluppato in partnership ufficiale con SASB, che supporta la tua organizzazione<br/>
                    nel processo di misurazione della performance di sostenibilità
                </span>
            </React.Fragment>
        ),
        (
            <React.Fragment>
                <h5>Cos'è SR Tool?</h5>
                <span>
                    Il gestionale dinamico, certificato da parte del GRI, che facilita e supporta le aziende<br/>
                    nella rendicontazione sistematica e nella gestione ed analisi dei dati
                </span>
            </React.Fragment>
        )
         */
    ];

    let context = !!props.file
        ? allContext[0]
        : allContext[ Math.floor( perc / 30 ) % allContext.length ]
    ;

    let mainText = !!props.file ? translate("YOUR_SCORE_CARD_IS_READY") : translate("making_scorecard_text");


    // error -----------------------------------------------------------------------------------------------------------
    if( !!props.errorMessage || !!errMsg ) {
        mainText = translate("something_went_wrong")
        context = (
            <span>{ props.errorMessage || errMsg }</span>
        );
    }
    // ----------------------------------------------------------------------------------------------------------- error

    let content = (
        <div className={""} id={"siscoring-waiting-modal-main-page"}>
            <img
                src={ PRODUCTION_HOST +"/assets/Loghi/Si_Scoring.png" }
                alt={"Si Scoring"}
            />
            <div className={"siscoring-waiting-modal-main-text"}>{ mainText }</div>
            <div className={"siscoring-waiting-modal-progress-container"}>
                <Progress
                    animated
                    striped
                    color={"blue"}
                    value={ perc }
                    className="mb-3 progress-sm"
                    id={"siscoring-waiting-modal-progress"}
                />
                <div className={"siscoring-waiting-modal-progress-value"+ ((perc > 52) ? " white-text" : "")}>{ perc } %</div>
            </div>

            <div className={"siscoring-waiting-modal-text"}>
                { context }
            </div>


            {
                (!!props.file && !props.errorMessage && !errMsg)
                    ? (
                        <ActionButton
                            buttonText={
                                <span>
                                    Download
                                    <i className="dripicons-download"/>
                                </span>
                            }
                            className={"siscoring-waiting-modal-download-button"}
                            promise={ () => {
                                return (
                                    new Promise((onEnd, onFail) => {
                                        try {

                                            let file = new Blob([ props.file ], {type: "application/pdf;charset=utf-8"});
                                            saveAs( file, "Si-Scoring Report "+ props.companyName );
                                            setTimeout(
                                                () => {
                                                    onEnd();
                                                },
                                                1000
                                            )

                                        }
                                        catch (e) {
                                            onFail( e );
                                        }
                                    })
                                )
                            }}
                            onResult={ result => {
                                showlog("file scaricato");
                                props.onClose()
                            }}
                            onError={ e => {
                                errorlog("download file", e);
                                let errorMessage = extractErrorMessage( e );
                                showlog( errorMessage );
                                setErrMsg( errorMessage );
                            }}
                        />
                    )
                    : (
                        <div className={"siscoring-waiting-modal-space-download-button"}/>
                    )
            }

            <div className={"siscoring-waiting-modal-footer"}>
                { translate("assistance_text") }
                <span
                    onClick={ clickEvent => {
                        /*
                        window.location.href =
                            window.location.href
                                .split("/")
                                .filter( (_, i) => i < 3)
                                .join("/")
                                + '/support'
                        ;
                        */
                        window.open(
                            window.location.href
                                .split("/")
                                .filter( (_, i) => i < 3)
                                .join("/")
                            + '/support'
                        )
                    }}
                >
                    { translate("Assistance") }
                </span>
            </div>

        </div>
    );

    if( !!props.file && (perc < 100) ) setPerc( 100 );

    // random method ---------------------------------------------------------------------------------------------------
    /*
    setTimeout(
        () => {
            if( !props.file ) {
                let step = (((perc === 1) ? 20 : 99) - parseInt(perc, 10));
                let newPerc = perc + Math.floor( Math.random() * (step +1) );
                setPerc( newPerc );
            }
        },
        (perc === 1) ? 2000 : 750
    )
    */

    // linear method ---------------------------------------------------------------------------------------------------
    setTimeout(
        () => {
            if( !props.file ) {
                let newPerc = perc + 1;
                if( newPerc < 100 ) setPerc( newPerc );
            }
        },
        250
    )
    // --------------------------------------------------------------------------------------------------- linear method

    let closeByHeaderX =
        ( !!props.file )
            ? (
                closeEvent => {
                    props.onClose();
                }
            )
            : null
    ;


    return (
        <Modal
            isOpen={true}
            size={"lg"}
            toggle={ closeEvent => {
                if( !!props.file ) {
                    props.onClose();
                }
            }}
        >
            <ModalHeader toggle={ closeByHeaderX } >
                Score Card
            </ModalHeader>
            <ModalBody>
                { content }
            </ModalBody>
        </Modal>
    );
}
