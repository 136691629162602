import React, {Component, Suspense} from 'react';
import {withRouter} from "react-router-dom";
import {getQuestions} from "../../../business-logic/rating";
import {errorlog, renderlog, showlog} from "../../../utils/dev-utils";
import {getQuestionFatherTreeNodeByPath} from "../../../business-logic/question-tree-manager";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledCollapse
} from "reactstrap";
import {COLOR_POLICY, MATERIALITY_REASON_LEVEL, PATH_PREFIX, role, SIRATING_TREE_DEEP_LEVELS_NAME} from "../../../env";
import classnames from "classnames";
import StandBySystemModalComponent from "../../../components/area/gri-admin/standby-system-modal";
import ErrorMessageModalComponent from "../../../components/error-message-modal-component";
import {cloneObject} from "../../../utils/object-utils";
import Select from "react-select";
import PageTitle from "../../../components/PageTitle";
import {EMOJ} from "../../../emoj";
import {getLastElementOr, isNotEmptyArray} from "../../../utils/array-utils";
import {emoj} from "../../../utils/string-utils";
import {WaitingOrNodataMessage} from "../../../components/waiting-or-nodata-message";
import MaterailityReport from "../../../components/area/super-admin/materailityReport-modal";
import {translate} from '../../../components/i18n/translate-function';

class SIRatingReadOnlyEvalutationPage extends Component {

	constructor(props) {
		super(props);

		showlog("Costruttore");


		this.loadData = this.loadData.bind(this);
		this.inWait = this.inWait.bind(this);
		this.buildQuestions = this.buildQuestions.bind(this);
		this.buildThemes = this.buildThemes.bind(this);
		this.buildSubThemes = this.buildSubThemes.bind(this);
		this.materialityFilter = this.materialityFilter.bind(this);
		this.getRatingId = this.getRatingId.bind(this);
		this.state = { render: false, isShowTextMotivation: false, nodeIdArray: false, textMotivation: false, nodeTextMotivationShowArray: [], textMotivationShowArray: [] }
	}

	inWait() {
		return (
			<div>Wait</div>
		);
	}


	getRatingId = () => (this.props.match.params.id || this.props.id);

	materialityFilter = element => {

		if (!!element.materiality && (!!this.state.materialityLevelActiveFilter || this.state.materialityLevelActiveFilter === 0)) {
			return (this.state.materialityLevelActiveFilter === element.materiality.value);
		}
		return true;

	}

	render() {


		renderlog("SIRatingReadOnlyEvalutationPage", this.state, this.props, COLOR_POLICY.warning);

		if (!this.state.tree) {
			return (
				<WaitingOrNodataMessage
					waiting={!this.state.tree}
					fullHeight={true}
					nodataMessage={"Nessuna Valutazione"}
				/>
			);
		}

		let confirmingText = (
			!!this.state.confirmed
				? "    Confermata    "
				: (
					!!this.state.error
						? "    " + emoj(EMOJ.warning) + "  " + emoj(EMOJ.sad_but_relieved_face) + "    "
						: "Conferma Configurazione"
				)
		)
			;




		return (
			<Suspense fallback={this.inWait()} >



				<PageTitle
					breadCrumbItems={[
						{
							label:
								Object.keys(role)
									.map(roleObjectName => role[roleObjectName])
									.filter(roleObject => roleObject.name === localStorage.getItem("role"))
									.reduce(getLastElementOr, "Nessun Ruolo")
									.label
							, path: PATH_PREFIX + "/welcome"
						},
						{ label: translate("Evaluations"), path: PATH_PREFIX + "/super-admin/si-rating/" + translate("evaluations") },
						{
							label:
								(!!this.state && !!this.state.metaData)
									? this.state.metaData.name
									: (
										(!!this.state && !!this.state.ratingName)
											? this.state.ratingName
											: this.props.match.params.id
									)
							, active: true
						}
					]}
					title={"SI Rating"}
				/>


				<Card className={"card-validated-bg"}>
					<CardHeader>

						<Row>
							<Col>
								<h4>
									<span className={"font-weight-normal"}>
										Configurazione <em>( in sola lettura )</em> di &nbsp;
										<strong>
											{
												(!!this.state && !!this.state.metaData)
													? this.state.metaData.name
													: (
														(!!this.state && !!this.state.ratingName)
															? this.state.ratingName
															: this.props.match.params.id
													)
											}
										</strong>
									</span>
									<br /><br />
								</h4>
							</Col>
							<Col style={{textAlign:"right"}}>
								<Button color="primary"
									onClick={
										()=>this.setState({materialityReportModal:true})
									}>
									Report Materialità
								</Button>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>

						<Row className={"sdg-flag-icon-container"}>
							<Col>
								<Label for="subThemes">{translate("Filter")}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
								<Select
									className="react-select question-filter-select"
									classNamePrefix="react-select"
									name="question-filter"
									id="question-filter"
									onChange={changeEvent => {

										this.setState({
											...this.state,
											materialityLevelActiveFilter: changeEvent.value
										})

									}}
									options={[
										{ label: "Tutte" },
										...MATERIALITY_REASON_LEVEL
									]}
									value={
										(!!this.state.materialityLevelActiveFilter || this.state.materialityLevelActiveFilter === 0)
											? (
												MATERIALITY_REASON_LEVEL
													.filter(mat => mat.value === this.state.materialityLevelActiveFilter)
													.reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0])
											)
											: null
									}
									placeholder={<span className={"select-placeholder"}>{translate("Select filter")}</span>}
								/>
							</Col>
						</Row>



						<Nav tabs>
							{
								this.state.tree.tabs
									.map((tab, index) => {
										return (
											<NavItem key={index}>
												<NavLink
													id={SIRATING_TREE_DEEP_LEVELS_NAME[0] + "-" + tab.id}
													href="#"
													className={classnames({ active: this.state.activeTab === tab.id })}
													onClick={() => {


														let treeTmp = cloneObject(this.state.tree);
														let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, [tab.id]);
														nodeTmp.open = true;

														this.setState({
															...this.state,
															activeTab: tab.id,
															tree: treeTmp
														});
													}}>
													<i
														className={classnames(
															tab.icon,
															'd-lg-none',
															'd-block',
															'mr-1'
														)} />
													<span className="d-none d-lg-block">{tab.name}</span>
												</NavLink>
											</NavItem>
										);
									})
							}



						</Nav>

						<TabContent activeTab={this.state.activeTab}>

							{
								this.state.tree.tabs
									.map((tab, index) => {
										return (
											<TabPane
												tabId={tab.id}
												key={index}
											>
												<Row>
													<Col sm="12">

														{
															tab.themes


																// filtro
																.filter(this.materialityFilter)



																.map((th, chIndex) => {
																	return (
																		<Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
																			<CardHeader>

																				<NavLink
																					id={SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}
																					href="#"
																					onClick={clickEvent => {

																						let treeTmp = cloneObject(this.state.tree);
																						let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, th.path);
																						nodeTmp.open = !getQuestionFatherTreeNodeByPath(this.state.tree, th.path).open;
																						this.setState({
																							...this.state,
																							tree: treeTmp
																						});

																					}}
																				>

																					<h5 className="m-0">
																						<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">
																							{th.name}
																						</div>




																						{
																							(!!!isNotEmptyArray(th.subThemes)) &&
																							(

																								<React.Fragment>

																									<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title spaceTextInputButton">
																										Livello di Materialità:&nbsp;&nbsp;&nbsp;&nbsp;
																										{
																											MATERIALITY_REASON_LEVEL
																												.filter(mat => mat.value === th.materiality.value)
																												.map(mat => mat.label)
																												.reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0].label)
																										}
																										&nbsp;&nbsp;&nbsp;&nbsp;
																										{
																											(!!th.materiality.motivation)
																												? (
																													<span className={"sirating-conf-readonly-materiality-reason"}>
																														{th.materiality.motivation}
																													</span>
																												)
																												: ""
																										}
																									</div>


																								</React.Fragment>
																							)
																						}

																						<span className="float-right">


																							{
																								(!!getQuestionFatherTreeNodeByPath(this.state.tree, th.path).open)
																									? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
																									: <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
																							}
																						</span>
																					</h5>
																				</NavLink>

																			</CardHeader>
																			<UncontrolledCollapse toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[1] + "-" + th.id}>
																				<CardBody>


																					{
																						// domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
																						this.buildQuestions(th.questions)
																					}

																					{   // sotto-tematica --------------------------------------------------------------------------------------------------------------------------------------------------------------------
																						this.buildSubThemes(th.subThemes)

																					}

																				</CardBody>
																			</UncontrolledCollapse>
																		</Card>
																	);
																})

														}

													</Col>
												</Row>
											</TabPane>
										);
									})
							}

							{/*<GotoTopComponent/>*/}

						</TabContent>
					</CardBody>
				</Card>






				{
					(!!this.state.errorMessage)
						? (
							<ErrorMessageModalComponent
								message={this.state.errorMessage}
								noheader={true}
								onClose={() => {
									this.setState({
										...this.state,
										errorMessage: null
									})
								}}
							/>
						)
						: null
				}



				{
					(!!this.state.inWaiting)
						? (
							<StandBySystemModalComponent
								message={this.state.processingMessage}
								didMount={() => {

									/*
									this.setState({
										...this.state,
										stopable: true
									})
									*/
								}}
							/>
						)
						: null
				}
				{
                    (!!this.state.materialityReportModal)
                        ? (
                            <MaterailityReport
                                evaluation_id={ this.getRatingId() }
                                onClose={ () => {
                                    this.setState({
                                        ...this.state,
                                        materialityReportModal: null
                                    })
                                }}
                            />
                        )
                        : null
                }

			</Suspense>
		);
	}

	componentDidMount() {

		this.loadData();
	}

	// non necessario
	buildThemes(themes) { }

	buildSubThemes(subThemes) {

		return (
			subThemes

				// filtro
				.filter(this.materialityFilter)

				.map((sth, infoIndex) => {

					return (
						<Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
							<CardHeader>
								<h5 className="m-0">
									<div className="custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title">
										{sth.name}

									</div>

									<React.Fragment>

										<div className={"custom-accordion-title pt-2 pb-2 sirating-themes-accordion-title spaceTextInputButton"} >
											Livello di Materialità:&nbsp;&nbsp;&nbsp;&nbsp;
											{
												MATERIALITY_REASON_LEVEL
													.filter(mat => mat.value === sth.materiality.value)
													.map(mat => mat.label)
													.reduce(getLastElementOr, MATERIALITY_REASON_LEVEL[0].label)
											}
											&nbsp;&nbsp;&nbsp;&nbsp;
											{
												(!!sth.materiality.motivation)
													? (
														<span className={"sirating-conf-readonly-materiality-reason"}>
															{sth.materiality.motivation}
														</span>
													)
													: ""
											}
										</div>



									</React.Fragment>




									<span className="float-right">
										<NavLink

											id={SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}
											href="#"
											onClick={clickEvent => {

												let treeTmp = cloneObject(this.state.tree);
												let nodeTmp = getQuestionFatherTreeNodeByPath(treeTmp, sth.path);
												nodeTmp.open = !getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open;
												this.setState({
													...this.state,
													tree: treeTmp
												});


											}}
										>
											{
												(!!getQuestionFatherTreeNodeByPath(this.state.tree, sth.path).open)
													? <i className="mdi mdi-minus-box-multiple font-18 accordion-arrow" />
													: <i className="mdi mdi-plus-box-multiple font-18 accordion-arrow" />
											}
										</NavLink>
									</span>
								</h5>

							</CardHeader>
							<UncontrolledCollapse toggler={"#" + SIRATING_TREE_DEEP_LEVELS_NAME[2] + "-" + sth.id}>
								<CardBody>

									{
										// domande --------------------------------------------------------------------------------------------------------------------------------------------------------------------
										this.buildQuestions(sth.questions)

									}

								</CardBody>
							</UncontrolledCollapse>
						</Card>
					);
				})
		);

	}


	buildQuestions(questions) {
		return (
			questions

				.map((question, qIndex) => {
					return (
						<Card className="mb-0" key={SIRATING_TREE_DEEP_LEVELS_NAME[3] + "-" + question.id}>
							<CardHeader>
								<div className={"custom-accordion-title tree_comander pt-2 pb-2 " + (!!question.enabled ? "question-enabled-text" : "question-disabled-text")}>
									<code>{question.code}</code><br />
									{
										(!!question.isSectorQuestion)
											? (
												<React.Fragment>
													<code className={"flag-sector"}>
														{question.sectorName}
													</code>

													{
														(!!question.companySize)
															? (
																<em className={"question-sector-company-size"}>
																	{
																		this.state.comanySizes
																			.filter(cs => cs.id === question.companySize)
																			.reduce(getLastElementOr, { name: "---" })
																			.name
																	}
																</em>

															)
															: null
													}

													<br />
												</React.Fragment>
											)
											: null
									}
									{question.name}
								</div>
								<span className={"float-right"}>
									{!!question.enabled}
								</span>
							</CardHeader>

						</Card>
					);
				})
		);
	}


	loadData() {
		getQuestions(this.getRatingId())

			// finish ----------------------------------------------------------------------------------------------------------------
			.then(({ seed, tree, status, comanySizes, ratingName }) => {

				showlog("controllo ultimo step");
				showlog(status, seed, tree);
				// debugger

				this.setState({
					...this.state,
					seed: seed,
					tree: tree,
					activeTab: tree.tabs[0].id,
					status: status,
					comanySizes: comanySizes,
					ratingName: ratingName
				})
			})

			.catch(e => {
				errorlog("errore", e);
			})
	}

}

export default withRouter(SIRatingReadOnlyEvalutationPage);
